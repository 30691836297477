import React, { useEffect, useRef, useState } from 'react';
import { Text, Checkbox, Icon, SearchBox, Label } from '@fluentui/react';
import { ITagsProps } from 'app/models/common/response';
import { useAssignRequest } from 'app/services/mutations';
import { AppQueryClient } from 'app/services/clients/query-client';

// STYLES
import './TagsList.scss';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    selectedKeys?: ITagsProps[];
    setSelectedKeys?: Function;
    options: ITagsProps[];
    selectedRequest?: any;
    addTagsRequest: Function;
}
const TagsList: React.FC<IProps> = ({ selectedKeys, setSelectedKeys, options, addTagsRequest }) => {
    // COMPONENT STATE
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<ITagsProps[]>([]);
    const dropDownRef = useRef(null);

    // HELPERS
    const { isLoading: isAssigning, isSuccess: successRequestAssign } = useAssignRequest();

    const onChange = (key: string) => {
        let newVal = selectedKeys.slice(0);
        let element = newVal.filter((item) => item.id === key)[0];
        if (element) {
            let arr = [...newVal];
            arr.splice(newVal.indexOf(element), 1);
            setSelectedKeys(arr);
        } else {
            setSelectedKeys([...newVal, options.filter((item) => item.id === key)[0]]);
        }
    };

    const isChecked = (item: string) => {
        return selectedKeys.filter((ele) => ele?.id === item).length !== 0;
    };

    const getTagDropdownText = (): string => {
        return selectedKeys?.length === 0 ? '+ Add Tag' : '+ Add more ...';
    };

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };

    useDropDownDismiss(dropDownRef, onToggleShow, addTagsRequest);

    useEffect(() => {
        if (!isAssigning && successRequestAssign) {
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
        }
    }, [successRequestAssign]);

    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: ITagsProps[] = [];
            options.forEach((item) => {
                if (item.name.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    // RENDER
    return (
        <div className={`tags-filter filter-cont `}>
            <div
                data-testid="tags-list-open-btn-test"
                className="tags-title row-flex-box align-center"
                onClick={() => onToggleShow()}>
                <Text className="tags-label txt-link">{getTagDropdownText()} </Text> <Icon iconName="ChevronDown" />
            </div>

            {showDropdown && (
                <div ref={dropDownRef} className="tags-dropdown-cont" data-testid="dropdown-container">
                    <div className="row-flex-box align-center justify-sb dropdown-header">
                        <div onClick={() => onToggleShow()} data-testid="onTogleShow">
                            <Text>{getTagDropdownText()}</Text>
                            <Icon iconName="ChevronUp" />
                        </div>
                    </div>

                    <div className="tags-search-cont">
                        <SearchBox
                            placeholder="Search"
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                            onSearch={(newValue) => setSearchValue(newValue)}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item) => (
                                <div className={`tags-dropdown-item ${isChecked(item.id) ? 'selected' : ''} `}>
                                    <Checkbox checked={isChecked(item.id)} onChange={() => onChange(item.id)} />
                                    <Label>{item.name}</Label>
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item) => (
                                <div className={`tags-dropdown-item ${isChecked(item.id) ? 'selected' : ''}  `}>
                                    <Checkbox checked={isChecked(item.id)} onChange={() => onChange(item.id)} />

                                    <Label>{item.name}</Label>
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function, addTagsRequest: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
                addTagsRequest();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, addTagsRequest]);
};

export default TagsList;
