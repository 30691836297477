import React, { Fragment, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Text, PrimaryButton, Image, Link, IconButton, MessageBar, MessageBarType, VerticalDivider } from '@fluentui/react';
import './FileDropzone.scss';
import fileAttachIcon from 'app/static/icons/file-attach.svg';
import { API, StringConstants } from 'app/utils/constants';
import { getIcon, keyPress } from 'app/components/helpers';
import { useHistory } from 'react-router';
import { getPortalValue } from 'app/utils/utilities';
import scssVariables from '../../assets/styles/variables.module.scss';
import { Portal } from 'app/enums/Portal.enum';
import { IAttachment, IBlobUploadFile } from 'app/models/common/post';
import { Files } from 'app/utils/constants'
import { createBlobName } from '../../utils/blobStorageHelpers';

interface IProps {
    title?: string;
    subTitle?: string;
    customRef?: any;
    fileNames?: any[];
    setFileNames?: Function;
    handleProgressBar?: Function;
    blobUploadFiles: IBlobUploadFile[];
    setBlobUploadFiles: Function;
    isUploadingToBlob: boolean;
}

const FileDropzone: React.FC<IProps> = ({ title, subTitle, customRef, fileNames, setFileNames, setBlobUploadFiles, isUploadingToBlob, blobUploadFiles }) => {
    const portal = getPortalValue(useHistory()?.location?.pathname);
    const [wrongFileType, setWrongFileType] = useState(false);
    const [attachmentList, setAttachmentList] = useState<IAttachment[]>([]);

    // added attachment will default to external
    const [isExternalAttachment, setIsExternalAttachment] = useState(true);
    const handleDrop = (acceptedFiles: any) => {
        setWrongFileType(false);
        let copyOfFileNames = [...fileNames];
        let attachments = [...attachmentList];
        let blobFiles: IBlobUploadFile[] = [...blobUploadFiles];
        acceptedFiles.forEach((file: any) => {
            if (Files.AllowedContentTypes.includes(file.type.toLowerCase()) && Files.AllowedFileExtensions.includes(file.name.split('.')?.pop().toLowerCase())) {
                if (fileNames.filter((item: any) => item.name === file.name).length === 0) {
                    const blobName = createBlobName(file.name);
                    const blobUrl = `${API.API_FILES_URL}` + blobName;
                    copyOfFileNames.push({
                        name: file.name,
                        size: file.size,
                        url: blobUrl,
                        blobName: blobName,
                        isExternal: isExternalAttachment
                    });
                    blobFiles.push({ blobName: blobName, file: file });
                    attachments.push({ url: blobUrl, type: file.type, isExternal: isExternalAttachment, blobName: blobName });
                }
            }
            else {
                setWrongFileType(true);
            }
        });
        setFileNames(copyOfFileNames);
        setAttachmentList(attachments);
        setBlobUploadFiles(blobFiles);
    };

    function handleRemove(i: any) {
        const list = [...fileNames];
        list.splice(i, 1);
        setFileNames(list);

        setWrongFileType(false);
    }

    const handleAddMore = () => {
        setIsExternalAttachment(true);
        customRef.current?.click();
    };

    const handleExternalAttachment = () => {
        setIsExternalAttachment(true);
        customRef.current?.click();
    };

    const handleInternalAttachment = () => {
        setIsExternalAttachment(false);
        customRef.current?.click();
    };

    return (
        <div data-testid="main-container" className={`dropzone-container ${fileNames?.length !== 0 ? 'list-view' : ''}`}>
            {fileNames?.length === 0 && (
                <Fragment>
                    {portal === Portal.Admin ? (
                        <div className="dropzone">
                            <div className="dropzone-content">
                                <Image className="file-attach-icon" src={fileAttachIcon} alt="drag drop" />
                                <Dropzone data-testid="drop-zone" onDrop={handleDrop} noDragEventsBubbling={true} noDrag={true}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone' })} data-testid="dropzone">
                                            {<input {...getInputProps()} data-testid="drop-input" />}
                                            <div className="managed-request-attachment-btns">
                                                <PrimaryButton
                                                    text="Upload internal"
                                                    className="btn-upload btn-internal-upload"
                                                    data-testid="primary-button"
                                                    onClick={handleInternalAttachment}
                                                />
                                                <PrimaryButton
                                                    text="Upload external"
                                                    className="btn-upload btn-external-upload"
                                                    data-testid="primary-button"
                                                    onClick={handleExternalAttachment}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <div className="managed-request-sub-title">
                                    <Text className="sub-title">
                                        Internal attachments will be available to admins only. External attachments will be available to anyone.
                                    </Text>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Dropzone data-testid="drop-zone" onDrop={handleDrop} noDragEventsBubbling={true}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: 'dropzone' })} data-testid="dropzone">
                                    <input {...getInputProps()} data-testid="drop-input" />
                                    <div className="dropzone-content">
                                        <Text className="txt-1">Drag &amp; Drop Files Here</Text>
                                        <Image className="file-attach-icon" src={fileAttachIcon} alt="drag drop" />
                                        <Text>(or)</Text>
                                        <PrimaryButton text="Upload" className="btn-upload" data-testid="primary-button" />
                                        {title && <Text className="title">{title}</Text>}
                                        {subTitle && <Text className="sub-title">{subTitle}</Text>}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    )}
                </Fragment>
            )}
            <div className="m-l-10 m-r-10 m-b-10 m-t-10">
                {wrongFileType && (
                    <MessageBar messageBarType={MessageBarType.error} messageBarIconProps={{ iconName: 'Error' }}>
                        One or more files not supported and have been removed from the list.
                    </MessageBar>
                )}
            </div>
            {fileNames?.length !== 0 && (
                <div className="attachment-list-container">
                    <div className="btn-attachments">
                        {portal === Portal.Admin ? (
                            <Dropzone noDrag onDrop={handleDrop} noDragEventsBubbling={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} ref={customRef} data-testid="second-zone" />
                                        <div className="add-more-attachments">
                                            <p>
                                                <Link
                                                    role="link"
                                                    className="add-more-link"
                                                    tabIndex={0}
                                                    onClick={handleInternalAttachment}
                                                    disabled={isUploadingToBlob}
                                                >
                                                    +Add Internal Attachments
                                                </Link>
                                                <Link
                                                    role="link"
                                                    className="add-more-link"
                                                    tabIndex={0}
                                                    onClick={handleAddMore}
                                                    disabled={isUploadingToBlob}
                                                >
                                                    +Add External Attachments
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        ) : (
                            <Dropzone noDrag onDrop={handleDrop} noDragEventsBubbling={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} ref={customRef} data-testid="second-zone" />
                                        <p>
                                            <Link role="link" className="add-more-link" tabIndex={0} onClick={handleAddMore} disabled={isUploadingToBlob}>
                                                Add more files
                                            </Link>
                                        </p>
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    </div>
                    {fileNames?.map((item: { name: string; isExternal: boolean }, idx: number) => {
                        return (
                            <div className="attachment-list attached-file" tabIndex={0}>
                                {/* attachment classification */}
                                {portal === Portal.Admin ? (
                                    <div className="attachment-group">
                                        <span
                                            className="attachment-classification"
                                            style={{
                                                color: item.isExternal ? scssVariables.red300 : scssVariables.green700,
                                                fontWeight: +scssVariables.fontWeight600
                                            }}>
                                            {item.isExternal ? <span>External</span> : <span>Internal</span>}
                                        </span>
                                        <VerticalDivider className="attachment-row-divider" />
                                    </div>
                                ) : null}

                                {/* file name, icon, and remove button */}
                                <div className="attachment-center-group">
                                    {/* file name and icon */}
                                    <div key={idx} className="attachment-file-group" tabIndex={0}>
                                        <span className={getIcon(item.name)} aria-label="file icon"></span>
                                        <span className="file-txt" aria-label="file name">
                                            {item.name}
                                        </span>
                                    </div>

                                    {/* remove attachment */}
                                    <div className="attachment-remove-group">
                                        <VerticalDivider className="attachment-row-divider" />
                                        <IconButton
                                            role="button"
                                            aria-label="remove"
                                            tabIndex={0}
                                            onClick={() => handleRemove(idx)}
                                            onKeyPress={(e: any) => keyPress(e, () => handleRemove(idx))}
                                            iconProps={{ iconName: 'Cancel' }}
                                            disabled={isUploadingToBlob}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default FileDropzone;
