import React from 'react';
import { Text } from '@fluentui/react';
import { Link } from 'react-router-dom';
import './HomeCards.scss';
import { Trans } from 'react-i18next';
import useStore from 'app/store';
import { isContentCreator, isContentManager, isSuperAdmin } from 'app/utils/authUtilities';
import { IUserDetails } from 'app/models/common/post';
import { StringConstants } from 'app/utils/constants';
const HomeCards: React.FC = () => {
    const changeView = useStore((state: any) => state.changeView);
    const changeCard = useStore((state: any) => state.changeCard);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const setRequestType = useStore((state: any) => state.setRequestType);
    const setListBuilderFilters = useStore((state: any) => state.setListBuilderFilters);
    const setFilterPanelDisabled = useStore((state: any) => state.setFilterPanelDisabled);
    const setFilterIcon = useStore((state: any) => state.setListIcon);

    return (
        <>
            <div className="home-admin-cards-container">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="home-cards">
                                <Link
                                    tabIndex={0}
                                    onClick={() => {
                                        changeView('listView');
                                        setRequestType(StringConstants.MANAGE_REQUEST);
                                        setListBuilderFilters(null);
                                        setFilterPanelDisabled(false);
                                        setFilterIcon('');
                                        changeCard('manageMy');
                                    }}
                                    to="/manage-requests">
                                    <div className="home-card">
                                        <span className="home-card-icon request-icon"></span>
                                        <div className="home-card-txt">
                                            <Text className="home-card-head">
                                                <Trans>Manage Requests</Trans>
                                            </Text>
                                            <Text className="home-card-desc">
                                                <Trans>Assign-reassign-track requests</Trans>
                                            </Text>
                                        </div>
                                    </div>
                                </Link>
                                <Link tabIndex={0} to="/manage-reports">
                                    <div className="home-card">
                                        <span className="home-card-icon triage-icon"></span>
                                        <div className="home-card-txt">
                                            <Text className="home-card-head">
                                                <Trans>Manage Reports</Trans>
                                            </Text>
                                            <Text className="home-card-desc">
                                                {isSuperAdmin(userDetails?.userRoles) && (
                                                    <Trans>
                                                        Manage custom reporting, utilization
                                                        <br /> insights, feedbacks & tags
                                                    </Trans>
                                                )}
                                                {!isSuperAdmin(userDetails?.userRoles) && <Trans>Manage custom reporting</Trans>}
                                            </Text>
                                        </div>
                                    </div>
                                </Link>
                                {(isContentCreator(userDetails?.userRoles) ||
                                    isContentManager(userDetails?.userRoles) ||
                                    isSuperAdmin(userDetails?.userRoles)) && (
                                    <Link role="link" to={`/manage-content/articles`}>
                                        <div className="home-card">
                                            <span className="home-card-icon manage-icon"></span>
                                            <div className="home-card-txt">
                                                <Text className="home-card-head">
                                                    <Trans>Manage Content</Trans>
                                                </Text>
                                                <Text className="home-card-desc">
                                                    <Trans>Publish spotlight, news and training</Trans>{' '}
                                                </Text>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                                <Link role="link" to={`/admin-training`}>
                                    <div className="home-card">
                                        <span className="home-card-icon admin-training-icon"></span>
                                        <div className="home-card-txt">
                                            <Text className="home-card-head">
                                                <Trans>Admin Training</Trans>
                                            </Text>
                                            <Text className="home-card-desc">
                                                <Trans>Watch Admin only training videos</Trans>{' '}
                                            </Text>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeCards;
