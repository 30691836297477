import { Client } from '@microsoft/microsoft-graph-client';
import { API } from './constants';

const error = 'error thrown by the authentication handler';

const client = Client.init({
    defaultVersion: 'v1.0',
    debugLogging: false,
    authProvider: (done) => {
        const graphAccessToken = localStorage.getItem('msGraphAccessToken');
        done(error, graphAccessToken);
    }
});

export const getUserPhoto = async (userId: string) => {
    try {
        if (userId === '0') {
            return null;
        } else {
            const endpoint = userId ? `${API.API_MS_GRAPH}users/${userId}/photo/$value` : `${API.API_MS_GRAPH}me/photo/$value`;
            const res = await client.api(endpoint).get();
            let arrayBuffer = await res.arrayBuffer();
            const dataURI = Buffer.from(arrayBuffer).toString('base64');
            return dataURI;
        }
    } catch (error) {
        return null;
    }
};

export const getUserProfile = async (userId: string) => {
    try {
        const endpoint = `${API.API_MS_GRAPH}users/${userId}`;
        const res = await client.api(endpoint).get();
        return res;
    } catch (error) {
        return null;
    }
};
