import React, { Dispatch, SetStateAction } from 'react';
import { Image, Link } from '@fluentui/react';

import './SelectTags.scss';
import { useEffect } from 'react';
import menuImage from 'app/static/icons/placeholder.svg';
import { useGetArticleCategories } from 'app/services/queries';
import { getValidTags } from 'app/components/helpers';
import { getArticleCategorySvg } from 'app/utils/SVGIcons';

const SelectTags: React.FC<{
    menuState: boolean;
    handleMenu: any;
    setSelectedTags: Dispatch<SetStateAction<any[]>>;
    selectedTags: Array<any>;
}> = ({ menuState, handleMenu, setSelectedTags, selectedTags }) => {
    const { data, isLoading } = useGetArticleCategories();
    const [tags, setTags] = React.useState([]);

    useEffect(() => {
        if (!isLoading) {
            setTags(getValidTags(data));
        }
    }, [isLoading, data]);

    const handleTag = (data: any) => {
        if (data.children?.length <= 0) {
            handleMenu(!menuState);
            const { id, displayName, categoryHierarchy: tagHierarchy } = data;
            if (selectedTags.find((t) => t.id === id) || selectedTags.length === 5) return;
            setSelectedTags([
                ...selectedTags,
                {
                    id,
                    displayName,
                    tagHierarchy
                }
            ]);
        }
    };
    return (
        <>
            <div className={`select-tags-component ${menuState ? 'activemenu' : ''}`}>
                {!isLoading &&
                    tags.length > 0 &&
                    tags.map((data: any) => {
                        const { displayName, children, id, icon = null, arrow = '>' } = data;
                        return (
                            <div
                                className="All-Categories"
                                onMouseDown={() => handleTag(data)}
                                key={id}
                                data-testid="handlediv-tag">
                                <div className="text-icon-arrow-container">
                                    <div className="text-icon-box">
                                        <Image
                                            src={getArticleCategorySvg(icon)}
                                            onMouseDown={() => handleTag(data)}
                                        />
                                        <span className="bld">
                                            <Link
                                                className="category-link"
                                                onMouseDown={() => handleTag(data)}
                                                data-testid="handle-tag">
                                                {displayName}
                                            </Link>
                                        </span>
                                    </div>
                                    {children.length > 0 && (
                                        <div className="menu-arrow">
                                            <div className="submenu">
                                                <div className="submenu-item-container">
                                                    {children.map((subData: any) => {
                                                        const { displayName, children, id, arrow = '>' } = subData;

                                                        return (
                                                            <div className="submenu-item" key={id}>
                                                                <div className="icon-text-container">
                                                                    <Image
                                                                        src={menuImage}
                                                                        onMouseDown={() => handleTag(subData)}
                                                                    />
                                                                    <div
                                                                        className="item-heading"
                                                                        onMouseDown={() => handleTag(subData)}
                                                                        data-testid="child-tag">
                                                                        {displayName}
                                                                    </div>
                                                                    {children.length > 0 && (
                                                                        <div className="menu-arrow">
                                                                            <div className="submenu">
                                                                                <div className="submenu-item-container">
                                                                                    {subData.children.map((innerMenu: any) => {
                                                                                        const { displayName, id } = innerMenu;
                                                                                        return (
                                                                                            <div
                                                                                                className="submenu-item"
                                                                                                key={id}>
                                                                                                <div className="icon-text-container">
                                                                                                    <Image
                                                                                                        src={menuImage}
                                                                                                        onMouseDown={() =>
                                                                                                            handleTag(innerMenu)
                                                                                                        }
                                                                                                    />
                                                                                                    <div
                                                                                                        className="item-heading"
                                                                                                        onMouseDown={() =>
                                                                                                            handleTag(innerMenu)
                                                                                                        }
                                                                                                        data-testid="subchild-tag">
                                                                                                        {displayName}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            {arrow}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            {arrow}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default SelectTags;
