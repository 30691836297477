import React, { useRef, useEffect } from 'react';
import { TextField, Text, ShimmerElementType, Separator, Shimmer, Link, TooltipHost, Image } from '@fluentui/react';
import './SearchCustomer.scss';
import { createTheme, ITheme } from '@fluentui/react/lib/Styling';
import { useId } from '@fluentui/react-hooks';
import InfoIcon from '../../../../../app/static/icons/tooltip-info-icon.svg';
import { useGetCustomersList } from 'app/services/mutations';
import { Trans } from 'react-i18next';
import { keyPress } from 'app/components/helpers';

interface IProps {
    status: string;
    setStatus: Function;
    toggleSearch: boolean;
    setToggleSearch: Function;
    setCustomerInputType?: Function;
    searchValue: string;
    setSearchValue: Function;
    setSuggestionSelected: Function;
    setFilteredData: Function;
    setRevenueRisk: Function;
    setImpDevices: Function;
    setRevenueHText: Function;
    setImpDevicesHText: Function;
    autoSuggestHText: boolean;
    setAutoSuggestHText: Function;
    origin: string;
    showModal?: Function;
    isFocus?: boolean;
    custName: string;
    setCustName: Function;
    customerSelected?: boolean;
    setCustomerSelected?: Function;
    lastSelectedCustomerName?: any;
    setLastSelectedCustomerName?: Function;
    setOriginalSelected?: Function;
    searchNotFound?: boolean;
    setSearchNotFound?: Function;
    disabled?: boolean;
    setDisabled?: Function;
    custNameHText?: boolean;
    setCustNameHText?: Function;
    setCountry?: Function;
}
export const SearchCustomer: React.FC<IProps> = ({
    toggleSearch,
    setToggleSearch,
    setStatus,
    status,
    setCustomerInputType,
    setCountry,
    setSearchNotFound,
    searchValue,
    setSearchValue,
    showModal,
    setSuggestionSelected,
    setFilteredData,
    setRevenueRisk,
    custName,
    setCustName,
    setImpDevices,
    setRevenueHText,
    setImpDevicesHText,
    autoSuggestHText,
    customerSelected,
    setAutoSuggestHText,
    custNameHText,
    setCustNameHText,
    origin,
    isFocus,
    setCustomerSelected,
    setLastSelectedCustomerName,
    setOriginalSelected
}) => {
    const [showSuggestion, setShowSuggestion] = React.useState(false);
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    const { data, mutate: getCustomersList, isLoading } = useGetCustomersList();

    const handleSearch = (e: any) => {
        if (origin === 'ADD_CUSTOMER') {
            setCustomerSelected(false);
            setOriginalSelected([]);
            setFilteredData([]);
        }
        setCustomerInputType('search');
        setSearchValue(e.target.value);
        setAutoSuggestHText(false);
        if (customerSelected) {
            setRevenueHText(false);
            setImpDevicesHText(false);
            setRevenueRisk('');
            setCustName(custName);
            setImpDevices('');
            setSuggestionSelected(false);
        }
        if (e.target.value.length > 2) {
            setShowSuggestion(true);
            setAutoSuggestHText(false);
            getCustomersList(e.target.value);
        }
        if (!customerSelected && !isFocus) {
            setCustName('');
        } else {
            setShowSuggestion(false);
        }
    };

    const handleSelectSuggestion = (selectedData: any) => {
        if (origin === 'ADD_CUSTOMER') {
            setLastSelectedCustomerName(selectedData.AccountName);
            setCustomerSelected(true);
            setOriginalSelected([{ ...selectedData }]);
        }
        setRevenueHText(false);
        setImpDevicesHText(false);
        setFilteredData([selectedData]);
        setSuggestionSelected(true);
        setShowSuggestion(false);
        setSearchValue(selectedData.AccountName.toString() + ' - ' + selectedData.AccountNumber.toString());
        setSearchNotFound(false);
        setAutoSuggestHText(false);
        setCustName(selectedData.AccountName);
        setCustNameHText(false);
        setCountry(selectedData.Country);
        setShowSuggestion(false);
    };

    const handleFocus = () => {
        if (searchValue?.length > 2) {
            getCustomersList(searchValue);
            setShowSuggestion(true);
        }
    };

    const suggestionRef = useRef(null);

    useSuggestionBoxDismiss(suggestionRef, setShowSuggestion);

    //loader shimmer element
    const shimmerElement = [
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line }
    ];

    const textInput = useRef(null);

    const filterHelper = (data: any) => {
        let uniqueData: any = [];
        let accountNumberSet: Set<string> = new Set<string>();
        for (let i = 0; i < data?.data?.length; i++) {
            if (!accountNumberSet.has(data.data[i].Document.AccountNumber)) {
                uniqueData.push(data.data[i].Document);
                accountNumberSet.add(data.data[i].Document.AccountNumber);
            }
        }

        return uniqueData;
    };

    const handleAddCustomerModal = () => {
        setCustomerInputType('textfield');
        showModal();
        setSearchValue(searchValue);
        setCustName(searchValue);
    };

    useEffect(() => {
        if (isFocus) {
            textInput.current.focus();
        }
    }, [isFocus, showSuggestion, toggleSearch]);

    useEffect(() => {
        if (data?.data?.length === 0) {
            setSearchNotFound(true);
        } else {
            setSearchNotFound(false);
        }
    }, [data]);

    return (
        <div>
            <div className={`${!toggleSearch ? 'show' : 'hide'} search-MSX-cont  `} ref={suggestionRef}>
                <TextField
                    className={`${autoSuggestHText ? 'error-feild' : ''}${showSuggestion ? 'active' : ''}`}
                    value={searchValue}
                    data-testid="search-box-test"
                    onFocus={handleFocus}
                    onChange={handleSearch}
                    placeholder="e.g: 3092603 - Contoso - 11+JID-7HH"
                    autoComplete={'off'}
                    componentRef={textInput}
                    ariaLabel="Search by MSX Opportunity ID / Account ID / Customer Name"
                />
                {showSuggestion && (
                    <div className={`suggest-cont-box`}>
                        {isLoading ? (
                            <div className="p-a-20">
                                <Shimmer className="p-b-5" shimmerElements={shimmerElement} />
                                <Shimmer className="p-b-5" shimmerElements={shimmerElement} />
                                <Shimmer shimmerElements={shimmerElement} />
                            </div>
                        ) : data?.data?.length === 0 ? (
                            <div className="p-a-20">
                                <Text>
                                    <span tabIndex={0} aria-label="No Customer Found">
                                        No customer found.
                                    </span>
                                    <Link onClick={handleAddCustomerModal}>
                                        <div className="add-cust-link">
                                            <Trans>Add Customer</Trans>
                                        </div>
                                    </Link>
                                </Text>
                            </div>
                        ) : (
                            filterHelper(data).map((item: any) => (
                                <div
                                    key={item?.id}
                                    tabIndex={0}
                                    onClick={() => handleSelectSuggestion(item)}
                                    className="suggestion-item  p-t-10 p-b-10"
                                    aria-label={`${item?.AccountName} - Account ID: ${item?.AccountNumber}`}
                                    onKeyPress={(e) => keyPress(e, () => handleSelectSuggestion(item))}
                                    data-testid="select-suggest">
                                    <Text>{`${item.AccountName} - Account ID: ${item.AccountNumber}`}</Text>
                                </div>
                            ))
                        )}
                        {data?.data?.length !== 0 ? (
                            <div className="suggestion-item p-t-10 p-b-10">
                                <div className="seperator-bar-margins">
                                    <Separator />
                                </div>
                                <Text aria-label="Try adding a customer manually" tabIndex={0}>
                                    Try adding a customer manually.
                                </Text>
                                <Link onClick={handleAddCustomerModal}>
                                    <div className="add-cust-link">
                                        <Trans>Add Customer</Trans>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                )}
            </div>
            <div>
                <div></div>
                <div className={`${toggleSearch ? 'show' : 'hide'} `}>
                    <TextField
                        value={custName}
                        onChange={(e: any) => {
                            setCustName(e.target.value);
                        }}
                        placeholder="e.g: Contoso Corporation"
                        className={` regular-text-field-search-customer ${custNameHText ? 'error-feild' : ''}`}
                        ariaLabel="Enter customer name"
                    />
                </div>
            </div>
            <div className={`${toggleSearch ? 'hide' : 'show'} info-msg`}>
                <Text>
                    Learn more about{' '}
                    <Text className="info-text" aria-label="Customer Information" tabIndex={0}>
                        Customer information
                    </Text>{' '}
                    <TooltipHost
                        id={tooltipId}
                        calloutProps={calloutProps}
                        tabIndex={0}
                        aria-label="Look up a customer by name of MSX Account ID"
                        content={'Look up a customer by name or MSX Account ID.'}>
                        <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                    </TooltipHost>
                </Text>
            </div>
            {custNameHText && (
                <Text className="helper-txt">
                    <Trans>Please enter a customer name.</Trans>
                </Text>
            )}
        </div>
    );
};
const useSuggestionBoxDismiss = (ref: any, setShowSuggestion: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowSuggestion(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keypress', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keypress', handleClickOutside);
        };
    }, [setShowSuggestion, ref]);
};
