import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { IUserDetails } from 'app/models/common/post';

import FeedbackResponseView from 'app/modules/admin/feedbackResponseView/FeedbackResponseView';
import Tags from 'app/modules/admin/tags/Tags';
import CustomReporting from 'app/modules/superAdmin/customReporting/CustomReporting';
import UtilizationInsights from 'app/modules/superAdmin/utilizationInsights/UtilizationInsights';

import useStore from 'app/store';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';

import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { capitalize, startCase } from 'lodash';

import './ManageReportsPage.scss';

type Selection = 'custom-reporting' | 'utilization-insights' | 'feedback' | 'tags';

const ManageReportsPage: React.FC = () => {
    const [currentSelection, setCurrentSelection] = useState<Selection>('custom-reporting');
    const history = useHistory();
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const itemsWithHref: IBreadcrumbItem[] = [
        { text: 'Home', key: 'home', onClick: () => history.push('/') },
        {
            text: capitalize(startCase(currentSelection)),
            key: `${currentSelection}`,
            isCurrentItem: true
        }
    ];
    return (
        <>
            <div className="container">
                <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} ariaLabel="bread crumb" overflowAriaLabel="More links" />
            </div>
            <div className="container panel-separator internal-nav m-t-10">
                <nav className="navigation_link" aria-label="header-nav-link">
                    <ul>
                        {(isSuperAdmin(userDetails?.userRoles) || isAdmin(userDetails?.userRoles)) && (
                            <li
                                className={currentSelection === 'custom-reporting' ? 'active' : ''}
                                onClick={() => setCurrentSelection('custom-reporting')}>
                                <Trans>Custom reporting</Trans>
                            </li>
                        )}
                        {isSuperAdmin(userDetails?.userRoles) && (
                            <li
                                className={currentSelection === 'utilization-insights' ? 'active' : ''}
                                onClick={() => setCurrentSelection('utilization-insights')}>
                                <Trans>Utilization insights</Trans>
                            </li>
                        )}
                        {isSuperAdmin(userDetails?.userRoles) && (
                            <li
                                className={currentSelection === 'feedback' ? 'active' : ''}
                                onClick={() => setCurrentSelection('feedback')}>
                                <Trans>Feedback</Trans>
                            </li>
                        )}
                        {isSuperAdmin(userDetails?.userRoles) && (
                            <li
                                className={currentSelection === 'tags' ? 'active' : ''}
                                onClick={() => setCurrentSelection('tags')}>
                                <Trans>Tags</Trans>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            <div className="container">
                {currentSelection === 'custom-reporting' && <CustomReporting />}
                {currentSelection === 'utilization-insights' && <UtilizationInsights />}
                {currentSelection === 'feedback' && <FeedbackResponseView />}
                {currentSelection === 'tags' && <Tags />}
            </div>
        </>
    );
};

export default ManageReportsPage;
