import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useStore from 'app/store';
import ListBuilder from '../../../modules/admin/listBuilder/ListBuilder';

const ListBuilderPage: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history?.location?.pathname);
    }, []);
    return (
        <>
            <ListBuilder 
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
            />
        </>
    );
};

export default ListBuilderPage;