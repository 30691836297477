import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './SearchBarWithSuggestions.scss';
import FormattedSuggestion from '../formattedSuggestion/FormattedSuggestion';
import { useSearchData } from 'app/services/mutations';
import useStore from 'app/store';
import voiceIcon from 'app/static/icons/microVoice.svg';
import { useSpeechToTextToken } from 'app/services/queries';

import { isUser } from 'app/utils/authUtilities';
import { keyPress } from 'app/components/helpers';

import * as speechSdk from 'microsoft-cognitiveservices-speech-sdk';
import { IUserDetails } from 'app/models/common/post';
export interface SearchResult {
    name: string;
    link: string;
}

const SearchBarWithSuggestions: React.FC = () => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const [isSearchResultShown, setIsSearchResultShown] = useState(false);

    //location
    let history = useHistory();

    const location = useLocation();
    const { pathname } = location;

    const [searchText, setSearchText] = useState('');
    const [searchResult, setSearchResult] = useState([]);

    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);

    const { mutate: searchData, data: searchResultData, isSuccess: isSuccessSearch } = useSearchData();

    const [isSearchFocused, setIsSearchFocused] = useState(false);

    const { data: generateToken, isLoading: isTokenLoading, refetch: getSpeechToTextToken } = useSpeechToTextToken();
    const [, setTokenObj] = useState({ Token: '', Region: '' });
    const [isRecording, setIsRecording] = useState(false);
    const [recognizer, setRecognizer] = useState(null);

    useEffect(() => {
        if (!isTokenLoading) {
            setTokenObj(generateToken);
        }
    }, [isTokenLoading]);

    //voice to text
    const sttFromMic = async (action: Boolean) => {
        if (action) {
            let token = JSON.parse(sessionStorage.getItem('speech-token'));
            if (!(token && token.Token)) {
                await getSpeechToTextToken();
                token = JSON.parse(sessionStorage.getItem('speech-token'));
            }
            const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(token?.Token ?? '', token?.Region ?? '');
            speechConfig.speechRecognitionLanguage = 'en-US';
            speechConfig.setServiceProperty('punctuation', 'explicit', speechSdk.ServicePropertyChannel.UriQueryParameter);
            const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();

            let newRecognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
            setRecognizer(newRecognizer);
            setIsRecording(true);

            newRecognizer.recognized = function (s: any, e: any) {
                let displayText;

                if (e.result.text) {
                    displayText = `${e.result.text}`;
                    displayText = displayText.replace('SKP ', 'SKP-');
                    //displayText = displayText.substring(0, displayText.length - 1);

                    setSearchText(displayText);
                    setUserSearchValue(displayText);
                    showResults();
                    setIsRecording(false);
                    searchFieldRef.current.focus();
                    searchData({
                        term: displayText,
                        searchOption: 'HelpRequest',
                        IsAdminSearch:
                            !isUser(userDetails?.userRoles) &&
                            (pathname.includes('manage-requests') ||
                                (!isUser(userDetails?.userRoles) && pathname === '/') ||
                                pathname.includes('report') ||
                                pathname.includes('request-detail') ||
                                pathname.includes('visual-flow')),
                        IsParentChildSearch: false
                    });

                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                }
            };

            newRecognizer.startContinuousRecognitionAsync(() => {
                setTimeout(() => {
                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                    setIsRecording(false);
                }, 20000);
            });
        }

        if (!action) {
            recognizer.stopContinuousRecognitionAsync(
                (result: string) => {},
                (e: string) => {}
            );
        }
    };

    const voiceStartClick = () => {
        sttFromMic(true);
    };

    useEffect(() => {
        if (isSuccessSearch) {
            setSearchResult(searchResultData?.data);
        }
    }, [isSuccessSearch]);

    const onFocus = () => {
        showResults();
    };

    const showResults = () => {
        setIsSearchFocused(true);
        if (searchText.length >= 3) setIsSearchResultShown(true);
        else setIsSearchResultShown(false);
    };

    const onChange = (event: any) => {
        setSearchText(event.target.value);

        setUserSearchValue(event.target.value);
        if (event.target.value.trim())
            searchData({
                term: event.target.value,
                searchOption: 'HelpRequest',
                IsAdminSearch:
                    !isUser(userDetails?.userRoles) &&
                    (pathname.includes('manage-requests') ||
                        (!isUser(userDetails?.userRoles) && pathname === '/') ||
                        pathname.includes('report') ||
                        pathname.includes('request-detail') ||
                        pathname.includes('visual-flow')),
                IsParentChildSearch: false
            });

        if (searchText.length >= 3 || event.target.value.length >= 3) setIsSearchResultShown(true);
        else setIsSearchResultShown(false);
    };

    const onKeyUp = (event: any) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            setSearchText('');
            history.push('/search-results/requests');
            setIsSearchResultShown(false);
            setSearchResult([]);
        }
    };
    const handleSuggestionClose = () => {
        if (searchText.length === 0) setIsSearchFocused(false);
        setIsSearchResultShown(false);

        setSearchText('');
    };

    const searchContainer = useRef(null);
    useSearchSuggestionClose(searchContainer, handleSuggestionClose);
    const searchFieldRef = useRef(null);
    return (
        <>
            <div className="search-box-top" role="searchbox" aria-label="search-requests-field" ref={searchContainer}>
                {!isRecording ? (
                    <img
                        tabIndex={0}
                        src={voiceIcon}
                        alt="Voice"
                        className="voice-icon"
                        onKeyPress={(e) => keyPress(e, voiceStartClick)}
                        onClick={() => {
                            voiceStartClick();
                        }}
                    />
                ) : (
                    ''
                )}
                {isRecording ? (
                    <div
                        className="recording-row recording-position"
                        onKeyPress={(e) =>
                            keyPress(e, () => {
                                setIsRecording(false);
                                sttFromMic(false);
                            })
                        }
                        tabIndex={0}
                        onClick={() => {
                            setIsRecording(false);
                            sttFromMic(false);
                        }}>
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="fourth-line"></div>
                        <div className="fifth-line"></div>
                    </div>
                ) : (
                    ''
                )}
                <input
                    tabIndex={0}
                    className={`search-input ${
                        isSearchResultShown ? 'search-input-focus' : searchResult?.length > 0 ? 'show-suggestions ' : ''
                    } ${searchText?.length > 3 ? 'search-input-grown' : ''}  ${
                        isSearchFocused ? 'search-animation' : 'search-shrink-animation'
                    }`}
                    onChange={(event) => onChange(event)}
                    onFocus={() => onFocus()}
                    type="text"
                    onKeyUp={(event) => onKeyUp(event)}
                    value={searchText}
                    data-testid="search-text"
                    role="searchbox"
                    ref={searchFieldRef}
                    placeholder={'Search'}
                />
                {isSearchResultShown ? (
                    <div className={searchResult?.length > 0 ? 'suggestions' : 'search-box-suggestions'}>
                        <ul>
                            {isSuccessSearch &&
                                searchResult?.length > 0 &&
                                searchResult.map((item: any, index) => {
                                    return (
                                        <div key={index}>
                                            <FormattedSuggestion
                                                name={item.Document.Title}
                                                UnifiedTrackingId={item.Document.UnifiedTrackingId}
                                                inputString={searchText}
                                                setSearchText={setSearchText}
                                                handleSuggestionClose={handleSuggestionClose}
                                            />
                                        </div>
                                    );
                                })}
                        </ul>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
};

const useSearchSuggestionClose = (ref: any, handleSuggestionClose: Function) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleSuggestionClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleSuggestionClose, ref]);
};
export default SearchBarWithSuggestions;
