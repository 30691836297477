import React, { useState, useEffect } from 'react';
import './index.scss';
import Button from '../../../../components/button/Button';
import draftIcon from '../../../../static/icons/draft.svg';
import {
    useCreateArticle,
    usePublishArticle,
    useScheduleToPublishArticle,
    useReDraftArticle,
    useUpdateArticle,
    useSubmitArticle,
    useCreateArticleTemplate,
    useRemoveArticle,
    useDeleteArticle
} from 'app/services/mutations';
import { IArticleData, IArticleTemplateData, IMenuOption, IUserDetails } from 'app/models/common/post';
import { ArticleStatus } from 'app/enums/Status.enum';
import MoreOptions from 'app/components/moreOptions/MoreOptions';
import { useHistory } from 'react-router-dom';
import { Checkbox, Modal, TextField, Text, Breadcrumb, IBreadcrumbItem } from '@fluentui/react';

import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { STATUS_OPTIONS } from 'app/utils/statusOptions';
import { BlobStorageHelper, RouteConstants, StringConstants } from 'app/utils/constants';
import { getRoles, isContentManager, isSuperAdmin } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { Trans, useTranslation } from 'react-i18next';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { keyPress, separateVideosFromHTMLString } from 'app/components/helpers';
import { SVGIcons } from 'app/utils/SVGIcons';
import { useGetArticleTemplates } from 'app/services/queries';
import { Loader } from 'app/components/loader/Loader';
import { removeBlobFileSasToken } from 'app/utils/blobStorageHelpers';

const { DUPLICATE, EDIT } = StringConstants;

interface IProps {
    editArticleId?: string;
    title?: string;
    status?: number;
    content?: string;
    isPreview: boolean;
    setIsPreview: (preview: boolean) => void;
    hideInfo: boolean;
    tags: any;
    thumbnail: string;
    coverPhoto: string;
    reviewNotes: string;
    type: number;
    helpfulResources: Array<any>;
    scheduledPublishOn?: Date;
    showAsBannerArticle: boolean;
    showOnHomePage: boolean;
}

const SubHeader: React.FC<IProps> = (props: IProps) => {
    const { t: translate } = useTranslation();
    const {
        editArticleId,
        title = 'Title',
        content = '',
        isPreview,
        setIsPreview,
        hideInfo,
        tags,
        thumbnail,
        coverPhoto,
        reviewNotes,
        type,
        helpfulResources,
        status,
        scheduledPublishOn,
        showAsBannerArticle: bannerArticle,
        showOnHomePage: homepageArticle
    } = props;
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const history = useHistory();
    const timeOffset = 30 - (moment().minute() % 30);
    type Action = 'Delete' | 'Remove' | '' | 'Save as template' | 'Move to draft' | 'Publish' | 'Submit for Review';

    const [articleId, setArticleId] = useState(editArticleId || undefined);
    const [primaryOption, setPrimaryOption] = useState(null);
    const [secondaryOptions, setSecondaryOptions] = useState([]);
    const [moreOptions2, setMoreOptions2] = useState(false);
    const [showDateField, setShowDateField] = useState(false);
    const [showTimeField, setShowTimeField] = useState(false);
    const [showModal, setModalStatus] = useState(false);
    const [backWithNoteModal, setBackWithNoteModal] = useState(false);
    const [publishArticleModal, setPublishArticleModal] = useState(false);
    const [viewNoteModal, setViewNoteModal] = useState(false);
    const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [action, setAction] = useState<Action>('');
    const [scheduledDateTime, setScheduledDateTime] = useState<Date>(
        scheduledPublishOn
            ? moment(scheduledPublishOn).toDate()
            : moment().add(timeOffset, 'minutes').set({ second: 0, millisecond: 0 }).toDate()
    );

    const isValidArticle =
        title.trim().length > 0 &&
        (((type === ArticleType.Training || type === ArticleType.Admin) &&
            separateVideosFromHTMLString(content).videos.length === 1) ||
            (separateVideosFromHTMLString(content).content.trim().replace('<p><br></p>', '').length > 7 &&
                (type === ArticleType.KnowledgeArticle || type === ArticleType.News)));

    const isValidContent =
        title.trim().length > 0 &&
        (separateVideosFromHTMLString(content).content.trim().replace('<p><br></p>', '').length > 7 ||
            type === ArticleType.Training ||
            type === ArticleType.Admin);

    const { mutateAsync: createArticle, isLoading: isCreating, isError: isCreatingFailed } = useCreateArticle();
    const { mutateAsync: updateArticle, isLoading: isUpdating, isError: isUpdatingFailed } = useUpdateArticle();
    const { mutateAsync: createArticleTemplate } = useCreateArticleTemplate();
    const { refetch: getArticleTemplates, data: articleTemplates, isLoading: isGettingTemplates } = useGetArticleTemplates();

    const { mutateAsync: submitArticle } = useSubmitArticle();
    const { mutateAsync: removeArticleAPI } = useRemoveArticle();
    const { mutateAsync: deleteArticleAPI } = useDeleteArticle();
    const { mutateAsync: publishArt } = usePublishArticle();
    const { mutateAsync: scheduleToPublishArt } = useScheduleToPublishArticle();
    const { mutateAsync: reDraftArticle } = useReDraftArticle();
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const article: IArticleData = {
        thumbnail: thumbnail,
        coverPhoto: coverPhoto,
        title: title ? title : '',
        content: content ? content : '',
        shouldHideAuthorInfo: hideInfo,
        tags: tags,
        helpfulResources: helpfulResources,
        type: type
    };

    const removeSasTokens = (article: IArticleData) => {
        if (article?.content?.includes(BlobStorageHelper.SAS_TOKEN_IDENTIFIER)) {
            article.content = removeBlobFileSasToken(content);
        };
        if (article?.thumbnail?.includes(BlobStorageHelper.SAS_TOKEN_IDENTIFIER)) {
            article.thumbnail = removeBlobFileSasToken(thumbnail);
        };
        if (article?.coverPhoto?.includes(BlobStorageHelper.SAS_TOKEN_IDENTIFIER)) {
            article.coverPhoto = removeBlobFileSasToken(coverPhoto);
        }
    }
    removeSasTokens(article);

    useEffect(() => {
        const autoSave = setInterval(() => {
            if (isValidArticle && !(isCreating || isUpdating)) saveArticle(article, true);
        }, 10000);
        return () => clearInterval(autoSave);
    });
    useEffect(() => {
        const statusOptionsList = STATUS_OPTIONS.find(({ ROLE }) => getRoles(userDetails?.userRoles).includes(ROLE))?.OPTIONS;
        const primaryOption = statusOptionsList?.find(({ STATUS }) => STATUS === ArticleStatus[status || 1])?.PRIMARY_OPTION;
        const secondaryOptions = statusOptionsList?.find(({ STATUS }) => STATUS === ArticleStatus[status || 1])?.MORE_OPTION;
        setPrimaryOption(primaryOption);
        const filteredOptions = secondaryOptions?.filter((option) => option !== EDIT && option !== DUPLICATE) || [];
        setSecondaryOptions(filteredOptions);
    }, [status]);

    const saveArticle = (article: IArticleData, autoSave: boolean = false) => {
        if (isValidArticle) {
            if (!articleId) {
                createArticle(article).then((res: any) => setArticleId(res.data.id));
            } else {
                updateArticle({ ...article, id: articleId }).then((res: any) => setArticleId(res.data.id));
            }
        }
        if (!autoSave) {
            if (
                (type === ArticleType.Training || type === ArticleType.Admin) &&
                separateVideosFromHTMLString(content).videos.length < 1
            )
                setShowNotificationModal(true);
        }
    };

    const submitForReview = async () => {
        if (!articleId) await saveArticle(article);
        submitArticle(articleId).then(() => {
            history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
        });
    };

    const editArticle = () => {
        setIsPreview(false);
    };

    const preview = () => {
        setIsPreview(true);
    };
    const openPublishModal = () => {
        setDateTimeField(scheduledPublishOn && true);
        setModalStatus(true);
        setPublishArticleModal(true);
        setBackWithNoteModal(false);
        setViewNoteModal(false);
    };

    const publishArticle = () => {
        publishArt({ articleId: articleId, showOnHomePage: showOnHomePage, showAsBannerArticle: showAsBannerArticle }).then(
            () => {
                history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
            }
        );
    };

    const scheduleToPublishArticle = () => {
        scheduleToPublishArt({
            articleId: articleId,
            showOnHomePage: showOnHomePage,
            showAsBannerArticle: showAsBannerArticle,
            scheduledPublishOn: scheduledDateTime.toISOString()
        }).then(() => {
            history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
        });
    };

    const openSubmitBackWithNoteModal = () => {
        setModalStatus(true);
        setBackWithNoteModal(true);
        setViewNoteModal(false);
        setPublishArticleModal(false);
        toggleMoreOptions2();
    };

    const hideModal = () => {
        cancelScheduling();
        setShowOnHomePage(false);
        setModalStatus(false);
        setBackWithNoteModal(false);
        setViewNoteModal(false);
        setPublishArticleModal(false);
    };

    const [note, setNote] = useState('');
    const noteValue = (value: string) => {
        setNote(value);
    };
    const sendArticleBackToDraftWithNote = () => {
        if (note && note.trim() !== '')
            reDraftArticle({ articleId: editArticleId, reviewNotes: note }).then((res) => {
                setModalStatus(false);
                history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
            });
    };
    const viewNote = () => {
        setModalStatus(true);
        setViewNoteModal(true);
        setPublishArticleModal(false);
        setBackWithNoteModal(false);
    };
    const [dateTimeField, setDateTimeField] = React.useState(false);

    const [showOnHomePage, setShowOnHomePage] = useState(homepageArticle);
    const [showAsBannerArticle, setShowAsBannerArticle] = useState(bannerArticle);
    const showDateTimeField = () => {
        setDateTimeField(true);
    };
    const cancelScheduling = () => {
        const timeOffset = 30 - (moment().minute() % 30);
        setDateTimeField(false);
        setScheduledDateTime(
            scheduledPublishOn
                ? moment(scheduledPublishOn).toDate()
                : moment().add(timeOffset, 'minutes').set({ second: 0, millisecond: 0 }).toDate()
        );
        setShowTimeField(false);
        setShowDateField(false);
    };

    const onDateSelection = (e: any) => {
        const publishDate = scheduledDateTime;
        publishDate.setFullYear(e.value.getFullYear());
        publishDate.setMonth(e.value.getMonth());
        publishDate.setDate(e.value.getDate());
        setScheduledDateTime(publishDate);
        setShowDateField(false);
    };
    const onTimeSelection = (e: any) => {
        const publishDate = scheduledDateTime;
        publishDate.setHours(e.value.getHours());
        publishDate.setMinutes(e.value.getMinutes());
        publishDate.setSeconds(0);
        setScheduledDateTime(publishDate);
        setShowTimeField(false);
    };
    const showHideOnHomepage = () => {
        setShowOnHomePage(!showOnHomePage);
    };
    const showHideAsBannerArticle = () => {
        setShowAsBannerArticle(!showAsBannerArticle);
    };
    const steps = {
        hour: 1,
        minute: 30
    };
    const hideSaveTemplateModal = () => {
        setShowSaveTemplateModal(false);
        setTemplateName('');
    };
    const hideConfirmationModal = () => {
        setShowConfirmationModal(false);
    };
    const showSaveAsTemplateModal = () => {
        setShowSaveTemplateModal(true);
        toggleMoreOptions2();
    };
    const showConformationModal = () => {
        setShowConfirmationModal(true);
    };

    const toggleMoreOptions2 = () => {
        setMoreOptions2(!moreOptions2);
    };

    const saveAsTemplate = () => {
        const data: IArticleTemplateData = {
            displayName: templateName,
            content: content,
            coverPhoto: coverPhoto
        };
        createArticleTemplate(data).then(() => hideSaveTemplateModal());
    };
    const setTemplateNameFun = (e: any) => {
        setTemplateName(e.target.value);
    };

    const deleteArticle = () => {
        deleteArticleAPI(articleId).then((res) => {
            history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
        });
        toggleMoreOptions2();
    };

    const removeArticle = () => {
        removeArticleAPI(articleId).then((res) => {
            history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
        });
        toggleMoreOptions2();
    };

    const actionMapper = {
        Delete: {
            action: () => {
                setAction('Delete');
                showConformationModal();
            },
            icon: 'ms-Icon ms-Icon--Delete'
        },
        Remove: {
            action: () => {
                setAction('Remove');
                showConformationModal();
            },
            icon: 'ms-Icon ms-Icon--PageRemove'
        },
        'Save as template': {
            action: () => {
                getArticleTemplates();
                showSaveAsTemplateModal();
            },
            icon: 'ms-Icon ms-Icon--SaveTemplate'
        },
        'Move to draft': {
            action: openSubmitBackWithNoteModal,
            icon: 'ms-Icon ms-Icon--Undo'
        },
        Publish: {
            action:
                type === ArticleType.Training ||
                type === ArticleType.Admin ||
                type === ArticleType.News ||
                type === ArticleType.KnowledgeArticle
                    ? openPublishModal
                    : () => setShowNotificationModal(true),

            icon: 'ms-Icon ms-Icon--PublishContent'
        },
        'Submit for Review': {
            action:
                type === ArticleType.Training ||
                type === ArticleType.Admin ||
                type === ArticleType.News ||
                type === ArticleType.KnowledgeArticle
                    ? () => {
                          setAction('Submit for Review');
                          showConformationModal();
                      }
                    : () => setShowNotificationModal(true),
            icon: 'ms-Icon ms-Icon--CheckMark'
        }
    } as const;

    const primaryOptionList: IMenuOption = {
        title: primaryOption,
        handleAction: actionMapper[primaryOption as keyof typeof actionMapper]?.action,
        id: articleId,
        icon: '',
        iconClass: actionMapper[primaryOption as keyof typeof actionMapper]?.icon
    };

    const menuOptionsList: Array<IMenuOption> = secondaryOptions?.map((option: keyof typeof actionMapper) => {
        return {
            title: option,
            handleAction: actionMapper[option]?.action,
            id: articleId,
            icon: '',
            iconClass: actionMapper[option]?.icon
        };
    });

    const handleDateClick = () => {
        setShowTimeField(false);
        setShowDateField(!showDateField);
    };
    const handleTimeClick = () => {
        setShowDateField(false);
        setShowTimeField(!showTimeField);
    };

    const formatMinTime = () => {
        if (moment(moment(scheduledDateTime).format('YYYY-MM-DD')).isSame(moment(moment().format('YYYY-MM-DD'))))
            return moment().add(timeOffset, 'minutes').set({ second: 0, millisecond: 0 }).toDate();
        return moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
    };
    const hideNotificationModal = () => {
        setShowNotificationModal(false);
    };
    const renderConfirmation = () => {
        return (
            <Modal isOpen={showConfirmationModal} onDismiss={hideConfirmationModal} isBlocking={false} className="surface-modal">
                <div className="modal-header">
                    <span>
                        <Trans>Alert</Trans>
                    </span>
                    <span
                        className="close"
                        tabIndex={0}
                        onKeyPress={(e: any) => keyPress(e, hideConfirmationModal)}
                        onClick={hideConfirmationModal}>
                        X
                    </span>
                </div>
                <div className="modal-body">
                    <Text>Are you sure you want to {action.toLowerCase()}?</Text>
                </div>
                <div className="modal-footer">
                    <Button
                        buttonClass="btn"
                        buttonText={translate('Cancel')}
                        onClick={() => {
                            hideConfirmationModal();
                            setAction('');
                        }}
                    />
                    <Button
                        buttonClass="btn btn--green"
                        buttonText="Confirm"
                        onClick={
                            action === 'Delete'
                                ? deleteArticle
                                : action === 'Submit for Review'
                                ? submitForReview
                                : action === 'Remove'
                                ? removeArticle
                                : null
                        }
                    />
                </div>
            </Modal>
        );
    };

    const itemsWithHref: IBreadcrumbItem[] = [
        {
            text: 'Articles',
            key: 'Articles',
            onClick: () => history.push(RouteConstants.MANAGE_CONTENT_ARTICLES)
        },
        {
            text: title ? title : StringConstants.CREATE_ARTICLE,
            key: title ? title : StringConstants.CREATE_ARTICLE,
            isCurrentItem: true
        }
    ];

    return (
        <>
            <div className="container">
                <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} overflowAriaLabel="More links" />
            </div>

            <div className="sub-header">
                <div className="container  ">
                    <div className="d-flex--between panel-separator">
                        <div className="d-flex">
                            <h3 className="m-a-0">{StringConstants.CREATE_ARTICLE}</h3>
                        </div>
                        <div className="d-flex--justEnd ai-center">
                            <div className="tags">
                                <div className={`tag  tag--${reviewNotes ? 'Redrafted' : 'orange'}`}>
                                    <img src={draftIcon} className="icon" alt="" />{' '}
                                    {reviewNotes ? translate('Redrafted') : translate('Draft')}
                                </div>
                                {reviewNotes && (
                                    <div
                                        className="tag c-pointer"
                                        tabIndex={0}
                                        onKeyPress={(e: any) => keyPress(e, () => viewNote())}
                                        onClick={() => viewNote()}
                                        data-testid="viewnote-click">
                                        <img src={draftIcon} className="icon" alt="" /> View Note
                                    </div>
                                )}
                            </div>
                            <div className="btn-group btn-group--icons m-r-20 m-l-20 h-max-content">
                                <Button
                                    buttonText=""
                                    buttonClass={`btn d-flex justify-content-center vertical-align editButton ${
                                        isPreview ? '' : 'btn--primary'
                                    }`}
                                    onClick={editArticle}
                                    data-testid="edit-button"
                                    aria-label="edit button"
                                    title="edit button"
                                    iconSVG={SVGIcons.ICON_EDIT}
                                />
                                <Button
                                    buttonText=""
                                    buttonClass={`btn d-flex justify-content-center vertical-align previewButton ${
                                        !isPreview ? '' : 'btn--primary'
                                    }`}
                                    onClick={preview}
                                    data-testid="preview-button"
                                    aria-label="preview button"
                                    title="preview button"
                                    iconSVG={SVGIcons.ICON_EYE}
                                />
                            </div>
                            <div>
                                <Button
                                    buttonText={isCreating || isUpdating ? translate('Saving...') : translate('Save')}
                                    buttonClass="btn d-flex justify-content-center vertical-align width-5 btn--secondary m-r-10 savingArticle"
                                    onClick={() => saveArticle(article)}
                                    disabled={!isValidContent}
                                />
                            </div>
                            <div className="d-flex">
                                {primaryOptionList.title && (
                                    <Button
                                        buttonText={primaryOptionList.title}
                                        buttonClass="btn btn--cyan"
                                        iconSVG={SVGIcons.ICON_UPLOAD}
                                        iconSrc={primaryOptionList.icon}
                                        onClick={() => primaryOptionList.handleAction(articleId)}
                                        disabled={
                                            !isValidArticle ||
                                            (type >= ArticleType.Training && separateVideosFromHTMLString(content).videos.length !== 1) ||
                                            (type >= ArticleType.Admin && separateVideosFromHTMLString(content).videos.length !== 1) ||
                                            !articleId ||
                                            isUpdatingFailed ||
                                            isCreatingFailed ||
                                            isCreatingFailed ||
                                            isUpdatingFailed
                                        }
                                    />
                                )}
                                <div className="more-links" tabIndex={0} onBlur={() => setMoreOptions2(false)}>
                                    {menuOptionsList.length > 0 && articleId && !(isCreatingFailed || isUpdatingFailed) && (
                                        <MoreOptions pos="left top" menuArray={menuOptionsList} fuIconName="MoreVertical" />
                                    )}

                                    {renderConfirmation()}
                                    <Modal
                                        isOpen={showSaveTemplateModal}
                                        onDismiss={hideSaveTemplateModal}
                                        isBlocking={false}
                                        className="surface-modal">
                                        <div className="modal-header">
                                            <span>
                                                <Trans>Save Template</Trans>
                                            </span>
                                            <span
                                                className="close"
                                                tabIndex={0}
                                                onKeyPress={(e: any) => keyPress(e, hideSaveTemplateModal)}
                                                onClick={hideSaveTemplateModal}>
                                                X
                                            </span>
                                        </div>
                                        {!isGettingTemplates ? (
                                            <>
                                                <div className="modal-body">
                                                    <TextField
                                                        label={translate('Enter Template Name')}
                                                        name="templateName"
                                                        onChange={(e) => setTemplateNameFun(e)}
                                                        errorMessage={
                                                            articleTemplates?.find(
                                                                (item: IArticleTemplateData) => item.displayName === templateName
                                                            )
                                                                ? 'Template name already exists'
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div className="modal-footer">
                                                    <Button
                                                        buttonClass="btn"
                                                        buttonText={translate('Cancel')}
                                                        onClick={() => {
                                                            hideSaveTemplateModal();
                                                        }}
                                                    />
                                                    <Button
                                                        buttonClass="btn btn--green"
                                                        buttonText={translate('Save')}
                                                        onClick={() => {
                                                            saveAsTemplate();
                                                        }}
                                                        disabled={
                                                            templateName.trim().length === 0 ||
                                                            articleTemplates?.find(
                                                                (item: any) => item.displayName === templateName
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <Loader />
                                        )}
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showNotificationModal}
                onDismiss={hideNotificationModal}
                isBlocking={false}
                className="surface-modal notification-modal">
                <div className="modal-header">
                    <span></span>
                    <span
                        className="close"
                        tabIndex={0}
                        onKeyPress={(e: any) => keyPress(e, hideNotificationModal)}
                        onClick={hideNotificationModal}>
                        X
                    </span>
                </div>
                <div className="modal-body text-center">
                    <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                    <h4 className="m-t-5 m-b-5">
                        {separateVideosFromHTMLString(content).videos.length < 1 && type >= 3
                            ? 'Please upload a training video.'
                            : 'Only 1 training video can be uploaded.'}
                    </h4>
                </div>
                <div className="modal-footer">
                    <Button buttonClass="btn btn--primary" buttonText="OK" onClick={hideNotificationModal} />
                </div>
            </Modal>
            <Modal isOpen={showModal} onDismiss={hideModal} isBlocking={false} className="surface-modal">
                <div className="modal-header">
                    <span>
                        {publishArticleModal && 'Publish the article.'}
                        {backWithNoteModal && 'Send back to draft with Note.'}
                        {viewNoteModal && 'Redrafted Note'}
                    </span>
                    <span
                        className="close"
                        data-testid="hideModal"
                        tabIndex={0}
                        onClick={hideModal}
                        onKeyPress={(e: any) => keyPress(e, hideModal)}>
                        X
                    </span>
                </div>
                <div className="modal-body">
                    {publishArticleModal && (
                        <>
                            <h2 className="m-a-0 text-overflow--100">{title}</h2>
                            {(type === ArticleType.News || type === ArticleType.Training || type === ArticleType.Admin) && (
                                <Checkbox
                                    defaultChecked={showOnHomePage}
                                    label={
                                        type === ArticleType.News
                                            ? 'Show Post on Home page (In the News)'
                                            : 'Show Post on Portal training (Must see)'
                                    }
                                    className="m-t-20"
                                    onChange={() => showHideOnHomepage()}
                                />
                            )}
                            {(type !== ArticleType.Admin) && (
                                <Checkbox
                                    defaultChecked={showAsBannerArticle}
                                    label="Show as banner article (Knowledge Portal)"
                                    className="m-t-20"
                                    onChange={() => showHideAsBannerArticle()}
                                />
                            )}
                        </>
                    )}
                    {backWithNoteModal && (
                        <>
                            <TextField
                                multiline
                                label="Enter Note"
                                onChange={(e: any) => {
                                    noteValue(e.target.value);
                                }}
                                rows={6}
                                autoFocus
                            />
                        </>
                    )}
                    {viewNoteModal && reviewNotes}
                    {publishArticleModal && dateTimeField && (
                        <div className="schedule-to-publish">
                            <div className="date-time-picker">
                                <DatePicker
                                    label={translate('Select Date')}
                                    format={'MMMM dd yyyy'}
                                    value={scheduledDateTime}
                                    onChange={(e) => onDateSelection(e)}
                                    show={showDateField}
                                    min={moment().toDate()}
                                />
                                <i
                                    className="k-icon k-i-calendar"
                                    aria-hidden="true"
                                    tabIndex={0}
                                    onKeyPress={(e: any) => keyPress(e, handleDateClick)}
                                    onClick={handleDateClick}></i>
                            </div>
                            <div className="date-time-picker">
                                <TimePicker
                                    label={translate('Select Time')}
                                    format="hh:mm a"
                                    steps={steps}
                                    value={scheduledDateTime}
                                    onChange={(e) => onTimeSelection(e)}
                                    show={showTimeField}
                                    min={formatMinTime()}
                                />
                                <i
                                    className="k-icon k-i-clock"
                                    aria-hidden="true"
                                    tabIndex={0}
                                    onKeyPress={(e: any) => keyPress(e, handleTimeClick)}
                                    onClick={handleTimeClick}></i>
                            </div>
                        </div>
                    )}
                </div>
                <div className="modal-footer d-flex ai-center">
                    {(isContentManager(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles)) && !viewNoteModal && (
                        <>
                            {(isContentManager(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles)) &&
                            backWithNoteModal ? (
                                <Button
                                    buttonClass="btn btn--green"
                                    buttonText={translate('Redraft')}
                                    onClick={() => {
                                        sendArticleBackToDraftWithNote();
                                    }}
                                    disabled={note.trim().length <= 0}
                                />
                            ) : (
                                <>
                                    {dateTimeField ? (
                                        <>
                                            <Button
                                                buttonClass="btn btn--secondary"
                                                buttonText={translate('Cancel Scheduling')}
                                                onClick={() => cancelScheduling()}
                                            />
                                            <Button
                                                buttonClass="btn btn--cyan"
                                                buttonText={translate('Scheduled to Publish')}
                                                onClick={() => {
                                                    scheduleToPublishArticle();
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                buttonClass="btn btn--secondary"
                                                buttonText={translate('Publish Now')}
                                                onClick={() => {
                                                    publishArticle();
                                                }}
                                            />
                                            <Button
                                                buttonClass="btn btn--green"
                                                buttonText={translate('Schedule for Later')}
                                                iconClass="ms-Icon ms-Icon--PrimaryCalendar"
                                                onClick={() => {
                                                    showDateTimeField();
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default SubHeader;
