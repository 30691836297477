import {
    ActionButton,
    IconButton,
    Image,
    PersonaInitialsColor,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text,
    TextField
} from '@fluentui/react';
import { useConfirmationModal } from '../../../../components/confirmationModal/ConfirmationModal';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Avatar from 'app/components/avatar/Avatar';
import { formatIntoRequestData } from 'app/components/helpers';
import { unifiedDashboardCardsList } from 'app/models/common/enumerators';
import { IAssociationData, IRequestParams } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import { useRequestList } from 'app/services/queries';
import associationImage from 'app/static/icons/association-icon.svg';
import unlinkIcon from 'app/static/icons/link-dismiss.svg';
import useStore from 'app/store';
import { getPortalValue } from 'app/utils/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './AssociationTab.scss';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    setIsLinkRequestModalOpen: Function;
    selectedRequest: IRequest;
    setSelectedRequest: Function;
    setIsAssociatingParent: Function;
    setIsEditMode: Function;
    parent: any;
}

const AssociationTab: React.FC<IProps> = (props) => {
    const pageSize = 1000;

    const history = useHistory();
    const portal = getPortalValue(history?.location?.pathname);

    const { setIsLinkRequestModalOpen, selectedRequest, setIsAssociatingParent, setSelectedRequest, setIsEditMode, parent } =
        props;

    const { t: translate } = useTranslation();
    const requestType = useStore((state: any) => state.requestType);

    const [containerWidth, setContainerWidth] = useState(1367);

    const AssignedToCell = (props: any) => {
        return (
            <td>
                {props.dataItem?.assignee ? (
                    <div className="assigned-to-cell">
                        <Avatar
                            userId={props.dataItem?.assignee?.id}
                            userName={props.dataItem?.assignee?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">{props.dataItem?.assignee?.displayName}</span>
                    </div>
                ) : (
                    <div className="assigned-to-cell">
                        {' '}
                        <Avatar
                            userId={'0'}
                            userName={'Unassigned'}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">Unassigned</span>
                    </div>
                )}
            </td>
        );
    };

    const customerNameHelper = (dataItem: any) => {
        if (dataItem !== undefined && dataItem.customerImpacts !== undefined && dataItem?.customerImpacts[0]?.customerName) {
            return dataItem?.customerImpacts[0]?.customerName;
        } else {
            return ' -';
        }
    };

    const TitleCell = (props: any) => {
        if (props.dataItem.isParent) {
            return <td title={props?.dataItem?.title}>{props?.dataItem?.title}</td>;
        } else {
            return <td title={customerNameHelper(props.dataItem)}>{customerNameHelper(props.dataItem)}</td>;
        }
    };

    const UnlinkCell = (props: any) => {
        const { ConfirmationModal, showModal } = useConfirmationModal();
        if (selectedRequest.isParent && selectedRequest.id === props.dataItem.id) {
            return <></>;
        }

        const requestData = props.dataItem;
        const child = requestData.isParent ? selectedRequest : requestData;

        const childUnassociationItem: IAssociationData = {
            requestId: child.unifiedTrackingId,
            associatedRequestId: child.parentRequestId
        };

        return (
            <td>
                <ConfirmationModal
                    childUnassociationItem={childUnassociationItem}
                    message={'Do you want to unlink this child ticket?'}
                />
                <IconButton role="button" aria-label="unlink request" onClick={showModal}>
                    <Image src={unlinkIcon} alt="unlink request" />
                </IconButton>
            </td>
        );
    };

    const IdCell = (props: GridCellProps) => {
        return (
            <td>
                <ActionButton
                    styles={{ label: { fontWeight: 600, margin: 0 } }}
                    aria-label={`navigate to request ${props.dataItem[props.field]}`}
                    text={props.dataItem[props.field]}
                    onClick={onIdClick(props.dataItem)}
                />
            </td>
        );
    };

    const setWidth = () => {
        let columnCount = columnsLookUpData.filter((item) => item.isChecked).length;
        if (
            requestType === StringConstants.MY_REQUEST &&
            columnsLookUpData.filter((item) => item.key === 0 && item.isChecked).length > 0
        ) {
            columnCount = columnCount - 1;
        }
        if (columnCount > 1) {
            let padding = 50 + 47 + 30;
            const availWidth = containerWidth - padding - 90;

            return parseInt((availWidth / columnCount).toString());
        } else {
            return containerWidth;
        }
    };

    const [columnsLookUpData, setColumnsLookUp] = useState([
        {
            key: 0,
            column: translate('ID'),
            isChecked: true
        },
        {
            key: 1,
            column: translate('Title'),
            isChecked: true
        },
        {
            key: 2,
            column: translate('Request Type'),
            isChecked: true
        },
        {
            key: 3,
            column: translate('Status'),
            isChecked: true
        },
        {
            key: 4,
            column: translate('Assigned To'),
            isChecked: true
        },
        {
            key: 5,
            column: translate('Total Revenue Risk'),
            isChecked: true
        },
        {
            key: 6,
            column: translate('Unlink'),
            isChecked: true
        }
    ]);

    const columnElements = [
        {
            key: 0,
            column: (
                <Column cell={IdCell} width={setWidth() - 50} className="cell-in-bold" field="id" title="ID" resizable={false} />
            )
        },
        {
            key: 1,
            column: <Column width={setWidth() - 30} field="Title" title="TITLE" cell={TitleCell} resizable={false} />
        },
        {
            key: 2,
            column: <Column width={setWidth()} title="REQUEST TYPE" field="requestType" resizable={false} />
        },
        {
            key: 3,
            column: <Column width={setWidth() - 50} field="status" title="STATUS" resizable={false} />
        },
        {
            key: 4,
            column: (
                <Column width={setWidth() + 30} field="assignedTo" title="ASSIGNED TO" cell={AssignedToCell} resizable={false} />
            )
        },
        {
            key: 5,
            column: <Column width={setWidth() + 50} field="revenue" title="TOTAL REVENUE RISK" resizable={false} />
        },
        {
            key: 6,
            column: (
                <Column
                    width={setWidth() - 30}
                    field="unlink"
                    title="UNLINK"
                    resizable={false}
                    className="right-aligned-column"
                    cell={UnlinkCell}
                />
            )
        }
    ];

    const childColumnElements = [
        {
            key: 0,
            column: (
                <Column cell={IdCell} width={setWidth() - 50} className="cell-in-bold" field="id" title="ID" resizable={false} />
            )
        },
        {
            key: 1,
            column: (
                <Column width={setWidth() + 30} field="Customer Name" title="CUSTOMER NAME" cell={TitleCell} resizable={false} />
            )
        },
        {
            key: 2,
            column: <Column width={setWidth()} title="REQUEST TYPE" field="requestType" resizable={false} />
        },
        {
            key: 3,
            column: <Column width={setWidth() - 50} field="status" title="STATUS" resizable={false} />
        },
        {
            key: 4,
            column: (
                <Column width={setWidth() + 30} field="assignedTo" title="ASSIGNED TO" cell={AssignedToCell} resizable={false} />
            )
        },
        {
            key: 5,
            column: <Column width={setWidth() + 50} field="revenue" title="TOTAL REVENUE RISK" resizable={false} />
        },
        {
            key: 6,
            column: (
                <Column
                    width={setWidth() - 30}
                    field="unlink"
                    title="UNLINK"
                    resizable={false}
                    className="right-aligned-column"
                    cell={UnlinkCell}
                />
            )
        }
    ];

    const [childColumnsLookUpData, setChildColumnsLookUp] = useState([
        {
            key: 0,
            column: translate('ID'),
            isChecked: true
        },
        {
            key: 1,
            column: translate('Customer Name'),
            isChecked: true
        },
        {
            key: 2,
            column: translate('Request Type'),
            isChecked: true
        },
        {
            key: 3,
            column: translate('Status'),
            isChecked: true
        },
        {
            key: 4,
            column: translate('Assigned To'),
            isChecked: true
        },
        {
            key: 5,
            column: translate('Total Revenue Risk'),
            isChecked: true
        },
        {
            key: 6,
            column: translate('Unlink'),
            isChecked: true
        }
    ]);

    const getColumns = (isParent: Boolean) => {
        let list: any = [];
        if (isParent) {
            columnsLookUpData.forEach((item) => {
                columnElements.every((ele) => {
                    if (ele.key === item.key && item.isChecked) {
                        list.push(ele);
                        return false;
                    } else {
                        return true;
                    }
                });
            });
        } else {
            childColumnsLookUpData.forEach((item) => {
                childColumnElements.every((ele) => {
                    if (ele.key === item.key && item.isChecked) {
                        list.push(ele);
                        return false;
                    } else {
                        return true;
                    }
                });
            });
        }

        if (requestType === StringConstants.MY_REQUEST) {
            return list.filter((item: any) => item.key !== 0);
        } else {
            return list;
        }
    };

    const [columnList, setColumnList] = useState(getColumns(false));
    const [childColumnList, setChildColumnList] = useState(getColumns(true));
    const [childRequests, setChildRequests] = useState<any[]>([]);
    const [criteria, setCriteria] = useState<IRequestParams>({
        from: '2001-01-01T00:00:00Z',
        to: '9999-12-31T23:59:59Z',
        pageNumber: 1,
        pageSize: pageSize,
        type: unifiedDashboardCardsList.ALL_REQUEST,
        requestType: [],
        parentRequestId: selectedRequest.parentRequestId,
        portal: portal
    });

    const [searchValue, setSearchValue] = useState('');
    const [filteredChildren, setFilteredChildren] = useState<any[]>();

    const { isLoading: isLoadingRequests } = useRequestList(criteria, {
        enabled: criteria !== undefined && criteria.parentRequestId !== undefined,
        onSuccess: (response) => {
            setChildRequests(formatIntoRequestData(response ? response.items : [], [], []));
            setFilteredChildren(formatIntoRequestData(response ? response.items : [], [], []));
        }
    });

    useEffect(() => {
        setSearchValue('');
    }, []);

    useEffect(() => {
        setColumnList(getColumns(true));
        setChildColumnList(getColumns(false));
    }, [selectedRequest]);

    useEffect(() => {
        setCriteria({ ...criteria, parentRequestId: parent?.id });
    }, [parent]);

    const handleOpenAssociateRequestModal = (associatingParent: boolean) => {
        setIsAssociatingParent(associatingParent);
        setIsLinkRequestModalOpen(true);
    };

    const textInput = useRef(null);

    const handleSearch = (e: any) => {
        setSearchValue(e.target.value);
        setFilteredChildren(
            childRequests.filter(
                (child) =>
                    child.id.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    child.title.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    const onIdClick = (item: any) => () => {
        setIsEditMode(false);
        setSelectedRequest(item);
    };

    return (
        <>
            {(selectedRequest.parentRequestId !== '' && selectedRequest.parentRequestId !== null) ||
            selectedRequest.isParent === true ? (
                <div className={'association-table-cont table-cont '}>
                    <div className="pr-table-name">
                        <Text>Associated parent:</Text>
                    </div>
                    <div className={'grid-cont'}>
                        <Tooltip openDelay={100} position="right" anchorElement="element">
                            <Grid data={[parent]} dataItemKey={'id'} sortable={true} resizable={false}>
                                {columnList?.map((item: any) => item.column)}
                            </Grid>
                        </Tooltip>
                    </div>
                    <div className="cr-panel-wrapper">
                        <div className={''}>
                            <div className="dp-header">
                                <div className="head-col-left">
                                    <Text className="dp-head-title">{`${childRequests.length} child requests`}</Text>
                                    <span
                                        className="associate-more-container"
                                        onClick={() => handleOpenAssociateRequestModal(false)}>
                                        <Text className="txt-link">Associate More</Text>
                                    </span>
                                </div>
                                <div className="head-col-right">
                                    <TextField
                                        value={searchValue}
                                        onChange={handleSearch}
                                        placeholder="Enter Unified Tracking ID or Title"
                                        autoComplete={'off'}
                                        componentRef={textInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid-cont'}>
                        <Tooltip openDelay={100} position="right" anchorElement="element">
                            {isLoadingRequests ? (
                                <div>
                                    <div className="m-t-50">
                                        <Spinner label="Loading requests..." size={SpinnerSize.large} />
                                    </div>
                                </div>
                            ) : (
                                <Grid data={filteredChildren} dataItemKey={'id'} sortable={true} resizable={false}>
                                    {childColumnList?.map((item: any) => item.column)}
                                </Grid>
                            )}
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <div className="no-association-content">
                    <div>
                        <Image src={associationImage} alt="associateRequest" />
                    </div>
                    <div className="no-association-text">
                        <Text> You haven't established any association yet for this request</Text>
                    </div>
                    <div className="primary-associate-button">
                        <PrimaryButton className="primary add-cust-btn" onClick={() => handleOpenAssociateRequestModal(true)}>
                            Associate Now
                        </PrimaryButton>
                    </div>
                </div>
            )}
        </>
    );
};

export default AssociationTab;
