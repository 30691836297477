import { DefaultButton, IconButton, IIconProps, Modal, Text } from '@fluentui/react';
import { keyPress } from 'app/components/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

const titleId = 'request-help-navigation-modal';
const cancelIcon: IIconProps = { iconName: 'Cancel' };

const PromptModal = (props: any) => {
    const { when, onOK, onCancel } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath] = useState('');

    useEffect(() => {
        setShowPrompt(true);
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <Modal
            titleAriaId={titleId}
            isOpen={showPrompt}
            onDismiss={handleCancel}
            isBlocking={false}
            containerClassName="request-help-navigation-modal">
            <>
                <div className="modal-head">
                    <Text>Confirm navigation</Text>
                    <IconButton
                        role="button"
                        className="modal-close"
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={handleCancel}
                        onKeyPress={(e) => keyPress(e, handleCancel)}
                        tabIndex={0}
                        data-testid="modal-close"
                    />
                </div>
                <div className="bug-info-modal-body">
                    <>
                        <div className="scroll-panel">
                            <div className="dp-content">
                                <div>
                                    <Text>
                                        Clicking here will navigate you away from this screen, however the changes you have made
                                        until this point will be saved as a draft.
                                    </Text>
                                </div>
                                <div className="m-t-15">
                                    <Text>Do you still want to leave this page?</Text>
                                </div>
                                <div className="form-btn-wrap">
                                    <DefaultButton className="default" onClick={handleCancel} data-testid="cancel-button">
                                        Cancel
                                    </DefaultButton>
                                    <DefaultButton type="submit" className="primary m-l-20" onClick={handleOK}>
                                        Confirm
                                    </DefaultButton>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </>
        </Modal>
    ) : null;
};

export default PromptModal;
