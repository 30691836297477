import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useGetArticle, useGenerateFileSasToken, useGetRelatedArticles } from 'app/services/queries';
import { usePostArticleViewed } from 'app/services/mutations';
import ArticleBanner from 'app/modules/articles/viewArticle/articleDetail/articleBanner/ArticleBanner';
import ArticleContent from 'app/modules/articles/viewArticle/articleDetail/articleContent/ArticleContent';

import HelpfulResourcesList from '../helpfulResourcesList/HelpfulResourcesList';
import CategoryWiseList from '../categoryWiseList/CategoryWiseList';
import { Trans } from 'react-i18next';
import { Loader } from 'app/components/loader/Loader';
import { IArticleData, IUserDetails } from 'app/models/common/post';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { separateVideosFromHTMLString } from 'app/components/helpers';
import { ArticleStatus } from 'app/enums/Status.enum';
import { BlobStorageContainer, BlobStorageHelper, Entity, StringConstants } from 'app/utils/constants';
import { isUser } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { addBlobFileSasToken, getBlobFileNameFromString } from 'app/utils/blobStorageHelpers';

interface IMatchParams {
    id: string;
}

const ArticleDetail: React.FC<{}> = () => {
    const params = useParams<IMatchParams>();
    let articleId = params.id;
    const userDetails: IUserDetails = useStore((state) => state.userDetails as IUserDetails);
    const { isLoading, data: articleInfo, isError, isFetched, refetch: getArticleAPI } = useGetArticle(articleId);
    const { data: relatedArticles } = useGetRelatedArticles(articleId);
    const { mutateAsync: postArticleViewedAPI } = usePostArticleViewed();
    const { data: tokenizedCoverPhoto } = useGenerateFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, getBlobFileNameFromString(articleInfo?.coverPhoto));
    const [ tokenizedContent, setTokenizedContent ] = useState('');

    useEffect(() => {
        if (articleInfo?.content && articleInfo?.content !== '') {
            const replaceStorageUrls = async () => {
                if(articleInfo?.content.match(BlobStorageHelper.STORAGE_URL_REGEX)) {
                    let contentTokenizedStr = await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, articleInfo?.content);
                    setTokenizedContent(contentTokenizedStr);
                }
                else {
                    setTokenizedContent(articleInfo?.content);
                }
            }

            replaceStorageUrls();
        }
    }, [articleInfo?.content]);

    useEffect(() => {
        getArticleAPI();
        postArticleViewedAPI(articleId);
    }, [articleId, getArticleAPI, postArticleViewedAPI]);

    useEffect(() => {
        if (isFetched && (articleInfo as IArticleData)?.type === ArticleType.Training) {
            separateVideosFromHTMLString((articleInfo as IArticleData).content);
        }
    }, [isFetched, articleInfo]);

    if (!isLoading && isFetched) {
        if (
            !articleInfo?.isActive ||
            ((articleInfo as IArticleData).status !== ArticleStatus.Published && isUser(userDetails?.userRoles))
        ) {
            return (
                <div className="d-flex jc-center ai-center message height-60 ">
                    <Trans>{StringConstants.ARTICLE_DETAIL_NOT_FOUND_MESSAGE}</Trans>
                </div>
            );
        } else {
            if (!isError && isFetched && !isLoading) {
                return (
                    <>
                        <ArticleBanner
                            title={articleInfo?.title}
                            articleDate={articleInfo?.modifiedOn ? articleInfo?.modifiedOn : articleInfo?.createdOn}
                            coverPhoto={tokenizedCoverPhoto}
                            tags={articleInfo?.tags}
                            createdBy={articleInfo?.createdBy}
                            articleId={articleInfo?.articleId}
                            articleType={articleInfo?.type}
                        />
                        <ArticleContent
                            content={tokenizedContent}
                            author={articleInfo?.createdBy}
                            publishedDate={articleInfo?.modifiedOn ? articleInfo?.modifiedOn : articleInfo?.createdOn}
                            views={articleInfo?.numberOfViews}
                            shouldHideAuthorInfo={articleInfo?.shouldHideAuthorInfo}
                            status={articleInfo?.status}
                        />
                        {articleInfo?.helpfulResources.length > 0 && (
                            <div className="helpful-resources">
                                <div className="container">
                                    <h4 className="m-t-0 fw-600">
                                        <Trans>Helpful Resources</Trans>
                                    </h4>
                                    <HelpfulResourcesList
                                        showDeleteIcon={false}
                                        helpfulResources={articleInfo?.helpfulResources}
                                    />
                                </div>
                            </div>
                        )}
                        {relatedArticles?.items?.length > 0 && (
                            <CategoryWiseList
                                background="gradient--purple m-b-4"
                                title="Related Articles"
                                icon={false}
                                articles={relatedArticles?.items.slice(0, 3)}
                                showCardBorder={false}
                                showViewAll={false}
                            />
                        )}
                    </>
                );
            }
        }
    }
    if (isLoading) {
        return <Loader classNames="m-t-20 m-b-50 height-70" />;
    }
};

export default ArticleDetail;
