import React, { useEffect, useRef, useState } from 'react';
import useStore from 'app/store';
import { Dropdown, Modal, Text, TextField, Link, IDropdownOption, IDropdownStyles, Image } from '@fluentui/react';
import { Trans } from 'react-i18next';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import addFolderButton from '../../../static/icons/addFolderButton.svg';
import NoLists from '../../../static/icons/NoLists.svg';
import { DragAndDrop } from '@progress/kendo-react-common';
import folderIcon from '../../../static/icons/folderIcon.svg';
import addListButton from '../../../static/icons/addListButton.svg';
import blackArrow from '../../../static/icons/blackArrow.svg';
import threeDots from '../../../static/icons/threeDots.svg';
import disabledEllipse from '../../../static/icons/ellipse-disabled.svg';
import starFav from '../../../static/icons/starFav.svg';
import starFav2 from '../../../static/icons/starFav2.svg';
import disabledStar from '../../../static/icons/star-disabled.svg';
import pencil from '../../../static/icons/pencil.svg';
import disabledPencil from '../../../static/icons/pencil-disabled.svg';
import trashCan from '../../../static/icons/trashCan.svg';
import disabledTrashCan from '../../../static/icons/trashcan-disabled.svg';
import chevronDown from '../../../static/icons/chevronDown.svg';
import save from '../../../static/icons/list-builder-save-icon.svg';
import cancel from '../../../static/icons/list-builder-cancel-icon.svg';
import DraggableIcon from 'app/static/icons/equal-to-icon.svg';

import blueCancel from '../../../static/icons/blueCancel.svg';
import bluePencil from '../../../static/icons/bluePencil.svg';
import blueTrashCan from '../../../static/icons/blueTrashCan.svg';
import blueSaveIcon from '../../../static/icons/blueSaveIcon.svg';
import blueStarFav from '../../../static/icons/blueStarFav.svg';
import blueThreeDots from '../../../static/icons/blueThreeDots.svg';
import './ManageLists.scss';
import { handleGrabWhiteIcon, iconOptions } from '../listBuilder/options/Options';
import {
    useDeleteFolder,
    useDeleteList,
    useDownloadListBuilderItems,
    useFavoriteList,
    useReorderFolders,
    useReorderLists,
    useUpdateFolder,
    useUpdateList
} from 'app/services/mutations';
import { useGetUserSavedLists } from 'app/services/queries';
import { IListFolder, IListQuery, IUserList, IUpdateFolder } from 'app/models/common/post';
import DraggableRow from './DraggableRow';
import { DragHandleCell } from './DragHandleCell';
import { changeDateFormat } from 'app/components/helpers';
import { DateFormats, RouteConstants } from 'app/utils/constants';
import { AddFolderManageListsModal } from './AddFolderModal';
import { useBoolean } from '@fluentui/react-hooks';
import DeleteModal from './DeleteModal';
import { useHistory } from 'react-router-dom';
import { GridItem } from '../listBuilder/models/models';
import SaveListModal from '../listBuilder/saveList/SaveList';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface IProps {}

type ContextProps = {
    reorder: (dataItem: any, direction: 'before' | 'after' | null) => void;
    dragStart: (dataItem: any) => void;
};
export const ReorderContext = React.createContext<ContextProps>({ reorder: () => {}, dragStart: () => {} });

const ManageLists: React.FC<IProps> = () => {
    const history: any = useHistory();

    const gridRef = useRef(null);

    const setListBuilderFilters = useStore((state: any) => state.setListBuilderFilters);
    const setPreviousLocation = useStore((state: any) => state.setPreviousLocation);
    const setRequestType = useStore((state: any) => state.setRequestType);
    const setListId = useStore((state: any) => state.setListId);
    const setFilterPanelDisabled = useStore((state: any) => state.setFilterPanelDisabled);
    const setListIcon = useStore((state: any) => state.setListIcon);

    // mutations and queries
    const { data: savedLists } = useGetUserSavedLists();
    const { mutate: updateFolder } = useUpdateFolder();
    const { mutate: favoriteList } = useFavoriteList();
    const { mutate: updateList, isLoading: isUpdatingList } = useUpdateList();
    const { mutate: deleteFolder } = useDeleteFolder();
    const { mutate: deleteList } = useDeleteList();
    const { mutate: reorderLists } = useReorderLists();
    const { mutate: reorderFolders } = useReorderFolders();
    const {
        mutate: downloadList,
        data: downloadListData,
        isLoading: isLoadingDownload,
        isSuccess: isSuccessDownload
    } = useDownloadListBuilderItems();

    // State
    const [toggleListHeader, setToggleListHeader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isListFavorite, setIsListFavorite] = useState(false);
    const [gridData, setGridData] = React.useState<GridItem[]>([]);
    const [activeItem, setActiveItem] = React.useState<any | null>(null);
    const [toggleFavorite, setToggleFavorite] = useState<boolean>(
        history?.location?.state?.toggleFavorite ? history?.location?.state?.toggleFavorite : false
    );
    const [updateListsObject, setUpdateListsObject] = useState<IUpdateFolder>({
        name: '',
        folderId: null
    });

    const [isFolderDeleting, setIsFolderDeleting] = useState<boolean>(false);
    const [deletingItemId, setDeletingItemId] = useState<string>('');

    // Save list states
    const [name, setName] = useState('');
    const [downloadedListName, setDownloadedListName] = useState('');
    const [icon, setIcon] = useState('');
    const [folders, setFolders] = useState<IListFolder[]>([]);
    const [editingProps, setEditingProps] = useState<any>({});

    const [selectedFolder, setSelectedFolder] = useState<IListFolder>({
        id: '',
        name: '',
        savedListQueries: []
    });
    const [queryToSave, setQueryToSave] = useState<IListQuery>({
        id: '',
        queryName: '',
        icon: 0,
        starred: false,
        queryFilters: null
    });
    const [folderData, setFolderData] = useState({
        queryName: '',
        icon: icon,
        name: name,
        selectedFolder: selectedFolder
    });

    const { AddFolderModal, showFolderModal } = AddFolderManageListsModal();

    const dropdownStyles: Partial<IDropdownStyles> = {
        callout: {
            minWidth: '200px !important'
        }
    };

    const getFolderDroppableStyles: (drag: boolean, props: any) => React.CSSProperties = (
        isDraggingOver: boolean,
        props: any
    ) => {
        const expandedItems = gridData.filter((item) => item.expanded);
        const cellIndex = expandedItems.findIndex((item) => item.Id === props.dataItem.Id);
        const cell = document.getElementsByClassName('k-hierarchy-cell')[cellIndex];

        if (cell) {
            isDraggingOver
                ? cell.setAttribute('class', 'k-hierarchy-cell hierarchy-cell-dragging')
                : cell.setAttribute('class', 'k-hierarchy-cell');
        }

        return {
            background: isDraggingOver ? '#F2FFD9' : '',
            padding: '10px 24px'
        };
    };

    const mapToTableList = () => {
        const tableList = [];
        for (let i = 0; i < savedLists?.listFolders?.length; i++) {
            const record = {
                Id: savedLists?.listFolders[i].id,
                FolderName: savedLists?.listFolders[i].name,
                expanded: true,
                CreatedBy: savedLists.createdBy.displayName,
                ModifiedOn: changeDateFormat(savedLists?.modifiedOn, DateFormats.MONTH_DAY_YEAR),
                CreatedOn: changeDateFormat(savedLists.listFolders[i].createdOn, DateFormats.MONTH_DAY_YEAR),
                InEdit: false,
                IsFavorite: false,
                SavedListQueries: savedLists?.listFolders[i].savedListQueries.map((r: any, indx: number) => {
                    return {
                        QueryId: r.id,
                        QueryName: r.queryName,
                        QueryIcon: r.icon,
                        InEdit: false,
                        Starred: r.starred,
                        FolderId: savedLists?.listFolders[i].id,
                        Filters: r.queryFilters,
                        ModifiedOn: r.modifiedOn ? changeDateFormat(r?.modifiedOn, DateFormats.MONTH_DAY_YEAR) : '',
                        CreatedOn: changeDateFormat(r.createdOn, DateFormats.MONTH_DAY_YEAR)
                    };
                })
            };
            tableList.push(record);
        }
        return tableList;
    };

    const mapGridDataToFavoritesList = () => {
        const favoritesList: any = [];

        for (let i = 0; i < gridData?.length; i++) {
            for (let j = 0; j < gridData[i].SavedListQueries.length; j++) {
                if (gridData[i].SavedListQueries[j].Starred) {
                    const favorite = {
                        Id: gridData[i].Id,
                        CreatedBy: gridData[i].CreatedBy,
                        CreatedOn: gridData[i].SavedListQueries[j].CreatedOn,
                        ModifiedOn: gridData[i].SavedListQueries[j].ModifiedOn,
                        QueryName: gridData[i].SavedListQueries[j].QueryName,
                        QueryIcon: gridData[i].SavedListQueries[j].QueryIcon,
                        Filters: gridData[i].SavedListQueries[j].Filters
                    };
                    favoritesList.push(favorite);
                }
            }
        }
        if (history?.location?.state?.favoritesData) {
            favoritesList.push(history.location.state.favoritesData);
        }
        return favoritesList;
    };

    const IconQueryCell = (props: any) => {
        return (
            <td className="favoritesIconList">
                <div className="list-icon-ml">
                    <Image src={handleGrabWhiteIcon(props?.dataItem?.QueryIcon)} alt="" />
                </div>
                <Link onClick={() => handleQueryNameClick(props.dataItem)}>{props?.dataItem?.QueryName}</Link>
            </td>
        );
    };

    useEffect(() => {
        if (savedLists) {
            setFolders([...savedLists.listFolders]);
        }

        setGridData(mapToTableList());
    }, [savedLists]);

    useEffect(() => {
        if (!isLoadingDownload && isSuccessDownload) {
            let blobData = new Blob([downloadListData.data], { type: 'text/csv;charset=utf-8' });
            saveAs(blobData, `${downloadedListName}_${new Date().getTime()}.csv`);
        }
    }, [isLoadingDownload, isSuccessDownload]);

    const renderImage = (props: any) => {
        return (
            <td>
                {isEdit && props.dataItem.InEdit ? (
                    <div className="folder-icon-animations">
                        <div
                            className="action-icons-lb device-animate-lb icon-spacing effect"
                            onClick={() => handleEditCancel(props.dataItem)}>
                            <img src={cancel} className="image" alt="cancel icon" />
                            <img alt="folder on hover icon" className="image hover" src={blueCancel} />
                        </div>
                        <div
                            className="action-icons-lb device-animate-lb icon-spacing effect"
                            onClick={() => handleFolderEditSave(props.dataItem)}>
                            <img src={save} className="image" alt="save icon" />
                            <img src={blueSaveIcon} className="image hover" alt="on hover icon" />
                        </div>
                    </div>
                ) : (
                    <div className="folder-icon-animations">
                        {!isEdit && !props.dataItem.InEdit ? (
                            <div
                                onClick={!isEdit && !props.dataItem.InEdit ? () => handleEdit(props.dataItem) : () => {}}
                                className="action-icons-lb device-animate-lb icon-spacing effect">
                                <img src={pencil} className="image" alt="edit folder icon" />
                                <img className="image hover" src={bluePencil} alt="pencil icon" />
                            </div>
                        ) : (
                            <div className="pencil-margins disabled-icon-spacing">
                                <img src={disabledPencil} alt="edit folder icon" />
                            </div>
                        )}

                        {!isEdit && !props.dataItem.InEdit ? (
                            <div
                                className="action-icons-lb device-animate-lb icon-spacing effect"
                                onClick={!isEdit && !props.dataItem.InEdit ? () => handleFolderDelete(props.dataItem) : () => {}}>
                                <img
                                    src={!isEdit && !props.dataItem.InEdit ? trashCan : disabledTrashCan}
                                    className="image"
                                    alt="delete folder icon"
                                />
                                <img
                                    className="image hover"
                                    src={!isEdit && !props.dataItem.InEdit ? blueTrashCan : disabledTrashCan}
                                    alt="delete folder icon"
                                />
                            </div>
                        ) : (
                            <div className="disabled-icon-spacing">
                                <img src={disabledTrashCan} alt="delete folder icon" />
                            </div>
                        )}
                    </div>
                )}
            </td>
        );
    };

    const handleFolderDelete = (props: any) => {
        setIsFolderDeleting(true);
        setDeletingItemId(props.Id);
        showModal();
    };

    const handleFolderEditSave = (props: any) => {
        if (updateListsObject.folderId !== null && updateListsObject.name.length > 0) {
            updateFolder(updateListsObject);
            props.FolderName = updateListsObject.name;
            props.InEdit = false;
            setIsEdit(false);
            setUpdateListsObject({
                name: '',
                folderId: null
            });
        }
    };

    const handleEdit = (props: any) => {
        setIsEdit(true);
        props.InEdit = true;
    };

    const handleFolderNameChange = (event: any, folder: any) => {
        updateListsObject.name = event.target.value;
        updateListsObject.folderId = folder.Id;

        setUpdateListsObject(updateListsObject);
    };

    const handleListEdit = (props: any, folder: any) => {
        setIsEdit(true);
        setName(props.QueryName);
        setIcon(iconOptions.find((icon: any) => icon.enumValue === props.QueryIcon));

        setSelectedFolder({
            id: folder.Id,
            name: folder.FolderName,
            savedListQueries: [
                ...folder.SavedListQueries.map((query: any) => {
                    return {
                        id: query.QueryId,
                        queryName: query.QueryName,
                        icon: query.QueryIcon,
                        starred: query.Starred,
                        queryFilters: query.Filters,
                        folderId: query.FolderId
                    };
                })
            ]
        });

        setQueryToSave({
            id: props.QueryId,
            queryName: props.QueryName,
            icon: props.QueryIcon,
            queryFilters: props.Filters,
            starred: props.Starred
        });

        setEditingProps(props);

        showEditModal();
    };

    const handleEditCancel = (props: any) => {
        setIsEdit(false);
        props.InEdit = false;
    };

    const handleListFavorite = (props: any, folderId: string) => {
        // Mutation for favoriting a list
        favoriteList(props.QueryId);

        setIsListFavorite(!isListFavorite);
        props.Starred = !props.Starred;
    };

    const handleQueryNameClick = (query: any) => {
        setListBuilderFilters(query.Filters);
        setPreviousLocation(window.location.pathname);
        setRequestType(query.QueryName);
        setListId(query.QueryId);
        setFilterPanelDisabled(true);
        setListIcon(iconOptions[query.QueryIcon].imageSrc);

        history.push(RouteConstants.MANAGE_REQUESTS);
    };

    const renderQueryName = (queryName: any, queryIcon: any, query: any) => {
        return (
            <div className="iconQueryContainer">
                <div className="iconStyles">
                    <img className="icon" src={iconOptions[queryIcon].imageSrc} alt="" />
                </div>
                <div>
                    <Link className="queryNameStyles" onClick={() => handleQueryNameClick(query)} disabled={isEdit && !query.InEdit}>
                        {queryName}
                    </Link>
                </div>
            </div>
        );
    };

    const onListMenuChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption, query: any, folder: any): void => {
        if (item.text === 'Modify List') {
            history.push({
                pathname: '/list-builder',
                state: {
                    query: query,
                    queryFilters: query.Filters,
                    inEditMode: true,
                    folderName: folder.FolderName
                }
            });
        } else {
            var listQuery: IListQuery = {
                id: query.QueryId,
                folderId: query.FolderId,
                starred: query.Starred,
                queryName: query.QueryName,
                icon: query.QueryIcon,
                createdOn: new Date(query.CreatedOn),
                queryFilters: query.Filters
            };

            setDownloadedListName(query.QueryName);
            downloadList(listQuery);
        }
    };

    const handleListDelete = (event: any, queryId: string) => {
        showModal();
        setDeletingItemId(queryId);
    };

    const handleDelete = () => {
        if (isFolderDeleting) {
            deleteFolder(deletingItemId, {
                onSuccess: () => {
                    setIsFolderDeleting(false);
                    setDeletingItemId('');
                    hideModal();
                }
            });
        } else {
            deleteList(deletingItemId, {
                onSuccess: () => {
                    setIsFolderDeleting(false);
                    setDeletingItemId('');
                    hideModal();
                }
            });
        }
    };

    const cancelDelete = () => {
        setIsFolderDeleting(false);
        setDeletingItemId('');
        hideModal();
    };

    const renderListImage = (SavedListQueries: any, folder: any) => {
        return (
            <div className="list-icon-animations">
                <>
                    <div
                        onClick={
                            !isEdit && !SavedListQueries.InEdit
                                ? () => handleListFavorite(SavedListQueries, SavedListQueries.FolderId)
                                : () => {}
                        }>
                        {!isEdit && !SavedListQueries.InEdit ? (
                            SavedListQueries.Starred ? (
                                <div className="action-icons-lb icon-spacing">
                                    <img src={starFav2} alt="favorite list star icon" />
                                </div>
                            ) : (
                                <div className="action-icons-lb device-animate-lb icon-spacing effect">
                                    <img className="image" src={starFav} alt="default star icon" />
                                    <img className="image hover" src={blueStarFav} alt="default hover star icon" />
                                </div>
                            )
                        ) : (
                            <div className="disabled-icon-spacing">
                                <img src={disabledStar} alt="trash can disabled" />
                            </div>
                        )}
                    </div>
                    {!isEdit && !SavedListQueries.InEdit ? (
                        <div
                            onClick={
                                !isEdit && !SavedListQueries.InEdit ? () => handleListEdit(SavedListQueries, folder) : () => {}
                            }
                            className="action-icons-lb device-animate-lb icon-spacing edit-icon effect">
                            <img className="image" src={pencil} alt="edit icon" />
                            <img className="image hover" src={bluePencil} alt="edit icon" />
                        </div>
                    ) : (
                        <div className="disabled-icon-spacing">
                            <img src={disabledPencil} alt="edit icon" />
                        </div>
                    )}
                    {!isEdit && !SavedListQueries.InEdit ? (
                        <div
                            className="action-icons-lb device-animate-lb icon-spacing effect"
                            onClick={(event) => {
                                handleListDelete(event, SavedListQueries.QueryId);
                            }}>
                            <img className="image" src={trashCan} alt="delete list icon" />
                            <img className={`image hover`} src={blueTrashCan} alt="delete list icon" />
                        </div>
                    ) : (
                        <div className="disabled-icon-spacing">
                            <img src={disabledTrashCan} alt="delete list icon" />
                        </div>
                    )}
                    {!isEdit && !SavedListQueries.InEdit ? (
                        <div className="action-icons-lb icon-spacing device-animate-lb drop-down-styles effect">
                            <img className="image" src={threeDots} alt="menu icon" />
                            <img className="image hover" src={blueThreeDots} alt="menu icon" />
                            <Dropdown
                                onChange={(event, options) => onListMenuChange(event, options, SavedListQueries, folder)}
                                placeholder="Select"
                                styles={dropdownStyles}
                                options={[
                                    { key: 0, text: 'Download list' },
                                    { key: 1, text: 'Modify List' }
                                ]}
                            />
                        </div>
                    ) : (
                        <div className="disabled-icon-spacing">
                            <img src={disabledEllipse} alt="disabled menu icon" />
                        </div>
                    )}
                </>
            </div>
        );
    };

    const DetailComponent = (props: any) => {
        return props.dataItem.SavedListQueries.length === 0 ? (
            <section>
                <div className="no-lists-available">
                    <img className="no-lists-img-styles" src={NoLists} alt="No Lists in this folder" />
                    <div className="no-lists-text">No lists in this folder</div>
                </div>
            </section>
        ) : (
            <Droppable droppableId={props.dataItem.Id} type={props.dataItem.FolderName} key={props.dataItem.Id}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        className="folder-droppable"
                        style={getFolderDroppableStyles(snapshot.isDraggingOver, props)}
                        {...provided.droppableProps}>
                        {props.dataItem.SavedListQueries.map((i: any, index: number) => (
                            <Draggable key={i.QueryId} draggableId={`draggable-${i.QueryId}`} index={index}>
                                {(provided) => {
                                    return (
                                        <div
                                            className="detailComponent"
                                            key={i.QueryId}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}>
                                            <div className="list-drag-cell" {...provided.dragHandleProps}>
                                                <Image className="drag-icon" src={DraggableIcon} alt="Drag" />
                                            </div>
                                            <div className="query-name-container">{renderQueryName(i.QueryName, i.QueryIcon, i)}</div>
                                            <div
                                                className={
                                                    !isEdit && !i.InEdit ? 'createdByListStyles' : 'createdByListStyles text-disabled'
                                                }>
                                                {props.dataItem.CreatedBy}
                                            </div>
                                            <div
                                                className={
                                                    !isEdit && !i.InEdit ? 'createdOnListStyles' : 'createdOnListStyles text-disabled'
                                                }>
                                                {i.CreatedOn}
                                            </div>
                                            <div
                                                className={
                                                    !isEdit && !i.InEdit ? 'modifiedOnListStyles' : 'modifiedOnListStyles text-disabled'
                                                }>
                                                {i.ModifiedOn}
                                            </div>
                                            <div>{renderListImage(i, props.dataItem)}</div>
                                        </div>
                                    );
                                }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    const expandChange = (event: any) => {
        let newData = gridData?.map((item) => {
            if (item.Id === event.dataItem.Id) {
                item.expanded = !event.dataItem.expanded;
            }

            return item;
        });

        setGridData(newData);
    };

    const handleToggleLists = () => {
        setToggleListHeader(!toggleListHeader);
        setToggleFavorite(!toggleFavorite);
    };

    const reorder = (dataItem: any, direction: 'before' | 'after') => {
        if (activeItem === dataItem) {
            return;
        }
        let reorderedData = gridData.slice();
        let prevIndex = reorderedData.findIndex((p) => p === activeItem);
        let nextIndex = reorderedData.findIndex((p) => p === dataItem);

        reorderedData.splice(prevIndex, 1);
        reorderedData.splice(Math.max(nextIndex + (direction === 'before' ? -1 : 0), 0), 0, activeItem || reorderedData[0]);

        folders.sort(
            (a, b) => reorderedData.findIndex((item) => item.Id === a.id) - reorderedData.findIndex((item) => item.Id === b.id)
        );

        setGridData(reorderedData.map((item) => {
            item.expanded = true;
            return item;
        }));

        reorderFolders(folders, {
            onSuccess: () => {
                setFolders(folders);
            }
        });
    };

    const dragStart = (dataItem: any) => {
        let newData = gridData?.map((item) => {
            item.expanded = false;
            return item;
        });

        setGridData(newData);
        setActiveItem(dataItem);
    };

    const handleFolderName = (props: any) => {
        return props.dataItem.InEdit ? (
            <td className="folder-name">
                <TextField
                    defaultValue={props.dataItem.FolderName}
                    onChange={(event) => handleFolderNameChange(event, props.dataItem)}
                />
            </td>
        ) : (
            <td className={isEdit && !props.dataItem.InEdit ? 'folder-name text-disabled' : 'folder-name'}>
                <div className="renderChevStyles">
                    <img className="chevronDown" src={chevronDown} alt="chevron down" onClick={() => expandChange(props)} />
                </div>
                <img className="folderIconStyles" src={folderIcon} alt="" />
                {props.dataItem.FolderName}
            </td>
        );
    };

    const handleCreatedBy = (props: any) => {
        return (
            <td className={isEdit && !props.dataItem.InEdit ? 'createdByFolderStyles text-disabled' : 'createdByFolderStyles'}>
                {props.dataItem.CreatedBy}
            </td>
        );
    };

    const handleCreatedOn = (props: any) => {
        return (
            <td className={isEdit && !props.dataItem.InEdit ? 'text-disabled createdOnFolderStyles' : 'createdOnFolderStyles'}>
                {props.dataItem.CreatedOn}
            </td>
        );
    };

    const handleModifiedOn = (props: any) => {
        return (
            <td className={isEdit && !props.dataItem.InEdit ? 'modifiedOnFolderStyles text-disabled' : 'modifiedOnFolderStyles'}>
                {props.dataItem.ModifiedOn}
            </td>
        );
    };

    const handleDragEnd = (param: DropResult) => {
        const folderId = param.source.droppableId;
        const lists = gridData.find((item) => item.Id === folderId).SavedListQueries;
        const folder = folders.find((item) => item.id === folderId);
        const listDestIndex = param.destination?.index;
        const startIndex = param.source.index;

        if (listDestIndex !== undefined && listDestIndex !== startIndex) {
            const removed = lists.splice(startIndex, 1)[0];
            const folderListRemoved = folder.savedListQueries.splice(startIndex, 1)[0];

            lists.splice(listDestIndex, 0, removed);
            folder.savedListQueries.splice(listDestIndex, 0, folderListRemoved);

            let reorderedGridData = [...gridData];
            let reorderedFolderData = [...folders];

            reorderedGridData.find((item) => item.Id === folderId).SavedListQueries = lists;
            reorderedFolderData.find((item) => item.id === folderId).savedListQueries = folder.savedListQueries;

            reorderLists(folder, {
                onSuccess: () => {
                    setGridData(reorderedGridData);
                    setFolders(reorderedFolderData);
                }
            });
        }
    };

    // Saves a list
    const handleSaveList = () => {
        var newList: IUserList = {
            listFolders: [
                {
                    id: selectedFolder.id,
                    name: selectedFolder.name,
                    savedListQueries: [queryToSave]
                }
            ]
        };

        updateList(newList, {
            onSuccess: () => {
                hideEditModal();

                var editedProps = editingProps;
                var folder = gridData.find((folder: any) => folder.Id === editedProps.FolderId);
        
                editedProps.QueryName = queryToSave.queryName;
                editedProps.QueryIcon = queryToSave.icon;
        
                // List has been moved to a different folder
                if (selectedFolder.id !== folder.Id) {
                    editedProps.FolderId = selectedFolder.id;
                    folder.SavedListQueries = folder.SavedListQueries.filter((query: any) => query.QueryId !== editedProps.QueryId);
        
                    var movedFolder = gridData.find((folder: any) => folder.Id === selectedFolder.id);
                    movedFolder.SavedListQueries.push(editedProps);
                    movedFolder.expanded = true;
                }
        
                setGridData(gridData);
                setEditingProps({});
                setIsEdit(false);
                setIcon('');
                setName('');
                setIsEdit(false);
                setSelectedFolder({
                    name: '',
                    savedListQueries: []
                });
            }
        });
    };

    const [isSaveListModalOpen, { setTrue: showEditModal, setFalse: hideEditModal }] = useBoolean(false);
    const [isDeleteModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    return (
        <div className="manage-lists-cont">
            <div className="managing-lists-container">
                <div className="arrow-btn-container">
                    <div
                        onClick={() => {
                            history.goBack();
                        }}
                        className="arrowStyles">
                        <img src={blackArrow} alt="SVG" />
                    </div>
                    <div>
                        {!toggleFavorite ? (
                            <Trans>
                                <Text className="arrowLabelStyles">Manage Lists</Text>
                            </Trans>
                        ) : (
                            <Trans>
                                <Text className="arrowLabelStyles">Favorites</Text>
                            </Trans>
                        )}
                    </div>
                </div>
            </div>
            <div className="manageList-header">
                <div className="manageList-header-buttons">
                    <div
                        onClick={handleToggleLists}
                        className={`header-buttons ${!toggleFavorite ? 'manage-list-active' : 'inactive'}`}>
                        My Saved Lists
                    </div>
                    <div
                        onClick={handleToggleLists}
                        className={`header-buttons ${!toggleFavorite ? 'inactive' : 'manage-list-active'}`}>
                        My Favorite Lists
                    </div>
                </div>

                <div className="manageList-header-buttons">
                    <a href="/list-builder">
                        <img src={addListButton} alt="add list button" />
                    </a>
                    <div className="add-folder-btn" onClick={showFolderModal}>
                        <img src={addFolderButton} alt="add folder button" />
                    </div>
                    <AddFolderModal folders={folders} setFolders={setFolders} editMode={isEdit} showModal={showEditModal} />
                </div>
            </div>
            <div>
                <div className="box">
                    {!toggleFavorite ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <ReorderContext.Provider value={{ reorder: reorder, dragStart: dragStart }}>
                                <DragAndDrop>
                                    <Grid
                                        ref={gridRef}
                                        detail={(props) => {
                                            return DetailComponent(props);
                                        }}
                                        style={{
                                            height: '800px'
                                        }}
                                        expandField="expanded"
                                        dataItemKey="Id"
                                        editField="inEdit"
                                        data={gridData}
                                        rowRender={(row, rowProps) => (
                                            <DraggableRow
                                                elementProps={row.props}
                                                {...rowProps}
                                            />
                                        )}>
                                        <Column title=" " field="" width="150px" cell={(e) => DragHandleCell(e)} />
                                        <Column field={'FolderName'} title="Name" width="440px" cell={handleFolderName} />
                                        <Column field="CreatedBy" title="Created By" width="290px" cell={handleCreatedBy} />
                                        <Column field="CreatedOn" title="Created On" width="155px" cell={handleCreatedOn} />
                                        <Column field="ModifiedOn" title="Last Modified" width="225px" cell={handleModifiedOn} />
                                        <Column title="Action" cell={renderImage} width="240px" />
                                    </Grid>
                                </DragAndDrop>
                            </ReorderContext.Provider>
                        </DragDropContext>
                    ) : (
                        <div className="favoritesTable">
                            <Grid
                                style={{
                                    height: '800px'
                                }}
                                dataItemKey="Id"
                                editField="inEdit"
                                data={mapGridDataToFavoritesList()}>
                                <Column title=" " field="" width="10%" />
                                <Column className="favoriteFontStyles" field="QueryName" title="List Name" cell={IconQueryCell} width="32%" />
                                <Column className="favoriteFontStyles" field="CreatedBy" title="Created By" width="25%" />
                                <Column className="favoriteFontStyles" field="CreatedOn" title="Created On" width="15%" />
                                <Column className="favoriteFontStyles" field="ModifiedOn" title="Last Modified" width="15%" />
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <SaveListModal
                    setIcon={setIcon}
                    icon={icon}
                    folders={folders}
                    showFolderModal={showFolderModal}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                    setFolderData={setFolderData}
                    setName={setName}
                    name={name}
                    queryToSave={queryToSave}
                    setQueryToSave={setQueryToSave}
                    handleSave={handleSaveList}
                    isModalOpen={isSaveListModalOpen}
                    showModal={showEditModal}
                    hideModal={hideEditModal}
                    isLoading={isUpdatingList}
                    handleModalCancel={() => setIsEdit(false)}
                />
            </div>
            <div>
                <Modal isOpen={isDeleteModalOpen} onDismiss={hideModal}>
                    <DeleteModal isFolderDeleting={isFolderDeleting} cancelDelete={cancelDelete} handleDelete={handleDelete} />
                </Modal>
            </div>
        </div>
    );
};

export default ManageLists;
