import React from 'react';
import './FeedbackView.scss';
import FeedbackCard from 'app/modules/admin/feedbackView/feedbackCard/FeedbackCard';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { useHistory } from 'react-router';
import { RouteConstants } from 'app/utils/constants';

const FeedbackView: React.FC = () => {
    const history = useHistory();
    const itemsWithHref: IBreadcrumbItem[] = [
        { text: 'Home', key: 'home', onClick: () => history.push(RouteConstants.KNOWLEDGE_PORTAL) },
        {
            text: 'Feedback',
            key: 'Feedback',
            isCurrentItem: true
        }
    ];
    return (
        <>
            <div className="container">
                <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} />
            </div>
            <div className="feedback-view-container">
                <div className="container">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className="feedback-wrapper">
                                    <FeedbackCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeedbackView;
