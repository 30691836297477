import { Modal, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import './SuccessfulSave.scss';
import xButton from '../../../../static/icons/xButton.svg';
import SaveIcon from '../../../../static/icons/SaveIcon.svg';
import { Trans } from 'react-i18next';
interface IProps {
    queryName: any;
    setUpdatedFolderIndicator:Function;
    updateEditMode: Function;
}

export const SuccessfulSaveModal = () => {
    const [isModalOpen, { setTrue: showSuccess, setFalse: hideSuccess }] = useBoolean(false);

    const SuccessfulSave: React.FC<IProps> = ({ queryName, setUpdatedFolderIndicator }) => {
        const handleSuccess = () => {
            hideSuccess();
            setUpdatedFolderIndicator(true);
        }

        return (
            <div>
                <Modal isOpen={isModalOpen} onDismiss={hideSuccess} isBlocking={false}>
                    <div>
                        <div onClick={hideSuccess} className="x-btn-styles">
                            <img src={xButton} alt="icon svg" />
                        </div>
                        <div className="successPopUpContainer">
                            <div className="saveIcon">
                                <img src={SaveIcon} alt="SVG" />
                            </div>
                            <div className="saved-container">
                                <label className="saved-success">
                                    <Trans>Saved Successfully</Trans>
                                </label>
                            </div>
                            <div className="saved-container">
                                <label className="list-name">
                                    <Trans>{`"${queryName}"`}</Trans>
                                </label>
                            </div>
                            <div>
                                <PrimaryButton
                                    tabIndex={0}
                                    onClick={handleSuccess}
                                    text="Ok"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    return { SuccessfulSave, showSuccess, hideSuccess };
};
