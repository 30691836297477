import { Modal } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import './SuccessPopUp.scss';
import confirmationThumbsUp from '../../../../../static/icons/confirmationThumbsUp.svg';
import { Trans } from 'react-i18next';

export const SuccessPopUpModal = () => {
    const [isModalOpen, { setTrue: showSuccess, setFalse: hideSuccess }] = useBoolean(false);

    const SuccessPopUp: React.FC = () => {
        return (
            <div>
                <div>
                <Modal
                    isOpen={isModalOpen}
                    onDismiss={hideSuccess}
                    isBlocking={false}
                >
                <div className="successPopUpContainer">
                    <div> 
                        <img src={confirmationThumbsUp} alt="SVG"/>
                    </div>
                    <div>
                        <label className="labelStyles">
                            <Trans>Thank you for adding the customer</Trans>
                        </label>
                    </div>
                </div>
                </Modal>
                </div>
            </div>
        );
    };

        return { SuccessPopUp, showSuccess, hideSuccess };
    };
                        