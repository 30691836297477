import React, { useEffect, useRef, useState } from 'react';
import './ManageRequestDetailedView.scss';
import ListPanel from './listPanel/ListPanel';
import DetailsPanel from '../detailsPanel/DetailsPanel';
import AddCustomerModal from './addCustomer/AddCustomerModal';
import { IRequest } from 'app/models/common/response';
import { useHeightListener } from 'app/components/heightListenerHook/HeightListener';
import { Loader } from 'app/components/loader/Loader';

interface IProps {
    isListView: boolean;
    handleToggleView: Function;
    requestData: any[];
    setRequestData: Function;
    requestTypes: any[];
    teams: any[];
    isRequestTypesLoading: boolean;
    isTeamsLoading: boolean;
    page: number;
    setPage: Function;

    selectedRequest: IRequest;
    setSelectedRequest: Function;
    isEditMode?: boolean;
    setIsEditMode?: Function;
    isLoading?: boolean;
    requestDetailsLookup?: any[];
    isRequestDetailsLoading?: boolean;
    resultCount?: number;
}
const statusTypes = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Active' },
    { id: 3, name: 'In Progress' },
    { id: 4, name: 'Resolved' },
    { id: 5, name: 'Closed' }
];

const associationTypes = [
    { id: 1, name: 'None' },
    { id: 2, name: 'Parent' },
    { id: 3, name: 'Child' }
];

const ManageRequestDetailedView: React.FC<IProps> = ({
    isListView,
    handleToggleView,
    requestData,
    setRequestData,
    requestTypes,
    isRequestTypesLoading,
    teams,
    isTeamsLoading,
    page,
    setPage,

    selectedRequest,
    setSelectedRequest,
    isEditMode,
    setIsEditMode,
    isLoading,
    requestDetailsLookup,
    isRequestDetailsLoading,
    resultCount
}) => {
    // state variables are declared here
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
    const [leftPanelHeight, setLeftPanelHeight] = useState(0);

    const leftPanelRef = useRef(null);
    const rightPanelRef = useRef(null);

    const height = useHeightListener(rightPanelRef);
    useEffect(() => {
        if (leftPanelRef.current && rightPanelRef.current) {
            setLeftPanelHeight(height);
        }
    }, [height]);

    return (
        <div className="manage-request-detailed-container">
            <div className="details-container">
                <div className="ud-contents-wrap">
                    {isLoading && (
                        <div style={{ width: '100%' }}>
                            <Loader classNames="m-b-50 height-70" />
                        </div>
                    )}
                    {!isLoading && (
                        <div className="grid-col-left" style={{ height: `${leftPanelHeight}px` }} ref={leftPanelRef}>
                            {!isRequestTypesLoading && !isTeamsLoading && !isRequestDetailsLoading && (
                                <ListPanel
                                    data={requestData}
                                    requestData={requestData}
                                    setRequestData={setRequestData}
                                    selectedRequest={selectedRequest}
                                    setSelectedRequest={setSelectedRequest}
                                    requestTypes={requestTypes}
                                    teams={teams}
                                    pageNumber={page}
                                    setPageNumber={setPage}
                                    statusTypes={statusTypes}
                                    isListView={isListView}
                                    handleToggleView={handleToggleView}
                                    leftPanelHeight={leftPanelHeight}
                                    setIsEditMode={setIsEditMode}
                                    resultCount={resultCount}
                                    isLoading={isLoading}
                                    associationTypes={associationTypes}
                                />
                            )}
                        </div>
                    )}
                    <div className={`grid-col-right ${isLoading ? 'hidden' : ''}`} ref={rightPanelRef}>
                        {!isLoading && !isRequestTypesLoading && !isTeamsLoading && !isRequestDetailsLoading && (
                            <DetailsPanel
                                selectedRequest={selectedRequest}
                                requestTypes={requestTypes}
                                statusTypes={statusTypes}
                                setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                                teams={teams}
                                isEditMode={isEditMode}
                                setIsEditMode={setIsEditMode}
                                setSelectedRequest={setSelectedRequest}
                                requestData={requestData}
                                setRequestData={setRequestData}
                                setPageNumber={setPage}
                                isLoading={isLoading}
                                pageNumber={page}
                                requestDetailsLookup={requestDetailsLookup}
                                associationTypes={associationTypes}
                            />
                        )}
                    </div>
                </div>
            </div>
            <AddCustomerModal
                modalState={isAddCustomerModalOpen}
                updateModalState={(value: boolean) => setIsAddCustomerModalOpen(value)}
            />
        </div>
    );
};

export default ManageRequestDetailedView;
