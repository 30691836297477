import React, { useEffect, useRef } from 'react';

import { Text, Image, Icon } from '@fluentui/react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DraggableIcon from 'app/static/icons/equal-to-icon.svg';
import { Checkbox } from '@fluentui/react';
import { IColumnLookUpProps } from 'app/models/common/post';

interface IProps {
    list: any;
    setList: Function;
    handleChecked: Function;
    showMenu: boolean;
    handleShowMenu: Function;
    columnsLookUpData: IColumnLookUpProps[];
    setColumnsLookUp: Function;
}

const CustomizeColumns: React.FC<IProps> = ({
    list,
    setList,
    handleChecked,
    showMenu,
    handleShowMenu,
    columnsLookUpData,
    setColumnsLookUp
}) => {
    const menuRef = useRef(null);
    useMenuDismiss(menuRef, handleShowMenu);

    return (
        <>
            <div className="customize-col-btn">
                <Text className="filter-heading">Customize Columns</Text>

                <div className="placeholder-title row-flex-box align-center" onClick={() => handleShowMenu()}>
                    <Text className="placeholder-label">Customized</Text> <Icon iconName="ChevronDown" />
                </div>

                {showMenu && (
                    <div className="customize-col-menu" ref={menuRef}>
                        <div onClick={() => handleShowMenu()} className="row-flex-box align-center dropdown-header">
                            <Text>Customize Columns</Text>
                            <Icon iconName="ChevronUp" />
                        </div>

                        <DragDropContext
                            onDragEnd={(param) => {
                                const srcI = param.source.index;
                                const destI = param.destination?.index;

                                if (destI !== undefined) {
                                    let s = 0;
                                    let d = 0;
                                    columnsLookUpData.forEach((item, i) => {
                                        if (list[srcI]?.key === item.key) {
                                            s = i;
                                        }
                                        if (list[destI]?.key === item.key) {
                                            d = i;
                                        }
                                    });

                                    let k = [...list];
                                    k.splice(destI, 0, k.splice(srcI, 1)[0]);
                                    setList(k);
                                    let l = [...columnsLookUpData];
                                    l.splice(d, 0, l.splice(s, 1)[0]);
                                    setColumnsLookUp(l);
                                }
                            }}>
                            <div className="menu-items ">
                                <Droppable droppableId="droppable-1">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {list.map((ele: IColumnLookUpProps, i: number) => {
                                                return (
                                                    <Draggable key={i} draggableId={'draggable-' + i} index={i}>
                                                        {(provided) => (
                                                            <div
                                                                className="row-flex-box justify-sb p-l-20 p-r-20 p-t-15 p-b-15 list-item align-center"
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}>
                                                                <Checkbox
                                                                    data-testid="checkbox"
                                                                    label={ele.column}
                                                                    onChange={(
                                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                                        checked?: boolean
                                                                    ) => handleChecked(ele.key, checked)}
                                                                    checked={ele.isChecked}
                                                                    disabled={ele.key === 0 ? true : false}
                                                                />

                                                                <div
                                                                    className="draggable-container"
                                                                    {...provided.dragHandleProps}>
                                                                    <Image className="drag-icon" src={DraggableIcon} alt="Drag" />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </DragDropContext>
                    </div>
                )}
            </div>
        </>
    );
};

const useMenuDismiss = (ref: any, handleShowMenu: any) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleShowMenu();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleShowMenu, ref]);
};
export default CustomizeColumns;
