import React from 'react';
import { Text, Dropdown, IDropdownOption, Label } from '@fluentui/react';
import { DatePicker } from '@fluentui/react';
import moment from 'moment';
import { CustomField } from '../customTextFields';
import { Trans, useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
interface IProps {
    tempDateImportanceValue: string;
    animateAction: string;
    dateImportance: any;
    setDateImportanceHText: any;
    msxMatched: any;
    dateImportanceHText: any;
    requestType: any;
    selectedDate: any;
    setDateSent: any;
    setSelectedDate: any;
    selectedIssue: IDropdownOption;
    onIssueChange: any;
    issueHText: boolean;
    editorState: EditorState;
    setEditorState: (e: EditorState) => void;
    hashTagsFormatted: any[];
    setHashTagsFormatted: (hashTagsFormatted: any[]) => void;
    setTextToSubmit?: (textToSubmit: string) => void;
}

const Action: React.FC<IProps> = (props) => {
    const { t: translate } = useTranslation();
    const options = [
        { key: 1, text: 'Technical Issue' },
        { key: 2, text: 'Safety Issue' },
        { key: 3, text: 'Repair/Warranty Issue' }
    ];

    const {
        tempDateImportanceValue,
        animateAction,
        dateImportance,
        setDateImportanceHText,
        dateImportanceHText,
        requestType,
        selectedDate,
        setDateSent,
        setSelectedDate,
        selectedIssue,
        onIssueChange,
        issueHText,
        editorState,
        setEditorState,
        hashTagsFormatted,
        setHashTagsFormatted,
        setTextToSubmit
    } = props;

    const today = new Date(Date.now());
    const minDate = new Date();
    minDate.setTime(today.getDate() + 7);
    return (
        <div
            className={`actionpage ${animateAction === 'S_TO_PREV' ? 'prevSlide' : ''}  ${
                animateAction === 'S_TO_NEXT' ? 'nextSlide' : ''
            }  ${animateAction === 'A_TO_LEFT' ? 'animateToLeft' : ''}  ${
                animateAction === 'A_TO_RIGHT' ? 'animateToRight' : ''
            }`}>
            <div className="mb-74">
                <div className="m-b-45">
                    <label className="m-20">
                        <Trans>Requested resolution date</Trans>
                    </label>

                    <DatePicker
                        className="action-date-picker regular-text-field"
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        minDate={moment().add(1, 'days').toDate()}
                        formatDate={(value) => moment(value).format('dddd, MMM DD, YYYY')}
                        value={selectedDate}
                        onSelectDate={(newValue: any) => {
                            setSelectedDate(newValue);
                            let currentTime = moment();
                            setDateSent(
                                moment(newValue)
                                    .set({
                                        hours: currentTime.hours(),
                                        minutes: currentTime.minutes(),
                                        seconds: currentTime.seconds()
                                    })
                                    .toISOString()
                            );
                        }}
                        data-testid="select date"
                    />
                </div>{' '}
                <Label>{translate('Why is this date important ?')}</Label>
                <CustomField
                    tempValue={tempDateImportanceValue}
                    value={dateImportance}
                    helperText={dateImportanceHText}
                    setHelperText={setDateImportanceHText}
                    type="DATE_IMPORTANCE"
                    rows={4}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    hashTagsFormatted={hashTagsFormatted}
                    setHashTagsFormatted={setHashTagsFormatted}
                    setTextToSubmit={setTextToSubmit}
                />
                {dateImportanceHText && (
                    <Text className="helper-txt">
                        <Trans>Please enter why this date is important.</Trans>
                    </Text>
                )}
                {requestType === 'CSSRequest' && (
                    <div className="action-issue-category">
                        <label className="m-20">
                            <Trans>What is the category of your issue?</Trans>{' '}
                        </label>

                        <Dropdown
                            selectedKey={selectedIssue ? selectedIssue.key : undefined}
                            onChange={onIssueChange}
                            placeholder="Select one"
                            options={options}
                            data-testid="select date"
                            className={`issue-category-dropdown ${issueHText ? 'error-field' : ''}`}
                        />
                        {issueHText && (
                            <Text className="helper-txt">
                                <Trans>Please select an issue category.</Trans>
                            </Text>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Action;
