import { StringConstants } from './constants';

const {
    ADMIN,
    CONTENT_CREATOR,
    CONTENT_MANAGER,
    DRAFT,
    PUBLISHED,
    REDRAFT,
    REMOVED,
    SCHEDULED_TO_PUBLISH,
    SUBMITTED,
    SUPER_ADMIN,
    PUBLISH,
    EDIT,
    DELETE,
    DUPLICATE,
    REMOVE,
    SAVE_AS_TEMPLATE,
    MOVE_TO_DRAFT,
    NONE,
    SUBMIT_FOR_REVIEW
} = StringConstants;

export const STATUS_OPTIONS = [
    {
        ROLE: SUPER_ADMIN,
        OPTIONS: [
            {
                STATUS: DRAFT,
                PRIMARY_OPTION: PUBLISH,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: SUBMITTED,
                PRIMARY_OPTION: PUBLISH,
                MORE_OPTION: [MOVE_TO_DRAFT, EDIT, DUPLICATE]
            },
            {
                STATUS: SCHEDULED_TO_PUBLISH,
                PRIMARY_OPTION: PUBLISH,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: PUBLISHED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: REMOVED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [MOVE_TO_DRAFT, DUPLICATE, DELETE]
            },
            {
                STATUS: REDRAFT,
                PRIMARY_OPTION: SUBMIT_FOR_REVIEW,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE]
            }
        ]
    },
    {
        ROLE: CONTENT_MANAGER,
        OPTIONS: [
            {
                STATUS: DRAFT,
                PRIMARY_OPTION: PUBLISH,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: SUBMITTED,
                PRIMARY_OPTION: PUBLISH,
                MORE_OPTION: [MOVE_TO_DRAFT, EDIT, DUPLICATE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: SCHEDULED_TO_PUBLISH,
                PRIMARY_OPTION: PUBLISH,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: PUBLISHED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE, SAVE_AS_TEMPLATE]
            },
            {
                STATUS: REMOVED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [MOVE_TO_DRAFT, DUPLICATE, DELETE]
            },
            {
                STATUS: REDRAFT,
                PRIMARY_OPTION: SUBMIT_FOR_REVIEW,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE]
            }
        ]
    },
    {
        ROLE: CONTENT_CREATOR,
        OPTIONS: [
            {
                STATUS: DRAFT,
                PRIMARY_OPTION: SUBMIT_FOR_REVIEW,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE]
            },
            {
                STATUS: SUBMITTED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [MOVE_TO_DRAFT, DUPLICATE]
            },
            {
                STATUS: SCHEDULED_TO_PUBLISH,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [DUPLICATE]
            },
            {
                STATUS: PUBLISHED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [DUPLICATE]
            },
            {
                STATUS: REMOVED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [MOVE_TO_DRAFT, DUPLICATE, DELETE]
            },
            {
                STATUS: REDRAFT,
                PRIMARY_OPTION: SUBMIT_FOR_REVIEW,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE]
            }
        ]
    },
    {
        ROLE: ADMIN,
        OPTIONS: [
            {
                STATUS: DRAFT,
                PRIMARY_OPTION: SUBMIT_FOR_REVIEW,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE]
            },
            {
                STATUS: SUBMITTED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [MOVE_TO_DRAFT, DUPLICATE]
            },
            {
                STATUS: SCHEDULED_TO_PUBLISH,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [DUPLICATE]
            },
            {
                STATUS: PUBLISHED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [DUPLICATE]
            },
            {
                STATUS: REMOVED,
                PRIMARY_OPTION: NONE,
                MORE_OPTION: [MOVE_TO_DRAFT, DUPLICATE, DELETE]
            },
            {
                STATUS: REDRAFT,
                PRIMARY_OPTION: SUBMIT_FOR_REVIEW,
                MORE_OPTION: [EDIT, DUPLICATE, REMOVE, DELETE]
            }
        ]
    }
];
