import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    IconButton,
    IIconProps,
    Text,
    DefaultButton,
    MessageBar,
    MessageBarType,
    Spinner,
    Dialog,
    DialogFooter,
    PrimaryButton,
    DialogType,
    SpinnerSize
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import './AddAttachmentModal.scss';
import { Trans, useTranslation } from 'react-i18next';
import { IAttachmentRequest, IAttachmentUpdateData } from 'app/models/common/request';
import FileDropzone from 'app/components/dropzone/FileDropzone';
import { useGetAttachments } from 'app/services/queries';
import { keyPress } from 'app/components/helpers';
import { blobStorageUpload, removeFailedUploadsFromAttachmentList } from 'app/utils/blobStorageHelpers';
import { useAddAttachment, useRemoveAttachment } from 'app/services/mutations';
import { Loader } from '../loader/Loader';
import { useHistory } from 'react-router';
import { getPortalValue } from 'app/utils/utilities';
import AttachmentRow from './attachmentRow';

const titleId = 'add-attachment-request-modal';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IProps {
    modalState: boolean;
    updateModalState: Function;
    selectedRequest: IAttachmentUpdateData;
    userType?: string;
    entityType?: number;
    onCompletedHandler?: Function;
}
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Remove attachment',
    closeButtonAriaLabel: 'Close',
    subText: 'Do you want to remove this attachment?'
};
const processingDialogContentProps = {
    type: DialogType.normal
};

const AddAttachmentModal: React.FC<IProps> = (props) => {
    // portal type
    const portal = getPortalValue(useHistory()?.location?.pathname);
    // function
    const { t: translate } = useTranslation();
    // Iprops destructuring
    const { modalState, updateModalState, selectedRequest, entityType, onCompletedHandler } = props;
    // state variables
    const [removalDetails, setRemovalDetails] = useState<{ i: number; id: string }>({ i: null, id: '' });
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(modalState);
    const [isAttachmentActive, setIsAttachmentActive] = useState(false);
    const [isFormView, setIsFormView] = useState(true);
    const [isError, setIsError] = useState(false);
    const [attachmentList, setAttachmentList] = useState([]);
    const [uploadedAttachments, setUploadedAttachments] = useState([]);
    const [isDataChanged, setIsDataChanged] = useState(false);

    // blob upload helpers
    const [isUploadingToBlob, setIsUploadingToBlob] = useBoolean(false);
    const [blobUploadFiles, setBlobUploadFiles] = useState([]);
    const [isUploadError, setIsUploadError] = useState(false);
    const [uploadErrors, setUploadErrors] = useState([]);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideProcessingDialog, { toggle: toggleHideProcessingDialog }] = useBoolean(true);
    const customRef = useRef(null);

    // queries
    const {
        mutate: addAttachmentMutation,
        isLoading: isLoadingAddAttachment,
        isSuccess: isSuccessAddAttachment,
        isError: isErrorAddAttachment
    } = useAddAttachment();

    const {
        mutate: removeAttachmentMutation,
        isLoading: isRemovingAttachment,
        isSuccess: isRemovingSuccess,
        isError: isRemovingError
    } = useRemoveAttachment();

    const {
        data: attachmentData,
        isLoading: isLoadingAttachments,
        refetch: refetchAttachments
    } = useGetAttachments({
        id: selectedRequest.requestId,
        team: selectedRequest.teamValue,
        requestType: selectedRequest.requestTypeValue,
        entityType: entityType,
        portal: portal
    });

    const handleModalDismiss = () => {
        if (isUploadingToBlob) {
            return false;
        }

        updateModalState(false);
        setIsAttachmentActive(false);
        setIsFormView(true);
        setUploadedAttachments([]);
        if (isDataChanged) {
            onCompletedHandler();
        }
    };

    const handleShowAttachment = () => {
        setIsAttachmentActive((value) => !value);
        setAttachmentList([]);
    };

    // upload attachments to blob storage
    const handleUploadAttachments = async () => {
        setIsUploadError(false);
        setUploadErrors([]);

        if (attachmentList.length === 0) {
            setIsError(true);
            return false;
        }

        let errors = false;
        setIsUploadingToBlob.setTrue();
        if (blobUploadFiles.length > 0) {
            await blobStorageUpload(blobUploadFiles)
            .then(res => {
                errors = res.length > 0 ? true : false;
                if (errors) {
                    setUploadErrors(res);
                    setIsUploadError(true);
                    let successfulUploads = removeFailedUploadsFromAttachmentList(res, attachmentList);
                    setAttachmentList(successfulUploads);
                }
            })
        }

        if (attachmentList.length > 0) {
            handleSubmitAttachment();
        }
        else {
            setIsUploadingToBlob.setFalse();
            setIsAttachmentActive(false);
        }

        setBlobUploadFiles([]);
    }

    // update cosmos
    const handleSubmitAttachment = () => {
        let attachedArrayForUpload = attachmentList.map((item) => ({
            url: item.url,
            fileName: item.name,
            isExternal: item.isExternal
        }));
        let apiRequestData: IAttachmentRequest = {
            entityId: selectedRequest.requestId,
            entityType: entityType,
            attachments: attachedArrayForUpload,
            requestType: selectedRequest.requestTypeValue,
            team: selectedRequest.teamValue
        };
        addAttachmentMutation(apiRequestData);
        setIsDataChanged(true);
        setIsUploadingToBlob.setFalse();
        setAttachmentList([]);
    };

    const handleRemove = (i: any, attachmentId: string) => {
        const list = [...uploadedAttachments];
        list.splice(i, 1);
        setUploadedAttachments(list);
        let apiRequestData: IAttachmentRequest = {
            entityId: selectedRequest.requestId,
            entityType: entityType,
            requestType: selectedRequest.requestTypeValue,
            team: selectedRequest.teamValue,
            id: attachmentId
        };
        removeAttachmentMutation(apiRequestData);
        if (list.length === 0) {
            setIsAttachmentActive(true);
        }
        setIsDataChanged(true);
    };

    const handleCancel = () => {
        if (!isUploadingToBlob) {
            setIsAttachmentActive(false);
        }
    };

    // hooks
    useEffect(() => {
        setUploadedAttachments([]);
        const toggleModal = (modalState: boolean) => {
            if (modalState) {
                if (!isLoadingAttachments) {
                    showModal();
                }
            } else {
                hideModal();
            }
        };
        toggleModal(modalState);
    }, [modalState, showModal, hideModal]);

    useEffect(() => {
        if (!isLoadingAttachments) {
            if (attachmentData && attachmentData.length > 0) {
                setUploadedAttachments(attachmentData);
            } else {
                setIsAttachmentActive(true);
                setUploadedAttachments([]);
            }
        }
    }, [isLoadingAttachments, attachmentData]);

    useEffect(() => {
        setIsError(false);
    }, [attachmentList]);

    useEffect(() => {
        if (!isLoadingAddAttachment) {
            refetchAttachments();
            setIsAttachmentActive(false);
        }
    }, [isLoadingAddAttachment]);

    useEffect(() => {
        if (isRemovingAttachment) {
            toggleHideProcessingDialog();
        }
        if (isRemovingSuccess || isRemovingError) {
            toggleHideProcessingDialog();
        }
    }, [isRemovingAttachment, isRemovingSuccess, isRemovingError]);

    return (
        <>
            {modalState && isModalOpen && (
                <Modal
                    titleAriaId={titleId}
                    isOpen={isModalOpen}
                    onDismiss={handleModalDismiss}
                    isBlocking={false}
                    containerClassName="add-attachment-request-modal">
                    {isFormView && (
                        <>
                            <Dialog
                                dialogContentProps={processingDialogContentProps}
                                hidden={hideProcessingDialog}
                                onDismiss={toggleHideProcessingDialog}
                                modalProps={{ isBlocking: false }}>
                                <Spinner label="Please wait, while we process your request" />
                            </Dialog>
                            <Dialog
                                modalProps={{ isBlocking: false }}
                                hidden={hideDialog}
                                onDismiss={toggleHideDialog}
                                dialogContentProps={dialogContentProps}>
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={() => {
                                            handleRemove(removalDetails.i, removalDetails.id);
                                            toggleHideDialog();
                                        }}
                                        text="Remove"
                                    />
                                    <DefaultButton onClick={() => toggleHideDialog()} text="Cancel" />
                                </DialogFooter>
                            </Dialog>
                            <div className="modal-head">
                                {selectedRequest.isHidden ? (
                                    <Text>
                                        <Trans>Attachments</Trans>
                                    </Text>
                                ) : (
                                    <Text>
                                        <Trans>Add Attachments</Trans>
                                    </Text>
                                )}
                                <IconButton
                                    role="button"
                                    className="modal-close"
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={handleModalDismiss}
                                    onKeyPress={(e: any) => keyPress(e, handleModalDismiss)}
                                    tabIndex={0}
                                />
                            </div>
                            <div className="add-attachment-modal-body">
                                <>
                                    <div className="dp-header">
                                        <div className="cust-details">
                                            <Text className="secondary-text">
                                                <span className="text-bold">
                                                    <Trans>Request ID</Trans> -{' '}
                                                </span>
                                                {selectedRequest.requestId}
                                            </Text>
                                            <Text className="secondary-text">
                                                <span className="text-bold">
                                                    {selectedRequest.teamName} <Trans>Application ID</Trans> -{' '}
                                                </span>
                                                {selectedRequest.applicationId}
                                            </Text>
                                            <Text className="secondary-text">
                                                <span className="text-bold">
                                                    <Trans>Current Team</Trans> -{' '}
                                                </span>
                                                {selectedRequest.teamName}
                                            </Text>
                                            <Text className="secondary-text">
                                                <span className="text-bold">
                                                    <Trans>Current Request Type</Trans> -{' '}
                                                </span>
                                                {selectedRequest.requestType}
                                            </Text>
                                        </div>
                                        <div className="dp-head-desc">
                                            <Text className="title-field">{selectedRequest.summary}</Text>
                                        </div>
                                    </div>

                                    <div className="dp-content">
                                        {isLoadingAttachments && <Loader classNames="m-t-50 m-b-50" />}
                                        {isLoadingAddAttachment && (
                                            <div className="m-t-20">
                                                <Spinner label={translate('Adding attachments. Please wait...')} />
                                            </div>
                                        )}
                                        {!isLoadingAttachments && isAttachmentActive && (
                                            <>
                                                {selectedRequest.isHidden ? (
                                                    <div className="no-attachments-cont">
                                                        <Text>No attachments available.</Text>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="attachment-container">
                                                            <FileDropzone
                                                                customRef={customRef}
                                                                fileNames={attachmentList}
                                                                setFileNames={setAttachmentList}
                                                                blobUploadFiles={blobUploadFiles}
                                                                setBlobUploadFiles={setBlobUploadFiles}
                                                                isUploadingToBlob={isUploadingToBlob}
                                                            />
                                                        </div>
                                                        {isError && (
                                                            <div className="error-message m-t-10">
                                                                <MessageBar
                                                                    messageBarType={MessageBarType.error}
                                                                    messageBarIconProps={{ iconName: 'Error' }}>
                                                                    <Trans>Please select a file to attach.</Trans>
                                                                </MessageBar>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {!isLoadingAttachments && !isAttachmentActive && (
                                            <div className="attachment-list-container">
                                                <div className="m-b-10">
                                                    {!isLoadingAddAttachment && isSuccessAddAttachment && (
                                                        <>
                                                        { !isUploadError ? (
                                                            <MessageBar messageBarType={MessageBarType.success}>
                                                                <Trans>Files uploaded successfully</Trans>
                                                            </MessageBar>
                                                        ) : null}
                                                        </>
                                                    )}
                                                    { isErrorAddAttachment && (
                                                        <>
                                                            <MessageBar
                                                                messageBarType={MessageBarType.error}
                                                                messageBarIconProps={{ iconName: 'Error'}}
                                                            >
                                                                <Text>Your file was stored but an error ocurred when updating the request</Text>
                                                            </MessageBar>
                                                        </>
                                                    )}
                                                    {!isLoadingAddAttachment && isUploadError && (
                                                        <MessageBar
                                                            messageBarType={MessageBarType.error}
                                                            messageBarIconProps={{ iconName: 'Error' }}>
                                                            <>
                                                                {uploadErrors?.map(err => {
                                                                    return (
                                                                        <li>{err.fileName} : {err.error}</li>
                                                                    )
                                                                })}
                                                            </>
                                                        </MessageBar>
                                                    )}
                                                </div>
                                                <div className="scroll-panel">
                                                    {uploadedAttachments &&
                                                        uploadedAttachments.map((item: any, i: number) => {
                                                            return (
                                                                <AttachmentRow
                                                                    item={item}
                                                                    key={item.id}
                                                                    index={i}
                                                                    entityType={entityType}
                                                                    isAttachmentActive={isAttachmentActive}
                                                                    selectedRequest={selectedRequest}
                                                                    setIsAttachmentActive={setIsAttachmentActive}
                                                                    setIsDataChanged={setIsDataChanged}
                                                                    setRemovalDetails={setRemovalDetails}
                                                                    setUploadedAttachments={setUploadedAttachments}
                                                                    toggleHideDialog={toggleHideDialog}
                                                                    uploadedAttachments={uploadedAttachments}
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-btn-wrap">
                                            {(!isAttachmentActive ||
                                                (isAttachmentActive && uploadedAttachments.length === 0)) && (
                                                <DefaultButton
                                                    className={`${selectedRequest.isHidden ? 'primary' : 'default'}`}
                                                    onClick={handleModalDismiss}
                                                    tabIndex={0}
                                                    onKeyPress={(e: any) => keyPress(e, handleModalDismiss)}
                                                    data-testid="cancel-button">
                                                    <Trans>Close</Trans>
                                                </DefaultButton>
                                            )}
                                            {isAttachmentActive && uploadedAttachments.length > 0 && (
                                                <DefaultButton
                                                    className="default"
                                                    onClick={handleCancel}
                                                    onKeyPress={(e: any) => keyPress(e, handleCancel)}
                                                    tabIndex={0}
                                                    data-testid="cancel-button">
                                                    <Trans>Cancel</Trans>
                                                </DefaultButton>
                                            )}
                                            {isAttachmentActive && !selectedRequest.isHidden && (
                                                <DefaultButton
                                                    type="submit"
                                                    className={ "primary m-l-20" }
                                                    onClick={handleUploadAttachments}
                                                    tabIndex={0}
                                                    onKeyPress={(e: any) => keyPress(e, handleUploadAttachments)}
                                                    data-testid="add-attach-btn"
                                                    disabled={isUploadingToBlob}
                                                    >
                                                    { isUploadingToBlob ? (
                                                        <div className="upload-attachment-spinner">
                                                        <Text className="upload-attachment-spinner-text">Uploading</Text>
                                                        <Spinner size={SpinnerSize.large} />
                                                        </div>
                                                    ) : (
                                                    <Trans>Attach</Trans>
                                                    )}
                                                </DefaultButton>
                                            )}
                                            {!isAttachmentActive && !selectedRequest.isHidden && (
                                                <DefaultButton
                                                    type="submit"
                                                    className="primary m-l-20"
                                                    onClick={handleShowAttachment}
                                                    onKeyPress={(e: any) => keyPress(e, handleShowAttachment)}
                                                    tabIndex={0}
                                                    data-testid="add-attach-btn">
                                                    <Trans>Add More</Trans>
                                                </DefaultButton>
                                            )}
                                        </div>
                                    </div>
                                </>
                            </div>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export default AddAttachmentModal;
