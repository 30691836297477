import React from 'react';
import './KnowledgeArticleLanding.scss';
import SearchNews from '../searchnews/SearchNews';
import { useGetUserPreferences, useKnowledgeCenterHomePageArticles } from 'app/services/queries';
import { Trans, useTranslation } from 'react-i18next';
import CategoryWiseList from '../categoryWiseList/CategoryWiseList';
import EmailSubscribe from 'app/modules/user/emailSubscribe/EmailSubscribe';
import { Link } from 'react-router-dom';
import { Loader } from 'app/components/loader/Loader';
import NoData from 'app/components/noData/NoData';
const KnowledgeArticleLanding: React.FC = () => {
    const { t: translate } = useTranslation();
    const { isFetched, data: articles, isLoading } = useKnowledgeCenterHomePageArticles();
    const { isFetched: isFetchedPreferences, data: userPreferences } = useGetUserPreferences();
    let showData =
        (articles?.industryInsights?.length > 0 ||
            articles?.latestFromSurface?.length > 0 ||
            articles?.productArticles?.length > 0 ||
            articles?.surfacePrograms?.length > 0) 
    if (isLoading || !isFetched || !isFetchedPreferences) {
        return <Loader classNames="height-70" />;
    } else {
        return (
            <>
                <div className="news-banner-container">
                    <div className="container">
                        <div className="strip p-l-20 p-r-20 border-radius--8 m-t-10">
                            <div className="d-flex--between ai-center ">
                                <div>
                                    <div className="strip-txt">
                                        <span className="bld">
                                            <Trans>Knowledge Articles</Trans>
                                        </span>
                                        {showData && (
                                            <Link to={`/all-articles`} className="view-all-link">
                                                View all articles
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    {showData && (
                                        <SearchNews
                                            placeholder={translate('Search article')}
                                            iconName="Search"
                                            animate={false}
                                            type="articles"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {showData ? (
                        <>
                            {isFetched && articles?.latestFromSurface?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        articles={articles?.latestFromSurface}
                                        categoryName="Program"
                                        showViewAll={false}
                                        showCardBorder={true}
                                    />
                                </div>
                            )}
                            {isFetchedPreferences && !userPreferences?.knowledgeCenterSubscription && (
                                <EmailSubscribe type="article" />
                            )}
                            {isFetched && articles?.surfacePrograms?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        title={translate('Surface Program')}
                                        articles={articles?.surfacePrograms}
                                        categoryName="Program"
                                        showCardBorder={true}
                                    />
                                </div>
                            )}

                            {isFetched && articles?.productArticles?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        iconColor="icon-white"
                                        title={translate('Product Articles')}
                                        articles={articles?.productArticles}
                                        background="gradient--purple"
                                        categoryName="Product"
                                        showCardBorder={false}
                                    />
                                </div>
                            )}

                            {isFetched && articles?.industryInsights?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        title={translate('Industry Insights')}
                                        articles={articles?.industryInsights}
                                        categoryName="Industry"
                                        showCardBorder={true}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <NoData />
                    )}
                </div>
            </>
        );
    }
};

export default KnowledgeArticleLanding;
