import React, { useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import {
    PersonaInitialsColor,
    Text,
    Link,
    Dialog,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    DialogType
} from '@fluentui/react';
import _ from 'lodash';
import { ICommentResponse, IRequest } from 'app/models/common/response';
import { changeDateFormat, injectTargetToAnchor, ReplaceUrlByAnchor } from '../../helpers';
import Avatar from 'app/components/avatar/Avatar';
import { IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';
import { useRemoveStatus } from 'app/services/mutations';
import CommentField from 'app/components/commentField/CommentField';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';
import { BlobStorageContainer, Entity } from 'app/utils/constants';

interface IProps {
    statusText: string;
    item: ICommentResponse;
    correlationId: string;
    isInternal?: boolean;
    isMultipleType?: boolean;
    selectedRequest?: IRequest;
    itemIndex?: number;
}

const dialogContentProps = {
    type: DialogType.normal,

    closeButtonAriaLabel: 'Close',
    subText: 'Do you want to delete this status?'
};

const Status: React.FC<IProps> = ({
    item,
    correlationId,
    isInternal,
    isMultipleType,
    statusText,
    itemIndex,
    selectedRequest
}) => {
    // state variables
    const [isEditActive, setIsEditActive] = React.useState(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [itemToDelete, setItemToDelete] = useState<string>(null);

    // queries
    const { mutate: removeStatusMutation } = useRemoveStatus();

    const userDetails: IUserDetails = useStore((state: any) => state.userDetails as IUserDetails);

    const getTaggedUser = (name: string, users: any) => {
        if (!_.isEmpty(name) && !_.isEmpty(users)) {
            return _.find(users, (item) => item.id === name);
        } else {
            return {
                id: '',
                displayName: '',
                email: ''
            };
        }
    };

    const [parsedStatusText, setParsedStatusText] = useState<string>('');
    useEffect(() => {
        if (statusText && statusText !== '') {
            const replaceStorageUrls = async () => {
                await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.COMMENT_STATUS_FILES, statusText)
                .then((res) => {
                    if (res !== '') {
                        setParsedStatusText(res);
                    }
                });
            }

            replaceStorageUrls();
        }
    }, [statusText]);

    const generateCommentMessage = (message: string, taggedUsers: any) => {
        let formattedMsg = message;
        let users = Array.from(message?.matchAll(/<userid:(.*?)>/g));
        for (const user of users) {
            formattedMsg = formattedMsg.replace(
                user[0],
                `<span class="tagged-user">${getTaggedUser(user[1], taggedUsers)?.displayName}</span>`
            );
        }
        if (isMultipleType) {
            formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request-detail');
        } else {
            formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request');
        }
        formattedMsg = ReplaceUrlByAnchor(injectTargetToAnchor(formattedMsg?.replace(/\\/g, '')), 'tagged-user', true);
        return formattedMsg;
    };

    const handleRemoveStatus = () => {
        setItemToDelete(item.id);
        toggleHideDialog();
    };

    const isCurrentUsersComment = (id: string) => {
        return id === userDetails?.id;
    };

    const toggleEdit = () => {
        setIsEditActive((value) => !value);
    };

    // useEffects
    useEffect(() => {
        removeStatusMutation({ id: itemToDelete, correlationId });
    }, [itemToDelete]);

    return (
        <>
            <div className="cmt-block" key={itemIndex}>
                <div className="cmt-contents status">
                    <div className="cmt-persona">
                        <Avatar
                            userId={item?.createdBy?.id}
                            userName={item?.createdBy?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={42}
                            roundedPic={true}
                        />
                    </div>
                    <div className="cmt-wrap">
                        <div className="cmt-details">
                            <Text className="cmt-usr-name">{item.createdBy && item.createdBy.displayName}</Text>
                            {isEditActive ? (
                                <>
                                    <CommentField
                                        isEditActive={isEditActive}
                                        initialValue={parsedStatusText}
                                        correlationId={item.correlationId}
                                        entityType={1}
                                        id={item.id}
                                        parentStatusId={item.parentCommentId}
                                        cancelOperation={toggleEdit}
                                        textareaSize={2}
                                        className=""
                                        isInternal={isInternal}
                                        fieldType="status"
                                        selectedRequest={selectedRequest}
                                    />
                                </>
                            ) : (
                                <>
                                    <div
                                        className="cmt-text status"
                                        dangerouslySetInnerHTML={{
                                            __html: generateCommentMessage(parsedStatusText, item.taggedUsers)
                                        }}></div>
                                </>
                            )}
                        </div>

                        <div className="cmt-action">
                            {isCurrentUsersComment(item.createdBy?.id) && !isEditActive && item.isActive && (
                                <>
                                    <Link
                                        className="action-items"
                                        data-testid="reply-one"
                                        tabIndex={0}
                                        onClick={() => {
                                            toggleEdit();
                                        }}>
                                        Edit
                                    </Link>
                                    <Text className="action-items">
                                        <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                                    </Text>
                                </>
                            )}

                            {(isAdmin(userDetails?.userRoles) ||
                                isSuperAdmin(userDetails?.userRoles) ||
                                isCurrentUsersComment(item.createdBy?.id)) &&
                                !isEditActive &&
                                item.isActive && (
                                    <>
                                        <Link
                                            className="action-items"
                                            data-testid="reply-one"
                                            tabIndex={0}
                                            onClick={() => toggleHideDialog()}>
                                            Delete
                                        </Link>
                                        <Dialog
                                            hidden={hideDialog}
                                            onDismiss={toggleHideDialog}
                                            dialogContentProps={dialogContentProps}>
                                            <DialogFooter>
                                                <PrimaryButton onClick={() => handleRemoveStatus()} text="Delete" />
                                                <DefaultButton onClick={() => toggleHideDialog()} text="Cancel" />
                                            </DialogFooter>
                                        </Dialog>
                                    </>
                                )}
                            {(item?.createdOn &&
                                <>
                                    <Text className="action-items">
                                        <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                                    </Text>
                                    <Text className="action-items">
                                        {changeDateFormat(item.createdOn, 'MMM DD YYYY')} at {changeDateFormat(item.createdOn, 'hh:mm a')}
                                    </Text>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Status;
