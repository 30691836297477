import React, { useLayoutEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import './NotificationOverLay.scss';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { INotificationItem } from 'app/models/common/response';
import { getIconForNotifications, getLinkForNotification, keyPress } from 'app/components/helpers';
interface IProps {
    items: INotificationItem[];
    handleNotificationClose: (a?: any) => void;
}

function useProfileSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateWidth() {
            var container = document.getElementById('profileHeader');
            if (container) setSize(container.clientWidth);
        }
        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, []);
    return size;
}

const NotificationOverLay: React.FC<IProps> = (props) => {
    const history = useHistory();
    var right = useProfileSize();
    const getFormattedDate = (createdOn: Date) => {
        var currentDate = new Date();
        var currentFormatDate = moment(currentDate).format('DD-MMM-YYYY');
        var createdOnFormattedDate = moment(createdOn).format('DD-MMM-YYYY');
        if (currentFormatDate === createdOnFormattedDate) {
            return 'Today at ' + moment(createdOn).format('hh:mm a');
        } else {
            var yesterday = new Date(currentDate);
            yesterday.setDate(yesterday.getDate() - 1);
            var yesterdayFormatDate = moment(yesterday).format('DD-MMM-YYYY');
            if (yesterdayFormatDate === createdOnFormattedDate) {
                return 'Yesterday at ' + moment(createdOn).format('hh:mm a');
            } else {
                return moment(currentDate).format('DD MMM [at] hh:mm a');
            }
        }
    };

    const renderNotification = (notification: INotificationItem) => {
        const [alt, icon] = getIconForNotifications(notification?.entityType, notification?.eventType);
        return (
            <div className="notification-item-row">
                <img className="notification-icon" src={icon} alt={alt} />
                <div className="notification-message-container">
                    <div className="notification-message">
                        <span>{notification?.text}</span>
                    </div>
                    <div className="notification-time">{getFormattedDate(notification?.createdOn)} </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="notification-overlay" style={{ right: right + 15 + 'px' }}>
                <div className="custom-row">
                    <div className="notification-header">
                        <Trans>Notifications</Trans>
                    </div>
                </div>
                <div>
                    {props.items && props.items.filter((x) => !x.isRead) && props.items.filter((x) => !x.isRead).length > 0 ? (
                        props.items
                            .filter((x) => !x.isRead)
                            .map((item, index) => (
                                <div
                                    tabIndex={0}
                                    onClick={() => {
                                        history.push(getLinkForNotification(item));
                                        props.handleNotificationClose();
                                    }}
                                    onKeyPress={(e: any) =>
                                        keyPress(e, () => {
                                            history.push(getLinkForNotification(item));
                                            props.handleNotificationClose();
                                        })
                                    }
                                    key={`notification${index}`}
                                    data-testid={`${index}`}>
                                    {renderNotification(item)}
                                </div>
                            ))
                    ) : (
                        <div className="no-unread">
                            <Trans>No unread notifications</Trans>
                        </div>
                    )}
                </div>
                <div className="view-all-notifications">
                    <Link role="link" to="/notifications" tabIndex={0} onClick={props.handleNotificationClose}>
                        <Trans>View All</Trans>
                    </Link>
                </div>
            </div>
        </>
    );
};
export default NotificationOverLay;
