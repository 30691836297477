import React from 'react';
import { Text } from '@fluentui/react';
import { Link } from 'react-router-dom';
import './index.scss';
import { Trans } from 'react-i18next';
const YouNeed: React.FC = () => {
    return (
        <div className="you-need-container">
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg12">
                        <div className="you-box-container">
                            <Text className="txt-cont">
                                <Trans>Did not find what you need?</Trans>
                                <Link role="link" className="requestHelpBtn" to="/help-request">
                                    <Trans>Raise a request here</Trans>
                                </Link>
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YouNeed;
