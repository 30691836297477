import { Portal } from 'app/enums/Portal.enum';
import { RouteConstants } from 'app/utils/constants';

/**
 * Returns the first value associated to the given search parameter.
 */
export function getUrlParamValue(param: string): string {
    const url = document.location.search || document.location.href;
    let urlParams = new URLSearchParams(url.substring(1));
    return urlParams.get(param) || '';
}

/*
    Determin if route is from admin or user portals
*/
export function getPortalValue(param: string): number {
    // default to user portal
    let value = Portal.User;

    // todo: include other routes that are admin only
    if (param?.includes(RouteConstants.MANAGE_REQUESTS) || param?.includes(RouteConstants.VISUAL_FLOW)) {
        value = Portal.Admin;
    }

    return value;
}

export function attachmentCounter(attachments: any[], portal: Portal) {
    let count = 0;

    if (attachments?.length > 0) {
        count = portal === Portal.Admin ? attachments.length : countExternalAttachments();
    }

    function countExternalAttachments() {
        let total = 0;
        attachments.forEach((attachment) => {
            total = attachment?.isExternal === true ? (total += 1) : total;
        });

        return total;
    }

    return count;
}


