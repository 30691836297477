import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Text, Stack, IChoiceGroupOption, ChoiceGroup, IStackProps, SpinnerSize, Spinner, Link } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import YouNeed from '../../youNeed';
import './index.scss';
import { useSearchResult } from 'app/services/mutations';
import useStore from 'app/store';
import { ISearchResultResponse } from 'app/models/common/post';
import SearchResultList from '../searchResultList';
import { escapeToSafeCharacters, getDate, getQueryParam, keyPress, setQueryParam } from 'app/components/helpers';
import { Trans, useTranslation } from 'react-i18next';
import Pagination from 'app/components/pagination/Pagination';
const stackTokens = { childrenGap: 10 };

//FluentUI Spinner related
const rowProps: IStackProps = { horizontal: true, verticalAlign: 'center' };
const tokens = {
    sectionStack: {
        childrenGap: 10
    },
    spinnerStack: {
        childrenGap: 20
    }
};

enum SearchOption {
    Article = 'Article',
    HelpRequest = 'HelpRequest',
    Default = 'Default'
}

const SearchResults: React.FC = () => {
    const { t: translate } = useTranslation();

    const location = useLocation();
    const history = useHistory();

    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);
    const userSearchValue = useStore((state: any) => state.userSearchValue);
    const setSearchValueClear = useStore((state: any) => state.setSearchValueClear);
    const [, setSearchResult] = useState<ISearchResultResponse>();

    const { mutate: searchData, data: searchResultData, isSuccess: isSuccessSearch, isLoading: isSearching } = useSearchResult();
    const [listData, setListData] = useState([]);

    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history).page) || 1);

    const [tags, setTags] = React.useState([]);

    const [articlesCount, setArticlesCount] = useState<number>();
    const [helpRequestCount, setHelpRequestCount] = useState<number>();
    const [totalCount, setTotalCount] = useState<number>();
    const [allDatesCount, setAllDatesCount] = useState<number>();

    const [searchPaginatedDetails, setSearchPaginatedDetails] = useState([]);

    const [searchLink, setSearchLink] = useState(SearchOption.Default);
    const displayPageSize = 10;

    //choices
    const [isChecked, setIsChecked] = useState('All Dates');

    const initialRender = useRef(true);

    useEffect(() => {
        setListData([]);
        const locationData: any = location?.state;
        if (userSearchValue?.trim()) {
            let payload = {
                searchTerm: escapeToSafeCharacters(userSearchValue),
                pageIndex: pageIndex,
                filter: getDate(isChecked),
                pageSize: displayPageSize,
                searchOption: searchLink,
                IsDateRangeCountRequired: pageIndex === 1 && searchLink === SearchOption.Default && isChecked === 'All Dates',
                SearchPaginatedDetails: locationData?.searchPaginatedDetails ?? searchPaginatedDetails,
                IsAdminSearch: false
            };
            searchData(payload);
        }
    }, [userSearchValue, isChecked, searchLink, pageIndex]);

    useEffect(() => {
        const locationData: any = location?.state;
        if (locationData?.userSearchValue) {
            if (
                initialRender.current === false &&
                locationData?.userSearchValue?.toLowerCase() === userSearchValue?.toLowerCase() &&
                userSearchValue.trim()
            ) {
                initialRender.current = false;
                setQueryParam(history, '/search-results', { page: 1 }, true, {
                    ...stateData,
                    userSearchValue: userSearchValue,
                    isChecked: 'All Dates',
                    searchLink: SearchOption.Default
                });
            }
        } else {
            setQueryParam(history, '/search-results', { page: 1 }, true, {
                ...stateData,
                userSearchValue: userSearchValue,
                isChecked: 'All Dates',
                searchLink: SearchOption.Default
            });
        }
    }, [userSearchValue]);

    useEffect(() => {
        if (isSuccessSearch) {
            setSearchResult(searchResultData?.data);

            if (searchResultData && searchResultData.data.SearchResult.Items) {
                setListData(searchResultData.data.SearchResult.Items);
                let tagsApi = [...searchResultData.data.SearchResult.Items];
                let tagsArray = new Set();

                tagsApi.map((item: any) => {
                    if (item.Document.keyphrases) {
                        item.Document?.keyphrases?.map((keyValue: string) => {
                            tagsArray.add(keyValue);

                            return keyValue;
                        });
                    }
                    return item;
                });

                setTags(Array.from(tagsArray));
            }

            if (searchResultData && searchResultData.data && searchLink === SearchOption.Default) {
                setHelpRequestCount(
                    searchResultData.data.SearchPaginatedDetails.find(
                        (res: { SearchOption: SearchOption }) => res.SearchOption === SearchOption.HelpRequest
                    )?.TotalCount || 0
                );
                setArticlesCount(
                    searchResultData.data.SearchPaginatedDetails.find(
                        (res: { SearchOption: SearchOption }) => res.SearchOption === SearchOption.Article
                    )?.TotalCount || 0
                );
            }

            if (searchResultData && searchResultData.data.DateRangeFacetCount) {
                setDateRangeFacetCount(searchResultData.data.DateRangeFacetCount);
            }
            if (searchResultData && searchResultData.data)
                setSearchPaginatedDetails(searchResultData.data.SearchPaginatedDetails);
        }
    }, [isSuccessSearch]);

    useEffect(() => {
        setTotalCount(helpRequestCount + articlesCount || 0);

        return () => {
            setTotalCount(0);
        };
    }, [articlesCount, helpRequestCount]);

    useEffect(() => {
        setSearchValueClear(false);
    }, []);

    const [dateRangeFacetCount, setDateRangeFacetCount] = useState<any>({});

    const onChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
            let filterValue = option.key;
            if (userSearchValue.trim()) {
                setQueryParam(history, '/search-results', { page: 1 }, false, {
                    ...stateData,
                    isChecked: filterValue,
                    searchLink: SearchOption.Default
                });
            }
        },
        [searchLink, userSearchValue, searchData]
    );

    const renderLabelWithLink = (data: string, val: number) => {
        return (
            <span className="label-text">
                <span>
                    {' '}
                    <Trans>{data}</Trans>{' '}
                </span>{' '}
                <span className="number-count">{val}</span>
            </span>
        );
    };

    //clear filters
    const clearAll = () => {
        if (userSearchValue.trim()) {
            setQueryParam(history, '/search-results', { page: 1 }, true, {
                ...stateData,
                isChecked: 'All Dates',
                searchLink: SearchOption.Default
            });
        }
    };

    //on tag click
    const tagClick = (search: string) => {
        // setUserSearchValue(search);
        setQueryParam(history, '/search-results', { page: 1 }, false, {
            ...stateData,
            userSearchValue: search,
            isChecked: 'All Dates',
            searchLink: SearchOption.Default
        });
    };

    useEffect(() => {
        if (searchResultData?.data?.DateRangeFacetCount) setAllDatesCount(searchResultData?.data?.SearchResult?.TotalCount || 0);
    }, [searchResultData]);

    const options: IChoiceGroupOption[] = [
        {
            key: 'All Dates',
            text: translate('All Dates'),
            onRenderLabel: () => renderLabelWithLink('All Dates', allDatesCount)
        },
        { key: 'Today', text: 'Today', onRenderLabel: () => renderLabelWithLink('Today', dateRangeFacetCount.Today) },
        {
            key: 'Past Week',
            text: 'Past Week',
            onRenderLabel: () => renderLabelWithLink('Past Week', dateRangeFacetCount.Lastweek)
        },
        {
            key: 'Past Month',
            text: 'Past Month',
            onRenderLabel: () => renderLabelWithLink('Past Month', dateRangeFacetCount.LastMonth)
        },
        {
            key: 'Past 6 Month',
            text: 'Past 6 Month',
            onRenderLabel: () => renderLabelWithLink('Past 6 Month', dateRangeFacetCount.LastSixMonth)
        }
    ];

    const stateData = useMemo(() => {
        return {
            userSearchValue: userSearchValue,
            isChecked: isChecked,
            searchLink: searchLink,
            searchPaginatedDetails: searchPaginatedDetails
        };
    }, [userSearchValue, isChecked, searchLink, searchPaginatedDetails]);

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData) {
            setUserSearchValue(locationData?.userSearchValue);
            setIsChecked(locationData?.isChecked);
            setSearchLink(locationData?.searchLink);
            setSearchPaginatedDetails(locationData?.searchPaginatedDetails);
            setPageIndex(Number(getQueryParam(history).page ?? 1));
        } else {
        }
    }, [history?.location?.search, location?.state]);

    return (
        <div className="search-results-container">
            <div className="left-sidebar">
                <div className="left-sidebar-container">
                    {tags.length > 0 && (
                        <div className="chips-container">
                            {tags.slice(0, 10).map((tag: any, i: number) => (
                                <Text
                                    key={i}
                                    className="chips"
                                    tabIndex={0}
                                    onClick={() => tagClick(tag)}
                                    onKeyPress={(e) => keyPress(e, () => tagClick(tag))}
                                    data-testid="clearall-test">
                                    <span className="chip chip-item" key={tag.Id}>
                                        {tag}
                                    </span>
                                </Text>
                            ))}
                        </div>
                    )}

                    <Text>
                        <h4>
                            <span>
                                <Trans>Filter Results</Trans>
                            </span>{' '}
                            <Link
                                className="clear-all-btn"
                                role="link"
                                tabIndex={0}
                                onClick={() => clearAll()}
                                data-testid="clear-all-link">
                                <Trans>Clear All</Trans>
                            </Link>
                        </h4>
                    </Text>
                    <div className="left-sidebar-filters">
                        <Text>
                            <h5>
                                <Trans>Last Updated</Trans>
                            </h5>
                        </Text>
                        <Stack tokens={stackTokens}>
                            <ChoiceGroup selectedKey={isChecked} options={options} onChange={onChange} />
                        </Stack>
                    </div>
                </div>
            </div>

            <div className="right-sidebar">
                <div className="right-sidebar-container">
                    <div className="top-controls">
                        <div className="tab-buttons">
                            <div className="buttonsContainer">
                                <button
                                    tabIndex={0}
                                    className={`btn ${searchLink === SearchOption.Default ? 'active' : ''}`}
                                    onClick={(e: any) => {
                                        if (userSearchValue.trim()) {
                                            setQueryParam(history, '/search-results', { page: 1 }, false, {
                                                ...stateData,
                                                searchLink: SearchOption.Default
                                            });
                                        }
                                    }}
                                    data-testid="all-button"
                                    role="link"
                                    aria-label={`All ${totalCount}  ${searchLink === SearchOption.Default ? 'selected' : ''} `}>
                                    <Trans>All</Trans> {totalCount >= 0 && `(${totalCount})`}
                                </button>
                                <button
                                    className={`btn ${searchLink === SearchOption.Article ? 'active' : ''}`}
                                    onClick={(e: any) => {
                                        if (userSearchValue.trim()) {
                                            setQueryParam(history, '/search-results', { page: 1 }, false, {
                                                ...stateData,
                                                searchLink: SearchOption.Article
                                            });
                                        }
                                    }}
                                    role="link"
                                    aria-label={`Articles ${articlesCount}  ${
                                        searchLink === SearchOption.Article ? 'selected' : ''
                                    } `}>
                                    <Trans>Articles</Trans> {articlesCount >= 0 && `(${articlesCount})`}
                                </button>
                                <button
                                    className={`btn ${searchLink === SearchOption.HelpRequest ? 'active' : ''}`}
                                    onClick={(e: any) => {
                                        if (userSearchValue.trim()) {
                                            setQueryParam(history, '/search-results', { page: 1 }, false, {
                                                ...stateData,
                                                searchLink: SearchOption.HelpRequest
                                            });
                                        }
                                    }}
                                    role="link"
                                    aria-label={`Help requests ${helpRequestCount}  ${
                                        searchLink === SearchOption.HelpRequest ? 'selected' : ''
                                    } `}>
                                    <Trans>Requests</Trans> {helpRequestCount >= 0 && `(${helpRequestCount})`}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="search-list-container" id="search-list-container">
                        {isSuccessSearch && !isSearching && listData.length >= 0 && (
                            <div className="scroll-panel " id="scroll-panel">
                                {isSuccessSearch && listData.length > 0 && (
                                    <>
                                        {listData.map((item: any, index: number) => (
                                            <div key={index}>
                                                <SearchResultList listData={item} key={item.Document.Id} />
                                            </div>
                                        ))}
                                    </>
                                )}

                                {isSuccessSearch && listData.length === 0 && (
                                    <p>
                                        <Trans>No data to display</Trans>
                                    </p>
                                )}
                            </div>
                        )}
                        {!isSuccessSearch && !isSearching && (
                            <p>
                                <Trans>No data to display</Trans>
                            </p>
                        )}
                        {isSearching && (
                            <Stack
                                horizontalAlign="center"
                                {...rowProps}
                                tokens={tokens.spinnerStack}
                                className="m-t-50 m-b-50 height-70">
                                <Spinner size={SpinnerSize.large} label="Loading..." />
                            </Stack>
                        )}
                    </div>

                    {listData && listData.length > 0 && (
                        <div className="pagination-container">
                            <Pagination
                                pageCount={searchResultData?.data.SearchResult.TotalPages}
                                setPageNumber={setPageIndex}
                                initialPage={pageIndex - 1}
                                totalCount={searchResultData?.data.SearchResult.TotalCount}
                                resultsPerPage={displayPageSize}
                                updateURL={true}
                                stateData={stateData}
                            />
                        </div>
                    )}

                    <div className="search-need-container">
                        <YouNeed />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchResults;
