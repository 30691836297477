import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, Link, IBreadcrumbItem, Image } from '@fluentui/react';
import './index.scss';
import ListPanel from './listPanel';
import DetailsPanel from '../detailsPanel/DetailsPanel';
import SelectableCards, { CardItem } from '../../../components/selectableCards/SelectableCards';
import BreadcrumbPanel from '../../../components/breadcrumbPanel/BreadcrumbPanel';
import AddCustomerModal from './addCustomer';
import { unifiedDashboardCardsList, filterConstants } from 'app/models/common/enumerators';
import { IRequest } from 'app/models/common/response';
import { formatCurrency, getQueryParam, getWeekMonthYearRange, setQueryParam } from 'app/components/helpers';
import { useRequestList, useRequestTypesLookUpData, useTeamLookUpData, useSummaryCardsCountForUser, useRequestListBuilder } from 'app/services/queries';
import filterIcon from 'app/static/icons/icon_filter.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHeightListener } from 'app/components/heightListenerHook/HeightListener';
import { useHistory, useLocation } from 'react-router-dom';
import allRequestIcon from 'app/static/icons/ud_all_request.svg';
import myRequestIcon from 'app/static/icons/ud_my_request.svg';
import bookmarkIcon from 'app/static/icons/ud_bookmark.svg';
import sharedIcon from 'app/static/icons/ud_shared.svg';
import { AppQueryClient } from 'app/services/clients/query-client';
import Pagination from 'app/components/pagination/Pagination';
import RequestTypeFilter from 'app/modules/admin/manageRequests/filterPanel/requestTypeFilter/RequestTypeFilter';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';
import { IRequestParams } from 'app/models/common/request';
import { getPortalValue } from 'app/utils/utilities';
import _ from 'lodash';
import moment from 'moment';
import { RouteConstants, StringConstants } from 'app/utils/constants';
import useStore from 'app/store';

const pageLength = 30;

/**
 * This needed to be fetched from API. Temporary variable
 */
const statusTypes = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Active' },
    { id: 3, name: 'In Progress' },
    { id: 4, name: 'Resolved' },
    { id: 5, name: 'Closed' }
];

const UnifiedDashboardView = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const portal = getPortalValue(history?.location?.pathname);

    const breadCrumbItems: IBreadcrumbItem[] = [
        {
            text: translate('Home'),
            key: '1',
            onClick: () => history.push('/')
        },
        { text: translate('Unified Dashboard'), key: '2', isCurrentItem: true, onClick: () => {} }
    ];

    const listBuilderFilters = useStore((state: any) => state.listBuilderFilters)
    const setListBuilderFilters = useStore((state: any) => state.setListBuilderFilters);
    const setPreviousLocation = useStore((state: any) => state.setPreviousLocation);
    const previousLocation = useStore((state: any) => state.previousLocation);
    
    // state variables are declared here
    const [activeFilterLink, setActiveFilterLink] = useState(filterConstants.NEW_REQUEST);
    const [selectedRequest, setSelectedRequest] = useState<IRequest>({});
    const [requestFilter, setRequestFilter] = useState({});
    const [criteria, setCriteria] = useState<IRequestParams>();
    const [dateFilterData, setDateFilterData] = useState<any>({});
    const [pageNumber, setPageNumber] = useState(Number(getQueryParam(history).page) || 1);
    const [requestData, setRequestData] = useState<IRequest[]>([]);
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
    const [countUrl, setCountUrl] = useState('');
    const [cardItems, setCardItems] = useState<CardItem[]>();
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [cardTypeFilter, setCardTypeFilter] = useState(1);
    const [selectedKeys, setSelectedKeys] = useState<IOptionProps[]>([]);
    const [tempSelectedKeys, setTempSelectedKeys] = useState<IOptionProps[]>([]);
    const [pageSize] = useState(pageLength);
    const [totalPage, setTotalPage] = useState(0);

    const resetData = () => {
        // setPageNumber(1);

        setRequestData([]);

        setSelectedRequest({});
    };
    // helper functions
    const handleActiveFilterLink = (value: filterConstants) => {
        if (activeFilterLink !== value) {
            /*  let filterData: any = {
                from: dateFilterData.from,
                to: dateFilterData.to,
                pageNumber: 1,
                pageSize: pageSize,
                type: cardTypeFilter
            };
            switch (value) {
                case filterConstants.ALL_REQUEST:
                    filterData.caseStatus = [1, 2, 3, 4, 5];
                    break;
                case filterConstants.NEW_REQUEST:
                    filterData.caseStatus = [1];
                    break;
                case filterConstants.ACTIVE_REQUEST:
                    filterData.caseStatus = [2, 3];
                    break;
                case filterConstants.RESOLVED_REQUEST:
                    filterData.caseStatus = [4];
                    break;
                case filterConstants.CLOSED_REQUEST:
                    filterData.caseStatus = [5];
                    break;
                default:
                    break;
            } */
            // setActiveFilterLink(value);
            // resetData();
            setListBuilderFilters(null);
            setPreviousLocation('');

            setQueryParam(history, RouteConstants.UNIFIED_DASHBOARD, { page: 1 }, false, {
                ...stateData,
                activeFilterLink: value
            });
            // setRequestFilter((value) => ({ ...value, ...filterData, pageSize: pageLength }));
        }
    };

    const setInitialFilter = () => {
        let dateRangeData: any = getWeekMonthYearRange(StringConstants.WEEK);
        let filterData = {
            from: dateRangeData.from,
            to: dateRangeData.to,
            pageNumber: 1,
            pageSize: pageSize,
            caseStatus: [1],
            hideParentRequests: true,
            portal: portal
        };
        let countUrl = `DateRange.From=${dateRangeData.from}&DateRange.To=${dateRangeData.to}&HideParentRequests=${true}`;
        setCountUrl(countUrl);
        setDateFilterData(dateRangeData);
        setRequestFilter((value) => ({ ...value, ...filterData }));
    };

    const refetchPageData = () => {
        AppQueryClient.invalidateQueries('useRequestList');
    };

    // queries
    const { data: apiRequestData, isLoading: isLoadingRequestList } = useRequestList(criteria);
    const { data: requestTypes, isLoading: isRequestTypesLoading } = useRequestTypesLookUpData();
    const { data: teams, isLoading: isTeamsLoading } = useTeamLookUpData();
    const { data: countData, isLoading: isCountLoading, refetch: getSummaryCards } = useSummaryCardsCountForUser(countUrl);
    const { data: listBuilderResults, isLoading: isListBuilderLoading } = useRequestListBuilder({
        filter: listBuilderFilters,
        pageNumber: pageNumber
    });

    // hooks are defined here

    let { id } = useParams<any>();

    useEffect(() => {
        if (id) {
            // setCardTypeFilter(unifiedDashboardCardsList.MY_REQUEST);
            setQueryParam(history, RouteConstants.UNIFIED_DASHBOARD, { page: 1 }, true, {
                ...stateData,
                newRequestId: id,
                cardTypeFilter: unifiedDashboardCardsList.MY_REQUEST
            });
        }
    }, []);

    useEffect(() => {
        if (!isLoadingRequestList) {
            if (apiRequestData?.items && apiRequestData?.items.length > 0 && selectedRequest && Object.keys(selectedRequest).length === 0 && previousLocation !== '/manage-lists') {
                setSelectedRequest(apiRequestData.items[0]);
                // history.push(`/unified-dashboard/${apiRequestData.items[0].unifiedTrackingId}`);
            }
        }
        // if (apiRequestData?.items.length === 0) {
        //     history.push(`/unified-dashboard`);
        // }
    }, [apiRequestData, isLoadingRequestList, selectedRequest, previousLocation]);

    useEffect(() => {
        const handleWeekMonthYearFilter = (dateFilterData: any) => {
            /*  let filterData = {
                from: dateFilterData.from,
                to: dateFilterData.to,
                pageNumber: 1,
                pageSize: pageSize
            };
            setRequestFilter((value) => ({ ...value, ...filterData })); */
            const fromValue = dateFilterData.from
                ? dateFilterData.from
                : moment('2001/01/01', 'YYYY/MM/DD').startOf('day').utc().format();
            const toValue = dateFilterData.to ? dateFilterData.to : moment().endOf('day').utc().format();
            let countUrl = `DateRange.From=${fromValue}&DateRange.To=${toValue}&HideParentRequests=${true}`;
            setCountUrl(countUrl);
        };
        if (dateFilterData && Object.keys(dateFilterData).length > 0) {
            handleWeekMonthYearFilter(dateFilterData);
        }
    }, [dateFilterData]);

    useEffect(() => {
        let filterObj: {
            type?: number;
            watchedByCurrentUser?: boolean;
            filterByCurrentUser?: boolean;
            isClosedRequestExcluded?: boolean;
        } = {
            type: cardTypeFilter,
            watchedByCurrentUser: false,
            filterByCurrentUser: false,
            isClosedRequestExcluded: true
        };
        if (cardTypeFilter === unifiedDashboardCardsList.SHARED_WITH_ME) {
            filterObj.watchedByCurrentUser = true;
        }
        if (cardTypeFilter === unifiedDashboardCardsList.MY_REQUEST) {
            filterObj.filterByCurrentUser = true;
        }
        if (cardTypeFilter === unifiedDashboardCardsList.ALL_REQUEST) {
            filterObj.filterByCurrentUser = false;
            filterObj.isClosedRequestExcluded = false;
        }
        let filteredRequestTypes: number[] = [];
        if (selectedKeys?.filter((ele: IOptionProps) => ele.key === 'all_request').length === 0) {
            selectedKeys?.forEach((ele) => {
                if (ele.type) {
                    filteredRequestTypes.push(ele.type as number);
                }
            });
        }
        // resetData();
        if (dateFilterData && Object.keys(dateFilterData).length > 0) {
            setRequestFilter((value) => ({
                ...value,
                ...filterObj,
                from: dateFilterData.from
                    ? dateFilterData.from
                    : moment('2001/01/01', 'YYYY/MM/DD').startOf('day').utc().format(),
                to: dateFilterData.to ? dateFilterData.to : moment().endOf('day').utc().format(),
                requestType: filteredRequestTypes
            }));
        }
    }, [cardTypeFilter, dateFilterData, tempSelectedKeys]);

    useEffect(() => {
        if (selectedKeys?.filter((ele: IOptionProps) => ele.key === 'all_request').length !== 0 || selectedKeys?.length === 0) {
            if (tempSelectedKeys?.length !== 0) {
                setTempSelectedKeys([]);
            }
        } else {
            let flag = false;
            selectedKeys.forEach((ele) => {
                if (tempSelectedKeys?.filter((it: IOptionProps) => it.key === ele.key).length === 0) {
                    flag = true;
                }
            });
            if (flag) {
                setTempSelectedKeys(selectedKeys);
            }
        }
    }, [selectedKeys]);
    useEffect(() => {
        let filterData: any = {};
        switch (activeFilterLink) {
            case filterConstants.ALL_REQUEST:
                filterData.caseStatus = [1, 2, 3, 4, 5];
                break;
            case filterConstants.NEW_REQUEST:
                filterData.caseStatus = [1];
                break;
            case filterConstants.ACTIVE_REQUEST:
                filterData.caseStatus = [2, 3];
                break;
            case filterConstants.RESOLVED_REQUEST:
                filterData.caseStatus = [4];
                break;
            case filterConstants.CLOSED_REQUEST:
                filterData.caseStatus = [5];
                break;
            default:
                break;
        }
        setRequestFilter((value) => ({ ...value, ...filterData }));
    }, [activeFilterLink]);

    useEffect(() => {
        resetData();
        getSummaryCards();
        setRequestFilter((value) => ({ ...value, ...{ type: cardTypeFilter, isBookmarked: isBookmarked } }));
    }, [isBookmarked]);

    useEffect(() => {
        if (!isCountLoading && countData) {
            const cards: CardItem[] = [
                {
                    type: unifiedDashboardCardsList.ALL_REQUEST,
                    label: translate('All Requests'),
                    icon: <Image src={allRequestIcon} alt="all request" />,
                    value: '',
                    isSelected: cardTypeFilter === unifiedDashboardCardsList.ALL_REQUEST
                },
                {
                    type: unifiedDashboardCardsList.MY_REQUEST,
                    label: translate('My Requests'),
                    icon: <Image src={myRequestIcon} alt="my request" />,
                    value: formatCurrency(countData.myRequests) || 0,
                    isSelected: cardTypeFilter === unifiedDashboardCardsList.MY_REQUEST
                },
                {
                    type: unifiedDashboardCardsList.STARRED_REQUEST,
                    label: translate('Starred Requests'),
                    icon: <Image src={bookmarkIcon} alt="starred request" />,
                    value: formatCurrency(countData.bookmarkRequests) || 0,
                    isSelected: cardTypeFilter === unifiedDashboardCardsList.STARRED_REQUEST
                },
                {
                    type: unifiedDashboardCardsList.SHARED_WITH_ME,
                    label: translate('Shared With Me'),
                    icon: <Image src={sharedIcon} alt="shared request" />,
                    value: formatCurrency(countData.watchRequests) || 0,
                    isSelected: cardTypeFilter === unifiedDashboardCardsList.SHARED_WITH_ME
                }
            ];
            setCardItems(cards);
        }
    }, [cardTypeFilter, countData, isCountLoading, translate]);

    useEffect(() => {
        setSelectedRequest({});
        setRequestData([]);
        setCriteria({ ...requestFilter, pageSize: pageLength, pageNumber: pageNumber, portal: portal, hideParentRequests: true });
    }, [requestFilter, pageNumber]);

    // This is implementation of the hook to automatically adjust the height of the left section based on the right details section
    const [leftPanelHeight, setLeftPanelHeight] = useState(0);
    const leftPanelRef = useRef(null);
    const rightPanelRef = useRef(null);
    const height = useHeightListener(rightPanelRef);
    useEffect(() => {
        if (leftPanelRef.current && rightPanelRef.current) {
            setLeftPanelHeight(height);
        }
    }, [height]);

    useEffect(() => {
        if (apiRequestData?.totalPages) {
            setTotalPage(apiRequestData?.totalPages);
        }
    }, [apiRequestData?.totalPages]);

    const stateData = useMemo(() => {
        return {
            cardTypeFilter: cardTypeFilter,
            dateFilterData: dateFilterData,
            activeFilterLink: activeFilterLink,
            requestTypeSelection: selectedKeys
        };
    }, [cardTypeFilter, dateFilterData, activeFilterLink, selectedKeys]);

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData) {
            setDateFilterData(locationData?.dateFilterData);
            setCardTypeFilter(locationData?.cardTypeFilter);
            setActiveFilterLink(locationData?.activeFilterLink);
            setSelectedKeys(locationData?.requestTypeSelection);
            setPageNumber(Number(getQueryParam(history).page));
        } else {
            // setPageNumber(Number(getQueryParam(history).page) || 1);
            setInitialFilter();
        }
    }, [history?.location?.search, location?.state]);

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData?.newRequestId) {
            const matchedRequest = _.find(requestData, (item: any) => item.unifiedTrackingId === locationData?.newRequestId);
            if (matchedRequest) {
                setSelectedRequest(matchedRequest);
            } else {
                setSelectedRequest({})
            }
        }
    }, [requestData, location?.state]);

    useEffect(() => {
        if (previousLocation === '/manage-lists') {
            setDateFilterData(StringConstants.YEAR);
            setCardTypeFilter(unifiedDashboardCardsList.ALL_REQUEST);
            setActiveFilterLink(filterConstants.ALL_REQUEST);
            setSelectedKeys([]);
            setPageNumber(1);
        }
    }, [previousLocation])

    // clear list builder data on refresh 
    // this will be the method to clear any filters for listbuilder for now
    window.onbeforeunload = (e: any) => {
        setListBuilderFilters(null);
        setPreviousLocation('');
    };

    return (
        <div className="unified-dashboard-container">
            <div className="container">
                <BreadcrumbPanel items={breadCrumbItems} />
                <SelectableCards
                    items={cardItems}
                    dateFilterData={dateFilterData}
                    setDateFilterData={setDateFilterData}
                    setCardTypeFilter={setCardTypeFilter}
                    stateData={stateData}
                />
                <div className="details-container">
                    <div className="filter-panel" role="navigation" aria-label="filters for status and request types">
                        <div className="filter-links">
                            <Link
                                tabIndex={0}
                                className={`${activeFilterLink === filterConstants.ALL_REQUEST ? 'active' : ''}`}
                                onClick={() => handleActiveFilterLink(filterConstants.ALL_REQUEST)}
                                aria-label={`All Requests ${countData?.myRequests} ${
                                    activeFilterLink === filterConstants.ALL_REQUEST ? 'selected tab' : 'tab'
                                }`}>
                                <Trans>All Requests</Trans>
                            </Link>
                            <Link
                                tabIndex={0}
                                className={`${activeFilterLink === filterConstants.NEW_REQUEST ? 'active' : ''}`}
                                onClick={() => handleActiveFilterLink(filterConstants.NEW_REQUEST)}
                                data-testid="new-request"
                                aria-label={`New Requests ${countData?.myRequests} ${
                                    activeFilterLink === filterConstants.NEW_REQUEST ? 'selected tab' : 'tab'
                                }`}>
                                <Trans>New Requests</Trans>
                            </Link>
                            <Link
                                tabIndex={0}
                                className={`${activeFilterLink === filterConstants.ACTIVE_REQUEST ? 'active' : ''}`}
                                onClick={() => handleActiveFilterLink(filterConstants.ACTIVE_REQUEST)}
                                aria-label={`Active Requests  ${
                                    activeFilterLink === filterConstants.ACTIVE_REQUEST ? 'selected tab' : 'tab'
                                }`}>
                                <Trans>Active Requests</Trans>{' '}
                            </Link>
                            <Link
                                tabIndex={0}
                                className={`${activeFilterLink === filterConstants.RESOLVED_REQUEST ? 'active' : ''}`}
                                onClick={() => handleActiveFilterLink(filterConstants.RESOLVED_REQUEST)}
                                aria-label={`Resolved Requests ${countData?.myRequests} ${
                                    activeFilterLink === filterConstants.RESOLVED_REQUEST ? 'selected tab' : 'tab'
                                }`}>
                                <Trans>Resolved Requests</Trans>
                            </Link>
                            <Link
                                tabIndex={0}
                                className={`${activeFilterLink === filterConstants.CLOSED_REQUEST ? 'active' : ''}`}
                                onClick={() => handleActiveFilterLink(filterConstants.CLOSED_REQUEST)}>
                                <Trans>Closed Requests</Trans>{' '}
                            </Link>
                        </div>
                        <div className="filter-controls">
                            <Image src={filterIcon} className="icon" alt="Filter Icon" />
                            <Text>
                                <Trans>Filters</Trans>
                            </Text>

                            <RequestTypeFilter
                                selectedKeys={selectedKeys}
                                setSelectedKeys={setSelectedKeys}
                                origin={'END_USER'}
                                stateData={stateData}
                            />
                        </div>
                    </div>
                    <div className="ud-contents-wrap">
                        <div className="grid-col-left" style={{ height: `${leftPanelHeight}px` }} ref={leftPanelRef}>
                            {!isRequestTypesLoading && !isTeamsLoading && (
                                <ListPanel
                                    id={id}
                                    data={apiRequestData}
                                    requestData={requestData}
                                    setRequestData={setRequestData}
                                    selectedRequest={selectedRequest}
                                    setSelectedRequest={setSelectedRequest}
                                    requestTypes={requestTypes}
                                    teams={teams}
                                    setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                                    setRequestFilter={setRequestFilter}
                                    cardTypeFilter={cardTypeFilter}
                                    isLoadingRequestList={isLoadingRequestList}
                                    isListBuilderLoading={isListBuilderLoading}
                                    listBuilderResults={listBuilderResults}
                                    previousLocation={previousLocation}
                                    listBuilderFilters={listBuilderFilters}
                                />
                            )}
                        </div>
                        <div className="grid-col-right" ref={rightPanelRef}>
                            {!isRequestTypesLoading && !isTeamsLoading && (
                                <DetailsPanel
                                    requestData={requestData}
                                    selectedRequest={selectedRequest}
                                    requestTypes={requestTypes}
                                    teams={teams}
                                    statusTypes={statusTypes}
                                    setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                                    cardTypeFilter={cardTypeFilter}
                                    setIsBookmarked={setIsBookmarked}
                                    isBookMarked={isBookmarked}
                                    resetData={resetData}
                                    refetchPageData={refetchPageData}
                                    isLoadingRequestList={isLoadingRequestList}
                                    setSelectedRequest={setSelectedRequest}
                                />
                            )}
                        </div>
                    </div>
                    {(pageNumber !== 1 || (!isLoadingRequestList && pageNumber === 1)) && requestData.length !== 0 && (
                        <div className="pagination-wrapper">
                            <Pagination
                                pageCount={totalPage}
                                setPageNumber={setPageNumber}
                                initialPage={pageNumber - 1}
                                totalCount={apiRequestData?.totalCount}
                                resultsPerPage={pageLength}
                                updateURL={true}
                                stateData={stateData}
                            />
                        </div>
                    )}
                </div>
            </div>
            <AddCustomerModal
                modalState={isAddCustomerModalOpen}
                updateModalState={(value: boolean) => setIsAddCustomerModalOpen(value)}
            />
        </div>
    );
};

export default UnifiedDashboardView;
