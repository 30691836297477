import React from 'react';

import commentIcon from 'app/static/icons/comment-icon.svg';

import { Trans } from 'react-i18next';
import moment from 'moment';

function VisualFlowStep(props: any) {
    return (
        <div className={`step step-${props.id} ${props.status}`} style={{ minWidth: props.width }}>
            <div className="step-details">
                <div className="number">{props.id}</div>
                <div
                    className="details"
                    onMouseEnter={(e) => props.triggerShowComments(e, props.id)}
                    onMouseLeave={(e) => props.hideComments(e, props.id)}>
                    <div className="title">{props.title}</div>

                    <div className="last-activity">
                        <Trans>Last Activity</Trans>: {moment(props.lastActivity).format('hh:mm a')}
                    </div>
                    <div className="custom-row">
                        <div className="messages">
                            <img className="comment-icon" src={commentIcon} alt="comment" />
                            {props.commentCount}
                        </div>
                    </div>
                </div>
            </div>
            <div className="verticle-dashed-line"></div>
            <div className="step-icon">
                <div className="step-icon__border">
                    <div className="step-icon__bg">{props.getIcon(props.icon)}</div>
                </div>
            </div>
            <div className="step-date-aging">
                <div className="date">{moment(props.date).format('MMM DD yyyy')}</div>
                <div className={`badge--status ${props.status}`}>{props.age}</div>
            </div>
            <span className="arrow"></span>
        </div>
    );
}

export default VisualFlowStep;
