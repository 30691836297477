import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Text, Link, IconButton, MessageBar, MessageBarType } from '@fluentui/react';
import useStore from 'app/store';
import { addItemsToList, getIcon, keyPress, removeSingleItemFromList } from 'app/components/helpers';
import fileAttachIcon from 'app/static/icons/file-attach.svg';
import { API, Files } from 'app/utils/constants';
import { Trans } from 'react-i18next';
import { IAttachmentFileInfo, IBlobError, IBlobUploadFile } from 'app/models/common/post';
import { createBlobName } from 'app/utils/blobStorageHelpers';

interface IFileProps {
    requestType: string;
    attachmentList: Array<Object>;
    setAttachmentList: Function;
    animateAttachments: string;
    readyToUploadFiles: boolean;
    setReadyToUploadFiles: Function;
    addToBlobUploadFiles: Function;
    uploadErrors: Array<IBlobError>;
    setUploadErrors: Function;
}

export const AddAttachments: React.FC<IFileProps> = ({ requestType, attachmentList, setAttachmentList, animateAttachments, addToBlobUploadFiles, setReadyToUploadFiles, uploadErrors, setUploadErrors }) => {
    // component state
    const attachedFiles = useStore((state: any) => state.attachedFiles);
    const addFiles = useStore((state: any) => state.addFiles);
    const [isError, setIsError] = useState(false);
    let componentUploadErrors: Array<IBlobError> = [];

    // helpers
    const handleDrop = (acceptedFiles: any) => {
        setUploadErrors([]);
        setIsError(false);
        componentUploadErrors = [];

        let fileNames = [...attachedFiles];
        let attachments = [...attachmentList];
        let blobFiles: Array<IBlobUploadFile> = [];
        let forAddFiles: IAttachmentFileInfo[] = [];
        acceptedFiles.forEach((file: any) => {
            if (Files.AllowedContentTypes.includes(file.type.toLowerCase()) && Files.AllowedFileExtensions.includes(file.name.split('.')?.pop().toLowerCase())) {
                if (attachedFiles.filter((item: any) => item.name === file.name).length === 0) {
                    const blobName = createBlobName(file.name);
                    const blobUrl = `${API.API_FILES_URL}` + blobName;
                    fileNames.push({
                        name: file.name,
                        size: file.size,
                        url: blobUrl,
                        blobName: blobName,
                        isExternal: true
                    });
                    forAddFiles.push({ name: file.name, size: file.size, blobName: blobName });
                    blobFiles.push({ blobName: blobName, file: file });
                    attachments.push({ url: blobUrl, type: file.type, isExternal: true, blobName: blobName });
                }
            }
            else {
                componentUploadErrors.push({ fileName: file.name, error: 'Check file type and extension', blobName: undefined })
                setIsError(true);
            }
        });

        addFiles(addItemsToList(attachedFiles, forAddFiles));
        setAttachmentList(attachments);
        setUploadErrors(componentUploadErrors);

        addToBlobUploadFiles(blobFiles);
    };

    const clearErrorMessage = (i: any) => {
        setUploadErrors(removeSingleItemFromList(uploadErrors, i));
    }

    const handleRemove = (i: any) => {
        const list = [...attachedFiles];
        addFiles(removeSingleItemFromList(list, i));

        const urlList = [...attachmentList];
        setAttachmentList(removeSingleItemFromList(urlList, i));

        setIsError(false);
    };
    useEffect(() => {
        // change upload button to next if no attachments
        attachedFiles.length < 1 ? setReadyToUploadFiles(false) : setReadyToUploadFiles(true);
    }, [attachedFiles])

    return (
        <div>
            <div
                className={`attachment-slide ${animateAttachments === 'S_TO_PREV' ? 'prevSlide' : ''} ${
                    animateAttachments === 'S_TO_NEXT' ? 'nextSlide' : ''
                } ${animateAttachments === 'A_TO_LEFT' ? 'animateToLeft' : ''} ${
                    animateAttachments === 'A_TO_RIGHT' ? 'animateToRight' : ''
                }`}>
                {requestType === 'PreSales' ||
                requestType === 'Rfx' ||
                requestType === 'Proposal' ||
                requestType === 'Question' ? (
                    <h3>
                        <Trans>Please upload supporting documents (if any)</Trans>
                    </h3>
                ) : (
                    <h3>
                        <Trans>Please upload the proof of purchase (with respective serial number)</Trans>
                    </h3>
                )}
                {isError && (
                    <div className="m-l-10 m-r-10 m-b-10 m-t-10">
                        { uploadErrors?.map(err => {
                            return (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    messageBarIconProps={{ iconName: 'Error' }}
                                    dismissButtonAriaLabel="Close"
                                    isMultiline={false}
                                    onDismiss={clearErrorMessage}>
                                        { err.fileName } : { err.error }
                                </MessageBar>
                            )
                        })}
                    </div>
                )}
                {attachedFiles.length === 0 && (
                    <div>
                        <div className="file-drop">
                            <Dropzone noClick onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} data-testid="noclick-drop" />
                                        <div className="text-cont">
                                            <Text className="txt-1">
                                                <Trans>Drag & Drop Files Here</Trans>
                                            </Text>
                                            <img className="file-attach-icon" src={fileAttachIcon} alt="drag drop" />
                                            <Text className="txt-1 m-b-25">(or)</Text>
                                            <Dropzone onDrop={handleDrop}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                        {...getRootProps({ className: 'dropzone-btn' })}
                                                        aria-label="Upload"
                                                        role="button">
                                                        <input {...getInputProps()} data-testid="uploadclick-drop" />
                                                        <Text className="addfile__button">
                                                            <Trans>Upload</Trans>
                                                        </Text>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                )}
                {attachedFiles.length !== 0 && (
                    <div className="attachment-list-container">
                        <div className="btn-attachments">
                            <Dropzone noDrag onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p>
                                            <Link data-testid="addMoreFilesLink">
                                                <Trans>Add more files</Trans>
                                            </Link>
                                        </p>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        {attachedFiles.map((item: any, i: any) => {
                            return (
                                <div key={i} className="attachment-list attached-file">
                                    <span className={getIcon(item.name)}></span>
                                    <span className="file-txt">{item.name}</span>
                                    <IconButton
                                        role="button"
                                        aria-label="close"
                                        tabIndex={0}
                                        onKeyPress={(e: any) => keyPress(e, () => handleRemove(i))}
                                        onClick={(event) => handleRemove(i)}
                                        iconProps={{ iconName: 'Cancel' }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
