export enum ListBuilderCondition {
    Equals = 1,
    DoesNotEqual,
    Contains,
    DoesNotContain,
    GreaterThan,
    LessThan,
    OlderThan,
    YoungerThan,
    Before,
    After,
    On,
    State
}