import React from 'react';
import { Text } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { changeDateFormat, getParagraphFromContent } from 'app/components/helpers';

import './index.scss';
interface ISearchProps {
    listData: any;
}

const SearchSuggestion: React.FC<ISearchProps> = (props) => {
    const { listData } = props;

    return (
        <>
            <div className="search-results-links">
                <Text>
                    <Link role="link" to={`/news-feed/article/${listData.Id}`}>
                        {listData.Title}
                    </Link>
                </Text>

                <p
                    className="search-results-description"
                    dangerouslySetInnerHTML={{
                        __html: getParagraphFromContent(listData.Content)
                    }}></p>

                <div className="tags-container">
                    <Text>
                        <span className="result-date"> {changeDateFormat(listData.CreatedOn, 'MMMM DD, YYYY')}</span>
                    </Text>
                    {listData.Tags[0] && (
                        <div>
                            {listData.Tags.map((item: any) => (
                                <span className="result-type" aria-label={item?.DisplayName} tabIndex={0}>
                                    <span className="type-icon"></span> {item?.DisplayName}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SearchSuggestion;
