import { Alignment, IStackProps, Spinner, SpinnerLabelPosition, SpinnerSize, Stack } from '@fluentui/react';
import React from 'react';
import './Loader.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    size?: SpinnerSize;
    rowProps?: IStackProps;
    tokens?: any;
    horizontalAlign?: Alignment;
    classNames?: string;
    loadingText?: string;
    labelPosition?: SpinnerLabelPosition;
}

export const Loader = (props: IProps) => {
    const {
        size = SpinnerSize.large,
        rowProps: rp,
        tokens: t,
        horizontalAlign = 'center',
        classNames = '',
        loadingText = 'Loading...',
        labelPosition = 'bottom'
    } = props;
    const rowProps: IStackProps = rp ? rp : { horizontal: true, verticalAlign: 'center' };
    const tokens = t
        ? t
        : {
              sectionStack: {
                  childrenGap: 10
              },
              spinnerStack: {
                  childrenGap: 20
              }
          };
    const { t: translate } = useTranslation();
    return (
        <Stack horizontalAlign={horizontalAlign} {...rowProps} tokens={tokens.spinnerStack} className={classNames}>
            <Spinner size={size} label={translate(loadingText)} labelPosition={labelPosition} />
        </Stack>
    );
};
