import { Icon, Link, PrimaryButton, Text, TextField } from '@fluentui/react';
import { StringConstants } from 'app/utils/constants';
import React from 'react';
import NumberFormat from 'react-number-format';

interface IProps {
    emailID: string;
    setEmailValidText: any;
    setEmailID: any;
    emailValidText: any;
    mobileNumber: any;
    setMobNumValidText: any;
    setMobileNumber: any;
    mobNumValidText: any;
    shippingAdd: any;
    setShippingAdd: any;
    fieldValidation: any;
    selectedModel: any;
    hideModal: any;
    goBackToInitial: Function;
    setInitialState: Function;
    setAnimateStep: Function;
    setAnimateStepReverse: Function;
    setFullReverse: Function;
    setCartState: Function;
    custAltPage: boolean;
    isCreatingRequest: boolean;
    setcustAltPage: Function;
    countryList: Array<any>;
    mobilePrefix: number;
    setMobilePrefix: Function;
}

const AlternateContactDetails: React.FC<IProps> = (props) => {
    const {
        emailID,
        setEmailValidText,
        setEmailID,
        emailValidText,
        mobileNumber,
        setMobNumValidText,
        setMobileNumber,
        mobNumValidText,
        shippingAdd,
        setShippingAdd,
        fieldValidation,
        selectedModel,
        hideModal,
        custAltPage,
        isCreatingRequest,
        setcustAltPage,
        mobilePrefix,
        setMobilePrefix
    } = props;

    const prepareOptions = () => {
        let options: Array<any> = [
            <option value={null} selected disabled hidden>
                Select the country
            </option>
        ];
        if (props.countryList) {
            props.countryList.forEach((item) => {
                options.push(
                    <option key={item.id} value={item.dialingCode}>
                        {item.name}
                        {' - +'}
                        {item.dialingCode}
                    </option>
                );
            });
        }
        return options;
    };

    return (
        <div className="alternative-contact-modal">
            <div className="head row-flex">
                <Text className="title model-title">Add alternate contact details</Text>
                {custAltPage && !isCreatingRequest && (
                    <Link
                        tabIndex={0}
                        onClick={() => {
                            if (custAltPage) {
                                hideModal();
                                setMobilePrefix(null);
                                setMobileNumber('');
                                setShippingAdd('');
                                setEmailID('');
                                setEmailValidText(false);
                                setMobNumValidText(false);
                                setTimeout(() => {
                                    setcustAltPage(false);
                                }, 1000);
                            }
                        }}
                        className="close-btn alternate-details-close-btn"
                        aria-label="close"
                        role="button"
                        data-testid="close-btn-test">
                        <Icon iconName="Cancel" aria-label={'cancel-button'} />
                    </Link>
                )}
            </div>
            <div className="form-control">
                <div className="form-row form-inline">
                    <div>
                        <span>
                            <label>Email Address</label>
                            <TextField
                                value={emailID}
                                onChange={(e: any) => {
                                    setEmailValidText(false);
                                    setEmailID(e.target.value);
                                }}
                                placeholder="e.g: john@abc.com"
                                className={`regular-text-field ${emailValidText ? 'error-feild' : ''}`}
                            />
                            {emailValidText && <Text className="helper-txt">Enter a valid email ID.</Text>}
                        </span>
                    </div>
                    <div>
                        <span className="shift-rt">
                            <label>Mobile Number</label>
                        </span>
                        <div className="custom-row alt-contact-num-cont">
                            <div className="alt-contact-select-cont">
                                <select
                                    value={mobilePrefix}
                                    onChange={(e: any) => {
                                        setMobilePrefix(e.target.value);
                                        if (mobileNumber) setMobNumValidText(false);
                                    }}
                                    data-testid="select-mobile-num-test"
                                    aria-label="Enter customer mobile number"
                                    className={`format-field-cont alt-contact-select w-140 ${
                                        mobNumValidText ? 'error-feild' : ''
                                    } ${mobNumValidText ? 'error-feild' : ''}`}>
                                    {prepareOptions()}
                                </select>
                            </div>
                            <div
                                className={`alt-contact-num-cont format-field-cont ${
                                    mobNumValidText ? 'error-feild' : ''
                                } left-space`}>
                                <NumberFormat
                                    mask=" "
                                    className="regular-text-field format-field alt-contact-num"
                                    value={mobileNumber}
                                    onChange={(e: any) => {
                                        setMobileNumber(e.target.value);
                                        if (mobilePrefix) setMobNumValidText(false);
                                    }}
                                    placeholder="e.g:(111) 111-1111"
                                    allowNegative={false}
                                />
                            </div>
                        </div>
                        {mobNumValidText && <Text className="helper-txt">Enter a valid mobile number.</Text>}
                    </div>
                </div>
                <div className="form-row">
                    <div>
                        <label>Shipping Address</label>
                        <TextField
                            value={shippingAdd}
                            onChange={(e: any) => setShippingAdd(e.target.value)}
                            placeholder="e.g: 866 Summit Park Avenue, Southfield, Michigan,United States(48075)"
                            className="desc shipping-add"
                            multiline
                            autoAdjustHeight
                            resizable={false}
                            rows={8}
                        />
                    </div>
                </div>

                <div className="form-row">
                    <PrimaryButton
                        className="alt-contact-update-btn"
                        text="Update"
                        onClick={() => {
                            if (
                                (emailID !== '' && !fieldValidation(emailID, StringConstants.EMAIL, selectedModel)) ||
                                (mobileNumber !== '' && !fieldValidation(mobileNumber, StringConstants.MOBILE_NUMBER, selectedModel)) ||
                                !mobilePrefix
                            ) {
                                setMobNumValidText(
                                    mobileNumber !== '' &&
                                        (!fieldValidation(mobileNumber, StringConstants.MOBILE_NUMBER, selectedModel) || !mobilePrefix)
                                        ? true
                                        : false
                                );
                                setEmailValidText(
                                    emailID !== '' && !fieldValidation(emailID, StringConstants.EMAIL, selectedModel) ? true : false
                                );
                            } else {
                                hideModal();
                            }
                        }}
                        disabled={emailID === '' || mobileNumber === '' || !mobilePrefix ? true : false}
                    />
                </div>
            </div>
        </div>
    );
};

export default AlternateContactDetails;
