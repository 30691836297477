import React, { useRef, useState } from 'react';
import { Text, TooltipHost, Image } from '@fluentui/react';
import InfoIcon from 'app/static/icons/tooltip-info-icon.svg';
import { useId } from '@fluentui/react-hooks';
import './index.scss';
import { EditorState } from 'draft-js';
import useStore from 'app/store';
import RequestRichTextEditor from 'app/modules/admin/detailsPanel/requestRichTextEditor/RequestRichTextEditor';
import { BlobStorageContainer, StringConstants } from 'app/utils/constants';
interface IProps {
    value: string;
    helperText: boolean;
    setHelperText: Function;
    type: string;
    rows: number;
    editorState: EditorState;
    tempValue: string;
    setEditorState: (e: EditorState) => void;
    hashTagsFormatted: any[];
    setHashTagsFormatted: (hashTagsFormatted: any[]) => void;
    setTextToSubmit?: (textToSubmit: string) => void;
}

export const CustomField: React.FC<IProps> = ({ value, helperText, type, hashTagsFormatted, setHashTagsFormatted, setTextToSubmit }) => {
    const [onFocusField, setFocusField] = useState(false);

    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    const fieldRef = useRef(null);
    const textAreaRef = useRef(null);
    const quickLinksValue = useStore((state: any) => state.quickLinksValue);
    const redirectRequestType = useStore((state: any) => state.redirectRequestType);
    const redirectSubType = useStore((state: any) => state.redirectSubType);

    useTextBoxDismiss(fieldRef, setFocusField, textAreaRef);

    const getToolTipContent = () => {
        if (type === StringConstants.DESCRIPTION) {
            if (!quickLinksValue && redirectRequestType === '') {
                return 'Please provide detailed insight into why this is important. Describe the request in as much detail as possible.';
            } else if (quickLinksValue === 1) {
                return 'Please provide insight into the pre-sales question and include as much detail as possible.';
            } else if (redirectRequestType === StringConstants.POST_SALES) {
                return 'Please provide detailed insight into the specific reason and actions needed to support your warranty request.';
            } else if (redirectRequestType === StringConstants.REVIEW_REQUEST) {
                return 'What concerns has the customer communicated to you about the progress in this support case and what is the action to be taken?';
            } else if (redirectRequestType === StringConstants.IRT && redirectSubType === 9) {
                return 'Provide qualitative insight in to why this is important. Use words to describe how big the market opportunity is for this request. ';
            } else if (redirectRequestType === StringConstants.IRT && redirectSubType === 10) {
                return 'Please provide detailed insight into what answer the customer is looking to get and why. Describe the request in as much detail as possible.';
            }
        } else {
            return 'This is not a guarantee that we can accommodate this delivery date. You might share that the customer will make a purchase decision on the last day of the month and needs resolution before that time. ';
        }
    };

    return (
        <div
            className={`custom-textfield ${helperText ? 'error-field' : ''}`}
            onBlur={() => {
                setFocusField(false);
            }}
            onFocus={() => {
                setFocusField(true);
            }}
            onMouseDown={() => {
                setFocusField(true);
            }}>
            <RequestRichTextEditor
                content={value}
                isMentionAvailable={false}
                hashTagsFormatted={hashTagsFormatted}
                setHashTagsFormatted={setHashTagsFormatted}
                isCommentField={true}
                isRequestHelpField={true}
                setTextToSubmit={setTextToSubmit}
                fileStorageContainer={BlobStorageContainer.REQUEST_DETAIL_FILES}
            />
            <div className={`description-box ${onFocusField ? 'add-bg' : ''}`}>
                <div className="description-text">
                    {type === StringConstants.DESCRIPTION && (
                        <>
                            <Text>
                                What does your customer need? How is this issue impacting their business? Please be as specific as
                                possible so we have enough information to take action on your behalf. Learn more about{' '}
                                <Text className="info-text">Request Details</Text>
                                <TooltipHost id={tooltipId} calloutProps={calloutProps} content={getToolTipContent()}>
                                    <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                                </TooltipHost>
                            </Text>
                        </>
                    )}
                    {type === 'DATE_IMPORTANCE' && (
                        <>
                            <Text>
                                List any key customer deadlines, events or business decisions that influence our response
                                timeline. Learn more about <Text className="info-text">Date Importance</Text>
                                <TooltipHost id={tooltipId} calloutProps={calloutProps} content={getToolTipContent()}>
                                    <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                                </TooltipHost>
                            </Text>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const useTextBoxDismiss = (ref: any, setFocusField: Function, textAreaRef: any) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && textAreaRef.current?.innerText?.trim() === '') {
                setFocusField(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, textAreaRef]);
};