import React from 'react';
import Sidebar from './sidebar/Sidebar';
import './MyAccount.scss';
import MyDetails from './myDetails/MyDetails';

const MyAccount: React.FC = () => {
    return (
        <div className="my-account">
            <div className="container d-flex">
                <Sidebar />
                <div className="content">
                    <MyDetails />
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
