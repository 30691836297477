import { DefaultButton, Link, Text } from '@fluentui/react';
import { useCustomMessageModal } from 'app/components/customMessageModal/CustomMessageModal';
import { ICommentDto, IStatusesDto } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import {
    useEditComment,
    useEditStatusResponse,
    usePostCommentsHashTags,
    useSubmitComment,
    useSubmitStatus,
    useSubmitStatuses
} from 'app/services/mutations';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { getURLForTeamAndType } from '../helpers';
import RequestRichTextEditor from 'app/modules/admin/detailsPanel/requestRichTextEditor/RequestRichTextEditor';
import { IUserBasicDetails } from 'app/models/common/post';
import _ from 'lodash';
import './CommentField.scss';
import { removeBlobFileSasToken } from 'app/utils/blobStorageHelpers';
import { BlobStorageContainer } from 'app/utils/constants';

interface IProps {
    id?: string;
    isEditActive?: boolean;
    initialValue?: string;
    correlatedChildrenIds?: string[];
    correlationId?: string;
    entityType?: number;
    cancelOperation?: Function;
    textareaSize?: number;
    className?: string;
    isInternal?: boolean;
    fieldType?: string;
    selectedRequest?: IRequest;
    parentCommentId?: string;
    parentStatusId?: string;
}

const CommentField: React.FC<IProps> = ({
    id,
    isEditActive,
    initialValue,
    correlatedChildrenIds,
    correlationId,
    parentCommentId,
    parentStatusId,
    entityType,
    cancelOperation,
    className,
    isInternal,
    fieldType,
    selectedRequest
}) => {
    // state variable
    const [, setUserType] = useState(null);
    const [hashTagsFormatted, setHashTagsFormatted] = useState<any[]>([]);

    const [commentText, setCommentText] = useState<string>(initialValue ? initialValue : '');
    const [textToSubmit, setTextToSubmit] = useState<string>('');

    const [mentionedUsers, setMentionedUsers] = useState<IUserBasicDetails[]>([]);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

    const { CustomMessageModal, showModal: displayErrorModal } = useCustomMessageModal();

    const handleCancel = () => {
        setCommentText('');
        setTextToSubmit('');
        setMentionedUsers([]);
        if (cancelOperation) cancelOperation();
    };

    const handleSubmit = () => {
        let data: ICommentDto = {
            //TODO: Uncomment post API update
            // hashtags: formatHashtags(hashtags),
            correlationId: correlationId,
            entityType: entityType,
            isInternal: isInternal,
            taggedUsers: _.uniq(mentionedUsers),
            id: id ?? null,
            parentStatusId: parentStatusId ?? null,
            parentCommentId: parentCommentId ?? null
        };

        if (commentText || textToSubmit) {
            if (isEditActive) {
                if (fieldType === 'comment') {
                    data.commentText = textToSubmit !== '' ? removeBlobFileSasToken(textToSubmit.trim()) : removeBlobFileSasToken(commentText.trim());
                    editCommentMutation(data);
                } else {
                    data.statusText = textToSubmit !== '' ? removeBlobFileSasToken(textToSubmit.trim()) : removeBlobFileSasToken(commentText.trim());
                    editStatusResponseMutation(data);
                }
            } else {
                if (fieldType === 'comment') {
                    data.commentText = textToSubmit !== '' ? removeBlobFileSasToken(textToSubmit.trim()) : removeBlobFileSasToken(commentText.trim());
                    submitCommentMutation(data);
                } else {
                    data.statusText = textToSubmit !== '' ? removeBlobFileSasToken(textToSubmit.trim()) : removeBlobFileSasToken(commentText.trim());
                    if (correlatedChildrenIds.length > 0) {
                        submitStatusesMutation({
                            parentStatus: { ...data, correlationId: selectedRequest.unifiedTrackingId },
                            childStatuses: correlatedChildrenIds.map((correlationId) => ({ ...data, correlationId }))
                        } as IStatusesDto);
                    } else {
                        submitStatusMutation(data);
                    }
                }
            }
        }

        setCommentText('');
        setTextToSubmit('');
        setMentionedUsers([]);
        if (cancelOperation) cancelOperation();
    };

    // queries
    const { mutate: submitStatusesMutation, isSuccess: succesStatuses, isError: isErrorSubmitStatuses } = useSubmitStatuses();
    const {
        mutate: editStatusResponseMutation,
        isSuccess: editStatusResponseSuccess,
        isError: isErrorStatusResponse
    } = useEditStatusResponse();
    const { mutate: editCommentMutation, isSuccess: editCommentSuccess, isError: isErrorEditComment } = useEditComment();
    const { mutate: submitCommentMutation, isSuccess: submitSuccess, isError: isErrorSubmitComment } = useSubmitComment();
    const { mutate: submitStatusMutation, isSuccess: successStatus, isError: isErrorSubmitStatus } = useSubmitStatus();
    const { mutate: postHashTags } = usePostCommentsHashTags();

    useEffect(() => {
        if (isInternal) {
            setUserType('Admin');
        } else {
            setUserType(null);
        }
    }, [isInternal]);

    useEffect(() => {
        if (editCommentSuccess || editStatusResponseSuccess) {
            if (cancelOperation) cancelOperation();
        }
    }, [editCommentSuccess, editStatusResponseSuccess, selectedRequest]);

    useEffect(() => {
        if (
            (successStatus || succesStatuses || submitSuccess || editCommentSuccess || editStatusResponseSuccess) &&
            selectedRequest
        ) {
            let url = getURLForTeamAndType(
                selectedRequest.requestTeam || selectedRequest.teamValue,
                selectedRequest.requestTypeValue || selectedRequest.requestType
            );
            if (Array.isArray(hashTagsFormatted) && hashTagsFormatted?.length > 0) {
                postHashTags({ url: url, data: hashTagsFormatted, correlationId: correlationId });
            }
        }
    }, [successStatus, succesStatuses, submitSuccess, editCommentSuccess, editStatusResponseSuccess, selectedRequest]);

    useEffect(() => {
        if (isErrorSubmitComment || isErrorSubmitStatus || isErrorSubmitStatuses || isErrorEditComment || isErrorStatusResponse) {
            displayErrorModal();
        }
    }, [isErrorSubmitComment, isErrorSubmitStatus, isErrorSubmitStatuses, isErrorEditComment, isErrorStatusResponse]);

    return (
        <div className={`${className}`}>
            <CustomMessageModal />
            <div className='comment-textfield'>
            <RequestRichTextEditor
                content={commentText}
                isMentionAvailable={true}
                setMentionedUsers={setMentionedUsers}
                mentionedUsers={mentionedUsers}
                hashTagsFormatted={hashTagsFormatted}
                setHashTagsFormatted={setHashTagsFormatted}
                isCommentField={true}
                setIsSubmitDisabled={setIsSubmitDisabled}
                setTextToSubmit={setTextToSubmit}
                textToSubmit={textToSubmit}
                fileStorageContainer={BlobStorageContainer.COMMENT_STATUS_FILES}
            />
            </div>
            {isEditActive ? (
                <div className="cmt-action">
                    {((commentText && commentText.trim().length > 0) || (textToSubmit && textToSubmit.trim().length > 0)) && (
                        <Link className="action-items" data-testid="reply-one" tabIndex={0} onClick={handleSubmit}>
                            Save
                        </Link>
                    )}

                    {(commentText.trim().length === 0 || textToSubmit.trim().length === 0) && (
                        <Link className="action-items" data-testid="reply-one" tabIndex={0} disabled>
                            Save
                        </Link>
                    )}

                    <Text className="action-items">
                        <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                    </Text>
                    <Link className="action-items" data-testid="reply-one" tabIndex={0} onClick={() => handleCancel()}>
                        Cancel
                    </Link>
                </div>
            ) : (
                <>
                    <div className="comment-form-btn-wrap">
                        <DefaultButton className="default" onClick={handleCancel}>
                            <Trans>Cancel</Trans>
                        </DefaultButton>
                        <DefaultButton type="submit" className="primary" onClick={handleSubmit}
                            disabled={isSubmitDisabled}
                        >
                            <Trans>Submit</Trans>
                        </DefaultButton>
                    </div>
                </>
            )}
        </div>
    );
};

export default CommentField;
