import React from 'react';
import ReactDOMServer from 'react-dom/server';

var emojis = [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈', '❤️',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯', '💩'
];

var Emojis = {
    // @Required @Unique
    // plugin name
    name: 'emojis_container',

    // @Required
    // data display
    display: 'command',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {

        // @Required
        // Registering a namespace for caching as a plugin name in the context object
        const context = core.context;
        context.emojis_container = {};

        // Generate submenu HTML
        // Always bind "core" when calling a plugin function
        let listDiv = this.setSubmenu(core);

        // You must bind "core" object when registering an event.
        /** add event listeners */
        listDiv.querySelector('.se-form-group').addEventListener('click', this.onClick.bind(core));

        // @Required
        // You must add the "submenu" element using the "core.initMenuTarget" method.
        /** append target button menu */
        core.initMenuTarget(this.name, targetElement, listDiv);
    },

    setSubmenu: function (core) {
        const listDiv = core.util.createElement('DIV');
        listDiv.className = 'se-menu-container se-submenu se-list-layer';

        var emojiDiv = (emoji) => {
            return (
                <div>
                    <button type="button" class="se-btn se-tooltip" data-command="bold" style={{margin: '0 !important', border: 'none' , height: '2rem', width: '3rem', fontSize: '20px'}}>
                        {emoji}
                    </button>
                </div>
            );
        };

        var emojiDivs = emojis.map((emoji) => ReactDOMServer.renderToString(emojiDiv(emoji)));

        listDiv.innerHTML = '' +
            '<div class="se-list-inner">' +
                '<div class="se-form-group" style="width: 20rem; display: flex; height: 20rem; overflow-y: scroll; flex-wrap: wrap;">' +
                    `${emojiDivs.join('')}` +
                '</div>' +
            '</div>';

        return listDiv;
    },

    onClick: function (e) {
        e.preventDefault();
        e.stopPropagation();

        this.functions.insertHTML(`<span style="font-size: 22px;">${e.target.innerHTML}</span>`);
    }
};

export default Emojis;