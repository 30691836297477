import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Text, Checkbox, Icon, SearchBox, Link, PersonaInitialsColor, Label, FontIcon } from '@fluentui/react';

import { IRequest, IUserProps } from 'app/models/common/response';
import Avatar from 'app/components/avatar/Avatar';
import { useAssignRequest } from 'app/services/mutations';
import './AssignedToFilter.scss';

import { AppQueryClient } from 'app/services/clients/query-client';
import { keyPress, setQueryParam } from 'app/components/helpers';
import { useHistory } from 'react-router-dom';
import { RouteConstants, StringConstants } from 'app/utils/constants';
import useStore from 'app/store';
interface IProps {
    selectedKeys: IUserProps[];
    setSelectedKeys: Function;
    origin: String;
    options: IUserProps[];
    selectedRequest?: IRequest;
    stateData?: any;
    setFilterActive?: (filterActive: boolean) => void;
}
const AssignedToFilter: React.FC<IProps> = ({ selectedKeys, setSelectedKeys, origin, options, selectedRequest, stateData, setFilterActive }) => {
    const filterPanelDisabled = useStore((state: any) => state.filterPanelDisabled);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IUserProps[]>([]);

    const dropDownRef = useRef(null);
    const { mutate: requestAssign, isLoading: isAssigning, isSuccess: isAssignSuccess } = useAssignRequest();

    const history = useHistory();
    const setQueryStateData = (data: any, replace: boolean) => {
        setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, replace, {
            ...stateData,
            ...data
        });
    };

    const sendSelectedValue = (data: any) => {
        let assignmentData = {
            id: selectedRequest.unifiedTrackingId,
            assignee: {
                id: data.id,
                displayName: data.displayName,
                email: data.mail
            }
        };
        if(data.displayName === StringConstants.UNASSIGNED)
        {
            assignmentData.assignee = null;
        }
        requestAssign({
            team: selectedRequest?.teamValue,
            requestType: selectedRequest.requestTypeValue,
            data: assignmentData
        });

        onToggleShow();
    };

    const onChange = (key: string) => {
        let element = selectedKeys.filter((item) => item.id === key)[0];
        if (element) {
            if (key === StringConstants.ALL) {
                setSelectedKeys([]);
            } else {
                let arr = [...selectedKeys];

                arr.splice(selectedKeys.indexOf(element), 1);
                arr = arr.filter((it) => it.id !== StringConstants.ALL);
                if (origin !== StringConstants.DETAILS_PANEL) {
                    setQueryStateData({ assignedToSelection: arr }, false);
                }
            }
        } else {
            if (origin === StringConstants.DETAILS_PANEL) {
                setSelectedKeys([options.filter((item) => item.id === key)[0]]);
                sendSelectedValue(options.filter((item) => item.id === key)[0]);
            } else {
                if (key === StringConstants.ALL) {
                    setQueryStateData({ assignedToSelection: options }, false);
                } else {
                    const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.id === key)[0]];
                    setQueryStateData({ assignedToSelection: tempSelectedKeys }, false);
                }
            }
        }
    };

    const isChecked = (item: string) => {
        return selectedKeys.filter((ele) => ele?.id === item).length !== 0;
    };

    const onToggleShow = () => {
        if (!filterPanelDisabled || origin !== StringConstants.FILTER_PANEL) {
            setShowDropdown((value) => !value);
        }
    };

    const getLabel = useMemo(() => {
        if (
            selectedKeys?.length === 0 ||
            (selectedKeys?.length === options?.length && origin === StringConstants.FILTER_PANEL) ||
            (selectedKeys?.filter((it) => it.id === StringConstants.ALL).length !== 0 && origin === StringConstants.FILTER_PANEL)
        ) {
            if (origin === StringConstants.FILTER_PANEL) return StringConstants.UPPERCASE_ALL;
            else return StringConstants.UNASSIGNED;
        } else {
            let arr: string[] = [];

            selectedKeys.forEach((item) => {
                if (item) {
                    arr.push(item?.text);
                }
            });

            return arr.join(', ');
        }
    }, [selectedKeys]);

    const checkEquality = (arr1: IUserProps[], arr2: IUserProps[]) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.id === arr1[i].id).length === 0) {
                return false;
            }
        }
        return true;
    };

    useDropDownDismiss(dropDownRef, onToggleShow, filterPanelDisabled);

    useEffect(() => {
        if (!isAssigning && isAssignSuccess) {
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
        }
    }, [isAssignSuccess]);
    useEffect(() => {
        if (!showDropdown) setSearchValue('');
    }, [showDropdown]);
    useEffect(() => {
        if (showDropdown && selectedKeys.length === 0 && origin === StringConstants.FILTER_PANEL) {
            setSelectedKeys(options);
        }
    }, [showDropdown, origin]);
    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IUserProps[] = [];
            options.forEach((item) => {
                if (
                    item.displayName.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                    item.text.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                    item.mail.toLowerCase().includes(searchValue.trim().toLowerCase())
                ) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    useEffect(() => {
        if (
            origin === StringConstants.FILTER_PANEL &&
            options.length !== 0 &&
            selectedKeys.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.id !== StringConstants.ALL)
            )
        ) {
            setSelectedKeys(options);
        }
    }, [selectedKeys, origin, options]);

    useEffect(() => {
        if (origin === StringConstants.FILTER_PANEL) {
            if (selectedKeys.length > 0 && selectedKeys.length !== options.length) {
                setFilterActive(true);
            } else {
                setFilterActive(false);
            }
        }
    }, [selectedKeys]);

    return (
        <div className={`assignedTo-filter filter-cont ${origin === StringConstants.DETAILS_PANEL ? 'details-type' : ''}`}>
            <Text className="filter-heading">Assigned To {(selectedKeys.length > 0 && selectedKeys.length !== options.length && origin === StringConstants.FILTER_PANEL) && <FontIcon iconName="LocationDot" className="filter-active-icon" />}</Text>

            <div
                className={`placeholder-title row-flex-box align-center ${filterPanelDisabled ? 'filter-disabled' : ''}`}
                data-testid="assignedTo-filter-open-btn-test"
                onClick={() => {
                    if (origin === StringConstants.FILTER_PANEL || (origin === StringConstants.DETAILS_PANEL && !selectedRequest.isHidden)) onToggleShow();
                }}
                tabIndex={0}
                onKeyPress={(e: any) => {
                    if (origin === StringConstants.FILTER_PANEL || (origin === StringConstants.DETAILS_PANEL && !selectedRequest.isHidden))
                        keyPress(e, onToggleShow);
                }}>
                {origin === StringConstants.DETAILS_PANEL &&
                    (selectedKeys.length === 0 ? (
                        <Avatar
                            userId={StringConstants.ZERO}
                            userName={StringConstants.UNASSIGNED}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={32}
                            roundedPic={true}
                        />
                    ) : (
                        <Avatar
                            userId={selectedKeys[0]?.id}
                            userName={selectedKeys[0]?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={32}
                            roundedPic={true}
                        />
                    ))}
                <Text
                    className={`${
                        origin === StringConstants.DETAILS_PANEL && selectedRequest.isHidden ? 'static-label' : 'placeholder-label'
                    }`}>
                    {getLabel}
                </Text>{' '}
                {(origin === StringConstants.FILTER_PANEL || (origin === StringConstants.DETAILS_PANEL && !selectedRequest.isHidden)) && (
                    <Icon iconName="ChevronDown" />
                )}
            </div>

            {showDropdown && (
                <div ref={dropDownRef} className={`dropdown-cont ${origin === StringConstants.DETAILS_PANEL ? 'position-cont' : ''}`}>
                    <div className="row-flex-box align-center justify-sb dropdown-header">
                        <div
                            tabIndex={0}
                            data-testid="assignedTo-filter-close-btn-test"
                            onKeyPress={(e: any) => {
                                if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER) keyPress(e, onToggleShow);
                            }}
                            onClick={() => onToggleShow()}>
                            <Text>Assigned To</Text>
                            <Icon iconName="ChevronUp" />
                        </div>
                        {origin !== StringConstants.DETAILS_PANEL && (
                            <Link data-testid="clear-btn-test" onClick={() => setSelectedKeys([])}>
                                Clear
                            </Link>
                        )}
                    </div>

                    <div className="assignedTo-filter-search-cont">
                        {origin !== StringConstants.DETAILS_PANEL && (
                            <div>
                                {options.length === selectedKeys.length ||
                                selectedKeys.filter((it) => it.id === StringConstants.ALL).length !== 0 ? (
                                    <div className="tags-cont">
                                        <div className="tag-item">
                                            <Text>All</Text>
                                            <Icon
                                                data-testid={`tags-test-all`}
                                                onKeyPress={(e: any) => {
                                                    if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER) setSelectedKeys([]);
                                                }}
                                                onClick={() => setSelectedKeys([])}
                                                iconName="Cancel"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="tags-cont">
                                        {selectedKeys.map((ele) => (
                                            <div className="tag-item">
                                                <Text>{options.filter((item) => item?.id === ele?.id)[0]?.text}</Text>
                                                <Icon
                                                    data-testid={`tags-test-${ele?.id}`}
                                                    onKeyPress={(e: any) => {
                                                        if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER) onChange(ele?.id);
                                                    }}
                                                    onClick={() => onChange(ele?.id)}
                                                    iconName="Cancel"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <SearchBox
                            data-testid="search-box-test"
                            placeholder="Search"
                            tabIndex={0}
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item, index) => (
                                <div
                                    tabIndex={0}
                                    onKeyPress={(e: any) => {
                                        if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER) onChange(item.id);
                                    }}
                                    className={`dropdown-item ${isChecked(item.id) ? 'selected' : ''} `}
                                    key={index}>
                                    <Checkbox
                                        checked={isChecked(item.id)}
                                        onChange={() => onChange(item.id)}
                                        className={origin === StringConstants.DETAILS_PANEL ? 'customRaido' : ''}
                                    />

                                    {item.id !== StringConstants.ALL && (
                                        <Avatar
                                            userId={item?.id}
                                            userName={item?.displayName}
                                            initialColor={PersonaInitialsColor.coolGray}
                                            imageSize={32}
                                            roundedPic={true}
                                        />
                                    )}
                                    <Label>{item.text}</Label>
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item, index) => (
                                <div
                                    tabIndex={0}
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER) onChange(item.id);
                                    }}
                                    className={`dropdown-item ${isChecked(item.id) ? 'selected' : ''}  `}
                                    key={index}>
                                    <Checkbox
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        ariaLabel="assignee selection"
                                        checked={isChecked(item.id)}
                                        onChange={() => onChange(item.id)}
                                        className={origin === StringConstants.DETAILS_PANEL ? 'customRaido' : ''}
                                    />

                                    {item.id !== StringConstants.ALL && (
                                        <Avatar
                                            userId={item?.id}
                                            userName={item?.displayName}
                                            initialColor={PersonaInitialsColor.coolGray}
                                            imageSize={32}
                                            roundedPic={true}
                                        />
                                    )}
                                    <Label>{item.text}</Label>
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
const useDropDownDismiss = (ref: any, onToggleShow: Function, filterPanelDisabled: boolean) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, filterPanelDisabled]);
};
export default AssignedToFilter;
