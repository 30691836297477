import React, { useEffect, useState } from 'react';
import { ActionButton, IContextualMenuItem } from '@fluentui/react';
import { ILookUpData } from 'app/models/common/post';
import { GetText } from 'app/components/helpers';
import { IRequest } from 'app/models/common/response';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    data?: ILookUpData[];
    onCompleteHandler?: Function;
    fieldName?: string;
    currentValue?: number | boolean;
    isEditMode?: boolean;
    selectedRequest?: IRequest;
}

const ActionDropdown: React.FC<IProps> = (props) => {
    // state variable
    const [selectionStatus, setSelectionStatus] = useState(null);

    const handleSelection = (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
        if (
            props.currentValue !== undefined &&
            props.currentValue !== null &&
            item?.key?.toString() === selectionStatus?.toString() &&
            !props.isEditMode
        ) {
            ev.preventDefault();
            return;
        }
        setSelectionStatus(item.key);
        if (props.onCompleteHandler) {
            props.onCompleteHandler(props.fieldName || '', item.key);
        }
    };

    const menuProps: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: 'admin-edit-status-menu',
            items: props.data?.map((item) => ({
                key: item.id,
                text: item.name,
                canCheck: true,
                isChecked: selectionStatus === item.id ? true : false,
                onClick: handleSelection
            }))
        }),
        [handleSelection, props.data]
    );

    useEffect(() => {
        setSelectionStatus(props.currentValue);
    }, [props.currentValue, props.selectedRequest]);

    useEffect(() => {
        if (!props.isEditMode) {
            setSelectionStatus(props.currentValue);
        }
    }, [props.isEditMode]);

    return (
        <>
            <ActionButton menuProps={menuProps} data-testid="action-btn-test" className="custom-selection-menu">
                {!GetText(props.data, selectionStatus)?.toString() && StringConstants.DASHES_WITH_SPACE}
                {GetText(props.data, selectionStatus)}
            </ActionButton>
        </>
    );
};

export default ActionDropdown;
