import React, { useState } from 'react';
import './FeedbackCard.scss';
import FeedbackContent from 'app/modules/admin/feedbackView/feedbackFormContent/FeedbackContent';
import FeedbackResponse from 'app/modules/admin/feedbackView/feedbackResponseContent/FeedbackResponse';

const FeedbackCard: React.FC = () => {
    const [showFeedbackContent, setShowFeedbackContent] = useState(true);
    const [showResponseContent, setShowResponseContent] = useState(false);

    const handleFeedbackContentToggle = () => {
        setShowFeedbackContent(!showFeedbackContent);
        setShowResponseContent(!showResponseContent);
    };
    return (
        <div className="feedback-card">
            <FeedbackContent showOnScreen={showFeedbackContent} handleShowOnScreen={handleFeedbackContentToggle} />
            <FeedbackResponse showOnScreen={showResponseContent} />
        </div>
    );
};

export default FeedbackCard;
