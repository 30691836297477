import React, { useState } from 'react';
import { DirectionalHint, ITooltipHostStyles, Separator, Text, TooltipDelay, TooltipHost } from '@fluentui/react';
import { StringConstants } from 'app/utils/constants';
import Avatar from 'app/components/avatar/Avatar';
import { Grid, GridColumn as Column, GridRowClickEvent, GridRowProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';
import { createTheme } from '@fluentui/react/lib/Styling';
import useStore from 'app/store';
import './QueryTable.scss';
import tagIcon from '../../../../static/icons/tagIcon.svg';
import dataPreviewHide from '../../../../static/icons/dataPreviewHide.svg';
import dataPreviewShow from '../../../../static/icons/dataPreviewShow.svg';
import statusActive from '../../../../static/icons/statusActive.svg';
import statusClosed from '../../../../static/icons/statusClosed.svg';
import statusResolved from '../../../../static/icons/statusResolved.svg';
import statusNew from '../../../../static/icons/statusNew.svg';
import { Loader } from 'app/components/loader/Loader';
import { useId } from '@fluentui/react-hooks';

interface IProps {
    filterData?: any;
    paginatedData: any;
    isRequestsLoading: boolean;
    isListBuilderLoading: boolean;
}

const QueryTable: React.FC<IProps> = ({ filterData, paginatedData, isListBuilderLoading, isRequestsLoading }) => {
    const { t: translate } = useTranslation();
    const setIsListView = useStore((state: any) => state.setIsListView);
    const newlyAddedRow = useStore((state: any) => state.newlyAddedRow);
    const [toggleDataPreview, setToggleDataPreview] = useState(false);

    const [columnsLookUpData, setColumnsLookUp] = useState([
        {
            key: 0,
            column: translate('ID'),
            isChecked: true
        },
        {
            key: 1,
            column: translate('Team'),
            isChecked: true
        },
        {
            key: 2,
            column: translate('Request Type'),
            isChecked: true
        },
        {
            key: 3,
            column: translate('Title'),
            isChecked: true
        },

        {
            key: 4,
            column: translate('Assigned To'),
            isChecked: true
        },
        {
            key: 5,
            column: translate('Status'),
            isChecked: true
        },

        {
            key: 6,
            column: translate('Tags'),
            isChecked: true
        }
    ]);

    const myTheme = createTheme({
        fonts: {
            medium: {
                fontFamily: 'Monaco, Menlo, Consolas',
                fontSize: '50px'
            }
        },
        palette: {
            neutralLighter: '#C7E0F4'
        }
    });

    const grabLink = (id: any) => {
        let link = '';
        if (id !== null && id !== undefined) {
            link = '/request-detail/' + id;
        }
        return link;
    };

    const TitleCell = (props: any) => {
        return (
            <td className="title-link-styles" title={props.dataItem.title}>
                <a target="_blank" rel="noreferrer" href={grabLink(props.dataItem.id)}>
                    {props.dataItem.title}
                </a>
            </td>
        );
    };

    const StatusColorHandler = (status: any) => {
        switch (status) {
            case 'New':
                return <img src={statusNew} alt="new icon" />;
            case 'Active':
                return <img src={statusActive} alt="active icon" />;
            case 'Resolved':
                return <img src={statusResolved} alt="resolved icon" />;
            case 'Closed':
                return <img src={statusClosed} alt="closed icon" />;
        }
    };

    const StatusCell = (props: any) => {
        return (
            <td>
                <div className="statusStyles">{StatusColorHandler(props.dataItem.status)}</div>
            </td>
        );
    };

    const TeamCell = (props: any) => {
        return (
            <td>
                {props.dataItem?.team ? (
                    <div className="assigned-to-cell">{props?.dataItem?.team}</div>
                ) : (
                    <div className="assigned-to-cell">
                        <span className="assigned-to-display-name">--</span>
                    </div>
                )}
            </td>
        );
    };

    const getColumns = () => {
        let list: any = [];
        columnsLookUpData.forEach((item) => {
            arr.every((ele) => {
                if ((ele.key === item.key && item.isChecked) || item.key === 0) {
                    list.push(ele);
                    return false;
                } else {
                    return true;
                }
            });
        });
        list.push(arr[8]);
        return list;
    };

    const hostStyles: Partial<ITooltipHostStyles> = {
        root: {
            display: 'inline-block',
            borderRadius: '13px',
            maxWidth: '60px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginLeft: '5px',
            cursor: 'pointer'
        }
    };

    const tooltipId = useId('tooltip');
    let tags: any[] = [];
    const TagsHeader = (props: any) => {
        props?.dataItem?.tags?.map((i: any) => {
            const tag = {
                Id: i.id,
                Text: i.name
            };
            tags.push(tag);
        });

        const handleSort = (content: any) => {
            content.sort((a: any, b: any) => {
                return a.name.length - b.name.length;
            });
            return content;
        };

        return (
            <td>
                {props?.dataItem?.tags && props?.dataItem?.tags.length > 0 ? (
                    <div>
                        <div className="tagNameContainers">
                            <img className="tag-icon-styles" src={tagIcon} alt="" />
                            {props.dataItem.tags[0]?.name.length > 15 ? (
                                <TooltipHost
                                    delay={TooltipDelay.zero}
                                    content={props.dataItem.tags[0]?.name}
                                    id={tooltipId}
                                    directionalHint={DirectionalHint.bottomCenter}
                                    styles={hostStyles}>
                                    <Text className="tag-names-tooltip">{props?.dataItem?.tags[0]?.name}</Text>
                                </TooltipHost>
                            ) : (
                                <Text className="tagNames">{props?.dataItem?.tags[0]?.name}</Text>
                            )}
                            {props.dataItem.tags.length <= 1 ? (
                                ''
                            ) : (
                                <TooltipHost
                                    delay={TooltipDelay.zero}
                                    content={handleSort(props?.dataItem?.tags)
                                        .slice(1)
                                        .map((i: any) => (
                                            <div className="listItemStyles">{` ` + i.name}</div>
                                        ))}
                                    id={tooltipId}
                                    directionalHint={DirectionalHint.bottomCenter}
                                    styles={hostStyles}
                                    className="">
                                    <span className="tag-count-container">
                                        <Text className="tag-count">+{props.dataItem.tags?.length - 1}</Text>
                                    </span>
                                </TooltipHost>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="tagNameContainers">
                            <img className="tag-icon-styles" src={tagIcon} alt="" />
                            <div className="tagNames"> -- </div>
                        </div>
                    </div>
                )}
            </td>
        );
    };

    const colorAssignee = (displayName: any) => {
        const c = displayName.toUpperCase().charAt(0);
        switch (c) {
            case 'A':
                return '#a6e9ed';
            case 'B':
                return '#eca5d1';
            case 'C':
            case 'D':
                return '#94c8d4';
            case 'E':
            case 'F':
            case 'G':
                return '#e6bfed';
            case 'H':
            case 'I':
                return '#e0cea2';
            case 'J':
            case 'K':
                return '#edbbe7';
            case 'L':
                return '#A2CEF5';
            case 'M':
            case 'N':
                return '#9abfdc';
            case 'O':
                return '#d69ca5';
            case 'P':
            case 'Q':
            case 'R':
                return '#d69ca5';
            case 'S':
                return '#9bd9db';
            case 'T':
                return '#c8d1fa';
            case 'U':
            case 'V':
            case 'W':
                return '#d9a7e0';
            case 'X':
            case 'Y':
            case 'Z':
                return '#d2ccf8';
        }
    };

    const AssignedToCell = (props: any) => {
        return (
            <td>
                {props.dataItem?.assignee ? (
                    <div className="assigned-to-cell">
                        <Avatar
                            userId={props.dataItem?.assignee?.id}
                            userName={props.dataItem?.assignee?.displayName}
                            color={colorAssignee(props?.dataItem?.assignee?.displayName)}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">{props.dataItem?.assignee?.displayName}</span>
                    </div>
                ) : (
                    <div className="assigned-to-cell">
                        <Avatar
                            userId={'0'}
                            userName={StringConstants.UNASSIGNED}
                            color="#a3b2e8"
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">Unassigned</span>
                    </div>
                )}
            </td>
        );
    };

    const arr = [
        {
            key: 0,
            column: <Column width={115} className="fontStyles" field="id" title="ID" />
        },
        {
            key: 1,
            column: <Column width={115} field="requestTeam" title="TEAM" cell={TeamCell} />
        },
        {
            key: 2,
            column: <Column width={145} title="REQUEST TYPE" field="requestType" className='fontStyles'/>
        },
        {
            key: 3,
            column: <Column width={500} field="Title" title="TITLE" cell={TitleCell} />
        },
        {
            key: 4,
            column: <Column width={250} field="assignedTo" title="ASSIGNED TO" cell={AssignedToCell} />
        },
        {
            key: 5,
            column: <Column width={139} field="status" title="STATUS" cell={StatusCell} />
        },
        {
            key: 6,
            column: <Column width={260} field="tags" title="TAGS" cell={TagsHeader} />
        }
    ];

    const handleDataShow = () => {
        setToggleDataPreview(false);
    };
    const handleDataHide = () => {
        setToggleDataPreview(true);
    };

    const onRowClick = (event: GridRowClickEvent) => {
        setIsListView(false);
    };

    const [columnList, setColumnList] = useState(getColumns());

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        const white = { backgroundColor: 'white' };
        const blue = { backgroundColor: 'rgb(175,238,238)' };
        const trProps: any = { style: props.dataItem.id === newlyAddedRow ? blue : white };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    };

    return (
        <div>
            <div className="dataPreviewStyles">
                <div className="seperator-bar-left">
                    <Separator theme={myTheme} />
                </div>
                {toggleDataPreview ? (
                    <img src={dataPreviewShow} onClick={handleDataShow} alt="" />
                ) : (
                    <img src={dataPreviewHide} onClick={handleDataHide} alt="" />
                )}
                <div className="seperator-bar-right">
                    <Separator theme={myTheme} />
                </div>
            </div>
            <div className="requestCountContainer">
                {paginatedData && paginatedData?.totalCount > 0 ? paginatedData.totalCount : 0} results found
            </div>
            <div id="blue" className={`table-container ${toggleDataPreview ? 'page-container' : ''}`}>
                {isRequestsLoading || isListBuilderLoading ? (
                    <div className="p-a-20">
                        <div style={{ width: '100%' }}>
                            <Loader classNames="m-b-50 height-70" />
                        </div>
                    </div>
                ) : (
                    <div className={`${toggleDataPreview ? 'hide' : 'show'} parent-grid-container`} role="main" aria-label="grid table">
                        <Grid data={filterData} sortable={true} rowRender={rowRender} onRowClick={onRowClick}>
                            {columnList?.map((item: any) => item?.column)}
                        </Grid>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QueryTable;
