import { Modal, TextField, Text, IIconProps, Image, Spinner, SpinnerSize } from '@fluentui/react';
import { Trans } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Button from '../../../..//components/button/Button';
import { IconButton } from '@fluentui/react/lib/Button';
import { iconOptions } from '../options/Options';
import './SaveList.scss';
import chevronDown from '../../../../static/icons/chevronDown.svg';
import addFolder from '../../../../static/icons/addFolder.svg';
import folderIcon from '../../../../static/icons/folderIcon.svg';
import folderHoverIcon from '../../../../static/icons/folderHoverIcon.svg';
import { iconButtonStyles } from '../helpers/helpers';
import { IListFolder, IListQuery } from 'app/models/common/post';

interface IProps {
    message?: string;
    icon?: any;
    setIcon: Function;
    setName: any;
    name: any;
    selectedFolder: any;
    setSelectedFolder: any;
    showFolderModal: any;
    folders: IListFolder[];
    setFolderData: Function;
    queryToSave: IListQuery;
    setQueryToSave: (queryToSave: IListQuery) => void;
    handleSave: () => void;
    isModalOpen: boolean;
    showModal: any;
    hideModal: any;
    isLoading?: boolean;
    handleModalCancel: Function;
}

const SaveListModal: React.FC<IProps> = ({
    folders,
    setFolderData,
    setIcon,
    icon,
    setName,
    name,
    selectedFolder,
    setSelectedFolder,
    showFolderModal,
    queryToSave,
    setQueryToSave,
    handleSave,
    isModalOpen,
    hideModal,
    isLoading = false,
    handleModalCancel
}) => {
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const [toggleDataPreview, setToggleDataPreview] = useState(false);
    const [toggleFolder, setToggleFolder] = useState(selectedFolder.name.length > 0 ? true : false);
    const [collapseIconList, setCollapseIconList] = useState(false);
    const [listName, setListName] = useState('');
    const handleDataShow = () => {
        setToggleDataPreview(!toggleDataPreview);
        setCollapseIconList(false);
    };

    const handleCancel = () => {
        handleModalCancel();
        setIcon('');
        hideModal();
    };

    const selectFolder = () => {
        setToggleFolder(!toggleFolder);
    };

    const addFolderHandler = () => {
        hideModal();
        showFolderModal();
        setListName(listName);
        setName(name);
        setFolderData({
            icon: icon.selectedImageSrc,
            name: name,
            selectFolder: selectFolder
        });
    };

    const handleSelectFolder = (folder: any) => {
        setSelectedFolder({ ...selectedFolder, name: folder.name, id: folder.id });
        setFolderData({ ...queryToSave, name: folder.name, id: folder.id });
        setToggleFolder(!toggleFolder);
    };

    const handleCollapseIconSection = () => {
        setCollapseIconList(true);
    };

    const handleQueryNameChange = (event: any) => {
        setName(event.target.value);
        setQueryToSave({ ...queryToSave, queryName: event.target.value });
    };

    const handleToggleFolder = () => {
        setToggleFolder(!toggleFolder);
        setCollapseIconList(true);
    };

    useEffect(() => {
        if (selectedFolder.name.length > 0) {
            setToggleFolder(true);
        }
    }, [selectedFolder]);

    return (
        <div>
            <div>
                <Modal isOpen={isModalOpen} onDismiss={() => { hideModal(); handleModalCancel(); }} isBlocking={false} className="">
                    {isLoading ? (
                        <Spinner label="Updating list. Please wait." size={SpinnerSize.large} className="saving-list-spinner"/>
                    ) : (
                        <>
                            <div className="customer-modal-header">
                                <div className="addCustomerHeader">Save A List</div>
                                <div>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={handleCancel}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="save-list-header">Icon</div>
                                <div className="icon-select-box">
                                    <div
                                        className={`select-styles ${toggleDataPreview ? 'select-initial' : ''}`}
                                        onClick={handleDataShow}>
                                        {icon && icon.imageSrc ? (
                                            <div>
                                                <div>
                                                    <img className="imgStyles" src={icon.imageSrc} alt="icon svg" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="textStyles">
                                                    <Trans>
                                                        <Text>Select an Icon</Text>
                                                    </Trans>
                                                </div>
                                            </div>
                                        )}
                                        <div className="chevron-styles">
                                            <img src={chevronDown} alt="chevron icon" />
                                        </div>
                                    </div>
                                    {toggleDataPreview && !collapseIconList && (
                                        <div className="save-lists-icons select-model-lb">
                                            {iconOptions &&
                                                iconOptions.map((item: any) => {
                                                    return (
                                                        <>
                                                            <div
                                                                key={item.key}
                                                                tabIndex={0}
                                                                onClick={() => {
                                                                    setIcon(item);
                                                                    setQueryToSave({ ...queryToSave, icon: item.enumValue });
                                                                }}
                                                                className={`cart-lb ${
                                                                    icon.key === item.key
                                                                        ? 'selected-icon-lb'
                                                                        : 'device-animate-lb'
                                                                }`}
                                                                data-testid="devicelist-div">
                                                                <Image
                                                                    className="image-styles"
                                                                    src={item.imageSrc}
                                                                    alt="Surface"
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div onClick={handleCollapseIconSection} className="list-name-styles">
                                <div className="save-list-header">Name</div>
                                <div>
                                    <TextField className="textfield-name" onChange={handleQueryNameChange} value={name} />
                                </div>
                            </div>
                            <div className="save-list-header">Folder</div>
                            {toggleFolder ? (
                                <div onClick={() => handleToggleFolder()}>
                                    {selectedFolder ? (
                                        <div className="select-a-folder">
                                            <div className="selectFolderCollapsed">
                                                <Trans>
                                                    <Text>{selectedFolder.name}</Text>
                                                </Trans>
                                            </div>
                                            <div className="chevron-folder-collapsed">
                                                <img src={chevronDown} alt="chevron icon" />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="selectFolderCollapsed">
                                                <Trans>
                                                    <Text>Select</Text>
                                                </Trans>
                                            </div>
                                            <div className="chevron-folder-collapsed">
                                                <img src={chevronDown} alt="chevron icon" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="folderStructureStyles">
                                    <div className="select-folder-styles" onClick={selectFolder}>
                                        <div className="selectFolder">
                                            <Trans>
                                                <Text>Select</Text>
                                            </Trans>
                                        </div>
                                        <div className="chevron-folder">
                                            <img src={chevronDown} alt="chevron icon" />
                                        </div>
                                    </div>
                                    <div className="addFolder" onClick={addFolderHandler}>
                                        <img src={addFolder} alt="add-folder" />
                                        <Text className="folderTextStyles">Add Folder</Text>
                                    </div>
                                    {folders?.map((folder: any, i) => (
                                        <div
                                            className="folderContainer effect"
                                            onClick={() => handleSelectFolder(folder)}
                                            key={i}>
                                            <img alt="folder icon" className="image" src={folderIcon} />
                                            <img alt="folder on hover icon" className="image hover" src={folderHoverIcon} />
                                            <Text className="folderTextStyles">{folder.name}</Text>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div>
                                <div className="modal-footer">
                                    <Button buttonClass="btn btn--secondary" buttonText="Cancel" onClick={handleCancel} />
                                    <Button
                                        disabled={
                                            name.trim().length > 0 && icon && selectedFolder.name.trim().length > 0 ? false : true
                                        }
                                        buttonClass="btn btn--primary"
                                        buttonText="Submit"
                                        onClick={handleSave}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </Modal>
            </div>
        </div>
    );
};

export default SaveListModal;
