import React from 'react';
import { Text, Link as FLink } from '@fluentui/react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.scss';
import useStore from 'app/store';
import { userPaths } from 'app/utils/authUtilities';
import { Trans } from 'react-i18next';
import { StringConstants } from 'app/utils/constants';
const Footer: React.FC = () => {
    const { pathname } = useLocation();
    const setQuickLinksValue = useStore((state: any) => state.setQuickLinksValue);
    const setRedirectRequestType = useStore((state: any) => state.setRedirectRequestType);
    const setQuickLinksFLow = useStore((state: any) => state.setQuickLinksFLow);
    const setQuickLinksID = useStore((state: any) => state.setQuickLinksID);
    const setOriginalRoutingML  = useStore((state: any) => state.setOriginalRoutingML );

    const setSelectedTab = useStore((state: any) => state.setSelectedTab);

    if (userPaths.includes(pathname.split('/')[1])) {
        return (
            <>
                <div className="footer">
                    <div className="container">
                        <div className="top-footer">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="row-flex">
                                        <div className="col-flex left-footer-cont">
                                            <div className="">
                                                <Text>
                                                    <h5>
                                                        <Trans>Help With A</Trans>
                                                    </h5>
                                                </Text>
                                                <ul className="footer_links row-flex ">
                                                    <li>
                                                        <Text>
                                                            <Link
                                                                onClick={() => {
                                                                    setQuickLinksValue(null);
                                                                    setRedirectRequestType(StringConstants.QUESTION);
                                                                    setQuickLinksFLow(2);
                                                                    setQuickLinksID(1);
                                                                    setOriginalRoutingML (true);
                                                                }}
                                                                to="/help-request"
                                                                data-testid="sales-link">
                                                                <span className="request-icon sales-icon"></span>
                                                                <Trans>Sales question</Trans>
                                                            </Link>
                                                        </Text>
                                                    </li>
                                                    <li>
                                                        <Text>
                                                            <Link
                                                                onClick={() => {
                                                                    setRedirectRequestType(StringConstants.REVIEW_REQUEST);
                                                                    setQuickLinksValue(null);
                                                                    setQuickLinksFLow(7);
                                                                    setQuickLinksID(2);
                                                                    setOriginalRoutingML (true);
                                                                }}
                                                                to="/help-request"
                                                                data-testid="support-link">
                                                                <span className="request-icon support-icon"></span>
                                                                <Trans>Support escalation</Trans>
                                                            </Link>
                                                        </Text>
                                                    </li>
                                                    <li>
                                                        <Text>
                                                            <Link
                                                                onClick={() => {
                                                                    setRedirectRequestType(StringConstants.POST_SALES);
                                                                    setQuickLinksValue(null);
                                                                    setQuickLinksFLow(13);
                                                                    setQuickLinksID(3);
                                                                    setOriginalRoutingML (true);
                                                                }}
                                                                to="/help-request"
                                                                data-testid="warranty-link">
                                                                <span className="request-icon warranty-icon"></span>
                                                                <Trans>Warranty need</Trans>
                                                            </Link>
                                                        </Text>
                                                    </li>
                                                    <li>
                                                        <Text>
                                                            <Link
                                                                onClick={() => {
                                                                    setRedirectRequestType(StringConstants.IRT);
                                                                    setQuickLinksValue(null);
                                                                    setQuickLinksFLow(9);
                                                                    setQuickLinksID(4);
                                                                    setOriginalRoutingML (true);
                                                                }}
                                                                to="/help-request"
                                                                data-testid="feature-link">
                                                                <span className="request-icon feature-icon"></span>
                                                                <Trans>Feature request</Trans>
                                                            </Link>
                                                        </Text>
                                                    </li>
                                                    <li>
                                                        <Text>
                                                            <Link
                                                                onClick={() => {
                                                                    setRedirectRequestType(StringConstants.IRT);
                                                                    setQuickLinksValue(null);
                                                                    setQuickLinksFLow(10);
                                                                    setQuickLinksID(5);
                                                                    setOriginalRoutingML (true);
                                                                }}
                                                                to="/help-request"
                                                                data-testid="messaging-link">
                                                                <span className="request-icon messaging-icon"></span>
                                                                <Trans>Messaging request</Trans>
                                                            </Link>
                                                        </Text>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-col  ms-md12 ms-lg2">
                                            <div className="links-container-user">
                                                <div>
                                                    <Link role="link" to="/submit-feedback" data-testid="feedback-link">
                                                        <Trans>Feedback</Trans>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-footer">
                        <div className="container">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6">
                                        <span className="icon-global"></span> English (United States)
                                        <Text className="links">
                                            <FLink role="link" href="https://msdpn.azurewebsites.net/" target="_blank">
                                                Privacy & Cookies
                                            </FLink>
                                        </Text>
                                        <Text className="links">
                                            <FLink
                                                role="link"
                                                href="https://www.microsoft.com/en-us/legal/terms-of-use"
                                                target="_blank">
                                                Terms of use
                                            </FLink>
                                        </Text>
                                        <Text className="links">
                                            <FLink
                                                role="link"
                                                href="https://microsoft.sharepoint.com/sites/surface/SitePages/Warranty-&-Support.aspx?xsdata=MDN8MDF8fGQ5NzY4MTVkZmRhNDQ5MWZhYTEwOTM5YjRjMWVmZjc1fDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDF8MHw2Mzc3MjU4OTY2MTY0MDc5Mzh8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaUlpd2lRVTRpT2lJaUxDSlhWQ0k2TVRKOQ%3D%3D&sdata=dFI3RTA0N25LR0F6RkwwNnpoOGEyUk9USWtDblAyZmlyVFkyQ2pJSG4wZz0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2Cv-aadhyapak%40microsoft.com"
                                                target="_blank">
                                                Warranty & support
                                            </FLink>
                                        </Text>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6">
                                        <div className="copyright">
                                            <Text>© Microsoft 2021</Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="footer-admin">
                    <div className="container">
                        <div className="top-footer">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm9">
                                        <Text aria-label="Contacts header">
                                            <h5>
                                                <Trans>Contacts</Trans>
                                            </h5>
                                        </Text>
                                        <ul className="footer_links">
                                            <li className="footer_links_inline">
                                                <Text role="navigation" aria-label="SDSG link">
                                                    <Link
                                                        role="link"
                                                        onClick={() => setSelectedTab(StringConstants.SDSG)}
                                                        to="/team-contacts"
                                                        data-testid="sdsg-link">
                                                        SDSG
                                                    </Link>
                                                </Text>
                                            </li>

                                            <li className="footer_links_inline">
                                                <Text role="navigation" aria-label="CXP link">
                                                    <Link
                                                        role="link"
                                                        onClick={() => setSelectedTab(StringConstants.CXP)}
                                                        to="/team-contacts"
                                                        data-testid="cxp-link">
                                                        CXP
                                                    </Link>
                                                </Text>
                                            </li>

                                            <li className="footer_links_inline">
                                                <Text role="navigation" aria-label="CSS link">
                                                    <Link
                                                        role="link"
                                                        onClick={() => setSelectedTab(StringConstants.CSS)}
                                                        to="/team-contacts"
                                                        data-testid="css-link">
                                                        CSS
                                                    </Link>
                                                </Text>
                                            </li>

                                            <li className="footer_links_inline">
                                                <Text role="navigation" aria-label="CREW link">
                                                    <Link
                                                        role="link"
                                                        onClick={() => setSelectedTab(StringConstants.CREW)}
                                                        to="/team-contacts"
                                                        data-testid="crew-link">
                                                        CREW
                                                    </Link>
                                                </Text>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="ms-Grid-col ms-sm3">
                                        <div className="links-container">
                                            <div role="navigation" aria-label="Feedback links">
                                                {
                                                    <Link
                                                        role="link"
                                                        className="footer-feedback-btn"
                                                        to="/submit-feedback"
                                                        data-testid="feedback-link">
                                                        <Trans>Feedback</Trans>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-footer">
                        <div className="container">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6">
                                        <span className="icon-global"></span>
                                        <Text className="lang">English (United States)</Text>
                                        <Text className="links" role="navigation" aria-label={'Privacy & Cookie'}>
                                            <FLink role="link" href="https://msdpn.azurewebsites.net/" target="_blank">
                                                <Trans>Privacy & Cookies</Trans>
                                            </FLink>
                                        </Text>
                                        <Text className="links" role="navigation" aria-label="Terms of use">
                                            <FLink
                                                role="link"
                                                href="https://www.microsoft.com/en-us/legal/terms-of-use"
                                                target="_blank">
                                                <Trans>Terms of use</Trans>
                                            </FLink>
                                        </Text>
                                        <Text className="links" role="navigation" aria-label="Terms of use">
                                            <FLink
                                                role="link"
                                                href="https://microsoft.sharepoint.com/sites/surface/SitePages/Warranty-&-Support.aspx?xsdata=MDN8MDF8fGQ5NzY4MTVkZmRhNDQ5MWZhYTEwOTM5YjRjMWVmZjc1fDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDF8MHw2Mzc3MjU4OTY2MTY0MDc5Mzh8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaUlpd2lRVTRpT2lJaUxDSlhWQ0k2TVRKOQ%3D%3D&sdata=dFI3RTA0N25LR0F6RkwwNnpoOGEyUk9USWtDblAyZmlyVFkyQ2pJSG4wZz0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2Cv-aadhyapak%40microsoft.com"
                                                target="_blank">
                                                <Trans>Warranty & support</Trans>
                                            </FLink>
                                        </Text>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6">
                                        <Text className="copyright" role="navigation" aria-label="© Microsoft 2021">
                                            © Microsoft 2021
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Footer;
