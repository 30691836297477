import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './EmbedPowerBI.scss';
import { getPowerBiToken } from 'app/utils/authUtilities';
interface IProps {
    embedURL: string;
    reportName?: string;
}
const EmbedPowerBI: React.FC<IProps> = (props: IProps) => {
    const { embedURL = '', reportName = '' } = props;
    // const [, setReport] = useState<Report>();
    const accessToken = getPowerBiToken();

    const getReportId = (): string => {
        const pattern = /reportId=\S{24,36}/;
        const matches = embedURL?.match(pattern);
        return matches ? matches[0].split('=').reverse()[0] : null;
    };

    return (
        <>
            {getReportId() && (
                <>
                    {reportName && <h3 className="p-t-30 m-b-10 text-center">{reportName}</h3>}
                    <div className="insights-container">
                        <PowerBIEmbed
                            embedConfig={{
                                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                                id: getReportId(),
                                embedUrl: embedURL,
                                accessToken: accessToken,
                                tokenType: models.TokenType.Aad,
                                settings: {
                                    panes: {
                                        filters: {
                                            visible: true
                                        },
                                        pageNavigation: {
                                            visible: true
                                        }
                                    }
                                }
                            }}
                            cssClassName={'report'}
                            // getEmbeddedComponent={(embedObject: Embed) => {
                            //     setReport(embedObject as Report);
                            // }}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default EmbedPowerBI;
