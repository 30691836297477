import React from 'react';
import { SpinnerSize, Text } from '@fluentui/react';
import { Link } from 'react-router-dom';
import './index.scss';

import { Trans } from 'react-i18next';
import { useGetBannerArticle } from 'app/services/queries';
import { ArticleStatus } from 'app/enums/Status.enum';
import { getParagraphFromContent } from 'app/components/helpers';
import { Loader } from 'app/components/loader/Loader';
import { ArticleType } from 'app/enums/ArticleType.enum';

const TopArticle: React.FC = () => {
    const {
        isFetched,
        data: bannerArticle,
        isLoading
    } = useGetBannerArticle(ArticleStatus.Published, true, 1, 1, 1, 'ModifiedOn');
    const content = getParagraphFromContent(bannerArticle?.items[0]?.content);

    return (
        <>
            <div className={`top-strip ${bannerArticle?.items.length === 0 ? 'bg-color-transparent m-t-0' : ''}`}>
                <div className="container">
                    {isLoading ? (
                        <Loader size={SpinnerSize.small} />
                    ) : (
                        isFetched &&
                        bannerArticle?.items.length > 0 && (
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row d-flex ai-center">
                                    <div className="ms-Grid-col ms-sm12 ms-lg11">
                                        <Text className="top-article">
                                            <span className="info-icon m-r-5">
                                                <i className="ms-Icon ms-Icon--Info" aria-hidden="true"></i>
                                            </span>
                                            <label>
                                                <span className="title">
                                                    {bannerArticle?.items[0]?.title}:{'  '}
                                                </span>
                                                <span className="content" dangerouslySetInnerHTML={{ __html: content }} />
                                            </label>
                                        </Text>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-lg1 text-right">
                                        <Link
                                            role="link"
                                            to={{
                                                pathname:
                                                    bannerArticle?.items[0]?.type === ArticleType.Training
                                                        ? `/portal-training`
                                                        : `/article/${bannerArticle?.items[0]?.id}`,
                                                state:
                                                    bannerArticle?.items[0]?.type === ArticleType.Training
                                                        ? bannerArticle?.items[0]?.id
                                                        : null
                                            }}
                                            className="btn-rht">
                                            <Trans role="contentinfo">Read More</Trans>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default TopArticle;
