import React from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import './BreadcrumbPanel.scss';

interface IProps {
    items: IBreadcrumbItem[];
}

const BreadcrumbPanel: React.FC<IProps> = (props) => {
    return (
        <div className="breadcrumbs-panel" role="navigation" aria-label="breadcrumbs navigation links">
            <Breadcrumb items={props.items} />
        </div>
    );
};

export default BreadcrumbPanel;
