import React, { useEffect, useRef, useState } from 'react';

import { Text, Checkbox, Icon, SearchBox, FontIcon } from '@fluentui/react';

import { useQueryAllDeviceList } from 'app/services/queries';
import { IDeviceProps } from 'app/models/common/response';
import { IOptionProps } from '../../models';
import { keyPress, setQueryParam } from 'app/components/helpers';
import { useHistory } from 'react-router-dom';
import { StringConstants, RouteConstants } from 'app/utils/constants';
import useStore from 'app/store';
interface IProps {
    selectedKeys: IOptionProps[];
    setSelectedKeys: Function;
    isListView: boolean;
    stateData?: any;
    setFilterActive: (filterActive: boolean) => void;
}

const ProductsFilter: React.FC<IProps> = ({ selectedKeys, setSelectedKeys, isListView, stateData, setFilterActive }) => {
    const { data, isLoading } = useQueryAllDeviceList();
    const filterPanelDisabled = useStore((state: any) => state.filterPanelDisabled);

    const [options, setOptions] = useState<IOptionProps[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IOptionProps[]>([]);

    const dropDownRef = useRef(null);

    const history = useHistory();

    const setQueryStateData = (data: any, replace: boolean) => {
        setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, replace, {
            ...stateData,
            ...data
        });
    };

    const onChange = (key: number, dependentProperties?: number[], parentProperty?: number) => {
        if (key === 0) {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: any[] = [];
                options.forEach((ele) => arr.push(ele));
                setQueryStateData({ productsSelection: arr }, false);
            } else {
                setSelectedKeys([]);
            }
        } else if (!dependentProperties) {
            if (selectedKeys.filter((item) => item.key === key).length !== 0) {
                const tempSelectedKeys = selectedKeys.filter(
                    (item) => item.key !== key && item.key !== 0 && item.key !== parentProperty
                );
                setQueryStateData({ productsSelection: tempSelectedKeys }, false);
            } else {
                let count = 0;
                let parent = options.filter((item) => item.key === parentProperty)[0];
                let temp = parent.dependentProperties as number[];
                temp.forEach((ele) => {
                    if (selectedKeys.filter((item) => item.key === ele).length !== 0) {
                        count = count + 1;
                    }
                });
                if (count + 1 === temp.length) {
                    const tempSelectedKeys = [
                        ...selectedKeys,
                        options.filter((item) => item.key === key)[0],
                        options.filter((item) => item.key === parentProperty)[0]
                    ];
                    setQueryStateData({ productsSelection: tempSelectedKeys }, false);
                } else {
                    const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.key === key)[0]];
                    setQueryStateData({ productsSelection: tempSelectedKeys }, false);
                }
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length !== 0) {
                let arr = selectedKeys;
                dependentProperties.forEach((ele) =>
                    arr.splice(
                        arr.findIndex((element) => {
                            if (element.key === ele) return true;
                        }),
                        1
                    )
                );
                const tempSelectedKeys = arr.filter((ele) => ele.key !== key && ele.key !== 0);
                setQueryStateData({ productsSelection: tempSelectedKeys }, false);
            } else {
                let arr: IOptionProps[] = [];
                dependentProperties.forEach((item) => {
                    if (selectedKeys.filter((it) => it.key === item).length === 0)
                        arr.push(options.filter((ele) => ele.key === item)[0]);
                });
                const tempSelectedKeys = [...selectedKeys.concat(arr), options.filter((item) => item.key === key)[0]];
                setQueryStateData({ productsSelection: tempSelectedKeys }, false);
            }
        }
    };
    const isChecked = (ele: number) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };
    const onToggleShow = () => {
        if (!filterPanelDisabled) {
            setShowDropdown((value) => !value);
        }
    };
    const removeDuplicates = (arr: string[]) => {
        let uniqueArr = arr.filter((ele, index) => {
            return arr.indexOf(ele) === index;
        });
        return uniqueArr;
    };
    const getLabel = () => {
        if (selectedKeys?.filter((ele) => ele.key === 0).length !== 0 || selectedKeys?.length === 0) {
            return StringConstants.UPPERCASE_ALL;
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                let element = options.filter((ele) => ele.key === item.key)[0];
                if (element?.parentProperty) {
                    if (selectedKeys.filter((ele) => ele.key === element?.parentProperty).length !== 0)
                        arr.push(options.filter((it) => it.key === element?.parentProperty)[0].text + ' - All');
                    else arr.push(element?.text);
                } else {
                    arr.push(element?.text + ' - All');
                }
            });
            let res = removeDuplicates(arr);
            return res.join(', ');
        }
    };

    const formatIntoOptions = (data: IDeviceProps[]) => {
        let arr: IOptionProps[] = [{ key: 0, text: StringConstants.UPPERCASE_ALL }];
        let index = 1;
        data?.forEach((item) => {
            let childArray: number[] = [];
            let parentIndex = index;
            index = index + 1;
            item.devices.forEach((ele) => {
                childArray.push(index);
                index = index + 1;
            });
            arr.push({ key: parentIndex, text: item.name, dependentProperties: childArray });
            let tempIndex = parentIndex;
            tempIndex = tempIndex + 1;
            item.devices.forEach((ele) => {
                arr.push({ key: tempIndex, text: ele.name, parentProperty: parentIndex, type: ele.id });
                tempIndex += 1;
            });
        });
        return arr;
    };
    const checkEquality = (arr1: IOptionProps[], arr2: IOptionProps[]) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.key === arr1[i].key).length === 0) {
                return false;
            }
        }
        return true;
    };
    useDropDownDismiss(dropDownRef, onToggleShow, filterPanelDisabled);

    useEffect(() => {
        if (!isLoading) {
            setOptions(formatIntoOptions(data));
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (!showDropdown) setSearchValue('');
    }, [showDropdown]);
    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IOptionProps[] = [];
            options.forEach((item) => {
                if (item.text.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    useEffect(() => {
        if (showDropdown && selectedKeys.length === 0) {
            setSelectedKeys(options.slice(0));
        }
    }, [showDropdown]);
    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys?.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.key !== 0)
            )
        ) {
            setSelectedKeys(options.slice(0));
        }
    }, [options, selectedKeys]);

    useEffect(() => {
        if (selectedKeys.length > 0 && selectedKeys.length !== options.length) {
            setFilterActive(true);
        } else {
            setFilterActive(false);
        }
    }, [selectedKeys]);

    return (
        <div className={`products-filter filter-cont ${isListView ? 'list-view-filter' : ''}`}>
            <Text className="filter-heading">Products & Models {(selectedKeys.length > 0 && selectedKeys.length !== options.length) && <FontIcon iconName="LocationDot" className="filter-active-icon" />}</Text>

            <div
                className={`placeholder-title row-flex-box align-center ${filterPanelDisabled ? 'filter-disabled' : ''}`}
                tabIndex={0}
                data-testid="pdt-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <Text className="placeholder-label">{getLabel()}</Text> <Icon iconName="ChevronDown" />
            </div>

            {showDropdown && !isLoading && (
                <div ref={dropDownRef} className="dropdown-cont">
                    <div
                        tabIndex={0}
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        data-testid="pdt-filter-close-btn-test"
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center justify-sb dropdown-header">
                        <Text>Products & Models</Text>
                        <Icon iconName="ChevronUp" />
                    </div>
                    <div className="search-bar-cont">
                        {selectedKeys.filter((ele) => ele.key === 0).length !== 0 ? (
                            <div className="tags-cont">
                                <div className="tag-item">
                                    <Text>All products</Text>{' '}
                                    <Icon
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                onChange(0);
                                            }
                                        }}
                                        data-testid="tag-all-test"
                                        onClick={() => onChange(0)}
                                        iconName="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="tags-cont">
                                {selectedKeys.map((ele) => (
                                    <div className="tag-item">
                                        <Text>
                                            {ele.text}
                                            {ele.dependentProperties ? ' - All' : ''}
                                        </Text>
                                        <Icon
                                            tabIndex={0}
                                            onKeyPress={(e: any) => {
                                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                    onChange(
                                                        ele.key as number,
                                                        ele.dependentProperties as number[],
                                                        ele.parentProperty as number
                                                    );
                                                }
                                            }}
                                            onClick={() =>
                                                onChange(
                                                    ele.key as number,
                                                    ele.dependentProperties as number[],
                                                    ele.parentProperty as number
                                                )
                                            }
                                            iconName="Cancel"
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <SearchBox
                            placeholder="Search"
                            tabIndex={0}
                            data-testid="search-field-test"
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            );
                                        }
                                    }}
                                    className={`dropdown-item ${
                                        item.parentProperty &&
                                        searchedResults.filter((ele) => ele.key === item.parentProperty).length !== 0
                                            ? 'child-item'
                                            : ''
                                    }  ${item.dependentProperties ? 'parent-item' : ''}`}>
                                    <Checkbox
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() =>
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            )
                                        }
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            );
                                        }
                                    }}
                                    className={`dropdown-item ${item.parentProperty ? 'child-item' : ''}  ${
                                        item.dependentProperties ? 'parent-item' : ''
                                    }`}>
                                    <Checkbox
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() =>
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            )
                                        }
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
const useDropDownDismiss = (ref: any, onToggleShow: Function, filterPanelDisabled: boolean) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, filterPanelDisabled]);
};
export default ProductsFilter;
