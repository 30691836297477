import React from 'react';
import { TextField, Image } from '@fluentui/react';
import { Icon } from '@fluentui/react';
import { keyPress } from 'app/components/helpers';
import { getDeviceIcon } from 'app/utils/SVGIcons';

interface IDeviceProps {
    selectedModel: any;
    onCountChange: any;
    removeSelectedModel: any;
    requestSection: string;
}

const CartItem: React.FC<IDeviceProps> = (props) => {
    const { selectedModel, onCountChange, removeSelectedModel, requestSection } = props;

    return (
        <>
            {selectedModel.map((item: any, idx: number) => {
                if (item.added) {
                    return (
                        <div key={idx} className={`item`}>
                            <h4>{item.name}</h4>
                            <div className={`added-device-details`}>
                                <div className={`added-device-icon`}>
                                    <Image src={getDeviceIcon(item.iconUrl)} alt="surface icon" />
                                </div>

                                <div className={`text-field p-r-20 p-l-20`}>
                                    <TextField
                                        onChange={onCountChange}
                                        name={item.name}
                                        type="number"
                                        value={item.count}
                                        ariaLabel={'troubled-device'}
                                        disabled={requestSection === 'preview'}
                                    />
                                </div>
                                {props.requestSection !== 'preview' && (
                                    <div
                                        className={`trash-icon`}
                                        tabIndex={0}
                                        onKeyPress={(e: any) => keyPress(e, () => removeSelectedModel(item))}
                                        onClick={() => removeSelectedModel(item)}
                                        aria-label="delete product"
                                        role="button">
                                        <Icon iconName="trash" aria-label="delete product" />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                } else {
                    return <div key={idx}></div>;
                }
            })}
        </>
    );
};

export default CartItem;
