import React, { useState, useEffect } from 'react';
import { Text, TextField, IconButton, IIconProps, PrimaryButton } from '@fluentui/react';
import './AddTags.scss';
import { keyPress } from 'app/components/helpers';
import { useAddTag } from 'app/services/mutations';
import Button from 'app/components/button/Button';

interface ITagDetailsProps {
    onDismiss?: Function;
}

const AddTags: React.FC<ITagDetailsProps> = (props) => {
    const { onDismiss } = props;

    const [inputTag, setInputTag] = useState('');
    const [error, setError] = useState(false);
    const [tagApiError, setTagApiError] = useState(false);

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    // queries
    const { mutate: addTagMutate, isLoading: isTagsLoading, isSuccess: isTagAdded, isError } = useAddTag();

    const addTag = () => {
        setError(false);
        if (inputTag?.trim().length < 1) {
            setError(true);
            return true;
        }

        if (!error) {
            addTagMutate(inputTag);
        }
    };

    useEffect(() => {
        setTagApiError(false);
        if (!isTagsLoading && isTagAdded) {
            onDismiss();
        } else if (!isTagsLoading && isError) {
            setTagApiError(true);
        }
    }, [isTagsLoading, isTagAdded, isError]);

    const onChange = (event: any) => {
        setInputTag(event.target.value);
        setError(false);
        setTagApiError(false);
    };

    return (
        <div className="add-tags-container">
            <div className={`close-model `}>
                <IconButton
                    role="button"
                    aria-label="close popup"
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    tabIndex={0}
                    onClick={() => onDismiss()}
                    onKeyPress={(e: any) => keyPress(e, onDismiss)}
                    data-testid="close-popup"
                />
            </div>
            <div className="modal-heading">
                <span>Add new tag</span>
            </div>

            <div className="modal-body">
                <div className="body-tags-container">
                    <TextField placeholder="e.g. Urgent" label="Tag name" value={inputTag} onChange={(e) => onChange(e)} />
                    {error && (
                        <div>
                            <Text className="helper-txt">Tag should be minimum 1 character.</Text>
                        </div>
                    )}

                    {tagApiError && (
                        <div>
                            <Text className="helper-txt">Tag already exists. Please use a different Tag name.</Text>
                        </div>
                    )}

                    <div className="btm-btn-container">
                        <Button buttonClass="btn btn--secondary" buttonText="Cancel" onClick={() => onDismiss()} />
                        <PrimaryButton onClick={() => addTag()} data-testid="add-button">
                            Add
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTags;
