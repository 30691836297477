import * as React from 'react';
import { NormalizedDragEvent, useDraggable, useDroppable } from '@progress/kendo-react-common';
import { GridRowProps } from '@progress/kendo-react-grid';
import { useContext } from 'react';
import { ReorderContext } from './ManageLists';
interface DraggableRowProps extends GridRowProps {
    elementProps: HTMLTableRowElement;
}

const DraggableRow: React.FC<DraggableRowProps> = (props) => {
    const [dropped, setDropped] = React.useState(false);
    const [dragged, setDragged] = React.useState(false);
    const [direction, setDirection] = React.useState<'before' | 'after' | null>(null);
    const [initial, setInitial] = React.useState({ x: 0, y: 0 });
    const { reorder, dragStart } = useContext(ReorderContext);
    const element = React.useRef<HTMLTableRowElement>(null);

    const handlePress = (event: NormalizedDragEvent) => {
        setInitial({ x: event.clientX - event.offsetX, y: event.clientY - event.offsetY });
    };

    const handleDragStart = (event: NormalizedDragEvent) => {
        if (!event.originalEvent.target || !(event.originalEvent.target as HTMLElement).dataset.dragHandle) {
            return;
        }
        setDragged(true);
        dragStart(props.dataItem);
    };

    const handleDrag = (event: NormalizedDragEvent) => {
        if (!element.current || !dragged) {
            return;
        }
        element.current.style.transform = `translateY(${event.clientY - initial.y + event.scrollY}px)`;
    };

    const handleDragEnd = () => {
        setDragged(false);
        setDropped(false);
        setInitial({ x: 0, y: 0 });
    };

    const handleRelease = () => {
        if (!element.current) {
            return;
        }
        element.current.style.transform = null;
    };

    const handleDragEnter = () => {
        setDropped(true);
        setDirection(null);
    };

    const handleDragOver = (event: NormalizedDragEvent) => {
        if (!element.current) {
            return;
        }
        const rect = element.current.getBoundingClientRect();
        setDirection(rect.top + rect.height / 2 <= event.pageY ? 'after' : 'before');
    };

    const handleDragLeave = () => {
        setDropped(false);
        setDirection(null);
    };

    const handleDrop = () => {
        reorder(props.dataItem, direction);
        setDropped(false);
        setDirection(null);
    };

    useDraggable(
        element,
        {
            onPress: handlePress,
            onDragStart: handleDragStart,
            onDrag: handleDrag,
            onDragEnd: handleDragEnd,
            onRelease: handleRelease
        },
        { autoScroll: dragged }
    );

    useDroppable(element, {
        onDragEnter: handleDragEnter,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop
    });

    return (
        <React.Fragment>
            {dropped && direction === 'before' && <tr style={{ outlineStyle: 'solid', outlineWidth: 1, outlineColor: 'red' }} />}
            <tr
                {...(props.elementProps as any)}
                ref={element}
                style={{
                    backgroundColor: '#fff',
                    userSelect: 'none',
                    pointerEvents: dragged ? 'none' : undefined,
                    opacity: dragged ? '0.8' : undefined
                }}
            />
            {dropped && direction === 'after' && <tr style={{ outlineStyle: 'solid', outlineWidth: 1, outlineColor: 'red' }} />}
        </React.Fragment>
    );
};

export default DraggableRow;