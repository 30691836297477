import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAdmin, isBetaUser, isContentCreator, isContentManager, isSuperAdmin, isUser } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { IUserDetails } from 'app/models/common/post';

export const AdminRoute: React.FC<{
    component: React.FC<any>;
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const condition = isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles);
    return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/404" />;
};
export const UserRoute: React.FC<{
    component: React.FC<any>;
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const condition =
        isUser(userDetails?.userRoles) ||
        isSuperAdmin(userDetails?.userRoles) ||
        isAdmin(userDetails?.userRoles) ||
        isContentCreator(userDetails?.userRoles) ||
        isContentManager(userDetails?.userRoles);
    return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/404" />;
};

export const SuperAdminRoute: React.FC<{
    component: React.FC<any>;
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const condition = isSuperAdmin(userDetails?.userRoles);
    return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/404" />;
};
export const ContentCreatorRoute: React.FC<{
    component: React.FC<any>;
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const condition =
        isContentCreator(userDetails?.userRoles) ||
        isContentManager(userDetails?.userRoles) ||
        isSuperAdmin(userDetails?.userRoles);
    return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/404" />;
};
export const ContentManagerRoute: React.FC<{
    component: React.FC<any>;
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const condition = isContentManager(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles);
    return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/404" />;
};

export const BetaUserRoute: React.FC<{
    component: React.FC<any>;
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const condition = isBetaUser(userDetails?.userRoles);
    return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/404" />;
}