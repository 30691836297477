import React from 'react';
import './InTheNews.scss';
import NewsCard from 'app/modules/articles/viewArticle/newsCard/NewsCard';
import { useGetArticlesInTheNews } from 'app/services/queries';
import Carousel, {RenderArrowProps} from 'react-elastic-carousel';
import { Trans } from 'react-i18next';
import { ArticleStatus } from 'app/enums/Status.enum';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { useHistory } from 'react-router-dom';
import { keyPress } from 'app/components/helpers';
const InTheNews: React.FC = () => {
    const history = useHistory();
    const { isFetched, data } = useGetArticlesInTheNews(ArticleStatus.Published, ArticleType.News, true, 1, 5, 1, 'ModifiedOn');

    const myArrow = (arrowProps: RenderArrowProps) => {
        const pointer = arrowProps.type === 'PREV' ? '<' : '>';
        var pointerDirection = pointer === '<' ? 'previous' : 'next';
        return (
            <button
                aria-label={`${pointerDirection}`}
                className={`rec-arrow-${pointerDirection}`}
                onClick={arrowProps.onClick}
                disabled={arrowProps.isEdge}>
                {pointer}
            </button>
        );
    };

    return (
        <>
            {isFetched && data?.items?.length > 0 && (
                <div className="main-spot-light-container">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-lg12">
                                <h2>
                                    <span
                                        tabIndex={0}
                                        className="c-pointer semi-bold color-primary"
                                        onKeyPress={(e: any) => keyPress(e, () => history.push('/news-feed/home'))}
                                        onClick={() => history.push('/news-feed/home')}>
                                        <Trans>In The News</Trans>
                                        <i className="ms-Icon ms-Icon--ArrowUpRight8" aria-hidden="true"></i>
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            {isFetched && data?.items.length > 3 ? (
                                <Carousel renderArrow={myArrow} isRTL={false} itemsToShow={3} itemPadding={[0, 8]}>
                                    {isFetched &&
                                        data?.items.length > 0 &&
                                        data?.items.map((data: any) => (
                                            <NewsCard
                                                className={'in-the-news-card'}
                                                key={data.id}
                                                data={data}
                                                showLastEdited={false}
                                                showMoreOptions={false}
                                                showStatus={false}
                                                showArticleType={false}
                                            />
                                        ))}
                                </Carousel>
                            ) : (
                                <div className="ms-Grid-row">
                                    {isFetched &&
                                        data?.items.length > 0 &&
                                        data?.items.map((data: any) => (
                                            <div key={data.id} className="ms-Grid-col ms-sm12 ms-lg4">
                                                <NewsCard
                                                    className={'in-the-news-card'}
                                                    data={data}
                                                    showLastEdited={false}
                                                    showMoreOptions={false}
                                                    showStatus={false}
                                                    showArticleType={false}
                                                />
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InTheNews;
