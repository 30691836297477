import {
    escapeToSafeCharacters,
    generateUrlForFetchComments,
    generateUrlForFetchStatus,
    getAgingArray,
    getDateRange,
    getShareRequestSubmitDetails,
    getUpdateRequestDetailsURL,
    getURLForTeamAndType
} from 'app/components/helpers';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { ReportType } from 'app/enums/ReportType.enum';
import { ArticleStatus } from 'app/enums/Status.enum';
import { unifiedDashboardCardsList } from 'app/models/common/enumerators';
import {
    IAttachmentRequest,
    ICommentCriteria,
    ICommentDto,
    IDevicesDto,
    IDownloadVisualFlow,
    IGetAttachment,
    IRemoveCommentData,
    IRemoveCustomerImpactData,
    IRequestParams,
    IShareRequest,
    IStatusesDto,
    IStatusUpdateObject,
    IUpdateAttachmentClassification,
    IUpdateRequest
} from 'app/models/common/request';
import { ITagsRequestAddProps, ITagsRequestProps } from 'app/models/common/response';
import { ICommentsRequest } from 'app/modules/admin/visualFlow/VisualFlow';
import { API, BlobStorageHelper } from 'app/utils/constants';
import axios, { AxiosRequestConfig } from 'axios';
import { AnyPtrRecord } from 'dns';
import {
    IAddCustomer,
    IAddEmote,
    IArticleData,
    IArticleTemplateData,
    IAuthorDetails,
    IComments,
    IEmail,
    IEmailTemplate,
    IFeedback,
    IListFolder,
    IListQuery,
    IPostHashTags,
    IReassign,
    IReport,
    IRequestHelp,
    IRequestPayload,
    IResources,
    IRouteProps,
    ISearchResult,
    ISearchSuggest,
    ISharedWith,
    IUnsubscribeFeedback,
    IUpdateFolder,
    IUserList
} from '../models/common/post';
import { apiClient, apiClientJSON } from './clients/api-client';

export class ApiRequestService {
    config?: AxiosRequestConfig = {};

    private cancellationToken = axios.CancelToken.source();

    static createInstance(): ApiRequestService {
        const activeInstance = new ApiRequestService();
        activeInstance.cancellationToken = axios.CancelToken.source();
        if (activeInstance.config) {
            activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
        }
        return activeInstance;
    }

    cancelRequests() {
        this.cancellationToken.cancel('RequestCancellation');
        return ApiRequestService.createInstance();
    }

    fetchDevices = () => apiClient.get(`/request/LookUp/DeviceList`);
    fetchCountries = () => apiClient.get(`/request/LookUp/Countries`);
    requestTypes = () => apiClient.get(`/request/LookUp/RequestTypes`);
    msxData = () => apiClient.get(`/request/LookUp/Msx`);

    //file upload token api
    generateContainerSasToken = (entity: string, containerName: string) => apiClient.get(`/${entity}/File/GenerateContainerSasToken/${containerName}`);

    generateFileSasToken = (entity: string, containerName: string, fileName: string) => apiClient.get(`/${entity}/file/GenerateFileSasToken/${containerName}/${fileName}`);

    generateRequestType = (data: IRouteProps) => apiClient.post(`/request/Route`, data);

    postRequestType = (subUrl: any, requestData: IRequestHelp) => apiClient.post(`${subUrl}`, requestData);

    postAddCustomer = (subUrl: any, customerData: IAddCustomer[]) => apiClient.post(`${subUrl}`, customerData);

    postAddCustomerReviewRequest = (customerData: any) => apiClient.post('/css/ReviewRequest/AddCustomerImpact', customerData);

    postAddCxpTechnicalRequest = (customerData: any) => apiClient.post('/cxp/IrtRequest/AddCustomerImpact', customerData);

    postFeedback = (feedbackData: IFeedback) => apiClient.post(`/request/AdminFeedback`, feedbackData);

    postFeedbackResponse = (id: string, response: string) =>
        apiClient.put(`/request/AdminFeedback/Feedback/${id}?adminResponse=${response}`);

    getFeedback = (subUrl: string) => apiClient.get(`/request/AdminFeedback/Feedback?${subUrl}`);

    requestReassign = (reassignData: IReassign) => apiClient.post(`/request/Reassign`, reassignData);

    requestInfo = (subUrl: string) => apiClient.get(`/Request/list?${subUrl}`);

    teamInfo = () => apiClient.get(`/request/LookUp/Teams`);

    reasonsInfo = () => apiClient.get(`/request/LookUp/ReasonForReassignment`);

    requestTypeInfo = () => apiClient.get(`/request/LookUp/RequestTypes`);

    postComments = (data: IComments) => apiClient.post(`/commentsData`, data);

    sdsgDataLink = () => apiClient.get(`/sdsg/Proposal?pageNumber=1&pageSize=10`);

    visualSteps = (id: string) => apiClient.get(`/request/VisualFlow/${id}`);

    markNotificationsRead1 = () => apiClient.put(`/user/Notification/MarkAsRead`, null);

    UnsubscribeFeedback = (data: IUnsubscribeFeedback) => apiClient.post(`user/Notification/UnsubscribeFeedback`, data);

    visualCommentsData = (data: ICommentsRequest) =>
        apiClient.get(
            `request/Comment/list?CorrelationId=${data.correlationId}&EntityType=${data.entintyType}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`
        );

    fetchNotifications = (pageNumber: number, pageSize: number) =>
        apiClient.get(`/user/Notification?pageNumber=${pageNumber}&pageSize=${pageSize}`);

    fetchNotificationsCount = () => apiClient.get(`/user/Notification/UnreadCount`);

    commentsData = () => apiClient.get(`/commentsData`);
    searchData = (data: ISearchSuggest) =>
        apiClient.get(
            `/Search/suggest?term=${encodeURIComponent(escapeToSafeCharacters(data.term))}&searchOption=${
                data.searchOption
            }&isAdminSearch=${data.IsAdminSearch}&isParentChildSearch=${data.IsParentChildSearch}`
        );
    searchCustomer = () => apiClient.get(`/searchCustomerData`);
    searchResult = (data: ISearchResult) => apiClient.post(`/Search/search`, data);
    getNewRequest = () => apiClient.get(`/Request/Draft`);
    updateNewRequest = (data: string) => apiClientJSON.put(`/Request/Draft`, data);
    autoSearch = (data: string) => apiClient.get(`/Search/autocomplete?term=${data}`);
    getCountries = () => apiClient.get('/request/LookUp/Countries');
    fetchProjects = (page: number, subUrl: string) => apiClient.get(`/Request/list?${subUrl}PageNumber=${page}&PageSize=10`);
    fetchRequests = (payload: IRequestPayload) => {
        let data: IRequestPayload;

        const { agingKeys, isStarredRequests, dateRangeFilter, activeRange, ...payData } = payload;
        if (payload.agingKeys) data = { ...payData, aging: getAgingArray(payload.agingKeys, activeRange, dateRangeFilter) };
        else data = { ...payData };
        data = { dateRange: getDateRange(dateRangeFilter, activeRange), ...data };

        return apiClient.post(`/Request/list`, data);
    };

    fetchListBuilderRequests = (payload: any) => {
        return apiClient.post('/Request/ListBuilder', payload);
    }

    fetchBookmarkedRequests = (payload: IRequestPayload) => {
        let data: IRequestPayload;

        const { agingKeys, isStarredRequests, dateRangeFilter, activeRange, ...payData } = payload;
        if (payload.agingKeys) data = { ...payData, aging: getAgingArray(payload.agingKeys, activeRange, dateRangeFilter) };
        else data = { ...payData };
        data = { dateRange: getDateRange(dateRangeFilter, activeRange), ...data };
        return apiClient.post(`/Request/bookmark/list`, data);
    };
    getBookmarkedRequests = (page: number, subUrl: string) =>
        apiClient.get(`/Request/bookmark/list?${subUrl}&PageNumber=${page}&PageSize=20`);
    getSummaryCardCount = (subUrl: string) => apiClient.get(`/Request/AdminSummaryCard?${subUrl}`);

    //#region ARTICLE APIs

    //Create
    createArticle = (articleData: IArticleData) => apiClient.post(`/Article`, articleData);

    createArticleTemplate = (articleTemplateData: IArticleTemplateData) =>
        apiClient.post(`/Article/ArticleTemplate`, articleTemplateData);
    //Retrieve
    getArticle = (articleId: string) => apiClient.get(`/Article/${articleId}`);

    getKnowledgeArticles = () => apiClient.get(`/Article/List?Type=1`);
    getKnowledgeCenterHomePageArticles = () => apiClient.get(`/Article/home?type=1`);
    getNewsFeedHomePageArticles = () => apiClient.get(`/Article/home?type=2`);
    getRelatedArticles = (articleId: string) => apiClient.get(`/Article/${articleId}/Related`);

    getMSXSearchResults = (searchValue: string) => apiClient.get(`/Search/msxsuggest?term=${searchValue}`);

    getArticlesInTheNews = (
        status: ArticleStatus,
        type: ArticleType,
        filterHomePageArticles: boolean,
        pageNumber: number,
        pageSize: number,
        sortDirection: number,
        sortProperty: string
    ) =>
        apiClient.get(
            `/Article/List?Status=${status}&Type=${type}&FilterHomePageArticles=${filterHomePageArticles}&PageNumber=${pageNumber}&PageSize=${pageSize}${
                sortDirection ? `&SortDirection=${sortDirection}` : ''
            }${sortProperty ? `&SortProperty=${sortProperty}` : ''}`
        );
    getBannerArticle = (
        status: ArticleStatus,
        filterBannerArticle: boolean,
        pageNumber: number,
        pageSize: number,
        sortDirection: number,
        sortProperty: string
    ) =>
        apiClient.get(
            `/Article/List?Status=${status}&FilterBannerArticle=${filterBannerArticle}&PageNumber=${pageNumber}&PageSize=${pageSize}${
                sortDirection ? `&SortDirection=${sortDirection}` : ''
            }${sortProperty ? `&SortProperty=${sortProperty}` : ''}`
        );
    getArticleTemplates = () => apiClient.get(`/Article/ArticleTemplate/List`);

    getArticleTemplateById = (templateId: string) => apiClient.get(`/Article/ArticleTemplate/${templateId}`);

    //Delete article template
    deleteArticleTemplateById = (templateId: string) => apiClient.delete(`Article/ArticleTemplate/${templateId}`);

    getArticlesByType = (
        pageNumber: number,
        pageSize: number,
        sortProperty: string,
        sortDirection: string,
        status: ArticleStatus,
        type: ArticleType,
        authorId?: string,
        isRootTag?: boolean,
        displayName?: string
    ) =>
        apiClient.get(
            `/Article/Type?PageNumber=${pageNumber}&PageSize=${pageSize}${
                sortDirection ? `&SortDirection=${sortDirection}` : ''
            }${sortProperty ? `&SortProperty=${sortProperty}` : ''}${status ? `&Status=${status}` : ''}${
                type ? `&Type=${type}` : ''
            }${authorId ? `&AuthorId=${authorId}` : ''}${
                displayName
                    ? isRootTag
                        ? `&RootTag=${displayName.replace('+', '%2B')}`
                        : `&Tag=${displayName.replace('+', '%2B')}`
                    : ''
            }`
        );

    getArticles = (
        pageNumber: number,
        pageSize: number,
        sortProperty: string,
        sortDirection: string,
        status: ArticleStatus,
        type: ArticleType,
        authorId?: string,
        isRootTag?: boolean,
        displayName?: string
    ) =>
        apiClient.get(
            `/Article/List?PageNumber=${pageNumber}&PageSize=${pageSize}${
                sortDirection ? `&SortDirection=${sortDirection}` : ''
            }${sortProperty ? `&SortProperty=${sortProperty}` : ''}${status ? `&Status=${status}` : ''}${
                type ? `&Type=${type}` : ''
            }${authorId ? `&AuthorId=${authorId}` : ''}${
                displayName
                    ? isRootTag
                        ? `&RootTag=${displayName.replace('+', '%2B')}`
                        : `&Tag=${displayName.replace('+', '%2B')}`
                    : ''
            }`
        );

    getArticleCategories = () => apiClient.get(`/Article/ArticleCategory/List`);

    getAuthors = (sortDirection: number, pageNumber: number, pageSize: number, sortProperty: string = 'ModifiedOn') =>
        apiClient.get(
            `/Article/Author/List?SortDirection=${sortDirection}&sortProperty=${sortProperty}&PageNumber=${pageNumber}&PageSize=${pageSize}`
        );

    getAuthorById = (authorId: string) => apiClient.get(`/Article/Author/${authorId}`);

    //Update
    updateArticle = (articleData: IArticleData) => apiClient.put(`/Article`, articleData);

    postArticleViewed = (articleId: string) => apiClient.post(`/Article/${articleId}/TrackView`);

    updateAuthorDetails = (authorDetails: IAuthorDetails) => apiClient.put(`/Article/Author`, authorDetails);
    //Draft
    draftArticle = (articleId: string) => apiClient.put(`/Article/${articleId}/Draft`);

    //Redraft
    reDraftArticle = (articleId: string, reviewNotes: string) => apiClient.put(`/Article/${articleId}/Redraft`, { reviewNotes });

    //Submit
    submitArticle = (articleId: string) => apiClient.put(`/Article/${articleId}/Submit`);

    //Publish
    publishArticle = (articleId: string, showOnHomePage: boolean, showAsBannerArticle: boolean) =>
        apiClient.put(`/Article/${articleId}/Publish`, { showOnHomePage, showAsBannerArticle });

    //ScheduleToPublish
    scheduleToPublishArticle = (
        articleId: string,
        showOnHomePage: boolean,
        showAsBannerArticle: boolean,
        scheduledPublishOn: string
    ) => apiClient.put(`/Article/${articleId}/ScheduleToPublish`, { showOnHomePage, showAsBannerArticle, scheduledPublishOn });

    //Remove
    removeArticle = (articleId: string) => apiClient.put(`/Article/${articleId}/Remove`);

    //Delete
    deleteArticle = (articleId: string) => apiClient.delete(`/Article/${articleId}`);

    //#end region

    //contact details GET call
    getTeamDetails = (teamId: number, count: number, type?: string, searchTerm?: string) => {
        if (type === 'Admin') {
            return apiClient.get(`/User/SearchAdminUsers?SearchText=${searchTerm}&userDomain=${teamId}`);
        }
        return apiClient.get(`/request/User/SearchGroup?requestDomain=${teamId}&maxResultCount=${count}`);
    };

    // APIs for End User Unified Dashboard
    getRequestList = (requestFilter: IRequestParams) => {
        let apiURL = '';
        if (requestFilter.type === unifiedDashboardCardsList.STARRED_REQUEST) {
            apiURL = '/Request/bookmark/list';
        } else if (requestFilter.type === unifiedDashboardCardsList.MY_REQUEST) {
            apiURL = '/Request/list';
        } else if (requestFilter.type === unifiedDashboardCardsList.SHARED_WITH_ME) {
            apiURL = '/Request/list';
        } else if (requestFilter.type === unifiedDashboardCardsList.ALL_REQUEST) {
            apiURL = '/Request/list';
        }

        let queryParams: IRequestPayload;
        queryParams = {
            pageNumber: requestFilter.pageNumber,
            pageSize: requestFilter.pageSize,
            getHiddenRequests: false,
            portal: requestFilter.portal
        };
        if (requestFilter.from && requestFilter.to) {
            queryParams = { ...queryParams, dateRange: { from: requestFilter.from, to: requestFilter.to } };
        }

        if (requestFilter.caseStatus && requestFilter.caseStatus.length > 0) {
            queryParams = { ...queryParams, caseStatus: requestFilter.caseStatus };
        }
        if (requestFilter.watchedByCurrentUser) {
            queryParams = { ...queryParams, watchedByCurrentUser: requestFilter.watchedByCurrentUser };
        }
        if (requestFilter.requestType.length) {
            queryParams = { ...queryParams, requestType: requestFilter.requestType };
        }
        if (requestFilter.parentRequestId) {
            queryParams = { ...queryParams, parentRequestId: requestFilter.parentRequestId };
        }
        if (requestFilter.hideParentRequests) {
            queryParams = { ...queryParams, hideParentRequests: requestFilter.hideParentRequests };
        } else {
            queryParams = { ...queryParams, hideParentRequests: false };
        }
        if (requestFilter.filterByCurrentUser) {
            queryParams = { ...queryParams, filterByCurrentUser: requestFilter.filterByCurrentUser };
        } else {
            queryParams = { ...queryParams, filterByCurrentUser: false };
        }
        if (requestFilter.isClosedRequestExcluded) {
            queryParams = { ...queryParams, isClosedRequestExcluded: requestFilter.isClosedRequestExcluded };
        } else {
            queryParams = { ...queryParams, isClosedRequestExcluded: false };
        }
        return apiClient.post(`${apiURL}`, queryParams);
    };

    //help request details
    getRequestDetails = (skpId: string) => apiClient.get(`/Request/${skpId}`);

    //file upload token api
    speechToken = () => apiClient.get(`/Search/speechtotexttoken`);

    //search user API

    getUsersList = (searchValue: string, type?: 'Admin' | null) => {
        switch (type) {
            case 'Admin':
                return apiClient.get(`/User/SearchAdminUsers?SearchText=${searchValue}`);
            default:
                return apiClient.get(`/User/SearchUsers?searchText=${searchValue}`);
        }
    };

    //add bookmark
    addBookmark = (requestId: string) => apiClient.put(`/Request/bookmark/${requestId}`);

    //remove bookmark
    removeBookmark = (requestId: string) => apiClient.delete(`/Request/bookmark/${requestId}`);

    //get popular articles
    getPopularArticles = (
        status: ArticleStatus,
        articleType: ArticleType,
        sortProperty: string = 'NumberOfViews',
        sortDirection: number = 1,
        pageNumber: number = 1,
        pageSize: number = 4
    ) =>
        apiClient.get(
            `/Article/List?Status=${status}&Type=${articleType}&SortProperty=${sortProperty}&SortDirection=${sortDirection}&PageNumber=${pageNumber}&PageSize=${pageSize}`
        );

    // submit shared request from end user unified dashboard
    shareRequest = (data: IShareRequest) => {
        const apiURL = getShareRequestSubmitDetails(data);
        let dataObj = {
            users: data.users,
            attachments: data.attachments,
            requestId: data.requestId
        };
        return apiClient.put(apiURL, dataObj);
    };

    // Fetch comments for an entity
    fetchComments = async (criteria: ICommentCriteria) => {
        const apiURL = generateUrlForFetchComments(criteria);
        return apiClient.get(apiURL);
    };

    // submit comment
    submitComment = (data: ICommentDto) => {
        const apiURL = '/request/Comment';
        return apiClient.post(apiURL, data);
    };

    // update comment
    updateComment = (data: ICommentDto) => {
        return apiClient.put('/request/Comment/CommentUpdate', data);
    };

    unassociatedRequest = (data: any) => {
        return apiClient.put('/Request/UnassociateRequest', data);
    };

    associateRequests = (data: any) => {
        return apiClient.put('/Request/AssociateRequests', data);
    };

    // Returns the count details for summary cards in Unified Dashboard
    getSummaryCardCountForUser = (subUrl: string) => {
        if (subUrl === 'ALL') return apiClient.get(`/Request/UserSummaryCard`);
        return apiClient.get(`/Request/UserSummaryCard?${subUrl}`);
    };

    //assign Request
    assignRequest = (team: number, requestType: number, data: AnyPtrRecord) => {
        let apiUrl = getURLForTeamAndType(team, requestType);
        return apiClient.post(`${apiUrl}/Assign`, data);
    };

    // update details of a request in admin dashboard
    updateRequest = (data: IUpdateRequest) => {
        const apiURL = getUpdateRequestDetailsURL(data);
        let dataObj = data;
        return apiClient.put(apiURL, dataObj);
    };

    // update details of a request in admin dashboard
    updateRequestDevices = (data: IDevicesDto) => {
        let dataObj = data;
        return apiClient.put('/Request/DevicesUpdate', dataObj);
    };

    updateStatus = (data: IStatusUpdateObject) => {
        return apiClient.put('/Request/StatusUpdate', data);
    };

    //Subscription
    updateNewsSubscription = () => apiClient.put(`/user/Preference/SubscribeNewsletter`);
    updateKnowledgeArticleSubscription = () => apiClient.put(`/user/Preference/SubscribeKnowledgeCenter`);

    //Unsubscribe
    updateNewsUnSubscription = () => apiClient.put(`/user/Preference/UnsubscribeNewsletter`);
    updateKnowledgeArticleUnSubscription = () => apiClient.put(`/user/Preference/UnsubscribeKnowledgeCenter`);

    //add attachment
    addAttachment = (requestData: IAttachmentRequest) => {
        let apiUrl = getURLForTeamAndType(requestData.team, requestData.requestType);
        return apiClient.put(`${apiUrl}/Attachment`, requestData);
    };

    //remove attachment
    removeAttachment = (requestData: IAttachmentRequest) => {
        let apiUrl = getURLForTeamAndType(requestData.team, requestData.requestType);
        let queryParam = `?entityId=${requestData.entityId}&entityType=${requestData.entityType}`;
        return apiClient.delete(`${apiUrl}/Attachment/${requestData.id}${queryParam}`);
    };

    // update attachment classification
    updateAttachmentClassification = (requestData: IUpdateAttachmentClassification) => {
        let apiUrl = getURLForTeamAndType(requestData.team, requestData.requestType);
        return apiClient.put(`${apiUrl}/Attachment/UpdateClassification`, requestData);
    };

    //remove customer impact
    removeCustomerImpact = (requestData: IRemoveCustomerImpactData) => {
        let apiUrl = getURLForTeamAndType(requestData.team, requestData.requestType);
        return apiClient.put(`${apiUrl}/${requestData.id}/DeleteCustomerImpact`, requestData.data);
    };

    // Get the attachment details
    getAttachments = (requestData: IGetAttachment) => {
        let apiUrl = getURLForTeamAndType(requestData.team, requestData.requestType);
        let queryString = `?entityId=${requestData.id}&entityType=${requestData.entityType}&portal=${requestData.portal}`;
        return apiClient.get(`${apiUrl}/Attachment/List${queryString}`);
    };

    /**
     * Upload a file to blob storage via service API
     * @param file
     * @param blobName
     * @returns Promise object with blob information includeing URLs
     */
    uploadFileToBlobStorageAsync = async (file: any, blobName: string) : Promise<any> => {
        const formData = new FormData();
        formData.append("file", file, blobName);
        return await apiClient.post(BlobStorageHelper.UPLOAD_ATTACHMENT_ROUTE, formData);
    }

    // Download Attachment Blob
    /**
     * Get the BlobSasUri to download the specified blob (file)
     * @param blobName
     * @returns BlobSasUri
     */
    getFileSasUriFromBlobStorageAsync = async (blobName: string) : Promise<any> => {
        return await apiClient.get(`${BlobStorageHelper.DOWNLOAD_ATTACHMENT_ROUTE}/${blobName}`);
    }

    //User info
    getUserDetails = () => apiClient.get(`/user/Preference/CurrentUserInfo`);

    //User preference
    getUserPreferences = () => apiClient.get(`/user/Preference`);

    // Get download data for visual flow
    getDownloadVisualFlow = (data: IDownloadVisualFlow) => {
        const genericApiClient = axios.create({
            baseURL: API.API_SERVER_URL,
            responseType: 'text',
            withCredentials: false,
            headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
        });
        let apiUrl = `/request/VisualFlow/${data.correlationId}/DownloadVisualFlow`;
        return genericApiClient.get(`${apiUrl}`);
    };

    // Get download data for search results
    getDownloadSearchResult = (data: ISearchResult) => {
        const genericApiClient = axios.create({
            baseURL: API.API_SERVER_URL,
            responseType: 'text',
            withCredentials: false,
            headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
        });
        return genericApiClient.post(`/Search/downloadsearchresult`, data);
    };

    // remove comment
    removeComment = (data: IRemoveCommentData) => {
        const apiURL = '/request/Comment';
        let queryParams = `?id=${data.id}&correlationId=${data.correlationId}&entityType=${data.entityType}`;
        return apiClient.delete(`${apiURL}${queryParams}`);
    };

    //store user preference
    storeUserPreference = (data: any) => apiClient.post(`/user/Preference`, data);

    //query list of users
    fetchUsersList = (team: number) => {
        if (team) return apiClient.get(`/User/SearchAdminUsers?UserDomain=${team}`);
        else return apiClient.get(`/User/SearchAdminUsers`);
    };

    //list of reports
    getReports = (
        pageNumber: number,
        pageSize: number,
        searchText?: string,
        sortProperty?: 'DisplayName' | 'Type' | 'ModifiedOn' | null,
        sortDirection?: string,
        filterBookmarkedReports?: boolean,
        reportType?: ReportType
    ) =>
        apiClient.get(
            `/user/Report/List?pageNumber=${pageNumber}&pageSize=${pageSize}${searchText ? `&searchText=${searchText}` : ''}${
                sortProperty ? `&sortProperty=${sortProperty}` : ''
            }${sortDirection ? `&sortDirection=${sortDirection}` : ''}${
                filterBookmarkedReports ? `&filterBookmarkedReports=${filterBookmarkedReports}` : ''
            }${reportType ? `&Type=${reportType}` : ''}`
        );

    //get reports by userId
    getReportsByUser = (userId: string) => apiClient.get(`user/Report/UserReports/${userId}`);

    //get user has bookmarked reports
    getUserHasBookmarkedReports = () => apiClient.get(`user/Report/HasBookmarkedReports`);

    //get reports by userId
    deleteReport = (reportId: string) => apiClient.delete(`user/Report/${reportId}`);

    //get reports by id
    getReportById = (reportId: string) => apiClient.get(`user/Report/${reportId}`);

    //share report
    shareReport = (data: ISharedWith) => apiClient.put(`user/Report/Share`, data);

    //bookmark report
    bookmarkReport = (reportId: string) => apiClient.put(`user/Report/Bookmark/${reportId}`);

    //update report
    updateReport = (report: IReport) => apiClient.put(`user/Report`, report);

    //add report
    addReport = (report: IReport) => apiClient.post(`user/Report`, report);

    // get request details lookup
    getRequestDetailsLookupInfo = () => apiClient.get(`/request/LookUp/RequestTypeDetails`);

    //hide a request
    hideRequest = (id: string) => apiClient.put(`/Request/hide/${id}`);

    //unhide a request
    unhideRequest = (id: string) => apiClient.put(`/Request/unhide/${id}`);

    // submit status
    submitStatus = (data: ICommentDto) => {
        const apiURL = '/request/Status';
        return apiClient.post(apiURL, data);
    };

    // submit multiple status, for parent/child
    submitStatuses = (statuses: IStatusesDto) => {
        return apiClient.post('/request/Status/Statuses', statuses);
    };

    // Fetch status for an entity
    fetchStatus = async (criteria: ICommentCriteria) => {
        const apiURL = generateUrlForFetchStatus(criteria);
        return apiClient.get(apiURL);
    };

    // remove status
    removeStatus = (data: IRemoveCommentData) => {
        const apiURL = '/request/Status';
        let queryParams = `?id=${data.id}&correlationId=${data.correlationId}`;
        return apiClient.delete(`${apiURL}${queryParams}`);
    };

     // update status response
     updateStatusResponse = (data: ICommentDto) => {
        const apiURL = '/request/Status/UpdateStatus';
        return apiClient.put(apiURL, data);
    };

    //get tags List
    getTagsList = () => apiClient.post(`/request/Tag/List`);

    //add tags List
    addNewTags = (tag: string) => apiClient.post(`/request/Tag`, { name: tag });

    //delete tag
    deleteTag = (tagId: string) => apiClient.delete(`/request/Tag/${tagId}`);

    //add tag from request
    addTagRequest = (data: ITagsRequestAddProps) => apiClient.put(`/Request/${data.requestId}/tag`, data.selectedValues);

    //delete tag from request
    deleteTagRequest = (data: ITagsRequestProps) =>
        apiClient.delete(`/Request/${data.requestId}/tag`, { data: { id: data.id, name: data.name } });

    //search links
    getWebSearchLinks = (term: string) =>
        apiClient.get(`/Search/websearch?term=${encodeURIComponent(escapeToSafeCharacters(term))}`);

    //intranet search links
    getIntranetSearchLinks = (term: string) =>
        apiClient.get(`/Search/sharepointsearch?term=${encodeURIComponent(escapeToSafeCharacters(term))}`);

    //post hash tags for comments
    postCommentsHashTags = (params: IPostHashTags) =>
        apiClient.put(`${params.url}/${params.correlationId}/AddHashTags`, params.data);

    //Email endpoints
    getEmailList = (pageNumber: number, pageSize: number, sortProperty: string, sortDirection: number) =>
        apiClient.get(
            `/user/Email/List?${sortDirection ? `&SortDirection=${sortDirection}` : ''}${
                sortProperty ? `&SortProperty=${sortProperty}` : ''
            }&PageNumber=${pageNumber}&PageSize=${pageSize}`
        );
    getEmailById = (emailId: string) => apiClient.get(`/user/Email/${emailId}`);
    postEmail = (params: IEmail) => apiClient.post(`/user/Email`, params);

    //Email template endpoints
    createEmailTemplate = (emailTemplate: IEmailTemplate) => apiClient.post(`/user/EmailTemplate`, emailTemplate);
    getEmailTemplates = () => apiClient.get(`/user/EmailTemplate/List`);
    getEmailTemplateById = (templateId: string) => apiClient.get(`/user/EmailTemplate/${templateId}`);
    deleteEmailTemplateById = (templateId: string) => apiClient.delete(`/user/EmailTemplate/${templateId}`);

    //Add Helpful resources
    addHelpfulResource = (data: IResources) => apiClient.post(`/Article/UrlMetadata`, data);

    //Add emote to comment
    addCommentEmote = (data: IAddEmote) => apiClient.put('/request/Comment/Emote', data);

    // Save a list builder query along with folders
    saveList = (data: IUserList) => apiClient.post('/user/lists', data);

    // Fetch saved list builder queries and folders
    getUserSavedLists = () => apiClient.get('/user/lists');

    // Favorite a saved list
    favoriteList = (data: any) => apiClient.put('/user/lists/Favorite', data);

    // Update the properties ofa list
    updateList = (data: IUserList) => apiClient.put('/user/lists', data);

    // Update the properties of a folder
    updateFolder = (data: IUpdateFolder) => apiClient.put('/user/lists/UpdateFolder', data);

    // Delete a folder
    deleteFolder = (folderId: string) => apiClient.put(`/user/lists/Folder/${folderId}`);

    // Delete a saved list
    deleteList = (queryId: string) => apiClient.put(`/user/lists/Query/${queryId}`);

    // Updates the query of a list
    updateQuery = (data: IListQuery) => apiClient.put('user/lists/UpdateQuery', data);

    // Saves the reordering of lists within a folder
    reorderLists = (data: IListFolder) => apiClient.put('/user/lists/Reorder', data);

    // Saves the reordering of folders
    reorderFolders = (data: IListFolder[]) => apiClient.put('user/lists/Folder/Reorder', data);

    // Gets a list of all ticket submitters
    getSubmitters = () => apiClient.get('/user/submitters');

    // Get download data for list builder query results
    getDownloadListBuilderItems = (data: IListQuery) => {
        const genericApiClient = axios.create({
            baseURL: API.API_SERVER_URL,
            responseType: 'text',
            withCredentials: false,
            headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
        });
        let apiUrl = `/request/DownloadListBuilderItems`;
        return genericApiClient.put(`${apiUrl}`, data);
    };

    fetchListSummary = (data: IListQuery[]) => apiClient.post('/request/UserListSummary', data);
}
