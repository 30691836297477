import { Loader } from 'app/components/loader/Loader';
import { ReportType } from 'app/enums/ReportType.enum';

import { useGetReports } from 'app/services/queries';
import { StringConstants } from 'app/utils/constants';
import React, { useEffect } from 'react';
import PowerBISlider from '../powerBISlider/PowerBISlider';

const UtilizationInsights: React.FC = () => {
    const {
        data: reports,
        isLoading,
        isFetched,
        refetch: getReports
    } = useGetReports(1, 100, '', 'ModifiedOn', '1', false, ReportType.UtilizationInsightsReport);
    useEffect(() => {
        getReports();
    }, []);
    return (
        <>
            {!isLoading &&
                isFetched &&
                (reports.items.length >= 0 ? (
                    <PowerBISlider data={reports.items} title="Utilization Insights Reports" />
                ) : (
                    <div className="height-70 d-flex justify-content-center ai-center message">
                        {StringConstants.UTILIZATION_REPORT_NOT_FOUND_MESSAGE}
                    </div>
                ))}
            {isLoading && !isFetched && <Loader classNames="height-70" />}
        </>
    );
};

export default UtilizationInsights;
