import React from 'react';
import './HomeBanner.scss';

import ResourceCenter from '../resourceCenter';
import InTheNews from '../inTheNews/InTheNews';
import SearchInput from '../search';
import TopArticle from '../topArticle';

const HomeBanner: React.FC = () => {
    return (
        <>
            <div className="home-banner-container">
                <div className="banner-img"></div>

                <TopArticle />
                <div className="container data-container">
                    <SearchInput />
                    <ResourceCenter />
                    <InTheNews />
                </div>
            </div>
        </>
    );
};

export default HomeBanner;
