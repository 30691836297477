import React, { useEffect, useState } from 'react';
import { PersonaInitialsColor, Text } from '@fluentui/react';
import './GridTable.scss';
import { Grid, GridColumn as Column, GridRowClickEvent, GridRowProps } from '@progress/kendo-react-grid';
import { EllipseMenu } from '../triageMenu/EllipseMenu';
import { ColHeadMenu } from './headerCellMenus/';
import useStore from 'app/store';
import { IColumnLookUpProps } from 'app/models/common/post';
import AgingProgress from 'app/components/agingProgress/AgingProgress';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Avatar from 'app/components/avatar/Avatar';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    requestData: any;

    columnsLookUpData: IColumnLookUpProps[];
    page: number;
    setPage: Function;

    isLoading: boolean;
    isPreviousData: boolean;

    isEditMode?: boolean;
    setIsEditMode?: Function;
    isListView?: boolean;
    setIsListView?: Function;
    setSelectedRequest?: Function;
    gridContRef: any;
    createdOnOrder: string;
    setCreatedOnOrder: Function;
    agingOrder: string;
    setAgingOrder: Function;
    revenueOrder: string;
    setRevenueOrder: Function;
    setCurrentSortProp: Function;
    stateData?: any;
}

const GridTable: React.FC<IProps> = ({
    requestData,

    columnsLookUpData,
    page,
    setPage,

    isEditMode,
    setIsEditMode,
    isListView,
    setIsListView,
    setSelectedRequest,
    gridContRef,
    createdOnOrder,
    setCreatedOnOrder,
    agingOrder,
    setAgingOrder,
    revenueOrder,
    setRevenueOrder,
    setCurrentSortProp,
    stateData
}) => {
    const [containerWidth, setContainerWidth] = useState(1367);

    const requestType = useStore((state: any) => state.requestType);
    const newlyAddedRow = useStore((state: any) => state.newlyAddedRow);

    const customCell = (props: Object) => {
        return (
            <td>
                {
                    <EllipseMenu
                        isHidden={requestType === StringConstants.HIDDEN_REQUEST}
                        row={props}
                        origin={StringConstants.LIST_VIEW}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        isListView={isListView}
                        setIsListView={setIsListView}
                        setSelectedRequest={setSelectedRequest}
                        rootIdName="grid-list-view-ellipse-id"
                        page={page}
                        setPage={setPage}
                    />
                }
            </td>
        );
    };
    const AssignedToCell = (props: any) => {
        return (
            <td>
                {props.dataItem?.assignee ? (
                    <div className="assigned-to-cell">
                        <Avatar
                            userId={props.dataItem?.assignee?.id}
                            userName={props.dataItem?.assignee?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">{props.dataItem?.assignee?.displayName}</span>
                    </div>
                ) : (
                    <div className="assigned-to-cell">
                        <Avatar
                            userId={'0'}
                            userName={StringConstants.UNASSIGNED}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">Unassigned</span>
                    </div>
                )}
            </td>
        );
    };

    const HeaderCell = () => {
        return <Text className="request-type-header">REQUEST TYPE</Text>;
    };
    const AgingHeader = () => {
        return (
            <ColHeadMenu
                type={StringConstants.UNASSIGNED}
                agingOrder={agingOrder}
                setAgingOrder={setAgingOrder}
                setCurrentSortProp={setCurrentSortProp}
            />
        );
    };
    const CreatedOnHeader = () => {
        return (
            <ColHeadMenu
                type={StringConstants.CREATED_ON}
                createdOnOrder={createdOnOrder}
                setCreatedOnOrder={setCreatedOnOrder}
                setCurrentSortProp={setCurrentSortProp}
                stateData={stateData}
            />
        );
    };
    const RevenueHeader = () => {
        return (
            <ColHeadMenu
                type={StringConstants.REVENUE}
                revenueOrder={revenueOrder}
                setRevenueOrder={setRevenueOrder}
                setCurrentSortProp={setCurrentSortProp}
                stateData={stateData}
            />
        );
    };

    const customAgingCell = (props: any) => {
        return (
            <td>
                <AgingProgress
                    startDate={props.dataItem.createdOnDate}
                    endDate={props.dataItem.dueDateValue}
                    closedDate={props.dataItem.closedOn}
                />
            </td>
        );
    };

    const TitleCell = (props: any) => {
        return <td title={props.dataItem.title}>{props.dataItem.title}</td>;
    };

    const setWidth = () => {
        let columnCount = columnsLookUpData.filter((item) => item.isChecked).length;

        if (columnCount > 1) {
            let padding = 50 + 47 + 30;
            const availWidth = containerWidth - padding - 90;
            return parseInt((availWidth / columnCount).toString());
        } else {
            return containerWidth;
        }
    };
    const arr = [
        {
            key: 0,
            column: <Column width={setWidth()} className="cell-in-bold" field="id" title="ID" resizable={false} />
        },
        {
            key: 1,
            column: <Column width={setWidth()} field="Title" title="TITLE" cell={TitleCell} resizable={false} />
        },
        {
            key: 2,
            column: (
                <Column width={setWidth()} title="REQUEST TYPE" headerCell={HeaderCell} field="requestType" resizable={false} />
            )
        },
        {
            key: 3,
            column: <Column width={setWidth()} field="status" title="STATUS" resizable={false} />
        },
        {
            key: 4,
            column: (
                <Column width={setWidth() + 30} field="assignedTo" title="ASSIGNED TO" cell={AssignedToCell} resizable={false} />
            )
        },
        {
            key: 5,
            column: (
                <Column width={setWidth()} field="createdOn" title="CREATED ON" headerCell={CreatedOnHeader} resizable={false} />
            )
        },
        {
            key: 6,
            column: <Column width={setWidth()} headerCell={AgingHeader} cell={customAgingCell} resizable={false} />
        },
        {
            key: 7,
            column: (
                <Column
                    width={setWidth() + 50}
                    field="totalRevenueValue"
                    title="TOTAL REVENUE RISK"
                    headerCell={RevenueHeader}
                    resizable={false}
                    className="right-aligned-column"
                />
            )
        },
        {
            key: 8,
            column: <Column width={90} reorderable={false} cell={customCell} resizable={false} />
        }
    ];

    const getColumns = () => {
        let list: any = [];
        columnsLookUpData.forEach((item) => {
            arr.every((ele) => {
                if ((ele.key === item.key && item.isChecked) || item.key === 0) {
                    list.push(ele);
                    return false;
                } else {
                    return true;
                }
            });
        });
        list.push(arr[8]);
        return list;
    };
    const [columnList, setColumnList] = useState(getColumns());

    const onRowClick = (event: GridRowClickEvent) => {
        setIsEditMode(false);
        setIsListView(false);
        setSelectedRequest(event.dataItem);
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        const white = { backgroundColor: 'white' };
        const blue = { backgroundColor: 'rgb(175,238,238)' };
        const trProps: any = { style: props.dataItem.id === newlyAddedRow ? blue : white };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    };

    useEffect(() => {
        if (gridContRef.current?.offsetWidth) {
            setContainerWidth(gridContRef.current.offsetWidth);
        }
    }, [gridContRef.current]);

    useEffect(() => {
        setColumnList(getColumns());
        return () => {};
    }, [columnsLookUpData, requestType]);

    if (requestData) {
        return (
            <>
                <div className="grid-cont" role="main" aria-label="grid table">
                    <Tooltip openDelay={100} position="right" anchorElement="element">
                        <Grid data={requestData} sortable={true} rowRender={rowRender} onRowClick={onRowClick} resizable={false}>
                            {columnList?.map((item: any) => item.column)}
                        </Grid>
                    </Tooltip>
                </div>
            </>
        );
    } else {
        <div>Loading...</div>;
    }
};

export default GridTable;
