import React from 'react';
import './ArticleBanner.scss';
import { Image, Link } from '@fluentui/react';
import { Constants } from 'app/utils/constants';
import { useState } from 'react';
import moment from 'moment';
import { ITags, IUser } from 'app/models/common/post';
import useStore from 'app/store';
import placeholderCategoryIcon from 'app/static/icons/category-icon-Placeholder.svg';
import { useHistory } from 'react-router-dom';
import { keyPress, setQueryParam } from 'app/components/helpers';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { SVGIcons } from 'app/utils/SVGIcons';

interface IProps {
    title: string;
    coverPhoto: string;
    articleDate: Date;
    tags: Array<ITags>;
    createdBy: IUser;
    articleId: string;
    articleType: ArticleType;
}
const ArticleBanner: React.FC<IProps> = (props: IProps) => {
    const bannerPlaceholder =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABDgAAAEYCAQAAAATjY6VAAAD5klEQVR42u3WMQEAAAzCsOHf9FRwkUjo1RwAQFkkAAAMBwBgOAAADAcAYDgAAMMBAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAMBwAAAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwAwHAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAADAcAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAMMBAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAMBwAAAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwAwHAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAADAcAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAMMBAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAMBwAAAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwAwHAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAADAcAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAMMBAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAMBwAAAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwAwHAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAADAcAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAAwHAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAADDAQAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwDAcAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAAMBwAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAAwHAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAADDAQAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwDAcAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAAMBwAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAAwHAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAADDAQAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwDAcAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAAMBwAgOEAAAwHAIDhAAAMBwBgOAAADAcAYDgAAAwHAGA4AADDAQBgOAAAwwEAGA4AAMMBABgOAADDAQAYDgDAcAAAGA4AwHAAAIYDAMBwAACGAwDAcAAAhgMAMBwAAIYDADAcAIDhAAAwHACA4QAAMBwAgOEAAAwHAIDhAAAMBwBgOCQAAAwHAGA4AAAMBwBgOAAAwwEAYDgAAMMBAGA4AADDAQAYDgAAwwEAGA4AwHAAABgOAMBwAAAYDgDAcAAAhgMAwHAAAIYDANjwvQoBGa4Zhw0AAAAASUVORK5CYII=';

    const { title, coverPhoto, articleDate, tags, articleType } = props;
    const history = useHistory();
    const setSelectedTag = useStore((state: any) => state.setSelectedTag);
    const articleCategories: Array<ITags> = useStore((state: any) => state.articleCategories?.items);
    const [toggleMenu, setToggleMenu] = useState(false);
    const handleMenu = () => {
        setToggleMenu(!toggleMenu);
    };

    const handleMenuItemClick = (data: any) => {
        setSelectedTag(data);
        const prefixPath =
            articleType === ArticleType.KnowledgeArticle
                ? '/knowledge-articles'
                : articleType === ArticleType.News
                ? '/news'
                : '/articles';
        const suffixPath = data.displayName !== 'All Categories' ? data.displayName : '';
        setQueryParam(history, prefixPath, { tag: suffixPath });
        handleMenu();
    };

    const getCategoryById = (tagId: string) => {
        var matchingTag = articleCategories?.find((item) => {
            return item.id === tagId;
        });
        return matchingTag;
    };
    return (
        <>
            <div className="newsfeed-article-banner-container">
                <div className="blue--overlay bg-overlay--black"></div>
                <img src={coverPhoto ? coverPhoto : bannerPlaceholder} alt="banner" className="banner-img" />
                <div className="ms-Grid banner-text" dir="ltr">
                    <div className="container ms-Grid-row article-holder">
                        <div className="ms-Grid-col ms-sm12 ms-md12">
                            <span
                                tabIndex={0}        
                                className="ms-Icon back-button"
                                onKeyPress={(e: any) => keyPress(e, () => history.goBack())}
                                onClick={() => history.goBack()}
                                dangerouslySetInnerHTML={{
                                    __html: SVGIcons.ICON_LEFT_ARROW
                                }}></span>
                            <div className="newsfeed-article-banner-date">
                                {moment(articleDate).format(Constants.DATE_FORMAT)}
                            </div>
                            <div className="newsfeed-article-banner-title">{title}</div>
                            <div className="newsfeed-banner-buttons tags">
                                {tags !== undefined &&
                                    tags.length > 0 &&
                                    tags.map((tag: ITags) => {
                                        return (
                                            <Link
                                                tabIndex={0}
                                                className="tag tag--sc tag--blue--shade2"
                                                key={tag.id}
                                                onKeyPress={(e: any) => keyPress(e, () => handleMenuItemClick(tag))}
                                                onClick={() => handleMenuItemClick(tag)}
                                                data-testid="handle-menu">
                                                {
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            getCategoryById(tag.id)?.icon
                                                                ? getCategoryById(tag.id)?.icon
                                                                : placeholderCategoryIcon
                                                        }
                                                        alt="switching out"
                                                    />
                                                }
                                                <span>{tag.displayName}</span>
                                            </Link>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticleBanner;
