import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import ArticleView from 'app/modules/articles/viewArticle/articlesListView/ArticleView';
import EmailList from 'app/pages/admin/emailList/EmailList';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import { isContentManager, isSuperAdmin } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { RouteConstants } from 'app/utils/constants';
const ManageContent: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const selection = pathname.split('/').pop();
    type Selection = 'articles' | 'emails';
    const [currentSelection, setCurrentSelection] = useState<Selection>(selection ? (selection as Selection) : 'articles');

    const userDetails = useStore((state: any) => state.userDetails);

    const itemsWithHref: IBreadcrumbItem[] = [
        { text: 'Home', key: 'home', onClick: () => history.push('/') },
        { text: capitalize(currentSelection), key: currentSelection, isCurrentItem: true }
    ];

    useEffect(() => {
        setCurrentSelection(pathname.split('/').pop() as Selection);
    }, [pathname]);

    return (
        <>
            <div className="container">
                <div className="breadcrumb">
                    <Breadcrumb
                        items={itemsWithHref}
                        maxDisplayedItems={4}
                        ariaLabel="bread crumb"
                        overflowAriaLabel="More links"
                    />
                </div>
            </div>
            <div className="container panel-separator internal-nav m-t-10 m-b-10">
                <nav className="navigation_link" aria-label="header-nav-link">
                    <ul>
                        <li
                            className={currentSelection === 'articles' ? 'active' : ''}
                            onClick={() => {
                                setCurrentSelection('articles');
                                history.push(RouteConstants.MANAGE_CONTENT_ARTICLES);
                            }}
                            data-testid="articles">
                            <Trans>Articles</Trans>
                        </li>
                        {(isContentManager(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles)) && (
                            <li
                                className={currentSelection === 'emails' ? 'active' : ''}
                                onClick={() => {
                                    setCurrentSelection('emails');
                                    history.push('/manage-content/emails');
                                }}
                                data-testid="emails">
                                <Trans>Emails</Trans>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            {currentSelection === 'articles' ? <ArticleView /> : <EmailList />}
        </>
    );
};

export default ManageContent;
