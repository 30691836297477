import React from 'react';

import './index.scss';
import { Trans } from 'react-i18next';
import useStore from 'app/store';
import { useHistory } from 'react-router-dom';
import { Link } from '@fluentui/react';
import { StringConstants } from 'app/utils/constants';
const ReqExpBanner: React.FC = () => {
    const history = useHistory();
    const quickLinksValue = useStore((state: any) => state.quickLinksValue);
    const redirectRequestType = useStore((state: any) => state.redirectRequestType);
    const quickLinksFLow = useStore((state: any) => state.quickLinksFLow);
    const GetBannerText = () => {
        if (!quickLinksValue && redirectRequestType === '') {
            return 'Request Help';
        } else if (quickLinksValue === 1) {
            return 'Help with a Sales question';
        } else if (redirectRequestType === StringConstants.POST_SALES) {
            return 'Help with a Warranty need';
        } else if (redirectRequestType === StringConstants.REVIEW_REQUEST) {
            return 'Help with a Support escalation';
        } else if (redirectRequestType === StringConstants.IRT && quickLinksFLow === 9) {
            return 'Help with a Feature request';
        } else if (redirectRequestType === StringConstants.IRT && quickLinksFLow === 10) {
            return 'Help with a Messaging request';
        }
    };
    return (
        <>
            <div className="container">
                <div className="help-request-banner-container" tabIndex={0}>
                    <ol>
                        <li>
                            <Link
                                data-testid="home-breadcrumb-link-test"
                                className="request-help-breadcrumb-link"
                                aria-label='Entering List, 1 of 2, Home Button'
                                onClick={() => {
                                    history.push('/');
                                }}>
                                <Trans>Home</Trans>
                            </Link>
                        </li>

                        <li>
                            <span tabIndex={0}>
                                <Trans aria-label="List 2 of 2, Request Help Button">{GetBannerText()}</Trans>
                            </span>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
};

export default ReqExpBanner;
