import React, { useState } from 'react';
import { IconButton, Toggle, VerticalDivider } from '@fluentui/react';
import './AddAttachmentModal.scss';
import { IAttachmentUpdateData, IUpdateAttachmentClassification } from 'app/models/common/request';
import { getIcon, keyPress } from 'app/components/helpers';
import { saveAs } from 'file-saver';
import { useUpdateAttachmentClassification } from 'app/services/mutations';
import { useHistory } from 'react-router';
import { Portal } from 'app/enums/Portal.enum';
import { getPortalValue } from 'app/utils/utilities';
import scssVariables from '../../assets/styles/variables.module.scss';
import { blobStorageDownload, getBlobFileNameFromString } from '../../utils/blobStorageHelpers';

interface IProps {
    index: number;
    item: any;
    entityType: number;
    isAttachmentActive: boolean;
    selectedRequest: IAttachmentUpdateData;
    setIsAttachmentActive: (attachment: boolean) => void;
    setIsDataChanged: (something: boolean) => void;
    setRemovalDetails: Function;
    setUploadedAttachments: (attachments: any[]) => void;
    toggleHideDialog: Function;
    uploadedAttachments: any[];
}

const AttachmentRow: React.FC<IProps> = (props) => {
    // portal type
    const portal = getPortalValue(useHistory()?.location?.pathname);

    // props destructuring
    const { index, item, entityType, selectedRequest, setRemovalDetails, toggleHideDialog } = props;

    // set attachment classification toggle
    const [attachmentToggleValue, setAttachmentToggleValue] = useState<boolean>(item.isExternal);
    const onToggleAttachmentClassification = () => {
        // set request data
        let apiRequestData: IUpdateAttachmentClassification = {
            entityId: selectedRequest.requestId,
            entityType: entityType,
            requestType: selectedRequest.requestTypeValue,
            team: selectedRequest.teamValue,
            id: item.id
        };
        // send the attachment to API service
        updateAttachmentClassification(apiRequestData);
        // set toggle checked value
        setAttachmentToggleValue(!attachmentToggleValue);
    };

    // helpers
    const { mutate: updateAttachmentClassification } = useUpdateAttachmentClassification();

    const downloadBlob = async (blobItem: any) => {
        const blobFilename = getBlobFileNameFromString(blobItem);
        const originalFilename = getFileNameFromURL(blobItem);
        await blobStorageDownload(blobFilename)
        .then((res) => {
            if (res) {
                saveAs(res.sasUri, originalFilename, { autoBom: true });
            }
        })
        .catch(err => {
            // log to app insights
        });
    };

    const getFileNameFromURL = (item: any) => {
        if (item.fileName) {
            return item.fileName.split('/').pop();
        } else if (item.url) {
            return getBlobFileNameFromString(item);
        } else {
            return '';
        }
    };

    return (
        <div className="attachment-list attached-file" tabIndex={0}>
            {/* attachment toggle */}
            {portal === Portal.Admin ? (
                <div className="attachment-toggle-group">
                    <span
                        className="internal-attachment"
                        style={{
                            color: attachmentToggleValue ? scssVariables.gray300 : scssVariables.internal,
                            fontWeight: attachmentToggleValue ? 'inherit' : +scssVariables.fontWeight600
                        }}>
                        Internal
                    </span>
                    <Toggle
                        className="attachment-classification-toggle"
                        style={{
                            backgroundColor: attachmentToggleValue ? scssVariables.external : scssVariables.internal
                        }}
                        checked={attachmentToggleValue}
                        ariaLabel="internal external attachment classification switch"
                        onChange={onToggleAttachmentClassification}
                    />
                    <span
                        className="external-attachment"
                        style={{
                            color: attachmentToggleValue ? scssVariables.external : scssVariables.gray300,
                            fontWeight: attachmentToggleValue ? +scssVariables.fontWeight600 : 'inherit'
                        }}>
                        External
                    </span>
                    <VerticalDivider className="attachment-row-divider" />
                </div>
            ) : null}

            {/* file name, icon, and remove button */}
            <div className="attachment-center-group">
                {/* file name and icon */}
                <div
                    className="attachment-file-group"
                    onKeyPress={(e: any) => keyPress(e, () => downloadBlob(item))}
                    tabIndex={0}
                    onClick={() => downloadBlob(item)}>
                    <span className={getIcon(getFileNameFromURL(item))} aria-label="file icon"></span>
                    <span className="file-txt" aria-label="file name">
                        {getFileNameFromURL(item)}
                    </span>
                </div>

                {/* remove attachment */}
                {!selectedRequest.isHidden && (
                    <div className="attachment-remove-group">
                        <VerticalDivider className="attachment-row-divider" />
                            <IconButton
                            role="button"
                            aria-label="remove"
                            tabIndex={0}
                            onClick={(event) => {
                                event.stopPropagation();
                                setRemovalDetails({ i: index, id: item.id });
                                toggleHideDialog();
                            }}
                            onKeyPress={(e: any) =>
                                keyPress(e, () => toggleHideDialog())
                            }
                            iconProps={{ iconName: 'Cancel' }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AttachmentRow;
