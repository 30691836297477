import { Modal, TextField, Text } from '@fluentui/react';
import Button from 'app/components/button/Button';
import { changeDateFormat, dateFormatter, getQueryParam, keyPress, setQueryParam } from 'app/components/helpers';
import { Loader } from 'app/components/loader/Loader';
import MoreOptions from 'app/components/moreOptions/MoreOptions';
import NoData from 'app/components/noData/NoData';
import Pagination from 'app/components/pagination/Pagination';
import { IEmail, IEmailTemplate, IMenuOption } from 'app/models/common/post';
import { IAuditField } from 'app/models/common/request';
import { useCreateEmailTemplate, useDeleteEmailTemplate } from 'app/services/mutations';
import { useGetEmailById, useGetEmailsList, useGetEmailTemplateById, useGetEmailTemplatesList } from 'app/services/queries';
import useStore from 'app/store';
import { Constants } from 'app/utils/constants';
import { SVGIcons } from 'app/utils/SVGIcons';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import './EmailList.scss';

const EmailList = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const columns = [
        {
            title: 'Sent on',
            width: '15%'
        },
        {
            title: 'Subject',
            width: '50%'
        },
        {
            title: 'To',
            width: '30%'
        },
        {
            title: 'Actions',
            width: '5%'
        }
    ];

    type Selection = 'my' | 'other';

    const newPost = () => {
        history.push('/email/create');
    };

    let newPostDropdownOption1: IMenuOption = {
        title: 'Create New',
        handleAction: newPost,
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--PageAdd'
    };
    let newPostDropdownOption2: IMenuOption = {
        title: 'From Template',
        handleAction: () => {
            setShowTemplateModal(true);
            getTemplates().then((res) => {
                showTemplates(res.data);
            });
        },
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--PageList'
    };

    const newPostDropdown = [newPostDropdownOption1, newPostDropdownOption2];

    const [tableData, setTableData] = useState<Array<IEmail>>([]);
    const [pageNumber, setPageNumber] = useState<number>(Number(getQueryParam(history).page ?? 1));
    const [pageSize] = useState<number>(9);
    const [emailId, setEmailId] = useState<string>();
    const [templateId, setTemplateId] = useState<string>();
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<Selection>('my');
    const [myTemplates, setMyTemplates] = useState<Array<IEmailTemplate & IAuditField>>([]);
    const [otherTemplates, setOtherTemplates] = useState<Array<IEmailTemplate & IAuditField>>([]);
    const [showSaveTemplateModal, setShowSaveTemplateModal] = useState<boolean>();
    const [templateName, setTemplateName] = useState<string>('');
    const userDetails = useStore((state: any) => state.userDetails);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { mutateAsync: deleteEmailTemplate, isLoading: isDeletingTemplate, isSuccess: isDeleted } = useDeleteEmailTemplate();

    const {
        isLoading: isLoadingEmails,
        isFetched: isFetchedEmail,
        data: emailsList,
        refetch: getEmails
    } = useGetEmailsList(Number(getQueryParam(history).page ?? 1), pageSize);

    const {
        isLoading: isGettingEmail,
        isFetched: IsEmailFetched,
        data: emailData,
        refetch: getEmailById
    } = useGetEmailById(emailId);

    const {
        data: templatesData,
        isLoading: isLoadingTemplates,
        isFetched: isFetchedTemplates,
        refetch: getTemplates
    } = useGetEmailTemplatesList();

    const {
        isLoading: isGettingTemplate,
        isFetched: isTemplateFetched,
        data: templateData,
        refetch: getTemplateById
    } = useGetEmailTemplateById(templateId);

    const { mutateAsync: saveAsTemplate } = useCreateEmailTemplate();

    const getDetails = (email: IEmail) => {
        setEmailId(email?.id);
        setShowDetailModal(true);
    };

    const hideDetailModal = () => {
        setShowDetailModal(false);
        setEmailId(null);
    };
    const hideTemplateModal = () => {
        setShowTemplateModal(false);
        setTemplateId(null);
    };

    const showTemplates = (templatesData: Array<IEmailTemplate & IAuditField>) => {
        setMyTemplates(
            templatesData?.filter(
                (template: IEmailTemplate & IAuditField) =>
                    template?.displayName && template?.createdBy?.id === userDetails?.id && template?.displayName?.trim() !== ''
            )
        );
        setOtherTemplates(
            templatesData?.filter(
                (template: IEmailTemplate & IAuditField) =>
                    template?.displayName && template?.createdBy?.id !== userDetails?.id && template?.displayName?.trim() !== ''
            )
        );
    };

    const showSaveAsTemplateModal = (emailId: string) => {
        setEmailId(emailId);
        setShowSaveTemplateModal(true);
    };

    const hideSaveAsTemplateModal = () => {
        setShowSaveTemplateModal(false);
        resetSaveAsTemplate();
        setEmailId(null);
    };

    const resetSaveAsTemplate = () => {
        setTemplateName(null);
    };

    const loadTemplate = (template: IEmailTemplate) => {
        history.push({ pathname: `/email/create`, state: { template } });
    };
    const reSend = (dataItemId: string) => {
        setEmailId(dataItemId);
        getEmailById().then((res) => {
            history.push({ pathname: `/email/create`, state: { template: res.data } });
        });
    };
    const viewTemplate = async (id: string) => {
        setTemplateId(id);
    };

    const createTemplate = () => {
        saveAsTemplate({ displayName: templateName, content: emailData?.content } as IEmailTemplate).then(() => {
            hideSaveAsTemplateModal();
        });
    };

    const menuOptionsList = (dataItem: IEmail): Array<IMenuOption> => [
        {
            title: 'View',
            handleAction: () => getDetails(dataItem),
            id: dataItem.id,
            icon: '',
            iconClass: 'ms-Icon ms-Icon--Info'
        },
        {
            title: 'Resend',
            handleAction: () => reSend(dataItem.id),
            id: dataItem.id,
            icon: '',
            iconClass: 'ms-Icon ms-Icon--Forward'
        },
        {
            title: 'Save as template',
            handleAction: () => showSaveAsTemplateModal(dataItem.id),
            id: dataItem.id,
            icon: '',
            iconClass: 'ms-Icon ms-Icon--SaveTemplate'
        }
    ];
    const deleteConfirmationModal = () => {
        setShowAlertModal(true);
    };
    const deleteTemplate = () => {
        if (!isDeletingTemplate) {
            setShowAlertModal(false);
            deleteEmailTemplate(templateId);
        }
    };
    const hideAlertModal = () => {
        setShowAlertModal(false);
    };

    useEffect(() => {
        setQueryParam(
            history,
            pathname,
            {
                page: getQueryParam(history).page ?? 1
            },
            true
        );
    }, []);

    useEffect(() => {
        const { page } = getQueryParam(history);
        setPageNumber(Number(page));
        getEmails();
    }, [history?.location?.search]);

    useEffect(() => {
        setTableData(emailsList?.items);
    }, [emailsList]);

    useEffect(() => {
        if (!isLoadingTemplates && isFetchedTemplates) {
            setMyTemplates(
                templatesData?.filter(
                    (article: IEmailTemplate & IAuditField) =>
                        article?.displayName && article?.createdBy?.id === userDetails?.id && article?.displayName?.trim() !== ''
                )
            );
            setOtherTemplates(
                templatesData?.filter(
                    (article: IEmailTemplate & IAuditField) =>
                        article?.displayName && article?.createdBy?.id !== userDetails?.id && article?.displayName?.trim() !== ''
                )
            );
        }
    }, [templatesData, isLoadingTemplates, isFetchedTemplates]);

    useEffect(() => {
        if (emailId) {
            getEmailById();
        }
    }, [emailId]);

    useEffect(() => {
        if (templateId) {
            getTemplateById();
        }
    }, [templateId]);

    useEffect(() => {
        if (templatesData && !isLoadingTemplates && isFetchedTemplates)
            if (myTemplates?.length > 0) {
                setCurrentSelection('my');
                setTemplateId(myTemplates[0]?.id);
            } else {
                setCurrentSelection('other');
                setTemplateId(otherTemplates[0]?.id);
            }
    }, [myTemplates, otherTemplates]);

    useEffect(() => {
        if (currentSelection === 'my') {
            setTemplateId(myTemplates[0]?.id);
        } else {
            setTemplateId(otherTemplates[0]?.id);
        }
    }, [currentSelection]);

    useEffect(() => {
        if (isDeleted) getTemplates();
    }, [isDeleted]);

    if (!isLoadingEmails && isFetchedEmail) {
        return (
            <>
                <div className="container internal-nav">
                    <nav className="navigation_link d-flex--between ai-center" aria-label="header-nav-link">
                        <ul className="m-a-0">
                            <li className="fs--18">
                                <Trans>Sent emails</Trans>
                            </li>
                        </ul>
                        <MoreOptions class="left bottom new-post" menuArray={newPostDropdown} placeholderText="New Email" />
                    </nav>
                </div>
                <div id="new-feedback" className="table-view email-view container m-t-10 m-b-20">
                    <div className="tv-header">
                        {columns.map((column) => (
                            <div
                                key={column.title}
                                className={`${column.title.toLowerCase()}-header`}
                                style={{ width: column.width }}>
                                {column.title}
                            </div>
                        ))}
                    </div>
                    <div className="tv-body">
                        {tableData?.map((dataItem: IEmail, idx: number) => (
                            <div key={idx} tabIndex={0} className="tv-row">
                                <div className="text-overflow--100" style={{ width: columns[0].width }}>
                                    {dateFormatter(
                                        changeDateFormat(dataItem.createdOn as unknown as Date, Constants.DATE_FORMAT)
                                    )}
                                    <br />
                                    <small>
                                        {changeDateFormat(dataItem.createdOn as unknown as Date, Constants.TIME_FORMAT)}
                                    </small>
                                </div>
                                <div className="text-overflow--100" style={{ width: columns[1].width }}>
                                    {dataItem.subject}
                                </div>
                                <div className="text-overflow--100" style={{ width: columns[2].width }}>
                                    {dataItem.to.join(', ')}
                                </div>
                                <div className="text-overflow--100" style={{ width: columns[3].width }}>
                                    <MoreOptions
                                        pos="right top"
                                        menuArray={menuOptionsList(dataItem)}
                                        fuIconName="MoreVertical"
                                    />
                                </div>
                            </div>
                        ))}
                        {tableData?.length === 0 && (
                            <div className="tv-no-content">
                                <NoData />
                            </div>
                        )}
                    </div>
                </div>

                {tableData?.length > 0 && (
                    <div className="ms-Grid-row text-center">
                        <div className="pagination-container">
                            <Pagination
                                pageCount={emailsList?.totalPages}
                                setPageNumber={setPageNumber}
                                initialPage={pageNumber - 1}
                                totalCount={emailsList?.totalCount}
                                resultsPerPage={pageSize}
                            />
                        </div>
                    </div>
                )}
                <Modal
                    isOpen={showDetailModal}
                    onDismiss={hideDetailModal}
                    isBlocking={false}
                    className="surface-modal large email-detail-modal">
                    <div className="modal-header d-flex--dirReverse">
                        <span
                            className="close"
                            tabIndex={0}
                            onClick={hideDetailModal}
                            onKeyPress={(e) => keyPress(e, hideDetailModal)}>
                            X
                        </span>
                    </div>
                    <div className="modal-body">
                        {!isGettingEmail && IsEmailFetched ? (
                            <>
                                <div className="bg-grey border-radius--8 p-l-15 p-r-15 p-t-5 p-b-5">
                                    {(emailData as IEmail)?.to.length > 0 && (
                                        <div className="text-field">
                                            <span className="label">To:</span>
                                            <span>{(emailData as IEmail)?.to.join(', ')}</span>
                                        </div>
                                    )}
                                    {(emailData as IEmail)?.cc.length > 0 && (
                                        <div className="text-field">
                                            <span className="label">Cc:</span>
                                            <span>{(emailData as IEmail)?.cc.join(', ')}</span>
                                        </div>
                                    )}
                                    {(emailData as IEmail)?.bcc.length > 0 && (
                                        <div className="text-field">
                                            <span className="label">Bcc:</span>
                                            <span>{(emailData as IEmail)?.bcc.join(', ')}</span>
                                        </div>
                                    )}
                                    {(emailData as IEmail)?.subject && (
                                        <div className="text-field">
                                            <span className="label">Subject:</span>
                                            <span>{(emailData as IEmail)?.subject}</span>
                                        </div>
                                    )}
                                </div>
                                {(emailData as IEmail)?.content.length > 0 && (
                                    <div className="text-field">
                                        <span dangerouslySetInnerHTML={{ __html: (emailData as IEmail)?.content }} className="" />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <Loader classNames="height-60" />
                            </>
                        )}
                    </div>
                </Modal>
                <Modal
                    isOpen={showTemplateModal}
                    onDismiss={hideTemplateModal}
                    isBlocking={false}
                    className="surface-modal large template-preview-modal">
                    <div className="modal-header">
                        <span>
                            <Trans>Select Template</Trans>
                        </span>
                        <span
                            className="close"
                            tabIndex={0}
                            onClick={hideTemplateModal}
                            onKeyPress={(e) => keyPress(e, hideTemplateModal)}>
                            X
                        </span>
                    </div>

                    <div className="modal-body">
                        <div className="panel-separator internal-nav m-b-15">
                            <nav className="navigation_link" aria-label="header-nav-link">
                                <ul>
                                    <li
                                        className={currentSelection === 'my' ? 'active' : ''}
                                        onClick={() => {
                                            setCurrentSelection('my');
                                        }}>
                                        <Trans>My templates</Trans>
                                    </li>
                                    <li
                                        className={currentSelection === 'other' ? 'active' : ''}
                                        onClick={() => {
                                            setCurrentSelection('other');
                                        }}>
                                        <Trans>Other templates</Trans>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        {!isLoadingTemplates && isFetchedTemplates ? (
                            <div className="template-Preview">
                                {(currentSelection === 'my' ? myTemplates : otherTemplates).length > 0 && (
                                    <div className="templates">
                                        <ul>
                                            {(currentSelection === 'my' ? myTemplates : otherTemplates)?.map(
                                                (template: IEmailTemplate) => {
                                                    return (
                                                        <li
                                                            key={template.id}
                                                            tabIndex={0}
                                                            onClick={() => viewTemplate(template.id)}
                                                            onKeyPress={(e) => keyPress(e, () => viewTemplate(template.id))}
                                                            className={template.id === templateId ? 'active' : ''}>
                                                            {template.displayName}

                                                            {currentSelection === 'my' && (
                                                                <span
                                                                    title="Delete Template"
                                                                    onClick={deleteConfirmationModal}
                                                                    className="ms-Icon"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: SVGIcons.ICON_TRASH
                                                                    }}></span>
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                )}
                                <div className="preview">
                                    {!isGettingTemplate && isTemplateFetched ? (
                                        <div
                                            className="content"
                                            dangerouslySetInnerHTML={{
                                                __html: templateData?.content
                                            }}></div>
                                    ) : (currentSelection === 'my' ? myTemplates : otherTemplates).length > 0 ? (
                                        <Loader classNames="height-60" />
                                    ) : (
                                        <span className="ai-center d-flex--center height-60 message">No data found</span>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <Loader classNames="height-60" />
                        )}
                    </div>
                    <div className="modal-footer">
                        <Button
                            buttonText="Use this template"
                            buttonClass="btn btn--primary m-r-15"
                            onClick={() => loadTemplate(templateData)}
                            disabled={templateData ? false : true}
                        />
                    </div>
                </Modal>
                <Modal
                    isOpen={showSaveTemplateModal}
                    onDismiss={hideSaveAsTemplateModal}
                    isBlocking={false}
                    className="surface-modal">
                    <div className="modal-header">
                        <span>
                            <Trans>Save Template</Trans>
                        </span>
                        <span
                            className="close"
                            tabIndex={0}
                            onKeyPress={(e: any) => keyPress(e, hideSaveAsTemplateModal)}
                            onClick={hideSaveAsTemplateModal}>
                            X
                        </span>
                    </div>
                    {!isGettingEmail ? (
                        <>
                            <div className="modal-body">
                                <TextField
                                    label={'Enter Template Name'}
                                    name="templateName"
                                    onChange={(e) => setTemplateName((e.target as HTMLInputElement).value)}
                                    errorMessage={
                                        templatesData?.find((item: IEmailTemplate) => item.displayName === templateName)
                                            ? 'Template name already exists'
                                            : ''
                                    }
                                />
                            </div>
                            <div className="modal-footer">
                                <Button buttonClass="btn" buttonText={'Cancel'} onClick={hideSaveAsTemplateModal} />
                                <Button
                                    buttonClass="btn btn--green"
                                    buttonText={'Save'}
                                    onClick={createTemplate}
                                    disabled={
                                        templateName?.trim()?.length === 0 ||
                                        templatesData?.find((item: IEmailTemplate) => item.displayName === templateName)
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </Modal>
                <Modal isOpen={showAlertModal} onDismiss={hideAlertModal} isBlocking={false} className={`surface-modal primary`}>
                    <div className={`modal-body p-b-20 d-flex--center ai-center p-t-20`}>
                        <div className="delete-confirmation">
                            <i className="ms-Icon ms-Icon--WarningSolid jc-center d-flex m-t-40 fs--40" aria-hidden="true"></i>
                            <Text className="jc-center d-flex fs--18 m-t-25">Are you sure you want to delete the template?</Text>
                        </div>
                    </div>
                    <div className="modal-footer d-flex ai-center">
                        <Button buttonText="Continue" buttonClass="btn btn--secondary" onClick={() => deleteTemplate()} />
                        <Button buttonText="Cancel" buttonClass="btn btn--primary" onClick={() => hideAlertModal()} />
                    </div>
                </Modal>
            </>
        );
    } else {
        return <Loader classNames="height-70" />;
    }
};

export default EmailList;
