import { IUserDetails } from 'app/models/common/post';
import { IListBuilderFilter } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface ILookUpProps {
    key: number;
    column: string;
    isChecked: boolean;
}

let store = (set: any) => ({
    //Store variables
    projectName: 'Surface Knowledge Portal',
    requestTitle: '',
    requestDescription: '',
    recentlyAddedData: [' '],
    postCommentsData: [] as Object[],
    redirectRequestType: '',
    redirectSubType: null as number | null,
    impactedRevenue: '',
    impactedDevices: '',
    successPopUp: false,
    count: {},
    requestType: 'MANAGE_REQUEST',
    quickLinksValue: null as number | null,
    accessToken: '',
    userDetails: {},
    msGraphAccessToken: '',
    accountDetails: {},
    selectedTag: {},
    userSearchValue: '',
    adminTicketsSearchValue: '',
    adminArticlesSearch: '',
    originalRoutingML : null as boolean,
    //admin
    isBoxchecked: { all: false, new: true, active: false, resolved: false },
    lastSelectedRequest: {},
    isListView: true, 
    requestData: [] as IRequest[],
    selectionData: {},
    page: 1,
    selectedTab: '0',
    triageModalSuccess: false,
    triageModalSuccessNewModel: false,
    view: 'listView',
    card: 'manageMy',
    newlyAddedRow: '',
    reloadData: false,
    attachedFiles: [] as Object[],
    visualFlowData: {},
    visibleColumns: [] as Array<number>,
    columnOrder: [] as Array<number>,
    columnsLookUpData: [
        {
            key: 0,
            column: 'Team',
            isChecked: true
        },
        {
            key: 1,
            column: 'ID',
            isChecked: true
        },
        {
            key: 2,
            column: 'Title',
            isChecked: true
        },
        {
            key: 3,
            column: 'Request Type',
            isChecked: true
        },
        {
            key: 4,
            column: 'Status',
            isChecked: true
        },
        {
            key: 5,
            column: 'Created On',
            isChecked: true
        },
        {
            key: 6,
            column: 'Aging',
            isChecked: true
        },
        {
            key: 7,
            column: 'Total Revenue Risk',
            isChecked: true
        }
    ],
    searchOrigin: '',
    articleCategories: [] as Array<Object>,
    searchValueClear: true,
    cardTypeFilter: 0,
    quickLinksFLow: null as number | null,
    quickLinksID: null as number | null,
    showNavigationPrompt: false,
    previousLocation: '',
    listBuilderFilters: null as IListBuilderFilter,
    filterPanelDisabled: false,
    listIcon: '',
    listId: '',
    //Set functions
    setNewlyAddedRow: (req: any) => set((state: any) => ({ newlyAddedRow: req })),
    addRequestTitle: (req: any) => set((state: any) => ({ requestTitle: req })),
    addRequestDescription: (req: any) => set((state: any) => ({ requestDescription: req })),
    addRecentData: (req: any) => set((state: any) => ({ recentlyAddedData: req })),
    addRevenueData: (req: any) => set((state: any) => ({ impactedRevenue: req })),
    addDevicesData: (req: any) => set((state: any) => ({ impactedDevices: req })),
    addCommentsData: (req: any) => set((state: any) => ({ postCommentsData: [...state.postCommentsData, req] })),
    addFiles: (file: any) => set((state: any) => ({ attachedFiles: file })),
    clearFiles: () => set((state: any) => ({ attachedFiles: [] as Object[] })),
    newCommentsData: (req: any) => set((state: any) => ({ postCommentsData: req })),
    addSuccessPopUp: (req: any) => set((state: any) => ({ successPopUp: req })),
    addCount: (c1: any, c2: any, c3: any) =>
        set((state: any) => ({
            count: { allSDSGCount: c1, allCount: c2, closedAllCount: c3 }
        })),
    setRequestData: (req: any) => set((state: any) => ({ requestData: req })),
    setQuickLinksFLow: (value: any) => set((state: any) => ({ quickLinksFLow: value })),

    setRequestType: (req: any) => set((state: any) => ({ requestType: req })),
    setRedirectRequestType: (req: any) => set((state: any) => ({ redirectRequestType: req })),
    setRedirectSubType: (req: any) => set((state: any) => ({ redirectSubType: req })),
    setSelectionData: (val: any) => set((state: any) => ({ selection: val })),
    addTriageModalSuccess: (req: any) => set((state: any) => ({ triageModalSuccess: req })),
    addTriageModalSuccessNewModel: (req: any) => set((state: any) => ({ triageModalSuccessNewModel: req })),
    changeView: (req: any) => set((state: any) => ({ view: req })),
    changeCard: (req: any) => set((state: any) => ({ card: req })),
    setAccessToken: (token: any) => set((state: any) => ({ accessToken: token })),
    setUserInfo: (userInfo: IUserDetails) => set((state: any) => ({ userDetails: userInfo })),
    setMSGraphAccessToken: (token: any) => set((state: any) => ({ msGraphAccessToken: token })),
    setAccountDetails: (account: any) => set((state: any) => ({ accountDetails: account })),
    setReloadData: (req: any) => set((state: any) => ({ reloadData: req })),
    setQuickLinksValue: (val: number | null) => set((state: any) => ({ quickLinksValue: val })),
    setQuickLinksID: (val: number | null) => set((state: any) => ({ quickLinksID: val })),
    setVisualFlowData: (req: any) => set((state: any) => ({ visualFlowData: req })),
    setUserSearchValue: (value: string) => set((state: any) => ({ userSearchValue: value })),
    setAdminTicketsSearchValue: (value: string) => set((state: any) => ({ adminTicketsSearchValue: value })),
    setAdminArticlesSearch: (value: string) => set((state: any) => ({ adminArticlesSearch: value })),
    setVisibleColumns: (value: Number) => set((state: any) => ({ visibleColumns: [...state.visibleColumns, value] })),
    setColumnOrder: (arr: Array<Number>) => set((state: any) => ({ columnOrder: arr })),
    setColumnsLookUp: (arr: Array<ILookUpProps>) => set((state: any) => ({ columnsLookUpData: arr })),
    setSelectedTag: (value: Object) => set((state: any) => ({ selectedTag: value })),
    setEditArticleId: (value: Object) => set((state: any) => ({ editArticleId: value })),
    setPage: (value: number) => set((state: any) => ({ page: value })),
    setArticleCategories: (value: Object[]) => set((state: any) => ({ articleCategories: value })),
    setSelectedTab: (val: string) => set((state: any) => ({ selectedTab: val })),
    setSearchOrigin: (val: string) => set((state: any) => ({ searchOrigin: val })),
    setSearchValueClear: (val: string) => set((state: any) => ({ searchValueClear: val })),
    setCardTypeFilter: (val: number) => set((state: any) => ({ searchValueClear: val })),
    setToInitialLookup: () =>
        set((state: any) => ({
            columnsLookUpData: [
                {
                    key: 0,
                    column: 'Team',
                    isChecked: true
                },
                {
                    key: 1,
                    column: 'ID',
                    isChecked: true
                },
                {
                    key: 2,
                    column: 'Title',
                    isChecked: true
                },
                {
                    key: 3,
                    column: 'Request Type',
                    isChecked: true
                },
                {
                    key: 4,
                    column: 'Status',
                    isChecked: true
                },
                {
                    key: 5,
                    column: 'Created On',
                    isChecked: true
                },
                {
                    key: 6,
                    column: 'Aging',
                    isChecked: true
                },
                {
                    key: 7,
                    column: 'Total Revenue Risk',
                    isChecked: true
                }
            ]
        })),
    setIsListView: (val: boolean) => set((state: any) => ({ isListView: val })),
    setLastSelectedRequest: (val: any) => set((state: any) => ({ lastSelectedRequest: val })),
    setBoxChange: (val: any) => set((state: any) => ({ isBoxchecked: val })),
    setShowNavigationPrompt: (val: boolean) => set((state: any) => ({ showNavigationPrompt: val })),
    setOriginalRoutingML : (val: boolean) => set((state: any) => ({originalRoutingML : val})),
    setPreviousLocation: (val: string) => set((state: any) => ({ previousLocation: val })),
    setListBuilderFilters: (val: IListBuilderFilter) => set((state: any) => ({ listBuilderFilters: val })),
    setFilterPanelDisabled: (val: boolean) => set((state: any) => ({ filterPanelDisabled: val })),
    setListIcon: (val: any) => set((state: any) => ({ listIcon: val })),
    setListId: (val: any) => set((state: any) => ({ listId: val }))
});
const useStore = create(persist(store, { name: 'req' }));
export default useStore;
