import { Modal, TextField, Text, IIconProps, IconButton, IButtonStyles, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import backArrowLb from '../../../static/icons/backArrowLb.svg';
import { IListFolder } from 'app/models/common/post';
import Button from 'app/components/button/Button';
import { useSaveList } from 'app/services/mutations';

interface IProps {
    editMode: boolean;
    setFolders?: Function;
    setSelectedFolder?: Function;
    folders?: any;
    showModal?: any;
}

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: 'white',
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        height: '50px',
        checked: {
            border: '2px solid green'
        }
    }
};

export const AddFolderManageListsModal = () => {
    const [isModalOpen, { setTrue: showFolderModal, setFalse: hideFolderModal }] = useBoolean(false);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const [newFolder, setNewFolder] = useState<IListFolder>({
        name: '',
        savedListQueries: []
    });

    const { mutate: saveList, isLoading: isListSaving } = useSaveList();

    const AddFolderModal: React.FC<IProps> = ({ editMode, folders, setFolders, showModal }) => {
        const handleCancelClick = () => {
            setNewFolder({
                name: '',
                savedListQueries: []
            });
            hideFolderModal();
        };

        const AddFolder = () => {
            if (editMode) {
                hideFolderModal();
                showModal();
                setFolders([...folders, newFolder]);

                setNewFolder(null);
            } else {
                saveList(
                    { listFolders: [newFolder] },
                    {
                        onSuccess: () => {
                            setNewFolder({
                                name: '',
                                savedListQueries: []
                            });
                            hideFolderModal();
                        }
                    }
                );
            }
        };

        const onChangeHandler = (e: any) => {
            setNewFolder({ ...newFolder, name: e.target.value });
        };

        return (
            <div>
                <Modal isOpen={isModalOpen} onDismiss={hideFolderModal} isBlocking={false} className="">
                    {isListSaving ? (
                        <>
                            <Spinner
                                label="Saving folder. Please wait."
                                size={SpinnerSize.large}
                                className="saving-list-spinner"
                            />
                        </>
                    ) : (
                        <>
                            <div className="folder-modal-header">
                                <div className="addFolderHeader">Add Folder</div>
                                <div>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={handleCancelClick}
                                    />
                                </div>
                            </div>
                            <div className="backArrowStyles" onClick={handleCancelClick}>
                                <img src={backArrowLb} alt="back arrow" /> <Text className="addFolderBackArrow">Add Folder</Text>
                            </div>
                            <div className="textField-btn-container">
                                <div>
                                    <div className="save-folder-header">Name</div>
                                    <TextField
                                        className="textfield-folder"
                                        value={newFolder?.name}
                                        onChange={(e: any) => {
                                            onChangeHandler(e);
                                        }}
                                        autoFocus
                                    />
                                </div>
                                <div>
                                    <div className="folder-modal-footer">
                                        <Button
                                            buttonClass="btn btn--secondary"
                                            buttonText="Cancel"
                                            onClick={handleCancelClick}
                                        />
                                        <Button
                                            disabled={newFolder?.name?.trim().length > 0 ? false : true}
                                            buttonClass="btn btn--primary"
                                            buttonText="Add"
                                            onClick={AddFolder}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Modal>
            </div>
        );
    };

    return { AddFolderModal, showFolderModal, hideFolderModal };
};
