import React from 'react';
import { Dropdown} from '@fluentui/react';
import { DatePicker, Text } from '@fluentui/react';
import moment from 'moment';


interface IProps {
    selectedTeam: any;
    reassignTeamHText: any;
    requestTypeHText: any;
    reasonHText: any;
    selectedRequest: any;
    selectedReason: any;
    selectedDate: any;
    onReassignTeamChange: any;
    onRequestTypeChange: any;
    onReasonChange: any;
    reassignTeamOptions: any;
    reqTypeOp: any;
    reasonOptions: any;
    teamNotSelectedHText: boolean;
    setTeamNotSelectedHText: Function;
    setRequestTypeHText: Function;
    setSelectedDate: Function;
    setDateSent: Function;
}

export const ReassignReq: React.FC<IProps> = ({
    selectedTeam,
    reassignTeamHText,
    selectedRequest,
    requestTypeHText,
    reasonHText,
    selectedReason,
    onReassignTeamChange,
    onRequestTypeChange,
    onReasonChange,
    selectedDate,
    reassignTeamOptions,
    reqTypeOp,
    reasonOptions,
    teamNotSelectedHText,
    setTeamNotSelectedHText,
    setRequestTypeHText,
    setSelectedDate,
    setDateSent,
 
}) => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date(Date.now()).getDate() + 1);

    const handleFocus = () => {
        if (!selectedTeam) {
            setTeamNotSelectedHText(true);
            setRequestTypeHText(false);
        }
    };
    return (
        <>
            <div className="form-row form-inline">
                <div className="select-team-field">
                    <label>
                        Reassign Team<span className="required">*</span>
                    </label>
                    <Dropdown
                        aria-label="Reassign Team"
                        selectedKey={selectedTeam ? selectedTeam.key : undefined}
                        onChange={onReassignTeamChange}
                        placeholder="Select Team"
                        className={`${reassignTeamHText ? 'error-field' : ''}`}
                        options={reassignTeamOptions}
                    />

                    {reassignTeamHText && (
                        <Text className="helper-text">Please select a team for this request to be reassigned to.</Text>
                    )}
                </div>

                <div className="select-type-field">
                    <label>
                        Request type<span className="required">*</span>
                    </label>
                    <Dropdown
                        aria-label="Request type"
                        selectedKey={selectedRequest ? selectedRequest.key : undefined}
                        onChange={onRequestTypeChange}
                        placeholder="Select Request Type"
                        className={`${requestTypeHText || teamNotSelectedHText ? 'error-field' : ''}`}
                        options={reqTypeOp}
                        data-testid="request-type-test"
                        onFocus={handleFocus}
                    />
                    {requestTypeHText && (
                        <Text className="helper-text">Please select the request type of the selected team.</Text>
                    )}
                    {teamNotSelectedHText && <Text className="helper-text">*Select a Team</Text>}
                </div>
            </div>

            <div className="form-row form-inline">
                <div className="select-reason-field">
                    <label>
                        Reason for Reassignment<span className="required">*</span>
                    </label>
                    <Dropdown
                        aria-label="Reason for Reassignment"
                        selectedKey={selectedReason ? selectedReason.key : undefined}
                        onChange={onReasonChange}
                        className={`${reasonHText ? 'error-field' : ''}`}
                        placeholder="Select Reason"
                        options={reasonOptions}
                    />
                    {reasonHText && <Text className="helper-text">Please specify the reason for this reassignment.</Text>}
                </div>
                <div className="select-date-field">
                    <label>
                        Requested Resolution Date<span className="required">*</span>
                    </label>
                    <DatePicker
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        minDate={moment().toDate()}
                        value={selectedDate}
                        onSelectDate={(newValue: any) => {
                            setSelectedDate(newValue);
                            let currentTime = moment();

                            setDateSent(
                                moment(newValue)
                                    .set({
                                        hours: currentTime.hours(),
                                        minutes: currentTime.minutes(),
                                        seconds: currentTime.seconds()
                                    })
                                    .toISOString()
                            );
                        }}
                        data-testid="start-date"
                    />
                </div>
            </div>
        </>
    );
};
