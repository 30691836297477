import { ListBuilderCondition } from 'app/enums/ListBuilderCondition.enum';
import { RequestType } from 'app/enums/RequestType.enum';
import { RequestStatus } from 'app/enums/Status.enum';
import { IListOptionProps } from '../models/models';
import '../saveList/SaveList.scss';
import postIt from '../../../../static/icons/postIt.svg';
import settingsLBIcon from '../../../../static/icons/settingsLBIcon.svg';
import starLBIcon from '../../../../static/icons/starLBIcon.svg';
import flagLBIcon from '../../../../static/icons/flagLBIcon.svg';
import warningLBIcon from '../../../../static/icons/warningLBIcon.svg';
import trophyLbIcon from '../../../../static/icons/trophyLbIcon.svg';
import notePadLBIcon from '../../../../static/icons/notePadLBIcon.svg';
import worldLBIcon from '../../../../static/icons/worldLBIcon.svg';
import alarmClockLBIcon from '../../../../static/icons/alarmClockLBIcon.svg';
import bugLBIcon from '../../../../static/icons/bugLBIcon.svg';
import messageLbIcon from '../../../../static/icons/messageLbIcon.svg';
import lightningBoltLBIcon from '../../../../static/icons/lightningBoltLBIcon.svg';
import questionMarkLBIcon from '../../../../static/icons/questionMarkLBIcon.svg';
import CheckboxLBIcon from '../../../../static/icons/CheckboxLBIcon.svg';
import selectedPostIt from 'app/static/icons/listbuilder-postit-selected.svg';
import selectedBug from 'app/static/icons/listbuilder-bug-selected.svg';
import selectedCaution from 'app/static/icons/listbuilder-caution-selected.svg';
import selectedChat from 'app/static/icons/listbuilder-chat-selected.svg';
import selectedCheck from 'app/static/icons/listbuilder-check-selected.svg';
import selectedClipboard from 'app/static/icons/listbuilder-clipboard-selected.svg';
import selectedClock from 'app/static/icons/listbuilder-clock-selected.svg';
import selectedFlag from 'app/static/icons/listbuilder-flag-selected.svg';
import selectedQuestion from 'app/static/icons/listbuilder-question-selected.svg';
import selectedSettings from 'app/static/icons/listbuilder-settings-selected.svg';
import selectedStar from 'app/static/icons/listbuilder-star-selected.svg';
import selectedThunder from 'app/static/icons/listbuilder-thunder-selected.svg';
import selectedTrophy from 'app/static/icons/listbuilder-trophy-selected.svg';
import selectedWorld from 'app/static/icons/listbuilder-world-selected.svg';
import { QueryIcon } from 'app/enums/QueryIcon.enum';
import { AssociationType } from 'app/enums/AssociationType.enum';
import { IUserDetails } from 'app/models/common/post';

export const ticketTypeOptions: IListOptionProps[] = [
    { key: 'CREW', text: 'CREW Tickets' },
    { key: 'CSS', text: 'CSS Tickets' },
    { key: 'CXP', text: 'CXP Tickets' },
    { key: 'SDSG', text: 'SDSG Tickets' }
];

export const handleGrabIcon = (item: string) => {
    let num = parseInt(item);
    return iconOptions[num]?.imageSrc;
}
export const handleGrabWhiteIcon = (item: string) => {
    let num = parseInt(item);
    return iconOptions[num]?.imageSrc;
}

export const handleGrabWhiteIconNumber = (num: number) => {
    return iconOptions[num]?.imageSrc;
}
export const iconOptions: any[] = [
    {
        key: 0,
        imageSrc: postIt,
        imageAlt: 'Post It Icon',
        selectedImageSrc: selectedPostIt,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.PostIt
    },
    {
        key: 1,
        imageSrc: bugLBIcon,
        imageAlt: 'Bug Icon',
        selectedImageSrc: selectedBug,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Bug
    },
    {
        key: 2,
        imageSrc: settingsLBIcon,
        imageAlt: 'Settings Icon',
        selectedImageSrc: selectedSettings,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Settings
    },
    {
        key: 3,
        imageSrc: starLBIcon,
        imageAlt: 'Star Icon',
        selectedImageSrc: selectedStar,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Star
    },
    {
        key: 4,
        imageSrc: flagLBIcon,
        imageAlt: 'Flag Icon',
        selectedImageSrc: selectedFlag,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Flag
    },
    {
        key: 5,
        imageSrc: warningLBIcon,
        imageAlt: 'Warning Icon',
        selectedImageSrc: selectedCaution,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Warning
    },
    {
        key: 6,
        imageSrc: messageLbIcon,
        imageAlt: 'Message Icon',
        selectedImageSrc: selectedChat,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Message
    },
    {
        key: 7,
        imageSrc: CheckboxLBIcon,
        imageAlt: 'Checkbox',
        selectedImageSrc: selectedCheck,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Checkbox
    },
    {
        key: 8,
        imageSrc: notePadLBIcon,
        imageAlt: 'Note Pad Icon',
        selectedImageSrc: selectedClipboard,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Notepad
    },
    {
        key: 9,
        imageSrc: worldLBIcon,
        imageAlt: 'World Icon',
        selectedImageSrc: selectedWorld,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.World
    },
    {
        key: 10,
        imageSrc: trophyLbIcon,
        imageAlt: 'Trophy Icon',
        selectedImageSrc: selectedTrophy,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Trophy
    },
    {
        key: 11,
        imageSrc: lightningBoltLBIcon,
        imageAlt: 'Lightning Bolt Icon',
        selectedImageSrc: selectedThunder,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Lightning
    },
    {
        key: 12,
        imageSrc: alarmClockLBIcon,
        imageAlt: 'Alarm Clock Icon',
        selectedImageSrc: selectedClock,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Alarm
    },
    {
        key: 13,
        imageSrc: questionMarkLBIcon,
        imageAlt: 'Question Mark Icon',
        selectedImageSrc: selectedQuestion,
        imageSize: { width: 20, height: 20 },
        text: '',
        enumValue: QueryIcon.Question
    },
];

// export const requestTeamTypeOptions = [
    // { key: 'A', text: 'CREW', itemType: DropdownMenuItemType.Header, teamOptionKey: 'A' },
    // { key: 'B', text: 'Product', enumValue: RequestType.Crew_ProductRequest, teamOptionKey: 'A' },
    // { key: 'C', text: 'Messaging', enumValue: RequestType.Crew_MessagingRequest, teamOptionKey: 'A' },
    // { key: 'D', text: 'Warranty', enumValue: RequestType.Crew_WarrantyRequest, teamOptionKey: 'A' },
    // { key: 'E', text: 'Pre Sales', enumValue: RequestType.PreSales, teamOptionKey: 'A' },
    // { key: 'F', text: 'Post Sales', enumValue: RequestType.PostSales, teamOptionKey: 'A' },
    // { key: 'W', text: 'CSS', itemType: DropdownMenuItemType.Header, teamOptionKey: 'B' },
    // { key: 'G', text: 'Review', enumValue: RequestType.CssReviewRequest, teamOptionKey: 'B' },
    // { key: 'X', text: 'CXP', itemType: DropdownMenuItemType.Header, teamOptionKey: 'C' },
    // { key: 'H', text: 'Technical', enumValue: RequestType.Cxp_TechnicalRequest, teamOptionKey: 'C' },
    // { key: 'I', text: 'Messaging', enumValue: RequestType.Cxp_MessagingRequest, teamOptionKey: 'C' },
    // { key: 'J', text: 'PFR', enumValue: RequestType.Cxp_PfrRequest, teamOptionKey: 'C' },
    // { key: 'K', text: 'Variant', enumValue: RequestType.Cxp_VariantRequest, teamOptionKey: 'C' },
    // { key: 'Y', text: 'SDSG', itemType: DropdownMenuItemType.Header, teamOptionKey: 'D' },
    // { key: 'L', text: 'RFX', enumValue: RequestType.Rfx, teamOptionKey: 'D' },
    // { key: 'M', text: 'Question', enumValue: RequestType.Question, teamOptionKey: 'D' },
    // { key: 'N', text: 'Proposal', enumValue: RequestType.Proposal, teamOptionKey: 'D' }
// ];

export const requestTeamTypeOptions = [
    {
        key: 'all_request',

        text: 'All request types'
    },
    {
        key: 'SDSG',

        text: 'Surface Deal Success Group (SDSG)',

        dependentProperties: ['rfx_request', 'proposal_request', 'question_request']
    },
    { key: 'rfx_request', text: 'Rfx', parentProperty: 'SDSG', enumValue: RequestType.Rfx },
    {
        key: 'proposal_request',

        text: 'Proposal',

        parentProperty: 'SDSG',
        enumValue: RequestType.Proposal
    },
    {
        key: 'question_request',

        text: 'Question',

        parentProperty: 'SDSG',
        enumValue: RequestType.Question
    },
    {
        key: 'CXP',

        text: 'Customer Experience Program Management (CXP)',

        dependentProperties: ['variant_request', 'feature_request', 'messaging_request', 'technical_request', 'program_request']
    },
    {
        key: 'variant_request',

        text: 'Variant',

        parentProperty: 'CXP',
        enumValue: RequestType.Cxp_VariantRequest
    },
    {
        key: 'feature_request',

        text: 'PFR',

        parentProperty: 'CXP',
        enumValue: RequestType.Cxp_PfrRequest
    },
    {
        key: 'messaging_request',

        text: 'Messaging',

        parentProperty: 'CXP',
        enumValue: RequestType.Cxp_MessagingRequest
    },
    {
        key: 'technical_request',

        text: 'Technical',

        parentProperty: 'CXP',
        enumValue: RequestType.Cxp_TechnicalRequest
    },
    {
        key: 'program_request',

        text: 'Program',

        parentProperty: 'CXP',
        enumValue: RequestType.Cxp_ProgramRequest
    },
    {
        key: 'CSS',

        text: 'Customer Service and Support (CSS)',

        dependentProperties: ['review_request']
    },
    {
        key: 'review_request',

        text: 'Review Request',

        parentProperty: 'CSS',
        enumValue: RequestType.CssReviewRequest
    },
    {
        key: 'CREW',

        text: 'Customer Repair Experience Warranty (CREW)',

        dependentProperties: [
            'pre_sales_request',
            'post_sales_request',
            'warranty_request',
            'product_request',
            'crew_messaging_request'
        ]
    },
    {
        key: 'pre_sales_request',

        text: 'Pre Sales',

        parentProperty: 'CREW',
        enumValue: RequestType.PreSales
    },

    {
        key: 'post_sales_request',

        text: 'Post Sales',

        parentProperty: 'CREW',
        enumValue: RequestType.PostSales
    },

    {
        key: 'warranty_request',

        text: 'Warranty',

        parentProperty: 'CREW',
        enumValue: RequestType.Crew_WarrantyRequest
    },

    {
        key: 'product_request',

        text: 'Product',

        parentProperty: 'CREW',
        enumValue: RequestType.Crew_ProductRequest
    },
    {
        key: 'crew_messaging_request',

        text: 'Messaging',

        parentProperty: 'CREW',
        enumValue: RequestType.Crew_MessagingRequest
    }
]

const equalsOperators = [
    { key: 1, text: 'equals (=)', enumValue: ListBuilderCondition.Equals },
    { key: 2, text: 'does not equal', enumValue: ListBuilderCondition.DoesNotEqual }
];

const containsOperators = [
    { key: 1, text: 'contains', enumValue: ListBuilderCondition.Contains },
    { key: 2, text: 'does not contain', enumValue: ListBuilderCondition.DoesNotContain }
];

const greaterLessThanOperators = [
    { key: 0, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    { key: 1, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan }
];

const olderYoungerThanOperators = [
    { key: 0, text: 'older than', enumValue: ListBuilderCondition.OlderThan },
    { key: 1, text: 'younger than', enumValue: ListBuilderCondition.YoungerThan }
];

const beforeAfterOperators = [
    { key: 0, text: 'Before', enumValue: ListBuilderCondition.Before },
    { key: 1, text: 'After', enumValue: ListBuilderCondition.After },
    { key: 2, text: 'On', enumValue: ListBuilderCondition.On }
];

export const allOperators = [
    ...equalsOperators,
    ...containsOperators,
    ...greaterLessThanOperators,
    ...olderYoungerThanOperators,
    ...beforeAfterOperators
];

const statusPicker = [
    { key: 0, text: 'New', enumValue: RequestStatus.New },
    { key: 1, text: 'Active', enumValue: RequestStatus.Active },
    { key: 3, text: 'Resolved', enumValue: RequestStatus.Resolved },
    { key: 4, text: 'Closed', enumValue: RequestStatus.Closed }
];

const deviceCategories = [
    { key: 0, text: 'Surface Duo', categoryId: 4 },
    { key: 1, text: 'Surface Book', categoryId: 3 },
    { key: 2, text: 'Surface Pro', categoryId: 8 },
    { key: 3, text: 'Surface Go', categoryId: 5 },
    { key: 4, text: 'Surface Hub', categoryId: 6 },
    { key: 5, text: 'Surface Laptop', categoryId: 7 },
    { key: 6, text: 'Surface Accessories', categoryId: 1 },
    { key: 7, text: 'Surface Audio', categoryId: 2 },
    { key: 8, text: 'Others', categoryId: 10 }
];

const relationshipOptions = [
    { key: 0, text: 'None', enumValue: AssociationType.None },
    { key: 1, text: 'Parent', enumValue: AssociationType.Parent },
    { key: 2, text: 'Child', enumValue: AssociationType.Child }
];

export const adminTempData: IUserDetails[] = [
    {
        displayName: 'Whitney Meulink',
        email: 'v-wmeulink@microsoft.com',
        id: 'bde160ea-a926-42dc-b823-88224ee0072d',
        userDomain: null,
        userRoles: []
    },
    {
        displayName: 'Ben Carpenter',
        email: 'bencarp@microsoft.com',
        id: 'b39beca0-61ab-40ff-b41d-4cb56310460c',
        userDomain: 1,
        userRoles: []
    },
    {
        displayName: 'Andrew Joung',
        email: 'andrewjoung@microsoft.com',
        id: '5d4081c9-b62c-4762-ab2a-872d23aa671d',
        userDomain: 2,
        userRoles: []
    },
    {
        displayName: 'Steven Miller',
        email: 'v-stemiller@microsoft.com',
        id: '0866f954-c8ef-43ce-a45c-99fbeb246927',
        userDomain: 2,
        userRoles: []
    },
    {
        displayName: 'Rohan Brooker',
        email: 'v-stemiller@microsoft.com',
        id: '0866998924-c8ef-43ce-a45c-99fbeb246927',
        userDomain: 2,
        userRoles: []
    }
];

// Team selectable fields
// Number of unique keys: 42

export const sdsgFields: IListOptionProps[] = [
    { key: '1', text: 'Status', condition: equalsOperators, picker: statusPicker, paramsProperty: 'status' },
    { key: '3', text: 'Title', condition: containsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'title' },
    {
        key: '4',
        text: 'Created On',
        condition: beforeAfterOperators,
        picker: [{ key: 0, text: 'Date Picker' }],
        paramsProperty: 'createdOn'
    },
    {
        key: '6',
        text: 'Assigned To',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'assignedTo'
    },
    {
        key: '42',
        text: 'Submitter',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'submittedBy'
    },
    { key: '7', text: 'Tags', condition: equalsOperators, picker: [{ key: 0, text: 'Tag Picker' }], paramsProperty: 'tags' },
    {
        key: '8',
        text: 'Description',
        condition: containsOperators,
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'description'
    },
    {
        key: '9',
        text: 'Total Revenue Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalRevenueRisk'
    },
    {
        key: '10',
        text: 'Total Device Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalDeviceRisk'
    },
    {
        key: '11',
        text: 'Aging',
        condition: [{ key: 1, text: 'equals (=)', enumValue: ListBuilderCondition.Equals }],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'aging'
    },
    {
        key: '12',
        text: 'Troubled Product',
        condition: equalsOperators,
        picker: deviceCategories,
        paramsProperty: 'troubledProductCategory'
    },
    // {
    //     key: '14',
    //     text: 'Requested Resolution Dates',
    //     condition: [
    //         { key: 0, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 1, text: 'more than', enumValue: ListBuilderCondition.OlderThan },
    //         { key: 2, text: 'On', enumValue: ListBuilderCondition.On }
    //     ],
    //     picker: [{ key: 0, text: 'today' }],
    //     paramsProperty: 'requestedResolutionDate'
    // },
    {
        key: '16',
        text: 'MSX Opportunity',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'msxOpportunity'
    },
    {
        key: '17',
        text: 'MSX Status',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Open' },
            { key: 1, text: 'Won' },
            { key: 2, text: 'Lost' }
        ],
        paramsProperty: 'msxStatus'
    },
    { key: '18', text: 'TPID', condition: equalsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'tpid' },
    {
        key: '19',
        text: 'End customer',
        condition: [
            { key: 0, text: 'equals (=)', enumValue: ListBuilderCondition.Equals },
            { key: 1, text: 'does not equal', enumValue: ListBuilderCondition.DoesNotEqual },
            { key: 2, text: 'contains', enumValue: ListBuilderCondition.Contains },
            { key: 3, text: 'does not contain', enumValue: ListBuilderCondition.DoesNotContain }
        ],
        picker: [{ key: 0, text: 'Customer Search' }],
        paramsProperty: 'endCustomer'
    },
    {
        key: '20',
        text: 'Customer country',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'text search' }],
        paramsProperty: 'customerCountry'
    },
    {
        key: '21',
        text: 'MSX devices',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalDevice'
    },
    {
        key: '22',
        text: 'MSX revenue',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalRevenue'
    },
    {
        key: '23',
        text: 'Customer Count',
        condition: [
            { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
            { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
            { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
        ],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'customerNumber'
    },
    { key: '24', text: 'Relationship', condition: equalsOperators, picker: relationshipOptions, paramsProperty: 'relationship' },
    // {
    //     key: '25',
    //     text: 'Number of Children',
    //     condition: [
    //         { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
    //         { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
    //     ],
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'childrenNumber'
    // },
    // {
    //     key: '26',
    //     text: 'Internal comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'internalComment'
    // },
    // {
    //     key: '27',
    //     text: 'External comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalComment'
    // },
    // {
    //     key: '28',
    //     text: 'External Status Timestamp',
    //     condition: olderYoungerThanOperators,
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'externalStatusTimestamp'
    // },
    // {
    //     key: '29',
    //     text: 'External Status',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalStatus'
    // },
    {
        key: '30',
        text: 'Attachment',
        condition: [{ key: 0, text: 'Equals', enumValue: ListBuilderCondition.Equals }],
        picker: [
            { key: 0, text: 'Has Attachment', enumValue: 1 },
            { key: 1, text: 'No Attachment', enumValue: 2 }
        ],
        paramsProperty: 'attachment'
    }
];

export const cxpFields: IListOptionProps[] = [
    { key: '1', text: 'Status', condition: equalsOperators, picker: statusPicker, paramsProperty: 'status' },
    { key: '3', text: 'Title', condition: containsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'title' },
    {
        key: '4',
        text: 'Created On',
        condition: beforeAfterOperators,
        picker: [{ key: 0, text: 'Date Picker' }],
        paramsProperty: 'createdOn'
    },
    {
        key: '6',
        text: 'Assigned To',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'assignedTo'
    },
    {
        key: '42',
        text: 'Submitter',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'submittedBy'
    },
    { key: '7', text: 'Tags', condition: equalsOperators, picker: [{ key: 0, text: 'Tag Picker' }], paramsProperty: 'tags' },
    {
        key: '8',
        text: 'Description',
        condition: containsOperators,
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'description'
    },
    {
        key: '9',
        text: 'Total Revenue Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalRevenueRisk'
    },
    {
        key: '10',
        text: 'Total Device Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalDeviceRisk'
    },
    {
        key: '11',
        text: 'Aging',
        condition: [{ key: 1, text: 'equals (=)', enumValue: ListBuilderCondition.Equals }],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'aging'
    },
    {
        key: '12',
        text: 'Troubled Product',
        condition: equalsOperators,
        picker: deviceCategories,
        paramsProperty: 'troubledProductCategory'
    },
    // {
    //     key: '14',
    //     text: 'Requested Resolution Date',
    //     condition: [
    //         { key: 0, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 1, text: 'more than', enumValue: ListBuilderCondition.OlderThan },
    //         { key: 2, text: 'On', enumValue: ListBuilderCondition.On }
    //     ],
    //     picker: [{ key: 0, text: 'today' }],
    //     paramsProperty: 'requestedResolutionDate'
    // },
    {
        key: '16',
        text: 'MSX Opportunity',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'msxOpportunity'
    },
    {
        key: '17',
        text: 'MSX Status',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Open' },
            { key: 1, text: 'Won' },
            { key: 2, text: 'Lost' }
        ],
        paramsProperty: 'msxStatus'
    },
    { key: '18', text: 'TPID', condition: equalsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'tpid' },
    {
        key: '19',
        text: 'End customer',
        condition: [
            { key: 0, text: 'equals (=)', enumValue: ListBuilderCondition.Equals },
            { key: 1, text: 'does not equal', enumValue: ListBuilderCondition.DoesNotEqual },
            { key: 2, text: 'contains', enumValue: ListBuilderCondition.Contains },
            { key: 3, text: 'does not contain', enumValue: ListBuilderCondition.DoesNotContain }
        ],
        picker: [{ key: 0, text: 'Customer Search' }],
        paramsProperty: 'endCustomer'
    },
    {
        key: '20',
        text: 'Customer country',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'text search' }],
        paramsProperty: 'customerCountry'
    },
    {
        key: '21',
        text: 'MSX devices',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalDeviceCount'
    },
    {
        key: '22',
        text: 'MSX revenue',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalRevenue'
    },
    {
        key: '23',
        text: 'Customer Count',
        condition: [
            { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
            { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
            { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
        ],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'customerNumber'
    },
    { key: '24', text: 'Relationship', condition: equalsOperators, picker: relationshipOptions, paramsProperty: 'relationship' },
    // {
    //     key: '25',
    //     text: 'Number of Children',
    //     condition: [
    //         { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
    //         { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
    //     ],
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'childrenNumber'
    // },
    // {
    //     key: '26',
    //     text: 'Internal comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'internalComment'
    // },
    // {
    //     key: '27',
    //     text: 'External comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalComment'
    // },
    // {
    //     key: '28',
    //     text: 'External Status Timestamp',
    //     condition: olderYoungerThanOperators,
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'externalStatusTimestamp'
    // },
    // {
    //     key: '29',
    //     text: 'External Status',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalStatus'
    // },
    {
        key: '30',
        text: 'Attachment',
        condition: [{ key: 0, text: 'Equals', enumValue: ListBuilderCondition.Equals }],
        picker: [
            { key: 0, text: 'Has Attachment', enumValue: 1 },
            { key: 1, text: 'No Attachment', enumValue: 2 }
        ],
        paramsProperty: 'attachment'
    },
    {
        key: '31',
        text: 'CSS case number',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [
            { key: 0, text: 'Yes' },
            { key: 1, text: 'No' }
        ],
        paramsProperty: 'cssCaseNumber'
    },
    {
        key: '32',
        text: 'Is DFS (design for surface) possible',
        condition: containsOperators,
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'dfsPossible'
    },
    {
        key: '33',
        text: 'Bug Type',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'PLE Bug', enumValue: 1 },
            { key: 1, text: 'MSFT Devices Non PLE Bug', enumValue: 2 },
            { key: 2, text: 'OS Bug', enumValue: 3 },
            { key: 3, text: 'Hub OS Bug', enumValue: 4 },
            { key: 4, text: 'Other Bugs', enumValue: 5 }
        ],
        paramsProperty: 'bugType'
    },
    {
        key: '34',
        text: 'Bug Number',
        condition: containsOperators,
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'bugNumber'
    },
    {
        key: '35',
        text: 'CXP Priority Number',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'cxpPriorityNumber'
    }
];

export const cssFields: IListOptionProps[] = [
    { key: '1', text: 'Status', condition: equalsOperators, picker: statusPicker, paramsProperty: 'status' },
    { key: '3', text: 'Title', condition: containsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'title' },
    {
        key: '4',
        text: 'Created On',
        condition: beforeAfterOperators,
        picker: [{ key: 0, text: 'Date Picker' }],
        paramsProperty: 'createdOn'
    },
    {
        key: '6',
        text: 'Assigned To',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'assignedTo'
    },
    {
        key: '42',
        text: 'Submitter',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'submittedBy'
    },
    { key: '7', text: 'Tags', condition: equalsOperators, picker: [{ key: 0, text: 'Tag Picker' }], paramsProperty: 'tags' },
    {
        key: '8',
        text: 'Description',
        condition: containsOperators,
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'description'
    },
    {
        key: '9',
        text: 'Total Revenue Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalRevenueRisk'
    },
    {
        key: '10',
        text: 'Total Device Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalDeviceRisk'
    },
    {
        key: '11',
        text: 'Aging',
        condition: [{ key: 1, text: 'equals (=)', enumValue: ListBuilderCondition.Equals }],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'aging'
    },
    {
        key: '12',
        text: 'Troubled Product',
        condition: equalsOperators,
        picker: deviceCategories,
        paramsProperty: 'troubledProductCategory'
    },
    // {
    //     key: '14',
    //     text: 'Requested Resolution Date',
    //     condition: [
    //         { key: 0, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 1, text: 'more than', enumValue: ListBuilderCondition.OlderThan },
    //         { key: 2, text: 'On', enumValue: ListBuilderCondition.On }
    //     ],
    //     picker: [{ key: 0, text: 'today' }],
    //     paramsProperty: 'requestedResolutionDate'
    // },
    {
        key: '16',
        text: 'MSX Opportunity',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'msxOpportunity'
    },
    {
        key: '17',
        text: 'MSX Status',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Open' },
            { key: 1, text: 'Won' },
            { key: 2, text: 'Lost' }
        ],
        paramsProperty: 'msxStatus'
    },
    { key: '18', text: 'TPID', condition: equalsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'tpid' },
    {
        key: '19',
        text: 'End customer',
        condition: [
            { key: 0, text: 'equals (=)', enumValue: ListBuilderCondition.Equals },
            { key: 1, text: 'does not equal', enumValue: ListBuilderCondition.DoesNotEqual },
            { key: 2, text: 'contains', enumValue: ListBuilderCondition.Contains },
            { key: 3, text: 'does not contain', enumValue: ListBuilderCondition.DoesNotContain }
        ],
        picker: [{ key: 0, text: 'Customer Search' }],
        paramsProperty: 'endCustomer'
    },
    {
        key: '20',
        text: 'Customer country',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'text search' }],
        paramsProperty: 'customerCountry'
    },
    {
        key: '21',
        text: 'MSX devices',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalDevice'
    },
    {
        key: '22',
        text: 'MSX revenue',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalRevenue'
    },
    {
        key: '23',
        text: 'Customer Count',
        condition: [
            { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
            { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
            { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
        ],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'customerNumber'
    },
    { key: '24', text: 'Relationship', condition: equalsOperators, picker: relationshipOptions, paramsProperty: 'relationship' },
    // {
    //     key: '25',
    //     text: 'Number of Children',
    //     condition: [
    //         { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
    //         { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
    //     ],
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'childrenNumber'
    // },
    // {
    //     key: '26',
    //     text: 'Internal comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'internalComment'
    // },
    // {
    //     key: '27',
    //     text: 'External comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalComment'
    // },
    // {
    //     key: '28',
    //     text: 'External Status Timestamp',
    //     condition: olderYoungerThanOperators,
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'externalStatusTimestamp'
    // },
    // {
    //     key: '29',
    //     text: 'External Status',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalStatus'
    // },
    {
        key: '30',
        text: 'Attachment',
        condition: [{ key: 0, text: 'Equals', enumValue: ListBuilderCondition.Equals }],
        picker: [
            { key: 0, text: 'Has Attachment', enumValue: 1 },
            { key: 1, text: 'No Attachment', enumValue: 2 }
        ],
        paramsProperty: 'attachment'
    },
    {
        key: '31',
        text: 'CSS case number',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [
            { key: 0, text: 'Yes' },
            { key: 1, text: 'No' }
        ],
        paramsProperty: 'cssCaseNumber'
    }
];

export const crewFields: IListOptionProps[] = [
    { key: '1', text: 'Status', condition: equalsOperators, picker: statusPicker, paramsProperty: 'status' },
    { key: '3', text: 'Title', condition: containsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'title' },
    {
        key: '4',
        text: 'Created On',
        condition: beforeAfterOperators,
        picker: [{ key: 0, text: 'Date Picker' }],
        paramsProperty: 'createdOn'
    },
    {
        key: '6',
        text: 'Assigned To',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'assignedTo'
    },
    {
        key: '42',
        text: 'Submitter',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'People Picker' }],
        paramsProperty: 'submittedBy'
    },
    { key: '7', text: 'Tags', condition: equalsOperators, picker: [{ key: 0, text: 'Tag Picker' }], paramsProperty: 'tags' },
    {
        key: '8',
        text: 'Description',
        condition: containsOperators,
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'description'
    },
    {
        key: '9',
        text: 'Total Revenue Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalRevenueRisk'
    },
    {
        key: '10',
        text: 'Total Device Risk',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'totalDeviceRisk'
    },
    {
        key: '11',
        text: 'Aging',
        condition: [{ key: 1, text: 'equals (=)', enumValue: ListBuilderCondition.Equals }],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'aging'
    },
    {
        key: '12',
        text: 'Troubled Product',
        condition: equalsOperators,
        picker: deviceCategories,
        paramsProperty: 'troubledProductCategory'
    },
    // {
    //     key: '14',
    //     text: 'Requested Resolution Date',
    //     condition: [
    //         { key: 0, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 1, text: 'more than', enumValue: ListBuilderCondition.OlderThan },
    //         { key: 2, text: 'On', enumValue: ListBuilderCondition.On }
    //     ],
    //     picker: [{ key: 0, text: 'today' }],
    //     paramsProperty: 'requestedResolutionDate'
    // },
    {
        key: '16',
        text: 'MSX Opportunity',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [{ key: 0, text: 'Text box' }],
        paramsProperty: 'msxOpportunity'
    },
    {
        key: '17',
        text: 'MSX Status',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Open' },
            { key: 1, text: 'Won' },
            { key: 2, text: 'Lost' }
        ],
        paramsProperty: 'msxStatus'
    },
    { key: '18', text: 'TPID', condition: equalsOperators, picker: [{ key: 0, text: 'Text box' }], paramsProperty: 'tpid' },
    {
        key: '19',
        text: 'End customer',
        condition: [
            { key: 0, text: 'equals (=)', enumValue: ListBuilderCondition.Equals },
            { key: 1, text: 'does not equal', enumValue: ListBuilderCondition.DoesNotEqual },
            { key: 2, text: 'contains', enumValue: ListBuilderCondition.Contains },
            { key: 3, text: 'does not contain', enumValue: ListBuilderCondition.DoesNotContain }
        ],
        picker: [{ key: 0, text: 'Customer Search' }],
        paramsProperty: 'endCustomer'
    },
    {
        key: '20',
        text: 'Customer country',
        condition: equalsOperators,
        picker: [{ key: 0, text: 'text search' }],
        paramsProperty: 'customerCountry'
    },
    {
        key: '21',
        text: 'MSX devices',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalDeviceCount'
    },
    {
        key: '22',
        text: 'MSX revenue',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'msxTotalRevenue'
    },
    {
        key: '23',
        text: 'Customer Count',
        condition: [
            { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
            { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
            { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
        ],
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'customerNumber'
    },
    { key: '24', text: 'Relationship', condition: equalsOperators, picker: relationshipOptions, paramsProperty: 'relationship' },
    // {
    //     key: '25',
    //     text: 'Number of Children',
    //     condition: [
    //         { key: 0, text: 'greater than (>)', enumValue: ListBuilderCondition.GreaterThan },
    //         { key: 1, text: 'less than (<)', enumValue: ListBuilderCondition.LessThan },
    //         { key: 2, text: 'equal to', enumValue: ListBuilderCondition.Equals }
    //     ],
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'childrenNumber'
    // },
    // {
    //     key: '26',
    //     text: 'Internal comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'internalComment'
    // },
    // {
    //     key: '27',
    //     text: 'External comment',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalComment'
    // },
    // {
    //     key: '28',
    //     text: 'External Status Timestamp',
    //     condition: olderYoungerThanOperators,
    //     picker: [{ key: 0, text: 'Number Text box' }],
    //     paramsProperty: 'externalStatusTimestamp'
    // },
    // {
    //     key: '29',
    //     text: 'External Status',
    //     condition: containsOperators,
    //     picker: [{ key: 0, text: 'Text box' }],
    //     paramsProperty: 'externalStatus'
    // },
    {
        key: '30',
        text: 'Attachment',
        condition: [{ key: 0, text: 'Equals', enumValue: ListBuilderCondition.Equals }],
        picker: [
            { key: 0, text: 'Has Attachment', enumValue: 1 },
            { key: 1, text: 'No Attachment', enumValue: 2 }
        ],
        paramsProperty: 'attachment'
    },
    {
        key: '31',
        text: 'CSS case number',
        condition: [...equalsOperators, { key: 3, text: 'State', enumValue: ListBuilderCondition.State }],
        picker: [
            { key: 0, text: 'Yes' },
            { key: 1, text: 'No' }
        ],
        paramsProperty: 'cssCaseNumber'
    },
    {
        key: '36',
        text: 'Is This a DCF Request',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Yes', boolValue: true },
            { key: 1, text: 'No', boolValue: false }
        ],
        paramsProperty: 'dcfRequest'
    },
    {
        key: '37',
        text: 'Proof of purchase attached',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Yes', boolValue: true },
            { key: 1, text: 'No', boolValue: false }
        ],
        paramsProperty: 'proofOfPurchaseAttached'
    },
    {
        key: '38',
        text: 'Priority',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Low', enumValue: 3 },
            { key: 1, text: 'Medium', enumValue: 2 },
            { key: 2, text: 'High', enumValue: 1 }
        ],
        paramsProperty: 'priority'
    },
    {
        key: '39',
        text: 'Serial number attached',
        condition: equalsOperators,
        picker: [
            { key: 0, text: 'Yes', boolValue: true },
            { key: 1, text: 'No', boolValue: false }
        ],
        paramsProperty: 'serialNumberAttached'
    },
    {
        key: '40',
        text: 'DCF Value',
        condition: greaterLessThanOperators,
        picker: [{ key: 0, text: 'Number Text box' }],
        paramsProperty: 'dcfValue'
    }
];
