import { Shimmer } from '@fluentui/react';
import { ICommentResponse } from 'app/models/common/response';
import React from 'react';
import './CommentsPanel.scss';
import Comment from './comment/Comment';

interface IProps {
    data?: ICommentResponse[];
    isMultipleType?: boolean;
    totalCount?: number;
    correlationId?: string;
    isLoading?: boolean;
    isInternal?: boolean;
    isClosed?: boolean;
}

const CommentsPanel: React.FC<IProps> = ({ data, isMultipleType, totalCount, correlationId, isLoading, isInternal, isClosed = false }) => {
    return (
        <div className="cmt-container">
            {isLoading && (
                <div>
                    <Shimmer />
                    <Shimmer width="75%" />
                    <Shimmer width="50%" />
                </div>
            )}
            {!isLoading && (
                <>
                    {totalCount > 0 && <div className="cmt-count">{totalCount} comments</div>}
                    {data &&
                        data.length > 0 &&
                        data
                            .filter((item) => item.isActive || (item.childComments.length !== 0 && !item.isActive))
                            .map((item, itemIndex) => (
                                <Comment
                                    key={itemIndex}
                                    correlationId={correlationId}
                                    item={item}
                                    isInternal={isInternal}
                                    isMultipleType={isMultipleType}
                                    itemIndex={itemIndex}
                                    isClosed={isClosed}
                                />
                            ))}
                </>
            )}
        </div>
    );
};

export default CommentsPanel;
