import React, { FC, useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import './Editor.scss';
import { buttonList, buttonListTrainingVideoAdded, setVideoOptions, setImageOptions } from './config';
import { fileUpload } from 'app/components/helpers';
import { useGenerateContainerSasToken } from 'app/services/queries';
import { ArticleType } from 'app/enums/ArticleType.enum';
import SunEditorCore from 'suneditor/src/lib/core';
import { ALLOWED_VIDEO_MIME_TYPES, Entity, BlobStorageContainer, BlobStorageSubfolder, StringConstants } from 'app/utils/constants';
import { addSasTokenToResponse } from 'app/utils/blobStorageHelpers';
import { IBlobUploadParams } from 'app/models/common/post';

interface IProps {
    setContent?: (content: string) => void;
    content: string;
    token?: string;
    handleFileUploadProgress?: (loadedBytes: number, totalBytes: number) => void;
    type: ArticleType;
}

const Editor: FC<IProps> = (props: IProps) => {
    const { handleFileUploadProgress, type, setContent, content } = props;
    const [isVideoUploaded, setIsVideoUploaded] = useState<boolean>(false);
    const { refetch: getToken } = useGenerateContainerSasToken({entity: StringConstants.ARTICLE, containerName: BlobStorageContainer.ARTICLE_FILES});
    const editorInstance = useRef(null);

    const videoUploadHandler = (files: File[], info: object, uploadHandler: Function) => {
        const file = files[0];
        if (!ALLOWED_VIDEO_MIME_TYPES.includes(file?.type.toLowerCase())) {
            uploadHandler('Unsupported Video format. Please upload .mp4/.ogg/.webm formats.');
        }
        const blobName = StringConstants.NEW_BLOB + new Date().getTime();
        try {
            getToken().then((res) => {
                const blobUploadParams: IBlobUploadParams = {
                    file: file,
                    blobName: blobName,
                    container: BlobStorageContainer.ARTICLE_FILES,
                    subfolder: BlobStorageSubfolder.NONE,
                    token: res.data
                };

                fileUpload(blobUploadParams, handleFileUploadProgress)
                    .then((res) => {
                        const response = {
                            result: [
                                {
                                    url: res._response.request.url.split(/[?#]/)[0],
                                    name: file.name,
                                    size: file.size
                                }
                            ]
                        };

                        addSasTokenToResponse(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, response)
                        .then((res) => {
                            uploadHandler(res);
                        });
                    })
                    .catch((err) => {
                        handleFileUploadProgress(0, 1);
                        return uploadHandler();
                    })
                    .finally(() => {
                        handleFileUploadProgress(0, 1);
                    });
            });
        } catch (err) {
            handleFileUploadProgress(0, 1);
            return uploadHandler();
        }
    };

    const imageUploadHandler = (files: File[], info: object, uploadHandler: Function) => {
        const file = files[0];
        const blobName = StringConstants.NEW_BLOB + new Date().getTime();
        try {
            getToken().then((res) => {
                const blobUploadParams: IBlobUploadParams = {
                    file: file,
                    blobName: blobName,
                    container: BlobStorageContainer.ARTICLE_FILES,
                    subfolder: BlobStorageSubfolder.NONE,
                    token: res.data
                };

                fileUpload(blobUploadParams, handleFileUploadProgress)
                    .then((res) => {
                        const response = {
                            result: [
                                {
                                    url: res._response.request.url.split(/[?#]/)[0],
                                    name: file.name,
                                    size: file.size
                                }
                            ]
                        };
                        addSasTokenToResponse(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, response)
                        .then((res) => {
                            uploadHandler(res);
                        });
                    })
                    .catch((err) => {
                        handleFileUploadProgress(0, 1);
                        return uploadHandler();
                    })
                    .finally(() => {
                        handleFileUploadProgress(0, 1);
                    });
            });
        } catch (err) {
            handleFileUploadProgress(0, 1);
            return uploadHandler();
        }
    };

    const getEditor = (editor: SunEditorCore) => (editorInstance.current = editor);

    useEffect(() => {
        if (type === ArticleType.Training && isVideoUploaded) {
            (editorInstance.current as SunEditorCore)?.setToolbarButtons(buttonListTrainingVideoAdded.responsive);
        } else {
            (editorInstance.current as SunEditorCore)?.setToolbarButtons(buttonList.responsive);
        }
        (editorInstance.current as SunEditorCore)?.setOptions({ mode: 'classic', ...setVideoOptions, ...setImageOptions });
    }, [type, isVideoUploaded]);

    useEffect(() => {
        content.includes('se-video-container') ? setIsVideoUploaded(true) : setIsVideoUploaded(false);
        return () => setIsVideoUploaded(false);
    }, [content]);

    return (
        <div>
            <SunEditor
                autoFocus={true}
                setContents={content}
                setOptions={{
                    mode: 'classic',
                    ...setVideoOptions,
                    ...setImageOptions,
                    attributesWhitelist: {
                        all: 'style',
                        input: 'checked'
                    }
                }}
                onChange={setContent}
                onVideoUploadBefore={videoUploadHandler}
                onImageUploadBefore={imageUploadHandler}
                getSunEditorInstance={getEditor}
            />
        </div>
    );
};

export default Editor;
