import React from 'react';
import plusIcon from '../../../../static/icons/plusIcon.svg';
import disabledPlusIcon from '../../../../static/icons/disabledPlusIcon.svg';
import './PlusIcon.scss';

interface IProps {
  handlePlusIcon?: any;
  toggleSaveAs?: any;
  triggerPlusIcon?: any;
  triggerIconDown?: any;
  plusIconPosition?: any;
  plusIconIndex: number;
  plusIconDisabled: boolean;
  plusIconRef: React.MutableRefObject<any>;
}
const PlusIcon: React.FC<IProps> = ({plusIconPosition, handlePlusIcon, toggleSaveAs, triggerPlusIcon, plusIconIndex, plusIconDisabled, plusIconRef }) => {
    const handlePlusIconClass = () => {
        switch(plusIconPosition){
            case "Up":
                return "plusIconUp";
            case "Down":
                return "plusIconDown";
            case "In":
                return "iconPlusOut";
            case "Out":
                return "iconPlusIn";
            default:
                return "";
        }
    }

    const onPlusIconClick = () => {
        var element = plusIconRef.current;

        if (element) {
            element.style.setProperty("--icon-previous-position", `${172 * plusIconIndex}px`);
            element.style.setProperty("--icon-position", `${172 * (plusIconIndex + 1)}px`);
    
            element.classList.remove("plusIconDown");
    
            void element.offsetWidth;
    
            element.classList.add("plusIconDown");
    
            handlePlusIcon();
        }
    }

    return(
        <div>
            { toggleSaveAs &&
                <div className={`plusIconContainer`} onClick={!plusIconDisabled ? onPlusIconClick : () => {}}>
                    <div id="div2" className={`${triggerPlusIcon ? handlePlusIconClass() : ""}`} ref={plusIconRef}>
                        <img src={plusIconDisabled? disabledPlusIcon : plusIcon} alt="plusIcon" />
                    </div>
                </div>
            }
        </div>
    )}

export default PlusIcon;