import { DefaultButton, IDropdownOption, Image, Link, PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { keyPress } from 'app/components/helpers';
import { useAssignRequest, useRequestReassign, useSubmitComment } from 'app/services/mutations';
import checkIcon from 'app/static/icons/success_modal_icon.svg';
import errorIcon from 'app/static/icons/warning-red.svg';
import useStore from 'app/store';
import { RouteConstants, StringConstants } from 'app/utils/constants';
import momentBusinessDays from 'moment-business-days';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppQueryClient } from '../../../services/clients/query-client';
import { ReassignReq } from '../reqReassignment/ReassignReq';
import './TriageModal.scss';

interface IDataProps {
    openModal: any;
    data: any;
    reassignTeamOptions: any;
    hideModal: Function;
    page: number;
    setPage: Function;
    setIsListView?: Function;
    setSelectedRequest?: Function;
}
const TriageModal: React.FC<IDataProps> = (props) => {
    const { openModal, data, reassignTeamOptions, hideModal } = props;

    const { t: translate } = useTranslation();
    const history = useHistory();

    const reasonOptions = [
        { key: 1, text: translate('Misroute') },
        { key: 2, text: translate('Escalation Reassignment') },
        { key: 3, text: translate('Task Required') },
        { key: 4, text: translate('Other') }
    ];
    const reqTypeOptions = [
        {
            key: 1,
            text: StringConstants.SDSG,
            options: [
                { key: 1, text: translate('Proposal') },
                { key: 2, text: translate('Question') },
                { key: 3, text: 'Rfx' }
            ]
        },
        {
            key: 2,
            text: StringConstants.CXP,
            options: [
                { key: 8, text: translate('Variant') },
                { key: 9, text: translate('PFR') },
                { key: 10, text: translate('Messaging') },
                { key: 11, text: translate('Technical') },
                { key: 12, text: translate('Program') }
            ]
        },
        {
            key: 3,
            text: StringConstants.CSS,
            options: [{ key: 7, text: translate('Review Request') }]
        },
        {
            key: 4,
            text: StringConstants.CREW,
            options: [
                { key: 4, text: translate('Pre Sales') },
                { key: 5, text: translate('Post Sales') },
                { key: 13, text: translate('Warranty') },
                { key: 14, text: translate('Product') },
                { key: 15, text: translate('Messaging') }
            ]
        }
    ];

    const tomorrow = new Date();

    const [reassignTeamHText, setReassignTeamHText] = useState(false);
    const [requestTypeHText, setRequestTypeHText] = useState(false);
    const [notifyToHText, setNotifyToHText] = useState(false);
    const [reasonHText, setReasonHText] = useState(false);
    const [teamNotSelectedHText, setTeamNotSelectedHText] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(momentBusinessDays().businessAdd(7).toDate());
    const [dateSent, setDateSent] = useState(momentBusinessDays().businessAdd(7).toISOString());
    const [reqTypeOp, setReqTypeOp] = useState([]);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [selectedTeam, setSelectedTeam] = useState<IDropdownOption>();
    const [assignedCommentData, setAssignedCommentData] = useState({});
    const [showSuccessPopUp, setSuccessPopUp] = React.useState(false);
    const [cssCaseNumber, setCssCaseNumber] = useState();
    const [showModal, setShowModal] = useState(openModal);
    const [cssError, setCssError] = useState(false);
    const [selectedRequestType, setSelectedRequestType] = useState<IDropdownOption>();
    const [selectedReason, setSelectedReason] = useState<IDropdownOption>();

    const clearFiles = useStore((state: any) => state.clearFiles);
    const addTriageModalSuccessNewModel = useStore((state: any) => state.addTriageModalSuccessNewModel);
    const setRequestType = useStore((state: any) => state.setRequestType);
    const setNewlyAddedRow = useStore((state: any) => state.setNewlyAddedRow);

    const {
        mutate: requestReassign,
        isLoading: isReassigning,
        isSuccess: successRequestReassign,
        isError: errorRequestReassign
    } = useRequestReassign();

    const {
        mutate: requestAssign,
        isLoading: isAssigning,
        isSuccess: successRequestAssign,
        isError: errorRequestAssign
    } = useAssignRequest();

    const {
        mutate: submitCommentMutation,
        isLoading: isLoadingSubmitComment,
        isSuccess: isSuccessSubmitComment,
        isError: isErrorSubmitComment
    } = useSubmitComment();

    tomorrow.setDate(new Date(Date.now()).getDate() + 1);

    const setErrorDisplay = () => {
        setReasonHText(selectedReason === undefined || selectedReason.key === null ? true : false);

        setReassignTeamHText(selectedTeam === undefined || selectedTeam.key === null ? true : false);
        setRequestTypeHText(selectedRequestType === undefined || selectedRequestType.key === null ? true : false);
    };

    const onReassignTeamChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        reqTypeOptions.every((ele: any) => {
            if (item.key === ele.key) {
                let ar: any = [];
                ele.options.forEach((it: any) => {
                    if (it.key !== data.requestTypeValue) ar.push(it);
                });

                setReqTypeOp(ar);
                return false;
            } else {
                return true;
            }
        });
        setSelectedRequestType({ key: null, text: '' });
        setSelectedTeam(item);
        setReassignTeamHText(false);
        setTeamNotSelectedHText(false);
    };
    const onRequestTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedRequestType(item);
        setRequestTypeHText(false);
    };
    const onReasonChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedReason(item);
        setNotifyToHText(false);
        setReasonHText(false);
    };

    const handleSubmit = (type: string) => {
        addTriageModalSuccessNewModel(true);

        switch (type) {
            case 'REASSIGN':
                if (selectedTeam.text === StringConstants.CSS) {
                    let reassignmentData = {
                        currentTeam: data.teamValue,
                        cssCaseNumber: cssCaseNumber,
                        currentRequestType: data.requestTypeValue,
                        reassignTeam: selectedTeam?.key,
                        reassignRequestType: selectedRequestType?.key,
                        requestedResolutionDate: dateSent,
                        requestId: data.unifiedTrackingId,
                        reasonForReassignment: selectedReason.key,
                        isParent: data.isParent,
                        parentRequestId: data.parentRequestId
                    };
                    requestReassign(reassignmentData);
                } else {
                    let reassignmentData = {
                        currentTeam: data.teamValue,
                        currentRequestType: data.requestTypeValue,
                        reassignTeam: selectedTeam?.key,
                        reassignRequestType: selectedRequestType?.key,
                        requestedResolutionDate: dateSent,
                        requestId: data.unifiedTrackingId,
                        reasonForReassignment: selectedReason.key,
                        isParent: data.isParent,
                        parentRequestId: data.parentRequestId
                    };
                    requestReassign(reassignmentData);
                }
                break;
            default:
                break;
        }

        setSuccessPopUp(true);
        setShowModal('');
    };

    useEffect(() => {
        clearFiles();
    }, []);

    useEffect(() => {
        if (successRequestReassign || successRequestAssign) {
            if (successRequestAssign) {
                if ((assignedCommentData as any).commentText) {
                    submitCommentMutation(assignedCommentData);
                } else {
                    setErrorMsg(false);
                }
            }
            if (successRequestReassign) {
                setErrorMsg(false);
            }
        } else if (errorRequestReassign || errorRequestAssign) {
            setErrorMsg(true);
        }
    }, [successRequestReassign, errorRequestReassign, errorRequestAssign, successRequestAssign]);

    useEffect(() => {
        if (isSuccessSubmitComment) {
            setErrorMsg(false);
        }
        if (isErrorSubmitComment) {
            setErrorMsg(true);
        }
    }, [isLoadingSubmitComment, isSuccessSubmitComment, isErrorSubmitComment]);

    return (
        <>
            {!showSuccessPopUp && (
                <div className="requestReassign-container">
                    <div className="modal-heading">
                        {showModal === 'reassign' && (
                            <span>
                                <Trans>Request Reassignment</Trans>
                            </span>
                        )}
                    </div>
                    <div className="modal-body">
                        <div className="body-main-container">
                            <div className="cust-details">
                                <Text className="secondary-text">
                                    <span className="text-bold">
                                        <Trans>Request ID</Trans>-{' '}
                                    </span>
                                    {data.id}
                                </Text>
                                <Text className="secondary-text">
                                    <span className="text-bold">
                                        <Trans>Current Team</Trans>-{' '}
                                    </span>
                                    {data.team}
                                </Text>
                                <Text className="secondary-text">
                                    <span className="text-bold">
                                        <Trans>Current Request Type</Trans>-{' '}
                                    </span>
                                    {data.requestType}
                                </Text>
                            </div>
                            <div>
                                <Text className="modal-body-title title-field"> {data.title}</Text>
                            </div>
                        </div>
                        <div className="body-form-container">
                            <form>
                                <div className="form-control">
                                    {showModal === 'reassign' && (
                                        <ReassignReq
                                            selectedTeam={selectedTeam}
                                            reassignTeamHText={reassignTeamHText}
                                            requestTypeHText={requestTypeHText}
                                            reasonHText={reasonHText}
                                            selectedRequest={selectedRequestType}
                                            selectedReason={selectedReason}
                                            selectedDate={selectedDate}
                                            onReassignTeamChange={onReassignTeamChange}
                                            onRequestTypeChange={onRequestTypeChange}
                                            onReasonChange={onReasonChange}
                                            reassignTeamOptions={
                                                data.teamValue === 3
                                                    ? reassignTeamOptions.filter((item: any) => item.key !== 3)
                                                    : reassignTeamOptions
                                            }
                                            reqTypeOp={reqTypeOp}
                                            reasonOptions={reasonOptions}
                                            teamNotSelectedHText={teamNotSelectedHText}
                                            setTeamNotSelectedHText={setTeamNotSelectedHText}
                                            setRequestTypeHText={setRequestTypeHText}
                                            setSelectedDate={setSelectedDate}
                                            setDateSent={setDateSent}
                                        />
                                    )}

                                    <div className="btn-container">
                                        <DefaultButton
                                            text="Cancel"
                                            onClick={() => {
                                                if (showModal === 'reassign') {
                                                    setSelectedTeam({ key: null, text: '' });
                                                    setSelectedReason({ key: null, text: '' });
                                                    setSelectedRequestType({ key: null, text: '' });
                                                    setSelectedDate(momentBusinessDays().businessAdd(7).toDate());
                                                    setDateSent(momentBusinessDays().businessAdd(7).toISOString());
                                                    hideModal();
                                                }
                                                hideModal();
                                            }}
                                        />
                                        <PrimaryButton
                                            text={translate('Submit')}
                                            disabled={isUploading}
                                            onClick={() => {
                                                if (showModal === 'reassign') {
                                                    if (
                                                        selectedTeam !== undefined &&
                                                        selectedTeam.key !== null &&
                                                        selectedRequestType !== undefined &&
                                                        selectedRequestType.key !== null &&
                                                        selectedReason !== undefined &&
                                                        selectedReason.key !== null
                                                    ) {
                                                        handleSubmit('REASSIGN');
                                                    } else {
                                                        setErrorDisplay();
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {showSuccessPopUp && (
                <div className="selected-customer-container">
                    {isReassigning || isAssigning ? (
                        <div className="modal-body success-body">
                            <Spinner
                                size={SpinnerSize.large}
                                label={translate('Please wait, while we are processing your request')}
                            />
                        </div>
                    ) : (
                        <div className="modal-body success-body">
                            {!errorMsg ? <Image src={checkIcon} alt="Success" /> : <Image src={errorIcon} alt="Error" />}

                            {(openModal === 'reassign' || openModal === 'assign') && (
                                <div>
                                    {!errorMsg ? (
                                        <div className="success-txt">
                                            {openModal === 'reassign' && (
                                                <Text className="span-txt">
                                                    <Trans>The request has been {translate('reassigned')}.</Trans>{' '}
                                                </Text>
                                            )}
                                            {openModal === 'assign' && (
                                                <Text className="span-txt">
                                                    <Trans>The request is successfully</Trans> {translate('assigned')}
                                                </Text>
                                            )}
                                            <Text className="span-txt">
                                                {openModal === 'reassign' && <Trans>You can find it listed </Trans>}
                                                {openModal === 'assign' && <Trans>The request is listed under </Trans>}
                                                <Link
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        setNewlyAddedRow(data.id);

                                                        setRequestType('ALL_REQUEST');

                                                        if (props.page === 1) {
                                                            AppQueryClient.invalidateQueries('requestQuery');
                                                        } else {
                                                            props.setPage(1);
                                                        }

                                                        AppQueryClient.invalidateQueries('getSummaryCount');
                                                        history?.push(RouteConstants.MANAGE_REQUESTS);

                                                        hideModal();
                                                    }}
                                                    data-testid="managelink-test">
                                                    {openModal === 'reassign' && <Trans>here</Trans>}
                                                    {openModal === 'assign' && (
                                                        <span>
                                                            "<Trans>Manage Requests</Trans>"
                                                        </span>
                                                    )}
                                                </Link>
                                            </Text>
                                        </div>
                                    ) : (
                                        <div className="success-txt">
                                            <Text className="span-txt">
                                                <Trans>An error occurred while</Trans>{' '}
                                                {openModal === 'reassign' ? translate('reassigning') : translate('assigning')}{' '}
                                                <Trans>your request, please try again</Trans>
                                            </Text>
                                            <Text className="span-txt">
                                                <Trans>Go back to</Trans>{' '}
                                                <Link
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        setRequestType('ALL_REQUEST');
                                                        AppQueryClient.invalidateQueries('requestQuery');
                                                        AppQueryClient.invalidateQueries('useSummaryCardsCountForUser');
                                                        history?.push(RouteConstants.MANAGE_REQUESTS);
                                                        hideModal();
                                                    }}
                                                    onKeyPress={(e: any) =>
                                                        keyPress(e, () => {
                                                            setRequestType('ALL_REQUEST');
                                                            AppQueryClient.invalidateQueries('requestQuery');
                                                            AppQueryClient.invalidateQueries('useSummaryCardsCountForUser');
                                                            history?.push(RouteConstants.MANAGE_REQUESTS);
                                                            hideModal();
                                                        })
                                                    }
                                                    data-testid="managelink-test">
                                                    "<Trans>Manage Requests</Trans>"
                                                </Link>
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
export default TriageModal;
