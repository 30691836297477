export enum RequestAction {
    Create = 1,
    Update,
    Delete,
    Assigned,
    AddCustomerImpact,
    AddAttachment,
    DeleteAttachment,
    DeleteCustomerImpact,
    CommentAdd,
    UpdateWatcher,
    MarkAsInProgress,
    MarkAsResolved,
    MarkAsClosed
}
