import React from 'react';
import { DefaultButton, IconButton, IIconProps, PrimaryButton, Text } from '@fluentui/react';
import './DeleteModal.scss';
import deleteLogo from '../../../static/icons/delete-logo.svg';

interface IProps {
    isFolderDeleting: boolean;
    cancelDelete: () => void;
    handleDelete: () => void;
}

const DeleteModal: React.FC<IProps> = ({ isFolderDeleting, cancelDelete, handleDelete }) => {
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    return (
        <>
            <div className="delete-modal-header">
                <div className="deleteHeader">Delete {isFolderDeleting ? 'Folder' : 'List'}</div>
                <div>
                    <IconButton
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={() => cancelDelete()}
                    />
                </div>
            </div>
            <div className="delete-modal-body">
                <div className="modal-body-content">
                    <img src={deleteLogo} alt="delete logo" />
                </div>
                <div className="modal-body-content modal-body-text">
                    <Text styles={{ root: { fontSize: '16px', fontWeight: 600 } }}>
                        Are you sure you want to delete this {isFolderDeleting ? 'folder' : 'list'} ?
                    </Text>
                </div>
                {isFolderDeleting && (
                    <div className="modal-body-content modal-body-text">
                        <Text>Deleting this folder will also delete all of its contents.</Text>
                    </div>
                )}
                <div className="modal-body-content modal-buttons-group">
                    <DefaultButton className="modal-button" onClick={() => cancelDelete()}>
                        Cancel
                    </DefaultButton>
                    <PrimaryButton className="modal-button" onClick={() => handleDelete()}>
                        Delete
                    </PrimaryButton>
                </div>
            </div>
        </>
    );
};

export default DeleteModal;
