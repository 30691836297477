import React, { useState } from 'react';
import './AddHelpfulResources.scss';
import { Image, Modal, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import Button from 'app/components/button/Button';

import circlePlusIcon from '../../../../static/icons/circle-plus.svg';
import surfaceStudioIcon from '../../../../static/icons/surfaceStudio-icon.svg';
import HelpfulResourcesList from '../../viewArticle/helpfulResourcesList/HelpfulResourcesList';

import { Trans, useTranslation } from 'react-i18next';
import { isValidURL, keyPress } from 'app/components/helpers';
import { useAddHelpfulResource } from 'app/services/mutations';

interface IProps {
    helpfulResources: Array<any>;
    isPreview: boolean;
    addHelpfulResource?: (resource: any) => void;
    removeHelpfulResource?: (resource: any) => void;
}

const AddHelpfulResources: React.FC<IProps> = (props: IProps) => {
    const { t: translate } = useTranslation();
    const { helpfulResources, addHelpfulResource, removeHelpfulResource, isPreview } = props;
    const [showModal, setModalStatus] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState('');
    const [customTitle, setCustomTitle] = useState('');
    const [isFetchingMetadata, setIsFetchingMetadata] = React.useState(false);
    const { mutateAsync: addResource } = useAddHelpfulResource();

    const getUrlMetadata = (e: any) => {
        const url = e.target.value;
        setUrl(url);
        if (isValidURL(url, true)) {
            setIsFetchingMetadata(true);
            addResource(url).then((meta: any) => {
                setIcon(meta?.data?.imageSrc);
                setDescription(meta?.data?.description);
                setTitle(meta?.data?.title);
                setCustomTitle(meta?.data?.title);
                setIsFetchingMetadata(false);
            });
        }
    };

    const updateCustomTitle = (e: any) => {
        setCustomTitle(e.target.value);
    };

    const hideModal = () => {
        setModalStatus(false);
    };
    const showModalWindow = () => {
        setModalStatus(true);
    };
    const saveResource = () => {
        const newResource = {
            url,
            title,
            description,
            icon,
            customTitle
        };
        addHelpfulResource(newResource);
        closeModalAndReset();
    };
    const closeModalAndReset = () => {
        hideModal();
        setUrl('');
        setTitle('');
        setDescription('');
        setCustomTitle('');
        setIcon('');
    };

    return (
        <div className="helpful-resources">
            <div className="container">
                <h4 className="m-t-0 fw-600">
                    <Trans>Helpful Resources</Trans>
                </h4>
                {!isPreview && (
                    <Button
                        buttonText={translate('Add Resource')}
                        iconSrc={circlePlusIcon}
                        onClick={() => showModalWindow()}
                        buttonClass="btn btn--transparent m-b-20"
                    />
                )}

                <Modal isOpen={showModal} onDismiss={closeModalAndReset} isBlocking={false} className="surface-modal medium">
                    <div className="modal-header">
                        <span>
                            <Trans>Add Resource</Trans>
                        </span>
                        <span
                            className="close"
                            tabIndex={0}
                            onKeyPress={(e: any) => keyPress(e, closeModalAndReset)}
                            onClick={closeModalAndReset}>
                            X
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <TextField
                                label="Add resource link"
                                onChange={getUrlMetadata}
                                name="Add Resource link"
                                value={url}
                                placeholder="Add resource link"
                            />
                        </div>
                        <div className="resource">
                            {isFetchingMetadata && (
                                <Spinner className="m-h-auto m-t-20 m-b-20" label="Loading..." size={SpinnerSize.large} />
                            )}
                            {isValidURL(url, true) && !isFetchingMetadata && (
                                <div className="resource__data">
                                    <div className="resource__icon">
                                        <Image src={icon ?? surfaceStudioIcon} title="Site icon" alt="Site icon" />
                                    </div>
                                    <div className="resource__details">
                                        <div className="resource__title">
                                            <div className="form-group">
                                                <TextField
                                                    onChange={updateCustomTitle}
                                                    label="Title"
                                                    name="Title"
                                                    value={customTitle}
                                                    placeholder="Title"
                                                />
                                            </div>
                                        </div>
                                        {description && (
                                            <div className="resource__description">
                                                <TextField
                                                    multiline
                                                    rows={3}
                                                    resizable={false}
                                                    label="Description"
                                                    readOnly
                                                    value={description}
                                                    className="border--none"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button buttonClass="btn" buttonText="Cancel" onClick={closeModalAndReset} />

                        <Button
                            buttonClass="btn btn--primary"
                            buttonText="Save Resource"
                            onClick={() => {
                                saveResource();
                            }}
                            disabled={!isValidURL(url, true) || !customTitle?.trim()?.length}
                        />
                    </div>
                </Modal>

                <HelpfulResourcesList showDeleteIcon={!isPreview} helpfulResources={helpfulResources} removeHelpfulResource={removeHelpfulResource} />
            </div>
        </div>
    );
};
export default AddHelpfulResources;
