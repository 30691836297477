import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import DetailsPanel from 'app/modules/admin/detailsPanel/DetailsPanel';
import { IRequest } from 'app/models/common/response';
import { useParams } from 'react-router-dom';
import { formatIntoRequestData } from 'app/components/helpers';
import './RequestDetails.scss';
import { Trans } from 'react-i18next';
import {
    useRequestTypesLookUpData,
    useTeamLookUpData,
    useGetRequestById,
    useRequestDetailsLookUpData
} from 'app/services/queries';
import AddCustomerModal from '../manageRequestDetailedView/addCustomer/AddCustomerModal';
import { IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
import { isAdmin, isSuperAdmin, isUser } from 'app/utils/authUtilities';
import { default as UserDetailsPanel } from 'app/modules/user/detailsPanel/DetailsPanel';
import { unifiedDashboardCardsList } from 'app/models/common/enumerators';
import { RouteConstants } from 'app/utils/constants';

interface IParams {
    id: string;
}

const statusTypes = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Active' },
    { id: 3, name: 'In Progress' },
    { id: 4, name: 'Resolved' },
    { id: 5, name: 'Closed' }
];

const associationTypes = [
    { id: 1, name: 'None' },
    { id: 2, name: 'Parent' },
    { id: 3, name: 'Child' }
];

const RequestDetails: React.FC = (props: any) => {
    let history = useHistory();
    const location = useLocation();
    const [showAdminViewOnly, setShowAdminViewOnly] = useState(false);
    const [showUserViewOnly, setShowUserViewOnly] = useState(false);
    useEffect(() => {
        if (props.location?.state && props.location?.state.previousPath) {
            if (props.location?.state.previousPath.includes(RouteConstants.UNIFIED_DASHBOARD)) {
                setShowAdminViewOnly(false);
                setShowUserViewOnly(true);
            } else if (props.location?.state.previousPath.includes(RouteConstants.MANAGE_REQUESTS)) {
                setShowAdminViewOnly(true);
                setShowUserViewOnly(false);
            }
            history.replace(location.pathname, {});
        }
    }, [location]);
    //set variables
    const [selectedRequest, setSelectedRequest] = useState<IRequest>({});
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const [cardTypeFilter, setCardTypeFilter] = useState(0);

    // skp id from url
    const { id: requestId } = useParams<IParams>();

    // queries
    const {
        data: apiRequestData,
        isLoading: isLoadingRequestList,
        isError: isErrorRequestDetails,
        isSuccess: isSuccessRequestDetails,
        refetch: refetchRequestDetails
    } = useGetRequestById(requestId);
    const { data: requestTypes } = useRequestTypesLookUpData();
    const { data: teams } = useTeamLookUpData();
    const { data: requestDetailsLookup } = useRequestDetailsLookUpData();
    const [isBookmarked, setIsBookmarked] = useState(false);

    const onRefetchHandler = () => {
        refetchRequestDetails();
    };

    useEffect(() => {
        if (!isLoadingRequestList && isSuccessRequestDetails) {
            setSelectedRequest(formatIntoRequestData([apiRequestData?.data], teams, requestTypes)[0]);
        }
    }, [apiRequestData?.data, isLoadingRequestList, isSuccessRequestDetails]);

    useEffect(() => {
        setCardTypeFilter(unifiedDashboardCardsList.MY_REQUEST);
    }, []);

    const [isEditMode, setIsEditMode] = useState(false);
    return (
        <div className="container request-details-container">
            {isLoadingRequestList && <div className="loader"></div>}

            {!isLoadingRequestList && (
                <p>
                    <Link role="link" to="" tabIndex={0} onClick={() => history.goBack()} className="back-request">
                        <span className="arrow-icon"></span>

                        {!isLoadingRequestList && isSuccessRequestDetails && apiRequestData?.data.summary}
                    </Link>
                </p>
            )}

            {!showAdminViewOnly && !showUserViewOnly && (
                <div className="manage-request-detailed-container ">
                    {!isLoadingRequestList &&
                        isSuccessRequestDetails &&
                        (isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles)) && (
                            <DetailsPanel
                                selectedRequest={selectedRequest}
                                requestTypes={requestTypes}
                                statusTypes={statusTypes}
                                associationTypes={associationTypes}
                                setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                                teams={teams}
                                isEditMode={isEditMode}
                                setIsEditMode={setIsEditMode}
                                setSelectedRequest={setSelectedRequest}
                                requestData={requestData}
                                setRequestData={setRequestData}
                                onRefetchHandler={onRefetchHandler}
                                requestDetailsLookup={requestDetailsLookup}
                                pageType="DETAILS_PAGE"
                            />
                        )}
                    {!isLoadingRequestList && isSuccessRequestDetails && isUser(userDetails?.userRoles) && (
                        <UserDetailsPanel
                            selectedRequest={selectedRequest}
                            requestTypes={requestTypes}
                            teams={teams}
                            statusTypes={statusTypes}
                            setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                            cardTypeFilter={cardTypeFilter}
                            setIsBookmarked={setIsBookmarked}
                            isBookMarked={isBookmarked}
                            isLoadingRequestList={isLoadingRequestList}
                            setSelectedRequest={setSelectedRequest}
                            pageType="DETAILS_PAGE"
                        />
                    )}
                    {!isLoadingRequestList && isErrorRequestDetails && (
                        <div className="no-results-wrap">
                            <Trans>The requested details are not available</Trans>.
                        </div>
                    )}
                </div>
            )}
            {showAdminViewOnly && (
                <div className="manage-request-detailed-container ">
                    {!isLoadingRequestList && isSuccessRequestDetails && (
                        <DetailsPanel
                            selectedRequest={selectedRequest}
                            requestTypes={requestTypes}
                            statusTypes={statusTypes}
                            associationTypes={associationTypes}
                            setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                            teams={teams}
                            isEditMode={isEditMode}
                            setIsEditMode={setIsEditMode}
                            setSelectedRequest={setSelectedRequest}
                            requestData={requestData}
                            setRequestData={setRequestData}
                            onRefetchHandler={onRefetchHandler}
                            requestDetailsLookup={requestDetailsLookup}
                        />
                    )}
                </div>
            )}

            {showUserViewOnly && (
                <div className="manage-request-detailed-container ">
                    {!isLoadingRequestList && isSuccessRequestDetails && (
                        <UserDetailsPanel
                            selectedRequest={selectedRequest}
                            requestTypes={requestTypes}
                            teams={teams}
                            statusTypes={statusTypes}
                            setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                            cardTypeFilter={cardTypeFilter}
                            setIsBookmarked={setIsBookmarked}
                            isBookMarked={isBookmarked}
                            isLoadingRequestList={isLoadingRequestList}
                            setSelectedRequest={setSelectedRequest}
                        />
                    )}
                </div>
            )}

            <AddCustomerModal
                modalState={isAddCustomerModalOpen}
                updateModalState={(value: boolean) => setIsAddCustomerModalOpen(value)}
            />
        </div>
    );
};

export default RequestDetails;
