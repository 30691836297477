import React, { useEffect, useState } from 'react';
import { PersonaInitialsColor } from '@fluentui/react';
import { Link } from '@fluentui/react';
import { ICommentResponse } from 'app/models/common/response';
import _ from 'lodash';
import Avatar from 'app/components/avatar/Avatar';
import Reply from './reply/Reply';

interface IProps {
    item?: ICommentResponse;
    correlationId: string;
    isOpen: boolean;
    isInternal?: boolean;
    isMultipleType?: boolean;
    isClosed?: boolean;
}

const ReplyList: React.FC<IProps> = ({ item, isOpen, isInternal, isMultipleType, correlationId, isClosed = false }) => {
    // state variables
    const [isReplyHidden, setIsReplyHidden] = useState(true);
    const Moment = require('moment');
    const sortedArray = item.childComments.sort((a, b) => Moment(a.createdOn).diff(Moment(b.createdOn)));

    const handleToggleReplyList = () => {
        setIsReplyHidden((value) => !value);
    };

    const renderCondensedReply = (items: ICommentResponse[]) => {
        let userList = [];
        let userCount;
        for (const item of items) {
            userList.push(item.createdBy);
        }
        const users = _.uniqWith(userList, _.isEqual);
        if (users.length === 1) {
            userCount = (
                <Link role="link" className="reply-link" tabIndex={0} onClick={handleToggleReplyList} style={{ left: '0.5rem' }}>
                    {items.length} replies
                </Link>
            );
        } else if (users.length === 2) {
            userCount = (
                <Link role="link" className="reply-link" tabIndex={0} onClick={handleToggleReplyList} style={{ left: '-0.5rem' }}>
                    {items.length} replies
                </Link>
            );
        } else {
            userCount = (
                <Link role="link" className="reply-link" tabIndex={0} onClick={handleToggleReplyList}>
                    {items.length} replies
                </Link>
            );
        }
        return (
            <>
                {users.length > 0 &&
                    users.map((item: any, itemIndex: number) => (
                        <Avatar
                            key={itemIndex}
                            userId={item?.id}
                            userName={item?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={32}
                            roundedPic={true}
                        />
                    ))}
                {userCount}
            </>
        );
    };

    // hooks
    useEffect(() => {
        setIsReplyHidden(!isOpen);
    }, [isOpen]);

    return (
        <>
            {!isReplyHidden && (
                <>
                    {item.childComments && item.childComments.length > 0 && (
                        <div className="cmt-action-links">
                            <Link role="link" tabIndex={0} onClick={handleToggleReplyList}>
                                Hide Replies
                            </Link>
                        </div>
                    )}
                    <div className="reply-container">
                        {sortedArray &&
                            sortedArray.length > 0 &&
                            sortedArray.map((child, childIndex) => (
                                <>
                                    <Reply
                                        key={childIndex}
                                        correlationId={correlationId}
                                        item={child}
                                        isInternal={isInternal}
                                        isMultipleType={isMultipleType}
                                        itemIndex={childIndex}
                                        isClosed={isClosed}
                                    />
                                </>
                            ))}
                    </div>
                </>
            )}
            {isReplyHidden && item.childComments && item.childComments.length > 0 && (
                <div className="reply-condensed">
                    <div className="reply-details">
                        <div className="reply-persona">{renderCondensedReply(item.childComments)}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReplyList;
