import jwt_decode from 'jwt-decode';
import { StringConstants } from './constants';
import { default as UserHome } from 'app/pages/user/home/Home';
import { default as AdminHome } from 'app/pages/admin/home/Home';
import { IRoles } from 'app/models/common/post';

const { ADMIN, SUPER_ADMIN, CONTENT_CREATOR, CONTENT_MANAGER, DO_NOT_DISTURB, BETA_USER } = StringConstants;

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};
export const getPowerBiToken = () => {
    return localStorage.getItem('powerBIToken');
};

export const decodeToken = (token: string) => {
    const decodedToken: { roles: Array<string>; oid: string; email: string } = jwt_decode(token);
    return decodedToken;
};
export const getDecodedData = (token: string) => {
    const decodedToken: any = jwt_decode(token);
    return decodedToken;
};
export const getRole = (token: string) => {
    const decodedToken = decodeToken(token);

    return decodedToken?.roles || [];
};

export const getRoles = (roles: IRoles[] | string) => {
    let role = [];
    if (typeof roles === 'string') {
        const decodedToken = decodeToken(roles);
        role = decodedToken?.roles || [];
    } else {
        role = roles?.map((role) => role.roleName) || [];
    }
    // return role;
    return Array.from(role).filter((r) => r !== DO_NOT_DISTURB);
};

export const isLoggedIn = (token: string) => {
    return token ? true : false;
};

export const isAdmin = (roles: IRoles[] | string) => {
    return getRoles(roles).includes(ADMIN);
};

export const isUser = (roles: IRoles[] | string) => {
    return getRoles(roles).length === 0;
};

export const isSuperAdmin = (roles: IRoles[] | string) => {
    return getRoles(roles).includes(SUPER_ADMIN);
};

export const isContentCreator = (roles: IRoles[] | string) => {
    return getRoles(roles).includes(CONTENT_CREATOR);
};

export const isContentManager = (roles: IRoles[] | string) => {
    return getRoles(roles).includes(CONTENT_MANAGER);
};

export const isBetaUser = (roles: IRoles[] | string) => {
    return getRoles(roles).includes(BETA_USER);
}

export const getComponent = (roles: IRoles[] | string): React.FC => {
    if (isAdmin(roles) || isSuperAdmin(roles)) return AdminHome;
    return UserHome;
};

export const userPaths = [
    'knowledge-portal',
    'help-request',
    'unified-dashboard',
    'visual-flow',
    'request',
    'article',
    'news-feed',
    'portal-training',
    'submit-feedback',
    'unsubscribe',
    'all-articles',
    'all-news',
    StringConstants.KNOWLEDGE_ARTICLES
];
