import React from 'react';
import '../SummaryCard.scss';
import useStore from 'app/store';
import { Text, FontIcon } from '@fluentui/react';
import { IListFolder, IListQuery } from 'app/models/common/post';
import { iconOptions } from '../../listBuilder/options/Options';
import noLists from 'app/static/icons/NoLists.svg';

interface IProps {
    folderData: IListFolder;
    stateData: any;
    listSummary: any;
}

const ListBuilderCardFolder: React.FC<IProps> = ({ folderData, listSummary }) => {
    const requestType = useStore((state: any) => state.requestType);
    const listId = useStore((state: any) => state.listId);
    const setLastSelectedRequest = useStore((state: any) => state.setLastSelectedRequest);
    const setRequestType = useStore((state: any) => state.setRequestType);
    const setListBuilderFilters = useStore((state: any) => state.setListBuilderFilters);
    const setFilterPanelDisabled = useStore((state: any) => state.setFilterPanelDisabled);
    const setListIcon = useStore((state: any) => state.setListIcon);
    const setListId = useStore((state: any) => state.setListId);

    const handleSetSelectedCard = (list: IListQuery) => {
        setLastSelectedRequest({});
        setRequestType(list.queryName);
        setListBuilderFilters(list.queryFilters);
        setFilterPanelDisabled(true);
        setListIcon(iconOptions[list.icon].imageSrc);
        setListId(list.id);
    };

    const setActiveCard = (type: string, id: string) => {
        return requestType === type && listId === id ? 'active' : '';
    };

    return (
        <div className="lb-card-folder">
            <div className="lb-card-folder-name">
                <FontIcon iconName="OpenFolderHorizontal" className="lb-folder-icon" /> 
                <Text className="lb-card-folder-text">{folderData.name}</Text>
            </div>
            <div className="lb-list-container">
                {folderData.savedListQueries.length > 0 ? folderData.savedListQueries.map((list) => (
                    <div className={`lb-card-list ${setActiveCard(list.queryName, list.id)}`} onClick={() => handleSetSelectedCard(list)}>
                        <img className={`lb-card-list-icon`} src={setActiveCard(list.queryName, list.id) === 'active' ? iconOptions[list.icon].selectedImageSrc : iconOptions[list.icon].imageSrc} alt="list icon" />
                        <Text className="lb-card-list-name">{list.queryName}</Text>
                        <Text className="lb-card-list-count" variant="mediumPlus">{listSummary?.find((item: any) => item.queryId === list.id).count ? listSummary?.find((item: any) => item.queryId === list.id).count : '0'}</Text>
                    </div>
                )) : (
                    <div className={`lb-no-list`}>
                        <img className="lb-card-list-icon" src={noLists} alt="No Lists in this folder" />
                        <Text className="lb-card-no-content">No lists in this folder</Text>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ListBuilderCardFolder;