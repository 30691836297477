import { Modal } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import Button from '../button/Button';
import './ConfirmationModal.scss';
import { useEffect } from 'react'; 
import { useUnassociateRequest } from 'app/services/mutations';
import { IAssociationData } from 'app/models/common/request';
import { Spinner } from '@fluentui/react';
interface IProps {
    message: string;
    childUnassociationItem: IAssociationData;
}

export const useConfirmationModal = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const ConfirmationModal: React.FC<IProps> = ({
        message,
        childUnassociationItem
     }) => {
        const { mutate: unassociateRequest,
            isLoading,
            isSuccess,
            isError
          } = useUnassociateRequest();

        useEffect(() => {
            if(isSuccess){
                hideModal();
            }
        }, [isSuccess]);
          
        const unassociateRequestAndCloseModal = () => {
            unassociateRequest(childUnassociationItem);
        }


        const errorMessage = "Something went wrong, please try again.";

        return (
            <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                className="surface-modal custom-message-modal-hook">
                <div className="modal-header">
                    <span className="p-b-20"></span>
                </div>
                <div className="modal-body text-center">
                    {isError ? (
                        <div>
                            <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                            <h4 className="m-t-5 m-b-5">{errorMessage}</h4>
                        </div>
                    ) : (
                        <div>
                            {isLoading ? <Spinner label="Unlinking request. Please wait." /> : <h4 className="m-t-5 m-b-5">{message}</h4>}
                        </div>
                    )}
                </div>
                <div className="confirmation-buttons">
                    <div className="child-btn-container">
                        <Button disabled={isLoading || isError} buttonClass="btn btn--secondary" buttonText="Unlink" onClick={unassociateRequestAndCloseModal} />
                    </div>
                    <div className="child-btn-container">
                        <Button buttonClass="btn btn--primary" buttonText="Cancel" onClick={hideModal} />
                    </div>
                </div>
            </Modal>
        );
    };

    return { ConfirmationModal, showModal, hideModal };
};
