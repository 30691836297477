import NotFound from 'app/components/notFound/NotFound';
import MyAccount from 'app/modules/myAccount/MyAccount';
import VisFlow from 'app/pages/admin/vis/vis';
import RequestExp from 'app/pages/user/newReqExperience/RequestExp';
import SearchResult from 'app/pages/user/searchResult/SearchResult';
import UnifiedDashboard from 'app/pages/user/unifiedDashboard/UnifiedDashboard';
import ArticlesByTagName from 'app/modules/articles/viewArticle/articlesByTagName/ArticlesByTagName';
import CreateArticle from '../modules/articles/createArticle/CreateArticle';
import ManageRequestsPage from 'app/pages/admin/manageRequests/ManageRequestsPage';
import ArticleView from 'app/modules/articles/viewArticle/articlesListView/ArticleView';
import ArticleDetail from 'app/modules/articles/viewArticle/articleDetail/ArticleDetail';
import TeamContactDetail from 'app/pages/admin/contactDetails/TeamContactDetails';

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Feedback from 'app/pages/admin/feedback/Feedback';
import SearchPage from 'app/pages/admin/search/SearchPage';
import NewsFeed from 'app/pages/user/newsfeed/NewsFeed';
import RequestDetails from 'app/modules/user/requestDetails';
import RequestAdminDetails from 'app/modules/admin/requestDetails/RequestDetails';
import NewsfeedAuthor from 'app/pages/user/newsfeedAuthor/NewsfeedAuthor';
import Home from 'app/pages/user/home/Home';
import { AdminRoute, BetaUserRoute, ContentCreatorRoute, ContentManagerRoute, UserRoute } from './privateRoute';
import Notifications from 'app/modules/admin/notifications/Notifications';
import TrainingPortal from 'app/modules/articles/viewArticle/trainingPortal/TrainingPortal';
import ViewReport from 'app/pages/admin/view-report/ViewReport';
import ManageReportsPage from 'app/pages/admin/manageReports/ManageReportsPage';
import KnowledgeArticleLanding from 'app/modules/articles/viewArticle/knowledgeArticleLanding/KnowledgeArticleLanding';
import ArticleSearchResults from 'app/modules/articles/viewArticle/searchnews/searchResults/ArticleSearchResults';
import NewEmail from 'app/modules/emailer/newEmail/NewEmail';
import ManageContent from 'app/pages/contentCreator/manageContent/ManageContent';
import Unsubscribe from 'app/pages/admin/unsubscribe/unsubscribe';
import { RequestDetailsRoute } from './redirectionRoute';
import { RouteConstants } from 'app/utils/constants';
import ListBuilder from 'app/pages/admin/listBuilder/ListBuilder';
import ManageLists from 'app/modules/admin/manageLists/ManageLists';
import AdminTrainingPortal from 'app/modules/articles/viewArticle/adminTrainingPortal/AdminTrainingPortal';

export const routes = (
    <Switch>
        <UserRoute exact path="/knowledge-portal" component={Home} />
        <UserRoute exact path="/help-request" component={RequestExp} />
        <Route exact path="/profile" component={MyAccount} />
        <UserRoute exact path="/unified-dashboard" component={UnifiedDashboard} />
        <UserRoute exact path="/unified-dashboard/:id" component={UnifiedDashboard} />
        <UserRoute exact path="/visual-flow/:id" component={VisFlow} />
        <UserRoute exact path="/search-results" component={SearchResult} />
        <Route
            exact
            path={[RouteConstants.SEARCH_RESULTS_ARTICLES, RouteConstants.SEARCH_RESULTS_ALL_ARTICLES, RouteConstants.SEARCH_RESULTS_NEWS]}
            component={ArticleSearchResults}
        />
        <UserRoute
            exact
            path={['/search-results/requests', '/search-results/requests/:searchType/:search?']}
            component={SearchPage}
        />
        <UserRoute exact path="/request/:id" component={RequestDetails} />
        <AdminRoute exact path="/request-detail/:id" component={RequestAdminDetails} />
        <Route exact path="/team-contacts" component={TeamContactDetail} />

        <UserRoute exact path="/article/:id" component={ArticleDetail} />
        <UserRoute exact path={['/articles', '/knowledge-articles', '/news']} component={ArticlesByTagName} />

        <Route exact path="/submit-feedback" component={Feedback} />
        <Route exact path="/notifications/unsubscribe" component={Unsubscribe} />

        <AdminRoute exact path="/manage-requests" component={ManageRequestsPage} />
        <AdminRoute exact path="/report/:id" component={ViewReport} />
        <AdminRoute exact path="/manage-reports" component={ManageReportsPage} />
        <AdminRoute exact path="/manage-lists" component={ManageLists} />
        <AdminRoute exact path="/list-builder" component={ListBuilder} />
        <AdminRoute exact path="/admin-training" component={AdminTrainingPortal} />
        <ContentManagerRoute exact path="/email/create" component={NewEmail} />
        <ContentCreatorRoute
            exact
            path={['/manage-content/article/edit/:id', '/manage-content/article/create']}
            component={CreateArticle}
        />
        <ContentCreatorRoute exact path={[RouteConstants.MANAGE_CONTENT_ARTICLES, RouteConstants.MANAGE_CONTENT_EMAILS]} component={ManageContent} />
        <Route exact path="/portal-training" component={TrainingPortal} />
        <Route exact path="/knowledge-articles/home" component={KnowledgeArticleLanding} />
        <Route exact path={[RouteConstants.ALL_ARTICLES, RouteConstants.ALL_NEWS]} component={ArticleView} />
        <Route exact path={['/knowledge-articles/article/:id', '/news-feed/article/:id']} component={ArticleDetail} />
        <Route exact path="/news-feed/home" component={NewsFeed} />
        <Route exact path="/news-feed/author/:id" component={NewsfeedAuthor} />
        <Route exact path="/notifications" component={Notifications} />
        <RequestDetailsRoute exact path={'/request-redirect/:id'} />
        <Route exact path="*" component={NotFound} />
    </Switch>
);
