import { PersonaInitialsColor } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Icon } from '@fluentui/react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import useStore from 'app/store';

import './Header.scss';
import { ContextualMenu, IContextualMenuItem } from '@fluentui/react';
import { useMsal } from '@azure/msal-react';

import SearchBarWithSuggestions from 'app/modules/admin/searchBarWithSuggestions/SearchBarWithSuggestions';
import { getRoles, isUser } from 'app/utils/authUtilities';
import { keyPress, quickSearchVisibility } from 'app/components/helpers';
import NotificationOverLay from 'app/modules/admin/notifications/notificationsOverflow/NotificationOverLay';
import { useGetArticleCategories, useNotifications, useNotificationsCount } from 'app/services/queries';
import { startCase } from 'lodash';
import { RouteConstants, StringConstants } from 'app/utils/constants';
import { useNotificationsRead } from 'app/services/mutations';
import { IUserDetails } from 'app/models/common/post';
import Avatar from '../avatar/Avatar';
import { Trans } from 'react-i18next';

const { END_USER } = StringConstants;

const Header: React.FC = () => {
    const { instance } = useMsal();
    const history = useHistory();
    const { pathname } = useLocation();
    const splitLocation = pathname.split('/').filter((path) => path);
    const isCA = pathname === '/manage-content/article/create';
    const isHR = pathname === RouteConstants.HELP_REQUEST;

    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [tempNotificationsCount, setTempNotificationsCount] = useState('0');
    const [bellAnimation, setBellAnimation] = useState(false);
    const [showContextualMenuUser, setShowContextualMenuUser] = React.useState(false);

    const userRef = React.useRef(null);
    const notificationsContainer = React.useRef(null);

    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);

    const { data: notificationsCount, refetch: getNotifications, isFetched: IsNotificationsFetched } = useNotificationsCount();
    const { mutate: notificationsRequest, data: notifications } = useNotifications();
    const { mutateAsync: markAllAsRead } = useNotificationsRead();
    const { isFetched: isFetchedCategories, data: articleCategories } = useGetArticleCategories();
    const setArticleCategories = useStore((state) => state.setArticleCategories);

    const handleLogout = () => {
        localStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: '/'
        });
    };
    const handleUserProfile = () => {
        history.push(RouteConstants.PROFILE);
    };

    const userItems: IContextualMenuItem[] = [
        {
            key: 'profile',
            text: 'Profile',
            onClick: handleUserProfile
        },
        {
            key: 'sign out',
            text: 'Sign Out',
            onClick: handleLogout
        }
    ];

    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>) => {
        ev.preventDefault(); // don't navigate
        setIsNotificationVisible((prevVal) => !prevVal);
    }, []);

    const onShowContextualMenuUser = React.useCallback(
        (ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>) => {
            ev.preventDefault(); // don't navigate
            setShowContextualMenuUser(!showContextualMenuUser);
        },
        []
    );
    const onHideContextualMenuUser = React.useCallback(() => setShowContextualMenuUser(false), []);

    const handleNotificationClose = () => {
        setIsNotificationVisible(false);

        markAllAsRead().then(() => {
            setInterval(() => {
                setBellAnimation(false);
            }, 15000);
            setTempNotificationsCount('0');
        });
    };

    const useNotificationClose = (ref: any, handleSuggestionClose: Function) => {
        React.useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleSuggestionClose();
                }
            }

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [handleSuggestionClose, ref]);
    };

    useEffect(() => {
        setTempNotificationsCount(notificationsCount > 99 ? '99+' : notificationsCount);
        if (notificationsCount > 0) {
            notificationsRequest({
                pageNumber: 1,
                pageSize: notificationsCount > 5 ? 5 : notificationsCount
            });
            setBellAnimation(true);
            setInterval(() => {
                setBellAnimation(false);
            }, 15000);
        }
    }, [notificationsCount, notificationsRequest]);

    useEffect(() => {
        const fetchNotifications = setInterval(() => {
            if (!IsNotificationsFetched) getNotifications();
        }, 30000);
        return () => {
            clearInterval(fetchNotifications);
        };
    }, []);

    useEffect(() => {
        setArticleCategories(articleCategories);
    }, [isFetchedCategories]);

    useNotificationClose(notificationsContainer, handleNotificationClose);

    const isActiveGlobalNav = (navItem: 'article' | 'news') => {
        switch (navItem) {
            case 'article':
                return ['/knowledge-articles/home', RouteConstants.SEARCH_RESULTS_ARTICLES, RouteConstants.ALL_ARTICLES].includes(
                    pathname
                );
            case StringConstants.NEWS:
                return ['/news-feed/home', RouteConstants.SEARCH_RESULTS_NEWS, RouteConstants.ALL_NEWS].includes(pathname);
            default:
                break;
        }
    };
    return (
        <div className="ms-Grid p-a-0 drop-shadow" dir="ltr">
            <div className="header">
                <div className="container">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col  ms-sm12 ms-lg6">
                            <div className="header-text" role="navigation">
                                <Link aria-label="admin dashboard link" role="link" to="/" onClick={() => setUserSearchValue('')}>
                                    <Trans>Surface Knowledge Portal</Trans>
                                </Link>
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg6">
                            <div className="user-menu">
                                <div
                                    onClick={onShowContextualMenu}
                                    tabIndex={0}
                                    onKeyPress={(e) => keyPress(e, () => onShowContextualMenu(e))}
                                    className="notif-button"
                                    aria-label="notification"
                                    role="button">
                                        <Icon
                                            id="notificationHeader"
                                            aria-hidden="true"
                                            className={`bell-icon ${bellAnimation && !isCA && !isHR ? 'animate' : ''}`}
                                            iconName="Ringer"
                                            aria-label="bell"
                                        />
                                    {tempNotificationsCount > '0' && (
                                        <div className={`badge ${tempNotificationsCount === '99+' ? 'badge--xl' : ''}`}>
                                            {tempNotificationsCount}
                                        </div>
                                    )}
                                </div>
                                {isNotificationVisible && (
                                    <div ref={notificationsContainer} className="notification-container">
                                        <NotificationOverLay
                                            handleNotificationClose={handleNotificationClose}
                                            items={tempNotificationsCount > '0' ? notifications?.data.items : []}
                                        />
                                    </div>
                                )}

                                {userDetails?.id && (
                                    <div id="profileHeader" className="profile border-left">
                                        <div className="display-pic">
                                            {userDetails && (
                                                <Avatar
                                                    userId={userDetails.id}
                                                    userName={userDetails.displayName}
                                                    initialColor={PersonaInitialsColor.teal}
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="userName"
                                            ref={userRef}
                                            tabIndex={0}
                                            onKeyPress={(e) => keyPress(e, () => onShowContextualMenuUser(e))}
                                            onClick={onShowContextualMenuUser}>
                                            {userDetails?.displayName}
                                            <br />
                                            <small>
                                                <Trans>
                                                    {getRoles(userDetails?.userRoles)?.length > 0
                                                        ? getRoles(userDetails?.userRoles)
                                                              .map((item) => startCase(item))
                                                              .join(', ')
                                                        : startCase(END_USER).split(' ')[1]}
                                                </Trans>
                                            </small>
                                        </div>

                                        <ContextualMenu
                                            items={userItems}
                                            hidden={!showContextualMenuUser}
                                            target={userRef}
                                            onItemClick={onHideContextualMenuUser}
                                            onDismiss={onHideContextualMenuUser}
                                            ariaLabel="profile-menu"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="bottom-header">
                    <nav className="navigation_link" aria-label="header-nav-link">
                        <ul>
                            <li className={`c-pointer ${splitLocation.reverse()[0] ? '' : 'active'}`}>
                                <Link role="link" to="/">
                                        <span
                                            aria-label="home"
                                            role="navigation"
                                            onClick={() => setUserSearchValue('')}
                                            className={`${
                                                isUser(userDetails?.userRoles) ? 'home-icon' : 'admin-home-icon'
                                            }`}></span>
                                </Link>
                            </li>

                            {!isUser(userDetails?.userRoles) && (
                                <li
                                    className={
                                        splitLocation.reverse()[0] === 'knowledge-portal' ||
                                        splitLocation.reverse()[1] === 'unified-dashboard' ||
                                        splitLocation.reverse()[0] === 'unified-dashboard'
                                            ? 'active'
                                            : ''
                                    }>
                                    <Link to="/knowledge-portal" onClick={() => setUserSearchValue('')}>
                                        <Trans>Knowledge Portal</Trans>
                                    </Link>
                                </li>
                            )}
                            <li className={isActiveGlobalNav('article') ? 'active' : ''}>
                                <Link role="link" to="/knowledge-articles/home">
                                    <Trans>Knowledge Articles</Trans>
                                </Link>
                            </li>

                            <li className={isActiveGlobalNav('news') ? 'active' : ''}>
                                <Link role="link" to="/news-feed/home">
                                    <Trans>News Feed</Trans>
                                </Link>
                            </li>
                        </ul>
                    </nav>

                    {quickSearchVisibility(splitLocation.join('/')) && <SearchBarWithSuggestions />}
                </div>
            </div>
        </div>
    );
};

export default Header;
