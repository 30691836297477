import React, { useState } from 'react';
import './Sidebar.scss';
import moment from 'moment';
import SelectTags from '../tags/SelectTags';
import { IUserDetails } from 'app/models/common/post';
import { Dropdown, PersonaInitialsColor } from '@fluentui/react';
import { Trans, useTranslation } from 'react-i18next';

import { UserDomain } from 'app/enums/UserDomain.enum';
import Avatar from 'app/components/avatar/Avatar';
import { keyPress } from 'app/components/helpers';
import { ArticleType } from 'app/enums/ArticleType.enum';

interface IProps {
    lastEditDate?: Date;
    author?: IUserDetails;
    stats?: any;
    hideInfo?: boolean;
    setHideInfo?: (hideInfo: any) => void;
    tags: Array<any>;
    setTags: (tags: any) => void;
    onArticleTypeChange?: any;
    selectedType?: any;
    dropdownOptions: any;
    createdOn?: Date;
    type: number;
}

const DATE_FORMAT = 'MMMM D, YYYY';
const TIME_FORMAT = 'h:mm A';

const Sidebar: React.FC<IProps> = (props: IProps) => {
    const {
        lastEditDate,
        author,
        stats,
        hideInfo,
        setHideInfo,
        tags,
        setTags,
        onArticleTypeChange,
        selectedType,
        dropdownOptions,
        createdOn,
        type
    } = props;
    const [toggleMenu, setToggleMenu] = useState(false);
    const handleMenu = () => {
        setToggleMenu(!toggleMenu);
    };

    const removeTag = (tagId: string) => {
        const filteredTags = tags.filter((tag) => tag.id !== tagId);
        setTags(filteredTags);
    };

    const { t: translate } = useTranslation();
    const getFormattedReadingTime = () => {
        let time = Math.ceil(stats.minutes);
        let units = time > 1 ? translate('minutes') : translate('minute');
        return `${'About'} ${time} ${units} ${'read'}`;
    };
    return (
        <div className="d-flex--col gap-30 pos-sticky top-0">
            <div className="type-dropdown">
                <label>
                    <Trans>Article Type</Trans>
                </label>
                <Dropdown
                    role="button"
                    aria-label="article type dropdown"
                    defaultSelectedKey={selectedType?.key ?? '1'}
                    selectedKey={selectedType?.key ?? '1'}
                    options={dropdownOptions}
                    onChange={onArticleTypeChange}
                />
            </div>
            <div>
                <label>
                    <Trans>Last Edited Date</Trans>
                </label>
                <div className="date">
                    {lastEditDate ? moment(lastEditDate).format(DATE_FORMAT) : moment(createdOn).format(DATE_FORMAT)}
                </div>
            </div>
            <div>
                <label>
                    <Trans>Written by</Trans>
                </label>
                <div className="author-details">
                    {!hideInfo && (
                        <div className="details-author-info-card-avatar">
                            <Avatar
                                userId={author?.id}
                                userName={author?.displayName}
                                initialColor={PersonaInitialsColor.teal}
                                imageSize={72}
                                roundedPic={true}
                            />
                        </div>
                    )}
                    <div className="details">
                        {!hideInfo && <div className="name">{author?.displayName}</div>}
                        <div className="role">---</div>
                        <div className="team">
                            {author?.userDomain > 0 && (
                                <div className="capsule capsule--green">{UserDomain[author?.userDomain]}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex vertical-align">
                <label className="d-flex ai-center">
                    <input
                        aria-label=""
                        className="m-l-0 m-r-5"
                        type="checkbox"
                        checked={!hideInfo}
                        onChange={() => setHideInfo(!hideInfo)}
                    />
                    {translate(`Show Photo & Name`)}
                </label>
            </div>
            {(type === ArticleType.KnowledgeArticle || type === ArticleType.News) && (
                <div className="select-tags">
                    <label>
                        <Trans>Choose Tags</Trans>
                        <i>
                            (<Trans>Limit upto 5</Trans>)
                        </i>
                    </label>
                    <div className="select-tags-input tags">
                        {tags &&
                            tags.map((tag) => {
                                const { id, tagHierarchy } = tag;
                                return (
                                    <div className="tag tag--close" key={id}>
                                        {tagHierarchy}
                                        <span
                                            className="icon icon--right"
                                            onKeyPress={(e: any) => keyPress(e, () => removeTag(id))}
                                            tabIndex={0}
                                            onClick={() => removeTag(id)}>
                                            x
                                        </span>
                                    </div>
                                );
                            })}
                        <button
                            onMouseDown={handleMenu}
                            onBlur={() => setToggleMenu(false)}
                            tabIndex={0}
                            aria-label="select tags"></button>
                    </div>
                    <SelectTags menuState={toggleMenu} handleMenu={handleMenu} setSelectedTags={setTags} selectedTags={tags} />
                </div>
            )}
            <div className="last-edited">
                <label>
                    <Trans>Last edited</Trans> <span className="time">{moment(lastEditDate).format(TIME_FORMAT)}</span>
                </label>
                <div className="details">
                    <i>
                        {stats?.words} <Trans>words</Trans>, {getFormattedReadingTime()}
                    </i>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
