import React, { useState, useEffect } from 'react';
import { useTeamContactList } from 'app/services/queries';
import { Text } from '@fluentui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './ContactTable.scss';
import useStore from 'app/store';
import { StringConstants } from 'app/utils/constants';

const ContactTable: React.FC = () => {
    // constants
    const getId = (value: string) => {
        let id = 0;
        switch (value) {
            case StringConstants.SDSG:
                id = 1;
                break;
            case StringConstants.CXP:
                id = 2;
                break;
            case StringConstants.CSS:
                id = 3;
                break;
            case StringConstants.CREW:
                id = 4;
                break;
            default:
                break;
        }
        return id;
    };
    const columns = [
        {
            key: 'team-member',
            title: 'TEAM MEMBER'
        },
        { key: 'email-id', title: 'EMAIL ID' },
        { key: 'contact', title: 'CONTACT' }
    ];
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(10);
    const [hasMore, setHasMore] = useState(true);

    const selectedTab = useStore((state: any) => state.selectedTab);

    const { data, isLoading } = useTeamContactList(getId(selectedTab), count);

    const fetchData = () => {
        setTimeout(() => {
            if (tableData?.length < count) {
                setHasMore(false);
            } else {
                setHasMore(true);
                setCount((count) => count + 10);
            }
        }, 1000);
    };

    useEffect(() => {
        if (!isLoading) {
            setTableData(data);
        }
    }, [data, isLoading]);

    return (
        <div className="contact-table-container p-t-10 p-b-10 p-l-10 p-r-10">
            <div className="table-inner-cont p-b-15">
                <div className="ct-header bold-text p-t-10 p-b-15 p-l-30 p-r-30  row-flex-box justify-sb">
                    {columns.map((column, i) => (
                        <div className={`${column.key.toLowerCase()}-header col-${i} `}>{column.title}</div>
                    ))}
                </div>
                <div className="ct-body">
                    <InfiniteScroll
                        dataLength={tableData?.length}
                        next={fetchData}
                        hasMore={hasMore}
                        loader={<div className="loader"></div>}>
                        {tableData?.map((dataItem, i) => (
                            <div className="ct-row justify-sb p-l-30 p-r-30 p-t-10 p-b-15 row-flex-box">
                                <div className={` col-0`}>
                                    <Text>{dataItem?.displayName}</Text>
                                </div>
                                <div className={` col-1`}>
                                    <Text>{dataItem?.mail}</Text>
                                </div>
                                <div className={`col-2`}>
                                    {dataItem?.businessPhones &&
                                        dataItem?.businessPhones?.map((contactNumber: any) => (
                                            <div>
                                                <Text>{contactNumber}</Text>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
};

export default ContactTable;
