export enum ArticleStatus {
    'Draft' = 1,
    'Submitted' = 2,
    'ScheduledToPublish' = 3,
    'Published' = 4,
    'Removed' = 5,
    'Redrafted' = 6,
    'Review Requests' = 7
}

export enum RequestStatus {
    New = 1,
    Active,
    InProgress,
    Resolved,
    Closed
}
