import React, { useEffect, useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import './CommentTypeSelector.scss';

interface IProps {
    isInternal: boolean;
    setIsInternal: Function;
    noExternalSection?: boolean;
    type?: string;
}

const CommentTypeSelector: React.FC<IProps> = ({ isInternal, setIsInternal, noExternalSection, type }) => {
    const options: IChoiceGroupOption[] = [
        { key: 'internal', text: 'Internal ' + type },
        { key: 'external', text: 'External ' + type }
    ];
    const singleOption: IChoiceGroupOption[] = [{ key: 'internal', text: 'Internal ' + type }];
    // state variables
    const [selectedKey, setSelectedKey] = useState('external');

    // helper functions
    const handleOnchange = (ev: any, option: IChoiceGroupOption): void => {
        if (option.key === 'internal') {
            setIsInternal(true);
        } else {
            setIsInternal(false);
        }
    };

    useEffect(() => {
        if (isInternal) {
            setSelectedKey('internal');
        } else {
            setSelectedKey('external');
        }
    }, [isInternal]);
    return (
        <div className="comment-type-selector">
            <div className="cmt-type-selector-wrap">
                <ChoiceGroup
                    className={`${noExternalSection ? 'single-select' : 'multi-select'}`}
                    selectedKey={selectedKey}
                    options={noExternalSection ? singleOption : options}
                    onChange={handleOnchange}
                    required={true}
                />
            </div>
        </div>
    );
};

export default CommentTypeSelector;
