import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useHeightListener = (ref: any) => {
    const [height, setHeight] = useState(0);

    const observer = useRef(
        new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;
            setHeight(height);
        })
    );

    useEffect(() => {
        if (ref.current) {
            observer.current.observe(ref.current);
        }
    }, [ref, observer]);

    return height;
};
