import React, { useEffect } from 'react';
import { PersonaInitialsColor, Text } from '@fluentui/react';
import _ from 'lodash';
import { ICommentResponse, IRequest } from 'app/models/common/response';
import { injectTargetToAnchor, ReplaceUrlByAnchor } from '../../helpers';
import Avatar from 'app/components/avatar/Avatar';
import ReplyPanel from '../replyPanel/ReplyPanel';
import ReplyList from '../replyList/ReplyList';

import CommentField from 'app/components/commentField/CommentField';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';
import { Entity, BlobStorageContainer } from 'app/utils/constants';

interface IProps {
    item: ICommentResponse;
    correlationId: string;
    isInternal?: boolean;
    isMultipleType?: boolean;
    selectedRequest?: IRequest;
    itemIndex?: number;
    isClosed?: boolean
}

const Comment: React.FC<IProps> = ({ item, correlationId, isInternal, isMultipleType, selectedRequest, itemIndex, isClosed = false }) => {
    const [isEditActive, setIsEditActive] = React.useState(false);
    const [parsedMessage, setParsedMessage] = React.useState('');

    const toggleEdit = () => {
        setIsEditActive((value) => !value);
    };

    const getTaggedUser = (name: string, users: any) => {
        if (!_.isEmpty(name) && !_.isEmpty(users)) {
            return _.find(users, (item) => item.id === name);
        } else {
            return {
                id: '',
                displayName: '',
                email: ''
            };
        }
    };

    useEffect(() => {
        if (item?.commentText && item?.commentText !== '') {
            const replaceStorageUrls = async () => {
                var res = await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.COMMENT_STATUS_FILES, item.commentText);
                if (res !== '') {
                    setParsedMessage(res);
                }
            };

            replaceStorageUrls();
        }
    }, [item.commentText]);

    const generateCommentMessage = (message: string, taggedUsers: any) => {
        // the default tag for the rich text editor is <p>
        // because old requests will not have any html tags and will be basic strings,
        // we use this check to see if it is rich text or basic text so that all text can be formatted correctly
        if (message.includes('<p>')) {
            return parsedMessage;
        }

        let formattedMsg = message;
        let users = Array.from(message?.matchAll(/<userid:(.*?)>/g));
        for (const user of users) {
            formattedMsg = formattedMsg.replace(
                user[0],
                `<span class="tagged-user">${getTaggedUser(user[1], taggedUsers)?.displayName}</span>`
            );
        }
        if (isMultipleType) {
            formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request-detail');
        } else {
            formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request');
        }
        formattedMsg = ReplaceUrlByAnchor(injectTargetToAnchor(formattedMsg?.replace(/\\/g, '')), 'tagged-user', true);
        return formattedMsg;
    };

    return (
        <>
            <div className="cmt-block" key={itemIndex}>
                <div className="cmt-contents">
                        <div className="cmt-connector"></div>
                        <div className="cmt-persona">
                            <Avatar
                                userId={item?.createdBy?.id}
                                userName={item?.createdBy?.displayName}
                                initialColor={PersonaInitialsColor.coolGray}
                                imageSize={32}
                                roundedPic={true}
                            />
                        </div>
                        <div className="cmt-wrap">
                            {isEditActive ? (
                                <>
                                    <div className="cmt-details">
                                        <Text className="cmt-usr-name">{item.createdBy && item.createdBy.displayName}</Text>
                                    </div>
                                    <CommentField
                                        isEditActive={isEditActive}
                                        initialValue={parsedMessage}
                                        correlationId={item.correlationId}
                                        entityType={1}
                                        parentCommentId={item.id}
                                        cancelOperation={toggleEdit}
                                        textareaSize={2}
                                        className=""
                                        isInternal={isInternal}
                                        fieldType="comment"
                                        selectedRequest={selectedRequest}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="cmt-details">
                                        <Text className="cmt-usr-name">{item.createdBy && item.createdBy.displayName}</Text>
                                        <span
                                            className="cmt-text"
                                            dangerouslySetInnerHTML={{
                                                __html: generateCommentMessage(item.commentText, item.taggedUsers)
                                            }}></span>
                                    </div>
                                </>
                            )}
                            {!isEditActive && correlationId && item && (
                                <ReplyPanel
                                    correlationId={correlationId}
                                    item={item}
                                    isInternal={isInternal}
                                    isMultipleType={isMultipleType}
                                    toggleEdit={toggleEdit}
                                    isEditActive={isEditActive}
                                    isClosed={isClosed}
                                />
                            )}
                            <ReplyList
                                correlationId={correlationId}
                                item={item}
                                isInternal={isInternal}
                                isOpen={itemIndex === 0 && item.childComments && item.childComments.length > 0 ? true : false}
                                isMultipleType={isMultipleType}
                                isClosed={isClosed}
                            />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Comment;
