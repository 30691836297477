import React from 'react';
import './Sidebar.scss';
import { Trans } from 'react-i18next';
const Sidebar: React.FC = () => {
    return (
        <div className="sidebar">
            <h2>
                <Trans>My Account</Trans>
            </h2>
            <ul>
                <li className="active">
                    <Trans>My Details</Trans>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
