import React from 'react';
import '@progress/kendo-theme-material';
import SearchResults from 'app/modules/admin/searchResults/SearchResults';

const SearchPage: React.FC = () => {
    return (
        <>
            <SearchResults />
        </>
    );
};

export default SearchPage;
