import React, { useRef, useEffect } from 'react';
import { TextField, Text, ShimmerElementType, Shimmer } from '@fluentui/react';
import './index.scss';
import { useGetCustomersList } from 'app/services/mutations';
import { Trans } from 'react-i18next';
import { keyPress } from 'app/components/helpers';
interface IProps {
    oppId:string;
    setOppId: Function;
    searchValue: string;
    setSearchValue: Function;
    setSuggestionSelected: Function;
    setFilteredData: Function;
    setRevenueRisk: Function;
    setImpDevices: Function;
    setRevenueHText: Function;
    setImpDevicesHText: Function;
    autoSuggestHText: boolean;
    setAutoSuggestHText: Function;
    origin: string;
    isFocus?: boolean;
    setCustomerSelected?: Function;
    setLastSelectedOppurtunityId?: Function;
    setOriginalSelected?: Function;
}
export const SearchMSX: React.FC<IProps> = ({
    searchValue,
    setSearchValue,
    setSuggestionSelected,
    setFilteredData,
    setRevenueRisk,
    setImpDevices,
    setRevenueHText,
    setImpDevicesHText,
    autoSuggestHText,
    setAutoSuggestHText,
    origin,
    oppId,
    setOppId,
    isFocus,
    setCustomerSelected,
    setLastSelectedOppurtunityId,
    setOriginalSelected
}) => {
    const [showSuggestion, setShowSuggestion] = React.useState(false);

    let count = 20;

    if (origin === 'REQUEST_EXPERIENCE') {
        count = 50;
    }

    const { data, mutate: getCustomersList, isLoading } = useGetCustomersList();

    const handleSearch = (e: any) => {
        if (origin === 'ADD_CUSTOMER') {
            setCustomerSelected(false);
            setOriginalSelected([]);
            setFilteredData([]);
        }

        setAutoSuggestHText(false);
        setRevenueHText(false);
        setImpDevicesHText(false);
        setRevenueRisk('');
        setImpDevices('');

        setSuggestionSelected(false);
        setSearchValue(e.target.value);
        if (e.target.value.length > 2) {
            setShowSuggestion(true);
            getCustomersList(e.target.value);
        } else {
            setShowSuggestion(false);
        }
    };

    const handleSelectSuggestion = (selectedData: any) => {
        if (origin === 'ADD_CUSTOMER') {
            setLastSelectedOppurtunityId(selectedData.OpportunityNumber);
            setCustomerSelected(true);
            setOriginalSelected([{ ...selectedData }]);
        }
        setRevenueHText(false);
        setImpDevicesHText(false);
        setRevenueRisk(selectedData.EstimatedValue?.toString());
        setImpDevices(selectedData.TotalDeviceCount?.toString());
        setAutoSuggestHText(false);

        setFilteredData([selectedData]);
        setSuggestionSelected(true);
        setSearchValue(
            selectedData.OpportunityNumber +
                ' - ' +
                selectedData.AccountName +
                ' - ' +
                selectedData.Country +
                ' - ' +
                selectedData.Topic?.substring(0, count)
        );
        setOppId(selectedData.OpportunityId);
        setShowSuggestion(false);
    };

    const handleFocus = () => {
        if (searchValue.length > 2) {
            getCustomersList(searchValue);
            setShowSuggestion(true);
        }
    };

    const suggestionRef = useRef(null);

    useSuggestionBoxDismiss(suggestionRef, setShowSuggestion);

    //loader shimmer element
    const shimmerElement = [
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line },
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line }
    ];

    const textInput = useRef(null);

    useEffect(() => {
        if (isFocus) {
            textInput.current.focus();
        }
    }, [isFocus]);

    return (
        <div className="search-MSX-cont" ref={suggestionRef} tabIndex={0} aria-label="Search Customer">
            <TextField
                className={`${autoSuggestHText ? 'error-feild' : ''}${showSuggestion ? 'active' : ''}`}
                value={searchValue}
                data-testid="search-box-test"
                onFocus={handleFocus}
                onChange={handleSearch}
                placeholder="e.g: 1-1KX7Y871 - Contoso Corporation - United States"
                autoComplete={'off'}
                componentRef={textInput}
                tabIndex={0}
                ariaLabel="Search by MSX Opportunity ID / Account ID / Customer Name"
            />
            {showSuggestion && (
                <div className={`suggest-cont-box`}>
                    {isLoading ? (
                        <div className="p-a-20">
                            <Shimmer className="p-b-5" shimmerElements={shimmerElement} />
                            <Shimmer className="p-b-5" shimmerElements={shimmerElement} />
                            <Shimmer shimmerElements={shimmerElement} />
                        </div>
                    ) : data?.data?.length === 0 ? (
                        <div className="p-a-20" tabIndex={0} aria-label="Sorry, we could not find anything that matches your search">
                            <Text>
                                <Trans>Sorry, we could not find anything that matches your search :(</Trans>
                            </Text>
                        </div>
                    ) : (
                        data?.data?.map((item: any) => (
                            <div
                                key={item?.Document?.id}
                                tabIndex={0}
                                onClick={() => handleSelectSuggestion(item.Document)}
                                className="suggestion-item  p-t-10 p-b-10"
                                onKeyPress={(e) => keyPress(e, () => handleSelectSuggestion(item.Document))}
                                data-testid="select-suggest">
                                <Text>
                                    {`${item.Document.OpportunityNumber} - ${item.Document.AccountName} - ${
                                        item.Document.Country
                                    }  - ${item.Document.Topic?.substring(0, count)} `}
                                </Text>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};
const useSuggestionBoxDismiss = (ref: any, setShowSuggestion: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowSuggestion(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keypress', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keypress', handleClickOutside);
        };
    }, [setShowSuggestion, ref]);
};
