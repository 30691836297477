import { ActionButton, Dialog, IContextualMenuItem, Spinner, SpinnerSize } from '@fluentui/react';
import { IRequest } from 'app/models/common/response';
import { useRequestReassign } from 'app/services/mutations';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppQueryClient } from 'app/services/clients/query-client';
import { useBoolean } from '@fluentui/react-hooks';
import moment from 'moment';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    selectedRequest?: IRequest;
    onRefetchHandler?: Function;
}

const RequestTypeDropdown: React.FC<IProps> = (props) => {
    const { t: translate } = useTranslation();
    const { selectedRequest, onRefetchHandler } = props;

    const [requestTypeSelection, setRequestTypeSelection] = useState(0);
    const [hideLoaderDialog, { toggle: toggleLoaderHideDialog }] = useBoolean(true);

    const {
        mutate: requestReassign,
        isLoading: isReassigning,
        isSuccess: isSuccessRequestReassign,
        isError: isErrorRequestReassign
    } = useRequestReassign();

    const handleRequestTypeSelection = (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
        if (parseInt(item.key) === selectedRequest.requestTypeValue) {
            ev.preventDefault();
            return;
        } else {
            let reassignmentData = {
                currentTeam: selectedRequest.teamValue,
                currentRequestType: selectedRequest.requestTypeValue,
                reassignTeam: selectedRequest.teamValue,
                reassignRequestType: parseInt(item.key),
                requestId: selectedRequest.unifiedTrackingId,
                requestedResolutionDate: moment(selectedRequest.dueDateValue).isValid()
                    ? moment(selectedRequest.dueDateValue).toISOString()
                    : null
            };
            requestReassign(reassignmentData);
        }
    };

    const sdsgMenu: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: 'admin-edit-status-menu',
            items: [
                {
                    key: 1,
                    text: translate('Proposal'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 1 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 2,
                    text: translate('Question'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 2 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 3,
                    text: 'Rfx',
                    canCheck: true,
                    isChecked: requestTypeSelection === 3 ? true : false,
                    onClick: handleRequestTypeSelection
                }
            ]
        }),
        [handleRequestTypeSelection, requestTypeSelection]
    );

    const cxpMenu: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: 'admin-edit-status-menu',
            items: [
                {
                    key: 8,
                    text: translate('Variant'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 8 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 9,
                    text: translate('PFR'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 9 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 10,
                    text: translate('Messaging'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 10 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 11,
                    text: translate('Technical'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 11 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 12,
                    text: translate('Program'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 12 ? true : false,
                    onClick: handleRequestTypeSelection
                }
            ]
        }),
        [handleRequestTypeSelection, requestTypeSelection]
    );

    const cssMenu: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: 'admin-edit-status-menu',
            items: [
                {
                    key: 6,
                    text: translate('CSS Request'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 6 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 7,
                    text: translate(StringConstants.REVIEW_REQUEST),
                    canCheck: true,
                    isChecked: requestTypeSelection === 7 ? true : false,
                    onClick: handleRequestTypeSelection
                }
            ]
        }),
        [handleRequestTypeSelection, requestTypeSelection]
    );

    const crewMenu: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: 'admin-edit-status-menu',
            items: [
                {
                    key: 4,
                    text: translate('Pre Sales'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 4 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 5,
                    text: translate(StringConstants.POST_SALES),
                    canCheck: true,
                    isChecked: requestTypeSelection === 5 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 13,
                    text: translate('Warranty'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 13 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 14,
                    text: translate('Product'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 14 ? true : false,
                    onClick: handleRequestTypeSelection
                },
                {
                    key: 15,
                    text: translate('Messaging'),
                    canCheck: true,
                    isChecked: requestTypeSelection === 15 ? true : false,
                    onClick: handleRequestTypeSelection
                }
            ]
        }),
        [handleRequestTypeSelection, requestTypeSelection]
    );

    const menuType: any = React.useMemo(() => {
        switch (selectedRequest.teamValue) {
            // SDSG
            case 1:
                return sdsgMenu;
            // CXP
            case 2:
                return cxpMenu;
            // CSS
            case 3:
                return cssMenu;
            // CREW
            case 4:
                return crewMenu;

            default:
                break;
        }
    }, [selectedRequest.teamValue, requestTypeSelection, selectedRequest.requestTypeValue, selectedRequest]);

    useEffect(() => {
        setRequestTypeSelection(selectedRequest?.requestTypeValue);
    }, [selectedRequest?.requestTypeValue]);

    useEffect(() => {
        if (isReassigning) {
            toggleLoaderHideDialog();
        }

        if (!isReassigning && isSuccessRequestReassign) {
            // setTimeout(() => {
            //     AppQueryClient.invalidateQueries('requestQuery').then(() => toggleLoaderHideDialog());
            //     AppQueryClient.invalidateQueries('getRequestById');
            //     if (onRefetchHandler) {
            //         onRefetchHandler();
            //     }
            // }, 2000);
            AppQueryClient.invalidateQueries('requestQuery').then(() => toggleLoaderHideDialog());
            AppQueryClient.invalidateQueries('getRequestById');
            if (onRefetchHandler) {
                onRefetchHandler();
            }
        }
    }, [isReassigning, isSuccessRequestReassign, isErrorRequestReassign]);

    return (
        <>
            <div>
                <ActionButton
                    menuProps={menuType}
                    className="status-selection-menu"
                    data-testid="request-type-selection-menu-test">
                    {selectedRequest.requestType}
                </ActionButton>
            </div>

            <Dialog hidden={hideLoaderDialog} onDismiss={toggleLoaderHideDialog}>
                <Spinner size={SpinnerSize.large} label="Updating request type. Please wait..." />
            </Dialog>
        </>
    );
};

export default RequestTypeDropdown;
