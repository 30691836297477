import React, { useEffect } from 'react';
import { PersonaInitialsColor } from '@fluentui/react';
import { Text } from '@fluentui/react';
import { injectTargetToAnchor, ReplaceUrlByAnchor } from 'app/components/helpers';
import { ICommentResponse, IRequest } from 'app/models/common/response';
import _ from 'lodash';
import Avatar from 'app/components/avatar/Avatar';
import CommentField from 'app/components/commentField/CommentField';
import ReplyPanel from '../../replyPanel/ReplyPanel';
import ReplyList from '../ReplyList';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';
import { BlobStorageContainer, Entity } from 'app/utils/constants';

interface IProps {
    item?: ICommentResponse;
    childItem?: ICommentResponse[];
    correlationId: string;
    isInternal?: boolean;
    isMultipleType?: boolean;
    itemIndex?: number;
    selectedRequest?: IRequest;
    isClosed?: boolean;
}

const Reply: React.FC<IProps> = ({ item, childItem, isInternal, isMultipleType, itemIndex, selectedRequest, correlationId, isClosed = false }) => {
    // state variables
    const [isEditActive, setIsEditActive] = React.useState(false);
    const [parsedMessage, setParsedMessage] = React.useState('');

    const toggleEdit = () => {
        setIsEditActive((value) => !value);
    };

    // helper functions
    const getTaggedUser = (name: string, users: any) => {
        if (!_.isEmpty(name) && !_.isEmpty(users)) {
            return _.find(users, (item) => item.id === name);
        } else {
            return {
                id: '',
                displayName: '',
                email: ''
            };
        }
    };

    useEffect(() => {
        if (item?.commentText && item?.commentText !== '') {
            const replaceStorageUrls = async () => {
                await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.COMMENT_STATUS_FILES, item.commentText)
                .then((res) => {
                    if (res !== '') {
                        setParsedMessage(res);
                    }
                });
            }

            replaceStorageUrls();
        }
    }, [item.commentText]);

    const generateCommentMessage = (message: string, taggedUsers: any) => {
        // the default tag for the rich text editor is <p>
        // because old requests will not have any html tags and will be basic strings,
        // we use this check to see if it is rich text or basic text so that all text can be formatted correctly
        if (message.includes('<p>')) {
            return parsedMessage;
        }

        let formattedMsg = message;
        let users = Array.from(message?.matchAll(/<userid:(.*?)>/g));
        for (const user of users) {
            formattedMsg = formattedMsg.replace(
                user[0],
                `<span class="tagged-user">${getTaggedUser(user[1], taggedUsers).displayName}</span>`
            );
        }
        if (isMultipleType) {
            formattedMsg = formattedMsg?.replaceAll('<:request-detail-url>', 'request-detail');
        } else {
            formattedMsg = formattedMsg?.replaceAll('<:request-detail-url>', 'request');
        }
        formattedMsg = ReplaceUrlByAnchor(injectTargetToAnchor(formattedMsg?.replace(/\\/g, '')), 'tagged-user', true);
        return formattedMsg;
    };

    return (
        <>
            <div className="reply-block">
                <div className="reply-details">
                    <div className="reply-persona">
                        <Avatar
                            userId={item.createdBy?.id}
                            userName={item.createdBy?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={32}
                            roundedPic={true}
                        />
                    </div>
                    <Text className="reply-usr-name">
                        {(item.createdBy && item.createdBy.displayName) || childItem}{' '}
                        {!isEditActive && (
                            <>
                                <span
                                    className="reply-text"
                                    dangerouslySetInnerHTML={{
                                        __html: generateCommentMessage(item.commentText, item.taggedUsers)
                                    }}>
                                </span>
                            </>
                        )}
                    </Text>
                </div>
                <div>
                    {isEditActive ? (
                        <>
                            <CommentField
                                isEditActive={isEditActive}
                                initialValue={parsedMessage}
                                correlationId={item.correlationId}
                                entityType={1}
                                parentCommentId={item.id}
                                cancelOperation={toggleEdit}
                                textareaSize={2}
                                className=""
                                isInternal={isInternal}
                                fieldType="comment"
                                selectedRequest={selectedRequest}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    {!isEditActive && item && (
                        <ReplyPanel
                            correlationId={item.correlationId}
                            item={item}
                            isInternal={isInternal}
                            isMultipleType={isMultipleType}
                            toggleEdit={toggleEdit}
                            isEditActive={isEditActive}
                            isClosed={isClosed}
                        />
                    )}
                    <ReplyList
                        item={item}
                        correlationId={correlationId}
                        isInternal={isInternal}
                        isOpen={itemIndex === 0 && item.childComments && item.childComments.length > 0 ? true : false}
                        isMultipleType={isMultipleType}
                        isClosed={isClosed}
                    />
                </div>
            </div>
        </>
    );
};

export default Reply;
