import React from 'react';
import { Text } from '@fluentui/react';
import { Link } from 'react-router-dom';

import './index.scss';
import { Trans } from 'react-i18next';
import useStore from 'app/store';
const ResourceCenter: React.FC = () => {
    const setOriginalRoutingML  = useStore((state: any) => state.setOriginalRoutingML );
    const setFilterPanelDisabled = useStore((state: any) => state.setFilterPanelDisabled);

    return (
        <>
            <div className="resource-center-container">
                <div className="ms-Grid " dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <div className="card p-0">
                                <div className="card-wrapper bg-gray">
                                    <Link role="link" to="/unified-dashboard" tabIndex={0} onClick={() => { setFilterPanelDisabled(false) }}>
                                        <span className="icon-font-wrapper">
                                            <svg
                                                width="48"
                                                height="48"
                                                viewBox="0 0 48 48"
                                                fill="#4F4F4F"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M6 6C4.89543 6 4 6.89543 4 8V33C4 34.1046 4.89543 35 6 35H18V40H13V42H35V40H30V35H42C43.1046 35 44 34.1046 44 33V8C44 6.89543 43.1046 6 42 6H6ZM20 40V35H28V40H20ZM7 8C6.44772 8 6 8.44772 6 9V32C6 32.5523 6.44772 33 7 33H41C41.5523 33 42 32.5523 42 32V9C42 8.44772 41.5523 8 41 8H7ZM36 25H12V27H36V25ZM19 17H36V19H19V17ZM14 20C15.1046 20 16 19.1046 16 18C16 16.8954 15.1046 16 14 16C12.8954 16 12 16.8954 12 18C12 19.1046 12.8954 20 14 20Z"
                                                />
                                            </svg>
                                        </span>
                                        <div className="card-box my-auto">
                                            <Text>
                                                <h5 className="card-title">
                                                    <Trans>Unified Dashboard</Trans>{' '}
                                                </h5>
                                            </Text>
                                            <Text className="desc-txt">
                                                <Trans>Track all existing requests and escalations here</Trans>
                                            </Text>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <div className="card p-0">
                                <div className="card-wrapper bg-gray">
                                    <Link 
                                        role="link" 
                                        to="/help-request" 
                                        tabIndex={0}
                                        onClick={() => {
                                            setOriginalRoutingML (false);
                                        }}>
                                        <span className="icon-font-wrapper">
                                            <svg
                                                width="48"
                                                height="48"
                                                viewBox="0 0 48 48"
                                                fill="#4F4F4F"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M41.629 6.371C42.1236 6.86566 42.1236 7.66767 41.629 8.16234L21.3624 28.429L19.2 28.8L19.571 26.6377L39.8377 6.371C40.3323 5.87633 41.1343 5.87633 41.629 6.371ZM37.8234 20.1535C38.0625 19.9144 38.3868 19.7801 38.725 19.7801C39.0632 19.7801 39.3875 19.9144 39.6266 20.1535C39.8657 20.3926 40 20.7169 40 21.0551V39.45C39.9992 40.1261 39.7303 40.7742 39.2523 41.2523C38.7742 41.7303 38.1261 41.9992 37.45 42H8.55C7.87393 41.9992 7.22577 41.7303 6.74771 41.2523C6.26966 40.7742 6.00076 40.1261 6 39.45V10.55C6.00076 9.87393 6.26966 9.22577 6.74771 8.74771C7.22577 8.26966 7.87393 8.00076 8.55 8H26.9449C27.283 8 27.6073 8.13433 27.8464 8.37344C28.0855 8.61255 28.2199 8.93685 28.2199 9.275C28.2199 9.61315 28.0855 9.93745 27.8464 10.1766C27.6073 10.4157 27.283 10.55 26.9449 10.55H8.55V39.45H37.45V21.0551C37.45 20.7169 37.5843 20.3926 37.8234 20.1535Z"
                                                />
                                            </svg>
                                        </span>
                                        <div className="card-box my-auto">
                                            <Text>
                                                <h5 className="card-title">
                                                    <Trans>Request Help</Trans>
                                                </h5>
                                            </Text>
                                            <Text className="desc-txt">
                                                <Trans>Raise a new request for help</Trans>
                                            </Text>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-lg4">
                            <div className="card p-0">
                                <div className="card-wrapper bg-gray">
                                    <Link role="link" to="/portal-training" tabIndex={0}>
                                        <span className="icon-font-wrapper">
                                            <svg
                                                width="48"
                                                height="48"
                                                viewBox="0 0 48 48"
                                                fill="#4F4F4F"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M7.375 11.357C7.375 9.15782 9.15782 7.375 11.357 7.375H35.2492C37.4485 7.375 39.2313 9.15782 39.2313 11.357V23.7026C38.629 23.3553 37.9222 23.2298 37.2403 23.3445V11.357C37.2403 10.2574 36.3489 9.36602 35.2492 9.36602H11.357C10.2574 9.36602 9.36602 10.2574 9.36602 11.357V29.2762C9.36602 30.3758 10.2574 31.2672 11.357 31.2672H21.3121V33.2582H19.3211V37.2403H24.2688L24.2987 37.2404H25.0526L27.0435 39.2313H14.3436C13.7938 39.2313 13.3481 38.7856 13.3481 38.2358C13.3481 37.686 13.7938 37.2403 14.3436 37.2403H17.3301V33.2582H11.357C9.15782 33.2582 7.375 31.4754 7.375 29.2762V11.357ZM34.0404 24.95C38.2739 24.95 41.7058 28.3819 41.7058 32.6154C41.7058 36.8489 38.2739 40.2808 34.0404 40.2808C29.8069 40.2808 26.375 36.8489 26.375 32.6154C26.375 28.3819 29.8069 24.95 34.0404 24.95ZM37.1348 31.8311L33.2234 29.2664C33.0905 29.1762 32.9432 29.1311 32.7815 29.1311C32.6689 29.1311 32.5621 29.1552 32.461 29.2033C32.3599 29.2483 32.2718 29.3099 32.1968 29.3881C32.1217 29.4662 32.061 29.5578 32.0148 29.663C31.9715 29.7682 31.9498 29.8794 31.9498 29.9966V35.2343C31.9498 35.3485 31.9715 35.4581 32.0148 35.5633C32.061 35.6685 32.1217 35.7616 32.1968 35.8428C32.2747 35.9209 32.3642 35.984 32.4653 36.0321C32.5664 36.0772 32.6718 36.0997 32.7815 36.0997C32.8653 36.0997 32.9432 36.0877 33.0154 36.0636C33.0905 36.0396 33.1642 36.0035 33.2364 35.9555L37.1478 33.287C37.2662 33.2089 37.3587 33.1037 37.4251 32.9715C37.4915 32.8393 37.5247 32.7011 37.5247 32.5568C37.5247 32.4066 37.49 32.2683 37.4207 32.1421C37.3514 32.0129 37.2561 31.9092 37.1348 31.8311Z"
                                                />
                                            </svg>
                                        </span>
                                        <div className="card-box my-auto">
                                            <Text>
                                                <h5 className="card-title">
                                                    <Trans>Portal Training</Trans>
                                                </h5>
                                            </Text>
                                            <Text className="desc-txt">
                                                <Trans>Learn more about the portal and it's features</Trans>
                                            </Text>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResourceCenter;
