import React, { useState } from 'react';
import ContentArea from './editor/ContentArea';

import './NewEmail.scss';

import EmailHeader from './subHeader/EmailHeader';
import { ISearchUser } from 'app/models/common/response';
import { useLocation } from 'react-router-dom';
import { IEmailTemplate } from 'app/models/common/post';

const NewEmail: React.FC = () => {
    const location: { state: { template?: IEmailTemplate } } = useLocation();
    const [title, setTitle] = useState(location.state?.template?.subject || '');
    const [content, setContent] = useState(location.state?.template?.content || '');
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [selectedCcPeople, setSelectedCcPeople] = useState([]);
    const [selectedBccPeople, setSelectedBccPeople] = useState([]);
    const [isPreview, setIsPreview] = useState(false);
    const extractEmails = (users: Array<ISearchUser>) => {
        return users.map((user) => user.mail);
    };

    const reset = () => {
        setTitle('');
        setContent('');
        setSelectedPeople([]);
        setSelectedCcPeople([]);
        setSelectedBccPeople([]);
    };

    return (
        <div className="email-container">
            <EmailHeader
                subject={title}
                to={extractEmails(selectedPeople)}
                cc={extractEmails(selectedCcPeople)}
                bcc={extractEmails(selectedBccPeople)}
                content={content}
                reset={reset}
                isPreview={isPreview}
                setIsPreview={setIsPreview}
            />
            <div className="ms-Grid container" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="d-flex">
                        <div className="content-area ms-Grid-col ms-xl12 ms-lg12 ms-sm12 p-b-40">
                            <ContentArea
                                title={title}
                                setTitle={setTitle}
                                content={content}
                                setContent={setContent}
                                selectedPeople={selectedPeople}
                                setSelectedPeople={setSelectedPeople}
                                selectedCcPeople={selectedCcPeople}
                                setSelectedCcPeople={setSelectedCcPeople}
                                selectedBccPeople={selectedBccPeople}
                                setSelectedBccPeople={setSelectedBccPeople}
                                isPreview={isPreview}
                                isEmail={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewEmail;
