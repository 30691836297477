import React from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import moment from 'moment';
import queryString from 'query-string';
import {
    FeedbackData,
    IRequestData,
    ILookUpData,
    IDateRangeProps,
    IStatusProps,
    IReport,
    IShareWithUser,
    IRequestPayload,
    Aging,
    IBlobUploadParams
} from 'app/models/common/post';
import { IRequest, ICommentResponse, INotificationItem, IUserProps } from 'app/models/common/response';
import {
    IShareRequest,
    ICommentCriteria,
    IUpdateRequest,
    IAddCustomerRequest,
    IAcquireTokenType
} from 'app/models/common/request';
import _, { iteratee } from 'lodash'; // eventhough this is listed under problems, defined but never used. It needs to stay apparently.
import { ArticleStatus } from 'app/enums/Status.enum';
import { API, appInsightsService, BlobStorageHelper, DateFormats, Entity, Files, Languages, LOGIN, RouteConstants, BlobStorageContainer, StringConstants } from 'app/utils/constants';
import axios from 'axios';
import { EntityType } from 'app/enums/EntityType.enum';
import { ArticleAction } from 'app/enums/ArticleAction.enum';
import { FeedbackAction } from 'app/enums/FeedbackAction.enum';
import { RequestAction } from 'app/enums/RequestAction.enum';
import { CommentAction } from 'app/enums/CommentAction.enum';
import { isContentCreator, isContentManager, isSuperAdmin, isUser } from 'app/utils/authUtilities';
import { UserDomain } from 'app/enums/UserDomain.enum';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';
import { ReportAction } from 'app/enums/ReportAction.enum';
import { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag';
import { getUserPhoto, getUserProfile } from 'app/utils/graphUtils';
import { RouteComponentProps } from 'react-router-dom';
import { Portal } from 'app/enums/Portal.enum';
import { RequestType } from 'app/enums/RequestType.enum';

// static icons
import receivedRequestIcon from '../../app/static/icons/received-requests.svg';
import replyIcon from '../../app/static/icons/notif_mention_comment.svg';
import newRequestIcons from '../../app/static/icons/notif_new_request.svg';
import InProgressIcons from '../../app/static/icons/notif_inprogress.svg';
import InResolvedClosedIcons from '../../app/static/icons/notif_resolved_closed.svg';
import SharedRequestIcons from '../../app/static/icons/notif_shared_request.svg';
import UnsharedRequestIcons from '../../app/static/icons/notif_unshare_request.svg';
import updateRequestIcons from '../../app/static/icons/notif_update_request.svg';
import assignRequestIcons from '../../app/static/icons/notif_assign_person.svg';
import reassignRequestIcons from '../../app/static/icons/notif_reassign_team.svg';
import addCommentRequestIcons from '../../app/static/icons/notif_add_comment.svg';
import emoteLikeIcon from '../../app/static/icons/notif_emote_icon.svg';
import emoteLoveIcon from '../../app/static/icons/comment-emote-heart.svg';
import emoteLaughIcon from '../../app/static/icons/comment-emote-laugh.svg';
import emoteShockIcon from '../../app/static/icons/comment-emote-shock.svg';
import emoteAngryIcon from '../../app/static/icons/comment-emote-angry.svg';
import articleSubmittedIcon from '../../app/static/icons/notif_article_submitted.svg';
import articleSentBackIcon from '../../app/static/icons/notif_article_sent_back.svg';
import newsArticleSubscribedIcon from '../../app/static/icons/notif_article_news_subscribed.svg';
import newsArticlePublishedIcon from '../../app/static/icons/notif_article_news_published.svg';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';

/**
 * @desription Upload files to Azure Blob Storage
 * @param params An IBlobUploadParams object containing values for the upload
 * @param handleFileUploadProgress An optional function passed to show file upload progress
 * @returns A BlockBlobUploadResponse
 *
 * See {@link https://learn.microsoft.com/en-us/javascript/api/@azure/storage-blob/blockblobuploadresponse?view=azure-node-latest BlockBlobClient.BlockBlobUploadResponse}
 */
export const fileUpload = async (params: IBlobUploadParams, handleFileUploadProgress?: Function) => {
    appInsightsService.logTrace(`fileUpload: Creating blob in ${params.subfolder} for ${params.blobName}`, {"fileUpload": `${params.blobName}`, "storage-container": `${params.container}`});

    const blobServiceClient = new BlobServiceClient(params.token);
    const containerClient = blobServiceClient.getContainerClient(params.subfolder);
    const content = params.file;
    const blockBlobClient = containerClient.getBlockBlobClient(params.blobName);

    const uploadBlobResponse = await blockBlobClient.upload(content, content.size, {
        onProgress: (progress) => {
            if (handleFileUploadProgress) handleFileUploadProgress(progress.loadedBytes, content.size);
        }
    });

    return uploadBlobResponse;
};

export const uploadFiles = async (params: IBlobUploadParams) => {
    appInsightsService.logTrace(`uploadFiles: Creating blob in ${params.subfolder} for ${params.blobName}`, {"uploadFiles": `${params.blobName}`, "storage-container": `${params.subfolder}`});

    const blobServiceClient = new BlobServiceClient(params.token);
    const containerClient = blobServiceClient.getContainerClient(params.subfolder);
    const content = params.file;
    const blockBlobClient = await containerClient.getBlockBlobClient(params.blobName);
    let uploadBlobResponse;
    try {
        uploadBlobResponse = await blockBlobClient.upload(content, content.size);
    } catch (err) {
        uploadBlobResponse = {};
    }

    return uploadBlobResponse;
};

export const getBlobByURL = async (blobURL: string, token: string, container: string = BlobStorageContainer.PORTAL_ATTACHMENTS) => {
    let downloaded;
    try {
        const blobServiceClient = new BlobServiceClient(token);
        const containerClient = blobServiceClient.getContainerClient(container);
        const blockBlobClient = containerClient.getBlockBlobClient(blobURL);
        const downloadBlockBlobResponse = await blockBlobClient.download();
        downloaded = await downloadBlockBlobResponse.blobBody;
    } catch (error) {
        downloaded = '';
    }

    return downloaded;
};

export const blobToFile = (blob: Blob, fileName: string): File => {
    let b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return b as File;
};

/**
 * Check if a blob's sas token has expired
 * @param blobSasToken
 * @returns true if the token is expired
 */
export const blobTokenIsExpired = (blobUrl: string) => {
    let signedExpiry = blobUrl.split('&')[1];
    let expiryTime = signedExpiry.replace('amp;', '').replace('se=', '');
    expiryTime = expiryTime.replaceAll('%3A', ':');

    let expiryDate = new Date(expiryTime).toISOString();
    let now = new Date().toISOString();

    return now > expiryDate;
}

/**
 * Find all valid URLs in a string.
 * @param input
 * @param includeSasToken
 * includeSasToken to find URLs. `true` will find URLs which contain tokens. `false` will exclude URLs which contain tokens. Defaults to `false`.
 * @returns a list of valid URLs
 */
export const findStorageUrlsInString = (input: string = '', includeSasToken: boolean = false): string[] => {
    let urls: string[] = [];

    if (input && input !== '') {
        let matchedResults = input.matchAll(BlobStorageHelper.STORAGE_URL_REGEX_WITH_TAGS_GI);
        let r = matchedResults.next();
        while(!r.done) {
            if (isValidURL(r.value[0]))
            {
                urls = [...urls, r.value[0]];
            }

            r = matchedResults.next();
        }
    }

    return urls;
}

/**
 * Get the storage container name from a valid storage URL
 * @param input - A storage URL
 * @returns Storage container name
 *
 * See {@link https://developer.mozilla.org/en-US/docs/Web/API/URL/URL, URL}
 */
export const getStorageContainerName = (input: string) => {
    if (input !== undefined && input !== StringConstants.EMPTY_STRING) {
        let url = new URL(input)
        let urlPathNames = url?.pathname
                            .slice(1, url.pathname.length) // remove first '/' this avoids having '' for index[0]
                            .split('/');

        return urlPathNames[0];
    }
}

// OTHER HELPERS
export const GetTypes = (id: number) => {
    let res = '';
    switch (id) {
        case 1:
            res = StringConstants.PROPOSAL;
            break;
        case 2:
            res = StringConstants.QUESTION;
            break;
        case 3:
            res = StringConstants.RFX;
            break;
        case 4:
            res = StringConstants.PRE_SALES;
            break;
        case 5:
            res = StringConstants.POST_SALES;
            break;
        case 6:
            res = StringConstants.CSS_REQUEST;
            break;
        case 7:
            res = StringConstants.REVIEW_REQUESTS;
            break;
        case 8:
            res = StringConstants.VARIANT;
            break;
        case 9:
            res = StringConstants.PFR;
            break;
        case 10:
            res = StringConstants.MESSAGING;
            break;
        case 11:
            res = StringConstants.TECHNICAL;
            break;
        case 12:
            res = StringConstants.PROGRAM;
            break;
        case 13:
            res = StringConstants.WARRANTY;
            break;
        case 14:
            res = StringConstants.PRODUCT;
            break;
        case 15:
            res = StringConstants.MESSAGING;
            break;
        default:
            break;
    }
    return res;
};

export const changeDateFormat = (value: Date, format: string) => {
    let localTime = moment.utc(value).toDate();
    let res = moment(localTime).format(format);
    return res;
};

export const GetText = (data: ILookUpData[], value: Number | boolean) => {
    return data?.find((element: ILookUpData) => element.id === value)?.name;
};

export const isNumberGreaterThanZero = (val: string) => {
    if (val === null || val === undefined) {
        return false;
    }

    //includes decimal numbers too
    let regex =
        /^(?!(?:^[-+]?[0.]+(?:[Ee]|$)))(?!(?:^-))(?:(?:[+-]?)(?=[0123456789.])(?:(?:(?:[0123456789]+)(?:(?:[.])(?:[0123456789]*))?|(?:(?:[.])(?:[0123456789]+))))(?:(?:[Ee])(?:(?:[+-]?)(?:[0123456789]+))|))$/;
    return val.match(regex);
};

export const fieldValidation = (value: any, type: any, selectedModel: any) => {
    let regex;
    if (type === StringConstants.MS_EMAIL) {
        regex = /^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@microsoft.com$/;
    }
    if (type === StringConstants.CURRENCY) {
        regex = /^(0|[1-9]\d*)(\.\d+)?$/;
    }
    if (type === StringConstants.NUMBER) {
        regex = /^\d+$/;
    }
    if (type === StringConstants.EMAIL) {
        regex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    }
    if (type === StringConstants.MOBILE_NUMBER) {
        regex = /^\d{3,20}$/;
    }

    let res;
    if (type === StringConstants.MODEL_COUNT) {
        let flag = false;
        selectedModel instanceof Array &&
            selectedModel.forEach((item: any) => {
                if (item.added && item.count && item.count.match(/^[0-9]*[1-9][0-9]*$/)) {
                    flag = true;
                }
            });
        res = !flag;
    } else {
        res = value.match(regex);
    }
    return res;
};

export const prepareModelList = (selectedModel: any, selectedProductId: any) => {
    let ar: any = [];
    selectedModel instanceof Array &&
        selectedModel.forEach((item: any) => {
            if (item.added) {
                ar.push({ id: item.id, name: item.name, categoryId: item.categoryId, deviceCount: item.count });
            }
        });
    return ar;
};

export const prepareCountryList = (selectedModel: any) => {
    let ar: any = [];
    selectedModel instanceof Array &&
        selectedModel.forEach((item: any) => {
            if (item.added) {
                ar.push({ id: item.id, name: item.name });
            }
        });
    return ar;
}

export const isUpperCase = (item: string) => {
    return item.toString() === item.toUpperCase();
}

export const isLowerCase = (item: string) => {
    return item.toString() === item.toLowerCase();
}

export const capitalizeFirstLetter = (customerName: string) => {
   return customerName.toLowerCase().replace(/\b\S/g, function (t) { return t.toUpperCase(); });
}

export const formatCustomerNames = (customerName: string) => {
    if (!(/^[A-Za-z0-9]*$/).test(customerName) || customerName.length < 2) {
        return customerName;
    }

    let custNameFirstLetters = customerName.substring(0,2);

    if(isUpperCase(custNameFirstLetters[0]) && isLowerCase(custNameFirstLetters[1])){
        return customerName;
    } else {
        let custName:any = capitalizeFirstLetter(customerName);
        if(custName.includes("Inc")){
            return custName.replace(/Inc/g, "INC.");
        }
        if(custName.includes("And")){
            return custName.replace(/And/g, "and");
        }
        return custName;
    }
}

export const requestHelpSubmit = (
    requestTypeFlow: number,
    datapass: any,
    requestType: any,
    selectedModel: any,
    selectedProductId: any,
    searchValue: any,
    emailID: any,
    filteredData: any,
    customerName: any,
    cssCaseNum: any,
    selectedDCFKey: string | undefined,
    fundsDescription: string,
    customerImpactDescription: string,
    originalRoutingML : boolean,
    contactName: string,
    contactNum: string,
    custEmail: string,
    custShippingAddress: string,
    companyName: string,
    country: string,
    alternateContactNumber: string,
    contactNumPrefix: string,
    alternateNumPrefix: string
) => {
    let subUrl = '';
    switch (requestTypeFlow) {
        case 1:
            let prop = {
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
                companyInformation: {
                    companyName: companyName,
                    country: country
                }
            };
            datapass = { ...datapass, ...prop };
            subUrl = RouteConstants.SDSG_PROPOSAL;
            break;
        case 2:
            let ques = {
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                questionText: searchValue,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
                companyInformation: {
                    companyName: companyName,
                    country: country
                },
            };
            datapass = { ...datapass, ...ques };

            subUrl = RouteConstants.SDSG_QUESTION;
            break;
        case 3:
            let rfx = {
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                questionText: searchValue,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
                companyInformation: {
                    companyName: companyName,
                    country: country
                }
            };
            datapass = { ...datapass, ...rfx };
            subUrl = RouteConstants.SDSG_RFX;
            break;
        case 4:
            let pre = {
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                questionText: searchValue,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
                companyInformation: {
                    companyName: companyName,
                    country: country
                },
                preSalesRequestType: 1,
                customerType: 1
            };
            datapass = { ...datapass, ...pre };
            subUrl = RouteConstants.CREW_PRESALES;
            break;
        case 5:
        case 13:
        case 14:
        case 15:
            let post = {
                requestType: requestTypeFlow,
                cssCaseNumber: cssCaseNum,
                customerType: 1,
                proofOfPurchaseAttached: true,
                serialNumbersAttached: true,
                isDcfRequest: selectedDCFKey === StringConstants.A,
                dcfJustification: fundsDescription,
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                questionText: searchValue,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
                companyInformation: {
                    companyContactName: contactName,
                    companyFullShippingAddress: custShippingAddress,
                    companyName: companyName,
                    contactEmailAddress: custEmail,
                    contactPhoneNumber: contactNumPrefix + StringConstants.WHITE_SPACE + contactNum,
                    country: country,
                    alternateContactNumber: alternateNumPrefix + StringConstants.WHITE_SPACE + alternateContactNumber
                }
            };
            datapass = { ...datapass, ...post };
            subUrl = RouteConstants.CREW_POSTSALES;
            break;
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
            let irt = {
                requestType: requestTypeFlow,
                dealInfluenced: true,
                questionText: searchValue,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
                companyInformation: {
                    companyName: companyName,
                    country: country
                },
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country
            };
            subUrl = RouteConstants.CXP_IRT_REQUEST;
            datapass = { ...datapass, ...irt };
            break;
        case 6:
            let cssres = {
                email: emailID,
                companyInformation: {
                    companyName: companyName,

                    country: country
                },
                companyContactName: filteredData[0]?.customerName,
                companyRegion: 1,
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                questionText: searchValue,
                customerName: customerName,
                originalRoutingML : originalRoutingML ,
            };
            datapass = { ...datapass, ...cssres };
            subUrl = RouteConstants.SUB_CSS_REQUEST;
            break;
        case 7:
            let cssreres = {
                cssTrackingId: cssCaseNum,
                companyInformation: {
                    companyName: companyName,
                    country: country
                },
                questionText: searchValue,
                customerName: customerName,
                cssCaseNumber: cssCaseNum,
                deviceModels: prepareModelList(selectedModel, selectedProductId),
                countryModels: country,
                originalRoutingML : originalRoutingML ,
            };
            datapass = { ...datapass, ...cssreres };
            subUrl = RouteConstants.CSS_REVIEW_REQUEST;
            break;
        default:
            subUrl = '';
    }
    return { datapass, subUrl };
};

export const requestAggregateSubmit = (requestTypeValue: any, selectedRequest: IRequest, datapass: any) => {
    let subUrl = '';
    switch (requestTypeValue) {
        case 1:
            let prop = {
                primaryPartner: StringConstants.PRIMARY_PARTNER,
                industry: {
                    id: 1,
                    name: StringConstants.TEST_INDUSTRY
                },
                deviceModels: selectedRequest.devices,
            };
            datapass = { ...datapass, ...prop };
            subUrl = RouteConstants.SDSG_PROPOSAL;
            break;
        case 2:
            let ques = {
                questionText: selectedRequest.summary,
                primaryPartner: StringConstants.PRIMARY_PARTNER,
                industry: {
                    id: 1,
                    name: StringConstants.TEST_INDUSTRY
                },
                deviceModels: selectedRequest.devices,
            };
            datapass = { ...datapass, ...ques };

            subUrl = RouteConstants.SDSG_QUESTION;
            break;
        case 3:
            let rfx = {
                primaryPartner: StringConstants.PRIMARY_PARTNER,
                industry: {
                    id: 1,
                    name: StringConstants.TEST_INDUSTRY
                },
                deviceModels: selectedRequest.devices,
            };
            datapass = { ...datapass, ...rfx };
            subUrl = RouteConstants.SDSG_RFX;
            break;
        case 4:
            let pre = {
                deviceModels: selectedRequest.devices,
                preSalesRequestType: 1,
                customerType: 1,
                actionRequired: StringConstants.STRING
            };
            datapass = { ...datapass, ...pre };
            subUrl = RouteConstants.CREW_PRESALES;
            break;
        case 13:
        case 14:
        case 15:
        case 5:
            let post = {
                requestType: requestTypeValue,
                cssCaseNumber: selectedRequest.cssCaseNumber,
                customerType: 1,
                proofOfPurchaseAttached: selectedRequest.proofOfPurchaseAttached,
                serialNumbersAttached: selectedRequest.serialNumbersAttached,
                actionRequired: StringConstants.STRING,
                dcfRequired: selectedRequest.isDcfRequest,
                dcfApprovedValue: selectedRequest.isDcfApproved,
                deviceModels: selectedRequest.devices,
            };
            datapass = { ...datapass, ...post };
            subUrl = RouteConstants.CREW_POSTSALES;
            break;
        case 6:
            let cssres = {
                email: '',
                primaryPartner: StringConstants.PRIMARY_PARTNER,
                companyContactName: '',
                companyRegion: '',
                deviceModels: '',
                countryModels: '',
                actionRequired: StringConstants.STRING
            };
            datapass = { ...datapass, ...cssres };
            subUrl = RouteConstants.SUB_CSS_REQUEST;
            break;
        case 7:
            let cssreres = {
                cssTrackingId: selectedRequest.cssCaseNumber,
                primaryPartner: StringConstants.PRIMARY_PARTNER,
                cssCaseNumber: selectedRequest.cssCaseNumber,
                deviceModels: selectedRequest.devices
            };
            datapass = { ...datapass, ...cssreres };
            subUrl = RouteConstants.CSS_REVIEW_REQUEST;
            break;
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
            let cxpRequest = {
                requestType: requestTypeValue,
                dealInfluenced: false,
                closingSummary: selectedRequest.closingSummary,
                deviceModels: selectedRequest.devices
            };
            subUrl = RouteConstants.CXP_IRT_REQUEST;
            datapass = { ...datapass, ...cxpRequest };
            break;
        default:
            subUrl = '';
    }

    return { datapass, subUrl };
};
const getTeams = (value: number) => {
    let requestTeam = '';
    switch (value) {
        case 1:
            requestTeam = StringConstants.SDSG;
            break;
        case 2:
            requestTeam = StringConstants.CXP;
            break;
        case 3:
            requestTeam = StringConstants.CSS;
            break;
        case 4:
            requestTeam = StringConstants.CREW;
            break;
        default:
            requestTeam = '';
            break;
    }
    return requestTeam;
};
export const formatIntoFeedbackData = (arr: Array<FeedbackData>) => {
    let list: Array<Object> = [];
    arr?.forEach((item) => {
        list.push({
            id: item.id,
            date: changeDateFormat(item.createdOn, DateFormats.DAY_MONTH_YEAR),
            feedback: item.feedbackMessage,
            experience: item.experience,
            category: GetText(
                [
                    { id: 0, name: StringConstants.BUG },
                    { id: 1, name: StringConstants.SUGGESTION },
                    { id: 2, name: StringConstants.COMPLIMENT },
                    { id: 3, name: StringConstants.BUG }
                ],
                item.feedbackCategory
            ),
            providedBy: item.createdBy.email,
            team: item.createdBy.userDomain === null ? StringConstants.DASH : getTeams(item.createdBy.userDomain),
            response: item.adminResponse,
            respondedOn: changeDateFormat(item.adminRespondedOn, DateFormats.DAY_MONTH_YEAR)
        });
    });
    return list;
};

const convertUTCtoLocal = (utcDate: Date) => {
    var toDt = moment.utc(utcDate).toDate();
    return moment(toDt).local().format(DateFormats.MONTH_DAY_YEAR);
};

export const formatSearchSuggestionIntoRequestData = (arr: any, isAssociatingParent: boolean) => {
    let list: any = [];
    arr?.forEach((item: any) => {
        if((!item.isParent && !isAssociatingParent) || isAssociatingParent) {
            list.push({
                id: item?.UnifiedTrackingId,
                title: item?.Title,
                status: GetText(
                    [
                        { id: 1, name: StringConstants.NEW },
                        { id: 2, name: StringConstants.ACTIVE },
                        { id: 3, name: StringConstants.IN_PROGRESS },
                        { id: 4, name: StringConstants.RESOLVED },
                        { id: 5, name: StringConstants.CLOSED }
                    ],
                    item?.Status
                ),
                dueDateValue: item?.RequestedResolutionDate,
                createdOn: convertUTCtoLocal(item?.CreatedOn),
                totalRevenueValue: StringConstants.DOLLAR_SIGN + StringConstants.WHITE_SPACE + formatCurrency(item?.TotalRevenue),
                requestTypeValue: item?.RequestType,
                requestType: GetTypes(item?.RequestType),
                isParent: item?.IsParent
            });
        }
    });

    return list;
};

export const formatSearchIntoRequestData = (arr: any, isAssociatingParent: boolean) => {
    let list: any = [];
    arr?.forEach((item: any, index: number) => {
        if((!item.IsParent && !isAssociatingParent) || isAssociatingParent) {
            list.push({
                id: item?.UnifiedTrackingId,
                title: item?.Title,
                unifiedTrackingId: item?.UnifiedTrackingId,
                status: GetText(
                    [
                        { id: 1, name: StringConstants.NEW },
                        { id: 2, name: StringConstants.ACTIVE },
                        { id: 3, name: StringConstants.IN_PROGRESS },
                        { id: 4, name: StringConstants.RESOLVED },
                        { id: 5, name: StringConstants.CLOSED }
                    ],
                    parseInt(item?.Status)
                ),
                dueDate: moment(item?.RequestedResolutionDate).isValid()
                    ? changeDateFormat(item?.RequestedResolutionDate, DateFormats.MONTH_DAY_YEAR)
                    : '',
                description: item?.Description,
                createdOn: convertUTCtoLocal(item?.CreatedOn),
                applicationId: item?.BackendId,
                team: getTeams(parseInt(item?.RequestTeam)),
                requestType: GetTypes(parseInt(item?.RequestType)),
                revenue: StringConstants.DOLLAR_SIGN + StringConstants.WHITE_SPACE + formatCurrency(item?.TotalRevenue),
                statusValue: parseInt(item?.Status),
                modifiedByName: !_.isEmpty(item?.modifiedBy)
                    ? item?.modifiedBy?.DisplayName
                    : !_.isEmpty(item?.createdBy)
                    ? item?.createdBy?.DisplayName
                    : '',
                modifiedOnDate: item?.ModifiedOn ? item?.ModifiedOn : item?.CreatedOn,
                createdOnDate: item?.CreatedOn,
                dueDateValue: item?.RequestedResolutionDate,
                totalDeviceCount: formatCurrency(item?.TotalDeviceCount),
                customerImpacts: item?.CustomerImpacts
                    ? item?.CustomerImpacts.map((impact: any) => {
                          return {
                              accountId: impact?.AccountId,
                              competitor: impact?.Competitor,
                              customerCountry: {
                                  id: impact?.CustomerCountry?.Id,
                                  name: impact?.CustomerCountry?.Name
                              },
                              customerImpactDescription: impact?.CustomerImpactDescription,
                              customerName: impact?.CustomerName,
                              deviceCount: impact?.DeviceCount,
                              opportunityId: impact?.OpportunityId,
                              status: impact?.Status,
                              msxOpportunityId: impact?.MsxOpportunityId,
                              revenue: impact?.Revenue,
                              tpId: impact?.TpId,
                              endCustomerSegment: impact?.EndCustomerSegment,
                              salesPay: impact?.SalesPay,
                              refreshYourDevice: impact?.RefreshYourDevice,
                              msxDeviceCount: impact?.MsxDeviceCount,
                              msxRevenue: impact?.MsxRevenue,
                              msxCurrentDeviceCount: impact?.MsxCurrentDeviceCount,
                              msxCurrentRevenue: impact?.MsxCurrentRevenue,
                              msxInfoUpdatedOn: impact?.MsxInfoUpdatedOn,
                              addedBy: {
                                  displayName: impact?.AddedBy?.DisplayName,
                                  email: impact?.AddedBy?.Email,
                                  id: impact?.AddedBy?.Id
                              },
                              addedOn: impact?.AddedOn,
                              city: impact?.City,
                              state: impact?.State,
                              zipCode: impact?.ZipCode
                          };
                      })
                    : [],
                requestTypeValue: parseInt(item?.RequestType),
                teamValue: parseInt(item?.RequestTeam),
                attachments: item?.attachments,
                summary: item?.Title,
                assignedTo: !_.isEmpty(item?.Assignee) ? item?.Assignee?.DisplayName : StringConstants.DASHES,
                businessJustification: item?.BusinessJustification,
                dcfJustification: item?.DcfJustification,
                criticality: item?.Criticality,
                impactReach: item?.ImpactReach,
                productCategory: item?.ProductCategory,
                approvedMessaging: item?.ApprovedMessaging,
                whatBusinessProblemIsSolved: item?.WhatBusinessProblemIsSolved,
                competitiveOffering: item?.CompetitiveOffering,
                isDesignForSurfacedPossible: item?.IsDesignForSurfacedPossible,
                pfrSubType: item?.PfrSubType,
                bugInformations: Array.isArray(item?.BugInformations)
                    ? item?.BugInformations
                    : item?.BugInformations
                    ? [item?.BugInformations]
                    : item?.BugInformations || null,
                bugNumber: item?.BugNumber,
                priority: item?.Priority,
                caseCodeInvestigation: item?.CaseCodeInvestigation,
                caseCodeRequest: item?.CaseCodeRequest,
                mainRequestType: item?.mainRequestType,
                subRequestType: item?.subRequestType,
                taskStatus: item?.TaskStatus,
                requestedResolutionDate: item?.RequestedResolutionDate,
                isBlockingSale: item?.IsBlockingSale,
                isDcfApproved: item?.IsDcfApproved,
                proofOfPurchaseAttached: item?.ProofOfPurchaseAttached === StringConstants.TRUE,
                serialNumbersAttached: item?.SerialNumbersAttached === StringConstants.TRUE,
                dcfApprovedValue: item?.DcfApprovedValue,
                dcfSR: item?.DcfSR,
                cssCaseNumber: item?.CssCaseNumber,
                answer: item?.Answer,
                whyThisDateIsImportant: item?.WhyThisDateIsImportant,
                companyInformation: item?.CompanyInformation,
                priorityNumber: item?.PriorityNumber,
                assignee: item?.Assignee
                    ? {
                          displayName: item?.Assignee?.DisplayName,
                          email: item?.Assignee?.Email,
                          id: item?.Assignee?.Email
                      }
                    : null,
                devices: item?.deviceModels,
                countries: item?.countryModels,
                isDcfRequest: item?.IsDcfRequest,
                isBookmarked: item?.IsBookmarked,
                parentRequestId: item?.ParentRequestId,
                isParent: item?.IsParent,
                databaseId: item?.Id,
                customerType: parseInt(item?.CustomerType),
                dealInfluenced: item?.DealInfluenced,
                closingSummary: item?.ClosingSummary,
                createdBy: {
                    email: item?.createdBy?.Email,
                    displayName: item?.createdBy?.DisplayName
                },
                isHidden: item?.IsHidden,
                tags: item?.Tags,
                closedOn: item?.ClosedOn
            });
        }
    });
    return list;
};

export const formatIntoRequestData = (
    arr: IRequestData[] | IRequest[] | any[],
    teams?: ILookUpData[],
    requestTypes?: ILookUpData[]
) => {
    let list: any = [];
    arr?.forEach((item: any) => {
        list.push({
            id: item?.unifiedTrackingId,
            title: item?.summary,
            unifiedTrackingId: item?.unifiedTrackingId,
            status: GetText(
                [
                    { id: 1, name: StringConstants.NEW },
                    { id: 2, name: StringConstants.ACTIVE },
                    { id: 3, name: StringConstants.IN_PROGRESS },
                    { id: 4, name: StringConstants.RESOLVED },
                    { id: 5, name: StringConstants.CLOSED }
                ],
                item?.status
            ),
            dueDate: moment(item?.requestedResolutionDate).isValid()
                ? changeDateFormat(item?.requestedResolutionDate, DateFormats.MONTH_DAY_YEAR)
                : '',
            description: item?.description,
            createdOn: convertUTCtoLocal(item?.createdOn),
            applicationId: item?.backendId,
            team: getTeams(item?.requestTeam),
            requestType: GetTypes(item?.requestType),
            revenue: StringConstants.DOLLAR_SIGN + StringConstants.WHITE_SPACE + formatCurrency(item?.msxTotalRevenue),
            totalRevenueValue: StringConstants.DOLLAR_SIGN + StringConstants.WHITE_SPACE + formatCurrency(item?.totalRevenue),
            statusValue: item?.status,
            modifiedByName: !_.isEmpty(item?.modifiedBy)
                ? item?.modifiedBy?.displayName
                : !_.isEmpty(item?.createdBy)
                ? item?.createdBy?.displayName
                : '',
            modifiedOnDate: item?.modifiedOn ? item?.modifiedOn : item?.createdOn,
            createdOnDate: item?.createdOn,
            dueDateValue: item?.requestedResolutionDate,
            totalDeviceCount: formatCurrency(item?.totalDeviceCount),
            customerImpacts: item?.customerImpacts,
            requestTypeValue: item?.requestType,
            teamValue: item?.requestTeam,
            attachments: item?.attachments,
            summary: item?.summary,
            assignedTo: !_.isEmpty(item?.assignee) ? item?.assignee?.displayName : StringConstants.DASHES,
            addedOn: item?.addedOn,
            businessJustification: item?.businessJustification,
            dcfRequired: item?.dcfRequired,
            dcfJustification: item?.dcfJustification,
            criticality: item?.criticality,
            impactReach: item?.impactReach,
            productCategory: item?.productCategory,
            approvedMessaging: item?.approvedMessaging,
            whatBusinessProblemIsSolved: item?.whatBusinessProblemIsSolved,
            competitiveOffering: item?.competitiveOffering,
            isDesignForSurfacedPossible: item?.isDesignForSurfacedPossible,
            pfrSubType: item?.pfrSubType,
            backendUrl: item?.backendUrl,
            bugInformations: Array.isArray(item?.bugInformations)
                ? item?.bugInformations
                : item?.bugInformations
                ? [item?.bugInformations]
                : item?.bugInformations || null,
            bugNumber: item?.bugNumber,
            priority: item?.priority,
            caseCodeInvestigation: item?.caseCodeInvestigation,
            caseCodeRequest: item?.caseCodeRequest,
            mainRequestType: item?.mainRequestType,
            subRequestType: item?.subRequestType,
            taskStatus: item?.taskStatus,
            requestedResolutionDate: item?.requestedResolutionDate,
            isBlockingSale: item?.isBlockingSale,
            isDcfApproved: item?.isDcfApproved,
            proofOfPurchaseAttached: item?.proofOfPurchaseAttached,
            serialNumbersAttached: item?.serialNumbersAttached,
            dcfApprovedValue: item?.dcfApprovedValue,
            dcfSR: item?.dcfSR,
            cssCaseNumber: item?.cssCaseNumber,
            answer: item?.answer,
            whyThisDateIsImportant: item?.whyThisDateIsImportant,
            companyInformation: item?.companyInformation,
            priorityNumber: item?.priorityNumber,
            assignee: item?.assignee,
            devices: item?.deviceModels,
            countries: item?.countryModels,
            isDcfRequest: item?.isDcfRequest,
            isBookmarked: item?.isBookmarked,
            parentRequestId: item?.parentRequestId,
            isParent: item?.isParent,
            childrenCount: item?.childrenCount,
            databaseId: item?.id,
            customerType: item?.customerType,
            dealInfluenced: item?.dealInfluenced,
            closingSummary: item?.closingSummary,
            createdBy: {
                email: item?.createdBy?.email,
                displayName: item?.createdBy?.displayName
            },
            isHidden: item?.isHidden,
            tags: item?.tags,
            closedOn: item?.closedOn,
            msxTotalDeviceCount: item?.msxTotalDeviceCount,
            msxTotalRevenue: item?.msxTotalRevenue,
            msxCurrentTotalDeviceCount: item?.msxCurrentTotalDeviceCount,
            msxCurrentTotalRevenue: item?.msxCurrentTotalRevenue
        });
    });

    return list;
};

export const getDateRangeUrl = (activeRange: string, dateRange: IDateRangeProps) => {
    let url = '';
    const DATE_FORMAT = DateFormats.YEAR_MONTH_DAY_MILLISECOND;
    const nowEnd = moment().endOf('day').utc().format(DATE_FORMAT);
    if (activeRange === '') {
        if (dateRange.start !== null || dateRange.end !== null) {
            if (dateRange.start !== null && dateRange.end === null)
                url = `DateRange.From=${moment(dateRange.start).startOf('day').utc().format(DATE_FORMAT)}&DateRange.To=${nowEnd}`;
            else if (dateRange.start === null && dateRange.end !== null)
                url = `DateRange.From=${moment('2001/01/01', 'YYYY/MM/DD')
                    .startOf('day')
                    .utc()
                    .format(DATE_FORMAT)}&DateRange.To=${moment(dateRange.end).endOf('day').utc().format(DATE_FORMAT)}`;
            else
                url = `DateRange.From=${moment(dateRange.start).startOf('day').utc().format(DATE_FORMAT)}&DateRange.To=${moment(
                    dateRange.end
                )
                    .endOf('day')
                    .utc()
                    .format(DATE_FORMAT)}`;
        }
    } else {
        if (activeRange === StringConstants.UPPERCASE_ALL) {
            url = StringConstants.UPPERCASE_ALL;
        } else if (activeRange === StringConstants.WEEK) {
            url = `DateRange.From=${moment()
                .subtract(7, 'days')
                .startOf('day')
                .utc()
                .format(DATE_FORMAT)}&DateRange.To=${nowEnd}`;
        } else if (activeRange === StringConstants.MONTH) {
            url = `DateRange.From=${moment()
                .subtract(1, 'months')
                .startOf('day')
                .utc()
                .format(DATE_FORMAT)}&DateRange.To=${nowEnd}`;
        } else if (activeRange === StringConstants.YEAR) {
            url = `DateRange.From=${moment()
                .subtract(12, 'months')
                .startOf('day')
                .utc()
                .format(DATE_FORMAT)}&DateRange.To=${nowEnd}`;
        }
    }
    return url;
};

export const getRequestTypeMenuItems = (id: number) => {
    let menu: string[] = [];
    switch (id) {
        case 1:
            menu = [StringConstants.UPPERCASE_ALL, StringConstants.PROPOSAL, StringConstants.QUESTION, StringConstants.RFX];
            break;
        case 2:
            menu = [StringConstants.UPPERCASE_ALL, StringConstants.VARIANT_REQUEST, StringConstants.FEATURE_REQUEST, StringConstants.MESSAGING_REQUEST];
            break;
        case 3:
            menu = [StringConstants.UPPERCASE_ALL, StringConstants.CSS_REQUEST, StringConstants.CSS_REVIEW_REQUEST];
            break;
        case 4:
            menu = [StringConstants.UPPERCASE_ALL, StringConstants.POST_SALES, StringConstants.PRE_SALES];
            break;
        default:
            break;
    }
    return menu;
};

export const getStatusBasedUrl = (isBoxchecked: IStatusProps) => {
    let url = '';
    Object.entries(isBoxchecked).map(([key, value]) => {
        if (value) {
            switch (key) {
                case 'new':
                    url = url + `&CaseStatus=1`;
                    break;
                case 'active':
                    url = url + `&CaseStatus=2&CaseStatus=3`;
                    break;
                case 'resolved':
                    url = url + `&CaseStatus=4`;
                    break;
                default:
                    break;
            }
        }
        return key;
    });
    return url;
};

export const getRequestTypeBasedUrl = (reqTypeSelection: any) => {
    let url = '';
    Object.entries(reqTypeSelection).map(([key, value]) => {
        if (value) {
            switch (key) {
                case StringConstants.PROPOSAL:
                    url = url + '&RequestType=1';
                    break;
                case StringConstants.QUESTION:
                    url = url + '&RequestType=2';
                    break;
                case StringConstants.RFX:
                    url = url + '&RequestType=3';
                    break;
                case StringConstants.PRE_SALES:
                    url = url + '&RequestType=4';
                    break;
                case StringConstants.POST_SALES:
                    url = url + '&RequestType=5';
                    break;
                case StringConstants.CSS_REQUEST:
                    url = url + '&RequestType=6';
                    break;
                case StringConstants.CSS_REVIEW_REQUEST:
                    url = url + '&RequestType=7';
                    break;
                case StringConstants.VARIANT_REQUEST:
                    url = url + '&RequestType=8';
                    break;
                case StringConstants.FEATURE_REQUEST:
                    url = url + '&RequestType=9';
                    break;
                case StringConstants.MESSAGING_REQUEST:
                    url = url + '&RequestType=10';
                    break;
                default:
                    break;
            }
        }
        return key;
    });
    return url;
};

export const getTeamBasedUrl = (selection: any) => {
    let url = '';
    Object.entries(selection).map(([key, value]) => {
        if (value) {
            switch (key) {
                case StringConstants.SDSG:
                    url = url + '&RequestTeam=1';
                    break;
                case StringConstants.CXP:
                    url = url + '&RequestTeam=2';
                    break;
                case StringConstants.CSS:
                    url = url + '&RequestTeam=3';
                    break;
                case StringConstants.CREW:
                    url = url + '&RequestTeam=4';
                    break;
                default:
                    break;
            }
        }
        return key;
    });
    return url;
};
export const getDateRange = (dateRange: IDateRangeProps, activeRange: string) => {
    let url;
    const nowEnd = moment().endOf('day').utc().format();
    if (activeRange === '') {
        if (dateRange.start !== null || dateRange.end !== null) {
            if (dateRange.start !== null && dateRange.end === null)
                url = {
                    from: moment(dateRange.start).startOf('day').utc().format(),
                    to: nowEnd
                };
            else if (dateRange.start === null && dateRange.end !== null)
                url = {
                    from: moment('2001/01/01', 'YYYY/MM/DD').startOf('day').utc().format(),
                    to: moment(dateRange.end).endOf('day').utc().format()
                };
            else {
                url = {
                    from: moment(dateRange.start).startOf('day').utc().format(),
                    to: moment(dateRange.end).endOf('day').utc().format()
                };
            }
        }
    } else {
        if (activeRange === StringConstants.UPPERCASE_ALL) {
            url = {
                from: null,
                to: null
            };
        } else if (activeRange === StringConstants.WEEK) {
            url = {
                from: moment().subtract(7, 'days').startOf('day').utc().format(),
                to: nowEnd
            };
        } else if (activeRange === StringConstants.MONTH) {
            url = {
                from: moment().subtract(1, 'months').startOf('day').utc().format(),
                to: nowEnd
            };
        } else if (activeRange === StringConstants.YEAR) {
            url = {
                from: moment().subtract(12, 'months').startOf('day').utc().format(),
                to: nowEnd
            };
        }
    }
    return url;
};

export const getStartDate = (activeRange: string, dateRange: IDateRangeProps) => {
    let url = '';
    const DATE_FORMAT = DateFormats.YEAR_MONTH_DAY_MILLISECOND;

    if (activeRange === '') {
        if (dateRange.start !== null || dateRange.end !== null) {
            if (dateRange.start !== null && dateRange.end === null)
                url = moment(dateRange.start).startOf('day').utc().format(DATE_FORMAT);
            else if (dateRange.start === null && dateRange.end !== null)
                url = moment('2001/01/01', 'YYYY/MM/DD').startOf('day').utc().format(DATE_FORMAT);
            else url = moment(dateRange.start).startOf('day').utc().format(DATE_FORMAT);
        }
    } else {
        if (activeRange === StringConstants.UPPERCASE_ALL) {
            url = moment('2001/01/01', 'YYYY/MM/DD').startOf('day').format(DATE_FORMAT);
        } else if (activeRange === StringConstants.WEEK) {
            url = moment().subtract(7, 'days').startOf('day').utc().format(DATE_FORMAT);
        } else if (activeRange === StringConstants.MONTH) {
            url = moment().subtract(1, 'months').startOf('day').utc().format(DATE_FORMAT);
        } else if (activeRange === StringConstants.YEAR) {
            url = moment().subtract(12, 'months').startOf('day').utc().format(DATE_FORMAT);
        }
    }
    return url;
};

export const getAgingArray = (arr: string[], activeRange?: string, dateRangeFilter?: IDateRangeProps) => {
    let agingArr: Aging[] = [];
    const DATE_FORMAT = DateFormats.YEAR_MONTH_DAY_MILLISECOND;
    arr.forEach((item) => {
        switch (item) {
            case '0d-7d':
                agingArr.push({
                    start: moment().subtract(7, 'days').startOf('day').utc().format(DATE_FORMAT),
                    end: moment().endOf('day').utc().format(DATE_FORMAT),
                    range: '0d-7d'
                });
                break;
            case '8d-30d':
                agingArr.push({
                    start: moment().subtract(30, 'days').startOf('day').utc().format(DATE_FORMAT),
                    end: moment().subtract(8, 'days').endOf('day').utc().format(DATE_FORMAT),
                    range: '8d-30d'
                });
                break;
            case '31d-plus':
                agingArr.push({
                    start: moment('01/01/1970').startOf('day').utc().format(DATE_FORMAT),
                    end: moment().subtract(31, 'days').endOf('day').utc().format(DATE_FORMAT),
                    range: '31d-plus'
                });
                break;
        }
    });
    return agingArr;
};
export const getFilteredUrl = (
    currentSortProp: string,
    page: number,
    requestType: string,
    dateRange: IDateRangeProps,
    activeRange: string,
    agingSelection: IOptionProps[],
    statusSelection: IOptionProps[],
    requestTypeSelection: IOptionProps[],
    assignedToSelection: IUserProps[],
    productsSelection: IOptionProps[],
    countrySelection: IOptionProps[],
    createdOnOrder: string,
    tagOrder: string,
    revenueOrder: string,
    relationshipSelection: IOptionProps[],
    DCFSelection: IOptionProps[],
    portal: Portal
) => {
    let res: IRequestPayload = {
        pageNumber: page,
        pageSize: 20,
        getHiddenRequests: requestType === StringConstants.HIDDEN_REQUEST,
        isStarredRequests: requestType === StringConstants.STARRED_REQUEST,
        watchedByCurrentUser: requestType === StringConstants.SHARED_REQUEST,
        isClosedRequestExcluded: false,
        dateRangeFilter: dateRange,
        activeRange: activeRange,
        portal: portal
    };

    if (requestTypeSelection?.length !== 0 && requestTypeSelection?.filter((ele) => ele.key === 'all_request')?.length === 0) {
        let ar: number[] = [];
        requestTypeSelection?.forEach((item) => {
            if (item.type) {
                ar.push(item.type as number);
            }
        });
        if (ar.length !== 0) {
            res = { ...res, requestType: ar };
        }
    }

    if (
        requestTypeSelection?.length === 0 ||
        requestTypeSelection?.filter(
            (item) => item.key === StringConstants.PRE_SALES_REQUEST || item.key === StringConstants.POST_SALES_REQUEST || item.key === StringConstants.WARRANTY_REQUEST
        ).length > 0
    ) {
        if (DCFSelection?.length !== 0 && DCFSelection?.filter((ele) => ele.key === StringConstants.ALL)?.length === 0) {
            res = { ...res, isDcfRequest: DCFSelection?.filter((ele) => ele.key === StringConstants.LOWERCASE_YES)?.length !== 0 };
        }
    }
    if (statusSelection?.length !== 0 && statusSelection?.filter((ele) => ele.key === StringConstants.ALL)?.length === 0) {
        let ar: number[] = [];
        statusSelection?.forEach((item) => {
            if (item.type) {
                ar = ar.concat(item.type as number[]);
            }
        });
        if (ar.length !== 0) {
            res = { ...res, caseStatus: ar };
        }
    }
    if (relationshipSelection?.length !== 0 && relationshipSelection?.filter((ele) => ele.key === StringConstants.ALL)?.length === 0) {
        let ar: number[] = [];
        relationshipSelection?.forEach((item) => {
            if (item.type) {
                ar = ar.concat(item.type as number[]);
            }
        });
        if (ar.length !== 0) {
            res = { ...res, relationship: ar };
        }
    }
    if (agingSelection?.length !== 0 && agingSelection?.filter((ele) => ele.key === StringConstants.ALL)?.length === 0) {
        let ar: string[] = [];
        agingSelection?.forEach((item) => {
            ar.push(item.key as string);
        });
        if (ar.length !== 0) {
            res = { ...res, agingKeys: ar };
        }
    }
    if (assignedToSelection?.length !== 0 && assignedToSelection?.filter((ele) => ele.id === StringConstants.ALL)?.length === 0) {
        let ar: string[] = [];
        let flag = false;

        assignedToSelection?.forEach((item) => {
            if (item.id !== '0' && item.id !== StringConstants.ALL) {
                ar.push(item.id);
            } else {
                flag = true;
            }
        });
        if (ar.length !== 0) {
            res = { ...res, assignee: ar };
        }
        if (flag) {
            res = { ...res, ShowUnassignedRequests: true };
        }
    }
    if (productsSelection?.length !== 0 && productsSelection?.filter((ele) => ele.key === 0)?.length === 0) {
        let ar: number[] = [];
        productsSelection?.forEach((item) => {
            if (item.type) {
                ar.push(item.type as number);
            }
        });
        if (ar.length !== 0) {
            res = { ...res, deviceModel: ar };
        }
    }
    if (countrySelection?.length !== 0 && countrySelection?.filter((ele) => ele.key === 0)?.length === 0){
        let ar: string[] = [];
        countrySelection?.forEach((item) => {
            if (item.text) {
                ar.push(item.text as string);
            }
        });
        if (ar.length !== 0) {
            res = { ...res, countryModel: ar };
        }
    }
    switch (currentSortProp) {
        case StringConstants.CREATED_ON:
            if (createdOnOrder === StringConstants.ASC) {
                res = { ...res, sortProperty: 'createdOn', sortDirection: 0 };
            } else if (createdOnOrder === StringConstants.DESC) {
                res = { ...res, sortProperty: 'createdOn', sortDirection: 1 };
            }
            break;

        case StringConstants.REVENUE:
            if (revenueOrder === StringConstants.ASC) {
                res = { ...res, sortProperty: 'totalRevenue', sortDirection: 0 };
            } else if (revenueOrder === StringConstants.DESC) {
                res = { ...res, sortProperty: 'totalRevenue', sortDirection: 1 };
            }
            break;
        case StringConstants.TAGS:
            if (tagOrder === StringConstants.ASC) {
                    res = { ...res, sortProperty: 'tags', sortDirection: 0 };
                }
                else if (tagOrder === StringConstants.DESC) {
                    res = { ...res, sortProperty: 'tags', sortDirection: 1 };
                }
            break;
        default:
            break;
    }
    return res;
};

export const ISOToDateTime = (value: string, format: string) => {
    return moment(value).format(format);
};

/**
 * @description Returns start and end date based on the type provided.
 * @param type `WEEK` / `MONTH` / `YEAR`
 * @returns object
 * @example returns -> { from:string, to:string, today:string }
 */
export const getWeekMonthYearRange = (type: string) => {
    let resultant = {};
    const today = moment();
    switch (type) {
        case StringConstants.WEEK:
            resultant = {
                from: moment().subtract(7, 'days').startOf('day').utc().format(),
                to: today.endOf('day').utc().format(),
                today: today.utc().format(),
                type: type
            };
            break;
        case StringConstants.MONTH:
            resultant = {
                from: moment().subtract(1, 'months').startOf('day').utc().format(),
                to: today.endOf('day').utc().format(),
                today: today.utc().format(),
                type: type
            };
            break;
        case StringConstants.YEAR:
            resultant = {
                from: moment().subtract(1, 'years').startOf('day').utc().format(),
                to: today.endOf('day').utc().format(),
                today: today.utc().format(),
                type: type
            };
            break;
        default:
            break;
    }

    return resultant;
};
/**
 * Returns information to be used with Aging progressbar component
 * @param createdDate
 * @param dueDate
 * @param closedDate
 * @param status
 */
export const calculateAging = (createdDate: string, dueDate: string, closedDate: string, status: number) => {
    const today = moment();
    const startDate = moment.utc(createdDate).local().startOf('day');
    const endDate = moment(closedDate).isValid() ? moment.utc(closedDate).local() : moment.utc(dueDate).local();

    let difference = Math.abs(startDate.diff(today, 'hours', true));

    if (status === 5) {
        difference = Math.abs(
            startDate.diff(moment(closedDate).isValid() ? moment.utc(closedDate).local() : today, 'hours', true)
        );
    }

    let totalDuration = Math.abs(startDate.diff(endDate, 'hours'));
    let progress;
    if (totalDuration === 0) {
        progress = 100;
    } else {
        progress = Math.round((difference / totalDuration) * 100);
    }

    let type = 'a';
    progress = progress > 100 ? 100 : progress;

    let diff = 0;

    let units = 'h';
    if (difference < 24) {
        // diff = Math.round(difference);
        diff = 0;
        units = 'd';
    } else {
        diff = Math.abs(Math.floor(difference / 24));

        units = 'd';
    }
    if (endDate.isValid()) {
    } else {
        progress = 10;
    }

    if (units === 'h' || (units === 'd' && diff <= 7)) {
        type = 'a';
    } else if (units === 'd' && diff >= 8 && diff <= 30) {
        type = 'b';
    } else {
        type = 'c';
    }

    return {
        difference: diff,
        units: units,
        progress: progress,
        type: type
    };
};

//helper for today, past week, past month, past 6months

export const getDate = (value: string) => {
    switch (value) {
        case 'Today':
            return `CreatedOn ge ${moment().startOf('day').format()} and CreatedOn le ${moment()
                .add(1, 'day')
                .startOf('day')
                .format()}`;

        case 'Past Week':
            return `CreatedOn ge ${moment().subtract(7, 'days').startOf('day').format()} and CreatedOn le ${moment()
                .endOf('day')
                .format()}`;
        case 'Past Month':
            return `CreatedOn ge ${moment().startOf('day').subtract(1, 'months').format()} and CreatedOn le ${moment()
                .endOf('day')
                .format()}`;
        case 'Past 6 Month':
            return `CreatedOn ge ${moment().subtract(6, 'months').startOf('day').format()} and CreatedOn le ${moment()
                .endOf('day')
                .format()}`;
        default:
            break;
    }

    return '';
};

/**
 * Get URL and Request body for the share request
 */
export const getShareRequestSubmitDetails = (data: IShareRequest) => {
    let apiURL = '';
    switch (data.team) {
        case 1: // SDSG
            if (data.requestType === RequestType.Proposal) {
                // Proposal
                apiURL = RouteConstants.SDSG_PROPOSAL_WATCHER;
            } else if (data.requestType === RequestType.Question) {
                // Question
                apiURL = RouteConstants.SDSG_QUESTION_WATCHER;
            } else if (data.requestType === RequestType.Rfx) {
                // RFX
                apiURL = RouteConstants.SDSG_RFX_WATCHER;
            }
            break;
        case 2: // CXP
            apiURL = RouteConstants.CXP_IRT_REQUEST_WATCHER;
            break;
        case 3: // CSS
            if (data.requestType === RequestType.CssRequest) {
                // CSS Request
                apiURL = RouteConstants.CSS_CSS_REQUEST_WATCHER;
            } else if (data.requestType === RequestType.CssReviewRequest) {
                // CSS Review Request
                apiURL = RouteConstants.CSS_REVIEW_REQUEST;
            }
            break;
        case 4: // Crew
            if (data.requestType === RequestType.PreSales) {
                // Pre sales
                apiURL = RouteConstants.CREW_PRESALES_WATCHER;
            } else if (data.requestType === RequestType.PostSales) {
                // Post Sales
                apiURL = RouteConstants.CREW_POSTSALES_WATCHER;
            } else if (data.requestType === RequestType.Crew_WarrantyRequest) {
                // Warranty
                apiURL = RouteConstants.CREW_POSTSALES_WATCHER;
            } else if (data.requestType === RequestType.Crew_ProductRequest) {
                // Product
                apiURL = RouteConstants.CREW_POSTSALES_WATCHER;
            } else if (data.requestType === RequestType.Crew_MessagingRequest) {
                // Messaging
                apiURL = RouteConstants.CREW_POSTSALES_WATCHER;
            }
            break;

        default:
            break;
    }
    return apiURL;
};

/**
 * Generate URL and Query string for fetching comments
 */
export const generateUrlForFetchComments = (criteria: ICommentCriteria) => {
    let apiURL = RouteConstants.REQUEST_COMMENT_LIST;
    let queryString = '';
    if (criteria.correlationId) {
        queryString += `CorrelationId=${criteria.correlationId}`;
    }
    if (criteria.pageNumber) {
        queryString += `&PageNumber=${criteria.pageNumber}`;
    }
    if (criteria.pageSize) {
        queryString += `&PageSize=${criteria.pageSize}`;
    }
    if (criteria.entityType) {
        queryString += `&EntityType=${criteria.entityType}`;
    }
    if (StringConstants.IN_INTERNAL in criteria) {
        queryString += `&isInternal=${criteria.isInternal}`;
    }
    return apiURL + queryString;
};

/**
 * Construct data for comments in appropriate format from API data
 */
export const generateDataForComments = async (data: ICommentResponse[]) => {
    let comments = [];
    for (const item of data) {
        if (_.isEmpty(item.parentCommentId)) {
            if (item?.commentText) {
                await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.COMMENT_STATUS_FILES, item?.commentText)
                .then((res) => {
                    item.commentText = res;
                });
            }
            comments.push(item);
        }
    }
    return comments;
};

/**
 * Returns initials from a persons name
 */

export const getNameInitials = (name: string) => {
    return name
        .match(/(^\S\S[A-Za-z]|\b\S)[A-Za-z]/g)
        .join('')
        .match(/(^\S|\S$)[A-Za-z]/g)
        .join('')
        .toUpperCase();
};

/**
 * Get URL and Request body for the update request
 */
export const getUpdateRequestDetailsURL = (data: IUpdateRequest) => {
    let apiURL = '';
    switch (data.team) {
        case 1: // SDSG
            if (data.requestType === 1) {
                // Proposal
                apiURL = RouteConstants.SDSG_PROPOSAL;
            } else if (data.requestType === 2) {
                // Question
                apiURL = RouteConstants.SDSG_QUESTION;
            } else if (data.requestType === 3) {
                // RFX
                apiURL = RouteConstants.SDSG_RFX;
            }
            break;
        case 2: // CXP
            apiURL = RouteConstants.CXP_IRT_REQUEST;
            break;
        case 3: // CSS
            if (data.requestType === 6) {
                // CSS Request
                apiURL = RouteConstants.CSS_CSS_REQUEST;
            } else if (data.requestType === 7) {
                // CSS Review Request
                apiURL = RouteConstants.CSS_REVIEW_REQUEST;
            }
            break;
        case 4: // Crew
            if (data.requestType === 4) {
                // Pre sales
                apiURL = RouteConstants.CREW_PRESALES;
            } else if (data.requestType === 5) {
                // Post Sales
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (data.requestType === 13) {
                // Warranty
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (data.requestType === 14) {
                // Product
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (data.requestType === 15) {
                // Messaging
                apiURL = RouteConstants.CREW_POSTSALES;
            }
            break;

        default:
            break;
    }
    return apiURL;
};

export const formatCurrency = (currency: number) => {
    const dollarUSLocale = Intl.NumberFormat(Languages.EN_US);

    return dollarUSLocale.format(currency);
};

/* header quick search visibility check based on pages */

export const quickSearchVisibility = (url: string) => {
    let visiblePages = [
        StringConstants.UNIFIED_DASHBOARD,
        StringConstants.MANAGE_REQUESTS,
        StringConstants.VISUAL_FLOW,
        StringConstants.SUBMIT_FEEDBACK,
        StringConstants.REQUEST,
        StringConstants.REQUEST_DETAIL,
        StringConstants.SEARCH_RESULTS_REQUESTS
    ];

    let routeLink = url;
    if (routeLink === '') {
        return true;
    } else {
        return visiblePages.some((value) => routeLink.includes(value));
    }
};

/* header quick search route link */
export const quickSearchRouteLink = (url: string) => {
    let visiblePages = [StringConstants.UNIFIED_DASHBOARD, StringConstants.REQUEST, StringConstants.HELP_REQUEST];

    let routeLink = url;
    if (visiblePages.some((value) => value === routeLink)) {
        return RouteConstants.SEARCH_RESULTS;
    }

    return RouteConstants.SEARCH_RESULTS_REQUESTS;
};

export const getTitleForArticleStatus = (articleStatus: ArticleStatus, canManageContent: boolean = false) => {
    let result = '';
    switch (articleStatus) {
        case ArticleStatus.Draft:
            result = canManageContent ? 'All Drafts' : 'My Drafts';
            break;
        case ArticleStatus.Submitted:
            result = canManageContent ? 'Review Requests' : 'Submitted';
            break;
        case ArticleStatus.ScheduledToPublish:
            result = 'Scheduled to Publish';
            break;
        case ArticleStatus.Published:
            result = 'Published';
            break;
        case ArticleStatus.Removed:
            result = 'Removed';
            break;
    }
    return result;
};

export const getInitialsFromName = (name: string) => {
    if (!name) return 'MS';
    name = name.split('(')[0].trim();
    return name
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
};
export const dateFormatter = (d: any) => {
    const date = moment(d).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');
    const prevDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
    if (moment(date).isSame(currentDate)) return 'Today';
    if (moment(date).isSame(prevDate)) return 'Yesterday';
    return d;
};

export const addCustomerSubmit = (data: IAddCustomerRequest) => {
    let apiURL = '';
    switch (data.team) {
        case 1: // SDSG
            if (data.requestType === 1) {
                // Proposal
                apiURL = RouteConstants.SDSG_PROPOSAL;
            } else if (data.requestType === 2) {
                // Question
                apiURL = RouteConstants.SDSG_QUESTION;
            } else if (data.requestType === 3) {
                // RFX
                apiURL = RouteConstants.SDSG_RFX;
            }
            break;
        case 2: // CXP
            apiURL = RouteConstants.CXP_IRT_REQUEST;
            break;
        case 3: // CSS
            if (data.requestType === 6) {
                // CSS Request
                apiURL = RouteConstants.CSS_CSS_REQUEST;
            } else if (data.requestType === 7) {
                // CSS Review Request
                apiURL = RouteConstants.CSS_REVIEW_REQUEST;
            }
            break;
        case 4: // Crew
            if (data.requestType === 4) {
                // Pre sales
                apiURL = RouteConstants.CREW_PRESALES;
            } else if (data.requestType === 5) {
                // Post Sales
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (data.requestType === 13) {
                // Warranty
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (data.requestType === 14) {
                // Product
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (data.requestType === 15) {
                // Messaging
                apiURL = RouteConstants.CREW_POSTSALES;
            }
            break;

        default:
            break;
    }

    apiURL = `${apiURL}/AddCustomerImpact`;

    return apiURL;
};

export const getValidTags = (tags: any[]) => {
    return tags?.filter((tag) => tag.isValidTag);
};

export const getURLForTeamAndType = (team: number, requestType: number) => {
    let apiURL = '';
    switch (team) {
        case 1: // SDSG
            if (requestType === 1) {
                // Proposal
                apiURL = RouteConstants.SDSG_PROPOSAL;
            } else if (requestType === 2) {
                // Question
                apiURL = RouteConstants.SDSG_QUESTION;
            } else if (requestType === 3) {
                // RFX
                apiURL = RouteConstants.SDSG_RFX;
            }
            break;
        case 2: // CXP
            apiURL = RouteConstants.CXP_IRT_REQUEST;
            break;
        case 3: // CSS
            if (requestType === 6) {
                // CSS Request
                apiURL = RouteConstants.CSS_CSS_REQUEST;
            } else if (requestType === 7) {
                // CSS Review Request
                apiURL = RouteConstants.CSS_REVIEW_REQUEST;
            }
            break;
        case 4: // Crew
            if (requestType === 4) {
                // Pre sales
                apiURL = RouteConstants.CREW_PRESALES;
            } else if (requestType === 5) {
                // Post Sales
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (requestType === 13) {
                // Warranty
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (requestType === 14) {
                // Product
                apiURL = RouteConstants.CREW_POSTSALES;
            } else if (requestType === 15) {
                // Messaging
                apiURL = RouteConstants.CREW_POSTSALES;
            }
            break;

        default:
            break;
    }

    apiURL = `${apiURL}`;
    return apiURL;
};

export const getProfileImageByUserId = async (userId: string) => {
    try {
        const photo = await getUserPhoto(userId);
        return photo;
    } catch (error) {
        return '';
    }
};

export const getProfileByUserId = async (userId?: string) => {
    let profileData = { mobilePhone: '', displayName: 'abc', jobTitle: '', id: '', mail: '', businessPhones: [''] };
    try {
        const profileInfo = await getUserProfile(userId);
        profileData = profileInfo;
    } catch (error) {
    } finally {
        return profileData;
    }
};

export const getParagraphFromContent = (content: string): string => {
    let description = new DOMParser().parseFromString(content, 'text/html');
    description.querySelectorAll('table, img, video, iframe, figure, br')?.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    description.body.querySelectorAll('*').forEach((node) => {
        if (node.innerHTML.trim() === '' || node.innerHTML.trim() === '&nbsp;') {
            node.parentNode.removeChild(node);
        } else {
            node.removeAttribute('style');
            node.classList.add('no-styles');
        }
    });
    description.body.querySelectorAll('a').forEach((node) => {
        node.setAttribute('target', '_black');
    });
    return description.documentElement.innerHTML;
};
export const separateVideosFromHTMLString = (content: string): { videos: string[]; content: string } => {
    let separatedContent: { videos: string[]; content: string } = { videos: [], content: '' };
    const document = new DOMParser().parseFromString(content, 'text/html');
    const videos = document.getElementsByClassName('se-component se-video-container');
    Array.from(videos).forEach((video) => {
        separatedContent.videos.push((video?.childNodes[0]?.childNodes[0] as HTMLElement)?.getAttribute('src'));
        video.remove();
    });
    separatedContent.content = document.getElementsByTagName('body')[0].innerHTML;

    return separatedContent;
};

export const recentlyAddedColumns = (PrimaryButton: any, handleClick: Function) => {
    return [
        {
            name: 'Customer Name',
            selector: 'AccountName',
            sortable: true
        },
        {
            name: 'Account ID',
            selector: 'AccountNumber',
            sortable: true
        },

        {
            name: 'Country',
            selector: 'Country',
            sortable: true
        },

        {
            name: 'MSX Opportunity ID',
            selector: 'OpportunityNumber',
            sortable: true
        },

        {
            name: 'Revenue',
            selector: 'EstimatedValue',
            sortable: true
        },
        {
            name: 'Device',
            selector: 'TotalDeviceCount',
            sortable: true
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any) => (
                <PrimaryButton
                    tabIndex={0}
                    onClick={() => handleClick(row)}
                    onKeyPress={(e: any) => keyPress(e, () => handleClick(row))}
                    text="Select"
                    className="select-btn"
                />
            )
        }
    ];
};

export const searchedColumns = (selectedAccountId: string) => {
    return [
        {
            name: 'Customer Name',
            selector: 'AccountName',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.AccountName}
                </div>
            )
        },
        {
            name: 'Account ID',
            selector: 'AccountNumber',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.AccountNumber}
                </div>
            )
        },

        {
            name: 'Country',
            selector: 'Country',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.Country}
                </div>
            )
        },

        {
            name: 'MSX Opportunity ID',
            selector: 'OpportunityNumber',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.OpportunityNumber}
                </div>
            )
        },

        {
            name: 'MSX Status',
            selector: 'Status',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.Status}
                </div>
            )
        },

        {
            name: 'Topic',
            selector: 'topic',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.Topic}
                </div>
            )
        },

        {
            name: 'Revenue',
            selector: 'EstimatedValue',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.EstimatedValue}
                </div>
            )
        },
        {
            name: 'Device',
            selector: 'TotalDeviceCount',
            sortable: true,
            cell: (row: any) => (
                <div className={` ${selectedAccountId && selectedAccountId !== row.AccountNumber ? 'disable-data' : ''} `}>
                    {row.TotalDeviceCount}
                </div>
            )
        }
    ];
};

export const getIcon = (filename: string) => {
    const extention = filename?.split('.').pop().toLowerCase();

    switch (extention) {
        case 'jpg':
        case 'jpeg':
            return 'icon icon--jpg';

        case 'doc':
        case 'docx':
            return 'icon icon--doc';
        case 'xls':
        case 'xlsx':
            return 'icon icon--xls';
        case 'ppt':
        case 'pptx':
            return 'icon icon--ppt';
        case 'pdf':
            return 'icon icon--pdf';
        case 'png':
            return 'icon icon--png';
        case 'mp3':
            return 'icon icon--mp3';
        case 'avi':
            return 'icon icon--avi';
        case 'txt':
            return 'icon icon--txt';
        case 'xml':
            return 'icon icon--xml';
        case 'rar':
            return 'icon icon--rar';
        case 'html':
            return 'icon icon--html';
        case 'zip':
            return 'icon icon--zip';
        case 'gif':
            return 'icon icon--gif';
        case 'mp4':
            return 'icon icon--mp4';
        case 'css':
            return 'icon icon--css';

        default:
            return 'icon icon--default';
    }
};

export const getQuickLinksText = (quickLinksValue: number, redirectRequestType: string, redirectSubType: number) => {
    if (quickLinksValue === 1) {
        return 'Sales question';
    } else if (redirectRequestType === StringConstants.POST_SALES) {
        return 'Warranty need';
    } else if (redirectRequestType === StringConstants.REVIEW_REQUEST) {
        return 'Support escalation';
    } else if (redirectRequestType === StringConstants.IRT && redirectSubType === 9) {
        return 'Feature request';
    } else if (redirectRequestType === StringConstants.IRT && redirectSubType === 10) {
        return 'Messaging request';
    }
};

export const getMSGraphTokenSilently = async (instance: any, account: any) => {
    const graphTokenRequest = {
        scopes: ['User.Read', 'User.ReadBasic.All'],
        account
    };
    const res = await instance.acquireTokenSilent(graphTokenRequest);
    return res.accessToken;
};
export const getPowerBITokenSilently = async (instance: any, account: any, scope: string[]) => {
    const graphTokenRequest = {
        scopes: [LOGIN.POWERBI_SCOPE],
        account
    };
    const res = await instance.acquireTokenSilent(graphTokenRequest);
    return res.accessToken;
};

export const getAccessToken = async (type: IAcquireTokenType, instance: any, scope: string[], account?: any) => {
    switch (type) {
        case 'POPUP':
            return instance
                .acquireTokenPopup({
                    scopes: scope
                })
                .then((response: any) => {
                    instance.setActiveAccount(response.account);
                    return response;
                });
        case 'SILENT':
            return instance
                .acquireTokenSilent({
                    scopes: scope,
                    account: account
                })
                .then((response: any) => {
                    instance.setActiveAccount(response.account);
                    return response;
                });
    }
};

export const getIconForNotifications = (
    entity: EntityType,
    event: ArticleAction | CommentAction | FeedbackAction | RequestAction | ReportAction
) => {
    switch (entity) {
        case EntityType.HelpRequest:
            switch (event) {
                case RequestAction.Create:
                    return ['receive request', newRequestIcons];
                case RequestAction.Update:
                    return ['update request', updateRequestIcons];
                case RequestAction.Delete:
                    return ['assigned request', reassignRequestIcons];
                case RequestAction.Assigned:
                    return ['assigned request', assignRequestIcons];
                case RequestAction.UpdateWatcher:
                    return ['assigned request', SharedRequestIcons];
                case RequestAction.MarkAsInProgress:
                    return ['status updated', InProgressIcons];
                case RequestAction.MarkAsResolved:
                    return ['status updated', InResolvedClosedIcons];
                case RequestAction.MarkAsClosed:
                    return ['status updated', InResolvedClosedIcons];
                default:
                    return ['default', updateRequestIcons];
            }
        case EntityType.Feedback:
            switch (event) {
                case FeedbackAction.Create:
                    return ['receive request', receivedRequestIcon];
                default:
                    return ['default', receivedRequestIcon];
            }
        case EntityType.Article:
            switch (event) {
                case ArticleAction.Publish:
                    return ['publish article', newsArticlePublishedIcon];
                case ArticleAction.Submit:
                    return ['submit article', articleSubmittedIcon];
                case ArticleAction.Redraft:
                    return ['send back article', articleSentBackIcon];
                case ArticleAction.SubscribeKnowledgeCenter:
                    return ['subscribe knowledge articles', newsArticleSubscribedIcon];
                case ArticleAction.SubscribeNewsletter:
                    return ['subscribe news articles', newsArticleSubscribedIcon];
                default:
                    return ['default', articleSubmittedIcon];
            }
        case EntityType.Comment:
            switch (event) {
                case CommentAction.Create:
                    return ['create comment', addCommentRequestIcons];
                case CommentAction.EmoteLike:
                    return ['emote like comment', emoteLikeIcon];
                case CommentAction.EmoteLove:
                    return ['emote love comment', emoteLoveIcon];
                case CommentAction.EmoteLaugh:
                    return ['emote love comment', emoteLaughIcon];
                case CommentAction.EmoteShock:
                    return ['emote shock comment', emoteShockIcon];
                case CommentAction.EmoteAngry:
                    return ['emote angry comment', emoteAngryIcon];
                default:
                    return ['default icon', replyIcon];
            }
        case EntityType.Report: {
            switch (event) {
                case ReportAction.Share:
                    return ['share report', SharedRequestIcons];
                default:
                    return ['unshare report', UnsharedRequestIcons];
            }
        }
        default:
            return ['default', newRequestIcons];
    }
};

export const getLinkForNotification = (item: INotificationItem) => {
    const accessToken = localStorage.getItem('accessToken');
    switch (item.entityType) {
        case EntityType.HelpRequest:
        case EntityType.Feedback:
            if (isUser(accessToken)) {
                return `/request/${item.entityId}`;
            } else {
                return `/request-detail/${item.entityId}`;
            }
        case EntityType.Article:
            switch (item.eventType) {
                case ArticleAction.Submit:
                    if (isContentCreator(accessToken)) return `/article/${item.entityId}`;
                    if (isContentManager(accessToken) || isSuperAdmin(accessToken))
                        return `/manage-content/article/edit/${item.entityId}`;
                    return `/article/${item.entityId}`;
                case ArticleAction.SaveDraft:
                    if (isContentCreator(accessToken)) return `/manage-content/article/edit/${item.entityId}`;
                    return `/article/${item.entityId}`;
                case ArticleAction.Publish:
                    return `/article/${item.entityId}`;
                case ArticleAction.SubscribeNewsletter:
                    return `/news-feed/home`;
                case ArticleAction.SubscribeKnowledgeCenter:
                    return `/knowledge-articles/home`;
                default:
                    return `/article/${item.entityId}`;
            }

        case EntityType.Comment:
            switch (item.eventType) {
                case CommentAction.Create:
                case CommentAction.Tag:
                case CommentAction.Reply:
                case CommentAction.EmoteLike:
                case CommentAction.EmoteLove:
                case CommentAction.EmoteLaugh:
                case CommentAction.EmoteShock:
                case CommentAction.EmoteAngry:
                    if (isUser(accessToken)) {
                        return `/request/${item.parentEntityId}`;
                    } else {
                        return `/request-detail/${item.parentEntityId}`;
                    }
                default:
                    if (isUser(accessToken)) {
                        return `/request/${item.entityId}`;
                    } else {
                        return `/request-detail/${item.entityId}`;
                    }
            }
        case EntityType.Report:
            return `/manage-reports`;
        case EntityType.Status:
            if (isUser(accessToken)) {
                return `/request/${item.entityId}`;
            } else {
                return `/request-detail/${item.entityId}`;
            }
    }
};

export const getUserList = async (searchWord: string): Promise<any[]> => {
    const graphAccessToken = localStorage.getItem('msGraphAccessToken');

    try {
        if (graphAccessToken && searchWord.trim()) {
            const userSuggestionsEndpoint = `${API.API_MS_GRAPH}me/people/?$search=${searchWord.trim()}`;

            const userSuggestions = await axios.get(userSuggestionsEndpoint, {
                headers: {
                    responseType: 'json',
                    Authorization: `Bearer ${graphAccessToken}`
                }
            });

            if (userSuggestions.status === 200) {
                return [];
            }
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const keyPress = (event: any, handler: Function) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') handler();
};

export const getTags = (articleCategories: Array<any>) => {
    let categoryList: Array<any> = [];
    if (articleCategories?.length > 0)
        articleCategories.forEach((category: any) => {
            categoryList = [...categoryList, category.displayName];
            if (category.children.length > 0) {
                let childTags = getTags(category.children);
                categoryList = [...categoryList, ...childTags];
            }
        });
    return categoryList;
};

export const getFilterTags = (articleCategories: Array<any>) => {
    let categoryList: Array<any> = [];
    if (articleCategories?.length > 0)
        articleCategories
            .filter((c) => c.isValidTag)
            .forEach((category: any) => {
                categoryList = [
                    ...categoryList,
                    {
                        name: category.displayName,
                        level: category.categoryHierarchy.split('>').length,
                        isLeaf: category.children.length === 0
                    }
                ];
                if (category.children.length > 0 && category.isValidTag) {
                    let childTags = getFilterTags(category.children);
                    categoryList = [...categoryList, ...childTags];
                }
            });
    return categoryList;
};

export const isValidURL = (url: string, required: boolean = false): boolean => {
    if (url) {
        const URLregex = new RegExp(
            /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            'ig'
        );
        return url.match(URLregex) !== null;
    } else return required ? false : true;
};
export const isValidPowerBiURL = (url: string, required: boolean = false): boolean => {
    if (url) {
        return (
            isValidURL(url, required) &&
            url.toLocaleLowerCase().includes('reportid=') &&
            url.toLowerCase().includes('.powerbi.com/reportembed')
        );
    } else return required ? false : true;
};

export const isValidEmail = (email: string, required: boolean = false): boolean => {
    if (email) {
        const pattern =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    } else return required ? false : true;
};

export const removeDuplicatesFromArray = (array: Array<any>) => {
    return Array.from(new Set(array));
};

export const getBookmarkedTeam = (report: IReport, currentUserId?: string): Array<string> => {
    let teamList = report?.sharedWithUsers
        .map((user: IShareWithUser) => {
            if (user?.isBookmarked) {
                if (currentUserId && user.id === currentUserId) return '';
                return UserDomain[user.userDomain];
            }
            return '';
        })
        .filter((i) => i);
    return removeDuplicatesFromArray(teamList);
};

// get the mapping details for a field based on request type
export const filterField = (fieldName: string, requestTypeValue: number) => {
    const mappingData: any = {
        // SDSG - Proposal
        1: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // SDSG - Question
        2: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            answer: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // SDSG - Rfx
        3: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CREW - Pre Sales
        4: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            isDcfRequest: true,
            dcfJustification: true,
            isBlockingSale: true,
            isDcfApproved: true,
            dcfApprovedValue: true,
            dcfSR: true,
            mainRequestType: true,
            subRequestType: true,
            priority: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CREW - Post Sales
        5: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            isDcfRequest: true,
            dcfJustification: true,
            isBlockingSale: true,
            isDcfApproved: true,
            dcfApprovedValue: true,
            dcfSR: true,
            proofOfPurchaseAttached: true,
            serialNumbersAttached: true,
            cssCaseNumber: true,
            mainRequestType: true,
            subRequestType: true,
            priority: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CSS - Request
        6: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CSS - Review Request
        7: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            cssCaseNumber: true,
            productCategory: true,
            taskStatus: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CXP - Variant Request
        8: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            priorityNo: true,
            criticality: true,
            impactReach: true,
            cssCaseNumber: true,
            whatBusinessProblemIsSolved: true,
            competitiveOffering: true,
            isDesignForSurfacedPossible: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CXP - PFR Request
        9: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            priorityNo: true,
            criticality: true,
            cssCaseNumber: true,
            impactReach: true,
            whatBusinessProblemIsSolved: true,
            competitiveOffering: true,
            isDesignForSurfacedPossible: true,
            pfrSubType: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CXP - Messaging Request
        10: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            priorityNo: true,
            cssCaseNumber: true,
            criticality: true,
            impactReach: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CXP - Technical Request
        11: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            priorityNo: true,
            cssCaseNumber: true,
            criticality: true,
            impactReach: true,
            bugInformations: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CXP - Program Request
        12: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            priorityNo: true,
            criticality: true,
            impactReach: true,
            cssCaseNumber: true,
            whatBusinessProblemIsSolved: true,
            competitiveOffering: true,
            isDesignForSurfacedPossible: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CREW - Warranty Request
        13: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            isDcfRequest: true,
            dcfJustification: true,
            isBlockingSale: true,

            isDcfApproved: true,
            dcfApprovedValue: true,
            dcfSR: true,
            mainRequestType: true,
            subRequestType: true,
            priority: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CREW - Product Request
        14: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            impactReach: true,
            productCategory: true,
            whatBusinessProblemIsSolved: true,
            competitiveOffering: true,
            mainRequestType: true,
            subRequestType: true,
            priority: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true
        },
        // CREW - Messaging Request
        15: {
            summary: true,
            description: true,
            requestedResolutionDate: true,
            status: true,
            productCategory: true,
            impactReach: true,
            priority: true,
            whyThisDateIsImportant: true,
            companyInformation: true,
            hashTags: true,
            mainRequestType: true,
            subRequestType: true
        }
    };
    const requestItem = mappingData[requestTypeValue];
    if (requestItem && requestItem[fieldName]) {
        return true;
    } else {
        return false;
    }
};

/**
 * Generate URL and Query string for fetching comments
 */
export const generateUrlForFetchStatus = (criteria: ICommentCriteria) => {
    let apiURL = '/request/Status/list?';
    let queryString = '';
    if (criteria.correlationId) {
        queryString += `CorrelationId=${criteria.correlationId}`;
    }
    if (criteria.pageNumber) {
        queryString += `&PageNumber=${criteria.pageNumber}`;
    }
    if (criteria.pageSize) {
        queryString += `&PageSize=${criteria.pageSize}`;
    }
    if (criteria.entityType) {
        queryString += `&EntityType=${criteria.entityType}`;
    }
    if (StringConstants.IN_INTERNAL in criteria) {
        queryString += `&isInternal=${criteria.isInternal}`;
    }
    return apiURL + queryString;
};

/**
 * Generate anchor tag from URL
 */

export const ReplaceUrlByAnchor = (stringValue: string, className: string, openInNewTab: boolean) => {
    if (!stringValue) {
        return '';
    }
    let classValue = className ? className : '';
    let targetValue = openInNewTab ? 'target="_blank"' : '';
    let formattedText = stringValue.replaceAll(
        // eslint-disable-next-line no-useless-escape
        /[^\"\'\>\=](\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gim,
        ` <a class="${classValue}" href="$1" ${targetValue}>$1</a>`
    );
    return formattedText;
};

export const highlightMatch = (str: string, highlightText: string[], highlightColorClass: string = 'color-blue--primary') => {
    let text = str;
    highlightText.forEach((tag) => {
        text = text.replace(`#${tag}`, `<span class = ${highlightColorClass}>#${tag}</span>`);
    });
    return text;
};
export const searchOnClickTag = (
    str: string,
    highlightText: string[],
    className: string = `hashtag-decoration`,
    redirectTo: string = '/search-results/requests/HashTag/'
) => {
    let text = str;
    highlightText.forEach((tag) => {
        text = text.replace(`#${tag}`, `<a href=${redirectTo}${tag} class=${className}>#${tag}</a>`);
    });
    return text;
};

export const highlightTags = (str: string, className?: string) => {
    const tags = extractHashtagsWithIndices(str).map((tags) => tags.hashtag);
    return searchOnClickTag(str, tags, className);
};

export const formatHashtags = (tags: string[]) => {
    return tags.map((tag: string) => ({ hashTag: tag }));
};

export const getCardLabel = (value: string) => {
    switch (value) {
        case StringConstants.MANAGE_REQUEST:
            return 'Manage Requests';
        case StringConstants.STARRED_REQUEST:
            return 'Favorite Requests';
        case StringConstants.SHARED_REQUEST:
            return 'Shared Requests';
        case StringConstants.HIDDEN_REQUEST:
            return 'Hidden Requests';
        default:
            return value;
    }
};

/**
 * This is check if the text is an Hashtag
 */
export const containsHashtag = (str: string) => {
    try {
        const tags = extractHashtagsWithIndices(str || '').map((tags) => tags.hashtag);
        if (Array.isArray(tags) && tags.length === 1 && str.trim().replace('#', '').length === tags[0].trim().length) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

/**
 * Escape special characters in a string
 * @param str
 * @returns a string with escaped characters.
 */
export const escapeToSafeCharacters = (str: string) => {
    try {
        let result = str;
        if (result) {
            // eslint-disable-next-line no-useless-escape
            result = result.replaceAll(/[-\/\\^$*+?.()|[\]{}\:\"]/g, '\\$&');
        }
        return result;
    } catch (error) {
        return str;
    }
};

/**
 * Replace special characters in a string
 * @param str
 * @returns a string with specified characters replaced with an underscore.
 */
export const replaceUnsafeCharacters = (str: string) => {
    try {
        let result = str;
        if (result) {
            // eslint-disable-next-line no-useless-escape
            result = result.replaceAll(Files.SpecialCharactersRegex, Files.SpecialCharactersReplacement);
        }
        return result;
    } catch (error) {
        return str;
    }
};

export const injectTargetToAnchor = (str: string) => {
    const aTag = /<(a)([^>]+)>/g;
    return str?.replaceAll(aTag, '<$1 target="_blank"$2>');
};

export const getPageFromStore = (pathname: string) => {
    switch (pathname) {
        case RouteConstants.MANAGE_CONTENT_ARTICLES:
            return `articles`;
        case RouteConstants.MANAGE_CONTENT_EMAILS:
            return `emails`;
        case RouteConstants.MANAGE_REPORTS:
            return `reports`;
        case RouteConstants.ALL_ARTICLES:
            return `knowledgeArticles`;
        case RouteConstants.ALL_NEWS:
            return `newsfeed`;
        case RouteConstants.SEARCH_RESULTS_ARTICLES:
            return `articleSearch`;
        case RouteConstants.SEARCH_RESULTS_NEWS:
            return `newsSearch`;
        case RouteConstants.SEARCH_RESULTS_ALL_ARTICLES:
            return `searchAll`;
        default:
            switch (pathname.split('/')[1]) {
                case StringConstants.KNOWLEDGE_ARTICLES:
                    return `knowledgeArticleByTag`;
                case StringConstants.ARTICLES:
                    return `articleByTag`;
                case StringConstants.NEWS:
                    return `newsByTag`;
                default:
                    switch (pathname.split('/')[2]) {
                        case StringConstants.AUTHOR:
                            return `articleByAuthor`;
                        default:
                            break;
                    }
            }
    }
};

export const getQueryParam = (history: RouteComponentProps['history']) => {
    return queryString.parse(history?.location?.search);
};

export const setQueryParam = (
    history: RouteComponentProps['history'],
    pathname: string,
    params: object,
    replace: boolean = false,
    state?: any
) => {
    if (replace) {
        history?.replace({
            pathname: pathname,
            search: queryString.stringify(params),
            state: state
        });
    } else {
        params = { ...getQueryParam(history), ...params };
        if (_.isEmpty(state)) {
            if (!_.isEqual(params, getQueryParam(history))) {
                history?.push({
                    pathname: pathname,
                    search: queryString.stringify(params),
                    state: state
                });
            }
        } else {
            history?.push({
                pathname: pathname,
                search: queryString.stringify(params),
                state: state
            });
        }
    }
};

export const sanitizePowerBIUrl = (url: string) => {
    let urlElement = new URL(url);
    urlElement.searchParams.delete(StringConstants.AUTO_AUTH);
    return urlElement.toString();
};

export const sanitizeTextForWordCount = (htmlContent: string) => {
    return htmlContent.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, StringConstants.WHITE_SPACE);
};

/**
 * Remove a single item from a list
 * @param currentList - current list of items
 * @param itemToRemove - single item to be removed
 */
 export const removeSingleItemFromList = (currentList: any[], itemToRemove: any): any[] => {
    const list = [...currentList];
    list.splice(itemToRemove, 1);
    return list;
}
/**
 * Remove multiple items from a list
 * @param currentList - current list of items
 * @param itemsToRemove - list of items to be removed
 */
export const removeMultipleItemsFromList = (currentList: any[], itemsToRemove: any[]): any[] => {
    const list = [...currentList];
    itemsToRemove.forEach(item => {
        list.splice(item, 1);
    });
    return list;
}

/**
 * Removes duplicate entries
 * @param arr
 * @returns Unique list
 */
 export const removeDuplicateEntries = (arr: any[]) => {
    let a: any[] = [];
    arr.forEach((x: any) => {
        if (!a.includes(x)) {
            a.push(x);
        };
    });
    return a;
}

/**
 * Add items to a list
 * @param currentList
 * @param itemsToAdd
 * @returns New list with added items
 */
export const addItemsToList = (currentList: any[], itemsToAdd: any[]): any[] => {
    const list = [...currentList];
    itemsToAdd.forEach(item => {
        list.push(item);
    });
    return list;
}
