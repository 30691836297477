import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { setQueryParam } from '../helpers';
import { useHistory } from 'react-router-dom';
interface IProps {
    pageCount: number;
    setPageNumber?: Function;
    initialPage?: number;
    totalCount?: number;
    resultsPerPage?: number;
    updateURL?: boolean;
    stateData?: any;
}

const Pagination: React.FC<IProps> = ({
    pageCount,
    setPageNumber,
    totalCount,
    resultsPerPage,
    initialPage,
    updateURL = true,
    stateData = {}
}) => {
    const { t: translate } = useTranslation();
    const { pathname } = useLocation();
    const history = useHistory();

    const useHandelPageChange = (data: any) => {
        setPageNumber(data.selected + 1);
        if (updateURL) setQueryParam(history, pathname, { page: `${data.selected + 1}` }, false, stateData);
    };

    return (
        <div className="paginate-cont">
            {totalCount && resultsPerPage && (
                <div className="pagination-indicator">
                    {`${initialPage * resultsPerPage + 1} -
                    ${
                        initialPage * resultsPerPage + resultsPerPage > totalCount
                            ? totalCount
                            : initialPage * resultsPerPage + resultsPerPage
                    }
                     ${translate('of')} ${totalCount}`}
                </div>
            )}
            <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                disableInitialCallback={true}
                containerClassName={'pagination'}
                activeClassName={'active'}
                onPageChange={useHandelPageChange}
                previousLabel={translate('Previous')}
                nextLabel={translate('Next')}
                initialPage={initialPage}
            />
        </div>
    );
};

export default Pagination;
