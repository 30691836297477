import React from 'react';
import { Link, Text } from 'office-ui-fabric-react';
import { IconButton, IIconProps } from '@fluentui/react';
import './SuccessPopup.scss';
import { keyPress, formatCurrency } from 'app/components/helpers';
import { AppQueryClient } from 'app/services/clients/query-client';


interface ISuccessData {
    customer_name?: string;
    country?: string;
    impacted_revenue?: string;
    skpIdPost?: string;
}

interface IAddDetailsProps {
    onDismiss?: Function;
    setDisplayScreen?: Function;
    setSelectedData: Function;
    successData?: ISuccessData;
    setSearchField?: Function;
    setMatchFound: Function;
    setToggleTable: Function;
    setSearchedCustomer: Function;
}

const SuccessPopup: React.FC<IAddDetailsProps> = (props) => {
    const {
        onDismiss,
        setDisplayScreen,
        successData,
        setSearchField,
        setMatchFound,
        setToggleTable,
        setSearchedCustomer,
        setSelectedData
    } = props;

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const clickHandler = () => {
        setDisplayScreen('searchCust');
        setSearchedCustomer([]);
        setSearchField('');
        setMatchFound(false);
        setSelectedData([]);
        setToggleTable(false);
    };

    return (
        <div className="selected-customer-container">
            <div className={`close-model  close-primary`}>
                <IconButton
                    role="button"
                    aria-label="close popup"
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    tabIndex={0}
                    onKeyPress={(e: any) => keyPress(e, onDismiss)}
                    onClick={(e: any) => {
                        AppQueryClient.invalidateQueries('useRequestList');
                        AppQueryClient.invalidateQueries('getRequestById');
                        onDismiss();
                    }}
                />
            </div>
            <div className="modal-body success-body">
                <div className="icon-thumbsUp">
                    <svg width="217" height="188" viewBox="0 0 217 188" fill="#0078D4" xmlns="http://www.w3.org/2000/svg">
                        <path
                            className="thumb"
                            d="M188.567 69.9824C176.986 72.7616 155.685 71.7891 148.767 71.9744L94.5 72.3831C94.5 72.3831 102.674 48.7113 115.5 39.383C121 35.3831 130.408 32.0198 135.245 33.3988C143.59 35.778 169.223 45.6359 188.937 37.7215C188.937 37.7215 204.843 30.0942 208.295 40.6903C211.748 51.2865 200.148 67.2032 188.567 69.9824Z"
                        />
                        <path
                            className="fists"
                            d="M156.335 65.7297C168.074 66.2633 195.552 61.728 202.222 76.1343C202.222 76.1343 212.093 92.1412 199.02 103.346C199.02 103.346 212.893 117.219 195.285 134.293C195.285 134.293 204.89 149.5 192.084 160.971C192.084 160.971 197.153 182.314 180.612 184.982H84.0368C84.0368 184.982 73.8991 179.113 69.3638 173.51V91.6077H78.968C78.968 91.6077 86.7047 84.4045 92.3071 75.6007C94.5327 72.1033 96.2953 67.9743 97.996 63.8828L109.5 59.8828C109.202 60.3879 109.216 60.8454 109.5 61.2601C112.513 65.6644 145.851 65.2421 156.335 65.7297Z"
                        />
                        <path
                            className="wrist"
                            d="M6.63043 89.3828C2.91739 89.3828 0 92.0778 0 95.5078V181.258C0 184.688 2.91739 187.383 6.63043 187.383H54.3696C58.0826 187.383 61 184.688 61 181.258V95.5078C61 92.0778 58.0826 89.3828 54.3696 89.3828H6.63043Z"
                        />
                    </svg>
                </div>
                <Text className="success-title">Your customer has been added.</Text>
                <Text className="text-body">
                    A request with{' '}
                    <Text className="txt-bld ">
                        {successData?.customer_name}
                        {', '}
                        {successData?.country}{' '}
                    </Text>
                    has been created with <span className="bld">Request ID : {successData?.skpIdPost}</span>
                </Text>
                {successData?.impacted_revenue !== '' && (
                    <Text className="text-body-bold">
                        The Impact is worth <span> ${formatCurrency(parseFloat(successData?.impacted_revenue))}</span>
                    </Text>
                )}
                <Text className="text-body-small">You can view the details on the dashboard</Text>
                <div className="add-another-customer">
                    <Link
                        tabIndex={0}
                        onClick={clickHandler}
                        onKeyPress={(e: any) => keyPress(e, () => clickHandler())}
                        className="add-cus">
                        Add Another Customer
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SuccessPopup;
