import React, { useEffect, useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    DirectionalHint,
    IContextualMenuProps,
    PrimaryButton,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import TriageModal from '../triageModal/TriageModal';
import { Modal, IIconProps } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import useStore from 'app/store';
import { useHistory } from 'react-router';
import { AppQueryClient } from 'app/services/clients/query-client';
import AddAttachmentModal from 'app/components/addAttachmentModal/AddAttachmentModal';
import './EllipseMenu.scss';
import { IAttachmentUpdateData } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import { useTranslation } from 'react-i18next';
import { keyPress } from 'app/components/helpers';
import { useHideRequest, useUnhideRequest } from 'app/services/mutations';
import _ from 'lodash';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    isHidden: boolean;
    row: any;
    origin: string;
    isEditMode?: boolean;
    setIsEditMode?: Function;
    isListView?: boolean;
    setIsListView?: Function;
    setSelectedRequest?: Function;
    rootIdName?: string;
    page?: number;
    setPage?: Function;
}

export const EllipseMenu: React.FunctionComponent<IProps> = (props) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const setLastSelectedRequest = useStore((state: any) => state.setLastSelectedRequest);
    const setVisualFlowData = useStore((state: any) => state.setVisualFlowData);
    const page = useStore((state: any) => state.page);
    const setPage = useStore((state: any) => state.setPage);
    const triageModalSuccessNewModel = useStore((state: any) => state.triageModalSuccessNewModel);
    const addTriageModalSuccessNewModel = useStore((state: any) => state.addTriageModalSuccessNewModel);
    const [modalClicked, setModalClicked] = useState('');
    const [modalData, setModalData] = useState({});
    const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] = React.useState(false);
    const [isHideRequest, setIsHideRequest] = useState(false);
    const { mutate: callHideRequest, isSuccess: isHidden } = useHideRequest();
    const { mutate: callUnhideRequest, isSuccess: isUnhidden } = useUnhideRequest();
    const [hideLoaderDialog, { toggle: toggleLoaderHideDialog }] = useBoolean(true);
    const requestData = useStore((state: any) => state.requestData);
    const openReassignModal = () => {
        setModalClicked('reassign');
        showModal();
    };

    const openVisualFlow = () => {
        if (props.row.dataItem) {
            history.push(`/visual-flow/${props.row.dataItem.id}`);
        } else {
            setLastSelectedRequest(props.row);
            history.push(`/visual-flow/${props.row.id}`);
        }
        setVisualFlowData(props.row);
    };

    const handleEdit = () => {
        if (props.setSelectedRequest && props.isListView) {
            props.setSelectedRequest(props.row.dataItem);
        }
        if (props.setIsListView) {
            props.setIsListView(false);
        }
        props.setIsEditMode(true);
    };

    const handleAddAttachment = () => {
        setIsAddAttachmentModalOpen(true);
    };

    const getModalData = (data: IRequest) => {
        let attachmentData: IAttachmentUpdateData = {};
        if (data) {
            attachmentData.applicationId = data.applicationId;
            attachmentData.attachments = data.attachments;
            attachmentData.requestId = data.unifiedTrackingId;
            attachmentData.requestType = data.requestType;
            attachmentData.teamName = data.team;
            attachmentData.summary = data.summary;
            attachmentData.teamValue = data.teamValue;
            attachmentData.requestTypeValue = data.requestTypeValue;
        }
        return attachmentData;
    };
    const hideRequest = () => {
        setIsHideRequest(true);
        toggleHideDialog();
    };
    const unhideRequest = () => {
        setIsHideRequest(false);
        toggleHideDialog();
    };
    const requestStateMenu: IContextualMenuProps = {
        className: 'kebab-menu-open',
        shouldFocusOnMount: true,
        id: props.rootIdName,
        alignTargetEdge: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        items: [
            {
                key: 'reassign',

                text: translate('Reassign Team'),
                onClick: openReassignModal
            },
            {
                key: 'notify',
                text: translate('Attachments'),
                onClick: handleAddAttachment
            },
            {
                key: 'visual-flow',
                className: 'visual-flow-btn',
                text: translate('Visual Flow'),
                onClick: openVisualFlow
            },
            {
                key: 'edit',
                text: translate('Edit'),
                onClick: handleEdit
            },
            {
                key: 'hide',
                text: translate('Hide'),
                onClick: hideRequest
            }
        ]
    };
    const hiddenStateMenu: IContextualMenuProps = {
        className: 'kebab-menu-open',
        shouldFocusOnMount: true,
        id: props.rootIdName,
        alignTargetEdge: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        items: [
            {
                key: 'unhide',
                text: translate('Unhide'),
                onClick: unhideRequest
            }
        ]
    };

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const titleId = useId('title');
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const teamOptions = [
        { key: 1, text: StringConstants.SDSG },
        { key: 2, text: StringConstants.CXP },
        { key: 3, text: StringConstants.CSS },
        { key: 4, text: StringConstants.CREW }
    ];

    const closeModal = () => {
        AppQueryClient.invalidateQueries('requestQuery');
        AppQueryClient.invalidateQueries('getSummaryCount');
        hideModal();
    };

    const onCompletedHandler = () => {
        AppQueryClient.invalidateQueries('requestQuery');
    };
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: isHideRequest ? 'Hide Request' : 'Unhide Request',
        closeButtonAriaLabel: 'Close',
        subText: isHideRequest ? 'Are you sure you want to hide the request?' : 'Are you sure you want to Unhide the request?'
    };

    useEffect(() => {
        if (isHidden || isUnhidden) {
            if (props.origin !== 'LIST_VIEW') {
                if (requestData && requestData.length > 1) {
                    let currentRequestId = props.row?.unifiedTrackingId;
                    const indexOfCurrentRequest = _.findIndex(
                        requestData,
                        (item: any) => currentRequestId === item.unifiedTrackingId
                    );
                    let indexToHighlight: number = null;
                    if (indexOfCurrentRequest === requestData.length - 1) {
                        indexToHighlight = indexOfCurrentRequest - 1;
                    } else {
                        indexToHighlight = indexOfCurrentRequest + 1;
                    }
                    setLastSelectedRequest(requestData[indexToHighlight]);
                }
            }
            AppQueryClient.invalidateQueries('requestQuery').then(() => toggleLoaderHideDialog());

            AppQueryClient.invalidateQueries('useSummaryCardsCountForUser');

            toggleHideDialog();
            toggleLoaderHideDialog();
        }
    }, [isUnhidden, isHidden]);
    return (
        <>
            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            if (props.row.dataItem) {
                                if (isHideRequest) callHideRequest(props.row.dataItem.id);
                                else callUnhideRequest(props.row.dataItem.id);
                            } else {
                                if (isHideRequest) callHideRequest(props.row.id);
                                else callUnhideRequest(props.row.id);
                            }
                        }}
                        text={StringConstants.YES}
                    />
                    <DefaultButton onClick={toggleHideDialog} text="No" />
                </DialogFooter>
            </Dialog>
            <Dialog hidden={hideLoaderDialog} onDismiss={toggleLoaderHideDialog}>
                <Spinner
                    size={SpinnerSize.large}
                    label={isHideRequest ? 'Hiding request, please wait...' : 'Unhiding request, please wait...'}
                />
            </Dialog>
            <Modal
                layerProps={{ eventBubblingEnabled: true }}
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={closeModal}
                isBlocking={false}
                className={`triage-model ${triageModalSuccessNewModel ? 'success-modal' : ''}`}>
                <div className={`close-model ${triageModalSuccessNewModel ? 'successCross' : ''} `}>
                    <IconButton
                        role="button"
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={closeModal}
                        onKeyPress={(e: any) => keyPress(e, closeModal)}
                        data-testid="close-modal"
                        tabIndex={0}
                    />
                </div>
                <TriageModal
                    hideModal={hideModal}
                    openModal={modalClicked}
                    data={modalData}
                    reassignTeamOptions={teamOptions}
                    setIsListView={props.setIsListView}
                    setSelectedRequest={props.setSelectedRequest}
                    page={page}
                    setPage={setPage}
                />
            </Modal>
            <IconButton
                role="button"
                aria-label="show action"
                className={`dots-menu ${props.rootIdName}`}
                onMenuClick={() => {
                    if (props.origin === 'LIST_VIEW') {
                        setModalData(props.row.dataItem);
                    } else {
                        setModalData(props.row);
                    }
                    addTriageModalSuccessNewModel(false);
                }}
                iconProps={{ iconName: 'MoreVertical' }}
                menuProps={props.isHidden ? hiddenStateMenu : requestStateMenu}
            />

            {isAddAttachmentModalOpen && (
                <AddAttachmentModal
                    modalState={isAddAttachmentModalOpen}
                    updateModalState={(value: boolean) => setIsAddAttachmentModalOpen(value)}
                    selectedRequest={getModalData(modalData)}
                    userType="admin"
                    entityType={1}
                    onCompletedHandler={onCompletedHandler}
                />
            )}
        </>
    );
};
