import React, { useEffect, useState } from 'react';
import './ArticleContent.scss';
import { PersonaInitialsColor } from '@fluentui/react';
import moment from 'moment';
import { BlobStorageContainer, BlobStorageHelper, Constants, Entity } from 'app/utils/constants';
import { IUserDetails } from 'app/models/common/post';
import { Trans } from 'react-i18next';
import { UserDomain } from 'app/enums/UserDomain.enum';
import Avatar from 'app/components/avatar/Avatar';
import ReactPlayer from 'react-player/lazy';
import { ArticleStatus } from 'app/enums/Status.enum';
import { sanitizeTextForWordCount } from 'app/components/helpers';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';

interface IProps {
    content: string;
    author?: IUserDetails;
    views: number;
    publishedDate?: Date;
    shouldHideAuthorInfo?: boolean;
    isTraining?: boolean;
    trainingVideo?: string;
    title?: string;
    status?: ArticleStatus;
}
const Words_Per_Minute = 250;

const ArticleContent: React.FC<IProps> = (props: IProps) => {
    const { content, author, views, publishedDate, shouldHideAuthorInfo, isTraining, trainingVideo, title, status } = props;
    const { displayName, userDomain, id }: IUserDetails = author || {
        displayName: '',
        email: '',
        userRoles: [],
        userDomain: UserDomain.SDSG,
        id: ''
    };
    const [tokenizedVideo, setTokenizedVideo] = useState('');
    const [tokenizedContent, setTokenizedContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const readingTime = require('reading-time');
    const stats = readingTime(sanitizeTextForWordCount(content), { wordsPerMinute: Words_Per_Minute });
    const getFormattedReadingTime = () => {
        let time = Math.ceil(stats.minutes);
        let units = time > 1 ? 'minutes' : 'minute';
        return `About ${time} ${units} read`;
    };

    useEffect(() => {
        setTokenizedContent('');
        if (content && content !== '') {
            const replaceStorageUrls = async () => {
                if (content.match(BlobStorageHelper.STORAGE_URL_REGEX)) {
                    let contentTokenizedStr = await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, content);
                    setTokenizedContent(contentTokenizedStr);
                }
                else {
                    setTokenizedContent(content);
                }
            };

            replaceStorageUrls();
        }
    }, [content]);

    useEffect(() => {
        document.querySelector('#tem').innerHTML = tokenizedContent;
        const sectionList = [];
        let id = 1;
        const headers: NodeListOf<Element> = document.querySelectorAll('#tem h2');
        for (var item of headers as any) {
            id += 1;
            const sectionId = 'generated-section-' + id;
            item.id = sectionId;
            sectionList.push({
                id: sectionId,
                text: item.textContent
            });
        }
    }, [content, tokenizedContent]);

    useEffect(() => {
        if (trainingVideo && trainingVideo !== '') {
            const replaceStorageUrls = async () => {
                await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, trainingVideo)
                .then((res) => {
                    setTokenizedVideo(res);
                    setIsLoading(false);
                });
            }

            replaceStorageUrls();
        }
    }, [trainingVideo]);

    const getText = (articleStatus: ArticleStatus) => {
        switch (articleStatus) {
            case ArticleStatus.ScheduledToPublish:
                return `Scheduled for`;
            case ArticleStatus.Published:
                return `Published on`;
            case ArticleStatus.Removed:
                return `Removed on`;
            default:
                return `Last Edited`;
        }
    };

    return (
        <>
            <div className={`newsfeed-article-content ${!isTraining ? 'container' : ''}`}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div
                            className={`ms-Grid-col article-content sun-editor-editable ${
                                isTraining ? 'ms-lg12' : 'ms-sm12 ms-md9 ms-lg9'
                            }`}
                            style={{ minHeight: '500px' }}>
                            {isTraining && (
                                <>
                                    {tokenizedVideo && !isLoading && (
                                        <div className="training-video-container">
                                            <ReactPlayer controls url={ tokenizedVideo } className="react-player" />
                                        </div>
                                    )}
                                    <div className="training-title-views">
                                        <span className="training-title">{title}</span>
                                        <div className="flex author-views">
                                            <svg
                                                className="ms-Icon"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 12 12"
                                                fill="#323130"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.97433 6.65857C1.88683 6.92025 1.60384 7.06162 1.34202 6.97435C1.08005 6.88702 0.93847 6.60386 1.02579 6.34189C1.01669 6.37235 1.02617 6.34078 1.02617 6.34078C1.03169 6.32438 1.03759 6.30811 1.04363 6.29189C1.05392 6.26424 1.06872 6.22604 1.0884 6.17879C1.12774 6.08438 1.18683 5.95334 1.26888 5.79787C1.43254 5.48778 1.69025 5.07538 2.06906 4.66214C2.83437 3.82726 4.08679 3 6.00014 3C7.91348 3 9.16591 3.82726 9.93121 4.66214C10.31 5.07538 10.5677 5.48778 10.7314 5.79787C10.8134 5.95334 10.8725 6.08438 10.9119 6.17879C10.9316 6.22604 10.9463 6.26424 10.9566 6.29189C10.9597 6.30015 10.97 6.35247 10.98 6.40273C10.989 6.44843 10.9977 6.49242 11.0002 6.5C11.0002 6.5 11.0837 6.83252 10.6582 6.97435C10.3968 7.06149 10.1143 6.92068 10.0263 6.65976L10.0259 6.65857L10.0255 6.65748L10.0195 6.64073C10.0134 6.62444 10.0033 6.59819 9.98879 6.56341C9.95977 6.49375 9.91339 6.39042 9.84701 6.26463C9.71379 6.01222 9.50275 5.67462 9.19406 5.33786C8.58437 4.67274 7.58679 4 6.00014 4C4.41348 4 3.41591 4.67274 2.80621 5.33786C2.49752 5.67462 2.28648 6.01222 2.15327 6.26463C2.08688 6.39042 2.0405 6.49375 2.01148 6.56341C1.99699 6.59819 1.98688 6.62444 1.98081 6.64073L1.97433 6.65857ZM4.00024 7C4.00024 5.89543 4.89567 5 6.00024 5C7.10481 5 8.00024 5.89543 8.00024 7C8.00024 8.10457 7.10481 9 6.00024 9C4.89567 9 4.00024 8.10457 4.00024 7Z" />
                                            </svg>

                                            <span>
                                                {views} <Trans>Views</Trans>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div id="tem"></div>
                        </div>
                        {!isTraining && (
                            <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 sticky-container-right">
                                <div className="newsfeed-author-info-card">
                                    {!shouldHideAuthorInfo && (
                                        <>
                                            <div className="newsfeed-author-text-written">
                                                <Trans>Written by</Trans>
                                            </div>
                                            <div className="newsfeed-card-group">
                                                <div className="newsfeed-author-info-card-avatar">
                                                    <Avatar
                                                        userId={id}
                                                        userName={displayName}
                                                        initialColor={PersonaInitialsColor.teal}
                                                        imageSize={72}
                                                        roundedPic={true}
                                                    />
                                                </div>
                                                <div className="newsfeed-author-title-group">
                                                    <div className="newsfeed-author-title">{displayName}</div>
                                                    <div className="newsfeed-author-designation"></div>
                                                    {UserDomain[userDomain] && (
                                                        <div className="newsfeed-author-tag">{UserDomain[userDomain]}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="author-publish-details">
                                        <div className="author-publish-title">
                                            <Trans>{getText(status)}</Trans>
                                        </div>
                                        <div>{moment(publishedDate).format(Constants.DATE_FORMAT)}</div>
                                        <div className="flex author-views">
                                            <i className="ms-Icon ms-Icon--RedEye" aria-hidden="true"></i>
                                            <span>
                                                {views} <Trans>Views</Trans>
                                            </span>
                                        </div>
                                        <div className="flex author-read">
                                            <i className="ms-Icon ms-Icon--ReadingMode" aria-hidden="true"></i>
                                            <span>{getFormattedReadingTime()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticleContent;
