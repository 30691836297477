
import React from 'react';
import { keyPress } from '../helpers';

import './Button.scss';

interface IProps {
    buttonClass?: string;
    iconClass?: string;
    iconSrc?: string;
    altText?: string;
    buttonText: string;
    onClick?: Function;
    disabled?: boolean;
    title?: string;
    iconSVG?: string;
    dataTestId?: string;
}

const Button: React.FC<IProps> = ({
    dataTestId = 'btn',
    buttonClass = '',
    iconSrc = '',
    iconClass = '',
    altText = '',
    buttonText = '',
    disabled = false,
    title = '',
    onClick,
    iconSVG
}) => {
    return (
        <button
            data-testid={dataTestId}
            title={title}
            tabIndex={0}
            className={buttonClass}
            onClick={() => onClick()}
            onKeyPress={(e: any) => keyPress(e, () => onClick())}
            disabled={disabled}>
            {iconClass && iconClass !== '' && <i className={`icon ${iconClass}`} aria-hidden="true"></i>}
            {iconSVG && iconSVG !== '' && <span className="icon-svg" dangerouslySetInnerHTML={{ __html: iconSVG }}></span>}
            {iconSrc.trim() !== '' && <img src={iconSrc} className="icon" alt={altText} />}
            {buttonText}
        </button>
    );
};

export default Button;
