import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import React from 'react';
import App from 'app/App';
import './i18n.js';

//azure authentication
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { QueryClientProvider } from 'react-query';
import { AppQueryClient } from 'app/services/clients/query-client';

const msalInstance = new PublicClientApplication(msalConfig);

const renderer = () => {
    return (
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={AppQueryClient}>
                <App />
            </QueryClientProvider>
        </MsalProvider>
    );
};

ReactDOM.render(
    renderer(),
    document.getElementById('root')
);
