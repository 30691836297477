export enum RequestType {
    Proposal = 1,
    Question = 2,
    Rfx = 3,
    PreSales = 4, 
    PostSales = 5, 
    CssRequest = 6,
    CssReviewRequest = 7, 
    Cxp_VariantRequest = 8,
    Cxp_PfrRequest = 9,
    Cxp_MessagingRequest = 10,
    Cxp_TechnicalRequest = 11, 
    Cxp_ProgramRequest = 12,
    Crew_WarrantyRequest = 13,
    Crew_ProductRequest = 14,
    Crew_MessagingRequest = 15
}
