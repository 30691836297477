import React, { useEffect, useRef, useState } from 'react';
import { usePostFeedbackResponse } from 'app/services/mutations';
import { Text, Link, TextField, DefaultButton, Image } from '@fluentui/react';
import fcIconTerrible from 'app/static/icons/fc_icon_terrible_active.svg';
import fcIconBad from 'app/static/icons/fc_icon_bad_active.svg';
import fcIconOkay from 'app/static/icons/fc_icon_okay_active.svg';
import fcIconGood from 'app/static/icons/fc_icon_good_active.svg';
import fcIconGreat from 'app/static/icons/fc_icon_great_active.svg';
import './FeedbackResponseForm.scss';
import { Trans } from 'react-i18next';
import { AppQueryClient } from 'app/services/clients/query-client';
const getExperienceIcon = (reactionName: string) => {
    let source;
    switch (reactionName.toLowerCase()) {
        case 'terrible':
            source = fcIconTerrible;
            break;
        case 'bad':
            source = fcIconBad;
            break;
        case 'okay':
            source = fcIconOkay;
            break;
        case 'good':
            source = fcIconGood;
            break;
        case 'great':
            source = fcIconGreat;
            break;
        default:
            break;
    }
    return source;
};

interface IProps {
    showResponseView: () => void;
    responseData: any;
}

const FeedbackResponseForm: React.FC<IProps> = ({ responseData, showResponseView }) => {
    const [feedbackComment, setFeedbackComment] = useState('');
    const [validateMsg, setValidateMsg] = useState(false);

    const formContainer = useRef(null);

    const {
        mutate: postFeedbackResponse,
        isLoading: isLoadingSubmitResponse,
        isSuccess: isSuccessSubmitResponse
    } = usePostFeedbackResponse();

    const data = responseData || {};

    const handleSubmitEvent = () => {
        if (feedbackComment.trim() === '') {
            setValidateMsg(true);
        } else {
            postFeedbackResponse({ id: data.id, response: feedbackComment });
        }
    };

    const handleBackEvent = () => {
        setValidateMsg(false);
        showResponseView();
    };

    const handleCommentChange = (e: any) => {
        setValidateMsg(false);
        setFeedbackComment(e.target.value);
    };

    useEffect(() => {
        if (formContainer?.current?.scrollIntoView) {
            formContainer.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [responseData]);

    useEffect(() => {
        if (!isLoadingSubmitResponse) {
            if (isSuccessSubmitResponse) {
                AppQueryClient.invalidateQueries('requestQuery');
                showResponseView();
            }
        }
    }, [isLoadingSubmitResponse, isSuccessSubmitResponse]);

    return (
        <div className="response-form-wrapper" ref={formContainer}>
            <div className="back-panel">
                <Link role="link" onClick={handleBackEvent}>
                    <i className="ms-Icon ms-Icon--Back" aria-hidden="true"></i>
                </Link>
            </div>
            <div className="response-container">
                <div className="response-content">
                    <div className="response-title">{data.date || ''}</div>
                    <div>
                        <Text>{data.feedback}</Text>
                    </div>
                    <div className="response-footer">
                        <div className="response-icon">
                            <Image src={getExperienceIcon(data.experience || '')} />
                            <Text>{data.experience || ''}</Text>
                        </div>
                        <div className="response-category">
                            <Text>{data.category || ''}</Text>
                        </div>
                        <div>{data.providedBy || ''}</div>
                        <div> ({data.team || ''})</div>
                    </div>
                </div>
                <div>
                    <Trans>Your Response</Trans>
                </div>
                <div className="textfield-wrap">
                    <TextField
                        value={feedbackComment}
                        placeholder="Type your response here..."
                        multiline
                        resizable={false}
                        borderless
                        rows={10}
                        onChange={handleCommentChange}
                    />
                </div>
                {validateMsg && (
                    <Text className="validate-msg m-t-10">
                        <Trans>Please fill in some response!</Trans>
                    </Text>
                )}
                <div className="form-btn-wrap">
                    <DefaultButton className="default" onClick={handleBackEvent} data-testid="cancel-button">
                        <Trans>Cancel</Trans>
                    </DefaultButton>
                    <DefaultButton type="submit" className="primary" onClick={handleSubmitEvent} data-testid="send-button">
                        <Trans>Send</Trans>
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
};

export default FeedbackResponseForm;
