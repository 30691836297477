import React from 'react';
import { Link, Text } from 'office-ui-fabric-react';
import { TextField, IconButton, IIconProps, IImageProps, TooltipHost, DefaultButton, PrimaryButton, Image } from '@fluentui/react';
import InfoIcon from '../../../static/icons/tooltip-info-icon.svg';
import { useId } from '@fluentui/react-hooks';
import { IAddCustomerData, ISelectedData } from 'app/models/common/response';
import NumberFormat from 'react-number-format';
import { keyPress } from 'app/components/helpers';
import './AddMultiDetails.scss';
import { RequestAction } from 'app/enums/RequestAction.enum';
import { RequestType } from 'app/enums/RequestType.enum';

interface IAddDetailsProps {
    selectedRequest: IAddCustomerData;
    setDisplayScreen?: Function;
    onDismiss?: Function;
    selectedData: Array<ISelectedData>;
    handleMultiChange: Function;
    multiFormError?: boolean;
    cssErrorText?: boolean;
    handleAddCustomer?: Function;
    cssNumber?: string;
    setCssNumber?: Function;
    setErrorText?: Function;
}

const AddMultiDetails: React.FC<IAddDetailsProps> = (props) => {
    const {
        selectedRequest,
        onDismiss,
        setDisplayScreen,
        selectedData,
        handleMultiChange,
        multiFormError,
        cssNumber,
        setCssNumber,
        handleAddCustomer,
        cssErrorText,
        setErrorText
    } = props;

    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const calloutProps = { gapSpace: 0 };
    const tooltipId = useId('tooltip');

    const clickHandler = () => {
        setDisplayScreen('searchCust');
    };

    const typeOfRequestValidator = (requestType: any) => {
        switch (requestType) {
            case 7:
                return requestType === 7
            case 11:
                return requestType === 11
            default:
                return false;
        }
    }
    const imageProps: IImageProps = {
        styles: () => ({ root: { paddingBottom: '10px' } }),
    };

    return (
        <div className="selected-customer-container">
            <div className={`close-model `}>
                <IconButton
                    role="button"
                    aria-label="close popup"
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    tabIndex={0}
                    onClick={() => onDismiss()}
                    onKeyPress={(e: any) => keyPress(e, () => onDismiss())}
                />
            </div>
            <div className="modal-heading">
                <span>Add a customer</span>
            </div>
            <div className="modal-body">
                <div className="body-main-container">
                    <Text className="modal-body-title back-title">
                        <Link
                            aria-label="back button"
                            tabIndex={0}
                            onClick={clickHandler}
                            onKeyPress={(e: any) => keyPress(e, clickHandler)}
                            data-testid="search-customer">
                            <span className="arrow-icon"></span>
                        </Link>
                        Add Details
                    </Text>
                    <div className="body-main-sub">
                        <div className="req-app-styles">
                            <Text className="secondary-text mb-10">
                                <span className="text-bold">Request ID - </span>
                                {selectedRequest.unifiedTrackingId}
                            </Text>
                            <div className="secondary-text mb-10 pipe-styles"> </div>
                            <Text className="secondary-text mb-10">
                                <span className="text-bold"> Application ID - </span>
                                {selectedRequest.applicationId}
                            </Text>
                        </div>
                        <div>
                            <Text className="modal-body-title title-field">{selectedRequest.summary}</Text>
                        </div>
                    </div>
                    <div className="body-main-sub">
                        <div className="req-app-styles">
                            <Text className="secondary-text mb-10">
                                <span className="text-bold">Customer Name - </span>
                                {selectedData[0]?.AccountName}
                            </Text>
                            <div className="secondary-text mb-10 pipe-styles"> </div>
                            <Text className="secondary-text mb-10">
                                <span className="text-bold"> Account ID - </span>
                                {selectedData[0]?.AccountNumber}
                            </Text>
                            <div className="secondary-text mb-10 pipe-styles"> </div>
                            <Text className="secondary-text mb-10">
                                <span className="text-bold"> Country/Subsidiary  - </span>
                                {selectedData[0]?.Country}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className="body-result-container">
                    <div>
                        {(selectedRequest.requestType === RequestType.CssReviewRequest || selectedRequest.requestType === RequestType.Cxp_TechnicalRequest) && (
                            <div className="info-css-textfield-container row-flex-box m-b-25">
                            <div className="css-case-styles">
                                <div className="tooltip-styles">
                                    <label className="label-styles">CSS Case Number</label>
                                    <Text>
                                        <TooltipHost
                                            className="tooltip-display"
                                            id={tooltipId}
                                            calloutProps={calloutProps}
                                            content={
                                                'For this type of request please have a CSS support case open first'
                                            }>
                                            <Image
                                                {...imageProps}
                                                aria-describedby={tooltipId} src={InfoIcon} alt="Info"
                                            />
                                        </TooltipHost>
                                    </Text>
                                </div>
                                <div className="info-container">
                                    <TextField
                                        value={cssNumber}
                                        onChange={(e: any) => {
                                            handleMultiChange(e, 'css-case-num', e.target.value);
                                            setCssNumber(e.target.value);
                                            setErrorText(false);
                                        }}
                                    />

                                </div>
                                <div>
                                    {cssErrorText &&
                                        <div className="error-info-message">
                                            <span className="helper-txt">Please enter the CSS Case Number.</span>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    {selectedData.map((item: any) => (
                        <div>
                            <div className="impacted-text-fields">
                                <div>
                                    <label className="label-styles">Impacted Revenue ($)</label>
                                    <NumberFormat
                                        aria-label='Impacted Revenue'
                                        thousandSeparator={true}
                                        value={item.EstimatedValue}
                                        onChange={(e: any) => {
                                            handleMultiChange(e, 'impacted_revenue', item.OpportunityNumber);
                                        }}
                                        placeholder="$"
                                        allowNegative={false}
                                        inputMode="numeric"
                                        className="num-foramt"
                                        decimalScale={2}
                                    />
                                    {item.revenueErrorText && (
                                        <span className="helper-txt">Please enter the revenue risk involved.</span>
                                    )}
                                </div>
                                <div>
                                    <label className="label-styles">Impacted Devices</label>
                                    <NumberFormat
                                        aria-label='Impacted Devices'
                                        thousandSeparator={true}
                                        value={item.TotalDeviceCount}
                                        onChange={(e: any) => {
                                            handleMultiChange(e, 'impacted_device', item.OpportunityNumber);
                                        }}
                                        placeholder="0"
                                        allowNegative={false}
                                        inputMode="numeric"
                                        className="num-foramt"
                                        decimalScale={0}
                                    />
                                    {item.devicesErrorText && (
                                        <span className="helper-txt">Please enter the device risk involved.</span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <label className="label-styles">Please tell us how this impacts your customer</label>
                                <TextField
                                    ariaLabel='Enter How this impacts your customer'
                                    className="cust-impact"
                                    multiline={true}
                                    onChange={(e: any) => {
                                        handleMultiChange(e, 'customer_impact', item.OpportunityNumber);
                                    }}
                                    value={item.CustomerImpact}
                                />
                            </div>
                        </div>
                    ))}
                    <div>{multiFormError && <label className="helper-txt">Impacted Devices and revenue not empty!</label>}</div>
                    <div className="add-details-btn">
                        <DefaultButton
                            tabIndex={0}
                            onClick={(e: any) => {
                                onDismiss();
                            }}
                            onKeyPress={(e: any) => keyPress(e, () => onDismiss())}
                            text="Cancel"
                        />
                        <PrimaryButton
                            tabIndex={0}
                            onClick={() => {
                                if (selectedRequest.requestType === RequestAction.DeleteAttachment && (cssNumber === '' || cssNumber === undefined)) {
                                    setErrorText(true);
                                    return;
                                }
                                handleAddCustomer();
                            }}
                            onKeyPress={(e: any) => keyPress(e, () => handleAddCustomer())}
                            text="Next"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMultiDetails;
