import React, { useEffect, useRef, useState } from 'react';

import { Text, Checkbox, Icon, FontIcon } from '@fluentui/react';

import { IOptionProps } from '../../models';
import { keyPress, setQueryParam } from 'app/components/helpers';
import { useHistory } from 'react-router-dom';
import { RouteConstants } from 'app/utils/constants';
import useStore from 'app/store';
interface IProps {
    selectedKeys: IOptionProps[];
    setSelectedKeys: Function;
    origin: string;
    stateData?: any;
    setFilterActive?: (filterActive: boolean) => void;
}

const options: IOptionProps[] = [
    {
        key: 'all_request',

        text: 'All request types'
    },
    {
        key: 'SDSG',

        text: 'Surface Deal Success Group (SDSG)',

        dependentProperties: ['rfx_request', 'proposal_request', 'question_request']
    },
    { key: 'rfx_request', text: 'Rfx', parentProperty: 'SDSG', type: 3 },
    {
        key: 'proposal_request',

        text: 'Proposal',

        parentProperty: 'SDSG',
        type: 1
    },
    {
        key: 'question_request',

        text: 'Question',

        parentProperty: 'SDSG',
        type: 2
    },
    {
        key: 'CXP',

        text: 'Customer Experience Program Management (CXP)',

        dependentProperties: ['variant_request', 'feature_request', 'messaging_request', 'technical_request', 'program_request']
    },
    {
        key: 'variant_request',

        text: 'Variant',

        parentProperty: 'CXP',
        type: 8
    },
    {
        key: 'feature_request',

        text: 'PFR',

        parentProperty: 'CXP',
        type: 9
    },
    {
        key: 'messaging_request',

        text: 'Messaging',

        parentProperty: 'CXP',
        type: 10
    },
    {
        key: 'technical_request',

        text: 'Technical',

        parentProperty: 'CXP',
        type: 11
    },
    {
        key: 'program_request',

        text: 'Program',

        parentProperty: 'CXP',
        type: 12
    },
    {
        key: 'CSS',

        text: 'Customer Service and Support (CSS)',

        dependentProperties: ['review_request']
    },
    {
        key: 'review_request',

        text: 'Review Request',

        parentProperty: 'CSS',
        type: 7
    },
    {
        key: 'CREW',

        text: 'Customer Repair Experience Warranty (CREW)',

        dependentProperties: [
            'pre_sales_request',
            'post_sales_request',
            'warranty_request',
            'product_request',
            'crew_messaging_request'
        ]
    },
    {
        key: 'pre_sales_request',

        text: 'Pre Sales',

        parentProperty: 'CREW',
        type: 4
    },

    {
        key: 'post_sales_request',

        text: 'Post Sales',

        parentProperty: 'CREW',
        type: 5
    },

    {
        key: 'warranty_request',

        text: 'Warranty',

        parentProperty: 'CREW',
        type: 13
    },

    {
        key: 'product_request',

        text: 'Product',

        parentProperty: 'CREW',
        type: 14
    },
    {
        key: 'crew_messaging_request',

        text: 'Messaging',

        parentProperty: 'CREW',
        type: 15
    }
];

const RequestTypeFilter: React.FC<IProps> = ({ selectedKeys, setSelectedKeys, origin, stateData, setFilterActive }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const filterPanelDisabled = useStore((state: any) => state.filterPanelDisabled);
    const dropDownRef = useRef(null);

    const history = useHistory();

    const setQueryStateData = (data: any, replace: boolean) => {
        if (origin === 'ADMIN') {
            setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, replace, {
                ...stateData,
                ...data
            });
        } else {
            setQueryParam(history, RouteConstants.UNIFIED_DASHBOARD, { page: 1 }, replace, {
                ...stateData,
                ...data
            });
        }
    };
    const onChange = (key: string, dependentProperties: string[], parentProperty: string) => {
        if (key === 'all_request') {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: any[] = [];
                options.forEach((ele) => arr.push(ele));
                setQueryStateData({ requestTypeSelection: arr }, false);
            } else {
                setSelectedKeys([]);
            }
        } else if (!dependentProperties) {
            if (selectedKeys.filter((item) => item.key === key).length !== 0) {
                const tempSelectedKeys = selectedKeys.filter(
                    (item) => item.key !== key && item.key !== 'all_request' && item.key !== parentProperty
                );
                setQueryStateData({ requestTypeSelection: tempSelectedKeys }, false);
            } else {
                let count = 0;
                let parent: IOptionProps = options.filter((item) => item.key === parentProperty)[0];
                let temp = parent.dependentProperties as string[];
                temp.forEach((ele) => {
                    if (selectedKeys.filter((item) => item.key === ele).length !== 0) {
                        count = count + 1;
                    }
                });
                if (count + 1 === temp.length) {
                    const tempSelectedKeys = [
                        ...selectedKeys,
                        options.filter((item) => item.key === key)[0],
                        options.filter((item) => item.key === parentProperty)[0]
                    ];
                    setQueryStateData({ requestTypeSelection: tempSelectedKeys }, false);
                } else {
                    const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.key === key)[0]];
                    setQueryStateData({ requestTypeSelection: tempSelectedKeys }, false);
                }
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length !== 0) {
                let arr = selectedKeys;

                dependentProperties.forEach((ele) => {
                    let ind = arr.findIndex((element) => {
                        if (element.key === ele) return true;
                    });
                    if (ind !== -1) {
                        arr.splice(ind, 1);
                    }
                });

                const tempSelectedKeys = arr.filter((ele) => ele.key !== key && ele.key !== 'all_request');
                setQueryStateData({ requestTypeSelection: tempSelectedKeys }, false);
            } else {
                let arr: IOptionProps[] = [];
                dependentProperties.forEach((item) => {
                    if (selectedKeys.filter((it) => it.key === item).length === 0)
                        arr.push(options.filter((ele) => ele.key === item)[0]);
                });
                const tempSelectedKeys = [...selectedKeys.concat(arr), options.filter((item) => item.key === key)[0]];
                setQueryStateData({ requestTypeSelection: tempSelectedKeys }, false);
            }
        }
    };
    const isChecked = (ele: string) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };
    const onToggleShow = () => {
        if (!filterPanelDisabled) {
            setShowDropdown((value) => !value);
        }
    };
    const removeDuplicates = (arr: string[]) => {
        let uniqueArr = arr.filter((ele, index) => {
            return arr.indexOf(ele) === index;
        });
        return uniqueArr;
    };
    const getLabel = () => {
        if (selectedKeys?.filter((ele) => ele?.key === 'all_request').length !== 0 || selectedKeys?.length === 0) {
            return 'All request types';
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                if (item.dependentProperties) {
                    arr.push(item?.key + ' - All');
                } else {
                    let parent = selectedKeys.filter((it) => it?.key === item?.parentProperty)[0];
                    if (selectedKeys.filter((ele) => ele?.key === parent?.key).length !== 0) {
                        arr.push(parent?.key + ' - All');
                    } else {
                        arr.push(item?.text);
                    }
                }
            });

            let res = removeDuplicates(arr);
            return res.join(', ');
        }
    };

    const checkEquality = (arr1: IOptionProps[], arr2: IOptionProps[]) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.key === arr1[i].key).length === 0) {
                return false;
            }
        }
        return true;
    };
    useDropDownDismiss(dropDownRef, onToggleShow, filterPanelDisabled);

    useEffect(() => {
        if (showDropdown && selectedKeys.length === 0) {
            setSelectedKeys(options.slice(0));
        }
    }, [showDropdown]);
    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys?.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.key !== 'all_request')
            )
        ) {
            setSelectedKeys(options.slice(0));
        }
    }, [selectedKeys]);

    useEffect(() => {
        if (setFilterActive) {
            if (selectedKeys.length > 0 && selectedKeys.length !== options.length) {
                setFilterActive(true);
            } else {
                setFilterActive(false);
            }
        }
    }, [selectedKeys]);

    return (
        <div className="request-type-filter filter-cont">
            {origin === 'ADMIN' && 
                <Text className="filter-heading">Request type {(selectedKeys.length > 0 && selectedKeys.length !== options.length) && <FontIcon iconName="LocationDot" className="filter-active-icon" />}</Text>
            }

            <div
                className={`placeholder-title row-flex-box align-center ${
                    origin === 'END_USER' ? 'unified-dashboard-filter' : ''
                }  ${filterPanelDisabled ? 'filter-disabled' : ''}`}
                tabIndex={0}
                data-testid="requestType-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <Text className={`placeholder-label`}>{getLabel()}</Text> <Icon iconName="ChevronDown" />
            </div>

            {showDropdown && (
                <div ref={dropDownRef} className="dropdown-cont">
                    <div
                        tabIndex={0}
                        data-testid="requestType-filter-close-btn-test"
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center dropdown-header">
                        <Text>Request Type</Text>
                        <Icon iconName="ChevronUp" />
                    </div>

                    {options.map((item, index) => (
                        <div
                            data-testid={`dropdown-item-test-${index}`}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                    onChange(
                                        item.key as string,
                                        item.dependentProperties as string[],
                                        item.parentProperty as string
                                    );
                                }
                            }}
                            className={`dropdown-item ${item.parentProperty ? 'child-item' : ''}  ${
                                item.dependentProperties ? 'parent-item' : ''
                            }`}>
                            <Checkbox
                                label={item.text}
                                tabIndex={0}
                                data-testid={`dropdown-checkbox-test-${index}`}
                                checked={isChecked(item.key as string)}
                                onChange={() =>
                                    onChange(
                                        item.key as string,
                                        item.dependentProperties as string[],
                                        item.parentProperty as string
                                    )
                                }
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
const useDropDownDismiss = (ref: any, onToggleShow: Function, filterPanelDisabled: boolean) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, filterPanelDisabled]);
};
export default RequestTypeFilter;
