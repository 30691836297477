import React, { useEffect, useRef, useState } from 'react';
import '../SummaryCard.scss';
import useStore from 'app/store';
import {
    Text,
    Link,
    createTheme,
    Separator,
    IIconProps,
    IconButton,
    FontIcon,
    IContextualMenuProps,
    Shimmer
} from '@fluentui/react';
import { IListFolder, IListQuery } from 'app/models/common/post';
import { useHistory } from 'react-router-dom';
import { useGetUserSavedLists, useListSummary } from 'app/services/queries';
import ListBuilderCardFolder from './ListBuilderCardFolder';

interface IProps {
    stateData: any;
    cardExpanded: boolean;
    setCardExpanded: Function;
}

const ListBuilderCard: React.FC<IProps> = ({ stateData, cardExpanded, setCardExpanded }) => {
    const cardRef = useRef(null);
    const cardBodyRef = useRef(null);

    const history = useHistory();

    const myTheme = createTheme({
        fonts: {
            medium: {
                fontFamily: 'Monaco, Menlo, Consolas',
                fontSize: '20px'
            }
        },
        palette: {
            neutralLighter: '#C7E0F4'
        }
    });

    const kebabIcon: IIconProps = { iconName: 'MoreVertical' };

    const onMenuItemClick = (ev: any, item: any) => {
        if (item.key === 'newList') {
            history.push('/list-builder');
        } else {
            history.push('/manage-lists');
        }
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'newList',
                text: 'New List'
            },
            {
                key: 'manageLists',
                text: 'Manage Lists'
            }
        ],
        isBeakVisible: false,
        onItemClick: onMenuItemClick
    };

    // state
    const [folders, setFolders] = useState<IListFolder[]>([]);
    const [listQueries, setListQueries] = useState<IListQuery[]>([]);
    const filterPanelDisabled = useStore((state: any) => state.filterPanelDisabled);

    // mutations and queries
    const { data: savedLists, isLoading: isUserListsLoading } = useGetUserSavedLists();
    const { data: listSummary, isLoading: isListSummaryLoading } = useListSummary(listQueries);

    useEffect(() => {
        if (savedLists) {
            savedLists.listFolders.forEach((folder: IListFolder) => {
                folder.savedListQueries.forEach((query) => {
                    listQueries.push(query);
                });
            });
            setListQueries(listQueries);
            setFolders(savedLists.listFolders);
        }
    }, [savedLists]);

    const onToggleShow = () => {
        setCardExpanded(false);
    };

    useExpandDismiss(cardRef, onToggleShow);

    return (
        <div className={`lb-card ${cardExpanded ? 'card-expanded' : ''}`} ref={cardRef}>
            <div className="lb-card-header">
                <div className="lb-card-header-text">
                    <Text variant="xLarge">
                        LISTS {filterPanelDisabled && <FontIcon iconName="LocationDot" className="lb-card-active-icon" />}
                    </Text>
                    <IconButton className="lb-card-menu" iconProps={kebabIcon} menuProps={menuProps} />
                </div>
                <Separator theme={myTheme} />
            </div>
            <div className={`lb-card-body ${cardExpanded ? 'card-expanded' : ''}`} ref={cardBodyRef}>
                {isUserListsLoading || isListSummaryLoading ? (
                    <div className="lb-card-body-loading">
                        <Shimmer />
                        <Shimmer width="75%" />
                        <Shimmer width="50%" />
                    </div>
                ) : folders.length > 0 ? (
                    folders.map((folder) => (
                        <ListBuilderCardFolder folderData={folder} stateData={stateData} listSummary={listSummary} />
                    ))
                ) : (
                    <div className={'lb-card-no-items'}>
                        <Text block variant="large" className="lb-card-welcome">
                            Welcome!
                        </Text>
                        <Text block>
                            You haven't created any Lists yet. Visit{' '}
                            <Link href="/admin-training" underline={true}>
                                Portal Training
                            </Link>{' '}
                            to learn more or click{' '}
                            <Link href="/list-builder" underline={true}>
                                here
                            </Link>{' '}
                            to build your first list
                        </Text>
                    </div>
                )}
            </div>
            {folders.length > 0 && !isUserListsLoading && cardBodyRef?.current?.clientHeight >= 200 && (
                <div className="lb-card-footer" onClick={() => setCardExpanded(!cardExpanded)}>
                    <Text>{!cardExpanded ? 'Expand' : 'Collapse'}</Text>
                    <FontIcon className="lb-card-footer-icon" iconName={!cardExpanded ? 'ChevronDown' : 'ChevronUp'} />{' '}
                </div>
            )}
        </div>
    );
};

export default ListBuilderCard;

const useExpandDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
