import React, { useEffect } from 'react';
import ContactDetails from 'app/modules/admin/contactDetails/ContactDetails';
import { useHistory } from 'react-router-dom';
import useStore from 'app/store';

const TeamContactDetails: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history.location.pathname);
    }, []);
    return (
        <>
            <ContactDetails />
        </>
    );
};

export default TeamContactDetails;
