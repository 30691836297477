import { BlobStorageHelper, StringConstants } from './constants';
import { AllCategoriesSvg,
    DefaultCategorySvg,
    EventsCategorySvg,
    IndustryCategoriesSvg,
    PlaceholderCategoriesSvg,
    ProductCategorySvg,
    ProgramCategorySvg,
    SalesRegionCategorySvg,
    SellWinsCategorySvg,
    TeamCategorySvg } from './categoryIconSvgComponents';

// static/icons
import DevicesDefaultIcon   from '../static/icons/devices_default_icon.svg';

// site-content/images - device categories icons
import AccessoriesIcon      from '../static/static-content/images/devices/Accessories.svg';
import SurfaceAudioIcon     from '../static/static-content/images/devices/SurfaceAudio.svg';
import SurfaceBookIcon      from '../static/static-content/images/devices/SurfaceBook.svg';
import SurfaceDockIcon      from '../static/static-content/images/devices/SurfaceDock.svg';
import SurfaceDuoIcon       from '../static/static-content/images/devices/SurfaceDuo.svg';
import SurfaceGoIcon        from '../static/static-content/images/devices/SurfaceGo.svg';
import SurfaceHubIcon       from '../static/static-content/images/devices/SurfaceHub.svg';
import SurfaceLaptopIcon    from '../static/static-content/images/devices/SurfaceLaptop.svg';
import SurfaceProIcon       from '../static/static-content/images/devices/SurfacePro.svg';
import SurfaceStudioIcon    from '../static/static-content/images/devices/SurfaceStudio.svg';
import SurfaceTypeCoverIcon from '../static/static-content/images/devices/SurfaceTypeCover.svg';
import SurfaceOthersIcon    from '../static/static-content/images/devices/Others.svg';

// article category banners (jpg)
import EventsBanner         from '../static/static-content/images/articles/banners/category-banner-Events.jpg';
import IndustryBanner       from '../static/static-content/images/articles/banners/category-banner-Industry.jpg';
import ProductBanner        from '../static/static-content/images/articles/banners/category-banner-Product.jpg';
import ProgramBanner        from '../static/static-content/images/articles/banners/category-banner-Program.jpg';
import SalesRegionBanner    from '../static/static-content/images/articles/banners/category-banner-SalesRegion.jpg';
import SellerWinsBanner     from '../static/static-content/images/articles/banners/category-banner-SellerWins.jpg';

// article category icons (svg)
import AllCategoriesIcon    from '../static/static-content/images/articles/icons/category-icon-AllCategories.svg';
import EventsIcon           from '../static/static-content/images/articles/icons/category-icon-Events.svg';
import IndustryIcon         from '../static/static-content/images/articles/icons/category-icon-Industry.svg';
import PlaceholderIcon      from '../static/static-content/images/articles/icons/category-icon-Placeholder.svg';
import ProductIcon          from '../static/static-content/images/articles/icons/category-icon-Product.svg';
import ProgramIcon          from '../static/static-content/images/articles/icons/category-icon-Program.svg';
import SalesRegionIcon      from '../static/static-content/images/articles/icons/category-icon-SalesRegion.svg';
import SellerWinsIcon       from '../static/static-content/images/articles/icons/category-icon-SellerWins.svg';
import TeamIcon             from '../static/static-content/images/articles/icons/category-icon-Team.svg';

// Url base path for DeviceCategories in cosmos
const deviceCategoryBaseUrl = '/static-content/images';
class DevicesCategoryIconUrlPath {
    static readonly ACCESORIES         = `${deviceCategoryBaseUrl}/Accessories.svg`;
    static readonly SURFACE_AUDIO      = `${deviceCategoryBaseUrl}/SurfaceAudio.svg`;
    static readonly SURFACE_BOOK       = `${deviceCategoryBaseUrl}/SurfaceBook.svg`;
    static readonly SURFACE_DOCK       = `${deviceCategoryBaseUrl}/SurfaceDock.svg`;
    static readonly SURFACE_DUO        = `${deviceCategoryBaseUrl}/SurfaceDuo.svg`;
    static readonly SURFACE_GO         = `${deviceCategoryBaseUrl}/SurfaceGo.svg`;
    static readonly SURFACE_HUB        = `${deviceCategoryBaseUrl}/SurfaceHub.svg`;
    static readonly SURFACE_LAPTOP     = `${deviceCategoryBaseUrl}/SurfaceLaptop.svg`;
    static readonly SURFACE_PRO        = `${deviceCategoryBaseUrl}/SurfacePro.svg`;
    static readonly SURFACE_STUDIO     = `${deviceCategoryBaseUrl}/SurfaceStudio.svg`;
    static readonly SURFACE_TYPE_COVER = `${deviceCategoryBaseUrl}/SurfaceTypeCover.svg`;
    static readonly SURFACE_OTHERS     = `${deviceCategoryBaseUrl}/Others.svg`;
}

// URL base path for ArticleCategories in cosmos
const articleCategoryBaseUrl = `${BlobStorageHelper.STORAGE_BASE_URL}/static-content/images/articles`;

class ArticleCategoryBannerUrlPath {
    static readonly  EVENTS         = `${articleCategoryBaseUrl}/category-banner-Events.jpg`;
    static readonly  INDUSTRY       = `${articleCategoryBaseUrl}/category-banner-Industry.jpg`;
    static readonly  PRODUCT        = `${articleCategoryBaseUrl}/category-banner-Product.jpg`;
    static readonly  PROGRAM        = `${articleCategoryBaseUrl}/category-banner-Program.jpg`;
    static readonly  SALES_REGION   = `${articleCategoryBaseUrl}/category-banner-SalesRegion.jpg`;
    static readonly  SELLER_WINS    = `${articleCategoryBaseUrl}/category-banner-SellerWins.jpg`;
}

class ArticleCategoryIconUrlPath {
    static readonly  ALL_CATEGORIES = `${articleCategoryBaseUrl}/category-icon-AllCategories.svg`;
    static readonly  EVENTS         = `${articleCategoryBaseUrl}/category-icon-Events.svg`;
    static readonly  INDUSTRY       = `${articleCategoryBaseUrl}/category-icon-Industry.svg`;
    static readonly  PLACEHOLDER    = `${articleCategoryBaseUrl}/category-icon-Placeholder.svg`;
    static readonly  PRODUCT        = `${articleCategoryBaseUrl}/category-icon-Product.svg`;
    static readonly  PROGRAM        = `${articleCategoryBaseUrl}/category-icon-Program.svg`;
    static readonly  SALES_REGION   = `${articleCategoryBaseUrl}/category-icon-SalesRegion.svg`;
    static readonly  SELLER_WINS    = `${articleCategoryBaseUrl}/category-icon-SellerWins.svg`;
    static readonly  TEAM           = `${articleCategoryBaseUrl}/category-icon-Team.svg`;
}

/**
 * Use to replace device icon URLs from storage with a local file path
 * @param iconUrl
 * @returns path to icon located in `src/app/static/static-content/images/devices`
 */
export function getDeviceIcon(iconUrl: string = '') {
    switch (iconUrl) {
        case DevicesCategoryIconUrlPath.ACCESORIES:
            return AccessoriesIcon;
        case DevicesCategoryIconUrlPath.SURFACE_AUDIO:
            return SurfaceAudioIcon;
        case DevicesCategoryIconUrlPath.SURFACE_BOOK:
            return SurfaceBookIcon;
        case DevicesCategoryIconUrlPath.SURFACE_DOCK:
            return SurfaceDockIcon;
        case DevicesCategoryIconUrlPath.SURFACE_DUO:
            return SurfaceDuoIcon;
        case DevicesCategoryIconUrlPath.SURFACE_GO:
            return SurfaceGoIcon;
        case DevicesCategoryIconUrlPath.SURFACE_HUB:
            return SurfaceHubIcon;
        case DevicesCategoryIconUrlPath.SURFACE_LAPTOP:
            return SurfaceLaptopIcon;
        case DevicesCategoryIconUrlPath.SURFACE_PRO:
            return SurfaceProIcon;
        case DevicesCategoryIconUrlPath.SURFACE_STUDIO:
            return SurfaceStudioIcon;
        case DevicesCategoryIconUrlPath.SURFACE_TYPE_COVER:
            return SurfaceTypeCoverIcon;
        case DevicesCategoryIconUrlPath.SURFACE_OTHERS:
            return SurfaceOthersIcon;
        default:
            return DevicesDefaultIcon;
    }
}

/**
 * Use to replace article banner URLs from storage with a local file path
 * @param bannerUrl
 * @returns path to icon located in `src/app/static/static-content/images/articles/banners`
 */
export function getArticleBanner(bannerUrl: string) {
    switch (bannerUrl) {
        case ArticleCategoryBannerUrlPath.EVENTS:
            return EventsBanner;
        case ArticleCategoryBannerUrlPath.INDUSTRY:
            return IndustryBanner;
        case ArticleCategoryBannerUrlPath.PRODUCT:
            return ProductBanner;
        case ArticleCategoryBannerUrlPath.PROGRAM:
            return ProgramBanner;
        case ArticleCategoryBannerUrlPath.SALES_REGION:
            return SalesRegionBanner;
        case ArticleCategoryBannerUrlPath.SELLER_WINS:
            return SellerWinsBanner;
        default:
            break;
    }
}

/**
 * Use to replace article icon URLs from storage with a local file path
 * @param iconUrl
 * @returns path to icon located in '/src/app/static/static-content/images/articles/icons'
 */
export function getArticleCategorySvg(iconUrl: string) {
    switch (iconUrl) {
        case ArticleCategoryIconUrlPath.ALL_CATEGORIES:
            return AllCategoriesIcon;
        case ArticleCategoryIconUrlPath.EVENTS:
            return EventsIcon;
        case ArticleCategoryIconUrlPath.INDUSTRY:
            return IndustryIcon;
        case ArticleCategoryIconUrlPath.PLACEHOLDER:
            return PlaceholderIcon;
        case ArticleCategoryIconUrlPath.PRODUCT:
            return ProductIcon;
        case ArticleCategoryIconUrlPath.PROGRAM:
            return ProgramIcon;
        case ArticleCategoryIconUrlPath.SALES_REGION:
            return SalesRegionIcon;
        case ArticleCategoryIconUrlPath.SELLER_WINS:
            return SellerWinsIcon;
        case ArticleCategoryIconUrlPath.TEAM:
            return TeamIcon;
        default:
            return StringConstants.EMPTY_STRING;
    }
}

export function getArticleCategorySvgComponent(iconUrl: string) {
    switch (iconUrl) {
        case ArticleCategoryIconUrlPath.ALL_CATEGORIES:
            return AllCategoriesSvg;
        case ArticleCategoryIconUrlPath.EVENTS:
            return EventsCategorySvg;
        case ArticleCategoryIconUrlPath.INDUSTRY:
            return IndustryCategoriesSvg;
        case ArticleCategoryIconUrlPath.PLACEHOLDER:
            return PlaceholderCategoriesSvg;
        case ArticleCategoryIconUrlPath.PRODUCT:
            return ProductCategorySvg;
        case ArticleCategoryIconUrlPath.PROGRAM:
            return ProgramCategorySvg;
        case ArticleCategoryIconUrlPath.SALES_REGION:
            return SalesRegionCategorySvg;
        case ArticleCategoryIconUrlPath.SELLER_WINS:
            return SellWinsCategorySvg;
        case ArticleCategoryIconUrlPath.TEAM:
            return TeamCategorySvg;
        default:
            return DefaultCategorySvg;
    }
}

/**
 * Use to find a device icon URL from a provided list
 * @param deviceList - list of devices
 * @param device - the device of the selected request
 * @returns a single device icon URL
 */
export function findDeviceIconUrl(deviceList: any, device: any) {
    let iconUrl = deviceList
                    ?.find((product: any) => product.id === device?.categoryId)
                    ?.devices?.find((model: any) => device?.id === model.id)
                    ?.iconUrl;

    return iconUrl;
}

export class SVGIcons {
    static readonly ICON_EDIT = `<svg width="13" height="13" viewBox="0 0 13 13" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path d="M11.6569 1.34311C12.4379 2.12416 12.4379 3.39049 11.6569 4.17154L4.27044 11.558C3.95004 11.8784 3.54859 12.1057 3.10901 12.2156L0.818012 12.7883C0.451822 12.8799 0.120125 12.5482 0.211673 12.182L0.784423 9.891C0.894318 9.45142 1.12161 9.04997 1.44201 8.72957L8.82847 1.34311C9.60952 0.562062 10.8759 0.562062 11.6569 1.34311ZM8.12129 3.46432L2.14912 9.43668C1.95688 9.62892 1.8205 9.86979 1.75457 10.1335L1.38393 11.6161L2.86648 11.2454C3.13022 11.1795 3.37109 11.0431 3.56333 10.8509L9.53529 4.87832L8.12129 3.46432ZM9.53558 2.05022L8.82829 2.75732L10.2423 4.17132L10.9498 3.46443C11.3403 3.07391 11.3403 2.44074 10.9498 2.05022C10.5593 1.65969 9.9261 1.65969 9.53558 2.05022Z"/></svg>`;
    static readonly ICON_BACK = `<svg width="24" height="24" viewBox="0 0 24 24" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path d="M10.2949 19.7162C10.6883 20.1038 11.3215 20.0991 11.7091 19.7057C12.0967 19.3123 12.092 18.6792 11.6986 18.2915L6.32827 13.0001H19.9995C20.5517 13.0001 20.9995 12.5524 20.9995 12.0001C20.9995 11.4479 20.5517 11.0001 19.9995 11.0001H6.33488L11.6986 5.71525C12.092 5.32763 12.0967 4.69448 11.7091 4.30108C11.3215 3.90767 10.6883 3.90298 10.2949 4.29061L3.37073 11.113C2.87382 11.6026 2.87382 12.4042 3.37073 12.8938L10.2949 19.7162Z" /></svg>`;
    static readonly ICON_DRAFT = `<svg width="11" height="11" viewBox="0 0 11 11" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path d="M6.54108 1.68622L9.31364 4.45878L3.86922 9.90315C3.71754 10.0548 3.52891 10.1643 3.32196 10.2207L0.519433 10.9851C0.212718 11.0687 -0.0687183 10.7873 0.0149313 10.4806L0.779256 7.67804C0.835697 7.47109 0.945168 7.28245 1.09685 7.13077L6.54108 1.68622ZM10.4258 0.574178C11.1914 1.33975 11.1914 2.58098 10.4258 3.34655L9.89434 3.87753L7.12178 1.10552L7.65344 0.574178C8.41902 -0.191393 9.66025 -0.191393 10.4258 0.574178Z" /></svg>`;
    static readonly ICON_REVIEW_REQUEST = `<svg width="15" height="15" viewBox="0 0 15 15" fill="#323130" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.52247 5.71289C4.06849 5.71289 2.88575 6.89563 2.88575 8.34961C2.88575 8.91815 3.0707 9.44171 3.37788 9.87278L0.817214 12.4334C0.645564 12.6051 0.645564 12.8832 0.817214 13.0549C0.988865 13.2265 1.26695 13.2265 1.43863 13.0549L3.99929 10.4942C4.43037 10.8014 4.95393 10.9863 5.52247 10.9863C6.97644 10.9863 8.15919 9.80358 8.15919 8.34961C8.15919 6.89563 6.97644 5.71289 5.52247 5.71289Z" />
                <path d="M12.9932 0C12.2378 0 11.6748 0.612832 11.6748 1.31836C11.6748 1.54688 11.7363 1.77536 11.8506 1.97754L12.2373 2.63672H4.64355C4.39743 2.63672 4.2041 2.83005 4.2041 3.07617V5.09766C4.60843 4.92188 5.05661 4.83398 5.52246 4.83398C7.46487 4.83398 9.03809 6.4072 9.03809 8.34961C9.03809 10.292 7.46487 11.8652 5.52246 11.8652C5.05661 11.8652 4.60843 11.7773 4.2041 11.6016V15H14.3115V1.31836C14.3115 0.588838 13.7227 0 12.9932 0ZM12.8613 10.415L11.1035 12.1728C10.9454 12.3486 10.6465 12.3487 10.4883 12.1728L9.60938 11.2939C9.43359 11.1269 9.43359 10.8457 9.60938 10.6787C9.77637 10.5029 10.0576 10.5029 10.2246 10.6787L10.7959 11.2412L12.2461 9.79978C12.4131 9.62402 12.6943 9.62402 12.8613 9.79978C13.0371 9.96677 13.0371 10.248 12.8613 10.415ZM12.8613 7.77829L11.1035 9.5361C10.9454 9.71188 10.6465 9.71194 10.4883 9.5361L9.60938 8.6572C9.43359 8.4902 9.43359 8.20895 9.60938 8.04196C9.77637 7.86621 10.0576 7.86621 10.2246 8.04196L10.7959 8.60446L12.2461 7.16306C12.4131 6.9873 12.6943 6.9873 12.8613 7.16306C13.0371 7.33005 13.0371 7.6113 12.8613 7.77829ZM12.8613 5.14157L11.1035 6.89938C10.9454 7.07517 10.6465 7.07522 10.4883 6.89938L9.60938 6.02048C9.43359 5.85349 9.43359 5.57224 9.60938 5.40524C9.77637 5.22949 10.0576 5.22949 10.2246 5.40524L10.7959 5.96774L12.2461 4.52634C12.4131 4.35059 12.6943 4.35059 12.8613 4.52634C13.0371 4.69333 13.0371 4.97458 12.8613 5.14157Z" />
                <path d="M10.7959 1.31836C10.7959 1.46774 10.8135 1.60837 10.8399 1.75781H7.29791C8.21194 1.45895 9.39846 1.07224 10.9278 0.580048C10.8399 0.808563 10.7959 1.06345 10.7959 1.31836Z" />
                <path d="M7.71427 8.30358C7.71427 9.51663 6.73089 10.5217 5.51784 10.5217C4.30479 10.5217 3.32141 9.51663 3.32141 8.30358C3.32141 7.09052 4.30479 6.10715 5.51784 6.10715C6.73089 6.10715 7.71427 7.09052 7.71427 8.30358Z" fill="white" />
            </svg>`;
    static readonly ICON_REMOVE = `<svg width="13" height="13" viewBox="0 0 13 13" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.36364C0 1.07034 1.07034 0 2.36364 0H10.6364C11.9297 0 13 1.07035 13 2.36364V10.6364C13 11.9296 11.9297 13 10.6364 13H2.36364C1.07034 13 0 11.9296 0 10.6364V2.36364ZM9.81866 3.18071C10.0494 3.41147 10.0494 3.78562 9.81866 4.01638L7.33566 6.49935L9.81866 8.98235C10.0494 9.2131 10.0494 9.58726 9.81866 9.81801C9.58785 10.0488 9.21375 10.0488 8.98294 9.81801L6.5 7.33501L4.01699 9.81801C3.78623 10.0488 3.41209 10.0488 3.18132 9.81801C2.95056 9.58726 2.95056 9.2131 3.18132 8.98235L5.66434 6.49935L3.18132 4.01638C2.95056 3.78562 2.95056 3.41147 3.18132 3.18071C3.41209 2.94994 3.78623 2.94994 4.01699 3.18071L6.5 5.66369L8.98294 3.18071C9.21375 2.94994 9.58785 2.94994 9.81866 3.18071Z"></path></svg>`;
    static readonly ICON_SCHEDULED_TO_PUBLISH = `<svg width="14" height="14" viewBox="0 0 14 14" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path d="M10.325 0C11.5814 0 12.6 1.01855 12.6 2.275V2.8H0V2.275C0 1.01855 1.01855 0 2.275 0H10.325ZM12.6 3.85V6.31527C11.8928 5.8625 11.052 5.6 10.15 5.6C7.6371 5.6 5.6 7.6371 5.6 10.15C5.6 11.052 5.8625 11.8928 6.31527 12.6H2.275C1.01855 12.6 0 11.5814 0 10.325V3.85H12.6ZM14 10.15C14 8.0237 12.2763 6.3 10.15 6.3C8.0237 6.3 6.3 8.0237 6.3 10.15C6.3 12.2763 8.0237 14 10.15 14C12.2763 14 14 12.2763 14 10.15ZM10.15 10.15H11.55C11.7433 10.15 11.9 10.3067 11.9 10.5C11.9 10.6933 11.7433 10.85 11.55 10.85H9.8C9.6067 10.85 9.45 10.6933 9.45 10.5L9.45 10.499L9.45 8.39995C9.45 8.20665 9.6067 8.04995 9.8 8.04995C9.9933 8.04995 10.15 8.20665 10.15 8.39995L10.15 10.15Z" /></svg>`;
    static readonly ICON_PUBLISH = `<svg width="14" height="14" viewBox="0 0 14 14" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path d="M5.23904 1.90747C5.00026 2.48735 4.80063 3.17355 4.65362 3.9375H9.3464C9.1994 3.17355 8.99972 2.48735 8.76094 1.90747C8.49676 1.26583 8.19324 0.775373 7.87858 0.452156C7.56527 0.130324 7.26853 0 7 0C6.73147 0 6.43473 0.130324 6.12142 0.452156C5.80676 0.775373 5.50324 1.26583 5.23904 1.90747ZM5.15421 0.245914C4.87981 0.61884 4.63781 1.06946 4.42994 1.57432C4.14929 2.25587 3.92276 3.05601 3.76355 3.9375H0.703704C1.57749 2.1444 3.19469 0.780172 5.15421 0.245914ZM8.84581 0.245915C9.12021 0.618842 9.36215 1.06947 9.57005 1.57432C9.85066 2.25587 10.0773 3.05601 10.2365 3.9375H13.2963C12.4226 2.1444 10.8053 0.780174 8.84581 0.245915ZM13.6514 4.8125H10.3688C10.4544 5.50804 10.5 6.24239 10.5 7C10.5 7.75761 10.4544 8.49196 10.3688 9.1875H13.6514C13.8776 8.49927 14 7.76393 14 7C14 6.23607 13.8776 5.50073 13.6514 4.8125ZM13.2963 10.0625H10.2365C10.0773 10.944 9.85066 11.7442 9.57005 12.4257C9.36215 12.9306 9.12021 13.3812 8.84581 13.7541C10.8053 13.2198 12.4226 11.8556 13.2963 10.0625ZM7 14C7.26853 14 7.56527 13.8697 7.87858 13.5478C8.19324 13.2247 8.49676 12.7341 8.76094 12.0925C8.99972 11.5126 9.1994 10.8265 9.3464 10.0625H4.65362C4.80063 10.8265 5.00026 11.5126 5.23904 12.0925C5.50324 12.7341 5.80676 13.2247 6.12142 13.5478C6.43473 13.8697 6.73147 14 7 14ZM5.15421 13.7541C3.19469 13.2198 1.57749 11.8556 0.703704 10.0625H3.76355C3.92276 10.944 4.14929 11.7442 4.42994 12.4257C4.63781 12.9306 4.87981 13.3812 5.15421 13.7541ZM0.348582 9.1875H3.63122C3.54559 8.49196 3.5 7.75761 3.5 7C3.5 6.24239 3.54559 5.50804 3.63122 4.8125H0.348582C0.122371 5.50073 0 6.23607 0 7C0 7.76393 0.122371 8.49927 0.348582 9.1875ZM4.375 7C4.375 6.23464 4.42374 5.4998 4.51319 4.8125H9.48684C9.57626 5.4998 9.625 6.23464 9.625 7C9.625 7.76536 9.57626 8.5002 9.48684 9.1875H4.51319C4.42374 8.5002 4.375 7.76536 4.375 7Z" /></svg>`;
    static readonly ICON_EYE = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="#323130"><path d="M0.984442 4.62471L0.983464 4.62815C0.912505 4.8948 0.638789 5.05404 0.372018 4.9833C-0.0590217 4.86907 0.0168651 4.37186 0.0168651 4.37186L0.0345297 4.31047C0.0345297 4.31047 0.0606335 4.22636 0.0816627 4.1653C0.123686 4.04329 0.187952 3.87274 0.279309 3.66977C0.461538 3.26493 0.754431 2.72477 1.19877 2.18295C2.09629 1.08851 3.60509 0 6.00017 0C8.39524 0 9.90404 1.08851 10.8016 2.18295C11.2459 2.72477 11.5388 3.26493 11.721 3.66977C11.8124 3.87274 11.8766 4.04329 11.9187 4.1653C11.9397 4.22636 11.9552 4.27541 11.9658 4.31047C11.9711 4.328 11.9752 4.34204 11.9781 4.35236L11.9816 4.365L11.9827 4.36916L11.9832 4.37069L11.9835 4.37186C12.0542 4.63878 11.8952 4.91253 11.6283 4.9833C11.3618 5.05397 11.0885 4.89556 11.0172 4.62937L11.0169 4.62815L11.0159 4.62471L11.0085 4.5997C11.0014 4.57616 10.9898 4.53927 10.9732 4.49095C10.9399 4.39422 10.8866 4.25227 10.8091 4.08023C10.6538 3.73508 10.4041 3.27523 10.0283 2.81706C9.28569 1.9115 8.0445 1 6.00017 1C3.95584 1 2.71464 1.9115 1.97201 2.81706C1.59627 3.27523 1.34655 3.73508 1.19119 4.08023C1.11375 4.25227 1.06047 4.39422 1.02715 4.49095C1.01051 4.53927 0.998902 4.57616 0.991789 4.5997L0.984442 4.62471ZM6.00024 3C4.61953 3 3.50024 4.11929 3.50024 5.5C3.50024 6.88071 4.61953 8 6.00024 8C7.38096 8 8.50024 6.88071 8.50024 5.5C8.50024 4.11929 7.38096 3 6.00024 3ZM4.50024 5.5C4.50024 4.67157 5.17182 4 6.00024 4C6.82867 4 7.50024 4.67157 7.50024 5.5C7.50024 6.32843 6.82867 7 6.00024 7C5.17182 7 4.50024 6.32843 4.50024 5.5Z" /></svg>`;
    static readonly ICON_UPLOAD = `<svg width="16" height="21" viewBox="0 0 16 21" fill="#323130" xmlns="http://www.w3.org/2000/svg"><path d="M1.25 2.49561H14.7477C15.1619 2.49561 15.4977 2.15982 15.4977 1.74561C15.4977 1.36591 15.2155 1.05211 14.8494 1.00245L14.7477 0.995605H1.25C0.835786 0.995605 0.5 1.33139 0.5 1.74561C0.5 2.1253 0.782154 2.4391 1.14823 2.48876L1.25 2.49561ZM7.88338 20.9933L8 21C8.51284 21 8.93551 20.614 8.99327 20.1166L9 20V7.41001L12.2941 10.7028C12.6546 11.0632 13.2218 11.0909 13.6141 10.7858L13.7083 10.7026C14.0687 10.3421 14.0964 9.77488 13.7913 9.38262L13.7081 9.28843L8.71048 4.29181C8.35016 3.93157 7.78331 3.9037 7.39103 4.20832L7.29682 4.2914L2.29338 9.28802C1.90259 9.67827 1.90215 10.3114 2.29241 10.7022C2.65265 11.063 3.21986 11.0911 3.61236 10.7863L3.70662 10.7032L7 7.41501V20C7 20.5128 7.38604 20.9355 7.88338 20.9933Z" /></svg>`;
    static readonly ICON_TRASH = `<svg width="16" height="17" viewBox="0 0 16 17" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"><path d="M7.75 0C9.18458 0 10.3626 1.09848 10.4888 2.50019L14.75 2.5C15.1642 2.5 15.5 2.83579 15.5 3.25C15.5 3.6297 15.2178 3.94349 14.8518 3.99315L14.75 4H14.167L12.8999 14.9792C12.7835 15.9884 11.929 16.75 10.9131 16.75H4.58688C3.571 16.75 2.7165 15.9884 2.60006 14.9792L1.332 4H0.75C0.370304 4 0.0565091 3.71785 0.00684667 3.35177L0 3.25C0 2.8703 0.282154 2.55651 0.648229 2.50685L0.75 2.5L5.01119 2.50019C5.13741 1.09848 6.31542 0 7.75 0ZM6.25 6.25C6.00454 6.25 5.80039 6.40477 5.75806 6.60886L5.75 6.6875V12.8125L5.75806 12.8911C5.80039 13.0952 6.00454 13.25 6.25 13.25C6.49546 13.25 6.69961 13.0952 6.74194 12.8911L6.75 12.8125V6.6875L6.74194 6.60886C6.69961 6.40477 6.49546 6.25 6.25 6.25ZM9.25 6.25C9.00454 6.25 8.80039 6.40477 8.75806 6.60886L8.75 6.6875V12.8125L8.75806 12.8911C8.80039 13.0952 9.00454 13.25 9.25 13.25C9.49546 13.25 9.69961 13.0952 9.74194 12.8911L9.75 12.8125V6.6875L9.74194 6.60886C9.69961 6.40477 9.49546 6.25 9.25 6.25ZM7.75 1.5C7.14524 1.5 6.64079 1.92947 6.52499 2.50005H8.97501C8.85921 1.92947 8.35476 1.5 7.75 1.5Z" /></svg>`;
    static readonly ICON_LEFT_ARROW = `<svg width="13" height="8" viewBox="0 0 13 8" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M4.6631 0.243389L4.60492 0.175995C4.39159 -0.0373321 4.05777 -0.0567257 3.82253 0.117815L3.75514 0.175995L0.356026 3.57511C0.142698 3.78844 0.123304 4.12226 0.297845 4.3575L0.356026 4.42489L3.75514 7.824C3.9898 8.05866 4.37026 8.05866 4.60492 7.824C4.81825 7.61068 4.83764 7.27685 4.6631 7.04162L4.60492 6.97423L2.23208 4.60037L12.3942 4.60088C12.6984 4.60088 12.9498 4.37483 12.9896 4.08154L12.9951 4C12.9951 3.6958 12.7691 3.44439 12.4758 3.4046L12.3942 3.39912L2.23208 3.3986L4.60492 1.02577C4.81825 0.812446 4.83764 0.478624 4.6631 0.243389L4.60492 0.175995L4.6631 0.243389Z" /></svg>`;
    static readonly ICON_TWITTER = `<svg width="13" height="10" viewBox="0 0 13 10" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M12.2888 1.1828C11.828 1.38249 11.3518 1.52074 10.8449 1.58218C11.3671 1.27496 11.7665 0.78341 11.9508 0.184332C11.4593 0.476191 10.9217 0.675883 10.3533 0.798771C9.89247 0.30722 9.23195 0 8.50999 0C7.11214 0 5.99078 1.13671 5.99078 2.5192C5.99078 2.71889 6.00615 2.90323 6.05223 3.08756C3.96313 2.99539 2.11982 1.98157 0.875576 0.46083C-0.0153609 2.05837 0.983103 3.37942 1.64363 3.82489C1.24424 3.82489 0.844854 3.702 0.506913 3.51767C0.506913 4.76191 1.38249 5.79109 2.5192 6.02151C2.27343 6.09831 1.72043 6.14439 1.38249 6.06759C1.70507 7.06605 2.64209 7.80338 3.73272 7.81874C2.8725 8.49462 1.6129 9.03226 0 8.86329C1.12135 9.58525 2.4424 10 3.87097 10C8.50998 10 11.0292 6.15975 11.0292 2.84178C11.0292 2.73426 11.0292 2.62673 11.0138 2.5192C11.5361 2.13518 11.9662 1.68971 12.2888 1.1828Z" /></svg>`;
    static readonly ICON_LINKED_IN = `<svg width="10" height="10" viewBox="0 0 10 10" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M9 0H1C0.45 0 0 0.45 0 1V9C0 9.55 0.45 10 1 10H9C9.55 10 10 9.55 10 9V1C10 0.45 9.55 0 9 0ZM3 8.5H1.5V4H3V8.5ZM2.25 3.15C1.75 3.15 1.35 2.75 1.35 2.25C1.35 1.75 1.75 1.35 2.25 1.35C2.75 1.35 3.15 1.75 3.15 2.25C3.15 2.75 2.75 3.15 2.25 3.15ZM8.5 8.5H7V5.85C7 5.45002 6.65 5.1 6.25 5.1C5.85 5.1 5.5 5.45002 5.5 5.85V8.5H4V4H5.5V4.6C5.75 4.2 6.3 3.9 6.75 3.9C7.7 3.9 8.5 4.7 8.5 5.65V8.5Z" /></svg>`;
    static readonly ICON_EMAIL =    `<svg width="15" height="10" viewBox="0 0 15 10" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.097 0.714478L9.78369 5.00001L14.097 9.28553C14.175 9.12256 14.2223 8.9424 14.2223 8.75001V1.25001C14.2223 1.05759 14.175 0.87745 14.097 0.714478Z" />
                                    <path d="M12.9721 0H1.24988C1.05747 0 0.877328 0.0473056 0.714355 0.125278L6.22722 5.61036C6.71466 6.09781 7.50733 6.09781 7.99477 5.61036L13.5076 0.125278C13.3447 0.0473056 13.1645 0 12.9721 0Z" />
                                    <path d="M0.125278 0.714478C0.0473056 0.87745 0 1.05759 0 1.25001V8.75001C0 8.94242 0.0473056 9.12259 0.125278 9.28553L4.43858 5.00001L0.125278 0.714478Z" />
                                    <path d="M9.19433 5.58923L8.58397 6.19959C7.77177 7.01179 6.45019 7.01179 5.63799 6.19959L5.02766 5.58923L0.714355 9.87476C0.877328 9.95273 1.05747 10 1.24988 10H12.9721C13.1645 10 13.3447 9.95273 13.5076 9.87476L9.19433 5.58923Z" />
                                    </svg>`;
    static readonly ICON_DEFAULT = `<svg width="20" height="20" viewBox="0 0 20 20" fill="#212121" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"/></svg>`;
}
