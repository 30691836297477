import React, { useEffect, useState } from 'react';
import { SpinnerSize, Spinner, Text, PrimaryButton, Modal } from '@fluentui/react';
import { useHistory, Link } from 'react-router-dom';
import DetailsPanel from 'app/modules/user/detailsPanel/DetailsPanel';
import { IRequest } from 'app/models/common/response';
import { useParams } from 'react-router-dom';
import './index.scss';
import { Trans } from 'react-i18next';
import { useRequestTypesLookUpData, useGetRequestById, useTeamLookUpData } from 'app/services/queries';
import { useBoolean } from '@fluentui/react-hooks';
import { isAdmin, isSuperAdmin, isUser } from 'app/utils/authUtilities';
import { IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';

interface IParams {
    id: string;
}

/**
 * This needed to be fetched from API. Temporary variable
 */
const statusTypes = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Active' },
    { id: 3, name: 'In Progress' },
    { id: 4, name: 'Resolved' },
    { id: 5, name: 'Closed' }
];

const RequestDetails = (props: any) => {
    let history = useHistory();
    //set variables
    const [selectedRequest, setSelectedRequest] = useState<IRequest>({});
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    // skp id from url
    const { id: requestId } = useParams<IParams>();

    // queries
    const {
        data: apiRequestData,
        isLoading: isLoadingRequestList,
        isError: isErrorRequestDetails,
        isSuccess: isSuccessRequestDetails
    } = useGetRequestById(requestId);
    const { data: requestTypes } = useRequestTypesLookUpData();
    const { data: teams } = useTeamLookUpData();
    useEffect(() => {
        if (!isLoadingRequestList) {
            setSelectedRequest(apiRequestData?.data);
        }
    }, [apiRequestData?.data, isLoadingRequestList, selectedRequest]);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    useEffect(() => {
        if (!isLoadingRequestList && apiRequestData?.data) {
            if ((isUser(userDetails?.userRoles) && apiRequestData?.data.isHidden) || apiRequestData?.data.isHiddenRequest || apiRequestData?.data.isParent) {
                showModal();
            }
        }
    }, [isLoadingRequestList]);

    return (
        <div className="container request-details-container">
            {isLoadingRequestList && (
                <div className="m-t-50 loader-container">
                    <Spinner label="Search results are loading. Please wait..." size={SpinnerSize.large} />
                </div>
            )}
            {<div></div>}
            <Modal className="hidden-request-modal" isOpen={isModalOpen} onDismiss={hideModal} isBlocking={true}>
                {apiRequestData?.data.isParent ? (
                    <Text className="modal-content">
                        The request {apiRequestData?.data.unifiedTrackingId} cannot be viewed as a non-admin. Please try searching by the ticket title.
                    </Text>
                ) : (
                    <Text className="modal-content">
                        Your request has been hidden by the administrator. If you have a concern, please log it in the feedback
                        window.
                    </Text>
                )}

                <div className="modal-footer">
                    <Link to={apiRequestData?.data.isParent ? '/unified-dashboard' : '/submit-feedback'}>
                        <PrimaryButton text="OK" />{' '}
                    </Link>
                </div>
            </Modal>
            {!isLoadingRequestList &&
            (isSuccessRequestDetails || isErrorRequestDetails) &&
            (isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles) || !apiRequestData?.data.isHidden) &&
            !apiRequestData?.data.isHiddenRequest ? (
                <div>
                    {!isLoadingRequestList && (
                        <p>
                            <Link role="link" to="" tabIndex={0} onClick={() => history.goBack()} className="back-request">
                                <span className="arrow-icon"></span>

                                {!isLoadingRequestList && isSuccessRequestDetails && apiRequestData?.data.summary}
                            </Link>
                        </p>
                    )}
                    <div className="unified-dashboard-container ">
                        {!isLoadingRequestList && isSuccessRequestDetails && !apiRequestData?.data.isParent && (
                            <DetailsPanel
                                teams={teams}
                                selectedRequest={selectedRequest}
                                requestTypes={requestTypes}
                                statusTypes={statusTypes}
                                setSelectedRequest={setSelectedRequest}
                                pageType="DETAILS_PAGE"
                            />
                        )}
                        {!isLoadingRequestList && (isErrorRequestDetails || apiRequestData?.data.isParent) && (
                            <div className="no-results-wrap">
                                <Trans>The requested details are not available</Trans>.
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="dummy-space"></div>
            )}
        </div>
    );
};

export default RequestDetails;
