import React, { useEffect } from 'react';
import { Modal, IconButton, IIconProps, Text, DefaultButton, TextField, ComboBox, IComboBoxOption } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import './index.scss';
import { Trans, useTranslation } from 'react-i18next';
import { keyPress } from 'app/components/helpers';

const titleId = 'add-customer-modal';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IProps {
    modalState: boolean;
    updateModalState: any;
}

const options: IComboBoxOption[] = [
    { key: 'menu_1', text: 'United States' },
    { key: 'menu_2', text: 'India' }
];
const AddCustomerModal: React.FC<IProps> = (props) => {
    const { t: translate } = useTranslation();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(props.modalState);

    useEffect(() => {
        const toggleModal = (modalState: boolean) => {
            if (modalState) {
                showModal();
            } else {
                hideModal();
            }
        };
        toggleModal(props.modalState);
    }, [props.modalState, showModal, hideModal]);

    const handleModalDismiss = () => {
        props.updateModalState(false);
    };
    return (
        <>
            {hideModal && (
                <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName="add-customer-modal">
                    <div className="modal-head">
                        <Text>
                            <Trans>Add a customer</Trans>
                        </Text>
                        <IconButton
                            role="button"
                            className="modal-close"
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            tabIndex={0}
                            onKeyPress={(e) => keyPress(e, handleModalDismiss)}
                            onClick={hideModal}
                        />
                    </div>
                    <div className="add-customer-modal-body">
                        <div className="dp-header">
                            <div className="head-wrap">
                                <div className="text-field-wrap">
                                    <label>
                                        <Trans>Search by MSX Opportunity ID / TPID / Customer Name</Trans>
                                    </label>
                                    <TextField placeholder={translate('Enter MSX Opp ID / TPID / Customer Name')} />
                                </div>
                                <div className="text-field-wrap">
                                    <label>
                                        <Trans>Customer Country (Subsidiary)</Trans>
                                    </label>
                                    <ComboBox options={options} placeholder="For example -  United States" />
                                </div>
                                <div className="btn-field-wrap">
                                    <DefaultButton type="submit" className="primary">
                                        <Trans>Search</Trans>
                                    </DefaultButton>
                                </div>
                            </div>
                        </div>
                        <div className="dp-content">
                            <div className="dp-content-title">
                                <Text>
                                    <Trans>Select from recently attached</Trans>
                                </Text>
                            </div>
                            <div className="table-wrap">
                                <table className="dp-table">
                                    <thead>
                                        <tr>
                                            <td>
                                                <Trans>Customer Name</Trans>
                                            </td>
                                            <td>
                                                <Trans>TPID</Trans>
                                            </td>
                                            <td>
                                                <Trans>Country</Trans>
                                            </td>
                                            <td>
                                                <Trans>MSX Opportunity ID</Trans>
                                            </td>
                                            <td>
                                                <Trans>Title</Trans>
                                            </td>
                                            <td>
                                                <Trans>Revenue</Trans>
                                            </td>
                                            <td>
                                                <Trans>Device</Trans>
                                            </td>
                                            <td>
                                                <Trans>Action</Trans>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>ASNET Technologies</td>
                                            <td>10470985</td>
                                            <td>Singapore</td>
                                            <td>1-1VW5P9</td>
                                            <td>Surface Pro 7 purchased</td>
                                            <td>$ 2,000</td>
                                            <td>1067</td>
                                            <td>
                                                <DefaultButton type="submit" className="primary">
                                                    <Trans>Select</Trans>
                                                </DefaultButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ASNET Technologies</td>
                                            <td>10470985</td>
                                            <td>Singapore</td>
                                            <td>1-1VW5P9</td>
                                            <td>Surface Pro 7 purchased</td>
                                            <td>$ 2,000</td>
                                            <td>1067</td>
                                            <td>
                                                <DefaultButton type="submit" className="primary">
                                                    <Trans>Select</Trans>
                                                </DefaultButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ASNET Technologies</td>
                                            <td>10470985</td>
                                            <td>Singapore</td>
                                            <td>1-1VW5P9</td>
                                            <td>Breakdown of Surface Laptop 3 Firmware update</td>
                                            <td>$ 2,000</td>
                                            <td>1067</td>
                                            <td>
                                                <DefaultButton type="submit" className="primary">
                                                    <Trans>Select</Trans>
                                                </DefaultButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ASNET Technologies</td>
                                            <td>10470985</td>
                                            <td>Singapore</td>
                                            <td>1-1VW5P9</td>
                                            <td>Surface Pro 7 purchased</td>
                                            <td>$ 2,000</td>
                                            <td>1067</td>
                                            <td>
                                                <DefaultButton type="submit" className="primary">
                                                    <Trans>Select</Trans>
                                                </DefaultButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default AddCustomerModal;
