import { Checkbox, DefaultButton, IconButton, IIconProps, Modal, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { keyPress } from 'app/components/helpers';
import './BugInformationModal.scss';
import { IRequest } from 'app/models/common/response';

const titleId = 'bug-info-modal';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IProps {
    modalState: boolean;
    updateModalState: Function;
    selectedRequest: IRequest;
    onUpdateHandler?: Function;
}

const BugInformationModal: React.FC<IProps> = ({ modalState, updateModalState, selectedRequest, onUpdateHandler }) => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(modalState);
    const [bugInformations, setBugInformations] = useState<any>({});
    const [selectedBugs, setSelectedBugs] = useState<any>({});
    const [errorFields, setErrorFields] = useState<any>({});

    const handleModalDismiss = () => {
        hideModal();
        updateModalState(false);
        setBugInformations({});
        setSelectedBugs({});
    };

    const handleChangeTextField = (e: any, type: number) => {
        const value = e.target.value;
        setErrorFields((val: any) => ({ ...val, [type]: false }));
        switch (type) {
            case 1:
                setBugInformations((val: any) => ({ ...val, pleBug: value }));
                break;
            case 2:
                setBugInformations((val: any) => ({ ...val, msftBug: value }));
                break;
            case 3:
                setBugInformations((val: any) => ({ ...val, osBug: value }));
                break;
            case 4:
                setBugInformations((val: any) => ({ ...val, hubOsBug: value }));
                break;
            case 5: 
                setBugInformations((val: any) => ({ ...val, otherBug: value }));
                break;
            default:
                break;
        }
    };

    const handleChangeCheckbox = (e: any, isChecked: boolean, type: number) => {
        setSelectedBugs((val: any) => ({ ...val, [type]: isChecked }));
    };

    const handleSave = () => {
        let bugInformationsData = [];
        let errorFlag = false;
        let errorData = { ...errorFields };
        for (const key in selectedBugs) {
            if (selectedBugs[key] === true) {
                let bugUrl = '';
                switch (parseInt(key)) {
                    case 1:
                        bugUrl = bugInformations.pleBug;
                        break;
                    case 2:
                        bugUrl = bugInformations.msftBug;
                        break;
                    case 3:
                        bugUrl = bugInformations.osBug;
                        break;
                    case 4:
                        bugUrl = bugInformations.hubOsBug;
                        break;
                    case 5:
                        bugUrl = bugInformations.otherBug;
                        break;
                    default:
                        break;
                }
                if (!bugUrl || bugUrl === '') {
                    errorData = { ...errorData, [key]: true };
                    errorFlag = true;
                } else {
                    errorData = { ...errorData, [key]: false };
                }
                bugInformationsData.push({
                    bugInformation: parseInt(key),
                    url: bugUrl
                });
            } else {
                errorData = { ...errorData, [key]: false };
            }
        }
        setErrorFields(errorData);
        if (errorFlag) {
            return false;
        }
        if (
            (bugInformationsData && bugInformationsData.length > 0) ||
            (bugInformationsData &&
                bugInformationsData.length === 0 &&
                selectedRequest.bugInformations &&
                selectedRequest.bugInformations.length > 0)
        ) {
            onUpdateHandler(bugInformationsData);
            setBugInformations({});
            setSelectedBugs({});
            updateModalState(false);
        }
    };

    useEffect(() => {
        if (modalState) {
            showModal();
        } else {
            hideModal();
            setBugInformations({});
            setSelectedBugs({});
        }
    }, [modalState, showModal, hideModal]);

    useEffect(() => {
        setBugInformations({});
        setSelectedBugs({});
        if (selectedRequest) {
            if (selectedRequest.bugInformations && selectedRequest.bugInformations.length > 0) {
                for (const bug of selectedRequest.bugInformations) {
                    setSelectedBugs((val: any) => ({ ...val, [parseInt(bug.bugInformation)]: true }));
                    switch (parseInt(bug.bugInformation)) {
                        case 1:
                            setBugInformations((val: any) => ({ ...val, pleBug: bug.url }));
                            break;
                        case 2:
                            setBugInformations((val: any) => ({ ...val, msftBug: bug.url }));
                            break;
                        case 3:
                            setBugInformations((val: any) => ({ ...val, osBug: bug.url }));
                            break;
                        case 4:
                            setBugInformations((val: any) => ({ ...val, hubOsBug: bug.url }));
                            break;
                        case 5:
                            setBugInformations((val: any) => ({ ...val, otherBug: bug.url }));
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }, [selectedRequest, modalState]);

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={isModalOpen}
            onDismiss={handleModalDismiss}
            isBlocking={false}
            containerClassName="bug-info-modal">
            <>
                <div className="modal-head">
                    <Text>Add bugs</Text>
                    <IconButton
                        role="button"
                        className="modal-close"
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={handleModalDismiss}
                        onKeyPress={(e) => keyPress(e, handleModalDismiss)}
                        tabIndex={0}
                    />
                </div>
                <div className="bug-info-modal-body">
                    <>
                        <div className="scroll-panel">
                            <div className="dp-content">
                                <div className="bug-info-control-group">
                                    <div className="checkbox-wrap">
                                        <Checkbox
                                            data-testid="ple-bug-checkbox"
                                            label="PLE Bug"
                                            onChange={(e, ischecked) => handleChangeCheckbox(e, ischecked, 1)}
                                            checked={selectedBugs[1] ? true : false}
                                        />
                                    </div>
                                    <div className="textfield-wrap">
                                        <TextField
                                            data-testid="ple-bug-test-field"
                                            value={bugInformations?.pleBug}
                                            borderless
                                            onChange={(e) => handleChangeTextField(e, 1)}
                                            disabled={!selectedBugs[1]}
                                        />
                                    </div>
                                    {errorFields[1] === true && (
                                        <div className="error-msg">Please enter a valid bug information</div>
                                    )}
                                </div>
                                <div className="bug-info-control-group">
                                    <div className="checkbox-wrap">
                                        <Checkbox
                                            label="MSFT Devices Non PLE Bug"
                                            onChange={(e, ischecked) => handleChangeCheckbox(e, ischecked, 2)}
                                            checked={selectedBugs[2] ? true : false}
                                        />
                                    </div>
                                    <div className="textfield-wrap">
                                        <TextField
                                            data-testid="MSFT-nonPLE-bug-text-field"
                                            value={bugInformations?.msftBug}
                                            borderless
                                            onChange={(e) => handleChangeTextField(e, 2)}
                                            disabled={!selectedBugs[2]}
                                        />
                                    </div>
                                    {errorFields[2] === true && (
                                        <div className="error-msg">Please enter a valid bug information</div>
                                    )}
                                </div>
                                <div className="bug-info-control-group">
                                    <div className="checkbox-wrap">
                                        <Checkbox
                                            label="OS Bug"
                                            onChange={(e, ischecked) => handleChangeCheckbox(e, ischecked, 3)}
                                            checked={selectedBugs[3] ? true : false}
                                        />
                                    </div>
                                    <div className="textfield-wrap">
                                        <TextField
                                            data-testid="OS-bug-text-field"
                                            value={bugInformations?.osBug}
                                            borderless
                                            onChange={(e) => handleChangeTextField(e, 3)}
                                            disabled={!selectedBugs[3]}
                                        />
                                    </div>
                                    {errorFields[3] === true && (
                                        <div className="error-msg">Please enter a valid bug information</div>
                                    )}
                                </div>
                                <div className="bug-info-control-group">
                                    <div className="checkbox-wrap">
                                        <Checkbox
                                            label="Hub OS Bug"
                                            onChange={(e, ischecked) => handleChangeCheckbox(e, ischecked, 4)}
                                            checked={selectedBugs[4] ? true : false}
                                        />
                                    </div>
                                    <div className="textfield-wrap">
                                        <TextField
                                            data-testid="Hub-OS-Bug-text-field"
                                            value={bugInformations?.hubOsBug}
                                            borderless
                                            onChange={(e) => handleChangeTextField(e, 4)}
                                            disabled={!selectedBugs[4]}
                                        />
                                    </div>
                                    {errorFields[4] === true && (
                                        <div className="error-msg">Please enter a valid bug information</div>
                                    )}
                                </div>
                                <div className="bug-info-control-group">
                                    <div className="checkbox-wrap">
                                        <Checkbox
                                            label="Other Bugs"
                                            onChange={(e, ischecked) => handleChangeCheckbox(e, ischecked, 5)}
                                            checked={selectedBugs[5] ? true : false}
                                        />
                                    </div>
                                    <div className="textfield-wrap">
                                        <TextField
                                            data-testid="other-bugs-text-field"
                                            value={bugInformations?.otherBug}
                                            borderless
                                            onChange={(e) => handleChangeTextField(e, 5)}
                                            disabled={!selectedBugs[5]}
                                        />
                                    </div>
                                    {errorFields[5] === true && (
                                        <div className="error-msg">Please enter a valid bug information</div>
                                    )}
                                </div>
                                <div className="form-btn-wrap">
                                    <DefaultButton className="default" onClick={handleModalDismiss} data-testid="cancel-button">
                                        Cancel
                                    </DefaultButton>
                                    <DefaultButton
                                        data-testid="update-btn-test"
                                        type="submit"
                                        className="primary m-l-20"
                                        onClick={handleSave}>
                                        Update
                                    </DefaultButton>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </>
        </Modal>
    );
};

export default BugInformationModal;
