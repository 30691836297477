import React, { useEffect, useState } from 'react';
import { Icon } from '@fluentui/react';
import { Text, ActionButton } from '@fluentui/react';
import { setQueryParam } from 'app/components/helpers';
import { useHistory } from 'react-router-dom';
import { RouteConstants, StringConstants } from 'app/utils/constants';
interface IProps {
    type: string;
    createdOnOrder?: string;
    setCreatedOnOrder?: Function;
    agingOrder?: string;
    setAgingOrder?: Function;
    revenueOrder?: string;
    setRevenueOrder?: Function;
    setCurrentSortProp: Function;
    stateData?: any;
}
let stateTimeout: any;
export const ColHeadMenu: React.FunctionComponent<IProps> = ({
    type,
    createdOnOrder,
    setCreatedOnOrder,
    agingOrder,
    setAgingOrder,
    revenueOrder,
    setRevenueOrder,
    setCurrentSortProp,
    stateData
}) => {
    const [localRevenueOrder, setLocalRevenueOrder] = useState(revenueOrder);

    const [localCreatedOnOrder, setLocalCreatedOnOrder] = useState(createdOnOrder);

    const history = useHistory();
    
    const handleRevenueOrder = () => {
        if (localRevenueOrder === '') {
            setLocalRevenueOrder(StringConstants.ASC);
        } else {
            if (localRevenueOrder === StringConstants.ASC) {
                setLocalRevenueOrder(StringConstants.DESC);
            } else {
                setLocalRevenueOrder(StringConstants.ASC);
            }
        }
    };

    const handleCreatedOnOrder = () => {
        if (localCreatedOnOrder === '') {
            setLocalCreatedOnOrder(StringConstants.ASC);
        } else {
            if (localCreatedOnOrder === StringConstants.ASC) {
                setLocalCreatedOnOrder(StringConstants.DESC);
            } else {
                setLocalCreatedOnOrder(StringConstants.ASC);
            }
        }
    };
    useEffect(() => {
        if (JSON.stringify(revenueOrder) !== JSON.stringify(localRevenueOrder)) {
            if (stateTimeout) {
                clearTimeout(stateTimeout);
            }
            stateTimeout = setTimeout(() => {
                // setRevenueOrder(localRevenueOrder);
                setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, true, {
                    ...stateData,
                    revenueOrder: localRevenueOrder
                });
            }, 300);
        }
    }, [localRevenueOrder]);

    useEffect(() => {
        if (JSON.stringify(createdOnOrder) !== JSON.stringify(localCreatedOnOrder)) {
            if (stateTimeout) {
                clearTimeout(stateTimeout);
            }
            stateTimeout = setTimeout(() => {
                // setCreatedOnOrder(localCreatedOnOrder);
                setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, true, {
                    ...stateData,
                    createdOnOrder: localCreatedOnOrder
                });
            }, 300);
        }
    }, [localCreatedOnOrder]);
    return (
        <div className={`header-cell-btn ${type === StringConstants.REVENUE ? 'right-aligned-header' : ''}`}>
            <ActionButton
                data-testid="action-btn-test"
                onClick={() => {
                    switch (type) {
                        case StringConstants.REVENUE:
                            handleRevenueOrder();
                            setCurrentSortProp(StringConstants.REVENUE);
                            break;
                        case StringConstants.CREATED_ON:
                            handleCreatedOnOrder();
                            setCurrentSortProp(StringConstants.CREATED_ON);
                            break;

                        default:
                            break;
                    }
                }}>
                <Text>{type === StringConstants.REVENUE ? 'TOTAL REVENUE RISK' : type === StringConstants.CREATED_ON ? 'CREATED ON' : type}</Text>{' '}
                {type !== 'AGING' && <Icon className="sort-icon m-l-5" iconName="Sort" />}
            </ActionButton>
        </div>
    );
};
