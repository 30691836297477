import { IReport } from 'app/models/common/post';
import EmbedPowerBI from 'app/modules/admin/embedPowerBI/EmbedPowerBI';
import React from 'react';
import Slider from 'react-slick';
import './PowerBISlider.scss';
interface IProps {
    data: IReport[];
    title: string;
}

const PowerBISlider: React.FC<IProps> = (props: IProps) => {
    const { data, title = 'Reports' } = props;
    const settings = {
        dots: true,
        dotClass: 'abc',
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        vertical: true,
        swipeToSlide: false,
        verticalSwiping: false,
        appendDots: (dots: any) => (
            <div className="slick-dots">
                {title.trim() !== '' && <div className="title">{title}</div>}
                <ul>{dots}</ul>
            </div>
        ),
        customPaging: (i: any) => <div>{data[i]?.displayName}</div>
    };
    return (
        <>
            {data.length > 1 ? (
                <Slider {...settings} aria-hidden="true" className="powerBI-slider">
                    {data.map((report: IReport) => (
                        <EmbedPowerBI key={report.id} embedURL={report.url} />
                    ))}
                </Slider>
            ) : (
                <div className="powerBI-slider slick-slider">
                    <div className="slick-list">
                        <EmbedPowerBI key={data[0]?.id} embedURL={data[0]?.url} />
                    </div>
                    <div className="slick-dots">
                        {title.trim() !== '' && <div className="title">{title}</div>}
                        <ul>
                            <li className="slick-active">
                                <div>{data[0]?.displayName}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default PowerBISlider;
