import React, { useEffect } from 'react';
import './ArticlesByTagName.scss';
import { Image, Icon } from '@fluentui/react';
import { Breadcrumb, IBreadcrumbItem, IDropdownOption, Dropdown } from '@fluentui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import SearchNews from '../searchnews/SearchNews';
import NewsCard from '../newsCard/NewsCard';
import { ITags } from 'app/models/common/post';
import useStore from 'app/store';
import placeholderCategoryIcon from 'app/static/icons/category-icon-Placeholder.svg';
import placeholderCategoryBanner from 'app/static/Product_News_Banner.png';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryParam, getTags, setQueryParam } from 'app/components/helpers';
import Pagination from 'app/components/pagination/Pagination';
import { Loader } from 'app/components/loader/Loader';
import { useGetArticles } from 'app/services/queries';
import { ArticleStatus } from 'app/enums/Status.enum';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { StringConstants } from 'app/utils/constants';
import { getArticleCategorySvgComponent } from 'app/utils/SVGIcons';

const ArticlesByTagName: React.FC = () => {
    const history = useHistory();
    const { t: translate } = useTranslation();
    const { pathname } = useLocation();
    const articleCategories: Array<ITags> = useStore((state: any) => state.articleCategories);

    const options: IDropdownOption[] = [
        { key: '1', text: 'Latest', data: { icon: 'List' } },
        { key: '0', text: 'Earliest', data: { icon: 'Previous' } }
    ];
    const [sortBy, setSortBy] = useState<IDropdownOption>(options[0]);
    const [displayName, setDisplayName] = useState<string>(null);

    const isRootTag = (tagName: string) => {
        return articleCategories?.some((c: any) => c.displayName.toLowerCase() === tagName?.toLocaleLowerCase());
    };

    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history).page));
    const resultsPerPage = 21;
    const {
        isFetched,
        isLoading,
        data: articles,
        refetch: getArticles
    } = useGetArticles(
        Number(getQueryParam(history).page),
        resultsPerPage,
        'ModifiedOn',
        getQueryParam(history).sort as string,
        null,
        ArticleStatus.Published,
        pathname.includes(StringConstants.KNOWLEDGE_ARTICLES)
            ? ArticleType.KnowledgeArticle
            : pathname.includes('news')
            ? ArticleType.News
            : null,
        isRootTag(getQueryParam(history).tag as string),
        getQueryParam(history).tag as string,
        false
    );

    useEffect(() => {
        const { tag } = getQueryParam(history);
        setQueryParam(
            history,
            pathname,
            {
                tag: tag,
                page: getQueryParam(history).page ?? '1',
                sort: getQueryParam(history).sort ?? '1'
            },
            true
        );
        const d = getTags(articleCategories)?.find(
            (categories: string) => categories.toLowerCase() === (tag as string)?.toLowerCase()
        );
        setDisplayName(d);
    }, []);

    useEffect(() => {
        const { page, sort, tag } = getQueryParam(history);
        const displayName = getTags(articleCategories)?.find(
            (categories: string) => categories.toLowerCase() === (tag as string)?.toLowerCase()
        );
        setDisplayName(displayName);
        setPageIndex(Number(page));
        setSortBy(options.find((option: IDropdownOption) => option.key === sort));
        getArticles();
    }, [history?.location?.search]);

    useEffect(() => {
        if (isFetched && !displayName && articleCategories) {
            history.push('/404');
        }
    }, [displayName, articleCategories, isFetched, history]);

    const [categoryIcon, setCategoryIcon] = useState<React.ReactNode>();

    const getCategoryByName = (tagName: string) => {
        var matchingTag = articleCategories?.find((item) => {
            return item.displayName === tagName;
        });
        return matchingTag;
    };

    useEffect(() => {
        let iconUrl = getCategoryByName(displayName)?.icon;
        setCategoryIcon(getArticleCategorySvgComponent(iconUrl));
    }, [displayName]);

    const onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <>
                {option.data && option.data.icon && (
                    <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </>
        );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        const option = options[0];

        return (
            <>
                {option.data && option.data.icon && (
                    <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </>
        );
    };
    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setQueryParam(history, pathname, { sort: item.key.toString(), page: '1' }, false);
    };
    const itemsWithHref: IBreadcrumbItem[] = [
        {
            text: pathname.includes(StringConstants.KNOWLEDGE_ARTICLES)
                ? 'Knowledge Articles'
                : pathname.includes('news')
                ? 'News'
                : 'Articles',
            key: pathname.includes(StringConstants.KNOWLEDGE_ARTICLES) ? 'Knowledge Articles' : pathname.includes('news') ? 'News' : 'Articles',
            onClick: () => history.goBack()
        },
        { text: displayName, key: displayName, isCurrentItem: true }
    ];

    return (
        <>
            {!isLoading && isFetched && (
                <>
                    <div className="container">
                        <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} overflowAriaLabel="More links" />
                    </div>
                    <div className="newsfeed-product-news-banner">
                        <div className="newsfeed-product-news-container">
                            <Image
                                className="newsfeed-banner"
                                src={
                                    getCategoryByName(displayName)?.bannerImage &&
                                    getCategoryByName(displayName)?.bannerImage !== undefined
                                        ? getCategoryByName(displayName)?.bannerImage
                                        : placeholderCategoryBanner
                                }
                            />
                            <div className=" ms-Grid" dir="ltr">
                                <div className="container ms-Grid-row product-news-box">
                                    <div className="ms-Grid-col ms-sm12 ms-md8">
                                        <div className="product-news-header-container">
                                            {categoryIcon ? (
                                                <span className="icon">{ categoryIcon }</span>
                                            ) : (
                                                <Image className="icon" src={placeholderCategoryIcon} />
                                            )}
                                            <h2>{displayName}</h2>
                                        </div>
                                        <div className="newsfeed-product-news-banner-title">{displayName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container count-sort-section d-flex--between ai-center p-t-10 p-b-10">
                            <div className="article-count">
                                <b>
                                    <Trans>All</Trans> {articles !== undefined && articles.length} <Trans>Articles</Trans>
                                </b>
                            </div>
                            <div className="d-flex--between ai-center gap-30">
                                <div className="sort-dropdown">
                                    <span className="sort-by">
                                        <Trans>Sort by</Trans>
                                    </span>
                                    <Dropdown
                                        aria-label='Sort By'
                                        onRenderTitle={onRenderTitle}
                                        onRenderOption={onRenderOption}
                                        placeholder="Latest"
                                        options={options}
                                        selectedKey={sortBy ? sortBy.key : undefined}
                                        onChange={onChange}
                                        className="selectType"
                                        title="category dropdown"
                                        id="category-drop"
                                    />
                                </div>
                                <SearchNews placeholder={translate('Search article')} iconName="Search" animate={false} />
                            </div>
                        </div>
                        {articles?.items.length > 0 ? (
                            <div className="card-grid">
                                <div className="ms-Grid container" dir="ltr">
                                    <div className="ms-Grid-row">
                                        {articles?.items?.map((data: any) => (
                                            <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xl4 card-list" key={data.id}>
                                                <NewsCard
                                                    data={data}
                                                    showMoreOptions={false}
                                                    showLastEdited={false}
                                                    showStatus={false}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex height-70 jc-center ai-center message">
                                <Trans>Run your imagination wide and create articles</Trans>
                            </div>
                        )}
                        {articles && articles?.items.length > 0 && (
                            <div className="ms-Grid-row text-center">
                                <div className="pagination-container">
                                    <Pagination
                                        pageCount={articles?.totalPages}
                                        setPageNumber={setPageIndex}
                                        initialPage={pageIndex - 1}
                                        totalCount={articles?.totalCount}
                                        resultsPerPage={resultsPerPage}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

            {isLoading && <Loader classNames=" height-70" />}
        </>
    );
};

export default ArticlesByTagName;
