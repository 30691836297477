import React, { useEffect, useState } from 'react';
import { Link, PersonaInitialsColor } from '@fluentui/react';

import './AuthorCard.scss';
import { getProfileByUserId } from 'app/components/helpers';
import Avatar from 'app/components/avatar/Avatar';
import { IAuthorDetails } from 'app/models/common/post';
import { UserDomain } from 'app/enums/UserDomain.enum';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
interface IAuthorData {
    data: any;
}

const AuthorCard: React.FC<IAuthorData> = (props: IAuthorData) => {
    const [authorDetail, setAuthorDetail] = useState<IAuthorDetails>();
    const { data: author }: { data: IAuthorDetails } = props;
    const history = useHistory();
    useEffect(() => {
        if (!author.displayName && author.id) {
            getProfileByUserId(author.id)
                .then((res) => {
                    setAuthorDetail({ ...author, displayName: res.displayName });
                })
                .catch();
        }
        setAuthorDetail(author);
    }, [author]);
    return (
        <>
            <div className="author-box">
                <div className="author-img">
                    {authorDetail && (
                        <Avatar
                            userId={authorDetail.id}
                            userName={authorDetail.displayName}
                            initialColor={PersonaInitialsColor.teal}
                            imageSize={120}
                            roundedPic={true}
                        />
                    )}
                </div>
                {authorDetail && (
                    <div className="about-author">
                        <div className="author-name text-lines text-lines--2">{authorDetail.displayName}</div>
                        {authorDetail.userDomain && (
                            <div className="capsule capsule--green team">{UserDomain[authorDetail.userDomain]}</div>
                        )}
                        <div className="view-all">
                            <Link
                                role="link"
                                onClick={() => history.push(`/news-feed/author/${authorDetail?.id}`)}
                                aria-label={`View All Posts by ${authorDetail.displayName}`}
                                tabIndex={0}>
                                <Trans>View All Posts</Trans>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AuthorCard;
