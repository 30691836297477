import React, { useEffect, useRef, useState } from 'react';
import { Text, Link } from '@fluentui/react';
import './ListPanel.scss';
import { IRequest } from 'app/models/common/response';
import { GetText, keyPress } from 'app/components/helpers';
import { formatCustomerNames } from 'app/components/helpers';
import { ILookUpData } from 'app/models/common/post';
import { Trans } from 'react-i18next';
import { useHeightListener } from 'app/components/heightListenerHook/HeightListener';
import _, { split } from 'lodash';
import useStore from 'app/store';

interface IProps {
    data: any;
    requestData: IRequest[];
    selectedRequest: IRequest;
    requestTypes: ILookUpData[];
    teams: ILookUpData[];
    pageNumber: number;
    statusTypes: ILookUpData[];
    associationTypes: ILookUpData[];
    setSelectedRequest: Function;
    setPageNumber: Function;
    setRequestData: Function;
    isListView: boolean;
    handleToggleView: Function;
    leftPanelHeight: number;
    setIsEditMode: Function;
    resultCount?: number;
    isLoading: boolean;
}

const ListPanel: React.FC<IProps> = (props) => {
    const setLastSelectedRequest = useStore((state: any) => state.setLastSelectedRequest);
    const lastSelectedRequest = useStore((state: any) => state.lastSelectedRequest);
    // props variables
    const { selectedRequest, requestData, statusTypes, leftPanelHeight, resultCount, isLoading, associationTypes } = props;
    // props functions
    const { setSelectedRequest, setIsEditMode } = props;
    // state variables
    const [toggleHeight, setToggleHeight] = useState(0);

    const selectedItemRef = useRef(null);
    // helper functions

    const renderTag = (status: number) => {
        let label = '';
        let tagClass = '';
        switch (status) {
            case 1:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-a';
                break;
            case 2:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-b';
                break;
            case 3:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-c';
                break;
            case 4:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-d';
                break;
            case 5:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-e';
                break;
            default:
                tagClass = 'tag-type-a';
                break;
        }
        return <Text className={`quick-tag ${tagClass}`}>{label}</Text>;
    };

    const renderAssociationTag = (item: any) => {
        let label = '';
        let tagClass = '';
        if (item.isParent) {
            label = GetText(associationTypes, 2);
            tagClass = 'tag-type-f';
        }

        if (item.parentRequestId !== null && item.parentRequestId !== '') {
            label = GetText(associationTypes, 3);
            tagClass = 'tag-type-g';
        }

        return <Text className={`quick-tag ${tagClass}`}>{label}</Text>;
    };

    // hooks
    useEffect(() => {
        if (requestData && requestData.length > 0) {
            if (Object.keys(selectedRequest).length === 0) {
                if (Object.entries(lastSelectedRequest).length === 0) {
                    setSelectedRequest(requestData[0]);
                } else {
                    setSelectedRequest(lastSelectedRequest);
                }
            } else {
                const existingSelectedItem = _.find(
                    requestData,
                    (item) => item.unifiedTrackingId === selectedRequest.unifiedTrackingId
                );
                if (existingSelectedItem) {
                    setSelectedRequest(existingSelectedItem);
                } else {
                    setSelectedRequest(requestData[0]);
                }
            }
        } else {
            setSelectedRequest({});
        }
    }, [requestData]);

    useEffect(() => {
        if (selectedItemRef.current) {
            selectedItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }
    }, []);

    const toggleContainerRef = useRef(null);
    const toggleContainerHeight = useHeightListener(toggleContainerRef);
    useEffect(() => {
        if (toggleContainerRef.current) {
            setToggleHeight(toggleContainerHeight);
        }
    }, [toggleContainerHeight]);

    const roundPrice = (price: any) => {
        return price - parseInt(price) >= .5 ? Math.ceil(price) : Math.floor(price); 
    }

    return (
        <div className="list-panel-container">
            <div ref={toggleContainerRef}></div>
            {resultCount > 0 && <div className="ad-results-count-wrap">{resultCount || 0} requests found</div>}
            <div id="list-scroll-panel" className="scroll-panel">
                <div className="list-item-wrapper">
                    {requestData && requestData.length > 0 && (
                        <>
                            {requestData.map((item: any, index) => (
                                <div
                                    key={index}
                                    className={`list-item ${
                                        item?.unifiedTrackingId === selectedRequest?.unifiedTrackingId ? 'active' : ''
                                    }`}
                                    ref={item?.unifiedTrackingId === selectedRequest?.unifiedTrackingId ? selectedItemRef : null}
                                    tabIndex={0}
                                    onKeyPress={(e) =>
                                        keyPress(e, () => {
                                            setSelectedRequest(item);
                                            if (
                                                item !== lastSelectedRequest &&
                                                Object.entries(lastSelectedRequest).length !== 0
                                            ) {
                                                setLastSelectedRequest({});
                                            }
                                            setIsEditMode(false);
                                        })
                                    }
                                    onClick={() => {
                                        setSelectedRequest(item);
                                        if (item !== lastSelectedRequest && Object.entries(lastSelectedRequest).length !== 0) {
                                            setLastSelectedRequest({});
                                        }
                                        setIsEditMode(false);
                                    }}>
                                    <div className="list-title">
                                        <Text>{item.unifiedTrackingId}</Text>
                                        {renderTag(item.statusValue)}
                                        {renderAssociationTag(item)}
                                    </div>
                                    <div className={`text-normal`}>
                                        <Text className="title-field">{item.title}</Text>
                                    </div>
                                    <div className="list-item-detail-wrap">
                                        <div className="list-item-col">
                                            <div>
                                                <div className="list-head">
                                                    <Text>
                                                        {item.isParent ? (
                                                            <Trans>NUMBER OF CUSTOMERS</Trans>
                                                        ) : 
                                                        (
                                                            <Trans>CUSTOMER</Trans>
                                                        )}
                                                    </Text>
                                                </div>
                                                <div className='cust-name'>
                                                    {item.isParent ? (
                                                        <Text>
                                                            {item.childrenCount}
                                                        </Text>
                                                    ) 
                                                    : 
                                                    (
                                                        <>
                                                            {item?.customerImpacts[0]?.customerName ? (
                                                                <Text>
                                                                    {formatCustomerNames(item?.customerImpacts[0]?.customerName)}
                                                                </Text>
                                                            ) 
                                                            : 
                                                            (
                                                                <Text>
                                                                --
                                                                </Text>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list-item-col">
                                            <div className="list-head">
                                                <Text>
                                                    <Trans>REQUEST TYPE</Trans>
                                                </Text>
                                            </div>
                                            <div className="list-value">
                                                <Text>{item.requestType}</Text>
                                            </div>
                                        </div>
                                        <div className="list-item-col">
                                            <div className="list-head">
                                                <Text>
                                                    <Trans>MSX REVENUE</Trans>
                                                </Text>
                                            </div>
                                            <div className="list-value">
                                                <Text className="limit-text">$ {new Intl.NumberFormat('en-US', {style: 'decimal'}).format(roundPrice(item.msxCurrentTotalRevenue ? item.msxCurrentTotalRevenue : item.msxTotalRevenue))}</Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    {!isLoading && requestData && requestData.length === 0 && (
                        <div className="list-item">
                            <Trans>No data to display</Trans>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListPanel;
