import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField } from '@fluentui/react';
import { useSpeechToTextToken } from 'app/services/queries';

import './SearchNews.scss';
import { getQueryParam, keyPress, setQueryParam } from 'app/components/helpers';
const speechSdk = require('microsoft-cognitiveservices-speech-sdk');

interface IProps {
    iconName?: string;
    placeholder?: string;
    animate?: boolean;
    type?: 'articles' | 'news';
    showDefaultValue?: boolean;
}

const SearchNews: React.FC<IProps> = (props) => {
    const { iconName = '', placeholder = '', animate = false, type = null, showDefaultValue = false } = props;
    const history = useHistory();

    const { data: generateToken, isLoading: isTokenLoading, refetch: getSpeechToTextToken } = useSpeechToTextToken();

    const [inputSearch, setInputSearch] = useState(showDefaultValue ? (getQueryParam(history).search as string) : '');
    const [, setTokenObj] = useState({ Token: '', Region: '' });
    const [isRecording, setIsRecording] = React.useState(false);

    useEffect(() => {
        const { search } = getQueryParam(history);
        if (showDefaultValue) setInputSearch(search as string);
    }, [history?.location?.search]);

    useEffect(() => {
        if (!isTokenLoading) {
            setTokenObj(generateToken);
        }
    }, [isTokenLoading]);

    const [recognizer, setRecognizer] = useState(null);
    //voice to text
    const sttFromMic = async (action: Boolean) => {
        if (action) {
            let token = JSON.parse(sessionStorage.getItem('speech-token'));
            if (!(token && token.Token)) {
                await getSpeechToTextToken();
                token = JSON.parse(sessionStorage.getItem('speech-token'));
            }
            const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(token?.Token ?? '', token?.Region ?? '');
            speechConfig.speechRecognitionLanguage = 'en-US';
            const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
            let newRecognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
            setRecognizer(newRecognizer);

            setIsRecording(true);
            newRecognizer.recognized = function (s: any, e: any) {
                let displayText;

                if (e.result.text) {
                    displayText = `${e.result.text}`;
                    displayText = displayText.substring(0, displayText.length - 1);
                    setInputSearch(displayText);
                    setIsRecording(false);

                    newRecognizer.stopContinuousRecognitionAsync(
                        (result: string) => {},
                        (e: string) => {}
                    );
                }
            };

            newRecognizer.startContinuousRecognitionAsync(() => {
                setTimeout(() => {
                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                    setIsRecording(false);
                }, 20000);
            });
        }

        if (!action) {
            recognizer.stopContinuousRecognitionAsync(
                (result: string) => {},
                (e: string) => {}
            );
        }
    };

    const onChange = (event: any) => {
        setInputSearch(event.target.value);
    };

    const onKeyUp = (event: any) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            setQueryParam(history, `/search-results/${type ? type : 'all-articles'}`, { search: event.target.value });
            setInputSearch('');
        }
    };

    const voiceSearchOn = () => {
        sttFromMic(true);
    };

    const voiceSearchOff = () => {
        setIsRecording(false);
        sttFromMic(false);
    };

    return (
        <div className="search-news-container d-flex">
            <TextField
                value={inputSearch}
                placeholder={placeholder}
                iconProps={{ iconName }}
                className={`search-news-input ${animate ? 'animation' : ''}`}
                onChange={(event) => onChange(event)}
                onKeyUp={(event) => onKeyUp(event)}
            />
            {isRecording && (
                <div
                    className="recording-row recording-position"
                    tabIndex={0}
                    data-testid="recording"
                    onKeyPress={(e: any) => keyPress(e, () => voiceSearchOff())}
                    onClick={() => voiceSearchOff()}>
                    <div className="first-line"></div>
                    <div className="second-line"></div>
                    <div className="third-line"></div>
                    <div className="fourth-line"></div>
                    <div className="fifth-line"></div>
                </div>
            )}
            {!isRecording && (
                <i
                    className="micro-icon"
                    tabIndex={0}
                    data-testid="start-recording"
                    onKeyPress={(e: any) => keyPress(e, () => voiceSearchOn())}
                    onClick={() => voiceSearchOn()}></i>
            )}
        </div>
    );
};
export default SearchNews;
