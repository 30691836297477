import { Checkbox, FontIcon, Icon, Text } from '@fluentui/react';
import { keyPress, setQueryParam } from 'app/components/helpers';
import useStore from 'app/store';
import { RouteConstants, StringConstants } from 'app/utils/constants';

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IOptionProps } from '../../models';

interface IProps {
    selectedKeys: IOptionProps[];
    setSelectedKeys: Function;
    stateData?: any;
    setFilterActive: (filterActive: boolean) => void;
}

const options: IOptionProps[] = [
    { key: StringConstants.ALL, text: StringConstants.UPPERCASE_ALL },
    { key: 'new', text: 'New', type: [1] },
    { key: 'active', text: 'Active', type: [2, 3] },
    { key: 'resolved', text: 'Resolved', type: [4] },
    { key: 'closed', text: 'Closed', type: [5] }
];

export const StatusFilter: React.FC<IProps> = ({ selectedKeys, setSelectedKeys, stateData, setFilterActive }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const filterPanelDisabled = useStore((state: any) => state.filterPanelDisabled);

    const history = useHistory();

    const setQueryStateData = (data: any, replace: boolean) => {
        setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, replace, {
            ...stateData,
            ...data
        });
    };

    const onChange = (key: string) => {
        if (key === StringConstants.ALL) {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: IOptionProps[] = [];
                options.forEach((ele) => arr.push(ele));
                setQueryStateData({ statusSelection: arr }, false);
            } else {
                setSelectedKeys([]);
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                const tempStatusKeys = [...selectedKeys, options.filter((item) => item.key === key)[0]];
                setQueryStateData({ statusSelection: tempStatusKeys }, false);
            } else {
                const tempStatusKeys = selectedKeys.filter((item) => item.key !== key && item.key !== StringConstants.ALL);
                setQueryStateData({ statusSelection: tempStatusKeys }, false);
            }
        }
    };
    const isChecked = (ele: string) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };
    const getLabel = () => {
        if (selectedKeys?.length === 0 || selectedKeys?.filter((ele) => ele.key === StringConstants.ALL).length !== 0) {
            return StringConstants.UPPERCASE_ALL;
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                arr.push(item.text);
            });
            return arr.join(', ');
        }
    };
    const onToggleShow = () => {
        if (!filterPanelDisabled) {
            setShowDropdown((value) => !value);
        }
    };
    const checkEquality = (arr1: IOptionProps[], arr2: IOptionProps[]) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2?.filter((it) => it.key === arr1[i].key).length === 0) {
                return false;
            }
        }
        return true;
    };
    const dropDownRef = useRef(null);
    useDropDownDismiss(dropDownRef, onToggleShow, filterPanelDisabled);

    useEffect(() => {
        if (showDropdown && selectedKeys.length === 0) {
            setSelectedKeys(options);
        }
    }, [showDropdown]);

    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys?.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.key !== StringConstants.ALL)
            )
        ) {
            setSelectedKeys(options.slice(0));
        }
    }, [selectedKeys, options]);

    useEffect(() => {
        if (selectedKeys.length > 0 && selectedKeys.length !== options.length) {
            setFilterActive(true);
        } else {
            setFilterActive(false);
        }
    }, [selectedKeys]);

    return (
        <div className="status-filter filter-cont">
            <Text className="filter-heading" data-testid="status-text-test">
                Status
                {(selectedKeys.length > 0 && selectedKeys.length !== options.length) && <FontIcon iconName="LocationDot" className="filter-active-icon" />}
            </Text>

            <div
                className={`placeholder-title row-flex-box align-center ${filterPanelDisabled ? 'filter-disabled' : ''}`}
                tabIndex={0}
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                data-testid="status-filter-open-btn-test"
                onClick={() => onToggleShow()}>
                <Text className="placeholder-label">{getLabel()}</Text> <Icon iconName="ChevronDown" />
            </div>
            {showDropdown && (
                <div ref={dropDownRef} className="dropdown-cont">
                    <div
                        tabIndex={0}
                        data-testid="status-filter-close-btn-test"
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center dropdown-header">
                        <Text>Status</Text>
                        <Icon iconName="ChevronUp" />
                    </div>
                    {options.map((item, index) => (
                        <div
                            key={index}
                            data-testid={`dropdown-item-test-${index}`}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                    onChange(item.key as string);
                                }
                            }}
                            className={`dropdown-item `}>
                            <Checkbox
                                tabIndex={0}
                                data-testid={`dropdown-checkbox-test-${index}`}
                                label={item.text}
                                checked={isChecked(item.key as string)}
                                onChange={() => onChange(item.key as string)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
const useDropDownDismiss = (ref: any, onToggleShow: Function, filterPanelDisabled: boolean) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, filterPanelDisabled]);
};
