import React, { useEffect } from 'react';

import VisualFlow from 'app/modules/admin/visualFlow/VisualFlow';
import { useHistory } from 'react-router-dom';
import useStore from 'app/store';

const VisFlow: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history?.location?.pathname);
    }, []);
    return (
        <>
            <VisualFlow />
        </>
    );
};

export default VisFlow;
