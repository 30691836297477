import React from 'react';
import './NewsFeedLanding.scss';

import SearchNews from '../searchnews/SearchNews';

import { useNewsFeedHomePageArticles, useGetUserPreferences } from 'app/services/queries';
import { Trans, useTranslation } from 'react-i18next';
import CategoryWiseList from '../categoryWiseList/CategoryWiseList';
import EmailSubscribe from 'app/modules/user/emailSubscribe/EmailSubscribe';
import AuthorSpotlight from '../authorSpotlight/AuthorSpotlight';
import { Loader } from 'app/components/loader/Loader';
import { Link } from 'react-router-dom';
import NoData from 'app/components/noData/NoData';
import TopNews from './topNews/TopNews';

const NewsFeedLanding: React.FC = () => {
    const { t: translate } = useTranslation();
    const { isFetched, data: articles, isLoading } = useNewsFeedHomePageArticles();
    const { isFetched: isFetchedPreferences, data: userPreferences } = useGetUserPreferences();

    let showData =
        articles?.industryInsights?.length > 0 ||
        articles?.latestFromSurface?.length > 0 ||
        articles?.productArticles?.length > 0 ||
        articles?.surfacePrograms?.length > 0 ||
        articles?.topStories?.length > 0;
    if (isLoading || !isFetched || !isFetchedPreferences) {
        return <Loader classNames="height-70" />;
    } else {
        return (
            <>
                <div className="news-banner-container">
                    <div className="container">
                        <div className="strip  p-l-20 p-r-20 border-radius--8 m-t-10">
                            <div className="d-flex--between ai-center">
                                <div className="">
                                    <div className="strip-txt">
                                        <span className="bld">
                                            <Trans>News Feed</Trans>
                                        </span>
                                        {showData && (
                                            <Link to={`/all-news`} className="view-all-link">
                                                View all news
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="">
                                    {showData && (
                                        <SearchNews placeholder={'Search news'} iconName="Search" animate={false} type="news" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {showData ? (
                        <>
                            <div className="data-container">
                                {isFetched ? <TopNews articles={articles} /> : <Loader classNames="m-t-20 m-b-50 height-70" />}
                            </div>
                            {isFetchedPreferences && !userPreferences?.newsletterSubscription && <EmailSubscribe type="news" />}
                            {isFetched && articles?.surfacePrograms?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        title={translate('Surface Program')}
                                        articles={articles?.surfacePrograms}
                                        categoryName="Program"
                                        showCardBorder={true}
                                    />
                                </div>
                            )}
                            {isFetched && articles?.productArticles?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        iconColor="icon-white"
                                        title={translate('Product News')}
                                        articles={articles?.productArticles}
                                        background="gradient--purple"
                                        categoryName="Product"
                                        showCardBorder={false}
                                    />
                                </div>
                            )}
                            {isFetched && articles?.industryInsights?.length > 0 && (
                                <div className="data-container">
                                    <CategoryWiseList
                                        title={translate('Industry Insights')}
                                        articles={articles?.industryInsights}
                                        categoryName="Industry"
                                        showCardBorder={true}
                                    />
                                </div>
                            )}
                            <AuthorSpotlight />
                        </>
                    ) : (
                        <NoData />
                    )}
                </div>
            </>
        );
    }
};

export default NewsFeedLanding;
