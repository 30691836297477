import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import { containsHashtag, escapeToSafeCharacters, getQueryParam, keyPress, setQueryParam } from 'app/components/helpers';
import { Loader } from 'app/components/loader/Loader';
import Pagination from 'app/components/pagination/Pagination';
import { useDownloadSearchResults, useSearchResult } from 'app/services/mutations';
import { useTeamLookUpData } from 'app/services/queries';
import useStore from 'app/store';
import { isUser } from 'app/utils/authUtilities';
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import './SearchResults.scss';
import { SearchResultSection } from './searchResultSection/SearchResultSection';
interface IParams {
    search: string;
    searchType?: 'HashTag' | 'Tag';
}

const SearchResults = () => {
    const displayPageSize = 21;
    const history = useHistory();
    const location = useLocation();
    const initialRender = useRef(true);
    const { t: translate } = useTranslation();

    const {
        mutate: searchForData,
        data: searchResults,
        isSuccess: isSuccessSearch,
        isLoading: isLoadingSearchResults
    } = useSearchResult();
    const {
        mutate: downloadSearchResultMutation,
        data: downloadSearchResultData,
        isLoading: isLoadingDownloadSearchResult,
        isSuccess: isSuccessDownloadSearchResult
    } = useDownloadSearchResults();
    const { search: searchParam, searchType } = useParams<IParams>();
    const { data: teams, isLoading: isTeamsLoading } = useTeamLookUpData();

    const [listData, setListData] = useState([]);
    const [searchField, setSearchField] = useState('Default');
    const [searchResultCount, setSearchResultCount] = useState(0);
    const [isAdvancedSearch, setIsAdvancedSearch] = useState(true);
    const [highlightFields, setHighlightFields] = useState(['Summary', 'Description']);
    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history).page) || 1);

    const userSearchValue = useStore((state: any) => state.userSearchValue);
    const searchOrigin = useStore((state: any) => state.searchOrigin);
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const userDetails = useStore((state: any) => state.userDetails);
    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);

    // helper functions
    const handleDownloadSearchResults = () => {
        let pagesizeValue = searchResults?.data.SearchResult.TotalCount;
        if (pagesizeValue) {
            if (pagesizeValue > 1000) {
                pagesizeValue = 1000;
            }
        } else {
            pagesizeValue = 0;
        }
        downloadSearchResultMutation({
            searchTerm: userSearchValue,
            IsAdminSearch: true,
            pageIndex: 1,
            pageSize: pagesizeValue,
            highlightPreTag: '<b class=highlight>',
            highlightPostTag: '</b>',
            HighlightFields: isAdvancedSearch ? ['Summary', 'Description'] : [],
            searchField: isAdvancedSearch ? 'Default' : 'TitleOnly',
            searchOption: 'HelpRequest',
            select: []
        });
    };

    const searchTypeChange = () => {
        setQueryParam(history, '/search-results/requests', { page: 1 }, false, {
            ...stateData,
            isAdvancedSearch: !isAdvancedSearch,
            highlightFields: !isAdvancedSearch ? ['Summary', 'Description'] : [],
            searchField: !isAdvancedSearch ? 'Default' : 'TitleOnly'
        });
    };

    const showDownloadBtn = () => {
        return (
            !isUser(userDetails?.userRoles) &&
            (searchOrigin?.includes('manage-requests') ||
                (!isUser(userDetails?.userRoles) && searchOrigin === '/') ||
                searchOrigin?.includes('report') ||
                searchOrigin?.includes('request-detail') ||
                searchOrigin?.includes('visual-flow'))
        );
    };

    useEffect(() => {
        setListData([]);
        if (userSearchValue?.trim()) {
            let payload = {
                ...{
                    searchTerm: escapeToSafeCharacters(userSearchValue),
                    pageIndex: pageIndex,
                    pageSize: displayPageSize,
                    highlightPreTag: '<b class=highlight>',
                    highlightPostTag: '</b>',
                    HighlightFields: isAdvancedSearch ? ['Summary', 'Description'] : [],
                    searchField: isAdvancedSearch ? 'Default' : 'TitleOnly',
                    searchOption: 'HelpRequest',
                    select: [],
                    IsAdminSearch:
                        !isUser(userDetails?.userRoles) &&
                        (searchOrigin.includes('manage-requests') ||
                            (!isUser(userDetails?.userRoles) && searchOrigin === '/') ||
                            searchOrigin.includes('report') ||
                            searchOrigin.includes('request-detail') ||
                            searchOrigin.includes('visual-flow'))
                },
                ...(searchType && { TagSearchType: searchType })
            };
            if (containsHashtag(userSearchValue || '')) {
                payload.TagSearchType = 'HashTag';
                payload.searchTerm = userSearchValue?.replace('#', '');
            }
            searchForData(payload);
        }
    }, [userSearchValue, isAdvancedSearch, pageIndex]);

    useEffect(() => {
        const locationData: any = location?.state;

        if (locationData?.userSearchValue) {
            if (
                initialRender.current === false &&
                locationData?.userSearchValue?.toLowerCase()?.replaceAll('#', '') ===
                    userSearchValue?.toLowerCase()?.replaceAll('#', '') &&
                userSearchValue.trim()
            ) {
                setQueryParam(history, '/search-results/requests', { page: 1 }, true, {
                    ...stateData,
                    userSearchValue: userSearchValue
                });
            }
        } else {
            setQueryParam(history, '/search-results/requests', { page: 1 }, true, {
                ...stateData,
                userSearchValue: userSearchValue
            });
        }
    }, [userSearchValue]);

    useEffect(() => {
        if (isSuccessSearch) {
            if (searchResults?.data.SearchResult.Items) {
                setListData(searchResults?.data.SearchResult.Items);
                setSearchResultCount(searchResults?.data.SearchResult.TotalCount);
            } else {
                setSearchResultCount(0);
            }
        } else {
            setSearchResultCount(0);
        }
    }, [isSuccessSearch]);

    useEffect(() => {
        if (!isLoadingDownloadSearchResult && isSuccessDownloadSearchResult) {
            let blobData = new Blob([downloadSearchResultData.data], { type: 'text/csv;charset=utf-8' });
            saveAs(blobData, `SearchResults_${new Date().getTime()}.csv`);
        }
    }, [isLoadingDownloadSearchResult, isSuccessDownloadSearchResult]);

    useEffect(() => {
        if (searchParam) {
            // setUserSearchValue(searchParam);
            setQueryParam(
                history,
                `/search-results/requests/${searchType}/${searchParam}`,
                { page: Number(getQueryParam(history).page) || 1 },
                true,
                {
                    ...stateData,
                    userSearchValue: searchParam
                }
            );
        }
        // if (!searchParam && !userSearchValue) setUserSearchValue('');
    }, [searchParam]);

    const stateData = useMemo(() => {
        return {
            userSearchValue: userSearchValue,
            highlightFields: highlightFields,
            searchField: searchField,
            searchOrigin: searchOrigin,
            searchType: searchType,
            isAdvancedSearch: isAdvancedSearch
        };
    }, [userSearchValue, highlightFields, searchField, searchOrigin, searchType, isAdvancedSearch]);

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData) {
            setHighlightFields(locationData?.highlightFields);
            setSearchField(locationData?.searchField);
            setSearchOrigin(locationData?.searchOrigin);
            setUserSearchValue(locationData?.userSearchValue);
            setIsAdvancedSearch(locationData?.isAdvancedSearch);
            setPageIndex(Number(getQueryParam(history).page ?? 1));
        } else {
        }
    }, [history?.location?.search, location?.state]);

    return (
        <div className="container admin-search-container">
            <div className="custom-row">
                <div className="left-section">
                    <div className="result-info-header">
                        {' '}
                        {!isAdvancedSearch ? translate('Title Search Results') : translate('Advanced Search Results')}{' '}
                        {searchResultCount ? `(${searchResultCount})` : `(${0})`}
                    </div>
                    <div
                        className="advanced-search"
                        tabIndex={0}
                        onClick={searchTypeChange}
                        onKeyPress={(e: any) => keyPress(e, searchTypeChange)}>
                        {' '}
                        {!isAdvancedSearch ? translate('Advanced Search') : translate('Title Only Search')}{' '}
                    </div>
                </div>
                <div className="right-section">
                    {isLoadingDownloadSearchResult && (
                        <Loader loadingText="Downloading..." size={SpinnerSize.medium} labelPosition="left" />
                    )}
                    {showDownloadBtn() && (
                        <DefaultButton
                            primary
                            text={translate('Download Search Results')}
                            onClick={handleDownloadSearchResults}
                            data-testid="default-button"
                        />
                    )}
                </div>
            </div>

            <div className="search-list-container" id="search-list-container">
                {isLoadingSearchResults && (
                    <div className="m-t-50">
                        <Spinner label="Search results are loading. Please wait..." size={SpinnerSize.large} />
                    </div>
                )}
                {listData?.length > 0 && (
                    <div className="scroll-panel " id="scroll-panel">
                        {isSuccessSearch && listData.length > 0 && (
                            <>
                                {listData.map((item: any, itemIndex: number) => (
                                    <div key={itemIndex}>
                                        <SearchResultSection
                                            searchResult={item}
                                            key={item?.Document?.UnifiedTrackingId}
                                            teams={teams}
                                            isTeamsLoading={isTeamsLoading}
                                        />
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                )}

                {!isLoadingSearchResults && listData?.length <= 0 && (
                    <div className="scroll-panel " id="scroll-panel">
                        <p>
                            <Trans>Sorry, we could not find anything that matches your search :(</Trans>
                        </p>
                    </div>
                )}
            </div>
            {listData && listData.length > 0 && (
                <div className="pagination-container">
                    <Pagination
                        pageCount={searchResults?.data.SearchResult.TotalPages}
                        setPageNumber={setPageIndex}
                        initialPage={pageIndex - 1}
                        totalCount={searchResults?.data.SearchResult.TotalCount}
                        resultsPerPage={displayPageSize}
                        updateURL={true}
                        stateData={stateData}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchResults;
