import { IButtonStyles, IDropdownStyles, ITextFieldStyles, IDropdownStyleProps, IStyleFunctionOrObject } from '@fluentui/react';
import { ListBuilderCondition } from 'app/enums/ListBuilderCondition.enum';

export const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: 'white',
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
        height: '50px',
        checked: {
            border: '2px solid green'
        }
    }
};

export const pickerTextFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        width: '160px',
        borderColor: '#C7E0F4'
    },
    field: {
        height: '38px'
    },
    fieldGroup: {
        height: '38px'
    }
};

export const listNameStyles: Partial<ITextFieldStyles> = {
    root: { height: '34px', width: '720px', margin: '20px', borderColor: '#C7E0F4' },
    field: {
        border: '1px solid #C7E0F4'
    }
};

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 180,
        boxSizing: 'border-box',
        background: '#FFFFFF'
    },
    dropdownItemsWrapper: {
        boxSizing: 'border-box',
        width: 180,
        height: 'auto',
        background: '#FFFFFF',
        borderRadius: 8
    },
    dropdownItems: {
        color: '#0078D4',
        dropdownOptionText: {
            color: '#0078D4'
        }
    },
    dropdownItemSelected: {
        color: '#0078D4 !important'
    },
    title: {
        borderRadius: '12px',
        display: 'flex',
        width: 180,
        height: 40,
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 2,
        color: `${'#0078D4'}`
    },
    caretDown: {
        paddingTop: 4,
        color: `${'#0078D4'}`
    }
};

export const dropdownCountryStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 140,
        boxSizing: 'border-box',
        background: '#FFFFFF'
    },
    dropdownItemsWrapper: {
        boxSizing: 'border-box',
        width: 180,
        height: 'auto',
        background: '#FFFFFF',
        border: '1px solid #0078D4',
        borderRadius: 8
    },
    dropdownItems: {
        color: '#0078D4',
        dropdownOptionText: {
            color: '#0078D4'
        }
    },
    dropdownItemSelected: {
        color: '#0078D4 !important'
    },
    title: {
        borderRadius: '12px',
        display: 'flex',
        width: 140,
        height: 40,
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 2,
        color: `${'#0078D4'}`
    },
    caretDown: {
        paddingTop: 4,
        paddingRight: 2,
        color: `${'#0078D4'}`
    }
};

export const conditionDropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 150,
        boxSizing: 'border-box',
        background: '#FFFFFF'
    },
    dropdownItemsWrapper: {
        boxSizing: 'border-box',
        width: 150,
        height: 'auto',
        background: '#FFFFFF',
        borderRadius: 8
    },
    callout: {
        width: '100px',
        minWidth: '100px !important'
    },
    dropdownItems: {
        color: '#0078D4',
        dropdownOptionText: {
            color: '#0078D4'
        }
    },
    dropdownItemSelected: {
        color: '#0078D4 !important'
    },
    title: {
        borderRadius: '12px',
        display: 'flex',
        width: 150,
        height: 40,
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 2,
        color: `${'#0078D4'}`
    },
    caretDown: {
        paddingTop: 4,
        paddingRight: 2,
        color: `${'#0078D4'}`
    }
};

export const pickerDropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 150,
        boxSizing: 'border-box',
        background: '#FFFFFF'
    },
    dropdownItemsWrapper: {
        boxSizing: 'border-box',
        width: 150,
        height: 'auto',
        background: '#FFFFFF',
        borderRadius: 8
    },
    callout: {
        width: '100px',
        minWidth: '100px !important'
    },
    dropdownItems: {
        color: '#0078D4',
        dropdownOptionText: {
            color: '#0078D4'
        }
    },
    dropdownItemSelected: {
        color: '#0078D4 !important'
    },
    title: {
        borderRadius: '12px',
        display: 'flex',
        width: 150,
        height: 40,
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 2,
        color: `${'#0078D4'}`
    },
    caretDown: {
        paddingTop: 4,
        paddingRight: 2,
        color: `${'#0078D4'}`
    }
};

export const handleSecondWordAttribute = (field: any, condition: any) => {
    switch (field) {
        case 'Status':
        case 'Assigned To':
        case 'Tags':
        case 'TPID':
        case 'Relationship':
        case 'Attachment':
        case 'Priority':
        case 'CSS case number':
            switch (condition){
                case 1:
                case 2:
                    return "to";
                case 12:
                default:
                    return "equals";
            }  
        case 'Customer country':
        case 'DCF Value':
        case 'Aging':
        case 'Serial number attached':
        case 'MSX Status':
        case 'Proof of purchase attached':
        case 'Is This a DCF Request':
        case 'Troubled Product':
        case 'Bug Type':
            return 'to';
        case 'MSX Opportunity':
            switch(condition) {
                case ListBuilderCondition.State:
                    return 'equals';
                default:
                    return 'to';
            }
        case 'Title':
        case 'Created On':
        case 'Description':
        case 'Total Device Risk':
        case 'Total Revenue Risk':
        case 'Customer Count':
        case 'Bug Number':
        case 'MSX devices':
        case 'MSX revenue':
            return 'this';
        case 'Submitted By':
            return 'that';
        case 'End customer':
            switch (condition){
                case 1:
                case 2:
                    return "to";
                case 3:
                case 4:
                default:
                    return "this";
            }    
        default:
            return "to";
    }
}

export const handleFirstWordAttribute = (field: any, condition: any) => {
    switch (field) {
        case 'Status':
        case 'Assigned To':
        case 'Tags':
        case 'TPID':
        case 'Relationship':
        case 'Attachment':
        case 'Priority':
        case 'CSS case number':
        case 'Customer country':
        case 'DCF Value':
        case 'Aging':
        case 'Serial number attached':
        case 'MSX Status':
        case 'Proof of purchase attached':
        case 'Is This a DCF Request':
        case 'Troubled Product':
        case 'Bug Type':
        case 'MSX Opportunity':
        case 'End customer':
        case 'Title':
        case 'Description':
        case 'Submitted By':
            return 'that';
        case 'Created On':
        case 'Total Device Risk':
        case 'Total Revenue Risk':
        case 'Customer Count':
        case 'Bug Number':
        case 'MSX revenue':
            return 'that is';
        case 'MSX devices':
            return 'that are';
        default:
            return "that";
    }
}