import React, { useRef, useState } from 'react';
import { Text, Icon, Checkbox } from '@fluentui/react';
import './TeamBuilder.scss';
import { requestTeamTypeOptions } from '../options/Options';
import { Trans } from 'react-i18next';
import PlusIcon from '../plusIcon/PlusIcon';
import { keyPress } from 'app/components/helpers';

interface IProps {
    handlePlusIcon?: any;
    toggleSaveAs?: any;
    triggerPlusIcon?: any;
    onSelectChange?: any;
    options?: any;
    selectedKey?: any;
    setPlusIconPosition: any;
    plusIconPosition?: any;
    selectedKeys?: any;
    plusIconIndex: number;
    plusIconDisabled: boolean;
    plusIconRef: any;
}

const TeamBuilder: React.FC<IProps> = ({
    selectedKeys,
    plusIconPosition,
    handlePlusIcon,
    toggleSaveAs,
    triggerPlusIcon,
    onSelectChange,
    plusIconIndex,
    plusIconDisabled,
    plusIconRef,
}) => {
    const dropDownRef = useRef(null);

    const [showDropdown, setShowDropdown] = useState(false);

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };
    
    const getLabel = () => {
        if (selectedKeys?.filter((ele: any) => ele?.key === 'all_request').length !== 0) {
            return 'All Request Types';
        } 
        else if (selectedKeys?.length === 0){
            return 'Request Types'
        }
        else {
            let arr: string[] = [];
            selectedKeys.forEach((item: any) => {
                if (item.dependentProperties) {
                    arr.push(item?.key + ' - All');
                } else {
                    let parent = selectedKeys.filter((it: any) => it?.key === item?.parentProperty)[0];
                    if (selectedKeys.filter((ele: any) => ele?.key === parent?.key).length !== 0) {
                        arr.push(parent?.key + ' - All');
                    } else {
                        arr.push(item?.text);
                    }
                }
            });

            let res = removeDuplicates(arr);
            return res.join(', ');
        }
    };

    const isChecked = (ele: string) => {
        return selectedKeys.filter((item: any) => item.key === ele).length !== 0;
    };

    const removeDuplicates = (arr: string[]) => {
        let uniqueArr = arr.filter((ele, index) => {
            return arr.indexOf(ele) === index;
        });
        return uniqueArr;
    };

    useDropDownDismiss(dropDownRef, onToggleShow);

    return (
        <div className="listBuilder-container">
            <div>
                <PlusIcon
                    triggerPlusIcon={triggerPlusIcon}
                    toggleSaveAs={toggleSaveAs}
                    handlePlusIcon={handlePlusIcon}
                    plusIconPosition={plusIconPosition}
                    plusIconIndex={plusIconIndex}
                    plusIconDisabled={plusIconDisabled}
                    plusIconRef={plusIconRef}
                />
            </div>
            <div className="listBuilder">
                <div className="lb-labelStyles">
                    <Trans>
                        <Text>I want to build a list of tickets which are of request type</Text>
                    </Trans>
                </div>
                <div className="request-type-filter filter-cont">
                    {origin === 'ADMIN' && <Text className="filter-heading">Request type</Text>}

                    <div
                        className={`placeholder-title row-flex-box align-center`}
                        tabIndex={0}
                        data-testid="requestType-filter-open-btn-test"
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        onClick={() => onToggleShow()}>
                        <Text className="placeholder-label">{getLabel()}</Text> <Icon iconName="ChevronDown" />
                    </div>

                    {showDropdown && (
                        <div ref={dropDownRef} className="dropdown-cont">
                            <div
                                tabIndex={0}
                                data-testid="requestType-filter-close-btn-test"
                                onKeyPress={(e: any) => {
                                    keyPress(e, onToggleShow);
                                }}
                                onClick={() => onToggleShow()}
                                className="row-flex-box align-center dropdown-header">
                                <Text>Request Type</Text>
                                <Icon iconName="ChevronUp" />
                            </div>

                            {requestTeamTypeOptions.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onSelectChange(
                                                item.key as string,
                                                item.dependentProperties as string[],
                                                item.parentProperty as string
                                            );
                                        }
                                    }}
                                    className={`dropdown-item ${item.parentProperty ? 'child-item' : ''}  ${
                                        item.dependentProperties ? 'parent-item' : ''
                                    }`}>
                                    <Checkbox
                                        label={item.text}
                                        tabIndex={0}
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        checked={isChecked(item.key as string)}
                                        onChange={() =>
                                            onSelectChange(
                                                item.key as string,
                                                item.dependentProperties as string[],
                                                item.parentProperty as string
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default TeamBuilder;
