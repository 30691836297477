import { Icon, IconButton, IIconProps, Image, TextField, Link } from '@fluentui/react';
import { fieldValidation, keyPress } from 'app/components/helpers';
import { useQueryAllDeviceList } from 'app/services/queries';
import defaultDeviceIcon from 'app/static/icons/devices_default_icon.svg';
import { StringConstants } from 'app/utils/constants';
import { getDeviceIcon } from 'app/utils/SVGIcons';
import { DefaultButton, Text } from 'office-ui-fabric-react';
import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './AddProduct.scss';

interface ISearchProps {
    setDisplayScreen?: Function;
    onDismiss?: Function;
    addOrUpdate?: String;
    onSuccessHandler?: Function;
    selectedRequest?: any;
    handleAddProduct?: Function;
    setCssNumberText?: Function;
    addedDevices?: any[];
    setAddedDevices?: (addedDevices: any[]) => void;
}

const AddProduct: React.FC<ISearchProps> = (props) => {
    const { t: translate } = useTranslation();
    const { onDismiss, setDisplayScreen, addOrUpdate, setCssNumberText, addedDevices, setAddedDevices } = props;
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const { data: getDeviceList } = useQueryAllDeviceList();
    const myRef = useRef(null);
    const [selectedProductId, setSelectedProductId] = React.useState('');
    const [selectedModel, setSelectedModel] = React.useState([]);
    const [modelsList, setModelsList] = React.useState([]);
    const [, setSelectedProductName] = React.useState('');
    const modelListRef = useRef(null);
    const [cartHText, setCartHText] = React.useState(false);
    const deviceSelected = (item: any) => {
        setCartHText(false);
        if (selectedProductId !== item.id) {
            setSelectedProductId(item.id);
            let ar = [...item.devices];
            let ex: any = [];
            ar.forEach((ele: any) => {
                let it = {};
                if (selectedModel.length === 0) {
                    it = { ...ele, added: false };
                    ex.push(it);
                } else {
                    selectedModel.every((em: any) => {
                        if (em.id === ele.id) {
                            it = em;
                            return false;
                        } else {
                            it = { ...ele, added: false };
                            return true;
                        }
                    });
                    ex.push(it);
                }
            });

            setModelsList(ex);
        } else {
            setSelectedProductId('');
            setSelectedProductName(StringConstants.NONE);
            let ex: any = [];
            selectedModel.forEach((ele) => {
                let flag = false;
                item.devices.every((it: any) => {
                    if (
                        it.id === ele.id &&
                        props?.selectedRequest?.devices?.find((device: any) => device.id === ele.id) === undefined &&
                        addOrUpdate === StringConstants.ADD &&
                        props?.addedDevices?.find((device: any) => device.id === ele.id) === undefined
                    ) {
                        flag = true;
                        return false;
                    } else {
                        return true;
                    }
                });
                if (!flag) {
                    ex.push(ele);
                }
            });
            setSelectedModel(ex);
        }
    };

    const onCountChange = (e: any) => {
        setCartHText(false);
        selectedModel.forEach((ele: any, index) => {
            if (ele.name === e.target.name) {
                selectedModel[index].count = e.target.value;
                if (!selectedModel[index].count.match(/^[0-9]*[1-9][0-9]*$/)) setCartHText(true);
            }
        });
        setSelectedModel([...selectedModel]);
    };

    const clickHandler = () => {
        setCssNumberText(false);
        setDisplayScreen('addMultiDetails');
    };

    const modelSelected = (item: any, categoryIdVal: any) => {
        setCartHText(false);
        if (selectedModel.indexOf(item) === -1) {
            item.categoryId = categoryIdVal;
            let newItem = [...selectedModel, item];
            setSelectedModel(newItem);

            if (addOrUpdate === StringConstants.ADD) {
                setAddedDevices(newItem);
            }
        } else {
            const i = selectedModel.indexOf(item);
            if (!selectedModel[i].added) {
                let ar = [...selectedModel];
                ar.splice(i, 1);
                setSelectedModel(ar);

                if (addOrUpdate === StringConstants.ADD) {
                    setAddedDevices(ar);
                }
            }
        }
    };

    const addToCart = (item: any) => {
        setCartHText(false);
        if (
            item.count?.trim() === StringConstants.NONE ||
            item.count?.trim() === undefined ||
            item.count?.trim() === StringConstants.ZERO
        ) {
            setCartHText(
                item.count === undefined
                    ? true
                    : item.count === StringConstants.NONE ||
                      item.count === StringConstants.ZERO ||
                      !fieldValidation(item.count, StringConstants.NUMBER, selectedModel)
                    ? true
                    : false
            );
        } else {
            const i = modelsList.indexOf(item);
            let eg = [...modelsList];
            eg[i].added = true;
            setModelsList(eg);
        }
    };

    function isAdded(item: any) {
        return selectedModel.filter((ele: any) => ele.id === item.id).length !== 0;
    }

    const removeSelectedModel = (item: any) => {
        const i = selectedModel.indexOf(item);
        let eg = [...selectedModel];
        eg[i].added = false;
        setSelectedModel(eg);
        let itemRemoved = selectedModel.filter((el) => el.id !== item.id);
        setSelectedModel([...itemRemoved]);
        let zeroModels = selectedModel.filter((x) => x.added && x.count === StringConstants.ZERO);
        setCartHText(zeroModels && zeroModels.length > 0 ? true : false);
    };

    useEffect(() => {
        var selectedItems: any = [];
        if (addOrUpdate === StringConstants.ADD) {
            setSelectedModel(addedDevices);
            return;
        }
        if (getDeviceList && props.selectedRequest && (props.selectedRequest.devices || props.selectedRequest.deviceModels)) {
            if (!props.selectedRequest.devices && props.selectedRequest.deviceModels) {
                props.selectedRequest.devices = props.selectedRequest.deviceModels;
            }
            props.selectedRequest.devices.map((item: any, index: any) => {
                let iconURL = getDeviceList
                    ?.find((product: any) => product.id === item.categoryId)
                    ?.devices?.find((model: any) => model.id === item.id)?.iconUrl;
                item.count = item.deviceCount;
                item.added = true;
                item.iconUrl = iconURL ? iconURL : defaultDeviceIcon;
                selectedItems.push(item);
            });
        }
        setSelectedModel(selectedItems);
    }, [getDeviceList]);

    const update = () => {
        if (cartHText) return;

        var emptyItems = selectedModel.filter((x) => x.added && (x.count === 0 || x.count === StringConstants.NONE));
        if (emptyItems && emptyItems.length > 0) {
            setCartHText(true);
            return;
        }

        props.selectedRequest.devices = [];
        selectedModel
            .filter((x) => x.added)
            .forEach((item: any) => {
                if (item.id === 0 || item.id === StringConstants.ZERO) {
                    getDeviceList.map((deviceList: any) => {
                        if (item.id !== 0 && item.id !== '0') return;
                        deviceList.devices.map((device: any) => {
                            if (item.id !== 0 && item.id !== StringConstants.ZERO) return;
                            if (device.name === item.name) {
                                item.id = device.id;
                            }
                        });
                    });
                }

                props.selectedRequest.devices.push({
                    categoryId: item.categoryId,
                    deviceCount: parseInt(item.count),
                    count: item.count,
                    iconUrl: item.iconUrl,
                    id: item.id,
                    name: item.name,
                    added: item.added
                });
            });

        props.handleAddProduct();
    };

    return (
        <>
            <div className="addImpact-container">
                <div>
                    <div className={`close-model `}>
                        <IconButton
                            role="button"
                            aria-label="close popup"
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            tabIndex={0}
                            onKeyPress={(e: any) => keyPress(e, onDismiss)}
                            onClick={() => onDismiss()}
                            data-testid="icon-button1"
                        />
                    </div>
                    <div className="modal-heading">
                        <span>
                            <Trans>
                                {addOrUpdate === StringConstants.UPDATE ? StringConstants.UPDATE : StringConstants.ADD} products
                            </Trans>
                        </span>
                    </div>
                    <div
                        className={`modal-body  ${
                            selectedModel && selectedModel.length > 0 && selectedModel[0]?.added === true
                                ? 'custom-product-list'
                                : ''
                        }`}>
                        <div
                            className={`body-main-container bg-white ${
                                !(selectedModel && selectedModel.length > 0 && selectedModel[0]?.added === true)
                                    ? 'only-product-list'
                                    : 'selected-product-list'
                            }`}>
                            <div className="product-list-header">Select the troubled product family</div>
                            <div className="products-list" ref={myRef}>
                                {getDeviceList &&
                                    getDeviceList.map((item: any, index: any) => {
                                        return (
                                            <>
                                                <div
                                                    aria-label={`${selectedProductId === item.id ? 'Selected Product' : ''} ${
                                                        item.name
                                                    }`}
                                                    key={item.id}
                                                    id={index}
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        deviceSelected(item);
                                                    }}
                                                    onKeyPress={(e) => keyPress(e, () => deviceSelected(item))}
                                                    className={` mar-rt ${
                                                        selectedProductId === item.id ? 'selected-product' : 'device-animate'
                                                    }`}
                                                    data-testid="devicelist-div">
                                                    <Image src={getDeviceIcon(item.iconUrl)} alt="Surface" />
                                                    <Text>{item.name}</Text>
                                                </div>
                                            </>
                                        );
                                    })}
                            </div>
                            {selectedProductId && (
                                <div className={`select-model`}>
                                    <label className="device-title">
                                        <Trans>Select the troubled models</Trans>
                                    </label>
                                    <div className={`model-list-container`}>
                                        <div className="shift-icons model-list" ref={modelListRef}>
                                            {modelsList &&
                                                modelsList.length > 0 &&
                                                modelsList?.map((item: any, i: any) => {
                                                    return (
                                                        <>
                                                            <div
                                                                aria-label={`${isAdded(item) ? 'Selected Product' : ''} ${
                                                                    item.name
                                                                }`}
                                                                key={item.name}
                                                                tabIndex={0}
                                                                onClick={(e: any) => {
                                                                    if (
                                                                        e.target &&
                                                                        e.target !==
                                                                            document.querySelector(`#model-field-${i}`) &&
                                                                        e.target !== document.querySelector(`#add-text-${i}`)
                                                                    ) {
                                                                        modelSelected(item, selectedProductId);
                                                                    }
                                                                }}
                                                                className={`mar-rt p-b-10 ${
                                                                    isAdded(item)
                                                                        ? 'selected-product'
                                                                        : 'device-animate add-align-prop'
                                                                } ${item.added ? 'added' : ''}`}
                                                                data-testid="modelList-div"
                                                                onKeyPress={(e) =>
                                                                    keyPress(e, () => {
                                                                        if (
                                                                            e.target &&
                                                                            e.target !==
                                                                                document.querySelector(`#model-field-${i}`)
                                                                        ) {
                                                                            modelSelected(item, selectedProductId);
                                                                        }
                                                                    })
                                                                }>
                                                                <Image src={getDeviceIcon(item.iconUrl)} alt="Surface icon" />

                                                                <Text className="model-name">{item.name}</Text>
                                                                <div>
                                                                    {isAdded(item) && (
                                                                        <TextField
                                                                            id={`model-field-${i}`}
                                                                            onChange={onCountChange}
                                                                            placeholder={translate('Quantity')}
                                                                            name={item.name}
                                                                            value={item.count}
                                                                            data-testid="add-text-field-test"
                                                                            type="number"
                                                                            className="regular-text-field quantity-field"
                                                                            min="0"
                                                                            autoFocus
                                                                        />
                                                                    )}

                                                                    {isAdded(item) && (
                                                                        <Text
                                                                            id={`add-text-${i}`}
                                                                            tabIndex={0}
                                                                            data-testid="add-btn-test"
                                                                            onClick={() => addToCart(item)}
                                                                            onKeyPress={(e) => keyPress(e, () => addToCart(item))}
                                                                            className="btn-add-product-modal">
                                                                            {item.added ? 'Added' : 'Add'}
                                                                        </Text>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                        </div>
                                    </div>{' '}
                                </div>
                            )}
                            {cartHText && (
                                <Text className="helper-txt error-msg">
                                    <Trans>Please add the number of products.</Trans>
                                </Text>
                            )}
                        </div>
                        {selectedModel && selectedModel.length > 0 && selectedModel.find((x) => x.added) && (
                            <div data-testid="cart-render-test" className="cart-section">
                                <div className="modal-cart-heading">
                                    <Trans>Added troubled devices</Trans>
                                </div>
                                <div className="cart-items-list">
                                    {selectedModel.map((item: any) => {
                                        if (item.added) {
                                            return (
                                                <div className={`item`}>
                                                    <h4>{item.name}</h4>
                                                    <div className="added-device-details">
                                                        <img src={getDeviceIcon(item.iconUrl)} alt="surface icon" />

                                                        <div className={`text-field p-r-20 p-l-20`}>
                                                            <TextField
                                                                type="number"
                                                                onChange={onCountChange}
                                                                name={item.name}
                                                                value={item.count}
                                                            />
                                                        </div>
                                                        <div
                                                            className={`trash-icon`}
                                                            data-testid="trash-button-test"
                                                            tabIndex={0}
                                                            onKeyPress={(e: any) => keyPress(e, () => removeSelectedModel(item))}
                                                            onClick={() => removeSelectedModel(item)}>
                                                            <Icon iconName="trash" />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return <div></div>;
                                        }
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`${
                        addOrUpdate === StringConstants.UPDATE ? 'event-section-no-back-button' : 'event-section p-t-30'
                    }`}>
                    <div className={`${addOrUpdate === StringConstants.UPDATE ? 'hide' : 'back-btn'}`}>
                        <Text>
                            <Link
                                aria-label="back button"
                                tabIndex={0}
                                onClick={clickHandler}
                                onKeyPress={(e: any) => keyPress(e, clickHandler)}>
                                <span>Back</span>
                            </Link>
                        </Text>
                    </div>
                    <div className={`${selectedModel[0]?.added ? 'show' : 'hide'} cancel-update-container`}>
                        <div className="cancel-btn" data-testid="cancel-modal-test" onClick={() => onDismiss()}>
                            Cancel
                        </div>
                        <DefaultButton data-testid="update-modal-test" className="default add-cust-btn" onClick={() => update()}>
                            <Text>Update</Text>
                        </DefaultButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddProduct;
