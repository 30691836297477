import React, { useEffect, useRef, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import ReqExpBanner from 'app/modules/user/newReqExpBanner';
import Address from './addressSection';
import useStore from 'app/store';
import ExpandIcon from 'app/static/icons/expand-icon.svg';
import CollapseIcon from 'app/static/icons/collapse-icon.svg';
import { Dialog, DialogFooter, IDropdownOption, Label, Spinner } from '@fluentui/react';
import errorIcon from 'app/static/icons/warning-red.svg';
import './index.scss';
import { DefaultButton, Icon, SpinnerSize } from '@fluentui/react';
import { Link, PrimaryButton, Text, Image, TextField } from '@fluentui/react';
import { Modal } from '@fluentui/react';
import momentBusinessDays from 'moment-business-days';
import SelectDevice from 'app/modules/user/newReqExperience/selectDevice';
import RequestPreview from 'app/modules/user/newReqExperience/requestPreview';

import NewCustomer from 'app/modules/user/newReqExperience/newCustomer/NewCustomer';
import Customer from 'app/modules/user/newReqExperience/customer/Customer';
import Action from 'app/modules/user/newReqExperience/action';
import AlternateContactDetails from 'app/modules/user/newReqExperience/alternativeContactDetails';
import CartItem from './CartItem';
import { CustomField } from './customTextFields';
import ActionSection from './actionSection';
import { IChoiceGroupOption } from '@fluentui/react';
import { Link as LinkRoute, useHistory } from 'react-router-dom';
import ScreenBg from './ScreenBg';
import moment from 'moment';
import quickLinksIcon from 'app/static/icons/quicklinks-icon.svg';
import {
    useRequestTypes,
    useMsxData,
    useSpeechToTextToken,
    useGetNewRequest,
    useGetRequestById,
    useGetCountries
} from 'app/services/queries';
import {
    useGettingRequestType,
    usePostRequestType,
    useSearchResult,
    useSaveRequest,
    usePostCommentsHashTags
} from 'app/services/mutations';
import { AddAttachments } from '../dropZone/AddAttachments';
import {
    addItemsToList,
    escapeToSafeCharacters,
    fieldValidation,
    getQuickLinksText,
    getURLForTeamAndType,
    isNumberGreaterThanZero,
    requestHelpSubmit
} from 'app/components/helpers';
import { Trans, useTranslation } from 'react-i18next';

import { IAddCustomerData, IRequest } from 'app/models/common/response';
import AddImpact from 'app/components/addCustomer/AddCustomer';
import { ReviewCustomer } from './reviewCustomer/index';
import { EditorState } from 'draft-js';
import { Loader } from 'app/components/loader/Loader';
import PromptModal from './PromptModal';
import NavigationPrompt from 'react-router-navigation-prompt';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';
import {
    blobStorageUpload,
    removeBlobFileSasToken,
    removeFailedBlobFiles,
    removeFailedUploadsFromAttachmentList,
    removeFailuresFromAttachedFiles
} from 'app/utils/blobStorageHelpers';
import { IAttachment, IBlobUploadFile } from 'app/models/common/post';
import { RouteConstants, StringConstants } from 'app/utils/constants';
import { TEAM_DETAILS } from 'app/modules/admin/contactDetails/constants';

const speechSdk = require('microsoft-cognitiveservices-speech-sdk');

const timestamp = new Date();

const RequestExperience: React.FC = () => {
    const history = useHistory();

    const searchContainer = useRef(null);
    const { t: translate } = useTranslation();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [descEditorState, setDescEditorState] = useState(EditorState.createEmpty());
    const [originalSelected, setOriginalSelected] = useState([]);

    const [customerSearchInput, setCustomerSearchInput] = useState('');
    const [customerSelected, setCustomerSelected] = useState(false);
    const [requestTypeFlow, setRequestTypeFlow] = useState(null);
    const addFiles = useStore((state: any) => state.addFiles);
    const attachedFiles = useStore((state: any) => state.attachedFiles);
    const setRedirectRequestType = useStore((state: any) => state.setRedirectRequestType);

    const quickLinksID = useStore((state: any) => state.quickLinksID);
    const setQuickLinksID = useStore((state: any) => state.setQuickLinksID);
    const redirectRequestType = useStore((state: any) => state.redirectRequestType);
    const setQuickLinksFLow = useStore((state: any) => state.setQuickLinksFLow);
    const quickLinksFLow = useStore((state: any) => state.quickLinksFLow);
    const [shrinkAnimation, setShrinkAnimation] = useState(false);
    const [requestSection, setRequestSection] = useState('selectDevice');
    const [requestOptions, setRequestOptions] = useState([]);
    const [requestType, setRequestType] = useState('');
    const [lastSelectedCustomerName, setLastSelectedCustomerName] = useState('');
    const [selectedRequestType, setSelectedRequestType] = useState('');
    const clearFiles = useStore((state: any) => state.clearFiles);
    const quickLinksValue = useStore((state: any) => state.quickLinksValue);
    const setQuickLinksValue = useStore((state: any) => state.setQuickLinksValue);
    const setOriginalRoutingML = useStore((state: any) => state.setOriginalRoutingML);
    const originalRoutingML = useStore((state: any) => state.originalRoutingML);
    const [addModelHText, setAddModelHText] = useState(false);
    const [addProductHText, setAddProductHText] = useState(false);
    const userDetails = useStore((state: any) => state.accountDetails.name);
    const [manualData, setManualData] = useState(false);
    const [customerEmail, setCustomerEmail] = useState('');
    const [contactNum, setContactNum] = useState('');
    const [customerShippingAddress, setCustomerShippingAddress] = useState('');
    const [currentSection, setCurrentSection] = useState('landing');
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [tempFlow, setTempFlow] = useState(null);
    const [newRevenueRisk, setNewRevenueRisk] = useState('');
    const [newDeviceRisk, setNewDeviceRisk] = useState('');
    const [issueHText, setIssueHText] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState<IDropdownOption>();
    const [customerEmailHText, setCustomerEmailHText] = useState(false);
    const [contactNumHText, setContactNumHText] = useState(false);
    const [customerShippingAddHText, setCustomerShippingAddHText] = useState(false);
    const [msxOpportunityId, setMsxOpportunityId] = useState('');
    const [msxStatus, setMsxStatus] = useState('');
    const [customerTPID, setCustomerTPID] = useState('');
    const [customerTPIDHText, setCustomerTPIDHText] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerAccountID, setCustomerAccountID] = useState('');
    const [customerAccountIDHText, setCustomerAccountIDHText] = useState(false);
    const [country, setCountry] = useState('');
    const [cssCaseNum, setCSSCaseNum] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [msxMatched, setMSXMatched] = useState(false);
    const [msxNotMatched, setMSXNotMatched] = useState(false);
    const [revenueRisk, setRevenueRisk] = useState('');
    const [impDevices, setImpDevices] = useState('');
    const [showSuggestion, setShowSuggestion] = React.useState(false);
    const [suggest, setSuggest] = React.useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [toggleSearch, setToggleSearch] = React.useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [newRequest, setNewRequest] = React.useState(false);
    const [dropDownValue, setDropDownValue] = useState('');

    // Rich text states
    const [requestDesc, setRequestDesc] = useState('');
    const [requestDescriptionToSubmit, setRequestDescriptionToSubmit] = useState<string>('');

    const [customerAltPage, setCustomerAltPage] = React.useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [emailID, setEmailID] = useState('');
    const [shippingAdd, setShippingAdd] = useState('');

    // Rich text states
    const [dateImportance, setDateImportance] = useState('');
    const [dateImportanceToSubmit, setDateImportanceToSubmit] = useState<string>('');

    const [selectedDCFKey, setSelectedDCFKey] = React.useState<string | undefined>();

    const [titleHText, setTitleHText] = useState(false);
    const [descHText, setDescHText] = useState(false);
    const [autoSuggestHText, setAutoSuggestHText] = useState(false);
    const [revenueHText, setRevenueHText] = useState(false);
    const [impDevicesHText, setImpDevicesHText] = useState(false);
    const [dateImportanceHText, setDateImportanceHText] = useState(false);
    const [emailHText, setEmailHText] = useState(false);
    const [caseNumHText, setCaseNumHText] = useState(false);
    const [emailValidText, setEmailValidText] = useState(false);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideCancelConfirmationDialog, { toggle: toggleCancelConfirmationDialog }] = useBoolean(true);
    const today = new Date(Date.now());
    today.setDate(today.getDate() + 1);
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 2);
    const { mutate: draftSaveRequest, data: savedDraftResponse } = useSaveRequest();
    const { data: draftRequest, isLoading, isSuccess: isGetDraftSuccess } = useGetNewRequest();
    const { data: countryList } = useGetCountries();
    const [mobNumValidText, setMobNumValidText] = useState(false);
    const [revenueValidText, setRevenueValidText] = useState(false);
    const [impDevicesValidText, setImpDevicesValidText] = useState(false);
    const [attachmentList, setAttachmentList] = useState([]);

    const [customerImpactDescription, setCustomerImpactDescription] = useState('');
    const [impactsHText, setImpactsHText] = useState(false);

    const [customerNameHText, setCustomerNameHText] = useState(false);
    const [contactName, setContactName] = useState('');
    const [contactNameHText, setContactNameHText] = useState(false);

    const [tempDescriptionValue, setTempDescriptionValue] = useState('');
    const [tempDateImportanceValue, setTempDateImportanceValue] = useState('');
    const [noQuickLinksSelected, setNoQuickLinksSelected] = useState(false);
    const [tempQuickLinkID, setTempQuickLinkID] = useState(null);
    const [, setRoutingFailedMsg] = useState(false);
    const [countryHText, setCountryHText] = useState(false);

    const [successMsg, setSuccessMsg] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [fundsDescription, setFundDescription] = useState('');
    const [selectedProductName, setSelectedProductName] = useState('');
    const [modelsList, setModelsList] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [selectedDate, setSelectedDate] = useState(momentBusinessDays().businessAdd(7).toDate());
    const [dateSent, setDateSent] = useState(momentBusinessDays().businessAdd(7).toISOString());
    const [pageLoadAnimation, setPageLoadAnimation] = useState(true);
    const [hideSearch, setHideSearch] = useState(false);
    const [recognizer, setRecognizer] = useState(null);
    const [animateReviewCustomer, setAnimateReviewCustomer] = useState('');
    const [, setAnimateDetails] = useState('');
    const [animateTitle, setAnimateTitle] = useState('');
    const [animateDescription, setAnimateDescription] = useState('');
    const [animateCustomer, setAnimateCustomer] = useState('');
    const [animateNewCustomer, setAnimateNewCustomer] = useState('');
    const [animateAction, setAnimateAction] = useState('');
    const [animateDCFAction, setAnimateDCFAction] = useState('');
    const [animateAddress, setAnimateAddress] = useState('');
    const [animateAttachments, setAnimateAttachments] = useState('');
    const [animatePreview, setAnimatePreview] = useState('');
    const [dcfBtnHText, setDCFBtnHText] = useState(false);
    const [fundHText, setFundHText] = useState(false);
    const [cartHText, setCartHText] = useState(false);
    const [animateStep, setAnimateStep] = useState('');
    const [animateStepReverse, setAnimateStepReverse] = useState('');
    const [fullReverse, setFullReverse] = useState(false);
    const [cartState, setCartState] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [quickLinksChange, setQuickLinksChange] = useState(false);
    const [msxData, setMsxData] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    const [suggestResult, setSuggestResult] = useState([]);
    const [, setTokenObj] = useState({ Token: '', Region: '' });

    const [addCusModal, setAddCusModal] = useState({});
    const [customerInputType, setCustomerInputType] = useState('search');
    const addSuccessPopUp = useStore((state: any) => state.addSuccessPopUp);
    const [isModalOpenCus, { setTrue: showModalCus, setFalse: hideModalCus }] = useBoolean(false);
    const titleId = useId('title');
    const searchFieldRef = useRef(null);
    const { mutate: searchData, data: searchResultData, isSuccess: isSuccessSearch } = useSearchResult();

    // State variables for addCustomer
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [displayScreen, setDisplayScreen] = useState('searchCust');

    const { data: requestTypesData, isLoading: requestLoading } = useRequestTypes();
    const { data: msxListData, isLoading: isMSXListDataLoading } = useMsxData();

    const {
        data: responseRequestType,
        mutate: gettingRequestType,
        isLoading: isGettingRequestType,
        isSuccess: successRequestType,
        isError: errorRequestType
    } = useGettingRequestType();
    const [mobilePrefix, setMobilePrefix] = useState(null);
    const [isCancelCLicked, setIsCancelClicked] = useState(false);
    const { data: generateVoiceToken, isLoading: isVoiceTokenLoading, refetch: getSpeechToTextToken } = useSpeechToTextToken();
    const {
        mutate: postRequestTypeHelp,
        isSuccess: successPostRequestHelp,
        isError: errorPostRequestHelp,
        isLoading: isCreatingRequest,
        data: postRequestResponse
    } = usePostRequestType();

    const [postSKPId, setPostSKPId] = useState('');
    const [contactNumPrefix, setContactNumPrefix] = useState(null);

    // blob upload helpers
    const [readyToUploadFiles, setReadyToUploadFiles] = useState(false);
    const [blobUploadFiles, setBlobUploadFiles] = useState<IBlobUploadFile[]>([]);
    const [isUplodingToBlob, setIsUplodingToBlob] = useState(false);
    const [uploadErrors, setUploadErrors] = useState([]);

    // upload attachments to blob storage
    const handleUploadAttachments = async () => {
        setUploadErrors([]);

        if (attachmentList.length === 0) {
            return false;
        }

        let successfulAttachments: any[] = [];
        setIsUplodingToBlob(true);
        if (blobUploadFiles.length > 0) {
            // send files to blobstorage service
            await blobStorageUpload(blobUploadFiles).then((res) => {
                let errors = res.length > 0 ? true : false;
                if (errors) {
                    setUploadErrors(res);
                    // remove failed files from attachments
                    successfulAttachments = removeFailedUploadsFromAttachmentList(res, attachmentList);
                }
                // update attachmentList with only successful attachments
                setAttachmentList(successfulAttachments.length > 0 ? successfulAttachments : attachmentList);
                //remove error files from blobUploadFiles
                setBlobUploadFiles(removeFailedBlobFiles(blobUploadFiles, attachmentList));
            });

            // update attachedFiles with only succesful attachments
            addFiles(removeFailuresFromAttachedFiles(attachmentList, attachedFiles));
        }

        setIsUplodingToBlob(false);
        setReadyToUploadFiles(false);
        setBlobUploadFiles([]);
    };

    const addToBlobUploadFiles = (newBlobFiles: IBlobUploadFile[]) => {
        setBlobUploadFiles(addItemsToList(blobUploadFiles, newBlobFiles));
    };

    useEffect(() => {
        if (attachmentList.length < 1) {
            setIsUplodingToBlob(false);
        }
    }, [blobUploadFiles]);

    const handleSuggestionClose = () => {
        setShowSuggestion(false);
    };

    const onIssueChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setIssueHText(false);
        setSelectedIssue(item);
    };

    useEffect(() => {
        if (isCancelCLicked && savedDraftResponse) {
            history.push(RouteConstants.KNOWLEDGE_PORTAL);
            goBackToInitial('search');
            toggleHideDialog();
        }
    }, [savedDraftResponse]);

    useEffect(() => {
        if (isCancelCLicked) {
            draftSaveRequest('"' + JSON.stringify({}).replace(/"/g, '\\"') + '"');
        }
    }, [isCancelCLicked]);

    useEffect(() => {
        if (successRequestType) {
            toggleHideDialog();
            if (responseRequestType.data.requestType !== requestTypeFlow) {
                setRequestTypeFlow(responseRequestType.data.requestType);
                setTempFlow(responseRequestType.data.requestType);
            }
            if (responseRequestType.data.requestType > 7 && responseRequestType.data.requestType < 13) {
                setRequestType(StringConstants.IRT);
            } else if (
                (responseRequestType.data.requestType > 12 && responseRequestType.data.requestType < 16) ||
                responseRequestType.data.requestType === 5
            ) {
                setRequestType(StringConstants.POST_SALES);
            } else {
                requestOptions.forEach((item: any) => {
                    if (item.id === responseRequestType.data.requestType) {
                        setRequestType(item.name);
                    }
                });
            }
        } else if (errorRequestType) {
            toggleHideDialog();
        }
    }, [successRequestType, isGettingRequestType, requestOptions, responseRequestType?.data, errorRequestType]);
    useSearchSuggestionClose(searchContainer, handleSuggestionClose);

    const [hashTagsFormatted, setHashTagsFormatted] = useState<any[]>([]);
    const { mutate: postHashTags } = usePostCommentsHashTags();

    useEffect(() => {
        if (successPostRequestHelp) {
            setCustomerAltPage(false);
            setSuccessMsg(true);
            setErrorMsg(false);
            setPostSKPId(postRequestResponse?.data?.unifiedId);

            if (selectedRequest) {
                let url = getURLForTeamAndType(
                    selectedRequest.requestTeam || selectedRequest.teamValue,
                    selectedRequest.requestTypeValue || selectedRequest.requestType
                );
                if (Array.isArray(hashTagsFormatted) && hashTagsFormatted?.length > 0) {
                    postHashTags({ url: url, data: hashTagsFormatted, correlationId: selectedRequest.unifiedTrackingId });
                }
            }
        } else if (errorPostRequestHelp) {
            setErrorMsg(true);
            setCustomerAltPage(false);
            setSuccessMsg(true);
        }
    }, [successPostRequestHelp, errorPostRequestHelp, showModal]);

    useEffect(() => {
        if (successRequestType) {
            setTimeout(function () {
                setCurrentSection('reqDetailsPage');
                setRequestSection('selectDevice');
            }, 300);

            setRoutingFailedMsg(false);
        }
        if (errorRequestType && isGettingRequestType) {
            setRoutingFailedMsg(true);
        }
    }, [errorRequestType, successRequestType, isGettingRequestType]);

    useEffect(() => {
        if (!requestLoading) {
            setRequestOptions(requestTypesData);
        }
        if (!isMSXListDataLoading) {
            setMsxData(msxListData);
        }
    }, [requestLoading, isMSXListDataLoading, msxListData, requestTypesData]);

    useEffect(() => {
        if (!isVoiceTokenLoading) {
            setTokenObj(generateVoiceToken);
        }
    }, [isVoiceTokenLoading]);

    useEffect(() => {
        if (isSuccessSearch) {
            setSuggestResult(searchResultData?.data?.SearchResult?.Items);
        }
    }, [isSuccessSearch]);

    useEffect(() => {
        if (searchValue.trim().length > 0 && currentSection === 'landing') {
            searchData({
                searchTerm: escapeToSafeCharacters(searchValue),
                pageIndex: 1,
                pageSize: 3,
                searchOption: 'HelpRequest',
                searchField: 'Default',
                orderBy: [],
                IsAdminSearch: false
            });
        }
    }, [searchValue]);
    useEffect(() => {
        if (quickLinksID && !tempQuickLinkID && noQuickLinksSelected) {
            if (currentSection === 'landing') {
                setTempQuickLinkID(quickLinksID);
                goBackToInitial('search');
            } else {
                toggleHideDialog();
                setQuickLinksChange(true);
            }
        }
    }, [quickLinksID]);

    useEffect(() => {
        if (quickLinksID !== tempQuickLinkID && quickLinksID && tempQuickLinkID) {
            if (currentSection === 'landing') {
                setTempQuickLinkID(quickLinksID);
                goBackToInitial('search');
            } else {
                toggleHideDialog();
                setQuickLinksChange(true);
            }
        }
    }, [quickLinksID, tempQuickLinkID]);
    useEffect(() => {
        if (currentSection !== 'landing') {
            submitDraftData();
        }
    }, [currentSection, requestSection]);

    function removeDuplicates(array: any) {
        let a: any = [];
        array.forEach((x: any) => {
            if (!a.includes(x)) {
                a.push(x);
            }
        });

        return a;
    }
    useEffect(() => {
        if (!isModalOpenCus) {
            setShowSuggestion(false);
            setSearchValue('');
            setSuggestResult([]);
        }
    }, [isModalOpenCus]);
    useEffect(() => {
        if (requestTypeFlow && tempFlow) {
            goBackToInitial('new request type');
        }
    }, [requestTypeFlow]);
    useEffect(() => {
        let navType: any = performance?.getEntriesByType('navigation')[0];
        if (
            !isLoading &&
            isGetDraftSuccess &&
            navType &&
            (navType.type === 'reload' || navType.type === 'navigate') &&
            !quickLinksValue &&
            redirectRequestType === ''
        ) {
            if (draftRequest && Object.entries(draftRequest).length !== 0) {
                setDraftData(draftRequest);
            } else clearFiles();
        }
    }, [isLoading, isGetDraftSuccess]);

    const onBtnChange = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
        setSelectedDCFKey(option.key);
        setDCFBtnHText(false);
    }, []);

    const setAddress = (value: any, type: any) => {
        switch (type) {
            case 'EMAIL':
                setCustomerEmail(value);
                setCustomerEmailHText(false);
                break;
            case 'CONTACT_NUMBER':
                setContactNum(value);
                setContactNumHText(false);
                break;
            case 'ADDRESS':
                setCustomerShippingAddress(value);
                setCustomerShippingAddHText(false);
                break;
            case 'CUSTOMER_NAME':
                setContactName(value);
                setContactNameHText(false);
                break;
            case 'CONTACT_NUMBER_PREFIX':
                setContactNumPrefix(value);
                if (contactNum) setContactNumHText(false);
                break;
            default:
                break;
        }
    };
    const setAction = (value: any) => {
        setFundDescription(value);
        setFundHText(false);
    };
    const [requestId, setRequestId] = useState('');

    // queries
    const {
        data: apiRequestData,
        isLoading: isLoadingRequestList,
        refetch: refetchRequestDetails
    } = useGetRequestById(requestId);
    const [selectedRequest, setSelectedRequest] = useState<IRequest>();

    useEffect(() => {
        if (!isLoadingRequestList && apiRequestData && apiRequestData?.data) {
            setSelectedRequest(apiRequestData?.data);
        }
    }, [apiRequestData?.data, isLoadingRequestList]);

    useEffect(() => {
        setTempQuickLinkID(quickLinksID);
        if (!quickLinksID) {
            setNoQuickLinksSelected(true);
        }
    }, []);

    const deviceSelected = (item: any) => {
        if (selectedProductId !== item.id) {
            setAddProductHText(false);
            setAddModelHText(false);
            setCartHText(false);
            setSelectedProductId(item.id);
            setSelectedProductName(item.name);
            let ar = [...item.devices];
            let ex: any = [];
            ar.forEach((ele: any) => {
                let it = {};
                if (selectedModel.length === 0) {
                    it = { ...ele, added: false, categoryId: item.id };
                    ex.push(it);
                } else {
                    selectedModel.every((em: any) => {
                        if (em.id === ele.id) {
                            it = em;
                            return false;
                        } else {
                            it = { ...ele, added: false, categoryId: item.id };
                            return true;
                        }
                    });
                    ex.push(it);
                }
            });

            setModelsList(ex);
        } else {
            setSelectedProductId('');
            setSelectedProductName('');
            let ex: any = [];
            selectedModel.forEach((ele) => {
                let flag = false;
                item.devices.every((it: any) => {
                    if (it.id === ele.id) {
                        flag = true;
                        return false;
                    } else {
                        return true;
                    }
                });
                if (!flag) {
                    ex.push(ele);
                }
            });
            setSelectedModel(ex);
        }
    };

    const modelSelected = (item: any, categoryIdVal: any) => {
        if (selectedModel.indexOf(item) === -1) {
            setAddProductHText(false);
            setAddModelHText(false);
            setCartHText(false);
            item.categoryId = categoryIdVal;
            let newItem = [...selectedModel, item];
            setSelectedModel(newItem);
        } else {
            const i = selectedModel.indexOf(item);
            if (!selectedModel[i].added) {
                let ar = [...selectedModel];
                ar.splice(i, 1);
                setSelectedModel(ar);
            }
        }
    };
    const onCountChange = (e: any) => {
        selectedModel.forEach((ele: any, index) => {
            if (ele.name === e.target.name) {
                selectedModel[index].count = e.target.value;
            }
        });
        setSelectedModel([...selectedModel]);
        setAddModelHText(false);
        setCartHText(false);
    };

    const removeSelectedModel = (item: any) => {
        const i = selectedModel.indexOf(item);
        let eg = [...selectedModel];
        eg[i].added = false;
        setSelectedModel(eg);
        let itemRemoved = selectedModel.filter((el) => el.id !== item.id);
        setSelectedModel([...itemRemoved]);
    };

    const getRequestType = () => {
        toggleHideDialog();
        gettingRequestType({
            title: searchValue,
            description: requestDescriptionToSubmit.length > 0 ? requestDescriptionToSubmit : requestDesc,
            requestDomain: quickLinksValue
        });
    };

    let datapass: any = {
        summary: searchValue,
        description: requestDescriptionToSubmit.length > 0 ? requestDescriptionToSubmit : requestDesc,
        requestedResolutionDate: dateSent,
        whyThisDateIsImportant: dateImportanceToSubmit.length > 0 ? dateImportanceToSubmit : dateImportance,
        hashTags: hashTagsFormatted,
        status: 1,
        attachments: attachmentList,
        customerImpacts: [
            {
                opportunityId: manualData ? '' : filteredData[0]?.OpportunityNumber,

                customerName: manualData ? customerName : filteredData[0]?.AccountName,

                tpId: manualData ? '' : filteredData[0]?.CustomerTPID,

                status: manualData ? '' : filteredData[0]?.Status,

                customerCountry: {
                    id: 1,
                    name: manualData ? country : filteredData[0]?.Country
                },

                customerImpactDescription: manualData ? customerImpactDescription : filteredData[0]?.CustomerImpactDescription,
                deviceCount: manualData
                    ? parseInt(newDeviceRisk?.replaceAll(',', ''))
                    : parseInt(impDevices?.replaceAll(',', '')) === filteredData[0]?.TotalDeviceCount
                    ? null
                    : parseInt(impDevices?.replaceAll(',', '')),
                revenue: manualData
                    ? parseFloat(newRevenueRisk?.replace('$', '').replaceAll(',', ''))
                    : parseFloat(revenueRisk?.replace('$', '').replaceAll(',', '')) === filteredData[0]?.EstimatedValue
                    ? null
                    : parseFloat(revenueRisk?.replace('$', '').replaceAll(',', '')),
                msxDeviceCount: manualData ? 0 : filteredData[0]?.TotalDeviceCount,
                msxRevenue: manualData ? 0 : filteredData[0]?.EstimatedValue,
                accountId: manualData ? '' : filteredData[0]?.AccountNumber,
                msxOpportunityId: manualData ? '' : filteredData[0]?.OpportunityId
            }
        ],
        originalRoutingML: originalRoutingML
    };

    const submitClick = () => {
        draftSaveRequest('"' + JSON.stringify({}).replace(/"/g, '\\"') + '"');

        if (!manualData) {
            let impact = datapass.customerImpacts[0];
            datapass = {
                ...datapass,
                customerImpacts: [
                    {
                        ...impact,
                        state: filteredData[0].Region,
                        customerImpactDescription: filteredData[0].CustomerImpactDescription,
                        city: filteredData[0].City,
                        zipCode: filteredData[0].PostalCode
                    }
                ]
            };
        }

        // must remove sas tokens from properties before saving.
        datapass.description = removeBlobFileSasToken(datapass.description);
        datapass.whyThisDateIsImportant = removeBlobFileSasToken(datapass.whyThisDateIsImportant);

        const requestHelpData = requestHelpSubmit(
            requestTypeFlow,
            datapass,
            requestType,
            selectedModel,
            selectedProductId,
            searchValue,
            emailID,
            filteredData,
            customerName,
            cssCaseNum,
            selectedDCFKey,
            fundsDescription,
            manualData ? customerImpactDescription : filteredData[0]?.CustomerImpactDescription,
            originalRoutingML,
            contactName,
            contactNum,
            customerEmail,
            customerShippingAddress,
            manualData ? customerName : filteredData[0]?.AccountName,
            manualData ? country : filteredData[0]?.Country,
            mobileNumber,
            contactNumPrefix,
            mobilePrefix
        );
        postRequestTypeHelp({ subUrl: requestHelpData.subUrl, data: requestHelpData.datapass });
        setCustomerAltPage(false);
        showModal();
        setOriginalRoutingML(null);
    };

    const goBackToInitial = (dest: any) => {
        if (dest !== 'search' && dest !== 'new request type') {
            setRedirectRequestType('');
            setTempQuickLinkID(null);
            setQuickLinksID(null);
            setQuickLinksFLow(null);
            setQuickLinksValue(null);
            setOriginalRoutingML(null);
        }

        setSuggestions([]);
        setMSXMatched(false);
        setMSXNotMatched(false);

        setAnimateAttachments('');
        clearFiles();

        setAnimatePreview('');
        setCSSCaseNum('');
        setEmailID('');
        setMobileNumber('');
        setShippingAdd('');

        setAnimateCustomer('');
        setNewDeviceRisk('');
        setNewRevenueRisk('');
        setRevenueRisk('');
        setImpDevices('');
        setCustomerSearchInput('');
        setCustomerSelected(false);
        setFilteredData([]);

        setAnimateNewCustomer('');
        setCustomerName('');
        setCountry('');
        setMsxOpportunityId('');
        setMsxStatus('');
        setCustomerTPID('');
        setCustomerAccountID('');

        setCustomerImpactDescription('');

        setManualData(false);

        setContactNumPrefix(null);
        setAnimateAddress('');
        setContactName('');
        setCustomerEmail('');
        setContactNum('');
        setCustomerShippingAddress('');

        setAnimateAction('');
        setDateImportance('');
        setDateImportanceToSubmit('');
        setTempDateImportanceValue('');
        setSelectedIssue(undefined);
        setSelectedDate(momentBusinessDays().businessAdd(7).toDate());
        setDateSent(momentBusinessDays().businessAdd(7).toISOString());

        setAnimateDCFAction('');
        setFundDescription('');
        setSelectedDCFKey(undefined);

        setCartState(false);
        setSelectedModel([]);
        setSelectedProductId('');
        setSelectedProductName('');
        setModelsList([]);

        setSuccessMsg(false);
        setErrorMsg(false);

        if (dest !== 'new request type') {
            setSuggestions([]);
            setShowSuggestion(false);
            setSuggest(false);
            setSearchValue('');
            setShowPrompt(false);
            setRequestDesc('');
            setRequestDescriptionToSubmit('');
            setTempDescriptionValue('');
        }

        if (dest === 'new request') {
            setNewRequest(true);
            setCurrentSection('titleSearchField');
            setRequestSection('selectDevice');
        } else if (dest === 'new request type') {
            setNewRequest(true);
        } else {
            setNewRequest(false);
        }

        if (dest === 'search') {
            setRequestSection('selectDevice');
            setCurrentSection('landing');
        }
        setDropDownValue('');
    };

    const prepareSearch = (e: any) => {
        setSearchValue(e.target.value);

        if (e.target.value.trim() === '') {
            setSuggest(false);
            setShowPrompt(true);
        } else {
            setSuggest(true);
            setShowPrompt(false);
        }
    };
    const handleFocus = (e: any) => {
        setShowSuggestion(true);
        if (searchValue.trim() === '') {
            setShowPrompt(true);
            setSuggest(false);
        } else {
            setSuggest(true);
        }
    };
    const suggestionCancel = () => {
        setShowPrompt(false);
        setShowSuggestion(false);
        setPageLoadAnimation(false);
    };
    const handleBlur = (e: any) => {};

    const setExpandVar = (id: any, type: any) => {
        let arr: any = [];
        if (type === undefined || !type) {
            suggestResult.forEach((ele: any) => {
                if (ele.Document.UnifiedTrackingId === id) {
                    arr.push({ ...ele, expanded: true });
                } else {
                    arr.push({ ...ele, expanded: false });
                }
            });
        } else if (type) {
            suggestResult.forEach((ele: any) => {
                if (ele.Document.UnifiedTrackingId === id) {
                    arr.push({ ...ele, expanded: false });
                } else {
                    arr.push({ ...ele });
                }
            });
        }

        setSuggestResult(arr);
    };

    const renderSuggestion = (suggestion: any) => {
        return <div>{suggestion.id}</div>;
    };
    const getSuggestionValue = (suggestion: any) => {
        return suggestion.id;
    };

    const addToCart = (item: any) => {
        setAddProductHText(false);
        setAddModelHText(false);
        setCartHText(false);
        if (
            item.count?.trim() === '' ||
            item.count === undefined ||
            item.count === '0' ||
            !fieldValidation(item.count, 'number', selectedModel)
        ) {
            setCartHText(
                item.count === undefined
                    ? true
                    : item.count === '' || item.count === '0' || !fieldValidation(item.count, 'number', selectedModel)
                    ? true
                    : false
            );
        } else {
            const i = modelsList.indexOf(item);
            let eg = [...modelsList];
            eg[i].added = true;
            setModelsList(eg);
        }
    };

    const setInitialState = (dest: any) => {
        setHideSearch(false);
        setAnimateDetails('');
        setAnimateCustomer('');
        setAnimateNewCustomer('');
        setAnimateAction('');
        setAnimateAttachments('');
        setAnimatePreview('');
        setAnimateDescription('');
        setAnimateTitle('');
        setAnimateDCFAction('');
        setAnimateAddress('');
    };

    const addMoreDevices = () => {
        setTimeout(() => {
            setRequestSection('selectDevice');
        }, 300);
        setAnimateAttachments('A_TO_RIGHT');
        setCartState(false);
        setAnimateStep('STEP_3');
        setAnimateStepReverse('STEP_3');
    };

    const sttFromMic = async (action: boolean) => {
        if (action) {
            let token = JSON.parse(sessionStorage.getItem('speech-token'));
            if (!(token && token.Token)) {
                await getSpeechToTextToken();
                token = JSON.parse(sessionStorage.getItem('speech-token'));
            }
            const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(token?.Token ?? '', token?.Region ?? '');
            speechConfig.speechRecognitionLanguage = 'en-US';
            const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
            let newRecognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
            setRecognizer(newRecognizer);
            setIsRecording(true);
            newRecognizer.recognized = function (s: any, e: any) {
                let displayText;

                if (e.result.text) {
                    displayText = `${e.result.text}`;
                    displayText = displayText.substring(0, displayText.length - 1);
                    setSearchValue(displayText);
                    setIsRecording(false);
                    searchFieldRef.current.focus();

                    newRecognizer.stopContinuousRecognitionAsync(
                        (result: string) => {},
                        (e: string) => {}
                    );
                }
            };

            newRecognizer.startContinuousRecognitionAsync(() => {
                setTimeout(() => {
                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                    setIsRecording(false);
                }, 20000);
            });
        }

        if (!action) {
            recognizer.stopContinuousRecognitionAsync(
                (result: string) => {},
                (e: string) => {}
            );
        }
    };

    const voiceSearchOn = () => {
        sttFromMic(true);
    };

    const voiceSearchOff = () => {
        setIsRecording(false);
        sttFromMic(false);
    };

    const onDetailsClicked = () => {
        if (requestSection === 'selectDevice' || requestSection === 'action' || requestSection === 'DCF_ACTION')
            setTimeout(function () {
                setShrinkAnimation(true);
                setCurrentSection('titleSearchField');
            }, 300);
    };
    const onSelectDeviceClicked = () => {
        if (requestSection === 'action' || requestSection === 'DCF_ACTION') {
            setTimeout(function () {
                setRequestSection('selectDevice');
            }, 300);
        }
    };

    const onCustomerClicked = () => {
        setTimeout(() => {
            if (manualData) {
                setAnimateNewCustomer('S_TO_NEXT');
                setRequestSection('newCustomer');
            } else {
                setAnimateCustomer('S_TO_NEXT');
                setRequestSection('customer');
            }
        }, 300);
    };

    const selectFirstTab = () => {
        if (
            requestSection === 'customer' ||
            requestSection === 'newCustomer' ||
            requestSection === 'address' ||
            requestSection === 'attachment' ||
            requestSection === 'preview'
        )
            setTimeout(function () {
                setCartState(false);
                setShrinkAnimation(true);
                setCurrentSection('titleSearchField');
            }, 300);
    };
    const selectSecondTab = () => {
        if (requestSection === 'attachment' || requestSection === 'preview') onCustomerClicked();
    };
    const selectThirdTab = () => {
        if (requestSection === 'preview')
            setTimeout(() => {
                setAnimateAttachments('S_TO_NEXT');
                setRequestSection('attachment');
            }, 300);
    };

    const getModalAddCustomerData = (data: any) => {
        let customerData: IAddCustomerData = {};
        customerData.applicationId = data.BackendId;
        customerData.unifiedTrackingId = data.UnifiedTrackingId;
        setRequestId(data.UnifiedTrackingId);
        refetchRequestDetails();
        customerData.requestType = parseInt(data.RequestType);
        customerData.requestTeam = parseInt(data.RequestTeam);
        customerData.summary = data.Title;
        customerData.customerImpacts = data.customerImpacts;
        setAddCusModal(customerData);
    };

    const getDraftData = () => {
        let draftData = {
            version: 2,
            currentSection: currentSection,
            shrinkAnimation: shrinkAnimation,
            searchValue: searchValue,
            requestDesc: encodeURIComponent(requestDescriptionToSubmit.length > 0 ? requestDescriptionToSubmit : requestDesc),
            animateTitle: animateTitle,
            animateDescription: animateDescription,

            requestTypeFlow: requestTypeFlow,
            requestType: requestType,
            requestOptions: requestOptions,
            requestSection: requestSection,
            newRequest: newRequest,

            selectedModel: selectedModel,
            selectedProductId: selectedProductId,
            selectedProductName: selectedProductName,
            modelsList: modelsList,
            cartState: cartState,

            dateImportance: encodeURIComponent(dateImportance),
            selectedDate: selectedDate,
            dateSent: dateSent,
            animateAction: animateAction,
            selectedDCFKey: selectedDCFKey,
            fundsDescription: fundsDescription,
            animateDCFAction: animateDCFAction,

            manualData: manualData,
            revenueRisk: revenueRisk,
            impDevices: impDevices,
            newDeviceRisk: newDeviceRisk,
            newRevenueRisk: newRevenueRisk,
            customerSearchInput: customerSearchInput,
            customerSelected: customerSelected,
            filteredData: filteredData,
            cssCaseNum: cssCaseNum,
            animateCustomer: animateCustomer,

            custName: customerName,
            status: msxStatus,
            country: country,
            msxOpportunityId: msxOpportunityId,
            customerImpactDescription: customerImpactDescription,
            tpid: customerTPID,
            accountID: customerAccountID,

            animateNewCustomer,

            contactName: contactName,
            custEmail: customerEmail,
            contactNum: contactNum,
            custShippingAddress: customerShippingAddress,
            animateAddress: animateAddress,

            custAltPage: customerAltPage,
            mobileNumber: mobileNumber,
            emailID: emailID,
            shippingAdd: shippingAdd,
            errorMsg: errorMsg,
            successMsg: successMsg,
            attachedFiles: attachedFiles,
            attachmentList: attachmentList,
            pageLoadAnimation: pageLoadAnimation
        };
        return draftData;
    };

    const submitDraftData = () => {
        draftSaveRequest('"' + JSON.stringify(getDraftData()).replace(/"/g, '\\"') + '"');
    };

    const setDraftData = (data: any) => {
        if (data && data?.version !== 2) {
            return;
        }

        setCurrentSection(data.currentSection);
        setShrinkAnimation(data.shrinkAnimation);
        setSearchValue(data.searchValue);
        setRequestDesc(decodeURIComponent(data.requestDesc));
        setRequestDescriptionToSubmit(decodeURIComponent(data.requestDesc));
        setTempDescriptionValue(decodeURIComponent(data.requestDesc));
        setAnimateTitle(data.animateTitle);
        setAnimateDescription(data.animateDescription);

        setRequestTypeFlow(data.requestTypeFlow);
        setRequestType(data.requestType);
        setRequestOptions(data.requestOptions);
        setRequestSection(data.requestSection);
        setNewRequest(data.newRequest);

        setSelectedModel(data.selectedModel);
        setSelectedProductId(data.selectedProductId);
        setSelectedProductName(data.selectedProductName);
        setModelsList(data.modelsList);
        setCartState(data.cartState);

        setDateImportance(decodeURIComponent(data.dateImportance));
        setTempDateImportanceValue(decodeURIComponent(data.dateImportance));

        setSelectedDate(new Date(data.selectedDate));
        setDateSent(data.dateSent);
        setAnimateAction(data.animateAction);

        setSelectedDCFKey(data.selectedDCFKey);
        setFundDescription(data.fundsDescription);
        setAnimateDCFAction(data.animateDCFAction);

        setManualData(data.manualData);
        setRevenueRisk(data.revenueRisk);
        setImpDevices(data.impDevices);
        setNewDeviceRisk(data.newDeviceRisk);
        setNewRevenueRisk(data.newRevenueRisk);
        setCustomerSearchInput(data.customerSearchInput);
        setCustomerSelected(data.customerSelected);
        setFilteredData(data.filteredData);
        setCSSCaseNum(data.cssCaseNum);
        setAnimateCustomer(data.animateCustomer);
        setCustomerName(data.custName);
        setCustomerTPID(data.tpid);
        setCountry(data.country);
        setMsxOpportunityId(data.oppId);
        setMsxData(data.status);
        setCustomerAccountID(data.accountID);
        setCustomerImpactDescription(data.customerImpactDescription);
        setAnimateNewCustomer(data.animateNewCustomer);

        setContactName(data.contactName);
        setCustomerEmail(data.custEmail);
        setContactNum(data.contactNum);
        setCustomerShippingAddress(data.custShippingAddress);
        setAnimateAddress(data.animateAddress);

        setCustomerAltPage(data.custAltPage);
        setMobileNumber(data.mobileNumber);
        setEmailID(data.emailID);
        setShippingAdd(data.shippingAdd);
        setErrorMsg(data.errorMsg);
        setSuccessMsg(data.successMsg);
        addFiles(data.attachedFiles);
        setAttachmentList(data.attachmentList);
        setPageLoadAnimation(data.pageLoadAnimation);
    };
    const handleClick = (dir: string) => {
        if (dir === 'NEXT') {
            if (currentSection === 'titleSearchField') {
                setShrinkAnimation(false);
                if (searchValue.trim() === '') {
                    setTitleHText(true);
                } else {
                    setAnimateTitle('A_TO_RIGHT');
                    setTimeout(function () {
                        setAnimateDescription('S_TO_PREV');
                        setCurrentSection(StringConstants.DESCRIPTION);
                    }, 300);
                }
            } else if (currentSection === StringConstants.DESCRIPTION) {
                setTempDescriptionValue(
                    requestDescriptionToSubmit.length > 0 ? requestDescriptionToSubmit.concat('') : requestDesc.concat('')
                );
                if (requestDesc.trim() === '' && requestDescriptionToSubmit.trim() === '') {
                    setDescHText(true);
                } else {
                    if (redirectRequestType !== '') {
                        setOriginalRoutingML(false);
                        setTempFlow(quickLinksFLow);
                        setRequestTypeFlow(quickLinksFLow);
                        setRequestType(redirectRequestType);
                        setCurrentSection('reqDetailsPage');
                        setRequestSection('selectDevice');
                    } else {
                        if (!isGettingRequestType) {
                            setOriginalRoutingML(true);
                            getRequestType();
                        }
                    }
                    setFullReverse(false);
                    setAnimateStep('STEP_1');
                    setAnimateStepReverse('');
                }
            }
        } else {
            if (currentSection === 'titleSearchField') {
                setTitleHText(false);
                setCurrentSection('landing');
            } else if (currentSection === StringConstants.DESCRIPTION) {
                setTempDescriptionValue(
                    requestDescriptionToSubmit.length > 0 ? requestDescriptionToSubmit.concat('') : requestDesc.concat('')
                );
                setDescHText(false);
                setShrinkAnimation(false);
                setAnimateDescription('A_TO_LEFT');
                setTimeout(() => {
                    setAnimateTitle('S_TO_NEXT');
                    setCurrentSection('titleSearchField');
                }, 300);
            }
        }
    };

    const nextWizard = () => {
        if (requestSection === 'details') {
        } else if (requestSection === 'selectDevice') {
            if (
                selectedProductId === '' ||
                selectedModel.length === 0 ||
                fieldValidation(selectedModel, 'modelCount', selectedModel)
            ) {
                setAddProductHText(selectedProductId === '');

                if (!(selectedProductId === '')) {
                    setAddModelHText(selectedModel.length === 0);
                }
                if (!(selectedProductId === '') && !(selectedModel.length === 0)) {
                    setCartHText(fieldValidation(selectedModel, 'modelCount', selectedModel));
                }
            } else {
                setTimeout(() => {
                    setCartState(true);
                    setRequestSection('action');
                    setAnimateAction('S_TO_PREV');
                }, 300);
            }
        } else if (requestSection === 'customer') {
            if (
                !isNumberGreaterThanZero(revenueRisk?.toString().replace('$', '').replaceAll(',', '')) ||
                impDevices.trim() === '' ||
                impDevices.trim() === '0' ||
                (requestType === 'CSSRequest' &&
                    emailID.trim() !== '' &&
                    !fieldValidation(emailID.trim(), 'email', selectedModel))
            ) {
                if (parseInt(impDevices?.replaceAll(',', '')) === filteredData[0]?.TotalDeviceCount) {
                    setImpDevices(null);
                }
                if (parseFloat(revenueRisk?.replace('$', '').replaceAll(',', '')) === filteredData[0]?.EstimatedValue) {
                    setRevenueRisk(null);
                }
                setAutoSuggestHText(!customerSelected);
                setRevenueHText(!isNumberGreaterThanZero(revenueRisk?.toString().replace('$', '').replaceAll(',', '')));
                setImpDevicesHText(impDevices?.trim() === '' || impDevices?.trim() === '0' ? true : false);
                setEmailValidText(
                    emailID.trim() !== '' && !fieldValidation(emailID.trim(), 'email', selectedModel) ? true : false
                );
            } else {
                setAnimateCustomer('A_TO_RIGHT');
                setTimeout(function () {
                    setAnimateAddress('S_TO_PREV');
                    setRequestSection('address');
                }, 300);
            }
        } else if (requestSection === 'newCustomer') {
            if (
                customerName?.trim() === '' ||
                country?.trim() === '' ||
                !isNumberGreaterThanZero(newRevenueRisk.toString().replace('$', '').replaceAll(',', '')) ||
                newDeviceRisk.trim() === '' ||
                newDeviceRisk.trim() === '0'
            ) {
                customerInputType === 'search'
                    ? setAutoSuggestHText(customerName?.trim() === '' ? true : false)
                    : setCustomerNameHText(customerName?.trim() === '' ? true : false);
                setCountry(country);
                setRevenueHText(!isNumberGreaterThanZero(newRevenueRisk.toString().replace('$', '').replaceAll(',', '')));
                setImpDevicesHText(newDeviceRisk.trim() === '' || newDeviceRisk.trim() === '0');
            } else {
                setAnimateNewCustomer('A_TO_RIGHT');
                setTimeout(function () {
                    setAnimateAddress('S_TO_PREV');
                    setRequestSection('address');
                }, 300);
            }
        } else if (requestSection === 'address') {
            if (
                !fieldValidation(customerEmail.trim(), 'email', selectedModel) ||
                !fieldValidation(contactNum.trim(), 'mobile number', selectedModel) ||
                customerShippingAddress.trim() === '' ||
                contactName.trim() === ''
            ) {
                setContactNameHText(contactName.trim() === '' ? true : false);
                setCustomerEmailHText(!fieldValidation(customerEmail.trim(), 'email', selectedModel));
                setContactNumHText(!fieldValidation(contactNum.trim(), 'mobile number', selectedModel));
                setCustomerShippingAddHText(customerShippingAddress.trim() === '' ? true : false);
            } else {
                setRequestSection('DCF_ACTION');
            }
        } else if (requestSection === 'REVIEW_CUSTOMER') {
            if (cssCaseNum.trim() === '') {
                setCaseNumHText(true);
            } else {
                setAnimateReviewCustomer('A_TO_RIGHT');
                setTimeout(() => {
                    if (manualData) {
                        setRequestSection('newCustomer');
                    } else {
                        setAnimateCustomer('S_TO_PREV');
                        setRequestSection('customer');
                    }
                }, 300);
            }
        } else if (requestSection === 'action') {
            setTempDateImportanceValue(
                dateImportanceToSubmit.length > 0 ? dateImportance.concat('') : dateImportance?.concat('')
            );
            if (
                (dateImportance.trim() === '' && dateImportanceToSubmit.trim() === '') ||
                (requestType === 'CSSRequest' && selectedIssue === undefined)
            ) {
                setIssueHText(requestType === 'CSSRequest' && selectedIssue === undefined);
                setDateImportanceHText(dateImportance.trim() === '');
            } else {
                setAnimateAction('A_TO_RIGHT');
                setTimeout(() => {
                    setAnimateDCFAction('S_TO_PREV');
                    setRequestSection('DCF_ACTION');
                }, 300);
            }
        }
    };
    const previousWizard = () => {
        if (requestSection === 'selectDevice') {
            setCartState(false);
            setShrinkAnimation(true);
            setCurrentSection(StringConstants.DESCRIPTION);
        } else if (requestSection === 'customer' || requestSection === 'newCustomer') {
            if (requestSection === 'customer') {
                setAnimateCustomer('A_TO_LEFT');
            }

            setTimeout(function () {
                if (requestType === StringConstants.REVIEW_REQUEST) {
                    setRequestSection('REVIEW_CUSTOMER');
                    setAnimateReviewCustomer('S_TO_NEXT');
                } else {
                    setRequestSection('action');
                }
            }, 300);

            setAnimateCustomer('A_TO_RIGHT');
        } else if (requestSection === 'action') {
            setTempDateImportanceValue(
                dateImportanceToSubmit.length > 0 ? dateImportanceToSubmit.concat('') : dateImportance?.concat('')
            );
            setAnimateAction('A_TO_LEFT');
            setTimeout(function () {
                setCartState(false);
                setRequestSection('selectDevice');
            }, 300);
        } else if (requestSection === 'address') {
            setAnimateAddress('A_TO_LEFT');
            setTimeout(function () {
                if (manualData) {
                    setAnimateNewCustomer('S_TO_NEXT');
                    setRequestSection('newCustomer');
                } else {
                    setAnimateCustomer('S_TO_NEXT');
                    setRequestSection('customer');
                }
            }, 300);
        } else if (requestSection === 'DCF_ACTION') {
            setAnimateDCFAction('A_TO_LEFT');
            setTimeout(function () {
                setAnimateAction('S_TO_NEXT');
                setRequestSection('action');
            }, 300);
        } else if (requestSection === 'REVIEW_CUSTOMER') {
            setAnimateReviewCustomer('A_TO_LEFT');
            setTimeout(function () {
                setRequestSection('details');
                setAnimateDetails('S_TO_NEXT');
            }, 300);
        }
    };

    const keyPress = (event: any, handler: Function, dir?: string) => {
        if (event.key === 'Enter') {
            if (dir) {
                handler(dir);
            } else {
                handler();
            }
        }
    };

    function nextBtnEnablingHandler() {
        if (currentSection === 'titleSearchField' && searchValue.trim() !== '') {
            return true;
        } else if (
            currentSection === StringConstants.DESCRIPTION &&
            (requestDesc.trim() !== '' || requestDescriptionToSubmit !== '')
        ) {
            return true;
        } else if (currentSection === 'reqDetailsPage') {
            if (
                requestSection === 'selectDevice' &&
                selectedProductId !== '' &&
                selectedModel.length !== 0 &&
                !fieldValidation(selectedModel, 'modelCount', selectedModel)
            ) {
                return true;
            } else if (requestSection === 'action' && (dateImportance.trim() !== '' || dateImportanceToSubmit.trim() !== '')) {
                return true;
            } else if (requestSection === 'DCF_ACTION') {
                if (selectedDCFKey === 'B' || (selectedDCFKey === 'A' && fundsDescription.trim() !== '')) {
                    return true;
                }
            } else if (requestSection === 'customer') {
                if (
                    isNumberGreaterThanZero(revenueRisk?.toString().replace('$', '').replaceAll(',', '')) &&
                    impDevices?.trim() !== '' &&
                    impDevices?.trim() !== '0' &&
                    (requestType === StringConstants.REVIEW_REQUEST ? cssCaseNum.trim() !== '' : true)
                ) {
                    return true;
                }
                if (TEAM_DETAILS[0].id === 'CSS' || TEAM_DETAILS[0].id === 'CXP') {
                    setCaseNumHText(cssCaseNum.trim() !== '');
                }
            } else if (requestSection === 'newCustomer') {
                if (
                    customerName?.trim() !== '' &&
                    country?.trim() !== '' &&
                    isNumberGreaterThanZero(newRevenueRisk.toString().replace('$', '').replaceAll(',', '')) &&
                    newDeviceRisk.trim() !== '' &&
                    newDeviceRisk.trim() !== '0' &&
                    (requestType === StringConstants.REVIEW_REQUEST ? cssCaseNum.trim() !== '' : true)
                ) {
                    return true;
                }
                if (TEAM_DETAILS[0].id === 'CSS' || TEAM_DETAILS[0].id === 'CXP') {
                    setCaseNumHText(cssCaseNum.trim() !== '');
                }
            } else if (requestSection === 'address') {
                if (
                    fieldValidation(customerEmail.trim(), 'email', selectedModel) &&
                    fieldValidation(contactNum.trim(), 'mobile number', selectedModel) &&
                    customerShippingAddress.trim() !== '' &&
                    contactName.trim() !== '' &&
                    contactNumPrefix
                ) {
                    return true;
                }
            } else if (requestSection === 'attachment' || requestSection === 'preview') {
                return true;
            }
        }
    }

    const getPlaceholderText = () => {
        if (!quickLinksValue && redirectRequestType === '') {
            return 'For example: My customer needs more attention on a support case';
        } else if (quickLinksValue === 1) {
            return 'For example: I need help filling out an RFP for my customer.';
        } else if (redirectRequestType === StringConstants.POST_SALES) {
            return 'For example: What protection plans are available in my market?';
        } else if (redirectRequestType === StringConstants.REVIEW_REQUEST) {
            return 'For example: My customer needs more attention on a support case.';
        } else if (redirectRequestType === StringConstants.IRT && quickLinksFLow === 9) {
            return 'For example: Can we make an ARM based Surface Go?';
        } else if (redirectRequestType === StringConstants.IRT && quickLinksFLow === 10) {
            return 'For example: Which Surface devices will run Windows 11?';
        }
    };
    function previousBtnEnablingHandler() {
        if (currentSection === 'titleSearchField' && searchValue.trim() === '') {
            return true;
        } else if (
            currentSection === StringConstants.DESCRIPTION &&
            (requestDesc.trim() === '' || requestDescriptionToSubmit === '')
        ) {
            return true;
        } else if (currentSection === 'reqDetailsPage') {
            if (
                requestSection === 'selectDevice' &&
                (selectedProductId === '' ||
                    selectedModel.length === 0 ||
                    fieldValidation(selectedModel, 'modelCount', selectedModel))
            ) {
                return true;
            } else if (
                requestSection === 'action' &&
                (dateImportance || dateImportanceToSubmit) &&
                (dateImportance.trim() === '' || dateImportanceToSubmit === '')
            ) {
                return true;
            } else if (requestSection === 'DCF_ACTION') {
                if (
                    (selectedDCFKey === 'A' && fundsDescription.trim() === '') ||
                    (selectedDCFKey !== 'B' && selectedDCFKey !== 'A')
                ) {
                    return true;
                }
            } else if (requestSection === 'address') {
                if (
                    !fieldValidation(customerEmail.trim(), 'email', selectedModel) ||
                    !fieldValidation(contactNum.trim(), 'mobile number', selectedModel) ||
                    customerShippingAddress.trim() === '' ||
                    contactName.trim() === ''
                ) {
                    return true;
                }
            }
        }
    }

    if (isLoading) {
        return <Loader classNames="height-70" />;
    } else
        return (
            <div className={`req-exp-cont ${currentSection !== 'landing' ? 'hide-overflow' : ''}`}>
                <NavigationPrompt
                    when={(currentLocation, nextLocation, _action) => {
                        if (nextLocation?.pathname?.includes('unified-dashboard') && _action === 'PUSH') {
                            return false;
                        } else if (nextLocation?.pathname?.includes('knowledge-portal') && isCancelCLicked === true) {
                            return false;
                        } else if (nextLocation?.pathname?.startsWith(currentLocation?.pathname)) {
                            return false;
                        } else {
                            if (currentSection !== 'landing' && !errorMsg && !successMsg) {
                                if (!hideDialog) {
                                    toggleHideDialog();
                                }
                                if (!hideCancelConfirmationDialog) {
                                    toggleCancelConfirmationDialog();
                                }
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }}
                    disableNative={true}>
                    {({ isActive, onConfirm, onCancel }: any) => <PromptModal when={true} onOK={onConfirm} onCancel={onCancel} />}
                </NavigationPrompt>
                {
                    <ScreenBg
                        step={` ${animateStep.includes('STEP_1') ? 'step1' : ''}
                                ${animateStep.includes('STEP_2') ? 'step2' : ''}
                                ${animateStep.includes('STEP_3') ? 'step3' : ''}
                                ${animateStep.includes('STEP_4') ? 'step4' : ''}
                                ${animateStepReverse.includes('STEP_1') ? 'step1-rev' : ''}
                                ${animateStepReverse.includes('STEP_2') ? 'step2-rev' : ''}
                                ${animateStepReverse.includes('STEP_3') ? 'step3-rev' : ''}
                                ${animateStepReverse.includes('STEP_4') ? 'step4-rev' : ''}
                                ${fullReverse ? 'step4-to-step0' : ''}`}
                    />
                }
                <ReqExpBanner />
                <div aria-label="Exiting List" tabIndex={0} className="content-cont container">
                    <div className={`landing-screen-cont ${currentSection === 'reqDetailsPage' ? 'no-margin' : ''}`}>
                        {(quickLinksValue || redirectRequestType !== '') && (
                            <div
                                aria-label="monitor icon"
                                tabIndex={0}
                                className={`quicklinks-tag ${currentSection === 'reqDetailsPage' ? 'no-bg' : ''}`}>
                                <Image src={quickLinksIcon} alt="Icon" />
                                <Text>{getQuickLinksText(quickLinksValue, redirectRequestType, quickLinksFLow)}</Text>
                            </div>
                        )}
                        <div className={`title-container ${shrinkAnimation ? 'shrink' : ''}`}>
                            {(currentSection === 'titleSearchField' || currentSection === StringConstants.DESCRIPTION) && (
                                <div className={`title-header `}>
                                    <Text className="new-request-text" aria-label="Creating a new request" tabIndex={0}>
                                        Creating a new request
                                    </Text>
                                    <div
                                        onClick={() => {
                                            toggleCancelConfirmationDialog();
                                        }}
                                        className="cancel-request-link"
                                        aria-label="Cancel"
                                        tabIndex={0}>
                                        Cancel request
                                    </div>
                                </div>
                            )}

                            {(currentSection === 'landing' ||
                                currentSection === 'titleSearchField' ||
                                currentSection === StringConstants.DESCRIPTION) && (
                                <div className={`landing-screen ${currentSection === 'landing' ? 'add-radius' : ''}`}>
                                    {currentSection === 'landing' && (
                                        <div
                                            tabIndex={0}
                                            aria-label="Welcome"
                                            className={`welcome-page ${hideSearch ? 'fadeOut' : ''} ${
                                                pageLoadAnimation ? 'page-load-animation' : ''
                                            } `}>
                                            <div className="welcome-text">
                                                <Text className="head" tabIndex={0} aria-label={userDetails}>
                                                    <Trans>Hi</Trans> {userDetails},
                                                </Text>
                                                <Text className="text" tabIndex={0} aria-label="How can we help you?">
                                                    <Trans>How can we help you?</Trans>
                                                </Text>
                                            </div>
                                            <div
                                                className={`search-cont  ${showSuggestion ? 'suggest' : ''} ${
                                                    searchValue.trim() !== '' && showSuggestion ? 'remove-glow' : ''
                                                }`}
                                                ref={searchContainer}>
                                                <span className={`type-ahead ${showSuggestion ? 'no-border' : ''}`}>
                                                    <Icon iconName="Search" aria-label="Search" tabIndex={0} />
                                                    <TextField
                                                        ariaLabel={'help-description'}
                                                        autoComplete={'off'}
                                                        componentRef={searchFieldRef}
                                                        value={searchValue}
                                                        onChange={prepareSearch}
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        placeholder={showPrompt ? '' : getPlaceholderText()}
                                                        data-testid="showprompt-placeholder"
                                                    />
                                                    {isRecording && (
                                                        <div
                                                            className="recording-row recording-position"
                                                            onClick={() => voiceSearchOff()}>
                                                            <div className="first-line"></div>
                                                            <div className="second-line"></div>
                                                            <div className="third-line"></div>
                                                            <div className="fourth-line"></div>
                                                            <div className="fifth-line"></div>
                                                        </div>
                                                    )}
                                                    {!isRecording && (
                                                        <i
                                                            className="micro-icon"
                                                            onKeyPress={(e: any) => keyPress(e, () => voiceSearchOn())}
                                                            tabIndex={0}
                                                            onClick={() => voiceSearchOn()}
                                                            aria-label="voice-icon"
                                                            role="button"></i>
                                                    )}
                                                </span>
                                                {showSuggestion && (
                                                    <div className={`suggestion`}>
                                                        {showPrompt && (
                                                            <span className={`start`}>
                                                                <Text
                                                                    className="start-prompt"
                                                                    aria-label={getPlaceholderText()}
                                                                    tabIndex={0}>
                                                                    <Trans>{getPlaceholderText()}</Trans>
                                                                </Text>
                                                                <Icon
                                                                    iconName="Cancel"
                                                                    tabIndex={0}
                                                                    ariaLabel="Close Button"
                                                                    onKeyPress={(e: any) => keyPress(e, suggestionCancel)}
                                                                    onClick={suggestionCancel}
                                                                />
                                                            </span>
                                                        )}

                                                        {suggest && (
                                                            <div className={`search-suggestion `}>
                                                                {suggestResult && suggestResult?.length > 0 && (
                                                                    <Text className="rel-requests" tabIndex={0}>
                                                                        <Trans>Suggested Requests</Trans>
                                                                    </Text>
                                                                )}
                                                                {suggestResult &&
                                                                    suggestResult.map((item: any, idx: number) => {
                                                                        return (
                                                                            <div
                                                                                key={idx}
                                                                                className={`suggestion-result-cont ${
                                                                                    item.expanded ? 'expanded-result-cont' : ''
                                                                                }`}
                                                                                tabIndex={0}
                                                                                onKeyPress={(e: any) =>
                                                                                    keyPress(e, () =>
                                                                                        setExpandVar(
                                                                                            item.Document.UnifiedTrackingId,
                                                                                            item.expanded
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onClick={() =>
                                                                                    setExpandVar(
                                                                                        item.Document.UnifiedTrackingId,
                                                                                        item.expanded
                                                                                    )
                                                                                }>
                                                                                <div className="suggestion-result">
                                                                                    {!item.expanded && (
                                                                                        <Image
                                                                                            className="m-r-15"
                                                                                            src={ExpandIcon}
                                                                                            alt="open"
                                                                                        />
                                                                                    )}
                                                                                    {item.expanded && (
                                                                                        <Image
                                                                                            className="m-r-15"
                                                                                            src={CollapseIcon}
                                                                                            alt="close"
                                                                                        />
                                                                                    )}
                                                                                    <Text className="result-skip-id">
                                                                                        {' '}
                                                                                        {item.Document.UnifiedTrackingId}
                                                                                    </Text>
                                                                                    <span className="dash m-l-10 m-r-10">-</span>{' '}
                                                                                    <span className="result-title">
                                                                                        {item.Document.Title}
                                                                                    </span>
                                                                                </div>
                                                                                {item.expanded && (
                                                                                    <div className="expanded-result">
                                                                                        <Text className="expanded-head">
                                                                                            <Trans>Description</Trans>
                                                                                        </Text>
                                                                                        <Text className="expanded-body">
                                                                                            <span
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: item.Document.Description.replace(
                                                                                                        /(<([^>]+)>)/gi,
                                                                                                        ''
                                                                                                    )
                                                                                                }}></span>
                                                                                        </Text>
                                                                                        {item.Document.DueDate && (
                                                                                            <>
                                                                                                <Text className="expanded-head">
                                                                                                    <Trans>
                                                                                                        Requested resolution date
                                                                                                    </Trans>
                                                                                                </Text>
                                                                                                <Text className="expanded-body">
                                                                                                    {item.Document.DueDate}
                                                                                                </Text>
                                                                                            </>
                                                                                        )}
                                                                                        <div className="addCustomer-container">
                                                                                            <DefaultButton
                                                                                                className="default add-cust-btn"
                                                                                                onClick={() => {
                                                                                                    getModalAddCustomerData(
                                                                                                        item.Document
                                                                                                    );
                                                                                                    addSuccessPopUp(false);
                                                                                                    showModalCus();
                                                                                                }}>
                                                                                                <Text>
                                                                                                    <Trans>Add a customer</Trans>
                                                                                                </Text>
                                                                                            </DefaultButton>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                {hideModal && (
                                                                    <Modal
                                                                        titleAriaId={titleId}
                                                                        isOpen={isModalOpenCus}
                                                                        onDismiss={hideModalCus}
                                                                        isBlocking={false}
                                                                        className="add-impact-model">
                                                                        <AddImpact
                                                                            selectedRequest={addCusModal}
                                                                            onDismiss={hideModalCus}
                                                                            globalSelectedRequest={selectedRequest}
                                                                            setSelectedRequest={setSelectedRequest}
                                                                            requestProcessing={requestProcessing}
                                                                            setRequestProcessing={setRequestProcessing}
                                                                            displayScreen={displayScreen}
                                                                            setDisplayScreen={setDisplayScreen}
                                                                        />
                                                                    </Modal>
                                                                )}
                                                                <div className="flex-center">
                                                                    <div className="create-req-cont ">
                                                                        <div
                                                                            className="create-req-link"
                                                                            aria-label="Create new request">
                                                                            <Trans>Do you want to</Trans>{' '}
                                                                            <Link
                                                                                onClick={() => {
                                                                                    setTimeout(function () {
                                                                                        setShrinkAnimation(true);
                                                                                        setNewRequest(true);
                                                                                        setCurrentSection('titleSearchField');
                                                                                        window.scrollTo({
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                            behavior: 'smooth'
                                                                                        });
                                                                                    }, 1000);
                                                                                }}
                                                                                aria-label="Creating a new request"
                                                                                tabIndex={0}
                                                                                data-testid="createnew-test">
                                                                                <Trans>create a new request?</Trans>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>{' '}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className={`first-titlefield-cont ${
                                            currentSection === 'titleSearchField' ||
                                            currentSection === StringConstants.DESCRIPTION
                                                ? 'fadeIn add-padding'
                                                : ''
                                        } ${
                                            currentSection !== 'titleSearchField' &&
                                            currentSection !== StringConstants.DESCRIPTION
                                                ? 'slideUp'
                                                : ''
                                        }`}>
                                        {(currentSection === 'titleSearchField' ||
                                            currentSection === StringConstants.DESCRIPTION) && (
                                            <div className="first-titlefield">
                                                {currentSection === StringConstants.DESCRIPTION && (
                                                    <Label className="desc-label">Please tell us more about your request</Label>
                                                )}
                                                <div className="row-flex">
                                                    <div
                                                        tabIndex={0}
                                                        onClick={() => handleClick('BACK')}
                                                        onMouseDown={() => handleClick('BACK')}
                                                        onKeyPress={(e) => keyPress(e, handleClick, 'BACK')}
                                                        className={`btn  active ${
                                                            previousBtnEnablingHandler() ? 'button-active-hover' : ''
                                                        } ${
                                                            currentSection === StringConstants.DESCRIPTION
                                                                ? 'align-flex-start'
                                                                : ''
                                                        }`}
                                                        aria-label="previous"
                                                        role="button">
                                                        <span className="left-arrow"></span>
                                                    </div>

                                                    {currentSection === 'titleSearchField' && (
                                                        <TextField
                                                            tabIndex={0}
                                                            aria-label="This will be the title of your request"
                                                            label="This will be the title of your request"
                                                            placeholder="Type your request title here"
                                                            value={searchValue}
                                                            onChange={(e: any) => {
                                                                setTitleHText(false);
                                                                setSearchValue(e.target.value);
                                                            }}
                                                            onKeyPress={(e) => keyPress(e, handleClick, 'NEXT')}
                                                            className={`title-field ${titleHText ? 'error-feild' : ''} ${
                                                                animateTitle === 'S_TO_NEXT' ? 'nextSlide' : ''
                                                            } ${animateTitle === 'S_TO_PREV' ? 'prevSlide' : ''} ${
                                                                animateTitle === 'A_TO_RIGHT' ? 'animateToRIght' : ''
                                                            } ${animateTitle === 'A_TO_LEFT' ? 'animateToLeft' : ''}`}
                                                        />
                                                    )}
                                                    {currentSection === StringConstants.DESCRIPTION && (
                                                        <div
                                                            className={`description-field ${
                                                                animateDescription === 'S_TO_NEXT' ? 'nextSlide' : ''
                                                            } ${animateDescription === 'S_TO_PREV' ? 'prevSlide' : ''} ${
                                                                animateDescription === 'A_TO_RIGHT' ? 'animateToRIght' : ''
                                                            } ${animateDescription === 'A_TO_LEFT' ? 'animateToLeft' : ''}`}>
                                                            <CustomField
                                                                value={requestDescriptionToSubmit}
                                                                helperText={descHText}
                                                                setHelperText={setDescHText}
                                                                tempValue={tempDescriptionValue}
                                                                type="DESCRIPTION"
                                                                rows={8}
                                                                editorState={descEditorState}
                                                                setEditorState={setDescEditorState}
                                                                hashTagsFormatted={hashTagsFormatted}
                                                                setHashTagsFormatted={setHashTagsFormatted}
                                                                setTextToSubmit={setRequestDescriptionToSubmit}
                                                            />
                                                        </div>
                                                    )}
                                                    <span
                                                        tabIndex={0}
                                                        onClick={() => handleClick('NEXT')}
                                                        onMouseDown={() => handleClick('NEXT')}
                                                        onKeyPress={(e) => keyPress(e, handleClick, 'NEXT')}
                                                        className={`btn ${
                                                            nextBtnEnablingHandler() ? 'active  button-active-hover' : ''
                                                        } ${
                                                            currentSection === StringConstants.DESCRIPTION
                                                                ? 'align-flex-start'
                                                                : ''
                                                        } `}
                                                        aria-label="next"
                                                        role="button">
                                                        <span className="right-arrow"></span>
                                                    </span>
                                                </div>
                                                {titleHText && (
                                                    <Text className={`helper-txt`}>
                                                        <Trans>Please enter a title for your request.</Trans>
                                                    </Text>
                                                )}
                                                {descHText && (
                                                    <Text
                                                        className={`helper-txt`}
                                                        tabIndex={0}
                                                        aria-label="Please type in some details for your request so we can help resolve
                                                            your issue faster"
                                                        role="log">
                                                        <Trans>
                                                            Please type in some details for your request so we can help resolve
                                                            your issue faster.
                                                        </Trans>
                                                    </Text>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {newRequest && (
                        <div className={`new-req-container ${newRequest ? 'fadeIn' : ''}`}>
                            {currentSection === 'reqDetailsPage' && (
                                <div
                                    className={`new-req-body-container ${requestSection === 'preview' ? 'form-preview' : ''} ${
                                        requestSection === 'preview' ? 'preview-bg' : ''
                                    }`}>
                                    <div
                                        className={`title-header remove-bg ${
                                            requestSection === 'preview' ? 'change-border' : ''
                                        } `}>
                                        <Text className="new-request-text">Creating a new request</Text>
                                        <div
                                            onClick={() => {
                                                toggleCancelConfirmationDialog();
                                            }}
                                            className="cancel-request-link">
                                            Cancel request
                                        </div>
                                    </div>
                                    <div className="static-title">
                                        <span className="title-icon"></span>
                                        <Text className="title">
                                            <Trans>Title</Trans> :
                                        </Text>

                                        <Text className="title-value">{searchValue}</Text>
                                    </div>

                                    <div
                                        className={`active-bar
                                        ${
                                            requestSection === 'selectDevice' ||
                                            requestSection === 'DCF_ACTION' ||
                                            requestSection === 'action'
                                                ? 'first-tab'
                                                : ''
                                        }
                                        ${
                                            requestSection === 'customer' ||
                                            requestSection === 'newCustomer' ||
                                            requestSection === 'address'
                                                ? 'second-tab'
                                                : ''
                                        }
                                        ${requestSection === 'attachment' ? 'third-tab' : ''}
                                        ${requestSection === 'preview' ? 'fourth-tab' : ''}`}></div>
                                    <ul className="new-req-body-menu">
                                        <li
                                            className={`${
                                                requestSection === 'details' ||
                                                requestSection === 'selectDevice' ||
                                                requestSection === 'action' ||
                                                requestSection === 'DCF_ACTION'
                                                    ? 'active icon-move-up-down'
                                                    : 'complete-section'
                                            }`}
                                            onClick={selectFirstTab}>
                                            <div className="row-flex-box justify-center" tabIndex={0} aria-label="request details">
                                                <span className="row-flex-box align-center main-tab-item">
                                                    <span className="icon request-help-icon"></span>
                                                    <span>
                                                        <Trans>Request Details</Trans>
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                        <li
                                            className={`${
                                                requestSection === 'customer' ||
                                                requestSection === 'newCustomer' ||
                                                requestSection === 'address'
                                                    ? 'active icon-move-up-down'
                                                    : ''
                                            } ${
                                                requestSection === 'attachment' || requestSection === 'preview'
                                                    ? 'complete-section'
                                                    : ''
                                            }`}
                                            onClick={selectSecondTab}>
                                            <div className="row-flex-box justify-center" tabIndex={0} aria-label="customer opportunity">
                                                <span className="row-flex-box align-center main-tab-item">
                                                    <span className={`icon customer-opportunity-icon`}></span>{' '}
                                                    <span>
                                                        <Trans>Customer Opportunity</Trans>
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                        <li
                                            className={`${requestSection === 'attachment' ? 'active icon-move-up-down' : ''}${
                                                requestSection === 'preview' ? 'complete-section' : ''
                                            }`}
                                            onClick={selectThirdTab}>
                                            <div className="row-flex-box justify-center" tabIndex={0} aria-label="attachments">
                                                <span className="row-flex-box align-center main-tab-item">
                                                    <span className="icon attach-icon"></span>{' '}
                                                    <span>
                                                        <Trans>Attachments</Trans>
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                        <li className={`${requestSection === 'preview' ? 'active icon-move-up-down' : ''}`}>
                                            <div className="row-flex-box justify-center" tabIndex={0} aria-label="preview">
                                                <span className="row-flex-box align-center main-tab-item">
                                                    <span className="icon preview-icon"></span>{' '}
                                                    <span>
                                                        <Trans>Preview</Trans>
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>

                                    <div
                                        className={`new-req-form-container p-l-40 p-r-40 ${
                                            requestSection === 'preview' ? 'form-preview' : ''
                                        }`}>
                                        {requestSection !== 'attachment' &&
                                            requestSection !== 'preview' &&
                                            requestSection !== 'customer' &&
                                            requestSection !== 'newCustomer' && (
                                                <div
                                                    className={`new-req-form-left ${
                                                        requestSection === 'DCF_ACTION' ? 'increase-margin' : ''
                                                    }`}>
                                                    <div
                                                        onClick={previousWizard}
                                                        tabIndex={0}
                                                        onMouseDown={previousWizard}
                                                        onKeyPress={(e) => keyPress(e, previousWizard)}
                                                        className={`direction-arrow-container active ${
                                                            previousBtnEnablingHandler() ? 'btn-active-shadow' : ''
                                                        } `}
                                                        aria-label="previous"
                                                        role="button">
                                                        <span className="left-arrow"></span>
                                                    </div>
                                                </div>
                                            )}
                                        {(requestSection === 'customer' || requestSection === 'newCustomer') && (
                                            <div className={`new-req-form-left`}></div>
                                        )}
                                        <div
                                            className={`new-req-form-body p-b-20 ${
                                                requestSection === 'selectDevice' || requestSection === 'action'
                                                    ? 'change-width'
                                                    : ''
                                            }   ${
                                                requestSection === 'preview' ||
                                                requestSection === 'DCF_ACTION' ||
                                                requestSection === 'customer' ||
                                                requestSection === 'newCustomer' ||
                                                requestSection === 'address'
                                                    ? 'no-padding'
                                                    : ''
                                            } `}>
                                            {(requestSection === 'selectDevice' ||
                                                requestSection === 'action' ||
                                                requestSection === 'DCF_ACTION') && (
                                                <ul
                                                    className={`list-menu ${
                                                        requestType === StringConstants.POST_SALES ? 'post-list' : ''
                                                    }`}>
                                                    <li className={`completed `} onClick={onDetailsClicked}>
                                                        <span className="circle-icon"></span>
                                                        <span className="circle-txt">
                                                            <Trans>Details</Trans>
                                                        </span>
                                                    </li>

                                                    <li
                                                        onClick={onSelectDeviceClicked}
                                                        className={`${requestSection === 'selectDevice' ? 'active' : ''} ${
                                                            requestSection === 'action' || requestSection === 'DCF_ACTION'
                                                                ? 'completed'
                                                                : ''
                                                        }`}>
                                                        <span
                                                            className={`circle-icon ${
                                                                requestSection === 'selectDevice'
                                                                    ? 'device-active-icon'
                                                                    : 'device-icon'
                                                            }  `}></span>
                                                        <span className="circle-txt">
                                                            <Trans>Device</Trans>
                                                        </span>
                                                    </li>

                                                    <li
                                                        className={`${
                                                            requestSection === 'action' || requestSection === 'DCF_ACTION'
                                                                ? 'active'
                                                                : ''
                                                        } `}>
                                                        <span
                                                            className={`circle-icon ${
                                                                requestSection === 'action' || requestSection === 'DCF_ACTION'
                                                                    ? 'action-active-icon'
                                                                    : 'action-icon'
                                                            }  `}></span>
                                                        <span className="circle-txt">
                                                            <Trans>Action</Trans>
                                                        </span>
                                                    </li>
                                                </ul>
                                            )}
                                            {requestSection === 'selectDevice' && (
                                                <>
                                                    <SelectDevice
                                                        deviceSelected={deviceSelected}
                                                        modelSelected={modelSelected}
                                                        selectedProductId={selectedProductId}
                                                        modelsList={modelsList}
                                                        selectedModel={selectedModel}
                                                        addToCart={addToCart}
                                                        onCountChange={onCountChange}
                                                        cartHText={cartHText}
                                                    />
                                                    {addModelHText && (
                                                        <Text
                                                            className="helper-txt"
                                                            aria-label="Please select a model of products"
                                                            role="log"
                                                            tabIndex={0}>
                                                            <Trans>Please select a model of products.</Trans>
                                                        </Text>
                                                    )}
                                                    {addProductHText && (
                                                        <Text className="helper-txt">
                                                            <Trans>Please select a product family.</Trans>
                                                        </Text>
                                                    )}
                                                    {cartHText && (
                                                        <Text className="helper-txt">
                                                            <Trans>Please add the number of products.</Trans>
                                                        </Text>
                                                    )}
                                                </>
                                            )}

                                            {(selectedModel.filter((item) => item.added === true).length !== 0 ||
                                                requestSection === 'preview') && (
                                                <div
                                                    className={`added-devices ${cartState ? 'animateToRight' : ''}  ${
                                                        requestSection === 'preview' ? 'cart-preview' : ''
                                                    } `}>
                                                    <h2>
                                                        <Trans>Troubled Devices</Trans>
                                                    </h2>
                                                    {requestSection === 'preview' &&
                                                        !fieldValidation(selectedModel, 'modelCount', selectedModel) && (
                                                            <Text
                                                                className="more-link"
                                                                tabIndex={0}
                                                                onKeyPress={(e) => keyPress(e, addMoreDevices)}
                                                                onClick={() => addMoreDevices()}
                                                                aria-label="Add more devices"
                                                                role="link">
                                                                <Trans>+ Add more devices</Trans>
                                                            </Text>
                                                        )}
                                                    {requestSection === 'preview' &&
                                                        fieldValidation(selectedModel, 'modelCount', selectedModel) && (
                                                            <Text
                                                                className="more-link"
                                                                tabIndex={0}
                                                                onKeyPress={(e) => keyPress(e, addMoreDevices)}
                                                                onClick={() => addMoreDevices()}
                                                                aria-label="Add a device"
                                                                role="link">
                                                                <Trans>+ Add a device</Trans>{' '}
                                                            </Text>
                                                        )}
                                                    <div className={`added-devices-container`}>
                                                        {deviceSelected && (
                                                            <CartItem
                                                                selectedModel={selectedModel}
                                                                onCountChange={onCountChange}
                                                                removeSelectedModel={removeSelectedModel}
                                                                requestSection={requestSection}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {requestSection === 'newCustomer' && (
                                                <NewCustomer
                                                    setToggleSearch={setToggleSearch}
                                                    toggleSearch={toggleSearch}
                                                    setCustomerInputType={setCustomerInputType}
                                                    lastSelectedCustomerName={lastSelectedCustomerName}
                                                    setLastSelectedCustomerName={setLastSelectedCustomerName}
                                                    originalSelected={originalSelected}
                                                    setOriginalSelected={setOriginalSelected}
                                                    disabled={disabled}
                                                    setDisabled={setDisabled}
                                                    searchNotFound={searchNotFound}
                                                    setSearchNotFound={setSearchNotFound}
                                                    autoSuggestHText={autoSuggestHText}
                                                    setAutoSuggestHText={setAutoSuggestHText}
                                                    customerSearchInput={customerSearchInput}
                                                    setCustomerSearchInput={setCustomerSearchInput}
                                                    customerSelected={customerSelected}
                                                    setCustomerSelected={setCustomerSelected}
                                                    setFilteredData={setFilteredData}
                                                    customerImpactDescription={customerImpactDescription}
                                                    setCustomerImpactDescription={setCustomerImpactDescription}
                                                    impactsHText={impactsHText}
                                                    setImpactsHText={setImpactsHText}
                                                    accountID={customerAccountID}
                                                    setAccountID={setCustomerAccountID}
                                                    accountIDHText={customerAccountIDHText}
                                                    setAccountIDHText={setCustomerAccountIDHText}
                                                    tpidHText={customerTPIDHText}
                                                    setTPIDHText={setCustomerTPIDHText}
                                                    tpid={customerTPID}
                                                    setTPID={setCustomerTPID}
                                                    custName={customerName}
                                                    setStatus={setMsxStatus}
                                                    status={msxStatus}
                                                    setCustName={setCustomerName}
                                                    setCustNameHText={setCustomerNameHText}
                                                    custNameHText={customerNameHText}
                                                    country={country}
                                                    setCountry={setCountry}
                                                    setCountryHText={setCountryHText}
                                                    countryHText={countryHText}
                                                    revenueRisk={newRevenueRisk}
                                                    setRevenueRisk={setNewRevenueRisk}
                                                    revenueHText={revenueHText}
                                                    revenueValidText={revenueValidText}
                                                    setRevenueHText={setRevenueHText}
                                                    impDevices={newDeviceRisk}
                                                    setImpDevices={setNewDeviceRisk}
                                                    setImpDevicesHText={setImpDevicesHText}
                                                    impDevicesHText={impDevicesHText}
                                                    impDevicesValidText={impDevicesValidText}
                                                    setManualData={setManualData}
                                                    setRequestSection={setRequestSection}
                                                    animateNewCustomer={animateNewCustomer}
                                                    requestType={requestType}
                                                    cssCaseNum={cssCaseNum}
                                                    setCSSCaseNum={setCSSCaseNum}
                                                    setCaseNumHText={setCaseNumHText}
                                                    caseNumHText={caseNumHText}
                                                    countryList={countryList}
                                                    selectedRequestType={selectedRequestType}
                                                    setSelectedRequestType={setSelectedRequestType}
                                                />
                                            )}
                                            {requestSection === 'REVIEW_CUSTOMER' && (
                                                <ReviewCustomer
                                                    cssCaseNum={cssCaseNum}
                                                    setCSSCaseNum={setCSSCaseNum}
                                                    caseNumHText={caseNumHText}
                                                    setCaseNumHText={setCaseNumHText}
                                                    animateReviewCustomer={animateReviewCustomer}
                                                />
                                            )}
                                            {requestSection === 'customer' && (
                                                <Customer
                                                    oppId={msxOpportunityId}
                                                    setOppId={setMsxOpportunityId}
                                                    setcustAltPage={setCustomerAltPage}
                                                    showModal={showModal}
                                                    animateCustomer={animateCustomer}
                                                    requestType={requestType}
                                                    cssCaseNum={cssCaseNum}
                                                    setCSSCaseNum={setCSSCaseNum}
                                                    setCaseNumHText={setCaseNumHText}
                                                    caseNumHText={caseNumHText}
                                                    autoSuggestHText={autoSuggestHText}
                                                    setRevenueRisk={setRevenueRisk}
                                                    setImpDevices={setImpDevices}
                                                    isMSXListDataLoading={isMSXListDataLoading}
                                                    msxData={msxData}
                                                    setMSXMatched={setMSXMatched}
                                                    setMSXNotMatched={setMSXNotMatched}
                                                    removeDuplicates={removeDuplicates}
                                                    setSuggestions={setSuggestions}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    suggestions={suggestions}
                                                    dropDownValue={dropDownValue}
                                                    setDropDownValue={setDropDownValue}
                                                    setFilteredData={setFilteredData}
                                                    setAutoSuggestHText={setAutoSuggestHText}
                                                    msxMatched={msxMatched}
                                                    filteredData={filteredData}
                                                    msxNotMatched={msxNotMatched}
                                                    revenueRisk={revenueRisk}
                                                    setRevenueHText={setRevenueHText}
                                                    setRevenueValidText={setRevenueValidText}
                                                    revenueHText={revenueHText}
                                                    impDevicesValidText={impDevicesValidText}
                                                    revenueValidText={revenueValidText}
                                                    impDevices={impDevices}
                                                    setImpDevicesHText={setImpDevicesHText}
                                                    setImpDevicesValidText={setImpDevicesValidText}
                                                    impDevicesHText={impDevicesHText}
                                                    emailID={emailID}
                                                    setEmailID={setEmailID}
                                                    setEmailValidText={setEmailValidText}
                                                    setEmailHText={setEmailHText}
                                                    emailHtext={emailHText}
                                                    emailValidText={emailValidText}
                                                    setManualData={setManualData}
                                                    setRequestSection={setRequestSection}
                                                    customerSearchInput={customerSearchInput}
                                                    setCustomerSearchInput={setCustomerSearchInput}
                                                    customerSelected={customerSelected}
                                                    setCustomerSelected={setCustomerSelected}
                                                />
                                            )}
                                            {requestSection === 'address' && (
                                                <Address
                                                    custEmail={customerEmail}
                                                    contactNum={contactNum}
                                                    custShippingAddress={customerShippingAddress}
                                                    setAddress={setAddress}
                                                    custEmailHText={customerEmailHText}
                                                    contactNumHtext={contactNumHText}
                                                    custShippingAddHText={customerShippingAddHText}
                                                    contactName={contactName}
                                                    contactNameHText={contactNameHText}
                                                    requestType={requestType}
                                                    setcustAltPage={setCustomerAltPage}
                                                    showModal={showModal}
                                                    animateAddress={animateAddress}
                                                    contactNumPrefix={contactNumPrefix}
                                                    countryList={countryList}
                                                />
                                            )}
                                            {requestSection === 'action' && (
                                                <Action
                                                    tempDateImportanceValue={tempDateImportanceValue}
                                                    animateAction={animateAction}
                                                    dateImportance={dateImportanceToSubmit}
                                                    setDateImportanceHText={setDateImportanceHText}
                                                    msxMatched={msxMatched}
                                                    dateImportanceHText={dateImportanceHText}
                                                    requestType={requestType}
                                                    selectedDate={selectedDate}
                                                    setDateSent={setDateSent}
                                                    setSelectedDate={setSelectedDate}
                                                    selectedIssue={selectedIssue}
                                                    onIssueChange={onIssueChange}
                                                    issueHText={issueHText}
                                                    editorState={editorState}
                                                    setEditorState={setEditorState}
                                                    hashTagsFormatted={hashTagsFormatted}
                                                    setHashTagsFormatted={setHashTagsFormatted}
                                                    setTextToSubmit={setDateImportanceToSubmit}
                                                />
                                            )}
                                            {requestSection === 'DCF_ACTION' && (
                                                <ActionSection
                                                    selectedDCFKey={selectedDCFKey}
                                                    onBtnChange={onBtnChange}
                                                    fundsDescription={fundsDescription}
                                                    setAction={setAction}
                                                    fundHText={fundHText}
                                                    dcfBtnHText={dcfBtnHText}
                                                    animateDCFAction={animateDCFAction}
                                                />
                                            )}

                                            {requestSection === 'attachment' && (
                                                <AddAttachments
                                                    requestType={requestType}
                                                    attachmentList={attachmentList}
                                                    setAttachmentList={setAttachmentList}
                                                    animateAttachments={animateAttachments}
                                                    readyToUploadFiles={readyToUploadFiles}
                                                    setReadyToUploadFiles={setReadyToUploadFiles}
                                                    addToBlobUploadFiles={addToBlobUploadFiles}
                                                    uploadErrors={uploadErrors}
                                                    setUploadErrors={setUploadErrors}
                                                />
                                            )}

                                            {requestSection === 'preview' && (
                                                <div
                                                    className={`preview  ${animatePreview === 'S_TO_PREV' ? 'prevSlide' : ''} ${
                                                        animatePreview === 'S_TO_NEXT' ? 'nextSlide' : ''
                                                    } ${animatePreview === 'A_TO_LEFT' ? 'animateToLeft' : ''} ${
                                                        animatePreview === 'A_TO_RIGHT' ? 'animateToRight' : ''
                                                    }`}>
                                                    <RequestPreview
                                                        prevData={{
                                                            type: requestType,
                                                            title: searchValue,
                                                            desc:
                                                                requestDescriptionToSubmit.length > 0
                                                                    ? requestDescriptionToSubmit
                                                                    : requestDesc,
                                                            msxMatched: msxMatched,
                                                            filteredData: filteredData,
                                                            dateImportance:
                                                                dateImportanceToSubmit.length > 0
                                                                    ? dateImportanceToSubmit
                                                                    : dateImportance,
                                                            dueDate: dateSent,
                                                            impDevices: impDevices,
                                                            revenueRisk: revenueRisk,
                                                            productName: selectedProductName,
                                                            emailID: emailID,
                                                            shippingAdd: shippingAdd,
                                                            mobileNumber: mobileNumber,
                                                            custEmail: customerEmail,
                                                            contactNum: contactNum,
                                                            custShippingAddress: customerShippingAddress,
                                                            cssCaseNum: cssCaseNum,
                                                            fundsDescription: fundsDescription,
                                                            selectedDCFKey: selectedDCFKey,
                                                            manualData: manualData,
                                                            status: msxStatus,
                                                            custName: customerName,
                                                            tpid: customerTPID,
                                                            accountID: customerAccountID,
                                                            customerImpactDescription: customerImpactDescription,
                                                            country: country,
                                                            selectedIssue: selectedIssue,
                                                            contactName: contactName,
                                                            newDeviceRisk: newDeviceRisk,
                                                            newRevenueRisk: newRevenueRisk,
                                                            mobilePrefix: mobilePrefix,
                                                            contactNumPrefix: contactNumPrefix
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {(() => {
                                                if (
                                                    requestSection === 'attachment' &&
                                                    readyToUploadFiles &&
                                                    attachmentList.length > 0
                                                ) {
                                                    return (
                                                        <PrimaryButton
                                                            className={'action-next-btn m-t-45 active shadow-active'}
                                                            onClick={handleUploadAttachments}
                                                            disabled={isUplodingToBlob}>
                                                            {isUplodingToBlob ? (
                                                                <div className="upload-attachment-spinner">
                                                                    <Text className="upload-attachment-spinner-text">
                                                                        Uploading
                                                                    </Text>
                                                                    <Spinner size={SpinnerSize.large} />
                                                                </div>
                                                            ) : (
                                                                <Trans>Upload</Trans>
                                                            )}
                                                        </PrimaryButton>
                                                    );
                                                }
                                                if (
                                                    (requestSection === 'action' && requestType !== StringConstants.POST_SALES) ||
                                                    (requestSection === 'customer' &&
                                                        requestType !== StringConstants.POST_SALES) ||
                                                    requestSection === 'attachment' ||
                                                    requestSection === 'DCF_ACTION' ||
                                                    requestSection === 'address' ||
                                                    (requestSection === 'newCustomer' &&
                                                        requestType !== StringConstants.POST_SALES)
                                                ) {
                                                    return (
                                                        <div className="next-btn-styles">
                                                            <PrimaryButton
                                                                text={translate('Next')}
                                                                className={`action-next-btn m-t-45 ${
                                                                    nextBtnEnablingHandler() ? 'active shadow-active' : ''
                                                                }`}
                                                                onClick={() => {
                                                                    if (requestSection === 'newCustomer') {
                                                                        if (
                                                                            customerName?.trim() === '' ||
                                                                            country?.trim() === '' ||
                                                                            !isNumberGreaterThanZero(
                                                                                newRevenueRisk
                                                                                    .toString()
                                                                                    .replace('$', '')
                                                                                    .replaceAll(',', '')
                                                                            ) ||
                                                                            newDeviceRisk.trim() === '' ||
                                                                            newDeviceRisk.trim() === '0' ||
                                                                            (requestType === StringConstants.REVIEW_REQUEST &&
                                                                                cssCaseNum.trim() === '')
                                                                        ) {
                                                                            if (
                                                                                TEAM_DETAILS[0].id === 'CSS' ||
                                                                                TEAM_DETAILS[0].id === 'CXP'
                                                                            ) {
                                                                                setCaseNumHText(cssCaseNum.trim() === '');
                                                                            }
                                                                            customerInputType === 'search'
                                                                                ? setAutoSuggestHText(
                                                                                      customerName?.trim() === '' ? true : false
                                                                                  )
                                                                                : setCustomerNameHText(
                                                                                      customerName?.trim() === '' ? true : false
                                                                                  );
                                                                            setCountryHText(
                                                                                country?.trim() === '' ? true : false
                                                                            );
                                                                            setRevenueHText(
                                                                                !isNumberGreaterThanZero(
                                                                                    newRevenueRisk
                                                                                        .toString()
                                                                                        .replace('$', '')
                                                                                        .replaceAll(',', '')
                                                                                )
                                                                            );
                                                                            setImpDevicesHText(
                                                                                newDeviceRisk.trim() === '' ||
                                                                                    newDeviceRisk.trim() === '0'
                                                                            );

                                                                            setCaseNumHText(
                                                                                requestType === StringConstants.REVIEW_REQUEST &&
                                                                                    cssCaseNum.trim() === ''
                                                                            );
                                                                        } else {
                                                                            setAnimateNewCustomer('A_TO_RIGHT');
                                                                            setTimeout(() => {
                                                                                setAnimateAttachments('S_TO_PREV');
                                                                                setRequestSection('attachment');
                                                                            }, 300);
                                                                        }
                                                                    } else if (requestSection === 'action') {
                                                                        setTempDateImportanceValue(
                                                                            dateImportanceToSubmit.length > 0
                                                                                ? dateImportanceToSubmit.concat('')
                                                                                : dateImportance?.concat('')
                                                                        );
                                                                        if (
                                                                            (dateImportance.trim() === '' &&
                                                                                dateImportanceToSubmit.trim() === '') ||
                                                                            (requestType === 'CSSRequest' &&
                                                                                selectedIssue === undefined)
                                                                        ) {
                                                                            setIssueHText(
                                                                                requestType === 'CSSRequest' &&
                                                                                    selectedIssue === undefined
                                                                            );
                                                                            setDateImportanceHText(dateImportance.trim() === '');
                                                                        } else {
                                                                            setAnimateAction('A_TO_RIGHT');
                                                                            setTimeout(() => {
                                                                                if (requestType === StringConstants.POST_SALES) {
                                                                                    setAnimateDCFAction('S_TO_PREV');
                                                                                    setRequestSection('DCF_ACTION');
                                                                                } else {
                                                                                    if (manualData) {
                                                                                        setAnimateNewCustomer('S_TO_PREV');
                                                                                        setRequestSection('newCustomer');
                                                                                    } else {
                                                                                        setAnimateCustomer('S_TO_PREV');
                                                                                        setRequestSection('customer');
                                                                                    }
                                                                                }
                                                                            }, 300);
                                                                        }
                                                                    } else if (requestSection === 'DCF_ACTION') {
                                                                        if (
                                                                            (selectedDCFKey !== 'B' && selectedDCFKey !== 'A') ||
                                                                            (selectedDCFKey === 'A' &&
                                                                                fundsDescription.trim() === '')
                                                                        ) {
                                                                            setFundHText(
                                                                                selectedDCFKey === 'A' &&
                                                                                    fundsDescription.trim() === ''
                                                                            );
                                                                            setDCFBtnHText(
                                                                                selectedDCFKey !== 'B' && selectedDCFKey !== 'A'
                                                                            );
                                                                        } else {
                                                                            setAnimateDCFAction('A_TO_RIGHT');
                                                                            setTimeout(() => {
                                                                                if (manualData) {
                                                                                    setAnimateNewCustomer('S_TO_PREV');
                                                                                    setRequestSection('newCustomer');
                                                                                } else {
                                                                                    setAnimateCustomer('S_TO_PREV');
                                                                                    setRequestSection('customer');
                                                                                }
                                                                            }, 300);
                                                                        }
                                                                    } else if (requestSection === 'customer') {
                                                                        if (
                                                                            !isNumberGreaterThanZero(
                                                                                revenueRisk
                                                                                    ?.toString()
                                                                                    .replace('$', '')
                                                                                    .replaceAll(',', '')
                                                                            ) ||
                                                                            impDevices?.trim() === '' ||
                                                                            impDevices?.trim() === '0' ||
                                                                            (requestType === StringConstants.REVIEW_REQUEST &&
                                                                                cssCaseNum.trim() === '')
                                                                        ) {
                                                                            if (
                                                                                TEAM_DETAILS[0].id === 'CSS' ||
                                                                                TEAM_DETAILS[0].id === 'CXP'
                                                                            ) {
                                                                                setCaseNumHText(cssCaseNum.trim() === '');
                                                                            }
                                                                            setAutoSuggestHText(!customerSelected);
                                                                            setRevenueHText(
                                                                                !isNumberGreaterThanZero(
                                                                                    revenueRisk
                                                                                        ?.toString()
                                                                                        .replace('$', '')
                                                                                        .replaceAll(',', '')
                                                                                )
                                                                            );
                                                                            setImpDevicesHText(
                                                                                impDevices?.trim() === '' ||
                                                                                    impDevices?.trim() === '0'
                                                                                    ? true
                                                                                    : false
                                                                            );
                                                                            setEmailValidText(
                                                                                emailID.trim() !== '' &&
                                                                                    !fieldValidation(
                                                                                        emailID.trim(),
                                                                                        'email',
                                                                                        selectedModel
                                                                                    )
                                                                                    ? true
                                                                                    : false
                                                                            );
                                                                            setCaseNumHText(
                                                                                requestType === StringConstants.REVIEW_REQUEST &&
                                                                                    cssCaseNum.trim() === ''
                                                                            );
                                                                        } else {
                                                                            setAnimateCustomer('A_TO_RIGHT');
                                                                            setTimeout(function () {
                                                                                setAnimateAttachments('S_TO_PREV');
                                                                                setRequestSection('attachment');
                                                                            }, 300);
                                                                        }
                                                                    } else if (requestSection === 'address') {
                                                                        if (
                                                                            !fieldValidation(
                                                                                customerEmail.trim(),
                                                                                'email',
                                                                                selectedModel
                                                                            ) ||
                                                                            !fieldValidation(
                                                                                contactNum.trim(),
                                                                                'mobile number',
                                                                                selectedModel
                                                                            ) ||
                                                                            customerShippingAddress.trim() === '' ||
                                                                            contactName.trim() === '' ||
                                                                            !contactNumPrefix
                                                                        ) {
                                                                            setContactNameHText(
                                                                                contactName.trim() === '' ? true : false
                                                                            );
                                                                            setCustomerEmailHText(
                                                                                !fieldValidation(
                                                                                    customerEmail.trim(),
                                                                                    'email',
                                                                                    selectedModel
                                                                                )
                                                                            );
                                                                            setContactNumHText(
                                                                                !fieldValidation(
                                                                                    contactNum.trim(),
                                                                                    'mobile number',
                                                                                    selectedModel
                                                                                ) || !contactNumPrefix
                                                                            );
                                                                            setCustomerShippingAddHText(
                                                                                customerShippingAddress.trim() === ''
                                                                                    ? true
                                                                                    : false
                                                                            );
                                                                        } else {
                                                                            setAnimateAddress('A_TO_RIGHT');
                                                                            setTimeout(() => {
                                                                                setAnimateAttachments('S_TO_PREV');
                                                                                setRequestSection('attachment');
                                                                            }, 300);
                                                                        }
                                                                    } else if (requestSection === 'attachment') {
                                                                        setAnimateAttachments('A_TO_RIGHT');
                                                                        setTimeout(() => {
                                                                            setCartState(false);
                                                                            setAnimatePreview('S_TO_PREV');
                                                                            setRequestSection('preview');
                                                                        }, 300);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })()}

                                            {requestSection === 'preview' && (
                                                <>
                                                    <Text className="txt-black submitted">
                                                        <Trans>Created by</Trans> : {userDetails}
                                                        {' on '}
                                                        {moment(timestamp).format('D MMMM  YYYY')}
                                                    </Text>

                                                    <PrimaryButton
                                                        onClick={() => {
                                                            if (!fieldValidation(selectedModel, 'modelCount', selectedModel)) {
                                                                submitClick();
                                                            }
                                                        }}
                                                        className={`${
                                                            nextBtnEnablingHandler() ? 'active shadow-active' : ''
                                                        } action-next-btn submit-btn `}
                                                        text={translate('Submit')}
                                                    />
                                                </>
                                            )}
                                            {(requestSection === 'customer' ||
                                                requestSection === 'newCustomer' ||
                                                requestSection === 'attachment' ||
                                                requestSection === 'preview') && (
                                                <div className="next-btn-container">
                                                    <Link
                                                        tabIndex={0}
                                                        onClick={() => {
                                                            if (requestSection === 'customer') {
                                                                setAnimateCustomer('A_TO_LEFT');
                                                                setTimeout(function () {
                                                                    if (requestType === StringConstants.POST_SALES) {
                                                                        setAnimateDCFAction('S_TO_NEXT');
                                                                        setRequestSection('DCF_ACTION');
                                                                    } else {
                                                                        setAnimateAction('S_TO_NEXT');
                                                                        setRequestSection('action');
                                                                    }
                                                                }, 300);
                                                            }
                                                            if (requestSection === 'newCustomer') {
                                                                setManualData(false);
                                                                setRequestSection('customer');
                                                                setRevenueHText(false);
                                                                setImpDevicesHText(false);
                                                                setImpactsHText(false);
                                                            }
                                                            if (requestSection === 'attachment') {
                                                                setAnimateAttachments('A_TO_LEFT');
                                                                if (customerInputType === 'search') {
                                                                    setToggleSearch(false);
                                                                } else {
                                                                    setToggleSearch(true);
                                                                }
                                                                setTimeout(() => {
                                                                    if (requestType === StringConstants.POST_SALES) {
                                                                        setAnimateAddress('S_TO_NEXT');
                                                                        setRequestSection('address');
                                                                    } else {
                                                                        if (manualData) {
                                                                            setAnimateNewCustomer('S_TO_NEXT');
                                                                            setRequestSection('newCustomer');
                                                                        } else {
                                                                            setAnimateCustomer('S_TO_NEXT');
                                                                            setRequestSection('customer');
                                                                        }
                                                                    }
                                                                }, 300);
                                                            }
                                                            if (requestSection === 'preview') {
                                                                setTimeout(() => {
                                                                    setRequestSection('attachment');
                                                                }, 300);
                                                                setAnimateAttachments('S_TO_NEXT');
                                                                setAnimatePreview('A_TO_RIGHT');
                                                                setAnimateStep('STEP_4');
                                                                setAnimateStepReverse('STEP_4');
                                                                setCartState(false);
                                                            }
                                                        }}
                                                        className={`back-link
                                                    ${
                                                        requestType === StringConstants.POST_SALES &&
                                                        requestSection === 'customer'
                                                            ? `no-float`
                                                            : ''
                                                    } `}>
                                                        <Trans>Back</Trans>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            {(requestSection === 'details' ||
                                                requestSection === 'selectDevice' ||
                                                requestSection === 'REVIEW_CUSTOMER' ||
                                                (requestType === StringConstants.POST_SALES && requestSection === 'action') ||
                                                (requestType === StringConstants.POST_SALES &&
                                                    (requestSection === 'customer' || requestSection === 'newCustomer'))) && (
                                                <div className="new-req-form-right">
                                                    <span
                                                        tabIndex={0}
                                                        onClick={nextWizard}
                                                        onMouseDown={nextWizard}
                                                        onKeyPress={(e) => keyPress(e, nextWizard)}
                                                        className={`direction-arrow-container right-container ${
                                                            nextBtnEnablingHandler() ? 'active btn-active-shadow' : ''
                                                        }`}
                                                        aria-label="next"
                                                        role="navigation">
                                                        <span className="right-arrow"></span>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {((requestSection === 'action' && requestType !== StringConstants.POST_SALES) ||
                                            requestSection === 'DCF_ACTION' ||
                                            requestSection === 'address' ||
                                            ((requestSection === 'customer' || requestSection === 'newCustomer') &&
                                                requestType !== StringConstants.POST_SALES)) && (
                                            <div className="new-req-form-right"></div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    ;
                    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog}>
                        {quickLinksChange ? (
                            <div className="row-flex-box justify-center">
                                <Text className="device-error">
                                    All of your current changes will be discarded. Do you want to proceed?
                                </Text>
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={() => {
                                            toggleHideDialog();
                                            setQuickLinksChange(false);
                                            setTempQuickLinkID(quickLinksID);
                                            goBackToInitial('search');
                                            setOriginalRoutingML(null);
                                        }}
                                        text="Ok"
                                    />
                                    <DefaultButton onClick={toggleHideDialog} text="Cancel" />
                                </DialogFooter>
                            </div>
                        ) : (
                            <Spinner size={SpinnerSize.large} label="Please wait, while we redirect you to the right place" />
                        )}
                    </Dialog>
                    <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={true} className="modal add-customer-model">
                        {!customerAltPage && !isCreatingRequest && (
                            <LinkRoute
                                tabIndex={0}
                                onClick={() => {
                                    draftSaveRequest('"' + JSON.stringify({}).replace(/"/g, '\\"') + '"');
                                    history.push(RouteConstants.KNOWLEDGE_PORTAL);
                                }}
                                className="close-btn close-link"
                                to="/knowledge-portal"
                                data-testid="closebtn-test">
                                <Icon iconName="Cancel" />
                            </LinkRoute>
                        )}

                        {customerAltPage && (
                            <AlternateContactDetails
                                emailID={emailID}
                                setEmailValidText={setEmailValidText}
                                setEmailID={setEmailID}
                                emailValidText={emailValidText}
                                mobileNumber={mobileNumber}
                                setMobNumValidText={setMobNumValidText}
                                setMobileNumber={setMobileNumber}
                                mobNumValidText={mobNumValidText}
                                shippingAdd={shippingAdd}
                                setShippingAdd={setShippingAdd}
                                fieldValidation={fieldValidation}
                                selectedModel={selectedModel}
                                hideModal={hideModal}
                                goBackToInitial={goBackToInitial}
                                setInitialState={setInitialState}
                                setAnimateStep={setAnimateStep}
                                setAnimateStepReverse={setAnimateStepReverse}
                                setFullReverse={setFullReverse}
                                setCartState={setCartState}
                                custAltPage={customerAltPage}
                                isCreatingRequest={isCreatingRequest}
                                setcustAltPage={setCustomerAltPage}
                                countryList={countryList}
                                mobilePrefix={mobilePrefix}
                                setMobilePrefix={setMobilePrefix}
                            />
                        )}

                        {isCreatingRequest && (
                            <div className="modal-cont">
                                <Spinner size={SpinnerSize.large} label="Please wait, while we are processing your request" />
                            </div>
                        )}
                        {!isCreatingRequest && !errorMsg && successMsg && (
                            <div>
                                <div className="modal-cont">
                                    <div className="icon-thumbsUp">
                                        <svg
                                            width="217"
                                            height="188"
                                            viewBox="0 0 217 188"
                                            fill="#0078D4"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                className="thumb"
                                                d="M188.567 69.9824C176.986 72.7616 155.685 71.7891 148.767 71.9744L94.5 72.3831C94.5 72.3831 102.674 48.7113 115.5 39.383C121 35.3831 130.408 32.0198 135.245 33.3988C143.59 35.778 169.223 45.6359 188.937 37.7215C188.937 37.7215 204.843 30.0942 208.295 40.6903C211.748 51.2865 200.148 67.2032 188.567 69.9824Z"
                                            />
                                            <path
                                                className="fists"
                                                d="M156.335 65.7297C168.074 66.2633 195.552 61.728 202.222 76.1343C202.222 76.1343 212.093 92.1412 199.02 103.346C199.02 103.346 212.893 117.219 195.285 134.293C195.285 134.293 204.89 149.5 192.084 160.971C192.084 160.971 197.153 182.314 180.612 184.982H84.0368C84.0368 184.982 73.8991 179.113 69.3638 173.51V91.6077H78.968C78.968 91.6077 86.7047 84.4045 92.3071 75.6007C94.5327 72.1033 96.2953 67.9743 97.996 63.8828L109.5 59.8828C109.202 60.3879 109.216 60.8454 109.5 61.2601C112.513 65.6644 145.851 65.2421 156.335 65.7297Z"
                                            />
                                            <path
                                                className="wrist"
                                                d="M6.63043 89.3828C2.91739 89.3828 0 92.0778 0 95.5078V181.258C0 184.688 2.91739 187.383 6.63043 187.383H54.3696C58.0826 187.383 61 184.688 61 181.258V95.5078C61 92.0778 58.0826 89.3828 54.3696 89.3828H6.63043Z"
                                            />
                                        </svg>
                                    </div>

                                    <Text className="heading">
                                        <Trans>
                                            You have successfully created a new request with ID{' '}
                                            <Text className="new-skp-id">{postSKPId}</Text> for{' '}
                                            <Text className="customer-info">
                                                {manualData
                                                    ? customerName + ' ' + country
                                                    : [filteredData[0]?.AccountName, filteredData[0]?.Country].join(', ')}
                                            </Text>
                                        </Trans>
                                    </Text>
                                    <Text className="text">
                                        <Trans>The recently added requests can be viewed under</Trans>{' '}
                                        <LinkRoute
                                            tabIndex={0}
                                            onClick={() => goBackToInitial('new request')}
                                            className="unif-dashboard-link"
                                            to={`/unified-dashboard/${postSKPId}`}>
                                            <Trans>My Requests</Trans>
                                        </LinkRoute>{' '}
                                        <Trans>on the unified dashboard</Trans>
                                    </Text>
                                </div>
                                <Link
                                    tabIndex={0}
                                    onClick={() => {
                                        hideModal();
                                        goBackToInitial('new request');
                                        setInitialState(true);
                                        setAnimateStep('');
                                        setAnimateStepReverse('');
                                        setFullReverse(true);
                                        setCartState(false);
                                    }}
                                    className="new-req-link">
                                    <Trans>Create new request</Trans>
                                </Link>
                            </div>
                        )}
                        {errorMsg && (
                            <div>
                                <div className="modal-cont">
                                    <div className="error-icon">
                                        <Image src={errorIcon} alt="Error" />
                                    </div>
                                    <Text className="heading">
                                        <Trans>An error occurred while creating a request, please try again</Trans>
                                    </Text>
                                    <Text className="text">
                                        <Link
                                            tabIndex={0}
                                            onClick={() => {
                                                hideModal();
                                                goBackToInitial('new request');
                                                setInitialState(true);
                                                setAnimateStep('');
                                                setAnimateStepReverse('');
                                                setFullReverse(true);
                                                setCartState(false);
                                            }}>
                                            <Trans>Create a New Request</Trans>
                                        </Link>
                                    </Text>
                                </div>
                                <LinkRoute to="/" className="new-req-link go-to-home-link">
                                    <Trans>Go to home</Trans>
                                </LinkRoute>
                            </div>
                        )}
                    </Modal>
                    <Dialog hidden={hideCancelConfirmationDialog} onDismiss={toggleCancelConfirmationDialog}>
                        <div className="row-flex-box justify-center">
                            <Text className="device-error">
                                All of your current changes will be discarded. Do you want to proceed?
                            </Text>
                            <DialogFooter>
                                <PrimaryButton
                                    onClick={() => {
                                        setIsCancelClicked(true);
                                    }}
                                    text="Ok"
                                />
                                <DefaultButton onClick={toggleCancelConfirmationDialog} text="Cancel" />
                            </DialogFooter>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
};

/**
 * This Hook handles closing the useSearchSuggestionClose  on clicking outside of the useSearchSuggestion container
 */
const useSearchSuggestionClose = (ref: any, handleSuggestionClose: Function) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleSuggestionClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleSuggestionClose, ref]);
};

export default RequestExperience;
