import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'app/components/loader/Loader';
import './CustomReporting.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from 'app/components/button/Button';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { ActionsCell } from './actionsCell';
import { Checkbox, Label, Modal, Stack, Text, TextField, Image } from '@fluentui/react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { ReportType } from 'app/enums/ReportType.enum';
import _ from 'lodash';
import { useGetReports, useGetUserHasBookmarkedReports, useSpeechToTextToken } from 'app/services/queries';
import { IReport, ISharedWith, IShareWithUser } from 'app/models/common/post';
import { IAuditField } from 'app/models/common/request';
import { Constants, StringConstants } from 'app/utils/constants';
import { useAddReport, useBookmarkReport, useDeleteReport, useShareReport, useUpdateReport } from 'app/services/mutations';
import { UserDomain } from 'app/enums/UserDomain.enum';
import { PeoplePicker } from 'app/components/peoplePicker/PeoplePicker';
import { ISearchUser } from 'app/models/common/response';
import {
    changeDateFormat,
    getBookmarkedTeam,
    getQueryParam,
    isValidPowerBiURL,
    keyPress,
    sanitizePowerBIUrl,
    setQueryParam
} from 'app/components/helpers';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';
import Pagination from 'app/components/pagination/Pagination';
import useStore from 'app/store';
import ColumnHeader from './headerCells/ColumnHeader';
import PowerBISlider from '../powerBISlider/PowerBISlider';
import successIcon from '../../../static/icons/green-check.svg';
import deleteIcon from '../../../static/icons/delete-icon.svg';
type ActionType = '' | 'Delete' | 'Share' | 'Add' | 'Bookmark' | 'Edit';

const RESULT_PER_PAGE = 20;

type SortProperty = 'DisplayName' | 'Type' | 'ModifiedOn';

const CustomReporting: React.FC = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const userDetails = useStore((state: any) => state.userDetails);
    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history).page ?? 1));
    const [sortDirection, setSortDirection] = useState('1');
    const [reportType, setReportType] = useState<ReportType | null>(null);
    const [sortProperty, setSortProperty] = useState<SortProperty>('ModifiedOn');
    const [showModal, setModalStatus] = useState(false);
    const [selectedReport, setSelectedReport] = useState<IReport>();
    const [currentSelection, setCurrentSelection] = useState<'custom-reporting' | 'starred-reports'>(null);
    const [actionType, setActionType] = useState<ActionType>('');
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [reports, setReports] = useState<(IReport & IAuditField)[]>([]);
    const [newReport, setNewReport] = useState<IReport>();
    const [shareWith, setShareWith] = useState<ISharedWith>();
    const [selectedPeople, setSelectedPeople] = useState<ISearchUser[]>(
        (newReport?.sharedWithUsers as unknown as ISearchUser[]) || []
    );
    const [inputSearch, setInputSearch] = useState(null);
    const [, setTokenObj] = useState({ Token: '', Region: '' });
    const [isRecording, setIsRecording] = useState(false);
    const [recognizer, setRecognizer] = useState(null);

    const { data: generateToken, isLoading: isTokenLoading, refetch: getSpeechToTextToken } = useSpeechToTextToken();
    const {
        isLoading: isLoadingReports,
        data: reportList,
        refetch: getReports,
        isFetched
    } = useGetReports(
        Number(getQueryParam(history).page),
        RESULT_PER_PAGE,
        getQueryParam(history).search as string,
        sortProperty,
        sortDirection,
        currentSelection === 'starred-reports',
        reportType
    );

    const {
        isLoading: isLoadingUserHasBookmarked,
        isFetched: isFetchedUserHasBookmarked,
        data: userHasBookmarked
    } = useGetUserHasBookmarkedReports();

    const { isLoading: isLoadingBookmark, mutateAsync: bookmarkReport } = useBookmarkReport();
    const { isLoading: isUpdatingReport, mutateAsync: updateReport } = useUpdateReport();
    const { isLoading: isAddingReport, mutateAsync: addReport } = useAddReport();
    const { isLoading: isDeletingReport, mutateAsync: deleteReport } = useDeleteReport();
    const { isLoading: isSharingReport, mutateAsync: shareReport } = useShareReport();

    const accessToken = localStorage.getItem('accessToken');
    const message = useRef(null);
    const speechSdk = require('microsoft-cognitiveservices-speech-sdk');
    const modifyReport = (report: IReport, action: 'update' | 'remove') => {
        const index = reports.findIndex((r) => r.id === report.id);
        if (index > -1) {
            switch (action) {
                case 'update':
                    reports.splice(index, 1, report as unknown as IReport & IAuditField);
                    break;
                case 'remove':
                    reports.splice(index, 1);
                    break;
                default:
                    break;
            }
        }
    };

    const clickHandler = (type: ActionType, dataItem: IReport & IAuditField) => {
        message.current = null;
        setSelectedReport(dataItem);
        setSelectedPeople(
            dataItem?.sharedWithUsers?.map(
                (item: IShareWithUser) =>
                    ({
                        id: item.id,
                        displayName: item.displayName,
                        mail: item.email,
                        isBookmarked: item.isBookmarked,
                        userDomain: item.userDomain
                    } as ISearchUser)
            )
        );
        setSuccess(false);
        setError(false);
        setActionType('');
        setShareWith({
            id: dataItem?.id,
            sharedWithTeams: dataItem?.sharedWithTeams,
            sharedWithUsers: dataItem?.sharedWithUsers
        });

        switch (type) {
            case 'Bookmark':
                bookmarkReport(dataItem?.id)
                    .then((res) => {
                        setSelectedReport(res.data);
                        getReports();
                        setActionType('');
                    })
                    .catch(({ response }) => {
                        if (response.status === 400) {
                            message.current = 'Failed to bookmark, maximum report bookmarked is 4';
                        }
                        setError(true);
                    });
                break;
            case 'Edit':
                setNewReport({ ...dataItem } as unknown as IReport);
                break;
            case 'Delete':
                let bookmarkedTeamList = getBookmarkedTeam(dataItem, userDetails?.id);
                if (bookmarkedTeamList.length)
                    message.current = `The report is bookmarked by ${bookmarkedTeamList.join(', ')} ${
                        bookmarkedTeamList.length > 1 ? 'members' : 'member'
                    } `;
                else {
                    message.current = null;
                }
                break;
            default:
                break;
        }
        setActionType(type);
    };

    const handleSubmit = (type: ActionType) => {
        switch (type) {
            case 'Add':
                let report: IReport = newReport;
                setSelectedReport(report);
                if (!report.type) report.type = ReportType.ApplicationReport;
                report.url = sanitizePowerBIUrl(report.url);
                addReport({ ...report, ...shareWith })
                    .then((res) => {
                        setSelectedReport(res.data);
                        setSuccess(true);
                    })
                    .catch(() => setError(true))
                    .finally(() => {
                        setNewReport(null);
                        setShareWith(null);
                    });
                break;

            case 'Edit':
                if (newReport.type === ReportType.UtilizationInsightsReport)
                    setNewReport((prev: IReport) => ({ ...prev, sharedWithUsers: [], sharedWithTeams: '' }));
                updateReport({ ...newReport, url: sanitizePowerBIUrl(newReport.url) })
                    .then((res) => {
                        setSelectedReport(res.data);
                        setSuccess(true);
                    })
                    .catch(() => setError(true))
                    .finally(() => {
                        setNewReport(null);
                        setShareWith(null);
                    });
                break;

            case 'Share':
                let share: ISharedWith = shareWith;
                if (!share.sharedWithTeams) share.sharedWithTeams = '';
                if (!share.sharedWithUsers) share.sharedWithUsers = [];
                shareReport({ id: selectedReport?.id, ...share })
                    .then(() => {
                        setSuccess(true);
                    })
                    .catch(() => setError(true))
                    .finally(() => {
                        setShareWith(null);
                    });
                break;

            case 'Delete':
                deleteReport(selectedReport?.id)
                    .then(() => {
                        modifyReport(selectedReport, 'remove');
                        message.current = null;
                        setSuccess(true);
                    })
                    .catch(() => setError(true));
                break;
            default: {
            }
        }
    };

    const hideModal = () => {
        setActionType('');
        setError(false);
        setSuccess(false);
        setModalStatus(false);
        setSelectedReport(null);
        setSelectedPeople([]);
        setNewReport(null);
        setShareWith(null);
        message.current = null;
    };
    const showModalPopup = () => {
        setModalStatus(true);
    };
    const options: IChoiceGroupOption[] = [
        {
            key: ReportType[ReportType.ApplicationReport],
            text: _.startCase(ReportType[ReportType.ApplicationReport]),
            tabIndex: 0
        },

        {
            key: ReportType[ReportType.UtilizationInsightsReport],
            text: _.startCase(ReportType[ReportType.UtilizationInsightsReport]),
            tabIndex: 0
        }
    ];
    const _onChange = React.useCallback((ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
        setSelectedPeople([]);
        setSelectedReport((prev) => ({ ...prev, sharedWithTeams: '', sharedWithUsers: [] }));
        setNewReport((prev) => ({ ...prev, sharedWithTeams: '', sharedWithUsers: [] }));
        setShareWith((prev) => ({ ...prev, sharedWithTeams: '', sharedWithUsers: [] }));
        setNewReport((prev) => ({
            ...prev,
            type: ReportType[option.key as keyof typeof ReportType]
        }));
    }, []);

    const onSelect = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        let sharedWithTeam = shareWith?.sharedWithTeams?.split(',') || [];
        const teamId = UserDomain[ev.currentTarget.id as keyof typeof UserDomain].toString();
        let bookmarkedTeamList = getBookmarkedTeam(selectedReport, userDetails?.id);
        if (isChecked) {
            sharedWithTeam = [...sharedWithTeam, teamId];
            if (bookmarkedTeamList.includes(UserDomain[Number(teamId)])) message.current = null;
        } else {
            if (bookmarkedTeamList.includes(UserDomain[Number(teamId)]))
                message.current = `The report is bookmarked by ${bookmarkedTeamList.join(', ')} team members`;

            sharedWithTeam = sharedWithTeam.filter((team) => team !== teamId);
        }
        setShareWith((prev) => ({ ...prev, sharedWithTeams: sharedWithTeam.filter((t) => t).join(',') }));
    };

    const renderShareWith = () => {
        return (
            <>
                <Stack className="">
                    <Label>Share with</Label>
                    <div className="d-flex m-t-10">
                        <Checkbox
                            label="SDSG"
                            id="SDSG"
                            onChange={onSelect}
                            checked={shareWith?.sharedWithTeams?.includes(UserDomain.SDSG.toString())}
                            className="m-r-20"
                            disabled={newReport?.type === ReportType.UtilizationInsightsReport}
                        />
                        <Checkbox
                            label="CXP"
                            id="CXP"
                            onChange={onSelect}
                            checked={shareWith?.sharedWithTeams?.includes(UserDomain.CXP.toString())}
                            className="m-r-20"
                            disabled={newReport?.type === ReportType.UtilizationInsightsReport}
                        />
                        <Checkbox
                            label="CSS"
                            id="CSS"
                            onChange={onSelect}
                            checked={shareWith?.sharedWithTeams?.includes(UserDomain.CSS.toString())}
                            className="m-r-20"
                            disabled={newReport?.type === ReportType.UtilizationInsightsReport}
                        />
                        <Checkbox
                            label="CREW"
                            id="CREW"
                            onChange={onSelect}
                            checked={shareWith?.sharedWithTeams?.includes(UserDomain.CREW.toString())}
                            className="m-r-20"
                            disabled={newReport?.type === ReportType.UtilizationInsightsReport}
                        />
                    </div>
                    <div className="error-message">{`${message.current ? `${message.current}` : ''}`}</div>
                </Stack>
                <span className="m-t-20 m-b-20 d-flex fw--600">(OR)</span>
                <Label>Share with People</Label>
                <PeoplePicker
                    isDomainSpecific={false}
                    selectedPeople={selectedPeople}
                    setSelectedPeople={setSelectedPeople}
                    isSingleSelect={false}
                    disabled={newReport?.type === ReportType.UtilizationInsightsReport}
                    dropdownPosition="top"
                    type="Admin"
                />
            </>
        );
    };

    const renderSuccess = () => {
        let message = '';
        switch (actionType) {
            case 'Share':
                message = `Share details have been updated successfully`;
                break;
            case 'Edit':
                message = `Report has been updated successfully`;
                break;
            case 'Add':
                message = `Report has been saved successfully`;
                break;
            case 'Delete':
                message = `Report has been deleted`;
                break;
            default:
                break;
        }
        return (
            <div className="success-confirmation p-b-50">
                <div className="icon-border">
                    {(actionType === 'Add' || actionType === 'Edit' || actionType === 'Share') && (
                        <Image className="notification-icon" src={successIcon} alt="success icon" />
                    )}
                    {actionType === 'Delete' && <Image className="notification-icon" src={deleteIcon} alt="delete icon" />}
                </div>
                <Text className="jc-center d-flex fs--18 m-t-25">{message}</Text>
            </div>
        );
    };

    const renderWarning = () => {
        switch (actionType) {
            case 'Add':
                message.current = 'There was an error while adding the report';
                break;
            case 'Edit':
                message.current = 'There was an error while saving the report';
                break;
            case 'Delete':
                message.current = 'There was an error while deleting the report';
                break;
            case 'Bookmark':
                if (!message.current)
                    message.current = `There was an error while ${
                        selectedReport.isBookmarked ? 'adding to' : 'removing from'
                    } starred reports`;
                break;

            default:
                break;
        }

        return (
            <>
                <div className="delete-confirmation p-b-50">
                    <div className="sharing-report">
                        <Link to={`/report/${selectedReport.id}`} className="report-title report-link text-overflow--80">
                            {selectedReport.displayName}
                        </Link>
                    </div>
                    <i className="ms-Icon ms-Icon--WarningSolid jc-center d-flex m-t-40 fs--40" aria-hidden="true"></i>
                    <Text className="jc-center d-flex fs--18 m-t-25">{message.current}</Text>
                </div>
            </>
        );
    };

    const CommandCell = (props: GridCellProps) => {
        return <ActionsCell clickHandler={clickHandler} {...props} />;
    };

    const renderDate = (props: GridCellProps) => {
        const { field, dataItem } = props;
        return <td>{changeDateFormat(dataItem[field], Constants.DATE_FORMAT)}</td>;
    };

    const renderDisplayName = (props: GridCellProps) => {
        const { field, dataItem } = props;
        return (
            <td>
                <a className="link--url text-overflow--100" rel="noreferrer" href={`/report/${dataItem['id']}`}>
                    {dataItem[field]}
                </a>
            </td>
        );
    };
    const renderReportType = (props: GridCellProps) => {
        const { field, dataItem } = props;
        return <td>{_.startCase(ReportType[dataItem[field]])}</td>;
    };

    useEffect(() => {
        setQueryParam(
            history,
            pathname,
            {
                ...{
                    page: getQueryParam(history).page ?? '1'
                },
                ...(getQueryParam(history).search ? { search: getQueryParam(history).search } : null)
            },
            true
        );
    }, []);

    useEffect(() => {
        const { page, search } = getQueryParam(history);
        setPageIndex(Number(page));
        setInputSearch(search as string);
        getReports();
    }, [
        history?.location?.search,
        sortProperty,
        sortDirection,
        reportType,
        isUpdatingReport,
        isAddingReport,
        isSharingReport,
        isDeletingReport
    ]);

    useEffect(() => {
        setQueryParam(history, pathname, { page: '1' }, false);
        setInputSearch('');
        setSortProperty('ModifiedOn');
        setSortDirection('1');
        setReportType(null);
        getReports();
    }, [currentSelection]);

    useEffect(() => {
        if (!isTokenLoading) {
            setTokenObj(generateToken);
        }
    }, [isTokenLoading]);

    useEffect(() => {
        if (actionType !== '') showModalPopup();
    }, [actionType]);

    useEffect(() => {
        if (reportList) {
            setReports(reportList?.items);
        }
    }, [reportList]);

    useEffect(() => {
        const sharedWithUser = selectedPeople?.map((people: ISearchUser) => ({
            id: people.id,
            displayName: people.displayName,
            email: people.mail,
            isBookmarked: people.isBookmarked,
            userDomain: people.userDomain
        }));
        setShareWith((prev) => ({ ...prev, sharedWithUsers: sharedWithUser }));
    }, [selectedPeople]);

    useEffect(() => {
        if (isFetchedUserHasBookmarked && !isLoadingUserHasBookmarked) {
            if (userHasBookmarked) {
                setCurrentSelection('starred-reports');
            } else {
                setCurrentSelection('custom-reporting');
            }
        }
    }, [isFetchedUserHasBookmarked, isLoadingUserHasBookmarked]);

    const onChange = (event: any) => {
        setInputSearch(event.target.value);
    };

    const onKeyUp = (event: any) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            setQueryParam(history, pathname, { search: event.target.value }, false);
            setInputSearch(event.target.value);
        }
    };
    const iconProps = { iconName: 'Search' };

    const voiceSearchOn = () => {
        sttFromMic(true);
    };

    const voiceSearchOff = () => {
        setIsRecording(false);
        sttFromMic(false);
    };
    const sttFromMic = async (action: Boolean) => {
        if (action) {
            let token = JSON.parse(sessionStorage.getItem('speech-token'));
            if (!(token && token.Token)) {
                await getSpeechToTextToken();
                token = JSON.parse(sessionStorage.getItem('speech-token'));
            }
            const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(token?.Token ?? '', token?.Region ?? '');
            speechConfig.speechRecognitionLanguage = 'en-US';
            const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
            let newRecognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
            setRecognizer(newRecognizer);
            setIsRecording(true);
            newRecognizer.recognized = function (s: any, e: any) {
                let displayText;

                if (e.result.text) {
                    displayText = `${e.result.text}`;
                    displayText = displayText.substring(0, displayText.length - 1);
                    setQueryParam(history, pathname, { search: displayText }, false);
                    setInputSearch(displayText);
                    setIsRecording(false);

                    newRecognizer.stopContinuousRecognitionAsync(
                        (result: string) => {},
                        (e: string) => {}
                    );
                }
            };

            newRecognizer.startContinuousRecognitionAsync(() => {
                setTimeout(() => {
                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                    setIsRecording(false);
                }, 20000);
            });
        }

        if (!action) {
            recognizer.stopContinuousRecognitionAsync(
                (result: string) => {},
                (e: string) => {}
            );
        }
    };

    const renderHeader = (type: 'DisplayName' | 'Type' | 'ModifiedOn') => {
        return (
            <ColumnHeader
                type={type}
                setSortDirection={setSortDirection}
                setSortProperty={setSortProperty}
                sortDirection={sortDirection}
                sortProperty={sortProperty}
                setReportType={setReportType}
            />
        );
    };
    if (isLoadingUserHasBookmarked) {
        return <Loader classNames="height-70" />;
    } else {
        return (
            <div className="custom-reporting">
                <div>
                    <div className="custom-reporting__header">
                        <div className="left-column">
                            <div className="tabs">
                                <button
                                    data-testid="starred"
                                    className={currentSelection === 'starred-reports' ? 'active' : ''}
                                    onClick={() => setCurrentSelection('starred-reports')}>
                                    Starred reports
                                </button>
                                <button
                                    data-testid="available"
                                    className={currentSelection === 'custom-reporting' ? 'active' : ''}
                                    onClick={() => setCurrentSelection('custom-reporting')}>
                                    Available reports
                                </button>
                            </div>
                        </div>
                        {isSuperAdmin(accessToken) && (
                            <div className="right-column">
                                <div className="search-news-container d-flex m-r-10">
                                    <TextField
                                        value={inputSearch}
                                        iconProps={iconProps}
                                        placeholder="Search Report"
                                        onChange={(event) => onChange(event)}
                                        onKeyUp={(event) => onKeyUp(event)}
                                        className={`search-news-input`}
                                    />
                                    {isRecording && (
                                        <div
                                            className="recording-row recording-position"
                                            tabIndex={0}
                                            onKeyPress={(e: any) => keyPress(e, () => voiceSearchOff())}
                                            onClick={() => voiceSearchOff()}>
                                            <div className="first-line"></div>
                                            <div className="second-line"></div>
                                            <div className="third-line"></div>
                                            <div className="fourth-line"></div>
                                            <div className="fifth-line"></div>
                                        </div>
                                    )}
                                    {!isRecording && (
                                        <i
                                            className="micro-icon"
                                            tabIndex={0}
                                            onKeyPress={(e: any) => keyPress(e, () => voiceSearchOn())}
                                            onClick={() => voiceSearchOn()}></i>
                                    )}
                                </div>
                                <Button
                                    dataTestId="add-report-btn"
                                    buttonText="Add New Report"
                                    buttonClass="btn btn--sm btn--primary"
                                    iconClass="ms-Icon ms-Icon--Add"
                                    onClick={() => {
                                        setActionType('Add');
                                        setSelectedReport(null);
                                        setShareWith(null);
                                        setNewReport(null);
                                        setSelectedPeople([]);
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="custom-reporting__data">
                        {currentSelection === 'custom-reporting' && (
                            <div className="table-cont">
                                <div className="grid-cont">
                                    <Grid style={{ marginBottom: '30px' }} data={reports}>
                                        <Column
                                            filterable={false}
                                            width="400"
                                            field="modifiedOn"
                                            headerCell={() => renderHeader('ModifiedOn')}
                                            cell={renderDate}
                                            title="Date"
                                        />
                                        <Column
                                            field="displayName"
                                            filterable={true}
                                            cell={renderDisplayName}
                                            headerCell={() => renderHeader('DisplayName')}
                                            title="Report Name"
                                        />
                                        <Column
                                            filterable={false}
                                            width="300"
                                            field="type"
                                            cell={renderReportType}
                                            title="Report Type"
                                            headerCell={isSuperAdmin(userDetails?.userRoles) ? () => renderHeader('Type') : null}
                                        />
                                        <Column
                                            filterable={false}
                                            cell={CommandCell}
                                            width="200px"
                                            title={isAdmin(accessToken) ? 'Starred' : 'Actions'}
                                        />
                                    </Grid>
                                </div>
                            </div>
                        )}
                        {isFetched &&
                            !isLoadingReports &&
                            reports &&
                            reports?.length > 0 &&
                            currentSelection === 'custom-reporting' && (
                                <div className="ms-Grid-row text-center">
                                    <div className="pagination-container">
                                        <Pagination
                                            pageCount={reportList?.totalPages}
                                            setPageNumber={setPageIndex}
                                            initialPage={pageIndex - 1}
                                            totalCount={reportList?.totalCount}
                                            resultsPerPage={RESULT_PER_PAGE}
                                        />
                                    </div>
                                </div>
                            )}
                        {
                            <>
                                {isLoadingReports ||
                                isUpdatingReport ||
                                isLoadingBookmark ||
                                isAddingReport ||
                                isDeletingReport ||
                                isSharingReport ? (
                                    <Loader classNames="height-70 grid-loader" />
                                ) : (
                                    actionType !== '' && (
                                        <Modal
                                            data-testid="modal"
                                            isOpen={showModal}
                                            onDismiss={hideModal}
                                            isBlocking={false}
                                            className={`surface-modal modal--xl ${!(error || success) ? 'primary' : ''}`}>
                                            <div className="modal-header">
                                                <span>{success || error ? `` : `${actionType} the report`}</span>
                                                <span
                                                    className="close"
                                                    tabIndex={0}
                                                    aria-label="Close popup modal"
                                                    onClick={hideModal}
                                                    onKeyPress={(e: any) => keyPress(e, hideModal)}>
                                                    X
                                                </span>
                                            </div>
                                            <div className={`modal-body p-b-20 ${!(error || success) ? 'p-t-20' : ''}`}>
                                                {(actionType === 'Add' || actionType === 'Edit') && !(error || success) && (
                                                    <div className="add-new-report">
                                                        <TextField
                                                            label="Report name"
                                                            onChange={({ target }) =>
                                                                setNewReport((prev) => ({
                                                                    ...prev,
                                                                    displayName: (target as HTMLInputElement).value
                                                                }))
                                                            }
                                                            value={newReport?.displayName}
                                                            className="m-b-20"
                                                            required
                                                        />
                                                        <div className="m-b-20">
                                                            <TextField
                                                                label="Report link"
                                                                value={newReport?.url}
                                                                onChange={({ target }) =>
                                                                    setNewReport((prev) => ({
                                                                        ...prev,
                                                                        url: (target as HTMLInputElement).value
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <span className="error-message">
                                                                {isValidPowerBiURL(newReport?.url, false)
                                                                    ? ''
                                                                    : 'Invalid PowerBi embed URL'}
                                                            </span>
                                                        </div>
                                                        <ChoiceGroup
                                                            className="inline-radio m-b-20"
                                                            defaultSelectedKey={ReportType[ReportType.ApplicationReport]}
                                                            options={options}
                                                            selectedKey={ReportType[newReport?.type]}
                                                            onChange={_onChange}
                                                            label="Report type"
                                                            required={true}
                                                        />
                                                        {actionType === 'Add' && renderShareWith()}
                                                    </div>
                                                )}

                                                {actionType === 'Share' && !(error || success) && (
                                                    <div>
                                                        <div className="sharing-report text-left">
                                                            <label className="m-b-5">You are sharing:</label>
                                                            <Link
                                                                to={`/report/${selectedReport.id}`}
                                                                className="report-title report-link">
                                                                {selectedReport.displayName}
                                                            </Link>
                                                        </div>
                                                        {renderShareWith()}
                                                    </div>
                                                )}
                                                {actionType === 'Delete' && !(error || success) && (
                                                    <div>
                                                        <div className="sharing-report">
                                                            <label>You are deleting:</label>
                                                            <Link
                                                                to={`/report/${selectedReport.id}`}
                                                                className="report-title report-link">
                                                                {selectedReport.displayName}
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            {`${message.current ? `${message.current}, a` : 'A'}`}re you sure you
                                                            want to delete?
                                                        </div>
                                                    </div>
                                                )}

                                                {error && renderWarning()}
                                                {success && renderSuccess()}
                                            </div>
                                            {!error && !success && (
                                                <div className="modal-footer d-flex ai-left">
                                                    <Button buttonText="Cancel" onClick={hideModal} buttonClass="btn btn--sm" />
                                                    <Button
                                                        dataTestId="submit-button"
                                                        buttonText={actionType === 'Edit' ? 'Save' : actionType}
                                                        onClick={() => handleSubmit(actionType)}
                                                        buttonClass="btn btn--primary btn--sm"
                                                        disabled={
                                                            actionType === 'Add' || actionType === 'Edit'
                                                                ? !(
                                                                      newReport?.displayName?.trim() &&
                                                                      isValidPowerBiURL(newReport?.url, true)
                                                                  )
                                                                : false
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Modal>
                                    )
                                )}
                            </>
                        }
                    </div>

                    {!isLoadingReports &&
                        reports &&
                        currentSelection === 'starred-reports' &&
                        (reports.length > 0 ? (
                            <PowerBISlider data={reports} title="" />
                        ) : (
                            <div className="height-70 d-flex justify-content-center ai-center message">
                                {StringConstants.STARRED_REPORT_NOT_FOUND_MESSAGE}
                            </div>
                        ))}
                </div>
            </div>
        );
    }
};

export default CustomReporting;
