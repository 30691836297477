export enum unifiedDashboardCardsList {
    ALL_REQUEST,
    MY_REQUEST,
    STARRED_REQUEST,
    SHARED_WITH_ME
}

export enum filterConstants {
    ALL_REQUEST,
    NEW_REQUEST,
    ACTIVE_REQUEST,
    RESOLVED_REQUEST,
    CLOSED_REQUEST
}

export enum unifiedDashboardViewList {
    HELP_REQUEST_DETAILS,
    RELATED_REQUESTS
}

export enum manageRequestCardList {
    MANAGE_MY_REQUESTS,
    MANAGE_ALL_REQUESTS,
    CLOSED_REQUESTS
}
