import React, { useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { Breadcrumb, IBreadcrumbItem, IDropdownOption, Dropdown } from '@fluentui/react';
import { useDeleteArticle, useRemoveArticle, useSearchResult } from 'app/services/mutations';
import { useState } from 'react';
import SearchNews from '../SearchNews';
import { IArticleData, ITagsSearch, IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
import Pagination from 'app/components/pagination/Pagination';
import { Trans, useTranslation } from 'react-i18next';
import NewsCard from '../../newsCard/NewsCard';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isAdmin, isContentCreator, isContentManager, isSuperAdmin } from 'app/utils/authUtilities';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { StringConstants } from 'app/utils/constants';
import { Loader } from 'app/components/loader/Loader';
import { getQueryParam, setQueryParam } from 'app/components/helpers';

interface IProps {
    articles: Array<IArticleData>;
}

const ArticleSearchResults: React.FC<IProps> = (props: IProps) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const { mutateAsync: deleteArticleAPI } = useDeleteArticle();
    const { mutateAsync: removeArticleAPI } = useRemoveArticle();
    const [selectedArticle, setSelectedArticle] = useState<string>('');

    const options: IDropdownOption[] = [
        { key: 'Relevance', text: 'Relevance', data: { icon: 'BullseyeTarget' } },
        { key: 'Latest', text: 'Latest', data: { icon: 'List' } },
        { key: 'Earliest', text: 'Earliest', data: { icon: 'Previous' } }
    ];
    const onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <>
                {option.data && option.data.icon && (
                    <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </>
        );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        const option = options[0];

        return (
            <>
                {option.data && option.data.icon && (
                    <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span>{option.text}</span>
            </>
        );
    };
    const [searchField] = useState('Default');
    const [searchResultCount, setSearchResultCount] = useState();
    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history)?.page));

    const itemsWithHref: IBreadcrumbItem[] = [
        {
            text: pathname?.split('/')?.pop()?.includes('-articles')
                ? 'Articles'
                : pathname?.split('/')?.pop()?.includes(StringConstants.ARTICLES)
                ? 'Knowledge articles'
                : 'News feed',
            key: 'Newsfeed',
            onClick: () =>
                history.push(
                    pathname?.split('/')?.pop()?.includes('-articles')
                        ? '/manage-content/articles'
                        : pathname?.split('/')?.pop()?.includes(StringConstants.ARTICLES)
                        ? '/knowledge-articles/home'
                        : '/news-feed/home'
                )
        },
        { text: 'Search', key: 'Search', isCurrentItem: true }
    ];

    const {
        mutate: searchData,
        data: searchResultData,
        isSuccess: isSuccessSearch,

        isLoading
    } = useSearchResult();

    const [listData, setListData] = useState<Array<IArticleData>>();
    //admin search value
    const displayPageSize = 21;
    const getFilter = () => {
        switch (pathname.split('/').pop()) {
            case StringConstants.ARTICLES:
                return `Type eq ${ArticleType.KnowledgeArticle}`;
            case 'news':
                return `Type eq ${ArticleType.News} `;
            default:
                return 'Status lt 5';
        }
    };

    useEffect(() => {
        setQueryParam(
            history,
            pathname,
            {
                page: getQueryParam(history).page ?? '1',
                search: getQueryParam(history).search,
                sort: 'Relevance'
            },
            true
        );
    }, []);

    useEffect(() => {
        const { page = '1', sort = 'Relevance', search } = getQueryParam(history);
        setListData([]);
        setPageIndex(Number(getQueryParam(history).page));
        setSearchResultCount(null);
        if ((search as string)?.trim()) {
            searchData({
                searchTerm: search as string,
                pageIndex: Number(page),
                pageSize: displayPageSize,
                searchField: searchField,
                searchOption: 'Article',
                select: [],
                orderBy: updateOrderBy(sort as string),
                IsAdminSearch: isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles),
                filter: getFilter()
            });
        }
    }, [history?.location?.search]);

    useEffect(() => {
        if (isSuccessSearch) {
            if (searchResultData && searchResultData.data.SearchResult.Items) {
                const items = searchResultData.data.SearchResult.Items;
                const mappedArticleItems: Array<IArticleData> = items.map(({ Document: a }: any) => {
                    return {
                        type: Number(a.ArticleType),
                        id: a.Id,
                        coverPhoto: a.CoverPhoto,
                        thumbnail: a.Thumbnail,
                        title: a.Title,
                        content: a.Description,
                        shouldHideAuthorInfo: a.ShouldHideAuthorInfo,
                        tags: a.Tags.map((tag: ITagsSearch) => {
                            return {
                                id: tag.Id,
                                displayName: tag.DisplayName,
                                tagHierarchy: tag.TagHierarchy
                            };
                        }),
                        numberOfViews: a.NumberOfViews,
                        publishedOn: a.PublishedOn,
                        scheduledPublishOn: a.ScheduledPublishOn,
                        publishedBy: a.publishedBy,
                        status: a.Status,
                        createdOn: a.CreatedOn,
                        createdBy: {
                            id: a.createdBy?.Id,
                            displayName: a.createdBy?.DisplayName,
                            email: a.createdBy?.Email
                        },
                        modifiedOn: a.ModifiedOn,
                        modifiedBy: {
                            id: a.modifiedBy?.Id,
                            displayName: a.modifiedBy?.DisplayName,
                            email: a.modifiedBy?.Email
                        }
                    };
                });
                setListData(mappedArticleItems);
                setSearchResultCount(searchResultData.data.SearchResult.TotalCount);
            }
        }
    }, [isSuccessSearch, searchResultData]);

    useEffect(() => {
        setListData((list) => list.filter((a) => a.id !== selectedArticle));
    }, [selectedArticle]);

    const updateOrderBy = (filterValue: string) => {
        let orderBy: string[] = [];
        if (filterValue === 'Latest') {
            orderBy = ['ModifiedOn desc '];
        } else if (filterValue === 'Earliest') {
            orderBy = ['ModifiedOn'];
        } else if (filterValue === 'Relevance') {
            orderBy = [];
        }

        return orderBy;
    };

    const filterOnChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setQueryParam(history, pathname, { sort: option.key.toString() });
    };

    return (
        <>
            <div className="container">
                <div className="brd">
                    <Breadcrumb
                        items={itemsWithHref}
                        maxDisplayedItems={4}
                        ariaLabel="bread crumb"
                        overflowAriaLabel="More links"
                    />
                </div>
            </div>
            <div className="newsfeed-product-news-banner">
                <div className="container count-sort-section p-b-20 p-t-20">
                    <div className="article-count">
                        {(searchResultCount as number) > 0 && (
                            <b>
                                {searchResultCount} <Trans>results for your search</Trans>
                                {(getQueryParam(history).search as string) && ` ' ${getQueryParam(history).search as string}'`}
                            </b>
                        )}
                    </div>
                    <div className="sort-dropdown">
                        <span className="sort-by">
                            <Trans>Sort by</Trans>
                        </span>
                        <Dropdown
                            aria-label='Sort By'
                            onRenderTitle={onRenderTitle}
                            onRenderOption={onRenderOption}
                            selectedKey={getQueryParam(history)?.sort}
                            options={options}
                            className="selectType m-r-20"
                            onChange={filterOnChange}
                        />
                        <SearchNews
                            placeholder={translate(
                                pathname?.split('/')?.pop()?.includes('articles') ? 'Search article' : 'Search news'
                            )}
                            iconName="Search"
                            animate={false}
                            type={
                                pathname?.split('/')?.pop()?.includes('-articles')
                                    ? null
                                    : pathname?.split('/')?.pop()?.includes('articles')
                                    ? 'articles'
                                    : 'news'
                            }
                            showDefaultValue={true}
                        />
                    </div>
                </div>
                <div className="card-grid">
                    <div className="ms-Grid container" dir="ltr">
                        <div className="ms-Grid-row">
                            {listData &&
                                listData.map((data) => (
                                    <div className="ms-Grid-col ms-sm12 ms-lg4 card-list" key={data.id}>
                                        <NewsCard
                                            showMoreOptions={
                                                isSuperAdmin(userDetails?.userRoles) ||
                                                isContentManager(userDetails?.userRoles) ||
                                                isContentCreator(userDetails?.userRoles)
                                            }
                                            data={data}
                                            deleteArticleAPI={(id: string) => {
                                                deleteArticleAPI(id).then(() => {
                                                    setSelectedArticle(id);
                                                });
                                            }}
                                            removeArticleAPI={(id: string) => {
                                                removeArticleAPI(id).then(() => {
                                                    setSelectedArticle(id);
                                                });
                                            }}
                                            showLastEdited={
                                                isSuperAdmin(userDetails?.userRoles) ||
                                                isContentManager(userDetails?.userRoles) ||
                                                isContentCreator(userDetails?.userRoles)
                                            }
                                            showArticleType={
                                                isSuperAdmin(userDetails?.userRoles) ||
                                                isContentManager(userDetails?.userRoles) ||
                                                isContentCreator(userDetails?.userRoles)
                                            }
                                            showStatus={
                                                isSuperAdmin(userDetails?.userRoles) ||
                                                isContentManager(userDetails?.userRoles) ||
                                                isContentCreator(userDetails?.userRoles)
                                            }
                                        />
                                    </div>
                                ))}
                        </div>
                        {isLoading && <Loader classNames="height-70" />}

                        {(!searchResultData || (listData && listData.length <= 0)) && !isLoading && (
                            <div className="ms-Grid-row">
                                <h4 className="height-60 d-flex jc-center ai-center message">
                                    <Trans>
                                        {StringConstants.ARTICLE_NOT_FOUND_MESSAGE}
                                        &nbsp;
                                        <Link to="/submit-feedback">here</Link>.
                                    </Trans>
                                </h4>
                            </div>
                        )}
                    </div>
                </div>
                {listData && listData.length > 0 && (
                    <div className="pagination-container">
                        <Pagination
                            pageCount={searchResultData?.data.SearchResult.TotalPages}
                            setPageNumber={setPageIndex}
                            initialPage={pageIndex - 1}
                            totalCount={searchResultCount}
                            resultsPerPage={displayPageSize}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ArticleSearchResults;
