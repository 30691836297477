import React, { useState, useEffect, useLayoutEffect } from 'react';
import './VisualFlow.scss';
import VisualFlowStep from './VisualFlowStep';
import VisualFlowHeader from './VisualFlowHeader';
import Comments from './comments';
import { useVisualStepsData, useVisualComments } from 'app/services/queries';
import leftArrow from 'app/static/icons/left-arrow-filled.svg';
import rightArrow from 'app/static/icons/right-arrow-fill.svg';
import commentIcon from 'app/static/icons/comment-icon.svg';
import defaultIcon from 'app/static/icons/vf-default.svg';
import RequestCreated from 'app/static/icons/vf-created.svg';
import RequestAssignedToTeam from 'app/static/icons/vf-assigned-to-team.svg';
import RequestAssignedToUser from 'app/static/icons/vf-assigned-to-user.svg';
import RequestReassigned from 'app/static/icons/vf-reassigned.svg';
import RequestCustomerAdded from 'app/static/icons/vf-customer-added.svg';
import RequestCustomerDeleted from 'app/static/icons/vf-customer-deleted.svg';
import RequestResolved from 'app/static/icons/vf-resolved.svg';
import RequestClosed from 'app/static/icons/vf-closed.svg';
import RequestInProgress from 'app/static/icons/vf-in-progress.svg';
import RequestActive from 'app/static/icons/vf-active.svg';
import RequestUpdated from 'app/static/icons/vf-updated.svg';
import RequestAttachmentAdded from 'app/static/icons/vf-attachment-added.svg';
import RequestAttachmentUpdated from 'app/static/icons/vf-attachment-updated.svg';
import RequestAttachmentDeleted from 'app/static/icons/vf-attachment-deleted.svg';
import RequestDeleted from 'app/static/icons/vf-deleted.svg';
import RequestAssociateAsParent from 'app/static/icons/vf-associate-as-parent.svg';
import RequestAssociateChildToParent from 'app/static/icons/vf-associate-child-to-parent.svg';
import RequestAssociateAsChild from 'app/static/icons/vf-associate-as-child.svg';
import RequestUnassociate from 'app/static/icons/vf-unassociate.svg';
import RequestPriorityChanged from 'app/static/icons/vf-priority-changed.svg';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Modal, PersonaInitialsColor } from '@fluentui/react';
import Avatar from 'app/components/avatar/Avatar';
import { Trans } from 'react-i18next';
import { injectTargetToAnchor, keyPress, ReplaceUrlByAnchor } from 'app/components/helpers';
import _ from 'lodash';
import Button from 'app/components/button/Button';
import { useBoolean } from '@fluentui/react-hooks';

export enum ActivityStatus {
    'new' = 1,
    'active' = 2,
    'inprogress' = 3,
    'resolved' = 4,
    'closed' = 5
}

export enum VisualFlowIcon {
    RequestCreated = 1,
    RequestAssignedToTeam = 2,
    RequestAssignedToUser = 3,
    RequestReassigned = 4,
    RequestCustomerAdded = 5,
    RequestCustomerDeleted = 6,
    RequestResolved = 7,
    RequestClosed = 8,
    RequestInProgress = 9,
    RequestActive = 10,
    RequestUpdated = 11,
    RequestAttachmentAdded = 12,
    RequestAttachmentUpdated = 13,
    RequestAttachmentDeleted = 14,
    // RequestCommentAdded = 15, // Unused
    // RequestCommentDeleted = 16, // Unused
    // RequestCommentUpdated = 17, // Unused
    RequestDeleted = 18,
    RequestAssociateAsParent = 19,
    RequestAssociateChildToParent = 20,
    RequestAssociateAsChild = 21,
    RequestUnassociate = 22,
    RequestPriorityChanged = 23
}

export interface IVisualFlowData {
    requestId: string;
    title: string;
    activities: IActivitiesEntity[];
}

export interface IActivitiesEntity {
    title: string;
    eventType: string;
    activityType: string;
    unifiedTrackingId: string;
    icon: number;
    status: number;
    attachmentCount: number;
    attachments?: any;
    requestType: number;
    requestTeam: number;
    assignee?: null;
    isActive: boolean;
    commentCount: number;
    entityType: number;
    id: string;
    createdOn: string;
    createdBy: any;
    modifiedOn?: string | null;
    modifiedBy?: any;
    aging: string;
    moreActivities: any;
    comments: [];
}

export interface ICommentsRequest {
    correlationId: string;
    entintyType: number;
    dateRangeFrom: string;
    dateRangeTo: string;
    pageNumber: number;
    pageSize: number;
}

function useContainerSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            var container = document.getElementsByClassName('visual-flow__steps-container');
            if (container[0]) setSize([container[0].clientWidth, container[0].clientHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function VisualFlow() {
    const { pathname } = useLocation();
    var pathArray = pathname.split('/');
    const id = pathArray && pathArray[pathArray.length - 1];
    const [width] = useContainerSize();
    const [showAllSteps, setShowAllSteps] = React.useState(false);
    const [showNextButton, setShowNextButton] = React.useState(false);
    const [showPrevButton, setShowPrevButton] = React.useState(false);
    const [counter, setCounter] = useState(0);
    const [steps, setStepsData] = useState<IActivitiesEntity[]>([]);
    const [onceExpanded, setOnceExpanded] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [notOnLoad, setNotOnLoad] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [maximizedView, setMaximizedView] = useState(false);
    const [onPageLoad, setOnPageLoad] = useState(true);
    const [showComment, setShowComments] = useState(false);
    const [modalPosition, setModalPosition] = useState([0, 0]);
    const [showModalId, setShowModalId] = useState();

    const [showExpandMsg, setShowExpandMsg] = React.useState(false);
    const [showCollapseMsg, setShowCollapseMsg] = React.useState(false);
    const [hoveredStep, setHoveredStep] = useState<IActivitiesEntity>();

    const { data: visualStepsData, isLoading } = useVisualStepsData(id);

    const { mutate: commentsRequest, data: commentsData } = useVisualComments();

    const [isListViewClicked, setIsListViewClicked] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            if (visualStepsData && visualStepsData.activities) {
                setStepsData(visualStepsData.activities);
            }
        }
    }, [isLoading, visualStepsData]);

    useEffect(() => {
        if (visualStepsData && visualStepsData.activities) {
            var commentsCount = visualStepsData.activities.reduce(function (sum: any, activity: any) {
                return sum + activity.commentCount;
            }, 0);

            var fromDate = moment(visualStepsData.activities[0].createdOn).format('YYYY-MM-DDT00:00:00');
            var toDate = moment(visualStepsData.activities[visualStepsData.activities.length - 1].createdOn).format(
                'YYYY-MM-DDT23:59:59'
            );

            commentsRequest({
                correlationId: id,
                entintyType: 1,
                dateRangeFrom: fromDate,
                dateRangeTo: toDate,
                pageNumber: 1,
                pageSize: commentsCount
            });
        }
    }, [visualStepsData]);

    useEffect(() => {
        if (visualStepsData && commentsData && commentsData.data.items && commentsData.data.items.length > 0) {
            commentsData.data.items.reverse();
            var sum = 0;
            visualStepsData.activities.forEach((item: any) => {
                for (var index = 1; index <= item.commentCount; index++) {
                    if (!item.comments) item.comments = [];
                    item.comments.push(commentsData.data.items[sum++]);
                }
            });
        }
    }, [commentsData]);

    useEffect(() => {
        if (!visualStepsData) return;
        visualStepsData.activities?.forEach((item: any, index: any) => {
            if (item.status === 3) {
                var currentDate = new Date(item.modifiedOn);
                var previousModifiedDate =
                    index === 1 ? visualStepsData.activities[0].createdOn : visualStepsData.activities[index - 1].modifiedOn;
                var previousDate = new Date(previousModifiedDate);
                var ageDifference = String((currentDate.getTime() - previousDate.getTime()) / (24 * 3600 * 1000));
                item.aging = `aging: ${parseInt(ageDifference)}d`;
            } else {
                item.aging = ActivityStatus[item.status];
            }
            return item;
        });
        setStepsData(visualStepsData.activities);
    });

    const expandSteps = () => {
        setShowAllSteps(true);
        setShowNextButton(true);
        setOnceExpanded(true);
        setNotOnLoad(false);
        setExpanded(true);
        setMaximizedView(false);
        setOnPageLoad(false);
        setShowComments(false);

        setShowExpandMsg(false);
    };
    const collapseSteps = () => {
        setShowAllSteps(false);
        setShowNextButton(false);
        setNotOnLoad(false);
        setExpanded(false);
        setMaximizedView(false);
        setOnPageLoad(false);
        setShowComments(false);

        setShowExpandMsg(false);
        setShowCollapseMsg(false);
    };

    const nextVisualSteps = () => {
        setCounter(counter + 1);
        setOnPageLoad(false);
        setShowComments(false);
        if (6 * (counter + 2) >= steps?.length) {
            setShowNextButton(false);
        } else {
            setShowNextButton(true);
        }

        if (counter >= 0) {
            setShowPrevButton(true);
        } else {
            setShowPrevButton(false);
        }
    };
    const prevVisualSteps = () => {
        setCounter(counter - 1);
        setNotOnLoad(true);
        setShowComments(false);
        setOnPageLoad(false);
        if (6 * (counter + 1) >= steps?.length) {
            setShowNextButton(false);
        } else {
            setShowNextButton(true);
        }

        if (counter === 1) {
            setShowPrevButton(false);
            setShowNextButton(true);
        } else if (counter >= 1) {
            setShowNextButton(true);
        }
    };

    const [isUpdateWarningModalOpen, { setTrue: showUpdateErrorModal, setFalse: hideUpdateWarningModal }] = useBoolean(false);

    const setFitIntoTheScreen = () => {
        if (steps.length > 10) {
            showUpdateErrorModal();
        } else {
            setFullScreen(true);
            setMaximizedView(false);
            setOnPageLoad(false);
            setShowComments(false);
        }
    };
    const setMaximizeView = () => {
        setFullScreen(false);
        setMaximizedView(true);
        setOnPageLoad(false);
        setShowComments(false);
    };
    const triggerShowComments = (e: any, id: any) => {
        const vfElePos = document.querySelector('.visual-flow-body').getBoundingClientRect().y;
        const vfPosY = e.target.getBoundingClientRect().y;
        const vfPosX = e.target.getBoundingClientRect().x;
        setShowModalId(id);
        const hoveredStep: IActivitiesEntity = steps[id - 1];
        setHoveredStep(hoveredStep);
        setModalPosition([vfPosX, vfPosY - vfElePos]);
        if (id !== showModalId) {
            setShowComments(true);
        } else {
            setShowComments(!showComment);
        }
    };

    const onHoverComments = () => {
        setShowComments(true);
    };

    const onMouseLeaveComments = () => {
        setShowComments(false);
    };

    const hideComments = (e: any, id: any) => {
        setShowComments(false);
    };

    const showOrHideAllDetails = () => {
        setIsListViewClicked((x) => !x);
    };

    const showOrHideListView = () => {
        setIsListViewClicked((x) => !x);
    };

    const getIcon = (icon: VisualFlowIcon) => {
        switch (icon) {
            case VisualFlowIcon.RequestCreated:
                return <img src={RequestCreated} alt="Created" />;
            case VisualFlowIcon.RequestAssignedToTeam:
                return <img src={RequestAssignedToTeam} alt="Assigned to Team" />;
            case VisualFlowIcon.RequestAssignedToUser:
                return <img src={RequestAssignedToUser} alt="Assigned to User" />;
            case VisualFlowIcon.RequestReassigned:
                return <img src={RequestReassigned} alt="Reassigned" />;
            case VisualFlowIcon.RequestCustomerAdded:
                return <img src={RequestCustomerAdded} alt="Customer Added" />;
            case VisualFlowIcon.RequestCustomerDeleted:
                return <img src={RequestCustomerDeleted} alt="Customer Deleted" />;
            case VisualFlowIcon.RequestResolved:
                return <img src={RequestResolved} alt="Resolved" />;
            case VisualFlowIcon.RequestClosed:
                return <img src={RequestClosed} alt="Closed" />;
            case VisualFlowIcon.RequestInProgress:
                return <img src={RequestInProgress} alt="In Progress" />;
            case VisualFlowIcon.RequestActive:
                return <img src={RequestActive} alt="Active" />;
            case VisualFlowIcon.RequestUpdated:
                return <img src={RequestUpdated} alt="Updated" />;
            case VisualFlowIcon.RequestAttachmentAdded:
                return <img src={RequestAttachmentAdded} alt="Attachment Added" />;
            case VisualFlowIcon.RequestAttachmentUpdated:
                return <img src={RequestAttachmentUpdated} alt="Attachment Updated" />;
            case VisualFlowIcon.RequestAttachmentDeleted:
                return <img src={RequestAttachmentDeleted} alt="Attachment Deleted" />;
            case VisualFlowIcon.RequestDeleted:
                return <img src={RequestDeleted} alt="Deleted" />;
            case VisualFlowIcon.RequestAssociateAsParent:
                return <img src={RequestAssociateAsParent} alt="Associated as Parent" />;
            case VisualFlowIcon.RequestAssociateChildToParent:
                return <img src={RequestAssociateChildToParent} alt="Child Associated" />;
            case VisualFlowIcon.RequestAssociateAsChild:
                return <img src={RequestAssociateAsChild} alt="Associated as Child" />;
            case VisualFlowIcon.RequestUnassociate:
                return <img src={RequestUnassociate} alt="Unassociated" />;
            case VisualFlowIcon.RequestPriorityChanged:
                return <img src={RequestPriorityChanged} alt="Priority Changed" />;
            default:
                return <img src={defaultIcon} alt="Request" />;
        }
    };

    const getTaggedUser = (name: string, users: any) => {
        if (!_.isEmpty(name) && !_.isEmpty(users)) {
            return _.find(users, (item) => item.id === name);
        } else {
            return {
                id: '',
                displayName: '',
                email: ''
            };
        }
    };

    const generateCommentMessage = (message: string, taggedUsers: any) => {
        let formattedMsg = message;
        let users = Array.from(message?.matchAll(/<userid:(.*?)>/g));
        for (const user of users) {
            formattedMsg = formattedMsg.replace(
                user[0],
                `<span class="tagged-user">${getTaggedUser(user[1], taggedUsers).displayName}</span>`
            );
        }
        formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request-detail');
        formattedMsg = ReplaceUrlByAnchor(injectTargetToAnchor(formattedMsg?.replace(/\\/g, '')), 'tagged-user', true);
        return formattedMsg;
    };

    return (
        <div className={` ${!isListViewClicked ? 'visual-flow-body' : ''}`}>
            <VisualFlowHeader
                visualFlowData={visualStepsData}
                fullScreenMode={fullScreen}
                setFitIntoTheScreen={setFitIntoTheScreen}
                setMaximizeView={setMaximizeView}
                showOrHideAllDetails={showOrHideAllDetails}
                isListViewClicked={isListViewClicked}
                showOrHideListView={showOrHideListView}
            />
            {!isListViewClicked ? (
                <div>
                    <div
                        className={`visual-flow container ${steps?.length <= 6 ? 'upto6steps' : 'morethan6steps'} ${
                            onPageLoad ? 'onPageLoad' : ''
                        } ${expanded ? 'expanded' : ''} ${maximizedView ? 'maximize-view' : ''} ${
                            fullScreen ? 'fit-into-screen' : ''
                        } ${counter === 0 && notOnLoad ? 'againInFirstSlide' : ''} ${
                            counter > 0 ? 'notInFirstSlide' : 'inFirstSlide'
                        } ${showAllSteps ? 'show-all-steps' : 'show-less-steps'} ${onceExpanded ? 'expandedOnce' : ''}`}>
                        <div className={`visual-flow__line ${!showNextButton ? 'visual-flow__line--size2' : ''}`}></div>
                        <div className="visual-flow__steps-container">
                            <div className={`visual-flow__steps slide--${counter}`} style={{ left: `-${width * counter}px` }}>
                                {steps?.map((step, index) => (
                                    <VisualFlowStep
                                        key={index}
                                        id={index + 1}
                                        title={step.title}
                                        lastActivity={step.modifiedOn ? step.modifiedOn : step.createdOn}
                                        date={step.modifiedOn ? step.modifiedOn : step.createdOn}
                                        age={step.aging}
                                        moreActivities={step.moreActivities}
                                        status={ActivityStatus[step.status]}
                                        attachmentCount={step.attachmentCount}
                                        triggerShowComments={triggerShowComments}
                                        hideComments={hideComments}
                                        showComment={showComment}
                                        width={`${width / 6 - 14}px`}
                                        getIcon={getIcon}
                                        icon={step.icon}
                                        commentCount={step.commentCount}
                                    />
                                ))}
                            </div>
                        </div>
                        {showPrevButton ? (
                            <div
                                className={`step-icon step-icon--prev `}
                                tabIndex={0}
                                onKeyPress={(e: any) => keyPress(e, prevVisualSteps)}
                                onClick={prevVisualSteps}>
                                <div className="step-icon__border step-icon__border--dashed-border">
                                    <div className="step-icon__bg">
                                        <span className="arrow"></span>
                                        <span className="arrow"></span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {showNextButton ? (
                            <div
                                className={`step-icon step-icon--next`}
                                onKeyPress={(e: any) => keyPress(e, nextVisualSteps)}
                                tabIndex={0}
                                onClick={nextVisualSteps}>
                                <div className="step-icon__border step-icon__border--dashed-border">
                                    <div className="step-icon__bg">
                                        <span className="arrow"></span>
                                        <span className="arrow"></span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="doubleArrows">
                            <span className="arrow"></span>
                            <span className="arrow"></span>
                        </div>
                        {counter === 0 && steps?.length > 6 ? (
                            <>
                                {showExpandMsg ? (
                                    <div className="expand-msg">
                                        <img src={leftArrow} alt="Left" />
                                        <div className="dotted-line"></div>
                                        <Trans>Expand View</Trans>
                                        <div className="dotted-line"></div>
                                        <img src={rightArrow} alt="Right" />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {showCollapseMsg ? (
                                    <div className="expand-msg">
                                        <img src={rightArrow} alt="Right" />
                                        <div className="dotted-line"></div>
                                        <Trans>Collapse View</Trans>
                                        <div className="dotted-line"></div>
                                        <img src={leftArrow} alt="Left" />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {!showAllSteps ? (
                                    <button
                                        data-testid="expandButton"
                                        className="expand accordion"
                                        onMouseEnter={() => setShowExpandMsg(true)}
                                        onMouseLeave={() => setShowExpandMsg(false)}
                                        onClick={expandSteps}>
                                        <span>
                                            <Trans>expand</Trans>
                                        </span>
                                    </button>
                                ) : (
                                    <button
                                        data-testid="collapseButton"
                                        className="collapse accordion"
                                        onMouseEnter={() => setShowCollapseMsg(true)}
                                        onMouseLeave={() => setShowCollapseMsg(false)}
                                        onClick={collapseSteps}>
                                        <span>
                                            <Trans>collapse</Trans>
                                        </span>
                                    </button>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <Comments
                        id={showModalId}
                        onHoverComments={onHoverComments}
                        onMouseLeaveComments={onMouseLeaveComments}
                        posX={modalPosition[0]}
                        posY={modalPosition[1]}
                        step={hoveredStep}
                        showComment={showComment}
                        getIcon={getIcon}
                    />
                </div>
            ) : (
                <div className="container row-steps-container">
                    {steps &&
                        steps.map((step, index) => (
                            <div className="custom-row verticle-detailed-border" key={index}>
                                <div className="row-number ">{index + 1}</div>
                                <div className="step-icon row-steps">
                                    <div className="step-icon__border">
                                        {step ? <div className="step-icon__bg">{getIcon(step.icon)}</div> : ''}
                                    </div>
                                </div>
                                <div className="row-step-details">
                                    <div className="custom-row">
                                        <div className="step-details-date">{moment(step.createdOn).format('MMM DD yyyy')} </div>
                                        <div className={`badge--status ${ActivityStatus[step.status]}`}>{step.aging}</div>
                                    </div>
                                    <div className="steps-details-title">{step.title}</div>
                                    <div className="steps-details-last-activty">
                                        Last Activity :{' '}
                                        {moment(step.modifiedOn ? step.modifiedOn : step.createdOn).format('hh:mm a')}
                                    </div>
                                    <div className="messages message-count">
                                        <img className="comment-icon" src={commentIcon} alt="comment" />
                                        {step.commentCount}
                                    </div>
                                    {step &&
                                        step.comments &&
                                        step.comments.length > 0 &&
                                        step.comments.map(
                                            (item: any, idx: number) =>
                                                item &&
                                                !item.parentCommentId && (
                                                    <div className={``} key={idx}>
                                                        <div className={`custom-row comment-row `} key={item.id}>
                                                            <Avatar
                                                                userId={item?.createdBy?.id}
                                                                userName={item?.createdBy?.displayName}
                                                                initialColor={PersonaInitialsColor.coolGray}
                                                                imageSize={32}
                                                                roundedPic={true}
                                                            />
                                                            <div className="comment-detail">
                                                                <div className="comment-display-name">
                                                                    {' '}
                                                                    {item.createdBy.displayName}{' '}
                                                                    <span
                                                                        className="comment-text"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: generateCommentMessage(
                                                                                item.commentText,
                                                                                item.taggedUsers
                                                                            )
                                                                        }}></span>
                                                                </div>
                                                                <div className="comment-time">
                                                                    {' '}
                                                                    {moment(item.createdOn).format(
                                                                        'MMM DD YYYY [at] hh:mm a'
                                                                    )}{' '}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {item.childComments && item.childComments.length > 0 && (
                                                            <div className="child-comment-container">
                                                                {item.childComments.map((childComment: any, idx: number) => (
                                                                    <div key={idx}>
                                                                        <div className="custom-row comment-row" key={item.id}>
                                                                            <Avatar
                                                                                userId={childComment?.createdBy?.id}
                                                                                userName={childComment?.createdBy?.displayName}
                                                                                initialColor={PersonaInitialsColor.coolGray}
                                                                                imageSize={32}
                                                                                roundedPic={true}
                                                                            />
                                                                            <div className="comment-detail">
                                                                                <div className="comment-display-name">
                                                                                    {' '}
                                                                                    {childComment.createdBy.displayName}{' '}
                                                                                    <span className="comment-text">
                                                                                        {' '}
                                                                                        {childComment.commentText}{' '}
                                                                                    </span>{' '}
                                                                                </div>
                                                                                <div className="comment-time">
                                                                                    {' '}
                                                                                    {moment(childComment.createdOn).format(
                                                                                        'MMM DD YYYY [at] hh:mm a'
                                                                                    )}{' '}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                        ))}
                </div>
            )}

            <Modal
                isOpen={isUpdateWarningModalOpen}
                onDismiss={hideUpdateWarningModal}
                isBlocking={false}
                className="surface-modal notification-modal">
                <div className="modal-header">
                    <span className="p-b-20"></span>
                </div>
                <div className="modal-body text-center">
                    <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                    <h4 className="m-t-5 m-b-5">The number of events has exceeded 10, they cannot be fit into one screen.</h4>
                </div>
                <div className="modal-footer">
                    <Button buttonClass="btn btn--primary" buttonText="OK" onClick={hideUpdateWarningModal} />
                </div>
            </Modal>
        </div>
    );
}

export default VisualFlow;
