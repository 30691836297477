import { DefaultButton, PersonaInitialsColor, Spinner, SpinnerSize, Text } from '@fluentui/react';
import { getter } from '@progress/kendo-data-query';
import { getSelectedState, Grid, GridColumn as Column, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import AgingProgress from 'app/components/agingProgress/AgingProgress';
import Avatar from 'app/components/avatar/Avatar';
import { formatIntoRequestData, requestAggregateSubmit } from 'app/components/helpers';
import { filterConstants } from 'app/models/common/enumerators';
import { IColumnLookUpProps } from 'app/models/common/post';
import { IChildAssociationData, IRequestParams } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import { useAssociateRequests, usePostRequestType } from 'app/services/mutations';
import { useRequestList } from 'app/services/queries';
import useStore from 'app/store';
import { StringConstants } from 'app/utils/constants';
import { getPortalValue } from 'app/utils/utilities';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import '../adminTable/GridTable.scss';
import AssociationSearchBar from './associationSearchBar/AssociationSearchBar';
import './LinkRequestsGridTable.scss';

interface IProps {
    requestData: any;
    columnsLookUpData: IColumnLookUpProps[];
    gridContRef: any;
    updateModalState: Function;
    selectedRequest: IRequest;
    setSelectedRequest: Function;
    isAssociatingParent: boolean;
}

const LinkRequestsGridTable: React.FC<IProps> = ({
    requestData,
    columnsLookUpData,
    gridContRef,
    updateModalState,
    selectedRequest,
    isAssociatingParent
}) => {
    const DATA_ITEM_KEY = 'id';
    const SELECTED_FIELD = 'selected';
    const idGetter = getter(DATA_ITEM_KEY);
    const pageSize = 50;

    const [containerWidth, setContainerWidth] = useState(1367);
    const [data, setData] = useState<any>();
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[] }>({});
    const [, setAllRequests] = useState<any[]>();
    const [selectedRequests, setSelectedRequests] = useState<any[]>([]);
    const [filteredResult, setFilteredResult] = useState<any[]>([]);

    const history = useHistory();
    const portal = getPortalValue(history?.location?.pathname);

    const [criteria] = useState<IRequestParams>({
        from: '2001-01-01T00:00:00Z',
        to: '9999-12-31T23:59:59Z',
        pageNumber: 1,
        pageSize: pageSize,
        type: filterConstants.ALL_REQUEST,
        requestType: [],
        portal: portal,
        hideParentRequests: !isAssociatingParent
    });

    const requestType = useStore((state: any) => state.requestType);

    const { isLoading: isLoadingRequests, refetch: refetchChildList } = useRequestList(criteria, {
        enabled: criteria !== undefined,
        onSuccess: (response) => {
            const formatedRequests = formatIntoRequestData(response ? response.items : [], [], []);
            if (isAssociatingParent) {
                setData(
                    formatedRequests
                        .filter(
                            (request: any) =>
                                request.isParent && request.id !== selectedRequest.id
                        )
                        .map((request: any) => Object.assign({ selected: false }, request))
                );
                setFilteredResult(
                    formatedRequests
                        .filter(
                            (request: any) =>
                                request.isParent && request.id !== selectedRequest.id
                        )
                        .map((request: any) => Object.assign({ selected: false }, request))
                );
            } else {
                setData(
                    formatedRequests
                        .filter(
                            (request: any) =>
                                (request.parentRequestId === null || request.parentRequestId === '') && !request.isParent && request.id !== selectedRequest.id
                        )
                        .map((request: any) => Object.assign({ selected: false }, request))
                );
                setFilteredResult(
                    formatedRequests
                        .filter(
                            (request: any) =>
                            (request.parentRequestId === null || request.parentRequestId === '') && !request.isParent && request.id !== selectedRequest.id
                        )
                        .map((request: any) => Object.assign({ selected: false }, request))
                );
            }

            setAllRequests(response.items);
        }
    });

    const { mutate: postRequestTypeHelp, isLoading: isCreatingRequest } = usePostRequestType();
    const { mutate: associateRequests, isLoading: isAssociatingRequests } = useAssociateRequests();

    const customAgingCell = (props: any) => {
        return (
            <td>
                <AgingProgress startDate={props.dataItem.createdOnDate} endDate={props.dataItem.dueDateValue} />
            </td>
        );
    };

    const AssignedToCell = (props: any) => {
        return (
            <td>
                {props.dataItem?.assignee ? (
                    <div className="assigned-to-cell">
                        <Avatar
                            userId={props.dataItem?.assignee?.id}
                            userName={props.dataItem?.assignee?.displayName}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">{props.dataItem?.assignee?.displayName}</span>
                    </div>
                ) : (
                    <div className="assigned-to-cell">
                        {' '}
                        <Avatar
                            userId={StringConstants.ZERO}
                            userName={StringConstants.UNASSIGNED}
                            initialColor={PersonaInitialsColor.coolGray}
                            imageSize={25}
                            roundedPic={true}
                        />
                        <span className="assigned-to-display-name">Unassigned</span>
                    </div>
                )}
            </td>
        );
    };

    const TitleCell = (props: any) => {
        return <td title={props.dataItem.title}>{props.dataItem.title}</td>;
    };

    const setWidth = () => {
        let columnCount = columnsLookUpData.filter((item) => item.isChecked).length;
        if (requestType === StringConstants.MY_REQUEST && columnsLookUpData.filter((item) => item.key === 0 && item.isChecked).length > 0) {
            columnCount = columnCount - 1;
        }
        if (columnCount > 1) {
            let padding = 50 + 47 + 30;
            const availWidth = containerWidth - padding - 90;

            return parseInt((availWidth / columnCount).toString());
        } else {
            return containerWidth;
        }
    };

    const arr = [
        {
            key: 0,
            column: (
                <Column
                    field={SELECTED_FIELD}
                    width={setWidth() - 60}
                    headerSelectionValue={filteredResult?.findIndex((item: any) => !selectedState[idGetter(item)]) === -1}
                />
            )
        },
        {
            key: 1,
            column: <Column width={setWidth()} className="cell-in-bold" field="id" title="ID" resizable={false} />
        },
        {
            key: 2,
            column: <Column width={setWidth()} field="Title" title="TITLE" cell={TitleCell} resizable={false} />
        },
        {
            key: 3,
            column: <Column width={setWidth()} title="REQUEST TYPE" field="requestType" resizable={false} />
        },
        {
            key: 4,
            column: <Column width={setWidth()} field="status" title="STATUS" resizable={false} />
        },
        {
            key: 5,
            column: (
                <Column width={setWidth() + 30} field="assignedTo" title="ASSIGNED TO" cell={AssignedToCell} resizable={false} />
            )
        },
        {
            key: 6,
            column: <Column width={setWidth()} field="createdOn" title="CREATED ON" resizable={false} />
        },
        {
            key: 7,
            column: <Column width={setWidth() - 30} field="aging" title="AGING" cell={customAgingCell} resizable={false} />
        },
        {
            key: 8,
            column: (
                <Column
                    width={setWidth() + 50}
                    field="revenue"
                    title="TOTAL REVENUE RISK"
                    resizable={false}
                    className="right-aligned-column"
                />
            )
        }
    ];

    const getColumns = () => {
        let list: any = [];
        columnsLookUpData.forEach((item) => {
            arr.every((ele) => {
                if (ele.key === item.key && item.isChecked) {
                    list.push(ele);
                    return false;
                } else {
                    return true;
                }
            });
        });

        if (requestType === StringConstants.MY_REQUEST) {
            return list.filter((item: any) => item.key !== 0);
        } else {
            return list;
        }
    };
    const [columnList, setColumnList] = useState(getColumns());

    useEffect(() => {
        if (gridContRef.current?.offsetWidth) {
            setContainerWidth(gridContRef.current.offsetWidth);
        }
    }, [gridContRef.current]);

    useEffect(() => {
        setColumnList(getColumns());
        return () => {};
    }, [columnsLookUpData, requestType]);

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY
        });

        var newSelectedRequests = filteredResult.filter((request: any) => Object.keys(newSelectedState).includes(request.id));
        var requestsToAdd = selectedRequests;

        newSelectedRequests.forEach((request: any) => {
            var exisitingIds = requestsToAdd.map((item: any) => item.id);
            !exisitingIds.includes(request.id) && requestsToAdd.push(request);
        });

        setSelectedRequests(requestsToAdd);
        setSelectedState(newSelectedState);
    };

    const onAssociate = () => {
        var selectedParentRequest = filteredResult.filter((request: any) => request.id === Object.keys(selectedState)[0])[0];

        if (!selectedParentRequest.isParent) {
            let aggregateCustomerImpact = [] as any[];

            selectedRequest?.customerImpacts?.forEach((impact: any) => {
                aggregateCustomerImpact.push(impact);
            });

            selectedParentRequest?.customerImpacts?.forEach((impact: any) => {
                aggregateCustomerImpact.push(impact);
            });

            let datapass = {
                summary: selectedParentRequest.summary,
                description: selectedParentRequest.description,
                requestedResolutionDate: selectedParentRequest.requestedResolutionDate,
                whyThisDateIsImportant: selectedParentRequest.whyThisDateIsImportant,
                status: selectedParentRequest.statusValue,
                attachments: selectedParentRequest.attachments,
                customerImpacts: aggregateCustomerImpact,
                isParent: true            };

            const requestAggregateData = requestAggregateSubmit(
                selectedParentRequest.requestTypeValue,
                selectedParentRequest,
                datapass
            );

            postRequestTypeHelp(
                { subUrl: requestAggregateData.subUrl, data: requestAggregateData.datapass },
                {
                    onSuccess: (response) => {
                        finishParentAssociation(response.data.unifiedId, selectedParentRequest);
                    }
                }
            );
        } else {
            const associationObject = {
                requestIds: [selectedRequest.id],
                associatedRequestId: selectedParentRequest.id
            };

            associateRequests(associationObject, {
                onSuccess: () => {
                    updateModalState(false, true);
                }
            });
        }
    };

    const finishParentAssociation = async (parentId: string, selectedParentRequest: any) => {
        const associateRequestsObject: IChildAssociationData = {
            requestIds: [selectedRequest.unifiedTrackingId, selectedParentRequest?.unifiedTrackingId],
            associatedRequestId: parentId
        };

        associateRequests(associateRequestsObject, {
            onSuccess: () => {
                updateModalState(false, true);
            }
        });
    };

    const onAssociateChildren = () => {
        var selectedRequestKeys = Object.keys(selectedState).filter((key) => selectedState[key] === true);
        var associateRequestsObject: IChildAssociationData = {
            requestIds: selectedRequestKeys,
            associatedRequestId: selectedRequest.isParent ? selectedRequest.unifiedTrackingId : selectedRequest.parentRequestId
        };

        associateRequests(associateRequestsObject, {
            onSuccess: () => {
                updateModalState(false, true);
            }
        });
    };

    if (requestData) {
        return (
            <div className="table-content">
                {isCreatingRequest || isAssociatingRequests ? (
                    <div>
                        <div className="m-t-50">
                            <Spinner label="Associating Request. Please wait..." size={SpinnerSize.large} />
                        </div>
                    </div>
                ) : (
                    <div className="grid-cont" role="main" aria-label="grid table">
                        <div className="lr-header">
                            <div className="head-col-left">
                                <Text className="dp-head-title">
                                    {isAssociatingParent
                                        ? 'Choose from exisiting requests'
                                        : `${
                                              Object.values(selectedState).filter((value) => value === true).length
                                          } Requests selected`}
                                </Text>
                            </div>
                            <div className="head-col-right">
                                <AssociationSearchBar
                                    isAssociatingParent={isAssociatingParent}
                                    setFilteredResult={setFilteredResult}
                                    refetchChildList={refetchChildList}
                                />
                            </div>
                        </div>
                        <Tooltip openDelay={100} position="right" anchorElement="element">
                            {isLoadingRequests ? (
                                <div>
                                    <div className="m-t-50">
                                        <Spinner label="Loading requests..." size={SpinnerSize.large} />
                                    </div>
                                </div>
                            ) : (
                                <Grid
                                    data={selectedRequests
                                        ?.map((request: any) => ({
                                            ...request,
                                            [SELECTED_FIELD]: selectedState[idGetter(request)]
                                        }))
                                        .filter((request: any) => request[SELECTED_FIELD] === true)
                                        .concat(
                                            filteredResult
                                                ?.map((request: any) => ({
                                                    ...request,
                                                    [SELECTED_FIELD]: selectedState[idGetter(request)]
                                                }))
                                                .filter(
                                                    (request) =>
                                                        !Object.keys(selectedState).includes(request.id) ||
                                                        request[SELECTED_FIELD] === false
                                                )
                                        )}
                                    dataItemKey={DATA_ITEM_KEY}
                                    sortable={true}
                                    onSelectionChange={onSelectionChange}
                                    resizable={false}
                                    selectedField={SELECTED_FIELD}
                                    selectable={{ enabled: true, cell: false, mode: isAssociatingParent ? 'single' : 'multiple' }}
                                    navigatable={true}>
                                    {columnList?.map((item: any) => item.column)}
                                </Grid>
                            )}
                        </Tooltip>
                        {isAssociatingParent ? (
                            selectedState[Object.keys(selectedState)[0]] ? (
                                <div className="parent-table-btn-wrap">
                                    <DefaultButton className="default" onClick={() => updateModalState(false)}>
                                        <Trans>Cancel</Trans>
                                    </DefaultButton>
                                    <DefaultButton type="submit" className="primary" onClick={onAssociate}>
                                        <Trans>Associate as parent</Trans>
                                    </DefaultButton>
                                </div>
                            ) : (
                                <></>
                            )
                        ) : (
                            <div className="parent-table-btn-wrap">
                                <DefaultButton className="default" onClick={() => updateModalState(false)}>
                                    <Trans>Cancel</Trans>
                                </DefaultButton>
                                <DefaultButton
                                    type="submit"
                                    className="primary"
                                    onClick={onAssociateChildren}
                                    disabled={Object.values(selectedState).filter((value) => value === true).length <= 0}>
                                    <Trans>Associate as child</Trans>
                                </DefaultButton>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    } else {
        <div>Loading...</div>;
    }
};

export default LinkRequestsGridTable;
