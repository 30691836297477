import React, { useEffect, useRef, useState } from 'react';
import { SearchBox, Text, Icon, Checkbox } from '@fluentui/react';
import './ProductDevicePicker.scss';
import './CountryPicker.scss';
import { useQueryAllCountryList } from 'app/services/queries';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';
import { keyPress } from 'app/components/helpers';
import { StringConstants } from 'app/utils/constants';
import { ICountryProps } from 'app/models/common/response';

interface IProps {
    selectedKeys: any[];
    setSelectedKeys: Function;
    selectedOptions: any[];
    setSelectedOptions: Function;
    multiSelectData?: any[];
    inEditMode?: boolean;
}

const CountryPicker: React.FC<IProps> = ({ selectedOptions, setSelectedOptions, multiSelectData, inEditMode }) => {
    const { data, isLoading } = useQueryAllCountryList();
    const [options, setOptions] = useState<IOptionProps[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IOptionProps[]>([]);
    const dropDownRef = useRef(null);
    const [selectedKeys, setSelectedKeys] = useState<IOptionProps[]>([]);

    // For countries, the back-end query checks against the country name
    // We must ensure that selectedOptions is set to an array of selected country names
    const onChange = (key: number, element: any) => {
        if (key === 0) {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: IOptionProps[] = [];
                options.forEach((ele) => arr.push(ele));
                setSelectedKeys(arr);
                setSelectedOptions([
                    ...arr.filter((option) => option.text !== StringConstants.UPPERCASE_ALL).map((item) => item.text)
                ]);
            } else {
                setSelectedKeys([]);
                setSelectedOptions([]);
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.key === key)[0]];

                setSelectedKeys(tempSelectedKeys);

                // Hong Kong SAR
                if (element.text === 'Hong Kong') {
                    tempSelectedKeys.push({ key: 74, text: 'Hong Kong SAR' });
                }

                // Account for different variations of a country name
                if (element.text === 'United States') {
                    tempSelectedKeys.push({ key: 187, text: 'United States of America' })
                }

                setSelectedOptions([...tempSelectedKeys.map((item) => item.text)]);
                setSearchedResults(options);
            } else {
                const tempSelectedKeys = selectedKeys.filter(
                    (item) => item.key !== key && item.text !== StringConstants.UPPERCASE_ALL
                );
                setSelectedKeys(tempSelectedKeys);
                setSelectedOptions([...tempSelectedKeys.map((item) => item.text)]);
            }
        }
    };
    const isChecked = (ele: number) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };
    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };
    const removeDuplicates = (arr: string[]) => {
        let uniqueArr = arr.filter((ele, index) => {
            return arr.indexOf(ele) === index;
        });
        return uniqueArr;
    };
    const getLabel = () => {
        if (selectedKeys?.filter((ele) => ele.key === 0).length !== 0 || selectedKeys?.length === 0) {
            return 'Country';
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                let element = options.filter((ele) => ele.key === item.key)[0];
                if (element?.parentProperty) {
                    if (selectedKeys.filter((ele) => ele.key === element?.parentProperty).length !== 0)
                        arr.push(options.filter((it) => it.key === element?.parentProperty)[0].text);
                    else arr.push(element?.text);
                } else {
                    arr.push(element?.text);
                }
            });
            let res = removeDuplicates(arr);
            return res.join(', ');
        }
    };

    const formatIntoOptions = (data: ICountryProps[]) => {
        let arr: IOptionProps[] = [{ key: 0, text: StringConstants.UPPERCASE_ALL }];
        let index = 1;
        data?.forEach((item) => {
            let childArray: number[] = [];
            let parentIndex = index;
            childArray.push(index);
            index = index + 1;
            if (item.name === 'United States of America') {
                arr.push({ key: parentIndex, text: 'United States' });
            } else if (item.name !== 'United States') {
                arr.push({ key: parentIndex, text: item.name });
            }
        });
        return arr;
    };
    const checkEquality = (arr1: IOptionProps[], arr2: IOptionProps[]) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.key === arr1[i].key).length === 0) {
                return false;
            }
        }
        return true;
    };
    useDropDownDismiss(dropDownRef, onToggleShow);

    // for autopopulation in edit mode
    useEffect(() => {
        if (multiSelectData.length > 0 && inEditMode && data?.length > 0) {
            const options = formatIntoOptions(data);
            const keys = options.filter((item: any) => multiSelectData.includes(item.text));
            setSelectedKeys(keys);
            setSelectedOptions(keys.map((key) => key.text));
            setOptions(options);
        }
    }, [inEditMode, multiSelectData, data]);

    useEffect(() => {
        if (!isLoading) {
            setOptions(formatIntoOptions(data));
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IOptionProps[] = [];
            options.forEach((item) => {
                if (item.text.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    useEffect(() => {
        if (!showDropdown) {
            setSearchValue('');
        }
    }, [showDropdown]);

    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys?.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.key !== 0)
            )
        ) {
            setSelectedKeys(options.slice(0));
        }
    }, [options, selectedKeys]);

    return (
        <div className="country-picker-cont">
            <div
                className="country-title row-flex-box align-center"
                tabIndex={0}
                data-testid="pdt-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <Text className="country-label">{getLabel()}</Text> <Icon iconName="ChevronDown" />
            </div>

            {showDropdown && !isLoading && (
                <div ref={dropDownRef} className="country-dropdown-cont position-cont">
                    <div
                        tabIndex={0}
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        data-testid="pdt-filter-close-btn-test"
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center justify-sb dropdown-header-lb">
                        <Text>Country</Text>
                        <Icon iconName="ChevronUp" />
                    </div>
                    <div className="country-search-bar-cont">
                        {selectedKeys.filter((ele) => ele.key === 0).length !== 0 ? (
                            <div className="country-cont-lb">
                                <div className="country-item-lb">
                                    <Text>Countries</Text>{' '}
                                    <Icon
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                onChange(0, selectedKeys);
                                            }
                                        }}
                                        data-testid="tag-all-test"
                                        onClick={() => onChange(0, selectedKeys)}
                                        iconName="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="country-cont-lb">
                                {selectedKeys
                                    .filter((selection) => {
                                        return selection.text !== 'Hong Kong SAR' && selection.text !== 'United States of America';
                                    })
                                    .map((ele) => (
                                        <div className="country-item-lb">
                                            <Text>
                                                {ele.text}
                                                {ele.dependentProperties ? ' - All' : ''}
                                            </Text>
                                            <Icon
                                                tabIndex={0}
                                                onKeyPress={(e: any) => {
                                                    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                        onChange(ele.key as number, ele);
                                                    }
                                                }}
                                                onClick={() => onChange(ele.key as number, ele)}
                                                iconName="Cancel"
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                        <SearchBox
                            placeholder="Search"
                            tabIndex={0}
                            data-testid="search-field-test"
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(item.key as number, item);
                                        }
                                    }}
                                    className={`country-dropdown-item-lb ${
                                        item.parentProperty &&
                                        searchedResults.filter((ele) => ele.key === item.parentProperty).length !== 0
                                            ? 'child-item'
                                            : ''
                                    }  ${item.dependentProperties ? 'parent-item' : ''}`}>
                                    <Checkbox
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() => onChange(item.key as number, item)}
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(item.key as number, item);
                                        }
                                    }}
                                    className="country-dropdown-item-lb parent-item">
                                    <Checkbox
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() => onChange(item.key as number, item)}
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default CountryPicker;
