import { escapeToSafeCharacters } from 'app/components/helpers';
import {
    IAttachmentRequest,
    ICommentDto,
    IDevicesDto,
    IDownloadVisualFlow,
    IRemoveCommentData,
    IRemoveCustomerImpactData,
    IStatusesDto,
    IStatusUpdateObject,
    IUpdateAttachmentClassification,
    IUpdateRequest
} from 'app/models/common/request';
import { ITagsRequestAddProps, ITagsRequestProps } from 'app/models/common/response';
import { appInsightsService, StringConstants } from 'app/utils/constants';
import { useMutation } from 'react-query';
import {
    IAddEmote,
    IArticleData,
    IArticleTemplateData,
    IAuthorDetails,
    IComments,
    IEmail,
    IEmailTemplate,
    IFeedback,
    IListFolder,
    IListQuery,
    IPostHashTags,
    IReassign,
    IReport,
    IRequestHelp,
    IResources,
    IRouteProps,
    ISearchResult,
    ISearchSuggest,
    ISharedWith,
    IUnsubscribeFeedback,
    IUpdateFolder,
    IUserList
} from '../models/common/post';
import { ApiRequestService } from './api-service';
import { AppQueryClient } from './clients/query-client';

interface IHelpService {
    subUrl: any;
    data: IRequestHelp;
}
interface IResponse {
    id: string;
    response: string;
}

export const useGettingRequestType = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((data: IRouteProps) => apiRequestService.generateRequestType(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useGettingRequestType was Succesful');
        }
    });
    return requestTypeMutation;
};

export const usePostRequestType = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation(
        (params: IHelpService) => apiRequestService.postRequestType(params.subUrl, params.data),
        {
            onSuccess: () => {
                setTimeout(() => {
                    AppQueryClient.invalidateQueries('useNotificationsCount');
                }, 2000);
                appInsightsService.logEvent('usePostRequestType was Succesful');
            }
        }
    );
    return requestTypeMutation;
};

//feedback submission
export const usePostFeedback = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((data: IFeedback) => apiRequestService.postFeedback(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('usePostFeedback was Succesful');
        }
    });
    return requestTypeMutation;
};

export const usePostFeedbackResponse = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation(
        (params: IResponse) => apiRequestService.postFeedbackResponse(params.id, params.response),
        {
            onSuccess: () => {
                setTimeout(() => {
                    AppQueryClient.invalidateQueries('useNotificationsCount');
                }, 2000);
                appInsightsService.logEvent('usePostFeedbackResponse was Succesful');
            }
        }
    );
    return requestTypeMutation;
};

//request Reassign
export const useRequestReassign = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((data: IReassign) => apiRequestService.requestReassign(data), {
        onSuccess: () => {
            AppQueryClient.invalidateQueries('useNotificationsCount');
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('listBuilder');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('useNotificationsCount');
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('listBuilder');
        }
    });
    return requestTypeMutation;
};

//post comments
export const usePostComments = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((data: IComments) => apiRequestService.postComments(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('usePostComments was Succesful');
        }
    });
    return requestTypeMutation;
};

//create article admin
export const useCreateArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IArticleData) => apiRequestService.createArticle(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useCreateArticle was Succesful');
        }
    });
    return response;
};

export const useUpdateArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IArticleData) => apiRequestService.updateArticle(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useUpdateArticle was Succesful');
        }
    });
    return response;
};
export const useUpdateAuthorDetails = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IAuthorDetails) => apiRequestService.updateAuthorDetails(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useUpdateAuthorDetails was Succesful');
        }
    });
    return response;
};
export const useSubmitArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation((articleId: string) => apiRequestService.submitArticle(articleId), {
        onSuccess: () => {
            appInsightsService.logEvent('useSubmitArticle was Succesful');
        }
    });
    return response;
};

export const useNotificationsRead = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation(() => apiRequestService.markNotificationsRead1(), {
        onSuccess: () => {
            appInsightsService.logEvent('useNotificationsRead was Succesful');
        }
    });
    return response;
};

export const usePublishArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation(
        ({
            articleId,
            showOnHomePage,
            showAsBannerArticle
        }: {
            articleId: string;
            showOnHomePage: boolean;
            showAsBannerArticle: boolean;
        }) => apiRequestService.publishArticle(articleId, showOnHomePage, showAsBannerArticle),
        {
            onSuccess: () => {
                setTimeout(() => {
                    AppQueryClient.invalidateQueries('useNotificationsCount');
                }, 2000);
                appInsightsService.logEvent('usePublishArticle was Succesful');
            }
        }
    );
    return response;
};

export const useScheduleToPublishArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation(
        ({
            articleId,
            showOnHomePage,
            showAsBannerArticle,
            scheduledPublishOn
        }: {
            articleId: string;
            showOnHomePage: boolean;
            showAsBannerArticle: boolean;
            scheduledPublishOn: string;
        }) => apiRequestService.scheduleToPublishArticle(articleId, showOnHomePage, showAsBannerArticle, scheduledPublishOn),
        {
            onSuccess: () => {
                setTimeout(() => {
                    AppQueryClient.invalidateQueries('useNotificationsCount');
                }, 2000);
                appInsightsService.logEvent('useScheduleToPublishArticle was Succesful');
            }
        }
    );
    return response;
};

export const useReDraftArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation(
        ({ articleId, reviewNotes }: { articleId: string; reviewNotes: string }) =>
            apiRequestService.reDraftArticle(articleId, reviewNotes),
        {
            onSuccess: () => {
                // Query Invalidations
                // AppQueryClient.invalidateQueries('gettingRequestType');
                appInsightsService.logEvent('useReDraftArticle was Succesful');
            }
        }
    );
    return response;
};

export const useRemoveArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((articleId: string) => apiRequestService.removeArticle(articleId), {
        onSuccess: () => {
            // Query Invalidations
            // AppQueryClient.invalidateQueries('gettingRequestType');
            appInsightsService.logEvent('useRemoveArticle was Succesful');
        }
    });
    return response;
};

//Delete Article
export const useDeleteArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation((articleId: string) => apiRequestService.deleteArticle(articleId), {
        onSuccess: () => {
            // Query Invalidations
            // AppQueryClient.invalidateQueries('gettingRequestType');
            appInsightsService.logEvent('useDeleteArticle was Succesful');
        }
    });
    return response;
};

//create article admin
export const usePostArticleViewed = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((articleId: string) => apiRequestService.postArticleViewed(articleId), {
        onSuccess: () => {
            // Query Invalidations
            // AppQueryClient.invalidateQueries('gettingRequestType');
            appInsightsService.logEvent('useDeleteArticle was Succesful');
        }
    });
    return response;
};

export const useSearchData = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: ISearchSuggest) => apiRequestService.searchData(data), {
        onSuccess: () => {
            appInsightsService.logEvent('useSearchData was Succesful');
        }
    });
    return response;
};

export const useAutoSearch = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation(
        (data: string) => apiRequestService.autoSearch(encodeURIComponent(escapeToSafeCharacters(data))),
        {
            onSuccess: () => {
                appInsightsService.logEvent('useAutoSearch was Succesful');
            }
        }
    );
    return response;
};

export const useSearchResult = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((data: ISearchResult) => apiRequestService.searchResult(data), {
        onSuccess: () => {
            appInsightsService.logEvent('useSearchResult was Succesful');
        }
    });
    return requestTypeMutation;
};

export const useSaveRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((data: string) => apiRequestService.updateNewRequest(data), {
        onSuccess: () => {
            appInsightsService.logEvent('useSaveRequest was Succesful');
        }
    });
    return requestTypeMutation;
};

export const useAddBookmark = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((requestId: string) => apiRequestService.addBookmark(requestId), {
        onSuccess: () => {
            // Query Invalidations
            // AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('useSummaryCardsCountForUser');
            appInsightsService.logEvent('useAddBookmark was Succesful');
        }
    });
    return requestTypeMutation;
};
export const useRemoveBookmark = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((requestId: string) => apiRequestService.removeBookmark(requestId), {
        onSuccess: () => {
            AppQueryClient.invalidateQueries('useSummaryCardsCountForUser');
            appInsightsService.logEvent('useRemoveBookmark was Succesful');
        }
    });
    return requestTypeMutation;
};

//Article Template
export const useCreateArticleTemplate = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IArticleTemplateData) => apiRequestService.createArticleTemplate(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
                appInsightsService.logEvent('useCreateArticleTemplate was Succesful');
            }, 2000);
        }
    });
    return response;
};

// Delete article template
export const useDeleteArticleTemplate = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((params: string) => apiRequestService.deleteArticleTemplateById(params), {
        onSuccess: () => {
            appInsightsService.logEvent('useDeleteArticleTemplate was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to submit shared request from unified dashboard
 */
export const useShareRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: Object) => apiRequestService.shareRequest(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
                appInsightsService.logEvent('useShareRequest was Succesful');
            }, 2000);
        }
    });
    return mutation;
};

export const useDraftArticle = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation((articleId: string) => apiRequestService.draftArticle(articleId), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useDraftArticle was Succesful');
        }
    });
    return response;
};

/**
 * Module: Request Experience Search Customer
 * Custom hook to get list of customers*/
export const useGetCustomersList = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation((searchValue: string) => apiRequestService.getMSXSearchResults(searchValue), {
        onSuccess: () => {
            appInsightsService.logEvent('useGetCustomersList was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to submit comment
 */
export const useSubmitComment = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: ICommentDto) => apiRequestService.submitComment(data), {
        onMutate: async () => {
            AppQueryClient.invalidateQueries('useFetchComments');
        },
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchComments');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('listBuilder');
            appInsightsService.logEvent('useSubmitComment was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('useFetchComments');
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('listBuilder');
            appInsightsService.logEvent('useSubmitComment Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to edit comment
 */
export const useEditComment = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: ICommentDto) => apiRequestService.updateComment(data), {
        onMutate: async () => {
            AppQueryClient.invalidateQueries('useFetchComments');
        },
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchComments');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            appInsightsService.logEvent('useEditComment was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('useFetchComments');
            appInsightsService.logEvent('useEditComment Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to update details of a request in admin dashboard
 */
export const useUpdateRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IUpdateRequest) => apiRequestService.updateRequest(data), {
        onMutate: async () => {
            await AppQueryClient.cancelQueries('requestQuery');
            await AppQueryClient.cancelQueries('listBuilder');
        },
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useUpdateRequest was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('requestQuery');
            appInsightsService.logEvent('useUpdateRequest Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to update the Devices of a request and its Parent if it is a child
 */
export const useUpdateRequestDevices = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IDevicesDto) => apiRequestService.updateRequestDevices(data), {
        onMutate: async () => {
            await AppQueryClient.cancelQueries('requestQuery');
        },
        onSuccess: () => {
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useUpdateRequestDevices was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            appInsightsService.logEvent('useUpdateRequestDevices Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to update the status of a request
 */
export const useUpdateStatus = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IStatusUpdateObject) => apiRequestService.updateStatus(data), {
        onMutate: async () => {
            await AppQueryClient.cancelQueries('requestQuery');
        },
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useUpdateStatus was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('requestQuery');
            appInsightsService.logEvent('useUpdateStatus Failed');
        }
    });
    return mutation;
};

export const useUnassociateRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: any) => apiRequestService.unassociatedRequest(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationCount');
            }, 2000);
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('requestQuery');
            appInsightsService.logEvent('useUnassociateRequest was Succesful');
        }
    });

    return mutation;
};

export const useAssociateRequests = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: any) => apiRequestService.associateRequests(data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationCount');
            }, 2000);
            appInsightsService.logEvent('useAssociateRequests was Succesful');
        }
    });

    return mutation;
};

export const useNewsSubscription = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation(() => apiRequestService.updateNewsSubscription(), {
        onSuccess: () => {
            appInsightsService.logEvent('useNewsSubscription was Succesful');
        }
    });
    return mutation;
};
export const useKnowledgeArticleSubscription = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation(() => apiRequestService.updateKnowledgeArticleSubscription(), {
        onSuccess: () => {
            appInsightsService.logEvent('useKnowledgeArticleSubscription was Succesful');
        }
    });
    return mutation;
};
export const useNewsUnSubscription = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation(() => apiRequestService.updateNewsUnSubscription(), {
        onSuccess: () => {
            appInsightsService.logEvent('useNewsUnSubscription was Succesful');
        }
    });
    return mutation;
};
export const useKnowledgeArticleUnSubscription = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation(() => apiRequestService.updateKnowledgeArticleUnSubscription(), {
        onSuccess: () => {
            appInsightsService.logEvent('useKnowledgeArticleUnSubscription was Succesful');
        }
    });
    return mutation;
};
/**
 * Module: Admin Assign Request
 * Custom hook to assign a request */
export const useAssignRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const response = useMutation(
        ({ team, requestType, data }: { team: number; requestType: number; data: any }) =>
            apiRequestService.assignRequest(team, requestType, data),
        {
            onSuccess: () => {
                setTimeout(() => {
                    AppQueryClient.invalidateQueries('useNotificationsCount');
                }, 2000);
                appInsightsService.logEvent('useAssignRequest was Succesful');
            }
        }
    );
    return response;
};

export const usePostAddCustomer = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((params: any) => apiRequestService.postAddCustomer(params.subUrl, params.data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('listBuilder');
            appInsightsService.logEvent('usePostAddCustomer was Succesful');
        }
    });
    return requestTypeMutation;
};

export const usePostAddCustomerReviewRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((params: any) => apiRequestService.postAddCustomerReviewRequest(params.data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('listBuilder');
            appInsightsService.logEvent('usePostAddCustomerReviewRequest was Succesful');
        }
    });
    return requestTypeMutation;
};

export const usePostAddCustomerTechnicalRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const requestTypeMutation = useMutation((params: any) => apiRequestService.postAddCxpTechnicalRequest(params.data), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('requestQuery');
            appInsightsService.logEvent('usePostAddCustomerReviewRequest was Succesful');
        }
    });
    return requestTypeMutation;
};

export const useAddAttachment = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((requestData: IAttachmentRequest) => apiRequestService.addAttachment(requestData), {
        onSuccess: () => {
            setTimeout(() => {
                AppQueryClient.invalidateQueries('useNotificationsCount');
            }, 2000);
            appInsightsService.logEvent('useAddAttachment was Succesful');
        }
    });
    return mutation;
};

export const useRemoveAttachment = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((requestData: IAttachmentRequest) => apiRequestService.removeAttachment(requestData), {
        onSuccess: () => {
            appInsightsService.logEvent('useRemoveAttachment was Succesful');
        }
    });
    return mutation;
};

export const useUpdateAttachmentClassification = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation(
        (requestData: IUpdateAttachmentClassification) => apiRequestService.updateAttachmentClassification(requestData),
        {
            onSuccess: () => {
                appInsightsService.logEvent('useUpdateAttachmentClassification was Succesful');
            }
        }
    );
    return mutation;
};

export const useRemoveCustomerImpact = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((requestData: IRemoveCustomerImpactData) => apiRequestService.removeCustomerImpact(requestData));
    return mutation;
};

/**
 * Mutation to download visual flow
 */
export const useDownloadVisualFlow = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IDownloadVisualFlow) => apiRequestService.getDownloadVisualFlow(data), {
        onSuccess: () => {
            appInsightsService.logEvent('useDownloadVisualFlow was Succesful');
        }
    });
    return mutation;
};

/**
 * Mutation to download visual flow
 */
export const useDownloadSearchResults = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: ISearchResult) => apiRequestService.getDownloadSearchResult(data), {
        onSuccess: () => {
            appInsightsService.logEvent('useDownloadSearchResults was Succesful');
        }
    });
    return mutation;
};

/**
 * Mutation used to remove comment
 */
export const useRemoveComment = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IRemoveCommentData) => apiRequestService.removeComment(data), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchComments');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            appInsightsService.logEvent('useRemoveComment was Succesful');
        }
    });
    return mutation;
};

/**
 * Mutation used to store user preference
 * */
export const useStorePreference = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: any) => apiRequestService.storeUserPreference(data), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useStorePreference was Succesful');
        }
    });
    return mutation;
};

/**
 * Mutation used to delete report
 * */
export const useDeleteReport = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((reportId: string) => apiRequestService.deleteReport(reportId), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useDeleteReport was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to share report
 * */
export const useShareReport = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((sharedWith: ISharedWith) => apiRequestService.shareReport(sharedWith), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useShareReport was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to bookmark report
 * */
export const useBookmarkReport = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((reportId: string) => apiRequestService.bookmarkReport(reportId), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useBookmarkReport was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to update report
 * */
export const useUpdateReport = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((report: IReport) => apiRequestService.updateReport(report), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useUpdateReport was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to add report
 * */
export const useAddReport = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((report: IReport) => apiRequestService.addReport(report), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useAddReport was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to hide a request
 * */
export const useHideRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((id: string) => apiRequestService.hideRequest(id), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useHideRequest was Succesful');
        }
    });
    return response;
};
/**
 * Mutation used to unhide a request
 * */
export const useUnhideRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((id: string) => apiRequestService.unhideRequest(id), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useUnhideRequest was Succesful');
        }
    });
    return response;
};

/**
 * Mutation used to submit status
 */
export const useSubmitStatus = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: ICommentDto) => apiRequestService.submitStatus(data), {
        onMutate: async () => {
            AppQueryClient.invalidateQueries('useFetchStatus');
        },
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchStatus');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            appInsightsService.logEvent('useSubmitStatus was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('useFetchStatus');
            appInsightsService.logEvent('useSubmitStatus Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to submit statuses for multiple requests
 * Primarily used for parent/child relationships
 */
export const useSubmitStatuses = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((statuses: IStatusesDto) => apiRequestService.submitStatuses(statuses), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchStatus');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            appInsightsService.logEvent('useSubmitStatuses was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('useFetchStatus');
            appInsightsService.logEvent('useSubmitStatuses Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to edit status
 */

export const useEditStatusResponse = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: ICommentDto) => apiRequestService.updateStatusResponse(data), {
        onMutate: async () => {
            AppQueryClient.invalidateQueries('useFetchStatus');
        },
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchStatus');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            appInsightsService.logEvent('useEditStatusResponse was Succesful');
        },
        onError: () => {
            AppQueryClient.invalidateQueries('useFetchStatus');
            appInsightsService.logEvent('useEditStatusResponse Failed');
        }
    });
    return mutation;
};

/**
 * Mutation used to remove status
 */
export const useRemoveStatus = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IRemoveCommentData) => apiRequestService.removeStatus(data), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('useFetchStatus');
            AppQueryClient.invalidateQueries('useNotificationsCount');
            appInsightsService.logEvent('useRemoveStatus was Succesful');
        }
    });
    return mutation;
};

// add tag
export const useAddTag = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const mutation = useMutation((tag: string) => apiRequestService.addNewTags(tag), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('getTagsList');
            appInsightsService.logEvent('useAddTag was Succesful');
        }
    });
    return mutation;
};

// delete tag
export const useDeleteTag = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const mutation = useMutation((tag: string) => apiRequestService.deleteTag(tag), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('getTagsList');
            appInsightsService.logEvent('useDeleteTag was Succesful');
        }
    });
    return mutation;
};

// add tag from manage request
export const useAddTagRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const mutation = useMutation((data: ITagsRequestAddProps) => apiRequestService.addTagRequest(data), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('listBuilder');
            appInsightsService.logEvent('useAddTagRequest was Succesful');
        }
    });
    return mutation;
};

// delete tag from manage request
export const useDeleteTagRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const mutation = useMutation((data: ITagsRequestProps) => apiRequestService.deleteTagRequest(data), {
        onSuccess: () => {
            // Query Invalidations
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries('listBuilder');
            appInsightsService.logEvent('useDeleteTagRequest was Succesful');
        }
    });
    return mutation;
};

export const usePostCommentsHashTags = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const commentsTagsMutation = useMutation((params: IPostHashTags) => apiRequestService.postCommentsHashTags(params), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('usePostCommentsHashTags was Succesful');
        }
    });
    return commentsTagsMutation;
};

// Create email
export const useSendEmail = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const commentsTagsMutation = useMutation((params: IEmail) => apiRequestService.postEmail(params), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useSendEmail was Succesful');
        }
    });
    return commentsTagsMutation;
};
// Create email template
export const useCreateEmailTemplate = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const commentsTagsMutation = useMutation((params: IEmailTemplate) => apiRequestService.createEmailTemplate(params), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useCreateEmailTemplate was Succesful');
        }
    });
    return commentsTagsMutation;
};
// Delete email template
export const useDeleteEmailTemplate = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((params: string) => apiRequestService.deleteEmailTemplateById(params), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useDeleteEmailTemplate was Succesful');
        }
    });
    return response;
};

// UnsubscribeFeedback
export const useUnsubscribeFeedback = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((params: IUnsubscribeFeedback) => apiRequestService.UnsubscribeFeedback(params), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useUnsubscribeFeedback was Succesful');
        }
    });
    return response;
};

//add Helpful resources
export const useAddHelpfulResource = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IResources) => apiRequestService.addHelpfulResource(data), {
        onSuccess: () => {
            // Query Invalidations
            appInsightsService.logEvent('useAddHelpfulResource was Succesful');
        }
    });
    return response;
};

//Add comment emotes
export const useAddCommentEmote = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IAddEmote) => apiRequestService.addCommentEmote(data), {
        onSuccess: () => {
            AppQueryClient.invalidateQueries('useFetchComments');
            appInsightsService.logEvent('useAddCommentEmote was Succesful');
        }
    });

    return response;
};

/**
 * Mutation to save a new list builder list
 */
export const useSaveList = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IUserList) => apiRequestService.saveList(data), {
        onSuccess: () => {
            AppQueryClient.invalidateQueries('useGetUserSavedLists');
        }
    });

    return response;
}

/**
 * Mutation to favorite a list builder list
 */
export const useFavoriteList = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: any) => apiRequestService.favoriteList(data), {
        onSuccess: () => {
            //AppQueryClient.invalidateQueries('useGetUserSavedLists');
        }
    });

    return response;
}

/**
 * Mutation to update a list builder list
 */
export const useUpdateList = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IUserList) => apiRequestService.updateList(data), {
        onSuccess: () => {

        }
    });

    return response;
}

/**
 * Mutation to update a list builder folder
 */
export const useUpdateFolder = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IUpdateFolder) => apiRequestService.updateFolder(data), {
        onSuccess: () => {

        }
    });

    return response;
}

/**
 * Mutation to delete a list builder folder and its contents
 */
export const useDeleteFolder = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: string) => apiRequestService.deleteFolder(data), {
        onSuccess: () => {
            AppQueryClient.invalidateQueries('useGetUserSavedLists');
        }
    });

    return response;
}

/**
 * Mutation to delete a list builder list
 */
export const useDeleteList = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: string) => apiRequestService.deleteList(data), {
        onSuccess: () => {
            AppQueryClient.invalidateQueries('useGetUserSavedLists');
        }
    })

    return response;
}

/**
 * Mutation to update the query parameters of a list builder list
 */
export const useUpdateQuery = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IListQuery) => apiRequestService.updateQuery(data), {
        onSuccess: () => {

        }
    });

    return response;
}

/**
 * Mutation to save the reordering of lists within a folder
 */
export const useReorderLists = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IListFolder) => apiRequestService.reorderLists(data));

    return response;
}

/**
 * Mutation to save the reordering of folders
 */
export const useReorderFolders = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const response = useMutation((data: IListFolder[]) => apiRequestService.reorderFolders(data));

    return response;
}

/**
 * Mutation to download list builder results
 */
export const useDownloadListBuilderItems = () => {
    const apiRequestService = ApiRequestService.createInstance();

    const mutation = useMutation((data: IListQuery) => apiRequestService.getDownloadListBuilderItems(data), {
        onSuccess: () => {
            appInsightsService.logEvent('The results of the list builder query have been successfully downloaded');
        }
    });
    return mutation;
};