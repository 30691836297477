import React, { FC } from 'react';
import { Trans } from 'react-i18next';
const NotFound: FC<any> = () => {
    return (
        <div className="d-flex jc-center ai-center message height-60 ">
            <Trans>Page not found</Trans>
        </div>
    );
};

export default NotFound;
