import { Loader } from 'app/components/loader/Loader';
import EmbedPowerBI from 'app/modules/admin/embedPowerBI/EmbedPowerBI';
import { useGetReportById } from 'app/services/queries';
import React from 'react';
import { useParams } from 'react-router-dom';
interface IParams {
    id: string;
}

const ViewReport: React.FC = () => {
    const { id: reportId } = useParams<IParams>();
    const { data: report, isLoading, isFetched } = useGetReportById(reportId);
    return (
        <div className="container">
            {!isLoading && isFetched && <EmbedPowerBI embedURL={report.url} />}
            {isLoading && <Loader />}
        </div>
    );
};

export default ViewReport;
