import React, { useState } from 'react';
import { Image } from '@fluentui/react';
import { useGetAuthors } from 'app/services/queries';
import './AuthorSpotlight.scss';

import authorImage from 'app/static/icons/FeaturedAuthors.svg';
import AuthorCard from '../authorCard/AuthorCard';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
const AuthorSpotlight: React.FC = () => {
    const [authorData, setAuthorData] = useState([]);
    const { isFetched, data: articles } = useGetAuthors(1, 1, 4);

    useEffect(() => {
        setAuthorData(articles?.items);
    }, [isFetched, articles]);
    return (
        <>
            {isFetched && authorData && (
                <div className="author-section">
                    <div className="container">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-lg12">
                                    <div className="header-author-container">
                                        <Image src={authorImage} alt="author icon" />
                                        <h2>
                                            <Trans>Featured Authors</Trans>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row d-flex">
                                {authorData?.map((data) => (
                                    <div key={data.id} className="ms-Grid-col ms-sm12 ms-lg3">
                                        <AuthorCard data={data} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AuthorSpotlight;
