import React, { useEffect, useState } from 'react';
import { Image, PrimaryButton, TextField, Text, DefaultButton } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react';

import { ContextualMenu } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { usePostFeedback } from '../../../../services/mutations';
import { Link } from 'react-router-dom';
import './FeedbackContent.scss';
import fcIconTerrible from 'app/static/icons/fc_icon_terrible.svg';
import fcIconBad from 'app/static/icons/fc_icon_bad.svg';
import fcIconOkay from 'app/static/icons/fc_icon_okay.svg';
import fcIconGood from 'app/static/icons/fc_icon_good.svg';
import fcIconGreat from 'app/static/icons/fc_icon_great.svg';
import fcIconTerribleActive from 'app/static/icons/fc_icon_terrible_active.svg';
import fcIconBadActive from 'app/static/icons/fc_icon_bad_active.svg';
import fcIconOkayActive from 'app/static/icons/fc_icon_okay_active.svg';
import fcIconGoodActive from 'app/static/icons/fc_icon_good_active.svg';
import fcIconGreatActive from 'app/static/icons/fc_icon_great_active.svg';
import { Trans, useTranslation } from 'react-i18next';
import { keyPress } from 'app/components/helpers';
const FeedbackContent: React.FC<{ showOnScreen: boolean; handleShowOnScreen: any }> = ({ showOnScreen, handleShowOnScreen }) => {
    const { t: translate } = useTranslation();
    useEffect(() => {
        const preLoadedImages = [fcIconTerribleActive, fcIconBadActive, fcIconOkayActive, fcIconGoodActive, fcIconGreatActive];
        preLoadedImages.forEach((image: any) => {
            const img = document.createElement('img');
            img.src = image;
        });
    }, []);
    const isDraggable = false;
    const [category, setCategory] = useState('');
    const [reaction, setReaction] = useState('');
    const [feedbackComment, setFeedbackComment] = React.useState('');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const { mutate: postFeedback } = usePostFeedback();

    const handleCommentChange = (event: any) => {
        setFeedbackComment(event.target.value);
    };
    const handleCategoryToggle = (categoryName: string) => {
        setCategory(categoryName);
    };
    const handleReactionToggle = (event: React.MouseEvent<HTMLImageElement>, reactionName: string) => {
        let source: string = '';
        setReaction(reactionName);
        switch (reactionName) {
            case 'terrible':
                source = fcIconTerribleActive;
                break;
            case 'bad':
                source = fcIconBadActive;
                break;
            case 'okay':
                source = fcIconOkayActive;
                break;
            case 'good':
                source = fcIconGoodActive;
                break;
            case 'great':
                source = fcIconGreatActive;
                break;
            default:
                break;
        }
        event.currentTarget.src = source;
    };
    const handleReactionMouseOver = (event: React.MouseEvent<HTMLImageElement>, source: any) => {
        event.currentTarget.src = source;
    };
    const handleReactionMouseOut = (event: React.MouseEvent<HTMLImageElement>, source: any, reactionName: string) => {
        let activeSource: string = '';
        event.currentTarget.src = source;
        if (reaction === reactionName) {
            switch (reactionName) {
                case 'terrible':
                    activeSource = fcIconTerribleActive;
                    break;
                case 'bad':
                    activeSource = fcIconBadActive;
                    break;
                case 'okay':
                    activeSource = fcIconOkayActive;
                    break;
                case 'good':
                    activeSource = fcIconGoodActive;
                    break;
                case 'great':
                    activeSource = fcIconGreatActive;
                    break;
                default:
                    break;
            }
            event.currentTarget.src = activeSource;
        }
    };
    const renderFeedBackIcon = (defaultSource: any, activeSource: any, reactionName: string) => {
        return (
            <Image
                className="fc-icon"
                src={reaction === reactionName ? activeSource : defaultSource}
                alt={reaction}
                onMouseOver={(event) => {
                    handleReactionMouseOver(event, activeSource);
                }}
                onMouseOut={(event) => {
                    handleReactionMouseOut(event, defaultSource, reactionName);
                }}
                tabIndex={0}
                onKeyPress={(e: any) => keyPress(e, () => handleReactionToggle(e, reactionName))}
                onClick={(event) => {
                    handleReactionToggle(event, reactionName);
                }}
                data-testid={defaultSource}
                aria-label={reactionName}
            />
        );
    };

    const getFeedbackCategory = (value: string) => {
        let res = 0;
        switch (value) {
            case 'suggestion':
                res = 1;
                break;
            case 'compliment':
                res = 2;
                break;
            case 'bug':
                res = 3;
                break;

            default:
                break;
        }
        return res;
    };
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (reaction && category && feedbackComment) {
            postFeedback({
                feedbackMessage: feedbackComment,
                experience: reaction,
                feedbackCategory: getFeedbackCategory(category)
            });
            handleShowOnScreen();
        } else {
            toggleHideDialog();
        }
    };

    const dialogContentProps = {
        type: DialogType.normal,
        subText: 'Please provide an appropriate feedback to continue.'
    };

    const modalProps = React.useMemo(() => {
        const modalPropsStyles = { main: { maxWidth: 450 } };

        const dragOptions = {
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu
        };

        return {
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined
        };
    }, [isDraggable]);

    return (
        <div className={`fc-content ${showOnScreen ? '' : 'hidden'}`}>
            <div className="fc-title">
                <Trans>Feedback</Trans>
            </div>
            <div>
                <Text>
                    <Trans>We would love to hear about your experience using the Admin portal</Trans>:
                </Text>
            </div>
            <div className="fc-icons-container">
                <div className="fc-icon-wrap" data-testid="great-icon">
                    {renderFeedBackIcon(fcIconGreat, fcIconGreatActive, 'great')}
                    <div className="fc-icon-desc">
                        <Trans>Great</Trans>
                    </div>
                </div>
                <div className="fc-icon-wrap" data-testid="good-icon">
                    {renderFeedBackIcon(fcIconGood, fcIconGoodActive, 'good')}
                    <div className="fc-icon-desc">
                        <Trans>Good</Trans>
                    </div>
                </div>
                <div className="fc-icon-wrap" data-testid="okay-icon">
                    {renderFeedBackIcon(fcIconOkay, fcIconOkayActive, 'okay')}
                    <div className="fc-icon-desc">
                        <Trans>Okay</Trans>
                    </div>
                </div>
                <div className="fc-icon-wrap" data-testid="bad-icon">
                    {renderFeedBackIcon(fcIconBad, fcIconBadActive, 'bad')}
                    <div className="fc-icon-desc">
                        <Trans>Bad</Trans>
                    </div>
                </div>
                <div className="fc-icon-wrap" data-testid="terrible-icon" aria-labelledby="Terrible-icon" role="navigation">
                    {renderFeedBackIcon(fcIconTerrible, fcIconTerribleActive, 'terrible')}
                    <div className="fc-icon-desc">
                        <Trans>Terrible</Trans>
                    </div>
                </div>
            </div>
            <div>
                <Text>
                    <Trans>Please select your feedback category</Trans> :
                </Text>
            </div>
            <div className="fc-category">
                <DefaultButton
                    className={category === 'suggestion' ? 'active' : ''}
                    onClick={() => {
                        handleCategoryToggle('suggestion');
                    }}
                    data-testid="suggest-button">
                    <i className="ms-Icon ms-Icon--OfficeChat" aria-hidden="true"></i>{' '}
                    <span>
                        <Trans>Suggestion</Trans>
                    </span>
                </DefaultButton>
                <DefaultButton
                    className={category === 'compliment' ? 'active' : ''}
                    onClick={() => {
                        handleCategoryToggle('compliment');
                    }}
                    data-testid="compliment-button">
                    <i className="ms-Icon ms-Icon--Heart" aria-hidden="true"></i>{' '}
                    <span>
                        <Trans>Compliment</Trans>
                    </span>
                </DefaultButton>
                <DefaultButton
                    className={category === 'bug' ? 'active' : ''}
                    onClick={() => {
                        handleCategoryToggle('bug');
                    }}
                    data-testid="report-button">
                    <i className="ms-Icon ms-Icon--Bug" aria-hidden="true"></i>{' '}
                    <span>
                        <Trans>Report A Bug</Trans>
                    </span>
                </DefaultButton>
            </div>
            <form onSubmit={handleSubmit} data-testid="form-button">
                <div>
                    <Text>
                        <Trans>Please provide your feedback below</Trans> :
                    </Text>
                </div>
                <div className="fc-textfield-wrap">
                    <TextField
                        aria-label="feedback-textArea"
                        data-testid="feedback-field-test"
                        value={feedbackComment}
                        placeholder="Details help make this feedback more impactful. What, when, and why are good things to include."
                        multiline
                        resizable={false}
                        borderless
                        rows={6}
                        onChange={handleCommentChange}
                    />
                </div>
                <div className="fc-form-btn-wrap">
                    <Link role="link" to="/">
                        <DefaultButton className="default">
                            <Trans>Cancel</Trans>
                        </DefaultButton>
                    </Link>
                    <DefaultButton data-testid="submit-btn-test" type="submit" className="primary">
                        <Trans>Submit</Trans>
                    </DefaultButton>
                </div>
            </form>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text={translate('OK')} />
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default FeedbackContent;
