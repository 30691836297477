import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, Checkbox, Icon, Label } from '@fluentui/react';
import './AgingPicker.scss';
import { getAgingArray, keyPress } from 'app/components/helpers';
import { StringConstants } from 'app/utils/constants';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';
import { useTranslation } from 'react-i18next';
import { IDateRangeProps } from 'app/models/common/post';

interface IProps {
    setSelectedOptions: Function;
    multiSelectData: any[];
    inEditMode: boolean;
    selectedKeys: any[];
    setSelectedKeys: Function;
}

const AgingPicker: React.FC<IProps> = ({ setSelectedOptions, inEditMode, multiSelectData, selectedKeys, setSelectedKeys }) => {
    const { t: translate } = useTranslation();
    const dropDownRef = useRef(null);
    //const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState<IDateRangeProps>({ start: null, end: null });
    const rangeItemConstants = {
        WEEK: translate(StringConstants.WEEK),
        MONTH: translate(StringConstants.MONTH),
        YEAR: translate(StringConstants.YEAR)
    };
    const [activeRange, setActiveRange] = useState(rangeItemConstants.WEEK);
    const [showDropdown, setShowDropdown] = useState(false);

    const agingOptions = useMemo(() => {
        const options: IOptionProps[] = [
            { key: 'all', text: 'All' },
            {
                key: '0d-7d',
                text: '0d - 7d'
            },
            {
                key: '8d-30d',
                text: '8d - 30d'
            },
            {
                key: '31d-plus',
                text: '31d+'
            }
        ];
        return options;
    }, [dateRange, activeRange]);

    // auto-populate fields when in edit mode
    useEffect(() => {
        if (inEditMode && multiSelectData.length > 0) {
            const dateRanges = multiSelectData.map((item: any) => item.range);
            const keys = agingOptions.filter((option) => dateRanges.includes(option.key));
            setSelectedKeys(keys);
            setSelectedOptions(getAgingArray(keys.map((item) => item.key.toString())));
        }
    }, [inEditMode, multiSelectData]);

    // For aging, the back end query uses an object of type Aging to create the query
    // getAgingArray is a helper method that maps the selected options to this Aging object
    // We must ensure that selectedOptions is set to this object
    const onChange = (key: string) => {
        if (key === 'all') {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: IOptionProps[] = [];
                agingOptions.forEach((ele) => arr.push(ele));

                const agingArray = getAgingArray(arr.map((item) => item.key.toString()));

                setSelectedKeys(arr);
                setSelectedOptions(agingArray);
            } else {
                setSelectedKeys([]);
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                const tempSelectedKeys = [...selectedKeys, agingOptions.filter((item) => item.key === key)[0]];
                const agingArray = getAgingArray(tempSelectedKeys.map((item) => item.key));

                setSelectedKeys(tempSelectedKeys);
                setSelectedOptions(agingArray);
            } else {
                const tempSelectedKeys = selectedKeys.filter((item) => item.key !== key && item.key !== StringConstants.ALL);
                const agingArray = getAgingArray(tempSelectedKeys.map((item) => item.key));

                setSelectedKeys(tempSelectedKeys);

                if (agingArray.length > 0) {
                    setSelectedOptions(agingArray);
                } else {
                    setSelectedOptions(null);
                }
            }
        }
    };

    const isChecked = (ele: string) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };

    const getLabel = () => {
        if (selectedKeys?.filter((ele) => ele.key === StringConstants.ALL).length !== 0) {
            return StringConstants.UPPERCASE_ALL;
        } else {
            let arr: string[] = [];
            selectedKeys?.forEach((item) => {
                arr.push(item.text);
            });
            return arr.join(', ');
        }
    };

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };

    useDropDownDismiss(dropDownRef, onToggleShow);
    
    return (
        <div className="aging-filter filter-cont">
            <div
                className="lb-picker-title row-flex-box align-center"
                tabIndex={0}
                data-testid="aging-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <div className="aging-picker-box">
                    <Text className="placeholder-label">{getLabel()}</Text>{' '}
                    <Icon className="chevron-styles" iconName="ChevronDown" />
                </div>
            </div>
            {showDropdown && (
                <div ref={dropDownRef} className="dropdown-cont">
                    <div
                        tabIndex={0}
                        data-testid="aging-filter-close-btn-test"
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center dropdown-header">
                        <Text>Aging</Text>
                        <Icon iconName="ChevronUp" />
                    </div>
                    {agingOptions.map((item, index) => (
                        <div
                            key={index}
                            data-testid={`dropdown-item-test-${index}`}
                            tabIndex={0}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                    onChange(item.key as string);
                                }
                            }}
                            className={`dropdown-item row-flex-box align-center ${
                                isChecked(item.key as string) ? 'selected' : ''
                            } `}>
                            <Checkbox
                                data-testid={`dropdown-checkbox-test-${index}`}
                                checked={isChecked(item.key as string)}
                                onChange={() => onChange(item.key as string)}
                            />
                            <span className={`aging-icon ${'icon-' + item.key}`}></span>
                            <Label>{item.text}</Label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AgingPicker;

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
