import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { IUserDetails } from 'app/models/common/post';

/**
 * This component is used to redirect the generic request details to appropriate request details page based on role.
 */
export const RequestDetailsRoute: React.FC<{
    path: string | string[];
    exact?: boolean;
}> = (props) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const condition = isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles);
    return (
        <Route
            path={props.path}
            exact={props.exact}
            render={({ match }) =>
                condition ? (
                    <Redirect to={`/request-detail/${match.params.id}`} />
                ) : (
                    <Redirect to={`/request/${match.params.id}`} />
                )
            }
        />
    );
};
