import React, { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import { buttonList, setVideoOptions, setImageOptions } from './config';
import { fileUpload } from 'app/components/helpers';
import { useGenerateContainerSasToken, useGetUsersList } from 'app/services/queries';
import SunEditorCore from 'suneditor/src/lib/core';
import { ALLOWED_VIDEO_MIME_TYPES, Entity, BlobStorageSubfolder, StringConstants } from 'app/utils/constants';
import ReactDOMServer from 'react-dom/server';
import Link from './link';
import plugins from 'suneditor/src/plugins';
import { useSearchData, useSearchResult } from 'app/services/mutations';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';
import { IBlobUploadParams, IUserBasicDetails, IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
import Emojis from './emoji';
import _ from 'lodash';
import { addSasTokenToResponse } from 'app/utils/blobStorageHelpers';

interface IProps {
    content: string;
    isMentionAvailable?: boolean;
    setMentionedUsers?: (mentionedUsers: IUserBasicDetails[]) => void;
    mentionedUsers?: IUserBasicDetails[];
    hashTagsFormatted?: any[];
    setHashTagsFormatted?: (unformatedHashTags: any[]) => void;
    isCommentField?: boolean;
    isRequestHelpField?: boolean;
    setIsSubmitDisabled?: (isSubmitDisabled: boolean) => void;
    setTextToSubmit?: (textToSubmit: string) => void;
    textToSubmit?: string;
    fileStorageContainer: string;
}

const RequestRichTextEditor: React.FC<IProps> = ({
    content,
    isMentionAvailable = false,
    setMentionedUsers,
    mentionedUsers,
    setHashTagsFormatted,
    hashTagsFormatted,
    isCommentField = false,
    isRequestHelpField = false,
    setIsSubmitDisabled,
    setTextToSubmit,
    textToSubmit,
    fileStorageContainer
}) => {
    const [, setPercentComplete] = useState<number>(0);

    const { refetch: getToken } = useGenerateContainerSasToken({entity: StringConstants.REQUEST, containerName: fileStorageContainer});

    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const editorInstance = useRef(null);

    const getEditor = (editor: SunEditorCore) => (editorInstance.current = editor);

    const handleFileUploadProgress = (loadedBytes: number, totalBytes: number) => {
        const percentageCompleted = Math.floor((100 * loadedBytes) / totalBytes);
        setPercentComplete(percentageCompleted / 100);
    };

    const videoUploadHandler = (files: File[], info: object, uploadHandler: Function) => {
        const file = files[0];
        if (!ALLOWED_VIDEO_MIME_TYPES.includes(file?.type.toLowerCase())) {
            uploadHandler('Unsupported Video format. Please upload .mp4/.ogg/.webm formats.');
        }
        const blobName = StringConstants.NEW_BLOB + new Date().getTime();
        try {
            getToken().then((res) => {
                const blobUploadParams: IBlobUploadParams = {
                    file: file,
                    blobName: blobName,
                    container: fileStorageContainer,
                    subfolder: BlobStorageSubfolder.NONE,
                    token: res.data
                };

                fileUpload(blobUploadParams, handleFileUploadProgress)
                    .then((res) => {
                        const response = {
                            result: [
                                {
                                    url: res._response.request.url.split(/[?#]/)[0],
                                    name: file.name,
                                    size: file.size
                                }
                            ]
                        };

                        addSasTokenToResponse(Entity.REQUEST, fileStorageContainer, response)
                        .then((res) => {
                            uploadHandler(res);
                        });
                    })
                    .catch((err) => {
                        handleFileUploadProgress(0, 1);
                        return uploadHandler();
                    })
                    .finally(() => {
                        handleFileUploadProgress(0, 1);
                    });
            });
        } catch (err) {
            handleFileUploadProgress(0, 1);
            return uploadHandler();
        }
    };

    const imageUploadHandler = (files: File[], info: object, uploadHandler: Function) => {
        const file = files[0];
        const blobName = StringConstants.NEW_BLOB + new Date().getTime();
        try {
            getToken().then((res) => {
                const blobUploadParams: IBlobUploadParams = {
                    file: file,
                    blobName: blobName,
                    container: fileStorageContainer,
                    subfolder: BlobStorageSubfolder.NONE,
                    token: res.data
                };

                fileUpload(blobUploadParams, handleFileUploadProgress)
                    .then((res) => {
                        const response = {
                            result: [
                                {
                                    url: res._response.request.url.split(/[?#]/)[0],
                                    name: file.name,
                                    size: file.size
                                }
                            ]
                        };

                        addSasTokenToResponse(Entity.REQUEST, fileStorageContainer, response)
                        .then((res) => {
                            uploadHandler(res);
                        });
                    })
                    .catch((err) => {
                        handleFileUploadProgress(0, 1);
                        return uploadHandler();
                    })
                    .finally(() => {
                        handleFileUploadProgress(0, 1);
                    });
            });
        } catch (err) {
            handleFileUploadProgress(0, 1);
            return uploadHandler();
        }
    };

    const [isMentionMode, setIsMentionMode] = useState<boolean>(false);
    const [isHashtagSearch, setIsHashtagSearch] = useState<boolean>(false);
    const [isSkpSearch, setIsSkpSearch] = useState<boolean>(false);
    const [skpSearchValue, setSkpSearchValue] = useState<string>('');
    const [hashtagSearchValue, setHashtagSearchValue] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');

    const { data: userList } = useGetUsersList(searchValue.substring(1, searchValue.length).trim(), false, 'Admin', null, 10);
    const { mutate: searchHashtagData, data: hashtagSuggestionData } = useSearchResult();
    const { mutate: searchData, data: searchResultData } = useSearchData();

    const onTypingHandler = (event: any) => {
        // init the mention scenario
        if ((event.key === '@' || event.key === '#' || event.key === '!') && !isMentionMode && !isHashtagSearch && !isSkpSearch) {
            switch (event.key) {
                case '@':
                    if (isMentionAvailable) {
                        setIsMentionMode(true);
                        setSearchValue(event.key);
                    }
                    break;
                case '#':
                    setIsHashtagSearch(true);
                    setHashtagSearchValue(event.key);
                    break;
                case '!':
                    setIsSkpSearch(true);
                    setSkpSearchValue(event.key);
                    break;
            }

            editorInstance.current.insertHTML('<span id="mention"></span>', true);
        } else if (isMentionMode || isHashtagSearch || isSkpSearch) {
            if (isMentionMode && /^[a-zA-Z\s]*$/.test(event.key) && event.key.length === 1) {
                setSearchValue(searchValue + event.key);
            } else if (isSkpSearch && event.key.length === 1) {
                setSkpSearchValue(skpSearchValue + event.key);
            } else if (isHashtagSearch && /^[a-zA-Z\s]*$/.test(event.key) && event.key.length === 1) {
                setHashtagSearchValue(hashtagSearchValue + event.key);
            } else if (event.key === 'Backspace') {
                if (isMentionMode) {
                    setSearchValue(searchValue.slice(0, -1));
                } else if (isHashtagSearch) {
                    setHashtagSearchValue(hashtagSearchValue.slice(0, -1));
                } else if (isSkpSearch) {
                    setSkpSearchValue(skpSearchValue.slice(0, -1));
                }
            } else if (isHashtagSearch && event.key === ' ') {
                var tempContent = editorInstance.current.getContents(true);
                setHashTagsFormatted([...hashTagsFormatted, { hashTag: hashtagSearchValue.substring(1) }]);
                var anchor = tempContent.replace(
                    hashtagSearchValue,
                    `<a class="mentioned-hashtag" href="javascript:void(0);">${hashtagSearchValue}</a>`
                );
                var newContent = anchor.replace('<span id="mention"></span>', '');

                // reset state
                setIsHashtagSearch(false);
                setHashtagSearchValue('');

                setTextToSubmit(newContent);
            }
        }
    };

    useEffect(() => {
        if (hashtagSearchValue.length > 3) {
            let payload: any = {
                searchTerm: `${hashtagSearchValue.substring(1)}*`,
                pageIndex: 1,
                pageSize: 30,
                highlightPreTag: '',
                highlightPostTag: '',
                HighlightFields: [],
                searchField: 'Default',
                searchOption: 'HelpRequest',
                TagSearchType: 'HashTag',
                orderBy: [],
                facets: [],
                select: ['HashTags/HashTag', 'Discriminator'],
                filter: `search.ismatch('${hashtagSearchValue.substring(1)}*', 'HashTags/HashTag')`
            };

            searchHashtagData(payload);
        } else if (hashtagSearchValue.length <= 0) {
            setIsHashtagSearch(false);

            var mentionSpan = document.getElementById('mention');
            var context = editorInstance.current.getContext();

            if (mentionSpan !== null) {
                editorInstance.current.core.removeNode(mentionSpan);
                context.link.linkController.style.display = StringConstants.NONE;
            }
        }
    }, [hashtagSearchValue]);

    useEffect(() => {
        if (skpSearchValue.length > 3) {
            searchData({
                term: skpSearchValue.substring(1),
                searchOption: 'HelpRequest',
                IsAdminSearch: isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles),
                IsParentChildSearch: false
            });
        } else if (skpSearchValue.length <= 0) {
            setIsSkpSearch(false);

            var mentionSpan = document.getElementById('mention');
            var context = editorInstance.current.getContext();

            if (mentionSpan !== null) {
                editorInstance.current.core.removeNode(mentionSpan);
                context.link.linkController.style.display = StringConstants.NONE;
            }
        }
    }, [skpSearchValue]);

    useEffect(() => {
        if (searchValue.length <= 0) {
            setIsMentionMode(false);
            var mentionSpan = document.getElementById('mention');
            var context = editorInstance.current.getContext();

            if (mentionSpan !== null) {
                editorInstance.current.core.removeNode(mentionSpan);
                context.link.linkController.style.display = StringConstants.NONE;
            }
        }
    }, [searchValue]);

    useEffect(() => {
        if (isCommentField && setIsSubmitDisabled) {
            // Handle cancel in CommentField sets textToSubmit = ''
            // We must do the same for the editor contents
            if (textToSubmit.length === 0) {
                editorInstance.current.setContents('');
            }

            setIsSubmitDisabled(editorInstance.current.getText().length <= 0);
        }

    }, [textToSubmit]);

    const MentionsListItem = (user: any) => {
        return (
            <div className="mentions-menu-item" aria-selected={true} role="option" id={user.id} data-name={user.displayName}>
                <div
                    className="mentions-menu-item-name"
                    data-name={user.displayName}
                    data-userid={user.id}
                    data-email={user.mail}>
                    {user.displayName}
                </div>
                <div
                    className="mentions-menu-item-email"
                    data-name={user.displayName}
                    data-userid={user.id}
                    data-email={user.mail}>
                    {user.mail}
                </div>
            </div>
        );
    };

    const SuggestionListItem = (item: any) => {
        return (
            <div
                className="mentions-menu-item"
                aria-selected={true}
                role="option"
                id={isSkpSearch ? item.Id : ''}
                data-name={isSkpSearch ? item.UnifiedTrackingId.toString() : item}>
                <div className="mentions-menu-item-name" data-name={isSkpSearch ? item.UnifiedTrackingId : item}>
                    {isSkpSearch ? `${item.UnifiedTrackingId}: ${item.Title}` : item}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (editorInstance.current !== null) {
            var editor = editorInstance.current as SunEditorCore;
            var context = editorInstance.current.getContext();
            var mentionSpan = document.getElementById('mention');

            if (
                (isMentionMode && userList?.length > 0) ||
                (isSkpSearch && searchResultData?.data?.length > 0) ||
                (isHashtagSearch && hashtagSuggestionData?.data?.SearchResult?.Items?.length > 0)
            ) {
                // display the dialog that has suggestions
                context.link.linkController.style.display = 'block';
                context.link.linkController.style.top = mentionSpan?.offsetTop + 27 + 'px';
                context.link.linkController.style.left = mentionSpan?.offsetLeft - 32 + 'px';

                var dataDivs;

                if (isMentionMode) {
                    dataDivs = userList.map((user: any) => ReactDOMServer.renderToString(MentionsListItem(user)));
                } else if (isSkpSearch) {
                    dataDivs = searchResultData.data.map((item: any) =>
                        ReactDOMServer.renderToString(SuggestionListItem(item.Document))
                    );
                } else if (isHashtagSearch) {
                    var hashtags: any[] = [];

                    hashtagSuggestionData.data.SearchResult.Items.forEach((item: any) => {
                        if (item?.Document?.HashTags && item?.Document?.HashTags.length > 0) {
                            hashtags.push(...item?.Document?.HashTags?.map((tag: any) => tag.HashTag));
                        }
                    });

                    hashtags = _.uniq(hashtags);

                    dataDivs = hashtags.map((hashtag) => ReactDOMServer.renderToString(SuggestionListItem(hashtag)));
                }

                // the content container in which the user divs will be viewed
                context.link.linkController.children[1].innerHTML = dataDivs.join('');

                for (var i = 0; i < context.link.linkController.children[1].children.length; i++) {
                    var child = context.link.linkController.children[1].children.item(i);

                    // add on click handlers for each suggestion
                    child.onclick = (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        var currentContent = editorInstance.current.getContents(true);
                        var addAnchor;

                        if (isMentionMode) {
                            addAnchor = currentContent.replace(
                                searchValue,
                                `<a class="mentioned-user">${event?.target?.dataset?.name}</a>&nbsp;`
                            );
                            const userIds = mentionedUsers.map((user) => user.id);

                            // ensure uniqueness of mentioned users
                            if (!userIds.includes(event?.target?.dataset?.userid)) {
                                setMentionedUsers([
                                    ...mentionedUsers,
                                    {
                                        id: event?.target?.dataset?.userid,
                                        displayName: event?.target?.dataset?.name,
                                        email: event?.target?.dataset?.email
                                    }
                                ]);
                            }
                        } else if (isSkpSearch) {
                            addAnchor = currentContent.replace(
                                skpSearchValue,
                                `<a class="mentioned-request" href="/request-detail/${event?.target?.dataset?.name}">${event?.target?.innerText}</a>&nbsp;`
                            );
                        } else if (isHashtagSearch) {
                            addAnchor = currentContent.replace(
                                hashtagSearchValue,
                                `<a class="mentioned-hashtag">#${event?.target?.innerText}</a>&nbsp`
                            );
                            setHashTagsFormatted([...hashTagsFormatted, { hashTag: event?.target?.innerText }]);
                        }

                        // remove placeholder and set the content of the editor
                        var newContent = addAnchor.replace('<span id="mention"></span>', '');
                        context.element.wysiwyg.innerHTML = newContent;
                        setTextToSubmit(newContent);

                        // ensure suggestion modal disappears
                        context.link.linkController.style.display = StringConstants.NONE;

                        // reset state
                        setIsMentionMode(false);
                        setIsSkpSearch(false);
                        setIsHashtagSearch(false);
                        setSkpSearchValue('');
                        setSearchValue('');
                        setHashtagSearchValue('');

                        editor.core.focusEdge(null);
                    };
                }
            } else {
                // if anchor does not exist, we will hide the dialog
                if (context?.link?.linkController) {
                    context.link.linkController.style.display = StringConstants.NONE;
                }
            }
        }
    }, [userList, searchResultData, hashtagSuggestionData, editorInstance, isMentionMode, isSkpSearch, isHashtagSearch]);

    // Deleting whole search term scenario
    // If the user highlights and deletes the whole phrase, disable mention mode and reset state
    const onInputHandler = (event: any) => {
        // if at mention mode is true and the text content does not include the seachValue
        if (isMentionMode && !editorInstance.current.getText().includes(searchValue)) {
            setTextToSubmit(event.target.innerHTML);
            if (event.key !== ' ') {
                setIsMentionMode(false);
                setSearchValue('');
            }
        } else if (isSkpSearch && !editorInstance.current.getText().includes(skpSearchValue)) {
            setTextToSubmit(event.target.innerHTML);
            setIsSkpSearch(false);
            setSkpSearchValue('');
        } else if (isHashtagSearch && !editorInstance.current.getText().includes(hashtagSearchValue)) {
            setTextToSubmit(event.target.innerHTML);
            setIsHashtagSearch(false);
            setHashtagSearchValue('');
        }
    };

    useEffect(() => {
        if (editorInstance.current) {
            const editor = editorInstance.current as SunEditorCore;
            const buttons = editor.core.context.element.toolbar.getElementsByClassName('se-btn se-tooltip');
            Array.prototype.forEach.call(
                buttons,
                (btn: Element) => btn.getAttribute('disabled') !== 'true' && btn.setAttribute('tabindex', '0')
            );
        }
    }, [editorInstance])

    return (
        <div>
            <form onKeyDown={onTypingHandler} onKeyUp={onInputHandler}>
                <SunEditor
                    setContents={content}
                    setOptions={{
                        mode: 'classic',
                        plugins: { ...plugins, Link, Emojis },
                        buttonList: isRequestHelpField  
                            ? [...buttonList.requestHelp]
                            : [...buttonList.responsive],
                        ...setVideoOptions,
                        ...setImageOptions,
                        attributesWhitelist: {
                            all: 'style',
                            input: 'checked'
                        },
                        minHeight: isCommentField ? '5rem' : '500px',
                        tabDisable: true,
                    }}
                    onChange={setTextToSubmit}
                    onVideoUploadBefore={videoUploadHandler}
                    onImageUploadBefore={imageUploadHandler}
                    getSunEditorInstance={getEditor}
                />
            </form>
        </div>
    );
};

export default RequestRichTextEditor;
