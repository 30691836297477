import 'office-ui-fabric-react/dist/css/fabric.css';
import { initializeIcons } from '@fluentui/react';
import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import useStore from './store';
import * as serviceWorker from '../serviceWorker';
import './App.module.scss';
import './assets/styles/styles.scss';
import { getComponent, isAdmin, isContentCreator, isContentManager, isSuperAdmin, isUser } from './utils/authUtilities';
import { routes } from './routes';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'authConfig';
import { Loader } from './components/loader/Loader';
import {
    getAccessToken,
    getMSGraphTokenSilently,
    getPowerBITokenSilently,
    getProfileByUserId,
    getProfileImageByUserId
} from './components/helpers';
import { useGetUserDetails } from './services/queries';

initializeIcons();

const ScrollToTop = (): null => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);

    return null;
};

const App: React.FC = () => {
    const setAccessToken = useStore((state: any) => state.setAccessToken);
    const setMSGraphAccessToken = useStore((state: any) => state.setMSGraphAccessToken);
    const setAccountDetails = useStore((state: any) => state.setAccountDetails);
    const accessToken = useStore((state: any) => state.accessToken);
    const userDetails = useStore((state: any) => state.userDetails);
    const { isFetched, data: userData } = useGetUserDetails();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const setUserInfo = useStore((state: any) => state.setUserInfo);

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
    }
    useEffect(() => {
        if (userData?.id)
        
            getProfileImageByUserId(userData?.id).then((profile: any) => {
                getProfileByUserId(userData?.id)
                    .then((contactDetail) => {
                        setUserInfo({
                            id: userData?.id,
                            displayName: userData?.displayName,
                            email: userData?.email,
                            userRoles: userData?.userRoles,
                            userDomain: userData?.userDomain,
                            contactNumber: contactDetail?.mobilePhone,
                            businessPhones: contactDetail?.businessPhones,
                            profilePic: profile
                        });
                    })
                    .catch();
            });
    }, [isFetched, userData]);

    useEffect(() => {
        if (account)
            getAccessToken('SILENT', instance, loginRequest.scopes, account).then((response) => {
                if (response) {
                    setAccessToken(response.accessToken);
                    localStorage.setItem('accessToken', response.accessToken);
                    setAccountDetails(response.account);
                    getMSGraphTokenSilently(instance, account).then((accessToken) => {
                        if (accessToken) {
                            setMSGraphAccessToken(accessToken);
                            localStorage.setItem('msGraphAccessToken', accessToken);
                            getPowerBITokenSilently(instance, account, loginRequest.powerBiScope).then((response) => {
                                if (response) localStorage.setItem('powerBIToken', response);
                            });
                        }
                    });
                }
            });
    });

    return (
        <>
            <AuthenticatedTemplate>
                {accessToken.length > 0 && (
                        <BrowserRouter>
                            <ScrollToTop />
                            {userData?.id && userDetails?.id ? (
                                <div className="ms-Grid-col p-a-0">
                                    <Header />

                                    <Switch>
                                        {(isAdmin(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles)) && (
                                            <Route path="/" exact component={getComponent(userDetails?.userRoles)} />
                                        )}
                                        {(isContentCreator(userDetails?.userRoles) ||
                                            isContentManager(userDetails?.userRoles)) && (
                                            <Redirect exact from="/" to="/manage-content/articles" />
                                        )}
                                        {isUser(userDetails?.userRoles) && <Redirect exact from="/" to="/knowledge-portal" />}

                                        {routes}
                                    </Switch>

                                    <Footer />
                                </div>
                            ) : (
                                <Loader
                                    classNames="m-t-50 m-b-50 height-70 initial-loader"
                                    loadingText="Loading Surface Knowledge Portal..."
                                />
                            )}
                        </BrowserRouter>
                )}
            </AuthenticatedTemplate>
        </>
    );
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
