import { Persona, PersonaInitialsColor, Image } from '@fluentui/react';
import { useGetUserProfilePicture } from 'app/services/queries';

import React, { useEffect, useState } from 'react';
import { getInitialsFromName } from '../helpers';

interface IProps {
    userId?: string;
    userName: string;
    initialColor?: PersonaInitialsColor;
    color?: string; 
    roundedPic?: boolean;
    imageSize?: number;
}

const Avatar = (props: IProps) => {
    const { userId, initialColor, color, roundedPic = true, imageSize = 36, userName = '' } = props;
    const [profilePic, setProfilePic] = useState<string>(null);

    const { data: profilePicture, isLoading: isProfilePictureLoading } = useGetUserProfilePicture(userId);

    useEffect(() => {
        if (!isProfilePictureLoading) {
            setProfilePic(profilePicture);
        }
    }, [profilePic, userId, isProfilePictureLoading, profilePicture]);

    const personName = userName?.split('(')[0].trim() || '';

    if (profilePic && profilePic.length > 20) {
        return (
            <Image
                className={roundedPic ? 'rounded-image m-r-10' : 'm-r-10'}
                src={`data:image/jpeg;base64, ${profilePic}`}
                alt="profile pic"
                height={imageSize}
                width={imageSize}
            />
        );
    } else {
        return <Persona imageInitials={getInitialsFromName(personName)} initialsColor={color ? color : initialColor} coinSize={imageSize} />;
    }
};

export default Avatar;
