export const TEAM_DETAILS = [
    {
        id: 'SDSG',

        name: 'Surface Deal Success Group (SDSG)',
        description: 'Pre-sales team that supports the commercial field sellers',
        secondaryText: 'Offers 3 main categories of support:',

        roles: [
            'Proposals: proactive sales material custom created for specific customers',
            'RFPs : help sales teams respond to customer’s request for proposals when purchasing  computing hardware',
            'Questions: all variety of question support, which can range from technical details, certifications, testing, engineering details, etc.. '
        ],
        action: 'The SDSG often acts as an  intermediary for field requests and escalates asks via the rest of the Surface business for answers. '
    },
    {
        id: 'CXP',
        name: 'Customer Experience Program Management (CXP)',
        description: 'Also known as Commercial Acceleration Team (CAT)',
        action: 'Engineering point of escalation for all escalated needs among other things. Point of contact for the SDSG, FDD, CSS and new product/feature requests'
    },
    {
        id: 'CSS',
        name: 'Customer Service and Support(CSS)',
        description:
            'The support team who manages and resolves technical support cases by working directly with end customers. Manages escalation path up to engineering.'
    },
    {
        id: 'CREW',
        name: 'Customer Repair Experience Warranty (CREW)',
        description: 'Includes Field Device Desk (FDD)',
        action: 'Warranty and product replacement operation for commercial customers.'
    }
];
