import { blobTokenIsExpired, findStorageUrlsInString, getStorageContainerName, isValidURL, replaceUnsafeCharacters } from 'app/components/helpers';
import { IAttachment, IBlobDownloadFile, IBlobError, IBlobUploadFile } from 'app/models/common/post';
import { ApiRequestService } from 'app/services/api-service';
import { appInsightsService, BlobStorageError, BlobStorageHelper, StringConstants } from './constants';

/**
 * Upload a list of files to blob storage via service API
 * @param uploadFiles
 * @returns IBlobError[] of failed files
 */
 export const blobStorageUpload = async (uploadFiles: IBlobUploadFile[]) : Promise<IBlobError[]> => {
    const _client = ApiRequestService.createInstance();
     const uploadFailures: IBlobError[] = [];

    try {
        await Promise.allSettled(
            uploadFiles.map(async (attachment: IBlobUploadFile) => {
                try {
                    await _client.uploadFileToBlobStorageAsync(attachment.file, attachment.blobName)
                    .then(response => {
                        const data = response.data;
                        if (data?.fullBlobUri === null || data?.fullBlobUri === '') {
                            uploadFailures.push({ fileName: attachment.file.name, blobName: attachment.blobName, error: 'file did not return a valid url.' });
                        }
                    })
                    .catch(err => {
                        // NOTE: If you get network error, check the file size. Large file sizes are giving this error.
                        let message = err.response?.data?.Message ? err.response.data.Message : err.message;
                        if (err.message === BlobStorageError.NETWORK_ERROR && err.stack.includes(BlobStorageError.CREATE_ERROR)) {
                            message = BlobStorageError.MAX_FILE_SIZE;
                        }
                        if (message.includes(BlobStorageError.FILE_ALREADY_EXISTS)) {
                            message = BlobStorageError.FILE_ALREADY_EXISTS;
                        }
                        uploadFailures.push({ fileName: attachment.file.name, blobName: attachment.blobName, error: message });
                    });
                }
                catch (exception) {
                    // log to appinsights
                }
            })
        );
    }
    catch (err) {
        // log to appinsights
    }

    return uploadFailures;
}

/**
 * Download a BlobSasUri for downloading a blob file
 * @param blobName
 * @returns IBlobDownloadFile
 */
export const blobStorageDownload = async (blobName: string) : Promise<IBlobDownloadFile> => {
    const _client = ApiRequestService.createInstance();
    let response: IBlobDownloadFile = { uri: undefined, token: undefined, sasUri: undefined, error: undefined };

    await _client.getFileSasUriFromBlobStorageAsync(blobName)
    .then(res => {
        // log to app insights
        response.sasUri = res.data.blobSasUri;
    })
    .catch(err => {
        // log to app insights
        response.error = err.response;
    });

    return response;
}

/**
 * Remove failed attachments from the current list of attachments
 * @param failedUploads
 * @param attachments
 * @returns List of successfully uploaded blobs
 */
export const removeFailedUploadsFromAttachmentList = ( failedUploads: IBlobError[], attachments: any[] ) : any[] => {
    failedUploads.forEach((failedUpload, i) => {
        let idx = attachments.findIndex(function(attachment) {
            if (attachment.blobName) {
                return attachment.blobName === failedUpload.blobName;
            }
            if (attachment.url) {
                return attachment.url.includes(failedUpload.blobName)
            }

            return false;
        })
        attachments.splice(idx, 1);
    })

    return attachments;
}

/**
 * Remove failed files from the current list of files
 * @param blobFiles
 * @param attachments
 * @returns List of successfully uploaded files
 */
export const removeFailedBlobFiles = ( blobFiles: IBlobUploadFile[], attachments: any[] ) : IBlobUploadFile[] => {
    attachments.forEach((attachment, i) => {
        let idx = blobFiles.findIndex(function(file) {
            return file.blobName !== attachment.blobName;
        })
        blobFiles.splice(idx, 1);
    })

    return blobFiles;
}

/**
 * Remove failed attached files from list of attached files
 * @param attachments
 * @param attachedFiles
 * @returns List of successfully uploaded attached files
 */
export const removeFailuresFromAttachedFiles = (attachments: IAttachment[], attachedFiles: any[]) => {
    // get list of file names
    let names = getNamesFromAttachments(attachments);
    let list = [...attachedFiles];

    attachedFiles.forEach(file => {
        if (!names.includes(file.blobName)) {
            // find the index
            let idx = list.findIndex(function(item) {
                return item.blobName === file.blobName;
            });
            // remove from list
            list.splice(idx, 1);
        }
    })

    return list;
}

/**
 * Create a unique blob name for attachment
 * @param fileName
 * @returns Unique blob name - string
 */
export const createBlobName = (fileName: string) => {
    return StringConstants.NEW_BLOB + new Date().getTime() + '_' + replaceUnsafeCharacters(fileName);
}

/**
 * Gets all file names from provided IAttachment array
 * @param files
 * @returns list of file names
 */
const getNamesFromAttachments = (files: IAttachment[]): string[] => {
    let attachmentFileNames: string[] = [];
    files.forEach(file => {
        attachmentFileNames.push(file.blobName);
    });

    return attachmentFileNames;
}

/**
 * Get blob file name from the full URL string.
 * @note input can be a string or an object with url property
 * @param input
 * @returns blob file name
 */
export const getBlobFileNameFromString = (input: any): string => {
    if (input?.url?.length > 0) {
        if (isValidURL(input.url)) {
            return input.url.split('/').pop();
        }
    }

    if (isValidURL(input, true)) {
        return input.split('/').pop();
    }
};

/**
 * Get the storage container name from a valid storage URL
 * @note input can be a string or an object with url property
 * @param input
 * @returns blob container name
 */
export const getBlobStorageContainerName = (input: any): string => {
    if (input?.url?.length > 0) {
        if (isValidURL(input.url)) {
            return getStorageContainerName(input?.url);
        }
    }

    if (isValidURL(input, true)) {
        return getStorageContainerName(input);
    }
};

/**
 * Replaces blob file URL(s) with a SaS URL(s).
 * @param input
 * @returns the input with replaced SaS URLs.
 */
export const addBlobFileSasToken = async (entity: string, containerName: string, input: string): Promise<string> => {
    const client = ApiRequestService.createInstance();

    if (input !== undefined || input !== '') {
        let urls = findStorageUrlsInString(input, true);

        for (let i = 0; i < urls?.length; i++) {
            let res;
            let container = getBlobStorageContainerName(urls[i]);

            if ( container !== undefined && container !== StringConstants.EMPTY_STRING) {
                if (urls[i]?.includes(BlobStorageHelper.SAS_TOKEN_IDENTIFIER)) {
                    // check if token is valid
                    if (blobTokenIsExpired(urls[i])) {
                        // if expired, regenerate the token
                        res = await client.generateFileSasToken(entity, container, getBlobFileNameFromString(urls[i]));
                    };
                }
                else {
                    res = await client.generateFileSasToken(entity, container, getBlobFileNameFromString(urls[i]));
                }

                if (res?.data) {
                    input = input.replace(urls[i], res.data);
                }

                if (container !== containerName) {
                    appInsightsService.logTrace(
                        `addBlobFileSasToken found a mismatched container name. provided: ${containerName} actual: ${container}`,
                        {'blob-storage-function': 'addBlobFileSasToken', 'blob-storage-error': BlobStorageError.CONTAINER_NAME_MISMATCH}
                    );
                }
            }
        }
    }

    return input;
}

/**
 * Replaces blob file SaS URL(s) with non SaS URL(s).
 * @param input
 * @returns the input with SaS tokens removed.
 */
 export const removeBlobFileSasToken = (input: string): string => {
    let urls = findStorageUrlsInString(input, true);
    for (let i = 0; i < urls.length; i++) {
        input = input.replace(urls[i], urls[i].split(BlobStorageHelper.SAS_TOKEN_IDENTIFIER)[0]);
    }

    return input;
}

/**
 * Add token to url in response after file is uploaded.
 * @param response
 * @returns `response` with URL updated with SaS tokens.
 */
export const addSasTokenToResponse = async (entity: string, containerName: string, response: any) => {
    for (let i = 0; i < response?.result?.length; i++) {
        await addBlobFileSasToken(entity, containerName, response.result[i].url)
        .then((res) => {
            response.result[i].url = res;
        });
    };

    return response;
};
