import React, { useEffect, useMemo, useState } from 'react';
import './FilterPanel.scss';
import { Text, Image, Link } from '@fluentui/react';
import RequestTypeFilter from './requestTypeFilter/RequestTypeFilter';
import ListActiveIcon from 'app/static/icons/list-view-active-icon.svg';
import ListIcon from 'app/static/icons/list-view-icon.svg';
import DetailsActiveIcon from 'app/static/icons/detail-view-active-icon.svg';
import DetailsIcon from 'app/static/icons/detail-view-icon.svg';
import { Trans } from 'react-i18next';
import CustomizeColumns from './draggableMenu/CustomizeColumns';
import { StatusFilter } from './statusFilter/StatusFilter';
import { IColumnLookUpProps, IDateRangeProps } from 'app/models/common/post';
import CountryFilter from './countryFilter/CountryFilter';
import { AgingFilter } from './agingFilter/AgingFilter';
import ProductsFilter from './productsFilter/ProductsFilter';
import { IUserProps } from 'app/models/common/response';
import AssignedToFilter from './assignedToFilter/AssignedToFilter';
import { IOptionProps } from '../models';
import _ from 'lodash';
import { RelationshipFilter } from './relationshipFilter/RelationshipFilter';
import { DCFfilter } from './DCFfilter/DCFfilter';
import { keyPress, setQueryParam } from 'app/components/helpers';
import { useHistory } from 'react-router-dom';
import useStore from 'app/store';
import { ConversationTranslationEventArgs } from 'microsoft-cognitiveservices-speech-sdk';

interface IToggleProps {
    isListView: boolean;
    requestTypeSelection: IOptionProps[];
    handleToggleView: Function;
    setRequestTypeSelection: Function;
    list: any;
    showMenu: boolean;
    columnsLookUpData: IColumnLookUpProps[];
    setList: Function;
    handleChecked: Function;
    handleShowMenu: Function;
    setColumnsLookUp: Function;
    statusSelection: IOptionProps[];
    setStatusSelection: Function;
    agingSelection: IOptionProps[];
    setAgingSelection: Function;
    productsSelection: IOptionProps[];
    countrySelection: IOptionProps[];
    setProductsSelection: Function;
    setCountrySelection: Function;
    assignedToSelection: IUserProps[];
    setAssignedToSelection: Function;
    relationshipSelection: IOptionProps[];
    setRelationshipSelection: Function;
    options: IUserProps[];
    setOptions: Function;
    userPreference?: any;
    dateRange?: IDateRangeProps;
    activeRange?: string;
    DCFSelection: IOptionProps[];
    setDCFSelection: Function;
    setPreferenceDataFlag: Function;
    stateData?: any;
    setListBuilderFilters?: Function;
    setPreviousLocation?: Function;
}
const FilterPanel: React.FC<IToggleProps> = ({
    isListView,
    handleToggleView,
    requestTypeSelection,
    setRequestTypeSelection,
    list,
    showMenu,
    columnsLookUpData,
    setList,
    handleChecked,
    handleShowMenu,
    setColumnsLookUp,
    statusSelection,
    setStatusSelection,
    agingSelection,
    setAgingSelection,
    productsSelection,
    countrySelection,
    setProductsSelection,
    setCountrySelection,
    assignedToSelection,
    setAssignedToSelection,
    relationshipSelection,
    setRelationshipSelection,
    options,
    userPreference,
    dateRange,
    activeRange,
    DCFSelection,
    setDCFSelection,
    setPreferenceDataFlag,
    stateData,
    setListBuilderFilters,
    setPreviousLocation,
}) => {
    const filterPanelDisabled = useStore((state: any) => state.filterPanelDisabled);

    const [assignedToActive, setAssignedToActive] = useState<boolean>(false);
    const [requestTypeActive, setRequestTypeActive] = useState<boolean>(false);
    const [statusActive, setStatusActive] = useState<boolean>(false);
    const [agingActive, setAgingActive] = useState<boolean>(false);
    const [productsActive, setProductsActive] = useState<boolean>(false);
    const [countryActive, setCountryActive] = useState<boolean>(false);
    const [relationshipActive, setRelationshipActive] = useState<boolean>(false);

    const [activeFilters, setActiveFilters] = useState<number>(0);

    const agingOptions = useMemo(() => {
        const options: IOptionProps[] = [
            { key: 'all', text: 'All' },
            {
                key: '0d-7d',
                text: '0d - 7d'
            },
            {
                key: '8d-30d',
                text: '8d - 30d'
            },
            {
                key: '31d-plus',
                text: '31d+'
            }
        ];
        return options;
    }, [dateRange, activeRange]);

    const showDCFfilter = () => {
        if (requestTypeSelection?.length === 0) return true;
        if (
            requestTypeSelection?.filter(
                (item) => item.key === 'pre_sales_request' || item.key === 'post_sales_request' || item.key === 'warranty_request'
            ).length > 0
        )
            return true;
        return false;
    };

    const history = useHistory();

    const clearFilter = () => {
        setDCFSelection([]);
        setRequestTypeSelection([]);
        setAgingSelection([]);
        setStatusSelection([]);
        setAssignedToSelection([]);
        setProductsSelection([]);
        setCountrySelection([]);
        setRelationshipSelection([]);
        setListBuilderFilters(null);
        setPreviousLocation('');
        let dataObj: any = {};
        dataObj.DCFSelection = [];
        dataObj.requestTypeSelection = [];
        dataObj.agingSelection = [];
        dataObj.statusSelection = [];
        dataObj.assignedToSelection = [];
        dataObj.productsSelection = [];
        dataObj.countrySelection = [];
        dataObj.relationshipSelection = [];
        setActiveFilters(0);
        setQueryParam(history, '/manage-requests', { page: 1 }, false, {
            ...stateData,
            ...dataObj
        });
    };
    useEffect(() => {
        if (userPreference) {
            let localFlag = {
                aging: false,
                assignee: false,
                caseStatus: false,
                requestType: false,
                deviceModel: false,
                countryModel: false,
                dcfRequested: false,
                relationship: false
            };

            try {
                const agingParsed = JSON.parse(userPreference.aging);
                if (agingParsed) {
                    let agingPreferenceValue = [];
                    for (const item of agingParsed) {
                        const matchedItem = _.find(agingOptions, (ele) => ele.key === item);
                        agingPreferenceValue.push(matchedItem);
                    }

                    setAgingSelection(agingPreferenceValue);
                    localFlag = { ...localFlag, aging: true };
                } else {
                    setAgingSelection([]);
                    localFlag = { ...localFlag, aging: true };
                }
            } catch (error) {
                setAgingSelection([]);
                localFlag = { ...localFlag, aging: true };
            }
            try {
                const assigneeParsed = userPreference.assignee ? JSON.parse(userPreference.assignee) : [];
                setAssignedToSelection(assigneeParsed);
                localFlag = { ...localFlag, assignee: true };
            } catch (error) {
                setAssignedToSelection([]);
                localFlag = { ...localFlag, assignee: true };
            }
            try {
                const caseStatusParsed = userPreference.caseStatus ? JSON.parse(userPreference.caseStatus) : [];
                setStatusSelection(caseStatusParsed);
                localFlag = { ...localFlag, caseStatus: true };
            } catch (error) {
                setStatusSelection([]);
                localFlag = { ...localFlag, caseStatus: true };
            }
            try {
                const requestTypeParsed = userPreference.requestType ? JSON.parse(userPreference.requestType) : [];
                setRequestTypeSelection(requestTypeParsed);
                localFlag = { ...localFlag, requestType: true };
            } catch (error) {
                setRequestTypeSelection([]);
                localFlag = { ...localFlag, requestType: true };
            }
            try {
                const deviceModelParsed = userPreference.deviceModel ? JSON.parse(userPreference.deviceModel) : [];
                setProductsSelection(deviceModelParsed);
                localFlag = { ...localFlag, deviceModel: true };
            } catch (error) {
                setProductsSelection([]);
                localFlag = { ...localFlag, deviceModel: true };
            }
            try {
                const countryModelParsed = userPreference.countryModel ? JSON.parse(userPreference.countryModel) : [];
                setCountrySelection(countryModelParsed);
                localFlag = { ...localFlag, countryModel: true };
            } catch (error) {
                setCountrySelection([]);
                localFlag = { ...localFlag, countryModel: true };
            }
            try {
                const dcfRequestedParsed = userPreference.dcfRequest ? JSON.parse(userPreference.dcfRequest) : [];
                setDCFSelection(dcfRequestedParsed);
                localFlag = { ...localFlag, dcfRequested: true };
            } catch (error) {
                setDCFSelection([]);
                localFlag = { ...localFlag, dcfRequested: true };
            }
            try {
                const relationshipParsed = userPreference.relationship ? JSON.parse(userPreference.relationship) : [];
                setRelationshipSelection(relationshipParsed);
                localFlag = { ...localFlag, relationship: true };
            } catch (error) {
                setRelationshipSelection([]);
                localFlag = { ...localFlag, relationship: true };
            }
            let count = 0;
            Object.entries(localFlag).forEach(([key, val]) => {
                if (val) {
                    count += 1;
                }
            });

            if (count === 8) {
                setPreferenceDataFlag(true);
            }
        }
    }, [userPreference, agingOptions]);

    useEffect(() => {
        if (filterPanelDisabled) {
            setDCFSelection([]);
            setRequestTypeSelection([]);
            setAgingSelection([]);
            setStatusSelection([]);
            setAssignedToSelection([]);
            setProductsSelection([]);
            setCountrySelection([]);
            setRelationshipSelection([]);
            setActiveFilters(0);
        }
    }, [filterPanelDisabled]);

    useEffect(() => {
        let activeCount = 0;

        if (assignedToActive) {
            activeCount++;
        }

        if (requestTypeActive) {
            activeCount++;
        }

        if (statusActive) {
            activeCount++;
        }

        if (agingActive) {
            activeCount++;
        }

        if (productsActive) {
            activeCount++
        }

        if (countryActive) {
            activeCount++;
        }

        if (relationshipActive) {
            activeCount++
        }

        setActiveFilters(activeCount);
    }, [assignedToActive, requestTypeActive, statusActive, agingActive, productsActive, countryActive, relationshipActive]);

    return (
        <>
            <div className="list-view-toggle row-flex-box justify-sb">
                <div className="row-flex-box align-center">
                    <Text className={`view-text ${isListView ? 'active' : ''}`}>
                        <Trans>List View</Trans>
                    </Text>
                    <div className="lv-toggle-wrap">
                        <div
                            className={`lv-toggle ${isListView ? 'handle-left' : ''}`}
                            tabIndex={0}
                            onClick={() => handleToggleView(true)}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                    handleToggleView(true);
                                }
                            }}
                            data-testid="toggle-button1">
                            {isListView && <Image src={ListActiveIcon} alt="Acitve" />}
                            {!isListView && <Image src={ListIcon} alt="Inacitve" />}
                        </div>
                        <div
                            className={`lv-toggle ${!isListView ? 'handle-right' : ''}`}
                            tabIndex={0}
                            onClick={() => handleToggleView(false)}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                    handleToggleView(false);
                                }
                            }}
                            data-testid="toggle-button2">
                            {!isListView && <Image src={DetailsActiveIcon} alt="Acitve" />}
                            {isListView && <Image src={DetailsIcon} alt="Inacitve" />}
                        </div>
                    </div>
                    <Text className={`view-text ${!isListView ? 'active' : ''}`}>
                        <Trans>Detail View</Trans>
                    </Text>
                </div>
                <div className="filter-panel row-flex-box align-center">
                    {/* {showDCFfilter() && (
                        <DCFfilter selectedKeys={DCFSelection} setSelectedKeys={setDCFSelection} stateData={stateData} />
                    )} */}
                    <RequestTypeFilter
                        selectedKeys={requestTypeSelection}
                        setSelectedKeys={setRequestTypeSelection}
                        origin={'ADMIN'}
                        stateData={stateData}
                        setFilterActive={setRequestTypeActive}
                    />
                    <AssignedToFilter
                        selectedKeys={assignedToSelection}
                        setSelectedKeys={setAssignedToSelection}
                        origin="FILTER_PANEL"
                        options={options}
                        stateData={stateData}
                        setFilterActive={setAssignedToActive}
                    />
                    <StatusFilter 
                        selectedKeys={statusSelection} 
                        setSelectedKeys={setStatusSelection} 
                        stateData={stateData} 
                        setFilterActive={setStatusActive}
                    />
                    <AgingFilter
                        selectedKeys={agingSelection}
                        setSelectedKeys={setAgingSelection}
                        options={agingOptions}
                        stateData={stateData}
                        setFilterActive={setAgingActive}
                    />
                    <ProductsFilter
                        isListView={isListView}
                        selectedKeys={productsSelection}
                        setSelectedKeys={setProductsSelection}
                        stateData={stateData}
                        setFilterActive={setProductsActive}
                    />
                    <CountryFilter
                        isListView={isListView}
                        selectedKeys={countrySelection}
                        setSelectedKeys={setCountrySelection}
                        stateData={stateData}
                        setFilterActive={setCountryActive}
                    />
                    <RelationshipFilter
                        selectedKeys={relationshipSelection}
                        setSelectedKeys={setRelationshipSelection}
                        stateData={stateData}
                        setFilterActive={setRelationshipActive}
                    />
                    {isListView && (
                        <CustomizeColumns
                            list={list}
                            setList={setList}
                            handleChecked={handleChecked}
                            showMenu={showMenu}
                            handleShowMenu={handleShowMenu}
                            columnsLookUpData={columnsLookUpData}
                            setColumnsLookUp={setColumnsLookUp}
                        />
                    )}
                    <div className="clear-btn-container">
                        <Link
                            data-testid="clear-btn-test"
                            className={`clear-filter-btn ${filterPanelDisabled ? 'filter-disabled' : ''}`}
                            tabIndex={0}
                            onKeyPress={(e: any) => keyPress(e, clearFilter)}
                            onClick={() => clearFilter()}
                            disabled={filterPanelDisabled}>
                            Clear filters
                        </Link>
                        {activeFilters > 0 && <Text className="filter-active-count" block>{activeFilters}</Text>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterPanel;
