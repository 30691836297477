export enum CommentAction {
    Create = 1,
    Tag,
    Reply,
    Delete,
    Emote,
    EmoteLike,
    EmoteLove,
    EmoteLaugh,
    EmoteShock,
    EmoteAngry
}
