import React from 'react';
import { TextField, Text, Link, TooltipHost, Image } from '@fluentui/react';
import NumberFormat from 'react-number-format';
import { Trans } from 'react-i18next';
import { IOptionProps } from '../../../admin/manageRequests/models';
import { useId } from '@fluentui/react-hooks';
import InfoIcon from '../../../../../app/static/icons/tooltip-info-icon.svg';
import './NewCustomer.scss';
import { SearchCustomer } from '../searchCustomer/SearchCustomer';
import { CustomerPopUpModal } from './customerPopUpModal/CustomerPopUpModal';
import { StringConstants } from 'app/utils/constants';
interface IProps {
    setToggleSearch?: Function;
    toggleSearch?: boolean;
    status?: string;
    setStatus?: Function;
    tpid?: string;
    setTPID?: any;
    tpidHText: any;
    setTPIDHText: any;
    autoSuggestHText: any;
    setAutoSuggestHText: any;
    accountID?: string;
    setAccountID?: any;
    setAccountIDHText: any;
    accountIDHText: any;
    setFilteredData: any;
    custName?: string;
    setCustName?: any;
    setCustNameHText: any;
    custNameHText: boolean;
    customerSelected: boolean;
    setCustomerSelected: Function;
    setSearchNotFound?: Function;
    setCustomerInputType?: Function;
    searchNotFound?: boolean;
    country: string;
    setCountry: any;
    setCountryHText: any;
    selectedRequestType: string;
    setSelectedRequestType: any;
    countryHText: any;
    revenueRisk: string;
    setRevenueRisk: any;
    revenueHText: any;
    revenueValidText: any;
    setRevenueHText: any;
    customerImpactDescription?: any;
    setCustomerImpactDescription?: any;
    impactsHText?: any;
    setImpactsHText?: any;
    customerSearchInput: string;
    setCustomerSearchInput: Function;
    disabled: boolean;
    setDisabled: Function;
    newCustomerOptions?: any;
    impDevices: string;
    setImpDevices: any;
    setImpDevicesHText: any;
    impDevicesHText: any;
    impDevicesValidText: any;
    setManualData?: Function;
    setRequestSection?: Function;
    animateNewCustomer?: string;
    requestType?: string;
    cssCaseNum?: string;
    setCSSCaseNum?: Function;
    setCaseNumHText?: Function;
    caseNumHText?: boolean;
    countryList?: Array<any>;
    requestOptionList?: Array<any>;
    options?: IOptionProps[];
    setOriginalSelected?: any;
    originalSelected?: any;
    lastSelectedCustomerName?: any;
    setLastSelectedCustomerName?: Function;
}

const NewCustomer: React.FC<IProps> = (props) => {
    const {
        toggleSearch,
        setToggleSearch,
        status,
        setStatus,
        setLastSelectedCustomerName,
        setCustomerInputType,
        lastSelectedCustomerName,
        setFilteredData,
        customerSelected,
        setCustomerSelected,
        customerSearchInput,
        setCustomerSearchInput,
        setAutoSuggestHText,
        custName,
        setCustName,
        autoSuggestHText,
        custNameHText,
        setCustNameHText,
        country,
        setCountry,
        setCountryHText,
        countryHText,
        revenueRisk,
        setRevenueRisk,
        revenueHText,
        revenueValidText,
        setRevenueHText,
        searchNotFound,
        setSearchNotFound,
        customerImpactDescription,
        setCustomerImpactDescription,
        impactsHText,
        setImpactsHText,
        setOriginalSelected,
        setDisabled,
        disabled,
        impDevices,
        setImpDevices,
        setImpDevicesHText,
        impDevicesHText,
        impDevicesValidText,
        setManualData,
        setRequestSection,
        animateNewCustomer,
        requestType,
        cssCaseNum,
        setCSSCaseNum,
        setCaseNumHText,
        caseNumHText,
        countryList
    } = props;

    const tooltipId = useId('tooltip');

    const calloutProps = { gapSpace: 0 };

    const prepareOptions = () => {
        let options: Array<any> = [
            <option key="default-key" value="DEFAULT">
                Select the country
            </option>
        ];
        if (countryList) {
            countryList.forEach((item) => {
                options.push(
                    <option key={item.id} value={item.name}>
                        {item.name}
                    </option>
                );
            });
        }
        return options;
    };

    const { CustomerModal, showModal } = CustomerPopUpModal();

    const handleCancelClick = () => {
        setToggleSearch(true);
        setCustomerSearchInput('');
    };

    return (
        <div
            className={`cant-find-customer new-customer-cont ${animateNewCustomer === 'S_TO_NEXT' ? 'nextSlide' : ''} ${
                animateNewCustomer === 'S_TO_PREV' ? 'prevSlide' : ''
            } ${animateNewCustomer === 'A_TO_RIGHT' ? 'animateToRight' : ''} ${
                animateNewCustomer === 'A_TO_LEFT' ? 'animateToLeft' : ''
            }`}>
            {requestType === StringConstants.REVIEW_REQUEST && (
                <div>
                    <div className="m-b-40 cssContainerStyles">
                        <TextField
                            label="CSS Case Number"
                            value={cssCaseNum}
                            onChange={(e: any) => {
                                setCSSCaseNum(e.target.value);
                                setCaseNumHText(false);
                            }}
                            className={`regular-text-field css-case-num-field ${caseNumHText ? 'error-feild' : ''}`}
                            placeholder="eg: 120072024000000"
                            data-testid="text1-test"
                        />
                    </div>
                    {caseNumHText && (
                        <div>
                            <Text className="helper-txt error-feild">Please enter a CSS case number.</Text>
                        </div>
                    )}
                </div>
            )}
            <div>
                <div className="customerStyles">
                    <div className="customer-request-type">
                        <label>
                            <Trans>Customer Information</Trans>
                        </label>
                    </div>
                    <div className="requestContainer">
                        <div className="parentClassOverlay">
                            <div className="searchCust">
                                <SearchCustomer
                                    showModal={showModal}
                                    setCustName={setCustName}
                                    status={status}
                                    setStatus={setStatus}
                                    custName={custName}
                                    lastSelectedCustomerName={lastSelectedCustomerName}
                                    setLastSelectedCustomerName={setLastSelectedCustomerName}
                                    customerSelected={customerSelected}
                                    setCustomerInputType={setCustomerInputType}
                                    toggleSearch={toggleSearch}
                                    setToggleSearch={setToggleSearch}
                                    setCountry={setCountry}
                                    setOriginalSelected={setOriginalSelected}
                                    setCustomerSelected={setCustomerSelected}
                                    searchValue={customerSearchInput}
                                    setSearchValue={setCustomerSearchInput}
                                    setSuggestionSelected={setCustomerSelected}
                                    setFilteredData={setFilteredData}
                                    setRevenueRisk={setRevenueRisk}
                                    setImpDevices={setImpDevices}
                                    setRevenueHText={setRevenueHText}
                                    setImpDevicesHText={setImpDevicesHText}
                                    autoSuggestHText={autoSuggestHText}
                                    setAutoSuggestHText={setAutoSuggestHText}
                                    setSearchNotFound={setSearchNotFound}
                                    searchNotFound={searchNotFound}
                                    origin="REQUEST_EXPERIENCE"
                                    disabled={disabled}
                                    setDisabled={setDisabled}
                                    setCustNameHText={setCustNameHText}
                                    custNameHText={custNameHText}
                                />
                                {autoSuggestHText && (
                                    <Text className="helper-txt">
                                        <Trans>Please search for a Customer by name or MSX Account Id</Trans>
                                    </Text>
                                )}
                            </div>
                        </div>
                        <div>
                            <CustomerModal
                                setCountry={setCountry}
                                setCustomerInputType={setCustomerInputType}
                                setCustomerSearchInput={setCustomerSearchInput}
                                setToggleSearch={setToggleSearch}
                                setCustNameHText={setCustNameHText}
                                searchNotFound={searchNotFound}
                                handleCancelClick={() => handleCancelClick}
                                setSearchNotFound={setSearchNotFound}
                                custNameHText={custNameHText}
                                custName={custName}
                                setCustName={setCustName}
                                message="It looks like your customer isn't in MSX. Do you want to type their name in?"
                            />
                        </div>
                    </div>
                </div>
                <div className={`mb-40 countryMargins ${toggleSearch ? '' : 'disable'}`}>
                    <label>
                        <Trans>Country</Trans>
                    </label>
                    <div>
                        <select
                            value={country}
                            onChange={(e: any) => {
                                setCountry(e.target.value);
                                setCountryHText(false);
                            }}
                            aria-label="Enter customer country"
                            className={`country-selection format-field-cont ${countryHText ? 'error-feild' : ''} `}>
                            {prepareOptions()}
                            <option value="DEFAULT" disabled></option>
                        </select>
                    </div>

                    {countryHText && (
                        <Text className="helper-txt">
                            <Trans>Please enter the country to which the customer belongs.</Trans>
                        </Text>
                    )}
                </div>
            </div>

            <div className={`row-flex p-t-20 deviceAndRevenueContainer`}>
                <div className="shift-lt mb-40">
                    <label>
                        <Trans>Sales Opportunity Revenue Risk</Trans>
                    </label>
                    <div
                        className={`format-field-cont ${revenueHText ? 'error-feild' : ''} ${
                            revenueValidText ? 'error-feild' : ''
                        }`}>
                        <NumberFormat
                            thousandSeparator={true}
                            value={revenueRisk}
                            onChange={(e: any) => {
                                setRevenueHText(false);
                                setRevenueRisk(e.target.value);
                            }}
                            placeholder="eg: $167,700"
                            allowNegative={false}
                            prefix={'$'}
                            className={`format-field regular-text-field ${revenueHText ? 'error-feild' : ''}  ${
                                revenueValidText ? 'error-feild' : ''
                            }`}
                            inputMode="numeric"
                            aria-label="Enter revenue risk"
                            decimalScale={2}
                        />
                    </div>
                    <div className="info-msg">
                        <Text>
                            Learn more about{' '}
                            <Text
                                aria-label="Enter future device quantities that may be at risk"
                                tabIndex={0}
                                className="info-text">
                                {' '}
                                Sales opportunity revenue risk
                            </Text>
                            <TooltipHost
                                id={tooltipId}
                                calloutProps={calloutProps}
                                content={'Enter future device quantities that may be at risk.'}>
                                <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                            </TooltipHost>
                        </Text>
                    </div>
                    {revenueHText && <Text className="helper-txt">Please enter the revenue risk involved.</Text>}
                    {revenueValidText && <Text className="helper-txt">Revenue risk entered is not valid.</Text>}
                </div>
                <div className="shift-rt mb-40">
                    <label>
                        <Trans>Sales Opportunity Devices Risk</Trans>
                    </label>
                    <div
                        className={`format-field-cont ${impDevicesHText ? 'error-feild' : ''}  ${
                            impDevicesValidText ? 'error-feild' : ''
                        }`}>
                        <NumberFormat
                            thousandSeparator={true}
                            value={impDevices}
                            onChange={(e: any) => {
                                setImpDevicesHText(false);
                                setImpDevices(e.target.value);
                            }}
                            placeholder="eg: 60,700"
                            allowNegative={false}
                            className={`format-field regular-text-field `}
                            inputMode="numeric"
                            aria-label="Enter device risk"
                            decimalScale={0}
                        />
                    </div>
                    <div className="info-msg">
                        <Text>
                            Learn more about{' '}
                            <Text
                                aria-label="Enter future device quantities that may be at risk"
                                tabIndex={0}
                                className="info-text">
                                Sales opportunity device risk
                            </Text>{' '}
                            <TooltipHost
                                id={tooltipId}
                                calloutProps={calloutProps}
                                content={'Enter future device quantities that may be at risk.'}>
                                <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                            </TooltipHost>
                        </Text>
                    </div>
                    {impDevicesHText && (
                        <Text className="helper-txt">
                            <Trans>Please enter the device risk involved.</Trans>
                        </Text>
                    )}{' '}
                    {impDevicesValidText && (
                        <Text className="helper-txt" aria-labelledby="Please enter the device risk involved">
                            <Trans>Device count entered is not valid.</Trans>
                        </Text>
                    )}
                </div>
            </div>
            <div className="customerImpactStyles">
                <label className="labelStyles">Please tell us how this impacts your customer</label>
                <TextField
                    className={`cust-impact ${impactsHText ? 'error-feild' : ''} customer-impact`}
                    multiline={true}
                    ariaLabel='Enter how this impacts your customer'
                    onChange={(e: any) => {
                        setCustomerImpactDescription(e.target.value);
                        setImpactsHText(false);
                    }}
                    value={customerImpactDescription}
                />
                {impactsHText && (
                    <Text className="helper-txt">
                        <Trans>Please tell us how this impacts your customer.</Trans>
                    </Text>
                )}
            </div>
        </div>
    );
};

export default NewCustomer;
