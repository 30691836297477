import React from 'react';
import { Text, TextField, TooltipHost, Image } from '@fluentui/react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import './index.scss';
import InfoIcon from 'app/static/icons/tooltip-info-icon.svg';
import { useId } from '@fluentui/react-hooks';
interface IProps {
    selectedDCFKey: any;
    onBtnChange: any;
    fundsDescription: string;
    setAction: Function;
    fundHText: Boolean;
    dcfBtnHText: Boolean;

    animateDCFAction: string;
}
const options: IChoiceGroupOption[] = [
    { key: 'A', text: 'Yes' },
    { key: 'B', text: 'No' }
];

const Action: React.FC<IProps> = ({
    selectedDCFKey,
    onBtnChange,
    fundsDescription,
    setAction,
    fundHText,
    dcfBtnHText,

    animateDCFAction
}) => {
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    return (
        <div
            className={`cant-find-customer ${animateDCFAction === 'S_TO_NEXT' ? 'nextSlide' : ''} ${
                animateDCFAction === 'S_TO_PREV' ? 'prevSlide' : ''
            } ${animateDCFAction === 'A_TO_RIGHT' ? 'animateToRight' : ''} ${
                animateDCFAction === 'A_TO_LEFT' ? 'animateToLeft' : ''
            }`}>
            <div className={`m-t-40`}>
                <div className="m-b-40">
                    <div className="dcf-label">
                        <label>Do you require Discretionary Commercial Funds (DCF)?</label>{' '}
                        <TooltipHost
                            id={tooltipId}
                            calloutProps={calloutProps}
                            content="Clarify if you need action on a warranty request or need to allocate funds to make a device swap that is above and beyond what is typically expected.">
                            <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                        </TooltipHost>
                    </div>
                    <ChoiceGroup selectedKey={selectedDCFKey} options={options} onChange={onBtnChange} className="choiceBtn" />
                    {dcfBtnHText && <Text className="helper-txt">Please choose one of the above.</Text>}
                </div>
                {selectedDCFKey === 'A' && (
                    <div className="shift-rt mb-40">
                        <label>How will you utilize these funds?</label>
                        <TextField
                            value={fundsDescription}
                            onChange={(e: any) => {
                                setAction(e.target.value);
                            }}
                            multiline
                            autoAdjustHeight
                            resizable={false}
                            rows={8}
                            placeholder="In as much detail as possible, describe what the Discretionary Commercial Funds will be used for and the expected outcome with this customer."
                            className={`desc ${fundHText ? 'error-feild' : ''}`}
                        />
                        {fundHText && <Text className="helper-txt">Please share your thoughts on utilizing the funds.</Text>}
                    </div>
                )}
            </div>
        </div>
    );
};
export default Action;
