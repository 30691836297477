import React, { useEffect, useState } from 'react';
import { useGetArticle, useGetArticlesByType } from 'app/services/queries';
import { usePostArticleViewed } from 'app/services/mutations';
import ArticleContent from 'app/modules/articles/viewArticle/articleDetail/articleContent/ArticleContent';
import { Loader } from 'app/components/loader/Loader';
import { IArticleData } from 'app/models/common/post';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { separateVideosFromHTMLString } from 'app/components/helpers';
import { ArticleStatus } from 'app/enums/Status.enum';
import TrainingVideosList from './trainingVideosList/TrainingVideosList';
import './TrainingPortal.scss';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router';
import NoData from 'app/components/noData/NoData';
import { RouteConstants } from 'app/utils/constants';

const TrainingPortal: React.FC<{}> = () => {
    const location: { state?: string } = useLocation();
    const { mutateAsync: postArticleViewedAPI } = usePostArticleViewed();
    const [trainingContent, setTrainingContent] = useState('');
    const [trainingVideo, setTrainingVideo] = useState('');
    const [articleInfo, setArticleInfo] = useState<IArticleData>();
    const history = useHistory();

    const [selectedTrainingArticleID, setSelectedTrainingArticleID] = useState(location?.state ? location?.state : '');

    const {
        isFetched,
        isLoading,
        data: articles
    } = useGetArticlesByType(1, 9999, 'ModifiedOn', '1', null, ArticleStatus.Published, ArticleType.Training);

    const {
        data: selectedArticle,
        isLoading: isLoadingArticle,
        isFetched: isFetchedArticle
    } = useGetArticle(selectedTrainingArticleID);

    useEffect(() => {
        const article = articles?.items?.filter((e: any) => e.showOnHomePage)[0];
        setSelectedTrainingArticleID(selectedTrainingArticleID ? selectedTrainingArticleID : article?.id);
    }, [isFetched, articles, postArticleViewedAPI]);

    useEffect(() => {
        if (selectedTrainingArticleID && selectedArticle) {
            setArticleInfo(selectedArticle);
            postArticleViewedAPI(selectedTrainingArticleID);
        }
    }, [selectedTrainingArticleID, selectedArticle, isLoadingArticle, isFetchedArticle]);

    useEffect(() => {
        if (isFetchedArticle && ((articleInfo as IArticleData)?.type === ArticleType.Training || (articleInfo as IArticleData)?.type === ArticleType.Admin)) {
            setTrainingContent(separateVideosFromHTMLString((articleInfo as IArticleData).content).content);
            setTrainingVideo(separateVideosFromHTMLString((articleInfo as IArticleData).content).videos[0]);
        }
    }, [isFetchedArticle, articleInfo]);

    const openTrainingVideo = (id: string) => {
        setSelectedTrainingArticleID(id);
    };
    const itemsWithHref: IBreadcrumbItem[] = [
        { text: 'Home', key: 'home', onClick: () => history.push(RouteConstants.KNOWLEDGE_PORTAL) },
        {
            text: 'Portal Training',
            key: 'Portal Training',
            isCurrentItem: true
        }
    ];
    if (!isLoadingArticle && !isLoading && isFetchedArticle && isFetched) {
        return (
            <>
                {articles?.totalCount > 0 ? (
                    <div className="training-articles">
                        <div className="container">
                            <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} />
                        </div>
                        <div className="container m-t-10">
                            <div className="training-article-details">
                                <ArticleContent
                                    content={trainingContent}
                                    views={articleInfo?.numberOfViews}
                                    isTraining={true}
                                    trainingVideo={trainingVideo}
                                    title={articleInfo?.title}
                                />
                            </div>
                            <div className="training-article-sidebar">
                                {articles?.items?.filter((e: any) => e.showOnHomePage)?.length > 0 && (
                                    <TrainingVideosList
                                        openThisTraining={openTrainingVideo}
                                        title="Must see"
                                        data={articles.items.filter((e: any) => e.showOnHomePage)}
                                        activeTrainingId={selectedTrainingArticleID}
                                    />
                                )}
                                {articles?.items?.filter((e: any) => !e.showOnHomePage)?.length > 0 && (
                                    <TrainingVideosList
                                        openThisTraining={openTrainingVideo}
                                        title="More videos"
                                        data={articles.items.filter((e: any) => !e.showOnHomePage)}
                                        activeTrainingId={selectedTrainingArticleID}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <NoData />
                )}
            </>
        );
    } else {
        return <Loader classNames="m-t-20 m-b-50 height-70" />;
    }
};

export default TrainingPortal;
