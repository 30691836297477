import React, { useState, useEffect } from 'react';
import Button from 'app/components/button/Button';
import { TextField, Modal, Text } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import './Tags.scss';
import AddTags from './AddTags/AddTags';
import DeleteTag from './DeleteTag/DeleteTag';
import { Loader } from 'app/components/loader/Loader';

import { useGetTagsList } from 'app/services/queries';

const Tags: React.FC = () => {
    const [inputSearch, setInputSearch] = useState('');

    const onChange = (event: any) => {
        setInputSearch(event.target.value);

        if (event.target.value) {
            let filterTag = tagsData.filter((item: any) => {
                if (item.name.toUpperCase().includes(event.target.value.toUpperCase())) {
                    return true;
                } else {
                    return false;
                }
            });

            setTagsList(filterTag);
        } else {
            setTagsList(tagsData);
        }
    };
    const iconProps = { iconName: 'Search' };

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const [isModalDeleteOpen, { setTrue: showDeleteModal, setFalse: hideDeleteModal }] = useBoolean(false);
    const titleId = useId('title');

    const [tagsList, setTagsList] = useState([]);

    const [deleteTag, setDeleteTag] = useState('');

    // queries
    const { data: tagsData, isLoading: isTagsLoading } = useGetTagsList();

    useEffect(() => {
        if (!isTagsLoading) {
            setTagsList(tagsData);
        }
    }, [isTagsLoading, tagsData]);

    return (
        <div className="admin-tags-container">
            <div className="admin-tags-head">
                <h2 className="page-head">Existing Tags -</h2>
                <div>
                    <div className="search-news-container d-flex m-r-10">
                        <TextField
                            value={inputSearch}
                            iconProps={iconProps}
                            placeholder="Tags Search"
                            onChange={(event) => onChange(event)}
                            className={`search-news-input`}
                            data-testid="text1-test"
                        />
                    </div>

                    <Button
                        buttonText="Add new tag"
                        buttonClass="btn btn--sm btn--primary"
                        iconClass="ms-Icon ms-Icon--Add"
                        onClick={() => {
                            showModal();
                        }}                        
                    />

                    {hideModal && (
                        <Modal
                            layerProps={{ eventBubblingEnabled: true }}
                            titleAriaId={titleId}
                            isOpen={isModalOpen}
                            onDismiss={hideModal}
                            isBlocking={false}
                            className="tags-impact-model">
                            <AddTags onDismiss={hideModal} />
                        </Modal>
                    )}
                </div>
            </div>

            {isTagsLoading && <Loader classNames="m-t-20 m-b-50 height-70" />}

            {!isTagsLoading && (
                <div>
                    {tagsList.map((item) => (
                        <span className="tag-list">
                            {item.name}{' '}
                            <i
                                className="cross-icon"
                                onClick={() => {
                                    showDeleteModal();
                                    setDeleteTag(item.id);
                                }}                               
                                ></i>
                        </span>
                    ))}

                    {tagsList.length === 0 && <Text>No result to display</Text>}
                </div>
            )}

            {hideDeleteModal && (
                <Modal
                    layerProps={{ eventBubblingEnabled: true }}
                    titleAriaId={titleId}
                    isOpen={isModalDeleteOpen}
                    onDismiss={hideDeleteModal}
                    isBlocking={false}
                    className="tags-impact-model">
                    <DeleteTag onDismiss={hideDeleteModal} deleteTag={deleteTag} />
                </Modal>
            )}
        </div>
    );
};

export default Tags;
