import Button from 'app/components/button/Button';
import React, { useEffect, useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { TextField } from '@fluentui/react';
import './UnsubscribeView.scss';
import { ArticleAction } from 'app/enums/ArticleAction.enum';
import { useKnowledgeArticleUnSubscription, useNewsUnSubscription, useUnsubscribeFeedback } from 'app/services/mutations';
import { IUnsubscribeFeedback } from 'app/models/common/post';
import { useHistory } from 'react-router';

interface IProps {
    entityType: string;
    eventType: string;
}

const UnsubscribeView: React.FC<IProps> = (props: IProps) => {
    const { eventType, entityType } = props;
    const history = useHistory();

    const [reason, setReason] = useState<string>();
    const [selection, setSelection] = useState<IChoiceGroupOption>();

    const { mutateAsync: unsubscribeFeedback } = useUnsubscribeFeedback();
    const { mutateAsync: knowledgeArticleUnSubscription } = useKnowledgeArticleUnSubscription();
    const { mutateAsync: newsUnSubscription } = useNewsUnSubscription();

    const handleChange = (type: 'Selection' | 'Reason', value: any) => {
        switch (type) {
            case 'Selection':
                setSelection(value);
                setReason(null);
                break;
            case 'Reason':
                setReason(value);
                break;
        }
    };

    const options: IChoiceGroupOption[] = [
        {
            key: '1',
            text: 'I no longer want to receive these emails',
            styles: { root: { marginBottom: '20px' } }
        },
        { key: '2', text: 'I never signed up for the mailing list', styles: { root: { marginBottom: '20px' } } },
        { key: 'Resubscribe', text: 'I unsubscribed by mistake, Please add me back', styles: { root: { marginBottom: '20px' } } },
        {
            key: 'OtherReason',
            text: 'Other (fill in the reason below)',
            styles: { root: { marginBottom: '20px' } },
            onRenderField: (props, render) => {
                return (
                    <>
                        {render!(props)}
                        {props?.checked && (
                            <TextField
                                className="m-t-20"
                                value={reason}
                                placeholder="Please enter the reason"
                                onChange={(e) => handleChange('Reason', (e.target as HTMLInputElement).value)}
                            />
                        )}
                    </>
                );
            }
        }
    ];

    const getUnsubscribedType = () => {
        switch (Number(eventType)) {
            case ArticleAction.UnsubscribeKnowledgeCenter:
                return 'knowledge article';
            case ArticleAction.UnsubscribeNewsletter:
                return 'newsfeed';
            default:
                break;
        }
    };

    const onSubmit = () => {
        if (selection?.key) {
            let data: IUnsubscribeFeedback = {
                entityType: Number(entityType),
                parentEntityType: null,
                reason: selection.key === 'OtherReason' ? `Other - ${reason}` : selection.text,
                eventType: Number(eventType),
                shouldResubscribe: selection.key === 'Resubscribe'
            };
            unsubscribeFeedback(data);
        }
        history.push('/');
    };

    const onReset = () => {
        setReason(null);
        setSelection(null);
    };

    useEffect(() => {
        switch (Number(eventType)) {
            case ArticleAction.UnsubscribeKnowledgeCenter:
                knowledgeArticleUnSubscription();
                break;
            case ArticleAction.UnsubscribeNewsletter:
                newsUnSubscription();
                break;
            default:
                break;
        }
    }, [eventType]);

    return (
        <div className="unsubscribe">
            <div className="d-flex--col">
                <h3 className="m-b-0">Unsubscribed Successfully</h3>
                <p>{`You will no longer receive the ${getUnsubscribedType()} notifications`}</p>
            </div>
            <div className="d-flex--col">
                <h4 className="m-b-10">If you have a moment, please let us know why you unsubscribed:</h4>
                <ChoiceGroup
                    options={options}
                    onChange={(e, option) => handleChange('Selection', option)}
                    selectedKey={selection ? selection.key : null}
                    className="m-b-20"
                />
                <div>
                    <Button buttonText="Reset" buttonClass="btn btn--secondary" onClick={onReset} />
                    <Button buttonText="Submit" buttonClass="btn btn--primary" onClick={onSubmit} />
                </div>
            </div>
        </div>
    );
};

export default UnsubscribeView;
