import { TextField, Text, Icon, Link, Shimmer, ShimmerElementType } from '@fluentui/react';
import React, { useState } from 'react';
import { useGetUsersList } from 'app/services/queries';
import './PeoplePicker.scss';
import { ISearchUser } from 'app/models/common/response';
import { isValidEmail, keyPress } from '../helpers';

interface IProps {
    isSingleSelect: boolean;
    selectedPeople: ISearchUser[];
    setSelectedPeople: Function;
    isDomainSpecific: boolean;
    dropdownPosition?: 'top' | 'bottom';
    type?: 'Admin' | null;
    team?: number;
    disabled?: boolean;
    allowAnyMailbox?: boolean;
    classNames?: string;
}

export const PeoplePicker: React.FC<IProps> = ({
    selectedPeople,
    setSelectedPeople,
    isSingleSelect,
    isDomainSpecific,
    allowAnyMailbox = false,
    type,
    team,
    disabled,
    classNames,
    dropdownPosition = 'bottom'
}) => {
    //useState Variables

    const [searchValue, setSearchValue] = useState('');
    const peoplePickerRef = React.useRef(null);
    const searchBoxRef = React.useRef(null);

    const count = 45;

    //get searched Users query
    const { data, isLoading } = useGetUsersList(searchValue.trim(), isDomainSpecific, type, team, count);

    //custom functions
    const handleSearch = (event: any) => {
        setSearchValue(event.target.value);
    };

    const handleSelectPerson = (item: ISearchUser) => {
        setSearchValue('');

        if (selectedPeople.indexOf(item) === -1) {
            if (isSingleSelect) {
                setSelectedPeople([item]);
            } else {
                setSelectedPeople([...selectedPeople, item]);
            }
        }
        searchBoxRef.current.focus();
    };

    const handleRemovePerson = (item: ISearchUser) => {
        const i = selectedPeople.indexOf(item);
        let arr = [...selectedPeople];
        arr.splice(i, 1);
        setSelectedPeople(arr);
    };

    //suggestion container dismiss function
    useSuggestionBoxDismiss(peoplePickerRef, setSearchValue);

    //loader shimmer element
    const shimmerElement = [
        { type: ShimmerElementType.circle },
        { type: ShimmerElementType.gap, width: '2%' },
        { type: ShimmerElementType.line }
    ];

    const buildUnknownUserObject = () => {
        return {
            displayName: searchValue,
            mail: searchValue,
            id: ''
        } as ISearchUser;
    };
    return (
        <div className={`people-picker-cont ${dropdownPosition} ${classNames}`} ref={peoplePickerRef}>
            <div className="picker-combo-box">
                {selectedPeople?.map((item) => {
                    return (
                        <div className="selected-item " key={item.id}>
                            <Text className="tag-name p-r-10">{item.displayName}</Text>
                            <Link role="link" tabIndex={0} onClick={() => handleRemovePerson(item)}>
                                <div className="close-button">
                                    <Icon iconName="Cancel" />
                                </div>
                            </Link>
                        </div>
                    );
                })}
                <TextField
                    data-testid="people-picker-field-test"
                    componentRef={searchBoxRef}
                    name="combo-box-field"
                    className="people-picker-field"
                    value={searchValue}
                    onChange={handleSearch}
                    disabled={disabled}
                />
            </div>
            {searchValue.trim().length > 1 && (
                <div className="suggestion-container">
                    {isLoading ? (
                        <div className="p-a-20">
                            <Shimmer className="p-b-5" shimmerElements={shimmerElement} />
                            <Shimmer className="p-b-5" shimmerElements={shimmerElement} />
                            <Shimmer shimmerElements={shimmerElement} />
                        </div>
                    ) : data?.length === 0 ? (
                        allowAnyMailbox && isValidEmail(searchValue) ? (
                            <div
                                data-testid="valid-email-test"
                                tabIndex={0}
                                onClick={() => handleSelectPerson(buildUnknownUserObject())}
                                onKeyPress={(e) => keyPress(e, () => handleSelectPerson(buildUnknownUserObject()))}
                                className="suggestion-item p-t-5 p-b-5 p-l-10">
                                <div className="suggestion-item-name">{searchValue}</div>
                                <div className="suggestion-item-email">{searchValue}</div>
                            </div>
                        ) : (
                            <div className="p-a-20">
                                <Text>Sorry, we could not find anything that matches your search :(</Text>
                            </div>
                        )
                    ) : data?.filter((user: any) => !selectedPeople.find((selectedUser) => user.id === selectedUser.id))
                          .length === 0 ? (
                        <div className="p-a-20">
                            <Text>You have already added this user.</Text>
                        </div>
                    ) : (
                        data
                            ?.filter((user: any) => !selectedPeople.find((selectedUser) => user.id === selectedUser.id))
                            ?.map((item: ISearchUser) => {
                                return (
                                    <div
                                        tabIndex={0}
                                        onClick={() => handleSelectPerson(item)}
                                        onKeyPress={(e) => keyPress(e, () => handleSelectPerson(item))}
                                        key={item?.id}
                                        className="suggestion-item p-t-5 p-b-5 p-l-10">
                                        <div className="suggestion-item-name">{item?.displayName}</div>
                                        <div className="suggestion-item-email">{item?.mail}</div>
                                    </div>
                                );
                            })
                    )}
                </div>
            )}
        </div>
    );
};
const useSuggestionBoxDismiss = (ref: any, setSearchValue: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setSearchValue('');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setSearchValue, ref]);
};
