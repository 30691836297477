import { Image, Text, TextField } from '@fluentui/react';
import { keyPress } from 'app/components/helpers';
import { useQueryAllDeviceList } from 'app/services/queries';
import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';

import { getDeviceIcon } from 'app/utils/SVGIcons';

interface IDeviceProps {
    deviceSelected: any;
    modelSelected: any;
    selectedProductId: any;
    modelsList: any;
    selectedModel: any;
    addToCart: any;
    onCountChange: any;
    cartHText: boolean;
}

const SelectDevice: React.FC<IDeviceProps> = (props) => {
    const { t: translate } = useTranslation();
    //props variables declared
    const { deviceSelected, modelSelected, selectedProductId, modelsList, selectedModel, addToCart, onCountChange } = props;
    const myRef = useRef(null);
    const modelListRef = useRef(null);
    //calling data from services
    const { isLoading: isDeviceListLoaded, data: getDeviceList } = useQueryAllDeviceList();

    const [, setNoScrollbar] = React.useState(false);

    useEffect(() => {
        if (selectedProductId && modelListRef.current) {
            var hasHorizontalScrollbar = modelListRef.current.scrollWidth > modelListRef.current.clientWidth;
            setNoScrollbar(hasHorizontalScrollbar);
        }
    }, [modelsList]);

    function isAdded(item: any) {
        return selectedModel?.filter((ele: any) => ele.id === item.id).length !== 0;
    }

    if (isDeviceListLoaded) {
        return (
            <div className="devices-loader" data-testid="loader-test">
                <div className="loader"></div>
            </div>
        );
    } else {
        return (
            <div>
                <label className="device-title" >
                    <Trans>Select the troubled product family</Trans>
                </label>
                <div>
                    <div className="products-list" ref={myRef}>
                        {getDeviceList &&
                            getDeviceList.map((item: any, index: any) => {
                                return (
                                    <div
                                        aria-label={`${index === 0 ? "Select the troubled product family" : ""} ${selectedProductId === item.id ? "Selected Product" : ""} ${item.name}`}
                                        key={item.id}
                                        id={index}
                                        tabIndex={0}
                                        onClick={() => {
                                            deviceSelected(item);
                                        }}
                                        onKeyPress={(e) => keyPress(e, () => deviceSelected(item))}
                                        className={` mar-rt ${
                                            selectedProductId === item.id ? 'selected-product' : 'device-animate'
                                        }`}
                                        data-testid={`devicelist-div-${index}`}>
                                        <Image src={getDeviceIcon(item.iconUrl)} alt="Surface" />
                                        <Text>{item.name}</Text>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                {selectedProductId && (
                    <div className={`select-model`}>
                        <label className="device-title">
                            <Trans>Select the troubled models</Trans>
                        </label>
                        <div className={`model-list-container`}>
                            <div className="shift-icons model-list" ref={modelListRef}>
                                {modelsList.map((item: any, i: any) => {
                                    return (
                                        <div
                                            aria-label={`${isAdded(item) ? "Selected Product" : ""} ${item.name}`}
                                            key={item.name}
                                            tabIndex={0}
                                            data-testid={`modelList-div-${i}`}
                                            onClick={(e: any) => {
                                                if (e.target && e.target !== document.querySelector(`#model-field-${i}`)) {
                                                    modelSelected(item, selectedProductId);
                                                }
                                            }}
                                            className={`mar-rt p-b-10  ${
                                                isAdded(item) ? 'selected-product' : 'device-animate add-align-prop'
                                            } ${item.added ? 'added' : ''}`}
                                            onKeyPress={(e) =>
                                                keyPress(e, () => {
                                                    if (e.target && e.target !== document.querySelector(`#model-field-${i}`)) {
                                                        modelSelected(item, selectedProductId);
                                                    }
                                                })
                                            }>
                                            <Image src={getDeviceIcon(item.iconUrl)} alt="Surface icon" />

                                            <Text className="model-name">{item.name}</Text>
                                            <div>
                                                {isAdded(item) && (
                                                    <TextField
                                                        id={`model-field-${i}`}
                                                        data-testid={`model-field-${i}-test`}
                                                        onChange={onCountChange}
                                                        placeholder={translate('Quantity')}
                                                        name={item.name}
                                                        value={item.count}
                                                        type="number"
                                                        className="regular-text-field quantity-field"
                                                        autoFocus
                                                    />
                                                )}

                                                {isAdded(item) && (
                                                    <Text
                                                        data-testid={`model-btn-${i}-test`}
                                                        tabIndex={0}
                                                        onClick={() => addToCart(item)}
                                                        onKeyPress={(e) => keyPress(e, () => addToCart(item))}
                                                        className="btn-add-product-modal">
                                                        {item.added ? 'Added' : 'Add'}
                                                    </Text>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
};

export default SelectDevice;
