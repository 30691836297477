import React, { useEffect } from 'react';
import FeedbackView from 'app/modules/admin/feedbackView/FeedbackView';
import { useHistory } from 'react-router-dom';
import useStore from 'app/store';

const Feedback: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history.location.pathname);
    }, []);
    return (
        <>
            <FeedbackView />
        </>
    );
};

export default Feedback;
