import React, { useState, useEffect, useRef } from 'react';
import { IContextualMenuProps, IIconProps, PrimaryButton } from '@fluentui/react';
import './ListBuilder.scss';
import QueryTable from './queryTable/QueryTable';
import { IOptionProps } from '../manageRequests/models';
import FieldBuilder from './fieldBuilder/FieldBuilder';
import { requestTeamTypeOptions, handleGrabIcon, ticketTypeOptions } from './options/Options';
import useStore from 'app/store';
import blueArrow from '../../../static/icons/blueArrow.svg';
import justSaved from '../../../static/icons/justSaved.svg';
import EditFolderIcon from '../../../static/icons/EditFolderIcon.svg';
import saveFavIcon from '../../../static/icons/saveFavIcon.svg';
import saveFavIcon2 from '../../../static/icons/saveFavIcon2.svg';
import TeamBuilder from './teamBuilder/TeamBuilder';
import { IListBuilderFilter, IListBuilderPropertyFilter, IListBuilderTeamFilter } from 'app/models/common/request';
import { useFetchUsersListByRole, useGetUserSavedLists, useRequestData, useRequestListBuilder } from 'app/services/queries';
import { formatIntoRequestData } from 'app/components/helpers';
import SaveListModal from './saveList/SaveList';
import { AddFolderListBuilderModal } from './saveList/addFolder/AddFolder';
import { IListFolder, IListQuery, IUserDetails, IUserList } from 'app/models/common/post';
import { useFavoriteList, useSaveList, useUpdateQuery } from 'app/services/mutations';
import { IUserProps } from 'app/models/common/response';
import { StringConstants } from 'app/utils/constants';
import { useBoolean } from '@fluentui/react-hooks';
import { Portal } from 'app/enums/Portal.enum';
import { useHistory } from 'react-router-dom';
import { SuccessfulSaveModal } from './successfulSave/SuccessfulSave';
import Pagination from 'app/components/pagination/Pagination';
interface IProps {
    selectedKeys?: IOptionProps[];
    setSelectedKeys?: Function;
    isListView?: boolean;
    isInEdit?: boolean;
}

const ListBuilder: React.FC<IProps> = ({ selectedKeys, setSelectedKeys, isListView }) => {
    const saveIcon: IIconProps = { iconName: 'Save' };

    const history: any = useHistory();

    const plusIconRef = useRef(null);

    // Pagination
    const page = useStore((state: any) => state.page);
    const setPage = useStore((state: any) => state.setPage);

    //const requestData = useStore((state: any) => state.requestData);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    // The filters for list builder query
    const [listBuilderFilter, setListBuilderFilter] = useState<IListBuilderFilter>(
        history?.location?.state?.inEditMode
            ? history?.location?.state?.queryFilters.propertyFilters !== null
                ? history?.location?.state?.queryFilters
                : { teamFilters: history?.location?.state?.queryFilters.teamFilters, propertyFilters: [] }
            : {
                  teamFilters: null,
                  propertyFilters: []
              }
    );

    // Queries and mutations
    const { data: savedLists, isLoading: isSavedListsLoading } = useGetUserSavedLists();
    const { data: requestResults, isLoading: isRequestsLoading } = useRequestData({
        portal: Portal.Admin,
        pageNumber: page,
        pageSize: 50,
        getHiddenRequests: false
    });
    const { data: listBuilderResults, isLoading: isListBuilderLoading } = useRequestListBuilder({
        filter: listBuilderFilter,
        pageNumber: page
    });
    const { data: usersListData, isLoading: isUserListLoading } = useFetchUsersListByRole();
    const { mutate: saveList, data: savedListId, isLoading: isSavingList } = useSaveList();
    const { mutate: updateQuery } = useUpdateQuery();
    const { mutate: favoriteList } = useFavoriteList();

    // Query table state
    const [requests, setRequests] = useState([]);
    const [paginatedData, setPaginatedData] = useState<any>({});

    // List builder state
    const [options, setOptions] = React.useState(ticketTypeOptions);
    const [toggleSaveAs, setToggleSaveAs] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [teamSelection, setTeamSelection] = useState<IOptionProps[]>([]);
    const [typeSelection, setTypeSelection] = useState<IOptionProps[]>([]);
    const [fieldComponents, setFieldComponents] = useState<JSX.Element[]>([]);
    const [requestTypeFilters, setRequestTypeFilters] = useState<IListBuilderTeamFilter>({ requestTypes: [] });
    const [userOptions, setUserOptions] = useState<any[]>([]);
    const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false);

    // Plus icon state
    const [fieldIndex, setFieldIndex] = useState(0);
    const [triggerPlusIcon, setTriggerPlusIcon] = useState(false);
    const [plusIconIndex, setPlusIconIndex] = useState(0);
    const [plusIconDisabled, setPlusIconDisabled] = useState(true);
    const [plusIconPosition, setPlusIconPosition] = useState('Out');

    // pagination states
    const [totalPage, setTotalPage] = useState(0);

    // Save list states
    const [name, setName] = useState('');
    const [icon, setIcon] = useState('');
    const [folders, setFolders] = useState<IListFolder[]>([]);
    const [newFolderIndicator, setNewFolderIndicator] = useState(false);
    const [toggleFavIcon, setToggleFavIcon] = useState(false);
    const [showFavStar, setShowFavStar] = useState(false);
    const [updatedFolderIndicator, setUpdatedFolderIndicator] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState<IListFolder>({
        id: '',
        name: '',
        savedListQueries: []
    });
    const [queryToSave, setQueryToSave] = useState<IListQuery>({
        queryName: '',
        icon: 0,
        queryFilters: null
    });
    const [folderData, setFolderData] = useState({
        queryName: '',
        icon: icon,
        name: name,
        selectedFolder: selectedFolder
    });

    // Modals
    const { AddFolderModal, showFolderModal } = AddFolderListBuilderModal();
    const { SuccessfulSave, showSuccess } = SuccessfulSaveModal();
    const [isSaveListModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    // Request type dropdown change handler
    const onSelectChange = (key: string, dependentProperties: string[], parentProperty: string): void => {
        let tempSelectedKeys: any[] = [];

        if (key === 'all_request') {
            if (selectedKeys.filter((item: any) => item.key === key).length === 0) {
                let arr: any[] = [];
                requestTeamTypeOptions.forEach((ele) => arr.push(ele));
                setSelectedKeys(arr);
                setTypeSelection([
                    ...arr.map((item) => ({
                        key: item.key,
                        text: item.text,
                        enumValue: item.enumValue,
                        parentProperty: parentProperty
                    }))
                ]);
                setRequestTypeFilters({
                    requestTypes: [...arr.filter((item) => item.enumValue !== undefined).map((i) => i.enumValue)]
                });
                setTeamSelection([...ticketTypeOptions]);
            } else {
                setSelectedKeys([]);
                setTypeSelection([]);
                setTeamSelection([]);
                setRequestTypeFilters({ requestTypes: [] });
            }
        } else {
            if (!dependentProperties) {
                if (selectedKeys.filter((item: any) => item.key === key).length !== 0) {
                    tempSelectedKeys = selectedKeys.filter(
                        (item: any) => item.key !== key && item.key !== 'all_request' && item.key !== parentProperty
                    );
                } else {
                    let count = 0;
                    let parent: IOptionProps = requestTeamTypeOptions.filter((item) => item.key === parentProperty)[0];
                    let temp = parent.dependentProperties as string[];
                    temp.forEach((ele) => {
                        if (selectedKeys.filter((item: any) => item.key === ele).length !== 0) {
                            count = count + 1;
                        }
                    });
                    if (count + 1 === temp.length) {
                        tempSelectedKeys = [
                            ...selectedKeys,
                            requestTeamTypeOptions.filter((item) => item.key === key)[0],
                            requestTeamTypeOptions.filter((item) => item.key === parentProperty)[0]
                        ];
                    } else {
                        tempSelectedKeys = [...selectedKeys, requestTeamTypeOptions.filter((item) => item.key === key)[0]];
                    }
                }

                if (tempSelectedKeys.findIndex((item) => item.parentProperty === parentProperty) > -1) {
                    teamSelection.findIndex((item) => item.key === parentProperty) === -1 &&
                        setTeamSelection([...teamSelection, ticketTypeOptions.find((item) => item.key === parentProperty)]);
                } else {
                    setTeamSelection([...teamSelection.filter((selection) => selection.key !== parentProperty)]);
                }
            } else {
                if (selectedKeys.filter((item: any) => item.key === key).length !== 0) {
                    let arr = selectedKeys;

                    dependentProperties.forEach((ele) =>
                        arr.splice(
                            arr.findIndex((element: any) => {
                                if (element.key === ele) return true;
                            }),
                            1
                        )
                    );

                    tempSelectedKeys = arr.filter((ele: any) => ele.key !== key && ele.key !== 'all_request');
                } else {
                    let arr: IOptionProps[] = [];
                    dependentProperties.forEach((item) => {
                        if (selectedKeys.filter((it: any) => it.key === item).length === 0)
                            arr.push(requestTeamTypeOptions.filter((ele) => ele.key === item)[0]);
                    });
                    tempSelectedKeys = [
                        ...selectedKeys.concat(arr),
                        requestTeamTypeOptions.filter((item) => item.key === key)[0]
                    ];
                }

                if (tempSelectedKeys.findIndex((item) => item.key === key) > -1) {
                    teamSelection.findIndex((item) => item.key === key) === -1 &&
                        setTeamSelection([...teamSelection, ticketTypeOptions.find((item) => item.key === key)]);
                } else {
                    setTeamSelection([...teamSelection.filter((selection) => selection.key !== key)]);
                }
            }

            setSelectedKeys(tempSelectedKeys);
            setTypeSelection([
                ...tempSelectedKeys.map((item: any) => ({
                    key: item.key,
                    text: item.text,
                    enumValue: item.enumValue,
                    parentProperty: parentProperty
                }))
            ]);
            setRequestTypeFilters({
                requestTypes: [...tempSelectedKeys.filter((item) => item.enumValue !== undefined).map((i) => i.enumValue)]
            });
        }
    };

    // Plus icon click handler, adds new field builder components and triggers plus icon animations
    const handlePlusIcon = () => {
        setPlusIconPosition('Down');
        setTriggerPlusIcon(true);
        setFieldIndex(fieldIndex + 1);
        setPlusIconIndex(plusIconIndex + 1);
        setFieldComponents([
            ...fieldComponents,
            <FieldBuilder
                key={Math.random()}
                setFieldIndex={setFieldIndex}
                fieldIndex={fieldIndex}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                isListView={isListView}
                teamSelection={teamSelection}
                setPlusIconPosition={setPlusIconPosition}
                plusIconPosition={plusIconPosition}
                listBuilderFilter={listBuilderFilter}
                setListBuilderFilter={setListBuilderFilter}
                setPlusIconDisabled={setPlusIconDisabled}
                setPlusIconIndex={setPlusIconIndex}
                plusIconIndex={plusIconIndex}
                fieldComponents={fieldComponents}
                setFieldComponents={setFieldComponents}
                userOptions={userOptions}
                plusIconRef={plusIconRef}
                setSaveButtonDisabled={setSaveButtonDisabled}
                typeSelection={typeSelection}
                deleteDisabled={deleteDisabled}
                setDeleteDisabled={setDeleteDisabled}
            />
        ]);
    };

    function routeChange() {
        history.push({
            pathname: '/manage-lists',
            state: {
                toggleFavorite: true
            }
        });
    }

    function savedListsRouteChange() {
        history.push({
            pathname: '/manage-lists',
            state: {
                toggleFavorite: false
            }
        });
    }

    function updateEditMode() {
        history.push({
            state: {
                inEditMode: false
            }
        });
    }

    // Opens the save list modal
    const handleSaveAs = () => {
        showModal();
        setShowFavStar(true);
        setQueryToSave({ ...queryToSave, queryFilters: listBuilderFilter });
    };

    const handleToggleFavIvon = () => {
        setToggleFavIcon(!toggleFavIcon);
        if (savedListId) {
            favoriteList(savedListId.data);
        }
    };

    // Saves a NEW list
    const handleSaveList = () => {
        var newList: IUserList = {
            listFolders: [
                {
                    id: selectedFolder.id,
                    name: selectedFolder.name,
                    savedListQueries: [queryToSave]
                }
            ]
        };
        saveList(newList, {
            onSuccess: () => {
                setFolderData({
                    queryName: queryToSave.queryName,
                    icon: queryToSave.icon.toString(),
                    name: selectedFolder.name,
                    selectedFolder: { ...selectedFolder }
                });

                hideModal();
                updateEditMode();
                setIcon('');
                setName('');
                setSelectedFolder({
                    name: '',
                    savedListQueries: []
                });

                setTimeout(() => {
                    setNewFolderIndicator(true);
                    setUpdatedFolderIndicator(true);
                }, 1000);
            }
        });
    };

    const handleUpdateQuery = () => {
        var queryToUpdate = history?.location?.state?.query;
        var updatedQuery: IListQuery = {
            id: queryToUpdate?.QueryId,
            queryName: queryToUpdate?.QueryName,
            icon: queryToUpdate?.QueryIcon,
            starred: queryToUpdate?.Starred,
            folderId: queryToUpdate?.FolderId,
            queryFilters: listBuilderFilter
        };

        setFolderData({
            queryName: queryToUpdate.QueryName,
            icon: queryToUpdate.QueryIcon,
            name: name,
            selectedFolder: selectedFolder
        });

        setNewFolderIndicator(true);

        updateQuery(updatedQuery, {
            onSuccess: () => {
                showSuccess();
            }
        });
    };

    // Menu props for save options in edit mode
    const saveButtonMenuProps: IContextualMenuProps = {
        items: [
            {
                key: 'saveAsNew',
                text: 'Save As New List',
                onClick: handleSaveAs
            },
            {
                key: 'saveList',
                text: 'Save List',
                onClick: handleUpdateQuery
            }
        ]
    };

    // Use effects
    useEffect(() => {
        // clear history when browser is refreshed
        window.history.replaceState({}, document.title);
    }, []);

    // use effect for auto populating team/request type list builder in edit mode
    useEffect(() => {
        if (history?.location?.state !== undefined && history?.location?.state?.inEditMode) {
            var state: any = history.location.state;
            var requestTypes: number[] = state.queryFilters.teamFilters.requestTypes;

            setRequestTypeFilters({ requestTypes: requestTypes });

            var teamSelectedKeys: any[] = [];
            var teamSelectedOptions: IOptionProps[] = [];

            var requestTypeSelection: IOptionProps[] = [];
            var requestTypeSelectedKeys: any[] = [];

            let sdsgParent = null;
            let crewParent = null;
            let cssParent = null;
            let cxpParent = null;

            // set the teams
            // For SDSG
            if (requestTypes.includes(1) || requestTypes.includes(2) || requestTypes.includes(3)) {
                teamSelectedKeys.push('D');
                teamSelectedOptions.push(ticketTypeOptions[3]);
            }

            if (requestTypes.includes(1) && requestTypes.includes(2) && requestTypes.includes(3)) {
                sdsgParent = requestTeamTypeOptions.find((item) => item.key === StringConstants.SDSG);
                requestTypeSelectedKeys.push(sdsgParent);
                requestTypeSelection.push({ key: sdsgParent.key, text: sdsgParent.text });
            }

            // For CREW
            if (
                requestTypes.includes(4) ||
                requestTypes.includes(5) ||
                requestTypes.includes(13) ||
                requestTypes.includes(13) ||
                requestTypes.includes(14) ||
                requestTypes.includes(15)
            ) {
                teamSelectedKeys.push('A');
                teamSelectedOptions.push(ticketTypeOptions[0]);
            }

            if (
                requestTypes.includes(4) &&
                requestTypes.includes(5) &&
                requestTypes.includes(13) &&
                requestTypes.includes(13) &&
                requestTypes.includes(14) &&
                requestTypes.includes(15)
            ) {
                crewParent = requestTeamTypeOptions.find((item) => item.key === StringConstants.CREW);
                requestTypeSelectedKeys.push(crewParent);
                requestTypeSelection.push({ key: crewParent.key, text: crewParent.text });
            }

            // For CSS
            if (requestTypes.includes(7)) {
                teamSelectedKeys.push('B');
                teamSelectedOptions.push(ticketTypeOptions[1]);

                cssParent = requestTeamTypeOptions.find((item) => item.key === StringConstants.CSS);
                requestTypeSelectedKeys.push(cssParent);
                requestTypeSelection.push({ key: cssParent.key, text: cssParent.text });
            }

            // For CXP
            if (requestTypes.includes(8) || requestTypes.includes(9) || requestTypes.includes(10) || requestTypes.includes(11)) {
                teamSelectedKeys.push('C');
                teamSelectedOptions.push(ticketTypeOptions[2]);
            }

            if (requestTypes.includes(8) && requestTypes.includes(9) && requestTypes.includes(10) && requestTypes.includes(11)) {
                cxpParent = requestTeamTypeOptions.find((item) => item.key === StringConstants.CXP);
                requestTypeSelectedKeys.push(cxpParent);
                requestTypeSelection.push({ key: cxpParent.key, text: cxpParent.text });
            }

            // For ALL
            if (sdsgParent !== null && cxpParent !== null && crewParent !== null && cssParent !== null) {
                requestTypeSelectedKeys.push(requestTeamTypeOptions[0]);
                requestTypeSelection.push({ key: requestTeamTypeOptions[0].key, text: requestTeamTypeOptions[0].text });
            }

            setTeamSelection([...teamSelectedOptions]);

            // set the request types
            requestTypes.forEach((requestType) => {
                var selectedOption = requestTeamTypeOptions.find((option) => option.enumValue === requestType);
                requestTypeSelectedKeys.push(selectedOption);
                requestTypeSelection.push({
                    key: selectedOption.key,
                    text: selectedOption.text,
                    enumValue: selectedOption.enumValue,
                    parentProperty: selectedOption.parentProperty
                });
            });

            setSelectedKeys([...requestTypeSelectedKeys]);
            setTypeSelection([...requestTypeSelection]);

            // set the property filters and render field builder components
            var propertyFilters = state.queryFilters.propertyFilters;

            var fieldBuilderComponents: JSX.Element[] = [];
            var fi = 0;
            var i = 0;

            propertyFilters?.forEach((filter: IListBuilderPropertyFilter) => {
                setPlusIconPosition('Down');
                setTriggerPlusIcon(true);

                fi += 1;
                i += 1;

                setFieldIndex(fi);
                setPlusIconIndex(i);

                fieldBuilderComponents.push(
                    <FieldBuilder
                        key={Math.random()}
                        setFieldIndex={setFieldIndex}
                        fieldIndex={fieldIndex}
                        selectedKeys={requestTypeSelectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        isListView={isListView}
                        teamSelection={teamSelection}
                        setPlusIconPosition={setPlusIconPosition}
                        plusIconPosition={plusIconPosition}
                        listBuilderFilter={listBuilderFilter}
                        setListBuilderFilter={setListBuilderFilter}
                        setPlusIconDisabled={setPlusIconDisabled}
                        setPlusIconIndex={setPlusIconIndex}
                        plusIconIndex={plusIconIndex}
                        fieldComponents={fieldBuilderComponents}
                        setFieldComponents={setFieldComponents}
                        userOptions={userOptions}
                        loadingPropertyFilter={filter}
                        inEditMode={state.inEditMode}
                        plusIconRef={plusIconRef}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                        typeSelection={typeSelection}
                        deleteDisabled={deleteDisabled}
                        setDeleteDisabled={setDeleteDisabled}
                    />
                );
            });

            setFieldComponents([...fieldBuilderComponents]);
        }
    }, [history?.location, history?.location?.state]);

    useEffect(() => {
        if (listBuilderResults !== undefined) {
            setRequests(formatIntoRequestData(listBuilderResults.items));
            setPaginatedData(listBuilderResults);
        }
    }, [listBuilderResults]);

    useEffect(() => {
        setPage(1);
        setPlusIconDisabled(
            listBuilderFilter?.teamFilters?.requestTypes?.length <= 0 &&
                plusIconIndex > listBuilderFilter?.propertyFilters?.length
        );
        setSaveButtonDisabled(
            listBuilderFilter?.teamFilters?.requestTypes?.length <= 0 &&
                plusIconIndex > listBuilderFilter?.propertyFilters?.length            
        )
    }, [listBuilderFilter]);

    useEffect(() => {
        if (typeSelection.length === 0) {
            setRequests(formatIntoRequestData(requestResults?.items));
            setPaginatedData(requestResults);
            setToggleSaveAs(false);
            setSaveButtonDisabled(true);
            setTriggerPlusIcon(false);
        } else {
            setToggleSaveAs(true);
            setSaveButtonDisabled(false);
            setTriggerPlusIcon(true);
        }
    }, [typeSelection]);

    useEffect(() => {
        // Set the plus icon position in edit mode
        if (toggleSaveAs && triggerPlusIcon) {
            if (history?.location?.state?.inEditMode) {
                var element = plusIconRef.current;

                if (element !== null) {
                    element.style.setProperty('--icon-previous-position', `${172 * plusIconIndex - 1}px`);
                    element.style.setProperty('--icon-position', `${172 * plusIconIndex}px`);

                    element.classList.remove('plusIconDown');

                    void element.offsetWidth;

                    element.classList.add('plusIconDown');
                }
            }
        }
    }, [toggleSaveAs, triggerPlusIcon]);

    useEffect(() => {
        if (!isSavedListsLoading && savedLists) {
            setFolders(savedLists.listFolders);
        }
    }, [savedLists, isSavedListsLoading]);

    useEffect(() => {
        setListBuilderFilter({
            ...listBuilderFilter,
            teamFilters: requestTypeFilters
        });
    }, [requestTypeFilters]);

    useEffect(() => {
        if (!isRequestsLoading && !history?.location?.state?.inEditMode && typeSelection.length === 0) {
            setRequests(formatIntoRequestData(requestResults?.items));
            setPaginatedData(requestResults);
        }
    }, [requestResults, isRequestsLoading, history?.location?.state]);

    useEffect(() => {
        if (!isUserListLoading && usersListData && usersListData.length !== 0) {
            let arr: IUserProps[] = [];
            arr.push({
                id: StringConstants.ALL,
                displayName: '',
                mail: '',
                mobilePhone: '',
                businessPhones: [''],
                text: StringConstants.UPPERCASE_ALL
            });

            if (usersListData.filter((item: IUserProps) => item.id !== userDetails.id).length !== 0) {
                arr.push({
                    id: userDetails?.id,
                    displayName: userDetails?.displayName,
                    mail: userDetails?.email,
                    mobilePhone: userDetails?.contactNumber,
                    businessPhones: userDetails?.businessPhones,
                    text: '@Me'
                });
            }

            arr.push({ id: '0', text: 'Unassigned', displayName: 'Unassigned', mail: '', mobilePhone: '', businessPhones: [] });

            usersListData
                .filter((item: IUserProps) => item.id !== userDetails.id)
                .forEach((element: IUserProps) => {
                    arr.push({ ...element, text: element.displayName });
                });

            setUserOptions(arr);
        }
    }, [usersListData, isUserListLoading]);

    useEffect(() => {
        if (paginatedData !== null && paginatedData !== undefined) {
            setTotalPage(paginatedData.totalPages);
        }
    }, [paginatedData]);

    return (
        <div>
            <div>
                <div className="building-list-container">
                    <div className="arrow-btn-container">
                        <div
                            onClick={() => {
                                history.goBack();
                            }}
                            className="arrowStyles">
                            <img src={blueArrow} alt="SVG" />
                        </div>
                        <div className="arrowLabelStyles">List Builder</div>
                    </div>
                    <div className="favorites-saved-container">
                        <div className="arrowLabelStyles">
                            <span className="savedLink" onClick={() => savedListsRouteChange()}>
                                Saved
                            </span>
                            <div>|</div>
                            <span className="favooritesLink" onClick={() => routeChange()}>
                                Favorites
                            </span>
                        </div>
                    </div>
                </div>
                {newFolderIndicator && updatedFolderIndicator && (
                    <div className="successBanner successBannerIn">
                        <div className="successContainer">
                            <div className="saved-icon-styles-new">
                                <img src={justSaved} alt="just saved icon" />
                                <img src={handleGrabIcon(folderData.icon)} alt="selected icon" />
                            </div>
                            <div className="nameFavIconStyles">
                                <div className="listNameStyles">{folderData.queryName}</div>

                                {showFavStar ? (
                                    <div className="toggle-fav-cont" onClick={handleToggleFavIvon}>
                                        {toggleFavIcon ? (
                                            <img src={saveFavIcon} alt="favorite icon selected" />
                                        ) : (
                                            <img src={saveFavIcon2} alt="favorite icon not selected" />
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {history?.location?.state?.inEditMode && (
                    <div className="editBannerIn">
                        <div className="edit-banner">
                            <div className="editFolder-cont">
                                <div className="saved-icon-styles-edit">
                                    <img src={EditFolderIcon} alt="edit folder icon" />
                                </div>
                                <div className="listNameStyles">{history?.location?.state?.folderName}</div>
                            </div>
                            <div className="editQuery-cont">
                                <div className="lb-query-edit-icon">
                                    <img
                                        className="icon-edit-mode"
                                        src={handleGrabIcon(history?.location?.state?.query.QueryIcon)}
                                        alt="Edit Query Icon"
                                    />
                                </div>
                                <div className="listNameStyles">{history?.location?.state?.query.QueryName}</div>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <div
                        className={`list-builder-parent-container ${
                            history?.location?.state?.inEditMode || newFolderIndicator ? 'list-builder-margins-helper' : ''
                        }`}>
                        <div className="teamBuilderContainer">
                            <TeamBuilder
                                handlePlusIcon={handlePlusIcon}
                                setPlusIconPosition={setPlusIconPosition}
                                plusIconPosition={plusIconPosition}
                                toggleSaveAs={toggleSaveAs}
                                triggerPlusIcon={triggerPlusIcon}
                                onSelectChange={onSelectChange}
                                options={options}
                                plusIconIndex={plusIconIndex}
                                plusIconDisabled={plusIconDisabled}
                                selectedKeys={selectedKeys}
                                plusIconRef={plusIconRef}
                            />
                            <div className="saveAsStyles">
                                {history?.location?.state?.inEditMode ? (
                                    <PrimaryButton
                                        tabIndex={0}
                                        disabled={saveButtonDisabled}
                                        text="Complete"
                                        split
                                        splitButtonAriaLabel="see 2 save options"
                                        aria-roledescription="split button"
                                        menuProps={saveButtonMenuProps}
                                        iconProps={saveIcon}
                                    />
                                ) : (
                                    <PrimaryButton
                                        tabIndex={0}
                                        onClick={handleSaveAs}
                                        disabled={saveButtonDisabled}
                                        text="Save As"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="fieldFilterContainer">
                            {fieldComponents.map((element, i) => {
                                return React.cloneElement(element, {
                                    key: element.key,
                                    index: i,
                                    listBuilderFilter: listBuilderFilter,
                                    plusIconIndex: plusIconIndex,
                                    fieldIndex: fieldIndex,
                                    teamSelection: teamSelection,
                                    fieldComponents: [...fieldComponents],
                                    typeSelection: typeSelection,
                                    deleteDisabled: deleteDisabled
                                });
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <SaveListModal
                        setIcon={setIcon}
                        icon={icon}
                        folders={folders}
                        showFolderModal={showFolderModal}
                        selectedFolder={selectedFolder}
                        setSelectedFolder={setSelectedFolder}
                        setFolderData={setFolderData}
                        setName={setName}
                        name={name}
                        queryToSave={queryToSave}
                        setQueryToSave={setQueryToSave}
                        handleSave={handleSaveList}
                        isModalOpen={isSaveListModalOpen}
                        showModal={showModal}
                        hideModal={hideModal}
                        isLoading={isSavingList}
                        handleModalCancel={() => {}}
                    />
                </div>
                <div>
                    <SuccessfulSave
                        queryName={folderData.queryName}
                        setUpdatedFolderIndicator={setUpdatedFolderIndicator}
                        updateEditMode={updateEditMode}
                    />
                </div>
                <div>
                    <AddFolderModal
                        setSelectedFolder={setSelectedFolder}
                        setFolders={setFolders}
                        folders={folders}
                        showModal={showModal}
                    />
                </div>

                <div>
                    <QueryTable
                        filterData={requests}
                        paginatedData={paginatedData}
                        isRequestsLoading={isRequestsLoading}
                        isListBuilderLoading={isListBuilderLoading}
                    />

                    {(page !== 1 ||
                        (!isRequestsLoading && page === 1 && requests.length !== 0) ||
                        (!isListBuilderLoading && page === 1 && requests.length !== 0)) && (
                        <Pagination
                            pageCount={totalPage}
                            setPageNumber={setPage}
                            initialPage={page - 1}
                            totalCount={paginatedData?.totalCount}
                            resultsPerPage={50}
                            updateURL={false}
                        />
                    )}
                </div>
                <div className="loadMore"></div>
            </div>
        </div>
    );
};

export default ListBuilder;
