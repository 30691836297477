import React, { useEffect, useRef, useState } from 'react';
import Editor from './Editor';
import { PeoplePicker } from 'app/components/peoplePicker/PeoplePicker';
import { Label } from '@fluentui/react';
import { ISearchUser } from 'app/models/common/response';
import EmailTeamDropdown from './emailTeamDropdown/EmailTeamDropdown';
import useStore from 'app/store';
import { IUserDetails } from 'app/models/common/post';
interface IProps {
    setTitle: (title: string) => void;
    title: string;
    setContent: (title: string) => void;
    content: string;
    handleFileUploadProgress?: (loadedBytes: number, totalBytes: number) => void;
    selectedPeople: Array<ISearchUser>;
    setSelectedPeople: (a: any) => void;
    selectedCcPeople: Array<ISearchUser>;
    setSelectedCcPeople: (a: any) => void;
    selectedBccPeople: Array<ISearchUser>;
    setSelectedBccPeople: (a: any) => void;
    isPreview: boolean;
    isEmail: boolean;
}

const ContentArea: React.FC<IProps> = (props: IProps) => {
    const {
        title,
        setTitle,
        content,
        setContent,
        handleFileUploadProgress,
        selectedPeople,
        setSelectedPeople,
        selectedCcPeople,
        setSelectedCcPeople,
        selectedBccPeople,
        setSelectedBccPeople,
        isPreview = false,
    } = props;

    const [showCcBcc, setShowCcBcc] = useState<boolean>(false);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails as IUserDetails);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (!showCcBcc) {
            setSelectedCcPeople([]);
            setSelectedBccPeople([]);
        }
    }, [showCcBcc]);

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };
    const dropDownRef = useRef(null);
    useDropDownDismiss(dropDownRef, onToggleShow);

    return (
        <>
            <div className="d-flex m-b-15 m-t-20 ai-center">
                <EmailTeamDropdown isDomainSpecific={true} selectedPeople={selectedPeople} setSelectedPeople={setSelectedPeople} title="To"/>
                <PeoplePicker
                    isDomainSpecific={false}
                    selectedPeople={selectedPeople}
                    setSelectedPeople={setSelectedPeople}
                    isSingleSelect={false}
                    allowAnyMailbox
                    classNames="d-flex-grow--1"
                />
                {!showCcBcc && (
                    <button className="m-l-10 btn btn--cyan text-lineHeight--26" onClick={() => setShowCcBcc((prev) => !prev)}>
                        Cc & Bcc
                    </button>
                )}
            </div>
            {showCcBcc && (
                <>
                    <div className="d-flex m-b-15 ai-center">
                        <EmailTeamDropdown isDomainSpecific={true} selectedPeople={selectedCcPeople} setSelectedPeople={setSelectedCcPeople} title="Cc" />
                        <PeoplePicker
                            isDomainSpecific={false}
                            selectedPeople={selectedCcPeople}
                            setSelectedPeople={setSelectedCcPeople}
                            isSingleSelect={false}
                            allowAnyMailbox
                            classNames="d-flex-grow--1"
                        />
                    </div>
                    <div className="d-flex ai-center m-b-15">
                        <EmailTeamDropdown  isDomainSpecific={true} selectedPeople={selectedBccPeople} setSelectedPeople={setSelectedBccPeople} title="Bcc" />
                        <PeoplePicker
                            isDomainSpecific={false}
                            selectedPeople={selectedBccPeople}
                            setSelectedPeople={setSelectedBccPeople}
                            isSingleSelect={false}
                            allowAnyMailbox
                            classNames="d-flex-grow--1"
                        />
                    </div>
                </>
            )}
            <div className="d-flex ai-center m-b-20">
                <Label className="p-r-20">Subject:</Label>
                <input className="subject" type="text" value={title} onChange={({ target: { value } }) => setTitle(value)} />
            </div>
            {!isPreview ? (
                <Editor content={content} setContent={setContent} handleFileUploadProgress={handleFileUploadProgress} />
            ) : (
                <div className="sun-editor-editable email" dangerouslySetInnerHTML={{ __html: content }} />
            )}
        </>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default ContentArea;
