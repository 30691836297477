import { Image, Link, PersonaInitialsColor, Spinner, SpinnerSize, Text, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import './MyDetails.scss';

import linkedInIcon from '../../../static/icons/linkedIn-blue.svg';
import editIcon from '../../../static/icons/edit-solid-black.svg';
import twitterIcon from '../../../static/icons/twitter-blue.svg';
import useStore from 'app/store';
import { useUpdateAuthorDetails } from 'app/services/mutations';
import { IAuthorDetails, IUserDetails } from 'app/models/common/post';
import Button from 'app/components/button/Button';

import { useGetAuthorById } from 'app/services/queries';
import { Trans, useTranslation } from 'react-i18next';
import { isContentManager, isSuperAdmin, isContentCreator } from 'app/utils/authUtilities';
import Avatar from 'app/components/avatar/Avatar';
import { isValidURL, keyPress } from 'app/components/helpers';
const MyDetails: React.FC = () => {
    const { t: translate } = useTranslation();
    const [editProfile, setEditProfile] = useState(false);
    const [aboutMe, setAboutMe] = useState<string>();
    const [twitterUrl, setTwitterURL] = useState();
    const [linkedInUrl, setLinkedInURL] = useState();

    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const authorDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const { data: profile, isLoading, isFetched, refetch: getAuthorDetails } = useGetAuthorById(authorDetails?.id);
    const { mutateAsync: updateAuthorDetails } = useUpdateAuthorDetails();

    const profileDetails: IAuthorDetails = {
        id: authorDetails?.id,
        aboutMe,
        twitterUrl,
        linkedInUrl
    };

    const editAboutMeFun = () => {
        setEditProfile(!editProfile);
    };
    const updateAboutMe = (e: any) => {
        setAboutMe(e.target.value);
    };
    const updateLinkedInURL = (e: any) => {
        isValidURL(e.target.value);
        setLinkedInURL(e.target.value);
    };
    const updateTwitterURL = (e: any) => {
        isValidURL(e.target.value);
        setTwitterURL(e.target.value);
    };
    const saveProfileDetails = () => {
        updateAuthorDetails(profileDetails)
            .then(() => getAuthorDetails())
            .catch(() => {})
            .finally(() => setEditProfile(false));
    };

    const cancelEditProfile = () => {
        setEditProfile(false);
    };

    useEffect(() => {
        setAboutMe(profile?.aboutMe);
        setTwitterURL(profile?.twitterUrl);
        setLinkedInURL(profile?.linkedInUrl);
    }, [isFetched, setAboutMe, setTwitterURL, setLinkedInURL, editProfile]);
    if (!isLoading) {
        return (
            <div className="my-details">
                <h3>
                    <Trans>My Details</Trans>
                </h3>
                <div className="details">
                    <div className="profile">
                        <div className="profile-pic rounded-image">
                            <Avatar
                                userId={authorDetails?.id}
                                userName={authorDetails?.displayName}
                                initialColor={PersonaInitialsColor.teal}
                                imageSize={100}
                                roundedPic={true}
                            />
                        </div>
                        <div className="name">{authorDetails?.displayName}</div>
                        <div className="email">{authorDetails?.email}</div>
                        <div className="phone">{authorDetails?.contactNumber ? authorDetails?.contactNumber : 'NA'}</div>
                    </div>
                    {(isSuperAdmin(userDetails?.userRoles) ||
                        isContentCreator(userDetails?.userRoles) ||
                        isContentManager(userDetails?.userRoles)) && (
                        <div className="update-details">
                            <div className="form-group m-b-40">
                                <label>
                                    <Trans>About Me</Trans>
                                    {!editProfile ? (
                                        <span
                                            className="edit-icon"
                                            tabIndex={0}
                                            onKeyPress={(e: any) => keyPress(e, editAboutMeFun)}
                                            onClick={() => editAboutMeFun()}>
                                            <Image src={editIcon} />
                                            <Trans>Edit Profile</Trans>
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </label>
                                {editProfile ? (
                                    <TextField
                                        value={aboutMe}
                                        placeholder="Tell us about yourself"
                                        onChange={(e) => updateAboutMe(e)}
                                        rows={8}
                                        multiline
                                        autoAdjustHeight
                                    />
                                ) : (
                                    <Text>{profile?.aboutMe}</Text>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    <Trans>Social Media</Trans>
                                </label>
                                <div className="social-share">
                                    <Image src={linkedInIcon} className="social-icon" />
                                    {editProfile ? (
                                        <TextField
                                            value={linkedInUrl}
                                            placeholder="Add LinkedIn Account"
                                            onChange={(e) => updateLinkedInURL(e)}
                                        />
                                    ) : (
                                        <Link role="link" href={profile?.linkedInUrl} target="_blank">
                                            {profile?.linkedInUrl}
                                        </Link>
                                    )}
                                </div>
                                <div className="social-share">
                                    <Image src={twitterIcon} className="social-icon" />
                                    {editProfile ? (
                                        <TextField
                                            value={twitterUrl}
                                            placeholder="Add Twitter Account"
                                            onChange={(e) => updateTwitterURL(e)}
                                        />
                                    ) : (
                                        <Link role="link" href={profile?.twitterUrl} target="_blank">
                                            {profile?.twitterUrl}
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className="profile-footer">
                                {editProfile && (
                                    <>
                                        <Button buttonClass="btn " buttonText="Cancel" onClick={() => cancelEditProfile()} />
                                        <Button
                                            buttonClass="btn btn--green"
                                            buttonText="Save"
                                            onClick={saveProfileDetails}
                                            disabled={!isValidURL(twitterUrl) || !isValidURL(linkedInUrl)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return <Spinner className="m-auto" size={SpinnerSize.large} label={translate('Loading...')} />;
    }
};

export default MyDetails;
