import React, { FC } from 'react';
import './NoData.scss';
import { Trans } from 'react-i18next';
const NoData: FC<any> = () => {
    return (
        <div className="d-flex jc-center ai-center message height-70 ">
            <Trans>No data available</Trans>
        </div>
    );
};

export default NoData;
