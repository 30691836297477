import React from 'react';
import { Icon } from '@fluentui/react';
import { Text, ActionButton } from '@fluentui/react';
import _ from 'lodash';
import { ReportType } from 'app/enums/ReportType.enum';

type SortProperty = 'DisplayName' | 'Type' | 'ModifiedOn';

interface IProps {
    type: 'DisplayName' | 'Type' | 'ModifiedOn';
    reportType?: ReportType;
    setSortDirection: (direction: string) => void;
    setSortProperty: (property: SortProperty) => void;
    sortDirection: string;
    sortProperty: string;
    setPageIndex?: (index: number) => void;
    setReportType: (reportType: ReportType | null) => void;
}

const ColumnHeader: React.FunctionComponent<IProps> = ({
    type,
    setSortDirection,
    setSortProperty,
    sortDirection,
    sortProperty,
    setReportType
}) => {
    const reportTypeMenu: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: '',
            items: [
                {
                    key: '',
                    text: _.startCase('All reports'),
                    canCheck: false,
                    onClick: () => setReportType(null)
                },
                {
                    key: ReportType.ApplicationReport,
                    text: _.startCase(ReportType[ReportType.ApplicationReport]),
                    canCheck: false,
                    onClick: () => setReportType(ReportType.ApplicationReport)
                },
                {
                    key: ReportType.UtilizationInsightsReport,
                    text: _.startCase(ReportType[ReportType.UtilizationInsightsReport]),
                    canCheck: false,
                    onClick: () => setReportType(ReportType.UtilizationInsightsReport)
                }
            ]
        }),
        []
    );

    return (
        <div className={`header-cell-btn`}>
            <ActionButton
                data-testid="action-button"
                menuProps={type === 'Type' ? reportTypeMenu : null}
                onClick={() => {
                    setSortDirection(sortProperty === type ? (sortDirection === '1' ? '0' : '1') : '1');
                    switch (type) {
                        case 'DisplayName':
                            setSortProperty('DisplayName');
                            break;
                        case 'ModifiedOn':
                            setSortProperty('ModifiedOn');
                            break;
                        case 'Type':
                            setSortProperty('Type');
                            break;
                        default:
                            break;
                    }
                }}>
                <Text>
                    {type === 'DisplayName'
                        ? _.upperCase('Report name')
                        : type === 'Type'
                        ? _.upperCase('Report type')
                        : type === 'ModifiedOn'
                        ? _.upperCase('Date')
                        : ''}
                </Text>{' '}
                {type === 'Type' ? (
                    <Icon className="chevron-down-icon m-l-5" iconName="ChevronDown" />
                ) : (
                    <Icon className="sort-icon m-l-5" iconName="Sort" />
                )}
            </ActionButton>
        </div>
    );
};

export default ColumnHeader;
