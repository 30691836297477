import { Modal } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import Button from '../button/Button';
import './CustomMessageModal.scss';

interface IProps {
    message?: string;
}

export const useCustomMessageModal = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const CustomMessageModal: React.FC<IProps> = ({ message = 'Something went wrong, please try again.' }) => {
        return (
            <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                className="surface-modal custom-message-modal-hook">
                <div className="modal-header">
                    <span className="p-b-20"></span>
                </div>
                <div className="modal-body text-center">
                    <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                    <h4 className="m-t-5 m-b-5">{message}</h4>
                </div>
                <div className="modal-footer">
                    <Button buttonClass="btn btn--primary" buttonText="OK" onClick={hideModal} />
                </div>
            </Modal>
        );
    };

    return { CustomMessageModal, showModal, hideModal };
};
