import React from 'react';
import _ from 'lodash';

import './comments.scss';

import commentIcon from 'app/static/icons/comment-icon.svg';

import { ActivityStatus } from './VisualFlow';
import moment from 'moment';
import { PersonaInitialsColor } from '@fluentui/react';
import Avatar from 'app/components/avatar/Avatar';
import { injectTargetToAnchor, ReplaceUrlByAnchor } from 'app/components/helpers';

function Comments(props: any) {
    const setPosition = () => {
        if (props.id < 6) {
            return { left: `${props.posX - 35}px`, top: `${props.posY + 20}px` };
        } else {
            return {
                top: `${props.posY + 20}px`,
                left: `${props.posX - 150}px`
            };
        }
    };

    const getTaggedUser = (name: string, users: any) => {
        if (!_.isEmpty(name) && !_.isEmpty(users)) {
            return _.find(users, (item) => item.id === name);
        } else {
            return {
                id: '',
                displayName: '',
                email: ''
            };
        }
    };

    const generateCommentMessage = (message: string, taggedUsers: any) => {
        let formattedMsg = message;
        let users = Array.from(message?.matchAll(/<userid:(.*?)>/g));
        for (const user of users) {
            formattedMsg = formattedMsg.replace(
                user[0],
                `<span class="tagged-user">${getTaggedUser(user[1], taggedUsers).displayName}</span>`
            );
        }
        formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request-detail');
        formattedMsg = ReplaceUrlByAnchor(injectTargetToAnchor(formattedMsg?.replace(/\\/g, '')), 'tagged-user', true);
        return formattedMsg;
    };

    return (
        <>
            {props.step && (
                <div
                    id={props.id}
                    className={`vf-comments-container  ${props.showComment ? 'openComments' : 'hideComments'}`}
                    style={setPosition()}
                    onMouseEnter={props.onHoverComments}
                    onMouseLeave={props.onMouseLeaveComments}>
                    <div className={`status bg-${ActivityStatus[props.step.status]}`}>{ActivityStatus[props.step.status]}</div>
                    <div className="custom-row">
                        <div className="step-icon">
                            <div className="step-icon__border">
                                {props.step ? <div className="step-icon__bg">{props.getIcon(props.step.icon)}</div> : ''}
                            </div>
                        </div>
                        <div className="step-title-section">
                            <div className="step-title">{props.step.title}</div>
                            <div className="last-activity">
                                Last Activity:
                                {moment(props.step.modifiedOn ? props.step.modifiedOn : props.step.createdOn).format('hh:mm a')}
                            </div>
                            <div className="attachments">
                                <img className="comment-icon" src={commentIcon} alt="comment" />
                                {props.step.commentCount}
                            </div>
                        </div>
                    </div>
                    {props.step &&
                        props.step.comments &&
                        props.step.comments.length > 0 &&
                        props.step.comments.map(
                            (item: any, idx: number) =>
                                item &&
                                !item.parentCommentId && (
                                    <div key={idx}>
                                        <div className="custom-row comment-row" key={item.id}>
                                            <Avatar
                                                userId={item?.createdBy?.id}
                                                userName={item?.createdBy?.displayName}
                                                initialColor={PersonaInitialsColor.coolGray}
                                                imageSize={32}
                                                roundedPic={true}
                                            />
                                            <div className="comment-detail">
                                                <div className="comment-display-name">
                                                    {item.createdBy.displayName}{' '}
                                                    <span
                                                        className="comment-text"
                                                        dangerouslySetInnerHTML={{
                                                            __html: generateCommentMessage(item.commentText, item.taggedUsers)
                                                        }}></span>
                                                </div>
                                                <div className="comment-time">
                                                    {moment(item.createdOn).format('MMM DD YYYY [at] hh:mm a')}
                                                </div>
                                            </div>
                                        </div>
                                        {item.childComments && item.childComments.length > 0 && (
                                            <div className="hover-child-comment-container">
                                                {item.childComments.map((childComment: any, idx: number) => (
                                                    <div key={idx}>
                                                        <div className="custom-row comment-row" key={item.id}>
                                                            <Avatar
                                                                userId={childComment?.createdBy?.id}
                                                                userName={childComment?.createdBy?.displayName}
                                                                initialColor={PersonaInitialsColor.coolGray}
                                                                imageSize={32}
                                                                roundedPic={true}
                                                            />
                                                            <div className="comment-detail">
                                                                <div className="comment-display-name">
                                                                    {childComment.createdBy.displayName}{' '}
                                                                    <span
                                                                        className="comment-text"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: generateCommentMessage(
                                                                                childComment.commentText,
                                                                                childComment.taggedUsers
                                                                            )
                                                                        }}></span>
                                                                </div>
                                                                <div className="comment-time">
                                                                    {moment(childComment.createdOn).format(
                                                                        'MMM DD YYYY [at] hh:mm a'
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )
                        )}
                </div>
            )}
        </>
    );
}

export default Comments;
