import useStore from 'app/store';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { ApiRequestService } from './api-service';
import { IRequestParams, ICommentCriteria, IGetAttachment } from 'app/models/common/request';
import { ArticleStatus } from 'app/enums/Status.enum';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { ICommentsRequest } from 'app/modules/admin/visualFlow/VisualFlow';
import { ReportType } from 'app/enums/ReportType.enum';
import { IListQuery, IRequestPayload, IUseGenerateTokenParams } from 'app/models/common/post';
import { getProfileImageByUserId } from 'app/components/helpers';

/**
 *
 * @returns Deals with character data api
 * Call this method from anywhere
 * Caching handled by react query
 * TODO: Enforce strong typing
 */
export const useQueryAllDeviceList = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'fetchDevices',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.fetchDevices();
            return res.data;
        },
        { cacheTime: 0 }
    );
};

export const useQueryAllCountryList = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'fetchCountries',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.fetchCountries();
            return res.data;
        },
        { cacheTime: 0 }
    );
};

export const useRequestTypes = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('requestTypes', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.requestTypes();
        return res.data;
    });
};

//fetching msx data
export const useMsxData = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('msxData', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.msxData();
        return res.data;
    });
};

/**
 * Generates an Azure Blob Storage container SaS token to allow file uploads
 * @param params Parameters used to call the appropriate API route including the container name
 * @returns Azure Blob Storage container SaS token in the form of a URI
 */
export const useGenerateContainerSasToken = (params: IUseGenerateTokenParams) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('generateContainerSasToken', async () => {
        if (params.containerName !== undefined && params.containerName.length > 0) {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.generateContainerSasToken(params.entity, params.containerName);
            return res.data;
        }
    });
};

// get article file sas url
export const useGenerateFileSasToken = (entity: string, containerName: string, fileName: string = '') => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['generateFileSasToken', fileName], async () => {
            // done to avoid data.data nesting in container
            if (fileName !== undefined && fileName.length > 0) {
                const res: AxiosResponse = await apiRequestService.generateFileSasToken(entity, containerName, fileName);
                return res.data;
            }
        },
        { enabled: true }
    );
};

//admin requests data
export const useRequestData = (data: IRequestPayload) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['requestQuery', data],
        async () => {
            if (Object.entries(data).length !== 0) {
                // done to avoid data.data nesting in container

                let payloadData: IRequestPayload = { ...data };

                // payloadData = { dateRange: getDateRange(dateRange, activeRange), ...payloadData };

                if (!data.isStarredRequests) {
                    const res: AxiosResponse = await apiRequestService.fetchRequests({ ...payloadData });
                    return res.data;
                } else {
                    const res: AxiosResponse = await apiRequestService.fetchBookmarkedRequests({
                        ...payloadData
                    });
                    return res.data;
                }
            }
        },
        { cacheTime: 0 }
    );
};

// list builder data
export const useRequestListBuilder = (data: any) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['listBuilder', data],
        async () => {
            if (data?.filter?.teamFilters?.requestTypes.length > 0) {
                let payloadData: any = { ...data };

                const res: AxiosResponse = await apiRequestService.fetchListBuilderRequests({ ...payloadData });
                return res.data;
            }
        }
    )
}

export const useListSummary = (data: IListQuery[]) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['listSummary', data],
        async () => {
            if (data?.length > 0) {
                //let payloadData: any = { [...data] };

                const res: AxiosResponse = await apiRequestService.fetchListSummary(data);
                return res.data;
            }
        }
    )
}

export const useTeamLookUpData = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('teamsQuery', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.teamInfo();
        return res.data;
    });
};

//lookup for reasons for request reassignment

export const useReasonLookUpData = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('reasonsQuery', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.reasonsInfo();
        return res.data;
    });
};

export const useRequestTypesLookUpData = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('requestTypesQuery', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.requestTypeInfo();
        return res.data;
    });
};

//visual flow data
export const useVisualStepsData = (id: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'visual',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.visualSteps(id);
            return res.data;
        },
        { cacheTime: 0 }
    );
};

export const useNotifications = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const requestTypeMutation = useMutation((data: any) => apiRequestService.fetchNotifications(data.pageNumber, data.pageSize), {
        onSuccess: () => {
        }
    });
    return requestTypeMutation;
};

export const useNotificationsFull = (pageNumber: number, pageSize: number) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['notifications', 'pageNumber'], async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.fetchNotifications(pageNumber, pageSize);
        return res.data;
    });
};

export const useNotificationsCount = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useNotificationsCount'], async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.fetchNotificationsCount();
        return res.data;
    });
};
export const useVisualComments = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const requestTypeMutation = useMutation((data: ICommentsRequest) => apiRequestService.visualCommentsData(data), {
        onSuccess: () => {
        }
    });
    return requestTypeMutation;
};

//getting comments

export const useComments = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('comments', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.commentsData();
        return res.data;
    });
};

//searchCustomerData
export const useSearchCustomerData = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('searchCustomer', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.searchCustomer();
        return res.data;
    });
};

//article
export const useGetArticles = (
    pageNumber: number,
    pageSize: number,
    sortProperty: string = null,
    sortDirection: string = null,
    authorId: string = null,
    status: ArticleStatus = null,
    type: ArticleType = null,
    isRootTag: boolean = false,
    displayName: string = '',
    enabled: boolean = true
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getArticles', authorId, status, type, displayName, pageNumber],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getArticles(
                pageNumber,
                pageSize,
                sortProperty,
                sortDirection,
                status,
                type,
                authorId,
                isRootTag,
                displayName
            );
            return res.data;
        },
        { cacheTime: 0, enabled: enabled }
    );
};


//knowledge article
export const useGetKnowledgeArticles = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('getKnowledgeArticles', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getKnowledgeArticles();
        return res.data;
    });
};

export const useGetArticlesByType = (
    pageNumber: number,
    pageSize: number,
    sortProperty: string = null,
    sortDirection: string = null,
    authorId: string = null,
    status: ArticleStatus = null,
    type: ArticleType = null,
    isRootTag: boolean = false,
    displayName: string = '',
    enabled: boolean = true
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getArticles', authorId, status, type, displayName, pageNumber],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getArticlesByType(
                pageNumber,
                pageSize,
                sortProperty,
                sortDirection,
                status,
                type,
                authorId,
                isRootTag,
                displayName
            );
            return res.data;
        },
        { cacheTime: 0, enabled: enabled }
    );
};

//articles In the News
export const useGetArticlesInTheNews = (
    status: ArticleStatus,
    type: ArticleType,
    filterHomePageArticles: boolean,
    pageNumber: number,
    pageSize: number,
    sortDirection: number,
    sortProperty: string
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['getArticlesInTheNews', status, type, filterHomePageArticles], async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getArticlesInTheNews(
            status,
            type,
            filterHomePageArticles,
            pageNumber,
            pageSize,
            sortDirection,
            sortProperty
        );
        return res.data;
    });
};

//banner articles
export const useGetBannerArticle = (
    status: ArticleStatus,
    filterBannerArticle: boolean,
    pageNumber: number,
    pageSize: number,
    sortDirection: number,
    sortProperty: string
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['getBannerArticle', status, filterBannerArticle], async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getBannerArticle(
            status,
            filterBannerArticle,
            pageNumber,
            pageSize,
            sortDirection,
            sortProperty
        );
        return res.data;
    });
};

//Knowledge center Home page articles
export const useKnowledgeCenterHomePageArticles = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'getHomePageArticles',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getKnowledgeCenterHomePageArticles();
            return res.data;
        },
        { cacheTime: 0 }
    );
};

//News feed Home page articles
export const useNewsFeedHomePageArticles = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'getHomePageArticles',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getNewsFeedHomePageArticles();
            return res.data;
        },
        { cacheTime: 0 }
    );
};

//article templates
export const useGetArticleTemplates = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'getArticleTemplates',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getArticleTemplates();
            return res.data;
        },
        { enabled: false, cacheTime: 0 }
    );
};
//Get article templates
export const useGetArticleTemplateById = (templateId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getArticleTemplateById', templateId],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getArticleTemplateById(templateId);
            return res.data;
        },
        { enabled: templateId ? true : false }
    );
};

export const useGetAuthorById = (authorId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getAuthorById', authorId],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getAuthorById(authorId);
            return res.data;
        },
        { enabled: authorId?.trim() ? true : false }
    );
};

//Related articles
export const useGetRelatedArticles = (articleId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getRelatedArticles', articleId],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getRelatedArticles(articleId);
            return res.data;
        },
        { enabled: articleId?.trim() ? true : false }
    );
};

//Featured Authors
export const useGetAuthors = (sortDirection: number, pageNumber: number, pageSize: number, sortProperty?: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('getAuthors', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getAuthors(sortDirection, pageNumber, pageSize, sortProperty);
        return res.data;
    });
};
//tags
export const useGetArticleCategories = () => {
    const apiRequestService = ApiRequestService.createInstance();
    const setArticleCategories = useStore((state: any) => state.setArticleCategories);
    return useQuery('getArticleCategories', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getArticleCategories();
        setArticleCategories(res.data);
        return res.data;
    });
};

export const useGetArticle = (articleId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getArticle', articleId],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getArticle(articleId);
            return res.data;
        },
        { refetchOnMount: false, enabled: articleId ? true : false, cacheTime: 0 }
    );
};

export const useGetFeedback = (subUrl: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'getFeedback',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getFeedback(subUrl);
            return res.data;
        },
        { cacheTime: 0 }
    );
};

export const useGetCount = (subUrl: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getSummaryCount', subUrl],
        async () => {
            // done to avoid data.data nesting in container
            if (subUrl !== '') {
                const res: AxiosResponse = await apiRequestService.getSummaryCardCount(subUrl);
                return res.data;
            }
        },
        { cacheTime: 0 }
    );
};

/**
 * Module: End User Unified Dashboard
 * Custom hook to get list of requests for end user unified dashboard
 */
export const useRequestList = (requestFilter: IRequestParams, queryConfig?: UseQueryOptions<any, unknown>) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useRequestList', requestFilter],
        async () => {
            if (Object.keys(requestFilter).length !== 0) {
                const res: AxiosResponse = await apiRequestService.getRequestList(requestFilter);
                // done to avoid data.data nesting in container
                return res.data;
            }
        },
        { ...queryConfig, cacheTime: 0 }
    );
};

/**
 * Module: End User Unified Dashboard
 * Custom hook to get list of requests for end user unified dashboard
 */
export const useGetRequestById = (requestId: string, queryConfig?: UseQueryOptions<any, unknown>) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getRequestById', requestId],
        async () => {
            if (requestId) {
                // done to avoid data.data nesting in container
                const res: AxiosResponse = await apiRequestService.getRequestDetails(requestId);
                return res;
            }
        },
        queryConfig
    );
};

/**
 * Module: Admin Team Contact Details
 * Custom hook to get list of team members and info about each of them
 */
export const useTeamContactList = (teamId: number, count: number) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useTeamContactList', teamId, count], async () => {
        const res: AxiosResponse = await apiRequestService.getTeamDetails(teamId, count, '');
        // done to avoid data.data nesting in container
        return res.data;
    });
};

//getting speech to text token
export const useSpeechToTextToken = () => {
    const time = 1000 * 60 * 8;
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        'speechToken',
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.speechToken();
            return res.data;
        },
        {
            onSuccess: (res) => {
                sessionStorage.setItem('speech-token', JSON.stringify(res));
            },
            cacheTime: time,
            refetchInterval: time,
            refetchIntervalInBackground: true
        }
    );
};

/**
 * Module: Request Assignment/Comments People Picker
 * Custom hook to get list of users */
export const useGetUsersList = (
    searchValue: string,
    isDomainSpecific: boolean,
    type: 'Admin' | null = null,
    team?: number,
    count?: number
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['getUsersList', searchValue, team], async () => {

            if (!isDomainSpecific) {
                const res: AxiosResponse = await apiRequestService.getUsersList(searchValue, type);
                // done to avoid data.data nesting in container
                return res.data;
            } else {
                const res: AxiosResponse = await apiRequestService.getTeamDetails(team, count, type, searchValue);
                // done to avoid data.data nesting in container
                return res.data;
            }

    });
};

export const useGetNewRequest = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetNewRequest'],
        async () => {
            const res: AxiosResponse = await apiRequestService.getNewRequest();
            // done to avoid data.data nesting in container
            return res.data;
        },
        { cacheTime: 0 }
    );
};

export const useGetCountries = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useGetCountries'], async () => {
        const res: AxiosResponse = await apiRequestService.getCountries();
        // done to avoid data.data nesting in container
        return res.data;
    });
};

export const useGetPopularArticles = (status: ArticleStatus, articleType: ArticleType, enabled: boolean = true) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['getPopularArticles', status, articleType],
        async () => {
            const res: AxiosResponse = await apiRequestService.getPopularArticles(status, articleType);
            // done to avoid data.data nesting in container
            return res.data;
        },
        { enabled: enabled }
    );
};

export const useFetchComments = (criteria: ICommentCriteria) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useFetchComments', criteria], async () => {
        if (Object.keys(criteria).length !== 0) {
            const res: AxiosResponse = await apiRequestService.fetchComments(criteria);
            // done to avoid data.data nesting in container
            return res.data;
        }
    });
};

export const useSummaryCardsCountForUser = (subUrl: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useSummaryCardsCountForUser', subUrl], async () => {
        // done to avoid data.data nesting in container
        if (subUrl !== '' && subUrl) {
            const res: AxiosResponse = await apiRequestService.getSummaryCardCountForUser(subUrl);
            return res.data;
        }
    });
};

export const useGetAttachments = (requestData: IGetAttachment) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetAttachments'],
        async () => {
            // done to avoid data.data nesting in container
            if (requestData) {
                const res: AxiosResponse = await apiRequestService.getAttachments(requestData);
                return res.data;
            }
        },
        { cacheTime: 0 }
    );
};

export const useGetUserDetails = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetUserDetails'],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getUserDetails();
            return res.data;
        },
        { cacheTime: 0 }
    );
};

export const useGetUserPreferences = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetUserPreferences'],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getUserPreferences();
            return res.data;
        },
        { cacheTime: 0 }
    );
};
export const useFetchUsersListByRole = (team?: number) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useFetchUsersListByRole', team], async () => {
        // done to avoid data.data nesting in container

        const res: AxiosResponse = await apiRequestService.fetchUsersList(team);
        return res.data;
    });
};

//list of reports
export const useGetReports = (
    pageNumber: number,
    pageSize: number,
    searchFilter?: string,
    sortProperty: 'DisplayName' | 'Type' | 'ModifiedOn' = 'ModifiedOn',
    sortDirection: string = '1',
    filterBookmarkedReports?: boolean,
    reportType?: ReportType
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetReports', pageNumber, pageSize, sortProperty, sortDirection, filterBookmarkedReports, reportType],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getReports(
                pageNumber,
                pageSize,
                searchFilter,
                sortProperty,
                sortDirection,
                filterBookmarkedReports,
                reportType
            );
            return res.data;
        },
        { cacheTime: 0, enabled: false }
    );
};

//get user has bookmarked reports
export const useGetUserHasBookmarkedReports = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetUserHasBookmarkedReports'],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getUserHasBookmarkedReports();
            return res.data;
        },
        { cacheTime: 0 }
    );
};
//get reports by userId
export const useGetReportsByUserId = (userId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetReportsByUserId', userId],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getReportsByUser(userId);
            return res.data;
        },
        { enabled: userId.trim() ? true : false }
    );
};

//get reports by id
export const useGetReportById = (reportId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetReportById', reportId],
        async () => {
            // done to avoid data.data nesting in container
            const res: AxiosResponse = await apiRequestService.getReportById(reportId);
            return res.data;
        },
        { enabled: reportId?.trim() ? true : false }
    );
};

// get request details lookup
export const useRequestDetailsLookUpData = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery('useRequestDetailsLookUpData', async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getRequestDetailsLookupInfo();
        return res.data;
    });
};

// get status
export const useFetchStatus = (criteria: ICommentCriteria) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['useFetchStatus', criteria], async () => {
        if (Object.keys(criteria).length !== 0) {
            const res: AxiosResponse = await apiRequestService.fetchStatus(criteria);
            // done to avoid data.data nesting in container
            return res.data;
        }
    });
};

//get tags
export const useGetTagsList = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(['getTagsList'], async () => {
        // done to avoid data.data nesting in container
        const res: AxiosResponse = await apiRequestService.getTagsList();
        return res.data;
    });
};

//get webSearchLinks

export const useGetWebSearchLinks = (term: string) => {
    const apiRequestService = ApiRequestService.createInstance();

    return useQuery(['getWebSearchLinks', term?.trim()], async () => {
        // done to avoid data.data nesting in container
        if (term?.trim()) {
            const res: AxiosResponse = await apiRequestService.getWebSearchLinks(term?.trim());
            return res.data;
        }
    });
};

//get IntranetSearchLinks
export const useGetIntranetSearchLinks = (term: string) => {
    const apiRequestService = ApiRequestService.createInstance();

    return useQuery(['getIntranetSearchLinks', term?.trim()], async () => {
        // done to avoid data.data nesting in container
        if (term?.trim()) {
            const res: AxiosResponse = await apiRequestService.getIntranetSearchLinks(term?.trim());
            return res.data;
        }
    });
};

//get all emails
export const useGetEmailsList = (
    pageNumber: number,
    pageSize: number,
    sortProperty: string = null,
    sortDirection: number = null
) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetEmailsList', pageNumber],
        async () => {
            // done to avoid data.data nesting in container

            const res: AxiosResponse = await apiRequestService.getEmailList(pageNumber, pageSize, sortProperty, sortDirection);
            return res.data;
        },
        { cacheTime: 0, enabled: false }
    );
};
//get email by id
export const useGetEmailById = (emailId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetEmailById', emailId],
        async () => {
            // done to avoid data.data nesting in container
            if (emailId.trim() !== '') {
                const res: AxiosResponse = await apiRequestService.getEmailById(emailId);
                return res.data;
            }
        },
        { enabled: false }
    );
};
//get all email templates
export const useGetEmailTemplatesList = () => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetEmailTemplatesList'],
        async () => {
            // done to avoid data.data nesting in container

            const res: AxiosResponse = await apiRequestService.getEmailTemplates();
            return res.data;
        },
        { enabled: false, cacheTime: 0 }
    );
};
//get email by id
export const useGetEmailTemplateById = (emailId: string) => {
    const apiRequestService = ApiRequestService.createInstance();
    return useQuery(
        ['useGetEmailTemplateById', emailId],
        async () => {
            // done to avoid data.data nesting in container
            if (emailId.trim() !== '') {
                const res: AxiosResponse = await apiRequestService.getEmailTemplateById(emailId);
                return res.data;
            }
        },
        { enabled: false }
    );
};

// get user profile pictures from Graph
// set cache time to 2 hours (milliseconds), any profile pictures that have not been rendered in that time are dumped
export const useGetUserProfilePicture = (userId: string) => {
    return useQuery(
        userId,
        async () => {
            return await getProfileImageByUserId(userId);
        },
        { staleTime: Infinity, cacheTime: 7200000 }
    );
};

// gets a list of queries saved by a user
export const useGetUserSavedLists = () => {
    const apiRequestService = ApiRequestService.createInstance();

    return useQuery(['useGetUserSavedLists'], async () => {
        const res: AxiosResponse = await apiRequestService.getUserSavedLists();
        return res.data;
    }, { enabled: true });
};

// gets a list of all ticket submitters
export const useGetSubmitters = () => {
    const apiRequestService = ApiRequestService.createInstance();

    return useQuery(['useGetSubmitters'], async () => {
        const res: AxiosResponse = await apiRequestService.getSubmitters();
        return res.data;
    }, { enabled: true });
};
