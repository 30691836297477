import React, { useState, useEffect } from 'react';
import { Text } from '@fluentui/react';
import './TopNews.scss';
import { Trans } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import NewsCard from '../../newsCard/NewsCard';
interface IProps {
    articles?: any;
}
const TopNews: React.FC<IProps> = (props: IProps) => {
    const { articles } = props;
    const [topStoriesData, setTopStoriesData] = useState([]);
    const [topStoriesRotated] = useState(true);

    useEffect(() => {
        setTopStoriesData(articles?.topStories);
    }, [articles]);

    const settings = {
        dots: false,
        infinite: topStoriesData.length > 5,
        slidesToShow: topStoriesData.length < 5 ? topStoriesData.length : 5,
        slidesToScroll: 1,
        arrows: false,
        autoplaySpeed: 5000,
        autoplay: true,
        vertical: true,
        pauseOnFocus: true
    };

    return (
        <>
            <div className="container news-section">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl9">
                            <div className="ms-Grid left" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        {articles?.latestFromSurface[0] && (
                                            <NewsCard
                                                size="news-card--wide"
                                                data={articles?.latestFromSurface[0]}
                                                showMoreOptions={false}
                                                showLastEdited={false}
                                                showStatus={false}
                                                showArticleType={false}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6 p-r-20">
                                        {articles?.latestFromSurface[1] && (
                                            <NewsCard
                                                className="m-b-0 latest--card"
                                                data={articles?.latestFromSurface[1]}
                                                showMoreOptions={false}
                                                showLastEdited={false}
                                                showStatus={false}
                                                showArticleType={false}
                                            />
                                        )}
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6  p-l-20">
                                        {articles?.latestFromSurface[2] && (
                                            <NewsCard
                                                className="m-b-0 latest--card"
                                                data={articles?.latestFromSurface[2]}
                                                showMoreOptions={false}
                                                showLastEdited={false}
                                                showStatus={false}
                                                showArticleType={false}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl3">
                            {topStoriesData?.length > 0 && (
                                <Text className="article-heading">
                                    <Trans>Top Stories This Week</Trans>
                                </Text>
                            )}
                            <div className="top-stories-container">
                                <Slider {...settings} aria-hidden="true">
                                    {topStoriesRotated &&
                                        topStoriesData?.length > 0 &&
                                        topStoriesData.map((data) => (
                                            <NewsCard
                                                key={data.id}
                                                hideCoverImage={true}
                                                hideDescription={true}
                                                size="news-card--small"
                                                data={data}
                                                showMoreOptions={false}
                                                showLastEdited={false}
                                                showStatus={false}
                                                showArticleType={false}
                                            />
                                        ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopNews;
