import React from 'react';
import { Text } from '@fluentui/react';
import './AgingProgress.scss';
import { calculateAging } from 'app/components/helpers';

interface IProps {
    startDate: string;
    endDate: string;
    closedDate?: string;
    status?: number;
}

const AgingProgress: React.FC<IProps> = ({ startDate, endDate, closedDate, status }) => {
    const { type, progress, difference, units } = calculateAging(startDate, endDate, closedDate, status);
    return (
        <div className="aging-progress-container">
            <span className={`aging-progress-wrap aging-type-${type}`}>
                <span className="aging-progress" style={{ width: progress + `%` }}></span>
            </span>
            <Text className="m-l-5">
                {difference}
                {units}
            </Text>
        </div>
    );
};

export default AgingProgress;
