import React from 'react';
import { TextField, Text } from '@fluentui/react';

interface IProps {
    cssCaseNum: string;
    setCSSCaseNum: Function;
    caseNumHText: boolean;
    setCaseNumHText: Function;
    animateReviewCustomer: string;
}
export const ReviewCustomer: React.FC<IProps> = ({
    cssCaseNum,
    setCSSCaseNum,
    caseNumHText,
    setCaseNumHText,
    animateReviewCustomer
}) => {
    return (
        <div
            className={`${animateReviewCustomer === 'S_TO_NEXT' ? 'nextSlide' : ''} ${
                animateReviewCustomer === 'S_TO_PREV' ? 'prevSlide' : ''
            } ${animateReviewCustomer === 'A_TO_RIGHT' ? 'animateToRIght' : ''} ${
                animateReviewCustomer === 'A_TO_LEFT' ? 'animateToLeft' : ''
            }`}>
            <label>CSS Case Number</label>
            <TextField
                value={cssCaseNum}
                onChange={(e: any) => {
                    setCSSCaseNum(e.target.value);
                    setCaseNumHText(false);
                }}
                className={`regular-text-field ${caseNumHText ? 'error-feild' : ''}`}
                placeholder="eg: 120072024000000"
                data-testid="text1-test"
            />
            {caseNumHText && <Text className="helper-txt">Please enter a CSS case number.</Text>}
        </div>
    );
};
