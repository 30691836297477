import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Icon, SearchBox, Text } from '@fluentui/react';
import './TagsPicker.scss';
import { useGetTagsList } from 'app/services/queries';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';
import { keyPress } from 'app/components/helpers';
import { StringConstants } from 'app/utils/constants';
import { ITagsProps } from 'app/models/common/response';

interface IProps {
    selectedKeys: any[];
    setSelectedKeys: Function;
    selectedOptions: any[];
    setSelectedOptions: Function;
    multiSelectData?: any[];
    inEditMode?: boolean;
}

const TagsPicker: React.FC<IProps> = ({
    selectedKeys,
    setSelectedKeys,
    selectedOptions,
    setSelectedOptions,
    multiSelectData,
    inEditMode
}) => {
    const dropDownRef = useRef(null);

    // state
    const [options, setOptions] = useState<IOptionProps[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IOptionProps[]>([]);

    // queries
    const { data: tagsData, isLoading: isTagsLoading } = useGetTagsList();

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };

    useDropDownDismiss(dropDownRef, onToggleShow);

    // use effects

    // for autopopulation in edit mode
    useEffect(() => {
        if (multiSelectData.length > 0 && inEditMode && tagsData?.length > 0) {
            const options = formatIntoOptions(tagsData);
            const keys = options.filter((option: any) => multiSelectData.includes(option.id));
            setSelectedKeys(keys);
            setSelectedOptions(keys.map((key: any) => key.id));
        }
    }, [inEditMode, multiSelectData, tagsData]);

    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IOptionProps[] = [];
            options.forEach((item) => {
                if (item.text.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys?.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.key !== 0)
            )
        ) {
            setSelectedKeys(options.slice(0));
        }
    }, [options, selectedKeys]);

    useEffect(() => {
        if (!isTagsLoading) {
            setOptions(formatIntoOptions(tagsData));
        }
    }, [tagsData, isTagsLoading]);

    const isChecked = (ele: number) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };

    const checkEquality = (arr1: IOptionProps[], arr2: IOptionProps[]) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.key === arr1[i].key).length === 0) {
                return false;
            }
        }
        return true;
    };

    const removeDuplicates = (arr: string[]) => {
        let uniqueArr = arr.filter((ele, index) => {
            return arr.indexOf(ele) === index;
        });
        return uniqueArr;
    };

    const formatIntoOptions = (data: ITagsProps[]) => {
        let arr: any[] = [{ key: 0, text: StringConstants.UPPERCASE_ALL }];
        let index = 1;
        data?.forEach((item) => {
            let childArray: number[] = [];
            let parentIndex = index;
            childArray.push(index);
            index = index + 1;
            arr.push({ key: parentIndex, text: item.name, id: item.id });
        });
        return arr;
    };

    const getLabel = () => {
        if (selectedKeys?.filter((ele) => ele.key === 0).length !== 0 || selectedKeys?.length === 0) {
            return <div className="spacing-container">Tags</div>;
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                let element = options.filter((ele) => ele.key === item.key)[0];
                if (element?.parentProperty) {
                    if (selectedKeys.filter((ele) => ele.key === element?.parentProperty).length !== 0)
                        arr.push(options.filter((it) => it.key === element?.parentProperty)[0].text);
                    else arr.push(element?.text);
                } else {
                    arr.push(element?.text);
                }
            });
            let res = removeDuplicates(arr);
            return res.join(', ');
        }
    };

    // For tags, the back-end query checks against the database ID of the tag
    // We must ensure that selectedOptions is set to any array of IDs of the selected tags
    const onChange = (key: number) => {
        if (key === 0) {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: any[] = [];
                options.forEach((ele) => arr.push(ele));
                setSelectedKeys(arr);
                setSelectedOptions([...arr.filter((option) => option.text !== StringConstants.UPPERCASE_ALL).map((item) => item.id)]);
            } else {
                setSelectedKeys([]);
                setSelectedOptions([]);
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.key === key)[0]];
                setSelectedKeys(tempSelectedKeys);
                setSelectedOptions([...tempSelectedKeys.map((item) => item.id)]);
                setSearchedResults(options);
            } else {
                const tempSelectedKeys = selectedKeys.filter((item) => item.key !== key && item.text !== StringConstants.UPPERCASE_ALL);
                setSelectedOptions([...tempSelectedKeys.map((item) => item.id)]);
                setSelectedKeys(tempSelectedKeys);
            }
        }
    };

    return (
        <div className="tags-picker-cont">
            <div
                className="tags-title-lb row-flex-box align-center"
                tabIndex={0}
                data-testid="pdt-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <Text className="tags-label-lb">{getLabel()}</Text> <Icon iconName="ChevronDown" />
            </div>

            {showDropdown && !isTagsLoading && (
                <div ref={dropDownRef} className="tags-dropdown-cont-lb position-cont">
                    <div
                        tabIndex={0}
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        data-testid="pdt-filter-close-btn-test"
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center justify-sb tags-header">
                        <Text>Tags</Text>
                        <Icon iconName="ChevronUp" />
                    </div>
                    <div className="search-bar-tags-picker">
                        {selectedKeys.filter((ele) => ele.key === 0).length !== 0 ? (
                            <div className="tags-cont-lb">
                                <div className="tags-item-lb">
                                    <Text>Tags</Text>{' '}
                                    <Icon
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                onChange(0);
                                            }
                                        }}
                                        data-testid="tag-all-test"
                                        onClick={() => onChange(0)}
                                        iconName="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="tags-cont-lb">
                                {selectedKeys.map((ele) => (
                                    <div className="tags-item-lb">
                                        <Text>
                                            {ele.text}
                                            {ele.dependentProperties ? ' - All' : ''}
                                        </Text>
                                        <Icon
                                            tabIndex={0}
                                            onKeyPress={(e: any) => {
                                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                    onChange(ele.key as number);
                                                }
                                            }}
                                            onClick={() => onChange(ele.key as number)}
                                            iconName="Cancel"
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <SearchBox
                            placeholder="Search"
                            tabIndex={0}
                            data-testid="search-field-test"
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(item.key as number);
                                        }
                                    }}
                                    className={`tags-dropdown-item-lb ${
                                        item.parentProperty &&
                                        searchedResults.filter((ele) => ele.key === item.parentProperty).length !== 0
                                            ? 'child-item'
                                            : ''
                                    }  ${item.dependentProperties ? 'parent-item' : ''}`}>
                                    <Checkbox
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() => onChange(item.key as number)}
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(item.key as number);
                                        }
                                    }}
                                    className="tags-dropdown-item-lb parent-item">
                                    <Checkbox
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() => onChange(item.key as number)}
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default TagsPicker;
