import React, { useRef, useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Link,
    PersonaInitialsColor,
    PrimaryButton,
    Text
} from '@fluentui/react';
import { changeDateFormat } from 'app/components/helpers';
import { ICommentResponse, IRequest } from 'app/models/common/response';
import CommentField from 'app/components/commentField/CommentField';
import { IRemoveCommentData } from 'app/models/common/request';
import { useAddCommentEmote, useRemoveComment } from 'app/services/mutations';
import { useBoolean } from '@fluentui/react-hooks';
import { Emote, IAddEmote, IUserDetails } from 'app/models/common/post';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { useLocation } from 'react-router-dom';
import { EmoteReaction } from 'app/enums/Emote.enum';
import Avatar from 'app/components/avatar/Avatar';

interface IProps {
    item?: ICommentResponse;
    correlationId: string;
    isInternal?: boolean;
    isMultipleType?: boolean;
    selectedRequest?: IRequest;
    toggleEdit?: Function;
    isEditActive?: boolean;
    isClosed?: boolean;
}

const dialogContentProps = {
    type: DialogType.normal,

    closeButtonAriaLabel: 'Close',
    subText: 'Do you want to delete this comment?'
};
const ReplyPanel: React.FC<IProps> = ({ item, correlationId, isInternal, selectedRequest, toggleEdit, isClosed = false }) => {
    const emojiList = [
        { emoji: '👍', reaction: EmoteReaction.Like },
        { emoji: '❤️', reaction: EmoteReaction.Love },
        { emoji: '😆', reaction: EmoteReaction.Laugh },
        { emoji: '😮', reaction: EmoteReaction.Shock },
        { emoji: '😡', reaction: EmoteReaction.Angry }
    ];

    const [isEmoting, setIsEmoting] = useState<boolean>(false);
    const [isViewingEmoteUsers, setIsViewingEmoteUsers] = useState<boolean>(false);
    const [viewedUsers, setViewedUsers] = useState<IUserDetails[]>([]);
    const [reactionTitle, setReactionTitle] = useState<string>('');

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isReplyActive, setIsReplyActive] = useState(false);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails as IUserDetails);
    const { mutate: removeCommentMutation } = useRemoveComment();

    const toggleReply = () => {
        setIsReplyActive((value) => !value);
    };

    const handleRemoveComments = () => {
        let data: IRemoveCommentData = {};
        data.id = item.id;
        data.correlationId = correlationId;
        data.entityType = 1;
        removeCommentMutation(data);
        toggleHideDialog();
    };

    const { mutate: addCommentEmote } = useAddCommentEmote();

    const handleEmojiClick = (event: any, emojiObject: any) => {
        const emoteObject: Emote = {
            reaction: emojiObject.reaction,
            user: userDetails
        };

        const addEmoteObject: IAddEmote = {
            commentId: item.id,
            emote: emoteObject
        };
        addCommentEmote(addEmoteObject);
        setIsEmoting(false);
        setIsViewingEmoteUsers(false);
        if (!item.emotes.some((emote) => emote.reaction === emojiObject.reaction && emote.user.id === userDetails.id)) {
            const currentUserEmote = item.emotes.find((emote) => emote.user.id === userDetails.id);
            if (currentUserEmote) {
                item.emotes = item.emotes.filter((emote) => emote.user.id !== userDetails.id);
            }
            item.emotes.push(emoteObject);
        }
        else {
            item.emotes = item.emotes.filter((emote) => emote.user.id !== userDetails.id)
        }
    };

    const handleEmoteHover = (event: any, viewing: boolean, users: IUserDetails[], reactionTitle: string, emoting: boolean) => {
        setIsEmoting(emoting);
        setReactionTitle(reactionTitle);
        setIsViewingEmoteUsers(viewing);
        setViewedUsers(users);
    };

    const emojiMenu = useRef(null);

    const closeEmojiMenu = (event: any) => {
        if (emojiMenu.current && isEmoting && !emojiMenu.current.contains(event.target)) {
            setIsEmoting(false);
        }
    };
    document.addEventListener('mousedown', closeEmojiMenu);

    const isCurrentUsersComment = (id: string) => {
        return id === userDetails?.id;
    };

    const { pathname } = useLocation();

    return (
        <>
            <div className="cmt-action">
                <div className="cmt-emoticon">
                    {isEmoting && (
                        <div className="emoji-dialog">
                            <div
                                className="emoji-dialog-space"
                                onMouseEnter={() => setIsEmoting(true)}
                                onMouseLeave={() => setIsEmoting(false)}></div>
                            <div
                                className="emoji-suggestions"
                                onMouseEnter={() => setIsEmoting(true)}
                                onMouseLeave={() => setIsEmoting(false)}
                                ref={emojiMenu}>
                                {emojiList.map((emojiObject, index) => {
                                    return (
                                        <button
                                            key={index}
                                            onClick={(event) => {
                                                handleEmojiClick(event, emojiObject);
                                            }}>
                                            {emojiObject.emoji}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {item.isActive && 
                    !isClosed && 
                    item.emotes.length <= 0 ? (
                        <Link
                            className="action-items"
                            data-testid="reply-one"
                            tabIndex={0}
                            onMouseEnter={() => setIsEmoting(true)}>
                            <i className="ms-Icon ms-Icon--Emoji2"></i>
                        </Link>
                    ) : (
                        <div className="cmt-emote">
                            <div className="emote-wrap">
                                {item.emotes.filter((emote) => emote.reaction === EmoteReaction.Like).length > 0 && (
                                    <div
                                        className="emote-reaction"
                                        onMouseEnter={(event) => {
                                            handleEmoteHover(
                                                event,
                                                true,
                                                item.emotes
                                                    .filter((emote) => emote.reaction === EmoteReaction.Like)
                                                    .map((emote) => emote.user),
                                                '👍 Like reaction',
                                                true
                                            );
                                        }}
                                        onMouseLeave={(event) => {
                                            handleEmoteHover(event, false, [], '', false);
                                        }}
                                        onClick={(event) => {
                                            handleEmojiClick(event, { reaction: EmoteReaction.Like });
                                        }}>
                                        👍{item.emotes.filter((emote) => emote.reaction === EmoteReaction.Like).length}
                                    </div>
                                )}
                                {item.emotes.filter((emote) => emote.reaction === EmoteReaction.Love).length > 0 && (
                                    <div
                                        className="emote-reaction"
                                        onMouseEnter={(event) => {
                                            handleEmoteHover(
                                                event,
                                                true,
                                                item.emotes
                                                    .filter((emote) => emote.reaction === EmoteReaction.Love)
                                                    .map((emote) => emote.user),
                                                '❤️ Love reaction',
                                                true
                                            );
                                        }}
                                        onMouseLeave={(event) => {
                                            handleEmoteHover(event, false, [], '', false);
                                        }}
                                        onClick={(event) => {
                                            handleEmojiClick(event, { reaction: EmoteReaction.Love });
                                        }}>
                                        ❤️{item.emotes.filter((emote) => emote.reaction === EmoteReaction.Love).length}
                                    </div>
                                )}
                                {item.emotes.filter((emote) => emote.reaction === EmoteReaction.Laugh).length > 0 && (
                                    <div
                                        className="emote-reaction"
                                        onMouseEnter={(event) => {
                                            handleEmoteHover(
                                                event,
                                                true,
                                                item.emotes
                                                    .filter((emote) => emote.reaction === EmoteReaction.Laugh)
                                                    .map((emote) => emote.user),
                                                '😆 Laugh reaction',
                                                true
                                            );
                                        }}
                                        onMouseLeave={(event) => {
                                            handleEmoteHover(event, false, [], '', false);
                                        }}
                                        onClick={(event) => {
                                            handleEmojiClick(event, { reaction: EmoteReaction.Laugh });
                                        }}>
                                        😆{item.emotes.filter((emote) => emote.reaction === EmoteReaction.Laugh).length}
                                    </div>
                                )}
                                {item.emotes.filter((emote) => emote.reaction === EmoteReaction.Shock).length > 0 && (
                                    <div
                                        className="emote-reaction"
                                        onMouseEnter={(event) => {
                                            handleEmoteHover(
                                                event,
                                                true,
                                                item.emotes
                                                    .filter((emote) => emote.reaction === EmoteReaction.Shock)
                                                    .map((emote) => emote.user),
                                                '😮 Shock reaction',
                                                true
                                            );
                                        }}
                                        onMouseLeave={(event) => {
                                            handleEmoteHover(event, false, [], '', false);
                                        }}
                                        onClick={(event) => {
                                            handleEmojiClick(event, { reaction: EmoteReaction.Shock });
                                        }}>
                                        😮{item.emotes.filter((emote) => emote.reaction === EmoteReaction.Shock).length}
                                    </div>
                                )}
                                {item.emotes.filter((emote) => emote.reaction === EmoteReaction.Angry).length > 0 && (
                                    <div
                                        className="emote-reaction"
                                        onMouseEnter={(event) => {
                                            handleEmoteHover(
                                                event,
                                                true,
                                                item.emotes
                                                    .filter((emote) => emote.reaction === EmoteReaction.Angry)
                                                    .map((emote) => emote.user),
                                                '😡 Angry reaction',
                                                true
                                            );
                                        }}
                                        onMouseLeave={(event) => {
                                            handleEmoteHover(event, false, [], '', false);
                                        }}
                                        onClick={(event) => {
                                            handleEmojiClick(event, { reaction: EmoteReaction.Angry });
                                        }}>
                                        😡{item.emotes.filter((emote) => emote.reaction === EmoteReaction.Angry).length}
                                    </div>
                                )}
                            </div>
                            {isViewingEmoteUsers && (
                                <div className="emote-users" id="user-container">
                                    <div className="reaction-title">{reactionTitle}</div>
                                    {viewedUsers.map((user, index) => {
                                        return (
                                            <div key={index} className="emote-user-item">
                                                <Avatar
                                                    userId={user.id}
                                                    userName={user.displayName}
                                                    initialColor={PersonaInitialsColor.coolGray}
                                                    imageSize={32}
                                                    roundedPic={true}
                                                />
                                                <Text>{user.displayName}</Text>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {item.isActive && !isClosed && (
                    <>
                        <Text className="action-items">
                            <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                        </Text>
                        <Link role="link" className="action-items" data-testid="reply-one" tabIndex={0} onClick={toggleReply}>
                            Reply
                        </Link>
                    </>
                )}

                {isCurrentUsersComment(item.createdBy?.id) &&
                    ((!pathname.includes('unified-dashboard') && !pathname.includes('request/')) ||
                        isCurrentUsersComment(item.createdBy?.id)) &&
                    item.isActive &&
                    !isClosed && (
                        <>
                            <Text className="action-items">
                                <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                            </Text>
                            <Link className="action-items" data-testid="reply-one" tabIndex={0} onClick={() => toggleEdit()}>
                                Edit
                            </Link>
                        </>
                    )}

                {(isAdmin(userDetails?.userRoles) ||
                    isSuperAdmin(userDetails?.userRoles) ||
                    isCurrentUsersComment(item.createdBy?.id)) &&
                    ((!pathname.includes('unified-dashboard') && !pathname.includes('request/')) ||
                        isCurrentUsersComment(item.createdBy?.id)) &&
                    item.isActive &&
                    !isClosed && (
                        <>
                            <Text className="action-items">
                                <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                            </Text>
                            <Link
                                className="action-items"
                                data-testid="reply-one"
                                tabIndex={0}
                                onClick={() => toggleHideDialog()}>
                                Delete
                            </Link>
                            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps}>
                                <DialogFooter>
                                    <PrimaryButton onClick={() => handleRemoveComments()} text="Delete" />
                                    <DefaultButton onClick={() => toggleHideDialog()} text="Cancel" />
                                </DialogFooter>
                            </Dialog>
                        </>
                    )}

                {item.isActive && !isClosed && (
                    <Text className="action-items">
                        <i className="ms-Icon ms-Icon--LocationDot" aria-hidden="true"></i>
                    </Text>
                )}
                <Text className="action-items">
                    {changeDateFormat(item.createdOn, 'MMM DD YYYY')} at {changeDateFormat(item.createdOn, 'hh:mm a')}
                </Text>
            </div>
            {isReplyActive && correlationId && item && (
                <>
                    <CommentField
                        correlationId={item?.correlationId}
                        entityType={1}
                        parentCommentId={item?.id}
                        cancelOperation={toggleReply}
                        textareaSize={1}
                        className="comment-reply-field-container"
                        isInternal={isInternal}
                        fieldType="comment"
                        selectedRequest={selectedRequest}
                    />
                </>
            )}
        </>
    );
};

export default ReplyPanel;
