import { IRequest } from 'app/models/common/response';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from '@fluentui/react';
import { IconButton, TextField, Text, TooltipHost, DirectionalHint } from '@fluentui/react';
import { ISOToDateTime, formatCurrency } from 'app/components/helpers';
import { isSuperAdmin } from 'app/utils/authUtilities';
import { IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
import './CustomerDetailsTab.scss';
import NumberFormat from 'react-number-format';
import { useId } from '@fluentui/react-hooks';
import { EditorState } from 'draft-js';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    selectedRequest: IRequest;
    toggleHideDialog: Function;
    setImpactForRemoval: Function;
    isEditMode?: boolean;
    companyInformation?: any;
    setCompanyInformation?: Function;
    customerDetailsValidation: { emailValidText: boolean; contactNumberValidText: boolean; alternateContactValidText: boolean };
    setCustomerDetailsValidation: Function;
    countyCodePrefix: { contactNumber: string; alternateContactNumber: string };
    setCountryCodePrefix: Function;
    countryList: any;
    onCompletedHandler?: Function;
}

const CustomerDetailsTab: React.FC<IProps> = (props) => {
    const {
        selectedRequest,
        companyInformation,
        customerDetailsValidation,
        setCustomerDetailsValidation,
        countyCodePrefix,
        setCountryCodePrefix
    } = props;
    const { toggleHideDialog, setImpactForRemoval, isEditMode, setCompanyInformation } = props;
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    // For edit mode to retain values on data refetches
    const [isEditingCompanyInformation, setIsEditingCompanyInformation] = useState<boolean>(false);
    const [companyNameFieldValue, setCompanyNameFieldValue] = useState<string>('');
    const [companyContactNameFieldValue, setCompanyContactNameFieldValue] = useState<string>('');
    const [companyEmailAddressFieldValue, setCompanyEmailAddressFieldValue] = useState<string>('');
    const [companyPhoneNumberFieldValue, setCompanyPhoneNumberFieldValue] = useState<string>('');
    const [companyAltPhoneNumberFieldValue, setCompanyAltPhoneNumberFieldValue] = useState<string>('');
    const [companyFullAddressFieldValue, setCompanyFullAddressFieldValue] = useState<string>('');

    // helper functions
    useEffect(()=> {
        setCompanyNameFieldValue(companyInformation.companyName);
        setCompanyEmailAddressFieldValue(companyInformation.contactEmailAddress);
        setCompanyContactNameFieldValue(companyInformation.companyContactName);
        setCompanyPhoneNumberFieldValue(companyInformation.contactPhoneNumber);
        setCompanyAltPhoneNumberFieldValue(companyInformation.companyAltPhoneNumber);
        setCompanyFullAddressFieldValue(companyInformation.companyFullAddress);
    }, [isEditMode]);

    const handleRemoveCustomerImpact = (item: any) => {
        toggleHideDialog();
        setImpactForRemoval(item);
    };

    const handleValueChange = (fieldName: string, newValue?: string) => {
        setIsEditingCompanyInformation(true);

        let companyInfo: any = {};
        companyInfo[fieldName] = newValue;

        if (fieldName === 'companyName') {
            setCompanyNameFieldValue(newValue);
        }
        else if (fieldName === 'companyContactName') {
            setCompanyContactNameFieldValue(newValue);
        }
        else if (fieldName ==='contactEmailAddress') {
            setCompanyEmailAddressFieldValue(newValue);
        }
        else if (fieldName === 'contactPhoneNumber') {
            setCompanyPhoneNumberFieldValue(newValue);
        }
        else if (fieldName === 'alternatePhoneNumber') {
            setCompanyAltPhoneNumberFieldValue(newValue);
        }
        else if (fieldName === 'companyFullShippingAddress') {
            setCompanyFullAddressFieldValue(newValue);
        }

        setCompanyInformation((value: any) => ({...value, ...companyInfo }));
    };
    const getData = (value: string, prefix: string) => {
        if (value && value.length > 2 && !value.includes(StringConstants.UNDEFINED)) {
            if (prefix && prefix !== StringConstants.UNDEFINED && prefix !== 'null') {
                return '+' + prefix + ' ' + value;
            } else {
                return value;
            }
        } else {
            return '--';
        }
    };
    const getTooltip = (city?: string, state?: string) => {
        let res: string[] = [];
        if (city) {
            res.push(city);
        }
        if (state) {
            res.push(state);
        }
        return res.join(', ');
    };
    const prepareOptions = (type: string) => {
        let options: Array<any> = [];
        if (
            (type === StringConstants.CONTACT_NUM &&
                (!countyCodePrefix.contactNumber || countyCodePrefix.contactNumber?.includes(StringConstants.UNDEFINED))) ||
            (type === StringConstants.ALT_CONTACT_NUM && !countyCodePrefix.alternateContactNumber) ||
            countyCodePrefix.alternateContactNumber?.includes(StringConstants.UNDEFINED)
        ) {
            options.push([
                <option value={null} selected disabled hidden>
                    Select the country
                </option>
            ]);
        }

        if (props.countryList) {
            props.countryList.forEach((item: any) => {
                options.push(
                    <option key={item.id} value={item.dialingCode}>
                        {item.name}
                        {' - +'}
                        {item.dialingCode}
                    </option>
                );
            });
        }
        return options;
    };
    const tooltipId = useId('tooltip');
    const urlString = "https://microsoftsales.crm.dynamics.com/main.aspx?appid=fe0c3504-3700-e911-a849-000d3a10b7cc&pagetype=entityrecord&etn=opportunity&id=";
    const handleLink = (opportunityId:string, backendOpportunityId: string) => {
        return (
            <Link target="_blank" href={urlString + backendOpportunityId}>
                {opportunityId}
            </Link>
    )}

    return (
        <>
            <div className="customer-details-cont full-width-cont">
                <div className=" row-flex-box full-width-cont">
                    <div className="col-flex full-width-cont">
                        {selectedRequest.isParent ? (
                            <>
                                <div className="m-b-25" />
                            </>
                        ) : (
                            <>
                                <div className="row-flex-box full-width-cont">
                                    <div className="col-flex  half-width-cont p-r-40">
                                        <div className="row-flex-box m-b-25">
                                            <div className="label-cont">
                                                <Trans>Customer Name: </Trans>
                                            </div>

                                            <div className="field-cont">
                                                {companyInformation?.companyName ? (
                                                    <TooltipHost
                                                        className="tooltip-text"
                                                        content={companyInformation?.companyName}
                                                        id={tooltipId}>
                                                        <Text className="limit-text">
                                                            {!isEditMode && companyInformation?.companyName}
                                                        </Text>
                                                    </TooltipHost>
                                                ) : (
                                                    <Text> {!isEditMode && !companyInformation?.companyName && '--'}</Text>
                                                )}

                                                {isEditMode && (
                                                    <div className="desc-textfield-wrap ">
                                                        <TextField
                                                            value={companyInformation?.companyName}
                                                            placeholder="e.g: Contoso Corporation"
                                                            resizable={false}
                                                            borderless
                                                            onChange={(
                                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                                newValue?: string
                                                            ) => {
                                                                handleValueChange('companyName', newValue);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row-flex-box m-b-25">
                                            <div className="label-cont">
                                                <Trans>Contact Name: </Trans>
                                            </div>
                                            <div className="field-cont">
                                                {!isEditMode && companyInformation?.companyContactName}
                                                {!isEditMode && !companyInformation?.companyContactName && '--'}
                                                {isEditMode && (
                                                    <div className="desc-textfield-wrap">
                                                        <TextField
                                                            value={companyInformation?.companyContactName}
                                                            placeholder="e.g: Alesia Hester"
                                                            resizable={false}
                                                            borderless
                                                            onChange={(
                                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                                newValue?: string
                                                            ) => {
                                                                handleValueChange('companyContactName', newValue);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-flex half-width-cont">
                                        <div className="row-flex-box m-b-25">
                                            <div className="label-cont">
                                                <Trans>Email ID: </Trans>
                                            </div>
                                            <div className="field-cont">
                                                {!isEditMode && companyInformation?.contactEmailAddress}
                                                {!isEditMode && !companyInformation?.contactEmailAddress && '--'}
                                                {isEditMode && (
                                                    <div
                                                        className={`desc-textfield-wrap ${
                                                            customerDetailsValidation.emailValidText ? 'error-field' : ''
                                                        }`}>
                                                        <TextField
                                                            value={companyInformation?.contactEmailAddress}
                                                            placeholder="e.g: jane.doe@microsoft.com"
                                                            resizable={false}
                                                            borderless
                                                            onChange={(
                                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                                newValue?: string
                                                            ) => {
                                                                setCustomerDetailsValidation({
                                                                    ...customerDetailsValidation,
                                                                    emailValidText: false
                                                                });
                                                                handleValueChange('contactEmailAddress', newValue);
                                                            }}
                                                        />
                                                        {customerDetailsValidation.emailValidText && (
                                                            <Text className="helper-txt">
                                                                <Trans>Please enter a valid ID</Trans>
                                                            </Text>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-flex m-b-25">
                                            <div className="row-flex-box">
                                                <div className="label-cont">
                                                    <Trans>Contact number: </Trans>
                                                </div>

                                                <div className="field-cont">
                                                    {!isEditMode &&
                                                        getData(
                                                            companyInformation?.contactPhoneNumber,
                                                            countyCodePrefix.contactNumber
                                                        )}

                                                    {isEditMode && (
                                                        <div className="custom-row col-flex">
                                                            <div
                                                                className={`row-flex ${
                                                                    customerDetailsValidation.contactNumberValidText
                                                                        ? 'error-field'
                                                                        : ''
                                                                }`}>
                                                                <div>
                                                                    <select
                                                                        value={countyCodePrefix.contactNumber}
                                                                        onChange={(e: any) => {
                                                                            setCountryCodePrefix({
                                                                                ...countyCodePrefix,
                                                                                contactNumber: e.target.value
                                                                            });
                                                                            setCustomerDetailsValidation({
                                                                                ...customerDetailsValidation,
                                                                                contactValidText: false
                                                                            });
                                                                        }}
                                                                        className="entry-width"
                                                                        aria-label="Enter customer mobile number">
                                                                        {prepareOptions(StringConstants.CONTACT_NUM)}
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    <NumberFormat
                                                                        mask=" "
                                                                        className="regular-text-field format-field"
                                                                        value={companyInformation.contactPhoneNumber}
                                                                        onChange={(e: any) => {
                                                                            setCustomerDetailsValidation({
                                                                                ...customerDetailsValidation,
                                                                                contactNumberValidText: false
                                                                            });
                                                                            handleValueChange(
                                                                                'contactPhoneNumber',
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        placeholder="e.g: 111 111 1111"
                                                                        allowNegative={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {customerDetailsValidation.contactNumberValidText && (
                                                <Text className="helper-txt">
                                                    <Trans>Please enter a valid contact number.</Trans>
                                                </Text>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {selectedRequest && selectedRequest.teamValue === 4 && isEditMode && (
                            <div>
                                <div>
                                    <div className="desc-textfield-wrap m-b-20">
                                        <TextField
                                            value={isEditingCompanyInformation ? companyFullAddressFieldValue : companyInformation.companyFullShippingAddress}
                                            placeholder="e.g: 866 Summit Park Avenue, Southfield, Michigan, United States(48075)"
                                            multiline
                                            resizable={false}
                                            borderless
                                            rows={6}
                                            onChange={(
                                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                                newValue?: string
                                            ) => {
                                                handleValueChange('companyFullShippingAddress', newValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="impact-table-wrap">
                <table className="dp-table impact-table">
                    <thead>
                        <tr>
                            <td>
                                <Trans>ADDED ON</Trans>
                            </td>
                            <td>
                                <Trans>MSX OPPORTUNITY</Trans>
                            </td>
                            <td>
                                <Trans>STATUS</Trans>
                            </td>
                            <td>
                                <Trans>TPID</Trans>
                            </td>
                            <td>
                                <Trans>END CUSTOMER</Trans>
                            </td>
                            <td>
                                <Trans>CUSTOMER COUNTRY</Trans>
                            </td>
                            <td>
                                <Trans>CUSTOMER ZIP</Trans>
                            </td>
                            <td>
                                <Trans>MSX DEVICE</Trans>
                            </td>
                            <td>
                                <Trans>MSX REVENUE</Trans>
                            </td>
                            <td>
                                <Trans>
                                SELLER  <br />ATTESTED DEVICE
                                </Trans>
                            </td>
                            <td>
                                <Trans>
                                SELLER <br />ATTESTED REVENUE{' '}
                                </Trans>
                            </td>
                            {isSuperAdmin(userDetails?.userRoles) && <td></td>}
                        </tr>
                    </thead>
                    <tbody>
                        {selectedRequest?.customerImpacts &&
                            selectedRequest.customerImpacts.map((item, index) => (
                                <tr key={index}>
                                    <td>{ISOToDateTime(item.addedOn, 'MMM DD YYYY')}</td>
                                    <td>{item.opportunityId ? handleLink(item.opportunityId, item.msxOpportunityId) : ' -'}</td>
                                    <td>{item?.status ? item.status : '--'}</td>
                                    <td>{item.tpId ? item.tpId : ' -'}</td>
                                    <td>{item.customerName}</td>
                                    <td>{item.customerCountry.name}</td>

                                    {item.zipCode ? (
                                        <td style={{ cursor: 'pointer' }}>
                                            <TooltipHost
                                                directionalHint={DirectionalHint.topCenter}
                                                content={getTooltip(item.city, item.state)}
                                                id={`${tooltipId}-zipcode`}>
                                                <Text> {item.zipCode}</Text>
                                            </TooltipHost>
                                        </td>
                                    ) : item.city || item.state ? (
                                        <td style={{ cursor: 'pointer' }}>
                                            <TooltipHost
                                                directionalHint={DirectionalHint.topCenter}
                                                content={getTooltip(item.city, item.state)}
                                                id={`${tooltipId}-city-state`}>
                                                <Text>Unavailable</Text>
                                            </TooltipHost>
                                        </td>
                                    ) : (
                                        <td>--</td>
                                    )}
                                    <td>{formatCurrency(item.msxDeviceCount)}</td>
                                    <td>$ {formatCurrency(item.msxRevenue)}</td>
                                    <td>{item.deviceCount ? formatCurrency(item.deviceCount) : '--'}</td>
                                    <td>{item.revenue ? '$' + formatCurrency(item.revenue) : '--'} </td>
                                    {isSuperAdmin(userDetails?.userRoles) &&
                                        selectedRequest.isParent !== true &&
                                        (selectedRequest.parentRequestId === null || selectedRequest.parentRequestId === '') && (
                                            <td>
                                                <IconButton
                                                    role="button"
                                                    aria-label="delete"
                                                    iconProps={{ iconName: 'Delete' }}
                                                    onClick={() => handleRemoveCustomerImpact(item)}
                                                />
                                            </td>
                                        )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default CustomerDetailsTab;
