import React from 'react';
import { IconButton, IIconProps, DefaultButton, PrimaryButton, Text } from '@fluentui/react';
import './DeleteTag.scss';
import { keyPress } from 'app/components/helpers';
import { useDeleteTag } from 'app/services/mutations';

interface ITagDetailsProps {
    onDismiss?: Function;
    deleteTag: string;
}

const DeleteTag: React.FC<ITagDetailsProps> = (props) => {
    const { onDismiss, deleteTag } = props;

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const { mutate: deleteTagMutate } = useDeleteTag();

    const deleteSelectedTag = () => {
        deleteTagMutate(deleteTag);
        onDismiss();
    };
    return (
        <div className="delete-tags-container">
            <div className={`close-model `}>
                <IconButton
                    role="button"
                    aria-label="close popup"
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    tabIndex={0}
                    onClick={() => onDismiss()}
                    onKeyPress={(e: any) => keyPress(e, onDismiss)}
                />
            </div>
            <div className="modal-heading">
                <span>Delete tag</span>
            </div>

            <div className="modal-body">
                <div className="body-tags-container">
                    <div className="warning-container">
                        <span className="warning-icon"></span>
                    </div>
                    <Text className="del-txt">
                        This tag has been used in multiple requests. Do you still wish to delete this tag?
                    </Text>

                    <div className="btm-btn-container">
                        <DefaultButton onClick={() => onDismiss()} data-testid="no-button">
                            No
                        </DefaultButton>
                        <PrimaryButton onClick={() => deleteSelectedTag()} data-testid="delete-button">
                            Yes
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteTag;
