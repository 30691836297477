import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField, Link, Icon } from '@fluentui/react';
import './index.scss';
import { Text } from '@fluentui/react';
import { Link as LinkRoute } from 'react-router-dom';
import SearchSuggestion from './searchSuggestion';
import { useSearchData, useAutoSearch } from 'app/services/mutations';
import { useGetWebSearchLinks, useSpeechToTextToken, useGetIntranetSearchLinks } from 'app/services/queries';
import { changeDateFormat, getIcon, keyPress } from 'app/components/helpers';
import useStore from 'app/store';
import { Trans } from 'react-i18next';
import { setTimeout } from 'timers';
import { debounce } from 'lodash';

const speechSdk = require('microsoft-cognitiveservices-speech-sdk');

const Search: React.FC = () => {
    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);
    const userSearchValue = useStore((state: any) => state.userSearchValue);

    //location
    let history = useHistory();
    let location = useLocation();

    const [searchResult, setSearchResult] = useState([]);

    const [openTickets, setOpenTickets] = useState([]);
    const [resolvedTickets, setResolvedTickets] = useState([]);
    const { mutate: searchData, data: searchResultData, isSuccess: isSuccessSearch } = useSearchData();

    const [autoSearch, setAutoSearch] = useState([]);
    //auto suggest
    const {
        mutate: autoSearchData,
        data: autoSearchResultData,
        isSuccess: isAutoSuccessSearch,
        isError: isAutoErrorSearch,
        isLoading: isAutoLoadingSearch
    } = useAutoSearch();

    useEffect(() => {
        return () => {
            setUserSearchValue('');
        };
    }, []);

    useEffect(() => {
        if (isAutoSuccessSearch && !isAutoLoadingSearch) {
            setAutoSearch(autoSearchResultData?.data);
            setAutoSuggestValue(
                autoSearchResultData?.data && autoSearchResultData?.data.length ? autoSearchResultData?.data[0] : ''
            );
        }
        if (isAutoErrorSearch && !isAutoLoadingSearch) {
            setAutoSuggestValue('');
        }
    }, [isAutoSuccessSearch, autoSearchResultData?.data, isAutoErrorSearch, isAutoLoadingSearch]);

    useEffect(() => {
        if (isSuccessSearch) {
            setSearchResult(searchResultData?.data);

            let openTicket: any = [];
            let resolvedTicket: any = [];
            searchResultData?.data?.forEach((item: any) => {
                if (item.Document.ResultType === 'Ticket') {
                    if (item.Document.Status === 1 || item.Document.Status === 2 || item.Document.Status === 3) {
                        openTicket.push(item.Document);
                    } else if (item.Document.Status === 4) {
                        resolvedTicket.push(item.Document);
                    }
                }
            });

            setOpenTickets(openTicket);
            setResolvedTickets(resolvedTicket);

            let tagsApi = searchResultData?.data;
            let tagsArray = new Set();

            tagsApi?.forEach((item: any) => {
                item.Document.keyphrases.forEach((i: any) => {
                    tagsArray.add(i);
                });
            });

            setTags(Array.from(tagsArray));
        }
    }, [isSuccessSearch]);

    useEffect(() => {
        if (userSearchValue) {
            setInputValue(userSearchValue);
            if (tempInputValue !== userSearchValue) {
                setTempInputValue(userSearchValue);
            }
        }
    }, [userSearchValue]);

    const { data: generateToken, isLoading: isTokenLoading, refetch: getSpeechToTextToken } = useSpeechToTextToken();

    useEffect(() => {
        if (!isTokenLoading) {
            setTokenObj(generateToken);
        }
    }, [isTokenLoading]);

    const [, setTokenObj] = useState({ Token: '', Region: '' });

    const [isRecording, setIsRecording] = useState(false);

    const [recognizer, setRecognizer] = useState(null);
    //voice to text
    const sttFromMic = async (action: Boolean) => {
        if (action) {
            let token = JSON.parse(sessionStorage.getItem('speech-token'));
            if (!(token && token.Token)) {
                await getSpeechToTextToken();
                token = JSON.parse(sessionStorage.getItem('speech-token'));
            }
            const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(token?.Token ?? '', token?.Region ?? '');
            speechConfig.speechRecognitionLanguage = 'en-US';
            speechConfig.setServiceProperty('punctuation', 'explicit', speechSdk.ServicePropertyChannel.UriQueryParameter);
            // speechConfig.enableDictation();
            const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();
            let newRecognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
            setRecognizer(newRecognizer);
            setIsRecording(true);
            newRecognizer.recognized = function (s: any, e: any) {
                let displayText;

                if (e.result.text) {
                    displayText = `${e.result.text}`;
                    displayText = displayText.replace('SKP ', 'SKP-');
                    //displayText = displayText.substring(0, displayText.length - 1);
                    setInputValue(displayText);
                    setUserSearchValue(displayText);
                    searchData({
                        term: displayText,
                        searchOption: 'Default',
                        IsAdminSearch: false,
                        IsParentChildSearch: false
                    });
                    setIsRecording(false);

                    if (displayText.length > 5 && !location.pathname.includes('/searchResult')) {
                        setShowSuggestion(true);
                    }
                    newRecognizer.stopContinuousRecognitionAsync(
                        (result: string) => {},
                        (e: string) => {}
                    );
                }
            };

            newRecognizer.startContinuousRecognitionAsync(() => {
                setTimeout(() => {
                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                    setIsRecording(false);
                }, 20000);
            });

            ref.current.focus();
        }

        if (!action) {
            recognizer.stopContinuousRecognitionAsync(
                (result: string) => {},
                (e: string) => {}
            );
        }
    };

    //local values
    const [showQuick, setShowQuick] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [tempInputValue, setTempInputValue] = useState(userSearchValue);
    const [inputValue, setInputValue] = useState(userSearchValue);
    const [searchWidth, setSearchWidth] = useState(false);
    const { data: webSearchData, isSuccess: webSearchLinksLoaded } = useGetWebSearchLinks(inputValue);
    const { data: intranetSearchData, isSuccess: intranetSearchLinksLoaded } = useGetIntranetSearchLinks(inputValue);

    //tags
    const [tags, setTags] = useState([]);

    //autosuggest
    const [autoSuggestValue, setAutoSuggestValue] = useState('');
    //search input value change
    const onChange = (event: any) => {
        //setTempInputValue(event.target.value);

        if (event.target.value.length > 2) {
            setInputValue(event.target.value);

            setShowSuggestion(true);
            if (event.target.value[event.target.value.length - 1] !== ' ') {
                searchData({
                    term: event.target.value?.trim(),
                    searchOption: 'Default',
                    IsAdminSearch: false,
                    IsParentChildSearch: false
                });
                autoSearchData(event.target.value?.trim());
            }

            setUserSearchValue(event.target.value);
        } else {
            setAutoSuggestValue('');
        }
    };

    const debouncedFunction = useCallback(debounce(onChange, 300), []);

    const debouncedChangeHandler = (event: any) => {
        debouncedFunction(event);
        setTempInputValue(event.target.value);
    };

    //search input on focus
    const inputFocus = () => {
        setShowQuick(!showQuick);
        setSearchWidth(true);
    };

    //search input on blur
    const handleSuggestionClose = () => {
        setShowQuick(!showQuick);
        setSearchWidth(false);
        setShowSuggestion(false);
    };

    const keydown = (e: any) => {
        /* 
        don't delete this for tab auto suggest
        if (e.keyCode === 9 && inputValue.trim()) {
            setInputValue(autoSearch && autoSearch.length ? autoSearch[0] : '');
        } */
        if (inputValue.length <= 0) {
            setAutoSuggestValue('');
        }
    };

    //search input ket up
    const keyUp = (e: any, isPaste: boolean) => {
        if (inputValue.length > 2) {
            setShowSuggestion(true);
        }

        if (inputValue.length <= 0) {
            setAutoSuggestValue('');
        }

        if (autoSearch && autoSearch.length <= 0) {
            setAutoSuggestValue('');
        }

        if (
            (e.code === 'Enter' || e.code === 'NumpadEnter') &&
            !location.pathname.includes('search-results') &&
            inputValue.trim().length > 2 &&
            e.target.value.trim().length > 2
        ) {
            history.push('/search-results');
        } else if (
            (e.code === 'Enter' || e.code === 'NumpadEnter') &&
            location.pathname.includes('search-results') &&
            inputValue.trim().length > 2 &&
            e.target.value.trim().length > 2
        ) {
            history.push('search-results');
            setUserSearchValue(e.target.value);
            setShowSuggestion(false);
        }
    };

    const voiceSearchOn = () => {
        sttFromMic(true);
        setSearchWidth(true);
    };

    const voiceSearchOff = () => {
        setIsRecording(false);
        sttFromMic(false);
    };

    //on tag click
    const tagClick = (search: string) => {
        setInputValue(search);
        setTempInputValue(search);
        searchData({
            term: search,
            searchOption: 'Default',
            IsAdminSearch: false,
            IsParentChildSearch: false
        });
        setTimeout(function () {
            setSearchWidth(true);
            setShowSuggestion(true);
        }, 200);

        ref.current.focus();
    };
    const inputBlur = () => {
        setAutoSuggestValue('');
    };

    //calling custom hook
    const searchContainer = useRef(null);
    useSearchSuggestionClose(searchContainer, handleSuggestionClose);

    //input on paste
    const onPaste = (e: any) => {
        onChange(e);

        if (inputValue.length > 2) {
            setShowSuggestion(true);
        }
    };

    const ref = useRef(null);

    return (
        <div className={`search-container ${searchWidth ? 'search-width' : ''}`} ref={searchContainer}>
            <div className={`search-box ${showSuggestion ? 'suggest-show' : ''}`}>
                <div className="search-typeahead">
                    <TextField
                        tabIndex={0}
                        onFocus={() => inputFocus()}
                        onBlur={() => inputBlur()}
                        value={tempInputValue}
                        //onChange={onChange}
                        onChange={debouncedChangeHandler}
                        onKeyUp={(e) => keyUp(e, false)}
                        className={`search-input ${tempInputValue.length === 0 ? 'search-input-icon' : ''}`}
                        autoComplete={'off'}
                        onPaste={(e) => onPaste(e)}
                        onKeyDown={keydown}
                        placeholder={'Search'}
                        componentRef={ref}
                    />
                    {tempInputValue.length === 0 && autoSuggestValue.length === 0 && (
                        <Icon className="placeholder-search-icon" iconName="Search" />
                    )}

                    {autoSuggestValue.length > tempInputValue.length && tempInputValue !== '' && (
                        <div className="auto-suggest-text">
                            {tempInputValue + autoSuggestValue.substring(tempInputValue.length)}
                        </div>
                    )}
                    {isRecording && (
                        <div
                            className="recording-row recording-position"
                            tabIndex={0}
                            onKeyPress={(e) => keyPress(e, () => voiceSearchOff())}
                            onClick={() => voiceSearchOff()}>
                            <div className="first-line"></div>
                            <div className="second-line"></div>
                            <div className="third-line"></div>
                            <div className="fourth-line"></div>
                            <div className="fifth-line"></div>
                        </div>
                    )}
                    {!isRecording && (
                        <i
                            className="micro-icon"
                            tabIndex={0}
                            onKeyPress={(e) => keyPress(e, () => voiceSearchOn())}
                            onClick={() => voiceSearchOn()}
                            aria-label="voice-icon"
                            role="button"></i>
                    )}
                </div>
            </div>

            <div>
                {showSuggestion &&
                    ((searchResult && searchResult.length > 0) || (intranetSearchData && intranetSearchData.length > 0)) && (
                        <div className="search-results-suggestion">
                            <div className="grid-box">
                                <div className="grid-left">
                                    <ul className="chips-container">
                                        {isSuccessSearch &&
                                            tags.length > 0 &&
                                            tags.slice(0, 10).map((tag: any, idx: number) => (
                                                <li key={idx}>
                                                    <span
                                                        tabIndex={0}
                                                        onKeyPress={(e) => keyPress(e, () => tagClick(tag))}
                                                        onClick={() => tagClick(tag)}
                                                        className="chip-item">
                                                        {tag}
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>

                                    {isSuccessSearch &&
                                        searchResult.length > 0 &&
                                        searchResult
                                            .filter((item: any) => item.Document.ResultType === 'Article')
                                            .map((item: any, idx: number) => (
                                                <SearchSuggestion key={idx} listData={item.Document} />
                                            ))}

                                    {intranetSearchLinksLoaded && intranetSearchData?.length > 0 && (
                                        <div className="request-container">
                                            <h6>
                                                <Trans>FROM THE INTRANET</Trans>
                                            </h6>

                                            {intranetSearchData?.map((item: any, idx: number) => {
                                                return (
                                                    <div key={idx} className="request-links-container col-flex-box">
                                                        <Text className="request-links">
                                                            <span className={getIcon(item?.Name)}></span>
                                                            <Link role="link" href={`${item?.Url}`} target="_blank">
                                                                {item?.Name.split('.')[0]}
                                                            </Link>
                                                        </Text>
                                                        <Text className="webSearch-snippet">{item?.Snippet} </Text>
                                                        <Link className="des-text" href={`${item?.Url}`} target="_blank">
                                                            {item?.Url}
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                                {isSuccessSearch && searchResult.length > 0 && (
                                    <div className="grid-right">
                                        {openTickets.length > 0 && (
                                            <div className="request-container">
                                                <h6>
                                                    <Trans>Open Requests</Trans>
                                                </h6>
                                                <div>
                                                    {isSuccessSearch &&
                                                        openTickets.map((item: any, idx: number) => {
                                                            return (
                                                                <div key={idx} className="request-links-container">
                                                                    <Text className="request-links">
                                                                        <LinkRoute
                                                                            role="link"
                                                                            to={`/request/${item.UnifiedTrackingId}`}
                                                                            tabIndex={0}>
                                                                            {item.UnifiedTrackingId} - {item.Title}
                                                                        </LinkRoute>
                                                                    </Text>
                                                                    <Text className="des-text">
                                                                        <Trans>Requested by</Trans> {item.CreatedBy?.DisplayName}{' '}
                                                                        on {changeDateFormat(item.CreatedOn, 'MMMM DD, YYYY')}
                                                                    </Text>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        )}

                                        {resolvedTickets && resolvedTickets.length > 0 && (
                                            <div className="request-container">
                                                <h6>
                                                    <Trans>Resolved Requests</Trans>
                                                </h6>
                                                {isSuccessSearch &&
                                                    resolvedTickets.length > 0 &&
                                                    resolvedTickets.map((item: any, idx: number) => {
                                                        return (
                                                            <div key={idx} className="request-links-container">
                                                                <Text className="request-links">
                                                                    <LinkRoute
                                                                        role="link"
                                                                        to={`/request/${item.UnifiedTrackingId}`}>
                                                                        {item.UnifiedTrackingId} - {item.Title}
                                                                    </LinkRoute>
                                                                </Text>
                                                                <Text className="des-text">
                                                                    <Trans>Requested by</Trans> {item.CreatedBy?.DisplayName} on{' '}
                                                                    {changeDateFormat(item.CreatedOn, 'MMMM DD, YYYY')}
                                                                </Text>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                        {webSearchLinksLoaded && webSearchData?.length > 0 && (
                                            <div className="request-container">
                                                <h6>
                                                    <Trans>FROM THE WEB</Trans>
                                                </h6>

                                                {webSearchData?.map((item: any, idx: number) => {
                                                    return (
                                                        <div key={idx} className="request-links-container col-flex-box">
                                                            <Text className="request-links">
                                                                <Link role="link" href={`${item?.Url}`} target="_blank">
                                                                    {item?.Name}
                                                                </Link>
                                                            </Text>
                                                            <Text className="webSearch-snippet">{item?.Snippet} </Text>
                                                            <Link className="des-text" href={`${item?.Url}`} target="_blank">
                                                                {item?.Url}
                                                            </Link>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

/**
 * This Hook handles closing the useSearchSuggestionClose  on clicking outside of the useSearchSuggestion container
 */
const useSearchSuggestionClose = (ref: any, handleSuggestionClose: Function) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleSuggestionClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleSuggestionClose, ref]);
};

export default Search;
