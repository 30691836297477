import React from 'react';
import './HelpfulResources.scss';
import { Image, Link } from '@fluentui/react';
import useStore from 'app/store';
import { isContentCreator, isContentManager, isSuperAdmin } from 'app/utils/authUtilities';
import { IUserDetails } from 'app/models/common/post';
import { Trans } from 'react-i18next';
import { keyPress } from 'app/components/helpers';
interface IProps {
    resourceData: any;
    showDeleteIcon: boolean;
    removeHelpfulResource?: (resource: any) => void;
}
const HelpfulResources: React.FC<IProps> = (props: IProps) => {
    const { resourceData, removeHelpfulResource, showDeleteIcon } = props;
    const userDetails: IUserDetails = useStore((state) => state.userDetails as IUserDetails);

    const deleteResource = (resourceData: any) => {
        removeHelpfulResource(resourceData);
    };
    return (
        <>
            <div className="resource-card">
                {resourceData.icon && (
                    <div className="resource__icon">
                        <Image src={resourceData.icon} alt="Resource" width="32px" height="32px" />
                    </div>
                )}
                <div className="resource__details">
                    {(isContentCreator(userDetails?.userRoles) ||
                        isContentManager(userDetails?.userRoles) ||
                        isSuperAdmin(userDetails?.userRoles)) &&
                        showDeleteIcon && (
                            <i
                                tabIndex={0}
                                className="ms-Icon ms-Icon--Delete delete-icon"
                                onClick={() => deleteResource(resourceData)}
                                onKeyPress={(e: any) => keyPress(e, () => deleteResource(resourceData))}
                                aria-hidden="true"></i>
                        )}
                    <div className="resource__title">
                        <Link role="link" href={resourceData.url} target="_blank" className="text-overflow--90">
                            {resourceData.customTitle ? resourceData.customTitle : resourceData.title}
                        </Link>
                    </div>
                    <div className="resource__description text-lines text-lines--3">{resourceData.description}</div>
                    <div className="resource__footer">
                        <Link role="link" href={resourceData.url} className="more-link" target="_blank">
                            <Trans>Learn More</Trans>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HelpfulResources;
