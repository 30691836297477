import React from 'react';
import './ArticlesByAuthor.scss';
import { Icon, Link, PersonaInitialsColor } from '@fluentui/react';
import { Breadcrumb, IBreadcrumbItem, IDropdownOption, Dropdown } from '@fluentui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import SearchNews from '../searchnews/SearchNews';
import NewsCard from '../newsCard/NewsCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetArticles, useGetAuthorById } from 'app/services/queries';
import Avatar from 'app/components/avatar/Avatar';
import Pagination from 'app/components/pagination/Pagination';
import { IAuthorDetails } from 'app/models/common/post';
import { getProfileByUserId, getQueryParam, setQueryParam } from 'app/components/helpers';
import { UserDomain } from 'app/enums/UserDomain.enum';
import { Trans } from 'react-i18next';
import { ArticleStatus } from 'app/enums/Status.enum';
import { Loader } from 'app/components/loader/Loader';
import { SVGIcons } from 'app/utils/SVGIcons';
interface IParams {
    id: string;
}
const options: IDropdownOption[] = [
    { key: '1', text: 'Latest', data: { icon: 'List' } },
    { key: '0', text: 'Earliest', data: { icon: 'Previous' } }
];
const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
        <>
            {option.data && option.data.icon && (
                <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
            )}
            <span>{option.text}</span>
        </>
    );
};
const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
        <>
            {option.data && option.data.icon && (
                <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
            )}
            <span>{option.text}</span>
        </>
    );
};
const ArticlesByAuthor: React.FC = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { id: authorId } = useParams<IParams>();
    const [sortBy, setSortBy] = useState<IDropdownOption>(options[0]);
    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history).page));
    const resultsPerPage = 21;

    const [pageData, setPageData] = useState([]);
    const [authorDetail, setAuthorDetail] = useState<IAuthorDetails>();

    const {
        isFetched,
        data: articles,
        refetch: getArticlesByAuthorId
    } = useGetArticles(
        Number(getQueryParam(history).page),
        resultsPerPage,
        'ModifiedOn',
        getQueryParam(history).sort as string,
        authorId,
        ArticleStatus.Published,
        null,
        null,
        null,
        false
    );
    const { data: profile } = useGetAuthorById(authorId);

    const itemsWithHref: IBreadcrumbItem[] = [
        {
            text: 'Home',
            key: 'home',
            onClick: () => history.goBack()
        },
        { text: profile?.displayName, key: 'author name', isCurrentItem: true }
    ];

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setQueryParam(history, pathname, { sort: item.key.toString(), page: '1' }, false);
    };

    useEffect(() => {
        setQueryParam(
            history,
            pathname,
            {
                page: getQueryParam(history).page ?? '1',
                sort: getQueryParam(history).sort ?? '1'
            },
            true
        );
    }, []);

    useEffect(() => {
        setPageIndex(Number(getQueryParam(history).page));
        setSortBy(options.find((option: IDropdownOption) => option.key === getQueryParam(history).sort));
        getArticlesByAuthorId().then((articles) => {
            setPageData(articles?.data?.items);
        });
    }, [history?.location?.search]);

    useEffect(() => {
        if (!(profile || profile?.displayName) && authorId) {
            getProfileByUserId(authorId)
                .then((res) => {
                    setAuthorDetail({ ...profile, displayName: res.displayName, id: authorId, email: res.mail });
                })
                .catch();
        }
        setAuthorDetail(profile);
    }, [authorId, profile]);

    if (isFetched)
        return (
            <>
                <div className="newsfeed-author-banner">
                    <div className="container">
                        <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} overflowAriaLabel="More links" />
                    </div>
                    <div className="newsfeed-author-container">
                        <div className="container ms-Grid" dir="ltr">
                            <div className="ms-Grid-row auth-box">
                                <div className="ms-Grid-col ms-sm12 ms-md4 author-card">
                                    <div className="author-img">
                                        {authorDetail && (
                                            <Avatar
                                                userId={authorDetail.id}
                                                userName={authorDetail.displayName}
                                                initialColor={PersonaInitialsColor.orange}
                                                imageSize={120}
                                                roundedPic={true}
                                            />
                                        )}
                                    </div>
                                    {authorDetail && (
                                        <div className="author-details">
                                            <div className="author-name">{authorDetail.displayName}</div>
                                            {authorDetail.userDomain && (
                                                <span className="capsule m-t-5">{UserDomain[authorDetail.userDomain]}</span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {authorDetail && (
                                    <div className="ms-Grid-col ms-sm12 ms-md8 p-t-25">
                                        {authorDetail?.aboutMe && (
                                            <div className="newsfeed-author-banner-title">
                                                <label>About me</label>
                                                {authorDetail?.aboutMe}
                                            </div>
                                        )}
                                        {(authorDetail?.email || authorDetail?.linkedInUrl || authorDetail?.twitterUrl) && (
                                            <div className="newsfeed-author-banner-icons">
                                                <label>Social handle -</label>
                                                <div className="d-flex gap-7 m-t-2">
                                                    {authorDetail.email && (
                                                          <Link role="link" href={`mailto:${authorDetail?.email}`}>
                                                          <span
                                                              className="ms-Icon"
                                                              dangerouslySetInnerHTML={{
                                                                  __html: SVGIcons.ICON_EMAIL
                                                              }}></span>
                                                        <span className='author-banner-details'>{authorDetail.email}</span>
                                                      </Link>
                                                    )}
                                                    {authorDetail.linkedInUrl && (
                                                        <Link role="link" target="_blank" href={authorDetail?.linkedInUrl}>
                                                            <span
                                                                className="ms-Icon"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: SVGIcons.ICON_LINKED_IN
                                                                }}></span>
                                                        </Link>
                                                    )}
                                                    {authorDetail.twitterUrl && (
                                                        <Link role="link" target="_blank" href={authorDetail?.twitterUrl}>
                                                            <span
                                                                className="ms-Icon"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: SVGIcons.ICON_TWITTER
                                                                }}></span>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="container count-sort-section d-flex--between ai-center">
                        <div className="article-count">
                            <b>
                                <Trans>All </Trans>
                                {articles?.totalCount} <Trans>Articles</Trans>
                            </b>
                        </div>
                        <div className="d-flex--between gap-20  ai-center">
                            <div className="sort-dropdown">
                                <span className="sort-by">
                                    <Trans>Sort by</Trans>
                                </span>
                                <Dropdown
                                    aria-label='Sort by'
                                    onRenderTitle={onRenderTitle}
                                    onRenderOption={onRenderOption}
                                    placeholder="Last Viewed"
                                    options={options}
                                    onChange={onChange}
                                    selectedKey={sortBy ? sortBy.key : undefined}
                                    className="selectType"
                                />
                            </div>
                            <SearchNews placeholder="Search article" iconName="Search" animate={false} />
                        </div>
                    </div>
                    <div className="card-grid">
                        <div className="ms-Grid container" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-row">
                                    {pageData?.length > 0 &&
                                        pageData?.map((data) => (
                                            <div key={data.id} className="ms-Grid-col ms-sm12 ms-lg4 card-list">
                                                {
                                                    <NewsCard
                                                        data={data}
                                                        showStatus={false}
                                                        showLastEdited={false}
                                                        showMoreOptions={false}
                                                        showArticleType={false}
                                                    />
                                                }
                                            </div>
                                        ))}
                                </div>
                            </div>
                            {pageData && pageData?.length > 0 && (
                                <div className="ms-Grid-row text-center">
                                    <div className="pagination-container">
                                        <Pagination
                                            pageCount={articles?.totalPages}
                                            setPageNumber={setPageIndex}
                                            initialPage={pageIndex - 1}
                                            totalCount={articles?.totalCount}
                                            resultsPerPage={resultsPerPage}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    else return <Loader classNames="m-t-20 m-b-50 height-70" />;
};

export default ArticlesByAuthor;
