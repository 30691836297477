import React, { useEffect } from 'react';
import { Link, Text } from 'office-ui-fabric-react';
import {
    TextField,
    IconButton,
    IIconProps,
    DefaultButton,
    PrimaryButton,
    TooltipHost,
    IImageProps,
    Image
} from '@fluentui/react';
import InfoIcon from '../../../static/icons/tooltip-info-icon.svg';
import { IAddCustomerData, ISelectedData } from 'app/models/common/response';
import NumberFormat from 'react-number-format';
import { keyPress } from 'app/components/helpers';
import { RequestDomain, StringConstants } from 'app/utils/constants';
import { RequestType } from 'app/enums/RequestType.enum';
import { useId } from '@fluentui/react-hooks';

interface IAddDetailsProps {
    selectedRequest: IAddCustomerData;
    setDisplayScreen?: Function;
    onDismiss?: Function;
    selectedData: Array<ISelectedData>;
    handleChange: Function;
    handleAddCustomer?: Function;
    customerHelperText?: boolean;
    newCountryHelperText?: boolean;
    errorCustomerName?: boolean;
    errorCountry?: boolean;
    errorEstimatedValue?: boolean;
    errorTotalDeviceCount?: boolean;

    type?: string;
    cssNumber?: string;
    setCssNumber?: Function;
    setErrorText?: Function;
    cssErrorText?: boolean;
    countryList: Array<any>;
}

const AddNewDetails: React.FC<IAddDetailsProps> = (props) => {
    const {
        selectedRequest,
        onDismiss,
        setDisplayScreen,
        selectedData,
        handleChange,
        handleAddCustomer,
        errorCustomerName,
        errorCountry,
        errorEstimatedValue,
        errorTotalDeviceCount,

        type,
        cssNumber,
        setCssNumber,
        cssErrorText,
        setErrorText,
        countryList
    } = props;

    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const calloutProps = { gapSpace: 0 };
    const tooltipId = useId('tooltip');

    const cancelHandler = () => {
        setDisplayScreen('searchCust');
    };

    const imageProps: IImageProps = {
        styles: () => ({ root: { paddingBottom: '10px' } })
    };

    const addHandler = () => {
        if (selectedRequest.requestTeam === RequestDomain.CSS) {
            if (cssNumber === '' || cssNumber === null) {
                setErrorText(true);
            } else {
                handleAddCustomer();
            }
        } else {
            handleAddCustomer();
        }
    };

    useEffect(() => {
        if (type === StringConstants.ADD_NEW_DETAILS && !selectedData[0]?.Country) {
            let e: any = {
                target: {
                    value: countryList[0].name
                }
            };
            handleChange(e, 'country');
        }
    }, []);

    return (
        <div className="selected-customer-container">
            <div className={`close-model `}>
                <IconButton
                    role="button"
                    aria-label="close popup"
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    tabIndex={0}
                    onClick={() => onDismiss()}
                    onKeyPress={(e: any) => keyPress(e, onDismiss)}
                />
            </div>
            <div className="modal-heading">
                <span>Add a customer</span>
            </div>
            <div className="modal-body">
                <div className="body-main-container">
                    <Text className="modal-body-title back-title">
                        <Link
                            aria-label="back button"
                            tabIndex={0}
                            onClick={cancelHandler}
                            onKeyPress={(e: any) => keyPress(e, cancelHandler)}
                            data-testid="search-customer">
                            <span className="arrow-icon"></span>
                        </Link>
                        Add Details
                    </Text>
                    <div className="body-main-sub">
                        <div>
                            <Text className="secondary-text mb-10">
                                <span className="text-bold">Request ID - </span>
                                {selectedRequest.unifiedTrackingId}
                            </Text>
                            <Text className="secondary-text mb-10">
                                <span className="text-bold">Application ID - </span>
                                {selectedRequest.applicationId}
                            </Text>
                        </div>
                        <div>
                            <Text className="modal-body-title title-field">{selectedRequest.summary}</Text>
                        </div>
                        {type === StringConstants.ADD_DETAILS && (
                            <div className="cust-details">
                                <Text className="secondary-text">
                                    <span className="text-bold">Customer Name: </span>
                                    {selectedData[0].AccountName}
                                </Text>
                                <Text className="secondary-text">
                                    <span className="text-bold">Account ID: </span>
                                    {selectedData[0].AccountNumber}
                                </Text>

                                <Text className="secondary-text">
                                    <span className="text-bold">Country/Subsidiary: </span>
                                    {selectedData[0].Country}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
                <div className="body-result-container">
                {(selectedRequest.requestType === RequestType.CssReviewRequest ||
                        selectedRequest.requestType === RequestType.Cxp_TechnicalRequest) && (
                            <div className="info-css-textfield-container row-flex-box m-b-25">
                            <div className="css-case-styles">
                                <div className="tooltip-styles">
                                    <label className="label-styles">CSS Case Number</label>
                                    <Text>
                                        <TooltipHost
                                            className="tooltip-display"
                                            id={tooltipId}
                                            calloutProps={calloutProps}
                                            content={
                                                'For this type of request please have a CSS support case open first'
                                            }>
                                            <Image
                                                {...imageProps}
                                                aria-describedby={tooltipId} src={InfoIcon} alt="Info"
                                            />
                                        </TooltipHost>
                                    </Text>
                                </div>
                                <div className="info-container">
                                <TextField
                                    value={cssNumber}
                                    onChange={(e: any) => {
                                        setCssNumber(e.target.value);
                                        setErrorText(false);
                                    }}
                                />
                                </div>
                                <div>
                                    {cssErrorText &&
                                        <div className="error-info-message">
                                            <span className="helper-text">Please enter the CSS Case Number.</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    {type === StringConstants.ADD_NEW_DETAILS && (
                        <div className="impacted-text-fields">
                            <div className="impacted-text-fields">
                                <div>
                                    <label>
                                        Customer Name<span className="required">*</span>
                                    </label>
                                    <TextField
                                        value={selectedData[0]?.AccountName}
                                        onChange={(e: any) => handleChange(e, 'accountName')}
                                        data-testid="accountName-text"
                                    />
                                    {errorCustomerName && <span className="helper-text">Please enter a customer name.</span>}
                                </div>
                                <div className="country-select">
                                    <label>
                                        Country/Subsidiary<span className="required">*</span>
                                    </label>
                                    <select
                                        value={selectedData[0]?.Country}
                                        onChange={(e: any) => handleChange(e, 'country')}
                                        aria-label="Enter customer country"
                                        data-testid="country-text"
                                        className="format-field-cont">
                                        {countryList?.map((item: any, key) => {
                                            return (
                                                <option key={key} value={item.name}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {errorCountry && (
                                        <span className="helper-text">
                                            Please enter the country to which the customer belongs.
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="impacted-text-fields">
                        <div>
                            <label>Revenue Risk ($)</label>
                            <NumberFormat
                                thousandSeparator={true}
                                value={selectedData[0]?.EstimatedValue}
                                onChange={(e: any) => handleChange(e, 'impacted_revenue')}
                                placeholder="$"
                                allowNegative={false}
                                inputMode="numeric"
                                className="num-foramt"
                            />
                            {errorEstimatedValue && <span className="helper-text">Please enter the revenue risk involved.</span>}
                        </div>

                        <div>
                            <label>Device Risk</label>
                            <NumberFormat
                                thousandSeparator={true}
                                value={selectedData[0]?.TotalDeviceCount}
                                onChange={(e: any) => handleChange(e, 'impacted_device')}
                                placeholder="0"
                                allowNegative={false}
                                inputMode="numeric"
                                className="num-foramt"
                                decimalScale={0}
                            />
                            {errorTotalDeviceCount && <span className="helper-text">Please enter the device risk involved.</span>}
                        </div>
                    </div>

                    <div className="add-details-btn">
                        <DefaultButton
                            tabIndex={0}
                            onClick={(e: any) => {
                                onDismiss();
                            }}
                            onKeyPress={(e: any) => keyPress(e, cancelHandler)}
                            text="Cancel"
                        />
                        <PrimaryButton
                            tabIndex={0}
                            onKeyPress={(e: any) => addHandler()}
                            onClick={() => addHandler()}
                            text="Next"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewDetails;
