import { Icon, Label } from '@fluentui/react';
import { keyPress } from 'app/components/helpers';
import { ISearchUser } from 'app/models/common/response';
import { useGetUsersList } from 'app/services/queries';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import './EmailTeamDropdown.scss';

interface IProps {
    title: string;
    selectedPeople: ISearchUser[];
    setSelectedPeople: Function;
    isDomainSpecific: boolean;
}

const EmailTeamDropdown: React.FC<IProps> = (props: IProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { title, selectedPeople, setSelectedPeople, isDomainSpecific } = props;
    const searchType = "Admin";
    const searchValue = '';
    const dropdownOptions = [
        { key: 1, team: 'SDSG' },
        { key: 2, team: 'CXP' },
        { key: 3, team: 'CSS' },
        { key: 4, team: 'CREW' },
        { key: 5, team: 'ALL ADMINS' }
    ];
    const [teamValue, setTeamValue] = useState();
    const count = 100;
    //Grabs new list of users when the SearchType value is changed
    const { data } = useGetUsersList(searchValue, isDomainSpecific, searchType, teamValue, count);

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };
    const dropDownRef = useRef(null);
    useDropDownDismiss(dropDownRef, onToggleShow);

    const handleSetTeamValue = (value: any) => {
        onToggleShow();
        setTeamValue(value);
    };

    //Adds team members to array and then filters to remove any duplicate Users
    const handleAddTeam = () => {
        var newArray = data.concat(selectedPeople);
        var filteredArray: ISearchUser[] = [];
        for (const user of newArray) {
            const found = filteredArray.some((item) => _.isEqual(item, user));
            if (!found) {
                filteredArray.push(user);
            }
        }
        setSelectedPeople(filteredArray);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            handleAddTeam();
        }
    }, [data]);

    return (
        <div>
            <div className="d-flex ai-center p-r-10">
                <Label className="">{title}:</Label>
                <div className="icon-backround">
                    <Icon
                        tabIndex={0}
                        className="chevron"
                        iconName="ChevronRight"
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        onClick={() => onToggleShow()}></Icon>
                    {showDropdown && (
                        <div ref={dropDownRef} className="dropdown-cont" data-testid="dropdown-container">
                            <div
                                tabIndex={0}
                                data-testid="email-teams-close-btn-test"
                                onKeyPress={(e: any) => {
                                    keyPress(e, onToggleShow);
                                }}
                                onClick={() => onToggleShow()}
                                className="row-flex-box align-center dropdown-header">
                                <Icon iconName="ChevronUp" />
                            </div>
                            {dropdownOptions.map((option: any, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    tabIndex={0}
                                    className="dropdown-item row-flex-box align-center"
                                    onClick={() => handleSetTeamValue(option.key)}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            handleSetTeamValue(option.key);
                                        }
                                    }}>
                                    {option.team}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default EmailTeamDropdown;
