import React from 'react';
import './index.scss';
import SearchInput from '..';
import TopArticle from '../../topArticle';
import SearchResults from '../searchResults';

const SearchResultsBanner: React.FC = () => {
    return (
        <>
            <div className="search-banner-container">
                <div className="banner-img"></div>

                <TopArticle />
                <div className="container data-container">
                    <SearchInput />
                    <SearchResults />
                </div>
            </div>
        </>
    );
};

export default SearchResultsBanner;
