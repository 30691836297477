import React, { useEffect, useMemo, useRef, useState } from 'react';
import './ManageRequests.scss';
import ManageRequestDetailedView from '../manageRequestDetailedView/ManageRequestDetailedView';
import GridTable from '../adminTable/GridTable';
import SummaryCard from '../summaryCards/SummaryCard';
import {
    useRequestData,
    useTeamLookUpData,
    useRequestTypesLookUpData,
    useGetUserPreferences,
    useFetchUsersListByRole,
    useSummaryCardsCountForUser,
    useRequestDetailsLookUpData,
    useRequestListBuilder
} from '../../../services/queries';
import { Breadcrumb, IBreadcrumbItem, Shimmer, Text } from '@fluentui/react';
import {
    formatIntoRequestData,
    getFilteredUrl,
    getDateRangeUrl,
    getCardLabel,
    formatCurrency,
    setQueryParam,
    getQueryParam
} from '../../../components/helpers';
import FilterPanel from './filterPanel/FilterPanel';

import useStore from 'app/store';
import { IRequest, IUserProps } from 'app/models/common/response';
import { IDateRangeProps, IRequestPayload } from 'app/models/common/post';
import Pagination from 'app/components/pagination/Pagination';
import { IUserDetails } from 'app/models/common/post';
import { useTranslation } from 'react-i18next';
import { useStorePreference } from 'app/services/mutations';
import { useHistory, useLocation } from 'react-router';
import { IOptionProps } from './models';
import { getPortalValue } from 'app/utils/utilities';
import { RouteConstants, StringConstants } from 'app/utils/constants';

const ManageRequests = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();

    const portal = getPortalValue(history?.location?.pathname);
    const location = useLocation();

    //store variables
    const lastSelectedRequest = useStore((state: any) => state.lastSelectedRequest);
    const setLastSelectedRequest = useStore((state: any) => state.setLastSelectedRequest);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const page = useStore((state: any) => state.page);
    const setPage = useStore((state: any) => state.setPage);
    const requestType = useStore((state: any) => state.requestType);
    const setNewlyAddedRow = useStore((state: any) => state.setNewlyAddedRow);
    const requestData = useStore((state: any) => state.requestData);
    const setRequestData = useStore((state: any) => state.setRequestData);
    const setRequestType = useStore((state: any) => state.setRequestType);
    const [currentSortProp, setCurrentSortProp] = useState('');
    const isListView = useStore((state: any) => state.isListView);
    const setIsListView = useStore((state: any) => state.setIsListView);
    const listBuilderFilters = useStore((state: any) => state.listBuilderFilters)
    const setListBuilderFilters = useStore((state: any) => state.setListBuilderFilters);
    const setPreviousLocation = useStore((state: any) => state.setPreviousLocation);
    const previousLocation = useStore((state: any) => state.previousLocation);
    const listIcon = useStore((state: any) => state.listIcon);

    //
    const [assignedToSelection, setAssignedToSelection] = useState<IUserProps[]>([]);
    const [statusSelection, setStatusSelection] = useState<IOptionProps[]>([]);
    const [relationshipSelection, setRelationshipSelection] = useState<IOptionProps[]>([]);

    const gridContRef = useRef(null);
    const itemsWithHref: IBreadcrumbItem[] = [
        { text: 'Home', key: 'home', onClick: () => history.push('/') },
        {
            text: getCardLabel(requestType),
            key: `${requestType}`,
            isCurrentItem: true
        }
    ];

    const renderBreadcrumb = (props: any) => {
        if (props.key !== StringConstants.MANAGE_REQUEST && props.key !== StringConstants.STARRED_REQUEST && props.key !== StringConstants.SHARED_REQUEST && props.key !== StringConstants.HIDDEN_REQUEST && props.key !== 'home') {
            return (
                <div className="breadcrumb-lb">
                    <img className="breadcrumb-lb-icon" src={listIcon} alt="list icon" />
                    <Text className="breadcrumb-lb-text">{props.text}</Text>
                </div>
            )
        } else {
            return (<Text className={props.key === 'home' ? 'breadcrumb-home' : 'breadcrumb-item'}>{props.text}</Text>)
        }
    }

    //useState variables
    const [columnsLookUpData, setColumnsLookUp] = useState([
        {
            key: 0,
            column: translate('ID'),
            isChecked: true
        },
        {
            key: 1,
            column: translate('Title'),
            isChecked: true
        },
        {
            key: 2,
            column: translate('Request Type'),
            isChecked: true
        },
        {
            key: 3,
            column: translate('Status'),
            isChecked: true
        },
        {
            key: 4,
            column: translate('Assigned To'),
            isChecked: true
        },
        {
            key: 5,
            column: translate('Created On'),
            isChecked: true
        },
        {
            key: 6,
            column: translate('Aging'),
            isChecked: true
        },
        {
            key: 7,
            column: translate('Total Revenue Risk'),
            isChecked: true
        }
    ]);

    const [requestPayload, setRequestPayload] = useState<IRequestPayload>();
    const [options, setOptions] = useState<IUserProps[]>([]);
    const [countUrl, setCountUrl] = useState('');

    const [list, setList] = useState([]);
    const [showMenu, setShowMenu] = useState(false);

    const [DCFSelection, setDCFSelection] = useState<IOptionProps[]>([]);
    const [createdOnOrder, setCreatedOnOrder] = useState('');
    const [tagOrder, setTagOrder] = useState('');
    const [agingOrder, setAgingOrder] = useState('');
    const [revenueOrder, setRevenueOrder] = useState('');
    const [agingSelection, setAgingSelection] = useState<IOptionProps[]>([]);
    const [totalPage, setTotalPage] = useState(0);
    const [isDatePickerActive, setIsDatePickerActive] = useState(false);
    const [activeRange, setActiveRange] = useState(StringConstants.ALL);
    const [dateRange, setDateRange] = useState<IDateRangeProps>({ start: null, end: null });
    const [selectedRequest, setSelectedRequest] = useState<IRequest>({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [preferenceDataFlag, setPreferenceDataFlag] = useState(false);
    const [productsSelection, setProductsSelection] = useState<IOptionProps[]>([]);
    const [countrySelection, setCountrySelection] = useState<IOptionProps[]>([]);
    const [requestTypeSelection, setRequestTypeSelection] = useState<IOptionProps[]>([]);
    const [preferenceFilterDataFlag, setPreferenceFilterDataFlag] = useState(false);
    const [filteredData, setFilteredData] = useState({
        DCFSelection: DCFSelection,
        agingSelection: agingSelection,
        statusSelection: statusSelection,
        requestTypeSelection: requestTypeSelection,
        assignedToSelection: assignedToSelection,
        productsSelection: productsSelection,
        countrySelection: countrySelection,
        relationshipSelection: relationshipSelection
    });

    //queries
    const { mutate: storePreference } = useStorePreference();
    const { data: userPreferenceData, isLoading: isPreferenceLoading } = useGetUserPreferences();
    const { data, isLoading, isPreviousData } = useRequestData(requestPayload);
    const { data: usersListData, isLoading: isUserListLoading } = useFetchUsersListByRole();
    const { data: requestTypes, isLoading: isRequestTypesLoading } = useRequestTypesLookUpData();
    const { data: teams, isLoading: isTeamsLoading } = useTeamLookUpData();
    const { data: countData } = useSummaryCardsCountForUser(countUrl);
    const { data: requestDetailsLookup, isLoading: isRequestDetailsLoading } = useRequestDetailsLookUpData();
    
    const { data: listBuilderResults, isLoading: isListBuilderLoading } = useRequestListBuilder({
        filter: listBuilderFilters,
        pageNumber: page
    });

    const cardsList = [
        {
            key: StringConstants.MANAGE_REQUEST,
            text: 'All Requests',
            count: countData?.allRequests ? formatCurrency(countData?.allRequests) : 0
        },
        {
            key: StringConstants.STARRED_REQUEST,
            text: 'Favorite Requests',
            count: countData?.bookmarkRequests ? formatCurrency(countData?.bookmarkRequests) : 0
        },
        {
            key: StringConstants.SHARED_REQUEST,
            text: 'Shared With Me',
            count: countData?.watchRequests ? formatCurrency(countData?.watchRequests) : 0
        },
        {
            key: StringConstants.HIDDEN_REQUEST,
            text: 'Hidden Requests',
            count: countData?.hiddenRequests ? formatCurrency(countData?.hiddenRequests) : 0
        }
    ];

    //custom functions

    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleToggleView = (state: boolean) => {
        setLastSelectedRequest({});
        setTimeout(() => {
            setIsListView(state);
            setIsEditMode(false);
        }, 400);
    };

    const setActiveRangeFilter = (value: string) => {
        let dataObj: any = {};
        if (value !== activeRange) {
            dataObj.activeRange = value;
        }
        if (value === StringConstants.WEEK || value === StringConstants.MONTH || value === StringConstants.YEAR || value === 'ALL') {
            dataObj.isDatePickerActive = false;
            dataObj.dateRange = { start: null, end: null };
        }
        setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, false, {
            ...stateData,
            ...dataObj
        });
    };

    const handleChecked = (key: number, checked?: boolean) => {
        let lookUp = [...columnsLookUpData];
        lookUp.every((item) => {
            if (item.key === key) {
                item.isChecked = checked;
                return false;
            } else {
                return true;
            }
        });
        setColumnsLookUp(lookUp);
    };

    //useEffect hooks

    useEffect(() => {
        if ((dateRange?.start || dateRange?.end) && isDatePickerActive) {
            setActiveRangeFilter('');
        }
    }, [dateRange, isDatePickerActive]);

    useEffect(() => {
        setList(columnsLookUpData);
    }, [requestType, columnsLookUpData]);

    useEffect(() => {
        if (preferenceDataFlag && preferenceFilterDataFlag) {
            let countUrl = getDateRangeUrl(activeRange, dateRange);
            setCountUrl(countUrl);
        }
    }, [activeRange, isDatePickerActive, dateRange, preferenceDataFlag, preferenceFilterDataFlag]);

    useEffect(() => {
        let localFilterData: {
            DCFSelection: IOptionProps[];
            agingSelection: IOptionProps[];
            statusSelection: IOptionProps[];
            requestTypeSelection: IOptionProps[];
            assignedToSelection: IUserProps[];
            productsSelection: IOptionProps[];
            countrySelection: IOptionProps[];
            relationshipSelection: IOptionProps[];
        } = {
            DCFSelection: [],
            agingSelection: [],
            statusSelection: [],
            requestTypeSelection: [],
            assignedToSelection: [],
            productsSelection: [],
            countrySelection: [],
            relationshipSelection: []
        };
        let localFlag = {
            DCFSelection: false,
            agingSelection: false,
            statusSelection: false,
            requestTypeSelection: false,
            assignedToSelection: false,
            productsSelection: false,
            countrySelection: false,
            relationshipSelection: false
        };

        if (DCFSelection?.length === 0 || DCFSelection?.filter((item) => item.key === StringConstants.ALL)?.length !== 0) {
            localFilterData = { ...localFilterData, DCFSelection: [] };
            if (filteredData.DCFSelection.length !== 0) {
                localFlag = { ...localFlag, DCFSelection: true };
            }
        } else {
            let flag = true;
            DCFSelection.forEach((item) => {
                if (filteredData.DCFSelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, DCFSelection: DCFSelection };
                localFlag = { ...localFlag, DCFSelection: true };
            }
        }
        if (agingSelection?.length === 0 || agingSelection?.filter((item) => item.key === StringConstants.ALL).length !== 0) {
            localFilterData = { ...localFilterData, agingSelection: [] };
            if (filteredData.agingSelection.length !== 0) {
                localFlag = { ...localFlag, agingSelection: true };
            }
        } else {
            let flag = true;
            agingSelection?.forEach((item) => {
                if (filteredData?.agingSelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, agingSelection: agingSelection };
                localFlag = { ...localFlag, agingSelection: true };
            }
        }
        if (statusSelection?.length === 0 || statusSelection?.filter((item) => item.key === StringConstants.ALL).length !== 0) {
            localFilterData = { ...localFilterData, statusSelection: [] };
            if (filteredData.statusSelection.length !== 0) {
                localFlag = { ...localFlag, statusSelection: true };
            }
        } else {
            let flag = true;
            statusSelection?.forEach((item) => {
                if (filteredData.statusSelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, statusSelection: statusSelection };
                localFlag = { ...localFlag, statusSelection: true };
            }
        }
        if (productsSelection?.length === 0 || productsSelection?.filter((item) => item.key === 0).length !== 0) {
            localFilterData = { ...localFilterData, productsSelection: [] };
            if (filteredData.productsSelection.length !== 0) {
                localFlag = { ...localFlag, productsSelection: true };
            }
        } else {
            let flag = true;
            productsSelection?.forEach((item) => {
                if (filteredData.productsSelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, productsSelection: productsSelection };
                localFlag = { ...localFlag, productsSelection: true };
            }
        }
        if (countrySelection?.length === 0 || countrySelection?.filter((item) => item?.key === 0).length !== 0) {
            localFilterData = { ...localFilterData, countrySelection: [] };
            if (filteredData.countrySelection.length !== 0) {
                localFlag = { ...localFlag, countrySelection: true };
            }
        } else {
            let flag = true;
            countrySelection?.forEach((item) => {
                if (filteredData.countrySelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, countrySelection: countrySelection };
                localFlag = { ...localFlag, countrySelection: true };
            }
        }
        if (assignedToSelection?.length === 0 || assignedToSelection?.filter((item) => item.id === StringConstants.ALL).length !== 0) {
            localFilterData = { ...localFilterData, assignedToSelection: [] };
            if (filteredData.assignedToSelection.length !== 0) {
                localFlag = { ...localFlag, assignedToSelection: true };
            }
        } else {
            let flag = true;
            assignedToSelection.forEach((item) => {
                if (filteredData.assignedToSelection.filter((ele) => ele.id === item.id).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, assignedToSelection: assignedToSelection };
                localFlag = { ...localFlag, assignedToSelection: true };
            }
        }
        if (
            requestTypeSelection?.length === 0 ||
            requestTypeSelection?.filter((item) => item.key === 'all_request').length !== 0
        ) {
            localFilterData = { ...localFilterData, requestTypeSelection: [] };
            if (filteredData?.requestTypeSelection.length !== 0) {
                localFlag = { ...localFlag, requestTypeSelection: true };
            }
        } else {
            let flag = true;
            requestTypeSelection?.forEach((item) => {
                if (filteredData.requestTypeSelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, requestTypeSelection: requestTypeSelection };
                localFlag = { ...localFlag, requestTypeSelection: true };
            }
        }
        if (relationshipSelection?.length === 0 || relationshipSelection?.filter((item) => item.key === StringConstants.ALL).length !== 0) {
            localFilterData = { ...localFilterData, relationshipSelection: [] };
            if (filteredData.relationshipSelection.length !== 0) {
                localFlag = { ...localFlag, relationshipSelection: true };
            }
        } else {
            let flag = true;
            relationshipSelection.forEach((item) => {
                if (filteredData.relationshipSelection.filter((ele) => ele.key === item.key).length === 0) {
                    flag = false;
                }
            });
            if (!flag) {
                localFilterData = { ...localFilterData, relationshipSelection: relationshipSelection };
                localFlag = { ...localFlag, relationshipSelection: true };
            }
        }
        let count = 0;
        Object.entries(localFlag).forEach(([key, val]) => {
            if (val) {
                count += 1;
            }
        });

        if (count > 0) {
            setFilteredData({
                DCFSelection: localFilterData.DCFSelection,
                agingSelection: localFilterData.agingSelection,
                statusSelection: localFilterData.statusSelection,
                requestTypeSelection: localFilterData.requestTypeSelection,
                assignedToSelection: localFilterData.assignedToSelection,
                productsSelection: localFilterData.productsSelection,
                countrySelection: localFilterData.countrySelection,
                relationshipSelection: localFilterData.relationshipSelection
            });
        }
    }, [DCFSelection, agingSelection, statusSelection, requestTypeSelection, assignedToSelection, productsSelection, countrySelection, relationshipSelection]);

    useEffect(() => {
        /* if (Object.entries(lastSelectedRequest).length === 0) {
            setSelectedRequest({});
        } */
        // setPage(1);
        setNewlyAddedRow('');
    }, [
        dateRange,
        activeRange,
        filteredData,
        agingSelection,
        statusSelection,
        requestTypeSelection,
        assignedToSelection,
        productsSelection,
        countrySelection,
        createdOnOrder,
        agingOrder,
        requestType,
        revenueOrder,
        relationshipSelection
    ]);

    useEffect(() => {
        if (listBuilderFilters !== null && !isListBuilderLoading && listBuilderResults !== undefined) {
            setRequestData(formatIntoRequestData(listBuilderResults?.items));
        } else if (!isLoading && !isTeamsLoading && !isRequestTypesLoading && !isRequestDetailsLoading && listBuilderFilters === null) {
            setRequestData(formatIntoRequestData(data?.items, teams, requestTypes));
        }
    }, [isLoading, isTeamsLoading, isRequestTypesLoading, isRequestDetailsLoading, teams, data?.items, requestTypes, data, listBuilderResults, previousLocation, isListBuilderLoading, listBuilderFilters]);

    useEffect(() => {
        if (data?.totalPages && listBuilderFilters === null) {
            setTotalPage(data?.totalPages);
        } else if (listBuilderFilters !== null && listBuilderResults?.totalPages) {
            setTotalPage(listBuilderResults?.totalPages);
        }
    }, [data?.totalPages, listBuilderResults?.totalPages, listBuilderFilters]);

    useEffect(() => {
        if (Object.entries(lastSelectedRequest).length === 0) {
            setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, true, { ...stateData });
        }
    }, []);

    useEffect(() => {
        setSelectedRequest({});
        setIsEditMode(false);
    }, [page]);
    useEffect(() => {
        if (Object.entries(lastSelectedRequest).length !== 0) {
            setSelectedRequest(lastSelectedRequest);
        }
    }, [lastSelectedRequest]);

    useEffect(() => {
        let columnsData: string[] = [];
        columnsLookUpData.forEach((item) => columnsData.push(item.key + ': ' + item.isChecked));
        if (!isPreferenceLoading) {
            storePreference({
                dashboardColumn: columnsData,
                dashboardView: isListView ? 'LIST' : 'DETAIL',
                dashboardDateFilter: activeRange,
                newsletterSubscription: true,
                requestType: JSON.stringify(requestTypeSelection),
                assignee: JSON.stringify(assignedToSelection),
                caseStatus: JSON.stringify(statusSelection),
                aging: JSON.stringify(agingSelection?.map((ele) => ele.key)),
                deviceModel: JSON.stringify(productsSelection),
                countryModel: JSON.stringify(countrySelection),
                relationship: JSON.stringify(relationshipSelection),
                dcfRequest: JSON.stringify(DCFSelection)
            });
        }
    }, [
        // isPreferenceLoading,
        columnsLookUpData,
        isListView,
        activeRange,
        requestTypeSelection,
        assignedToSelection,
        statusSelection,
        agingSelection,
        productsSelection,
        countrySelection,
        relationshipSelection,
        DCFSelection
    ]);

    useEffect(() => {
        if (preferenceFilterDataFlag && preferenceDataFlag) {
            setRequestPayload(
                getFilteredUrl(
                    currentSortProp,
                    page,
                    requestType,
                    dateRange,
                    activeRange,
                    agingSelection,
                    statusSelection,
                    requestTypeSelection,
                    assignedToSelection,
                    productsSelection,
                    countrySelection,
                    createdOnOrder,
                    tagOrder,
                    revenueOrder,
                    relationshipSelection,
                    DCFSelection,
                    portal
                )
            );
        }
    }, [
        agingSelection,
        statusSelection,
        DCFSelection,
        requestTypeSelection,
        assignedToSelection,
        productsSelection,
        countrySelection,
        createdOnOrder,
        tagOrder,
        dateRange,
        activeRange,
        revenueOrder,
        requestType,
        page,
        relationshipSelection,
        preferenceDataFlag,
        preferenceFilterDataFlag
    ]);

    useEffect(() => {
        if (
            requestType !== StringConstants.MANAGE_REQUEST &&
            requestType !== StringConstants.STARRED_REQUEST &&
            requestType !== StringConstants.SHARED_REQUEST &&
            requestType !== StringConstants.HIDDEN_REQUEST
        ) {
            setRequestType(StringConstants.MANAGE_REQUEST);
        }
    }, []);

    useEffect(() => {
        if (!isPreferenceLoading && !userPreferenceData) {
            setPreferenceFilterDataFlag(true);
            setPreferenceDataFlag(true);
        } else if (!isPreferenceLoading && userPreferenceData) {
            let localFLag = { view: false, dateFilter: false, columns: false };
            switch (userPreferenceData?.dashboardView) {
                case 'LIST':
                    setIsListView(true);
                    localFLag = { ...localFLag, view: true };
                    break;
                case 'DETAIL':
                    setIsListView(false);
                    localFLag = { ...localFLag, view: true };
                    break;
                default:
                    localFLag = { ...localFLag, view: true };
                    break;
            }
            switch (userPreferenceData?.dashboardDateFilter) {
                case StringConstants.WEEK:
                case StringConstants.MONTH:
                case StringConstants.YEAR:
                case 'ALL':
                    setActiveRange(userPreferenceData?.dashboardDateFilter);
                    localFLag = { ...localFLag, dateFilter: true };
                    break;
                default:
                    setActiveRange(StringConstants.WEEK);
                    localFLag = { ...localFLag, dateFilter: true };
                    break;
            }
            if (userPreferenceData?.dashboardColumn.length === 8) {
                let tempArray: any[] = [];
                userPreferenceData.dashboardColumn.forEach((item: string) => {
                    let keyStr = parseInt(item.split(': ')[0]);
                    let checked = item.split(': ')[1] === 'true';
                    let element = columnsLookUpData.filter((ele) => ele.key === keyStr)[0];
                    element.isChecked = checked;
                    tempArray.push(element);
                });

                setColumnsLookUp(tempArray);
                localFLag = { ...localFLag, columns: true };
            } else {
                localFLag = { ...localFLag, columns: true };
            }

            let count = 0;
            Object.entries(localFLag).forEach(([key, val]) => {
                if (val) {
                    count += 1;
                }
            });
            if (count === 3) {
                setPreferenceFilterDataFlag(true);
            }
        }
    }, [isPreferenceLoading]);

    useEffect(() => {
        if (!isUserListLoading && usersListData && usersListData.length !== 0) {
            let arr: IUserProps[] = [];            
            arr.push({
                id: StringConstants.ALL,
                displayName: '',
                mail: '',
                mobilePhone: '',
                businessPhones: [''],
                text: StringConstants.UPPERCASE_ALL
            });

            if (usersListData.filter((item: IUserProps) => item.id !== userDetails.id).length !== 0) {
                arr.push({
                    id: userDetails.id,
                    displayName: userDetails.displayName,
                    mail: userDetails.email,
                    mobilePhone: userDetails.contactNumber,
                    businessPhones: userDetails.businessPhones,
                    text: '@Me'
                });
            }

            arr.push({ id: '0', text: 'Unassigned', displayName: 'Unassigned', mail: '', mobilePhone: '', businessPhones: [] });
            usersListData
                .filter((item: IUserProps) => item.id !== userDetails.id)
                .forEach((element: IUserProps) => {
                    arr.push({ ...element, text: element.displayName });
                });
            setOptions(arr);
        }
    }, [usersListData, isUserListLoading]);

    useEffect(() => {
        if (
            requestTypeSelection?.length !== 0 &&
            requestTypeSelection?.filter(
                (item) => item.key === StringConstants.PRE_SALES_REQUEST || item.key === StringConstants.POST_SALES_REQUEST || item.key === StringConstants.WARRANTY_REQUEST
            ).length === 0
        ) {
            setDCFSelection([]);
        }
    }, [requestTypeSelection]);

    const stateData = useMemo(() => {
        return {
            DCFSelection: DCFSelection,
            statusSelection: statusSelection,
            agingSelection: agingSelection,
            requestTypeSelection: requestTypeSelection,
            assignedToSelection: assignedToSelection,
            productsSelection: productsSelection,
            countrySelection: countrySelection,
            activeRange: activeRange,
            requestType: requestType,
            isDatePickerActive: isDatePickerActive,
            revenueOrder: revenueOrder,
            createdOnOrder: createdOnOrder,
            tagOrder: tagOrder,
            dateRange: dateRange,
            relationshipSelection: relationshipSelection
        };
    }, [
        DCFSelection,
        statusSelection,
        agingSelection,
        requestTypeSelection,
        assignedToSelection,
        productsSelection,
        countrySelection,
        activeRange,
        requestType,
        isDatePickerActive,
        revenueOrder,
        createdOnOrder,
        dateRange,
        relationshipSelection
    ]);

    useEffect(() => {
        const locationData = location?.state as any;
        if (locationData) {
            setDCFSelection(locationData?.DCFSelection);
            setStatusSelection(locationData?.statusSelection);
            setAgingSelection(locationData?.agingSelection);
            setRequestTypeSelection(locationData?.requestTypeSelection);
            setAssignedToSelection(locationData?.assignedToSelection);
            setProductsSelection(locationData?.productsSelection);
            setCountrySelection(locationData?.countrySelection)
            setActiveRange(locationData?.activeRange);
            setRequestType(locationData?.requestType);
            setIsDatePickerActive(locationData?.isDatePickerActive);
            setRevenueOrder(locationData?.revenueOrder);
            setCreatedOnOrder(locationData?.createdOnOrder);
            setDateRange(locationData?.dateRange);
            setRelationshipSelection(locationData?.relationshipSelection);
            setPage(Number(getQueryParam(history).page));
        } else {
            setPage(Number(getQueryParam(history).page) || 1);
        }
    }, [history?.location?.search, location?.state]);

    return (
        <>
            <div className="container manage-request-cont m-b-30">
                <Breadcrumb className="manage-request-breadcrumb" items={itemsWithHref} maxDisplayedItems={4} onRenderItem={(props) => renderBreadcrumb(props)} />

                <SummaryCard
                    cardsList={cardsList}
                    stateData={stateData}
                />
                {isPreferenceLoading ? (
                    <div style={{ height: '500px' }}>
                        <Shimmer className="p-b-10 p-t-10" />
                        <Shimmer className="p-b-10" />
                    </div>
                ) : (
                    <div>
                        <FilterPanel
                            isListView={isListView}
                            handleToggleView={handleToggleView}
                            requestTypeSelection={requestTypeSelection}
                            setRequestTypeSelection={setRequestTypeSelection}
                            list={list}
                            showMenu={showMenu}
                            columnsLookUpData={columnsLookUpData}
                            setList={setList}
                            handleChecked={handleChecked}
                            handleShowMenu={handleShowMenu}
                            setColumnsLookUp={setColumnsLookUp}
                            statusSelection={statusSelection}
                            setStatusSelection={setStatusSelection}
                            agingSelection={agingSelection}
                            setAgingSelection={setAgingSelection}
                            setProductsSelection={setProductsSelection}
                            productsSelection={productsSelection}
                            setCountrySelection={setCountrySelection}
                            countrySelection={countrySelection}
                            assignedToSelection={assignedToSelection}
                            setAssignedToSelection={setAssignedToSelection}
                            options={options}
                            setOptions={setOptions}
                            userPreference={userPreferenceData}
                            DCFSelection={DCFSelection}
                            setDCFSelection={setDCFSelection}
                            setPreferenceDataFlag={setPreferenceDataFlag}
                            relationshipSelection={relationshipSelection}
                            setRelationshipSelection={setRelationshipSelection}
                            stateData={stateData}
                            setPreviousLocation={setPreviousLocation}
                            setListBuilderFilters={setListBuilderFilters}
                        />
                        {isListView && (
                            <div className="col-flex-box ">
                                <div ref={gridContRef} className={`table-cont  `}>
                                    {isLoading ? (
                                        <div className="p-a-20">
                                            <Shimmer className="p-b-10" />
                                            <Shimmer className="p-b-10" width="75%" /> <Shimmer width="50%" />
                                        </div>
                                    ) : (
                                        <GridTable
                                            columnsLookUpData={columnsLookUpData}
                                            requestData={requestData}
                                            page={page}
                                            setPage={setPage}
                                            isLoading={isLoading}
                                            isPreviousData={isPreviousData}
                                            isEditMode={isEditMode}
                                            setIsEditMode={setIsEditMode}
                                            isListView={isListView}
                                            setIsListView={setIsListView}
                                            setSelectedRequest={setSelectedRequest}
                                            gridContRef={gridContRef}
                                            createdOnOrder={createdOnOrder}
                                            setCreatedOnOrder={setCreatedOnOrder}
                                            agingOrder={agingOrder}
                                            setAgingOrder={setAgingOrder}
                                            revenueOrder={revenueOrder}
                                            setRevenueOrder={setRevenueOrder}
                                            setCurrentSortProp={setCurrentSortProp}
                                            stateData={stateData}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {!isListView && (
                    <ManageRequestDetailedView
                        isListView={isListView}
                        handleToggleView={handleToggleView}
                        requestData={requestData}
                        setRequestData={setRequestData}
                        requestTypes={requestTypes}
                        isRequestTypesLoading={isRequestTypesLoading}
                        teams={teams}
                        isTeamsLoading={isTeamsLoading}
                        page={page}
                        setPage={setPage}
                        selectedRequest={selectedRequest}
                        setSelectedRequest={setSelectedRequest}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        isLoading={isLoading}
                        requestDetailsLookup={requestDetailsLookup}
                        isRequestDetailsLoading={isRequestDetailsLoading}
                        //resultCount={data?.totalCount}
                        resultCount={requestData?.length}
                    />
                )}
                {(page !== 1 || (!isLoading && page === 1)) && requestData.length !== 0 && (
                    <Pagination
                        pageCount={totalPage}
                        setPageNumber={setPage}
                        initialPage={page - 1}
                        //totalCount={data?.totalCount}
                        totalCount={requestData.length}
                        resultsPerPage={listBuilderFilters === null ? 20 : (requestData.length < 50 ? requestData.length : 50)}
                        updateURL={true}
                        stateData={stateData}
                    />
                )}
            </div>
        </>
    );
};

export default ManageRequests;
