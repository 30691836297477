import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import UnifiedDashboardView from 'app/modules/user/unifiedDashboardView';
import useStore from 'app/store';
import { useHistory } from 'react-router-dom';
import { AppQueryClient } from 'app/services/clients/query-client';
const pageVariants = {
    initial: {
        opacity: 0,
        y: '100vw',
        scale: 0.8
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 0,
        y: '-100vw',
        scale: 1.2
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 1
};

const UnifiedDashboard: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history?.location?.pathname);
    }, []);
    setInterval(() => {
        AppQueryClient.invalidateQueries('useSummaryCardsCountForUser');
    }, 100000);
    return (
        <>
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                <UnifiedDashboardView />
            </motion.div>
        </>
    );
};

export default UnifiedDashboard;
