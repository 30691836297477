import React from 'react';
import { motion } from 'framer-motion';
import NewsFeedLanding from 'app/modules/articles/viewArticle/newsFeedLanding/NewsFeedLanding';

const pageVariants = {
    initial: {
        opacity: 0,
        y: '100vw',
        scale: 0.8
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 0,
        y: '-100vw',
        scale: 1.2
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 1
};

const NewsFeed: React.FC = () => {
    return (
        <>
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                <NewsFeedLanding />
            </motion.div>
        </>
    );
};

export default NewsFeed;
