import React from 'react';
import { Text } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { changeDateFormat, getParagraphFromContent } from 'app/components/helpers';
import useStore from 'app/store';
import './index.scss';
import { ArticleType } from 'app/enums/ArticleType.enum';

interface ISearchProps {
    listData: any;
}

const SearchResultList: React.FC<ISearchProps> = (props) => {
    const { listData } = props;
    const setSearchValueClear = useStore((state: any) => state.setSearchValueClear);

    const starList = [];

    for (let i = 1; i <= listData?.Document.rating; i++) {
        starList.push(
            <Link role="link" to="#" key={`${listData.Document.id}-${i}`}>
                <span className="rating-icon active"></span>
            </Link>
        );
    }

    const notClearSearchValue = (item: any) => {
        setSearchValueClear(false);
    };
    const redirectTo = () => {
        if (listData.Document.ArticleType === ArticleType.Training.toString()) {
            return { pathname: '/portal-training', state: listData.Document.Id };
        } else {
            return listData.Document.ResultType === 'Ticket'
                ? `/request/${listData.Document.UnifiedTrackingId}`
                : `/article/${listData.Document.Id}`;
        }
    };
    return (
        <>
            <div className="search-list" data-status={listData.Document.ResultType}>
                <div>
                    <Text>
                        <Link
                            to="#"
                            className={`badge badge-pill badge-primary  ${
                                listData.Document.ResultType === 'Ticket' ? 'badge-help' : 'badge-article'
                            }`}>
                            {listData.Document.ResultType === 'Ticket' ? 'Support Requests' : listData.Document.ResultType}
                        </Link>
                    </Text>
                </div>
                <div className="results-list">
                    <Link onClick={() => notClearSearchValue(listData.Document)} to={redirectTo}>
                        <h3>{listData.Document.Title}</h3>
                    </Link>

                    {listData.Document.ResultType === 'Ticket' && (
                        <p
                            className="results-description"
                            dangerouslySetInnerHTML={{
                                __html: `${listData.Document.UnifiedTrackingId} - ${listData.Document.Description}`
                            }}></p>
                    )}

                    {listData.Document.ResultType !== 'Ticket' && (
                        <p
                            className="results-description"
                            dangerouslySetInnerHTML={{
                                __html: getParagraphFromContent(listData?.Document?.Description)
                            }}></p>
                    )}
                </div>
                <div>
                    <div className="results-social">
                        <span className="text-article results-date">
                            Last Updated : {changeDateFormat(listData.Document.CreatedOn, 'MMMM DD, YYYY')}
                        </span>
                        {listData.Document.ResultType !== 'Ticket' && listData.Document.Tags[0]?.DisplayName && (
                            <div className='results-social-list'>
                                {listData.Document.Tags.map((item: any) => (
                                    <span className="text-article">
                                        <span className="results-type">
                                            <span className="article-icon"></span>
                                            {item?.DisplayName}
                                        </span>
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchResultList;
