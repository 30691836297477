import * as React from 'react';
import './DragHandleCell.scss';

export const DragHandleCell = (props: any) => {
    return (
        <td {...props}>
            <div className="dragCellStyles">
                <span className="k-icon k-i-reorder" style={{ cursor: 'move' }} data-drag-handle={true} />
            </div>
        </td>
    );
};
