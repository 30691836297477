import React, { useEffect, useRef, useState } from 'react';
import { Icon, SearchBox, Text } from '@fluentui/react';
import { Trans } from 'react-i18next';
import './FieldBuilder.scss';
import xButton from '../../../../static/icons/xButton.svg';
import xButtonDisabled from '../../../../static/icons/xButton-disabled.svg';
import { IOptionProps } from '../../manageRequests/models';
import Picker from './picker/Picker';
import { keyPress } from 'app/components/helpers';
import ConditionDropdown from './conditionDropdown/ConditionDropdown';
import { IListOptionProps } from '../models/models';
import { allOperators, crewFields, cssFields, cxpFields, sdsgFields } from '../options/Options';
import { IListBuilderFilter, IListBuilderPropertyFilter } from 'app/models/common/request';
import { handleFirstWordAttribute, handleSecondWordAttribute } from '../helpers/helpers';
import { RequestType } from 'app/enums/RequestType.enum';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    //setConditionSelection?: any;
    selectedKeys?: IOptionProps[];
    setSelectedKeys?: Function;
    isListView?: boolean;
    plusIconPosition?: string;
    setPlusIconPosition?: Function;
    fieldOptions?: any;
    setFieldIndex?: any;
    fieldIndex: any;
    //isChecked?: any;
    handleExitOut?: any;
    teamSelection?: IOptionProps[];
    listBuilderFilter: IListBuilderFilter;
    setListBuilderFilter: (listBuilderFilter: IListBuilderFilter) => void;
    key?: number;
    index?: number;
    setPlusIconDisabled: (plusIconDisabled: boolean) => void;
    setPlusIconIndex: (plusIconIndex: number) => void;
    plusIconIndex: number;
    fieldComponents: JSX.Element[];
    setFieldComponents: (components: JSX.Element[]) => void;
    userOptions: any[];
    loadingPropertyFilter?: IListBuilderPropertyFilter;
    inEditMode?: boolean;
    plusIconRef: React.MutableRefObject<any>;
    setSaveButtonDisabled: Function;
    typeSelection: any[];
    deleteDisabled: boolean;
    setDeleteDisabled: (deleteDisabled: boolean) => void;
}

const FieldBuilder: React.FC<IProps> = ({
    selectedKeys,
    teamSelection,
    listBuilderFilter,
    fieldIndex,
    setFieldIndex,
    setListBuilderFilter,
    index,
    setPlusIconPosition,
    setPlusIconDisabled,
    setPlusIconIndex,
    plusIconIndex,
    fieldComponents,
    setFieldComponents,
    loadingPropertyFilter,
    inEditMode,
    plusIconRef,
    setSaveButtonDisabled,
    typeSelection,
    deleteDisabled,
    setDeleteDisabled
}) => {
    const dropDownRef = useRef(null);

    const [selectedConditionKey, setSelectedConditionKey] = useState<string | number | undefined>('condition');
    const [fieldOption, setFieldOption] = useState<string>('any field');
    const [stringLength, setStringLength] = useState(0);
    const [toggleListBuilder, setToggleListBuilder] = useState(true);
    const [triggerListBuilder, setTriggerListBuilder] = useState(false);

    const [conditionSelection, setConditionSelection] = useState<IOptionProps>();
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IOptionProps[]>([]);
    const [conditions, setConditions] = useState('');
    const [fieldSelection, setFieldSelection] = useState<IOptionProps>();
    const [picker, setPicker] = useState('');
    const [pickerOption, setPickerOption] = React.useState<string | number | Date | undefined | any[]>(undefined);
    const [fieldOptions, setFieldOptions] = useState<IOptionProps[]>([]);

    // AND/OR container states
    const [initializeAndOr, setInitializeAndOr] = useState(true);
    const [activateAnd, setActivateAnd] = useState(true);
    const [activateOr, setActivatedOr] = useState(false);
    const [showAndOr, setShowAndOr] = useState(true);
    const [triggerAndOr, setTriggerAndOr] = useState(true);

    // Query progression, field disabled
    const [pickerDropdownDisabled, setPickerDropdownDisabled] = useState<boolean>(true);
    const [fieldPickerActive, setFieldPickerActive] = useState<boolean>(true);
    const [conditionPickerActive, setConditionPickerActive] = useState<boolean>(false);
    const [pickerActive, setPickerActive] = useState<boolean>(false);

    const [propertyFilter, setPropertyFilter] = useState<IListBuilderPropertyFilter>({
        condition: null,
        operator: 'or',
        filter: null
    });

    const handleAnd = () => {
        setInitializeAndOr(false);
        setActivateAnd(!activateAnd);
        setActivatedOr(!activateOr);

        setPropertyFilter({
            ...propertyFilter,
            operator: 'and'
        });
    };

    const handleOr = () => {
        setInitializeAndOr(!activateAnd);
        setActivatedOr(!activateOr);
        setActivateAnd(!activateAnd);

        setPropertyFilter({
            ...propertyFilter,
            operator: 'or'
        });
    };

    const handleExitOut = () => {
        // X Out of TypeBuilder - Different animation triggers
        listBuilderFilter.propertyFilters.splice(index, 1);
        setListBuilderFilter({ ...listBuilderFilter, propertyFilters: listBuilderFilter.propertyFilters });
        setPlusIconPosition('Up');
        setDeleteDisabled(true);

        setTimeout(() => {
            setTriggerListBuilder(true);
            setTriggerAndOr(false);
        }, 750);
        setTimeout(() => {
            setToggleListBuilder(false);
            setShowAndOr(false);
            setActivateAnd(false);
            setActivatedOr(false);
            fieldComponents.splice(index, 1);
            setFieldComponents([...fieldComponents]);
            setDeleteDisabled(false);
        }, 2000);

        setFieldIndex(fieldIndex - 1);

        // for plus icon animations
        var element = plusIconRef.current;

        element.style.setProperty('--icon-previous-position', `${172 * (plusIconIndex > 0 ? plusIconIndex - 1 : 0)}px`);
        element.style.setProperty('--icon-position', `${172 * plusIconIndex}px`);

        element.classList.remove('plusIconUp');

        void element.offsetWidth;

        element.classList.add('plusIconUp');

        setPlusIconIndex(plusIconIndex - 1);
    };

    let maxLength = 20;

    const onToggleShow = () => {
        setShowDropdown((value: any) => !value);
    };

    useDropDownDismiss(dropDownRef, onToggleShow);

    const handleOptionClick = (value: any) => {
        // reset user selections
        setPickerOption(null);
        setSelectedPickerOptions([]);
        setSelectedPickerKeys([]);
        setConditionSelection(null);
        setPropertyFilter({
            ...propertyFilter,
            condition: null,
            filter: null
        });

        // set new user selection
        onToggleShow();
        setSelectedConditionKey('condition');
        setFieldOption(value.text);
        setFieldSelection({ key: value.key, text: value.text, paramsProperty: value.paramsProperty });
        setConditions(value.condition);
        setPicker(value.picker);
        setStringLength(value.text.length);
        setSearchValue('');
        setFieldPickerActive(false);
        setConditionPickerActive(true);
    };

    const onChangeSearchBox = (key: number) => {
        if (key === 0) {
            if (selectedKeys.filter((item) => item.key === key)?.length === 0) {
                let arr: IOptionProps[] = [];
                fieldOptions.forEach((ele: any) => {
                    arr.push(ele);
                    //setTypeBuilderKey(ele.key);
                });

                //setSelectedKeys(arr);
            } else {
                //setSelectedKeys([]);
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key)?.length === 0) {
                //const tempSelectedKeys = [...selectedKeys, fieldOptions.filter((item: any) => item.key === key)[0]];
                //setSelectedKeys(tempSelectedKeys);
            } else {
                //const tempSelectedKeys = selectedKeys.filter((item) => item.key !== key && item.key !== StringConstants.ALL);
                //setSelectedKeys([]);
            }
        }
    };

    const formatIntoOptions = (data: IListOptionProps[]) => {
        let arr: IListOptionProps[] = [];
        let index = 1;
        data?.forEach((item) => {
            let childArray: number[] = [];
            childArray.push(index);
            index = index + 1;
            arr.push({
                key: item.key,
                text: item.text,
                condition: item.condition,
                picker: item.picker,
                paramsProperty: item.paramsProperty
            });
        });
        return arr;
    };

    const fieldOptionHelper = (requestTeam: string): IOptionProps[] => {
        switch (requestTeam) {
            case StringConstants.CREW_TICKETS:
                return formatIntoOptions(crewFields);
            case StringConstants.CSS_TICKETS:
                return formatIntoOptions(cssFields);
            case StringConstants.CXP_TICKETS:
                return formatIntoOptions(cxpFields);
            case StringConstants.SDSG_TICKETS:
                return formatIntoOptions(sdsgFields);
        }
    };

    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IOptionProps[] = [];
            fieldOptions.forEach((item: any) => {
                if (item.text.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    useEffect(() => {
        let options: IOptionProps[] = [];
        for (let i = 0; i < teamSelection?.length; i++) {
            let teamOptions = fieldOptionHelper(teamSelection[i].text);
            for (let j = 0; j < teamOptions.length; j++) {
                if (
                    (teamSelection[i].text === StringConstants.CXP_TICKETS &&
                        !typeSelection.some((x: any) => x.enumValue === RequestType.Cxp_TechnicalRequest)) ||
                    (teamSelection[i].text === StringConstants.CREW_TICKETS &&
                        !typeSelection.some((x: any) => x.enumValue === RequestType.PostSales)) ||
                    (teamSelection[i].text === StringConstants.CSS_TICKETS &&
                        !typeSelection.some((x: any) => x.enumValue === RequestType.CssReviewRequest))
                ) {
                    // Only add Bug Type filter to the list of options if CXP Technical is included in the selection
                    // Only add CSS Case Number filter to the list of options if CXP Technical, CSS Review Request, or CREW Postsales are included in the selection
                    if (teamOptions[j].key !== '33' && teamOptions[j].key !== '31') {
                        options.push(teamOptions[j]);
                    }
                } else if (!options.some((x) => x.key === teamOptions[j].key)) {
                    options.push(teamOptions[j]);
                }
            }
        }

        setFieldOptions(options);
    }, [teamSelection, typeSelection]);

    useEffect(() => {
        setPropertyFilter({ ...propertyFilter, condition: conditionSelection?.enumValue });
        setPickerDropdownDisabled(
            conditionSelection === null ||
                conditionSelection === undefined ||
                fieldSelection === null ||
                fieldSelection === undefined
        );
    }, [conditionSelection, fieldSelection]);

    useEffect(() => {
        if (fieldSelection !== null && fieldSelection !== undefined && pickerOption !== null && pickerOption !== undefined) {
            setSaveButtonDisabled(false);
            setPlusIconDisabled(false);
            setPropertyFilter({
                ...propertyFilter,
                filter: { ...propertyFilter.filter, [fieldSelection.paramsProperty]: pickerOption }
            });
            setPickerActive(false);
        } else {
            setSaveButtonDisabled(true);
            setPlusIconDisabled(true);
            setPickerActive(true);
        }
    }, [pickerOption]);

    useEffect(() => {
        if (propertyFilter.condition && propertyFilter.filter && propertyFilter.operator) {
            setPlusIconDisabled(false);
            setSaveButtonDisabled(false);
            if (listBuilderFilter.propertyFilters[index] !== null && listBuilderFilter.propertyFilters[index] !== undefined) {
                var filters = listBuilderFilter.propertyFilters;
                filters[index] = propertyFilter;
                setListBuilderFilter({ ...listBuilderFilter, propertyFilters: filters });
            } else {
                setListBuilderFilter({
                    ...listBuilderFilter,
                    propertyFilters: [...listBuilderFilter.propertyFilters, propertyFilter]
                });
            }
        } else {
            setPlusIconDisabled(true);
            setSaveButtonDisabled(true);
        }
    }, [propertyFilter, listBuilderFilter.propertyFilters]);

    const [selectedPickerKeys, setSelectedPickerKeys] = useState<any[]>([]);
    const [selectedPickerOptions, setSelectedPickerOptions] = useState<any[]>([]);

    const [multiSelectData, setMultiSelectData] = useState<any[]>([]);

    // prepopulate the field builders if list builder is in edit mode
    useEffect(() => {
        if (inEditMode && loadingPropertyFilter !== null) {
            setFieldPickerActive(false);
            setConditionPickerActive(false);
            setPickerActive(false);

            setPropertyFilter(loadingPropertyFilter);

            if (loadingPropertyFilter.operator === 'and' && index > 0) {
                setActivateAnd(false);
                setActivatedOr(true);
            } else {
                setActivateAnd(true);
                setActivatedOr(false);
            }

            var propertyKeys = Object.keys(loadingPropertyFilter.filter);
            var selectedFilter = {
                key: '',
                value: null as any
            };

            var filters: any = loadingPropertyFilter.filter;

            propertyKeys.forEach((key) => {
                if (filters[key] !== null) {
                    if (
                        (key === 'aging' || key === 'tags' || key === 'assignedTo' || key === 'troubledProductCategory') &&
                        filters[key].length > 0
                    ) {
                        selectedFilter.key = key;
                        selectedFilter.value = filters[key];
                    }

                    if (key !== 'aging' && key !== 'tags' && key !== 'assignedTo' && key !== 'troubledProductCategory') {
                        selectedFilter.key = key;
                        selectedFilter.value = filters[key];
                    }
                }
            });

            onToggleShow();
            setSelectedConditionKey('condition');

            // select field option
            var selectedOption: any = fieldOptions.find((option) => option.paramsProperty === selectedFilter.key);

            setFieldOption(selectedOption?.text);
            setFieldSelection(selectedOption);
            setConditions(selectedOption?.condition);
            setPicker(selectedOption?.picker);
            setStringLength(selectedOption?.text?.length);
            setSearchValue('');

            // set the condition of the field
            var selectedCondition = allOperators.find((operator) => operator.enumValue === loadingPropertyFilter.condition);

            setSelectedConditionKey(selectedCondition.key);
            setConditionSelection(selectedCondition);

            // multi-selected options will be a type of array, typeof will return "object" for arrays
            // multiSelectData is used in the individual multiselect pickers to set the selected keys and selected options
            if (typeof selectedFilter?.value === 'object') {
                // Relationship, Priority, and Bug Type use the fluent UI dropdown component so we set the keys and options here
                // The other multiselect pickers are custom dropdowns and set their keys and options using multiSelectData
                if (
                    selectedOption?.text === 'Relationship' ||
                    selectedOption?.text === 'Priority' ||
                    selectedOption?.text === 'Bug Type' ||
                    selectedOption?.text === 'Status'
                ) {
                    var values: any[] = selectedFilter.value;
                    var selections = selectedOption?.picker?.filter((option: any) => values.includes(option.enumValue));
                    setSelectedPickerKeys(selections.map((item: any) => item.key));
                    setSelectedPickerOptions(values);
                } else {
                    setMultiSelectData(selectedFilter.value);
                }
            } else {
                setPickerOption(selectedFilter.value);
            }

            setPropertyFilter(loadingPropertyFilter);
        }
    }, [inEditMode, loadingPropertyFilter, fieldOptions]);

    return (
        <div className={`listBuilder2-container`}>
            {showAndOr && (
                <div className="div3">
                    <div className={`${triggerAndOr ? 'andOrInContainer' : 'andOrOutContainer'}`}>
                        <div
                            className={`${
                                initializeAndOr
                                    ? 'pipeStyles-and'
                                    : activateAnd || index === 0
                                    ? 'pipeStyles-and'
                                    : 'pipeStyles-or'
                            }`}></div>

                        <div className="andOrStyles">
                            {index !== 0 && (
                                <div onClick={handleOr} className={`orStyles ${activateOr ? '' : 'activeOr'}`}>
                                    Add
                                </div>
                            )}
                            <div>
                                {index === 0 ? (
                                    <div className={`andStyles`}>And</div>
                                ) : (
                                    <div onClick={handleAnd} className={`andStyles ${activateAnd ? '' : 'activeAnd'}`}>
                                        Reduce
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {toggleListBuilder && (
                <div className={`listBuiderFieldCont ${triggerListBuilder ? 'fieldWigetOut' : 'fieldWigetIn'}`}>
                    <div className={` ${activateAnd || index === 0 ? 'pipeStyles-and' : 'pipeStyles-or'}`}></div>
                    <div className={`listBuilder2 `}>
                        <div className="field-container">
                            <div className="add-to-list-container">
                                <Trans>
                                    <Text>
                                        {activateAnd
                                            ? index === 0
                                                ? 'My list should only include'
                                                : 'To my list where'
                                            : 'My list so it contains just'}
                                    </Text>
                                </Trans>
                            </div>
                            <div className="search-cond-cont">
                                <div
                                    className={`${
                                        stringLength >= maxLength
                                            ? 'any-field-lg'
                                            : stringLength < 12
                                            ? 'any-field-sm'
                                            : 'any-field-md'
                                    } ${fieldPickerActive ? 'field-active' : ''}`}
                                    tabIndex={0}
                                    data-testid="pdt-filter-open-btn-test"
                                    onKeyPress={(e: any) => {
                                        keyPress(e, onToggleShow);
                                    }}
                                    onClick={() => onToggleShow()}>
                                    <div
                                        className={`parentContainer ${
                                            stringLength >= maxLength
                                                ? 'chevron-option-styles-lg'
                                                : stringLength < 20
                                                ? 'chevron-option-styles-sm'
                                                : 'chevron-option-styles-md'
                                        }`}>
                                        <div
                                            className={`${
                                                stringLength >= maxLength
                                                    ? 'optionStyles-lg'
                                                    : stringLength < 12
                                                    ? 'optionStyles-sm'
                                                    : 'optionStyles-md'
                                            }`}>
                                            <Text>{fieldOption}</Text>
                                        </div>
                                        <div className="chevron-styles">
                                            <Icon iconName="ChevronDown" />
                                        </div>
                                    </div>
                                </div>
                                {showDropdown && (
                                    <div ref={dropDownRef} className="dropdown-cont-tb">
                                        <div className="field-builder-search-bar">
                                            <SearchBox
                                                placeholder="Search"
                                                tabIndex={0}
                                                data-testid="search-field-test"
                                                onChange={(e: any) => {
                                                    if (e?.target?.value) setSearchValue(e?.target?.value);
                                                    else setSearchValue('');
                                                }}
                                            />
                                        </div>
                                        {searchValue.trim() !== '' ? (
                                            <div>
                                                {' '}
                                                {searchedResults.map((item: any, index: any) => (
                                                    <div
                                                        data-testid={`dropdown-item-test-${index}`}
                                                        onKeyPress={(e: any) => {
                                                            if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                                onChangeSearchBox(item.key as number);
                                                            }
                                                        }}
                                                        className="option-styles dropdown-item parent-item">
                                                        <option
                                                            label={item.text}
                                                            onClick={() => {
                                                                handleOptionClick(item);
                                                            }}
                                                            value={item.text}
                                                        />
                                                    </div>
                                                ))}{' '}
                                            </div>
                                        ) : (
                                            <div>
                                                {fieldOptions.map((item: any, index: any) => (
                                                    <div>
                                                        <div
                                                            className={`${
                                                                fieldOption === item.text ? 'selected-option' : ''
                                                            } option-styles dropdown-item parent-item`}
                                                            data-testid={`dropdown-item-test-${index}`}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                                    onChangeSearchBox(item.key as number);
                                                                }
                                                            }}>
                                                            <div>
                                                                <option
                                                                    label={item.text ? item.text : 'any field'}
                                                                    value={item.text}
                                                                    onClick={() => {
                                                                        handleOptionClick(item);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}{' '}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="that-field">
                                    <Trans>
                                        <Text>{handleFirstWordAttribute(fieldOption, conditionSelection?.enumValue)}</Text>
                                    </Trans>
                                </div>
                                <div>
                                    <ConditionDropdown
                                        setConditionSelection={setConditionSelection}
                                        conditions={conditions}
                                        setSelectedKey={setSelectedConditionKey}
                                        selectedKey={selectedConditionKey}
                                        fieldOption={fieldOption}
                                        conditionPickerActive={conditionPickerActive}
                                        setConditionPickerActive={setConditionPickerActive}
                                        setPickerActive={setPickerActive}
                                        //setConditionOption={setConditionOption}
                                    />
                                </div>
                                <div className="to-this-field">
                                    <Trans>
                                        <Text>{handleSecondWordAttribute(fieldOption, conditionSelection?.enumValue)}</Text>
                                    </Trans>
                                </div>
                                <div className="this-field">
                                    <Picker
                                        picker={picker}
                                        setPickerOption={setPickerOption}
                                        pickerOption={pickerOption}
                                        fieldOption={fieldOption}
                                        //setPickerSelection={setPickerSelection}
                                        setSelectedKeys={setSelectedPickerKeys}
                                        setSelectedOptions={setSelectedPickerOptions}
                                        selectedKeys={selectedPickerKeys}
                                        selectedOptions={selectedPickerOptions}
                                        inEditMode={inEditMode}
                                        multiSelectData={multiSelectData}
                                        pickerDropdownDisabled={pickerDropdownDisabled}
                                        pickerActive={pickerActive}
                                        conditionSelection={conditionSelection}
                                    />
                                </div>
                            </div>
                            <div className="line-bar-container"></div>
                            <div onClick={!deleteDisabled ? () => handleExitOut() : () => {}} className={`x-button-styles ${deleteDisabled ? "x-button-disabled" : ""}`}>
                                <img src={!deleteDisabled ? xButton : xButtonDisabled} alt="icon svg" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default FieldBuilder;
