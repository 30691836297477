import React, { useEffect, useRef, useState } from 'react';
import { Icon, Text, Link, DefaultButton, IconButton, DatePicker } from '@fluentui/react';
import './SelectableCards.scss';
import { getWeekMonthYearRange, setQueryParam } from 'app/components/helpers';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteConstants, StringConstants } from 'app/utils/constants';

export interface CardItem {
    type: number;
    icon?: any;
    label: string;
    value?: number | string;
    isSelected?: boolean;
}

interface IProps {
    items: CardItem[];
    dateFilterData?: object;
    setDateFilterData?: Function;
    setCardTypeFilter?: Function;
    stateData?: any;
}

const SelectableCards: React.FC<IProps> = (props) => {
    const { t: translate } = useTranslation();
    const rangeItemConstants = { WEEK: translate(StringConstants.WEEK), MONTH: translate(StringConstants.MONTH), YEAR: translate(StringConstants.YEAR) };
    // props variables
    const { items, stateData } = props;

    // state variables
    const [isCardsMinimized, setIsCardsMinimized] = useState(false);
    const [selectedCard, setSelectedCard] = useState(0);
    const [isDatePickerActive, setIsDatePickerActive] = useState(false);
    const [activeRange, setActiveRange] = useState(rangeItemConstants.WEEK);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const history = useHistory();
    const location = useLocation();

    const setQueryStateData = (data: any, replace: boolean) => {
        setQueryParam(history, RouteConstants.UNIFIED_DASHBOARD, { page: 1 }, replace, {
            ...stateData,
            ...data
        });
    };

    // helper functions
    const toggleCardsPanel = () => {
        setIsCardsMinimized((currentState) => !currentState);
    };

    const handleSetSelectedCard = (type: number) => {
        setSelectedCard(type);
        setQueryStateData({ cardTypeFilter: type }, false);
    };

    const setActiveCard = (type: number) => {
        return selectedCard === type ? 'active' : '';
    };

    const handleShowDatePicker = () => {
        setIsDatePickerActive((value) => !value);
    };

    const handleWeekMonthYearFilter = (type: string) => {
        setActiveRange(type);
        const rangeData = getWeekMonthYearRange(type);

        if (activeRange !== type) {
            setQueryStateData({ dateFilterData: rangeData }, false);
        }
        if (startDate || endDate) {
            setStartDate(null);
            setEndDate(null);
            handleShowDatePicker();
        }
    };

    const getActiveRangeText = () => {
        let res = '';
        switch (activeRange) {
            case StringConstants.WEEK:
                res = 'Week';
                break;
            case StringConstants.MONTH:
                res = 'Month';
                break;
            case StringConstants.YEAR:
                res = 'Year';
                break;
            default:
                break;
        }
        return res;
    };

    // hooks
    const datePickerRef = useRef(null);

    useDatePickerDismiss(datePickerRef, handleShowDatePicker, startDate, endDate);

    const handleCustomDateChange = (value: Date, type: string) => {
        const startDateValue = type === 'start' ? value : startDate;
        const endDateValue = type === 'end' ? value : endDate;
        if (startDateValue || endDateValue) {
            setActiveRange('');

            const rangeData = {
                from: startDateValue ? moment(startDateValue).startOf('day').utc().format() : null,
                to: endDateValue ? moment(endDateValue).endOf('day').utc().format() : null,
                today: moment().format('YYYY-MM-DD')
            };
            setQueryStateData({ dateFilterData: rangeData }, false);
        }
    };

    useEffect(() => {
        if (items && items.length > 0) {
            for (const item of items) {
                if (item.isSelected) {
                    setSelectedCard(item.type);
                }
            }
        }
    }, [items]);

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData) {
            if (locationData?.dateFilterData?.type) {
                setActiveRange(locationData?.dateFilterData?.type);
            } else {
                setStartDate(locationData?.dateFilterData?.from ? moment(locationData?.dateFilterData?.from).toDate() : null);
                setEndDate(locationData?.dateFilterData?.to ? moment(locationData?.dateFilterData?.to).toDate() : null);
                setIsDatePickerActive(true);
                setActiveRange('');
            }
        }
    }, [history?.location?.search, location?.state]);

    return (
        <>
            <div className={`collapsible-Panel ${isCardsMinimized ? 'minimized' : ''}`}>
                <div className="calendar-head">
                    <Text className="title">
                        <Trans>Current</Trans> {getActiveRangeText()} <Trans>Stats</Trans>
                    </Text>
                    <div className="cal-container">
                        {isDatePickerActive && (
                            <div className="date-picker-block" ref={datePickerRef}>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        placeholder="From: MM/DD/YYYY"
                                        ariaLabel="Select a date"
                                        value={startDate}
                                        data-testid="start-date"
                                        onSelectDate={(value) => {
                                            handleCustomDateChange(value, 'start');
                                        }}
                                    />
                                </div>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        placeholder="To: MM/DD/YYYY"
                                        ariaLabel="Select a date"
                                        value={endDate}
                                        data-testid="end-date"
                                        onSelectDate={(value) => {
                                            handleCustomDateChange(value, 'end');
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {!isDatePickerActive && (
                            <span role="contentinfo">
                                <IconButton
                                    className="cal_icon"
                                    iconProps={{ iconName: 'Calendar' }}
                                    onClick={handleShowDatePicker}
                                    role="button"
                                    aria-label="date filter"
                                />
                            </span>
                        )}
                        <div className="cal-tabs" role="main" aria-label="Tabs to filter data by week, month or year">
                            <DefaultButton
                                className={`cal-item ${activeRange === rangeItemConstants.WEEK ? 'active' : ''}`}
                                text={translate('Week')}
                                allowDisabledFocus
                                onClick={() => handleWeekMonthYearFilter(rangeItemConstants.WEEK)}
                                data-testid="week-button"
                                role="link"
                                aria-label={`Week ${activeRange === rangeItemConstants.WEEK ? 'selected' : ''}`}
                            />
                            <DefaultButton
                                className={`cal-item ${activeRange === rangeItemConstants.MONTH ? 'active' : ''}`}
                                text={translate('Month')}
                                allowDisabledFocus
                                onClick={() => handleWeekMonthYearFilter(rangeItemConstants.MONTH)}
                                data-testid="month-button"
                                role="link"
                                aria-label={`Month ${activeRange === rangeItemConstants.MONTH ? 'selected' : ''}`}
                            />
                            <DefaultButton
                                className={`cal-item ${activeRange === rangeItemConstants.YEAR ? 'active' : ''}`}
                                text={translate('Year')}
                                allowDisabledFocus
                                onClick={() => handleWeekMonthYearFilter(rangeItemConstants.YEAR)}
                                data-testid="year-button"
                                role="link"
                                aria-label={`Year ${activeRange === rangeItemConstants.YEAR ? 'selected' : ''}`}
                            />
                        </div>
                    </div>
                </div>

                <div className="cards-container">
                    {items &&
                        items.length > 0 &&
                        items.map((item, itemIndex) => (
                            <div
                                tabIndex={0}
                                key={itemIndex}
                                className={`menu-card ${setActiveCard(item.type)}`}
                                onClick={() => handleSetSelectedCard(item.type)}
                                onKeyPress={(event: any) => {
                                    if (event.key === 'Enter' || event.key === 'NumpadEnter') handleSetSelectedCard(item.type);
                                }}>
                                {item.icon}
                                <div className="mc-details">
                                    <Text className="count">{item.value}</Text>
                                    <Text className="label">{item.label}</Text>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="cards-toggle" role="main" aria-label="Summary Cards">
                <Link
                    onClick={toggleCardsPanel}
                    role="button"
                    aria-label={`toggle cards ${isCardsMinimized ? 'collapsed' : 'expanded'}`}>
                    <Icon
                        iconName={`${isCardsMinimized ? 'ChevronDownMed' : 'ChevronUpMed'}`}
                        className="icon"
                        data-testid="link-test"
                    />
                </Link>
            </div>
        </>
    );
};

/**
 * This Hook handles closing the date range filter on clicking outside of the date range filter
 */
const useDatePickerDismiss = (ref: any, handleShowDatePicker: Function, startDate: Date, endDate: Date) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (!(startDate && endDate)) {
                    handleShowDatePicker();
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [endDate, handleShowDatePicker, ref, startDate]);
};

export default SelectableCards;
