import React from 'react';
import { Link } from '@fluentui/react';
import { isUser } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { IUserDetails } from 'app/models/common/post';
import { useHistory } from 'react-router';
import { keyPress } from 'app/components/helpers';
import { RouteConstants} from 'app/utils/constants';
interface IProps {
    name: string;
    UnifiedTrackingId: string;
    inputString: string;
    setSearchText?: Function;
    handleSuggestionClose?: Function;
}

const FormattedSuggestion: React.FC<IProps> = (props) => {
    let parts: any[];
    try {
        parts = props.name ? props.name.split(new RegExp(`(${props.inputString})`, 'gi')) : [];
    } catch (error) {
        parts = [props.name];
    }
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const searchOrigin = useStore((state: any) => state.searchOrigin);

    const history = useHistory();
    const getPageLink = () => {
        if (
            searchOrigin.includes('manage-requests') ||
            (!isUser(userDetails?.userRoles) && searchOrigin === '/') ||
            searchOrigin.includes('report') ||
            searchOrigin.includes('visual-flow')
        )
            return RouteConstants.REQUEST_DETAIL;
        else return RouteConstants.REQUEST;
    };
    const pageLink = isUser(userDetails?.userRoles) ? RouteConstants.REQUEST : getPageLink();
    const clickHandler = () => {
        history.push({
            pathname: `${pageLink}${props.UnifiedTrackingId}`,
            state: { previousPath: window.location.pathname }
        });
        props.setSearchText('');
        props.handleSuggestionClose();
    };
    return (
        <li>
            <Link tabIndex={0} onClick={clickHandler} onKeyPress={(e: any) => keyPress(e, clickHandler)} data-testid="link-test">
                {props.UnifiedTrackingId} -{' '}
                {parts.map((part, idx: number) =>
                    part.toLowerCase() === props.inputString.toLowerCase() ? (
                        <b key={idx}>{part}</b>
                    ) : (
                        <span key={idx}>{part}</span>
                    )
                )}
            </Link>
        </li>
    );
};

export default FormattedSuggestion;
