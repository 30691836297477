import { SpinnerSize } from '@fluentui/react';
import React from 'react';
import './EmailSubscribe.scss';
import { Trans } from 'react-i18next';
import { useKnowledgeArticleSubscription, useNewsSubscription } from 'app/services/mutations';
import { Loader } from 'app/components/loader/Loader';
import { useGetUserPreferences } from 'app/services/queries';

interface IProps {
    type: 'article' | 'news';
}

const EmailSubscribe: React.FC<IProps> = (props: IProps) => {
    const { type } = props;
    const {
        isLoading: isLoadingKnowledgeArticle,
        isSuccess: isSuccessKnowledgeArticle,
        mutateAsync: subscribeKnowledgeArticle
    } = useKnowledgeArticleSubscription();
    const { isLoading: isLoadingNews, isSuccess: isSuccessNews, mutateAsync: subscribeNews } = useNewsSubscription();
    const { data: userPreferences } = useGetUserPreferences();

    let isSuccess = isSuccessKnowledgeArticle || isSuccessNews;
    let isLoading = isLoadingKnowledgeArticle || isLoadingNews;

    const handleSubscribe = async (event: any) => {
        event.preventDefault();
        switch (type) {
            case 'article':
                subscribeKnowledgeArticle();
                break;
            case 'news':
                subscribeNews();
                break;
        }
    };

    return (
        <div className="overlay">
            <div className="container subscribe-container">
                <div className="subscribe-left">
                    <div className="subscribe-comment">
                        <Trans>Stay on top of your game.</Trans>
                        <br /> <Trans>{`Subscribe to ${type === 'article' ? 'articles' : type === 'news' ? 'news' : ''}`}</Trans>
                    </div>
                    <form onSubmit={handleSubscribe}>
                        <div
                            className={`mail ${
                                isSuccess ||
                                (userPreferences?.newsletterSubscription && type === 'news') ||
                                (userPreferences?.knowledgeCenterSubscription && type === 'article')
                                    ? 'success'
                                    : ''
                            }`}>
                            <div className="typehead">
                                <button
                                    type="submit"
                                    className="subscribe-button"
                                    disabled={
                                        isSuccess ||
                                        (userPreferences?.newsletterSubscription && type === 'news') ||
                                        (userPreferences?.knowledgeCenterSubscription && type === 'article')
                                    }>
                                    {isLoading && <Loader size={SpinnerSize.small} loadingText="" />}
                                    {isSuccess ||
                                        (userPreferences?.newsletterSubscription && type === 'news') ||
                                        (userPreferences?.knowledgeCenterSubscription && type === 'article' && (
                                            <i className="ms-Icon ms-Icon--CheckMark" aria-hidden="true"></i>
                                        ))}
                                    <Trans>
                                        {isSuccess ||
                                        (userPreferences?.newsletterSubscription && type === 'news') ||
                                        (userPreferences?.knowledgeCenterSubscription && type === 'article')
                                            ? 'Subscribed'
                                            : 'Subscribe Now'}
                                    </Trans>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default EmailSubscribe;
