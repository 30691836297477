import React, { useEffect } from 'react';

import SearchResultsBanner from 'app/modules/user/search/searchResultsBanner';
import useStore from 'app/store';

const SearchResult: React.FC = () => {
    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);
    const searchValueClear = useStore((state: any) => state.searchValueClear);
    useEffect(() => {
        return () => {
            if (searchValueClear) {
                setUserSearchValue('');
            }
        };
    }, [setUserSearchValue, searchValueClear]);

    return (
        <>
            <SearchResultsBanner />
        </>
    );
};

export default SearchResult;
