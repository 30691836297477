import React from 'react';
import './HomeBanner.scss';

// import Insights from '../insights';
import HomeCards from '../homeCards/HomeCards';
// import useStore from 'app/store';
// import { IUserDetails } from 'app/models/common/post';

const HomeBanner: React.FC = () => {
    /* ===== This is temporarily commented for future use. please do not remove =====*/
    // const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    return (
        <div className="admin-home-banner-container">
            <div className="container admin-data-container">
                <HomeCards />
                {/* ===== This is temporarily commented for future use. please do not remove =====*/}
                {/* {userDetails.userDomain && <Insights />} */}
            </div>
        </div>
    );
};

export default HomeBanner;
