import React, { useEffect, useRef, useState } from 'react';
import { Modal, IconButton, IIconProps, Text, Spinner, PrimaryButton, Image, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import './LinkChildrenModal.scss';
import { Trans, useTranslation } from 'react-i18next';
import { keyPress, requestAggregateSubmit } from 'app/components/helpers';
import { useAssociateRequests, usePostRequestType } from 'app/services/mutations';
import useStore from 'app/store';
import LinkRequestsGridTable from 'app/modules/admin/linkRequestsTable/LinkRequestsGridTable';
import { IRequest } from 'app/models/common/response';
import createNewAssociationIcon from 'app/static/icons/create-new.svg';
import existingAssociationIcon from 'app/static/icons/exisiting-icon.svg';
import { AppQueryClient } from 'app/services/clients/query-client';
import { StringConstants } from 'app/utils/constants';

const titleId = 'add-attachment-request-modal';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IProps {
    modalState: boolean;
    updateModalState: Function;
    selectedRequest: IRequest;
    userType?: string;
    entityType?: number;
    onCompletedHandler?: Function;
    setSelectedRequest: Function;
    isAssociatingParent: boolean;
}

const LinkChildrenModal: React.FC<IProps> = (props) => {
    const { t: translate } = useTranslation();
    const { modalState, updateModalState, selectedRequest, setSelectedRequest, isAssociatingParent } = props;

    // state variables
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(modalState);
    const [isFormView, setIsFormView] = useState(true);
    const [isChooseMode, setIsChooseMode] = useState(true);

    const requestData = useStore((state: any) => state.requestData);
    const gridContRef = useRef(null);

    const [columnsLookUpData] = useState([
        {
            key: 0,
            column: translate('selected'),
            isChecked: !isAssociatingParent
        },
        {
            key: 1,
            column: translate('ID'),
            isChecked: true
        },
        {
            key: 2,
            column: translate('Title'),
            isChecked: true
        },
        {
            key: 3,
            column: translate('Request Type'),
            isChecked: true
        },
        {
            key: 4,
            column: translate('Status'),
            isChecked: true
        },
        {
            key: 5,
            column: translate('Assigned To'),
            isChecked: true
        },
        {
            key: 6,
            column: translate('Created On'),
            isChecked: true
        },
        {
            key: 7,
            column: translate('Aging'),
            isChecked: true
        },
        {
            key: 8,
            column: translate('Total Revenue Risk'),
            isChecked: true
        }
    ]);

    // helper functions
    const handleModalDismiss = () => {
        updateModalState(false);
        setIsFormView(true);
    };

    // hooks
    useEffect(() => {
        const toggleModal = (modalState: boolean) => {
            if (modalState) {
                showModal();
            } else {
                hideModal();
            }
        };
        toggleModal(modalState);
    }, [modalState, showModal, hideModal]);

    let datapass = {
        summary: selectedRequest.summary,
        description: selectedRequest.description,
        requestedResolutionDate: selectedRequest.requestedResolutionDate,
        whyThisDateIsImportant: selectedRequest.whyThisDateIsImportant,

        status: 1,
        attachments: selectedRequest.attachments,
        customerImpacts: [] as any[],
        isParent: true,
        originalRoutingML: false
    };

    const { mutate: associateRequests, isLoading: isAssociatingRequest } = useAssociateRequests();

    const { mutate: postRequestTypeHelp, isLoading: isCreatingRequest } = usePostRequestType();

    const onAssociate = () => {
        var selectedParentRequest = selectedRequest;

        var aggregateCustomerImpact = [] as any;
        selectedRequest.customerImpacts.forEach((impact) => {
            aggregateCustomerImpact.push(impact);
        });

        datapass.customerImpacts = aggregateCustomerImpact;
        datapass.status = selectedParentRequest.statusValue;

        const requestAggregateData = requestAggregateSubmit(
            selectedParentRequest.requestTypeValue,
            selectedParentRequest,
            datapass
        );

        postRequestTypeHelp(
            { subUrl: requestAggregateData.subUrl, data: requestAggregateData.datapass },
            {
                onSuccess: (response) => {
                    finishParentAssociation(response.data.unifiedId);
                }
            }
        );
    };

    const finishParentAssociation = (parentId: string) => {
        const associationObject = {
            requestIds: [selectedRequest.unifiedTrackingId],
            associatedRequestId: parentId
        };

        associateRequests(associationObject, { onSuccess: () => {
            AppQueryClient.invalidateQueries('getRequestById');
            AppQueryClient.invalidateQueries('useRequestList');
            AppQueryClient.invalidateQueries('requestQuery');
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            updateModalState(false);
        }});
    };

    return (
        <>
            {modalState && isModalOpen && (
                <Modal
                    titleAriaId={titleId}
                    isOpen={isModalOpen}
                    onDismiss={handleModalDismiss}
                    isBlocking={false}
                    containerClassName="link-children-request-modal">
                    {isFormView && (
                        <>
                            <div className="modal-head">
                                <Text>
                                    {isAssociatingParent ? (
                                        <Trans>Parent Requests</Trans>
                                    ) : (
                                        <Trans>Associate request as child</Trans>
                                    )}
                                </Text>
                                <IconButton
                                    role="button"
                                    className="modal-close"
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={handleModalDismiss}
                                    onKeyPress={(e: any) => keyPress(e, handleModalDismiss)}
                                    tabIndex={0}
                                />
                            </div>
                            <div className="add-attachment-modal-body">
                                {isCreatingRequest || isAssociatingRequest ? (
                                    <div>
                                        <div className="m-t-50">
                                            <Spinner label="Associating Request. Please wait..." size={SpinnerSize.large} />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {isAssociatingParent ? (
                                            <div className="dp-content">
                                                {isChooseMode ? (
                                                    <>
                                                        <div className="associate-option-container">
                                                            <div className="ao-left">
                                                                <Text>Pair with existing parent</Text>
                                                                <PrimaryButton onClick={() => setIsChooseMode(false)}>
                                                                    Associate Now
                                                                </PrimaryButton>
                                                                <Image
                                                                    src={existingAssociationIcon}
                                                                    alt="choose from existing request"
                                                                />
                                                            </div>
                                                            <div className="ao-right">
                                                                <Text>Create new parent request</Text>
                                                                <PrimaryButton onClick={onAssociate}>Create now</PrimaryButton>
                                                                <Image
                                                                    src={createNewAssociationIcon}
                                                                    alt="create new parent request"
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <LinkRequestsGridTable
                                                        requestData={requestData}
                                                        columnsLookUpData={columnsLookUpData}
                                                        gridContRef={gridContRef}
                                                        updateModalState={updateModalState}
                                                        selectedRequest={selectedRequest}
                                                        setSelectedRequest={setSelectedRequest}
                                                        isAssociatingParent={isAssociatingParent}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className="dp-content">
                                                <LinkRequestsGridTable
                                                    requestData={requestData.filter((data: any) => data.parentRequestId === null || data.parentRequestId === "")}
                                                    columnsLookUpData={columnsLookUpData}
                                                    gridContRef={gridContRef}
                                                    updateModalState={updateModalState}
                                                    selectedRequest={selectedRequest}
                                                    setSelectedRequest={setSelectedRequest}
                                                    isAssociatingParent={isAssociatingParent}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export default LinkChildrenModal;
