import React from 'react';
import { Link } from '@fluentui/react';
import { IArticleData } from 'app/models/common/post';
import { getParagraphFromContent } from 'app/components/helpers';
import { useGenerateFileSasToken } from 'app/services/queries';
import { getBlobFileNameFromString } from 'app/utils/blobStorageHelpers';
import { BlobStorageContainer, Entity } from 'app/utils/constants';

interface IProps {
    index: number;
    article: IArticleData;
    openThisTraining: Function;
    activeTrainingId: string;
}

const VideoSliderCard: React.FC<IProps> = (props) => {
    const { index, article, openThisTraining, activeTrainingId } = props;
    const { data: thumbnail } = useGenerateFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, getBlobFileNameFromString(article?.thumbnail));

    return (
        <>
            <div key={index} className={`item ${activeTrainingId === article?.id ? 'active' : ''}`}>
                <Link onClick={() => openThisTraining(article?.id)}>
                    <div className="video-thumb">
                        <div
                            className="video-thumb"
                            style={{
                                backgroundImage: `url(${ thumbnail })`
                            }}></div>
                        <span className="play-button">
                            <i className="ms-Icon ms-Icon--BoxPlaySolid"></i>
                        </span>
                    </div>
                    <div className="details">
                        <h5 dangerouslySetInnerHTML={{ __html: article?.title }}></h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getParagraphFromContent((article as IArticleData).content)
                            }}></div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default VideoSliderCard;
