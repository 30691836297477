import React, { useEffect, useState } from 'react';
import { Text } from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';
import './AddCustomer.scss';
import { IconButton, IIconProps, PrimaryButton, Spinner, SpinnerSize, Link as HLink } from '@fluentui/react';
import DataTable from 'react-data-table-component';
import { SearchMSX } from 'app/modules/user/newReqExperience/searchMSX';
import { IRequest, ISelectedData } from 'app/models/common/response';
import { usePostAddCustomer, usePostAddCustomerReviewRequest, usePostAddCustomerTechnicalRequest } from 'app/services/mutations';
import { addCustomerSubmit, isNumberGreaterThanZero, keyPress, searchedColumns } from 'app/components/helpers';
import { ChevronDownIcon } from '@fluentui/react-icons-mdl2';
import { useGetCustomersList } from 'app/services/mutations';
import _ from 'lodash';
import AddMultiDetails from './AddMultiDetails/AddMultiDetails';
import AddNewDetails from './AddNewDetails/AddNewDetails';
import SuccessPopup from './SuccessPopUp/SuccessPopup';
import { Trans, useTranslation } from 'react-i18next';
import AddProduct from 'app/modules/admin/detailsPanel/addProduct/AddProduct';
import { IAddCustomer, IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
import { useGetCountries } from 'app/services/queries';
import { AppQueryClient } from 'app/services/clients/query-client';
import { StringConstants } from 'app/utils/constants';
import { RequestType } from 'app/enums/RequestType.enum';
interface ISearchProps {
    selectedRequest: any;
    globalSelectedRequest: IRequest;
    onDismiss?: Function;
    onSuccessHandler?: Function;
    setSelectedRequest: Function;
    requestProcessing: boolean;
    setRequestProcessing: (requestProcessing: boolean) => void;
    displayScreen: string;
    setDisplayScreen: (displayScreen: string) => void;
}

const AddCustomer: React.FC<ISearchProps> = (props) => {
    const { t: translate } = useTranslation();
    const {
        selectedRequest,
        onDismiss,
        onSuccessHandler,
        globalSelectedRequest,
        setSelectedRequest,
        displayScreen = 'searchCust',
        setDisplayScreen,
        requestProcessing,
        setRequestProcessing
    } = props;

    const cancelIcon: IIconProps = { iconName: StringConstants.CANCEL };
    const { data: countryList } = useGetCountries();
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails as IUserDetails);
    const [toggleTable, setToggleTable] = React.useState(false);
    const [customerHelperText] = useState(false);
    const [newCountryHelperText] = useState(false);
    const [searchField, setSearchField] = useState('');
    const [searchedCustomer, setSearchedCustomer] = useState([]);
    const [oppId, setOppId] = useState('');
    const [manualData, setManualData] = useState(false);
    const [EstimatedValue, setEstimatedValue] = useState('');
    const [matchFound, setMatchFound] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [searchedMSX] = useState('');
    const [successData, setSuccessData] = useState({ customer_name: '', country: '', impacted_revenue: '', skpIdPost: '' });
    const [, setSuggestionSelected] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [, setRevenueRisk] = useState('');
    const [, setImpDevices] = useState('');
    const [, setRevenueHText] = useState('');
    const [, setImpDevicesHText] = useState('');
    const [autoSuggestHText, setAutoSuggestHText] = useState();
    const [customerSelected, setCustomerSelected] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState('');
    const [lastSelectedOppurtunityId, setLastSelectedOppurtunityId] = useState('');
    const [isSearchText, setIsSearchText] = useState(false);
    const [, setErrorImpactedDevices] = useState(false);
    const [, setErrorRevenue] = useState(false);
    const [errorCustomerName, setErrorCustomerName] = useState(false);
    const [errorCountry, setErrorCountry] = useState(false);
    const [errorEstimatedValue, setErrorEstimatedValue] = useState(false);
    const [errorTotalDeviceCount, setErrorTotalDeviceCount] = useState(false);
    const [noDetailsFound, setNoDetailsFound] = useState(false);
    const [originalSelected, setOriginalSelected] = useState([]);
    const [multiFormError] = useState(false);
    const [cssNumber, setCssNumber] = useState();
    const [cssNumberText, setCssNumberText] = useState(false);
    const [isAddNewDetails, setIsAddNewDetails] = useState(false);
    const [addedDevices, setAddedDevices] = useState([]);

    const rowDisabledCriteria = (row: any) => {
        return selectedAccountId && row.AccountNumber !== selectedAccountId;
    };

    const handleSelect = (state: { selectedCount: number; selectedRows: Array<any> }) => {
        if (state.selectedRows.length !== 0) {
            setCustomerSelected(true);
        } else {
            setCustomerSelected(false);
        }
        const { selectedCount, selectedRows } = state;
        if (selectedCount === 0) {
            setSelectedAccountId('');
        }
        if (selectedCount === 1) {
            setSelectedAccountId(selectedRows[0]?.AccountNumber);
        }
        if (selectedCount > 0) {
            const arr: any = [];

            setOriginalSelected(selectedRows);

            selectedRows.forEach((item: any) => {
                arr.push({ ...item, revenueErrorText: false, devicesErrorText: false });
            });
            setSelectedData(arr);
        } else {
            setSelectedData([]);
        }
    };

    const [toggledClearRows, setToggledClearRows] = useState(false);
    const handleClearRows = () => {
        setToggledClearRows(!toggledClearRows);
    };
    const {
        data: getCustomersListData,
        mutate: getCustomersList,
        isSuccess: isSuccessGetCustomersListData,
        isLoading: isLoadingCustomerList
    } = useGetCustomersList();

    const handleCustomerSearch = () => {
        setIsSearchText(false);
        setSelectedAccountId('');
        handleClearRows();
        if (searchField.length <= 0) {
            setIsSearchText(true);
            return false;
        }

        if (searchField.trim().length > 0) {
            if (searchField.trim().includes(lastSelectedOppurtunityId) && lastSelectedOppurtunityId.trim() !== '') {
                getCustomersList(`${lastSelectedOppurtunityId}&pageSize=20`);
            } else {
                getCustomersList(`${searchField}&pageSize=20`);
            }

            setSelectedData([]);
        }
    };

    const {
        mutate: postAddCustomer,
        isSuccess: successPostAddCustomer,
        isError: errorPostAddCustomer,
        isLoading: isPostAddCustomer,
        data: postAddCustomerResponse
    } = usePostAddCustomer();

    const {
        mutate: postAddCustomerRR,
        isSuccess: successPostAddCustomerRR,
        isError: errorPostAddCustomerRR,
        isLoading: isPostAddCustomerRR,
        data: postAddCustomerResponseRR
    } = usePostAddCustomerReviewRequest();

    const {
        mutate: postAddCustomerTR,
        isSuccess: successPostAddCustomerTR,
        isError: errorPostAddCustomerTR,
        isLoading: isPostAddCustomerTR,
        data: postAddCustomerResponseTR
    } = usePostAddCustomerTechnicalRequest();

    const handleAddCustomer = () => {
        setErrorImpactedDevices(false);
        setErrorRevenue(false);
        setErrorCustomerName(false);
        setErrorCountry(false);
        setErrorEstimatedValue(false);
        setErrorTotalDeviceCount(false);
        setCssNumberText(false);

        if (!selectedData[0] || !selectedData[0]?.AccountName) {
            setErrorCustomerName(true);
        }
        if (
            selectedRequest.requestType === RequestType.CssReviewRequest ||
            selectedRequest.requestType === RequestType.Cxp_TechnicalRequest
        ) {
            if (!cssNumber || cssNumber === '') {
                setCssNumberText(true);
            }
        }
        if (!selectedData[0] || !selectedData[0].Country) {
            setErrorCountry(true);
        }

        if (!selectedData[0] || !selectedData[0].EstimatedValue) {
            setErrorEstimatedValue(true);
        }

        if (!selectedData[0] || !selectedData[0].TotalDeviceCount) {
            setErrorTotalDeviceCount(true);
        }

        let errorFlag = false;
        selectedData.every((ele) => {
            if (
                !ele?.AccountName?.trim() ||
                ele?.AccountName?.trim() === '' ||
                !ele?.Country?.trim() ||
                ele?.Country?.trim() === '' ||
                !isNumberGreaterThanZero(ele?.EstimatedValue?.toString().replace('$', '').replaceAll(',', '')) ||
                !ele?.TotalDeviceCount?.toString()?.trim() ||
                ele?.TotalDeviceCount?.toString()?.trim() === '0' ||
                ele?.TotalDeviceCount?.toString()?.trim() === '' ||
                ((selectedRequest.requestType === RequestType.CssReviewRequest ||
                    selectedRequest.requestType === RequestType.Cxp_TechnicalRequest) &&
                    (!cssNumber || cssNumber === ''))
            ) {
                errorFlag = true;
                return false;
            } else {
                return true;
            }
        });
        if (selectedData.length === 0) {
            setErrorEstimatedValue(true);
            setErrorImpactedDevices(true);
            setErrorCustomerName(true);
            setErrorCountry(true);
            errorFlag = true;
        }

        if (errorFlag) {
            let tempArray: ISelectedData[] = [];

            selectedData.forEach((ele) => {
                let updatedValue = { ...ele };
                if (!ele?.AccountName?.trim() || ele?.AccountName?.trim() === '') {
                    setErrorCustomerName(true);
                }
                if (!ele?.Country?.trim() || ele?.Country?.trim() === '') {
                    setErrorCountry(true);
                }
                if (ele?.requestType === RequestType.CssReviewRequest || ele?.requestType === RequestType.Cxp_TechnicalRequest) {
                    if (!cssNumber || cssNumber === '') {
                        setErrorCountry(true);
                    }
                }
                if (!isNumberGreaterThanZero(ele?.EstimatedValue?.toString().replace('$', '').replaceAll(',', ''))) {
                    if (displayScreen === StringConstants.CAMELCASE_ADD_NEW_DETAILS) {
                        setErrorRevenue(true);
                    } else {
                        updatedValue.revenueErrorText = true;
                    }
                }
                if (
                    !ele?.TotalDeviceCount?.toString()?.trim() ||
                    ele?.TotalDeviceCount?.toString()?.trim() === '0' ||
                    ele?.TotalDeviceCount?.toString()?.trim() === ''
                ) {
                    if (displayScreen === StringConstants.CAMELCASE_ADD_NEW_DETAILS) {
                        setErrorImpactedDevices(true);
                    } else {
                        updatedValue.devicesErrorText = true;
                    }
                }
                tempArray.push(updatedValue);
            });
            setSelectedData(tempArray);
            return;
        } else {
            setDisplayScreen('updateProducts');
            return;
        }
    };

    const [addedCustomerImpacts, setAddedCustomerImpacts] = useState<IAddCustomer[]>([]);

    const submitAddCustomer = () => {
        let subUrl = addCustomerSubmit({
            team: selectedRequest?.requestTeam,
            requestType: selectedRequest?.requestType,
            skpId: selectedRequest?.unifiedTrackingId
        });
        if (
            selectedRequest.requestType === RequestType.CssReviewRequest ||
            selectedRequest.requestType === RequestType.Cxp_TechnicalRequest
        ) {
            let dataPass = {
                requestId: selectedRequest.unifiedTrackingId,
                deviceModels: globalSelectedRequest.devices,
                id: selectedRequest.unifiedTrackingId,
                cssCaseNumber: cssNumber,
                originalRoutingML: false,
                customerImpacts: selectedData.map((item, index) => ({
                    opportunityId: item.OpportunityNumber,
                    msxOpportunityId: item.OpportunityId,
                    tpId: item.TPID,
                    accountId: item.AccountNumber,
                    customerName: item.AccountName,
                    customerImpactDescription: item.CustomerImpactDescription,
                    status: item.Status,
                    customerCountry: {
                        id: item.id,
                        name: item.Country
                    },
                    deviceCount:
                        parseInt(item.TotalDeviceCount?.toString().replaceAll(',', '')) ===
                        parseInt(
                            originalSelected.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]?.TotalDeviceCount
                        )
                            ? 0
                            : parseInt(item.TotalDeviceCount?.toString().replaceAll(',', '')),
                    revenue:
                        parseFloat(item.EstimatedValue?.toString().replace('$', '').replaceAll(',', '')) ===
                        parseFloat(
                            originalSelected.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]?.EstimatedValue
                        )
                            ? 0
                            : parseFloat(item.EstimatedValue?.toString().replace('$', '').replaceAll(',', '')),
                    msxDeviceCount: isAddNewDetails
                        ? 0
                        : parseInt(
                              originalSelected.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]
                                  ?.TotalDeviceCount
                          ),
                    msxRevenue: isAddNewDetails
                        ? 0
                        : parseFloat(
                              originalSelected.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]?.EstimatedValue
                          ),
                    addedOn: new Date(),
                    addedBy: {
                        id: userDetails.id,
                        displayName: userDetails.displayName,
                        email: userDetails.email,
                        userDomain: userDetails.userDomain,
                        userRoles: userDetails.userRoles
                    },
                    endCustomerSegment: '',
                    competitor: '',
                    salesPlay: '',
                    refreshYourDevice: ''
                }))
            };
            if (!manualData) {
                let impacts = dataPass.customerImpacts;
                let res: any[] = [];
                impacts.forEach((item, index) => {
                    let temp = {
                        ...item,
                        state: selectedData[index]?.Region,
                        city: selectedData[index]?.City,
                        zipCode: selectedData[index]?.PostalCode
                    };
                    res.push(temp);
                });
                dataPass = { ...dataPass, customerImpacts: res };
            }
            setAddedCustomerImpacts(dataPass.customerImpacts);
            setSelectedRequest({ ...globalSelectedRequest, updatedCustCssNum: cssNumber });
            if (selectedRequest.requestType === RequestType.CssReviewRequest) {
                postAddCustomerRR({ data: dataPass });
            } else {
                postAddCustomerTR({ data: dataPass });
            }
        } else {
            let dataPass = {
                requestId: selectedRequest.unifiedTrackingId,
                deviceModels: globalSelectedRequest.devices,
                originalRoutingML: false,
                customerImpacts: selectedData.map((item, index) => ({
                    opportunityId: item.OpportunityNumber,
                    tpId: item.TPID,
                    msxOpportunityId: item.OpportunityId,
                    accountId: item.AccountNumber,
                    customerName: item.AccountName,
                    customerImpactDescription: item.CustomerImpact,
                    status: item.Status,
                    customerCountry: {
                        name: item.Country
                    },
                    deviceCount:
                        parseInt(item.TotalDeviceCount?.toString().replaceAll(',', '')) ===
                        parseInt(
                            originalSelected?.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]?.TotalDeviceCount
                        )
                            ? 0
                            : parseInt(item.TotalDeviceCount?.toString().replaceAll(',', '')),
                    revenue:
                        parseFloat(item.EstimatedValue?.toString().replace('$', '').replaceAll(',', '')) ===
                        parseFloat(
                            originalSelected?.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]?.EstimatedValue
                        )
                            ? 0
                            : parseFloat(item.EstimatedValue?.toString().replace('$', '').replaceAll(',', '')),
                    msxDeviceCount: isAddNewDetails
                        ? 0
                        : parseInt(
                              originalSelected.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]
                                  ?.TotalDeviceCount
                          ),
                    msxRevenue: isAddNewDetails
                        ? 0
                        : parseFloat(
                              originalSelected.filter((ele) => ele.OpportunityNumber === item.OpportunityNumber)[0]?.EstimatedValue
                          )
                }))
            };
            if (!manualData) {
                let impacts = dataPass.customerImpacts;
                let res: any[] = [];
                impacts.forEach((item, index) => {
                    if (item.msxDeviceCount === item.deviceCount) {
                        item.deviceCount = null;
                    }
                    if (item.msxRevenue === item.revenue) {
                        item.revenue = null;
                    }
                    let temp = {
                        ...item,
                        state: selectedData[index]?.Region,
                        city: selectedData[index]?.City,
                        zipCode: selectedData[index]?.PostalCode
                    };
                    res.push(temp);
                });
                dataPass = { ...dataPass, customerImpacts: res };
            }
            setAddedCustomerImpacts(dataPass.customerImpacts);
            postAddCustomer({ subUrl: subUrl, data: dataPass });
        }
    };

    const handleMultiChange = (e: any, type: string, id: string) => {
        let updateValue = selectedData.slice(0);

        updateValue = updateValue.map((item) => {
            if (item.OpportunityNumber === id) {
                switch (type) {
                    case 'impacted_revenue':
                        item.EstimatedValue = e.target.value;
                        item.revenueErrorText = false;
                        break;
                    case 'impacted_device':
                        item.TotalDeviceCount = e.target.value;
                        item.devicesErrorText = false;
                        break;
                    case 'accountNumber':
                        item.AccountNumber = e.target.value;
                        break;
                    case 'accountName':
                        item.AccountName = e.target.value;
                        break;
                    case 'country':
                        item.Country = e.target.value;
                        break;
                    case 'customer_impact':
                        item.CustomerImpact = e.target.value;
                        break;
                    case 'css-case-num':
                        setCssNumber(e.target.value);
                        break;
                    default:
                        break;
                }
            }
            return item;
        });
        setSelectedData(updateValue);
    };

    const handleChange = (e: any, type: string) => {
        let updateValue = selectedData.slice(0);
        if (updateValue.length === 0) {
            updateValue = [{}];
        }

        if (updateValue.length !== 0) {
            switch (type) {
                case 'impacted_revenue':
                    updateValue[0]['EstimatedValue'] = e.target.value;
                    setErrorEstimatedValue(false);
                    break;
                case 'impacted_device':
                    updateValue[0]['TotalDeviceCount'] = e.target.value;
                    setErrorTotalDeviceCount(false);
                    break;
                case 'accountNumber':
                    updateValue[0]['AccountNumber'] = e.target.value;
                    break;
                case 'accountName':
                    updateValue[0]['AccountName'] = e.target.value;
                    setErrorCustomerName(false);
                    break;
                case 'country':
                    updateValue[0]['Country'] = e.target.value;
                    setErrorCountry(false);
                    break;
                case 'css-case-num':
                    setCssNumber(e.target.value);
                    setCssNumberText(false);
                    break;
                default:
                    break;
            }
        }
        setSelectedData(updateValue);
    };

    const clickHandler = () => {
        handleCustomerSearch();
        setIsFocus(false);
    };

    useEffect(() => {
        setDisplayScreen('searchCust');
        setIsFocus(true);
    }, []);

    useEffect(() => {
        if (selectedData) {
            let totalEstimatedValue = 0;

            selectedData.map(
                (item) =>
                    (totalEstimatedValue =
                        totalEstimatedValue + parseFloat(item.EstimatedValue?.toString().replace('$', '').replaceAll(',', '')))
            );

            setEstimatedValue(totalEstimatedValue.toString());
        }
    }, [selectedData]);

    useEffect(() => {
        if (isSuccessGetCustomersListData) {
            let searchData: any = [];
            searchData = getCustomersListData?.data
                ?.filter(
                    (item: any) =>
                        !_.find(selectedRequest.customerImpacts, (obj) => {
                            return (
                                item.Document.AccountNumber === obj.accountId &&
                                item.Document.OpportunityNumber === obj.opportunityId
                            );
                        })
                )
                .map((item: any) => {
                    const {
                        Document: {
                            AccountName,
                            TPID,
                            Country,
                            OpportunityNumber,
                            OpportunityId,
                            Topic,
                            EstimatedValue,
                            TotalDeviceCount,
                            AccountNumber,
                            Region,
                            City,
                            PostalCode,
                            Status
                        }
                    } = item;
                    return {
                        AccountName,
                        TPID,
                        Country,
                        OpportunityNumber,
                        OpportunityId,
                        Topic,
                        EstimatedValue,
                        TotalDeviceCount,
                        AccountNumber,
                        Region,
                        City,
                        PostalCode,
                        Status
                    };
                });
            if (searchData?.length === 0) {
                setNoDetailsFound(true);
            } else {
                setNoDetailsFound(false);
            }
            setSearchedCustomer(searchData);
        }
    }, [isSuccessGetCustomersListData]);

    useEffect(() => {
        if (!isPostAddCustomer) {
            if (successPostAddCustomer) {
                setSuccessData({
                    customer_name: selectedData[0]?.AccountName,
                    country: selectedData[0]?.Country,
                    impacted_revenue: EstimatedValue,
                    skpIdPost: postAddCustomerResponse?.data?.id
                });
                if (onSuccessHandler) {
                    onSuccessHandler(postAddCustomerResponse, addedCustomerImpacts);
                } else {
                    setRequestProcessing(false);
                    setDisplayScreen('successPopUp');
                    AppQueryClient.invalidateQueries('getRequestById');
                }

                globalSelectedRequest.updatedUnifiedTrackingId = postAddCustomerResponse?.data?.id;
                setSelectedRequest(globalSelectedRequest);
            } else if (errorPostAddCustomer) {
                setRequestProcessing(false);
                setDisplayScreen('errorPopUp');
            }
        }
    }, [successPostAddCustomer, errorPostAddCustomer, isPostAddCustomer]);

    useEffect(() => {
        if (!isPostAddCustomerRR) {
            if (successPostAddCustomerRR) {
                setSuccessData({
                    customer_name: selectedData[0]?.AccountName,
                    country: selectedData[0]?.Country,
                    impacted_revenue: EstimatedValue,
                    skpIdPost: postAddCustomerResponseRR?.data?.id
                });
                if (onSuccessHandler) {
                    onSuccessHandler(postAddCustomerResponseRR, addedCustomerImpacts);
                } else {
                    setRequestProcessing(false);
                    setDisplayScreen('successPopUp');
                }
                globalSelectedRequest.updatedUnifiedTrackingId = postAddCustomerResponseRR?.data?.id;
                setSelectedRequest(globalSelectedRequest);
            } else if (errorPostAddCustomerRR) {
                setRequestProcessing(false);
                setDisplayScreen('errorPopUp');
            }
        }
    }, [successPostAddCustomerRR, errorPostAddCustomerRR, isPostAddCustomerRR]);

    useEffect(() => {
        if (!isPostAddCustomerTR) {
            if (successPostAddCustomerTR) {
                setSuccessData({
                    customer_name: selectedData[0]?.AccountName,
                    country: selectedData[0]?.Country,
                    impacted_revenue: EstimatedValue,
                    skpIdPost: postAddCustomerResponseTR?.data?.id
                });
                if (onSuccessHandler) {
                    onSuccessHandler(postAddCustomerResponseTR, addedCustomerImpacts);
                } else {
                    setRequestProcessing(false);
                    setDisplayScreen('successPopUp');
                }
                globalSelectedRequest.updatedUnifiedTrackingId = postAddCustomerResponseTR?.data?.id;
                setSelectedRequest(globalSelectedRequest);
            } else if (errorPostAddCustomerTR) {
                setRequestProcessing(false);
                setDisplayScreen('errorPopUp');
            }
        }
    }, [successPostAddCustomerTR, errorPostAddCustomerTR, isPostAddCustomerTR]);

    const sortData = (data: any[], selectedAccount: string) => {
        const selectedData: any[] = [];
        const otherData: any[] = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].AccountNumber === selectedAccount) {
                selectedData.push(data[i]);
            } else {
                otherData.push(data[i]);
            }
        }
        return selectedData.concat(otherData);
    };

    return (
        <>
            <div>
                {displayScreen === 'searchCust' && (
                    <div className="addImpact-container">
                        <div className={`close-model `}>
                            <IconButton
                                role="button"
                                aria-label="close popup"
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                tabIndex={0}
                                onKeyPress={(e: any) => keyPress(e, onDismiss)}
                                onClick={() => onDismiss()}
                                data-testid="icon-button1"
                            />
                        </div>
                        <div className="modal-heading">
                            <span>
                                <Trans>Add a customer</Trans>
                            </span>
                        </div>
                        <div className="modal-body">
                            <div className="body-main-container ">
                                <Text className="modal-body-title">
                                    <Trans>Search Customer</Trans>
                                </Text>

                                <div className="d-flex">
                                    <div>
                                        <div className="form-control auto-suggest-container">
                                            <label>
                                                <Trans>Search by MSX Opportunity ID / Account ID / Customer Name</Trans>
                                            </label>
                                            <div>
                                                <SearchMSX
                                                    oppId={oppId}
                                                    setOppId={setOppId}
                                                    searchValue={searchField}
                                                    setSearchValue={setSearchField}
                                                    setSuggestionSelected={setSuggestionSelected}
                                                    setFilteredData={setSelectedData}
                                                    setRevenueRisk={setRevenueRisk}
                                                    setImpDevices={setImpDevices}
                                                    setRevenueHText={setRevenueHText}
                                                    setImpDevicesHText={setImpDevicesHText}
                                                    autoSuggestHText={autoSuggestHText}
                                                    setAutoSuggestHText={setAutoSuggestHText}
                                                    origin="ADD_CUSTOMER"
                                                    isFocus={isFocus}
                                                    setCustomerSelected={setCustomerSelected}
                                                    setLastSelectedOppurtunityId={setLastSelectedOppurtunityId}
                                                    setOriginalSelected={setOriginalSelected}
                                                />
                                            </div>

                                            {isSearchText && (
                                                <label className="helper-txt search-error">
                                                    <Trans>Please enter a valid ID/customer name.</Trans>
                                                </label>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <PrimaryButton
                                            onClick={clickHandler}
                                            tabIndex={0}
                                            onKeyPress={(e: any) => keyPress(e, clickHandler)}
                                            text={translate('Search')}
                                            disabled={selectedData.length !== 0}
                                            data-testid="primary-button"
                                        />
                                    </div>
                                    <div className={`proceed-btn`}>
                                        <PrimaryButton
                                            tabIndex={0}
                                            onClick={() => {
                                                setIsAddNewDetails(false);
                                                setDisplayScreen('addMultiDetails');
                                                setManualData(false);
                                            }}
                                            onKeyPress={(e: any) =>
                                                keyPress(e, () => {
                                                    setIsAddNewDetails(false);
                                                    setDisplayScreen('addMultiDetails');
                                                })
                                            }
                                            text={`Proceed (${selectedData.length})`}
                                            data-testid="addMultiDetails-test"
                                            disabled={selectedData.length === 0 || !customerSelected}
                                            className={selectedData.length === 0 ? 'disabled-button' : ''}
                                        />
                                    </div>
                                </div>
                            </div>

                            {searchedCustomer.length > 1 && (
                                <div className="add-heading" aria-label="Search Results" tabIndex={0}>
                                    <div className="search-count-container">Search Results ({searchedCustomer.length})</div>
                                </div>
                            )}

                            <div className={`body-result-container ${displayScreen === 'searchCust' ? 'add-height' : ''}`}>
                                {toggleTable && !matchFound && searchedMSX === '' && (
                                    <span className="no-results-found">
                                        <Trans>Sorry,we couldn't find any results</Trans>
                                    </span>
                                )}

                                {isLoadingCustomerList && (
                                    <div className="m-t-50">
                                        <Spinner label="Search results are loading. Please wait..." size={SpinnerSize.large} />
                                    </div>
                                )}
                                {noDetailsFound && (
                                    <div tabIndex={0} aria-label="No details found">
                                        No details found
                                    </div>
                                )}
                                {searchedCustomer && searchedCustomer.length > 0 && (
                                    <>
                                        {searchedCustomer.length > 0 && (
                                            <>
                                                <div className="addCustomer-table-container ">
                                                    <DataTable
                                                        columns={searchedColumns(selectedAccountId)}
                                                        data={sortData(searchedCustomer, selectedAccountId)}
                                                        sortIcon={<ChevronDownIcon />}
                                                        selectableRows
                                                        onSelectedRowsChange={handleSelect}
                                                        selectableRowDisabled={rowDisabledCriteria}
                                                        clearSelectedRows={toggledClearRows}
                                                        selectableRowsNoSelectAll
                                                    />
                                                </div>

                                                <div className="btn-link-container">
                                                    <HLink
                                                        tabIndex={0}
                                                        onClick={() => {
                                                            setIsAddNewDetails(true);
                                                            setDisplayScreen(StringConstants.CAMELCASE_ADD_NEW_DETAILS);
                                                            setManualData(true);
                                                        }}
                                                        onKeyPress={(e: any) =>
                                                            keyPress(e, () =>
                                                                setDisplayScreen(StringConstants.CAMELCASE_ADD_NEW_DETAILS)
                                                            )
                                                        }
                                                        className="btn-link link-right">
                                                        <Trans>Can't Find Customer</Trans>
                                                    </HLink>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {displayScreen === 'addDetails' && !isPostAddCustomer && (
                    <AddNewDetails
                        selectedRequest={selectedRequest}
                        onDismiss={onDismiss}
                        setDisplayScreen={setDisplayScreen}
                        selectedData={selectedData}
                        handleChange={handleChange}
                        handleAddCustomer={handleAddCustomer}
                        customerHelperText={customerHelperText}
                        newCountryHelperText={newCountryHelperText}
                        errorCustomerName={errorCustomerName}
                        errorCountry={errorCountry}
                        errorEstimatedValue={errorEstimatedValue}
                        errorTotalDeviceCount={errorTotalDeviceCount}
                        type="AddDetails"
                        cssNumber={cssNumber}
                        setCssNumber={setCssNumber}
                        cssErrorText={cssNumberText}
                        setErrorText={setCssNumberText}
                        countryList={countryList}
                    />
                )}

                {displayScreen === 'addMultiDetails' && !isPostAddCustomer && (
                    <AddMultiDetails
                        selectedRequest={selectedRequest}
                        onDismiss={onDismiss}
                        setDisplayScreen={setDisplayScreen}
                        selectedData={selectedData}
                        handleMultiChange={handleMultiChange}
                        multiFormError={multiFormError}
                        handleAddCustomer={handleAddCustomer}
                        cssNumber={cssNumber}
                        setCssNumber={setCssNumber}
                        cssErrorText={cssNumberText}
                        setErrorText={setCssNumberText}
                    />
                )}

                {displayScreen === StringConstants.CAMELCASE_ADD_NEW_DETAILS && (
                    <AddNewDetails
                        selectedRequest={selectedRequest}
                        onDismiss={onDismiss}
                        setDisplayScreen={setDisplayScreen}
                        selectedData={selectedData}
                        handleChange={handleChange}
                        handleAddCustomer={handleAddCustomer}
                        customerHelperText={customerHelperText}
                        newCountryHelperText={newCountryHelperText}
                        errorCustomerName={errorCustomerName}
                        errorCountry={errorCountry}
                        errorEstimatedValue={errorEstimatedValue}
                        errorTotalDeviceCount={errorTotalDeviceCount}
                        type="AddNewDetails"
                        cssNumber={cssNumber}
                        setCssNumber={setCssNumber}
                        cssErrorText={cssNumberText}
                        setErrorText={setCssNumberText}
                        countryList={countryList}
                    />
                )}

                {displayScreen === 'successPopUp' && (
                    <SuccessPopup
                        onDismiss={onDismiss}
                        setDisplayScreen={setDisplayScreen}
                        successData={successData}
                        setSearchField={setSearchField}
                        setMatchFound={setMatchFound}
                        setToggleTable={setToggleTable}
                        setSearchedCustomer={setSearchedCustomer}
                        setSelectedData={setSelectedData}
                    />
                )}

                {displayScreen === 'errorPopUp' && (
                    <div className="selected-customer-container">
                        <div className={`close-model close-primary `}>
                            <IconButton
                                role="button"
                                aria-label="close popup"
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                tabIndex={0}
                                onClick={(e: any) => {
                                    onDismiss();
                                }}
                                onKeyPress={(e: any) => keyPress(e, () => onDismiss())}
                            />
                        </div>
                        <div className="modal-body success-body">
                            <Text className="success-title">
                                <Trans>Something went wrong.</Trans>
                            </Text>
                            <Text className="success-title error-link">
                                <Link to="/submit-feedback">
                                    <Trans>Please tell us about it</Trans>
                                </Link>
                            </Text>
                        </div>
                    </div>
                )}
                {displayScreen === 'updateProducts' && (
                    <AddProduct
                        setCssNumberText={setCssNumberText}
                        addOrUpdate={StringConstants.ADD}
                        selectedRequest={globalSelectedRequest}
                        onDismiss={onDismiss}
                        setDisplayScreen={setDisplayScreen}
                        addedDevices={addedDevices}
                        setAddedDevices={setAddedDevices}
                        handleAddProduct={() => {
                            setRequestProcessing(true);
                            submitAddCustomer();
                            setDisplayScreen('');
                        }}
                    />
                )}
                {requestProcessing && (
                    <Spinner
                        className="p-t-50 p-b-50"
                        size={SpinnerSize.large}
                        label="Please wait, while we are processing your request"
                    />
                )}
            </div>
        </>
    );
};

export default AddCustomer;
