import React, { useEffect, useRef, useState } from 'react';
import { Text, Link, Icon, Image, Shimmer, Modal } from '@fluentui/react';
import './index.scss';
import { IAddCustomerData, IRequest } from 'app/models/common/response';
import { formatIntoRequestData, GetText, keyPress, requestAggregateSubmit } from 'app/components/helpers';

import detailsIcon from 'app/static/icons/detail-icon.svg';
import { IAddCustomer, ILookUpData, IUserDetails } from 'app/models/common/post';
import { useHeightListener } from 'app/components/heightListenerHook/HeightListener';
import useStore from 'app/store';
import { useBoolean, useId } from '@fluentui/react-hooks';
import AddImpact from 'app/components/addCustomer/AddCustomer';
import { Trans } from 'react-i18next';
import { useAssociateRequests, usePostRequestType } from 'app/services/mutations';
import { AppQueryClient } from 'app/services/clients/query-client';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    id: string;
    data: any;
    requestData: IRequest[];
    selectedRequest: IRequest;
    requestTypes: ILookUpData[];
    teams: ILookUpData[];

    setSelectedRequest: Function;

    setRequestData: Function;
    setIsAddCustomerModalOpen: Function;
    setRequestFilter: Function;
    cardTypeFilter: number;
    isLoadingRequestList: boolean;
    isListBuilderLoading: boolean;
    listBuilderResults: any;
    previousLocation: string;
    listBuilderFilters: any;
}

const ListPanel: React.FC<IProps> = (props) => {
    // props variables
    const { data, selectedRequest, requestTypes, teams, requestData, isLoadingRequestList, listBuilderResults, previousLocation, isListBuilderLoading, listBuilderFilters } = props;
    // props functions
    const { setSelectedRequest, setRequestData } = props;

    // state variables
    const [isMinimizedView, setIsMinimizedView] = useState(true);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    // helper functions
    const handleToggleView = (state: boolean) => {
        setIsMinimizedView(state);
    };

    // hooks
    /* useEffect(() => {
        const matchedRequest = _.find(requestData, (item: any) => item.unifiedTrackingId === id);
        if (matchedRequest) {
            const locationData = location.state as any;
            setSelectedRequest(matchedRequest);
            setQueryParam(
                history,
                RouteConstants.UNIFIED_DASHBOARD,
                { page: Number(getQueryParam(history).page) ? Number(getQueryParam(history).page) : 1 },
                true,
                {}
            );
        }
    }, [id, requestData, setSelectedRequest]); */

    useEffect(() => {
        if (listBuilderFilters !== null && previousLocation === '/manage-lists' && !isListBuilderLoading && listBuilderResults !== undefined) {
            setRequestData(listBuilderResults?.items?.filter((item: any) => item.isParent !== true));
            setSelectedRequest(listBuilderResults?.items?.filter((item: any) => item.isParent !== true)[0])
        } else if (!isLoadingRequestList) {
            if (data?.items && data?.items.length > 0) {
                setRequestData(data?.items);
            } else {
                setRequestData([]);
            }
        }
    }, [data, setRequestData, isLoadingRequestList,  listBuilderResults, previousLocation, isListBuilderLoading, listBuilderFilters]);

    // This is to dynamically adjust the height of left section based on right details section
    const [toggleHeight, setToggleHeight] = useState(0);
    const toggleContainerRef = useRef(null);
    const toggleContainerHeight = useHeightListener(toggleContainerRef);
    useEffect(() => {
        if (toggleContainerRef.current) {
            setToggleHeight(toggleContainerHeight);
        }
    }, [toggleContainerHeight]);

    //add customer
    const addSuccessPopUp = useStore((state: any) => state.addSuccessPopUp);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const titleId = useId('title');

    const getModalAddCustomerData = (data: IRequest) => {
        if (data) {
            let customerData: IAddCustomerData = {};
            customerData.applicationId = data.backendId;
            customerData.unifiedTrackingId = data.unifiedTrackingId;
            customerData.requestType = data.requestType;
            customerData.requestTeam = data.requestTeam;
            customerData.summary = data.summary;
            customerData.customerImpacts = data.customerImpacts;
            return customerData;
        }
    };

    const { mutate: associateRequests } = useAssociateRequests();
    const { mutate: postRequestTypeHelp } = usePostRequestType();

    // State variables for addCustomer
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [displayScreen, setDisplayScreen] = useState('searchCust');

    const handleAddCustomerSuccess = (postAddCustomerResponseData: any, addedCustomerImpacts: IAddCustomer[]) => {
        if (selectedRequest.parentRequestId !== "" && selectedRequest.parentRequestId !== null) {
            const associationObject = {
                requestIds: [postAddCustomerResponseData?.data?.id],
                associatedRequestId: selectedRequest.parentRequestId
            };

            associateRequests(associationObject, {
                onSuccess: () => {
                    setRequestProcessing(false);
                    setDisplayScreen('successPopUp');
    
                    AppQueryClient.invalidateQueries('getRequestById');
                    AppQueryClient.invalidateQueries('useRequestList');
                    AppQueryClient.invalidateQueries('requestQuery');
                },
                onError: () => {
                    setRequestProcessing(false);
                    setDisplayScreen('errorPopUp')
                }
            });
        } else if (selectedRequest.unifiedTrackingId !== postAddCustomerResponseData?.data?.id) {
            // aggregate parent with selectedRequest becoming aggregate parent
            let datapass = {
                summary: selectedRequest.summary,
                description: selectedRequest.description,

                requestedResolutionDate: selectedRequest.requestedResolutionDate,
                whyThisDateIsImportant: selectedRequest.whyThisDateIsImportant,

                status: 1,
                attachments: selectedRequest.attachments,
                customerImpacts: [] as any[],
                isParent: true
            };

            var aggregateCustomerImpact = [] as any;

            selectedRequest.customerImpacts.forEach((impact) => {
                aggregateCustomerImpact.push(impact);
            });

            datapass.customerImpacts = aggregateCustomerImpact;
            datapass.status = selectedRequest.statusValue;

            const requestAggregateData = requestAggregateSubmit(selectedRequest.requestType, selectedRequest, datapass);
            postRequestTypeHelp(
                { subUrl: requestAggregateData.subUrl, data: requestAggregateData.datapass },
                {
                    onSuccess: (response) => {
                        finishParentAssociation(response.data.unifiedId, postAddCustomerResponseData?.data?.id);
                    }
                }
            );
        } else {
            setRequestProcessing(false);
            setDisplayScreen('successPopUp');

            AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
        }
    };

    const finishParentAssociation = (parentId: string, postAddCustomerId: string) => {
        const associationObject = {
            requestIds: [selectedRequest.unifiedTrackingId, postAddCustomerId],
            associatedRequestId: parentId
        };

        associateRequests(associationObject, {
            onSuccess: () => {
                setRequestProcessing(false);
                setDisplayScreen('successPopUp');

                AppQueryClient.invalidateQueries('getRequestById');
                AppQueryClient.invalidateQueries('useRequestList');
                AppQueryClient.invalidateQueries('requestQuery');
            },
            onError: () => {
                setRequestProcessing(false);
                setDisplayScreen('errorPopUp')
            }
        });
    };

    return (
        <div className="list-panel-container" role="main" aria-label="List view panel">
            <div className="list-view-toggle">
                <Text className={`${isMinimizedView ? 'active' : ''}`}>
                    <Trans>Minimized View</Trans>
                </Text>
                <div className="lv-toggle-wrap">
                    <div
                        className={`lv-toggle ${isMinimizedView ? 'handle-left' : ''}`}
                        onClick={() => handleToggleView(true)}
                        onKeyPress={(e) => keyPress(e, () => handleToggleView(true))}
                        tabIndex={0}
                        data-testid="toggle-test">
                        <Icon iconName="List" className="icon starred" />
                    </div>
                    <div
                        className={`lv-toggle ${!isMinimizedView ? 'handle-right' : ''}`}
                        onClick={() => handleToggleView(false)}
                        onKeyPress={(e) => keyPress(e, () => handleToggleView(false))}
                        tabIndex={0}
                        data-testid="togglefalse-test">
                        <Image src={detailsIcon} alt="Toggle detailed view icon" />
                    </div>
                </div>
                <Text className={`${!isMinimizedView ? 'active' : ''}`}>
                    <Trans>Maximized View</Trans>
                </Text>
            </div>

            <div id="list-scroll-panel" className="scroll-panel">
                <div className="list-item-wrapper">
                    {requestData && requestData.length > 0 && (
                        <div>
                            {requestData.map((item: IRequest, index) => (
                                <div
                                    key={index}
                                    className={`list-item ${
                                        item?.unifiedTrackingId === selectedRequest?.unifiedTrackingId ? 'active' : ''
                                    }`}
                                    tabIndex={0}
                                    onClick={() => {
                                        setSelectedRequest(item);
                                        // history?.push(`${item.unifiedTrackingId}`);
                                    }}
                                    onKeyPress={(e) =>
                                        keyPress(e, () => {
                                            setSelectedRequest(item);
                                            // history?.push(`${item.unifiedTrackingId}`);
                                        })
                                    }
                                    data-testid="select-test">
                                    <div className="list-title">
                                        <Text>
                                            {item.unifiedTrackingId}
                                            {item.isBookmarked ? (
                                                <Icon iconName="FavoriteStarFill" className="icon star-icon m-l-5" />
                                            ) : (
                                                ''
                                            )}
                                        </Text>

                                        <Link
                                            tabIndex={0}
                                            className="link-list-add"
                                            onKeyPress={(e) =>
                                                keyPress(e, () => {
                                                    addSuccessPopUp(false);
                                                    showModal();
                                                })
                                            }
                                            onClick={() => {
                                                addSuccessPopUp(false);
                                                showModal();
                                            }}>
                                            +<Trans>Add a customer</Trans>
                                        </Link>
                                    </div>
                                    <div className={`list-desc ${isMinimizedView ? 'text-normal' : ''}`}>
                                        <Text className="title-field">{item.summary}</Text>
                                    </div>
                                    {!isMinimizedView && (
                                        <>
                                            {userDetails.email === item?.createdBy?.email && (
                                                <>
                                                    <div className="list-head">
                                                        <Text>
                                                            <Trans>CUSTOMER</Trans>
                                                        </Text>
                                                    </div>
                                                    <div className="list-value">
                                                        <Text>
                                                            {item.customerImpacts && item.customerImpacts?.length > 0
                                                                ? item.customerImpacts[0].customerName
                                                                : '--'}
                                                        </Text>
                                                    </div>
                                                </>
                                            )}
                                            <div className="list-head">
                                                <Text>
                                                    <Trans>REQUEST TYPE</Trans>
                                                </Text>
                                            </div>
                                            <div className="list-value">
                                                <Text>
                                                    {GetText(requestTypes, item.requestType)} ({GetText(teams, item.requestTeam)})
                                                </Text>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    {isLoadingRequestList && (
                        <>
                            <Shimmer />
                            <Shimmer width="75%" />
                            <Shimmer width="50%" />
                        </>
                    )}
                    {!isLoadingRequestList && requestData && requestData.length === 0 && (
                        <div className="list-item">
                            <Trans>No data to display</Trans>
                        </div>
                    )}
                </div>
            </div>

            {hideModal && (
                <Modal
                    layerProps={{ eventBubblingEnabled: true }}
                    titleAriaId={titleId}
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    isBlocking={false}
                    className="add-impact-model">
                    <AddImpact
                        selectedRequest={getModalAddCustomerData(selectedRequest)}
                        onDismiss={hideModal}
                        globalSelectedRequest={selectedRequest}
                        setSelectedRequest={setSelectedRequest}
                        onSuccessHandler={handleAddCustomerSuccess}
                        requestProcessing={requestProcessing}
                        setRequestProcessing={setRequestProcessing}
                        displayScreen={displayScreen}
                        setDisplayScreen={setDisplayScreen}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ListPanel;
