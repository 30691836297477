import React, { useEffect, useState } from 'react';
import { DefaultButton, Icon, ImageIcon, Link, Text, Modal } from '@fluentui/react';
import './DetailsPanel.scss';
import { useAddBookmark, useAssociateRequests, usePostRequestType, useRemoveBookmark } from 'app/services/mutations';
import ShareRequestModal from 'app/components/shareRequestModal/ShareRequestModal';
import { IRequest, IAddCustomerData } from 'app/models/common/response';
import {
    ISOToDateTime,
    GetText,
    formatCurrency,
    GetTypes,
    keyPress,
    ReplaceUrlByAnchor,
    highlightTags,
    changeDateFormat,
    requestAggregateSubmit,
    injectTargetToAnchor
} from 'app/components/helpers';
import { IAddCustomer, ILookUpData, IUserDetails } from 'app/models/common/post';
import AgingProgress from 'app/components/agingProgress/AgingProgress';
import CommentSection from 'app/components/commentSection/CommentSection';
import { Trans } from 'react-i18next';
import attachmentActiveIcon from 'app/static/icons/attachment-blue-icon.svg';
import AddImpact from 'app/components/addCustomer/AddCustomer';
import { useBoolean, useId } from '@fluentui/react-hooks';
import useStore from 'app/store';
import AddAttachmentModal from 'app/components/addAttachmentModal/AddAttachmentModal';
import { IAttachmentUpdateData, ICommentCriteria } from 'app/models/common/request';
import { Loader } from 'app/components/loader/Loader';
import moment from 'moment';
import { useFetchStatus } from 'app/services/queries';
import _ from 'lodash';
import { AppQueryClient } from 'app/services/clients/query-client';
import { attachmentCounter, getPortalValue } from 'app/utils/utilities';
import { useHistory } from 'react-router-dom';
import { RequestStatus } from 'app/enums/Status.enum';
import { BlobStorageContainer, Entity, StringConstants } from 'app/utils/constants';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';

interface IProps {
    requestData?: IRequest[];
    selectedRequest?: IRequest;
    requestTypes?: ILookUpData[];
    statusTypes?: ILookUpData[];
    setIsAddCustomerModalOpen?: Function;
    cardTypeFilter?: number;
    setIsBookmarked?: Function;
    isBookMarked?: boolean;
    resetData?: Function;
    teams?: ILookUpData[];
    refetchPageData?: Function;
    isLoadingRequestList?: boolean;
    setSelectedRequest: Function;
    pageType?: string;
}

const DetailsPanel: React.FC<IProps> = (props) => {
    // props variables
    const { selectedRequest, requestTypes, statusTypes, teams, isLoadingRequestList, requestData, setSelectedRequest } = props;
    // props function
    const { refetchPageData, pageType } = props;

    // state variables
    const portal = getPortalValue(useHistory()?.location?.pathname);
    const titleId = useId('title');
    const addSuccessPopUp = useStore((state: any) => state.addSuccessPopUp);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isShareRequestModalOpen, setIsShareRequestModalOpen] = useState(false);
    const [isStarred, setIsStarred] = useState(false);
    const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] = useState(false);
    const [isShowComment, setIsShowComment] = useState(true);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const { mutate: addBookmark } = useAddBookmark();
    const { mutate: removeBookmark } = useRemoveBookmark();
    const [attachmentCount, setAttachmentCount] = useState<number>(0);
    const isClosed = selectedRequest?.status === RequestStatus.Closed;

    const [criteria, setCriteria] = useState<ICommentCriteria>({});
    const { data: commentsData, isLoading: isLoadingComments } = useFetchStatus(criteria);

    const handleShareRequest = () => {
        setIsShareRequestModalOpen(true);
    };

    const handleAddAttachment = () => {
        setIsAddAttachmentModalOpen(true);
    };

    const getModalData = (data: IRequest) => {
        let attachmentData: IAttachmentUpdateData = {};
        attachmentData.applicationId = data.backendId === null ? '--' : data.backendId;
        attachmentData.attachments = data.attachments;
        attachmentData.requestId = data.unifiedTrackingId;
        attachmentData.requestType = GetText(requestTypes, data.requestType);
        attachmentData.teamName = GetText(teams, data.requestTeam);
        attachmentData.teamValue = data.requestTeam;
        attachmentData.requestTypeValue = data.requestType;
        attachmentData.summary = data.summary;
        return attachmentData;
    };

    const renderTag = (status: number) => {
        let label = '';
        let tagClass = '';
        switch (status) {
            case 1:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-a';
                break;
            case 5:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-b';
                break;
            case 2:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-c';
                break;
            default:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-a';
                break;
        }
        return <Text className={`quick-tag ${tagClass}`}>{label}</Text>;
    };

    const onToggleBookmark = () => {
        if (isStarred) {
            removeBookmark(selectedRequest.unifiedTrackingId);
        } else {
            addBookmark(selectedRequest.unifiedTrackingId);
        }
        setTimeout(() => {
            if (pageType === StringConstants.DETAILS_PAGE) {
                AppQueryClient.invalidateQueries('getRequestById');
            } else {
                refetchPageData();
            }
        }, 500);
    };

    const handleAttachmentCompleted = () => {
        if (pageType === StringConstants.DETAILS_PAGE) {
            AppQueryClient.invalidateQueries('getRequestById');
        } else {
            refetchPageData();
        }
    };

    const getModalAddCustomerData = (data: IRequest) => {
        let customerData: IAddCustomerData = {};
        customerData.applicationId = data.backendId;
        customerData.unifiedTrackingId = data.unifiedTrackingId;
        customerData.requestType = data.requestType;
        customerData.requestTeam = data.requestTeam;
        customerData.summary = data.summary;
        customerData.customerImpacts = data.customerImpacts;
        return customerData;
    };

    useEffect(() => {
        setIsStarred(selectedRequest?.isBookmarked);
    }, [selectedRequest?.isBookmarked]);

    useEffect(() => {
        setIsShowComment(true);
    }, [selectedRequest]);

    useEffect(() => {
        // set filter for fetching status information
        if (selectedRequest) {
            let filterData: ICommentCriteria = {
                correlationId: selectedRequest.unifiedTrackingId,
                pageNumber: 1,
                pageSize: 1,
                entityType: 1,
                isInternal: false
            };
            setCriteria(filterData);
        }
    }, [selectedRequest]);

    const getTaggedUser = (name: string, users: any) => {
        if (!_.isEmpty(name) && !_.isEmpty(users)) {
            return _.find(users, (item) => item.id === name);
        } else {
            return {
                id: '',
                displayName: '',
                email: ''
            };
        }
    };

    // add sas token to storage urls in comments
    const [parsedMessage, setParsedMessage] = useState<string>(commentsData?.items[0]?.statusText);
    useEffect(() => {
        if (commentsData?.items[0]?.statusText && commentsData?.items[0]?.statusText !== '') {
            const replaceStorageUrls = async () => {
                await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.COMMENT_STATUS_FILES, commentsData?.items[0].statusText)
                .then((res) => {
                    if (res !== '') {
                        setParsedMessage(res);
                    }
                });
            }

            replaceStorageUrls();
        }
    }, [commentsData]);

    // add sas token to storage urls in description and whyThisDateIsImportant
    const [selectedRequestDescription, setSelectedRequestDescription] = useState('');
    const [selectedRequestWhyThisDateIsImportant, setSelectedRequestWhyThisDateIsImportant] = useState('');
    useEffect(() => {
        const replaceStorageUrls = async () => {
            let description = await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.REQUEST_DETAIL_FILES, selectedRequest?.description);
            setSelectedRequestDescription(description);

            let importance = await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.REQUEST_DETAIL_FILES, selectedRequest?.whyThisDateIsImportant);
            setSelectedRequestWhyThisDateIsImportant(importance);
        }

        replaceStorageUrls();
    }, [selectedRequest, selectedRequest?.description, selectedRequest?.whyThisDateIsImportant])

    const generateCommentMessage = (message: string, taggedUsers: any) => {
        let formattedMsg = message;
        if (message === '' || message === undefined) {
            return formattedMsg;
        }

        let users = Array.from(message?.matchAll(/<userid:(.*?)>/g));
        for (const user of users) {
            formattedMsg = formattedMsg.replace(
                user[0],
                `<span class="tagged-user">${getTaggedUser(user[1], taggedUsers)?.displayName}</span>`
            );
        }

        formattedMsg = formattedMsg.replaceAll('<:request-detail-url>', 'request');
        formattedMsg = ReplaceUrlByAnchor(injectTargetToAnchor(formattedMsg?.replace(/\\/g, '')), 'tagged-user', true);

        return formattedMsg;
    };

    const { mutate: associateRequests } = useAssociateRequests();
    const { mutate: postRequestTypeHelp } = usePostRequestType();

    // State variables for addCustomer
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [displayScreen, setDisplayScreen] = useState('searchCust');

    const handleAddCustomerSuccess = (postAddCustomerResponseData: any, addedCustomerImpacts: IAddCustomer[]) => {
        if (selectedRequest.parentRequestId !== '' && selectedRequest.parentRequestId !== null) {
            const associationObject = {
                requestIds: [postAddCustomerResponseData?.data?.id],
                associatedRequestId: selectedRequest.parentRequestId
            };

            associateRequests(associationObject, {
                onSuccess: () => {
                    setRequestProcessing(false);
                    setDisplayScreen('successPopUp');

                    AppQueryClient.invalidateQueries('getRequestById');
                    AppQueryClient.invalidateQueries('useRequestList');
                    AppQueryClient.invalidateQueries('requestQuery');
                },
                onError: () => {
                    setRequestProcessing(false);
                    setDisplayScreen('errorPopUp');
                }
            });
        } else if (selectedRequest.unifiedTrackingId !== postAddCustomerResponseData?.data?.id) {
            // aggregate parent with selectedRequest becoming aggregate parent
            let datapass = {
                summary: selectedRequest.summary,
                description: selectedRequest.description,

                requestedResolutionDate: selectedRequest.requestedResolutionDate,
                whyThisDateIsImportant: selectedRequest.whyThisDateIsImportant,

                status: 1,
                attachments: selectedRequest.attachments,
                customerImpacts: [] as any[],
                isParent: true
            };

            var aggregateCustomerImpact = [] as any;

            selectedRequest.customerImpacts.forEach((impact) => {
                aggregateCustomerImpact.push(impact);
            });

            datapass.customerImpacts = aggregateCustomerImpact;
            datapass.status = selectedRequest.statusValue;

            const requestAggregateData = requestAggregateSubmit(selectedRequest.requestType, selectedRequest, datapass);
            postRequestTypeHelp(
                { subUrl: requestAggregateData.subUrl, data: requestAggregateData.datapass },
                {
                    onSuccess: (response) => {
                        finishParentAssociation(response.data.unifiedId, postAddCustomerResponseData?.data?.id);
                    }
                }
            );
        } else {
            setRequestProcessing(false);
            setDisplayScreen('successPopUp');

            AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
        }
    };

    const finishParentAssociation = (parentId: string, postAddCustomerId: string) => {
        const associationObject = {
            requestIds: [selectedRequest.unifiedTrackingId, postAddCustomerId],
            associatedRequestId: parentId
        };

        associateRequests(associationObject, {
            onSuccess: () => {
                setRequestProcessing(false);
                setDisplayScreen('successPopUp');

                AppQueryClient.invalidateQueries('getRequestById');
                AppQueryClient.invalidateQueries('useRequestList');
                AppQueryClient.invalidateQueries('requestQuery');
            },
            onError: () => {
                setRequestProcessing(false);
                setDisplayScreen('errorPopUp');
            }
        });
    };

    useEffect(() => {
        if (requestData && requestData.length > 0 && selectedRequest && Object.entries(selectedRequest).length !== 0) {
            let temp = requestData.filter((x) => x.unifiedTrackingId === selectedRequest.unifiedTrackingId)[0];
            if (setSelectedRequest) {
                setSelectedRequest(temp);
            }
        }
    }, [requestData]);

    useEffect(() => {
        const updateAttachmentCount = () => {
            setAttachmentCount(attachmentCounter(selectedRequest?.attachments, portal));
        };

        updateAttachmentCount();
    }, [selectedRequest]);

    return (
        <>
            <div className="details-panel-container" role="main" aria-label="details view panel">
                <div className="scroll-panel">
                    <div className="details-panel-wrapper">
                        {!isLoadingRequestList && selectedRequest && Object.keys(selectedRequest).length > 0 && (
                            <>
                                <div className="dp-header">
                                    <Text className="dp-head-title">{selectedRequest.unifiedTrackingId}</Text>
                                    {renderTag(selectedRequest.status)}
                                    <div className="dp-head-desc">
                                        <Text className="title-field">{selectedRequest.summary}</Text>
                                    </div>
                                    <div className="dp-head-user">
                                        <Text>
                                            <Trans>Created by</Trans> :{' '}
                                            {selectedRequest.createdBy && selectedRequest.createdBy.email}
                                        </Text>
                                        <Text className="dp-head-separator">|</Text>
                                        <Text>
                                            <Trans>Created On</Trans> :{' '}
                                            {ISOToDateTime(selectedRequest.createdOn, 'MMM DD YYYY, hh:mm a')}
                                        </Text>
                                    </div>
                                </div>
                                <div></div>
                                <div className="dp-filter-panel">
                                    <div className="dp-filter-links">
                                        <div className="dp-heading tab-heading">
                                            <Trans>Help Request Details</Trans>
                                        </div>
                                    </div>
                                    <div className="dp-filter-controls">
                                        <Link
                                            role="link"
                                            tabIndex={0}
                                            onClick={onToggleBookmark}
                                            data-testid="fav-test"
                                            className="filter-icon">
                                            <Icon
                                                title="Star icon"
                                                aria-label="starred"
                                                iconName={isStarred ? `FavoriteStarFill` : 'FavoriteStar'}
                                                className={`icon ${isStarred ? 'starred' : ''}`}
                                            />
                                        </Link>

                                        {selectedRequest?.status !== RequestStatus.Closed ||
                                        (selectedRequest?.status === RequestStatus.Closed &&
                                            selectedRequest?.createdBy?.id === userDetails?.id) ? (
                                            <Link
                                                role="link"
                                                className="attachments-icon-link filter-icon"
                                                tabIndex={0}
                                                onKeyPress={(e: any) => keyPress(e, handleAddAttachment)}
                                                onClick={handleAddAttachment}
                                                aria-label="attachments"
                                                data-testid="attachment-button">
                                                <ImageIcon
                                                    imageProps={{
                                                        src: attachmentActiveIcon,
                                                        height: 16,
                                                        width: 16
                                                    }}
                                                />
                                                <Text className="attachments-icon-count">{attachmentCount}</Text>
                                            </Link>
                                        ) : null}
                                        <Link
                                            role="link"
                                            tabIndex={0}
                                            onClick={handleShareRequest}
                                            data-testid="share-test"
                                            className="filter-icon">
                                            <Icon iconName="Share" className="icon" aria-label="share" />
                                        </Link>

                                        <DefaultButton
                                            disabled={selectedRequest?.isParent}
                                            className={
                                                !selectedRequest?.isParent ? 'default add-cust-btn' : 'disabled-add-cust-btn'
                                            }
                                            onClick={() => {
                                                addSuccessPopUp(false);
                                                showModal();
                                            }}
                                            data-testid="add-test">
                                            <Icon aria-hidden="true" iconName="Add" className="m-r-5" />
                                            <Text className='add-cust-label'>
                                                <Trans>Add a customer</Trans>
                                            </Text>
                                        </DefaultButton>
                                        {hideModal && (
                                            <Modal
                                                layerProps={{ eventBubblingEnabled: true }}
                                                titleAriaId={titleId}
                                                isOpen={isModalOpen}
                                                onDismiss={hideModal}
                                                isBlocking={false}
                                                className="add-impact-model">
                                                <AddImpact
                                                    selectedRequest={getModalAddCustomerData(selectedRequest)}
                                                    onDismiss={hideModal}
                                                    globalSelectedRequest={selectedRequest}
                                                    setSelectedRequest={props.setSelectedRequest}
                                                    onSuccessHandler={handleAddCustomerSuccess}
                                                    requestProcessing={requestProcessing}
                                                    setRequestProcessing={setRequestProcessing}
                                                    displayScreen={displayScreen}
                                                    setDisplayScreen={setDisplayScreen}
                                                />
                                            </Modal>
                                        )}
                                    </div>
                                </div>
                                {!isLoadingComments && commentsData && commentsData.items?.length > 0 && (
                                    <div className="status-disp-container">
                                        <div className="status-disp-label">Status :</div>
                                        <div className="status-disp-desc">
                                            <div className="status-desc-text">
                                                <span
                                                    className="cmt-text status"
                                                    dangerouslySetInnerHTML={{
                                                        __html: generateCommentMessage(
                                                            parsedMessage,
                                                            commentsData?.items[0].taggedUsers
                                                        )
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="status-desc-sub-text">
                                                {changeDateFormat(commentsData?.items[0]?.createdOn, 'dddd, MMM DD, YYYY')} at{' '}
                                                {changeDateFormat(commentsData?.items[0]?.createdOn, 'hh:mm a')}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="dp-content">
                                    <div className="dp-details">
                                        <table className="dp-content-details-tbl" role="presentation">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Trans>REQUEST TYPE</Trans>
                                                    </td>
                                                    <td>
                                                        <Trans>AGING</Trans>
                                                    </td>
                                                    {userDetails.email === selectedRequest.createdBy.email && (
                                                        <td>
                                                            <Trans>CUSTOMER</Trans>
                                                        </td>
                                                    )}
                                                    <td>
                                                        <Trans>IMPACTED DEVICE</Trans>
                                                    </td>
                                                    <td>
                                                        <Trans>IMPACTED REVENUE</Trans>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{GetTypes(selectedRequest.requestType)}</td>
                                                    <td>
                                                        <AgingProgress
                                                            startDate={selectedRequest.createdOn}
                                                            endDate={selectedRequest.requestedResolutionDate}
                                                            closedDate={selectedRequest.closedOn}
                                                        />
                                                    </td>
                                                    {userDetails.email === selectedRequest.createdBy.email && (
                                                        <td>
                                                            {selectedRequest.customerImpacts &&
                                                            selectedRequest.customerImpacts.length > 0
                                                                ? selectedRequest.customerImpacts[0]?.customerName
                                                                : '--'}
                                                        </td>
                                                    )}
                                                    <td>{formatCurrency(selectedRequest.msxTotalDeviceCount)}</td>
                                                    <td>$ {formatCurrency(selectedRequest.msxTotalRevenue)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="dp-content-title">
                                            <Text>
                                                <Trans>DESCRIPTION</Trans>:
                                            </Text>
                                        </div>
                                        <div className="dp-content-text">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: highlightTags(
                                                        ReplaceUrlByAnchor(
                                                            injectTargetToAnchor(selectedRequestDescription),
                                                            'link-item',
                                                            true
                                                        )
                                                    )
                                                }}
                                            ></span>
                                        </div>

                                        <div className="dp-content-title">
                                            <Text>
                                                <Trans>REQUESTED RESOLUTION DATE </Trans>:
                                            </Text>
                                        </div>
                                        <div className="dp-content-text">
                                            <Text>
                                                {moment(selectedRequest.requestedResolutionDate).isValid()
                                                    ? ISOToDateTime(selectedRequest.requestedResolutionDate, 'MMM DD YYYY')
                                                    : '--'}
                                            </Text>
                                        </div>

                                        <div className="dp-content-title">
                                            <Text>
                                                <Trans>WHY IS THIS DATE IMPORTANT? </Trans>:
                                            </Text>
                                        </div>
                                        <div className="dp-content-text">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: highlightTags(
                                                        ReplaceUrlByAnchor(
                                                            injectTargetToAnchor(selectedRequestWhyThisDateIsImportant),
                                                            'link-item',
                                                            true
                                                        )
                                                    )
                                                }}
                                            ></span>
                                        </div>
                                        <div className="dp-content-title">
                                            <Text>
                                                <Trans>ADDED IMPACT</Trans> :
                                            </Text>
                                        </div>
                                        <table className="dp-table impact-table" role="presentation">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <Trans>ADDED ON</Trans>
                                                    </td>
                                                    {userDetails.email === selectedRequest.createdBy.email && (
                                                        <td>
                                                            <Trans>MSX</Trans>
                                                        </td>
                                                    )}
                                                    {userDetails.email === selectedRequest.createdBy.email && (
                                                        <td>
                                                            <Trans>END CUSTOMER</Trans>
                                                        </td>
                                                    )}
                                                    <td>
                                                        <Trans>COUNTRY</Trans>
                                                    </td>
                                                    <td>
                                                        <Trans>IMPACTED DEVICE</Trans>
                                                    </td>
                                                    <td>
                                                        <Trans>IMPACTED REVENUE</Trans>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRequest.customerImpacts &&
                                                    selectedRequest.customerImpacts.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{ISOToDateTime(item.addedOn, 'MMM DD YYYY')}</td>
                                                            {userDetails.email === selectedRequest.createdBy.email && (
                                                                <td>{item.opportunityId}</td>
                                                            )}
                                                            {userDetails.email === selectedRequest.createdBy.email && (
                                                                <td>{item.customerName}</td>
                                                            )}
                                                            <td>{item.customerCountry.name}</td>
                                                            <td>{formatCurrency(item.deviceCount)}</td>
                                                            <td>$ {formatCurrency(item.revenue)} </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                        {(selectedRequest.status !== RequestStatus.Closed ||
                                            selectedRequest.createdBy.displayName === userDetails.displayName) && (
                                            <div>
                                                <div className="dp-content-title">
                                                    <Trans>COMMENTS</Trans>
                                                    {isClosed && <Trans>(CLOSED)</Trans>}:
                                                </div>
                                                <div>
                                                    {isShowComment && selectedRequest.unifiedTrackingId && (
                                                        <CommentSection
                                                            correlationId={selectedRequest.unifiedTrackingId}
                                                            isMultipleType={false}
                                                            defaultIsInternal={false}
                                                            isClosed={isClosed}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {isLoadingRequestList && <Loader classNames="m-t-20 m-b-50 height-70" />}
                        {!isLoadingRequestList && selectedRequest && Object.keys(selectedRequest).length === 0 && (
                            <div className="no-data">
                                <Trans>No data to display</Trans>
                            </div>
                        )}
                    </div>
                </div>

                <ShareRequestModal
                    modalState={isShareRequestModalOpen}
                    updateModalState={(value: boolean) => setIsShareRequestModalOpen(value)}
                    selectedRequest={selectedRequest}
                />
                {isAddAttachmentModalOpen && (
                    <AddAttachmentModal
                        modalState={isAddAttachmentModalOpen}
                        updateModalState={(value: boolean) => setIsAddAttachmentModalOpen(value)}
                        selectedRequest={getModalData(selectedRequest)}
                        entityType={1}
                        onCompletedHandler={handleAttachmentCompleted}
                    />
                )}
            </div>
        </>
    );
};

export default DetailsPanel;
