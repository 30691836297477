import { Modal, TextField, Text, IIconProps, getTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import './CustomerPopUpModal.scss'
import { Trans } from 'react-i18next';
import React from 'react';
import Button from '../../../../../components/button/Button';
import { IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { SuccessPopUpModal } from '../successPopUp/SuccessPopUp';

interface IProps {
    message: string;
    setToggleSearch:Function;
    custNameHText: boolean;
    setCustomerInputType: Function;
    setCustNameHText: any;
    custName: string;
    searchNotFound: boolean;
    handleCancelClick: Function;
    setSearchNotFound: Function;
    setCustName: Function;
    setCustomerSearchInput: Function;
    setCountry: Function;
}

export const CustomerPopUpModal = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const theme = getTheme();
    const { SuccessPopUp, showSuccess, hideSuccess } = SuccessPopUpModal();

    const CustomerModal: React.FC<IProps> = ({
        message,
        setCustomerSearchInput,
        setToggleSearch,
        custNameHText,
        setCustNameHText,
        setCustomerInputType,
        custName,
        setCustName,
        setSearchNotFound,
        handleCancelClick,
        setCountry,
        searchNotFound
    }) => {

    const handleCancel = () => {
        handleCancelClick();
        setSearchNotFound(false);
        setCustName('');
        setCountry('');
        setCustomerInputType('search');
        setCustomerSearchInput('');
        hideModal();
    }

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: 'white',
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
          },
          rootHovered: {
            color: theme.palette.neutralDark,
          },
      };

    const AddCustomerToField = () => {
        setSearchNotFound(true);
        setCustName(custName);
        setCustomerInputType('textfield');
        setToggleSearch(true);
        setCountry('');
        hideModal();
        showSuccess();
        setTimeout(() => {
            hideSuccess();
          }, 3000);
    }

    return (
        <div>
            <div>
                <Modal
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    isBlocking={false}
                    className="surface-modal custom-message-modal-hook">
                <div className="customer-modal-header">
                    <div className='addCustomerHeader'>
                        ADD A CUSTOMER
                    </div>
                    <div>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={handleCancel}
                        />
                    </div>
                </div>
                <div className="fontStyles">
                    {message}
                </div>
                <div className="customer-container">
                    <div className="companyNameStyles">
                        Customer Name
                    </div>
                <TextField
                    value={custName}
                    onChange={(e: any) => {
                        setCustName(e.target.value);
                        setCustNameHText(false);
                    }}
                    autoFocus={true}
                    placeholder="Type here..."
                    className={`regular-text-field-modal ${custNameHText ? 'error-feild' : ''}`}
                    ariaLabel="Enter customer name"
                />
                {custNameHText && (
                    <Text className="helper-txt">
                        <Trans>Please enter a customer name.</Trans>
                    </Text>
                )}
                </div>
                    <div className="confirmation-buttons">
                        <div className="modal-footer">
                            <Button buttonClass="btn btn--secondary" buttonText="Cancel" onClick={handleCancel} />
                            <Button disabled={custName? false : true} buttonClass="btn btn--primary" buttonText="Submit" onClick={AddCustomerToField} />
                        </div>
                    </div>
                </Modal>
                <SuccessPopUp
                />
            </div>
        </div>
        );
    };

    return { CustomerModal, showModal, hideModal };
};
                        