import React from 'react';
import Editor from './Editor';
import { useTranslation } from 'react-i18next';
import { ArticleType } from 'app/enums/ArticleType.enum';

interface IProps {
    setTitle: (title: string) => void;
    title: string;
    setContent: (title: string) => void;
    content: string;
    isPreview: boolean;
    handleFileUploadProgress?: (loadedBytes: number, totalBytes: number) => void;
    type: ArticleType;
}

const ContentArea: React.FC<IProps> = (props: IProps) => {
    const { t: translate } = useTranslation();
    const { title, setTitle, content, setContent, isPreview = false, handleFileUploadProgress, type } = props;

    if (!isPreview) {
        return (
            <>
                <input
                    className="page-title"
                    type="text"
                    value={title}
                    placeholder={translate('Article title')}
                    onChange={({ target: { value } }) => setTitle(value)}
                />

                <div className={type === ArticleType.Training ? 'training-article' : ''}>
                    <Editor
                        type={type}
                        content={content}
                        setContent={setContent}
                        handleFileUploadProgress={handleFileUploadProgress}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <h2>{title}</h2>
                <div className="sun-editor-editable" dangerouslySetInnerHTML={{ __html: content }} />
            </>
        );
    }
};

export default ContentArea;
