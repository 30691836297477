import React, { useEffect } from 'react';

import RequestExperience from 'app/modules/user/newReqExperience';
import { useHistory } from 'react-router-dom';
import useStore from 'app/store';

const RequestExp: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history?.location?.pathname);
    }, []);
    return (
        <div>
            <RequestExperience />
        </div>
    );
};

export default RequestExp;
