export enum QueryIcon {
    PostIt = 0,
    Bug,
    Settings,
    Star,
    Flag,
    Warning,
    Message,
    Checkbox,
    Notepad,
    World,
    Trophy,
    Lightning,
    Alarm,
    Question
}