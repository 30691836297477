import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

/*
see official docs for application insights for javascript:
https://learn.microsoft.com/en-us/azure/azure-monitor/app/nodejs#telemetryclient-api
https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics
*/

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

export default class AppInsightsService {
    private readonly appInsights: ApplicationInsights;
    private readonly env: string;
    private defaultOptions: {
        "ai.cloud.role": "FrontEnd"
    };

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                },
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                correlationHeaderExcludedDomains: ['*.graph.windows.net']
            }
        });

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    public logEvent(name: string, properties: { [key: string]: string } = this.defaultOptions) {
        if (this.appInsights) {
            this.appInsights.trackEvent({
                name: name,
                properties
            });
        }
    }

    public logPageView(name: string, properties: { [key: string]: string } = this.defaultOptions) {
        if (this.appInsights) {
            this.appInsights.trackPageView({
                name: name,
                properties
            });
        }
    }

    public logPageViewPerformance(name: string, properties?: { [key: string]: string }) {
        if (this.appInsights) {
            this.appInsights.trackPageViewPerformance({
                name: name,
                properties
            });
        }
    }

    public logException(errorMessage: string, error: any, properties: { [key: string]: string } = this.defaultOptions) {
        if (this.appInsights) {
            if (this.env.toLowerCase() === ('local' || 'test')) {
                console.error(error);
            }
            this.appInsights.trackException({
                exception: new Error(errorMessage),
                properties: {
                    ...properties,
                    errorMessage: error.message
                }
            });
        }
    }

    public logTrace(message: string, properties?: { [key: string]: string }) {
        if (this.appInsights) {
            this.appInsights.trackTrace({
                message: message,
                properties: properties
            });
        }
    }

    public logMetric(name: string, average: number, properties?: { [key: string]: string }) {
        if (this.appInsights) {
            this.appInsights.trackMetric({
                name: name,
                average: average,
                properties: properties
            });
        }
    }

    public logDependencyData(data: string, responseCode: number, id: string, properties?: { [key: string]: string }) {
        if (this.appInsights) {
            this.appInsights.trackDependencyData({
                data: data,
                responseCode: responseCode,
                id: id,
                properties: properties
            });
        }
    }

    public startTrackPage(pageName: string) {
        if (this.appInsights) {
            this.appInsights.startTrackPage(pageName);
        }
    }

    public stopTrackPage(
        pageName: string,
        url: string,
        properties?: { [key: string]: string },
        measurements?: { [key: string]: number }
    ) {
        if (this.appInsights) {
            this.appInsights.stopTrackPage(pageName, url, properties, measurements);
        }
    }

    public startTrackEvent(event: string) {
        if (this.appInsights) {
            this.appInsights.startTrackEvent(event);
        }
    }

    public stopTrackEvent(event: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        if (this.appInsights) {
            this.appInsights.stopTrackEvent(event, properties, measurements);
        }
    }

    public flush() {
        if (this.appInsights) {
            this.appInsights.flush();
        }
    }
}
