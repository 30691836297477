import React, { useEffect, useState } from 'react';
import './Notifications.scss';
import { useNotificationsFull } from 'app/services/queries';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SpinnerSize } from '@fluentui/react';
import { Trans } from 'react-i18next';
import { Loader } from 'app/components/loader/Loader';
import { INotificationItem } from 'app/models/common/response';
import { getIconForNotifications, getLinkForNotification, keyPress } from 'app/components/helpers';
interface IFormattedNotification {
    date: string;
    notifications: INotificationItem[];
}

const Notifications = () => {
    const history = useHistory();

    const [pageNumber, setPageNumber] = useState(1);
    const { data: notificationsData, isLoading, refetch: getNotifications } = useNotificationsFull(pageNumber, 10);
    const [notifications, setNotifications] = useState([]);
    const [formattedNotifications, setFormattedNotifications] = useState<IFormattedNotification[]>([]);
    useEffect(() => {
        let isMounted = true;
        if (!isLoading && isMounted) {
            if (notificationsData?.items?.length > 0) {
                const formattedNotification = _.chain([...notifications, ...notificationsData?.items])
                    .groupBy((obj: INotificationItem) => {
                        return moment(obj.createdOn).format('DD MMM YYYY');
                    })
                    .map((value: any, key: string) => ({ date: key, notifications: value }))
                    .value()
                    .sort((a: any, b: any) => {
                        if (moment(b.date).isSameOrBefore(a.date)) return -1;
                        else return 1;
                    });

                setNotifications([...notifications, ...notificationsData?.items]);
                setFormattedNotifications(formattedNotification);
            }
        }
        return () => {
            isMounted = false;
            setNotifications([]);
            setFormattedNotifications([]);
        };
    }, [isLoading, notificationsData?.items]);

    useEffect(() => {
        getNotifications();
    }, [pageNumber]);

    const fetchData = () => {
        setPageNumber(pageNumber + 1);
    };

    const renderNotification = (notification: INotificationItem) => {
        const [alt, icon] = getIconForNotifications(notification?.entityType, notification?.eventType);
        return (
            <>
                <img className="notification-icon" src={icon} alt={alt} />
                <div className="notification-message-container">
                    <div className="notification-message">{notification?.text}</div>
                    <div className="notification-time">{moment(notification?.createdOn).fromNow()} </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div className="notification-banner">
                {!isLoading && (
                    <div className="container notification-overflow-1">
                        <div className="notification-header">
                            <Trans>Notifications</Trans>
                        </div>
                        <InfiniteScroll
                            next={fetchData}
                            dataLength={notifications.length}
                            hasMore={notificationsData?.hasNextPage}
                            loader={<Loader size={SpinnerSize.small} />}
                            endMessage={
                                <p className="text-center">
                                    <Trans>Yay! You have seen it all</Trans>
                                </p>
                            }>
                            {formattedNotifications &&
                                formattedNotifications.map((item: IFormattedNotification, index: number) => (
                                    <div key={index}>
                                        <div className="notification-header-time">{moment(item.date).format('DD MMM YYYY')}</div>
                                        <div>
                                            {item.notifications.map((notification: INotificationItem, index: number) => (
                                                <div
                                                    tabIndex={0}
                                                    key={`notification${index}`}
                                                    onClick={() => history.push(getLinkForNotification(notification))}
                                                    onKeyPress={(e: any) =>
                                                        keyPress(e, () => history.push(getLinkForNotification(notification)))
                                                    }
                                                    className="notification-item-row">
                                                    {renderNotification(notification)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                        </InfiniteScroll>
                    </div>
                )}
            </div>
        </>
    );
};

export default Notifications;
