import React from 'react';
import { Text, Image } from '@fluentui/react';
import './FeedbackResponse.scss';
import checkIcon from 'app/static/icons/success_modal_icon.svg';
import { Trans } from 'react-i18next';

const FeedbackResponse: React.FC<{ showOnScreen: boolean }> = ({ showOnScreen }) => {
    return (
        <div className={`fc-response ${showOnScreen ? '' : 'hidden'}`}>
            <div className="fc-response-icon">
                <Image src={checkIcon} alt="Success" />
            </div>
            <div className="fc-res-text">
                <Text>
                    <Trans>
                        We appreciate you writing to us, we will ensure that your feedback is tended to at the earliest, you will
                        also receive a copy of the feedback on your email.
                    </Trans>
                </Text>
            </div>
        </div>
    );
};

export default FeedbackResponse;
