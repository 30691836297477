import { Image, ITooltipHostStyles, Link, Text, TextField, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { keyPress } from 'app/components/helpers';
import InfoIcon from 'app/static/icons/tooltip-info-icon.svg';
import { StringConstants } from 'app/utils/constants';
import React from 'react';
import { Trans } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { SearchMSX } from '../searchMSX/index';
interface IProps {
    setOppId: Function;
    oppId: string;
    setcustAltPage: Function;
    showModal: any;
    animateCustomer: string;
    requestType: any;
    cssCaseNum: any;
    setCSSCaseNum: any;
    setCaseNumHText: any;
    caseNumHText: any;
    autoSuggestHText: any;
    setRevenueRisk: any;
    setImpDevices: any;
    isMSXListDataLoading: any;
    msxData: any;
    setMSXMatched: any;
    setMSXNotMatched: any;
    removeDuplicates: any;
    setSuggestions: any;
    getSuggestionValue: any;
    renderSuggestion: any;
    suggestions: any;
    dropDownValue: any;
    setDropDownValue: any;
    setFilteredData: any;
    setSuggestionSelected?: any;
    setAutoSuggestHText: any;
    msxMatched: any;
    filteredData: any;
    msxNotMatched: any;
    revenueRisk: any;
    setRevenueHText: any;
    setRevenueValidText: any;
    revenueHText: any;
    impDevicesValidText: any;
    revenueValidText: any;
    impDevices: any;
    setImpDevicesHText: any;
    setImpDevicesValidText: any;
    impDevicesHText: any;
    emailID: any;
    setEmailID: any;
    setEmailValidText: any;
    setEmailHText: any;
    emailHtext: any;
    emailValidText: any;
    setManualData: any;
    setRequestSection: any;
    customerSearchInput: string;
    setCustomerSearchInput: Function;
    customerSelected: boolean;
    setCustomerSelected: Function;
}
const Customer: React.FC<IProps> = (props) => {
    const {
        setOppId,
        oppId,
        setcustAltPage,
        showModal,
        animateCustomer,
        requestType,
        cssCaseNum,
        setCSSCaseNum,
        setCaseNumHText,
        caseNumHText,
        autoSuggestHText,
        setRevenueRisk,
        setImpDevices,
        setFilteredData,
        setAutoSuggestHText,
        revenueRisk,
        setRevenueHText,
        setRevenueValidText,
        revenueHText,
        impDevicesValidText,
        revenueValidText,
        impDevices,
        setImpDevicesHText,
        setImpDevicesValidText,
        impDevicesHText,
        setManualData,
        setRequestSection,
        customerSearchInput,
        setCustomerSearchInput,
        customerSelected,
        setCustomerSelected
    } = props;
    const tooltipId = useId('tooltip');
    const inlineBlockStyle: Partial<ITooltipHostStyles> = {
        root: { display: 'inline-block' }
    };

    const calloutProps = { gapSpace: 0 };
    return (
        <div
            className={`search-customer-cont ${animateCustomer === 'S_TO_NEXT' ? 'nextSlide' : ''} ${
                animateCustomer === 'S_TO_PREV' ? 'prevSlide' : ''
            } ${animateCustomer === 'A_TO_RIGHT' ? 'animateToRight' : ''} ${
                animateCustomer === 'A_TO_LEFT' ? 'animateToLeft' : ''
            }`}>
            {requestType === StringConstants.REVIEW_REQUEST && (
                <div>
                    <div className="m-b-40 cssContainerStyles">
                        <TextField
                            label="CSS Case Number"
                            value={cssCaseNum}
                            onChange={(e: any) => {
                                setCSSCaseNum(e.target.value);
                                setCaseNumHText(false);
                            }}
                            className={`regular-text-field css-case-num-field ${caseNumHText ? 'error-feild' : ''}`}
                            placeholder="eg: 120072024000000"
                            data-testid="text1-test"
                        />
                    </div>
                    {caseNumHText && (
                        <div>
                            <Text className="helper-txt-case-num error-field">Please enter a CSS case number.</Text>
                        </div>
                    )}
                </div>
            )}
            <div className={`auto-search-cont  ${autoSuggestHText ? 'error-feild-auto' : ''}`}>
                <label>
                    <Trans>Do you have an MSX Opportunity ID?</Trans>
                </label>
                <SearchMSX
                    oppId={oppId}
                    setOppId={setOppId}
                    searchValue={customerSearchInput}
                    setSearchValue={setCustomerSearchInput}
                    setSuggestionSelected={setCustomerSelected}
                    setFilteredData={setFilteredData}
                    setRevenueRisk={setRevenueRisk}
                    setImpDevices={setImpDevices}
                    setRevenueHText={setRevenueHText}
                    setImpDevicesHText={setImpDevicesHText}
                    autoSuggestHText={autoSuggestHText}
                    setAutoSuggestHText={setAutoSuggestHText}
                    origin="REQUEST_EXPERIENCE"
                />
                {autoSuggestHText && (
                    <Text className="helper-txt" aria-label="Please enter a valid ID or Customer name" tabIndex={0}>
                        <Trans>Please enter a valid ID/customer name.</Trans>
                    </Text>
                )}
            </div>
            {customerSelected && (
                <div className={`row-flex m-t-40  ${customerSelected ? 'slideDown' : ''}`}>
                    <div className="shift-lt col-flex-box">
                        <label>
                            <Trans>Sales opportunity revenue risk</Trans>{' '}
                        </label>
                        <div
                            className={`format-field-cont ${revenueHText ? 'error-feild' : ''} ${
                                revenueValidText ? 'error-feild' : ''
                            }`}>
                            <NumberFormat
                                aria-label='Enter sales opportunity risk'
                                thousandSeparator={true}
                                value={revenueRisk}
                                onChange={(e: any) => {
                                    setRevenueHText(false);
                                    setRevenueRisk(e.target.value);
                                    setRevenueValidText(false);
                                }}
                                data-testid="revenue-risk-test"
                                placeholder="eg: $167,700"
                                allowNegative={false}
                                prefix={'$'}
                                className={`format-field regular-text-field   `}
                                inputMode="numeric"
                                decimalScale={2}
                            />
                        </div>
                        <div className="info-msg">
                            <Text>
                                Learn more about{' '}
                                <Text aria-label="Sales opportunity revenue risk" tabIndex={0} className="info-text">
                                    {' '}
                                    Sales opportunity revenue risk
                                </Text>
                                <TooltipHost
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    aria-label="We pull this dynamically from MSX. If you do not feel the Revenue Risk is listed accurately, please adjust the number"
                                    content={
                                        'We pull this dynamically from MSX. If you do not feel the Revenue Risk is listed accurately, please adjust the number.'
                                    }>
                                    <Image
                                        tabIndex={0}
                                        aria-label="We pull this dynamically from MSX. If you do not feel the Revenue Risk is listed accurately, please adjust the number"
                                        aria-describedby={tooltipId}
                                        src={InfoIcon}
                                        alt="Info"
                                    />
                                </TooltipHost>
                            </Text>
                        </div>
                        {revenueHText && (
                            <Text className="helper-txt">
                                <Trans>Please enter the revenue risk.</Trans>
                            </Text>
                        )}
                        {revenueValidText && (
                            <Text className="helper-txt">
                                <Trans>Please enter a purely numeric value e.g.72000.</Trans>
                            </Text>
                        )}
                    </div>
                    <div className="shift-rt">
                        <label>
                            <Trans>Sales opportunity device risk </Trans>
                        </label>
                        <div
                            className={`format-field-cont ${impDevicesHText ? 'error-feild' : ''}  ${
                                impDevicesValidText ? 'error-feild' : ''
                            }`}>
                            <NumberFormat
                                aria-label='Enter sales opportunity device risk'
                                thousandSeparator={true}
                                value={impDevices}
                                data-testid="device-risk-test"
                                onChange={(e: any) => {
                                    setImpDevicesHText(false);
                                    setImpDevices(e.target.value);
                                    setImpDevicesValidText(false);
                                }}
                                placeholder="eg: 60,700"
                                allowNegative={false}
                                className={`format-field regular-text-field ${impDevicesHText ? 'error-feild' : ''}  ${
                                    impDevicesValidText ? 'error-feild' : ''
                                }`}
                                inputMode="numeric"
                                decimalScale={0}
                            />
                        </div>
                        <div className="info-msg">
                            <Text>
                                Learn more about{' '}
                                <Text aria-label="Sales opportunity device risk" tabIndex={0} className="info-text">
                                    Sales opportunity device risk
                                </Text>{' '}
                                <TooltipHost
                                    id={tooltipId}
                                    calloutProps={calloutProps}
                                    content={
                                        'We pull this dynamically from MSX. If you do not feel the Device Risk listed accurately, please adjust the number.'
                                    }>
                                    <Image
                                        tabIndex={0}
                                        aria-label="We pull this dynamically from MSX. If you do not feel the Device Risk listed accurately, please adjust the number."
                                        aria-describedby={tooltipId}
                                        src={InfoIcon}
                                        alt="Info"
                                    />
                                </TooltipHost>
                            </Text>
                        </div>

                        {impDevicesHText && (
                            <Text className="helper-txt">
                                <Trans>Please enter the device risk involved.</Trans>
                            </Text>
                        )}
                        {impDevicesValidText && (
                            <Text className="helper-txt">
                                <Trans>Please enter a purely numeric value e.g.72000.</Trans>
                            </Text>
                        )}
                    </div>
                </div>
            )}
            {requestType === StringConstants.POST_SALES && customerSelected && (
                <div className={`row-flex m-t-20 mar-t-40 ${customerSelected ? 'slideDown' : ''}`}>
                    <div className="col-50 post-sales-case-num">
                        <div className="case-num-label">
                            <label>
                                <Trans>Do you have a CSS case number?</Trans>
                            </label>{' '}
                            <TooltipHost
                                content="Please include the relevant CSS case number related to the issue blocking your customer. This helps us take action quickly."
                                styles={inlineBlockStyle}
                                id={tooltipId}
                                calloutProps={calloutProps}>
                                <Image aria-describedby={tooltipId} src={InfoIcon} alt="Info" />
                            </TooltipHost>
                        </div>
                        <TextField
                            ariaLabel='Enter css case number'
                            value={cssCaseNum}
                            onChange={(e: any) => {
                                setCSSCaseNum(e.target.value);
                                setCaseNumHText(false);
                            }}
                            className={` regular-text-field ${caseNumHText ? 'error-feild' : ''}`}
                            placeholder="eg: 120072024000000"
                            data-testid="optional-css-field-test"
                        />
                    </div>
                </div>
            )}

            <div className="row-flex-box align-end">
                <Link
                    tabIndex={0}
                    onClick={() => {
                        setRequestSection('newCustomer');
                        setManualData(true);
                        setRevenueHText(false);
                        setImpDevicesHText(false);
                    }}
                    className="new-customer-link"
                    data-testid="cant-find-Customer">
                    <Trans>I don't have an MSX Opportunity</Trans>
                </Link>
            </div>
        </div>
    );
};

export default Customer;
