export const iconSet = {
    undo: '<i class="ms-Icon ms-Icon--Undo"/>',
    text: '<i class="ms-Icon ms-Icon--Undo"/>',
    redo: '<i class="ms-Icon ms-Icon--Redo"></i>',
    italic: '<i class="ms-Icon ms-Icon--Italic"></i>',
    bold: '<i class="ms-Icon ms-Icon--Bold"></i>',
    underline: '<i class="ms-Icon ms-Icon--Underline"></i>',
    subscript: '<i class="ms-Icon ms-Icon--Subscript"></i>',
    superscript: '<i class="ms-Icon ms-Icon--Superscript"></i>',
    strike: '<i class="ms-Icon ms-Icon--Strikethrough"></i>',
    font_color: '<i class="ms-Icon ms-Icon--FontColor"></i>',
    highlight_color: '<i class="ms-Icon ms-Icon--Highlight"></i>',
    link: '<i class="ms-Icon ms-Icon--Link"></i>',
    indent: '<i class="ms-Icon ms-Icon--DecreaseIndentLegacy"></i>',
    outdent: '<i class="ms-Icon ms-Icon--IncreaseIndentLegacy"></i>',
    align_left: '<i class="ms-Icon ms-Icon--AlignLeft"></i>',
    align_center: '<i class="ms-Icon ms-Icon--AlignCenter"></i>',
    align_right: '<i class="ms-Icon ms-Icon--AlignRight"></i>',
    align_justify: '<i class="ms-Icon ms-Icon--AlignJustify"></i>',
    horizontal_rule: '<i class="ms-Icon ms-Icon--CalculatorSubtract"></i>',
    list_number: '<i class="ms-Icon ms-Icon--NumberedList"></i>',
    list_bullets: '<i class="ms-Icon ms-Icon--BulletedList"></i>',
    line_height: '<i class="ms-Icon ms-Icon--LineSpacing"></i>',
    table: '<i class="ms-Icon ms-Icon--Table"></i>',
    insert_row_above: '<i class="ms-Icon ms-Icon--InsertRowsAbove"></i>',
    insert_row_below: '<i class="ms-Icon ms-Icon--InsertRowsBelow"></i>',
    delete_row: '<i class="ms-Icon ms-Icon--DeleteRows"></i>',
    merge_cell: '<i class="ms-Icon ms-Icon--Merge"></i>',
    insert_column_left: '<i class="ms-Icon ms-Icon--InsertColumnsLeft"></i>',
    insert_column_right: '<i class="ms-Icon ms-Icon--InsertColumnsRight"></i>',
    delete_column: '<i class="ms-Icon ms-Icon--DeleteColumns"></i>',
    split_cell: '<i class="ms-Icon ms-Icon--Split"></i>',
    table_header: '<i class="ms-Icon ms-Icon--Header"></i>',
    delete: '<i class="ms-Icon ms-Icon--Delete"></i>',
    reduction: '<i class="ms-Icon ms-Icon--MinimumValue"></i>',
    expansion: '<i class="ms-Icon ms-Icon--MaximumValue"></i>',
    image: '<i class="ms-Icon ms-Icon--Photo2Add"></i>',
    video: '<i class="ms-Icon ms-Icon--MediaAdd"></i>',
    audio: '<i class="ms-Icon ms-Icon--Media"></i>',
    image_gallery: '<i class="ms-Icon ms-Icon--ImageDiff"></i>',
    show_blocks: '<i class="ms-Icon ms-Icon--LargeGrid"></i>',
    more_vertical: '<i class="ms-Icon ms-Icon--MoreVertical"></i>',
    more_horizontal: '<i class="ms-Icon ms-Icon--More"></i>',
    more_plus: `<span><i class="ms-Icon ms-Icon--PhotoVideoMedia"></i>${' '}<i class="ms-Icon ms-Icon--ChevronDown"></i></span>`,
    more_plain_text: '<span><i class="ms-Icon ms-Icon--FontColorA"></i><i class="ms-Icon ms-Icon--ChevronDown"></i></span>',
    code_view: '<i class="ms-Icon ms-Icon--CodeEdit"></i>'
};

export const buttonList = {
    responsive: [
        // default
        ['undo', 'redo'],
        ['formatBlock', 'fontSize'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['image', 'link'],
        ['table', 'imageGallery'],
        ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView'],
        // (min-width: 992)
        [
            '%992', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                ['bold', 'underline', 'italic', 'strike'],
                [':t-More Text-default.more_plain_text', 'subscript', 'superscript', 'fontColor', 'hiliteColor'],

                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight', 'table', 'link'],
                ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView'],
                [':r-Media-default.more_plus', 'image', 'imageGallery']
            ]
        ],
        // (min-width: 767)
        [
            '%767', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                [
                    ':t-More Text-default.more_plain_text',
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                    'fontColor',
                    'hiliteColor'
                ],

                ['outdent', 'indent', 'table', 'link'],
                [':e-Line-default.more_horizontal', 'align', 'horizontalRule', 'list', 'lineHeight'],
                [':r-Media-default.more_plus', 'image', 'imageGallery'],
                ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView']
            ]
        ],
        // (min-width: 480)
        [
            '%480', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                [
                    ':t-More Text-default.more_plain_text',
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                    'fontColor',
                    'hiliteColor',
                    'removeFormat'
                ],
                ['table', 'link', 'horizontalRule', 'list'],
                [':e-Line-default.more_horizontal', 'outdent', 'indent', 'align', 'lineHeight'],
                [':r-Media-default.more_plus', 'image', 'imageGallery'],
                ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView']
            ]
        ]
    ],
    default: [
        ['undo', 'redo'],
        ['formatBlock', 'fontSize'],
        ['bold', 'italic', 'underline', 'subscript', 'superscript', 'strike', 'fontColor', 'hiliteColor', 'link'],
        ['align', 'indent', 'outdent', 'lineHeight'],
        ['blockquote', 'list', 'image', 'imageGallery', 'table', 'codeView', 'horizontalRule'],
        ['preview']
    ]
};

export const setImageOptions = {
    imageResizing: true,
    imageWidth: '100%',
    imageRotation: true
};