import UnsubscribeView from 'app/modules/articles/viewArticle/unsubscribe/UnsubscribeView';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getQueryParam } from 'app/components/helpers';

interface IParams {
    entityType: string;
    eventType: string;
}

const Unsubscribe: React.FC = () => {
    const history = useHistory();
    const params = getQueryParam(history) as unknown as IParams;
    const { entityType, eventType } = params;

    return (
        <>
            <UnsubscribeView eventType={eventType} entityType={entityType} />
        </>
    );
};

export default Unsubscribe;
