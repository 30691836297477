import { Dropdown, Icon, IDropdownOption, IDropdownProps, Image } from '@fluentui/react';
import { IMenuOption } from 'app/models/common/post';
import { BlobStorageHelper } from 'app/utils/constants';
import { getArticleCategorySvg } from 'app/utils/SVGIcons';
import React from 'react';
import './MoreOptions.scss';
interface IProps {
    class?: string;
    pos?: string;
    menuArray?: Array<IMenuOption>;
    onRenderPlaceholderValue?: Function;
    fuIconName?: string;
    placeholderText?: string;
}

const MoreOptions: React.FC<IProps> = (props: IProps) => {
    const { menuArray, class: className, fuIconName, placeholderText } = props;

    const options: IDropdownOption[] = menuArray?.map((menu: IMenuOption) => ({
        key: menu?.title,
        text: menu?.title,
        data: {
            iconClass: menu?.iconClass,
            icon: menu?.icon.includes(BlobStorageHelper.STORAGE_BASE_URL) ? getArticleCategorySvg(menu?.icon) : menu.icon,
            articleId: menu?.id
        }
    }));
    const onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <>
                {option.data && option.data.iconClass && (
                    <Icon className={`icon ${option.data?.iconClass}`} aria-hidden="true" title={option.data?.icon} />
                )}
                {option.data && option.data?.icon && <Image className="icon-img" src={option.data?.icon} alt={option.text} />}
                <span>
                    {'  '}
                    {option.text}
                </span>
            </>
        );
    };

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const selectedAction: IMenuOption = menuArray.find((menu: IMenuOption) => menu.title === item.text);
        selectedAction.handleAction(item?.data?.articleId);
    };

    const onRenderPlaceholder = (props: IDropdownProps): JSX.Element => {
        return (
            <div className="dropdown-placeholder">
                <Icon iconName={fuIconName} aria-hidden="true" />
                <span>{props.placeholder}</span>
            </div>
        );
    };

    const onRenderTitle = (): JSX.Element => {
        return (
            <div className="dropdown-placeholder">
                <Icon iconName={fuIconName} aria-hidden="true" />
                <span>{placeholderText}</span>
            </div>
        );
    };

    return (
        <Dropdown
            onRenderTitle={onRenderTitle}
            onRenderOption={onRenderOption}
            placeholder={placeholderText}
            onRenderPlaceholder={onRenderPlaceholder}
            options={options}
            onChange={onChange}
            className={className}
            id="moreOptions"
            selectedKey={''}
            ariaLabel="more options"
        />
    );
};

export default MoreOptions;
