import { Link } from '@fluentui/react';
import CommentField from 'app/components/commentField/CommentField';
import CommentsPanel from 'app/components/commentsPanel/CommentsPanel';
import CommentTypeSelector from 'app/components/commentTypeSelector/CommentTypeSelector';
import { generateDataForComments } from 'app/components/helpers';
import { ICommentCriteria } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import { useFetchComments } from 'app/services/queries';
import React, { useEffect, useState } from 'react';
import './CommentSection.scss';

interface IProps {
    correlationId?: string;
    isMultipleType?: boolean;
    defaultIsInternal?: boolean;
    noExternalSection?: boolean;
    selectedRequest?: IRequest;
    isClosed?: boolean;
}

const pageLength = 100;

const CommentSection: React.FC<IProps> = ({
    correlationId,
    isMultipleType,
    defaultIsInternal,
    noExternalSection,
    selectedRequest,
    isClosed = false
}) => {
    // state variables
    const [criteria, setCriteria] = useState<ICommentCriteria>({});
    const [pageNumber] = useState(1);
    const [commentList, setCommentList] = useState([]);
    const [totalComments, setTotalComments] = useState(0);
    const [isInternal, setIsInternal] = useState(defaultIsInternal);
    const [pageSize, setPageSize] = useState(pageLength);

    // queries
    const { data: commentsData, isLoading: isLoadingComments } = useFetchComments(criteria);

    // helper functions
    const setInitialFilter = () => {
        let filterData: ICommentCriteria = {
            correlationId: correlationId,
            pageNumber: pageNumber,
            pageSize: pageSize,
            entityType: 1,
            isInternal: false
        };
        setCriteria(filterData);
    };

    const handleShowMore = () => {
        setCriteria((value: any) => ({ ...value, ...{ pageSize: pageLength + pageSize } }));
        setPageSize((size) => size + pageLength);
    };

    // hooks
    useEffect(() => {
        setInitialFilter();
    }, []);

    useEffect(() => {
        let filterData: ICommentCriteria = {
            correlationId: correlationId,
            pageNumber: pageNumber,
            pageSize: pageLength,
            entityType: 1,
            isInternal: isInternal
        };
        setCriteria(filterData);
    }, [correlationId, isInternal]);

    useEffect(() => {
        if (!isLoadingComments && commentsData !== '') {
            const replaceStorageUrls = async () => {
                await generateDataForComments(commentsData ? commentsData.items : [])
                .then((res) => {
                    setCommentList(res);
                    setTotalComments(commentsData ? commentsData.totalCount : 0);
                });
            }

            replaceStorageUrls();
        }
    }, [commentsData]);

    return (
        <>
            {isMultipleType && (
                <div>
                    <CommentTypeSelector
                        isInternal={isInternal}
                        setIsInternal={setIsInternal}
                        noExternalSection={noExternalSection}
                        type={'Comments'}
                    />
                </div>
            )}
            <div
                className={`cmt-container-wrapper ${
                    isMultipleType ? (isInternal ? 'internal-highlight' : 'external-highlight') : ''
                }`}>
                {!isClosed && (
                    <div className="m-b-20">
                        <CommentField
                            correlationId={correlationId}
                            entityType={1}
                            textareaSize={3}
                            isInternal={isInternal}
                            fieldType="comment"
                            selectedRequest={selectedRequest}
                        />
                    </div>
                )}
                {
                    <CommentsPanel
                        correlationId={correlationId}
                        data={commentList}
                        isMultipleType={isMultipleType}
                        totalCount={totalComments}
                        isLoading={isLoadingComments}
                        isInternal={isInternal}
                        isClosed={isClosed}
                    />
                }
                {commentsData && commentsData.hasNextPage && (
                    <Link onClick={handleShowMore} className="show-more-btn">
                        Show more
                    </Link>
                )}
            </div>
        </>
    );
};

export default CommentSection;
