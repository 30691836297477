import React, { useEffect, useState } from 'react';
import Button from '../../../../components/button/Button';
import { useSendEmail } from 'app/services/mutations';
import { IEmail } from 'app/models/common/post';
import { useHistory } from 'react-router-dom';
import { Modal, Text, IBreadcrumbItem, Breadcrumb } from '@fluentui/react';
import { Loader } from 'app/components/loader/Loader';
import { SVGIcons } from 'app/utils/SVGIcons';
import { RouteConstants, StringConstants } from 'app/utils/constants';

interface IProps {
    to: Array<string>;
    subject: string;
    content: string;
    bcc?: Array<string>;
    cc?: Array<string>;
    reset: Function;
    isPreview: boolean;
    setIsPreview: (preview: boolean) => void;
}

const EmailHeader: React.FC<IProps> = (props: IProps) => {
    const { to, subject, content, bcc, cc, reset, isPreview = false, setIsPreview } = props;
    const history = useHistory();
    const { isLoading, mutateAsync: send } = useSendEmail();
    const [showModal, setModalStatus] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendMail = () => {
        if (subject.trim().length > 0 && content.trim().replace('<p><br></p>', '').length > 0 && to.length > 0 && !isLoading) {
            send({ to, subject, content, bcc, cc } as IEmail)
                .then(() => {
                    setSuccess(true);
                })
                .catch(() => {
                    setError(true);
                    setModalStatus(true);
                });
        }
    };

    const cancel = () => {
        if (content.trim().replace('<p><br></p>', '').length > 0) {
            setShowAlertModal(true);
        } else {
            discardMail();
        }
    };
    const discardMail = () => {
        reset();
        history.push(RouteConstants.MANAGE_CONTENT_EMAILS);
    };
    const renderWarning = () => {
        return (
            <>
                <div className="delete-confirmation p-b-30 p-t-30">
                    <i className="ms-Icon ms-Icon--WarningSolid jc-center d-flex m-t-40 fs--40" aria-hidden="true"></i>
                    <Text className="jc-center d-flex fs--18 m-t-25">{`Failed to send the email, please try again.`}</Text>
                </div>
            </>
        );
    };

    const hideModal = () => {
        setModalStatus(false);
        setError(false);
        setSuccess(false);
    };
    const editEmail = () => {
        setIsPreview(false);
    };
    const preview = () => {
        setIsPreview(true);
    };
    const hideAlertModal = () => {
        setShowAlertModal(false);
    };
    useEffect(() => {
        if (success) discardMail();
    }, [success]);

    const itemsWithHref: IBreadcrumbItem[] = [
        {
            text: 'Emails',
            key: 'Emails',
            onClick: () => history.push(RouteConstants.MANAGE_CONTENT_EMAILS)
        },
        { text: StringConstants.CREATE_EMAIL, key: StringConstants.CREATE_EMAIL, isCurrentItem: true }
    ];

    return (
        <>
            <div className="container">
                <Breadcrumb items={itemsWithHref} maxDisplayedItems={4} overflowAriaLabel="More links" />
            </div>
            <div className="sub-header p-t-10 p-b-0">
                <div className="container">
                    <div className="d-flex--between panel-separator">
                        <div className="d-flex">
                            <h3 className="m-a-0">{StringConstants.CREATE_EMAIL}</h3>
                        </div>
                        <div className="d-flex--justEnd ai-center internal-nav">
                            <Button
                                buttonText={`Cancel`}
                                buttonClass="btn btn--secondary d-flex justify-content-center vertical-align width-5 "
                                onClick={cancel}
                            />
                            <div className="btn-group btn-group--icons m-r-20 m-l-20 h-max-content">
                                <Button
                                    buttonText=""
                                    buttonClass={`btn d-flex justify-content-center vertical-align ${
                                        isPreview ? '' : 'btn--primary'
                                    }`}
                                    onClick={editEmail}
                                    data-testid="edit-button"
                                    aria-label="edit button"
                                    title="edit button"
                                    iconSVG={SVGIcons.ICON_EDIT}
                                />
                                <Button
                                    buttonText=""
                                    buttonClass={`btn d-flex justify-content-center vertical-align ${
                                        !isPreview ? '' : 'btn--primary'
                                    }`}
                                    onClick={preview}
                                    data-testid="preview-button"
                                    aria-label="preview button"
                                    title="preview button"
                                    iconSVG={SVGIcons.ICON_EYE}
                                />
                            </div>
                            <Button
                                buttonText={!isLoading ? `Send` : 'Sending...'}
                                buttonClass="btn btn--primary d-flex justify-content-center vertical-align width-5 "
                                onClick={sendMail}
                                disabled={
                                    isLoading &&
                                    !(
                                        subject.trim().length > 0 &&
                                        content.trim().replace('<p><br></p>', '').length > 0 &&
                                        to.length > 0
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={showModal} onDismiss={hideModal} isBlocking={false} className={`surface-modal primary`}>
                <div className={`modal-body p-b-20 ${isLoading ? 'd-flex--center ai-center p-t-20' : ''}`}>
                    {isLoading && <Loader />}
                    {error && renderWarning()}
                </div>
            </Modal>
            <Modal isOpen={showAlertModal} onDismiss={hideAlertModal} isBlocking={false} className={`surface-modal primary`}>
                <div className={`modal-body p-b-20 d-flex--center ai-center p-t-20`}>
                    <div className="delete-confirmation">
                        <i className="ms-Icon ms-Icon--WarningSolid jc-center d-flex m-t-40 fs--40" aria-hidden="true"></i>
                        <Text className="jc-center d-flex fs--18 m-t-25">{`Your changes will be lost. Are you sure you want to continue?`}</Text>
                    </div>
                </div>
                <div className="modal-footer d-flex ai-center">
                    <Button buttonText="Continue" buttonClass="btn btn--secondary" onClick={() => discardMail()} />
                    <Button buttonText="Cancel" buttonClass="btn btn--primary" onClick={() => hideAlertModal()} />
                </div>
            </Modal>
        </>
    );
};

export default EmailHeader;
