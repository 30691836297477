import { Text } from '@fluentui/react';
import React from 'react';
import moment from 'moment';
import useStore from 'app/store';
import { Trans } from 'react-i18next';
import { getIcon, highlightTags } from 'app/components/helpers';
import { StringConstants } from 'app/utils/constants';

interface IPreviewProps {
    prevData: any;
}

const RequestPreview: React.FC<IPreviewProps> = (props) => {
    const { prevData } = props;
    const attachedFiles = useStore((state: any) => state.attachedFiles);
    const addComaSeparators = (data: string) => {
        let val = data?.toString();
        let value = '';
        let decValue = '';
        if (val?.includes('.')) {
            value = val.split('.')[0];
            decValue = val.split('.')[1];
        } else {
            value = val;
        }

        if (value?.includes(',')) {
            if (decValue !== '') return value + '.' + decValue;
            else return value;
        } else {
            const temp = value?.split('$');
            const numValue = temp[temp?.length - 1];
            const revNum = numValue?.split('').reverse().join('');

            const commaAddedNum = revNum?.match(/.{1,3}/g).join(',');

            if (value?.startsWith('$')) {
                if (decValue !== '') return '$' + commaAddedNum?.split('').reverse().join('') + '.' + decValue;
                else return '$' + commaAddedNum?.split('').reverse().join('');
            } else {
                if (decValue !== '') return commaAddedNum?.split('').reverse().join('') + '.' + decValue;
                else return commaAddedNum?.split('').reverse().join('');
            }
        }
    };

    const getAttestedData = (data?: string, msxValue?: string) => {
        if (data && data.toString().trim() !== '' && data.toString().trim() !== '$' && data.toString().trim().replace('$','') !== msxValue.toString()) {
            return addComaSeparators(data);
        } else {
            return '--';
        }
    };

    const getData = (data?: string) => {
        if (data && data.toString().trim() !== '' && data.toString().trim() !== '$') {
            return addComaSeparators(data);
        } else {
            return '--';
        }
    };

    return (
        <div className="row-flex">
            <div className="col-flex">
                <div className="cont bound-right">
                    <Text className="heading ">
                        <Trans>Request Details</Trans>
                    </Text>
                    <Text className="txt-bold title">{prevData?.title}</Text>
                    <span dangerouslySetInnerHTML={{ __html: highlightTags(prevData?.desc) }} />
                    {prevData?.type === StringConstants.POST_SALES && (
                        <div>
                            <div>
                                <Text className=" date txt-black space">
                                    <Trans>Discretionary Commercial Fund (DCF):</Trans>{' '}
                                </Text>
                                <Text>{prevData?.selectedDCFKey === 'A' ? StringConstants.YES : 'No'}</Text>
                            </div>

                            {prevData?.selectedDCFKey === 'A' && (
                                <div className="txt-black space">
                                    <Text className="date txt-black space files-txt">
                                        <Trans>Fund Utilization:</Trans>{' '}
                                    </Text>
                                    <Text>{prevData?.fundsDescription}</Text>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="type-date">
                        <span className="c-flex col-flex-box">
                            <Text className="heading  mar-top">
                                <Trans>Requested resolution date</Trans>
                            </Text>
                            <Text className=""> {moment(prevData?.dueDate).format('dddd, MMMM DD, YYYY')}</Text>
                        </span>
                        <span className="txt-black space files-txt col-flex-box">
                            <Text className=" heading mar-top">
                                <Trans>Why is this date important?</Trans>
                            </Text>
                            <span className="" dangerouslySetInnerHTML={{ __html: highlightTags(prevData?.dateImportance) }} />
                        </span>
                        {prevData?.type === 'CSSRequest' && (
                            <span className="c-flex">
                                <Text className="date mar-top">
                                    <Trans>Issue Category</Trans>
                                    {' : '}{' '}
                                </Text>
                                <Text className="mar-top p-l-5"> {prevData?.selectedIssue?.text}</Text>
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-flex boud-left-container">
                <div className="cont bound-left">
                    <Text className="heading">
                        <Trans>Customer Info</Trans>
                    </Text>
                    {prevData?.manualData ? (
                        <Text className="txt-black">
                            {prevData?.custName}
                        </Text>
                    ) : (
                        <Text className="txt-black">
                            {prevData?.filteredData[0]?.OpportunityNumber +
                                (prevData?.filteredData[0]?.AccountName && prevData?.filteredData[0]?.AccountName?.trim() !== ''
                                    ? ' - ' + prevData?.filteredData[0]?.AccountName
                                    : '') +
                                (prevData?.filteredData[0]?.Region && prevData?.filteredData[0]?.Region?.trim() !== ''
                                    ? ' - ' + prevData?.filteredData[0]?.Region
                                    : '') +
                                (prevData?.filteredData[0]?.Country && prevData?.filteredData[0]?.Country?.trim() !== ''
                                    ? ' - ' + prevData?.filteredData[0]?.Country
                                    : '')}
                        </Text>
                    )}

                    <div className="column-flex sales-opportunity-risk m-t-25">
                        <div className="column-flex">
                            <Text className="heading">Seller Attested Revenue Risk</Text>
                            {prevData?.manualData ? (
                                <Text className="text">
                                    { getData(
                                        prevData?.newRevenueRisk?.startsWith('$')
                                            ? prevData?.newRevenueRisk
                                            : '$' + prevData?.newRevenueRisk
                                    )}
                                </Text>
                            ) : (
                                <Text className="text">
                                    {getAttestedData(
                                        prevData?.revenueRisk?.startsWith('$')
                                            ? prevData?.revenueRisk
                                            : '$' + prevData?.revenueRisk
                                    , prevData?.filteredData[0]?.EstimatedValue)}
                                </Text>
                            )}
                        </div>

                        <div className="column-flex m-t-25">
                            <Text className="heading">Seller Attested Device Risk </Text>
                            {prevData?.manualData ? (
                                <Text className="text">{getData(prevData?.newDeviceRisk)}</Text>
                            ) : (
                                <Text className="text">{getAttestedData(prevData?.impDevices, prevData?.filteredData[0]?.TotalDeviceCount)}</Text>
                            )}
                        </div>
                    </div>
                    {!prevData?.manualData && (
                        <div className="column-flex sales-opportunity-risk m-t-25">
                            <div className="column-flex">
                                <Text className="heading">MSX revenue risk</Text>

                                {prevData?.filteredData[0]?.EstimatedValue && (
                                    <Text className="text">{getData('$' + Math.round(prevData?.filteredData[0]?.EstimatedValue))}</Text>
                                )}
                            </div>

                            <div className="column-flex m-t-25">
                                <Text className="heading">MSX device risk </Text>

                                {prevData?.filteredData[0]?.TotalDeviceCount && (
                                    <Text className="text">{getData(prevData?.filteredData[0]?.TotalDeviceCount)}</Text>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="box-container ">
                        {(prevData?.type === StringConstants.REVIEW_REQUEST || prevData?.type === StringConstants.POST_SALES) && prevData?.cssCaseNum !== '' && (
                            <span className="d-inline m-b-20">
                                <Text className="txt-black preview-case-num space-case">
                                    <Trans>CSS Case Number</Trans> :{' '}
                                </Text>
                                <Text>{prevData?.cssCaseNum}</Text>
                            </span>
                        )}

                        {(prevData?.type === StringConstants.POST_SALES || prevData?.type === StringConstants.PRE_SALES) && (
                            <div>
                                {prevData?.type === StringConstants.POST_SALES && (
                                    <div>
                                        <Text className="heading">
                                            <Trans>Shipping Details</Trans>
                                        </Text>
                                        <div className="contact-name">{prevData?.contactName}</div>
                                        <span className="r-flex">
                                            <span className="mail-icon"></span>
                                            <div className="txt-black text-container">
                                                <Text className="txt-black">{prevData?.custEmail}</Text>
                                            </div>
                                        </span>

                                        <span className="r-flex">
                                            <span className="address-icon"></span>
                                            <div className="txt-black text-container">
                                                <Text>{prevData?.custShippingAddress}</Text>
                                            </div>
                                        </span>

                                        <span className="r-flex">
                                            <span className="phone-icon"></span>
                                            <div className="txt-black text-container">
                                                <Text className="txt-black">
                                                    +{prevData?.contactNumPrefix} {prevData?.contactNum}
                                                </Text>
                                            </div>
                                        </span>
                                    </div>
                                )}
                                <div>
                                    {(prevData?.emailID !== '' ||
                                        prevData?.shippingAdd !== '' ||
                                        prevData?.mobileNumber !== '') && (
                                        <Text className="heading">
                                            <Trans>Alternate Contact Details</Trans>
                                        </Text>
                                    )}
                                    {prevData?.emailID !== '' && (
                                        <span className="r-flex">
                                            <span className="mail-icon"></span>
                                            <Text className="txt-black">{prevData?.emailID}</Text>
                                        </span>
                                    )}
                                    {prevData?.shippingAdd !== '' && (
                                        <span className="r-flex">
                                            <span className="address-icon"></span>
                                            <Text className="txt-black">{prevData?.shippingAdd}</Text>
                                        </span>
                                    )}
                                    {prevData?.mobileNumber !== '' && (
                                        <span className="r-flex">
                                            <span className="phone-icon"></span>
                                            <Text className="txt-black">
                                                +{prevData?.mobilePrefix} {prevData?.mobileNumber}
                                            </Text>
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {attachedFiles.length !== 0 && (
                    <div className="box-container cont">
                        <Text className="heading">
                            <Trans>Attachments</Trans>
                        </Text>
                        {attachedFiles.map((item: any) => {
                            return (
                                <div className="attached-file">
                                    <span className={getIcon(item?.name)}></span>
                                    <Text className="txt-black files-txt">{item.name}</Text>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default RequestPreview;
