import React, { useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import './FeedbackResponseView.scss';

import NewFeedback from './newFeedback/NewFeedback';
import PastFeedback from './pastFeedback/PastFeedback';
import FeedbackResponseForm from './feedbackResponseForm/FeedbackResponseForm';
import { Trans, useTranslation } from 'react-i18next';
const FeedbackResponseView = () => {
    const { t: translate } = useTranslation();
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [responseData, setResponseData] = useState(null);

    const showResponseForm = (data: any) => {
        setShowFeedbackForm(true);
        setResponseData(data);
    };

    const showResponseView = () => {
        setShowFeedbackForm(false);
    };

    return (
        <div className="view-container">
            <div className="">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="view-wrapper">
                                <div>
                                    <Trans>Feedback Response</Trans>
                                </div>
                                <div className="pivot-container">
                                    <Pivot>
                                        <PivotItem itemID="new-feedback" headerText={translate('New Feedback')}>
                                            {!showFeedbackForm && (
                                                <div>
                                                    <NewFeedback showResponseForm={showResponseForm} />
                                                </div>
                                            )}
                                            {showFeedbackForm && (
                                                <div>
                                                    <FeedbackResponseForm
                                                        showResponseView={showResponseView}
                                                        responseData={responseData}
                                                    />
                                                </div>
                                            )}
                                        </PivotItem>
                                        <PivotItem itemID="past-feedback" headerText={translate('Past Feedback')}>
                                            <PastFeedback />
                                        </PivotItem>
                                    </Pivot>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackResponseView;
