import React, { useState, useRef, useEffect, useCallback } from 'react';
import ContentArea from './editor/ContentArea';
import useStore from 'app/store';

import './CreateArticle.scss';
import Sidebar from './sidebar/Sidebar';
import Button from 'app/components/button/Button';
import { blobToFile, fileUpload, keyPress, sanitizeTextForWordCount, separateVideosFromHTMLString } from 'app/components/helpers';
import { useGenerateContainerSasToken } from 'app/services/queries';
import { useLocation, useParams } from 'react-router-dom';
import { useGetArticle } from 'app/services/queries';
import { IArticleData, IBlobUploadParams, IMenuOption, IUserDetails } from 'app/models/common/post';
import { ArticleStatus } from 'app/enums/Status.enum';
import MoreOptions from 'app/components/moreOptions/MoreOptions';
import SubHeader from './subHeader';
import { IDropdownOption, Modal, ProgressIndicator, TooltipHost } from '@fluentui/react';
import AddHelpfulResources from './addHelpfulResources/AddHelpfulResources';
import { useTranslation } from 'react-i18next';
import { Loader } from 'app/components/loader/Loader';
import { ArticleType } from 'app/enums/ArticleType.enum';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
    IMAGE_ASPECT_RATIO,
    THUMBNAIL_ASPECT_RATIO,
    IMAGE_MIN_HEIGHT,
    IMAGE_MIN_WIDTH,
    THUMBNAIL_MIN_WIDTH,
    StringConstants,
    THUMBNAIL_MIN_HEIGHT,
    BlobStorageContainer,
    BlobStorageSubfolder,
    Entity
} from 'app/utils/constants';
import { useId } from '@fluentui/react-hooks';
import { isSuperAdmin } from 'app/utils/authUtilities';
import { addBlobFileSasToken, addSasTokenToResponse } from 'app/utils/blobStorageHelpers';

interface IParams {
    id: string;
}

const articleTypeOptions: IDropdownOption[] = [
    { key: '1', text: 'Knowledge Article' },
    { key: '2', text: 'News Article' },
    { key: '3', text: 'Training' },
    { key: '4', text: 'Training (Admin Only)' }
];

let filteredArticleTypeOptions: IDropdownOption[] = [];

const Words_Per_Minute = 250;
const CreateArticle: React.FC = () => {
    const { t: translate } = useTranslation();
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const location: { state: { template?: any; action?: string } } = useLocation();
    const [currentSelection, setCurrentSelection] = useState<'banner' | 'thumbnail'>(null);
    const [aspectRatio, minWidth, x, y] = [
        currentSelection === 'banner' ? IMAGE_ASPECT_RATIO : THUMBNAIL_ASPECT_RATIO,
        currentSelection === 'banner' ? IMAGE_MIN_WIDTH : THUMBNAIL_MIN_WIDTH,
        0,
        0
    ];
    const uploadImage = (type: 'banner' | 'thumbnail') => {
        setCurrentSelection(type);
        fileUploader.current.click();
    };

    //Filters options to Remove Admin Options if user is not Admin
    useEffect(() => {
       if(!isSuperAdmin(userDetails.userRoles)){
        filteredArticleTypeOptions = articleTypeOptions.filter(item => item.text !== 'Training (Admin Only)')
       }
       else {
        filteredArticleTypeOptions = articleTypeOptions
       }
    }, []);

    useEffect(() => {
        setCrop({ aspect: aspectRatio, width: minWidth, x: x, y: y });
    }, [currentSelection]);
    const { id: editArticleId } = useParams<IParams>();
    const [title, setTitle] = useState(location?.state?.action ? location?.state?.template?.title : '');
    const [content, setContent] = useState(location?.state?.template?.content || '');
    const [tokenizedContent, setTokenizedContent] = useState('');
    const [lastEditDate, setLastEditDate] = useState(new Date());
    const [author, setAuthor] = useState<IUserDetails>(useStore((state: any) => state.userDetails));
    const [hideInfo, setHideInfo] = useState(location?.state?.action ? location?.state?.template?.shouldHideAuthorInfo : false);
    const [isPreview, setIsPreview] = useState(false);
    const [banner, setBanner] = useState(location?.state?.template?.coverPhoto || null);
    const [tokenizedBanner, setTokenizedBanner] = useState('');
    const [thumbnail, setThumbnail] = useState(location?.state?.template?.thumbnail || null);
    const [tokenizedThumbnail, setTokenizedThumbnail] = useState('');
    const [tags, setTags] = useState(location?.state?.action ? location?.state?.template?.tags : []);
    const [helpfulResources, setHelpfulResources] = useState(
        location?.state?.action ? location?.state?.template?.helpfulResources : []
    );
    const [showMenu, setShowMenu] = useState(false);
    const fileUploader = useRef(null);
    const { data: token, isFetched: isTokenFetched } = useGenerateContainerSasToken({entity: StringConstants.ARTICLE, containerName: BlobStorageContainer.ARTICLE_FILES});
    const [articleState, setArticleState] = useState<ArticleStatus>();
    const [reviewNotes, setReviewNotes] = useState('');
    const [type, setType] = useState(location?.state?.action ? location?.state?.template?.type : 1);
    const [percentComplete, setPercentComplete] = React.useState(0);
    const [showModal, setModalStatus] = useState(false);
    const [showCropModal, setCropModalStatus] = useState(false);
    const [selectedType, setSelectedType] = useState<IDropdownOption>(
        location?.state?.action ? filteredArticleTypeOptions[location?.state?.template?.type - 1] : filteredArticleTypeOptions[0]
    );
    const prevType = useRef<IDropdownOption>(filteredArticleTypeOptions[0]);
    const [image, setImage] = useState<File>();
    const imgRef = useRef(null);
    const [upImg, setUpImg] = useState<string>();
    const [crop, setCrop] = useState<Partial<Crop>>({ aspect: aspectRatio, width: minWidth, x: x, y: y });
    const readingTime = require('reading-time');
    const stats = readingTime(sanitizeTextForWordCount(content), { wordsPerMinute: Words_Per_Minute });
    const [imageUploadErrorModal, setImageUploadErrorModal] = useState(false);
    const cropInfo = useId('cropInfo');
    const handleFileChange = async (type: 'banner' | 'thumbnail') => {
        if (
            (crop.width < IMAGE_MIN_WIDTH && currentSelection === 'banner') ||
            (crop.width < THUMBNAIL_MIN_WIDTH && currentSelection === 'thumbnail')
        ) {
            setImageUploadErrorModal(true);
        } else {
            const blob = await getCroppedImg(imgRef.current, crop);
            setPercentComplete(0);

            const blobName = StringConstants.NEW_BLOB + new Date().getTime();
            if (isTokenFetched) {
                const blobUploadParams: IBlobUploadParams = {
                    file: blobToFile(blob as Blob, image.name),
                    blobName: blobName,
                    container: BlobStorageContainer.ARTICLE_FILES,
                    subfolder: BlobStorageSubfolder.NONE,
                    token: token
                };

                const response = await fileUpload(blobUploadParams, handleFileUploadProgress);
                if (type === 'banner') {
                    addSasTokenToResponse(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, response);
                    setBanner(response._response.request.url.split(/[?#]/)[0]);
                }
                if (type === 'thumbnail') {
                    addSasTokenToResponse(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, response);
                    setThumbnail(response._response.request.url.split(/[?#]/)[0]);
                }
                cancelUploadBanner();
                setPercentComplete(0);
            }
        }
        fileUploader.current.value = null;
    };

    const handleFileUploadProgress = (loadedBytes: number, totalBytes: number) => {
        const percentageCompleted = Math.floor((100 * loadedBytes) / totalBytes);
        setPercentComplete(percentageCompleted / 100);
    };

    const hideImageUploadErrorModal = () => {
        setImageUploadErrorModal(false);
        fileUploader.current.value = null;
    };

    const removeImage = (type: 'banner' | 'thumbnail') => {
        setCurrentSelection(type);
        if (type === 'banner') setBanner('');
        if (type === 'thumbnail') setThumbnail('');
        setShowMenu(!showMenu);
    };

    const replaceImage = (type: 'banner' | 'thumbnail') => {
        setCurrentSelection(type);
        uploadImage(type);
        setShowMenu(!showMenu);
    };

    const onArticleTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setType(Number(item.key));
        prevType.current = selectedType;
        setSelectedType(item);
    };

    const addHelpfulResource = (newResource: any) => {
        if (helpfulResources.find((i: any) => i.url === newResource.url)) return;
        const resources = [...helpfulResources, newResource];
        setHelpfulResources(resources);
    };
    const removeHelpfulResource = (removeResource: any) => {
        setHelpfulResources(helpfulResources.filter((i: any) => i.url !== removeResource.url));
    };

    const { isLoading, data: articleInfo, isFetched, isSuccess } = useGetArticle(editArticleId);
    useEffect(() => {
        if (!isLoading && editArticleId && isSuccess) {
            const {
                content,
                coverPhoto,
                thumbnail,
                createdBy,
                createdOn,
                title,
                tags,
                status,
                reviewNotes,
                type,
                helpfulResources
            }: IArticleData = articleInfo;
            setTitle(title);
            setContent(content);
            setBanner(coverPhoto);
            setThumbnail(thumbnail);
            setTags(tags);
            setHelpfulResources(helpfulResources);
            setAuthor(createdBy);
            setLastEditDate(createdOn);
            setArticleState(status);
            setIsPreview(true);
            setReviewNotes(reviewNotes);
            setType(type);
            prevType.current = filteredArticleTypeOptions.find((o) => o.key === `${type}`);
            setSelectedType(filteredArticleTypeOptions.find((o) => o.key === `${type}`));
        }
    }, [isLoading, isFetched, articleInfo, editArticleId, isSuccess]);

    let newsCardMenuOption1: IMenuOption = {
        title: 'Reupload',
        handleAction: () => replaceImage('banner'),
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--CloudUpload'
    };
    let newsCardMenuOption2: IMenuOption = {
        title: 'Remove',
        handleAction: () => removeImage('banner'),
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--Delete'
    };

    const bannerImageOptionArray = [newsCardMenuOption1, newsCardMenuOption2];

    let thumbnailImageMenuOption1: IMenuOption = {
        title: 'Reupload',
        handleAction: () => replaceImage('thumbnail'),
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--CloudUpload'
    };
    let thumbnailImageMenuOption2: IMenuOption = {
        title: 'Remove',
        handleAction: () => removeImage('thumbnail'),
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--Delete'
    };

    const thumbnailImageOptionArray = [thumbnailImageMenuOption1, thumbnailImageMenuOption2];

    useEffect(() => {
        if ((type === ArticleType.Training || type === ArticleType.Admin) && !editArticleId && separateVideosFromHTMLString(content).videos.length > 1) {
            setModalStatus(true);
        }
        if (type === ArticleType.Training || type === ArticleType.Admin) setBanner(null);
    }, [type]);

    const hideModal = () => {
        setModalStatus(false);
    };

    const cancelToChangeType = () => {
        setSelectedType(prevType.current);
        setType(Number(prevType.current.key));
        setModalStatus(false);
    };
    const changeArticleTypeToTraining = () => {
        setModalStatus(false);
        setContent('');
        setBanner(null);
    };
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    function getCroppedImg(image: any, crop: any) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    resolve(blob as Blob);
                },
                'image/jpeg',
                1
            );
        });
    }

    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                var img = new Image();
                img.onload = function () {
                    if (
                        (currentSelection === 'banner' && (img.width < IMAGE_MIN_WIDTH || img.height < IMAGE_MIN_HEIGHT)) ||
                        (currentSelection === 'thumbnail' &&
                            (img.width < THUMBNAIL_MIN_WIDTH || img.height < THUMBNAIL_MIN_HEIGHT))
                    ) {
                        setImageUploadErrorModal(true);
                        return;
                    }
                    setImage(e.target.files[0]);
                    setUpImg(reader.result as string);
                    setCrop({ ...crop, width: minWidth, x: x, y: y });
                    setCropModalStatus(true);
                };
                img.src = reader.result as string;
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const cancelUploadBanner = () => {
        setCropModalStatus(false);
        fileUploader.current.value = null;
    };

    useEffect(() => {
        const replaceStorageUrls = async () => {
            if (content && content !== '') {
                await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, content)
                .then((res) => {
                    if (res && res !== '') {
                        setTokenizedContent(res);
                    }
                });
            }
            if (banner && banner !== '') {
                await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, banner)
                .then((res) => {
                    if (res && res !== '') {
                        setTokenizedBanner(res);
                    }
                });
            }
            if (thumbnail && thumbnail !== '') {
                await addBlobFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, thumbnail)
                .then((res) => {
                    if (res && res !== '') {
                        setTokenizedThumbnail(res);
                    }
                });
            }
        }

        replaceStorageUrls();
    }, [content, banner, thumbnail]);

    if (!isLoading && location) {
        return (
            <>
                <SubHeader
                    editArticleId={editArticleId}
                    title={title}
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                    content={tokenizedContent}
                    hideInfo={hideInfo}
                    tags={tags}
                    coverPhoto={banner ? encodeURI(banner) : null}
                    thumbnail={thumbnail ? encodeURI(thumbnail) : null}
                    status={articleState}
                    reviewNotes={reviewNotes}
                    type={type}
                    helpfulResources={helpfulResources}
                    scheduledPublishOn={articleInfo?.scheduledPublishOn}
                    showAsBannerArticle={articleInfo?.showAsBannerArticle}
                    showOnHomePage={articleInfo?.showOnHomePage}
                />

                <div className="ms-Grid container" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="d-flex">
                            <div className="content-area ms-Grid-col ms-xl9 ms-lg12 ms-sm12 p-r-40 m-b-30">
                                {thumbnail && !isPreview && (
                                    <div className="banner thumbnail m-b-20">
                                        <span className="fw--600 d-flex p-b-10">Uploaded Thumbnail -</span>
                                        <img src={tokenizedThumbnail} alt="" />
                                        <MoreOptions
                                            pos="left top m-b-2"
                                            class="icon-overlay"
                                            menuArray={thumbnailImageOptionArray}
                                            fuIconName="MoreVertical"
                                        />
                                    </div>
                                )}
                                {banner && (
                                    <div className="banner m-b-20">
                                        {!isPreview && <span className="fw--600 d-flex p-b-10">Uploaded Banner -</span>}
                                        <img src={tokenizedBanner} alt="" />
                                        {!isPreview && (
                                            <MoreOptions
                                                pos="left top m-b-2"
                                                class="icon-overlay"
                                                menuArray={bannerImageOptionArray}
                                                fuIconName="MoreVertical"
                                            />
                                        )}
                                    </div>
                                )}

                                <div>
                                    {!thumbnail && (percentComplete === 0 || percentComplete === 1) && (
                                        <Button
                                            buttonClass="btn btn--transparent"
                                            buttonText={translate('Upload thumbnail image')}
                                            altText="upload thumbnail image"
                                            onClick={() => uploadImage('thumbnail')}
                                        />
                                    )}
                                    {!banner &&
                                        (type === ArticleType.KnowledgeArticle || type === ArticleType.News) &&
                                        (percentComplete === 0 || percentComplete === 1) && (
                                            <Button
                                                buttonClass="btn btn--transparent"
                                                buttonText={translate('Upload banner image')}
                                                altText="upload banner"
                                                onClick={() => uploadImage('banner')}
                                            />
                                        )}
                                </div>

                                <input
                                    name="image"
                                    type="file"
                                    id="file"
                                    ref={fileUploader}
                                    accept="image/*"
                                    onChange={onSelectFile}
                                    style={{ display: 'none' }}
                                />

                                {percentComplete > 0 && percentComplete < 1 && (
                                    <ProgressIndicator
                                        className="m-t-20 progressbar"
                                        label="Upload Progress"
                                        percentComplete={percentComplete}
                                        barHeight={5}
                                    />
                                )}
                                <ContentArea
                                    title={title}
                                    setTitle={setTitle}
                                    content={tokenizedContent}
                                    setContent={setContent}
                                    isPreview={isPreview}
                                    type={type}
                                    handleFileUploadProgress={handleFileUploadProgress}
                                />
                            </div>
                            <div className="sidebar ms-Grid-col ms-xl3 ms-lg12 ms-sm12">
                                <Sidebar
                                    lastEditDate={lastEditDate}
                                    author={author}
                                    stats={stats}
                                    hideInfo={hideInfo}
                                    setHideInfo={setHideInfo}
                                    tags={tags}
                                    setTags={setTags}
                                    selectedType={selectedType}
                                    onArticleTypeChange={onArticleTypeChange}
                                    dropdownOptions={filteredArticleTypeOptions}
                                    type={type}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {(type === ArticleType.KnowledgeArticle || type === ArticleType.News) && (
                    <AddHelpfulResources
                        helpfulResources={helpfulResources}
                        addHelpfulResource={addHelpfulResource}
                        removeHelpfulResource={removeHelpfulResource}
                        isPreview={isPreview}
                    />
                )}
                <Modal
                    isOpen={imageUploadErrorModal}
                    onDismiss={hideImageUploadErrorModal}
                    isBlocking={false}
                    className="surface-modal notification-modal">
                    <div className="modal-header">
                        <span></span>
                        <span
                            className="close"
                            tabIndex={0}
                            onKeyPress={(e: any) => keyPress(e, hideImageUploadErrorModal)}
                            onClick={hideImageUploadErrorModal}>
                            X
                        </span>
                    </div>
                    <div className="modal-body text-center">
                        <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                        <h4 className="m-t-30 m-b-5">
                            {currentSelection === 'banner'
                                ? StringConstants.MIN_BANNER_DIMENSIONS_ERROR
                                : StringConstants.MIN_THUMBNAIL_DIMENSIONS_ERROR}
                        </h4>
                    </div>
                    <div className="modal-footer">
                        <Button buttonClass="btn btn--primary" buttonText="OK" onClick={hideImageUploadErrorModal} />
                    </div>
                </Modal>
                <Modal
                    isOpen={showCropModal}
                    onDismiss={cancelUploadBanner}
                    isBlocking={true}
                    className="surface-modal image-crop-dialog">
                    <div className="modal-header">
                        <span className="d-flex ai-center">
                            {`Cropped Size: ${Math.round(crop.width)} x ${Math.round(crop.height)}`}
                            <span className="fs--16 m-l-15 color-gray--300 fw--normal float-right m-t-3">
                                <TooltipHost content={`Minimum width is ${minWidth} pixels`} id={cropInfo}>
                                    <i aria-describedby={cropInfo} className="ms-Icon ms-Icon--InfoSolid" aria-hidden="true"></i>
                                </TooltipHost>
                            </span>
                        </span>
                        <span className="close" tabIndex={0} onClick={cancelUploadBanner}>
                            X
                        </span>
                    </div>
                    <div className="modal-body p-b-20">
                        {upImg ? (
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                keepSelection={true}
                                minWidth={minWidth}
                            />
                        ) : (
                            <Loader classNames="m-t-50 m-b-50" />
                        )}
                    </div>
                    <div className="modal-footer d-flex ai-left">
                        <Button buttonText="Cancel" buttonClass="btn btn--sm" onClick={() => cancelUploadBanner()} />
                        {currentSelection === 'banner' && (
                            <Button
                                buttonText={`${percentComplete > 0 && percentComplete <= 1 ? 'Uploading...' : 'Upload'}`}
                                onClick={() => handleFileChange('banner')}
                                buttonClass="btn btn--primary btn--sm"
                                disabled={percentComplete > 0 && percentComplete <= 1}
                            />
                        )}
                        {currentSelection === 'thumbnail' && (
                            <Button
                                buttonText={`${percentComplete > 0 && percentComplete <= 1 ? 'Uploading...' : 'Upload'}`}
                                onClick={() => handleFileChange('thumbnail')}
                                buttonClass="btn btn--primary btn--sm"
                                disabled={percentComplete > 0 && percentComplete <= 1}
                            />
                        )}
                    </div>
                </Modal>

                <Modal isOpen={showModal} onDismiss={hideModal} isBlocking={true} className="surface-modal">
                    <div className="modal-body p-t-20 p-b-20">
                        <h4 className="m-t-0 m-b-5">Are you sure you want to change the article type to "Training"?</h4>
                        <p>
                            <strong>
                                Note: Changing the article type to "Training" will clear the content and support only 1 video.
                            </strong>
                        </p>
                    </div>
                    <div className="modal-footer d-flex ai-left">
                        <Button buttonText="OK" onClick={() => changeArticleTypeToTraining()} buttonClass="btn btn--secondary" />
                        <Button buttonText="Cancel" buttonClass="btn btn--primary" onClick={() => cancelToChangeType()} />
                    </div>
                </Modal>
            </>
        );
    } else {
        return <Loader classNames=" height-70" />;
    }
};

export default CreateArticle;
