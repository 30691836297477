import React, { useEffect } from 'react';
import ManageRequests from 'app/modules/admin/manageRequests/ManageRequests';
import '@progress/kendo-theme-material';
import useStore from 'app/store';
import { useHistory } from 'react-router';
const ManageRequestsPage: React.FC = () => {
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();
    useEffect(() => {
        setSearchOrigin(history?.location?.pathname);
    }, []);

    return (
        <>
            <ManageRequests />
        </>
    );
};

export default ManageRequestsPage;
