import React from 'react';
import Slider from 'react-slick';
import VideoSliderCard from '../../videoSliderCard/VideoSliderCard';

interface IProps {
    data: [];
    title?: String;
    openThisTraining: Function;
    activeTrainingId: string;
}

const TrainingVideosList: React.FC<IProps> = (props) => {
    const { data, title, openThisTraining, activeTrainingId } = props;

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: data?.length < 6 ? data.length : 6,
        slidesToScroll: 1,
        arrows: true,
        autoplaySpeed: 5000,
        autoplay: true,
        vertical: true,
        pauseOnFocus: true
    };

    return (
        <>
            {data && (
                <div className="other-training-videos">
                    <h4 className="title">{title}</h4>
                    <div className="training-videos-list">
                        <Slider {...settings} aria-hidden="true">
                            {data?.length > 0 &&
                                data?.map((article: any, index) => (
                                    <VideoSliderCard
                                        index={index}
                                        article={article}
                                        openThisTraining={openThisTraining}
                                        activeTrainingId={activeTrainingId}
                                    />
                                ))}
                        </Slider>
                    </div>
                </div>
            )}
        </>
    );
};

export default TrainingVideosList;
