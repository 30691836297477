import AppInsightsService from "app/services/AppInsights";
export class Constants {
    static readonly COLOR_MS_RED: string = '#F34F1C';
    static readonly COLOR_MS_GREEN: string = '#7FBC00';
    static readonly COLOR_MS_YELLOW: string = '#FFBA01';
    static readonly COLOR_MS_BLUE: string = '#01A6F0';
    static readonly COLOR_MS_GREY: string = '#747474';
    static readonly DATE_FORMAT: string = 'MMMM D, YYYY';
    static readonly TIME_FORMAT: string = 'h:mm A';
    static readonly ADMIN_SEARCH_MIN: string = 'Search';
    static readonly ADMIN_SEARCH_MAX: string = 'Search via ID or Request Title';
}

export const appInsightsService = new AppInsightsService();

export const IMAGE_MIN_WIDTH = 1080;
export const IMAGE_ASPECT_RATIO = 3.86;
export const THUMBNAIL_ASPECT_RATIO = 2.09;
export const THUMBNAIL_MIN_WIDTH = 522;
export const IMAGE_MIN_HEIGHT = Math.round(IMAGE_MIN_WIDTH / IMAGE_ASPECT_RATIO);
export const THUMBNAIL_MIN_HEIGHT = Math.round(THUMBNAIL_MIN_WIDTH / THUMBNAIL_ASPECT_RATIO);
export const ALLOWED_VIDEO_MIME_TYPES = ['video/mp4', 'video/ogg', 'video/webm'];

export class StringConstants {
    // CamelCase Strings
    static readonly CAMELCASE_ADD_NEW_DETAILS: string = 'addNewDetails';
    static readonly ALL: string = 'all';
    static readonly ANSWER: string = 'answer';
    static readonly ARTICLE: string = 'article';
    static readonly ARTICLES: string = 'articles';
    static readonly AUTHOR: string = 'author';
    static readonly AUTO_AUTH: string = 'autoAuth';
    static readonly BUG_INFORMATION: string = 'bugInformation';
    static readonly BUISNESS_PROBLEM: string = 'whatBusinessProblemIsSolved';
    static readonly COMPETITIVE_OFFERING: string = 'competitiveOffering';
    static readonly COMPLIMENT: string = 'Compliment';
    static readonly CRITICALITY: string = 'criticality';
    static readonly CSS_CASE_NUMBER: string = 'cssCaseNumber';
    static readonly CURRENCY: string = 'currency';
    static readonly PFR_SUBTYPE: string = 'pfrSubtype';
    static readonly EMAIL: string = 'email'
    static readonly GET_REQUEST_BY_ID: string = 'getRequestById'
    static readonly HISTORY: string = 'history';
    static readonly IS_DESIGNED_FOR_SURFACE_POSSIBLE: string = 'isDesignForSurfacedPossible';
    static readonly IS_DCF_REQUEST: string = 'isDcfRequest';
    static readonly IN_INTERNAL: string = 'isInternal';
    static readonly IMPACT_REACH: string = 'impactReach';
    static readonly LOWERCASE_ADD_NEW_DETAILS: string = 'addNewDetails';
    static readonly MAIN_REQUEST_TYPE: string = 'mainRequestType';
    static readonly MODEL_COUNT: string = 'modelCount';
    static readonly NEW_BLOB: string = 'newblob';
    static readonly NEWS: string = 'news';
    static readonly NONE: string = 'none';
    static readonly LIST_BUILDER_QUERY: string = 'listBuilder';

    static readonly NUMBER: string = 'number';
    static readonly SEARCH_CUST: string = 'searchCust';
    static readonly STRING: string = 'string';
    static readonly SUB_REQUEST_TYPE: string = 'subRequestType';
    static readonly SUGGESTION: string = 'Suggestion';
    static readonly UNDEFINED: string = 'undefined';
    static readonly PRIORITY_NO: string = 'priorityNo';
    static readonly PRIORITY: string = 'priority';
    static readonly REQUEST_QUERY: string = 'requestQuery'
    static readonly TASK_STATUS: string = 'taskStatus';
    static readonly LOWERCASE_YES: string = 'yes';
    static readonly ADD: string = 'Add';
    static readonly UPDATE: string = 'Update';

    static readonly PROOF_OF_PURCHASE_ATTACHED: string = 'proofOfPurchaseAttached';
    static readonly SERIAL_NUMBERS_ATTACHED: string = 'serialNumbersAttached';


    static readonly PRE_SALES_REQUEST: string = 'pre_sales_request';
    static readonly POST_SALES_REQUEST: string = 'post_sales_request';
    static readonly WARRANTY_REQUEST: string = 'warranty_request';


    //PascalCase Strings
    static readonly UPPERCASE_ALL: string = 'ALL';
    static readonly ACTIVE: string = 'Active';
    static readonly ADMIN: string = 'Admin';
    static readonly ADD_NEW_DETAILS: string = 'AddNewDetails';
    static readonly ADD_DETAILS: string = 'AddDetails';
    static readonly BETA_USER: string = "Beta";
    static readonly BUG: string = 'Bug';
    static readonly CONTENT_CREATOR: string = 'ContentCreator';
    static readonly CONTENT_MANAGER: string = 'ContentManager';
    static readonly CANCEL: string = 'Cancel';
    static readonly CLOSED: string = 'Closed';
    static readonly CSS_REQUEST: string = 'CSS Request';
    static readonly CSS_REVIEW_REQUEST: string = 'CSS Review Request';
    static readonly DELETE: string = 'Delete';
    static readonly DRAFT: string = 'Draft';
    static readonly DO_NOT_DISTURB: string = 'DoNotDisturb';
    static readonly DUPLICATE: string = 'Duplicate';
    static readonly EDIT: string = 'Edit';
    static readonly END_USER: string = 'EndUser';
    static readonly ENTER: string = 'Enter';
    static readonly FEATURE_REQUEST: string = 'Feature Request';
    static readonly IN_PROGRESS: string = 'In Progress';
    static readonly MESSAGING: string = 'Messaging';
    static readonly MESSAGING_REQUEST: string = 'Messaging Request';
    static readonly MORE_OPTION: string = 'More option';
    static readonly MOVE_TO_DRAFT: string = 'Move to draft';
    static readonly NEW: string = 'New';
    static readonly NUM_PAD_ENTER: string = 'NumpadEnter';
    static readonly PRE_SALES: string = 'Pre Sales';
    static readonly PRODUCT: string = 'Product';
    static readonly PROGRAM: string = 'Program';
    static readonly PUBLISH: string = 'Publish';
    static readonly PUBLISHED: string = 'Published';
    static readonly PRIMARY_OPTION: string = 'Primary option';
    static readonly PRIMARY_PARTNER: string = 'PrimaryPartner';
    static readonly PROPOSAL: string = 'Proposal';
    static readonly POST_SALES: string = 'Post Sales';
    static readonly QUESTION: string = 'Question';
    static readonly REMOVE: string = 'Remove';
    static readonly REMOVED: string = 'Removed';
    static readonly REDRAFT: string = 'Redraft';
    static readonly RESOLVED: string = 'Resolved';
    static readonly REVIEW_REQUEST: string = 'Review Request';
    static readonly REVIEW_REQUESTS: string = 'Review Requests';
    static readonly RFX: string = 'Rfx';
    static readonly SAVE_AS_TEMPLATE: string = 'Save as template';
    static readonly SUBMIT_FOR_REVIEW: string = 'Submit for Review';
    static readonly SUPER_ADMIN: string = 'SuperAdmin';
    static readonly SUBMITTED: string = 'Submitted';
    static readonly SCHEDULED_TO_PUBLISH: string = 'ScheduledToPublish';
    static readonly TECHNICAL: string = 'Technical';
    static readonly UNASSIGNED: string = 'Unassigned';
    static readonly VARIANT: string = 'Variant ';
    static readonly VARIANT_REQUEST: string = 'Variant Request';
    static readonly WARRANTY: string = 'Warranty';
    static readonly YES: string = 'Yes';
    static readonly CSS_TICKETS: string = "CSS Tickets";
    static readonly CXP_TICKETS: string = "CXP Tickets";
    static readonly CREW_TICKETS: string = "CREW Tickets";
    static readonly SDSG_TICKETS: string = "SDSG Tickets";

    static readonly WHITE_SPACE: string = ' ';
    static readonly EMPTY_STRING: string = '';

    //LowerCase
    static readonly HELP_REQUEST: string = 'help-request';
    static readonly KNOWLEDGE_ARTICLES = 'knowledge-articles';
    static readonly MANAGE_REQUESTS: string = 'manage-requests';
    static readonly MS_EMAIL: string = 'ms email';
    static readonly MOBILE_NUMBER: string = 'mobile number';
    static readonly REQUEST: string = 'request';
    static readonly REQUEST_DETAIL: string = 'request-detail';
    static readonly SEARCH_RESULTS_REQUESTS: string = 'search-results/requests';
    static readonly SUBMIT_FEEDBACK: string = 'submit-feedback';
    static readonly TEST_INDUSTRY: string = 'test industry';
    static readonly UNIFIED_DASHBOARD: string = 'unified-dashboard';
    static readonly VISUAL_FLOW: string = 'visual-flow';

    // Numbers
    static readonly ZERO: string = '0';
    static readonly FIVE: string = '5';

    //Letters
    static readonly A: string = 'A';

    //Symbols
    static readonly DOLLAR_SIGN: string = '$';
    static readonly FORWARDSLASH: string = '/';
    static readonly DASHES_WITH_SPACE: string = ' -- ';
    static readonly DASHES: string = '--';
    static readonly DASH: string = '-';

    //Booleans
    static readonly TRUE: string = 'TRUE';

    static readonly MIN_BANNER_DIMENSIONS_ERROR: string = `Banner image minimum width must be ${IMAGE_MIN_WIDTH} pixels and height must be ${IMAGE_MIN_HEIGHT} pixels.`;
    static readonly MIN_THUMBNAIL_DIMENSIONS_ERROR: string = `Thumbnail image minimum width must be ${THUMBNAIL_MIN_WIDTH} pixels and height must be ${THUMBNAIL_MIN_HEIGHT} pixels.`;

    static readonly SHARE_REPORT_TYPE_MESSAGE = `You cannot share Utilization Insights Report`;
    static readonly NOT_BOOKMARKED_ANY_REPORT_MESSAGE = `You have not bookmarked any reports.`;
    static readonly UTILIZATION_REPORT_NOT_FOUND_MESSAGE = `No Utilization reports found`;
    static readonly STARRED_REPORT_NOT_FOUND_MESSAGE = `No Starred reports found`;
    static readonly ARTICLE_NOT_FOUND_MESSAGE = `We're adding new articles all the time. Request something`;
    static readonly ARTICLE_DETAIL_NOT_FOUND_MESSAGE = `Article not found`;
    static readonly ARTICLE_NOT_FOUND_CREATE_MORE_MESSAGE = `Run your imagination wild and create articles`;
    static readonly CREATE_ARTICLE = 'Create Article';
    static readonly CREATE_EMAIL = 'Create Email';

    static readonly AGING: string = 'AGING';
    static readonly HIDDEN_REQUEST: string = 'HIDDEN_REQUEST';
    static readonly LIST_VIEW: string = 'LIST_VIEW';
    static readonly CREATED_ON: string = 'CREATED_ON';
    static readonly TAGS: string = 'TAGS';
    static readonly CUSTOMER_INFORMATION: string = 'CUSTOMER_INFORMATION';
    static readonly COMMENTS_STATUS: string = 'COMMENTS_STATUS';
    static readonly REVENUE: string = 'REVENUE';
    static readonly REQUEST_DETAILS: string = 'REQUEST_DETAILS';
    static readonly SDSG: string = 'SDSG';
    static readonly SHARED_REQUEST: string = 'SHARED_REQUEST';
    static readonly STARRED_REQUEST: string = 'STARRED_REQUEST';
    static readonly CXP: string = 'CXP';
    static readonly CREW: string = 'CREW';
    static readonly CSS: string = 'CSS';
    static readonly IMAGE: string = 'IMAGE';
    static readonly IRT: string = 'IRT';
    static readonly NAME: string = 'NAME';
    static readonly ACTION: string = 'ACTION';
    static readonly DESCRIPTION: string = 'DESCRIPTION';
    static readonly SECONDARY_TEXT: string = 'SECONDARY_TEXT';
    static readonly ASC: string = 'ASC';
    static readonly DESC: string = 'DESC';
    static readonly PFR: string = 'PFR';
    static readonly MY_REQUEST: string = 'MY_REQUEST';
    static readonly MANAGE_REQUEST: string = 'MANAGE_REQUEST';
    static readonly CONTACT_NUM: string = 'CONTACT_NUM';
    static readonly ALT_CONTACT_NUM: string = 'ALT_CONTACT_NUM';
    static readonly DETAILS_PAGE: string = 'DETAILS_PAGE';
    static readonly DETAILS_PANEL: string = 'DETAILS_PANEL';
    static readonly LIST_BUILDER: string = 'LIST_BUILDER';
    static readonly DETAIL_VIEW: string = 'DETAIL_VIEW';
    static readonly FILTER_PANEL: string = 'FILTER_PANEL';
    static readonly WEEK: string = 'WEEK';
    static readonly MONTH: string = 'MONTH';
    static readonly YEAR: string = 'YEAR';

    static readonly HAS_MSX_OPP: string = 'Has MSX Opportunity';
    static readonly NO_MSX_OPP: string = 'No MSX Opportunity';

    static readonly HAS_CSS_CASE: string = 'Has CSS Case';
    static readonly NO_CSS_CASE: string = 'No CSS Case';
}

export class RouteConstants {
    static readonly ALL_ARTICLES = '/all-articles';
    static readonly ALL_NEWS = '/all-news';
    static readonly ARTICLE = '/article';
    static readonly ARTICLES = '/articles';
    static readonly CXP_IRT_REQUEST = '/cxp/IrtRequest';
    static readonly CXP_IRT_REQUEST_WATCHER = '/cxp/IrtRequest/Watcher';
    static readonly SUB_CSS_REQUEST = '/css/cssRequest';
    static readonly CSS_CSS_REQUEST = '/css/CssRequest';
    static readonly CSS_CSS_REQUEST_WATCHER = '/css/CssRequest/Watcher';
    static readonly CSS_REVIEW_REQUEST_WATCHER = '/css/ReviewRequest/Watcher';
    static readonly CSS_REVIEW_REQUEST = '/css/ReviewRequest';
    static readonly CREW_PRESALES = '/crew/PreSales';
    static readonly CREW_POSTSALES = '/crew/PostSales';
    static readonly CREW_PRESALES_WATCHER = '/crew/PreSales/Watcher';
    static readonly CREW_POSTSALES_WATCHER = '/crew/PostSales/Watcher';
    static readonly HELP_REQUEST = '/help-request';
    static readonly LIST_BUILDER = '/list-builder';
    static readonly NEWS_FEED = '/news-feed';
    static readonly NEWS_FEED_ALL = '/news-feed/all';
    static readonly NEWS_FEED_ARTICLE = '/news-feed/article';
    static readonly NEWS_FEED_AUTHOR = '/news-feed/author';
    static readonly NOTIFICATIONS = '/notifications';
    static readonly KNOWLEDGE_ARTICLES = '/knowledge-articles';
    static readonly KNOWLEDGE_ARTICLES_ARTICLE = '/knowledge-articles/article';
    static readonly KNOWLEDGE_PORTAL = '/knowledge-portal';
    static readonly MANAGE_CONTENT_ARTICLES = '/manage-content/articles';
    static readonly MANAGE_CONTENT_ARTICLE_CREATE = '/manage-content/article/create';
    static readonly MANAGE_CONTENT_ARTICLE_EDIT = '/manage-content/article/edit';
    static readonly MANAGE_CONTENT_EMAILS = '/manage-content/emails';
    static readonly MANAGE_REPORTS = '/manage-reports';
    static readonly MANAGE_REQUESTS = '/manage-requests';
    static readonly PORTAL_TRAINING = '/portal-training';
    static readonly PROFILE = '/profile';
    static readonly REPORT = '/report';
    static readonly REQUEST = '/request/';
    static readonly REQUEST_DETAIL = '/request-detail/';
    static readonly REQUEST_COMMENT_LIST = '/request/Comment/list?';
    static readonly SDSG_PROPOSAL = '/sdsg/Proposal';
    static readonly SDSG_PROPOSAL_WATCHER = '/sdsg/Proposal/Watcher';
    static readonly SDSG_QUESTION = '/sdsg/Question';
    static readonly SDSG_QUESTION_WATCHER = '/sdsg/Question/Watcher';
    static readonly SDSG_RFX_WATCHER = '/sdsg/Rfx/Watcher';
    static readonly SDSG_RFX = '/sdsg/Rfx';
    static readonly SEARCH_RESULTS = '/search-results';
    static readonly SEARCH_RESULTS_ALL_ARTICLES = '/search-results/all-articles';
    static readonly SEARCH_RESULTS_ARTICLES = '/search-results/articles';
    static readonly SEARCH_RESULTS_NEWS = '/search-results/news';
    static readonly SEARCH_RESULTS_REQUESTS = '/search-results/requests';
    static readonly SUBMIT_FEEDBACK = '/submit-feedback';
    static readonly TEAM_CONTACTS = '/team-contacts';
    static readonly UNIFIED_DASHBOARD = '/unified-dashboard';
    static readonly VISUAL_FLOW = '/visual-flow';
}

export const API = {
    API_URL_LOCAL: process.env.REACT_APP_URL_LOCAL,
    API_URL: process.env.REACT_APP_URL,
    API_Server_URL: process.env.REACT_APP_Server_URL,
    API_FILES_URL: process.env.REACT_APP_FILES_URL,
    API_SERVER_URL: process.env.REACT_APP_API_URL,
    API_MS_GRAPH: 'https://graph.microsoft.com/v1.0/'
};

export const LOGIN = {
    LOGIN_CLIENT_ID: process.env.REACT_APP_LOGIN_CLIENTID,
    LOGIN_SCOPE: process.env.REACT_APP_LOGIN_SCOPE,
    LOGIN_AUTHORITY: process.env.REACT_APP_AUTHORITY,
    LOGIN_SCOPE_GRAPH: process.env.REACT_APP_LOGIN_SCOPE_GRAPH,
    POWERBI_SCOPE: process.env.REACT_APP_POWERBI_SCOPE
};

/**
 * Azure Blob Storage string helpers
 */
export class BlobStorageHelper {
    static readonly MAX_FILE_SIZE = '2GB'
    static readonly UPLOAD_ATTACHMENT_ROUTE = '/request/file/UploadAttachment';
    static readonly DOWNLOAD_ATTACHMENT_ROUTE = 'request/file/DownloadAttachment';
    static readonly STORAGE_URL_REGEX = new RegExp('(https://[A-Za-z0-9]+.blob.core.windows.net)[A-Za-z0-9-./?=&%;]+');
    static readonly STORAGE_URL_REGEX_WITH_TAGS_GI = new RegExp('(https://[A-Za-z0-9]+.blob.core.windows.net)[A-Za-z0-9-./?=&%;]+', 'gi');
    static readonly STORAGE_BASE_URL = process.env.REACT_APP_STORAGE_URL;
    static readonly SAS_TOKEN_IDENTIFIER = '?sv=';
}

/**
 * @description Azure Blob Storage container names
 */
export class BlobStorageContainer {
    static readonly ARTICLE_FILES = 'article-files';
    static readonly COMMENT_STATUS_FILES = 'comment-status-files';
    static readonly PORTAL_ATTACHMENTS = 'portal-attachments';
    static readonly REQUEST_DETAIL_FILES = 'request-detail-files';
}

/**
 * @description Azure Blob Storage subfolder names
 */
export class BlobStorageSubfolder {
    static readonly NONE = '';
    static readonly PORTAL_ATTACHMENTS = 'portal-attachments';
}

export class Entity {
    static readonly ARTICLE = 'article';
    static readonly REQUEST = 'request';
}

/**
 * @description Azure Blob Storage errors
 */
export class BlobStorageError {
    static readonly CREATE_ERROR = 'createError';
    static readonly CONTAINER_NAME_MISMATCH = 'mismatched container name';
    static readonly FILE_ALREADY_EXISTS = 'The file already exists and will not be uploaded.';
    static readonly MAX_FILE_SIZE = `File could not be uploaded, current max file size is ${BlobStorageHelper.MAX_FILE_SIZE}`;
    static readonly NETWORK_ERROR = 'Network Error';
}

export class Files {
    // dash, dot, and underscore are acceptable
    static readonly SpecialCharactersRegex = new RegExp(/[~#%{}]/g);
    static readonly SpecialCharactersReplacement = '_';
    static readonly AllowedContentTypes = [
        "application/msword",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/xml",
        "text/csv",
        "text/plain",
        "text/xml",
        "image/gif",
        "image/heic",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "video/mp4",
        "video/quicktime",
        "video/x-m4v"
    ];
    static readonly AllowedFileExtensions = [
        "dat",
        "csv",
        "doc",
        "docx",
        "pdf",
        "ppt",
        "pptx",
        "txt",
        "xls",
        "xlsx",
        "xml",
        "gif",
        "heic",
        "jfif",
        "jpeg",
        "jpg",
        "png",
        "log",
        "blg",
        "evtx",
        "m4v",
        "mov",
        "mp4"
    ];
}

export class Languages {
    static readonly EN_US: string = 'en-US';
}

export class DateFormats {
    static readonly DAY_MONTH_YEAR: string = 'DD MMM YYYY';
    static readonly MONTH_DAY_YEAR: string = 'MMM DD, YYYY';
    static readonly YEAR_MONTH_DAY: string = 'YYYY/MM/DD';
    static readonly YEAR_MONTH_DAY_MILLISECOND: string = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
}

export class RequestDomain {
    static readonly CREW: number = 1;
    static readonly CXP: number = 2;
    static readonly CSS: number = 3;
    static readonly SDSG: number = 4;
}
