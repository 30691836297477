import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, Checkbox, Icon, Link, PersonaInitialsColor, Label, SearchBox } from '@fluentui/react';
import './AssignedToPicker.scss';
import { IUserProps } from 'app/models/common/response';
import Avatar from 'app/components/avatar/Avatar';
import { keyPress } from 'app/components/helpers';
import { useFetchUsersListByRole, useGetSubmitters } from 'app/services/queries';
import '../Picker.scss';
import { StringConstants } from 'app/utils/constants';
import { IUserDetails } from 'app/models/common/post';
import useStore from 'app/store';
interface IProps {
    selectedOptions: any;
    setSelectedOptions: Function;
    selectedKeys: any[];
    setSelectedKeys: Function;
    multiSelectData: any[];
    inEditMode: boolean;
    isSubmitterPicker: boolean;
}

const AssignedToPicker: React.FC<IProps> = ({
    setSelectedOptions,
    selectedOptions,
    selectedKeys,
    setSelectedKeys,
    multiSelectData,
    inEditMode,
    isSubmitterPicker
}) => {
    const dropDownRef = useRef(null);
    const origin = StringConstants.LIST_BUILDER;

    // State
    const [options, setOptions] = useState<IUserProps[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IUserProps[]>([]);

    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    // Query
    const { data: usersListData, isLoading: isUserListLoading } = useFetchUsersListByRole();
    const { data: submittersData, isLoading: isSubmittersLoading } = useGetSubmitters();

    // Handler methods

    const checkEquality = (arr1: IUserProps[], arr2: IUserProps[]) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.id === arr1[i].id).length === 0) {
                return false;
            }
        }
        return true;
    };

    // For assigned to, the back-end query builder checks against the user ID
    // We need to make sure that selected options is an array of user ID's 
    const onChange = (key: string) => {
        if (key === StringConstants.UPPERCASE_ALL) {
            if (selectedKeys.filter((item) => item.id === key).length === 0) {
                let arr: any[] = [];
                options.forEach((ele: any) => arr.push(ele));
                setSelectedKeys(arr);
                setSelectedOptions([...arr.filter((option) => option.id !== StringConstants.UPPERCASE_ALL).map((item) => item.id)]);
            } else {
                setSelectedKeys([]);
                setSelectedOptions([]);
            }
        } else {
            if (selectedKeys.filter((item) => item.id === key).length === 0) {
                const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.id === key)[0]];
                setSelectedKeys(tempSelectedKeys);
                setSelectedOptions([...selectedOptions, key]);
                setSearchedResults(options);
            } else {
                const tempSelectedKeys = selectedKeys.filter(
                    (item) => item.id !== key && item.text !== StringConstants.UPPERCASE_ALL
                );
                setSelectedKeys(tempSelectedKeys);
                setSelectedOptions([...tempSelectedKeys.map((item) => item.id)]);
            }
        }
    };

    const getLabel = useMemo(() => {
        if (
            selectedKeys?.length === 0 ||
            (selectedKeys?.length === options?.length && origin === StringConstants.LIST_BUILDER) ||
            (selectedKeys?.filter((it) => it.id === StringConstants.UPPERCASE_ALL).length !== 0 &&
                origin === StringConstants.LIST_BUILDER)
        ) {
            if (origin === StringConstants.LIST_BUILDER) {
                return isSubmitterPicker ? 'Submitter' : 'Admin';
            } else {
                return StringConstants.UNASSIGNED;
            }
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                if (item) {
                    arr.push(item?.displayName);
                }
            });

            return arr.join(', ');
        }
    }, [selectedKeys]);

    const isChecked = (item: string) => {
        return selectedKeys.filter((ele) => ele?.id === item).length !== 0;
    };
    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };

    useDropDownDismiss(dropDownRef, onToggleShow);

    // use effects

    useEffect(() => {
        var users = [];

        if (!isUserListLoading && !isSubmittersLoading) {
            users = isSubmitterPicker ? submittersData : usersListData;
        }

        if (users.length !== 0) {
            let arr: IUserProps[] = [];
            arr.push({
                id: StringConstants.UPPERCASE_ALL,
                displayName: StringConstants.UPPERCASE_ALL,
                mail: '',
                mobilePhone: '',
                businessPhones: [''],
                text: StringConstants.UPPERCASE_ALL
            });

            if (users.filter((item: IUserProps) => item.id !== userDetails.id).length !== 0) {
                arr.push({
                    id: userDetails.id,
                    displayName: userDetails.displayName,
                    mail: userDetails.email,
                    mobilePhone: userDetails.contactNumber,
                    businessPhones: userDetails.businessPhones,
                    text: '@Me'
                });
            }

            if (!isSubmitterPicker) {
                arr.push({ id: '00000000-0000-0000-0000-000000000000', text: 'Unassigned', displayName: 'Unassigned', mail: '', mobilePhone: '', businessPhones: [] });
            }
            
            users.filter((item: IUserProps) => item.id !== userDetails.id)
                .forEach((element: IUserProps) => {
                    arr.push({ ...element, text: element.displayName });
                });

            setOptions(arr);
        }
    }, [usersListData, isUserListLoading, submittersData, isSubmittersLoading, isSubmitterPicker]);

    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.id !== StringConstants.UPPERCASE_ALL)
            )
        ) {
            setSelectedKeys(options);
        }
    }, [selectedKeys, origin, options]);

    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IUserProps[] = [];
            options?.forEach((item) => {
                if (
                    item?.displayName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                    item?.text?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                    item?.mail?.toLowerCase().includes(searchValue.trim().toLowerCase())
                ) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    // auto-populate fields when in edit mode
    useEffect(() => {
        if (multiSelectData.length > 0 && inEditMode) {
            var keys = [];
            if (!isSubmitterPicker && usersListData?.length > 0) {
                keys = usersListData.filter((user: any) => multiSelectData.includes(user.id));
            } else if (submittersData?.length > 0) {
                keys = submittersData.filter((user: any) => multiSelectData.includes(user.id));
            }

            setSelectedKeys(keys);
            setSelectedOptions(keys.map((item: any) => item.id));
        }
    }, [multiSelectData, inEditMode, usersListData, isSubmitterPicker, submittersData]);

    useEffect(() => {
        if (!showDropdown) {
            setSearchValue('');
        }
    }, [showDropdown])

    return (
        <div className={`assignedTo-picker`}>
            <div
                className="assignedTo-title row-flex-box align-center"
                tabIndex={0}
                data-testid="pdt-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <Text className="assignedTo-label">{getLabel}</Text> <Icon iconName="ChevronDown" />
            </div>

            {showDropdown && (
                <div
                    ref={dropDownRef}
                    className={`assignedTo-dropdown-cont ${origin === StringConstants.LIST_BUILDER ? 'position-cont' : ''}`}>
                    <div className="row-flex-box align-center justify-sb dropdown-header-lb">
                        <div
                            tabIndex={0}
                            data-testid="assignedTo-filter-close-btn-test"
                            onKeyPress={(e: any) => {
                                if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER)
                                    keyPress(e, onToggleShow);
                            }}
                            onClick={() => onToggleShow()}>
                            <Text>{isSubmitterPicker ? "Submitter" : "Assigned To"}</Text>
                            <Icon iconName="ChevronUp" />
                        </div>
                        {origin !== StringConstants.LIST_BUILDER && (
                            <Link data-testid="clear-btn-test" onClick={() => setSelectedKeys([])}>
                                Clear
                            </Link>
                        )}
                    </div>
                    <div className="search-bar-assignedTo">
                        {origin === StringConstants.LIST_BUILDER && (
                            <div>
                                {options.length === selectedKeys.length ||
                                selectedKeys.filter((it) => it.id === StringConstants.UPPERCASE_ALL).length !== 0 ? (
                                    <div className="assignedTo-cont">
                                        <div className="assignedTo-item">
                                            <Text>All</Text>
                                            <Icon
                                                data-testid={`tags-test-all`}
                                                onKeyPress={(e: any) => {
                                                    if (
                                                        e.key === StringConstants.ENTER ||
                                                        e.key === StringConstants.NUM_PAD_ENTER
                                                    )
                                                        setSelectedKeys([]);
                                                }}
                                                onClick={() => setSelectedKeys([])}
                                                iconName="Cancel"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="assignedTo-cont">
                                        {selectedKeys.map((ele) => (
                                            <div className="assignedTo-item">
                                                <Text>{ele.displayName}</Text>
                                                <Icon
                                                    data-testid={`tags-test-${ele?.id}`}
                                                    onKeyPress={(e: any) => {
                                                        if (
                                                            e.key === StringConstants.ENTER ||
                                                            e.key === StringConstants.NUM_PAD_ENTER
                                                        )
                                                            onChange(ele?.id);
                                                    }}
                                                    onClick={() => onChange(ele?.id)}
                                                    iconName="Cancel"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <SearchBox
                            data-testid="search-box-test"
                            placeholder="Search"
                            tabIndex={0}
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item, index) => (
                                <div
                                    tabIndex={0}
                                    onKeyPress={(e: any) => {
                                        if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER)
                                            onChange(item.id);
                                    }}
                                    className={`assignedTo-dropdown-item ${isChecked(item.id) ? 'selected' : ''} `}
                                    key={index}>
                                    <Checkbox checked={isChecked(item.id)} onChange={() => onChange(item.id)} />

                                    {item.id !== StringConstants.UPPERCASE_ALL && (
                                        <Avatar
                                            userId={item?.id}
                                            userName={item?.displayName}
                                            initialColor={PersonaInitialsColor.coolGray}
                                            imageSize={32}
                                            roundedPic={true}
                                        />
                                    )}
                                    <Label>{item.displayName}</Label>
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item, index) => (
                                <div
                                    tabIndex={0}
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === StringConstants.ENTER || e.key === StringConstants.NUM_PAD_ENTER)
                                            onChange(item.id);
                                    }}
                                    className={`assignedTo-dropdown-item ${isChecked(item.id) ? 'selected' : ''}  `}
                                    key={index}>
                                    <Checkbox
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        ariaLabel="assignee selection"
                                        checked={isChecked(item.id)}
                                        onChange={() => onChange(item.id)}
                                    />
                                    {item.id !== StringConstants.UPPERCASE_ALL && (
                                        <Avatar
                                            userId={item?.id}
                                            userName={item?.displayName}
                                            initialColor={PersonaInitialsColor.coolGray}
                                            imageSize={32}
                                            roundedPic={true}
                                        />
                                    )}
                                    <Label>{item.displayName}</Label>
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
export default AssignedToPicker;
