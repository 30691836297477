import { Link, Shimmer } from '@fluentui/react';
import CommentField from 'app/components/commentField/CommentField';
import CommentTypeSelector from 'app/components/commentTypeSelector/CommentTypeSelector';
import { generateDataForComments } from 'app/components/helpers';
import { ICommentCriteria } from 'app/models/common/request';
import { IRequest } from 'app/models/common/response';
import { useFetchStatus } from 'app/services/queries';
import React, { useEffect, useState } from 'react';
import Status from './status/Status';

interface IProps {
    correlatedChildrenIds?: string[];
    correlationId?: string;
    isMultipleType?: boolean;
    defaultIsInternal?: boolean;
    noExternalSection?: boolean;
    selectedRequest?: IRequest;
}

const pageLength = 100;

const StatusSection: React.FC<IProps> = ({
    correlatedChildrenIds,
    correlationId,
    isMultipleType,
    defaultIsInternal,
    noExternalSection,
    selectedRequest
}) => {
    // state variables
    const [criteria, setCriteria] = useState<ICommentCriteria>({});
    const [pageNumber] = useState(1);
    const [commentList, setCommentList] = useState([]);
    const [totalComments, setTotalComments] = useState(0);
    const [isInternal, setIsInternal] = useState(defaultIsInternal);
    const [pageSize, setPageSize] = useState(pageLength);

    // queries
    const { data: commentsData, isLoading: isLoadingComments } = useFetchStatus(criteria);

    // helper functions
    const setInitialFilter = () => {
        let filterData: ICommentCriteria = {
            correlationId: correlationId,
            pageNumber: pageNumber,
            pageSize: pageSize,
            entityType: 1,
            isInternal: false
        };
        setCriteria(filterData);
    };

    const handleShowMore = () => {
        setCriteria((value: any) => ({ ...value, ...{ pageSize: pageLength + pageSize } }));
        setPageSize((size) => size + pageLength);
    };

    // hooks
    useEffect(() => {
        setInitialFilter();
    }, []);

    useEffect(() => {
        let filterData: ICommentCriteria = {
            correlationId: correlationId,
            pageNumber: pageNumber,
            pageSize: pageLength,
            entityType: 1,
            isInternal: isInternal
        };
        setCriteria(filterData);
    }, [correlationId, isInternal]);

    useEffect(() => {
        if (!isLoadingComments && commentsData !== '') {
            const replaceStorageUrls = async () => {
                await generateDataForComments(commentsData ? commentsData.items : [])
                .then((res) => {
                    setCommentList(res);
                    setTotalComments(commentsData ? commentsData.totalCount : 0);
                });
            }

            replaceStorageUrls();
        }
    }, [commentsData]);

    return (
        <>
            {isMultipleType && (
                <div>
                    <CommentTypeSelector
                        isInternal={isInternal}
                        setIsInternal={setIsInternal}
                        noExternalSection={noExternalSection}
                        type={'Status'}
                    />
                </div>
            )}
            <div
                className={`cmt-container-wrapper ${
                    isMultipleType ? (isInternal ? 'internal-highlight' : 'external-highlight') : ''
                }`}>
                <div className="m-b-20">
                    {isMultipleType && (
                        <CommentField
                            correlatedChildrenIds={correlatedChildrenIds}
                            correlationId={correlationId}
                            entityType={1}
                            textareaSize={3}
                            isInternal={isInternal}
                            fieldType="status"
                            selectedRequest={selectedRequest}
                        />
                    )}
                </div>
                <div className="cmt-container">
                    {isLoadingComments && (
                        <div>
                            <Shimmer />
                            <Shimmer width="75%" />
                            <Shimmer width="50%" />
                        </div>
                    )}
                    {!isLoadingComments && (
                        <>
                            {!isMultipleType && totalComments === 0 && <span>No status available.</span>}
                            {totalComments > 0 && <div className="cmt-count">{totalComments} status</div>}
                            {commentList &&
                                commentList.length > 0 &&
                                commentList
                                    .filter((item) => item.isActive)
                                    .map((item, itemIndex) => (
                                        <Status
                                            statusText={item.statusText}
                                            correlationId={correlationId}
                                            item={item}
                                            isInternal={isInternal}
                                            isMultipleType={isMultipleType}
                                            itemIndex={itemIndex}
                                        />
                                    ))}
                        </>
                    )}
                </div>
                {commentsData && commentsData.hasNextPage && (
                    <Link data-testid="show-more-btn-test" onClick={handleShowMore} className="show-more-btn">
                        Show more
                    </Link>
                )}
            </div>
        </>
    );
};

export default StatusSection;
