import React from 'react';
import HelpfulResourcesCard from '../../viewArticle/helpfulResourcesCard/HelpfulResources';

interface IProps {
    helpfulResources: Array<any>;
    showDeleteIcon: boolean;
    addHelpfulResource?: (resource: any) => void;
    removeHelpfulResource?: (resource: any) => void;    
    
}
const HelpfulResourcesList: React.FC<IProps> = (props: IProps) => {
    const { helpfulResources, removeHelpfulResource, showDeleteIcon } = props;

    return (
        <div className="helpful-resources-wrapper">
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    {helpfulResources?.length > 0 &&
                        helpfulResources.map((data: any, idx) => {
                            return (
                                <div key={idx} className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ">
                                    <HelpfulResourcesCard
                                        resourceData={data}
                                        showDeleteIcon={showDeleteIcon}
                                        removeHelpfulResource={removeHelpfulResource}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
export default HelpfulResourcesList;
