import React, { useEffect } from 'react';
import { Dropdown } from '@fluentui/react';
import { conditionDropdownStyles } from '../../helpers/helpers';
import './ConditionDropdown.scss';

interface IProps {
    conditions: any;
    selectedKey: any;
    setSelectedKey: any;
    fieldOption: any;
    setConditionSelection: any;
    conditionPickerActive: boolean;
    setConditionPickerActive: (conditionPickerActive: boolean) => void;
    setPickerActive: (pickerActive: boolean) => void;
}

const ConditionDropdown: React.FC<IProps> = ({
    fieldOption,
    setConditionSelection,
    conditions,
    selectedKey,
    setSelectedKey,
    conditionPickerActive,
    setConditionPickerActive,
    setPickerActive
}) => {
    const onChange = React.useCallback(
        (event: React.FormEvent<HTMLDivElement>, option?: any, index?: number): void => {
            setSelectedKey(option.key);
            setConditionSelection({ key: option.key, text: option.text, enumValue: option.enumValue });
            setConditionPickerActive(false);
            setPickerActive(true);
        },
        [selectedKey, conditions]
    );

    useEffect(() => {
        if (fieldOption === 'Attachment') {
            setConditionSelection({ key: conditions[0].key, text: conditions[0].text, enumValue: conditions[0].enumValue });
            setConditionPickerActive(false);
        }
    }, [selectedKey]);

    return (
        <div className="condition-dropdown-cont">
            <Dropdown
                className={conditionPickerActive ? 'field-active' : ''}
                placeholder={fieldOption === 'Attachment' ? conditions[0].text : 'condition'}
                options={conditions}
                styles={conditionDropdownStyles}
                defaultSelectedKeys={fieldOption === 'Attachment' ? setSelectedKey(conditions[0].key) : null}
                selectedKey={selectedKey}
                disabled={conditions ? false : true}
                onChange={onChange}
            />
            <div className="DropDown-margins" />
        </div>
    );
};

export default ConditionDropdown;
