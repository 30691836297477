import React, { useEffect, useRef, useState } from 'react';
import { Text, Checkbox, Icon, SearchBox } from '@fluentui/react';
import './ProductDevicePicker.scss';
import { useQueryAllDeviceList } from 'app/services/queries';
import { IDeviceProps } from 'app/models/common/response';
import { keyPress } from 'app/components/helpers';
import { StringConstants } from 'app/utils/constants';
import { IOptionProps } from 'app/modules/admin/manageRequests/models';

interface IProps {
    selectedOptions: any[];
    setSelectedOptions: Function;
    selectedKeys: any[];
    setSelectedKeys: Function;
    inEditMode: boolean;
    multiSelectData: any[];
}

const ProductDevicePicker: React.FC<IProps> = ({
    setSelectedOptions,
    selectedKeys,
    setSelectedKeys,
    inEditMode,
    multiSelectData
}) => {
    const dropDownRef = useRef(null);

    // state
    const [options, setOptions] = useState<IOptionProps[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedResults, setSearchedResults] = useState<IOptionProps[]>([]);

    // queries
    const { data, isLoading } = useQueryAllDeviceList();

    const formatIntoOptions = (data: IDeviceProps[]) => {
        let arr: IOptionProps[] = [{ key: 0, text: StringConstants.UPPERCASE_ALL }];
        let index = 1;
        data?.forEach((item) => {
            let childArray: number[] = [];
            let parentIndex = index;
            index = index + 1;
            item.devices.forEach((ele) => {
                childArray.push(index);
                index = index + 1;
            });
            arr.push({ key: parentIndex, text: item.name, dependentProperties: childArray });
            let tempIndex = parentIndex;
            tempIndex = tempIndex + 1;
            item.devices.forEach((ele) => {
                arr.push({ key: tempIndex, text: ele.name, parentProperty: parentIndex, type: ele.id });
                tempIndex += 1;
            });
        });
        return arr;
    };

    const checkEquality = (arr1: IOptionProps[], arr2: IOptionProps[]) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.filter((it) => it.key === arr1[i].key).length === 0) {
                return false;
            }
        }
        return true;
    };

    const onToggleShow = () => {
        setShowDropdown((value) => !value);
    };

    useDropDownDismiss(dropDownRef, onToggleShow);

    // use effects

    useEffect(() => {
        if (!isLoading) {
            setOptions(formatIntoOptions(data));
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (!showDropdown) setSearchValue('');
    }, [showDropdown]);

    useEffect(() => {
        if (searchValue.trim() !== '') {
            let arr: IOptionProps[] = [];
            options.forEach((item) => {
                if (item.text.toLowerCase().includes(searchValue.trim().toLowerCase())) {
                    arr.push(item);
                }
            });
            setSearchedResults(arr);
        }
    }, [searchValue]);

    // for autopopulation in edit mode
    useEffect(() => {
        if (multiSelectData.length > 0 && inEditMode && data?.length > 0) {
            const options = formatIntoOptions(data);
            const keys = options.filter((item: any) => multiSelectData.includes(item.text));

            setSelectedKeys(keys);
            setSelectedOptions(keys.map((key) => key.text));
            setOptions(options);
        }
    }, [inEditMode, multiSelectData, data]);
    
    useEffect(() => {
        if (
            options.length !== 0 &&
            selectedKeys?.length !== 0 &&
            checkEquality(
                selectedKeys,
                options.filter((it) => it.key !== 0)
            )
        ) {
            setSelectedKeys(options.slice(0));
        }
    }, [options, selectedKeys]);

    // For troubled products and product categories, the back-end query checks aginst the product name 
    // We must ensure that selected options is set to an array of selected product names 
    const onChange = (key: number, dependentProperties?: number[], parentProperty?: number) => {
        if (key === 0) {
            if (selectedKeys.filter((item) => item.key === key).length === 0) {
                let arr: IOptionProps[] = [];
                options.forEach((ele) => arr.push(ele));
                setSelectedKeys([...arr]);
                setSelectedOptions([...arr.filter((option) => option.text !== StringConstants.UPPERCASE_ALL).map((item) => item.text)]);
            } else {
                setSelectedKeys([]);
                setSelectedOptions(null);
            }
        } else if (!dependentProperties) {
            if (selectedKeys.filter((item) => item.key === key).length !== 0) {
                const tempSelectedKeys = selectedKeys.filter(
                    (item) => item.key !== key && item.key !== 0 && item.key !== parentProperty
                );

                setSelectedKeys(tempSelectedKeys);
                
                if (tempSelectedKeys.length > 0) {
                    setSelectedOptions(tempSelectedKeys.map((key) => key.text));
                } else {
                    setSelectedOptions(null);
                }
            } else {
                let count = 0;
                let parent = options.filter((item) => item.key === parentProperty)[0];
                let temp = parent.dependentProperties as number[];
                temp.forEach((ele) => {
                    if (selectedKeys.filter((item) => item.key === ele).length !== 0) {
                        count = count + 1;
                    }
                });
                if (count + 1 === temp.length) {
                    const tempSelectedKeys = [
                        ...selectedKeys,
                        options.filter((item) => item.key === key)[0],
                        options.filter((item) => item.key === parentProperty)[0]
                    ];

                    setSelectedKeys(tempSelectedKeys);

                    if (tempSelectedKeys.length > 0) {
                        setSelectedOptions(tempSelectedKeys.map((key) => key.text));
                    } else {
                        setSelectedOptions(null);
                    }
                } else {
                    const tempSelectedKeys = [...selectedKeys, options.filter((item) => item.key === key)[0]];

                    setSelectedKeys(tempSelectedKeys);

                    if (tempSelectedKeys.length > 0) {
                        setSelectedOptions(tempSelectedKeys.map((key) => key.text));
                    } else {
                        setSelectedOptions(null);
                    }
                }
            }
        } else {
            if (selectedKeys.filter((item) => item.key === key).length !== 0) {
                let arr = selectedKeys;
                dependentProperties.forEach((ele) =>
                    arr.splice(
                        arr.findIndex((element) => {
                            if (element.key === ele) return true;
                        }),
                        1
                    )
                );
                const tempSelectedKeys = arr.filter((ele) => ele.key !== key && ele.key !== 0);

                setSelectedKeys(tempSelectedKeys);

                if (tempSelectedKeys.length > 0) {
                    setSelectedOptions(tempSelectedKeys.map((key) => key.text));
                } else {
                    setSelectedOptions(null);
                }
            } else {
                let arr: IOptionProps[] = [];
                dependentProperties.forEach((item) => {
                    if (selectedKeys.filter((it) => it.key === item).length === 0)
                        arr.push(options.filter((ele) => ele.key === item)[0]);
                });
                const tempSelectedKeys = [...selectedKeys.concat(arr), options.filter((item) => item.key === key)[0]];

                setSelectedKeys(tempSelectedKeys);

                if (tempSelectedKeys.length > 0) {
                    setSelectedOptions(tempSelectedKeys.map((key) => key.text));
                } else {
                    setSelectedOptions(null);
                }
            }
        }
    };

    const isChecked = (ele: number) => {
        return selectedKeys.filter((item) => item.key === ele).length !== 0;
    };

    const removeDuplicates = (arr: string[]) => {
        let uniqueArr = arr.filter((ele, index) => {
            return arr.indexOf(ele) === index;
        });
        return uniqueArr;
    };

    const getLabel = () => {
        if (selectedKeys?.filter((ele) => ele.key === 0).length !== 0 || selectedKeys?.length === 0) {
            return <div className="spacing-container">Product</div>
        } else {
            let arr: string[] = [];
            selectedKeys.forEach((item) => {
                let element = options.filter((ele) => ele.key === item.key)[0];
                if (element?.parentProperty) {
                    if (selectedKeys.filter((ele) => ele.key === element?.parentProperty).length !== 0)
                        arr.push(options.filter((it) => it.key === element?.parentProperty)[0].text + ' - All');
                    else arr.push(element?.text);
                } else {
                    arr.push(element?.text + ' - All');
                }
            });
            let res = removeDuplicates(arr);
            return res.join(', ');
        }
    };

    return (
        <div className={`product-picker`}>
            <div
                className="product-title"
                tabIndex={0}
                data-testid="pdt-filter-open-btn-test"
                onKeyPress={(e: any) => {
                    keyPress(e, onToggleShow);
                }}
                onClick={() => onToggleShow()}>
                <Text className="product-label">{getLabel()}</Text> <Icon iconName="ChevronDown" />
            </div>
            {showDropdown && !isLoading && (
                <div ref={dropDownRef} className="product-dropdown-cont position-cont">
                    <div
                        tabIndex={0}
                        onKeyPress={(e: any) => {
                            keyPress(e, onToggleShow);
                        }}
                        data-testid="pdt-filter-close-btn-test"
                        onClick={() => onToggleShow()}
                        className="row-flex-box align-center justify-sb dropdown-header-lb">
                        <Text>Products & Models</Text>
                        <Icon iconName="ChevronUp" />
                    </div>
                    <div className="search-bar-product-device">
                        {selectedKeys.filter((ele) => ele.key === 0).length !== 0 ? (
                            <div className="product-cont">
                                <div className="product-item">
                                    <Text>All products</Text>{' '}
                                    <Icon
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                onChange(0);
                                            }
                                        }}
                                        data-testid="tag-all-test"
                                        onClick={() => onChange(0)}
                                        iconName="Cancel"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="product-cont">
                                {selectedKeys.map((ele) => (
                                    <div className="product-item">
                                        <Text>
                                            {ele.text}
                                            {ele.dependentProperties ? ' - All' : ''}
                                        </Text>
                                        <Icon
                                            tabIndex={0}
                                            onKeyPress={(e: any) => {
                                                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                                    onChange(
                                                        ele.key as number,
                                                        ele.dependentProperties as number[],
                                                        ele.parentProperty as number
                                                    );
                                                }
                                            }}
                                            onClick={() =>
                                                onChange(
                                                    ele.key as number,
                                                    ele.dependentProperties as number[],
                                                    ele.parentProperty as number
                                                )
                                            }
                                            iconName="Cancel"
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <SearchBox
                            placeholder="Search"
                            tabIndex={0}
                            data-testid="search-field-test"
                            onChange={(e: any) => {
                                if (e?.target?.value) setSearchValue(e?.target?.value);
                                else setSearchValue('');
                            }}
                        />
                    </div>
                    {searchValue.trim() !== '' ? (
                        <div>
                            {' '}
                            {searchedResults.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            );
                                        }
                                    }}
                                    className={`product-dropdown-item-lb ${
                                        item.parentProperty &&
                                        searchedResults.filter((ele) => ele.key === item.parentProperty).length !== 0
                                            ? 'child-item'
                                            : ''
                                    }  ${item.dependentProperties ? 'parent-item' : ''}`}>
                                    <Checkbox
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() =>
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            )
                                        }
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    ) : (
                        <div>
                            {options.map((item, index) => (
                                <div
                                    data-testid={`dropdown-item-test-${index}`}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            );
                                        }
                                    }}
                                    className={`product-dropdown-item-lb ${item.parentProperty ? 'child-item' : ''}  ${
                                        item.dependentProperties ? 'parent-item' : ''
                                    }`}>
                                    <Checkbox
                                        data-testid={`dropdown-checkbox-test-${index}`}
                                        tabIndex={0}
                                        label={item.text}
                                        checked={isChecked(item.key as number)}
                                        onChange={() =>
                                            onChange(
                                                item.key as number,
                                                item.dependentProperties as number[],
                                                item.parentProperty as number
                                            )
                                        }
                                    />
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const useDropDownDismiss = (ref: any, onToggleShow: Function) => {
    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleShow();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default ProductDevicePicker;
