import React from 'react';
import './ScreenBg.scss';
import bg from 'app/static/images/dots-min.svg';
import stripes from 'app/static/images/stripes.svg';
function ScreenBg(prop: any) {
    return (
        <div className={`screen-bg step0 ${prop.step}`}>
            <div className={`gradient-bg`}></div>
            <div className={`stripes1`}>
                <img src={stripes} alt="" />
            </div>
            <div className={`stripes2`}>
                <img src={stripes} alt="" />
            </div>
            <div className={`dotted-bg`}>
                <img src={bg} alt="" />
                <img src={bg} alt="" />
            </div>
            <div className={`stars`}>
                <div className={`stars1`}></div>
                <div className={`stars2`}></div>
                <div className={`stars3`}></div>
            </div>
        </div>
    );
}

export default ScreenBg;
