import React, { useEffect, useState } from 'react';
import './Picker.scss';
import { Dropdown, TextField, DatePicker } from '@fluentui/react';
import { pickerDropdownStyles, pickerTextFieldStyles } from '../../helpers/helpers';
import { useQueryAllCountryList } from 'app/services/queries';
import ProductDevicePicker from './pickerHelpers/ProductDevicePicker';
import AssignedToPicker from './pickerHelpers/AssignedToPicker';
import TagsPicker from './pickerHelpers/TagsPicker';
import AgingPicker from './pickerHelpers/AgingPicker';
import CountryPicker from './pickerHelpers/CountryPicker';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ListBuilderCondition } from 'app/enums/ListBuilderCondition.enum';
import { StringConstants } from 'app/utils/constants';

interface IProps {
    picker?: any;
    setPickerSelection?: any;
    fieldOption?: any;
    setPickerOption?: any;
    pickerOption?: any;
    selectedKeys: any[];
    setSelectedKeys: Function;
    selectedOptions: any[];
    setSelectedOptions: Function;
    inEditMode?: boolean;
    multiSelectData?: any[];
    pickerDropdownDisabled: boolean;
    pickerActive: boolean;
    conditionSelection: any;
}

const Picker: React.FC<IProps> = ({
    picker,
    //setPickerSelection,
    fieldOption,
    pickerOption,
    setPickerOption,
    setSelectedOptions,
    setSelectedKeys,
    selectedKeys,
    selectedOptions,
    inEditMode,
    multiSelectData,
    pickerDropdownDisabled,
    pickerActive,
    conditionSelection
}) => {
    const { data: countryData, isLoading: isCountryDataLoading } = useQueryAllCountryList();
    const [countryOptions, setCountryOptions] = useState<any[]>([]);

    const onChange = React.useCallback((event: React.FormEvent<HTMLDivElement>, option?: any, index?: number): void => {
        setPickerOption(option.enumValue);
        //commenting out for now per Andrew
        //setPickerSelection([{key: option.key, text: option.text, value: option.enumValue }])
    }, []);

    const onTextDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: any, index?: number): void => {
        setPickerOption(option.text);
        //commenting out for now per Andrew
        // setPickerSelection([{key: option.key, text: option.text, value: option.enumValue}]);
    };

    const onTextfieldChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
            if (newValue.length > 0) {
                setPickerOption(newValue);
            } else {
                setPickerOption(null);
            }
        },
        []
    );

    const onMultiSelectChange = (event: any, item: any) => {
        if (item.selected) {
            setSelectedKeys([...selectedKeys, item.key]);
            setSelectedOptions([...selectedOptions, item.enumValue]);
        } else {
            selectedOptions.splice(selectedOptions.indexOf(item.enumValue), 1);
            selectedKeys.splice(selectedKeys.indexOf(item.key), 1);
            setSelectedKeys([...selectedKeys]);
            
            if (selectedOptions.length > 0) {
                setSelectedOptions([...selectedOptions]);
            } else {
                setSelectedOptions([]);
            }
        }
    };

    const onNumberTextfieldChange = (values: NumberFormatValues) => {
        if (values.formattedValue.length > 0) {
            setPickerOption(values.floatValue);
        } else {
            setPickerOption(null);
        }
    };

    const onCssTextfieldChange = (values: NumberFormatValues) => {
        if (values.formattedValue.length > 0) {
            setPickerOption(values.formattedValue);
        } else {
            setPickerOption(null);
        }
    };

    const onBoolDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: any, index?: number): void => {
        setPickerOption(option.boolValue);
    };

    useEffect(() => {
        if (!isCountryDataLoading) {
            setCountryOptions(
                countryData?.map((country: any) => {
                    return { key: country.id, text: country.name };
                })
            );
        }
    }, [countryData, isCountryDataLoading]);

    useEffect(() => {
        if (selectedOptions !== null && selectedOptions.length > 0) {
            setPickerOption(selectedOptions);
        } else {
            setPickerOption(null);
        }
    }, [selectedOptions]);

    const handlePickerComponent = () => {
        switch (fieldOption) {
            case 'Status':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            className="lb-multi-picker"
                            onChange={onMultiSelectChange}
                            placeholder="Status"
                            styles={pickerDropdownStyles}
                            options={picker}
                            selectedKeys={selectedKeys}
                            calloutProps={{
                                className: "lb-multi-picker-callout"
                            }}
                            multiSelect
                        />
                    </div>
                );
            case 'Title':
                return (
                    <div className="picker-textfield-cont">
                        <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                    </div>
                );
            case 'Created On':
                return (
                    <DatePicker
                        className="datePickerStyles"
                        onSelectDate={(date) => {
                            setPickerOption(date);
                        }}
                        value={pickerOption !== null ? new Date(pickerOption) : null}
                    />
                );
            case 'Submitted By':
                return (
                    <div className="picker-textfield-cont">
                        <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                    </div>
                );
            case 'Assigned To':
                return (
                    <div className="picker-search-box">
                        <AssignedToPicker
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            multiSelectData={multiSelectData}
                            inEditMode={inEditMode}
                            isSubmitterPicker={false}
                        />
                    </div>
                );
            case 'Submitter':
                return (
                    <div className="picker-search-box">
                        <AssignedToPicker
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            multiSelectData={multiSelectData}
                            inEditMode={inEditMode}
                            isSubmitterPicker={true}
                        />
                    </div>
                );
            case 'Tags':
                return (
                    <div className="picker-search-box">
                        <TagsPicker
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            setSelectedOptions={setSelectedOptions}
                            selectedOptions={selectedOptions}
                            inEditMode={inEditMode}
                            multiSelectData={multiSelectData}
                        />
                    </div>
                );
            case 'Description':
                return (
                    <div className="picker-textfield-cont">
                        <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                    </div>
                );
            case 'Troubled Product':
                return (
                    <div className="picker-product-box">
                        <ProductDevicePicker
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            multiSelectData={multiSelectData}
                            inEditMode={inEditMode}
                        />
                    </div>
                );
            case 'MSX Opportunity':
                return (
                    <div className="picker-textfield-cont">
                        {conditionSelection?.enumValue === ListBuilderCondition.State ? 
                            <Dropdown 
                                options={[{ key: 0, text: StringConstants.HAS_MSX_OPP }, { key: 1, text: StringConstants.NO_MSX_OPP }]}
                                styles={pickerDropdownStyles}
                                onChange={onTextDropdownChange}
                            />
                            :
                            <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                        }
                    </div>
                );
            case 'MSX Status':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            onChange={onTextDropdownChange}
                            styles={pickerDropdownStyles}
                            placeholder="Status"
                            options={picker}
                            selectedKey={selectedKeys[0]}
                        />
                    </div>
                );
            case 'TPID':
                return (
                    <div className="picker-textfield-cont">
                        <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                    </div>
                );
            case 'End customer':
                return (
                    <div className="picker-textfield-cont">
                        <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                    </div>
                );
            case 'Customer country':
                return (
                    <div className="picker-search-box">
                        <CountryPicker
                            selectedKeys={selectedKeys}
                            setSelectedKeys={setSelectedKeys}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            multiSelectData={multiSelectData}
                            inEditMode={inEditMode}
                        />
                    </div>
                );
            case 'MSX revenue':
            case 'Total Revenue Risk':
                return (
                    <div className="numberFormatWrapper">
                        <NumberFormat
                            thousandSeparator={true}
                            value={pickerOption === null ? '' : pickerOption}
                            onValueChange={onNumberTextfieldChange}
                            placeholder="$"
                            allowNegative={false}
                            inputMode="numeric"
                            className="num-format"
                            decimalScale={2}
                        />
                    </div>
                );
            case 'Customer Count':
            case 'CXP Priority Number':
            case 'MSX devices':
            case 'Total Device Risk':
            case 'DCF Value':
                return (
                    <div className="numberFormatWrapper">
                        <NumberFormat
                            thousandSeparator={true}
                            value={pickerOption === null ? '' : pickerOption}
                            onValueChange={onNumberTextfieldChange}
                            allowNegative={false}
                            inputMode="numeric"
                            className="num-format"
                            decimalScale={2}
                        />
                    </div>
                );
            case 'Relationship':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            onChange={onMultiSelectChange}
                            placeholder="Relationship"
                            styles={pickerDropdownStyles}
                            options={picker}
                            selectedKeys={selectedKeys}
                            calloutProps={{
                                className: "lb-multi-picker-callout"
                            }}
                            multiSelect
                        />
                    </div>
                );
            case 'Attachment':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            placeholder="State"
                            styles={pickerDropdownStyles}
                            onChange={onChange}
                            options={picker}
                            selectedKey={selectedKeys[0]}
                        />
                    </div>
                );
            case 'Is DFS (design for surface) possible':
                return <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />;
            case 'Bug Type':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            placeholder="Bug"
                            styles={pickerDropdownStyles}
                            options={picker}
                            selectedKeys={selectedKeys}
                            onChange={onMultiSelectChange}
                            calloutProps={{
                                className: "lb-multi-picker-callout"
                            }}
                            multiSelect
                        />
                    </div>
                );
            case 'Aging':
                return (
                    <AgingPicker
                        setSelectedOptions={setSelectedOptions}
                        multiSelectData={multiSelectData}
                        inEditMode={inEditMode}
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                    />
                );
            case 'Bug Number':
                return (
                    <div className="picker-textfield-cont">
                        <TextField styles={pickerTextFieldStyles} onChange={onTextfieldChange} value={pickerOption} />
                    </div>
                );
            case 'CSS case number':
                return (
                    <div className="numberFormatWrapper">
                        {conditionSelection?.enumValue === ListBuilderCondition.State ? 
                            <Dropdown 
                                options={[{ key: 0, text: StringConstants.HAS_CSS_CASE }, { key: 1, text: StringConstants.NO_CSS_CASE }]}
                                styles={pickerDropdownStyles}
                                onChange={onTextDropdownChange}
                            />
                            :
                            <NumberFormat
                                value={pickerOption === null ? '' : pickerOption}
                                onValueChange={onCssTextfieldChange}
                                allowNegative={false}
                                inputMode="numeric"
                                className="num-format"
                            />
                        }
                    </div>
                );
            case 'Is This a DCF Request':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            placeholder="Yes/No"
                            styles={pickerDropdownStyles}
                            options={picker}
                            onChange={onBoolDropdownChange}
                            selectedKey={selectedKeys[0]}
                        />
                    </div>
                );
            case 'Proof of purchase attached':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            placeholder="Yes/No"
                            styles={pickerDropdownStyles}
                            options={picker}
                            onChange={onBoolDropdownChange}
                            selectedKey={selectedKeys[0]}
                        />
                    </div>
                );
            case 'Priority':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            placeholder="Priority"
                            onChange={onMultiSelectChange}
                            styles={pickerDropdownStyles}
                            options={picker}
                            selectedKeys={selectedKeys}
                            calloutProps={{
                                className: "lb-multi-picker-callout"
                            }}
                            multiSelect
                        />
                    </div>
                );
            case 'Serial number attached':
                return (
                    <div className="picker-dropdown-cont">
                        <Dropdown
                            placeholder="Yes/No"
                            styles={pickerDropdownStyles}
                            options={picker}
                            onChange={onBoolDropdownChange}
                            selectedKey={selectedKeys[0]}
                        />
                    </div>
                );
            default:
                return <div>this</div>;
        }
    };

    return (
        <div>
            <div className={`${!pickerDropdownDisabled ? `${pickerActive ? 'field-active' : ''}` : 'this-field-text'}`}>
                {!pickerDropdownDisabled ? 
                    handlePickerComponent()
                    :
                    (<div>this</div>)
                }
            </div>
        </div>
    );
};

export default Picker;
