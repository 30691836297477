/* eslint-disable no-console */
import { PublicClientApplication } from '@azure/msal-browser';
import { getAccessToken, getMSGraphTokenSilently, getPowerBITokenSilently } from 'app/components/helpers';
import { loginRequest, msalConfig } from 'authConfig';
import axios from 'axios';
import { API } from '../../utils/constants';

export const apiClient = axios.create({
    baseURL: API.API_SERVER_URL,
    responseType: 'json',
    withCredentials: false
});

export const apiClientJSON = axios.create({
    baseURL: API.API_SERVER_URL,
    responseType: 'json',
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json'
    }
});

const updateHeaders = (config: any, token: string) => {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers[`Content-Type`] = 'application/json';
    return config;
};
const msalInstance = new PublicClientApplication(msalConfig);
const account = msalInstance.getAllAccounts()[0];

const fetchToken = async (request: any) => {
    if (account) {
        return getAccessToken('SILENT', msalInstance, loginRequest.scopes, account)
            .then((accessTokenResponse) => {
                const config = updateHeaders(request, accessTokenResponse.accessToken);
                localStorage.setItem('accessToken', accessTokenResponse.accessToken);
                getMSGraphTokenSilently(msalInstance, account).then((accessToken) => {
                    if (accessToken) localStorage.setItem('msGraphAccessToken', accessToken);
                });
                getPowerBITokenSilently(msalInstance, account, loginRequest.powerBiScope).then((response) => {
                    if (response) localStorage.setItem('powerBIToken', response);
                });
                return config;
            })
            .catch(() => {
                msalInstance.acquireTokenRedirect(loginRequest);
            });
    } else {
        msalInstance.acquireTokenRedirect(loginRequest);
    }
};

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error?.response && (error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true;
            const config = await fetchToken(originalRequest);
            return apiClient(config);
        } else if (error?.request) {
            if (localStorage.getItem('accessToken')) {
                await fetchToken(originalRequest);
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }
);

apiClient.interceptors.request.use(
    (config) => {
        const updatedConfig = {
            ...config
        };
        const token = localStorage.getItem('accessToken');
        if (token) {
            return updateHeaders(updatedConfig, token);
        }
        return updatedConfig;
    },
    () => {
        msalInstance.acquireTokenRedirect(loginRequest);
    }
);
apiClientJSON.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error?.response && (error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true;
            const config = await fetchToken(originalRequest);
            return apiClient(config);
        } else if (error.request) {
            if (localStorage.getItem('accessToken')) {
                await fetchToken(originalRequest);
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    }
);

apiClientJSON.interceptors.request.use(
    (config) => {
        const updatedConfig = {
            ...config
        };
        const token = localStorage.getItem('accessToken');
        if (token) {
            return updateHeaders(updatedConfig, token);
        }
        return updatedConfig;
    },
    () => {
        msalInstance.acquireTokenRedirect(loginRequest);
    }
);
