import React, { useEffect, useState } from 'react';
import { Text, Image, Link, Modal, TextField, TooltipHost, Icon, PersonaInitialsColor } from '@fluentui/react';

import './NewsCard.scss';
import { IArticleData, IMenuOption, ITags, IUserDetails, IArticleTemplateData } from 'app/models/common/post';
import useStore from 'app/store';
import placeholderCategoryIcon from 'app/static/icons/category-icon-Placeholder.svg';
import MoreOptions from 'app/components/moreOptions/MoreOptions';
import Button from '../../../../components/button/Button';
import { ArticleStatus } from 'app/enums/Status.enum';
import { useHistory, useLocation } from 'react-router-dom';
import { BlobStorageContainer, Constants, Entity, RouteConstants, StringConstants } from 'app/utils/constants';
import { useGetArticle, useGenerateFileSasToken, useGetArticleTemplates } from 'app/services/queries';
import * as _ from 'lodash';
import { STATUS_OPTIONS } from 'app/utils/statusOptions';
import { isContentManager, getRoles, isSuperAdmin } from 'app/utils/authUtilities';
import { useCreateArticleTemplate, useDraftArticle, useReDraftArticle } from 'app/services/mutations';
import { Trans, useTranslation } from 'react-i18next';
import { ArticleType } from 'app/enums/ArticleType.enum';
import {
    changeDateFormat,
    dateFormatter,
    getParagraphFromContent,
    keyPress,
    setQueryParam
} from 'app/components/helpers';
import { UserDomain } from 'app/enums/UserDomain.enum';
import Avatar from 'app/components/avatar/Avatar';
import { Loader } from 'app/components/loader/Loader';
import { getBlobFileNameFromString } from 'app/utils/blobStorageHelpers';
import { getArticleCategorySvg, getArticleCategorySvgComponent } from 'app/utils/SVGIcons';

interface IProps {
    data: any;
    size?: string;
    status?: ArticleStatus;
    deleteArticleAPI?: (id: string) => any;
    removeArticleAPI?: (id: string) => any;
    getArticlesAPI?: () => any;
    hideCoverImage?: boolean;
    hideDescription?: boolean;
    className?: string;
    showMoreOptions?: boolean;
    showStatus?: boolean;
    showLastEdited?: boolean;
    showArticleType?: boolean;
    tabIndex?: number;
    border?: boolean;
}

const NewsCard: React.FC<IProps> = (props: IProps) => {
    const {
        size,
        data,
        hideCoverImage = false,
        hideDescription = false,
        deleteArticleAPI,
        removeArticleAPI,
        getArticlesAPI,
        className,
        showMoreOptions = true,
        showStatus = true,
        showLastEdited = true,
        showArticleType = true,
        tabIndex,
        border = true
    } = props;
    type Action = 'Delete' | 'Remove' | '' | 'Save as template' | 'Move to draft' | 'Publish' | 'Submit for Review' | 'Duplicate';

    const { t: translate } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const setSelectedTag = useStore((state: any) => state.setSelectedTag);
    const setEditArticleId = useStore((state: any) => state.setEditArticleId);
    const articleCategories: Array<ITags> = useStore((state: any) => state?.articleCategories);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const [selectedArticleId, setSelectedArticleId] = useState<string>();
    const [optionsList, setOptionsList] = useState([]);
    const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
    const [showRedraftModal, setShowRedraftModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [categoryIconComponent, setCategoryIconComponent] = useState<React.ReactNode>(undefined);
    const [selectedArticle, setSelectedArticle] = useState<string>('');
    const [note, setNote] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [action, setAction] = useState<Action>('');
    const [description, setDescription] = useState('');

    const { refetch: getArticleAPI, data: articleDetail, isLoading } = useGetArticle(selectedArticleId);
    const { refetch: getArticleTemplates, data: articleTemplates, isLoading: isGettingTemplates } = useGetArticleTemplates();
    const { mutateAsync: saveAsDraft } = useDraftArticle();
    const { mutateAsync: reDraftArticle } = useReDraftArticle();
    const { mutateAsync: saveAsTemplate, isLoading: isSaving } = useCreateArticleTemplate();

    const tags = data?.tags;
    const displayTag = tags?.length > 2 ? tags?.slice(0, 2) : tags;
    const moreTags = tags?.length > 2 ? tags?.slice(2, tags?.length) : [];
    const content = getParagraphFromContent(data?.content);
    const placeholderThumbnail =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgoAAAD6CAQAAABqxafuAAACUUlEQVR42u3UIQEAAAzDsM+/6dNJGEgkFDQHUCIBYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAGAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCgCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgBgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAKAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCYAqAKQCmAGAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAmAKgCkApgCYAoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCoApAKYAmAJgCgCmAJgCYAqAKQCmAJgCYAqAKQCmAJgCsOQBY8UA+3FrQYEAAAAASUVORK5CYII=';

    const hideSaveTemplateModal = () => {
        setShowSaveTemplateModal(false);
        setTemplateName('');
        setDescription(null);
        setSelectedArticleId(null);
    };

    const hideRedraftModal = () => {
        setShowRedraftModal(false);
        setNote('');
    };
    const showRedraftModalPopup = () => setShowRedraftModal(true);

    const noteValue = (value: string) => setNote(value);

    const setTemplateNameFun = (e: any) => setTemplateName(e.target.value);

    const showSaveAsTemplateModal = () => setShowSaveTemplateModal(true);

    useEffect(() => {
        setDescription(articleDetail?.content);
    }, [articleDetail]);

    useEffect(() => {
        if (selectedArticleId && action === 'Duplicate')
            getArticleAPI().then((res) => {
                history.push({
                    pathname: `/manage-content/article/create`,
                    state: { template: res.data, action: 'duplicate' }
                });
            });
    }, [getArticleAPI, history, selectedArticleId]);

    useEffect(() => {
        const statusOptionsList = STATUS_OPTIONS.find(({ ROLE }) => getRoles(userDetails?.userRoles).includes(ROLE))?.OPTIONS;
        const options = statusOptionsList?.find(({ STATUS }) => STATUS === ArticleStatus[data.status])?.MORE_OPTION;
        setOptionsList(options);
        return () => {
            setOptionsList([]);
        };
    }, [data, userDetails?.userRoles]);

    const getCategoryById = (tagId: string) => {
        var matchingTag = articleCategories?.find((item) => {
            return item.id === tagId;
        });
        return matchingTag;
    };

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const showConformationModal = () => {
        setShowConfirmationModal(true);
    };

    const deleteArticle = (id: string) => deleteArticleAPI(id);

    const duplicateArticle = (id: string) => setSelectedArticleId(id);

    const removeArticle = (id: string) => removeArticleAPI(id);
    const editArticle = (id: string) => {
        history.push(`/manage-content/article/edit/${id}`);
        setEditArticleId(id);
    };

    const createTemplate = () => {
        if (!isSaving) {
            const { coverPhoto, thumbnail } = data;
            const template: IArticleTemplateData = {
                displayName: templateName,
                content: description,
                coverPhoto: coverPhoto,
                thumbnail: thumbnail
            };
            saveAsTemplate(template).then(() => hideSaveTemplateModal());
        }
    };

    const moveToDraft = (id: string) => {
        saveAsDraft(id).then(() => getArticlesAPI());
    };

    const actionMapper = {
        Edit: {
            action: editArticle,
            icon: 'ms-Icon ms-Icon--PageEdit'
        },
        Delete: {
            action: (id: string) => {
                setSelectedArticle(id);
                setAction('Delete');
                showConformationModal();
            },
            icon: 'ms-Icon ms-Icon--Delete'
        },
        Remove: {
            action: (id: string) => {
                setSelectedArticle(id);
                setAction('Remove');
                showConformationModal();
            },
            icon: 'ms-Icon ms-Icon--PageRemove'
        },
        Duplicate: {
            action: (id: string) => {
                setAction('Duplicate');
                duplicateArticle(id);
            },
            icon: 'ms-Icon ms-Icon--Copy'
        },
        'Save as template': {
            action: (id: string) => {
                setSelectedArticleId(id);
                getArticleTemplates();
                showSaveAsTemplateModal();
            },
            icon: 'ms-Icon ms-Icon--SaveTemplate'
        },
        'Move to draft': {
            action:
                isContentManager(userDetails?.userRoles) || isSuperAdmin(userDetails?.userRoles)
                    ? showRedraftModalPopup
                    : moveToDraft,
            icon: 'ms-Icon ms-Icon--Undo'
        }
    };

    const sendArticleBackToDraftWithNote = () => {
        if (note && note.trim() !== '')
            reDraftArticle({ articleId: data.id, reviewNotes: note }).then((res) => {
                hideRedraftModal();
                getArticlesAPI();
            });
    };

    const formatArticleDetailLink = (articleId: string) => {
        if (data?.type === ArticleType.Training && (data as IArticleData)?.status === ArticleStatus.Published) {
            history.push({ pathname: '/portal-training', state: articleId });
        } else {
            history.push(`/article/${articleId}`);
        }
    };

    const setArticleDetails = () => {
        formatArticleDetailLink(data.id);
    };

    const menuOptionsList: Array<IMenuOption> = optionsList?.map((option: keyof typeof actionMapper) => {
        return {
            title: option,
            handleAction: actionMapper[option]?.action,
            id: data.id,
            icon: '',
            iconClass: actionMapper[option]?.icon
        };
    });

    const formatTags = (moreTags: Array<ITags>) => {
        return moreTags.map((tag: ITags) => ({
            title: tag.displayName,
            id: tag.id,
            handleAction: () => handleTagClick(tag),
            icon: getCategoryById(tag.id)?.icon ? getCategoryById(tag.id)?.icon : placeholderCategoryIcon
        }));
    };

    const knowledgeArticlePaths = ['/knowledge-articles/home', RouteConstants.ALL_ARTICLES, RouteConstants.SEARCH_RESULTS_ARTICLES];
    const newsArticlePaths = ['/news-feed/home', RouteConstants.ALL_NEWS, RouteConstants.SEARCH_RESULTS_NEWS];

    const handleTagClick = (data: any) => {
        setSelectedTag(data);
        const prefixPath =
            knowledgeArticlePaths.includes(pathname) || pathname.split('/')[1] === StringConstants.KNOWLEDGE_ARTICLES
                ? '/knowledge-articles'
                : newsArticlePaths.includes(pathname) || pathname.split('/')[1] === 'news'
                ? '/news'
                : '/articles';
        const suffixPath = data.displayName !== 'All Categories' ? data.displayName : '';
        setQueryParam(history, prefixPath, { tag: suffixPath });
    };

    const getLastEditedDate = (articleInfo: IArticleData) => {
        const { createdOn, modifiedOn, scheduledPublishOn, status } = articleInfo;
        let date;
        let time;
        switch (status) {
            case ArticleStatus.ScheduledToPublish:
                date = changeDateFormat(scheduledPublishOn, Constants.DATE_FORMAT);
                time = changeDateFormat(scheduledPublishOn, Constants.TIME_FORMAT);
                break;
            case ArticleStatus.Published:
            case ArticleStatus.Removed:
            default:
                date = modifiedOn
                    ? changeDateFormat(modifiedOn, Constants.DATE_FORMAT)
                    : changeDateFormat(createdOn, Constants.DATE_FORMAT);
                time = modifiedOn
                    ? changeDateFormat(modifiedOn, Constants.TIME_FORMAT)
                    : changeDateFormat(createdOn, Constants.TIME_FORMAT);
                break;
        }
        return `${dateFormatter(date)}  ${time}`;
    };

    const getText = (articleInfo: IArticleData) => {
        const { status } = articleInfo;
        switch (status) {
            case ArticleStatus.ScheduledToPublish:
                return `Scheduled for`;
            case ArticleStatus.Published:
                return `Published on`;
            case ArticleStatus.Removed:
                return `Removed on`;
            default:
                return `Last Edited`;
        }
    };

    const getNewsCardCategoryName = () => {
        return data?.tags?.slice(0, 1)[0]?.tagHierarchy?.split('>')[0].trim();
    };

    const getCategoryByName = (tagName: string) => {
        var matchingCategory = articleCategories?.find((item) => {
            return item.displayName === tagName;
        });
        return matchingCategory;
    };

    const renderStatus = (article: IArticleData) => {
        return (
            <div className="status">
                <span
                    className={`capsule capsule--${
                        article?.status === 1 && article?.reviewNotes
                            ? ArticleStatus[ArticleStatus.Redrafted]
                            : ArticleStatus[article?.status]
                    }`}>
                    {article?.status === ArticleStatus['Draft'] && article?.reviewNotes
                        ? ArticleStatus[ArticleStatus.Redrafted]
                        : _.capitalize(_.startCase(ArticleStatus[article?.status]))}
                </span>
            </div>
        );
    };

    const renderArticleType = (articleType: ArticleType) => {
        return (
            <span>
                {data?.type === ArticleType.KnowledgeArticle && (
                    <Icon className="c-default m-r-5 m-t-8" iconName="KnowledgeArticle" title="Knowledge Article" />
                )}
                {data?.type === ArticleType.News && <Icon className="c-default m-r-5 m-t-9" iconName="News" title="News" />}
                {data?.type === ArticleType.Training && (
                    <Icon className="c-default m-r-5 m-t-9" iconName="D365TalentLearn" title="Training" />
                )}
            </span>
        );
    };

    const renderConfirmation = () => {
        return (
            <Modal isOpen={showConfirmationModal} onDismiss={hideConfirmationModal} isBlocking={false} className="surface-modal">
                <div className="modal-header">
                    <span>
                        <Trans>Alert</Trans>
                    </span>
                    <span
                        className="close"
                        tabIndex={0}
                        onKeyPress={(e: any) => keyPress(e, hideConfirmationModal)}
                        onClick={hideConfirmationModal}>
                        X
                    </span>
                </div>
                <div className="modal-body">
                    <Text>Are you sure you want to {action.toLowerCase()}?</Text>
                </div>
                <div className="modal-footer">
                    <Button
                        buttonClass="btn"
                        buttonText={translate('Cancel')}
                        onClick={() => {
                            hideConfirmationModal();
                            setAction('');
                        }}
                    />
                    <Button
                        buttonClass="btn btn--green"
                        buttonText="Confirm"
                        onClick={
                            action === 'Delete'
                                ? () => deleteArticle(selectedArticle)
                                : action === 'Remove'
                                ? () => removeArticle(selectedArticle)
                                : null
                        }
                    />
                </div>
            </Modal>
        );
    };

    useEffect(() => {
        let iconUrl = getCategoryByName(getNewsCardCategoryName())?.icon
        let iconComponent = getArticleCategorySvgComponent(iconUrl);
        setCategoryIconComponent(iconComponent);
    }, [data]);

    const { data: tokenizedThumbnail } = useGenerateFileSasToken(Entity.ARTICLE, BlobStorageContainer.ARTICLE_FILES, getBlobFileNameFromString(data?.thumbnail));

    return (
        <div
            className={`news-card ${border ? '' : 'no-border'} ${className ? className : ''} ${size ? size : ''} article-card`}
            tabIndex={tabIndex}>
            {!hideCoverImage && (
                <div className={`card-img default-newscard bg--gray-700`}>
                    {showStatus && renderStatus(data)}
                    <div
                        className={`img-area ${!(data?.thumbnail && data?.thumbnail?.length > 10) ? 'placeholder' : ''}`}
                        onClick={setArticleDetails}
                        onKeyPress={(e) => keyPress(e, setArticleDetails)}
                        tabIndex={0}
                        aria-label={data.title}
                        role="link"
                        data-testid="article-thumbnail">
                        {data?.thumbnail && data?.thumbnail?.length > 10 ? (
                            <img src={tokenizedThumbnail} alt="thumbnail" />
                        ) : (
                            <>
                                <img src={placeholderThumbnail} alt="thumbnail" />
                                <span className="icon">{categoryIconComponent}</span>
                            </>
                        )}
                    </div>
                </div>
            )}
            <div className="card-details">
                <Text className="date">
                    {changeDateFormat(data.modifiedOn ? data.modifiedOn : data.createdOn, Constants.DATE_FORMAT)}
                </Text>
                <div className={`d-flex color-gray--300`}>
                    {showArticleType && renderArticleType(data?.type)}
                    <h4>
                        <Link role="link" tabIndex={0} onClick={setArticleDetails} data-testid="set-articles">
                            {data.title}
                        </Link>
                    </h4>
                </div>
                {displayTag?.length > 0 && (
                    <div className="tags">
                        {displayTag &&
                            displayTag.length > 0 &&
                            displayTag.map((tag: ITags) => {
                                return (
                                    <TooltipHost key={tag.id} content={tag.displayName} id={tag.id}>
                                        <Link
                                            tabIndex={0}
                                            aria-describedby={tag.id}
                                            className="tag tag--sc"
                                            key={tag.id}
                                            onClick={() => handleTagClick(tag)}
                                            data-testid="tag">
                                            {
                                                <Image
                                                    className="icon"
                                                    src={
                                                        getCategoryById(tag.id)?.icon
                                                            ? getArticleCategorySvg(getCategoryById(tag.id)?.icon)
                                                            : placeholderCategoryIcon
                                                    }
                                                    alt="switching out"
                                                />
                                            }
                                            <span>{tag.displayName}</span>
                                        </Link>
                                    </TooltipHost>
                                );
                            })}
                        {moreTags !== undefined && moreTags.length > 0 && (
                            <MoreOptions pos="left top" menuArray={formatTags(moreTags)} fuIconName="More" />
                        )}
                    </div>
                )}

                {!hideDescription && (
                    <div className="description">
                        <div
                            className="card-description text-lines text-lines--3"
                            dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                )}
                <div className="card-footer">
                    {!data.shouldHideAuthorInfo && (
                        <div className="author-data ">
                            <Avatar
                                userId={data?.createdBy?.id}
                                userName={data?.createdBy?.displayName}
                                initialColor={PersonaInitialsColor.teal}
                                imageSize={24}
                                roundedPic={true}
                            />

                            <Link
                                data-testid="author-data"
                                role="link"
                                className="profile-name"
                                onClick={() => history.push(`/news-feed/author/${data.createdBy?.id}`)}>
                                {data.createdBy?.displayName}
                            </Link>
                        </div>
                    )}
                    {data.createdBy?.UserDomain > 0 && (
                        <span className="capsule capsule--green">{UserDomain[data.createdBy?.UserDomain]}</span>
                    )}

                    <div className="last-modified-details">
                        {showLastEdited && <div className="last-modified">{`${getText(data)} ${getLastEditedDate(data)}`}</div>}

                        {showMoreOptions && <MoreOptions pos="left top" menuArray={menuOptionsList} fuIconName="MoreVertical" />}
                        {renderConfirmation()}

                        <Modal
                            isOpen={showRedraftModal}
                            onDismiss={hideRedraftModal}
                            isBlocking={false}
                            className="surface-modal">
                            <div className="modal-header">
                                <span>
                                    <Trans>Send back to draft with Note.</Trans>
                                </span>
                                <span
                                    className="close"
                                    tabIndex={0}
                                    onKeyPress={(e: any) => keyPress(e, hideRedraftModal)}
                                    onClick={hideRedraftModal}>
                                    X
                                </span>
                            </div>
                            <div className="modal-body">
                                <TextField
                                    multiline
                                    label="Enter Note"
                                    onChange={(e: any) => {
                                        noteValue(e.target.value);
                                    }}
                                    rows={6}
                                    autoFocus
                                />
                            </div>
                            <div className="modal-footer">
                                <Button
                                    buttonClass="btn btn--green"
                                    buttonText={translate('Redraft')}
                                    onClick={() => {
                                        sendArticleBackToDraftWithNote();
                                    }}
                                    disabled={note.trim().length <= 0}
                                />
                            </div>
                        </Modal>

                        <Modal
                            isOpen={showSaveTemplateModal}
                            onDismiss={hideSaveTemplateModal}
                            isBlocking={false}
                            className="surface-modal">
                            <div className="modal-header">
                                <span>
                                    <Trans>Save Template</Trans>
                                </span>
                                <span
                                    className="close"
                                    tabIndex={0}
                                    onKeyPress={(e: any) => keyPress(e, hideSaveTemplateModal)}
                                    onClick={hideSaveTemplateModal}>
                                    X
                                </span>
                            </div>
                            {!(isGettingTemplates || isLoading) ? (
                                <>
                                    <div className="modal-body">
                                        <TextField
                                            label={translate('Enter Template Name')}
                                            name="templateName"
                                            onChange={(e) => setTemplateNameFun(e)}
                                            errorMessage={
                                                articleTemplates?.find(
                                                    (item: IArticleTemplateData) => item.displayName === templateName
                                                )
                                                    ? 'Template name already exists'
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <Button
                                            buttonClass="btn width-5"
                                            buttonText={translate('Cancel')}
                                            onClick={hideSaveTemplateModal}
                                        />
                                        <Button
                                            buttonClass="btn btn--green width-5"
                                            buttonText={isSaving ? 'Saving...' : 'Save'}
                                            onClick={createTemplate}
                                            disabled={
                                                templateName.trim().length === 0 ||
                                                articleTemplates?.find((item: any) => item.displayName === templateName)
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <Loader />
                            )}
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsCard;
