/* eslint-disable */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            'For example: My customer needs more attention on a support case':
                'For example: My customer needs more attention on a support case',
            'All Requests': 'All Requests',
            'Show Photo & Name': 'Show Photo & Name',
            'News Article': 'News Article',
            'Knowledge Article': 'Knowledge Article',
            minute: 'minute',
            minutes: 'minutes',
            read: 'read',
            About: 'About',
            of: 'of',
            'Upload & manage training content': 'Upload & manage training content',
            'Create, manage & publish news': 'Create, manage & publish news',
            Attach: 'Attach',
            'Files are attached successfully': 'Files are attached successfully',
            'Please select any file to attach.': 'Please select any file to attach.',
            'Adding attachments. Please wait...': 'Adding attachments. Please wait...',
            'For example: Attention required': 'For example: Attention required',
            'Your Comment': 'Your Comment',
            'This Field is Mandatory.': 'This Field is Mandatory.',
            'Add Comment For': 'Add Comment For',
            'Assign To': 'Assign To',
            'Sorry, we could not find anything that matches your search :(':
                'Sorry, we could not find anything that matches your search :(',
            'Related Results': 'Related Results',
            'Failed to upload some of the files': 'Failed to upload some of the files',
            'Add Attachments': 'Add Attachments',
            'Post Sales': 'Post Sales',
            'Pre Sales': 'Pre Sales',
            'CSS Review Request': 'CSS Review Request',
            'CSS Request': 'CSS Request',
            'Variant Request': 'Variant Request',
            Question: 'Question',
            Proposal: 'Proposal',
            Other: 'Other',
            'Task Required': 'Task Required',
            'Escalation Reassignment': 'Escalation Reassignment',
            Misroute: 'Misroute',
            'The notification is successfully sent': 'The notification is successfully sent',
            'Go back to': 'Go back to',
            'your request, please try again': 'your request, please try again',
            'An error occured while': 'An error occured while',
            'The request is listed under': 'The request is listed under',
            assign: 'assign',
            reassign: 'reassign',
            'The request is successfully': 'The request is successfully',
            'Please wait, while we are processing your request': 'Please wait, while we are processing your request',
            'See Request Details': 'See Request Details',
            'Current Request Type': 'Current Request Type',
            'Current Team': 'Current Team',
            'Assign Request': 'Assign Request',
            'Request Reassignment': 'Request Reassignment',
            'Customize Columns': 'Customize Columns',
            Resolved: 'Resolved',
            Active: 'Active',
            New: 'New',
            Edit: 'Edit',
            'Visual Flow': 'Visual Flow',
            'Reassign Team': 'Reassign Team',
            Assign: 'Assign',
            Stats: 'Stats',
            Current: 'Current',
            'Support Escalation': 'Support Escalation',
            'Search via ID or Request Title': 'Search via ID or Request Title',
            'Content Manager': 'Content Manager',
            'Content Creator': 'Content Creator',
            'End User': 'End User',
            Admin: 'Admin',
            'Super Admin': 'Super Admin',
            'Past 6 Month': 'Past 6 Month',
            'Past Month': 'Past Month',
            'Past Week': 'Past Week',
            Today: 'Today',
            'All Dates': 'All Dates',
            'Review Requests': 'Review Requests',
            Quantity: 'Quantity',
            'Select effected models': 'Select effected models',
            'Select the product family of effected products': 'Select the product family of effected products',
            'Alternate Contact Details': 'Alternate Contact Details',
            'Shippping Details': 'Shippping Details',
            'CSS Case Number': 'CSS Case Number',
            'Customer Info': 'Customer Info',
            'Issue Category': 'Issue Category',
            'Fund Utilization:': 'Fund Utilization:',
            'Discretionary Commercial Fund (DCF):': 'Discretionary Commercial Fund (DCF):',
            'Add more files': 'Add more files',
            'File size : Any size is acceptable': 'File size : Any size is acceptable',
            'Supported file/document format: All Types': 'Supported file/document format: All Types',
            Upload: 'Upload',
            'Drag & Drop Files Here': 'Drag & Drop Files Here',
            'Please upload the proof of purchase (with respective serial number)':
                'Please upload the proof of purchase (with respective serial number)',
            'Please upload supporting documents (if any)': 'Please upload supporting documents (if any)',
            'Please select an issue category.': 'Please select an issue category.',
            'What is the category of your issue?': 'What is the category of your issue?',
            'Requested Resolution Date': 'Requested Resolution Date',
            'Please enter the business justification.': 'Please enter the business justification.',
            'In a few words, tell us how resolution of this issue will help you win an opportunity.':
                'In a few words, tell us how resolution of this issue will help you win an opportunity.',
            'Please enter a valid contact number.': 'Please enter a valid contact number.',
            'Contact Number': 'Contact Number',
            'Please enter a shipping address.': 'Please enter a shipping address.',
            'Shipping Address': 'Shipping Address',
            'Contact Email': 'Contact Email',
            'Contact name is mandatory.': 'Contact name is mandatory.',
            'Contact Name': 'Contact Name',
            'e.g: Screen panel is not flat and I think that could be swollen battery.':
                'e.g: Screen panel is not flat and I think that could be swollen battery.',
            'Please tell us how this impacts your customer': 'Please tell us how this impacts your customer',
            'Device count entered is not valid.': 'Device count entered is not valid.',
            'Please enter the country to which the customer belongs.': 'Please enter the country to which the customer belongs.',
            'Please enter a customer name.': 'Please enter a customer name.',
            "Can't find my customer": "Can't find my customer",
            'Add alternate contact': 'Add alternate contact',
            'CC Someone': 'CC Someone',
            'Do you have a CSS case number?': 'Do you have a CSS case number?',
            'Please enter the device risk involved.': 'Please enter the device risk involved.',
            'Device Risk': 'Device Risk',
            'Please enter a purely numeric value e.g.72000.': 'Please enter a purely numeric value e.g.72000.',
            'Please enter the revenue risk.': 'Please enter the revenue risk.',
            'Please enter a valid ID/customer name.': 'Please enter a valid ID/customer name.',
            'Please enter a valid ID': 'Please enter a valid ID',
            'Do you have an MSX Opportunity ID / Account ID / Company Name ?':
                'Do you have an MSX Opportunity ID / Account ID / Company Name ?',
            'An error ocurred, please try creating a': 'An error ocurred, please try creating a',
            'Please type in some details for your request so we can help resolve your issue faster.':
                'Please type in some details for your request so we can help resolve your issue faster.',
            'For example: My laptop often gets overheated after I continuously use it for 1 hour or more.':
                'For example: My laptop often gets overheated after I continuously use it for 1 hour or more.',
            'Please tell us more about your request': 'Please tell us more about your request',
            Customer: 'Customer',
            Details: 'Details',
            'Something went wrong, please try again later': 'Something went wrong, please try again later',
            'No unread notifications': 'No unread notifications',
            'Terms of use': 'Terms of use',
            Privacy: 'Privacy',
            Contacts: 'Contacts',
            'From Template': 'From Template',
            'Create New': 'Create New',
            'All News': 'All News',
            'Industry Insights': 'Industry Insights',
            'Product News': 'Product News',
            'Surface Program': 'Surface Program',
            'Please Try again...!': 'Please Try again...!',
            'Sorry some thing went wrong!': 'Sorry some thing went wrong!',
            "Can't Find Customer": "Can't Find Customer",
            "Can't Find Oppurtunity": "Can't Find Oppurtunity",
            "Sorry,we couldn't find any results": "Sorry,we couldn't find any results",
            'MSX Opportunity ID / Account ID / Customer Name field cant be empty!':
                'MSX Opportunity ID / Account ID / Customer Name field cant be empty!',
            'Search Customer': 'Search Customer',
            'Maximized View': 'Maximized View',
            'Minimized View': 'Minimized View',
            'Popular Resources': 'Popular Resources',
            'One Surface Commercial SharePoint': 'One Surface Commercial SharePoint',
            'Surface Marketing SharePoint': 'Surface Marketing SharePoint',
            'Public Documentation': 'Public Documentation',
            'Product safety warnings & instructions': 'Product safety warnings & instructions',
            'Battery Docs': 'Battery Docs',
            'Energy Efficiency': 'Energy Efficiency',
            'Top rated knowledge articles': 'Top rated knowledge articles',
            'Designed for Surface Catalog': 'Designed for Surface Catalog',
            'Device safety & environmental information': 'Device safety & environmental information',
            'Portal Requests you are following': 'Portal Requests you are following',
            'Popular Articles': 'Popular Articles',
            'Quick Links': 'Quick Links',
            Previous: 'Previous',
            Next: 'Next',
            'View all requests from different teams': 'View all requests from different teams',
            'Manage All Requests': 'Manage All Requests',
            'Assign-reassign-track requests': 'Assign-reassign-track requests',
            'Manage My Requests': 'Manage My Requests',
            'Latest from Surface': 'Latest from Surface',
            'Top Stories this week': 'Top Stories this week',
            'Stay on top of your game.': 'Stay on top of your game.',
            'Subscribe to News': 'Subscribe to News',
            'Email ID': 'Email ID',
            'Subscribe Now': 'Subscribe Now',
            'Surface Knowledge Portal': 'Surface Knowledge Portal',
            'Knowledge Portal': 'Knowledge Portal',
            'Custom reporting': 'Custom reporting',
            'Utilization insights': 'Utilization insights',
            'Sort by': 'Sort by',
            'Search news': 'Search News',
            'Enter Email here': 'Enter Email here',
            'News Feed': 'News Feed',
            Article: 'Article',
            'Written by': 'Written by',
            'Published Date': 'Published Date',
            All: 'All',
            Articles: 'Articles',
            'Read More': 'Read More',
            'How can we help you?': 'How can we help you?',
            'Unified Dashboard': 'Unified Dashboard',
            'Track all existing requests and escalations here': 'Track all existing requests and escalations here',
            'Request Help': 'Request Help',
            'Raise a new request for help': 'Raise a new request for help',
            'Portal Training': 'Portal Training',
            "Learn more about the portal and it's features": "Learn more about the portal and it's features",
            'Learn More': 'Learn More',
            'In The News': 'In The News',
            'Help With A': 'Help With A',
            'Sales Question': 'Sales Question',
            'Warranty Need': 'Warranty Need',
            'Feature Request': 'Feature Request',
            'Messaging Request': 'Messaging Request',
            Hi: 'Hi',
            "For example: My Customer's Surface Pro 7 is overheating": "For example: My Customer's Surface Pro 7 is overheating",
            'Related Requests': 'Related Requests',
            Title: 'Title',
            Description: 'Description',
            'Business Justification': 'Business Justification',
            'BUSINESS JUSTIFICATION': 'BUSINESS JUSTIFICATION',
            'Add Customer': 'Add Customer',
            'Do you want to': 'Do you want to',
            'Create new Request?': 'Create new Request?',
            'Please enter the Title to proceed!': 'Please enter the Title to proceed!',
            'Request Details': 'Request Details',
            'Select Device': 'Select Device',
            Attachments: 'Attachments',
            Preview: 'Preview',
            Address: 'Address',
            Action: 'Action',
            'Please add a model to your cart!': 'Please add a model to your cart!',
            'Added Devices': 'Added Devices',
            'Add More': 'Add More',
            'Created by': 'Created by',
            Back: 'Back',
            Close: 'Close',
            'Perfect, your request has been submitted successfully': 'Perfect, your request has been submitted successfully',
            'Track your request in': 'Track your request in',
            'Create New Request': 'Create New Request',
            'An error occurred while creating a request, please try again':
                'An error occurred while creating a request, please try again',
            'Go to home': 'Go to home',
            Home: 'Home',
            'My Requests': 'My Requests',
            'Starred Requests': 'Starred Requests',
            'Shared With Me': 'Shared With Me',
            'New Requests': 'New Requests',
            'Active Requests': 'Active Requests',
            'Closed Requests': 'Closed Requests',
            Filters: 'Filters',
            'Filter Results': 'Filter Results',
            'Clear All': 'Clear All',
            'Select an option': 'Select an option',
            'Add a customer': 'Add a customer',
            'Search by MSX Opportunity ID / TPID / Customer Name': 'Search by MSX Opportunity ID / TPID / Customer Name',
            'Enter MSX Opp ID / TPID / Customer Name': 'Enter MSX Opp ID / TPID / Customer Name',
            'Customer Country (Subsidiary)': 'Customer Country (Subsidiary)',
            Search: 'Search',
            'Select from recently attached': 'Select from recently attached',
            'Customer Name': 'Customer Name',
            TPID: 'TPID',
            Country: 'Country',
            'MSX Opportunity ID': 'MSX Opportunity ID',
            Revenue: 'Revenue',
            Device: 'Device',
            Select: 'Select',
            'SDSG Application ID': 'SDSG Application ID',
            'Application ID': 'Application ID',
            'Created On': 'Created On',
            'CREATED ON': 'CREATED ON',
            'Modified By': 'Modified By',
            'Modified On': 'Modified On',
            'REQUEST TYPE': 'REQUEST TYPE',
            'TOTAL REVENUE': 'TOTAL REVENUE',
            'TOTAL IMPACTED DEVICE': 'TOTAL IMPACTED DEVICE',
            TITLE: 'TITLE',
            REVENUE: 'REVENUE',
            'SDSG APPLICATION ID': 'SDSG APPLICATION ID',
            'RESOLUTION DATE': 'RESOLUTION DATE',
            AGING: 'AGING',
            CUSTOMER: 'CUSTOMER',
            STATUS: 'STATUS',
            DESCRIPTION: 'DESCRIPTION',
            'WHAT ACTION IS REQUIRED': 'WHAT ACTION IS REQUIRED',
            'ADDED IMPACT': 'ADDED IMPACT',
            'IMPACT EVIDENCE': 'IMPACT EVIDENCE',
            'ADDITIONAL DETAILS': 'ADDITIONAL DETAILS',
            Added: 'Added',
            Hide: 'Hide',
            MSX: 'MSX',
            'ADDED ON': 'ADDED ON',
            'END CUSTOMER': 'END CUSTOMER',
            'CUSTOMER INDUSTRY': 'CUSTOMER INDUSTRY',
            COUNTRY: 'COUNTRY',
            'IMPACTED DEVICE': 'IMPACTED DEVICE',
            'IMPACTED REVENUE': 'IMPACTED REVENUE',
            'Impacted Revenue': 'Impacted Revenue',
            COMMENTS: 'COMMENTS',
            Comments: 'Comments',
            ATTACHMENTS: 'ATTACHMENTS',
            Submit: 'Submit',
            Cancel: 'Cancel',
            'No data to display': 'No data to display',
            'Help Request Details': 'Help Request Details',
            'Help Requests': 'Help Requests',
            'Last Updated': 'Last Updated',
            'Did not find what you need?': 'Did not find what you need?',
            'Raise a request here': 'Raise a request here',
            Bookmark: 'Bookmark',
            Print: 'Print',
            Save: 'Save',
            Share: 'Share',
            'Was this information helpful?': 'Was this information helpful?',
            'Rate this article': 'Rate this article',
            Keywords: 'Keywords',
            'My Account': 'My Account',
            'My Details': 'My Details',
            Favorite: 'Favorite',
            Feedback: 'Feedback',
            Notifications: 'Notifications',
            Logout: 'Logout',
            'Social Media': 'Social Media',
            'About Me': 'About Me',
            'Page not found': 'Page not found',
            'Advanced Search Results': 'Advanced Search Results',
            'Title Search Results': 'Title Search Results',
            'Download Search Results': 'Download Search Results',
            'Advanced Search': 'Advanced Search',
            'Title Only Search': 'Title Only Search',
            'No records found': 'No records found',
            'Saving...': 'Saving...',
            'Failed to save': 'Failed to save',
            Saved: 'Saved',
            Redrafted: 'Redrafted',
            Redraft: 'Redraft',
            Draft: 'Draft',
            Drafts: 'Drafts',
            'Save Template': 'Save Template',
            'Enter Template Name': 'Enter Template Name',
            'Select Date': 'Select Date',
            'Select Time': 'Select Time',
            'Scheduled to Publish': 'Scheduled to Publish',
            'Cancel Scheduling': 'Cancel Scheduling',
            'Publish Now': 'Publish Now',
            'Schedule for Later': 'Schedule for Later',
            'Upload Banner Image': 'Upload Banner Image',
            'Helpful Resources': 'Helpful Resources',
            'Add Resource': 'Add Resource',
            'Article Type': 'Article Type',
            'Last Edited Date': 'Last Edited Date',
            'Choose Tags': 'Choose Tags',
            'Limit upto 5': 'Limit upto 5',
            'Last edited': 'Last edited',
            words: 'words',
            'Article title': 'Article title',
            'Manage Content': 'Manage Content',
            'Publish spotlight, news and training': 'Publish spotlight, news and training',
            'Surface content management': 'Surface content management',
            'Manage News': 'Manage News',
            Training: 'Training',
            'results for your search': 'results for your search',
            'No results found': 'No results found',
            Published: 'Published',
            Removed: 'Removed',
            'New Post': 'New Post',
            'Select Template': 'Select Template',
            'Template Preview': 'Template Preview',
            'Please select a Template': 'Please select a Template',
            'Run your imagination wide and create articles': 'Run your imagination wide and create articles',
            'Loading...': 'Loading...',
            'Please check your microphone and audio levels..': 'Please check your microphone and audio levels..',
            'Expand View': 'Expand View',
            'Collapse View': 'Collapse View',
            'Please add the number of products.': 'Please add the number of products.',
            'Edit Profile': 'Edit Profile',
            expand: 'expand',
            collapse: 'collapse',
            'Request ID': 'Request ID',
            'See all details': 'See all details',
            Download: 'Download',
            'Fit Into The Screen': 'Fit Into The Screen',
            'Maximize View': 'Maximize View',
            'List View': 'List View',
            'Detail View': 'Detail View',
            'Last Activity': 'Last Activity',

            'We would love to hear about your experience using the Admin portal':
                'We would love to hear about your experience using the Admin portal',
            Great: 'Great',
            Good: 'Good',
            Okay: 'Okay',
            Bad: 'Bad',
            Terrible: 'Terrible',
            'Please select your feedback category': 'Please select your feedback category',
            Suggestion: 'Suggestion',
            Compliment: 'Compliment',
            'Feedback Response': 'Feedback Response',
            'Report A Bug': 'Report A Bug',
            'Please provide your feedback below': 'Please provide your feedback below',
            OK: 'OK',
            'We really appreciate you for taking the time to write to us, and for suggesting your views.We are always trying our best to make your experience better for you. You will receive a copy of your feedback on your email':
                'We really appreciate you for taking the time to write to us, and for suggesting your views.We are always trying our best to make your experience better for you. You will receive a copy of your feedback on your email',
            'New Feedback': 'New Feedback',
            'Past Feedback': 'Past Feedback',
            Date: 'Date',
            Category: 'Category',
            Experience: 'Experience',
            'Provided By': 'Provided By',
            'Your Response': 'Your Response',
            'No data available to display': 'No data available to display',
            'Please fill in some response!': 'Please fill in some response!',
            Send: 'Send',
            'Responded On': 'Responded On',
            Response: 'Response',
            WEEK: 'WEEK',
            Week: 'Week',
            MONTH: 'MONTH',
            Month: 'Month',
            YEAR: 'YEAR',
            Year: 'Year',
            'TimeLine View': 'TimeLine View',
            'Open Requests': 'Open Requests',
            'Requested by': 'Requested by',
            'Resolved Requests': 'Resolved Requests',
            'The requested details are not available': 'The requested details are not available',
            SDSG: 'SDSG',
            CXP: 'CXP',
            CSS: 'CSS',
            CREW: 'CREW',
            Outline: 'Outline',
            Views: 'Views',
            Team: 'Team',
            TEAM: 'TEAM',
            ID: 'ID',

            'Request Type': 'Request Type',
            Status: 'Status',
            'Assigned To': 'Assigned To',
            'ASSIGNED TO': 'ASSIGNED TO',
            Aging: 'Aging',
            'Total Revenue Risk': 'Total Revenue Risk',
            'TOTAL REVENUE RISK': 'TOTAL REVENUE RISK',
            'Revenue Risk': 'Revenue Risk',
            'This is the surface content management experience, you can create and upload articles to the knowledge center and news feeds of the surface knowledge portal through this interface.':
                'This is the surface content management experience, you can create and upload articles to the knowledge center and news feeds of the surface knowledge portal through this interface.',
            'Note: Format as': 'Note: Format as',
            'Note:': 'Note:',
            Header2: 'Header2',
            'to view as a section in outline': 'to view as a section in outline',
            'Featured Authors': 'Featured Authors',
            'View All Posts': 'View All Posts',
            'Yay! You have seen it all': 'Yay! You have seen it all',
            'Knowledge Center': 'Knowledge Center',
            'View All': 'View All',
            'Article Search': 'Article Search',
            Latest: 'Latest',
            Earliest: 'Earliest'
        }
    },
    ja: {
        translation: {
            'All Requests': 'すべての要求',
            'Show Photo & Name': '写真と名前を表示',
            'News Article': 'ニュース記事',
            'Knowledge Article': 'ナレッジ記事',
            minute: '分',
            minutes: '分',
            read: '読む',
            About: 'に関しては',
            of: 'の',
            'Upload & manage training content': 'トレーニングコンテンツのアップロードと管理',
            'Create, manage & publish news': 'ニュースの作成、管理、公開',
            Attach: '添付する',
            'Files are attached successfully': 'ファイルは正常に添付されています',
            'Please select any file to attach.': '添付するファイルを選択してください。',
            'Adding attachments. Please wait...': '添付ファイルの追加。お待ちください。。。',
            'For example: Attention required': '例: 注意が必要です',
            'Your Comment': 'あなたのコメント',
            'This Field is Mandatory.': 'このフィールドは必須です。',
            'Add Comment For': 'コメントの追加',
            'Assign To': '割り当て',
            'Sorry, we could not find anything that matches your search :(':
                '申し訳ありませんが、検索:(に一致するものは見つかりませんでした',
            'Related Results': '関連する結果',
            'Failed to upload some of the files': 'いくつかのファイルをアップロードできませんでした',
            'Add Attachments': '添付ファイルの追加',
            'Post Sales': '販売の転記',
            'Pre Sales': 'プリセールス',
            'CSS Review Request': 'CSS レビュー要求',
            'CSS Request': 'CSS リクエスト',
            'Variant Request': 'バリアントリクエスト',
            Question: '質問',
            Proposal: '建議',
            Other: '他',
            'Task Required': '必要なタスク',
            'Escalation Reassignment': 'エスカレーションの再割り当て',
            Misroute: 'ミスルート',
            'The notification is successfully sent': '通知が正常に送信されました',
            'Go back to': 'に戻る',
            'your request, please try again': 'リクエストをもう一度お試しください',
            'An error occured while': 'エラーが発生しました',
            'The request is listed under': '要求は下に表示されます。',
            assign: '割り当てる',
            reassign: '割り当て',
            'The request is successfully': '要求は正常に完了しました',
            'Please wait, while we are processing your request': 'お待ちください、私たちはあなたの要求を処理している間',
            'See Request Details': 'リクエストの詳細を参照してください。',
            'Current Request Type': '現在の要求の種類',
            'Current Team': '現在のチーム',
            'Assign Request': '要求の割り当て',
            'Request Reassignment': '再割り当てを要求',
            'Customize Columns': '列のカスタマイズ',
            Resolved: '解決',
            Active: '能動',
            New: '新機能',
            Edit: '編集',
            'Visual Flow': 'ビジュアルフロー',
            'Reassign Team': 'チームの再割り当て',
            Assign: '割り当てる',
            Stats: '統計',
            Current: '現在の',
            'Support Escalation': 'サポートエスカレーション',
            'Search via ID or Request Title': 'ID または要求のタイトルを使用して検索',
            'Content Manager': 'コンテンツマネージャ',
            'Content Creator': 'コンテンツ作成者',
            'End User': 'エンドユーザ',
            Admin: '管理者',
            'Super Admin': 'スーパー管理者',
            'Past 6 Month': '過去 6 か月間',
            'Past Month': '過去 1 か月',
            'Past Week': '過去 1 週間',
            Today: '今日',
            'All Dates': 'すべての日付',
            'Review Requests': 'レビュー要求',
            Quantity: '量',
            'Select effected models': '効果のあるモデルを選択する',
            'Select the product family of effected products': '影響を受けた製品の製品ファミリを選択します',
            'Alternate Contact Details': '代替連絡先の詳細daitai renraku saki no shousai',
            'Shippping Details': '出荷の詳細',
            'CSS Case Number': 'CSS ケース番号',
            'Customer Info': '顧客情報',
            'Issue Category': '問題カテゴリ',
            'Fund Utilization:': 'ファンド利用率:',
            'Discretionary Commercial Fund (DCF):': '裁量商業基金(DCF):',
            'Add more files': 'ファイルを追加する',
            'File size : Any size is acceptable': 'ファイルサイズ : 任意のサイズを使用できます。',
            'Supported file/document format: All Types': 'サポートされているファイル/ドキュメント形式: すべてのタイプ',
            Upload: 'アップロード',
            'Drag & Drop Files Here': 'ここにファイルをドラッグ&ドロップ',
            'Please upload the proof of purchase (with respective serial number)':
                '購入証明書をアップロードしてください(各シリアル番号付き)',
            'Please upload supporting documents (if any)': 'サポートドキュメントをアップロードしてください(存在する場合)',
            'Please select an issue category.': '問題カテゴリを選択してください。',
            'What is the category of your issue?': '問題のカテゴリは何ですか?',
            'Requested Resolution Date': '要求された解決日',
            'Please enter the business justification.': '業務上の妥当性を入力してください。',
            'In a few words, tell us how resolution of this issue will help you win an opportunity.':
                'いくつかの言葉で、この問題の解決がチャンスを獲得するのにどのように役立つかを教えてください。',
            'Please enter a valid contact number.': '有効な連絡先番号を入力してください。',
            'Contact Number': '連絡先番号',
            'Please enter a shipping address.': '配送先住所を入力してください。',
            'Shipping Address': '出荷先住所',
            'Contact Email': '連絡先の電子メール',
            'Contact name is mandatory.': '連絡先名は必須です。',
            'Contact Name': '連絡先名',
            'e.g: Screen panel is not flat and I think that could be swollen battery.':
                '例えば:スクリーンパネルは平らではないし、私はそれが膨れ上がったバッテリーかもしれないと思う。',
            'Please tell us how this impacts your customer': 'これがお客様にどのような影響を与えるのか教えてください。',
            'Device count entered is not valid.': '入力されたデバイス数が無効です。',
            'Please enter the country to which the customer belongs.': '顧客が属する国を入力してください。',
            'Please enter a customer name.': '顧客名を入力してください。',
            "Can't find my customer": '顧客が見つからない',
            'Add alternate contact': '代替連絡先の追加',
            'CC Someone': 'CC 誰か',
            'Do you have a CSS case number?': 'CSS ケース番号はありますか?',
            'Please enter the device risk involved.': '関連するデバイスのリスクを入力してください。',
            'Device Risk': 'デバイスのリスク',
            'Please enter a purely numeric value e.g.72000.': '72000 というように、純粋な数値を入力してください。',
            'Please enter the revenue risk.': '収益リスクを入力してください。',
            'Please enter a valid ID/customer name.': '有効な ID/顧客名を入力してください。',
            'Please enter a valid ID': '有効な ID を入力してください',
            'Do you have an MSX Opportunity ID / Account ID / Company Name ?':
                'MSX の営業案件 ID / アカウント ID / 会社名はありますか?',
            'An error ocurred, please try creating a': 'エラーが発生しました。',
            'Please type in some details for your request so we can help resolve your issue faster.':
                '問題の解決を早く行えるため、リクエストの詳細を入力してください。',
            'For example: My laptop often gets overheated after I continuously use it for 1 hour or more.':
                'たとえば、ラップトップは、1時間以上連続して使用すると過熱することがよくあります。',
            'Please tell us more about your request': 'ご要望について詳しくお聞かせください',
            Customer: '顧客',
            Details: '細部',
            'Something went wrong, please try again later': '何かが間違っていた、後でもう一度やり直してください',
            'No unread notifications': '未読通知なし',
            'Terms of use': '利用規約',
            Privacy: 'プライバシー',
            Contacts: '連絡先',
            'From Template': 'テンプレートから',
            'Create New': '新規作成',
            'All News': 'すべてのニュース',
            'Industry Insights': '業界のインサイト',
            'Product News': '製品ニュース',
            'Surface Program': 'サーフェスプログラム',
            'Please Try again...!': 'もう一度お試しください..!',
            'Sorry some thing went wrong!': '申し訳ありませんが、いくつかのことが間違っていました!',
            "Can't Find Customer": '顧客を検索できません',
            "Can't Find Oppurtunity": 'オプレットユニティを見つけることができません',
            "Sorry,we couldn't find any results": '申し訳ありませんが、結果が見つかりませんでした',
            'MSX Opportunity ID / Account ID / Customer Name field cant be empty!':
                'MSX の営業案件 ID / アカウント ID / 顧客名フィールドは空にできません!',
            'Search Customer': '顧客の検索',
            'Maximized View': '最大化されたビュー',
            'Minimized View': '最小化されたビュー',
            'Popular Resources': '人気のリソース',
            'One Surface Commercial SharePoint': 'ワンサーフェスコマーシャルSharePoint',
            'Surface Marketing SharePoint': 'サーフェス マーケティング SharePoint',
            'Public Documentation': '公開ドキュメント',
            'Product safety warnings & instructions': '製品の安全に関する警告と手順',
            'Battery Docs': 'バッテリードキュメント',
            'Energy Efficiency': 'エネルギー効率',
            'Top rated knowledge articles': 'トップ評価のサポート情報記事',
            'Designed for Surface Catalog': '表面カタログ用に設計',
            'Device safety & environmental information': 'デバイスの安全性と環境情報',
            'Portal Requests you are following': 'あなたがフォローしているポータル要求',
            'Quick Links': 'クイックリンク',
            'Popular Articles': '人気記事',
            Previous: '先の',
            Next: '次に',
            'View all requests from different teams': '異なるチームからの依頼をすべて表示',
            'Manage All Requests': 'すべての要求の管理',
            'Assign-reassign-track requests': '割り当て再割り当て要求',
            'Manage My Requests': '自分のリクエストを管理する',
            'Latest from Surface': 'サーフェスから最新',
            'Top Stories this week': '今週のトップストーリー',
            'Stay on top of your game.': 'あなたのゲームの上に滞在。',
            'Subscribe to News': 'ニュースを購読する',
            'Email ID': '電子メール ID',
            'Subscribe Now': '今すぐ購読',
            'Surface Knowledge Portal': '表面知識ポータル',
            'Knowledge Portal': 'ナレッジポータル',
            'Custom reporting': 'カスタム レポート',
            'Utilization insights': '利用インサイト',
            'Sort by': '並べ替え順',
            'Search news': '検索ニュース',
            'Enter Email here': 'ここにメールを入力してください',
            'News Feed': 'ニュースフィード',
            Article: '記事',
            'Written by': '書き込み',
            'Published Date': '発行日',
            All: 'すべての',
            Articles: '記事',
            'Read More': '続きを読む',
            'How can we help you?': 'どのように私たちはあなたを助けることができますか?',
            'Unified Dashboard': '統合ダッシュボード',
            'Track all existing requests and escalations here': '既存のすべてのリクエストとエスカレーションを追跡する',
            'Request Help': 'ヘルプを依頼する',
            'Raise a new request for help': '新しいヘルプ要求を発生させる',
            'Portal Training': 'ポータルトレーニング',
            "Learn more about the portal and it's features": 'ポータルとその機能の詳細',
            'Learn More': '詳細情報',
            'In The News': 'イン・ザ・ニュース',
            'Help With A': 'Aに関するヘルプ',
            'Sales Question': 'セールスの質問',
            'Warranty Need': '保証の必要性',
            'Feature Request': '機能リクエスト',
            'Messaging Request': 'メッセージング要求',
            Hi: 'こんにちは',
            "For example: My Customer's Surface Pro 7 is overheating": 'たとえば、お客様の Surface Pro 7 が過熱している',
            'Related Requests': '関連するリクエスト',
            Title: 'タイトル',
            Description: '形容',
            'Business Justification': 'ビジネスの正当性',
            'BUSINESS JUSTIFICATION': 'ビジネスの正当性',
            'Add Customer': '顧客の追加',
            'Do you want to': 'あなたはしたいですか?',
            'Create new Request?': '新しい要求を作成しますか?',
            'Please enter the Title to proceed!': '進むにはタイトルを入力してください!',
            'Request Details': 'リクエストの詳細',
            'Select Device': 'デバイスの選択',
            Attachments: '添付 ファイル',
            Preview: 'プレビュー',
            Address: '住所',
            Action: 'アクション',
            'Please add a model to your cart!': 'あなたのカートにモデルを追加してください!',
            'Added Devices': '追加されたデバイス',
            'Add More': '追加',
            'Created by': '作成者',
            Back: '戻る',
            Close: '閉める',
            'Perfect, your request has been submitted successfully': '完璧な、あなたの要求は正常に送信されました',
            'Track your request in': 'でのリクエストを追跡する',
            'Create New Request': '新しい要求の作成',
            'An error occurred while creating a request, please try again': '要求の作成中にエラーが発生しました。',
            'Go to home': '家に行く',
            Home: '家',
            'My Requests': 'マイリクエスト',
            'Starred Requests': 'スタードリクエスト',
            'Shared With Me': '自分と共有',
            'New Requests': '新しいリクエスト',
            'Active Requests': 'アクティブな要求',
            'Closed Requests': 'クローズドリクエスト',
            Filters: 'フィルター',
            'Filter Results': '結果をフィルター処理する',
            'Clear All': 'すべてクリア',
            'Select an option': 'オプションを選択する',
            'Add a customer': '顧客の追加',
            'Search by MSX Opportunity ID / TPID / Customer Name': 'MSX商談 ID/TPID/お客様名で検索',
            'Enter MSX Opp ID / TPID / Customer Name': 'MSX Opp ID /TPID/顧客名を入力してください',
            'Customer Country (Subsidiary)': '顧客国 (子会社)',
            Search: '捜索',
            'Select from recently attached': '最近アタッチされたから選択',
            'Customer Name': '顧客名',

            Country: '国',
            'MSX Opportunity ID': 'MSX の営業案件 ID',
            Revenue: '収入',
            Device: 'デバイス',
            Select: '選ぶ',
            'SDSG Application ID': 'SDSG アプリケーション ID',
            'Application ID': 'アプリケーション ID',
            'Created On': '作成日',
            'CREATED ON': '作成日',
            'Modified By': '更新者',
            'Modified On': '変更日',
            'REQUEST TYPE': '要求タイプ',
            'TOTAL REVENUE': '総収益',
            'TOTAL IMPACTED DEVICE': '影響を受けたデバイスの合計',
            TITLE: 'タイトル',
            REVENUE: '収入',
            'SDSG APPLICATION ID': 'SDSG アプリケーション ID',
            'RESOLUTION DATE': '解決日',
            AGING: '老化',
            CUSTOMER: '顧客',
            STATUS: '地位',
            DESCRIPTION: '形容',
            'WHAT ACTION IS REQUIRED': '必要なアクション',
            'ADDED IMPACT': '追加された影響',
            'IMPACT EVIDENCE': '影響の証拠',
            'ADDITIONAL DETAILS': 'その他の詳細',
            Added: '追加',
            Hide: '隠れる',
            MSX: 'MSX',
            'ADDED ON': '上に追加',
            'END CUSTOMER': 'エンドカスタマー',
            'CUSTOMER INDUSTRY': '顧客産業',
            COUNTRY: '国',
            'IMPACTED DEVICE': '影響を受けたデバイス',
            'IMPACTED REVENUE': '影響を受けた収益',
            'Impacted Revenue': '影響を受けた収益',
            COMMENTS: 'コメント',
            Comments: 'コメント',
            ATTACHMENTS: '添付 ファイル',
            Submit: '送信',
            Cancel: 'キャンセル',
            'No data to display': '表示するデータがありません',
            'Help Request Details': 'ヘルプリクエストの詳細',
            'Help Requests': 'ヘルプリクエスト',
            'Last Updated': '最終更新日',
            'Did not find what you need?': '必要なものが見つかりませんでしたか?',
            'Raise a request here': 'ここで要求を発生させる',
            Bookmark: 'ブックマーク',
            Print: 'プリント',
            Save: 'セーブ',
            Share: '共有',
            'Was this information helpful?': 'この情報は役に立ちましたか?',
            'Rate this article': 'この記事を評価する',
            Keywords: 'キーワード',
            'My Account': 'マイアカウント',
            'My Details': 'マイ・ディテール',
            Favorite: 'お気に入り',
            Feedback: 'フィードバック',
            Notifications: '通知',
            Logout: 'ログアウト',
            'Social Media': 'ソーシャルメディア',
            'About Me': '私について',
            'Page not found': 'ページが見つかりません',
            'Advanced Search Results': '高度な検索結果',
            'Title Search Results': 'タイトル検索結果',
            'Download Search Results': '検索結果のダウンロード',
            'Advanced Search': '高度な検索',
            'Title Only Search': 'タイトルのみの検索',
            'No records found': 'レコードが見つかりません',
            'Saving...': '節約。。。',
            Saved: '保存',
            Redrafted: '再ドラフト',
            Redraft: '再投稿',
            Draft: 'ドラフト',
            Drafts: '下書き',
            'Save Template': 'テンプレートの保存',
            'Enter Template Name': 'テンプレート名の入力',
            'Select Date': '日付の選択',
            'Select Time': '時間の選択',
            'Scheduled to Publish': '公開予定',
            'Cancel Scheduling': 'スケジュールのキャンセル',
            'Publish Now': '今すぐ公開',
            'Schedule for Later': '後でのスケジュール',
            'Upload Banner Image': 'バナー画像のアップロード',
            'Helpful Resources': '役に立つリソース',
            'Add Resource': 'リソースの追加',
            'Article Type': '記事の種類',
            'Last Edited Date': '最終編集日',
            'Choose Tags': 'タグの選択',
            'Limit upto 5': '5まで制限',
            'Last edited': '最終編集',
            words: '言葉',
            'Article title': '記事のタイトル',
            'Manage Content': 'コンテンツの管理',
            'Publish spotlight, news and training': 'スポットライト、ニュース、トレーニングを公開',
            'Surface content management': '表面コンテンツ管理',
            'Manage News': 'ニュースの管理',
            Training: '訓練',
            'results for your search': '検索の結果',
            'No results found': '結果が見つかりません',
            Published: '公開',
            Removed: '削除',
            'New Post': '新しい投稿',
            'Select Template': 'テンプレートの選択',
            'Template Preview': 'テンプレートプレビュー',
            'Please select a Template': 'テンプレートを選択してください',
            'Run your imagination wide and create articles': '想像力を広く実行し、記事を作成する',
            'Loading...': '積載。。。',
            'Please check your microphone and audio levels..': 'マイクとオーディオのレベルを確認してください。',
            'Expand View': 'ビューを展開',
            'Collapse View': 'ビューの折りたたみ',
            'Please add the number of products.': '製品数を追加してください。',
            'Edit Profile': 'プロファイルの編集',
            expand: '膨らむ',
            collapse: '倒れる',
            'Request ID': 'リクエスト ID',
            'See all details': 'すべての詳細を表示',
            Download: 'ダウンロード',
            'Fit Into The Screen': '画面にフィット',
            'Maximize View': 'ビューを最大化',
            'List View': 'リストビュー',
            'Detail View': '詳細ビュー',
            'Last Activity': '最後のアクティビティ',

            'We would love to hear about your experience using the Admin portal':
                '管理ポータルを使用して、あなたの経験について聞いてみたいです',
            Great: 'すごい',
            Good: 'よし',
            Okay: '大丈夫です',
            Bad: '悪い',
            Terrible: 'やばい',
            'Please select your feedback category': 'フィードバックのカテゴリを選択してください',
            Suggestion: '提案',
            Compliment: 'お世辞',
            'Feedback Response': 'フィードバック応答',
            'Report A Bug': 'バグを報告する',
            'Please provide your feedback below': '以下にご意見をお寄せください',
            OK: 'わかりました',
            'We really appreciate you for taking the time to write to us, and for suggesting your views.We are always trying our best to make your experience better for you. You will receive a copy of your feedback on your email':
                '私たちは、私たちに手紙を書くために時間を取っていただき、あなたの意見を提案するために本当に感謝しています。私たちは常にあなたの経験をより良くするために最善を尽くしています。メールに対するフィードバックのコピーを受け取ります',
            'New Feedback': '新しいフィードバック',
            'Past Feedback': '過去のフィードバック',
            Date: '日付',
            Category: 'カテゴリ',
            Experience: '経験',
            'Provided By': '提供する人',
            'Your Response': 'あなたの応答',
            'No data available to display': '表示できるデータがありません',
            'Please fill in some response!': '何か回答を記入してください!',
            Send: '送信',
            'Responded On': '応答日',
            Response: '応答',
            WEEK: '週',
            Week: '週',
            MONTH: '月',
            Month: '月',
            YEAR: '年',
            Year: '年',
            'TimeLine View': 'タイムラインビュー',
            'Open Requests': 'オープンリクエスト',
            'Requested by': '要求された人',
            'Resolved Requests': '解決済み要求',
            'The requested details are not available': '要求された詳細は利用できません',
            SDSG: 'SDSG',
            CXP: 'CXP',
            CSS: 'CSS',
            CREW: 'CREW',
            Outline: '概要',
            Views: '表示モード',
            Team: 'チーム',
            TEAM: 'チーム',
            ID: '身分証明書',

            'Request Type': '要求の種類',
            Status: '地位',
            'Assigned To': '割り当て先',
            'ASSIGNED TO': '割り当て先',
            Aging: '老化',
            'TOTAL REVENUE RISK': '総収益リスク',
            'Total Revenue Risk': '総収益リスク',
            'Revenue Risk': '収益リスク',
            'This is the surface content management experience, you can create and upload articles to the knowledge center and news feeds of the surface knowledge portal through this interface.':
                'これは、表面コンテンツ管理の経験であり、このインターフェイスを介して、サーフェスナレッジポータルのナレッジセンターとニュースフィードに記事を作成してアップロードすることができます。',
            'Note: Format as': '注: フォーマット形式',
            'Note:': '注:',
            Header2: 'ヘッダー2',
            'to view as a section in outline': 'アウトラインのセクションとして表示するには',
            'Featured Authors': '注目の著者',
            'View All Posts': 'すべての投稿を表示',
            'Yay! You have seen it all': 'イェーイ！あなたはそれをすべて見てきました',
            'Knowledge Center': 'ナレッジセンター',
            'View All': 'すべてを見る',
            'Article Search': '記事検索',
            Latest: '最新',
            Earliest: '初期'
        }
    },
    md: {
        translation: {
            'All Requests': '所有请求',
            'Show Photo & Name': '显示照片和名称',
            'News Article': '新闻文章',
            'Knowledge Article': '知识文章',
            minute: '分钟',
            minutes: '分钟',
            read: '读',
            About: '大约',
            of: '之',
            'Upload & manage training content': '上传并管理培训内容',
            'Create, manage & publish news': '创建、管理和发布新闻',
            Attach: '附加',
            'Files are attached successfully': '文件成功连接',
            'Please select any file to attach.': '请选择任何文件进行附加。',
            'Adding attachments. Please wait...': '添加附件。请稍候。。。',
            'For example: Attention required': '例如：需要注意',
            'Your Comment': '您的评论',
            'This Field is Mandatory.': '此字段是强制性的。',
            'Add Comment For': '添加评论',
            'Assign To': '分配给',
            'Sorry, we could not find anything that matches your search :(': '对不起，我们找不到任何与您的搜索:(',
            'Related Results': '相关结果',
            'Failed to upload some of the files': '未能上传部分文件',
            'Add Attachments': '添加附件',
            'Post Sales': '售后',
            'Pre Sales': '预售',
            'CSS Review Request': 'CSS 审核请求',
            'CSS Request': 'CSS 请求',
            'Variant Request': '变体请求',
            Question: '问题',
            Proposal: '建议',
            Other: '其他',
            'Task Required': '所需的任务',
            'Escalation Reassignment': '升级重新分配',
            Misroute: '误路',
            'The notification is successfully sent': '通知已成功发送',
            'Go back to': '回到',
            'your request, please try again': '您的请求，请再试一次',
            'An error occured while': '发生错误时',
            'The request is listed under': '请求列在下',
            assign: '分配',
            reassign: '分配',
            'The request is successfully': '请求成功',
            'Please wait, while we are processing your request': '请等待，而我们正在处理您的请求',
            'See Request Details': '查看请求详细信息',
            'Current Request Type': '当前请求类型',
            'Current Team': '当前团队',
            'Assign Request': '分配请求',
            'Request Reassignment': '请求重新分配',
            'Customize Columns': '自定义列',
            Resolved: '解决',
            Active: '积极',
            New: '新增功能',
            Edit: '编辑',
            'Visual Flow': '视觉流',
            'Reassign Team': '重新分配团队',
            Assign: '分配',
            Stats: '统计',
            Current: '当前',
            'Support Escalation': '支持升级',
            'Search via ID or Request Title': '通过 ID 或请求标题进行搜索',
            'Content Manager': '内容经理',
            'Content Creator': '内容创建者',
            'End User': '最终用户',
            Admin: '管理',
            'Super Admin': '超级管理员',
            'Past 6 Month': '过去 6 个月',
            'Past Month': '过去一个月',
            'Past Week': '过去一周',
            Today: '今天',
            'All Dates': '所有日期',
            'Review Requests': '审核请求',
            Quantity: '数量',
            'Select effected models': '选择有效果的模型',
            'Select the product family of effected products': '选择受影响产品的产品系列',
            'Alternate Contact Details': '备用联系方式',
            'Shippping Details': '装船详情',
            'CSS Case Number': 'CSS 案例编号',
            'Customer Info': '客户信息',
            'Issue Category': '发行类别',
            'Fund Utilization:': '资金利用率：',
            'Discretionary Commercial Fund (DCF):': '自由裁量商业基金：',
            'Add more files': '添加更多文件',
            'File size : Any size is acceptable': '文件大小：任何大小都是可以接受的',
            'Supported file/document format: All Types': '支持的文件/文档格式：所有类型',
            Upload: '上传',
            'Drag & Drop Files Here': '在此处拖动和丢弃文件',
            'Please upload the proof of purchase (with respective serial number)': '请上传购买证明（相关序列号）',
            'Please upload supporting documents (if any)': '请上传证明文件（如有）',
            'Please select an issue category.': '请选择问题类别。',
            'What is the category of your issue?': '您的问题类别是什么？',
            'Requested Resolution Date': '请求的解决日期',
            'Please enter the business justification.': '请输入业务理由。',
            'In a few words, tell us how resolution of this issue will help you win an opportunity.':
                '用几句话来说，告诉我们解决这个问题将如何帮助你赢得机会。',
            'Please enter a valid contact number.': '请输入有效的联系电话。',
            'Contact Number': '联系电话',
            'Please enter a shipping address.': '请输入运输地址。',
            'Shipping Address': '运输地址',
            'Contact Email': '联系电子邮件',
            'Contact name is mandatory.': '联系人姓名是强制性的。',
            'Contact Name': '联系人姓名',
            'e.g: Screen panel is not flat and I think that could be swollen battery.':
                '屏幕面板不是平的，我想那可能是电池肿胀。',
            'Please tell us how this impacts your customer': '请告诉我们这对您的客户有何影响',
            'Device count entered is not valid.': '输入的设备计数无效。',
            'Please enter the country to which the customer belongs.': '请进入客户所属的国家/',
            'Please enter a customer name.': '请输入客户姓名。',
            "Can't find my customer": '找不到我的客户',
            'Add alternate contact': '添加备用联系人',
            'CC Someone': 'Cc 某人',
            'Do you have a CSS case number?': '你有CSS案例号码吗？',
            'Please enter the device risk involved.': '请输入所涉及的设备风险。',
            'Device Risk': '设备风险',
            'Please enter a purely numeric value e.g.72000.': '请输入纯数字值，例如 72000。。',
            'Please enter the revenue risk.': '请输入收入风险。',
            'Please enter a valid ID/customer name.': '请输入有效的 ID/客户姓名。',
            'Please enter a valid ID': '请输入有效 ID',
            'Do you have an MSX Opportunity ID / Account ID / Company Name ?': '您有 MSX 机会 ID / 帐户 ID / 公司名称吗？',
            'An error ocurred, please try creating a': '错误发生，请尝试创建一个',
            'Please type in some details for your request so we can help resolve your issue faster.':
                '请输入一些详细信息，以便我们帮助更快地解决您的问题。',
            'For example: My laptop often gets overheated after I continuously use it for 1 hour or more.':
                '例如：我的笔记本电脑经常过热后，我不断使用它1小时或更多。',
            'Please tell us more about your request': '请告诉我们更多有关您的请求',
            Customer: '客户',
            Details: '详',
            'Something went wrong, please try again later': '出问题了，请以后再试一次',
            'No unread notifications': '无未读通知',
            'Terms of use': '使用条款',
            Privacy: '隐私',
            Contacts: '接触',
            'From Template': '从模板',
            'Create New': '创建新',
            'All News': '所有新闻',
            'Industry Insights': '行业洞察',
            'Product News': '产品新闻',
            'Surface Program': '表面程序',
            'Please Try again...!': '请再试一次...！',
            'Sorry some thing went wrong!': '对不起， 有些事出了问题！',
            "Can't Find Customer": '找不到客户',
            "Can't Find Oppurtunity": '找不到机会',
            "Sorry,we couldn't find any results": '对不起，我们找不到任何结果',
            'MSX Opportunity ID / Account ID / Customer Name field cant be empty!': 'MSX 机会 ID / 帐户 ID / 客户名字段不能空！',
            'Search Customer': '搜索客户',
            'Maximized View': '最大化视图',
            'Minimized View': '最小视图',
            'Popular Resources': '大众资源',
            'One Surface Commercial SharePoint': '一个表面商业共享点',
            'Surface Marketing SharePoint': '表面营销共享点',
            'Public Documentation': '公共文档',
            'Product safety warnings & instructions': '产品安全警告和说明',
            'Battery Docs': '电池文档',
            'Energy Efficiency': '能效',
            'Top rated knowledge articles': '顶级知识文章',
            'Designed for Surface Catalog': '专为表面目录设计',
            'Device safety & environmental information': '设备安全与环境信息',
            'Portal Requests you are following': '您正在关注的门户请求',
            'Popular Articles': '热门文章',
            'Quick Links': '快速链接',
            Previous: '以前',
            Next: '下一个',
            'View all requests from different teams': '查看来自不同团队的所有请求',
            'Manage All Requests': '管理所有请求',
            'Assign-reassign-track requests': '分配-重新分配-轨道请求',
            'Manage My Requests': '管理我的请求',
            'Latest from Surface': '来自表面的最新消息',
            'Top Stories this week': '本周头条新闻',
            'Stay on top of your game.': '保持你的游戏的顶部。',
            'Subscribe to News': '订阅新闻',
            'Email ID': '电子邮件 ID',
            'Subscribe Now': '立即订阅',
            'Surface Knowledge Portal': '表面知识门户',
            'Knowledge Portal': '知识门户',
            'Custom reporting': '自定义报告',
            'Utilization insights': '利用性见解',
            'Sort by': '排序',
            'Search news': '搜索新闻',
            'Enter Email here': '在此处输入电子邮件',
            'News Feed': '动态消息',
            Article: '品',
            'Written by': '作者：：',
            'Published Date': '发布日期',
            All: '都',
            Articles: '文章',
            'Read More': '阅读更多',
            'How can we help you?': '我们如何帮助您？',
            'Unified Dashboard': '统一仪表板',
            'Track all existing requests and escalations here': '在此处跟踪所有现有请求和升级',
            'Request Help': '请求帮助',
            'Raise a new request for help': '提出新的帮助请求',
            'Portal Training': '门户培训',
            "Learn more about the portal and it's features": '了解有关门户及其功能的更多内容',
            'Learn More': '了解更多信息',
            'In The News': '在新闻',
            'Help With A': '帮助 A',
            'Sales Question': '销售问题',
            'Warranty Need': '保修需求',
            'Feature Request': '功能请求',
            'Messaging Request': '消息传递请求',
            Hi: '你好',
            "For example: My Customer's Surface Pro 7 is overheating": '例如：我客户的表面 Pro 7 过热',
            'Related Requests': '相关请求',
            Title: '标题',
            Description: '描述',
            'Business Justification': '业务理由',
            'BUSINESS JUSTIFICATION': '业务理由',
            'Add Customer': '添加客户',
            'Do you want to': '你想吗？',
            'Create new Request?': '你想吗?',
            'Please enter the Title to proceed!': '请输入标题继续！',
            'Request Details': '请求详细信息',
            'Select Device': '选择设备',
            Attachments: '附件',
            Preview: '预览',
            Address: '地址',
            Action: '行动',
            'Please add a model to your cart!': '请在购物车中添加模型！',
            'Added Devices': '添加设备',
            'Add More': '添加更多',
            'Created by': '创建者',
            Back: '返回',
            Close: '关闭',
            'Perfect, your request has been submitted successfully': '完美，您的请求已成功提交',
            'Track your request in': '跟踪您的请求',
            'Create New Request': '创建新请求',
            'An error occurred while creating a request, please try again': '创建请求时会发生错误，请重试',
            'Go to home': '回家',
            Home: '家',
            'My Requests': '我的请求',
            'Starred Requests': '星数请求',
            'Shared With Me': '与我分享',
            'New Requests': '新请求',
            'Active Requests': '主动请求',
            'Closed Requests': '已关闭请求',
            Filters: '过滤 器',
            'Filter Results': '筛选结果',
            'Clear All': '全部清除',
            'Select an option': '选择选项',
            'Add a customer': '添加客户',
            'Search by MSX Opportunity ID / TPID / Customer Name': '通过 MSX 机会 ID / TPID / 客户姓名进行搜索',
            'Enter MSX Opp ID / TPID / Customer Name': '输入 MSX Opp ID / TPID / 客户姓名',
            'Customer Country (Subsidiary)': '客户国（子公司）',
            Search: '搜索',
            'Select from recently attached': '从最近附加的中选择',
            'Customer Name': '客户姓名',
            TPID: 'TPID',
            Country: '国家',
            'MSX Opportunity ID': 'MSX 机会 ID',
            Revenue: '收入',
            Device: '装置',
            Select: '选择',
            'SDSG Application ID': 'SDSG 应用程序 ID',
            'Application ID': '申请 ID',
            'Created On': '创建上',
            'CREATED ON': '创建上',
            'Modified By': '修改由',
            'Modified On': '已修改打开',
            'REQUEST TYPE': '请求类型',
            'TOTAL REVENUE': '总收入',
            'TOTAL IMPACTED DEVICE': '受影响设备总数',
            TITLE: '标题',
            REVENUE: '收入',
            'SDSG APPLICATION ID': 'SDSG 应用程序 ID',
            'RESOLUTION DATE': '解决日期',
            AGING: '老化',
            CUSTOMER: '客户',
            STATUS: '地位',
            DESCRIPTION: '描述',
            'WHAT ACTION IS REQUIRED': '需要采取什么行动',
            'ADDED IMPACT': '增加影响',
            'IMPACT EVIDENCE': '影响证据',
            'ADDITIONAL DETAILS': '其他详细信息',
            Added: '添加',
            Hide: '隐藏',
            MSX: 'MSX',
            'ADDED ON': '已添加',
            'END CUSTOMER': '最终客户',
            'CUSTOMER INDUSTRY': '客户行业',
            COUNTRY: '国家',
            'IMPACTED DEVICE': '受影响的设备',
            'IMPACTED REVENUE': '受影响的收入',
            'Impacted Revenue': '受影响的收入',
            COMMENTS: '评论',
            Comments: '评论',
            ATTACHMENTS: '附件',
            Submit: '提交',
            Cancel: '取消',
            'No data to display': '没有数据显示',
            'Help Request Details': '帮助请求详细信息',
            'Help Requests': '帮助请求',
            'Last Updated': '最后更新',
            'Did not find what you need?': '没找到你需要什么？',
            'Raise a request here': '在此处提出请求',
            Bookmark: '书签',
            Print: '打印',
            Save: '救',
            Share: '共享',
            'Was this information helpful?': '这些信息有帮助吗？',
            'Rate this article': '评价本文',
            Keywords: '关键字',
            'My Account': '我的帐户',
            'My Details': '我的详细信息',
            Favorite: '喜欢',
            Feedback: '反馈',
            Notifications: '通知',
            Logout: '注销',
            'Social Media': '社交媒体',
            'About Me': '关于我',
            'Page not found': '未找到页面',
            'Advanced Search Results': '高级搜索结果',
            'Title Search Results': '标题搜索结果',
            'Download Search Results': '下载搜索结果',
            'Advanced Search': '高级搜索',
            'Title Only Search': '仅限标题搜索',
            'No records found': '未找到记录',
            'Saving...': '储蓄。。。',
            Saved: '保存',
            Redrafted: '重新起草',
            Redraft: '雷德拉夫特',
            Draft: '草案',
            Drafts: '草稿',
            'Save Template': '保存模板',
            'Enter Template Name': '输入模板名称',
            'Select Date': '选择日期',
            'Select Time': '选择时间',
            'Scheduled to Publish': '计划发布',
            'Cancel Scheduling': '取消日程安排',
            'Publish Now': '现在发布',
            'Schedule for Later': '稍后的日程安排',
            'Upload Banner Image': '上传横幅图像',
            'Helpful Resources': '有用资源',
            'Add Resource': '添加资源',
            'Article Type': '文章类型',
            'Last Edited Date': '上次编辑日期',
            'Choose Tags': '选择标签',
            'Limit upto 5': '限制至 5',
            'Last edited': '上次编辑',
            words: '的话',
            'Article title': '文章标题',
            'Manage Content': '管理内容',
            'Publish spotlight, news and training': '发布聚光灯、新闻和培训',
            'Surface content management': '表面内容管理',
            'Manage News': '管理新闻',
            Training: '训练',
            'results for your search': '搜索结果',
            'No results found': '未发现任何结果',
            Published: '发表',
            Removed: '删除',
            'New Post': '新帖子',
            'Select Template': '选择模板',
            'Template Preview': '模板预览',
            'Please select a Template': '请选择模板',
            'Run your imagination wide and create articles': '发挥你的想象力，创造文章',
            'Loading...': '装载。。。',
            'Please check your microphone and audio levels..': '请检查您的麦克风和音频级别。',
            'Expand View': '扩展视图',
            'Collapse View': '折叠视图',
            'Please add the number of products.': '请添加产品数量。',
            'Edit Profile': '编辑配置文件',
            expand: '扩大',
            collapse: '崩溃',
            'Request ID': '请求 ID',
            'See all details': '查看所有详细信息',
            Download: '下载',
            'Fit Into The Screen': '适合屏幕',
            'Maximize View': '最大化视图',
            'List View': '列表视图',
            'Detail View': '详细信息视图',
            'Last Activity': '最后活动',

            'We would love to hear about your experience using the Admin portal': '我们很乐意听到您使用管理员门户体验',
            Great: '伟大',
            Good: '好',
            Okay: '好',
            Bad: '坏',
            Terrible: '可怕',
            'Please select your feedback category': '请选择您的反馈类别',
            Suggestion: '建议',
            Compliment: '称赞',
            'Feedback Response': '反馈响应',
            'Report A Bug': '报告错误',
            'Please provide your feedback below': '请提供以下反馈',
            OK: '还行',
            'We really appreciate you for taking the time to write to us, and for suggesting your views.We are always trying our best to make your experience better for you. You will receive a copy of your feedback on your email':
                '我们非常感谢您花时间写信给我们，并建议您的观点。我们一直在尽力让你的经验更好地为您。您将收到电子邮件中反馈的副本',
            'New Feedback': '新反馈',
            'Past Feedback': '过去的反馈',
            Date: '日期',
            Category: '类别',
            Experience: '经验',
            'Provided By': '提供者',
            'Your Response': '您的回复',
            'No data available to display': '没有可用的数据可供显示',
            'Please fill in some response!': '请填写一些回复！',
            Send: '发送',
            'Responded On': '响应上',
            Response: '响应',
            WEEK: '周',
            Week: '周',
            MONTH: '月',
            Month: '月',
            YEAR: '年',
            Year: '年',
            'TimeLine View': '时间线视图',
            'Open Requests': '开放请求',
            'Requested by': '请求',
            'Resolved Requests': '已解决的请求',
            'The requested details are not available': '未提供所要求的详细信息',
            SDSG: 'SDSG',
            CXP: 'CXP',
            CSS: 'CSS',
            CREW: 'CREW',
            Outline: '大纲',
            Views: '视图',
            Team: '团队',
            TEAM: '团队',
            ID: 'ID',

            'Request Type': '请求类型',
            Status: '地位',
            'Assigned To': '分配给',
            'ASSIGNED TO': '分配给',
            Aging: '老化',
            'TOTAL REVENUE RISK': '总收入风险',
            'Total Revenue Risk': '总收入风险',
            'Revenue Risk': '收入风险',
            'This is the surface content management experience, you can create and upload articles to the knowledge center and news feeds of the surface knowledge portal through this interface.':
                '这是表面内容管理的经验，您可以创建和上传文章到知识中心和新闻源的表面知识门户通过这个界面。',
            'Note: Format as': '注：格式为',
            'Note:': '注意',
            Header2: '头 2',
            'to view as a section in outline': '将视之为大纲中的一节',
            'Featured Authors': '精选作者',
            'View All Posts': '查看所有帖子',
            'Yay! You have seen it all': '耶！你已经看到了这一切',
            'Knowledge Center': '知识中心',
            'View All': '查看所有',
            'Article Search': '文章搜索',
            Latest: '最近的',
            Earliest: '最早'
        }
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)

    .init({
        resources,
        // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
