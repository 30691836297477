import { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    DirectionalHint,
    Icon,
    IconButton,
    IContextualMenuItem,
    IIconProps,
    ITooltipHostStyles,
    ITooltipProps,
    Link,
    Modal,
    Pivot,
    PivotItem,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text,
    TextField,
    Toggle,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import AddAttachmentModal from 'app/components/addAttachmentModal/AddAttachmentModal';
import AddImpact from 'app/components/addCustomer/AddCustomer';
import Button from 'app/components/button/Button';
import CommentSection from 'app/components/commentSection/CommentSection';
import {
    filterField,
    formatHashtags,
    formatIntoRequestData,
    GetText,
    highlightTags,
    injectTargetToAnchor,
    ISOToDateTime,
    keyPress,
    ReplaceUrlByAnchor,
    requestAggregateSubmit,
    getURLForTeamAndType
} from 'app/components/helpers';
import LinkChildrenModal from 'app/components/linkChildrenModal/LinkChildrenModal';
import { Loader } from 'app/components/loader/Loader';
import ShareRequestModal from 'app/components/shareRequestModal/ShareRequestModal';
import StatusSection from 'app/components/statusSection/StatusSection';
import { unifiedDashboardCardsList } from 'app/models/common/enumerators';
import { IAddCustomer, ILookUpData, IUserDetails } from 'app/models/common/post';
import { formatCustomerNames } from 'app/components/helpers';
import { IAttachmentUpdateData, IDevicesDto, IRemoveCustomerImpactData, IRequestParams, IStatusUpdateObject } from 'app/models/common/request';
import { IAddCustomerData, IRequest, ITagsProps, IUserProps } from 'app/models/common/response';
import { AppQueryClient } from 'app/services/clients/query-client';
import {
    useAddBookmark,
    useAddTagRequest,
    useAssociateRequests,
    usePostRequestType,
    useRemoveBookmark,
    useRemoveCustomerImpact,
    useUpdateRequest,
    useUpdateStatus,
    usePostCommentsHashTags,
    useUpdateRequestDevices
} from 'app/services/mutations';
import {
    useFetchUsersListByRole,
    useGetCountries,
    useGetRequestById,
    useGetTagsList,
    useRequestList
} from 'app/services/queries';
import useStore from 'app/store';
import { Entity, BlobStorageContainer, StringConstants } from 'app/utils/constants';
import { attachmentCounter, getPortalValue } from 'app/utils/utilities';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AssignedToFilter from '../manageRequests/filterPanel/assignedToFilter/AssignedToFilter';
import { EllipseMenu } from '../triageMenu/EllipseMenu';
import AssociationTab from './associationTab/AssociationTab';
import CustomerDetailsTab from './customerDetailsTab/CustomerDetailsTab';
import './DetailsPanel.scss';
import RequestDetailsTab from './requestDetailsTab/RequestDetailsTab';
import RequestRichTextEditor from './requestRichTextEditor/RequestRichTextEditor';
import TagsList from './tagsList/TagsList';
import { addBlobFileSasToken } from 'app/utils/blobStorageHelpers';

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Do you want to remove this sales opportunity?'
};

const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block', borderRadius: '13px' }
};

const DetailsPanel = (props: {
    selectedRequest: IRequest;
    requestTypes: ILookUpData[];
    statusTypes: ILookUpData[];
    associationTypes: ILookUpData[];
    setIsAddCustomerModalOpen?: Function;
    teams: ILookUpData[];
    isEditMode?: boolean;
    setIsEditMode?: Function;
    setSelectedRequest?: Function;
    requestData?: IRequest[];
    setRequestData?: Function;
    setPageNumber?: Function;
    isLoading?: boolean;
    pageNumber?: number;
    onRefetchHandler?: Function;
    requestDetailsLookup?: ILookUpData[];
    pageType?: string;
}) => {
    const {
        selectedRequest,
        statusTypes,
        associationTypes,
        isEditMode,
        isLoading,
        pageNumber,
        requestDetailsLookup,
        setIsEditMode,
        setSelectedRequest,
        setPageNumber,
        pageType
    } = props;

    // state variables
    const [isMissingModalOpen, { setTrue: showMissingModal, setFalse: hideMissingModal }] = useBoolean(false);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const history = useHistory();
    const portal = getPortalValue(history?.location?.pathname);
    const [attachmentCount, setAttachmentCount] = useState<number>(0);
    const [selectedTab, setSelectedTab] = useState('REQUEST_DETAILS');
    const [missingDetails, setMissingDetails] = useState<string[]>([]);
    const [options, setOptions] = useState<IUserProps[]>([]);
    const [assignedToSelection, setAssignedToSelection] = useState<IUserProps[]>([]);
    const [resolutionDate, setResolutionDate] = useState<Date>();

    // state variables for rich text editor
    const [description, setDescription] = useState<string>('');
    const [descriptionTextToSubmit, setDescriptionTextToSubmit] = useState<string>('');
    const [whyThisDateIsImportant, setWhyThisDateIsImportant] = useState<string>('');
    const [dateImportantTextToSubmit, setDateImportantTextToSubmit] = useState<string>('');

    const [statusSelection, setStatusSelection] = useState(0);
    const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] = useState(false);
    const [isLinkRequestModalOpen, setIsLinkRequestModalOpen] = useState(false);
    const [isAssociatingParent, setIsAssociatingParent] = useState<boolean>(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [impactForRemoval, setImpactForRemoval] = useState<IRemoveCustomerImpactData>({});
    const [isInvalidTitle, setIsInvalidTitle] = useState(false);
    const [isInvalidDescription, setIsInvalidDescription] = useState(false);
    const [hideUpdateLoaderDialog, { toggle: toggleUpdateLoaderHideDialog }] = useBoolean(true);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const [isShareRequestModalOpen, setIsShareRequestModalOpen] = useState(false);
    const [isShowComment, setIsShowComment] = useState(true);
    const [countyCodePrefix, setCountryCodePrefix] = useState({ contactNumber: '', alternateContactNumber: '' });
    const [parentRequest, setParentRequest] = useState<any>({});

    // data variable used for edit
    const [title, setTitle] = useState('');
    const [titleFieldValue, setTitleFieldValue] = useState<string>('');
    const [editableFields, setEditableFields] = useState({});
    const [isUpdateTriggered, setIsUpdateTriggered] = useState(false);
    const [isShowLoader, setIsShowLoader] = useState(true);
    const [whatBusinessProblemIsSolved, setWhatBusinessProblemIsSolved] = useState('');
    const [competitiveOffering, setCompetitiveOffering] = useState('');
    const [isDesignForSurfaceDFSPossible, setIsDesignForSurfaceDFSPossible] = useState('');
    const [dcfJustification, setDcfJustification] = useState('');
    const [dcfApprovedValue, setDcfApprovedValue] = useState(null);
    const [customerDetailsValidation, setCustomerDetailsValidation] = useState({
        emailValidText: false,
        contactNumberValidText: false,
        alternateContactValidText: false
    });
    const [dcfSR, setDcfSR] = useState('');
    const [cssCaseNumber, setCssCaseNumber] = useState('');
    const [answer, setAnswer] = useState('');
    const [companyInformation, setCompanyInformation] = useState<any>({});
    const [isStarred, setIsStarred] = useState(false);

    const { data: countryList } = useGetCountries();
    const {
        mutate: addBookmark,
        isLoading: isLoadingAddBookmark,
        isSuccess: isSuccessAddBookmark,
        isError: isErrorAddBookMark
    } = useAddBookmark();
    const {
        mutate: removeBookmark,
        isLoading: isLoadingRemoveBookmark,
        isSuccess: isSuccessRemoveBookmark,
        isError: isErrorRemoveBookmark
    } = useRemoveBookmark();
    const { mutate: addTagMutate } = useAddTagRequest();
    const [tagsListData, setTagsListData] = useState([]);
    const [selectedTags, setSelectedTags] = useState<ITagsProps[]>([]);

    const [isUpdateErrorModalOpen, { setTrue: showUpdateErrorModal, setFalse: hideUpdateErrorModal }] = useBoolean(false);

    const onToggleBookmark = () => {
        if (isStarred) {
            removeBookmark(selectedRequest.unifiedTrackingId);
        } else {
            addBookmark(selectedRequest.unifiedTrackingId);
        }
    };

    const pageSize = 500;

    const [criteria, setCriteria] = useState<IRequestParams>({
        from: '2001-01-01T00:00:00Z',
        to: '9999-12-31T23:59:59Z',
        pageNumber: 1,
        pageSize: pageSize,
        type: unifiedDashboardCardsList.ALL_REQUEST,
        requestType: [],
        parentRequestId: undefined,
        portal: portal
    });

    const [correlatedChildrenIds, setCorrelatedChildrenIds] = useState<string[]>([]);
    const { data: usersListData, isLoading: isUserListLoading } = useFetchUsersListByRole(selectedRequest.teamValue);

    const { data: tagsData, isLoading: isTagsLoading } = useGetTagsList();

    useGetRequestById(selectedRequest.isParent ? selectedRequest.unifiedTrackingId : selectedRequest.parentRequestId, {
        enabled:
            selectedRequest !== undefined && (selectedRequest.parentRequestId !== '' || selectedRequest.parentRequestId !== null),
        onSuccess: (response) => {
            if (response !== undefined) {
                setParentRequest(formatIntoRequestData([response.data], [], [])[0]);
                setCriteria({ ...criteria, parentRequestId: response.data.unifiedTrackingId });
            }
        }
    });

    useRequestList(criteria, {
        enabled: criteria !== undefined && criteria.parentRequestId !== undefined,
        onSuccess: (response) => {
            setCorrelatedChildrenIds(response.items.map((item: any) => item.unifiedTrackingId));
        }
    });

    // queries
    const { mutate: removeCustomerImpactMutation } = useRemoveCustomerImpact();

    // helper functions
    const handleModalDismiss = () => {
        hideMissingModal();
        setMissingDetails([]);
    };
    const renderTag = (status: number) => {
        let label = '';
        let tagClass = '';
        switch (status) {
            case 1:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-a';
                break;
            case 2:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-b';
                break;
            case 3:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-c';
                break;
            case 4:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-d';
                break;
            case 5:
                label = GetText(statusTypes, status);
                tagClass = 'tag-type-e';
                break;
            default:
                tagClass = 'tag-type-a';
                break;
        }
        return <Text className={`quick-tag ${tagClass}`}>{label}</Text>;
    };

    const renderAssociationTag = () => {
        let label = '';
        let tagClass = '';
        if (selectedRequest.isParent) {
            label = GetText(associationTypes, 2);
            tagClass = 'tag-type-f';
        }

        //Renders a child tag on the associated request
        if (selectedRequest.parentRequestId !== null && selectedRequest.parentRequestId !== '') {
            label = GetText(associationTypes, 3);
            tagClass = 'tag-type-g';
        }

        return <Text className={`quick-tag ${tagClass}`}>{label}</Text>;
    };

    const handleCancelEdit = () => {
        setIsEditMode(false);
        setIsInvalidDescription(false);
        setIsInvalidTitle(false);
    };

    const [hideLoadingScreen, setHideLoadingScreen] = useState(false);

    const handleAddProduct = () => {
        setIsShowLoader(true);
        toggleUpdateLoaderHideDialog();
        let devicesToUpdate: IDevicesDto = {
            id: selectedRequest.unifiedTrackingId,
            devices: selectedRequest.devices
        } 
        updateRequestDevicesMutation(devicesToUpdate);
    }

    const handleSaveRequest = () => {
        // Add the validations on edit here
        let customerDetailsValidationDetails = { ...customerDetailsValidation };
        let flag = false;
        let customerDetailFlag = false;

        if (descriptionTextToSubmit.trim() === '') {
            flag = true;
            setIsInvalidDescription(true);
        }
        if (title.trim() === '') {
            flag = true;
            setIsInvalidTitle(true);
        }
        if (
            companyInformation?.contactEmailAddress &&
            companyInformation?.contactEmailAddress.trim() !== '' &&
            !companyInformation?.contactEmailAddress.trim().match(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/)
        ) {
            flag = true;
            customerDetailFlag = true;
            customerDetailsValidationDetails = { ...customerDetailsValidationDetails, emailValidText: true };
            if (selectedTab !== StringConstants.CUSTOMER_INFORMATION) {
                setMissingDetails([...missingDetails, 'Customer email ID']);
            }
        }

        if (
            companyInformation?.contactPhoneNumber &&
            companyInformation?.contactPhoneNumber?.trim() !== '' &&
            (!companyInformation?.contactPhoneNumber?.trim().match(/^\d{3,20}$/) ||
                !countyCodePrefix.contactNumber ||
                countyCodePrefix.contactNumber?.trim() === '')
        ) {
            flag = true;
            customerDetailFlag = true;
            customerDetailsValidationDetails = { ...customerDetailsValidationDetails, contactNumberValidText: true };
            if (selectedTab !== StringConstants.CUSTOMER_INFORMATION) {
                setMissingDetails([...missingDetails, 'Contact number']);
            }
        }
        if (
            companyInformation?.alternateContactNumber &&
            companyInformation?.alternateContactNumber?.trim() !== '' &&
            (!companyInformation?.alternateContactNumber?.trim().match(/^\d{3,20}$/) ||
                !countyCodePrefix.alternateContactNumber ||
                countyCodePrefix.alternateContactNumber?.trim() === '')
        ) {
            flag = true;
            customerDetailFlag = true;
            customerDetailsValidationDetails = { ...customerDetailsValidationDetails, alternateContactValidText: true };
            if (selectedTab !== StringConstants.CUSTOMER_INFORMATION) {
                setMissingDetails([...missingDetails, 'Alternative contact number']);
            }
        }
        setCustomerDetailsValidation({ ...customerDetailsValidationDetails });
        if (customerDetailFlag && selectedTab !== StringConstants.CUSTOMER_INFORMATION) {
            showMissingModal();
        }
        if (flag) {
            return false;
        }
        let companyInfo: any = { ...companyInformation };
        if (companyInformation.contactPhoneNumber && companyInformation.contactPhoneNumber.trim() !== '') {
            companyInfo.contactPhoneNumber = countyCodePrefix.contactNumber + ' ' + companyInfo.contactPhoneNumber;
        }
        if (companyInformation.alternateContactNumber && companyInformation.alternateContactNumber.trim() !== '') {
            companyInfo.alternateContactNumber =
                countyCodePrefix.alternateContactNumber + ' ' + companyInformation.alternateContactNumber;
        }
        setIsUpdateTriggered(true);
        setIsShowLoader(true);

        let updatedCssNumber = selectedRequest.updatedCustCssNum ? selectedRequest.updatedCustCssNum : cssCaseNumber;
        setEditableFields((value: any) => ({
            ...value,
            ...{
                summary: title,
                description: descriptionTextToSubmit,
                requestedResolutionDate: moment.utc(resolutionDate).isValid() ? moment.utc(resolutionDate).format() : null,
                status: statusSelection,
                whatBusinessProblemIsSolved: whatBusinessProblemIsSolved,
                competitiveOffering: competitiveOffering,
                isDesignForSurfacedPossible: isDesignForSurfaceDFSPossible,
                dcfJustification: dcfJustification,
                dcfApprovedValue: dcfApprovedValue,
                dcfSR: dcfSR,
                cssCaseNumber: updatedCssNumber,
                answer: answer,
                whyThisDateIsImportant: dateImportantTextToSubmit,
                companyInformation: companyInfo,
                deviceModels: selectedRequest.devices
            },
            hashTags: formatHashtags(
                Array.from(
                    new Set([
                        ...extractHashtagsWithIndices(dateImportantTextToSubmit).map((tags) => tags.hashtag),
                        ...extractHashtagsWithIndices(descriptionTextToSubmit).map((tags) => tags.hashtag)
                    ])
                )
            )
        }));
    };

    const generateEditData = () => {
        let editedData: any = {};
        editedData = { ...editedData, ...editableFields };
        let filteredData: any = {};
        for (const key in editedData) {
            if (filterField(key, selectedRequest.requestTypeValue)) {
                filteredData[key] = editedData[key];
            }
        }

        if (selectedRequest.updatedUnifiedTrackingId) {
            filteredData.status =
                selectedRequest.updatedUnifiedTrackingId !== selectedRequest.unifiedTrackingId ? 1 : selectedRequest.statusValue;
        }

        let selectedRequestTempObj = { ...selectedRequest };

        if (selectedRequestTempObj.updatedUnifiedTrackingId) {
            delete selectedRequestTempObj.updatedUnifiedTrackingId;
            setSelectedRequest(selectedRequestTempObj);
        }

        filteredData.id = selectedRequest.updatedUnifiedTrackingId
            ? selectedRequest.updatedUnifiedTrackingId
            : selectedRequest.unifiedTrackingId;
        filteredData.team = selectedRequest.teamValue;
        filteredData.requestType = selectedRequest.requestTypeValue;
        filteredData.deviceModels = selectedRequest.devices;
        return filteredData;
    };

    const sanitizeValue = (value: any) => {
        if (value === undefined || value === null || value === '') {
            return null;
        } else {
            return value;
        }
    };

    const getContactNumber = (value: string) => {
        let val = value.split(' ');
        if (val && val.length === 2) return val[1];
        else if (val && val.length > 2) {
            let formattedNumber = '';
            val.forEach((item: any, index: number) => {
                if (index !== 0) formattedNumber += item;
            });
            return formattedNumber;
        }
        return value;
    };

    const getCountryCode = (value: string) => {
        if (!value) return null;

        let val = value.split(' ');
        if (countryList) {
            let list: Array<any> = countryList;
            var dialingCodeItems = list.filter((x) => x.dialingCode === val[0]);
            if (dialingCodeItems && dialingCodeItems.length > 0) {
                return dialingCodeItems[0].dialingCode;
            }
            return null;
        }
        return null;
    };

    const initializeEditData = () => {
        let fieldData: any = {};
        // these fields are mandatory
        fieldData.description = selectedRequest.description;
        fieldData.requestedResolutionDate = moment.utc(selectedRequest.dueDateValue).isValid()
            ? moment.utc(selectedRequest.dueDateValue).format()
            : null;
        fieldData.status = sanitizeValue(selectedRequest.statusValue);
        // these fields are optional
        fieldData.criticality = sanitizeValue(selectedRequest.criticality);
        fieldData.impactReach = sanitizeValue(selectedRequest.impactReach);
        fieldData.productCategory = sanitizeValue(selectedRequest.productCategory);
        fieldData.approvedMessaging = sanitizeValue(selectedRequest.approvedMessaging);
        fieldData.whatBusinessProblemIsSolved = sanitizeValue(selectedRequest.whatBusinessProblemIsSolved);
        fieldData.competitiveOffering = sanitizeValue(selectedRequest.competitiveOffering);
        fieldData.isDesignForSurfacedPossible = sanitizeValue(selectedRequest.isDesignForSurfacedPossible);
        fieldData.pfrSubType = sanitizeValue(selectedRequest.pfrSubType);
        fieldData.bugInformations = sanitizeValue(selectedRequest.bugInformations);
        fieldData.bugNumber = sanitizeValue(selectedRequest.bugNumber);
        fieldData.priority = sanitizeValue(selectedRequest.priority);
        fieldData.mainRequestType = sanitizeValue(selectedRequest.mainRequestType);
        fieldData.subRequestType = sanitizeValue(selectedRequest.subRequestType);
        fieldData.taskStatus = sanitizeValue(selectedRequest.taskStatus);
        fieldData.isDcfRequest = sanitizeValue(selectedRequest.isDcfRequest);
        fieldData.dcfJustification = sanitizeValue(selectedRequest.dcfJustification);
        fieldData.isBlockingSale = sanitizeValue(selectedRequest.isBlockingSale);
        fieldData.isDcfApproved = sanitizeValue(selectedRequest.isDcfApproved);
        fieldData.proofOfPurchaseAttached = sanitizeValue(selectedRequest.proofOfPurchaseAttached);
        fieldData.serialNumbersAttached = sanitizeValue(selectedRequest.serialNumbersAttached);
        fieldData.dcfApprovedValue = sanitizeValue(selectedRequest.dcfApprovedValue);
        fieldData.dcfSR = sanitizeValue(selectedRequest.dcfSR);
        fieldData.cssCaseNumber = sanitizeValue(selectedRequest.cssCaseNumber);
        fieldData.answer = sanitizeValue(selectedRequest.answer);
        fieldData.whyThisDateIsImportant = sanitizeValue(selectedRequest.whyThisDateIsImportant);
        fieldData.hashTags = sanitizeValue(selectedRequest.hashTags);
        let companyInfo = {
            companyContactName:
                selectedRequest.companyInformation && selectedRequest.companyInformation.companyContactName
                    ? selectedRequest.companyInformation.companyContactName
                    : null,
            companyFullShippingAddress:
                selectedRequest.companyInformation && selectedRequest.companyInformation.companyFullShippingAddress
                    ? selectedRequest.companyInformation.companyFullShippingAddress
                    : null,
            companyName:
                selectedRequest.companyInformation && selectedRequest.companyInformation.companyName
                    ? selectedRequest.companyInformation.companyName
                    : null,
            contactEmailAddress:
                selectedRequest.companyInformation && selectedRequest.companyInformation.contactEmailAddress
                    ? selectedRequest.companyInformation.contactEmailAddress
                    : null,
            contactPhoneNumber:
                selectedRequest.companyInformation && selectedRequest.companyInformation.contactPhoneNumber
                    ? getContactNumber(selectedRequest.companyInformation.contactPhoneNumber)
                    : null,
            alternateContactNumber:
                selectedRequest.companyInformation && selectedRequest.companyInformation.alternateContactNumber
                    ? getContactNumber(selectedRequest.companyInformation.alternateContactNumber)
                    : null
        };
        fieldData.companyInformation = companyInfo;
        setCountryCodePrefix({
            contactNumber: getCountryCode(selectedRequest?.companyInformation?.contactPhoneNumber),
            alternateContactNumber: getCountryCode(selectedRequest?.companyInformation?.alternateContactNumber)
        });
        setCompanyInformation(companyInfo);

        fieldData.summary = sanitizeValue(selectedRequest.title);
        setEditableFields(fieldData);
    };

    const onActionDropdownChangeHandler = (fieldName: string, fieldValue: any) => {
        setIsUpdateTriggered(false);
        setIsShowLoader(true);
        let fieldData: any = {};
        switch (fieldName) {
            case 'productCategory':
                fieldData.productCategory = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'pfrSubtype':
                fieldData.pfrSubType = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'priority':
                fieldData.priority = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'criticality':
                fieldData.criticality = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'impactReach':
                fieldData.impactReach = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'mainRequestType':
                fieldData.mainRequestType = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'subRequestType':
                fieldData.subRequestType = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'taskStatus':
                fieldData.taskStatus = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'isDcfRequest':
                fieldData.isDcfRequest = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(true);
                break;
            case 'isBlockingSale':
                fieldData.isBlockingSale = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'isDcfApproved':
                fieldData.isDcfApproved = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'proofOfPurchaseAttached':
                fieldData.proofOfPurchaseAttached = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;
            case 'serialNumbersAttached':
                fieldData.serialNumbersAttached = fieldValue;
                if (!isEditMode) {
                    setIsUpdateTriggered(true);
                }
                setIsShowLoader(false);
                break;

            default:
                break;
        }

        let companyInfo: any = { ...companyInformation };
        if (companyInformation.contactPhoneNumber && companyInformation.contactPhoneNumber.trim() !== '') {
            companyInfo.contactPhoneNumber = countyCodePrefix.contactNumber + ' ' + companyInfo.contactPhoneNumber;
        }
        if (companyInformation.alternateContactNumber && companyInformation.alternateContactNumber.trim() !== '') {
            companyInfo.alternateContactNumber =
                countyCodePrefix.alternateContactNumber + ' ' + companyInformation.alternateContactNumber;
        }
        setEditableFields((value: any) => ({ ...value, ...fieldData, companyInformation: companyInfo }));
    };

    const [isStatusClosed, setIsStatusClosed] = useState(false);
    const [statusClosedSKPID, setStatusClosedSKPID] = useState('');

    const {
        mutate: updateStatusMutation,
        isSuccess: isSuccessStatusUpdate,
        isError: isErrorStatusUpdate,
        isLoading: isLoadingStatusUpdate
    } = useUpdateStatus();

    const handleStatusSelect = (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
        setStatusSelection(parseInt(item.key));
        if (!isEditMode) {
            if (selectedRequest.statusValue === parseInt(item.key)) {
                ev.preventDefault();
                return;
            }
            setStatusClosedSKPID('');
            if (item.key === StringConstants.FIVE) {
                setStatusClosedSKPID(selectedRequest.id);
            }
            toggleUpdateLoaderHideDialog();
            setIsShowLoader(true);

            var statusUpdateObject: IStatusUpdateObject = {
                id: selectedRequest.unifiedTrackingId,
                status: parseInt(item.key)
            };

            updateStatusMutation(statusUpdateObject);
        }
    };

    const getModalData = (data: IRequest) => {
        let attachmentData: IAttachmentUpdateData = {};
        attachmentData.applicationId = data.applicationId;
        attachmentData.attachments = data.attachments;
        attachmentData.requestId = data.unifiedTrackingId;
        attachmentData.requestType = data.requestType;
        attachmentData.teamName = data.team;
        attachmentData.summary = data.summary;
        attachmentData.teamValue = data.teamValue;
        attachmentData.requestTypeValue = data.requestTypeValue;
        attachmentData.isHidden = data.isHidden;
        return attachmentData;
    };

    const handleRemoveImpactConfirm = () => {
        let apiRequestData: IRemoveCustomerImpactData = {
            id: selectedRequest.unifiedTrackingId,
            team: selectedRequest.teamValue,
            requestType: selectedRequest.requestTypeValue,
            data: {
                opportunityId: impactForRemoval.opportunityId,
                accountId: impactForRemoval.accountId,
                customerName: impactForRemoval.customerName
            }
        };
        removeCustomerImpactMutation(apiRequestData, {
            onSuccess: () => {
                AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
                AppQueryClient.invalidateQueries(StringConstants.GET_REQUEST_BY_ID);
                AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            }
        });
        toggleHideDialog();
    };

    const onCompletedHandler = () => {
        AppQueryClient.invalidateQueries(StringConstants.GET_REQUEST_BY_ID);
        AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
        AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
    };

    const statusMenu: any = React.useMemo(
        () => ({
            shouldFocusOnMount: true,
            className: 'admin-edit-status-menu',
            items: [
                {
                    key: 1,
                    text: 'New',
                    canCheck: true,
                    isChecked: statusSelection === 1 ? true : false,
                    onClick: handleStatusSelect
                },
                {
                    key: 2,
                    text: 'Active',
                    canCheck: true,
                    isChecked: statusSelection === 2 ? true : false,
                    onClick: handleStatusSelect
                },
                {
                    key: 4,
                    text: 'Resolved',
                    canCheck: true,
                    isChecked: statusSelection === 4 ? true : false,
                    onClick: handleStatusSelect
                },
                {
                    key: 5,
                    text: 'Closed',
                    canCheck: true,
                    isChecked: statusSelection === 5 ? true : false,
                    onClick: handleStatusSelect
                }
            ]
        }),
        [handleStatusSelect, statusSelection, selectedRequest]
    );

    const { mutate: associateRequests } = useAssociateRequests();
    const { mutate: postRequestTypeHelp } = usePostRequestType();

    // State variables for addCustomer
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [displayScreen, setDisplayScreen] = useState('searchCust');

    const handleAddCustomerSuccess = (postAddCustomerResponseData: any, addedCustomerImpacts: IAddCustomer[]) => {
        if (
            selectedRequest.isParent === true ||
            (selectedRequest.parentRequestId !== null && selectedRequest.parentRequestId !== '')
        ) {
            const parentRequestId = selectedRequest.isParent
                ? selectedRequest.unifiedTrackingId
                : selectedRequest.parentRequestId;

            if (parentRequestId !== postAddCustomerResponseData?.data?.id) {
                const associationObject = {
                    requestIds: [postAddCustomerResponseData?.data?.id],
                    associatedRequestId: selectedRequest.isParent
                        ? selectedRequest.unifiedTrackingId
                        : selectedRequest.parentRequestId
                };

                associateRequests(associationObject, {
                    onSuccess: () => {
                        setRequestProcessing(false);
                        setDisplayScreen('successPopUp');

                        AppQueryClient.invalidateQueries('getRequestById');
                        AppQueryClient.invalidateQueries('useRequestList');
                        AppQueryClient.invalidateQueries('requestQuery');
                        AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
                    },
                    onError: () => {
                        setRequestProcessing(false);
                        setDisplayScreen('errorPopUp');
                    }
                });
            }
        } else if (selectedRequest.unifiedTrackingId !== postAddCustomerResponseData?.data?.id) {
            // aggregate parent with selectedRequest becoming aggregate parent
            let datapass = {
                summary: selectedRequest.summary,
                description: selectedRequest.description,

                requestedResolutionDate: selectedRequest.requestedResolutionDate,
                whyThisDateIsImportant: selectedRequest.whyThisDateIsImportant,

                status: 1,
                attachments: selectedRequest.attachments,
                customerImpacts: [] as any[],
                isParent: true
            };

            var aggregateCustomerImpact = [] as any;

            selectedRequest.customerImpacts.forEach((impact) => {
                aggregateCustomerImpact.push(impact);
            });

            datapass.customerImpacts = aggregateCustomerImpact;
            datapass.status = selectedRequest.statusValue;

            const requestAggregateData = requestAggregateSubmit(selectedRequest.requestTypeValue, selectedRequest, datapass);
            postRequestTypeHelp(
                { subUrl: requestAggregateData.subUrl, data: requestAggregateData.datapass },
                {
                    onSuccess: (response) => {
                        associateChildren(response.data.unifiedId, postAddCustomerResponseData?.data?.id);
                    },
                    onError: () => {
                        setRequestProcessing(false);
                        setDisplayScreen('errorPopUp');
                    }
                }
            );
        } else {
            setRequestProcessing(false);
            setDisplayScreen('successPopUp');

            AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY)
        }
    };

    const associateChildren = (parentId: string, postAddCustomerId: string) => {
        const associationObject = {
            requestIds: [selectedRequest.unifiedTrackingId, postAddCustomerId],
            associatedRequestId: parentId
        };

        associateRequests(associationObject, {
            onSuccess: () => {
                setRequestProcessing(false);
                setDisplayScreen('successPopUp');

                AppQueryClient.invalidateQueries('getRequestById');
                AppQueryClient.invalidateQueries('useRequestList');
                AppQueryClient.invalidateQueries('requestQuery');
                AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            },
            onError: () => {
                setRequestProcessing(false);
                setDisplayScreen('errorPopUp');
            }
        });
    };

    const handleAddAttachment = () => {
        setIsAddAttachmentModalOpen(true);
    };

    const resetEditableFields = () => {
        setResolutionDate(new Date(selectedRequest?.dueDateValue));
        setDescription(selectedRequest?.description);
        setStatusSelection(selectedRequest?.statusValue);
        setWhatBusinessProblemIsSolved(selectedRequest?.whatBusinessProblemIsSolved);
        setCompetitiveOffering(selectedRequest?.competitiveOffering);
        setIsDesignForSurfaceDFSPossible(selectedRequest?.isDesignForSurfacedPossible);
        setDcfJustification(selectedRequest?.dcfJustification);
        setDcfApprovedValue(selectedRequest?.dcfApprovedValue);
        setDcfSR(selectedRequest?.dcfSR);
        setCssCaseNumber(selectedRequest?.cssCaseNumber);
        setAnswer(selectedRequest?.answer);
        setWhyThisDateIsImportant(selectedRequest?.whyThisDateIsImportant);
        setTitle(selectedRequest?.title);
        let companyInfo = {
            companyContactName:
                selectedRequest.companyInformation && selectedRequest.companyInformation.companyContactName
                    ? selectedRequest.companyInformation.companyContactName
                    : null,
            companyFullShippingAddress:
                selectedRequest.companyInformation && selectedRequest.companyInformation.companyFullShippingAddress
                    ? selectedRequest.companyInformation.companyFullShippingAddress
                    : null,
            companyName:
                selectedRequest.companyInformation && selectedRequest.companyInformation.companyName
                    ? selectedRequest.companyInformation.companyName
                    : null,
            contactEmailAddress:
                selectedRequest.companyInformation && selectedRequest.companyInformation.contactEmailAddress
                    ? selectedRequest.companyInformation.contactEmailAddress
                    : null,
            contactPhoneNumber:
                selectedRequest.companyInformation && selectedRequest.companyInformation.contactPhoneNumber
                    ? getContactNumber(selectedRequest.companyInformation.contactPhoneNumber)
                    : null,
            alternateContactNumber:
                selectedRequest.companyInformation && selectedRequest.companyInformation.alternateContactNumber
                    ? getContactNumber(selectedRequest.companyInformation.alternateContactNumber)
                    : null
        };
        setCountryCodePrefix({
            contactNumber: getCountryCode(selectedRequest?.companyInformation?.contactPhoneNumber),
            alternateContactNumber: getCountryCode(selectedRequest?.companyInformation?.alternateContactNumber)
        });
        setCompanyInformation(companyInfo);
    };

    const handleShareRequest = () => {
        setIsShareRequestModalOpen(true);
    };

    const getDetailsForSharedRequest = (requestData: IRequest) => {
        return {
            id: requestData.id,
            unifiedTrackingId: requestData.unifiedTrackingId,
            requestType: requestData.requestTypeValue,
            requestTeam: requestData.teamValue,
            summary: requestData.summary
        };
    };

    const onCommentToggleChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setIsShowComment(!checked);
    };

    const titleEdit = (e: any) => {
        setTitle(e.target.value);
        setTitleFieldValue(e.target.value);
        setIsInvalidTitle(false);
    };

    const bugInfoUpdateHandler = (data: any) => {
        setIsUpdateTriggered(true);
        let fieldData: any = {};
        fieldData.bugInformations = data;
        setEditableFields((value: any) => ({ ...value, ...fieldData }));
    };

    const handleUpdateModalState = (value: boolean, associateSuccess: boolean) => {
        if (!value && associateSuccess) {
            AppQueryClient.invalidateQueries('useRequestList').then(() => {
                AppQueryClient.invalidateQueries(StringConstants.GET_REQUEST_BY_ID).then(async () => {
                    await AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
                    await AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
                });
            });
        }

        setIsLinkRequestModalOpen(value);
    };

    // queries
    const {
        mutate: updateRequestMutation,
        isSuccess: isSuccessUpdate,
        isError: isErrorUpdate,
        isLoading: isLoadingUpdate
    } = useUpdateRequest();

    const {
        mutate: updateRequestDevicesMutation,
        isSuccess: isSuccessDeviceUpdate,
        isError: isErrorDeviceUpdate,
        isLoading: isLoadingDeviceUpdate
    } = useUpdateRequestDevices();

    // hooks
    useEffect(() => {
        resetEditableFields();
        if (selectedRequest?.assignee?.id) {
            setAssignedToSelection([
                {
                    id: selectedRequest?.assignee?.id,
                    displayName: selectedRequest?.assignee?.displayName,
                    mail: selectedRequest?.assignee?.email,
                    text: selectedRequest?.assignee?.displayName
                }
            ]);
        } else {
            setAssignedToSelection([
                {
                    id: '0',
                    displayName: StringConstants.UNASSIGNED,
                    text: StringConstants.UNASSIGNED
                }
            ]);
        }
    }, [selectedRequest, options]);
    useEffect(() => {
        if (!isUserListLoading && usersListData) {
            let arr: IUserProps[] = [];
            if (usersListData.filter((item: IUserProps) => item.id === userDetails.id).length !== 0) {
                arr.push({
                    id: userDetails.id,
                    displayName: userDetails.displayName,
                    mail: userDetails.email,
                    mobilePhone: userDetails.contactNumber,
                    businessPhones: userDetails.businessPhones,
                    text: '@Me'
                });
            }
            arr.push({
                id: '0',
                displayName: StringConstants.UNASSIGNED,
                text: StringConstants.UNASSIGNED,
                mail: StringConstants.UNASSIGNED
            });
            usersListData
                .filter((item: IUserProps) => item.id !== userDetails.id)
                .forEach((element: IUserProps) => {
                    arr.push({ ...element, text: element.displayName });
                });
            setOptions(arr);
        }
    }, [usersListData, isUserListLoading]);
    useEffect(() => {
        if (!isEditMode) {
            setCustomerDetailsValidation({
                contactNumberValidText: false,
                alternateContactValidText: false,
                emailValidText: false
            });
        }
    }, [isEditMode]);
    useEffect(() => {
        if (!isEditMode) {
            setIsEditMode(false);
            resetEditableFields();
        }
    }, [isEditMode]);
    useEffect(() => {
        setTitleFieldValue(title);
    }, [isEditMode]);

    useEffect(() => {
        setStatusSelection(selectedRequest?.statusValue);
        setIsInvalidDescription(false);
        setIsShowComment(true);
        setSelectedTags(selectedRequest.tags);
    }, [selectedRequest]);

    useEffect(() => {
        if (isSuccessUpdate || isSuccessDeviceUpdate || (isSuccessStatusUpdate && statusClosedSKPID !== '')) {
            setHideLoadingScreen(true);
            setIsStatusClosed(true);
            setStatusClosedSKPID('');
            setIsStatusClosed(false);
        }
        if ((!isLoadingUpdate || !isLoadingStatusUpdate || !isLoadingDeviceUpdate) && (isSuccessUpdate || isSuccessStatusUpdate || isSuccessDeviceUpdate)) {
            if (isEditMode) {
                setIsEditMode(false);
            }

            AppQueryClient.invalidateQueries(StringConstants.GET_REQUEST_BY_ID);
            AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY).then(() => {
                if (isShowLoader && !isModalOpen) {
                    AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
                    setIsShowLoader(false);
                    toggleUpdateLoaderHideDialog();
                }
            });
        }

        if ((!isLoadingUpdate || !isLoadingStatusUpdate || !isLoadingDeviceUpdate) && (isErrorUpdate || isErrorStatusUpdate || isErrorDeviceUpdate)) {
            if (isShowLoader && !isModalOpen) {
                setIsShowLoader(false);
                toggleUpdateLoaderHideDialog();
            }
            showUpdateErrorModal();
        }
    }, [isSuccessUpdate, isErrorUpdate, isSuccessStatusUpdate, isErrorStatusUpdate, isLoadingStatusUpdate, isLoadingUpdate, isSuccessDeviceUpdate, isErrorDeviceUpdate, isLoadingDeviceUpdate]);

    useEffect(() => {
        if (!isTagsLoading) {
            setTagsListData(tagsData);
        }
    }, [isTagsLoading]);

    //add customer
    const addSuccessPopUp = useStore((state: any) => state.addSuccessPopUp);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const titleId = useId('title');

    const getModalAddCustomerData = (data: IRequest) => {
        let customerData: IAddCustomerData = {};
        customerData.applicationId = data.applicationId;
        customerData.unifiedTrackingId = data.unifiedTrackingId;
        customerData.requestType = data.requestTypeValue;
        customerData.requestTeam = data.teamValue;
        customerData.summary = data.summary;
        customerData.customerImpacts = data.customerImpacts;
        return customerData;
    };

    const addTagsRequest = () => {
        let addTag = {
            requestId: selectedRequest.unifiedTrackingId,
            selectedValues: selectedTags
        };
        addTagMutate(addTag);
    };

    useEffect(() => {
        setIsUpdateTriggered(false);
        initializeEditData();
    }, [selectedRequest, countryList]);

    useEffect(() => {
        if (isUpdateTriggered) {
            if (isShowLoader && !isModalOpen) {
                toggleUpdateLoaderHideDialog();
            }
            let dataToUpdate = generateEditData();
            updateRequestMutation(dataToUpdate);
            setIsUpdateTriggered(false);
        }
    }, [editableFields]);

    useEffect(() => {
        setIsStarred(selectedRequest.isBookmarked);
    }, [selectedRequest.isBookmarked]);

    const tooltipId = useId('tooltip');

    const tooltipProps: ITooltipProps = React.useMemo(
        () => ({
            onRenderContent: () => (
                <ul className="tags-list">
                    {selectedRequest &&
                        selectedRequest.tags &&
                        selectedRequest.tags?.slice(1).map((item) => (
                            <li>
                                <span className="tags-txt1">{item.name}</span>
                            </li>
                        ))}
                </ul>
            ),

            calloutProps: {
                styles: {
                    beak: { padding: '0' },
                    beakCurtain: { borderRadius: '13px', padding: '0' },
                    calloutMain: { borderRadius: '10px' }
                }
            }
        }),
        [selectedRequest]
    );

    useEffect(() => {
        if (!isLoadingAddBookmark && (isSuccessAddBookmark || isErrorAddBookMark)) {
            if (pageType === StringConstants.DETAILS_PAGE) {
                AppQueryClient.invalidateQueries(StringConstants.GET_REQUEST_BY_ID);
            } else {
                AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
                AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            }
        }
        if (!isLoadingRemoveBookmark && (isSuccessRemoveBookmark || isErrorRemoveBookmark)) {
            if (pageType === StringConstants.DETAILS_PAGE) {
                AppQueryClient.invalidateQueries(StringConstants.GET_REQUEST_BY_ID);
            } else {
                AppQueryClient.invalidateQueries(StringConstants.REQUEST_QUERY);
                AppQueryClient.invalidateQueries(StringConstants.LIST_BUILDER_QUERY);
            }
        }
    }, [
        isLoadingAddBookmark,
        isSuccessAddBookmark,
        isErrorAddBookMark,
        isLoadingRemoveBookmark,
        isSuccessRemoveBookmark,
        isErrorRemoveBookmark
    ]);

    useEffect(() => {
        const updateAttachmentCount = () => {
            setAttachmentCount(attachmentCounter(selectedRequest?.attachments, portal));
        };

        updateAttachmentCount();
    }, [selectedRequest]);

    const [hashTagsFormatted, setHashTagsFormatted] = useState<any[]>([]);
    const { mutate: postHashTags } = usePostCommentsHashTags();

    useEffect(() => {
        if (isSuccessUpdate && selectedRequest) {
            let url = getURLForTeamAndType(
                selectedRequest.requestTeam || selectedRequest.teamValue,
                selectedRequest.requestTypeValue || selectedRequest.requestType
            );
            if (Array.isArray(hashTagsFormatted) && hashTagsFormatted?.length > 0) {
                postHashTags({ url: url, data: hashTagsFormatted, correlationId: selectedRequest.unifiedTrackingId });
            }
        }
    }, [isSuccessUpdate, selectedRequest]);

        // add sas token to storage urls in description and whyThisDateIsImportant
    const [selectedRequestDescription, setSelectedRequestDescription] = useState(selectedRequest?.description);
    const [selectedRequestWhyThisDateIsImportant, setSelectedRequestWhyThisDateIsImportant] = useState(selectedRequest?.whyThisDateIsImportant);
    useEffect(() => {
        const replaceStorageUrls = async () => {
            let description = await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.REQUEST_DETAIL_FILES, selectedRequest?.description);
            setSelectedRequestDescription(description);

            let importance = await addBlobFileSasToken(Entity.REQUEST, BlobStorageContainer.REQUEST_DETAIL_FILES, selectedRequest?.whyThisDateIsImportant);
            setSelectedRequestWhyThisDateIsImportant(importance);
        }

        replaceStorageUrls();
    }, [selectedRequest])

    return (
        <>
            {isStatusClosed && (
                <div className="closed-status-banner">
                    Request {statusClosedSKPID} has been Closed. Please go to &nbsp;<span> Closed Request </span>&nbsp; tab to
                    view
                </div>
            )}
            <Modal isOpen={isMissingModalOpen} onDismiss={hideMissingModal} isBlocking={false} containerClassName="missing-modal">
                <div className="modal-heading">
                    <Text>Details missing</Text>
                    <IconButton
                        role="button"
                        className="modal-close"
                        iconProps={cancelIcon}
                        ariaLabel="Close missing popup modal"
                        onClick={handleModalDismiss}
                        onKeyPress={(e: any) => keyPress(e, handleModalDismiss)}
                        tabIndex={0}
                    />
                </div>
                <div className="missing-modal-body">
                    <Text>
                        The below mentioned details are missing in the{' '}
                        <Link
                            onClick={() => {
                                handleModalDismiss();
                                setSelectedTab(StringConstants.CUSTOMER_INFORMATION);
                            }}>
                            Customer Information
                        </Link>{' '}
                        tab.
                    </Text>
                    <ul>
                        {missingDetails.map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                    <Text>Navigate to the mentioned tab?</Text>
                    <div className="btn-cont">
                        <PrimaryButton
                            onClick={() => {
                                handleModalDismiss();
                                setSelectedTab(StringConstants.CUSTOMER_INFORMATION);
                            }}
                            text="Okay"
                        />
                    </div>
                </div>
            </Modal>
            <div className="details-panel-container">
                <div className="scroll-panel">
                    {selectedRequest && Object.keys(selectedRequest).length > 0 && (
                        <>
                            <div className="details-panel-wrapper">
                                <div className="dp-header">
                                    <div className="head-col-left">
                                        <Text className="dp-head-title">{selectedRequest.unifiedTrackingId}</Text>
                                        {renderTag(selectedRequest.statusValue)}
                                        {renderAssociationTag()}
                                        <div className="dp-head-desc">
                                            {!isEditMode && (
                                                <TooltipHost content={selectedRequest.title} id={tooltipId}>
                                                    <Text className="title-field">{selectedRequest.title}</Text>{' '}
                                                </TooltipHost>
                                            )}
                                            {isEditMode && (
                                                <div className="m-b-20">
                                                    <TextField
                                                        value={isEditMode ? titleFieldValue : title}
                                                        placeholder="Enter title"
                                                        resizable={false}
                                                        borderless
                                                        onChange={(e) => titleEdit(e)}
                                                        className="editTitle"
                                                    />
                                                </div>
                                            )}

                                            {isInvalidTitle && (
                                                <div className="error-msg-block m-b-30">Please enter a valid title</div>
                                            )}
                                        </div>
                                        <div className="dp-head-user">
                                            <Text>
                                                <Trans>Team Assigned</Trans> :{' '}
                                                {selectedRequest.team ? selectedRequest.team : ' --'}
                                            </Text>
                                            <Text className="dp-head-separator">|</Text>
                                            {selectedRequest.isParent ?
                                            <Text>
                                                <Trans>Number of Customers</Trans> :{' '}
                                                <Text>{selectedRequest.childrenCount}</Text>
                                            </Text> :
                                            <Text>
                                                <Trans>Customer</Trans> :{' '}
                                                <Text>{selectedRequest?.customerImpacts[0]?.customerName? formatCustomerNames(selectedRequest?.customerImpacts[0]?.customerName) : " -- "}</Text>
                                            </Text>}
                                            <Text className="dp-head-separator">|</Text>
                                            <Text>
                                                <Trans>Created On</Trans> :{' '}
                                                {ISOToDateTime(selectedRequest.createdOn, 'DD MMM YYYY')}
                                            </Text>
                                            <Text className="dp-head-separator">|</Text>
                                            <Text>
                                                <Trans>Submitted By</Trans> : {selectedRequest.createdBy?.displayName}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className="head-col-right">
                                        {!isEditMode && !selectedRequest.isHidden && (
                                            <DefaultButton
                                                className="default add-cust-btn"
                                                onClick={() => {
                                                    addSuccessPopUp(false);
                                                    showModal();
                                                }}>
                                                <Icon iconName="Add" className="m-r-5" /> <Text>Add Customer</Text>
                                            </DefaultButton>
                                        )}
                                        {hideModal && (
                                            <Modal
                                                layerProps={{ eventBubblingEnabled: true }}
                                                titleAriaId={titleId}
                                                isOpen={isModalOpen}
                                                onDismiss={hideModal}
                                                isBlocking={false}
                                                className="add-impact-model">
                                                <AddImpact
                                                    selectedRequest={getModalAddCustomerData(selectedRequest)}
                                                    onDismiss={hideModal}
                                                    onSuccessHandler={handleAddCustomerSuccess}
                                                    globalSelectedRequest={selectedRequest}
                                                    setSelectedRequest={setSelectedRequest}
                                                    requestProcessing={requestProcessing}
                                                    setRequestProcessing={setRequestProcessing}
                                                    displayScreen={displayScreen}
                                                    setDisplayScreen={setDisplayScreen}
                                                />
                                            </Modal>
                                        )}
                                        {isEditMode && (
                                            <span className="form-btn-wrap">
                                                <DefaultButton className="default" onClick={handleCancelEdit}>
                                                    Cancel
                                                </DefaultButton>
                                                <DefaultButton className="primary m-l-10" onClick={handleSaveRequest}>
                                                    Save
                                                </DefaultButton>
                                            </span>
                                        )}
                                        <EllipseMenu
                                            isHidden={selectedRequest.isHidden}
                                            row={selectedRequest}
                                            origin={StringConstants.DETAIL_VIEW}
                                            isEditMode={isEditMode}
                                            setIsEditMode={setIsEditMode}
                                            rootIdName="grid-details-view-ellipse-id"
                                            setSelectedRequest={setSelectedRequest}
                                            page={pageNumber}
                                            setPage={setPageNumber}
                                        />
                                    </div>
                                </div>
                                <div></div>
                                <div className="dp-content">
                                    <div className="dp-details">
                                        <div className="shared-container">
                                            <AssignedToFilter
                                                selectedKeys={assignedToSelection}
                                                setSelectedKeys={setAssignedToSelection}
                                                origin={StringConstants.DETAILS_PANEL}
                                                options={options}
                                                selectedRequest={selectedRequest}
                                            />

                                            {tagsListData && (
                                                <div className="tags-block">
                                                    <span className="sperator-line"></span>
                                                    <div className="tag-icon-wrap">
                                                        <span className="tagSolidIcon"></span>
                                                        <Text> TAG(s) :</Text>
                                                    </div>

                                                    {selectedTags && (
                                                        <TagsList
                                                            options={tagsListData}
                                                            selectedKeys={selectedTags}
                                                            setSelectedKeys={setSelectedTags}
                                                            addTagsRequest={addTagsRequest}
                                                        />
                                                    )}
                                                    {selectedRequest?.tags?.length > 0 && (
                                                        <span className="tag-list">{selectedRequest?.tags[0]?.name} </span>
                                                    )}

                                                    {selectedRequest?.tags && selectedRequest?.tags.length > 1 && (
                                                        <span className="device-count-container">
                                                            <TooltipHost
                                                                tooltipProps={tooltipProps}
                                                                delay={TooltipDelay.zero}
                                                                id={tooltipId}
                                                                directionalHint={DirectionalHint.bottomCenter}
                                                                styles={hostStyles}
                                                                className="hostStyles-new">
                                                                <Text className="device-count">
                                                                    +{selectedRequest?.tags.length - 1}
                                                                </Text>
                                                            </TooltipHost>
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            <div>
                                                {!selectedRequest.isHidden && (
                                                    <span className="shared-icon-container" onClick={onToggleBookmark}>
                                                        <Icon
                                                            title="Star icon"
                                                            aria-label="starred"
                                                            iconName={isStarred ? `FavoriteStarFill` : 'FavoriteStar'}
                                                            className={`icon ${isStarred ? 'starred' : ''}`}
                                                        />
                                                    </span>
                                                )}

                                                <span className="shared-icon-container" onClick={handleAddAttachment}>
                                                    <i className="shared-icon attch-icon"></i>
                                                    <Text className="shared-txt">{attachmentCount}</Text>
                                                </span>
                                                {!selectedRequest.isHidden && (
                                                    <span className="shared-icon-container mr-0" onClick={handleShareRequest}>
                                                        <i className="shared-icon share-icon"></i>
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="dp-content-title">
                                            <Text>
                                                <Trans>DESCRIPTION</Trans>
                                            </Text>
                                        </div>
                                        {isEditMode && (
                                            <div className="desc-textfield-wrap m-b-20">
                                                <RequestRichTextEditor
                                                    content={selectedRequestDescription}
                                                    hashTagsFormatted={hashTagsFormatted}
                                                    setHashTagsFormatted={setHashTagsFormatted}
                                                    setTextToSubmit={setDescriptionTextToSubmit}
                                                    fileStorageContainer={BlobStorageContainer.COMMENT_STATUS_FILES}
                                                />
                                            </div>
                                        )}
                                        {isInvalidDescription && (
                                            <div className="error-msg-block m-b-30">Please enter a description</div>
                                        )}
                                        {!isEditMode && !selectedRequest.description && '--'}
                                        {!isEditMode && (
                                            <div className="dp-content-text">
                                                <Text>
                                                    {selectedRequest.description && (
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: highlightTags(
                                                                    ReplaceUrlByAnchor(
                                                                        injectTargetToAnchor(selectedRequestDescription),
                                                                        'link-item',
                                                                        true
                                                                    )
                                                                )
                                                            }}
                                                        ></span>
                                                    )}
                                                </Text>
                                            </div>
                                        )}

                                        <div className="pivot-container">
                                            <div className="pivot-header-container">
                                                <Text>REQUEST INFORMATION :</Text>
                                            </div>
                                            <Pivot
                                                selectedKey={selectedTab}
                                                onLinkClick={(item) => setSelectedTab(item.props.itemKey)}>
                                                <PivotItem
                                                    className="tab-content"
                                                    itemKey={StringConstants.REQUEST_DETAILS}
                                                    itemID="request-details-tab"
                                                    headerText={'Request Details'}>
                                                    {!isLoading && (
                                                        <RequestDetailsTab
                                                            selectedRequest={selectedRequest}
                                                            requestDetailsLookup={requestDetailsLookup}
                                                            isEditMode={isEditMode}
                                                            resolutionDate={resolutionDate}
                                                            setResolutionDate={setResolutionDate}
                                                            statusMenu={statusMenu}
                                                            statusTypes={statusTypes}
                                                            statusSelection={statusSelection}
                                                            onCompleteHandler={onActionDropdownChangeHandler}
                                                            whatbusinessproblemissolved={whatBusinessProblemIsSolved}
                                                            setwhatbusinessproblemissolved={setWhatBusinessProblemIsSolved}
                                                            competitiveoffering={competitiveOffering}
                                                            setCompetitiveoffering={setCompetitiveOffering}
                                                            isdesignforsurfacedfspossible={isDesignForSurfaceDFSPossible}
                                                            setIsdesignforsurfacedfspossible={setIsDesignForSurfaceDFSPossible}
                                                            dcfJustification={dcfJustification}
                                                            setDcfJustification={setDcfJustification}
                                                            dcfApprovedValue={dcfApprovedValue}
                                                            setDcfApprovedValue={setDcfApprovedValue}
                                                            dcfSR={dcfSR}
                                                            setDcfSR={setDcfSR}
                                                            cssCaseNumber={cssCaseNumber}
                                                            setCssCaseNumber={setCssCaseNumber}
                                                            answer={answer}
                                                            setAnswer={setAnswer}
                                                            whyThisDateIsImportant={selectedRequestWhyThisDateIsImportant}
                                                            setWhyThisDateIsImportant={setWhyThisDateIsImportant}
                                                            setTextToSubmit={setDateImportantTextToSubmit}
                                                            handleAddProduct={handleAddProduct}
                                                            hideLoadingScreen={hideLoadingScreen}
                                                            bugInfoUpdateHandler={bugInfoUpdateHandler}
                                                            hashTagsFormatted={hashTagsFormatted}
                                                            setHashTagsFormatted={setHashTagsFormatted}
                                                        />
                                                    )}
                                                </PivotItem>
                                                <PivotItem
                                                    className="tab-content"
                                                    itemKey={StringConstants.CUSTOMER_INFORMATION}
                                                    itemID="customer-info-tab"
                                                    headerText={'Customer Information'}>
                                                    <CustomerDetailsTab
                                                        selectedRequest={selectedRequest}
                                                        toggleHideDialog={toggleHideDialog}
                                                        setImpactForRemoval={setImpactForRemoval}
                                                        isEditMode={isEditMode}
                                                        companyInformation={companyInformation}
                                                        setCompanyInformation={setCompanyInformation}
                                                        customerDetailsValidation={customerDetailsValidation}
                                                        setCustomerDetailsValidation={setCustomerDetailsValidation}
                                                        countyCodePrefix={countyCodePrefix}
                                                        setCountryCodePrefix={setCountryCodePrefix}
                                                        countryList={countryList}
                                                        onCompletedHandler={onCompletedHandler}
                                                    />
                                                </PivotItem>
                                                <PivotItem
                                                    className="tab-content"
                                                    itemID="association-tab"
                                                    headerText={'Parent-Child Association'}>
                                                    <AssociationTab
                                                        setIsLinkRequestModalOpen={setIsLinkRequestModalOpen}
                                                        selectedRequest={selectedRequest}
                                                        setSelectedRequest={setSelectedRequest}
                                                        setIsAssociatingParent={setIsAssociatingParent}
                                                        setIsEditMode={setIsEditMode}
                                                        parent={parentRequest}
                                                    />
                                                </PivotItem>
                                                <PivotItem
                                                    className="tab-content"
                                                    itemID="comment-status-tab"
                                                    itemKey={StringConstants.COMMENTS_STATUS}
                                                    headerText={'Comment & Status'}>
                                                    <div className="comments-status-toggle-block">
                                                        <span
                                                            className={`${isShowComment ? 'active' : ''}`}
                                                            onClick={() => setIsShowComment(true)}>
                                                            Comment
                                                        </span>
                                                        <Toggle
                                                            checked={!isShowComment}
                                                            ariaLabel="status and comment switch"
                                                            onChange={onCommentToggleChange}
                                                        />{' '}
                                                        <span
                                                            className={`${!isShowComment ? 'active' : ''}`}
                                                            onClick={() => setIsShowComment(false)}>
                                                            Status
                                                        </span>
                                                    </div>
                                                    {isShowComment && selectedRequest.unifiedTrackingId && (
                                                        <CommentSection
                                                            correlationId={selectedRequest.unifiedTrackingId}
                                                            isMultipleType={true}
                                                            defaultIsInternal={true}
                                                            noExternalSection={selectedRequest.isHidden}
                                                            selectedRequest={selectedRequest}
                                                        />
                                                    )}
                                                    {!isShowComment && selectedRequest.unifiedTrackingId && (
                                                        <StatusSection
                                                            correlatedChildrenIds={
                                                                selectedRequest.isParent === true ? correlatedChildrenIds : []
                                                            }
                                                            correlationId={selectedRequest.unifiedTrackingId}
                                                            isMultipleType={true}
                                                            defaultIsInternal={true}
                                                            noExternalSection={selectedRequest.isHidden}
                                                            selectedRequest={selectedRequest}
                                                        />
                                                    )}
                                                </PivotItem>
                                            </Pivot>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {isLoading && <Loader classNames="m-t-20 m-b-50 height-70" />}
                    {!isLoading && selectedRequest && Object.keys(selectedRequest).length === 0 && (
                        <div className="no-data">
                            <Trans>No data to display</Trans>
                        </div>
                    )}
                </div>
                {isAddAttachmentModalOpen && (
                    <AddAttachmentModal
                        modalState={isAddAttachmentModalOpen}
                        updateModalState={(value: boolean) => setIsAddAttachmentModalOpen(value)}
                        selectedRequest={getModalData(selectedRequest)}
                        entityType={1}
                        onCompletedHandler={onCompletedHandler}
                    />
                )}
                {isLinkRequestModalOpen && (
                    <LinkChildrenModal
                        modalState={isLinkRequestModalOpen}
                        updateModalState={handleUpdateModalState}
                        selectedRequest={selectedRequest}
                        entityType={1}
                        onCompletedHandler={onCompletedHandler}
                        setSelectedRequest={setSelectedRequest}
                        isAssociatingParent={isAssociatingParent}
                    />
                )}
                <Dialog
                    hidden={hideDialog}
                    onDismiss={toggleHideDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={{
                        isBlocking: true,
                        className: 'remove-impact-confirmation'
                    }}>
                    <DialogFooter>
                        <DefaultButton onClick={handleRemoveImpactConfirm} text="Remove" />
                        <PrimaryButton onClick={toggleHideDialog} text="Cancel" />
                    </DialogFooter>
                </Dialog>
                <Dialog
                    hidden={hideUpdateLoaderDialog}
                    onDismiss={toggleUpdateLoaderHideDialog}
                    modalProps={{ isBlocking: false }}>
                    <Spinner size={SpinnerSize.large} label="Updating details. Please wait..." />
                </Dialog>

                <ShareRequestModal
                    modalState={isShareRequestModalOpen}
                    updateModalState={(value: boolean) => setIsShareRequestModalOpen(value)}
                    selectedRequest={getDetailsForSharedRequest(selectedRequest)}
                />

                <Modal
                    isOpen={isUpdateErrorModalOpen}
                    onDismiss={hideUpdateErrorModal}
                    isBlocking={false}
                    className="surface-modal notification-modal">
                    <div className="modal-header">
                        <span className="p-b-20"></span>
                    </div>
                    <div className="modal-body text-center">
                        <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                        <h4 className="m-t-5 m-b-5">Something went wrong, please try again.</h4>
                    </div>
                    <div className="modal-footer">
                        <Button buttonClass="btn btn--primary" buttonText="OK" onClick={hideUpdateErrorModal} />
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default DetailsPanel;
