export enum ArticleAction {
    SaveDraft = 1, //Save as Draft
    Submit, //Draft -> Submitted
    Redraft, //Submitted -> Draft; ReviewNotes is mandatory
    SchedulePublish, //Submitted -> ScheduledToPublish; Sets ScheduledPublishDateTime
    Publish, //Submitted -> Published
    Remove, //Published -> Removed
    SubscribeNewsletter,
    SubscribeKnowledgeCenter,
    UnsubscribeNewsletter,
    UnsubscribeKnowledgeCenter
}
