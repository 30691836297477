import React, { useEffect, useState } from 'react';
import { Modal, IconButton, IIconProps, Text, DefaultButton, Image } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import './ShareRequestModal.scss';
import { IRequest } from 'app/models/common/response';
import { PeoplePicker } from 'app/components/peoplePicker/PeoplePicker';
import { useShareRequest } from 'app/services/mutations';
import { IShareRequest, IUserData } from 'app/models/common/request';
import checkIcon from 'app/static/icons/success_modal_icon.svg';
import { keyPress } from 'app/components/helpers';

const titleId = 'share-request-modal';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IProps {
    modalState: boolean;
    updateModalState: Function;
    selectedRequest: IRequest;
}

const ShareRequestModal: React.FC<IProps> = (props) => {
    // state variables
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(props.modalState);

    const [selectedPeople, setSelectedPeople] = useState([]);
    const [notes, setNotes] = useState('');
    const [isFormView, setIsFormView] = useState(true);
    const [isError, setIsError] = useState(false);

    // queries
    const { mutate: shareRequestMutation, isLoading, isError: isErrorSharing, isSuccess: isSuccessSharing } = useShareRequest();

    // helper functions
    const submitShareRequest = () => {
        let dataObj: IShareRequest = {};
        let usersArray: IUserData[] = [];
        for (const user of selectedPeople) {
            usersArray.push({
                id: user.id,
                displayName: user.displayName,
                email: user.mail
            });
        }
        dataObj.requestId = props.selectedRequest.id;
        dataObj.users = usersArray;
        dataObj.attachments = [];
        dataObj.requestType = props.selectedRequest.requestType;
        dataObj.team = props.selectedRequest.requestTeam;
        if (usersArray.length > 0) {
            shareRequestMutation(dataObj);
        } else {
            setIsError(true);
        }
    };

    const handleModalDismiss = () => {
        props.updateModalState(false);

        setIsFormView(true);
    };

    // hooks
    useEffect(() => {
        setIsFormView(true);
    }, []);

    useEffect(() => {
        const toggleModal = (modalState: boolean) => {
            if (modalState) {
                showModal();
            } else {
                hideModal();
            }
        };
        toggleModal(props.modalState);
        setIsError(false);
        setSelectedPeople([]);
        setNotes('');
    }, [props.modalState, showModal, hideModal]);

    useEffect(() => {
        setIsError(false);
    }, [selectedPeople, notes]);

    useEffect(() => {
        if (isErrorSharing || isSuccessSharing) {
            setIsFormView(false);
        }
    }, [isErrorSharing, isSuccessSharing]);

    return (
        <>
            {props.modalState && (
                <Modal
                    titleAriaId={titleId}
                    isOpen={isModalOpen}
                    onDismiss={handleModalDismiss}
                    isBlocking={false}
                    containerClassName="share-request-modal">
                    {isFormView && (
                        <>
                            <div className="modal-head">
                                <Text>Share request</Text>
                                <IconButton
                                    role="button"
                                    className="modal-close"
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={handleModalDismiss}
                                    onKeyPress={(e) => keyPress(e, handleModalDismiss)}
                                    tabIndex={0}
                                />
                            </div>
                            <div className="share-request-modal-body">
                                <>
                                    <div className="dp-header">
                                        <Text className="dp-head-title">{props.selectedRequest.unifiedTrackingId}</Text>
                                        <div className="dp-head-desc">
                                            <Text className="title-field">{props.selectedRequest.summary}</Text>
                                        </div>
                                    </div>
                                    <div className="scroll-panel">
                                        <div className="dp-content">
                                            <div className="text-field-wrap">
                                                <label>Share With</label>
                                                <PeoplePicker
                                                    isDomainSpecific={false}
                                                    selectedPeople={selectedPeople}
                                                    setSelectedPeople={setSelectedPeople}
                                                    isSingleSelect={false}
                                                />
                                            </div>

                                            {isError && (
                                                <div className="error-message m-t-10">
                                                    <Text>Please enter valid details to share the request.</Text>
                                                </div>
                                            )}
                                            <div className="form-btn-wrap">
                                                <DefaultButton
                                                    className="default"
                                                    onClick={handleModalDismiss}
                                                    data-testid="cancel-button">
                                                    Cancel
                                                </DefaultButton>
                                                <DefaultButton
                                                    type="submit"
                                                    className="primary m-l-20"
                                                    onClick={submitShareRequest}
                                                    data-testid="submit-button">
                                                    Submit
                                                </DefaultButton>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </>
                    )}
                    {!isFormView && !isLoading && isSuccessSharing && (
                        <>
                            <div className="share-request-success">
                                <div className="modal-head">
                                    <IconButton
                                        role="button"
                                        className="modal-close"
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={handleModalDismiss}
                                        onKeyPress={(e) => keyPress(e, handleModalDismiss)}
                                        tabIndex={0}
                                    />
                                </div>
                                <div className={`fc-response`}>
                                    <div className="fc-response-icon">
                                        <Image src={checkIcon}></Image>
                                    </div>
                                    <div className="fc-res-text m-t-30">
                                        <Text>The request is successfully shared.</Text>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {!isFormView && !isLoading && isErrorSharing && (
                        <>
                            <div className="share-request-failure">
                                <div className="modal-head">
                                    <IconButton
                                        role="button"
                                        className="modal-close"
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={handleModalDismiss}
                                        onKeyPress={(e) => keyPress(e, handleModalDismiss)}
                                        tabIndex={0}
                                    />
                                </div>
                                <div className={`fc-response`}>
                                    <div className="fc-response-icon">
                                        <i className="ms-Icon ms-Icon--WarningSolid" aria-hidden="true"></i>
                                    </div>
                                    <div className="fc-res-text m-t-30">
                                        <Text>Something went wrong, please try again.</Text>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export default ShareRequestModal;
