import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GetText, highlightTags } from 'app/components/helpers';
import './SearchResultSection.scss';
import useStore from 'app/store';
import { isUser } from 'app/utils/authUtilities';
import { IUserDetails } from 'app/models/common/post';
import { DirectionalHint, ITooltipProps, TooltipDelay, TooltipHost, Text, ITooltipHostStyles } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { StringConstants } from 'app/utils/constants';
export interface IAdminSearchResult {
    Document: {
        Title: string;
        UnifiedTrackingId?: string;
        Description: string;
        RequestTeam: string;
        Status: any;
        Summary: string;
        RequestTags?: any;
        HashTags: { HashTag: string }[];
        IsParent?: boolean;
        ParentRequestId: string;
    };
    Highlights: {
        Summary: any;
        Description: any;
    };
}

interface IProps {
    searchResult: IAdminSearchResult;
    teams: any;
    isTeamsLoading: boolean;
}

enum HelpRequestTicketStatus {
    'New' = 1,
    'Active' = 2,
    'Inprogress' = 3,
    'Resolved' = 4,
    'Closed' = 5
}

const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block', borderRadius: '13px' }
};

export const SearchResultSection: React.FC<IProps> = ({ searchResult, teams, isTeamsLoading }) => {
    const tooltipId = useId('tooltip');
    const history = useHistory();
    const searchOrigin = useStore((state: any) => state.searchOrigin);
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);
    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);
    const getPageLink = () => {
        if (
            searchOrigin?.includes('manage-requests') ||
            (!isUser(userDetails?.userRoles) && searchOrigin === '/') ||
            searchOrigin?.includes('report') ||
            searchOrigin?.includes('visual-flow')
        )
            return '/request-detail/';
        else return '/request/';
    };

    const tagTooltipProps: ITooltipProps = React.useMemo(
        () => ({
            onRenderContent: () => (
                <ul className="tags-list">
                    {searchResult?.Document &&
                        searchResult?.Document?.RequestTags &&
                        searchResult?.Document?.RequestTags?.slice(2).map((item: any) => (
                            <li key={item?.Id} onClick={() => searchTag(item?.Name)}>
                                <span className="tags-txt">{item?.Name}</span>
                            </li>
                        ))}
                </ul>
            ),

            calloutProps: {
                styles: {
                    beak: { padding: '0' },
                    beakCurtain: { borderRadius: '13px', padding: '0' },
                    calloutMain: { borderRadius: '10px' }
                }
            }
        }),
        [searchResult?.Document]
    );

    const hashTagTooltipProps: ITooltipProps = React.useMemo(
        () => ({
            onRenderContent: () => (
                <ul className="tags-list">
                    {searchResult?.Document &&
                        searchResult?.Document?.HashTags &&
                        searchResult?.Document?.HashTags?.slice(3).map((item: any) => (
                            <li key={item?.Id} onClick={() => searchHashTag(item?.HashTag)}>
                                <span className="tags-txt"> {`#${item?.HashTag}`}</span>
                            </li>
                        ))}
                </ul>
            ),

            calloutProps: {
                styles: {
                    beak: { padding: '0' },
                    beakCurtain: { borderRadius: '13px', padding: '0' },
                    calloutMain: { borderRadius: '10px' }
                }
            }
        }),
        [searchResult?.Document]
    );

    const searchTag = (searchValue: string) => {
        setUserSearchValue(searchValue);
        history.push(`/search-results/requests/Tag`);
    };

    const searchHashTag = (searchValue: string) => {
        setUserSearchValue(searchValue);
        history.push(`/search-results/requests/HashTag`);
    };

    const renderAssociationTag = () => {
        let label = '';
        let tagClass = '';
        if (searchResult?.Document?.IsParent) {
            label = 'Parent';
            tagClass = 'result-association Parent';
        }

        if (searchResult?.Document?.ParentRequestId !== "" || searchResult?.Document?.ParentRequestId !== null) {
            label = 'Child';
            tagClass = 'result-association Child';
        }

        return <div className={`${tagClass}`}>{label}</div>;
    };

    return (
        <div className="search-result-container">
            <Link
                role="link"
                to={`${isUser(userDetails?.userRoles) ? '/request/' : getPageLink()}${searchResult?.Document?.UnifiedTrackingId}`}
                className="result-title">
                {searchResult?.Document?.UnifiedTrackingId} -{' '}
                {searchResult.Highlights && <span dangerouslySetInnerHTML={{ __html: searchResult?.Highlights?.Summary }}></span>}
                {!searchResult.Highlights && <span dangerouslySetInnerHTML={{ __html: searchResult?.Document?.Title }}></span>}
            </Link>
            <div className="custom-row result-section">
                <div className={`result-status  ${HelpRequestTicketStatus[searchResult?.Document?.Status]}`}>
                    {HelpRequestTicketStatus[searchResult?.Document?.Status]}
                </div>
                {renderAssociationTag()}
                {!isTeamsLoading && teams.length > 0 && searchResult?.Document?.RequestTeam && (
                    <div className="result-tag">{GetText(teams, parseInt(searchResult?.Document?.RequestTeam))}</div>
                )}
                {searchResult?.Document?.RequestTags?.length > 0 &&
                    !isUser(userDetails?.userRoles) &&
                    (searchOrigin.includes('manage-requests') ||
                        (!isUser(userDetails?.userRoles) && searchOrigin === '/') ||
                        searchOrigin.includes('report') ||
                        searchOrigin.includes('request-detail') ||
                        searchOrigin.includes('visual-flow')) && (
                        <div className="tags-container">
                            {searchResult?.Document?.RequestTags?.slice(0, 2).map((tag: any) => {
                                return (
                                    <div className="tag-display" key={tag?.Id} onClick={() => searchTag(tag?.Name)}>
                                        {tag?.Name}
                                    </div>
                                );
                            })}
                            {searchResult?.Document?.RequestTags?.length > 2 && (
                                <div className="d-flex-align-center">
                                    <TooltipHost
                                        tooltipProps={tagTooltipProps}
                                        delay={TooltipDelay.zero}
                                        id={tooltipId}
                                        directionalHint={DirectionalHint.bottomCenter}
                                        styles={hostStyles}
                                        className="hostStyles-new">
                                        <Text className="tags-count">+{searchResult?.Document?.RequestTags?.length - 2}</Text>
                                    </TooltipHost>
                                </div>
                            )}
                            {searchResult?.Document?.HashTags?.slice(0, 3).map((tag: any) => {
                                return (
                                    <div className="tag-display" key={tag?.Id} onClick={() => searchHashTag(tag?.HashTag)}>
                                        {`#${tag?.HashTag}`}
                                    </div>
                                );
                            })}
                            {searchResult?.Document?.HashTags?.length > 3 && (
                                <div className="d-flex-align-center">
                                    <TooltipHost
                                        tooltipProps={hashTagTooltipProps}
                                        delay={TooltipDelay.zero}
                                        id={tooltipId}
                                        directionalHint={DirectionalHint.bottomCenter}
                                        styles={hostStyles}
                                        className="hostStyles-new">
                                        <Text className="tags-count">+{searchResult?.Document?.HashTags?.length - 2}</Text>
                                    </TooltipHost>
                                </div>
                            )}
                        </div>
                    )}
            </div>
            {searchResult.Highlights && (
                <div
                    className="result-description"
                    dangerouslySetInnerHTML={{ __html: highlightTags(searchResult.Highlights?.Description?.join()) }}></div>
            )}
            {!searchResult.Highlights && (
                <div
                    className="result-description"
                    dangerouslySetInnerHTML={{ __html: highlightTags(searchResult.Document?.Description) }}></div>
            )}
        </div>
    );
};
