import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { IDropdownOption, Dropdown, Text, Breadcrumb, IBreadcrumbItem, Modal, Icon, IDropdownStyles } from '@fluentui/react';
import './ArticleView.scss';
import SearchNews from 'app/modules/articles/viewArticle/searchnews/SearchNews';
import { IArticleData, IArticleTemplateData, IMenuOption, IUserDetails } from 'app/models/common/post';
import { useGetArticles, useGetArticleTemplateById, useGetArticleTemplates } from 'app/services/queries';
import { ArticleStatus } from 'app/enums/Status.enum';
import NewsCard from 'app/modules/articles/viewArticle/newsCard/NewsCard';
import { useDeleteArticle, useDeleteArticleTemplate, useRemoveArticle } from 'app/services/mutations';
import MoreOptions from 'app/components/moreOptions/MoreOptions';
import Button from 'app/components/button/Button';
import { StringConstants } from 'app/utils/constants';
import { Trans, useTranslation } from 'react-i18next';
import { isContentCreator, isContentManager, isSuperAdmin } from 'app/utils/authUtilities';
import useStore from 'app/store';
import { ArticleType } from 'app/enums/ArticleType.enum';
import { getTitleForArticleStatus, keyPress, getFilterTags, getQueryParam, setQueryParam } from 'app/components/helpers';
import Pagination from 'app/components/pagination/Pagination';
import { Loader } from 'app/components/loader/Loader';
import { SVGIcons } from 'app/utils/SVGIcons';

const { DRAFT, PUBLISHED, REMOVED, REVIEW_REQUESTS, SCHEDULED_TO_PUBLISH } = StringConstants;

const ArticleView: React.FC = () => {
    const { t: translate } = useTranslation();
    const { pathname } = useLocation();
    const history = useHistory();
    const isKC = pathname.split('/')[1] === 'all-articles';
    const isAllNews = pathname.split('/')[1] === 'all-news';
    const options: IDropdownOption[] = [
        { key: '1', text: translate('Latest'), data: { icon: 'List' } },
        { key: '0', text: translate('Earliest'), data: { icon: 'Previous' } }
    ];

    const articleCategories = useStore((state) => state.articleCategories) ?? [];
    const filterOptions: IDropdownOption[] = [
        {
            key: '',
            data: { level: 1 },
            text: isKC ? 'Knowledge articles' : 'News'
        },
        ...getFilterTags(articleCategories).map((category) => ({
            key: category?.name,
            data: category,
            text: `${category?.name}`
        }))
    ];

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdownItem: { height: 'auto' },
        dropdown: { width: '250px' }
    };

    const [sortBy, setSortBy] = useState<IDropdownOption>(options[0]);
    const [filterBy, setFilterBy] = useState<IDropdownOption>(filterOptions[0]);
    const { mutateAsync: deleteArticleAPI, isLoading: isDeleting } = useDeleteArticle();
    const { mutateAsync: removeArticleAPI, isLoading: isRemoving } = useRemoveArticle();
    const {
        mutateAsync: deleteArticleTemplate,
        isLoading: isDeletingTemplate,
        isSuccess: isDeleted
    } = useDeleteArticleTemplate();
    const [viewSelectedTemplate, setViewSelectedTemplate] = React.useState<string>('');
    const {
        data: templatesData,
        refetch: getTemplates,
        isLoading: isTemplatesLoading,
        isFetched: isTemplatesFetched
    } = useGetArticleTemplates();

    const { data: templateDataById, refetch: getArticleTemplateById } = useGetArticleTemplateById(viewSelectedTemplate);
    const userDetails: IUserDetails = useStore((state) => state.userDetails as IUserDetails);
    const isSAdmin = isSuperAdmin(userDetails?.userRoles) || isContentManager(userDetails?.userRoles);
    const [showModal, setModalStatus] = useState(false);

    const [pageIndex, setPageIndex] = useState(Number(getQueryParam(history).page ?? '1'));
    const resultsPerPage = 21;

    const [status, setStatus] = useState(
        isKC || isAllNews ? ArticleStatus.Published : Number(getQueryParam(history).articleStatus ?? ArticleStatus.Draft)
    );

    type Selection = 'my' | 'other';
    const [currentSelection, setCurrentSelection] = useState<Selection>('my');
    const [myTemplates, setMyTemplates] = useState<Array<IArticleTemplateData>>([]);
    const [isRootTag, setIsRootTag] = useState<boolean>(null);
    const [otherTemplates, setOtherTemplates] = useState<Array<IArticleTemplateData>>([]);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const {
        isFetched,
        data: articles,
        refetch: getArticles,
        isFetching: isGettingArticles
    } = useGetArticles(
        Number(getQueryParam(history).page),
        resultsPerPage,
        'ModifiedOn',
        getQueryParam(history).sort as string,
        isKC || isAllNews ? null : isContentCreator(userDetails?.userRoles) ? userDetails?.id : null,
        isKC || isAllNews ? ArticleStatus.Published : Number(getQueryParam(history).articleStatus),
        isKC ? ArticleType.KnowledgeArticle : isAllNews ? ArticleType.News : null,
        isRootTag,
        isKC || isAllNews
            ? (getQueryParam(history).filterBy as string) === StringConstants.ALL
                ? null
                : (getQueryParam(history).filterBy as string)
            : null,
        false
    );

    const handleSectionLink = (value: string) => {
        if (value === DRAFT) {
            setQueryParam(history, pathname, { articleStatus: ArticleStatus.Draft.toString(), page: '1' }, false);
        } else if (value === SCHEDULED_TO_PUBLISH) {
            setQueryParam(history, pathname, { articleStatus: ArticleStatus.ScheduledToPublish.toString(), page: '1' }, false);
        } else if (value === PUBLISHED) {
            setQueryParam(history, pathname, { articleStatus: ArticleStatus.Published.toString(), page: '1' }, false);
        } else if (value === REMOVED) {
            setQueryParam(history, pathname, { articleStatus: ArticleStatus.Removed.toString(), page: '1' }, false);
        } else {
            setQueryParam(history, pathname, { articleStatus: ArticleStatus.Submitted.toString(), page: '1' }, false);
        }
    };

    useEffect(() => {
        setQueryParam(
            history,
            pathname,
            {
                ...{
                    page: getQueryParam(history).page ?? 1,
                    sort: getQueryParam(history).sort ?? 1
                },
                ...(isKC || isAllNews ? { filterBy: getQueryParam(history).filterBy ?? StringConstants.ALL } : null),
                ...(!(isKC || isAllNews) ? { articleStatus: status ?? 1 } : null)
            },
            true
        );
    }, []);

    useEffect(() => {
        const { page, articleStatus, filterBy: filter, sort } = getQueryParam(history);

        setPageIndex(Number(page));
        setStatus(Number(articleStatus));
        setFilterBy(filterOptions.find((f: IDropdownOption) => f.key.toString() === (filter as string)) ?? filterOptions[0]);
        setIsRootTag(filterBy.data?.isLeaf);
        setSortBy(options.find((option: IDropdownOption) => option.key.toString() === (sort as string)));
        getArticles();
    }, [history?.location?.search, isDeleting, isRemoving]);

    useEffect(() => {
        if (viewSelectedTemplate) getArticleTemplateById();
    }, [viewSelectedTemplate, getArticleTemplateById]);

    const allNewsBreadcrumb: IBreadcrumbItem[] = [
        { text: 'News Feed', key: 'news feed', onClick: () => history.push('/news-feed/home') },
        {
            text: 'All News Feed',
            key: 'All News Feed',
            isCurrentItem: true
        }
    ];
    const allArticlesBreadcrumb: IBreadcrumbItem[] = [
        { text: 'Knowledge Articles', key: 'knowledge articles', onClick: () => history.push('/knowledge-articles/home') },
        {
            text: 'All Knowledge Articles',
            key: 'all knowledge articles',
            isCurrentItem: true
        }
    ];
    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setQueryParam(history, pathname, { sort: item.key.toString(), page: '1' }, false);
    };
    const onFilterChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setIsRootTag(!item.data?.isLeaf);
        setQueryParam(
            history,
            pathname,
            {
                filterBy: item?.key ? item?.key.toString() : StringConstants.ALL,
                page: '1'
            },
            false
        );
    };

    const newPost = () => {
        history.push('/manage-content/article/create');
    };
    let newPostDropdownOption1: IMenuOption = {
        title: translate('Create New'),
        handleAction: newPost,
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--PageAdd'
    };
    let newPostDropdownOption2: IMenuOption = {
        title: translate('From Template'),
        handleAction: (a?: any) => {
            setModalStatus(true);
            getTemplates();
        },
        id: '',
        icon: '',
        iconClass: 'ms-Icon ms-Icon--PageList'
    };

    const newPostDropdown = [newPostDropdownOption1, newPostDropdownOption2];

    const hideModal = () => {
        setModalStatus(false);
    };

    const loadTemplate = (template: IArticleTemplateData) => {
        history.push({ pathname: `/manage-content/article/create`, state: { template } });
    };
    const viewTemplate = async (id: string) => {
        setViewSelectedTemplate(id);
    };
    const onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <>
                {option.data && option.data.icon && (
                    <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span className={option?.data?.level === 1 ? 'fw--600' : option?.data?.level === 2 ? 'p-l-15' : 'p-l-25'}>
                    <span className={option?.data?.isLeaf ? '' : 'fw--600'}>{option?.text}</span>
                </span>
            </>
        );
    };
    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        const option = options[0];

        return (
            <>
                {option.data && option.data.icon && (
                    <Icon className={`icon`} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                )}
                <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '90%', overflow: 'hidden' }}>
                    {option.text}
                </span>
            </>
        );
    };
    const deleteConfirmationModal = () => {
        setShowAlertModal(true);
    };
    const deleteTemplate = () => {
        if (!isDeletingTemplate) {
            setShowAlertModal(false);
            deleteArticleTemplate(viewSelectedTemplate);
        }
    };
    const hideAlertModal = () => {
        setShowAlertModal(false);
    };

    useEffect(() => {
        if (!isTemplatesLoading && isTemplatesFetched) {
            setMyTemplates(
                templatesData?.filter(
                    (article: IArticleTemplateData) =>
                        article?.displayName && article?.createdBy?.id === userDetails?.id && article?.displayName?.trim() !== ''
                )
            );
            setOtherTemplates(
                templatesData?.filter(
                    (article: IArticleTemplateData) =>
                        article?.displayName && article?.createdBy?.id !== userDetails?.id && article?.displayName?.trim() !== ''
                )
            );
        }
    }, [templatesData, isTemplatesLoading, isTemplatesFetched]);

    useEffect(() => {
        if (templatesData && !isTemplatesLoading && isTemplatesFetched)
            if (myTemplates?.length > 0) {
                setCurrentSelection('my');
                setViewSelectedTemplate(myTemplates[0]?.id);
            } else {
                setCurrentSelection('other');
                setViewSelectedTemplate(otherTemplates[0]?.id);
            }
    }, [myTemplates, otherTemplates]);

    useEffect(() => {
        if (currentSelection === 'my') {
            setViewSelectedTemplate(myTemplates[0]?.id);
        } else {
            setViewSelectedTemplate(otherTemplates[0]?.id);
        }
    }, [currentSelection]);

    useEffect(() => {
        if (isDeleted) getTemplates();
    }, [isDeleted]);

    return (
        <>
            {!(isKC || isAllNews) && (
                <div className="sub-header sub-header--sc">
                    <div className="container">
                        <div className="d-flex--between ai-center">
                            <div className="">
                                <div className="draft-published-section">
                                    <div
                                        className={`draft ${status === ArticleStatus.Draft && 'active-tab'}`}
                                        onClick={() => handleSectionLink(DRAFT)}>
                                        <span
                                            className="ms-Icon"
                                            dangerouslySetInnerHTML={{
                                                __html: SVGIcons.ICON_DRAFT
                                            }}></span>
                                        <Text
                                            tabIndex={0}
                                            className={`draft-tab`}
                                            onKeyPress={(e) => keyPress(e, () => handleSectionLink(DRAFT))}>
                                            <Trans>{getTitleForArticleStatus(ArticleStatus.Draft, isSAdmin)}</Trans>
                                        </Text>
                                    </div>

                                    <div
                                        className={`draft  review-requests ai-baseline ${
                                            status === ArticleStatus.Submitted && 'active-tab'
                                        }`}
                                        onClick={() => handleSectionLink(REVIEW_REQUESTS)}>
                                        <span
                                            className="ms-Icon"
                                            dangerouslySetInnerHTML={{
                                                __html: SVGIcons.ICON_REVIEW_REQUEST
                                            }}></span>
                                        <Text
                                            tabIndex={0}
                                            onKeyPress={(e) => keyPress(e, () => handleSectionLink(REVIEW_REQUESTS))}
                                            className={`draft-tab`}
                                            onClick={() => handleSectionLink(REVIEW_REQUESTS)}>
                                            <Trans>{getTitleForArticleStatus(ArticleStatus.Submitted, isSAdmin)}</Trans>
                                        </Text>
                                    </div>
                                    <div
                                        className={`draft  removed ${status === ArticleStatus.Removed && 'active-tab'}`}
                                        onClick={() => handleSectionLink(REMOVED)}>
                                        <span
                                            className="ms-Icon"
                                            dangerouslySetInnerHTML={{
                                                __html: SVGIcons.ICON_REMOVE
                                            }}></span>
                                        <Text
                                            tabIndex={0}
                                            className={`draft-tab`}
                                            onKeyPress={(e) => keyPress(e, () => handleSectionLink(REMOVED))}>
                                            <Trans>{getTitleForArticleStatus(ArticleStatus.Removed)}</Trans>
                                        </Text>
                                    </div>
                                    <div
                                        className={`draft  published ${
                                            status === ArticleStatus.ScheduledToPublish && 'active-tab'
                                        }`}
                                        onClick={() => handleSectionLink(SCHEDULED_TO_PUBLISH)}>
                                        <span
                                            className="ms-Icon"
                                            dangerouslySetInnerHTML={{
                                                __html: SVGIcons.ICON_SCHEDULED_TO_PUBLISH
                                            }}></span>
                                        <Text
                                            tabIndex={0}
                                            className={`draft-tab`}
                                            onKeyPress={(e) => keyPress(e, () => handleSectionLink(SCHEDULED_TO_PUBLISH))}>
                                            <Trans>{getTitleForArticleStatus(ArticleStatus.ScheduledToPublish)}</Trans>
                                        </Text>
                                    </div>
                                    <div
                                        className={`draft  published ${status === ArticleStatus.Published && 'active-tab'}`}
                                        onClick={() => handleSectionLink(PUBLISHED)}>
                                        <span
                                            className="ms-Icon"
                                            dangerouslySetInnerHTML={{
                                                __html: SVGIcons.ICON_PUBLISH
                                            }}></span>
                                        <Text
                                            tabIndex={0}
                                            className={`draft-tab`}
                                            onKeyPress={(e) => keyPress(e, () => handleSectionLink(PUBLISHED))}>
                                            <Trans>{getTitleForArticleStatus(ArticleStatus.Published)}</Trans>
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <MoreOptions
                                    class="left bottom new-post"
                                    menuArray={newPostDropdown}
                                    placeholderText="New Post"
                                    fuIconName="CirclePlus"
                                />
                                <Modal
                                    isOpen={showModal}
                                    onDismiss={hideModal}
                                    isBlocking={false}
                                    className="surface-modal large template-preview-modal">
                                    <div className="modal-header">
                                        <span>
                                            <Trans>Select Template</Trans>
                                        </span>
                                        <span
                                            className="close"
                                            tabIndex={0}
                                            onClick={hideModal}
                                            onKeyPress={(e) => keyPress(e, hideModal)}>
                                            X
                                        </span>
                                    </div>
                                    <div className="modal-body">
                                        <div className="panel-separator internal-nav m-b-15">
                                            <nav className="navigation_link" aria-label="header-nav-link">
                                                <ul>
                                                    <li
                                                        className={currentSelection === 'my' ? 'active' : ''}
                                                        onClick={() => {
                                                            setCurrentSelection('my');
                                                        }}>
                                                        <Trans>My templates</Trans>
                                                    </li>
                                                    <li
                                                        className={currentSelection === 'other' ? 'active' : ''}
                                                        onClick={() => {
                                                            setCurrentSelection('other');
                                                        }}>
                                                        <Trans>Other templates</Trans>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        {!isTemplatesLoading && isTemplatesFetched ? (
                                            <div className="template-Preview">
                                                {(currentSelection === 'my' ? myTemplates : otherTemplates)?.length > 0 && (
                                                    <div className="templates">
                                                        <ul>
                                                            {(currentSelection === 'my' ? myTemplates : otherTemplates).map(
                                                                (template: IArticleTemplateData) => {
                                                                    return (
                                                                        <li
                                                                            key={template.id}
                                                                            tabIndex={0}
                                                                            onClick={() => viewTemplate(template.id)}
                                                                            onKeyPress={(e) =>
                                                                                keyPress(e, () => viewTemplate(template.id))
                                                                            }
                                                                            className={
                                                                                template.id === viewSelectedTemplate
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }>
                                                                            {template.displayName}
                                                                            {currentSelection === 'my' && (
                                                                                <span
                                                                                    title="Delete Template"
                                                                                    onClick={deleteConfirmationModal}
                                                                                    className="ms-Icon"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: SVGIcons.ICON_TRASH
                                                                                    }}></span>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                                <div className="preview">
                                                    {templateDataById && viewSelectedTemplate ? (
                                                        <div
                                                            className="content"
                                                            dangerouslySetInnerHTML={{
                                                                __html: templateDataById?.content
                                                            }}></div>
                                                    ) : (currentSelection === 'my' ? myTemplates : otherTemplates)?.length > 0 ? (
                                                        <Loader classNames="height-60" />
                                                    ) : (
                                                        <span className="ai-center d-flex--center height-60 message">
                                                            No data found
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <Loader classNames="height-60" />
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <Button
                                            buttonText="Use this template"
                                            buttonClass="btn btn--primary m-r-15"
                                            onClick={() => loadTemplate(templateDataById)}
                                            disabled={templateDataById ? false : true}
                                        />
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="article-listview-container pos-relative m-t-1">
                <div className="container">
                    {(isKC || isAllNews) && (
                        <Breadcrumb
                            items={isKC ? allArticlesBreadcrumb : allNewsBreadcrumb}
                            maxDisplayedItems={4}
                            ariaLabel="bread crumb"
                            overflowAriaLabel="More links"
                        />
                    )}
                    <div className={isKC || isAllNews ? 'strip p-l-20 p-r-20 border-radius--8 m-t-10' : 'p-t-20'}>
                        <div className="d-flex--between ai-center">
                            <div className="">
                                {isKC && (
                                    <div className="strip-txt">
                                        <span className="bld">
                                            <Link to={'/knowledge-articles/home'} aria-label="back button" className="back-arrow">
                                                <span
                                                    className="ms-Icon"
                                                    aria-label="back"
                                                    role="navigation"
                                                    dangerouslySetInnerHTML={{
                                                        __html: SVGIcons.ICON_BACK
                                                    }}></span>
                                            </Link>
                                            <Trans>All Knowledge Articles</Trans>
                                        </span>
                                    </div>
                                )}
                                {isAllNews && (
                                    <div className="strip-txt">
                                        <span className="bld">
                                            <Link to={'/news-feed/home'} aria-label="back button" className="back-arrow">
                                                <span
                                                    className="ms-Icon"
                                                    dangerouslySetInnerHTML={{
                                                        __html: SVGIcons.ICON_BACK
                                                    }}></span>
                                            </Link>
                                            <Trans>All News Feed</Trans>
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div>
                                <div className="d-flex--justEnd header-tab">
                                    {(isKC || isAllNews) && (
                                        <div className="right-content">
                                            <div className="sort-dropdown">
                                                <span className="sort-by whitespace-nowrap">
                                                    <Trans aria-labelledby="category-drop">Filter by</Trans>
                                                </span>
                                                <Dropdown
                                                    aria-label="article category filter"
                                                    styles={dropdownStyles}
                                                    onRenderTitle={onRenderTitle}
                                                    onRenderOption={onRenderOption}
                                                    placeholder=""
                                                    options={filterOptions}
                                                    onChange={onFilterChange}
                                                    selectedKey={filterBy ? filterBy.key : undefined}
                                                    id="category-drop"
                                                    title="filter dropdown"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="right-content">
                                        <div className="sort-dropdown">
                                            <span className="sort-by whitespace-nowrap">
                                                <Trans>Sort by</Trans>
                                            </span>
                                            <Dropdown
                                                aria-label="Sort by"
                                                onRenderTitle={onRenderTitle}
                                                onRenderOption={onRenderOption}
                                                placeholder="Last Viewed"
                                                options={options}
                                                onChange={onChange}
                                                selectedKey={sortBy ? sortBy.key : undefined}
                                                className="selectType"
                                                id="sort-drop"
                                                title="sort dropdown"
                                            />
                                        </div>
                                        <div className="search11">
                                            <SearchNews
                                                placeholder={isAllNews ? 'Search news' : 'Search articles'}
                                                type={isAllNews ? 'news' : isKC ? 'articles' : null}
                                                iconName="Search"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid container" dir="ltr">
                    <div className={`ms-Grid-row ${isAllNews || isKC ? 'm-t-40' : 'm-t-20'}`}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="card-grid">
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        {articles &&
                                            articles?.items?.length > 0 &&
                                            !isGettingArticles &&
                                            articles?.items.map((article: IArticleData) => (
                                                <div className="ms-Grid-col ms-sm12 ms-lg4 card-list" key={article.id}>
                                                    <NewsCard
                                                        data={article}
                                                        deleteArticleAPI={(id: string) => deleteArticleAPI(id)}
                                                        removeArticleAPI={(id: string) => removeArticleAPI(id)}
                                                        getArticlesAPI={getArticles}
                                                        showMoreOptions={isKC || isAllNews ? false : true}
                                                        showStatus={isKC || isAllNews ? false : true}
                                                        showArticleType={isKC || isAllNews ? false : true}
                                                        showLastEdited={isKC || isAllNews ? false : true}
                                                    />
                                                </div>
                                            ))}
                                        {articles?.items?.length === 0 && isFetched && !isGettingArticles && (
                                            <div className="d-flex jc-center height-70 ai-center message">
                                                {isKC || isAllNews ? (
                                                    <Trans>
                                                        {StringConstants.ARTICLE_NOT_FOUND_MESSAGE}
                                                        &nbsp;
                                                        <Link to="/submit-feedback">here</Link>.
                                                    </Trans>
                                                ) : (
                                                    <Trans>{StringConstants.ARTICLE_NOT_FOUND_CREATE_MORE_MESSAGE}</Trans>
                                                )}
                                            </div>
                                        )}
                                        {isGettingArticles && <Loader classNames="height-70" />}
                                    </div>
                                    {articles && articles?.items?.length > 0 && (
                                        <div className="ms-Grid-row text-center">
                                            <div className="pagination-container">
                                                <Pagination
                                                    pageCount={articles?.totalPages}
                                                    setPageNumber={setPageIndex}
                                                    initialPage={pageIndex - 1}
                                                    totalCount={articles?.totalCount}
                                                    resultsPerPage={resultsPerPage}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={showAlertModal} onDismiss={hideAlertModal} isBlocking={false} className={`surface-modal primary`}>
                <div className={`modal-body p-b-20 d-flex--center ai-center p-t-20`}>
                    <div className="delete-confirmation">
                        <i className="ms-Icon ms-Icon--WarningSolid jc-center d-flex m-t-40 fs--40" aria-hidden="true"></i>
                        <Text className="jc-center d-flex fs--18 m-t-25">Are you sure you want to delete the template?</Text>
                    </div>
                </div>
                <div className="modal-footer d-flex ai-center">
                    <Button
                        buttonText="Continue"
                        buttonClass="btn btn--secondary"
                        onClick={() => deleteTemplate()}
                        disabled={isDeletingTemplate}
                    />
                    <Button buttonText="Cancel" buttonClass="btn btn--primary" onClick={() => hideAlertModal()} />
                </div>
            </Modal>
        </>
    );
};

export default ArticleView;
