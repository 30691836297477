import React, { useEffect, useState } from 'react';
import './VisualFlowHeader.scss';
import { Icon } from '@fluentui/react';
import { Text, Link } from '@fluentui/react';

import { useHistory } from 'react-router';
import { ActivityStatus } from './VisualFlow';

import AddAttachmentModal from 'app/components/addAttachmentModal/AddAttachmentModal';
import { IAttachmentUpdateData } from 'app/models/common/request';
import { useGetRequestById, useRequestTypesLookUpData, useTeamLookUpData } from 'app/services/queries';
import { GetText, keyPress } from 'app/components/helpers';
import { Trans } from 'react-i18next';
import { useDownloadVisualFlow } from 'app/services/mutations';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import { RouteConstants} from 'app/utils/constants';

function VisualFlowHeader({
    visualFlowData,
    setFitIntoTheScreen,
    setMaximizeView,
    fullScreenMode,
    showOrHideAllDetails,

    isListViewClicked,
    showOrHideListView   
}: any) {
    // state variables
    const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] = useState(false);
    const [unifiedTrackingId, setUnifiedTrackingId] = useState('');

    const history = useHistory();
    let { id } = useParams<any>();

    // queries
    const {
        data: requestData,
        isLoading: isLoadingRequestData,
        refetch: refetchRequestData
    } = useGetRequestById(unifiedTrackingId);
    const { data: requestTypes, isLoading: isRequestTypesLoading } = useRequestTypesLookUpData();
    const { data: teams, isLoading: isTeamsLoading } = useTeamLookUpData();
    const {
        mutate: downloadVisualFlowMutation,
        data: downloadVisualFlowData,
        isLoading: isLoadingDownloadVisualFlow,
        isSuccess: isSuccessDownloadVisualFlow
    } = useDownloadVisualFlow();

    const getStat = () => {
        if (visualFlowData && visualFlowData.activities && visualFlowData.activities.length > 0)
            return ActivityStatus[visualFlowData.activities[visualFlowData.activities.length - 1].status];
    };

    const getStatusByValue = (value: number) => {
        if (value) return ActivityStatus[value];
    };

    const getModalData = (data: any) => {
        let attachmentData: IAttachmentUpdateData = {};
        if (data) {
            attachmentData.applicationId = data.backendId === null || data.backendId === undefined ? '--' : data.backendId;
            attachmentData.attachments = data.attachments;
            attachmentData.requestId = data.unifiedTrackingId;
            attachmentData.requestType = GetText(requestTypes, data.requestType);
            attachmentData.teamName = GetText(teams, data.requestTeam);
            attachmentData.summary = data.summary;
            attachmentData.teamValue = data.requestTeam;
            attachmentData.requestTypeValue = data.requestType;
        }
        return attachmentData;
    };

    const handleAddAttachment = () => {
        setIsAddAttachmentModalOpen(true);
    };

    const handleAttachmentCompleted = () => {
        refetchRequestData();
    };

    const handleVisualFlowDownload = () => {
        downloadVisualFlowMutation({ correlationId: unifiedTrackingId });
    };

    useEffect(() => {
        if (visualFlowData && visualFlowData.activities ? visualFlowData.activities[0].unifiedTrackingId : '') {
            setUnifiedTrackingId(visualFlowData.activities[0].unifiedTrackingId);
        } else {
            setUnifiedTrackingId(id);
        }
    }, [visualFlowData, id]);

    useEffect(() => {
        if (!isLoadingDownloadVisualFlow && isSuccessDownloadVisualFlow) {
            let blobData = new Blob([downloadVisualFlowData.data], { type: 'text/csv;charset=utf-8' });
            saveAs(blobData, `VisualFlow_${unifiedTrackingId}_${new Date().getTime()}.csv`);
        }
    }, [isLoadingDownloadVisualFlow, isSuccessDownloadVisualFlow]);

    return (
        <div className="visual-flow__header container">
            {visualFlowData && visualFlowData.activities ? (
                <>
                    <div className="icon-column">
                        <Icon
                            iconName="ArrowUpRight8"
                            className="leftArrow"
                            onKeyPress={(e: any) => keyPress(e, () => history?.goBack())}
                            onClick={() => history?.goBack()}
                            data-testid="img-btn"
                            tabIndex={0}
                        />
                    </div>
                    <div className="ticket-details">
                        <div className="ticket-no">
                            <Text className="req-id ">
                                <Trans>Request ID</Trans> - {visualFlowData.activities[0].unifiedTrackingId}
                            </Text>
                            <span
                                className={`badge--status resolved bg-${
                                    ActivityStatus[visualFlowData.activities[visualFlowData.activities.length - 1].status]
                                }`}>
                                {getStat()}
                            </span>
                        </div>
                        <h2 title={requestData?.data?.summary}>{requestData?.data?.summary}</h2>
                        <div className="links">
                            <Link role="link" className="attachment-count" tabIndex={0} onClick={handleAddAttachment}>
                                <Trans>Attachments</Trans> ({!isLoadingRequestData && requestData?.data.attachments?.length})
                            </Link>
                            <div className="vertical-line"></div>
                            <Link role="link" className="download" onClick={handleVisualFlowDownload}>
                                <Icon iconName="Download" className="download-icon" />
                                <Trans>Download</Trans>
                            </Link>
                        </div>
                    </div>
                    {!isListViewClicked ? (
                        <div className="buttons">
                            {!fullScreenMode ? (
                                <>
                                    <button className="full-screen" onClick={setFitIntoTheScreen}>
                                        <span className="icon">
                                            <Icon iconName="ArrowUpRightMirrored8" />
                                            <Icon iconName="ArrowUpRight8" />
                                            <Icon iconName="ArrowDownRight8" />
                                            <Icon iconName="ArrowDownRightMirrored8" />
                                        </span>
                                        <span className="text">
                                            <Trans>Fit Into The Screen</Trans>
                                        </span>
                                    </button>
                                    <div
                                        className="banner-btn m-l-20 list-view-btn"
                                        tabIndex={0}
                                        onKeyPress={(e: any) => keyPress(e, showOrHideListView)}
                                        onClick={showOrHideListView}>
                                        <span className="view-icon"></span>
                                        <Text>
                                            <Trans>List View</Trans>
                                        </Text>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <button className="maximize-view" onClick={setMaximizeView}>
                                        <span className="icon">
                                            <Icon iconName="ArrowUpRightMirrored8" />
                                            <Icon iconName="ArrowUpRight8" />
                                            <Icon iconName="ArrowDownRight8" />
                                            <Icon iconName="ArrowDownRightMirrored8" />
                                        </span>
                                        <span className="text">
                                            <Trans>Maximize View</Trans>
                                        </span>
                                    </button>
                                    <div
                                        className="banner-btn m-l-20 list-view-btn"
                                        tabIndex={0}
                                        onKeyPress={(e: any) => keyPress(e, showOrHideAllDetails)}
                                        onClick={showOrHideAllDetails}>
                                        <span className="view-icon"></span>
                                        <Text>
                                            <Trans>List View</Trans>
                                        </Text>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className="banner-btn m-l-20 list-view-btn" tabIndex={0} onClick={showOrHideAllDetails}>
                                <span className="timeline-icon"></span>
                                <Text>
                                    <Trans>TimeLine View</Trans>
                                </Text>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    {!isLoadingRequestData && requestData && (
                        <>
                            <div className="icon-column">
                                <Icon
                                    iconName="ArrowUpRight8"
                                    className="leftArrow"
                                    onKeyPress={(e: any) => keyPress(e, () => history?.push(RouteConstants.MANAGE_REQUESTS))}
                                    onClick={() => history?.push(RouteConstants.MANAGE_REQUESTS)}
                                    data-testid="img-btn"
                                    tabIndex={0}
                                />
                            </div>
                            <div className="ticket-details">
                                <div className="ticket-no">
                                    <Text className="req-id ">
                                        <Trans>Request ID</Trans> - {requestData?.data?.unifiedTrackingId}
                                    </Text>
                                    <span className={`badge--status resolved bg-${ActivityStatus[requestData?.data?.status]}`}>
                                        {getStatusByValue(requestData?.data?.status)}
                                    </span>
                                </div>
                                <h2>{requestData?.data?.summary}</h2>
                            </div>
                        </>
                    )}
                </>
            )}

            {isAddAttachmentModalOpen && !isLoadingRequestData && !isRequestTypesLoading && !isTeamsLoading && (
                <AddAttachmentModal
                    modalState={isAddAttachmentModalOpen}
                    updateModalState={(value: boolean) => setIsAddAttachmentModalOpen(value)}
                    selectedRequest={getModalData(requestData?.data)}
                    entityType={1}
                    onCompletedHandler={handleAttachmentCompleted}
                />
            )}            
        </div>
    );
}

export default VisualFlowHeader;
