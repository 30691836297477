import React, { useEffect } from 'react';
import { Modal, IconButton, IIconProps, Text, DefaultButton, TextField, ComboBox, IComboBoxOption } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import './AddCustomerModal.scss';
import { keyPress } from 'app/components/helpers';

const titleId = 'add-customer-modal';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface IProps {
    modalState: boolean;
    updateModalState: any;
}

const options: IComboBoxOption[] = [
    { key: 'menu_1', text: 'United States' },
    { key: 'menu_2', text: 'India' }
];

const AddCustomerModal: React.FC<IProps> = (props) => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(props.modalState);

    useEffect(() => {
        const toggleModal = (modalState: boolean) => {
            if (modalState) {
                showModal();
            } else {
                hideModal();
            }
        };
        toggleModal(props.modalState);
    }, [props.modalState, showModal, hideModal]);

    const handleModalDismiss = () => {
        props.updateModalState(false);
    };
    return (
        <>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={handleModalDismiss}
                isBlocking={false}
                containerClassName="add-customer-modal">
                <div className="modal-head">
                    <Text>Add a customer</Text>
                    <IconButton
                        tabIndex={0}
                        role="button"
                        className="modal-close"
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={handleModalDismiss}
                        onKeyPress={(e: any) => keyPress(e, handleModalDismiss)}
                    />
                </div>
                <div className="add-customer-modal-body">
                    <div className="dp-header">
                        <div className="head-wrap">
                            <div className="text-field-wrap">
                                <label>Search by MSX Opportunity ID / TPID / Customer Name</label>
                                <TextField placeholder="Enter MSX Opp ID / TPID / Customer Name" />
                            </div>
                            <div className="text-field-wrap">
                                <label>Customer Country (Subsidiary)</label>
                                <ComboBox options={options} placeholder="For example -  United States" />
                            </div>
                            <div className="btn-field-wrap">
                                <DefaultButton type="submit" className="primary">
                                    Search
                                </DefaultButton>
                            </div>
                        </div>
                    </div>
                    <div className="dp-content">
                        <div className="dp-content-title">
                            <Text>Select from recently attached</Text>
                        </div>
                        <div className="table-wrap">
                            <table className="dp-table">
                                <thead>
                                    <tr>
                                        <td>Customer Name</td>
                                        <td>TPID</td>
                                        <td>Country</td>
                                        <td>MSX Opportunity ID</td>
                                        <td>Title</td>
                                        <td>Revenue</td>
                                        <td>Device</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ASNET Technologies</td>
                                        <td>10470985</td>
                                        <td>Singapore</td>
                                        <td>1-1VW5P9</td>
                                        <td>Surface Pro 7 purchased</td>
                                        <td>$ 2,000</td>
                                        <td>1067</td>
                                        <td>
                                            <DefaultButton type="submit" className="primary">
                                                Select
                                            </DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ASNET Technologies</td>
                                        <td>10470985</td>
                                        <td>Singapore</td>
                                        <td>1-1VW5P9</td>
                                        <td>Surface Pro 7 purchased</td>
                                        <td>$ 2,000</td>
                                        <td>1067</td>
                                        <td>
                                            <DefaultButton type="submit" className="primary">
                                                Select
                                            </DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ASNET Technologies</td>
                                        <td>10470985</td>
                                        <td>Singapore</td>
                                        <td>1-1VW5P9</td>
                                        <td>Breakdown of Surface Laptop 3 Firmware update</td>
                                        <td>$ 2,000</td>
                                        <td>1067</td>
                                        <td>
                                            <DefaultButton type="submit" className="primary">
                                                Select
                                            </DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ASNET Technologies</td>
                                        <td>10470985</td>
                                        <td>Singapore</td>
                                        <td>1-1VW5P9</td>
                                        <td>Surface Pro 7 purchased</td>
                                        <td>$ 2,000</td>
                                        <td>1067</td>
                                        <td>
                                            <DefaultButton type="submit" className="primary">
                                                Select
                                            </DefaultButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddCustomerModal;
