import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import HomeBanner from 'app/modules/user/homeBanner/HomeBanner';
import useStore from 'app/store';
import { useHistory } from 'react-router-dom';

const pageVariants = {
    initial: {
        opacity: 0,
        y: '100vw',
        scale: 0.8
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    },
    out: {
        opacity: 0,
        y: '-100vw',
        scale: 1.2
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 1
};

const Home: React.FC = () => {
    const setQuickLinksValue = useStore((state: any) => state.setQuickLinksValue);
    const setQuickLinksFlow = useStore((state: any) => state.setQuickLinksFLow);
    const setRedirectRequestType = useStore((state: any) => state.setRedirectRequestType);
    const setRedirectSubType = useStore((state: any) => state.setRedirectSubType);
    const setQuickLinksID = useStore((state: any) => state.setQuickLinksID);
    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);
    const setSearchOrigin = useStore((state: any) => state.setSearchOrigin);
    const history = useHistory();

    useEffect(() => {
        setQuickLinksID(null);
        setQuickLinksValue(null);
        setRedirectSubType(null);
        setQuickLinksFlow(null);
        setRedirectRequestType('');
        setSearchOrigin(history.location.pathname);
    }, []);

    useEffect(() => {
        setUserSearchValue('');
    }, [setUserSearchValue]);

    return (
        <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
            <HomeBanner />
        </motion.div>
    );
};

export default Home;
