import React, { useState } from 'react';
import './SummaryCard.scss';
import useStore from 'app/store';
import { Icon, Text, Link } from '@fluentui/react';
import { Trans } from 'react-i18next';
import { keyPress, setQueryParam } from 'app/components/helpers';
import { useHistory } from 'react-router-dom';
import { RouteConstants } from 'app/utils/constants';
import ListBuilderCard from './listBuilderCard/ListBuilderCard';
interface ICardsListProps {
    key: string;
    text: string;
    count: number | string;
}
interface IProps {
    cardsList: ICardsListProps[];
    stateData?: any;
}

const SummaryCard: React.FC<IProps> = ({
    cardsList,
    stateData
}) => { 
    const requestType = useStore((state: any) => state.requestType);
    const setLastSelectedRequest = useStore((state: any) => state.setLastSelectedRequest);
    const setListBuilderFilters = useStore((state: any) => state.setListBuilderFilters);
    const setFilterPanelDisabled = useStore((state: any) => state.setFilterPanelDisabled);
    const [isCardsMinimized, setIsCardsMinimized] = useState(false);
    const [cardExpanded, setCardExpanded] = useState<boolean>(false);

    const history = useHistory();

    const handleSetSelectedCard = (type: string) => {
        setLastSelectedRequest({});
        let dataObj: any = {};
        dataObj.requestType = type;
        setListBuilderFilters(null);
        setFilterPanelDisabled(false);
        setQueryParam(history, RouteConstants.MANAGE_REQUESTS, { page: 1 }, false, {
            ...stateData,
            ...dataObj
        });
    };
    const toggleCardsPanel = () => {
        setIsCardsMinimized((currentState) => !currentState);
    };

    const setActiveCard = (type: string) => {
        return requestType === type ? 'active' : '';
    };

    return (
        <div className={`admin-summary-card-container`}>
            <div className={`cards-container ${isCardsMinimized ? 'minimized' : ''} ${cardExpanded ? 'card-expanded' : ''}`}>
                <ListBuilderCard stateData={stateData} cardExpanded={cardExpanded} setCardExpanded={setCardExpanded}/>

                {cardsList?.map((item, itemIndex) => (
                    <div
                        key={itemIndex}
                        tabIndex={0}
                        className={`menu-card ${setActiveCard(item.key)}`}
                        onClick={() => handleSetSelectedCard(item.key)}
                        data-testid={`${item.key.toLowerCase()}`}>
                        <div className="mc-content">
                            <div className="mc-details">
                                <img className={`icon ${item.key.toLowerCase() + '-icon'} ${setActiveCard(item.key)}`} alt="card icon"></img>
                                <Text className="count">{item.count}</Text>
                            </div>
                            <Text block className="label">
                                <Trans>{item.text}</Trans>
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            <div className="cards-admin-toggle">
                <Link
                    role="button"
                    onClick={toggleCardsPanel}
                    onKeyPress={(e: any) => keyPress(e, () => toggleCardsPanel())}
                    tabIndex={0}
                    aria-label={`toggle cards ${isCardsMinimized ? 'expand' : 'expanded'}`}
                    data-testid="link-icon">
                    <Icon aria-label={`${isCardsMinimized ? 'ChevronDown' : 'ChevronUp'}`} iconName={`${isCardsMinimized ? 'ChevronDownMed' : 'ChevronUpMed'}`} className="icon" />
                </Link>
            </div>
        </div>
    );
};

export default SummaryCard;
