/* eslint-disable no-sequences */
export const iconSet = {
    undo: '<i class="ms-Icon ms-Icon--Undo"/>',
    text: '<i class="ms-Icon ms-Icon--Undo"/>',
    redo: '<i class="ms-Icon ms-Icon--Redo"></i>',
    italic: '<i class="ms-Icon ms-Icon--Italic"></i>',
    bold: '<i class="ms-Icon ms-Icon--Bold"></i>',
    underline: '<i class="ms-Icon ms-Icon--Underline"></i>',
    subscript: '<i class="ms-Icon ms-Icon--Subscript"></i>',
    superscript: '<i class="ms-Icon ms-Icon--Superscript"></i>',
    strike: '<i class="ms-Icon ms-Icon--Strikethrough"></i>',
    font_color: '<i class="ms-Icon ms-Icon--FontColor"></i>',
    highlight_color: '<i class="ms-Icon ms-Icon--Highlight"></i>',
    link: '<i class="ms-Icon ms-Icon--Link"></i>',
    indent: '<i class="ms-Icon ms-Icon--DecreaseIndentLegacy"></i>',
    outdent: '<i class="ms-Icon ms-Icon--IncreaseIndentLegacy"></i>',
    align_left: '<i class="ms-Icon ms-Icon--AlignLeft"></i>',
    align_center: '<i class="ms-Icon ms-Icon--AlignCenter"></i>',
    align_right: '<i class="ms-Icon ms-Icon--AlignRight"></i>',
    align_justify: '<i class="ms-Icon ms-Icon--AlignJustify"></i>',
    horizontal_rule: '<i class="ms-Icon ms-Icon--CalculatorSubtract"></i>',
    list_number: '<i class="ms-Icon ms-Icon--NumberedList"></i>',
    list_bullets: '<i class="ms-Icon ms-Icon--AlignJustify"></i>',
    line_height: '<i class="ms-Icon ms-Icon--LineSpacing"></i>',
    reduction: '<i class="ms-Icon ms-Icon--MinimumValue"></i>',
    expansion: '<i class="ms-Icon ms-Icon--MaximumValue"></i>',
    image: '<i class="ms-Icon ms-Icon--Photo2Add"></i>',
    video: '<i class="ms-Icon ms-Icon--MediaAdd"></i>',
    more_vertical: '<i class="ms-Icon ms-Icon--MoreVertical"></i>',
    more_horizontal: '<i class="ms-Icon ms-Icon--More"></i>',
    more_plus: `<span><i class="ms-Icon ms-Icon--PhotoVideoMedia"></i>${' '}<i class="ms-Icon ms-Icon--ChevronDown"></i></span>`,
    more_plain_text: '<span><i class="ms-Icon ms-Icon--FontColorA"></i><i class="ms-Icon ms-Icon--ChevronDown"></i></span>',
    emojis: '<i class="ms-Icon ms-Icon--Emoji2" />'
};

export const buttonList = {
    responsive: [
        // default
        ['undo', 'redo'],
        ['formatBlock', 'fontSize'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['image', 'video', 'link'],
        [{
            name: 'emojis_container',
            dataDisplay: 'container',
            innerHTML: '<i class="ms-Icon ms-Icon--Emoji2"></i>',
        }],
        // (min-width: 992)
        [
            '%992', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                ['bold', 'underline', 'italic', 'strike'],
                [':t-More Text-default.more_plain_text', 'subscript', 'superscript', 'fontColor', 'hiliteColor'],

                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight', 'link'],
                [':r-Media-default.more_plus', 'image', 'video'],
                [{
                    name: 'emojis_container',
                    dataDisplay: 'container',
                    innerHTML: '<i class="ms-Icon ms-Icon--Emoji2"></i>',
                }]
            ]
        ],
        // (min-width: 767)
        [
            '%767', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                [
                    ':t-More Text-default.more_plain_text',
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                    'fontColor',
                    'hiliteColor'
                ],

                ['outdent', 'indent', 'table', 'link'],
                [':e-Line-default.more_horizontal', 'align', 'horizontalRule', 'list', 'lineHeight'],
                [':r-Media-default.more_plus', 'image', 'video'],
                [{
                    name: 'emojis_container',
                    dataDisplay: 'container',
                    innerHTML: '<i class="ms-Icon ms-Icon--Emoji2"></i>',
                }]
            ]
        ],
        // (min-width: 480)
        [
            '%480', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                [
                    ':t-More Text-default.more_plain_text',
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                    'fontColor',
                    'hiliteColor',
                    'removeFormat'
                ],
                ['table', 'link', 'horizontalRule', 'list'],
                [':e-Line-default.more_horizontal', 'outdent', 'indent', 'align', 'lineHeight'],
                [':r-Media-default.more_plus', 'image', 'video'],
                [{
                    name: 'emojis_container',
                    dataDisplay: 'container',
                    innerHTML: '<i class="ms-Icon ms-Icon--Emoji2"></i>',
                }]
            ]
        ]
    ],
    default: [
        ['undo', 'redo'],
        ['formatBlock', 'fontSize'],
        ['bold', 'italic', 'underline', 'subscript', 'superscript', 'strike', 'fontColor', 'hiliteColor', 'link'],
        ['align', 'indent', 'outdent', 'lineHeight'],
        ['blockquote', 'list', 'image', 'video', 'horizontalRule']
    ],
    requestHelp: [
        ['list', 'image', 'link', 'indent', 'outdent']
    ]
};

export const buttonListTrainingVideoAdded = {
    responsive: [
        // default
        ['undo', 'redo'],
        ['formatBlock', 'fontSize'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['image', 'audio', 'link'],
        ['table', 'imageGallery'],
        ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView'],
        // (min-width: 992)
        [
            '%992', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                ['bold', 'underline', 'italic', 'strike'],
                [':t-More Text-default.more_plain_text', 'subscript', 'superscript', 'fontColor', 'hiliteColor'],

                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight', 'table', 'link'],
                ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView'],
                [':r-Media-default.more_plus', 'image', 'audio', 'imageGallery']
            ]
        ],
        // (min-width: 767)
        [
            '%767', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                [
                    ':t-More Text-default.more_plain_text',
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                    'fontColor',
                    'hiliteColor'
                ],

                ['outdent', 'indent'],
                [':e-Line-default.more_horizontal', 'align', 'horizontalRule', 'list', 'lineHeight', 'table', 'link'],
                [':r-Media-default.more_plus', 'image', 'audio', 'imageGallery'],
                ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView']
            ]
        ],
        // (min-width: 480)
        [
            '%480', [
                ['undo', 'redo'],
                ['formatBlock', 'fontSize'],
                [
                    ':t-More Text-default.more_plain_text',
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'subscript',
                    'superscript',
                    'fontColor',
                    'hiliteColor',
                    'removeFormat'
                ],
                ['table', 'link', 'horizontalRule', 'list'][
                    (':e-Line-default.more_horizontal', 'outdent', 'indent', 'align', 'lineHeight')
                ],
                [':r-Media-default.more_plus', 'image', 'audio', 'imageGallery'],
                ['-right', ':i-More options-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView']
            ]
        ]
    ],
    default: [
        ['undo', 'redo'],
        ['formatBlock', 'fontSize'],
        ['bold', 'italic', 'underline', 'subscript', 'superscript', 'strike', 'fontColor', 'hiliteColor', 'link'],
        ['align', 'indent', 'outdent', 'lineHeight'],
        ['blockquote', 'list', 'image', 'imageGallery', 'table', 'audio', 'codeView', 'horizontalRule'],
        ['preview']
    ]
};

export const setVideoOptions = {
    icons: iconSet,
    videoFileInput: true,
    videoUrlInput: true,
    videoWidth: '100%',
    videoAccept: '*',
    videoResizing: true,
    video: false
};

export const setImageOptions = {
    icons: iconSet,
    imageResizing: true,
    imageWidth: '100%',
    imageRotation: true
};