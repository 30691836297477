import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Image, Spinner, Text } from '@fluentui/react';
import { formatIntoFeedbackData } from '../../../../components/helpers';
import fcIconTerrible from 'app/static/icons/fc_icon_terrible_active.svg';
import fcIconBad from 'app/static/icons/fc_icon_bad_active.svg';
import fcIconOkay from 'app/static/icons/fc_icon_okay_active.svg';
import fcIconGood from 'app/static/icons/fc_icon_good_active.svg';
import fcIconGreat from 'app/static/icons/fc_icon_great_active.svg';
import { useGetFeedback } from 'app/services/queries';
import './PastFeedback.scss';
import { Trans, useTranslation } from 'react-i18next';

const PastFeedback: React.FC = () => {
    const { t: translate } = useTranslation();

    const columns = [
        {
            title: translate('Date'),
            width: '10%'
        },
        {
            title: translate('Feedback'),
            width: '22%'
        },
        {
            title: translate('Response'),
            width: '22%'
        },
        {
            title: translate('Experience'),
            width: '10%'
        },
        {
            title: translate('Category'),
            width: '10%'
        },
        {
            title: translate('Provided By'),
            width: '16%'
        },
        {
            title: translate('Responded On'),
            width: '10%'
        }
    ];
    const { data, isLoading } = useGetFeedback('isnewFeedbackOnly=false');
    const status = 'success';
    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(2);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            setTableData(() => formatIntoFeedbackData(data?.slice(0, 10)));
            if (data?.length < 10) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        }
    }, [isLoading, data]);
    // useEffect(() => {
    //     setTableData([]);
    // }, []);
    const getExperienceIcon = (reactionName: string) => {
        let source;
        switch (reactionName.toLowerCase()) {
            case 'terrible':
                source = fcIconTerrible;
                break;
            case 'bad':
                source = fcIconBad;
                break;
            case 'okay':
                source = fcIconOkay;
                break;
            case 'good':
                source = fcIconGood;
                break;
            case 'great':
                source = fcIconGreat;
                break;
            default:
                break;
        }
        return source;
    };

    const fetchData = () => {
        setTimeout(() => {
            setTableData((arr) => arr.concat(formatIntoFeedbackData(data?.slice(pageNumber * 10 - 10, pageNumber * 10))));
            setHasMore(data.length > pageNumber * 10);
            setPageNumber((page) => page + 1);
        }, 1000);
    };

    if (isLoading) {
        return (
            <div className="default-container">
                <Trans>
                    <Spinner className="m-t-50" label="Loading..." labelPosition="right" />
                </Trans>
            </div>
        );
    } else {
        return (
            <div id="past-feedback" className="table-view">
                <div className="tv-header">
                    {columns.map((column) => (
                        <div className={`${column.title.toLowerCase()}-header`} style={{ width: column.width }}>
                            {column.title}
                        </div>
                    ))}
                </div>
                <div className="tv-body">
                    {status === 'success' && (
                        <InfiniteScroll dataLength={tableData.length} next={fetchData} hasMore={hasMore} loader={<Spinner />}>
                            <>
                                {tableData.map((data: any) => (
                                    <div className="tv-row">
                                        <div style={{ width: columns[0].width }}>{data.date}</div>
                                        <div style={{ width: columns[1].width }}>{data.feedback}</div>
                                        <div style={{ width: columns[2].width }}>{data.response}</div>
                                        <div style={{ width: columns[3].width }} className="col-experience">
                                            <Image src={getExperienceIcon(data.experience)} alt={data.experience} />
                                            <Text className="experience-title">{data.experience}</Text>
                                        </div>
                                        <div style={{ width: columns[4].width }}>{data.category}</div>
                                        <div style={{ width: columns[5].width }}>{data.providedBy}</div>
                                        <div style={{ width: columns[6].width }}>{data.respondedOn}</div>
                                    </div>
                                ))}
                                {tableData.length === 0 && (
                                    <div className="tv-no-content">
                                        <Trans>No data available to display</Trans>.
                                    </div>
                                )}
                            </>{' '}
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        );
    }
};

export default PastFeedback;
