import React from 'react';
import { Image, Text } from '@fluentui/react';
import { Pivot, PivotItem } from '@fluentui/react';
import useStore from 'app/store';
import ContactTable from './contactTable/ContactTable';
import './ContactDetails.scss';
import SDSGLogo from 'app/static/icons/SDSG-logo.svg';
import CXPLogo from 'app/static/icons/CXP-logo.svg';
import CSSLogo from 'app/static/icons/CSS-logo.svg';
import CREWLogo from 'app/static/icons/CREW-logo.svg';
import { TEAM_DETAILS } from './constants';
import { useTranslation } from 'react-i18next';
import { StringConstants } from 'app/utils/constants';

interface ITeamDetailsProps {
    id: string;
    name: string;
    description: string;
    action?: string;
    roles?: string[];
    secondaryText?: string;
}

const ContactDetails: React.FC = () => {
    const { t: translate } = useTranslation();
    const HEADER_TABS = { SDSG: translate(StringConstants.SDSG), CXP: translate(StringConstants.CXP), CSS: translate(StringConstants.CSS), CREW: translate(StringConstants.CREW) };
    const selectedTab = useStore((state: any) => state.selectedTab);
    const setSelectedTab = useStore((state: any) => state.setSelectedTab);

    const getDetails = (value: string, type: string) => {
        let imgSrc;
        switch (value) {
            case StringConstants.SDSG:
                imgSrc = SDSGLogo;
                break;
            case StringConstants.CXP:
                imgSrc = CXPLogo;
                break;
            case StringConstants.CSS:
                imgSrc = CSSLogo;
                break;
            case StringConstants.CREW:
                imgSrc = CREWLogo;
                break;
            default:
                break;
        }

        const res: ITeamDetailsProps = TEAM_DETAILS.find((item: ITeamDetailsProps) => item.id === selectedTab);

        if (type === StringConstants.IMAGE) {
            return imgSrc;
        } else if (type === StringConstants.NAME) {
            return res?.name;
        } else if (type === StringConstants.ACTION) {
            return res?.action;
        } else if (type === StringConstants.DESCRIPTION) {
            if (res?.description) {
                return res.description;
            } else {
                return;
            }
        } else if (type === StringConstants.SECONDARY_TEXT) {
            if (res?.secondaryText) {
                return res.secondaryText;
            } else {
                return;
            }
        }
    };
    const getRoles = (value: string) => {
        const res: ITeamDetailsProps = TEAM_DETAILS.find((item: ITeamDetailsProps) => item.id === value);

        if (res?.roles) {
            return res.roles;
        } else {
            return [];
        }
    };

    return (
        <div className="footer-contact-background p-t-15 p-b-20">
            <div className="container">
                <Text className=" p-l-25 bold-text contact-heading">Contacts</Text>
                <div className="contact-main-content m-t-10 row-flex-box justify-sb">
                    <div className="team-desc-container column-flex-box p-b-25 p-t-15 p-l-20 p-r-20">
                        <Image className="team-icon" src={getDetails(selectedTab, StringConstants.IMAGE)} alt={selectedTab} />
                        <Text className="bold-text team-name">{getDetails(selectedTab, StringConstants.NAME)}</Text>
                        <Text className="team-description p-t-10 common-font">{getDetails(selectedTab, StringConstants.DESCRIPTION)}</Text>
                        <Text className="team-secondary-text p-t-25 common-font">
                            {getDetails(selectedTab, StringConstants.SECONDARY_TEXT)}
                        </Text>
                        <div className="p-t-5 column-flex-box">
                            {getRoles(selectedTab).map((item) => (
                                <Text className="team-roles common-font p-t-10">{item}</Text>
                            ))}
                        </div>
                        <Text className="team-action p-t-25 common-font"> {getDetails(selectedTab, StringConstants.ACTION)}</Text>
                    </div>
                    <div className="contact-table p-t-25 p-b-25 p-l-40 p-r-40">
                        <Pivot
                            onLinkClick={(item) => setSelectedTab(item.props.itemKey)}
                            aria-label="Override Selected Item Pivot Example"
                            selectedKey={selectedTab}>
                            {Object.entries(HEADER_TABS).map(([key, value]) => (
                                <PivotItem id={`^^^^^^^^^^^^6^`} itemKey={value} headerText={value}>
                                    <ContactTable />
                                </PivotItem>
                            ))}
                        </Pivot>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContactDetails;
