import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchData, useSearchResult } from 'app/services/mutations';
import useStore from 'app/store';
import voiceIcon from 'app/static/icons/microVoice.svg';
import { useSpeechToTextToken } from 'app/services/queries';

import { isUser } from 'app/utils/authUtilities';
import { escapeToSafeCharacters, formatSearchIntoRequestData, formatSearchSuggestionIntoRequestData, getDate, keyPress } from 'app/components/helpers';

import * as speechSdk from 'microsoft-cognitiveservices-speech-sdk';
import { IUserDetails } from 'app/models/common/post';
import { Languages, StringConstants } from 'app/utils/constants';

export interface SearchResult {
    name: string;
    link: string;
}

enum SearchOption {
    Article = 'Article',
    HelpRequest = 'HelpRequest',
    Default = 'Default'
}

interface IProps {
    isAssociatingParent: boolean;
    setFilteredResult: Function;
    refetchChildList: Function;
}

const AssociationSearchBar: React.FC<IProps> = ({ isAssociatingParent, setFilteredResult, refetchChildList }) => {
    const userDetails: IUserDetails = useStore((state: any) => state.userDetails);

    const [isSearchResultShown, ] = useState(false);

    const location = useLocation();
    const { pathname } = location;

    const [searchText, setSearchText] = useState('');
    const [searchResult, ] = useState([]);

    const setUserSearchValue = useStore((state: any) => state.setUserSearchValue);

    const { mutate: suggestData, data: suggestResultData, isSuccess: isSuccessSuggest } = useSearchData();
    const { mutate: searchData, data: searchResultData, isSuccess: isSuccessSearch } = useSearchResult();

    const [isSearchFocused, setIsSearchFocused] = useState(false);

    const { data: generateToken, isLoading: isTokenLoading, refetch: getSpeechToTextToken } = useSpeechToTextToken();
    const [, setTokenObj] = useState({ Token: '', Region: '' });
    const [isRecording, setIsRecording] = useState(false);
    const [recognizer, setRecognizer] = useState(null);

    useEffect(() => {
        if (!isTokenLoading) {
            setTokenObj(generateToken);
        }
    }, [isTokenLoading]);

    //voice to text
    const sttFromMic = async (action: Boolean) => {
        if (action) {
            let token = JSON.parse(sessionStorage.getItem('speech-token'));
            if (!(token && token.Token)) {
                await getSpeechToTextToken();
                token = JSON.parse(sessionStorage.getItem('speech-token'));
            }
            const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(token?.Token ?? '', token?.Region ?? '');
            speechConfig.speechRecognitionLanguage = Languages.EN_US;
            speechConfig.setServiceProperty('punctuation', 'explicit', speechSdk.ServicePropertyChannel.UriQueryParameter);
            const audioConfig = speechSdk.AudioConfig.fromDefaultMicrophoneInput();

            let newRecognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
            setRecognizer(newRecognizer);
            setIsRecording(true);

            newRecognizer.recognized = function (s: any, e: any) {
                let displayText;

                if (e.result.text) {
                    displayText = `${e.result.text}`;
                    displayText = displayText.replace('SKP ', 'SKP-');

                    setSearchText(displayText);
                    setUserSearchValue(displayText);
                    setIsRecording(false);
                    searchFieldRef.current.focus();
                    suggestData({
                        term: displayText,
                        searchOption: 'HelpRequest',
                        IsAdminSearch:
                            !isUser(userDetails?.userRoles) &&
                            (pathname.includes('manage-requests') ||
                                (!isUser(userDetails?.userRoles) && pathname === StringConstants.FORWARDSLASH) ||
                                pathname.includes('report') ||
                                pathname.includes('request-detail') ||
                                pathname.includes('visual-flow')),
                        IsParentChildSearch: true
                    });

                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                }
            };

            newRecognizer.startContinuousRecognitionAsync(() => {
                setTimeout(() => {
                    newRecognizer.stopContinuousRecognitionAsync(
                        () => {},
                        (e: string) => {}
                    );
                    setIsRecording(false);
                }, 20000);
            });
        }

        if (!action) {
            recognizer.stopContinuousRecognitionAsync(
                (result: string) => {},
                (e: string) => {}
            );
        }
    };

    const voiceStartClick = () => {
        sttFromMic(true);
    };

    useEffect(() => {
        if (isSuccessSearch) {
            var filteredResults = searchResultData?.data?.SearchResult?.Items?.filter((result: any) => {
                if (isAssociatingParent) {
                    return result.Document.IsParent === true;
                } 
                return (result.Document.ParentRequestId === null || result.Document.ParentRequestId === "");
            }).map((item: any) => item.Document);

            setFilteredResult(formatSearchIntoRequestData(filteredResults, isAssociatingParent))
        }
    }, [isSuccessSearch]);

    useEffect(() => {
        if (isSuccessSuggest) {
            var filteredResults = suggestResultData?.data?.filter((result: any) => {
                if (isAssociatingParent) {
                    return result.Document.IsParent === true;
                } 

                return result.Document.ParentRequestId === null;
            }).map((item: any) => item.Document);

            setFilteredResult(formatSearchSuggestionIntoRequestData(filteredResults, isAssociatingParent))
        }
    }, [isSuccessSuggest]);

    const onFocus = () => {
        setIsSearchFocused(true);
    };

    const onChange = (event: any) => {
        setSearchText(event.target.value);
        
        if (event.target.value.length <= 2) {
            refetchChildList();
        } else {
            setUserSearchValue(event.target.value);
            if (event.target.value.trim()) {
                let payload = {
                    searchTerm: escapeToSafeCharacters(event.target.value),
                    pageIndex: 1,
                    filter: getDate('All Dates'),
                    pageSize: 30,
                    searchOption: SearchOption.Default,
                    IsDateRangeCountRequired: true,
                    SearchPaginatedDetails: [] as any[],
                    IsAdminSearch: true
                };

                searchData(payload);
            }
        }
    };

    const searchContainer = useRef(null);
    const searchFieldRef = useRef(null);

    return (
        <>
            <div className="search-box-top" role="searchbox" aria-label="search-requests-field" ref={searchContainer}>
                {!isRecording ? (
                    <img
                        tabIndex={0}
                        src={voiceIcon}
                        alt="Voice"
                        className="voice-icon"
                        onKeyPress={(e) => keyPress(e, voiceStartClick)}
                        onClick={() => {
                            voiceStartClick();
                        }}
                    />
                ) : (
                    ''
                )}
                {isRecording ? (
                    <div
                        className="recording-row recording-position"
                        onKeyPress={(e) =>
                            keyPress(e, () => {
                                setIsRecording(false);
                                sttFromMic(false);
                            })
                        }
                        tabIndex={0}
                        onClick={() => {
                            setIsRecording(false);
                            sttFromMic(false);
                        }}>
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="fourth-line"></div>
                        <div className="fifth-line"></div>
                    </div>
                ) : (
                    ''
                )}
                <input
                    tabIndex={0}
                    className={`search-input ${
                        isSearchResultShown ? 'search-input-focus' : searchResult?.length > 0 ? 'show-suggestions ' : ''
                    } ${searchText?.length > 3 ? 'search-input-grown' : ''}  ${
                        isSearchFocused ? 'search-animation' : 'search-shrink-animation'
                    }`}
                    onChange={(event) => onChange(event)}
                    onFocus={() => onFocus()}
                    type="text"
                    value={searchText}
                    data-testid="search-text"
                    role="searchbox"
                    ref={searchFieldRef}
                    placeholder={'Search'}
                />
            </div>
        </>
    );
};

export default AssociationSearchBar;
