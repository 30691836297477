import { Modal, TextField, Text, IIconProps, IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import Button from '../../../../../components/button/Button';
import backArrowLb from '../../../../../static/icons/backArrowLb.svg';
import './AddFolder.scss';
import { iconButtonStyles } from '../../helpers/helpers';
import { IListFolder } from 'app/models/common/post';

interface IProps {
    setFolders?: Function;
    setSelectedFolder?: Function;
    folders?: any;
    showModal?: any;
}

export const AddFolderListBuilderModal = () => {
    const [isModalOpen, { setTrue: showFolderModal, setFalse: hideFolderModal }] = useBoolean(false);
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const [newFolder, setNewFolder] = useState<IListFolder>({
        name: '',
        savedListQueries: []
    });

    const AddFolderModal: React.FC<IProps> = ({ setFolders, folders, showModal }) => {
        const handleCancelClick = () => {
            hideFolderModal();
            showModal();
        };
        const AddFolder = () => {
            hideFolderModal();
            showModal();
            setFolders([...folders, newFolder]);

            setNewFolder(null);
        };

        const onChangeHandler = (e: any) => {
            setNewFolder({ ...newFolder, name: e.target.value });
        };

        return (
            <div>
                <Modal isOpen={isModalOpen} onDismiss={hideFolderModal} isBlocking={false} className="">
                    <div className="folder-modal-header">
                        <div className="addFolderHeader">Add Folder</div>
                        <div>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={handleCancelClick}
                            />
                        </div>
                    </div>
                    <div className="backArrowStyles" onClick={handleCancelClick}>
                        <img src={backArrowLb} alt="back arrow" /> <Text className="addFolderBackArrow">Add Folder</Text>
                    </div>
                    <div className="textField-btn-container">
                        <div>
                            <div className="save-folder-header">Name</div>
                            <TextField
                                className="textfield-folder"
                                value={newFolder?.name}
                                onChange={(e: any) => {
                                    onChangeHandler(e);
                                }}
                                autoFocus
                            />
                        </div>
                        <div>
                            <div className="folder-modal-footer">
                                <Button buttonClass="btn btn--secondary" buttonText="Cancel" onClick={handleCancelClick} />
                                <Button
                                    disabled={newFolder?.name?.trim().length > 0 ? false : true}
                                    buttonClass="btn btn--primary"
                                    buttonText="Add"
                                    onClick={AddFolder}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    return { AddFolderModal, showFolderModal, hideFolderModal };
};
