import React, { useState, useEffect } from 'react';
import { Image, Text, Link, Spinner } from '@fluentui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import fcIconTerrible from 'app/static/icons/fc_icon_terrible_active.svg';
import fcIconBad from 'app/static/icons/fc_icon_bad_active.svg';
import fcIconOkay from 'app/static/icons/fc_icon_okay_active.svg';
import fcIconGood from 'app/static/icons/fc_icon_good_active.svg';
import fcIconGreat from 'app/static/icons/fc_icon_great_active.svg';
import { useGetFeedback } from 'app/services/queries';
import { formatIntoFeedbackData, keyPress } from '../../../../components/helpers';
import { FeedbackData } from 'app/models/common/post';
import './NewFeedback.scss';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
    showResponseForm: (data: any) => void;
}

const NewFeedback: React.FC<IProps> = (props) => {
    const { t: translate } = useTranslation();

    // constants
    const columns = [
        {
            title: translate('Date'),
            width: '10%'
        },
        {
            title: translate('Feedback'),
            width: '50%'
        },
        {
            title: translate('Experience'),
            width: '10%'
        },
        {
            title: translate('Category'),
            width: '10%'
        },
        {
            title: translate('Provided By'),
            width: '20%'
        }
    ];
    // Hooks declarations

    const { data, isLoading } = useGetFeedback('isnewFeedbackOnly=true');

    const status = 'success';
    const [tableData, setTableData] = React.useState([]);
    const [pageNumber, setPageNumber] = useState(2);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            setTableData(() => formatIntoFeedbackData(data?.slice(0, 10)));
            if (data?.length < 10) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        }
    }, [isLoading, data]);

    // useEffect(() => {
    //     setTableData([]);
    // }, []);

    // helper functions

    const getExperienceIcon = (reactionName: string) => {
        let source;
        switch (reactionName?.toLowerCase()) {
            case 'terrible':
                source = fcIconTerrible;
                break;
            case 'bad':
                source = fcIconBad;
                break;
            case 'okay':
                source = fcIconOkay;
                break;
            case 'good':
                source = fcIconGood;
                break;
            case 'great':
                source = fcIconGreat;
                break;
            default:
                source = fcIconBad;
                break;
        }
        return source;
    };

    const handleShowResponseForm = (dataItem: FeedbackData) => {
        props.showResponseForm(dataItem);
    };

    const fetchData = () => {
        setTimeout(() => {
            setTableData((arr) => arr.concat(formatIntoFeedbackData(data?.slice(pageNumber * 10 - 10, pageNumber * 10))));
            setHasMore(data.length > pageNumber * 10);
            setPageNumber((page) => page + 1);
        }, 1000);
    };

    if (isLoading) {
        return (
            <div className="default-container">
                <Trans>
                    <Spinner className="m-t-50" label="Loading..." labelPosition="right" />
                </Trans>
            </div>
        );
    } else {
        return (
            <div id="new-feedback" className="table-view">
                <div className="tv-header">
                    {columns.map((column) => (
                        <div className={`${column.title.toLowerCase()}-header`} style={{ width: column.width }}>
                            {column.title}
                        </div>
                    ))}
                </div>
                <div className="tv-body">
                    {status === 'success' && (
                        <InfiniteScroll dataLength={tableData.length} next={fetchData} hasMore={hasMore} loader={<Spinner />}>
                            <>
                                {tableData.map((dataItem: any) => (
                                    <div
                                        tabIndex={0}
                                        className="tv-row"
                                        onKeyPress={(e: any) => keyPress(e, () => handleShowResponseForm(dataItem))}
                                        onClick={() => {
                                            handleShowResponseForm(dataItem);
                                        }}>
                                        <div style={{ width: columns[0].width }}>{dataItem?.date}</div>
                                        <div style={{ width: columns[1].width }}>
                                            {dataItem?.feedback}
                                            <div className="feedback-link">
                                                <Link>
                                                    <Trans>Your Response</Trans>
                                                </Link>
                                            </div>
                                        </div>
                                        <div style={{ width: columns[2].width }} className="col-experience">
                                            <Image src={getExperienceIcon(dataItem?.experience)} alt={dataItem?.experience} />
                                            <Text className="experience-title">{dataItem?.experience}</Text>
                                        </div>
                                        <div style={{ width: columns[3].width }}>{dataItem?.category}</div>
                                        <div style={{ width: columns[4].width }}>{dataItem.providedBy}</div>
                                    </div>
                                ))}
                                {tableData.length === 0 && (
                                    <div className="tv-no-content">
                                        <Trans>No data available to display</Trans>.
                                    </div>
                                )}
                            </>{' '}
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        );
    }
};

export default NewFeedback;
