import { TooltipHost } from '@fluentui/react';
import { ReportType } from 'app/enums/ReportType.enum';
import { IReport } from 'app/models/common/post';
import { IAuditField } from 'app/models/common/request';
import { isAdmin, isSuperAdmin } from 'app/utils/authUtilities';
import * as React from 'react';
import { useId } from '@fluentui/react-hooks';
import { StringConstants } from 'app/utils/constants';
type ActionType = '' | 'Delete' | 'Share' | 'Add' | 'Bookmark' | 'Edit';

interface IProps {
    dataItem: any;
    clickHandler: (action: ActionType, dataItem: IReport & IAuditField) => void;
}

export const ActionsCell = (props: IProps) => {
    const { dataItem, clickHandler } = props;
    const accessToken = localStorage.getItem('accessToken');
    const tooltipId = useId('tooltip');
    return (
        <td className="k-command-cell">
            {(isSuperAdmin(accessToken) || isAdmin(accessToken)) && (
                <button aria-label="bookmark" onClick={() => clickHandler('Bookmark', dataItem)}>
                    <i
                        className={`ms-Icon ${
                            dataItem?.isBookmarked ? 'ms-Icon--FavoriteStarFill color-yellow--100' : 'ms-Icon--FavoriteStar'
                        }`}></i>
                </button>
            )}
            {isSuperAdmin(accessToken) && (
                <TooltipHost
                    content={
                        dataItem.type === ReportType.UtilizationInsightsReport ? StringConstants.SHARE_REPORT_TYPE_MESSAGE : ''
                    }
                    id={tooltipId}>
                    <button
                        aria-label="share"
                        onClick={() => clickHandler('Share', dataItem)}
                        disabled={dataItem.type === ReportType.UtilizationInsightsReport}>
                        <i aria-describedby={tooltipId} className="ms-Icon ms-Icon--Share"></i>
                    </button>
                </TooltipHost>
            )}
            {isSuperAdmin(accessToken) && (
                <button aria-label="edit" onClick={() => clickHandler('Edit', dataItem)}>
                    <i className="ms-Icon ms-Icon--PageEdit"></i>
                </button>
            )}
            {isSuperAdmin(accessToken) && (
                <button aria-label="delete" onClick={() => clickHandler('Delete', dataItem)}>
                    <i className="ms-Icon ms-Icon--Delete"></i>
                </button>
            )}
        </td>
    );
};
