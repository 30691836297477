import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import {
    Text,
    DatePicker,
    ActionButton,
    TooltipHost,
    TooltipDelay,
    DirectionalHint,
    ITooltipProps,
    ITooltipHostStyles,
    Modal,
    TextField,
    Link
} from '@fluentui/react';
import { IRequest } from 'app/models/common/response';
import RequestTypeDropdown from '../requestTypeDropdown/RequestTypeDropdown';
import AgingProgress from 'app/components/agingProgress/AgingProgress';
import {
    ISOToDateTime,
    GetText,
    filterField,
    formatCurrency,
    highlightTags,
    isValidURL,
    ReplaceUrlByAnchor,
    injectTargetToAnchor
} from 'app/components/helpers';
import { ILookUpData } from 'app/models/common/post';

import ActionDropdown from '../actionDropdown/ActionDropdown';
import './RequestDetailsTab.scss';
import { useBoolean, useId } from '@fluentui/react-hooks';

import * as _ from 'lodash';
import AddProduct from '../addProduct/AddProduct';
import { useQueryAllDeviceList } from 'app/services/queries';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import BugInformationModal from '../bugInformationModal/BugInformationModal';
import RequestRichTextEditor from '../requestRichTextEditor/RequestRichTextEditor';
import { Languages, BlobStorageContainer, StringConstants } from 'app/utils/constants';
import { findDeviceIconUrl, getDeviceIcon } from 'app/utils/SVGIcons';

interface IProps {
    selectedRequest: IRequest;
    requestDetailsLookup?: ILookUpData[];
    isEditMode?: boolean;
    resolutionDate?: Date;
    setResolutionDate?: Function;
    statusMenu?: any;
    statusTypes?: ILookUpData[];
    statusSelection?: number;
    onCompleteHandler?: Function;
    whatbusinessproblemissolved?: string;
    setwhatbusinessproblemissolved?: Function;
    competitiveoffering?: string;
    setCompetitiveoffering?: Function;
    isdesignforsurfacedfspossible?: string;
    setIsdesignforsurfacedfspossible?: Function;
    dcfJustification?: string;
    setDcfJustification?: Function;
    dcfApprovedValue?: number;
    setDcfApprovedValue?: Function;
    dcfSR?: string;
    setDcfSR?: Function;
    cssCaseNumber?: string;
    setCssCaseNumber?: Function;
    answer?: string;
    setAnswer?: Function;
    whyThisDateIsImportant?: string;
    setWhyThisDateIsImportant?: (whyThisDateIsImportant: string) => void;
    setTextToSubmit?: (textToSubmit: string) => void;
    handleAddProduct: Function;
    hideLoadingScreen: boolean;
    bugInfoUpdateHandler?: Function;
    hashTagsFormatted: any[];
    setHashTagsFormatted: (hashTagsFormatted: any[]) => void;
}

const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block', borderRadius: '13px' }
};

const RequestDetailsTab: React.FC<IProps> = (props) => {
    const {
        selectedRequest,
        isEditMode,
        resolutionDate,
        statusMenu,
        statusTypes,
        statusSelection,
        whatbusinessproblemissolved,
        competitiveoffering,
        isdesignforsurfacedfspossible,
        dcfJustification,
        dcfApprovedValue,
        dcfSR,
        cssCaseNumber,
        answer,
        requestDetailsLookup,
        whyThisDateIsImportant,
        hashTagsFormatted
    } = props;
    const {
        setResolutionDate,
        onCompleteHandler,
        setwhatbusinessproblemissolved,
        setCompetitiveoffering,
        setIsdesignforsurfacedfspossible,
        setDcfJustification,
        setDcfApprovedValue,
        setDcfSR,
        setCssCaseNumber,
        setAnswer,
        setTextToSubmit,
        handleAddProduct,
        bugInfoUpdateHandler,
        setHashTagsFormatted
    } = props;
    const tooltipId = useId('tooltip');
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isBugInfoModalOpen, setIsBugInfoModalOpen] = useState(false);
    const yesNoLookup = [
        {
            id: true,
            name: StringConstants.YES
        },
        {
            id: false,
            name: 'No'
        }
    ];

    //handle editable fields
    const [isEdittingRequestDetails, setIsEdittingRequestDetails] = useState<boolean>(false);
    const [businessProblemFieldValue, setBusinessProblemFieldValue] = useState<string>('');
    const [compOfferFieldValue, setCompOfferFieldValue] = useState<string>('');
    const [isDfsFieldValue, setIsDfsFieldValue] = useState<string>('');
    const [cssCaseNumberFieldValue, setCssCaseNumberFieldValue] = useState<string>('');
    const [dcfJustificationFieldValue, setDcfJustificationFieldValue] = useState<string>('');
    const [dcfApprovedFieldValue, setDcfApprovedFieldValue] = useState<number>(null);
    const [dcfSRFieldValue, setDcfSRFieldValue] = useState<string>('');
    const [answerFieldValue, setAnswerFieldValue] = useState<string>('');
    const [resolutionDateFieldValie, setResolutionDateFieldValue] = useState<any>();

    useEffect(() => {
        setBusinessProblemFieldValue(whatbusinessproblemissolved);
        setCompOfferFieldValue(competitiveoffering);
        setIsDfsFieldValue(isdesignforsurfacedfspossible);
        setCssCaseNumberFieldValue(cssCaseNumber);
        setDcfApprovedFieldValue(dcfApprovedValue);
        setDcfJustificationFieldValue(dcfJustification);
        setDcfSRFieldValue(dcfSR);
        setAnswerFieldValue(answer);
        setResolutionDateFieldValue(resolutionDate);
    }, [isEditMode]);

    // handler functions
    const getFieldValues = (fieldName: string) => {
        const teamDetails: any = _.find(requestDetailsLookup, { requestTeamId: selectedRequest.teamValue });
        if (teamDetails) {
            const requestTypeDetails: any = _.find(teamDetails.fieldTypeDetail, {
                requestTypeId: selectedRequest.requestTypeValue
            });
            if (requestTypeDetails) {
                return requestTypeDetails[fieldName];
            }
        }
    };
    const getNameByID = (val: Array<{ id: number | boolean; name: string }>, id: number | boolean) => {
        const res = val.filter((item: { id: number | boolean; name: string }) => item.id === id)[0]?.name;
        if (!res) return '--';
        return res;
    };
    const { isLoading: isDeviceListLoaded, data: getDeviceList } = useQueryAllDeviceList();

    const getDeviceIconSrc = (device: any) => {
        let iconUrl: string = undefined;
        if (selectedRequest) {
            iconUrl = findDeviceIconUrl(getDeviceList, device);
        }
        return iconUrl ? getDeviceIcon(iconUrl) : getDeviceIcon();
    };

    const tooltipProps: ITooltipProps = React.useMemo(
        () => ({
            onRenderContent: () => (
                <ul className="device-list">
                    {!isDeviceListLoaded &&
                        selectedRequest &&
                        selectedRequest.devices &&
                        selectedRequest.devices?.slice(1).map((device) => {
                            return (
                                <li>
                                    <img src={ getDeviceIconSrc(device) } alt={device.name} title={device.name} />
                                    <span className="device-txt">
                                        {device.name} ({device.deviceCount})
                                    </span>
                                </li>
                            );
                        })}
                </ul>
            ),

            calloutProps: {
                styles: {
                    beak: { padding: '0' },
                    beakCurtain: { borderRadius: '13px', padding: '0' },
                    calloutMain: { borderRadius: '10px' }
                }
            }
        }),
        [selectedRequest, isDeviceListLoaded]
    );

    const handleResolutionDate = (newValue: any) => {
        setIsEdittingRequestDetails(true);
        setResolutionDate(newValue);
        setResolutionDateFieldValue(newValue);
    };

    const handleWhatbusinessproblemissolvedChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        setIsEdittingRequestDetails(true);
        setBusinessProblemFieldValue(newValue);
        setwhatbusinessproblemissolved(newValue);
    };

    const handleCompetitiveofferingChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        setIsEdittingRequestDetails(true);
        setCompOfferFieldValue(newValue);
        setCompetitiveoffering(newValue);
    };

    const handleIsdesignforsurfacedfspossibleChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        setIsEdittingRequestDetails(true);
        setIsDfsFieldValue(newValue);
        setIsdesignforsurfacedfspossible(newValue);
    };

    const handleDcfJustificationChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setIsEdittingRequestDetails(true);
        setDcfJustificationFieldValue(newValue);
        setDcfJustification(newValue);
    };

    const handleDcfApprovedValueChange = (value: NumberFormatValues) => {
        setIsEdittingRequestDetails(true);
        setDcfApprovedFieldValue(value.floatValue);
        setDcfApprovedValue(value.floatValue);
    };

    const handleDcfSRChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setIsEdittingRequestDetails(true);
        setDcfSRFieldValue(newValue);
        setDcfSR(newValue);
    };

    const handleCssCaseNumberChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setIsEdittingRequestDetails(true);
        setCssCaseNumberFieldValue(newValue);
        setCssCaseNumber(newValue);
    };

    const handleAnswerChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setIsEdittingRequestDetails(true);
        setAnswerFieldValue(newValue);
        setAnswer(newValue);
    };

    const convertToURL = (stringValue: string, className: string, openInNewTab: boolean) => {
        if (!stringValue) {
            return '';
        }
        let classValue = className ? className : '';
        let targetValue = openInNewTab ? 'target="_blank"' : '';
        return stringValue.replaceAll(
            // eslint-disable-next-line no-useless-escape
            /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gim,
            ` <a class="${classValue}" href="$1" ${targetValue}>$1</a>`
        );
    };

    const roundPrice = (price: any) => {
        return price - parseInt(price) >= 0.5 ? Math.ceil(price) : Math.floor(price);
    };

    return (
        <div className="request-details-tab">
            <table className="dp-content-details-tbl">
                <tbody>
                    <tr className="tbl-header">
                        <td>
                            <Trans>REQUEST TYPE</Trans>
                        </td>
                        <td>
                            <Trans>MSX REVENUE</Trans>
                        </td>
                        <td>
                            <Trans>MSX DEVICE</Trans>
                        </td>
                        <td>
                            <Trans>AGING</Trans>
                        </td>
                        <td>
                            <Trans>STATUS</Trans>
                        </td>
                    </tr>
                    <tr>
                        {(selectedRequest.teamValue === 3 ||
                            selectedRequest.statusValue === 4 ||
                            selectedRequest.statusValue === 5 ||
                            selectedRequest.isHidden) && <td>{selectedRequest.requestType}</td>}
                        {selectedRequest.teamValue !== 3 &&
                            !(selectedRequest.statusValue === 4 || selectedRequest.statusValue === 5) &&
                            !selectedRequest.isHidden && (
                                <td>
                                    <RequestTypeDropdown selectedRequest={selectedRequest} />
                                </td>
                            )}
                        {selectedRequest.isParent ? (
                            <>
                                <td>
                                    ${' '}
                                    {new Intl.NumberFormat(Languages.EN_US, { style: 'decimal' }).format(
                                        roundPrice(selectedRequest.msxCurrentTotalRevenue ? selectedRequest.msxCurrentTotalRevenue : selectedRequest.msxTotalRevenue)
                                    )}
                                </td>
                                <td>{selectedRequest.msxCurrentTotalDeviceCount ? selectedRequest.msxCurrentTotalDeviceCount : selectedRequest.msxTotalDeviceCount}</td>
                            </>
                        ) : (
                            <>
                                <td>
                                    ${' '}
                                    {new Intl.NumberFormat(Languages.EN_US, { style: 'decimal' }).format(
                                        roundPrice(selectedRequest.msxCurrentTotalRevenue ? selectedRequest.msxCurrentTotalRevenue : selectedRequest.msxTotalRevenue)
                                    )}
                                </td>
                                <td>{selectedRequest.msxCurrentTotalDeviceCount ? selectedRequest.msxCurrentTotalDeviceCount : selectedRequest.msxTotalDeviceCount}</td>
                            </>
                        )}
                        <td>
                            <AgingProgress
                                startDate={selectedRequest.createdOnDate}
                                endDate={selectedRequest.dueDateValue}
                                closedDate={selectedRequest.closedOn}
                                status={selectedRequest.statusValue}
                            />
                        </td>
                        <td>
                            {!isEditMode && !selectedRequest.isHidden && (
                                <ActionButton menuProps={statusMenu} className="status-selection-menu">
                                    {GetText(statusTypes, statusSelection)}
                                </ActionButton>
                            )}
                            {selectedRequest.isHidden && <Text>{GetText(statusTypes, statusSelection)}</Text>}
                            {isEditMode && (
                                <ActionButton menuProps={statusMenu} className="status-selection-menu">
                                    {GetText(statusTypes, statusSelection)}
                                </ActionButton>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="tab-details-heading">
                TROUBLED PRODUCTS:{' '}
                {(!selectedRequest.isHidden && !selectedRequest.isParent)  && (
                    <span
                        className="txt-links update-product-link"
                        onClick={() => {
                            showModal();
                        }}>
                        Update products
                    </span>
                )}
            </div>
            {hideModal && (
                <Modal
                    layerProps={{ eventBubblingEnabled: true }}
                    titleAriaId={'Update Products'}
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    isBlocking={false}
                    className="add-impact-model">
                    <AddProduct
                        addOrUpdate={StringConstants.UPDATE}
                        selectedRequest={selectedRequest}
                        onDismiss={hideModal}
                        handleAddProduct={() => {
                            handleAddProduct();
                            hideModal();
                        }}
                    />
                </Modal>
            )}

            <div className="tbl-header request-details-row row-flex-box">
                {getFieldValues('productCategory') && (
                    <div className=" request-details-col">
                        {' '}
                        <div className="header-tab">PRODUCT CATEGORY</div>{' '}
                        {!selectedRequest.isHidden ? (
                            <div className="content-tab">
                                <ActionDropdown
                                    data={getFieldValues('productCategory')}
                                    onCompleteHandler={onCompleteHandler}
                                    fieldName="productCategory"
                                    currentValue={selectedRequest.productCategory}
                                    isEditMode={isEditMode}
                                    selectedRequest={selectedRequest}
                                />
                            </div>
                        ) : (
                            <div className="mr-top">
                                <Text>{getNameByID(getFieldValues('productCategory'), selectedRequest.productCategory)}</Text>
                            </div>
                        )}
                    </div>
                )}

                <div className="request-details-col ">
                    <div className="header-tab ">PRODUCT ADDED</div>{' '}
                    <div className="row-flex-box content-tab align-center">
                        {!isDeviceListLoaded &&
                            selectedRequest &&
                            selectedRequest?.devices &&
                            selectedRequest?.devices.length === 0 &&
                            '--'}
                        {!isDeviceListLoaded &&
                            selectedRequest &&
                            selectedRequest?.devices &&
                            selectedRequest?.devices.length > 0 && (
                                <img
                                    src={ getDeviceIconSrc(selectedRequest.devices[0]) }
                                    alt="Device"
                                    title="Device Image"
                                    className="pro-img"
                                />
                            )}
                        {!isDeviceListLoaded &&
                            selectedRequest &&
                            selectedRequest?.devices &&
                            selectedRequest?.devices.length > 0 && (
                                <Text>
                                    {selectedRequest?.devices[0]?.name} (
                                    {formatCurrency(selectedRequest?.devices[0]?.deviceCount)})
                                </Text>
                            )}
                        {selectedRequest?.devices && selectedRequest?.devices.length > 1 && (
                            <span className="device-count-container">
                                <TooltipHost
                                    tooltipProps={tooltipProps}
                                    delay={TooltipDelay.zero}
                                    id={tooltipId}
                                    directionalHint={DirectionalHint.bottomCenter}
                                    styles={hostStyles}
                                    className="hostStyles-new">
                                    <Text className="device-count">+{selectedRequest?.devices.length - 1}</Text>
                                </TooltipHost>
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="tab-details-heading">TIME SENSITIVITY</div>
            <div className="tab-details-sub-heading">REQUESTED RESOLUTION DATE</div>
            <div className="tab-details-text">
                {isEditMode && (
                    <DatePicker
                        placeholder="From: MM/DD/YYYY"
                        ariaLabel="Select a date"
                        value={
                            moment(selectedRequest.dueDateValue).isValid()
                            ? isEdittingRequestDetails
                                ? resolutionDateFieldValie
                                : new Date(ISOToDateTime(selectedRequest.dueDateValue, 'MMM DD YYYY'))
                            : null
                        }
                        onSelectDate={(value) => handleResolutionDate(value)}
                    />
                )}
                {!isEditMode
                    ? moment(selectedRequest.dueDateValue).isValid()
                        ? ISOToDateTime(selectedRequest.dueDateValue, 'MMM DD YYYY')
                        : '--'
                    : ''}
            </div>

            {filterField('whyThisDateIsImportant', selectedRequest.requestTypeValue) && (
                <div className="tab-details-sub-heading">WHY IS THIS DATE IMPORTANT?</div>
            )}
            {filterField('whyThisDateIsImportant', selectedRequest.requestTypeValue) && !isEditMode && (
                <div className="tab-details-text">
                    {whyThisDateIsImportant !== undefined && whyThisDateIsImportant && (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: highlightTags(
                                    ReplaceUrlByAnchor(
                                        injectTargetToAnchor(whyThisDateIsImportant),
                                        'link-item',
                                        true
                                    )
                                )
                            }}
                        ></span>
                    )}
                    <Text>{!whyThisDateIsImportant && '--'}</Text>
                </div>
            )}
            {filterField('whyThisDateIsImportant', selectedRequest.requestTypeValue) && isEditMode && (
                <div className="desc-textfield-wrap m-b-20">
                    <RequestRichTextEditor
                        content={whyThisDateIsImportant}
                        hashTagsFormatted={hashTagsFormatted}
                        setHashTagsFormatted={setHashTagsFormatted}
                        setTextToSubmit={setTextToSubmit}
                        fileStorageContainer={BlobStorageContainer.COMMENT_STATUS_FILES}
                    />
                </div>
            )}

            {selectedRequest.requestTypeValue !== 1 && selectedRequest.requestTypeValue !== 3 && (
                <div className="tab-details-heading m-t-30">ADDITIONAL DETAILS :</div>
            )}
            {(getFieldValues(StringConstants.PFR_SUBTYPE) ||
                filterField(StringConstants.PRIORITY_NO, selectedRequest.requestTypeValue) ||
                getFieldValues(StringConstants.PRIORITY) ||
                getFieldValues(StringConstants.CRITICALITY) ||
                getFieldValues(StringConstants.IMPACT_REACH) ||
                getFieldValues(StringConstants.MAIN_REQUEST_TYPE) ||
                getFieldValues(StringConstants.SUB_REQUEST_TYPE) ||
                getFieldValues(StringConstants.TASK_STATUS)) && (
                <table className="dp-content-details-tbl">
                    <tbody>
                        <tr className="tbl-header">
                            {getFieldValues(StringConstants.PFR_SUBTYPE) && <td>REQUEST SUBTYPE</td>}
                            {filterField(StringConstants.PRIORITY_NO, selectedRequest.requestTypeValue) && <td>PRIORITY NO.</td>}
                            {getFieldValues(StringConstants.PRIORITY) && <td>PRIORITY</td>}
                            {getFieldValues(StringConstants.CRITICALITY) && <td>CRITICALITY</td>}

                            {getFieldValues(StringConstants.IMPACT_REACH) && <td>IMPACT REACH</td>}
                            {getFieldValues(StringConstants.MAIN_REQUEST_TYPE) && <td>MAIN REQUEST TYPE</td>}
                            {getFieldValues(StringConstants.SUB_REQUEST_TYPE) && <td>SUB-REQUEST TYPE</td>}
                            {getFieldValues(StringConstants.TASK_STATUS) && <td>TASK STATUS</td>}
                        </tr>
                        <tr>
                            {getFieldValues(StringConstants.PFR_SUBTYPE) && (
                                <td style={{ width: '200px' }}>
                                    {selectedRequest.isHidden ? (
                                        <Text>{getNameByID(getFieldValues(StringConstants.PFR_SUBTYPE), selectedRequest.pfrSubType)}</Text>
                                    ) : (
                                        <ActionDropdown
                                            data={getFieldValues(StringConstants.PFR_SUBTYPE)}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="pfrSubtype"
                                            currentValue={selectedRequest.pfrSubType}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {filterField(StringConstants.PRIORITY_NO, selectedRequest.requestTypeValue) && (
                                <td style={{ width: '100px' }}>
                                    <Text>{selectedRequest.priorityNumber && selectedRequest.priorityNumber}</Text>
                                    <Text>{!selectedRequest.priorityNumber && '--'}</Text>
                                </td>
                            )}
                            {getFieldValues(StringConstants.PRIORITY) && (
                                <td style={{ width: '100px' }}>
                                    {selectedRequest.isHidden ? (
                                        <Text>{getNameByID(getFieldValues(StringConstants.PRIORITY), selectedRequest.priority)}</Text>
                                    ) : (
                                        <ActionDropdown
                                            data={getFieldValues(StringConstants.PRIORITY)}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="priority"
                                            currentValue={selectedRequest.priority}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {getFieldValues(StringConstants.CRITICALITY) && (
                                <td style={{ width: '100px' }}>
                                    {selectedRequest.isHidden ? (
                                        <Text>{getNameByID(getFieldValues(StringConstants.CRITICALITY), selectedRequest.criticality)}</Text>
                                    ) : (
                                        <ActionDropdown
                                            data={getFieldValues(StringConstants.CRITICALITY)}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="criticality"
                                            currentValue={selectedRequest.criticality}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {getFieldValues(StringConstants.IMPACT_REACH) && (
                                <td>
                                    {selectedRequest.isHidden ? (
                                        <Text>{getNameByID(getFieldValues(StringConstants.IMPACT_REACH), selectedRequest.impactReach)}</Text>
                                    ) : (
                                        <ActionDropdown
                                            data={getFieldValues(StringConstants.IMPACT_REACH)}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="impactReach"
                                            currentValue={selectedRequest.impactReach}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {getFieldValues(StringConstants.MAIN_REQUEST_TYPE) && (
                                <td>
                                    {selectedRequest.isHidden ? (
                                        <Text>
                                            {getNameByID(getFieldValues(StringConstants.MAIN_REQUEST_TYPE), selectedRequest.mainRequestType)}
                                        </Text>
                                    ) : (
                                        <ActionDropdown
                                            data={getFieldValues(StringConstants.MAIN_REQUEST_TYPE)}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="mainRequestType"
                                            currentValue={selectedRequest.mainRequestType}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {getFieldValues(StringConstants.SUB_REQUEST_TYPE) && (
                                <td>
                                    {selectedRequest.isHidden ? (
                                        <Text>
                                            {getNameByID(getFieldValues(StringConstants.SUB_REQUEST_TYPE), selectedRequest.subRequestType)}
                                        </Text>
                                    ) : (
                                        <ActionDropdown
                                            data={getFieldValues(StringConstants.SUB_REQUEST_TYPE)}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="subRequestType"
                                            currentValue={selectedRequest.subRequestType}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {getFieldValues(StringConstants.TASK_STATUS) && !selectedRequest.isHidden && (
                                <td>
                                    <ActionDropdown
                                        data={getFieldValues(StringConstants.TASK_STATUS)}
                                        onCompleteHandler={onCompleteHandler}
                                        fieldName="taskStatus"
                                        currentValue={selectedRequest.taskStatus}
                                        isEditMode={isEditMode}
                                        selectedRequest={selectedRequest}
                                    />
                                </td>
                            )}
                            {getFieldValues(StringConstants.TASK_STATUS) && selectedRequest.isHidden && (
                                <td>{getNameByID(getFieldValues(StringConstants.TASK_STATUS), selectedRequest.taskStatus)}</td>
                            )}
                        </tr>
                    </tbody>
                </table>
            )}

            {getFieldValues(StringConstants.BUG_INFORMATION) && (
                <table className="dp-content-details-tbl">
                    <tbody>
                        <tr className="tbl-header">
                            {getFieldValues(StringConstants.BUG_INFORMATION) && (
                                <td>
                                    <span className="m-r-10">BUG INFORMATION</span>
                                    {!selectedRequest.isHidden && (
                                        <Link className="add-bug-link" onClick={() => setIsBugInfoModalOpen(true)}>
                                            +Add bug
                                        </Link>
                                    )}
                                </td>
                            )}
                        </tr>
                        <tr>
                            {getFieldValues(StringConstants.BUG_INFORMATION) && (
                                <td>
                                    {(!selectedRequest.bugInformations || selectedRequest.bugInformations?.length === 0) && '--'}
                                    {selectedRequest.bugInformations &&
                                        selectedRequest.bugInformations.map((item) => (
                                            <div className="bug-info-disp-item">
                                                {GetText(getFieldValues(StringConstants.BUG_INFORMATION), item.bugInformation)} -{' '}
                                                {isValidURL(item?.url) && (
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: convertToURL(
                                                                item?.url?.replaceAll(',', ' ,'),
                                                                'link-item',
                                                                true
                                                            )
                                                        }}
                                                    ></span>
                                                )}
                                                {!isValidURL(item?.url) && <Text>{item?.url}</Text>}
                                            </div>
                                        ))}
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            )}
            {filterField(StringConstants.BUISNESS_PROBLEM, selectedRequest.requestTypeValue) && (
                <div className="tab-details-sub-heading">WHAT BUSINESS PROBLEM IS SOLVED</div>
            )}
            {filterField(StringConstants.BUISNESS_PROBLEM, selectedRequest.requestTypeValue) && !isEditMode && (
                <div className="dp-content-text">
                    <Text>{selectedRequest.whatBusinessProblemIsSolved && selectedRequest.whatBusinessProblemIsSolved}</Text>
                    <Text>{!selectedRequest.whatBusinessProblemIsSolved && '--'}</Text>
                </div>
            )}
            {filterField(StringConstants.BUISNESS_PROBLEM, selectedRequest.requestTypeValue) && isEditMode && (
                <div className="desc-textfield-wrap m-b-20">
                    <TextField
                        value={isEdittingRequestDetails ? businessProblemFieldValue : whatbusinessproblemissolved}
                        placeholder="What business problem is solved"
                        multiline
                        resizable={false}
                        borderless
                        rows={8}
                        onChange={handleWhatbusinessproblemissolvedChange}
                    />
                </div>
            )}

            {filterField(StringConstants.COMPETITIVE_OFFERING, selectedRequest.requestTypeValue) && (
                <div className="tab-details-sub-heading">COMPETITIVE OFFERING</div>
            )}
            {filterField(StringConstants.COMPETITIVE_OFFERING, selectedRequest.requestTypeValue) && !isEditMode && (
                <div className="dp-content-text">
                    <Text>{selectedRequest.competitiveOffering && selectedRequest.competitiveOffering}</Text>
                    <Text>{!selectedRequest.competitiveOffering && '--'}</Text>
                </div>
            )}
            {filterField(StringConstants.COMPETITIVE_OFFERING, selectedRequest.requestTypeValue) && isEditMode && (
                <div className="desc-textfield-wrap m-b-20">
                    <TextField
                        value={isEdittingRequestDetails ? compOfferFieldValue : competitiveoffering}
                        placeholder="Competitive offering"
                        multiline
                        resizable={false}
                        borderless
                        rows={8}
                        onChange={handleCompetitiveofferingChange}
                    />
                </div>
            )}

            {filterField(StringConstants.IS_DESIGNED_FOR_SURFACE_POSSIBLE, selectedRequest.requestTypeValue) && (
                <div className="tab-details-sub-heading">IS DFS (DESIGNED FOR SURFACE) POSSIBLE?</div>
            )}
            {filterField(StringConstants.IS_DESIGNED_FOR_SURFACE_POSSIBLE, selectedRequest.requestTypeValue) && !isEditMode && (
                <div className="dp-content-text">
                    <Text>{selectedRequest.isDesignForSurfacedPossible && selectedRequest.isDesignForSurfacedPossible}</Text>
                    <Text>{!selectedRequest.isDesignForSurfacedPossible && '--'}</Text>
                </div>
            )}
            {filterField(StringConstants.IS_DESIGNED_FOR_SURFACE_POSSIBLE, selectedRequest.requestTypeValue) && isEditMode && (
                <div className="desc-textfield-wrap m-b-20">
                    <TextField
                        value={isEdittingRequestDetails ? isDfsFieldValue : isdesignforsurfacedfspossible}
                        placeholder="Is DFS (Designed for Surface) possible?"
                        multiline
                        resizable={false}
                        borderless
                        rows={8}
                        onChange={handleIsdesignforsurfacedfspossibleChange}
                    />
                </div>
            )}

            {filterField(StringConstants.IS_DCF_REQUEST, selectedRequest.requestTypeValue) && (
                <>
                    <div className="tab-details-sub-heading">IS THIS A DCF REQUEST?</div>
                    <div className="dp-content-text">
                        {selectedRequest.isHidden ? (
                            <Text>{getNameByID(yesNoLookup, selectedRequest.isDcfRequest)}</Text>
                        ) : (
                            <ActionDropdown
                                data={yesNoLookup}
                                onCompleteHandler={onCompleteHandler}
                                fieldName={StringConstants.IS_DCF_REQUEST}
                                currentValue={selectedRequest.isDcfRequest}
                                isEditMode={isEditMode}
                                selectedRequest={selectedRequest}
                            />
                        )}
                    </div>
                </>
            )}

            {selectedRequest.isDcfRequest && filterField('dcfJustification', selectedRequest.requestTypeValue) && (
                <div className="tab-details-sub-heading">DCF JUSTIFICATION</div>
            )}
            {selectedRequest.isDcfRequest && filterField('dcfJustification', selectedRequest.requestTypeValue) && !isEditMode && (
                <div className="dp-content-text">
                    <Text>{selectedRequest.dcfJustification && selectedRequest.dcfJustification}</Text>
                    <Text>{!selectedRequest.dcfJustification && '--'}</Text>
                </div>
            )}
            {selectedRequest.isDcfRequest && filterField('dcfJustification', selectedRequest.requestTypeValue) && isEditMode && (
                <div className="desc-textfield-wrap m-b-20">
                    <TextField
                        value={isEdittingRequestDetails ? dcfJustificationFieldValue : dcfJustification}
                        placeholder="DCF justification"
                        multiline
                        resizable={false}
                        borderless
                        rows={8}
                        onChange={handleDcfJustificationChange}
                    />
                </div>
            )}
            {selectedRequest.isDcfRequest && filterField(StringConstants.IS_DCF_REQUEST, selectedRequest.requestTypeValue) && (
                <table className="dp-content-details-tbl">
                    <tbody>
                        <tr className="tbl-header">
                            <td>IS THIS A BLOCKING SALE?</td>
                            <td>DCF APPROVED?</td>
                            <td>DCF APPROVED VALUE</td>
                            <td>DCF SR's</td>
                        </tr>
                        <tr>
                            <td>
                                {selectedRequest.isHidden ? (
                                    <Text>{getNameByID(yesNoLookup, selectedRequest.isBlockingSale)}</Text>
                                ) : (
                                    <ActionDropdown
                                        data={yesNoLookup}
                                        onCompleteHandler={onCompleteHandler}
                                        fieldName="isBlockingSale"
                                        currentValue={selectedRequest.isBlockingSale}
                                        isEditMode={isEditMode}
                                        selectedRequest={selectedRequest}
                                    />
                                )}
                            </td>
                            <td>
                                {selectedRequest.isHidden ? (
                                    <Text>{getNameByID(yesNoLookup, selectedRequest.isDcfApproved)}</Text>
                                ) : (
                                    <ActionDropdown
                                        data={yesNoLookup}
                                        onCompleteHandler={onCompleteHandler}
                                        fieldName="isDcfApproved"
                                        currentValue={selectedRequest.isDcfApproved}
                                        isEditMode={isEditMode}
                                        selectedRequest={selectedRequest}
                                    />
                                )}
                            </td>
                            <td>
                                {!isEditMode && (
                                    <>
                                        {selectedRequest.dcfApprovedValue && (
                                            <Text>
                                                ${formatCurrency(selectedRequest.dcfApprovedValue)}
                                            </Text>
                                        )}
                                        <Text>{!selectedRequest.dcfApprovedValue && '--'}</Text>
                                    </>
                                )}
                                {isEditMode && (
                                    <div className="desc-textfield-wrap m-b-20">
                                        <NumberFormat
                                            thousandSeparator={true}
                                            value={isEdittingRequestDetails ? dcfApprovedFieldValue : dcfApprovedValue}
                                            onValueChange={handleDcfApprovedValueChange}
                                            placeholder="eg: $167,700"
                                            allowNegative={false}
                                            prefix={'$'}
                                            decimalScale={2}
                                            className={`format-field regular-text-field   `}
                                            inputMode="numeric"
                                        />
                                    </div>
                                )}
                            </td>
                            <td>
                                {!isEditMode && (
                                    <>
                                        <Text>{selectedRequest.dcfSR && selectedRequest.dcfSR}</Text>
                                        <Text>{!selectedRequest.dcfSR && '--'}</Text>
                                    </>
                                )}
                                {isEditMode && (
                                    <div className="desc-textfield-wrap m-b-20">
                                        <TextField
                                            value={isEdittingRequestDetails ? dcfSRFieldValue : dcfSR}
                                            placeholder="DCF SR's"
                                            resizable={false}
                                            borderless
                                            onChange={handleDcfSRChange}
                                        />
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
            {(filterField(StringConstants.PROOF_OF_PURCHASE_ATTACHED, selectedRequest.requestTypeValue) ||
                filterField(StringConstants.SERIAL_NUMBERS_ATTACHED, selectedRequest.requestTypeValue) ||
                filterField(StringConstants.CSS_CASE_NUMBER, selectedRequest.requestTypeValue)) && (
                <table className="dp-content-details-tbl">
                    <tbody>
                        <tr className="tbl-header">
                            {filterField(StringConstants.PROOF_OF_PURCHASE_ATTACHED, selectedRequest.requestTypeValue) && (
                                <td>PROOF OF PURCHASE ATTACHED?</td>
                            )}
                            {filterField(StringConstants.SERIAL_NUMBERS_ATTACHED, selectedRequest.requestTypeValue) && (
                                <td>SERIAL NUMBER ATTACHED?</td>
                            )}
                            {filterField(StringConstants.CSS_CASE_NUMBER, selectedRequest.requestTypeValue) && <td>CSS CASE NUMBER</td>}
                        </tr>
                        <tr>
                            {filterField(StringConstants.PROOF_OF_PURCHASE_ATTACHED, selectedRequest.requestTypeValue) && (
                                <td>
                                    {selectedRequest.isHidden ? (
                                        <Text>{getNameByID(yesNoLookup, selectedRequest.proofOfPurchaseAttached)}</Text>
                                    ) : (
                                        <ActionDropdown
                                            data={yesNoLookup}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="proofOfPurchaseAttached"
                                            currentValue={selectedRequest.proofOfPurchaseAttached}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {filterField(StringConstants.SERIAL_NUMBERS_ATTACHED, selectedRequest.requestTypeValue) && (
                                <td>
                                    {selectedRequest.isHidden ? (
                                        <Text>{getNameByID(yesNoLookup, selectedRequest.serialNumbersAttached)}</Text>
                                    ) : (
                                        <ActionDropdown
                                            data={yesNoLookup}
                                            onCompleteHandler={onCompleteHandler}
                                            fieldName="serialNumbersAttached"
                                            currentValue={selectedRequest.serialNumbersAttached}
                                            isEditMode={isEditMode}
                                            selectedRequest={selectedRequest}
                                        />
                                    )}
                                </td>
                            )}
                            {filterField(StringConstants.CSS_CASE_NUMBER, selectedRequest.requestTypeValue) && (
                                <td>
                                    {!isEditMode && (
                                        <>
                                            <Text className="tbl-css-num">
                                                {selectedRequest.cssCaseNumber && selectedRequest.cssCaseNumber}
                                            </Text>
                                            <Text>{!selectedRequest.cssCaseNumber && '--'}</Text>
                                        </>
                                    )}
                                    {isEditMode && (
                                        <div className="desc-textfield-wrap m-b-20">
                                            <TextField
                                                value={isEdittingRequestDetails ? cssCaseNumberFieldValue : cssCaseNumber}
                                                placeholder="CSS case number"
                                                resizable={false}
                                                borderless
                                                onChange={handleCssCaseNumberChange}
                                            />
                                        </div>
                                    )}
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            )}

            {filterField(StringConstants.ANSWER, selectedRequest.requestTypeValue) && <div className="tab-details-sub-heading">ANSWER</div>}
            {filterField(StringConstants.ANSWER, selectedRequest.requestTypeValue) && !isEditMode && (
                <div className="dp-content-text">
                    <Text>{selectedRequest.answer && selectedRequest.answer}</Text>
                    <Text>{!selectedRequest.answer && '--'}</Text>
                </div>
            )}
            {filterField(StringConstants.ANSWER, selectedRequest.requestTypeValue) && isEditMode && (
                <div className="desc-textfield-wrap m-b-20">
                    <TextField
                        value={isEdittingRequestDetails ? answerFieldValue : answer}
                        placeholder="Answer"
                        multiline
                        resizable={false}
                        borderless
                        rows={8}
                        onChange={handleAnswerChange}
                    />
                </div>
            )}
            <BugInformationModal
                modalState={isBugInfoModalOpen}
                updateModalState={setIsBugInfoModalOpen}
                selectedRequest={selectedRequest}
                onUpdateHandler={bugInfoUpdateHandler}
            />
        </div>
    );
};

export default RequestDetailsTab;
