import React from 'react';
import { Text, TextField, Link } from '@fluentui/react';
import NumberFormat from 'react-number-format';
import { Trans } from 'react-i18next';
import { keyPress } from 'app/components/helpers';
import { StringConstants } from 'app/utils/constants';
interface IProps {
    custEmail: string;
    contactNum: string;
    custShippingAddress: string;
    custEmailHText: boolean;
    contactNumHtext: boolean;
    custShippingAddHText: boolean;
    setAddress: Function;
    requestType: string;
    setcustAltPage: any;
    showModal: any;
    contactName: any;
    contactNameHText: any;
    animateAddress: string;
    contactNumPrefix: number;
    countryList: Array<any>;
}

const Address: React.FC<IProps> = (props) => {
    const prepareOptions = () => {
        let options: Array<any> = [
            <option value={null} selected disabled hidden>
                Select the country
            </option>
        ];
        if (props.countryList) {
            props.countryList.forEach((item) => {
                options.push(
                    <option key={item.id} value={item.dialingCode}>
                        {item.name}
                        {' - +'}
                        {item.dialingCode}
                    </option>
                );
            });
        }
        return options;
    };
    return (
        <div
            className={`cant-find-customer address-section ${props.animateAddress === 'S_TO_NEXT' ? 'nextSlide' : ''} ${
                props.animateAddress === 'S_TO_PREV' ? 'prevSlide' : ''
            } ${props.animateAddress === 'A_TO_RIGHT' ? 'animateToRight' : ''} ${
                props.animateAddress === 'A_TO_LEFT' ? 'animateToLeft' : ''
            }`}>
            <div className={`row-flex m-t-40`}>
                <div className="mb-40 shift-lt">
                    <label>
                        <Trans>Contact Name</Trans>
                    </label>
                    <TextField
                        value={props.contactName}
                        onChange={(e: any) => {
                            props.setAddress(e.target.value, 'CUSTOMER_NAME');
                        }}
                        placeholder="e.g: Alesia Hester"
                        className="regular-text-field"
                    />
                    {props.contactNameHText && (
                        <Text className="helper-txt">
                            <Trans>Contact name is mandatory.</Trans>
                        </Text>
                    )}
                </div>
                <div className="shift-rt mb-40">
                    <label>
                        <Trans>Contact Email</Trans>{' '}
                    </label>
                    <TextField
                        value={props.custEmail}
                        onChange={(e: any) => {
                            props.setAddress(e.target.value, 'EMAIL');
                        }}
                        placeholder="e.g: jane.doe@microsoft.com"
                        className="regular-text-field"
                    />
                    {props.custEmailHText && (
                        <Text className="helper-txt">
                            <Trans>Please enter a valid ID</Trans>
                        </Text>
                    )}
                </div>
            </div>

            <div className={`m-t-40`}>
                <label>
                    <Trans>Shipping Address</Trans>
                </label>
                <TextField
                    value={props.custShippingAddress}
                    onChange={(e: any) => {
                        props.setAddress(e.target.value, 'ADDRESS');
                    }}
                    multiline
                    autoAdjustHeight
                    resizable={false}
                    rows={8}
                    className="desc"
                    placeholder="e.g: 866 Summit Park Avenue, Southfield, Michigan,United States(48075)"
                />
                {props.custShippingAddHText && (
                    <Text className="helper-txt">
                        <Trans>Please enter a shipping address.</Trans>
                    </Text>
                )}
            </div>

            <div className={`row-flex m-t-40`}>
                <div className="">
                    <label>
                        <Trans>Contact Number</Trans>
                    </label>
                    <div className="custom-row">
                        <div>
                            <select
                                value={props.contactNumPrefix}
                                onChange={(e: any) => {
                                    props.setAddress(e.target.value, 'CONTACT_NUMBER_PREFIX');
                                }}
                                aria-label="Enter customer mobile number"
                                className={`format-field-cont ${props.contactNumHtext ? 'error-feild' : ''} ${
                                    props.contactNumHtext ? 'error-feild' : ''
                                }`}
                                defaultValue={null}>
                                {prepareOptions()}
                            </select>
                        </div>
                        <div className={`format-field-cont ${props.contactNumHtext ? 'error-feild' : ''} left-space`}>
                            <NumberFormat
                                mask=" "
                                className="regular-text-field format-field"
                                value={props.contactNum}
                                onChange={(e: any) => {
                                    props.setAddress(e.target.value, 'CONTACT_NUMBER');
                                }}
                                placeholder="e.g:(111) 111-1111"
                                allowNegative={false}
                            />
                        </div>
                    </div>

                    {props.contactNumHtext && (
                        <Text className="helper-txt">
                            <Trans>Please enter a valid contact number.</Trans>
                        </Text>
                    )}
                </div>
            </div>
            {props.requestType === StringConstants.POST_SALES && (
                <Link
                    tabIndex={0}
                    onClick={() => {
                        props.setcustAltPage(true);
                        props.showModal();
                    }}
                    onKeyPress={(e) =>
                        keyPress(e, () => {
                            props.setcustAltPage(true);
                            props.showModal();
                        })
                    }
                    className={`cant-find-customer add-alternate-contact ${
                        props.requestType === StringConstants.POST_SALES || props.requestType === StringConstants.PRE_SALES ? 'slideDown' : ''
                    }`}
                    data-testid="add-alternate-contact">
                    <Trans>Add alternate contact</Trans>
                </Link>
            )}
        </div>
    );
};
export default Address;
